import {useMediaQuery} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import React, {forwardRef, useEffect, useMemo, useRef, useState} from 'react';
import {Scrollbar} from 'react-scrollbars-custom';
import styled, {css} from 'styled-components';

import {generateTablesContentHeight} from './utils';
/**@deprecated не использовать этот компонент, заменить на ./ReactTableComponent**/
const DefaultSortTable = forwardRef(function DefaultSortTable(props, ref) {
  const {
    hasMaxHeight = true,
    headerHeight = 60,
    customTableHeight = null,
    noScrollX,
    columns,
    rows = [],
    defaultSortModel = [
      {
        field: 'player',
        sort: 'asc',
      },
    ],
    hideFooterPagination = true,
    disableSelectionOnClick = true,
    disableDensitySelector = true,
    disableColumnSelector = true,
    disableColumnMenu = true,
    onRowClick = () => {},
    autoHeight = true,
    hasStickyHeader = false, //если хотим зафиксировать шапку, то нужно либо ограничить высоту FullHeightWrapper, либо fitAvailableHeight=true
    fitAvailableHeight = false,
    wrapperHeight,
    scrollToRight = false,
    stickyHeaders = false,
    lastColumnSticky = false,
    translateContentSizeYToHolder = false,
    stickyHeadersTrashhold = 993,
    firstColumnsStickyTrashhold = 'sm',
    lastColumnsStickyTrashhold = 'sm',
    marginBottom = 0,
  } = props;
  const hasStickyHeadersTrashhold = useMediaQuery((theme) => theme.breakpoints.up(stickyHeadersTrashhold));
  const calculateHeight = useMemo(() => {
    if (wrapperHeight) return wrapperHeight;
    return fitAvailableHeight && ref?.current
      ? window.innerHeight - ref.current.getBoundingClientRect().top - 14 + 'px'
      : generateTablesContentHeight(rows, hasMaxHeight, marginBottom) + 'px';
  }, [fitAvailableHeight, ref?.current]);
  const [sortModel, setSortModel] = useState(defaultSortModel);

  const scrollbar = useRef(null);
  const isMount = useRef(false);

  const ScrollTable = () => {
    if (isMount.current === false && scrollToRight) {
      scrollbar.current.scrollToRight();
      isMount.current = true;
    }
  };

  useEffect(() => {
    if (scrollbar.current && scrollToRight) {
      isMount.current = false;
    }
  }, [props]);

  const setHandleTableHeight = () => {
    if (customTableHeight) {
      return customTableHeight;
    } else {
      return generateTablesContentHeight(rows, hasMaxHeight, marginBottom);
    }
  };

  return (
    <Wrapper
      height={hasStickyHeader ? calculateHeight : '100%'}
      ref={ref}
      translateContentSizeYToHolder={translateContentSizeYToHolder}
    >
      <Container
        firstColumnsStickyTrashhold={firstColumnsStickyTrashhold}
        hasCustomScroll={hasStickyHeader}
        lastColumnSticky={lastColumnSticky}
        lastColumnsStickyTrashhold={lastColumnsStickyTrashhold}
        stickyHeaders={stickyHeaders && hasStickyHeadersTrashhold}
      >
        {!hasStickyHeader ? (
          <Scrollbar
            disableTrackXWidthCompensation
            noScrollX={noScrollX}
            ref={scrollbar}
            style={{
              height: setHandleTableHeight(),
              width: '100%',
            }}
            translateContentSizeYToHolder={translateContentSizeYToHolder}
            onUpdate={() => ScrollTable()}
          >
            <DataGrid
              {...props}
              autoHeight={!hasStickyHeader && autoHeight}
              columnBuffer={columns.length}
              columns={columns}
              disableColumnMenu={disableColumnMenu}
              disableColumnSelector={disableColumnSelector}
              disableDensitySelector={disableDensitySelector}
              disableSelectionOnClick={disableSelectionOnClick}
              experimentalFeatures={{newEditingApi: true}}
              headerHeight={headerHeight}
              hideFooterPagination={hideFooterPagination}
              rowThreshold={10000}
              rows={rows}
              sortModel={sortModel}
              sortingOrder={['asc', 'desc']}
              throttleRowsMs={20000}
              onRowClick={onRowClick}
              onSortModelChange={(model) => setSortModel(model)}
            />
          </Scrollbar>
        ) : (
          <DataGrid
            {...props}
            autoHeight={!hasStickyHeader && autoHeight}
            columnBuffer={columns.length}
            columns={columns}
            disableColumnMenu={disableColumnMenu}
            disableColumnSelector={disableColumnSelector}
            disableDensitySelector={disableDensitySelector}
            disableSelectionOnClick={disableSelectionOnClick}
            experimentalFeatures={{newEditingApi: true}}
            headerHeight={headerHeight}
            hideFooterPagination={hideFooterPagination}
            rowThreshold={10000}
            rows={rows}
            sortModel={sortModel}
            sortingOrder={['asc', 'desc']}
            throttleRowsMs={20000}
            onRowClick={onRowClick}
            onSortModelChange={(model) => setSortModel(model)}
          />
        )}
      </Container>
    </Wrapper>
  );
});

export default DefaultSortTable;

export const Wrapper = styled.div`
  display: flex;
  min-height: 200px;
  height: ${(props) => props.height};
  width: 100%;
  .ScrollbarsCustom {
    min-height: 170px;
    &-Track {
      background: #b6b6b6 !important;
    }
    &-Thumb {
      background: #686868 !important;
    }
    &-Content {
      ${({translateContentSizeYToHolder}) =>
        translateContentSizeYToHolder &&
        css`
          max-width: 100%;
          column-width: 1000000px;
        `};
    }
  }
`;
export const Container = styled.div`
  flex-grow: 1;
  height: 100%;
  .MuiDataGrid {
    background-color: blue !important;
    border-radius: 25px !important;
    &-main {
      overflow: visible;
      background-color: white;
    }
    &-columnHeaders {
      overflow: ${(props) => (props.hasCustomScroll ? 'hidden' : 'visible')};
      border-bottom: 0;
      ${(props) => {
        if (props.stickyHeaders) {
          return css`
            position: sticky;
            top: 0;
            background-color: white;
            z-index: 1000;
          `;
        }
      }}
    }
    &-columnHeadersInner {
      ${(props) => {
        if (props.stickyHeaders) {
          return css`
            background-color: #fff;
          `;
        }
      }}
    }
    &-columnHeader {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      &:first-child {
        ${({theme, firstColumnsStickyTrashhold}) => theme.breakpoints.up(firstColumnsStickyTrashhold)} {
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 999;
        }
      }
      &:last-child {
        ${({theme, lastColumnsStickyTrashhold}) => theme.breakpoints.up(lastColumnsStickyTrashhold)} {
          ${(props) => {
            if (props.lastColumnSticky) {
              return css`
                position: sticky;
                right: 0;
                background-color: white;
                z-index: 999;
              `;
            }
          }}
        }
      }
    }
    &-columnHeaderTitle {
      font: bold normal 14px/57px 'Proxima Nova';
      color: #2c2c2c;
    }
    &-cell {
      font: normal normal 14px/16px 'Proxima Nova';
      color: #2c2c2c;
      letter-spacing: 0.02em;
      &:first-child {
        ${({theme, firstColumnsStickyTrashhold}) => theme.breakpoints.up(firstColumnsStickyTrashhold)} {
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 999;
        }
        &:hover {
          background-color: #f5f5f5;
        }
      }
      &:last-child {
        ${({theme, lastColumnsStickyTrashhold}) => theme.breakpoints.up(lastColumnsStickyTrashhold)} {
          ${(props) => {
            if (props.lastColumnSticky) {
              return css`
                position: sticky;
                right: 0;
                background-color: white;
                z-index: 999;
              `;
            }
          }}
        }
      }
      &:focus,
      &:focus-within {
        outline: none !important;
      }
    }
    &-virtualScroller {
      overflow: ${(props) => (props.hasCustomScroll ? 'auto' : 'visible') + '!important'};
      ${(props) => {
        if (props.stickyHeaders) {
          return css`
            margin-top: 0 !important;
          `;
        }
      }}
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background: #686868 !important;
      }
    }
    &-virtualScrollerContent {
      overflow: visible;
    }
    &-root {
      border-right: 0;
      @media screen and (max-width: 768px) {
        border-top: 0;
      }
      .MuiDataGrid {
        &-columnHeader {
          padding: 0 12px;
          min-height: 60px;
          &:focus,
          &:focus-within {
            outline: none !important;
          }
        }
        &-columnHeaderTitleContainer {
          padding: 0;
        }
        &-columnSeparator {
          right: 0 !important;
        }
      }
    }
    &-footerContainer {
      min-height: auto;
    }
  }
`;
