import PropTypes from 'prop-types';
import React from 'react';

import TeamCard from '../TeamCard';
import {SchoolName, WrapperInner} from './TeamCards';

const TeamCards = ({schoolName, cards}) => {
  return (
    <>
      <SchoolName>{schoolName?.toUpperCase()}</SchoolName>
      <WrapperInner>
        {cards?.map((team, index) => (
          <TeamCard key={index} team={team} />
        ))}
      </WrapperInner>
    </>
  );
};

TeamCards.propTypes = {
  schoolName: PropTypes.string,
  cards: PropTypes.array,
};

export default TeamCards;
