import styled from 'styled-components';

export const Body = styled.div`
  padding: 12px 24px 12px 34px;
  font-size: 14px;

  @media screen and (max-width: 360px) {
    padding: 12px;
  }
`;

export const Container = styled.div`
  background-color: white;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const TodayDate = styled.div`
  font-weight: 600;
  font-family: 'Proxima Nova';
  font-size: 16px;
`;

export const Rows = styled.div`
  display: flex;
  padding: 4px 24px;
  padding-left: 34px;
  padding-top: ${(props) => props.isMobile && '12px'};
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  font-size: 14px;
  color: #747474;
  font-weight: normal;

  @media screen and (max-width: 360px) {
    padding: 4px 12px;
  }
`;
