import {getStatsMapInfo, getStatsThrowsStatistics} from '@api/StatsAPI';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  statistics: {
    isLoading: false,
    isLoad: false,
    data: {},
    error: false,
  },
  map: {
    isLoading: false,
    isLoad: false,
    data: {
      away: [],
      home: [],
      showUnsuccessfullShotAttempts: false,
    },
    error: false,
  },
};

//redusers
const throwsStatistics = createSlice({
  name: 'throwsStatistics',
  initialState,
  reducers: {
    clearData: () => initialState,
    statisticsLoading: ({statistics}) => {
      statistics.isLoading = true;
    },
    statisticsLoad: ({statistics}, action) => {
      statistics.isLoading = false;
      statistics.isLoad = true;
      statistics.data = action.payload.data?.result;
    },
    statisticsError: ({statistics}) => {
      statistics.isLoading = false;
      statistics.isLoad = true;
      statistics.error = true;
    },
    mapLoading: ({map}) => {
      map.isLoading = true;
    },
    mapLoad: ({map}, action) => {
      map.isLoading = false;
      map.isLoad = true;
      map.data = action.payload.data;
    },
    mapError: ({map}) => {
      map.isLoading = false;
      map.isLoad = true;
      map.error = true;
    },
  },
});

const {actions, reducer} = throwsStatistics;

const {clearData, statisticsLoading, statisticsLoad, statisticsError, mapLoading, mapLoad, mapError} = actions;

export {reducer as throwsStatisticsReducer};

//fetch fns
function loadData(fetchAPI, loadingAction, loadAction, errorAction) {
  return (dispatch) => {
    dispatch(loadingAction());
    return fetchAPI()
      .then((data) => dispatch(loadAction({data: data.data})))
      .catch(() => {
        dispatch(errorAction());
      });
  };
}
export const clearThrowsStatisticsdData = () => (dispatch) => dispatch(clearData());

export const loadThrowsStatistics = (params) =>
  loadData(() => getStatsThrowsStatistics(params), statisticsLoading, statisticsLoad, statisticsError);

export const loadThrowsMap = (params) => loadData(() => getStatsMapInfo(params), mapLoading, mapLoad, mapError);
