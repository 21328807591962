import React from 'react';

import {Button} from '../../ui/Button';
import ReactComponentModal from '../../ui/ReactComponent/ReactComponentModal/ReactComponentModal';

export default function returnFromArchiveModal(props) {
  let {returnFromArchiveModalState = false, onClose = () => {}, onSave = () => {}} = props;

  return (
    <ReactComponentModal
      buttons={
        <React.Fragment>
          <Button
            className={'DialogButton DialogButton_no'}
            onClick={() => {
              onClose();
            }}
          >
            Отменить
          </Button>
          <Button
            className={'DialogButton DialogButton_yes'}
            type={'submit'}
            onClick={() => {
              onSave();
            }}
          >
            Восстановить
          </Button>
        </React.Fragment>
      }
      className="ReactComponentModalDefault"
      content={
        <>
          <p>Вы уверены, что хотите восстановить игрока из архива?</p>
        </>
      }
      title="Восстановить игрока"
      visible={returnFromArchiveModalState}
      onClose={() => {
        onClose();
      }}
    />
  );
}
