import '@lourenci/react-kanban/dist/styles.css';

import {
  cleanMedicalProfileForm,
  getMedicalProfileRecommendationsForCard,
  handleChangeForm,
  handleMedicalProfileBoard,
  postPlayerMedicalProfileForm,
  savePlayerMedicalProfileRecommendation,
} from '@actions/MedicalProfileActions';
import {emptyBoard, generateBoardByUserData} from '@helpers/MedicalProfileHelper';
import Board, {moveCard} from '@lourenci/react-kanban';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import {Button} from '@ui/Button';
import ButtonDefault from '@ui/MUI/Button';
import {grey} from '@ui/MUI/colors';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import styled, {createGlobalStyle} from 'styled-components';

import MedicalProfileAPI from '../../../api/MedicalProfileAPI';
import OldTabs from '../../ui/OldTabs';
import ReactComponentModal from '../../ui/ReactComponent/ReactComponentModal/ReactComponentModal';
import Consultation from '../modals/Consultation';
import GeneralInfo from '../modals/GeneralInfo';
import MedicalHistory from '../modals/MedicalHistory';
import Nutrition from '../modals/Nutrition';
import Recommendations from '../modals/Recommendations';
import Training from '../modals/Training';
import Card from '../tools/MedicalProfileCard';

export default function MedicalProfileBoard(props) {
  const dispatch = useDispatch();
  const {updateUserData = () => {}} = props;
  const {medicalProfileUserData, medicalProfileBoard, medicalProfileForm, medicalProfileRecommendation, state} =
    useSelector((state) => {
      const medicalProfileBoard = state.medicalProfile.medicalProfileBoard
        ? state.medicalProfile.medicalProfileBoard
        : emptyBoard();
      const medicalProfileUserData = state.medicalProfile.medicalProfileUserData
        ? state.medicalProfile.medicalProfileUserData
        : [];
      const medicalProfileForm = state.medicalProfile.medicalProfileForm ? state.medicalProfile.medicalProfileForm : {};
      const medicalProfileRecommendation = state.medicalProfile.medicalProfileRecommendation || {
        comment: '',
      };
      return {
        medicalProfileBoard: medicalProfileBoard,
        medicalProfileUserData: medicalProfileUserData,
        medicalProfileForm: medicalProfileForm,
        medicalProfileRecommendation,
        state: state,
      };
    }, shallowEqual);

  useEffect(() => {
    if (medicalProfileUserData) {
      const updatedBoard = generateBoardByUserData(medicalProfileUserData, state);
      dispatch(handleMedicalProfileBoard(updatedBoard));
    }
  }, [medicalProfileUserData]);

  useEffect(() => {
    if (medicalProfileForm.objectId) {
      dispatch(getMedicalProfileRecommendationsForCard(medicalProfileForm.objectId));
    }
  }, [medicalProfileForm.objectId]);

  const [editTherapyModalVisible, setEditTherapyModalVisible] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });
  const [deleteCardModalState, setDeleteCardModalState] = useState(false);
  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {},
  });
  const editTherapyModalContent = () => {
    return (
      <>
        <ModalTitle>Изменить лечение</ModalTitle>
        <OldTabs
          tabs={[
            {
              label: 'Общая информация',
              content: <GeneralInfo control={control} errors={errors} setValue={setValue} />,
            },
            {
              label: 'История',
              content: <MedicalHistory />,
            },
            {
              label: 'Тренировка',
              content: <Training />,
            },
            {
              label: 'Посещение/Консультация',
              content: <Consultation />,
            },
            {
              label: 'Питание',
              content: <Nutrition />,
            },
            {
              label: 'Рекомендации',
              content: <Recommendations playerId={medicalProfileForm.playerId} />,
            },
          ]}
        ></OldTabs>
      </>
    );
  };

  const handleInitCard = (id) => {
    const card = medicalProfileUserData.find((card) => card.objectId === id);
    dispatch(handleChangeForm(card));
    setEditTherapyModalVisible({...editTherapyModalVisible, right: true});
  };
  function handleCardMove(_card, source, destination) {
    let updatedBoard = moveCard(medicalProfileBoard, source, destination);
    //сохранение единообразия данных в карточках доски
    //и карточках user data
    const updatedCard = medicalProfileUserData.find((card) => card.objectId === _card.id);
    const updatedCardIndex = medicalProfileUserData.findIndex((card) => card.objectId === _card.id);
    let cardWithUpdatedStatus = {...updatedCard, status: destination.toColumnId};
    medicalProfileUserData[updatedCardIndex] = cardWithUpdatedStatus;

    //если карточка перемещается на столбец "выздоровел"
    //назначаем автоматически текущую дату как дату выздоровления
    let newRecoveryDate = '';

    if (destination.toColumnId === 'recovered') {
      newRecoveryDate = new Date().getTime();
      cardWithUpdatedStatus.recoveryDate = newRecoveryDate;
    } else {
      delete cardWithUpdatedStatus.recoveryDate;
    }

    let updatedColumn = updatedBoard.columns.find((column) => column.id === destination.toColumnId);
    const updatedCardIndexInColumn = updatedColumn.cards.findIndex((i) => i.id === cardWithUpdatedStatus.objectId);
    const updatedColumnIndexInBoard = updatedBoard.columns.findIndex((i) => i.id === updatedColumn.id);
    updatedColumn.cards[updatedCardIndexInColumn] = {..._card, recoveryDate: newRecoveryDate};
    updatedBoard.columns[updatedColumnIndexInBoard] = updatedColumn;

    dispatch(postPlayerMedicalProfileForm(cardWithUpdatedStatus));
    dispatch(handleMedicalProfileBoard(updatedBoard));
  }

  const addRecommendation = () => {
    let requestObject = {
      playerId: medicalProfileForm.playerId,
      comment: medicalProfileRecommendation.comment,
      taskGroups: [],
    };
    if (medicalProfileForm.playerId) {
      dispatch(savePlayerMedicalProfileRecommendation(medicalProfileForm.objectId, requestObject));
    }
  };

  const deleteCard = () => {
    MedicalProfileAPI.deleteMedicalProfileCard(medicalProfileForm.objectId)
      .then(() => {
        let updatedMedicalProfileUserData = medicalProfileUserData.filter(
          (card) => card.objectId !== medicalProfileForm.objectId,
        );
        let updatedBoard = generateBoardByUserData(updatedMedicalProfileUserData, state);
        dispatch(handleMedicalProfileBoard(updatedBoard));
      })
      .then(() => {
        setDeleteCardModalState(false);
        setEditTherapyModalVisible({...editTherapyModalVisible, right: false});
        dispatch(cleanMedicalProfileForm(''));
        updateUserData();
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Карточка успешно удалена');
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message.length) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${err.response.data.message}`);
            }
          }
        }
      });
  };

  const isValidMedicalForm = (medicalProfileForm) => {
    let result = true;

    if (
      !medicalProfileForm.school ||
      !medicalProfileForm.group ||
      !medicalProfileForm.description ||
      !medicalProfileForm.playerId ||
      !medicalProfileForm.type ||
      !medicalProfileForm.status
    ) {
      result = false;
    }

    return result;
  };
  return (
    <Wrapper onSubmit={handleSubmit()}>
      <Board
        disableColumnDrag
        renderCard={(a, {dragging}) => (
          <Card
            a={a}
            cardOnClick={(id) => {
              handleInitCard(id);
            }}
            dragging={dragging}
          />
        )}
        onCardDragEnd={handleCardMove}
      >
        {medicalProfileBoard}
      </Board>
      <>
        <GlobalStyle />
        <DrawerDefault
          buttons={
            <ModalButtonsContainer>
              <ButtonDefault
                color="primary"
                variant="contained"
                onClick={() => {
                  if (!isValidMedicalForm(medicalProfileForm)) return;
                  const updatedData = medicalProfileUserData.map((card) => {
                    if (card.objectId === medicalProfileForm.objectId) return {...medicalProfileForm};
                    return card;
                  });

                  const updatedBoard = generateBoardByUserData(updatedData, state);

                  dispatch(handleMedicalProfileBoard(updatedBoard));
                  dispatch(postPlayerMedicalProfileForm(medicalProfileForm))
                    .then(() => {
                      setEditTherapyModalVisible({...editTherapyModalVisible, right: false});
                      updateUserData();
                    })
                    .then(() => {
                      addRecommendation();
                    })
                    .then(() => {
                      if (medicalProfileForm.objectId) {
                        dispatch(getMedicalProfileRecommendationsForCard(medicalProfileForm.objectId));
                      }
                    });
                }}
              >
                Сохранить
              </ButtonDefault>
              <ButtonsWrapper>
                <ButtonDefault
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setDeleteCardModalState(true);
                  }}
                >
                  Удалить
                </ButtonDefault>
                <ButtonDefault
                  color="neutral"
                  variant="contained"
                  onClick={() => {
                    dispatch(cleanMedicalProfileForm({}));
                    setEditTherapyModalVisible({...editTherapyModalVisible, right: false});
                  }}
                >
                  Закрыть
                </ButtonDefault>
              </ButtonsWrapper>
            </ModalButtonsContainer>
          }
          content={editTherapyModalContent()}
          drawerState={editTherapyModalVisible}
          setDrawerState={() => {
            setEditTherapyModalVisible({
              ...editTherapyModalVisible,
              right: !editTherapyModalVisible.right,
            });
            dispatch(handleChangeForm({}));
          }}
        />
      </>
      <ReactComponentModal
        buttons={
          <React.Fragment>
            <Button
              className={'DialogButton DialogButton_no'}
              onClick={() => {
                setDeleteCardModalState(false);
              }}
            >
              {window.innerWidth >= 480 ? 'Вернуться к карточке' : 'Вернуться'}
            </Button>
            <Button
              className={'DialogButton DialogButton_yes'}
              onClick={() => {
                deleteCard();
              }}
            >
              Удалить
            </Button>
          </React.Fragment>
        }
        className="ReactComponentModalDefault LinesNotSaved"
        content={
          <>
            <DialogContentText className="ReactComponentModalDefaultText">
              Вы действительно хотите удалить карточку?
            </DialogContentText>
          </>
        }
        title="Удалить карточку"
        visible={deleteCardModalState}
        onClose={() => {
          setDeleteCardModalState(false);
        }}
      />
    </Wrapper>
  );
}
const Wrapper = styled.form`
  .react-kanban {
    &-board {
      position: relative;
      padding: 0;
      background-color: #e5e5e5;
      border-radius: 6px;
      margin-bottom: 20px;

      &::-webkit-scrollbar-track {
        height: 8px;
        background-color: ${grey['12']} !important;
        border-radius: 6px;
      }
      &::-webkit-scrollbar-thumb {
        height: 8px;
        background-color: ${grey['54']} !important;
        border-radius: 2px;
      }
    }
    &-column {
      padding: 0;
      margin: 0;
      background-color: #e5e5e5;
      min-height: 80px !important;
      min-width: calc(100% / 6);
      flex: 0 0 calc(100% / 6);
      box-sizing: border-box;

      &:after {
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        display: block;
        width: 2px;
        height: 100%;
        background-color: #dddad4;
        z-index: 1;
        content: '';
      }

      :first-of-type {
        :after {
          display: none;
        }
      }

      > div {
        padding: 10px 13px 0 34px;
        :first-of-type {
          background: #fffaed;
        }
        > div > div {
          display: block !important;
        }
      }
      > div:nth-child(2) {
        max-height: 550px;
        overflow-y: auto;
        ::-webkit-scrollbar-thumb {
          height: 8px;
          background-color: #686868;
          border-radius: 2px;
        }
      }
      &-header {
        padding-bottom: 0;
        height: 50px;
        font: 700 normal 18px/50px 'Bebas Neue';
        color: #2c2c2c;
        :focus {
          outline: none;
        }
      }
    }
  }
  .EditTherapy {
    .sidebar__inner {
      .sidebar__content {
        padding-bottom: 0;
      }
    }
  }
`;
const DialogContentText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 18px/24px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: -0.02em;
`;
const ModalTitle = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  font: bold normal 16px/20px 'Proxima Nova';
  color: rgb(44, 44, 44);
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;
const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ButtonsWrapper = styled.div`
  .MuiButton {
    &-root {
      &:first-child {
        margin-right: 18px;
      }
    }
  }
`;
const GlobalStyle = createGlobalStyle`
  .MuiDrawer {
    &-paper {
      max-width: calc(100% - 250px); 
      .TabItemsBlock {
        padding-right: 32px;
        padding-left: 32px;
        margin: 0 -78px 0 -32px;
        justify-content: flex-start;
        background-color: #fffaed;
        box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.3);

        @media (min-width: 769px) and (max-width: 1200px) {
          height: 60px;
        }
        @media screen and (max-width: 768px) {
          padding-top: 8px;
          padding-bottom: 8px;
          flex-direction: column;
          height: auto;
        }

        .TabName {
          margin-right: 24px;
          color: #990011;
          text-transform: uppercase;
          @media screen and (max-width: 768px) {
            margin-right: 0;
            :last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }  
    }
  } 
`;
