import {Box} from '@ui/MUI';
import {green, grey} from '@ui/MUI/colors';
import React from 'react';

export const TOOLTIP_TITLES_MATRIX = {
  [green[60]]: 'Лучший',
  [grey[24]]: 'Средний',
};

export const TITLES_MATRIX = {
  uNcHvpkPFg4t: {y: 'Время(сек)'},
  i6ss9z3iip: {y: 'Время(сек)'},
  ncEDMKZVMM: {y: 'Время(сек)'},
  YPVZqpzfcI: {y: 'Длина(см)'},
  Xx9OYrarH5: {y: 'Высота(см)'},
  '-JuGyneCYBL1': {y: 'Длина(м)'},
  ice6a13iur: {y: 'Длина(cм)'},
  'u7F-wrG6GD6h': {y: 'Количество'},
  ft6g9B2yLv03: {y: 'Количество'},
  '54tGNLW9J7jV': {y: 'Время(сек)'},
  LGMFVmqjjd_l: {y: 'Время(сек)'},
  'AFcqyTRvguE-': {y: 'Время(сек)'},
  AYq86kLnrMDh: {y: 'Время(сек)'},
  _MDdw9tjWKh1: {y: 'Время(сек)'},
  afPSPXMFcpH1: {y: 'Время(сек)'},
  mI1idaZOiABd: {y: 'Время(сек)'},
  '7Gm7MTdkfsYa': {y: 'Время(сек)'},
  '4BZYbd168KaK': {y: 'Время(сек)'},
  eDjMWZEDA0Sb: {y: 'Время(сек)'},
  MS2_YS08eCPP: {y: 'Количество'},
  pE5xHMcCzju5: {y: 'Время(сек)'},
  ypjjV_ydGQrE: {y: 'Время(сек)', x: 'Этап, Количество попаданий'},
  Kf3AdBTfKHZI: {y: 'Время(сек)', x: 'Этап, Количество попаданий'},
  cc_yDyptaY6b: {y: 'Уровни'},
  wMhqP7HzsSgC: {y: 'Сумма (YBT)'},
};

export const svg = (color) => (
  <Box sx={{position: 'absolute', top: '50%', left: 0, transform: 'translateY(-65%)'}}>
    <svg fill="none" height="8" viewBox="0 0 38 8" width="38" xmlns="http://www.w3.org/2000/svg">
      <line stroke={color} strokeWidth="2" x2="38" y1="4" y2="4" />
      <path
        d="M18.9998 7C18.2044 7 17.4415 6.68404 16.8789 6.1216C16.3164 5.55916 16.0002 4.79628 16 4.00075C15.9998 3.20522 16.3156 2.44218 16.8779 1.87946C17.4401 1.31674 18.2029 1.0004 18.9983 1C19.7938 0.999603 20.5569 1.31518 21.1197 1.87734C21.6826 2.43951 21.9991 3.20222 21.9997 3.99775C22.0003 4.79328 21.6849 5.55647 21.1229 6.11948C20.561 6.68238 19.7986 6.99909 19.0033 7C19.0031 7 19.003 7 19.0028 7L18.9998 7Z"
        fill="white"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  </Box>
);
