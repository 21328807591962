import CommonReport from '@components/apps/NewBodyMassIndex/components/CommonReport';
import Norms from '@components/apps/NewBodyMassIndex/components/Norms';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {userRoles} from '@src/constants';
import {blue, darkRed, grey, purple, yellow} from '@ui/MUI/colors';
import React from 'react';

export const actions = {
  actionEdit: 'edit',
  actionAdd: 'add',
};

export const arrowsColors = {
  redArrow: '#8C1A1A',
  blueArrow: '#1C5B9A',
  white: '#FFFFFF',
  darkPurple: purple['100'],
  green: '#197B00',
  yellow: yellow['100'],
};

export const squareColors = {
  darkRed: 'rgba(192, 91, 84, 0.5)',
  red: 'rgba(192, 91, 84, 0.2)',
  yellow: yellow['100'],
  blue: 'rgba(25, 118, 210, 0.15)',
  purple: purple['100'],
  grey: '#DBDBDB',
  darkPurple: '#59056F',
  darkBlue: '#03478A',
  green: '#197B00',
};

export const pathSymbols = {
  arrowUp: 'path://M0.934805 5.835L0.0498047 4.95L4.9998 0L9.9498 4.95L9.0648 5.835L4.9998 1.77L0.934805 5.835Z ',
  arrowDown:
    'path://M0.934805 0.584434L0.0498047 1.46943L4.9998 6.41943L9.9498 1.46943L9.0648 0.584434L4.9998 4.64943L0.934805 0.584434Z',
  doubleUp:
    'path://M0.934805 6.41947L0.0498047 5.53447L4.9998 0.584473L9.9498 5.53447L9.0648 6.41947L4.9998 2.35447L0.934805 6.41947Z, M0.934805 10.5845L0.0498047 9.69951L4.9998 4.74951L9.9498 9.69951L9.0648 10.5845L4.9998 6.51951L0.934805 10.5845Z',
  doubleDown:
    'path://M0.934805 4.165L0.0498047 5.05L4.9998 10L9.9498 5.05L9.0648 4.165L4.9998 8.23L0.934805 4.165Z, M0.934805 -3.91006e-05L0.0498047 0.884961L4.9998 5.83496L9.9498 0.884961L9.0648 -3.91006e-05L4.9998 4.06496L0.934805 -3.91006e-05Z',
};

export const chartColumnColors = {
  heightNormal: '#AB60BE',
  heightHigher: '#59056F',
  weightHigher: '#03478A',
  weightNormal: '#2482DE',
  bmiHigher: '#197B00',
  bmiNormal: '#6DC656',
  anyLower: '#D8D8D8',
  fatNormal: yellow['60'],
  fatHigher: yellow['100'],
  fatLower: yellow['15'],
};

export const height = 'height';
export const weight = 'weight';
export const bmi = 'bmi';
export const caliperometry = 'caliperometry';

export const defaultBarWidth = 65;

export const TABS = {
  default: [{label: 'Общий отчет', value: <CommonReport />}],
  [userRoles.doctor]: [
    {label: 'Общий отчет', value: <CommonReport />},

    {
      label: 'Нормы',
      value: <Norms />,
    },
  ],
};

export const ACTION_TYPES = {
  edit: 'edit',
  add: 'add',
};

export const TYPES_OF_BODY = [
  {
    label: 'Астеник',
    value: 'asthenic',
  },
  {
    label: 'Нормостеник',
    value: 'normosthenic',
  },
  {
    label: 'Гиперстеник',
    value: 'hypersthenic',
  },
];
export const TYPES_OF_BODY_ENUM = {
  asthenic: TYPES_OF_BODY[0],
  normosthenic: TYPES_OF_BODY[1],
  hypersthenic: TYPES_OF_BODY[2],
};

export function flag(isIncrease, isDecrease) {
  if (isIncrease)
    return {
      ...this.increase,
      icon: <ExpandLessIcon sx={{zIndex: 1, fill: this.increase.mainColor}} />,
      type: 'increase',
    };
  if (isDecrease)
    return {
      ...this.decrease,
      icon: <ExpandMoreIcon sx={{zIndex: 1, fill: this.decrease.mainColor}} />,
      type: 'decrease',
    };
  return {};
}
export const GLOSSARY_ITEMS = {
  height: {
    label: 'Рост',
    increase: {
      flag: 'increaseHeightMoreFivePerSixMonth',
      label: 'увелич. на 5 см и более',
      bgc: grey['12'],
      mainColor: grey['68'],
    },
    decrease: {
      flag: 'decreaseHeightLessFivePerSixMonth',
      label: 'уменьшился',
      bgc: grey['12'],
      mainColor: grey['68'],
    },
    flag,
  },
  weight: {
    label: 'Вес',
    increase: {
      flag: 'increaseWeightMoreFivePerSixMonth',
      label: 'увелич. на 5 кг и более',
      bgc: darkRed['20'],
      mainColor: darkRed['100'],
    },
    decrease: {
      flag: 'decreaseWeightLessFivePerSixMonth',
      label: 'снижен на 5 кг и более',
      bgc: blue['15'],
      mainColor: blue['100'],
    },
    flag,
  },
  caliperometry: {
    label: '% жира',
    increase: {
      flag: 'increaseFatMoreFivePerSixMonth',
      label: 'выше нормы',
      bgc: darkRed['20'],
      mainColor: darkRed['100'],
    },
    decrease: {
      flag: 'decreaseFatLessFivePerSixMonth',
      label: 'ниже нормы',
      bgc: blue['15'],
      mainColor: blue['100'],
    },
    flag,
  },
};
