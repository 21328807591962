import React from 'react';

function Recommendation() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M46.0938 0H12.9062C5.77831 0 0 5.77831 0 12.9062V46.0938C0 53.2217 5.77831 59 12.9062 59H46.0938C53.2217 59 59 53.2217 59 46.0938V12.9062C59 5.77831 53.2217 0 46.0938 0ZM55.3125 46.0938C55.3125 51.1852 51.1852 55.3125 46.0938 55.3125H12.9062C7.81485 55.3125 3.6875 51.1852 3.6875 46.0938V12.9062C3.6875 7.81485 7.81485 3.6875 12.9062 3.6875H46.0938C51.1852 3.6875 55.3125 7.81485 55.3125 12.9062V46.0938Z"
          fill="#E0E0E0"
        />
        <path
          d="M18.4375 12.9062H14.75C13.7317 12.9062 12.9062 13.7317 12.9062 14.75V18.4375C12.9062 19.4558 13.7317 20.2812 14.75 20.2812H18.4375C19.4558 20.2812 20.2812 19.4558 20.2812 18.4375V14.75C20.2812 13.7317 19.4558 12.9062 18.4375 12.9062Z"
          fill="#E0E0E0"
        />
        <path
          d="M18.4375 25.8125H14.75C13.7317 25.8125 12.9062 26.6379 12.9062 27.6562V31.3438C12.9062 32.3621 13.7317 33.1875 14.75 33.1875H18.4375C19.4558 33.1875 20.2812 32.3621 20.2812 31.3438V27.6562C20.2812 26.6379 19.4558 25.8125 18.4375 25.8125Z"
          fill="#E0E0E0"
        />
        <path
          d="M18.4375 38.7188H14.75C13.7317 38.7188 12.9062 39.5442 12.9062 40.5625V44.25C12.9062 45.2683 13.7317 46.0938 14.75 46.0938H18.4375C19.4558 46.0938 20.2812 45.2683 20.2812 44.25V40.5625C20.2812 39.5442 19.4558 38.7188 18.4375 38.7188Z"
          fill="#E0E0E0"
        />
        <path d="M46.0938 14.75H23.9688V18.4375H46.0938V14.75Z" fill="#E0E0E0" />
        <path d="M46.0938 27.6562H23.9688V31.3438H46.0938V27.6562Z" fill="#E0E0E0" />
        <path d="M46.0938 40.5625H23.9688V44.25H46.0938V40.5625Z" fill="#E0E0E0" />
      </g>
    </React.Fragment>
  );
}
export default Recommendation;
