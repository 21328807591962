import {SCHOOL_LESSONS} from '@components/calendar/constants';
import {Typography} from '@mui/material';
import {GAME_TYPES} from '@src/constants';
import Box from '@ui/MUI/Box';
import Link from '@ui/MUI/Link';
import Stack from '@ui/MUI/Stack';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const getFormattedDate = (date) => moment(date).format('DD.MM.YYYY');
const getFormattedTime = (date) => moment(date).format('HH:mm');

const LessonView = ({
  type,
  name,
  schoolLesson,
  timeStart,
  timeEnd,
  teamName,
  opponentTeamName,
  playersNames,
  playerRole,
  room,
  lockerRoom,
  broadcastLink,
}) => {
  return (
    <>
      <Stack direction="row" flexWrap="wrap" mt={4} spacing={2}>
        <InfoBlock>
          <Label>Тип мероприятия</Label>
          <InfoText>{GAME_TYPES[type]}</InfoText>
        </InfoBlock>

        {name && (
          <InfoBlock>
            <Label>Название</Label>
            <InfoText>{name}</InfoText>
          </InfoBlock>
        )}

        {schoolLesson && (
          <InfoBlock>
            <Label>Предмет</Label>
            <InfoText>{SCHOOL_LESSONS?.[schoolLesson]}</InfoText>
          </InfoBlock>
        )}
      </Stack>

      <Stack direction="row" flexWrap="wrap" mt={4} spacing={2}>
        <InfoBlock>
          <Label>Дата начала</Label>
          <InfoText>{getFormattedDate(timeStart)}</InfoText>
        </InfoBlock>
        <InfoBlock>
          <Label>Время начала</Label>
          <InfoText>{getFormattedTime(timeStart)}</InfoText>
        </InfoBlock>
      </Stack>

      <Stack direction="row" flexWrap="wrap" mt={4} spacing={2}>
        <InfoBlock>
          <Label>Дата окончания</Label>
          <InfoText>{getFormattedDate(timeEnd)}</InfoText>
        </InfoBlock>
        <InfoBlock>
          <Label>Время окончания</Label>
          <InfoText>{getFormattedTime(timeEnd)}</InfoText>
        </InfoBlock>
      </Stack>

      <Stack direction="row" flexWrap="wrap" mt={4} spacing={2}>
        {teamName && (
          <InfoBlock>
            <Label>Команда</Label>
            <InfoText>{teamName}</InfoText>
          </InfoBlock>
        )}

        {opponentTeamName && (
          <InfoBlock>
            <Label>Команда соперника</Label>
            <InfoText>{opponentTeamName}</InfoText>
          </InfoBlock>
        )}
      </Stack>

      {playersNames?.length ? (
        <InfoBlock>
          <Label>Игроки</Label>
          <InfoText>{playersNames.join(', ')}</InfoText>
        </InfoBlock>
      ) : null}

      {playerRole && (
        <InfoBlock>
          <Label>Амплуа</Label>
          <InfoText>{playerRole}</InfoText>
        </InfoBlock>
      )}

      <Stack direction="row" flexWrap="wrap" mt={4} spacing={2}>
        {room && (
          <InfoBlock>
            <Label>Место проведения</Label>
            <InfoText>{room}</InfoText>
          </InfoBlock>
        )}

        {lockerRoom ? (
          <InfoBlock>
            <Label>Раздевалка</Label>
            <InfoText>{lockerRoom}</InfoText>
          </InfoBlock>
        ) : null}
      </Stack>

      {broadcastLink && (
        <InfoBlock>
          <Label>Ссылка</Label>
          <Link rel="noopener noreferrer" target="_blank" to={{pathname: broadcastLink}}>
            {broadcastLink}
          </Link>
        </InfoBlock>
      )}
    </>
  );
};

export default LessonView;

const InfoBlock = styled(Box)`
  min-width: 150px;
`;

const Label = styled(Typography)`
  ${({theme}) => theme.typography.body2}
  color: #747474;
`;

const InfoText = styled(Label)`
  font-weight: 600;
  color: #2c2c2c;
`;
