import StorageApi from '@api/StorageApi';
import {LoadingButton} from '@mui/lab';
import {Chip, Stack, Typography} from '@mui/material';
import {bytesToMb} from '@src/helpers';
import React, {useRef, useState} from 'react';

const UploadFile = ({
  label,
  onChange,
  value = '',
  uploadedText = 'Файл успешно добавлен',
  disabled = false,
  accept,
  isUploadToStorage = false,
  maxSizeMb,
  helperText,
  showButtonWhenFileSelected = true,
  ...props
}) => {
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  return (
    <Stack spacing={2}>
      {value && (
        <>
          <div>
            <Chip
              label={value.originalName || value.name}
              variant="outlined"
              onDelete={() => {
                onChange && onChange(null);
              }}
            />
          </div>
          {isUploadToStorage && <Typography>{uploadedText}</Typography>}
        </>
      )}
      <div>
        {(showButtonWhenFileSelected || !value || error) && (
          <LoadingButton component="label" disabled={disabled} loading={isLoading} sx={{height: '45px'}} {...props}>
            {value ? 'Заменить файл' : label}

            <input
              hidden
              accept={accept}
              disabled={disabled}
              ref={inputRef}
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                e.target.value = '';
                if (maxSizeMb) {
                  const size = bytesToMb(file.size);
                  if (size > maxSizeMb) {
                    setError(`Выберите файл меньше ${maxSizeMb} mb`);
                    return;
                  }
                }

                // вызываем onChange только если выбрали файл
                if (isUploadToStorage) {
                  setIsLoading(true);
                  StorageApi.uploadFile(file)
                    .then((res) => {
                      onChange(res.data);
                      setIsLoading(false);
                    })
                    .finally(() => setIsLoading(false));
                }
                if (!isUploadToStorage) {
                  setIsLoading(true);
                  onChange(file);
                  setIsLoading(false);
                }
                setError('');
              }}
            />
          </LoadingButton>
        )}
        {error && (
          <Typography color="error" display="block" mt={0} variant="caption">
            {error}
          </Typography>
        )}
        {props.error && (
          <Typography color="error" display="block" mt={0} variant="caption">
            {helperText}
          </Typography>
        )}
      </div>
    </Stack>
  );
};

export default UploadFile;
