import ConfirmModal from '@common/ConfirmModal';
import TrainingItem from '@common/TrainingControl/TrainingItem';
import {useOneTraining} from '@components/apps/TrainingControlProcess/hooks/api';
import isObjectEmpty from '@helpers/isObjectEmpty';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {LoadingButton} from '@mui/lab';
import {createTrainingControl, reset} from '@slices/trainingControlProcess';
import {Box, Button} from '@ui/MUI';
import React, {memo, useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import LoadingHolder from '../../../LoadingHolder';
import {ButtonsWrapper} from './TrainingFrom';

const TrainingForm = ({refetch, close}) => {
  const {isLoading, data, form} = useOneTraining();

  const methods = useForm({defaultValues: {exercises: []}});
  const dispatch = useStoreDispatch();

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const {
    formState: {isValid, isDirty},
    handleSubmit,
    setValue,
  } = methods;

  const onSubmit = (training) => {
    const payload = {
      ...training,
      id: data.id,
      exercises: training.exercises.map((exercise) => ({
        ...exercise,
        repeatEstimate: Number(exercise.repeatEstimate),
        timeEstimate: Number(exercise.timeEstimate),
      })),
    };

    dispatch(
      createTrainingControl({
        name: 'training',
        payload,
        skipFetch: true,
        onSuccess: () => {
          handleClose();
          refetch();
        },
      }),
    );
  };

  const handleClose = () => {
    close();
    dispatch(reset({name: 'training'}));
    if (isConfirmModalVisible) {
      setIsConfirmModalVisible(!isConfirmModalVisible);
    }
  };

  useEffect(() => {
    if (!isObjectEmpty(data)) {
      setValue(
        'exercises',
        data.exercises.map(({id}) => ({exerciseId: id, comment: ''})),
      );
    }
  }, [data]);

  const component = useMemo(() => {
    if (isLoading) {
      return <LoadingHolder />;
    }
    return <TrainingItem {...data} isForm />;
  }, [data, isLoading]);

  return (
    <>
      <FormProvider {...methods}>
        <Box bgcolor="#fff" borderRadius="6px" pt={4}>
          <Box px={4}>{component}</Box>
          <ButtonsWrapper>
            <LoadingButton
              color="primary"
              disabled={!isDirty || !isValid}
              loading={form.isLoading}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </LoadingButton>
            <Button
              disableElevation
              variant="contained"
              onClick={() => {
                setIsConfirmModalVisible(true);
              }}
            >
              Отменить
            </Button>
          </ButtonsWrapper>
        </Box>
      </FormProvider>
      <ConfirmModal
        isToggle={isConfirmModalVisible}
        onActionButtonClick={handleClose}
        onClose={() => setIsConfirmModalVisible(false)}
      />
    </>
  );
};

export default memo(TrainingForm);
