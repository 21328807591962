import {SvgIcon} from '@mui/material';
import React from 'react';

function HockeyStickAndPuck({...props}) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M23.9306 1.72832L23.4754 2.57615L23.0202 3.42398L22.565 4.2718L20.1589 8.83702L17.8179 13.2718L13.8512 19.5327C13.8512 19.5327 12.5506 21.0979 12.3555 21.0327C12.2905 20.9675 12.1604 20.9022 12.0954 20.837C11.7052 20.5109 11.5752 19.8588 11.8353 19.4022L12.2905 18.4892L17.7529 8.25006L20.1589 3.81528L20.4841 3.16311L20.9392 2.31528L21.3944 1.46745L21.8496 0.619629"
        fill="#A5100E"
      />
      <path
        d="M23.4754 1.72832L23.0202 2.57615L22.565 3.42398L22.1098 4.33702L19.7037 8.90224L17.3627 13.337L13.3309 19.5327C13.3309 19.5327 12.5506 20.4457 12.0954 20.837C11.7052 20.5109 11.5752 19.8588 11.8353 19.4022L12.2905 18.4892L17.7529 8.25006L20.1589 3.81528L20.484 3.16311L20.9392 2.31528L21.3944 1.46745L21.8496 0.619629"
        fill="#C52E25"
      />
      <path
        d="M19.7686 9.55407L14.1762 19.9889C13.3308 21.4889 12.9406 21.9454 11.51 22.3367C11.2499 22.4019 10.0794 22.5976 9.81928 22.5976L2.53611 23.3802H2.34102C1.04045 23.3802 0 22.6628 0 21.3584C0 21.228 0 21.0976 0.0650284 20.9671C0.260113 19.8584 1.23554 19.0106 2.40605 19.0106H10.5346C11.1198 19.0106 11.9002 19.141 12.3554 18.5541L17.8178 8.31494L19.5085 9.42364L19.7686 9.55407Z"
        fill="#AD7C5E"
      />
      <path
        d="M19.5085 9.35918L14.2412 19.207C13.3958 20.707 13.0057 21.1635 11.575 21.5548C11.3149 21.6201 10.1444 21.8157 9.88431 21.8157L2.53611 22.5983H2.34102C1.17051 22.5983 0.195085 22.0114 0 20.9027C0.195085 19.794 1.17051 18.9461 2.34102 18.9461H10.4696C11.0548 18.9461 11.8352 19.0766 12.2904 18.4896L17.7527 8.25049L19.5085 9.35918Z"
        fill="#C2AA88"
      />
      <path d="M23.0849 3.42364L22.5647 4.33668L20.5488 3.16277L21.004 2.31494L23.0849 3.42364Z" fill="#FECCBD" />
      <path d="M23.9304 1.72832L23.4752 2.57615L21.3943 1.46745L21.8495 0.619629L23.9304 1.72832Z" fill="#FECCBD" />
      <path
        d="M9.49445 13.9896C9.49445 14.7722 7.41355 15.4896 4.81241 15.4896C2.21128 15.4896 0.130371 14.8375 0.130371 13.9896C0.130371 13.5331 0.130371 11.3809 0.130371 11.3809C0.97574 11.1853 3.6419 12.5549 4.81241 12.5549C5.98292 12.5549 8.71411 11.1853 9.49445 11.3809C9.49445 11.3157 9.49445 13.5331 9.49445 13.9896Z"
        fill="#263536"
      />
      <path
        d="M9.49445 11.3149C9.49445 12.0975 7.41355 12.8149 4.81241 12.8149C2.21128 12.8149 0.130371 12.1628 0.130371 11.3149C0.130371 10.5323 2.21128 9.81494 4.81241 9.81494C7.34852 9.88016 9.49445 10.5323 9.49445 11.3149Z"
        fill="#354546"
      />
      <path
        d="M6.97962 11.1196C6.97962 11.3805 6.0933 11.6196 4.98541 11.6196C3.87752 11.6196 2.99121 11.4022 2.99121 11.1196C2.99121 10.8588 3.87752 10.6196 4.98541 10.6196C6.06561 10.6414 6.97962 10.8588 6.97962 11.1196Z"
        fill="#EE1122"
      />
    </SvgIcon>
  );
}

export default HockeyStickAndPuck;
