import React from 'react';
import {useParams} from 'react-router';

import GroupPanel from '../groups/panels/GroupPanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function GroupApp() {
  const params = useParams();

  return (
    <ChessTemplate active="teams">
      <GroupPanel id={params.id} />
    </ChessTemplate>
  );
}
