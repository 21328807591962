import date from '@helpers/date';
import {Box, DatePicker} from '@ui/MUI';
import React, {memo} from 'react';
import {Controller} from 'react-hook-form';

function DateField({isEdit, name, label, isRequired = false}) {
  return (
    <Box position="relative">
      <Controller
        name={name}
        render={({field: {onChange, ref, ...field}, fieldState: {error}}) => {
          return (
            <DatePicker
              InputProps={{readOnly: !isEdit}}
              helperText={error?.message}
              label={label}
              readOnly={!isEdit}
              ref={ref}
              size="small"
              {...field}
              error={error}
              onChange={(value) => {
                if (!value) return onChange(null);
                onChange(date(value).valueOf());
              }}
            />
          );
        }}
        rules={{
          required: isRequired ? 'Обязательное поле!' : false,
          validate: (v) => {
            if (v && (date(v).isBefore('01.01.1970') || !date(v).isValid())) return 'Некорректная дата';
          },
        }}
      />
    </Box>
  );
}

export default memo(DateField);
