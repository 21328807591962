import {Box, Typography} from '@ui/MUI';
import FormHelperText from '@ui/MUI/FormHelperText';
import RadioGroup from '@ui/MUI/RadioGroup';
import PropTypes from 'prop-types';
import React, {forwardRef} from 'react';

const ControlledQuestionsList = forwardRef(function ControlledQuestionsList(
  {value, onChange, questions, title, error},
  ref,
) {
  return (
    <Box>
      <Typography mb={2} variant="subtitle1">
        {title}
      </Typography>
      <RadioGroup
        checkboxes={questions?.map(({label, value, icon}) => ({
          label: `${icon} ${label}`,
          value,
        }))}
        error={error?.message}
        ref={ref}
        value={value}
        onChange={(e, value) => onChange(Number(value))}
      />

      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
    </Box>
  );
});

ControlledQuestionsList.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      icon: PropTypes.string,
    }),
  ),
  title: PropTypes.string.isRequired,
};

export default ControlledQuestionsList;
