import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 4;
  padding: 20px 34px;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  @media screen and (min-width: 1025px) {
    position: sticky;
    top: 0;
  }
  & + .mainCalendar__preloaderWrapper {
    .fc-header-toolbar {
      @media screen and (min-width: 1025px) {
        position: sticky;
        top: ${(props) => props.filterHeight}px;
      }
      @media (min-width: 1201px) and (max-width: 1440px) {
        top: 200px;
      }
    }
    .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
      @media (min-width: 1441px) and (max-width: 1500px) {
        top: calc(70px + 170px);
      }
      @media (min-width: 1201px) and (max-width: 1440px) {
        top: calc(140px + 200px);
      }
      @media (min-width: 1025px) and (max-width: 1200px) {
        top: calc(108px + ${(props) => props.filterHeight - 14}px);
      }
      @media screen and (max-width: 1024px) {
        top: auto;
      }
    }
  }
`;

export const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -14px;
  margin-left: -14px;
  box-sizing: border-box;
`;
export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font: 600 normal 12px/16px 'Proxima Nova';
  color: #747474;
  text-transform: uppercase;
`;
export const Column = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 14px;
  padding-left: 14px;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1199px) {
    margin-bottom: 12px;

    :nth-child(4),
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
    :nth-child(4) {
      margin-bottom: 12px;
    }
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    :nth-child(4) {
      margin-bottom: 12px;
    }
  }
`;
