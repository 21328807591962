import TVScheduleAPI from '../../api/TVScheduleAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

const errorMessage = (err) => {
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], `${err.response.data.message}`);
      }
    }
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

export function getTVScheduleForHall() {
  return (dispatch) => {
    dispatch({
      type: types.TV_SCHEDULE_LOADING_START,
    });
    return TVScheduleAPI.getTVScheduleForHall()
      .then((data) => {
        dispatch({
          type: types.TV_SCHEDULE_LOADING_END,
          tvSchedule: data.data || {},
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          data: err,
          type: types.TV_SCHEDULE_LOADING_ERROR,
        });
      });
  };
}

export function getTVScheduleForRoom(roomId) {
  return (dispatch) => {
    dispatch({
      type: types.TV_SCHEDULE_LOADING_START,
    });
    return TVScheduleAPI.getTVScheduleForRoom(roomId)
      .then((data) => {
        dispatch({
          type: types.TV_SCHEDULE_LOADING_END,
          tvSchedule: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.TV_SCHEDULE_LOADING_ERROR,
        });
      });
  };
}
