import {userRoles} from '@src/constants';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import styled from 'styled-components';

import * as actions from '../../../redux/actions/SchoolsActions';
import {loadTeachers} from '../../../redux/actions/UsersActions';
import AllStudentsPanel from '../../admin/panels/AllStudentsPanel';
import SchoolLessonsPanel from '../../lessons/panels/SchoolLessonsPanel';
import FullPagePreloader from '../../preloader/FullPagePreloader';
import Tabs from '../../ui/Tabs';

export default function SchoolPanel(props) {
  const {id} = props;
  const {url} = useRouteMatch();

  const school = useSelector((state) => state.schools.schoolsMap.get(id));
  const currentUserId = useSelector((state) => state.users.currentUser.id);
  const currentUserIsTeacher = useSelector((state) => Boolean(state.users.currentUser.userRole === userRoles.teacher));
  const {name, description} = school;
  let tabs = [];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTeachers());
  }, []);

  useEffect(() => {
    dispatch(actions.loadAllOpposingTeams());
  }, []);

  if (!id || !school) {
    return <FullPagePreloader visible={true} />;
  }

  if (currentUserIsTeacher) {
    tabs = [
      {
        label: 'Расписание',
        content: (
          <TabItem>
            <SchoolLessonsPanel
              hasFilter
              className={
                currentUserIsTeacher === true ? 'SchoolLessonsPanel SchoolLessonsPanel_teacher' : 'SchoolLessonsPanel'
              }
              schoolId={id}
              teacherId={currentUserIsTeacher ? currentUserId : undefined}
            />
          </TabItem>
        ),
        link: '',
      },
    ];
  } else {
    tabs = [
      {
        label: 'Расписание',
        content: (
          <TabItem>
            <SchoolLessonsPanel
              hasFilter
              schoolId={id}
              teacherId={currentUserIsTeacher === true ? currentUserId : undefined}
            />
          </TabItem>
        ),
        link: '',
      },
      {
        label: 'Игроки',
        content: (
          <TabItem>
            <AllStudentsPanel
              defaultFilterData={{
                selectedSchoolId: school.id,
              }}
              titleVisible={false}
            />
          </TabItem>
        ),
        link: 'active',
      },
    ];
  }
  const title = <PageTitleDefault>{name}</PageTitleDefault>;
  return (
    <Wrapper key={id}>
      <ContentPlaceholder>
        <TopPlaceholder className={!description ? 'TopPlaceholder' : 'TopPlaceholder TopPlaceholder_with-desc'}>
          <DescriptionPlaceholder>{description}</DescriptionPlaceholder>
        </TopPlaceholder>
        <Tabs tabs={tabs} title={title} url={url} />
      </ContentPlaceholder>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .Wrapper {
    margin-bottom: 20px;
  }
`;

const TopPlaceholder = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
  z-index: 101;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    position: relative;
    top: auto;
    left: auto;
    padding: 20px 20px 0;
    text-align: left;
    margin-bottom: -50px;
  }
  @media screen and (max-width: 1024px) {
    padding-top: 0;
  }
  @media screen and (max-width: 992px) {
    text-align: center;
  }
`;

const DescriptionPlaceholder = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #fffaed;
`;

const ContentPlaceholder = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;

  @media screen and (max-width: 991px) {
    position: relative;
    top: auto;
  }
`;

const TabItem = styled.div`
  width: 100%;

  .CalendarTabs {
    position: absolute;
    left: 36px;
    align-items: center;
    height: 58px;
    margin-bottom: 0;

    .TabName {
      position: relative;
      padding-left: 18px;
      font: bold normal 20px/36px 'Bebas Neue';
      color: rgba(0, 0, 0, 0.5);
      text-transform: uppercase;
      :first-of-type {
        :before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-60%);
          display: block;
          background: url(/src/assets/icons/calendar-list.svg) no-repeat;
          width: 12px;
          height: 12px;
          content: '';
        }
      }
      :last-of-type {
        :before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-60%);
          display: block;
          background: url(/src/assets/icons/calendar-grid.svg) no-repeat;
          width: 12px;
          height: 12px;
          content: '';
        }
      }
      &.tab-active {
        background-color: transparent;
        border-radius: 0;
        color: #990011 !important;

        :first-of-type {
          :before {
            background: url(/src/assets/icons/calendar-list-red.svg) no-repeat;
          }
        }
        :last-of-type {
          :before {
            background: url(/src/assets/icons/calendar-grid-red.svg) no-repeat;
          }
        }
      }
    }
  }
`;
