// кто-то сломал стили кнопки, я теперь не могу нормально ее закостомить, приходится импортить напрямую из mui
// надо срочно взять в работу нормализацию кита, а то жопа-боль
import {Close} from '@mui/icons-material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {updateFormData, updateFormVisability, updatePreviewVisability} from '@slices/materials';
import Box from '@ui/MUI/Box';
import Chip from '@ui/MUI/Chip';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import React, {memo, useMemo} from 'react';
import {useDispatch} from 'react-redux';

import {useCurrentBreakPoint, useMaterialsPreview} from '../../hooks';
import Item from '../Item';
import {
  ButtonContainer,
  ButtonFixedWrapper,
  Circle,
  Container,
  ContentContainer,
  ContentWrapper,
  Image,
  Video,
} from './Preview';

const Top = ({close, type}) => {
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" px={2} py={3} sx={{background: 'white'}}>
      <Typography variant="h3">{type}</Typography>
      <Circle>
        <Close sx={{fontSize: '10px'}} onClick={close} />
      </Circle>
    </Stack>
  );
};

const Preview = () => {
  const {data, isOpen} = useMaterialsPreview();
  const isImage = data?.type?.slug === 'image';
  const isLinkContainYandexDisk = data?.link?.includes('disk.yandex.ru');

  const breakpoint = useCurrentBreakPoint();

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(updatePreviewVisability(false));
  };

  const edit = () => {
    dispatch(updatePreviewVisability(false));
    dispatch(updateFormData(data));
    dispatch(updateFormVisability(true));
  };

  const content = useMemo(() => {
    if (!data || !isOpen) return;

    if (isImage) {
      return <Image src={data.link || data.storage.link} width={'100%'} />;
    }

    const formatedLink = isLinkContainYandexDisk ? `https://getfile.dokpub.com/yandex/get/${data.link}` : data.link;

    return <Video controls url={formatedLink || data.storage.link} width="100%" />;
  }, [data?.link, data?.storage?.link, isLinkContainYandexDisk]);

  return (
    isOpen && (
      <Modal open={isOpen} onClose={handleClose}>
        <Container>
          {breakpoint === 'xxs' && <Top close={handleClose} type={data?.type?.name} />}
          <Stack alignItems="center" direction="row" spacing={7}>
            {content}
          </Stack>
          <ContentContainer>
            <ContentWrapper breakpoint={breakpoint}>
              <Box mb={4}>
                <Typography variant="h3">{data.name}</Typography>
                {(!!data.tags.length || data?.category) && (
                  <Stack direction="row" flexWrap="wrap" gap={1} mt={3}>
                    {data?.category && <Chip icon={<Circle type={data.category.slug} />} label={data.category.name} />}
                    {data?.tags?.map((tag) => (
                      <Chip key={tag.slug} label={tag.name} variant="outlined" />
                    ))}
                  </Stack>
                )}
              </Box>
              <Stack alignItems="flex-start" spacing={3} sx={{pb: '56px'}}>
                {data.description && <Item description={data.description} label="Описание" />}
                {!!data.groups.length && (
                  <Item description={data.groups.map((group) => group.name).join(', ')} label="Команда" />
                )}
                {!!data.students.length && (
                  <Item description={data.students.map((student) => student.name).join(', ')} label="Игроки" />
                )}
                {!!data.teachers.length && (
                  <Item description={data.teachers.map((teacher) => teacher.name).join(', ')} label="Тренеры" />
                )}
                {data.canEdit && (
                  <ButtonFixedWrapper>
                    <ButtonContainer>
                      <Button
                        color="primary"
                        size="small"
                        sx={{border: 'none', padding: '4px', fontSize: 13}}
                        onClick={edit}
                      >
                        Редактировать
                      </Button>
                    </ButtonContainer>
                  </ButtonFixedWrapper>
                )}
              </Stack>
            </ContentWrapper>
          </ContentContainer>
        </Container>
      </Modal>
    )
  );
};

export default memo(Preview);
