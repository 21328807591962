import styled from 'styled-components';

export const InputWrapper = styled.div`
  max-width: calc(100% / 3 - 8px);
  @media screen and (max-width: 1280px) {
    max-width: 50%;
  }
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
`;
