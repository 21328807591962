import styled from 'styled-components';

export const Wrapper = styled.div`
  width: calc(100% / 4 - 8px * 3 / 4);
  background: #ffffff;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 16px;
  box-sizing: border-box;
  @media screen and (max-width: 1400px) {
    width: calc(100% / 3 - 8px * 2 / 3);
  }
  @media screen and (max-width: 768px) {
    width: calc(100% / 2 - 8px / 2);
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

export const Title = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
`;

export const CountItem = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

export const Bold = styled.span`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : 600)};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  margin-left: ${(props) => props.marginLeft};
`;

export const Statistics = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 21px;
`;

export const StatisticsItem = styled.div`
  .title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  .count {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }
`;
