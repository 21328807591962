import {useGroup, useSchool} from '@hooks/filters';
import {Grid} from '@mui/material';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import React, {useEffect} from 'react';
import styled from 'styled-components';

import StateHelper from '../../../helpers/StateHelper';

export function LinesFilter(props) {
  let {
    selectedSchool,
    setSelectedSchool,
    selectedTeam,
    setSelectedTeam,
    filters,
    onChange = () => {},
    onChangePlayers = () => {},
  } = props;

  const school = useSchool({});
  const group = useGroup({connectorValue: selectedSchool?.value});

  useEffect(() => {
    setSelectedSchool(school.options[0]);
    setSelectedTeam(group.options[0]);
  }, []);

  const handleChange = (_, v, name) => {
    if (name === 'school') {
      setSelectedSchool && setSelectedSchool(v);
      setSelectedTeam(null);
      onChange({...filters, school: v, group: null});
    }
    if (name === 'group') {
      setSelectedTeam && setSelectedTeam(v);
      onChange({...filters, school: selectedSchool, group: v});
    }
  };

  useEffect(() => {
    if (selectedTeam?.id) {
      StateHelper.getStudentsByTeamId(selectedTeam?.id).then((options) => {
        onChangePlayers(options);
      });
    }
  }, [selectedTeam?.id]);

  return (
    <Wrapper className={'PhysicalTestingFilter'}>
      <Grid container spacing={4}>
        <Grid item md={3} mobileM={5} xxs={5}>
          <AutocompleteDefault
            isLoading={school.isLoading}
            label="Школа"
            multiple={false}
            name="school"
            options={school.options}
            value={selectedSchool}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} mobileM={5} xxs={5}>
          <AutocompleteDefault
            isLoading={group.isLoading}
            label="Команда"
            multiple={false}
            name="group"
            options={group.options}
            value={selectedTeam}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 12px 34px 20px;
  margin-top: 0;
  margin-bottom: 4px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 6px;
  @media screen and (max-width: 767px) {
    padding-right: 12px;
    padding-left: 12px;
  }

  .MuiGrid {
    &-container {
      @media screen and (max-width: 480px) {
        flex-direction: column;
        width: 100%;
      }
      .MuiGrid {
        &-item {
          @media screen and (max-width: 480px) {
            width: 100%;
            max-width: 100%;
            padding-left: 0;
          }
        }
      }
    }
  }
`;
