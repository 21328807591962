import {LEADERS, OUTSIDERS} from '@components/apps/StatsReportForOneGame/constants';
import {longDash} from '@src/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

TopFiveItem.propTypes = {
  value: PropTypes.shape({value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])}),
};

function TopFiveItem({value}) {
  if (value?.value || Number(value?.value) === 0) {
    return <StyledTopFiveItem value={value}>{value?.value}</StyledTopFiveItem>;
  }
  return longDash;
}

export default TopFiveItem;

const StyledTopFiveItem = styled.span`
  ${({theme}) => theme.typography.body2};
  padding-right: ${({theme}) => theme.spacing(3)};
  position: relative;
  text-align: right;
  &::before {
    transition: 0.3s ease;
    position: absolute;
    content: '';
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) =>
      props?.value?.topFive ? LEADERS : props?.value?.topFiveBackwards ? OUTSIDERS : 'transparent'};
  }
`;
