import {Check, Delete, Edit} from '@mui/icons-material';
import ButtonDefault from '@ui/MUI/Button';
import Stack from '@ui/MUI/Stack';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(ButtonDefault)`
  width: 38px;
  min-width: 38px;
  height: 38px;
  background-color: ${(props) => (props.isAcceptable ? '#990011' : '#f3f3f3')};
  border-radius: 8px;
  color: #0000009e;
  padding: 0;
  &:hover {
    background-color: ${(props) => (props.isAcceptable ? '#990011' : '#f3f3f3')};
  }

  svg {
    color: ${(props) => props.isAcceptable && '#FFFFFF'};
  }
`;

const IconButtonGroup = ({onEdit, onAccept, isAcceptable, onDelete}) => {
  return (
    <Stack direction="row" spacing={3}>
      {(onEdit || onAccept) && (
        <StyledButton isAcceptable={isAcceptable} onClick={isAcceptable ? onAccept : onEdit}>
          {isAcceptable ? <Check /> : <Edit />}
        </StyledButton>
      )}
      {onDelete && (
        <StyledButton>
          <Delete onClick={onDelete} />
        </StyledButton>
      )}
    </Stack>
  );
};

export default IconButtonGroup;
