import {formatTableData, hideEmptyPlayersInTableData} from '@components/apps/FunctionalTesting/helpers';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {getTeamName} from '@helpers/CommonHelper';
import date from '@helpers/date';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {FormControlLabel} from '@mui/material';
import {selectTableEditCreate} from '@selectors/functionalTesting';
import {fetchDateForReportByDate} from '@slices/functionalTesting';
import {userRoles} from '@src/constants';
import ButtonDefault from '@ui/MUI/Button';
import CheckboxDefault from '@ui/MUI/Checkbox';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {actions} from '../../constants';
import {InfoBlock, LocalPreloaderWrapper, TableWrapper} from './Report';
import ReportTable from './ReportTable';

const Report = () => {
  const history = useHistory();
  const isDoctor = useStore((state) => state.users.currentUser.userRole === userRoles.doctor);
  const isAdmin = useStore((state) => state.users.currentUser.userRole === userRoles.admin);
  const canEdit = isDoctor || isAdmin;
  const {items, isLoading} = useStore(selectTableEditCreate);

  const {teamId, reportDate} = useParams();

  const [teamName, setTeamName] = useState();

  useEffect(() => {
    getTeamName(teamId).then((res) => {
      setTeamName(res);
    });
  }, [teamId]);

  const [isEmptyPlayersHide, setIsEmptyPlayersHide] = useState(true);

  const dispatch = useStoreDispatch();
  useEffect(() => {
    dispatch(fetchDateForReportByDate(teamId, reportDate));
  }, [teamId, reportDate]);

  return (
    <>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {
            label: 'Функциональное тестирование',
            path: '/functional_testing',
          },
          {
            label: teamName,
            path: `/functional_testing/${teamId}`,
          },
          {
            label: `Отчет за ${date(reportDate).format('DD.MM.YYYY')}`,
            path: `/functional_testing/${teamId}/${reportDate}`,
          },
        ]}
        text={`Текущее тестирование за ${date(reportDate).format('DD.MM.YYYY')}`}
      />
      <TableWrapper>
        {isLoading ? (
          <LocalPreloaderWrapper isLoading={isLoading} marginBottom="8px" padding={'0 24px'}>
            <LocalPreloader visible={true} />
          </LocalPreloaderWrapper>
        ) : (
          <>
            <InfoBlock>
              {canEdit && (
                <ButtonDefault
                  color="primary"
                  data-testid="edit-button"
                  size="medium"
                  sx={{width: {xxs: '100%', xs: 'auto'}}}
                  variant="outlined"
                  onClick={() => {
                    history.push(`/functional_testing_create/${teamId}/${actions.actionEdit}/${reportDate}`);
                  }}
                >
                  Редактировать
                </ButtonDefault>
              )}
              <FormControlLabel
                control={
                  <CheckboxDefault
                    checked={isEmptyPlayersHide}
                    data-testid="hide-players-checkbox"
                    size="small"
                    onChange={() => setIsEmptyPlayersHide(!isEmptyPlayersHide)}
                  />
                }
                label="Скрыть игроков без данных"
                labelPlacement="end"
                value="isPlayersHidden"
              />
            </InfoBlock>
            {items.length > 0 && (
              <ReportTable
                isEmptyPlayersHide={isEmptyPlayersHide}
                tableData={formatTableData(hideEmptyPlayersInTableData(isEmptyPlayersHide, items))}
              />
            )}
          </>
        )}
      </TableWrapper>
    </>
  );
};

export default Report;
