import PlayerInfo from '@common/DevelopmentJournal/components/PlayerInfo';
import {joinEstimatesProperty} from '@common/DevelopmentJournal/helpers';
import {useRoleAccess} from '@hooks/useRoleAccess';
import {longDash, userRoles} from '@src/constants';
import {Button} from '@ui/MUI';
import React from 'react';
import {useHistory} from 'react-router-dom';

const ActionButton = ({id}) => {
  const isPsycho = useRoleAccess([userRoles.psycho]);
  const history = useHistory();

  const handleClick = () => {
    history.push(`/development_journal/player/${id}?isCompleted=true`);
  };

  return (
    <Button color="primary" disabled={!isPsycho} variant="text" onClick={handleClick}>
      Смотреть
    </Button>
  );
};

export const columns = [
  {
    header: 'Игрок',
    accessorFn: (row) => row.student,
    headerStyle: {width: 300, maxWidth: 300},
    cellStyle: {width: 300, maxWidth: 300},
    cell: (info) => {
      const value = info.getValue();
      return <PlayerInfo {...value} />;
    },
  },
  {
    header: 'Завершенные навыки',
    accessorFn: (row) => {
      return row.student.estimates.length ? joinEstimatesProperty(row.student.estimates, 'skill.name') : longDash;
    },
  },
  {
    header: 'Всего',
    accessorFn: (row) => row.student.estimates.length,
    headerStyle: {width: 100, maxWidth: 100},
    cellStyle: {width: 100, maxWidth: 100},
  },
  {
    id: 'action',
    header: '',
    accessorFn: (row) => row.student.id,
    headerStyle: {width: 150, maxWidth: 150},
    cellStyle: {width: 150, maxWidth: 200},
    cell: (info) => <ActionButton id={info.getValue()} />,
  },
];
