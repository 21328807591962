import React, {useState} from 'react';

import {Button} from '../../../../../../ui/Button';
import Icon from '../../../../../../ui/Icon/Icon';
import {generateClassNameByEstimation} from '../../../../../helper';
import {
  ColumnValue,
  CommentText,
  CommentTitle,
  EstimationTableBody,
  EstimationTableColumn,
  EstimationTableComments,
  EstimationTableCommentsColumn,
  EstimationTableGoaltenders,
  EstimationTableHead,
  EstimationTableHeadColumn,
  EstimationTableRow,
  SkillName,
} from '../../../styles';

export default function GoaltendersTable({gameEstimateReport, size: {screenWidth}}) {
  return (
    <EstimationTableGoaltenders>
      <EstimationTableHead>
        <EstimationTableRow>
          <EstimationTableHeadColumn colSpan={screenWidth > 768 ? '4' : '3'}>
            {gameEstimateReport?.student?.lastName} {gameEstimateReport?.student?.firstName}
          </EstimationTableHeadColumn>
        </EstimationTableRow>
        <EstimationTableRow>
          <EstimationTableHeadColumn>Навык</EstimationTableHeadColumn>
          <EstimationTableHeadColumn>Оц. Игрока</EstimationTableHeadColumn>
          <EstimationTableHeadColumn>Оц. Тренера</EstimationTableHeadColumn>
          {screenWidth > 768 && <EstimationTableHeadColumn>Коммент.</EstimationTableHeadColumn>}
        </EstimationTableRow>
      </EstimationTableHead>
      <EstimationTableBody>
        {gameEstimateReport?.parameters.map((parameter) => {
          return <PlayerRowGoaltenders key={parameter.id} parameter={parameter} screenWidth={screenWidth} />;
        })}
      </EstimationTableBody>
    </EstimationTableGoaltenders>
  );
}

function PlayerRowGoaltenders({parameter, screenWidth}) {
  const [isCommentsShowed, setIsCommentsShowed] = useState(false);
  const isDesktop = screenWidth > 768;
  return (
    <React.Fragment key={parameter.id}>
      <EstimationTableRow isCommentsShowed={isCommentsShowed}>
        <EstimationTableColumn>
          <SkillName>{parameter.name}</SkillName>
        </EstimationTableColumn>
        <EstimationTableColumn align="center">
          <ColumnValue className={generateClassNameByEstimation(parameter.studentsValue)}>
            {parameter.studentsValue ? parameter.studentsValue : 'Б/О'}
          </ColumnValue>
        </EstimationTableColumn>
        <EstimationTableColumn align="center">
          <ColumnValue className={generateClassNameByEstimation(parameter.teachersValue)}>
            {parameter.teachersValue ? parameter.teachersValue : 'Б/О'}
          </ColumnValue>
        </EstimationTableColumn>
        {isDesktop && (
          <EstimationTableColumn>
            <Button
              onClick={() => {
                setIsCommentsShowed(!isCommentsShowed);
              }}
            >
              <Icon height={17} type="Comment" viewBox={'0 0 19 17'} width={19} />
            </Button>
          </EstimationTableColumn>
        )}
      </EstimationTableRow>
      <EstimationTableComments isCommentsShowed={isCommentsShowed || !isDesktop}>
        {isDesktop && <EstimationTableCommentsColumn></EstimationTableCommentsColumn>}
        <EstimationTableCommentsColumn colSpan={isDesktop ? '2' : '3'}>
          {isDesktop ? (
            parameter?.comment || 'Комментарий отсутствует'
          ) : (
            <>
              <CommentTitle>Комментарий от тренера</CommentTitle>
              <CommentText>{parameter?.comment || '-'}</CommentText>
            </>
          )}
        </EstimationTableCommentsColumn>
        {isDesktop && <EstimationTableCommentsColumn></EstimationTableCommentsColumn>}
      </EstimationTableComments>
    </React.Fragment>
  );
}
