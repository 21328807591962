import styled from 'styled-components';

export const Item = styled.div`
  flex: 0 0 calc(100% / 7);
  max-width: calc(100% / 7);
  text-align: center;
  padding-top: 4px;
  border-right: 1px solid ${({isToday, isNextDayToday}) => (isToday || isNextDayToday ? '#8C1A1A' : '#E0E0E0')};
`;

export const Items = styled.div`
  display: flex;
  padding: 10px 0px;
  background: #ffffff;
  border-radius: 6px;
  width: 100%;
`;
