import {Avatar as MuiAvatar} from '@mui/material';
import {AvatarProps} from '@mui/material/Avatar/Avatar';
import {convertImgTo200} from '@src/helpers';
import {Skeleton} from '@ui/MUI';
import React from 'react';

type CustomAvatarProps = AvatarProps & {
  width: number;
  height?: number;
  isHighResolution?: boolean;
  isLoading?: boolean;
};
function Avatar({children, width, height, sx, variant, src, isHighResolution, isLoading, ...props}: CustomAvatarProps) {
  return isLoading ? (
    <Skeleton height={height || width || 20} variant="circular" width={width || height || 20} />
  ) : (
    <MuiAvatar
      src={isHighResolution ? src : convertImgTo200(src)}
      sx={{...sx, width: width || height || 20, height: height || width || 20}}
      variant={variant}
      {...props}
    >
      {children}
    </MuiAvatar>
  );
}

export default Avatar;
