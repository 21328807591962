import {getAllMKCTournaments} from '@api/mkcStatisticsAPI';
import {getTournamentsList} from '@api/TournamentsAPI';
import CommonHelper from '@helpers/CommonHelper';
import date from '@helpers/date';
import {useSeasons} from '@hooks/seasonsHooks';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import {Button, CloseButton} from '@ui/Button';
import DateTimePicker from '@ui/MUI/DateTimePicker';
import ReactComponentModal from '@ui/ReactComponent/ReactComponentModal/ReactComponentModal';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import SelectDefault from '@ui/Select';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import styled from 'styled-components';

import UsersAPI from '../../../../../api/UsersAPI';
import Field from '../../../../Field';
import {ADDITIONAL_EDUCATION_LESSONS, EVENT_TYPES, SCHOOL_LESSONS} from '../../../constants';
import {getNearest15Minutes} from '../../../helper';
import {
  BottomPanel,
  Buttons,
  Form,
  FormContent,
  FormContentItem,
  FormContentItemHalf,
  FormContentItemLong,
  FormContentItems,
  Label,
} from '../style';

const LessonForm = (props) => {
  const {
    allAmplua,
    rooms,
    lockerRooms,
    setLoading,
    onFormSubmit,
    defaultValues,
    isAdd,
    onCloseButtonClick,
    defaultPlayers,
  } = props;

  const {currentSeason} = useSeasons();
  const [tournaments, setTournaments] = useState([]);
  useEffect(() => {
    const avangardClubId = 1;
    if (currentSeason?.value) {
      getTournamentsList({season: currentSeason.value}).then((res) => {
        setTournaments(res.data);
      });
      getAllMKCTournaments({
        clubId: avangardClubId,
        yearBegin: date(currentSeason?.startDate).format('YYYY').toString(),
        yearEnd: date(currentSeason?.endDate).format('YYYY').toString(),
      }).then((res) => {
        setTournaments((prev) =>
          [...res.data, ...prev].flat()?.map((tournament) => {
            if (tournament?.MKCId) {
              return {...tournament, mkcId: tournament?.MKCId.toString()};
            } else {
              return {...tournament, id: tournament?.id};
            }
          }),
        );
      });
    }
  }, [currentSeason?.value]);
  const tournamentsOptions = tournaments?.map((tournament) => ({
    value: tournament.id || tournament.mkcId,
    label: tournament.name,
    isMKC: !!tournament.mkcId,
  }));

  const nearest15MinutesDate = getNearest15Minutes();
  const {
    handleSubmit,
    register,
    reset,
    formState: {errors},
    control,
    setValue,
    getValues,
    watch,
    ...rest
  } = useForm({
    defaultValues: defaultValues || {
      startTime: nearest15MinutesDate,
      endTime: new Date(nearest15MinutesDate.getTime() + 900000),
    },
  });

  const [playersState, setPlayersState] = useState({
    isLoading: false,
    options: isAdd ? [] : CommonHelper.getDropDownItemsByUsersWithCustomProps(defaultPlayers, 'hockeyRole'),
  });

  const [deleteModalState, setDeleteModalState] = useState(false);

  const watchEventType = watch('type');
  const watchLesson = watch('lesson');
  const watchStartTime = watch('startTime');
  const watchEndTime = watch('endTime');
  const watchRoom = watch('room');
  const watchPlayerRole = watch('playerRole');
  const watchSchool = watch('school');
  const watchGroup = watch('team');

  const isGroupRequired = () => {
    let res = true;
    if (
      watchEventType?.value === 'training' ||
      watchEventType?.value === 'training_gpt' ||
      watchLesson?.value === 'academy_board'
    ) {
      res = false;
    }
    if (watchLesson?.value === 'academy_board') {
      res = false;
    }
    return res;
  };
  const renderLessonCondition =
    watchEventType?.value === 'school_lessons' ||
    watchEventType?.value === 'group_consultation' ||
    watchEventType?.value === 'personal_consultation';
  const renderAdditionalEducationLessonCondition = watchEventType?.value === 'additional_education';
  const renderNameCondition = watchEventType?.value === 'educational_event' || watchEventType?.value === 'master';
  const isGame = watchEventType?.value === 'away_game' || watchEventType?.value === 'game';

  const isOpponentTraining =
    watchEventType?.value === 'training_opponent' || watchEventType?.value === 'home_games_vhl';

  const renderSchoolTeamPlayersCondition =
    watchEventType?.value !== 'room_inaccessibility' &&
    watchEventType?.value !== 'training_vhl' &&
    watchEventType?.value !== 'training_tkg' &&
    watchEventType?.value !== 'training_cherry' &&
    watchEventType?.value !== 'training_opponent' &&
    watchEventType?.value !== 'rehabilitation_vhl' &&
    watchEventType?.value !== 'training_trainers_ha';

  const inputCommand = watchEventType?.value === 'training_tkg';

  const renderPlayersCondition =
    watchEventType?.value !== 'room_inaccessibility' &&
    watchEventType?.value !== 'riding_technique' &&
    watchEventType?.value !== 'rehabilitation_on_ice' &&
    watchEventType?.value !== 'training_vhl' &&
    watchEventType?.value !== 'training_tkg' &&
    watchEventType?.value !== 'training_cherry' &&
    watchEventType?.value !== 'training_opponent' &&
    watchEventType?.value !== 'rehabilitation_vhl' &&
    watchEventType?.value !== 'training_trainers_ha';

  const renderRoomCondition = watchEventType?.value !== 'away_game' && watchEventType?.value !== 'exit_from_academy';

  const renderLockerRoomCondition =
    (watchRoom?.value === 'QTLaWG5PUv' ||
      watchRoom?.value === 'QvUwFTZqfA' ||
      watchRoom?.value === '1VQxx2Dje1' ||
      watchRoom?.value === 'RnanlyjQOi' ||
      watchRoom?.value === 'PbTHwI0YJW' ||
      watchRoom?.value === '6CuPCIvPCd' ||
      watchRoom?.value === 'NIrZtOBThI') &&
    watchEventType?.value !== 'room_inaccessibility';

  const selectRef = React.useRef();

  const {newCancelToken} = useCancelToken({
    cancelDeps: [],
  });

  const setPlayersBySearch = useCallback(
    async ({hockeyRole, ...params} = {}, needSet = true) => {
      setPlayersState((prevState) => ({...prevState, isLoading: true}));
      await UsersAPI.getStudentsV2('', {
        ...params,
        cancelToken: newCancelToken(),
      })
        .then(({result}) => {
          let players = result.map((player) => ({
            ...player,
            value: player?.id,
            label: CommonHelper.getShortNameByUser(player),
          }));
          if (hockeyRole?.length) {
            players = players?.filter((player) => hockeyRole?.includes(player?.hockeyRole));
          }
          setPlayersState(() => {
            return {
              options: players,
              isLoading: false,
            };
          });
          needSet && setValue('players', players);
        })
        .catch(() => {
          setPlayersState((prev) => ({...prev, isLoading: false}));
        });
    },
    [newCancelToken],
  );

  const onSubmitReset = async (data) => {
    setLoading(true);
    try {
      await onFormSubmit(data);
      reset({
        startTime: nearest15MinutesDate,
        endTime: new Date(nearest15MinutesDate.getTime() + 900000),
      });
    } catch (error) {
      ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка: ${error}, попробуйте позже`);
    }
    setLoading(false);
  };

  useDeepEffect(() => {
    const params = {
      schoolId: watchSchool?.value,
      hockeyRole: watchPlayerRole?.map((item) => item?.value),
      groupId: watchGroup?.value,
      active: true,
      limit: 100000,
    };
    watchSchool?.value && setPlayersBySearch(params);
  }, [watchPlayerRole, watchSchool?.value, watchGroup?.value]);
  return (
    <FormProvider
      control={control}
      getValues={getValues}
      handleSubmit={handleSubmit}
      register={register}
      reset={reset}
      setValue={setValue}
      watch={watch}
      {...rest}
    >
      <Form onSubmit={handleSubmit(onSubmitReset)}>
        <FormContent>
          <FormContentItems>
            <FormContentItemLong>
              <Label>Тип мероприятия</Label>
              <Controller
                control={control}
                name="type"
                render={({field}) => (
                  <SelectDefault
                    errorText="Обязательное поле"
                    isInvalid={errors.type}
                    isMulti={false}
                    options={EVENT_TYPES}
                    {...field}
                  />
                )}
                rules={{required: true}}
              />
            </FormContentItemLong>

            {renderLessonCondition && (
              <FormContentItemHalf>
                <Label>Предмет</Label>
                <Controller
                  control={control}
                  name="lesson"
                  render={({field}) => (
                    <SelectDefault
                      errorText="Обязательное поле"
                      isInvalid={errors.lesson}
                      isMulti={false}
                      options={SCHOOL_LESSONS}
                      {...field}
                    />
                  )}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}

            {renderAdditionalEducationLessonCondition && (
              <FormContentItemHalf>
                <Label>Предмет</Label>
                <Controller
                  control={control}
                  name="lesson"
                  render={({field}) => (
                    <SelectDefault
                      errorText="Обязательное поле"
                      isInvalid={errors.lesson}
                      isMulti={false}
                      options={ADDITIONAL_EDUCATION_LESSONS}
                      {...field}
                    />
                  )}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}

            {renderNameCondition && (
              <FormContentItemHalf>
                <Label>Название мероприятия</Label>
                <Input
                  placeholder="Введите название мероприятия"
                  {...register('name', {required: true, shouldUnregister: true})}
                />
                {errors.name && <ErrorText className="ErrorText">Обязательное поле</ErrorText>}
              </FormContentItemHalf>
            )}
          </FormContentItems>

          <FormContentItems>
            <FormContentItemHalf>
              <Label>Время начала</Label>
              <Controller
                control={control}
                name="startTime"
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <DateTimePicker
                    DialogProps={{sx: {zIndex: 999999}}}
                    PopperProps={{sx: {zIndex: 999999}}}
                    disablePast={false}
                    error={error}
                    helperText={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: true,
                  validate: (value) => {
                    if (!moment(value).isValid()) {
                      return 'Введите корректную дату и время';
                    }
                    if (moment(watchEndTime).isBefore(value)) {
                      return 'Время начала не может быть больше времени окончания';
                    }
                  },
                }}
              />
            </FormContentItemHalf>

            <FormContentItemHalf>
              <Label>Время окончания</Label>
              <Controller
                control={control}
                name="endTime"
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <DateTimePicker
                    DialogProps={{sx: {zIndex: 999999}}}
                    PopperProps={{sx: {zIndex: 999999}}}
                    disablePast={false}
                    error={error}
                    helperText={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: true,
                  validate: (value) => {
                    if (!moment(value).isValid()) {
                      return 'Введите корректную дату и время';
                    }
                    if (moment(watchStartTime).isAfter(value)) {
                      return 'Время окончания не может быть меньше времени начала';
                    }
                  },
                }}
              />
            </FormContentItemHalf>
          </FormContentItems>

          <FormContentItems>
            {renderSchoolTeamPlayersCondition && (
              <FormContentItemHalf>
                <Label>Школа</Label>
                <Field
                  isRequired
                  component="school"
                  connector="team"
                  name="school"
                  onChange={() => {
                    setValue('team', null);
                  }}
                />
              </FormContentItemHalf>
            )}

            {renderPlayersCondition && (
              <FormContentItemHalf>
                <Label>Команда</Label>
                <Field component="group" isRequired={isGroupRequired()} name="team" watcher="school" />
              </FormContentItemHalf>
            )}
            {isGame && (
              <FormContentItemHalf>
                <Label>Турнир</Label>
                <Controller
                  control={control}
                  name="tournamentId"
                  render={({field}) => {
                    return (
                      <SelectDefault isInvalid={errors.type} isMulti={false} options={tournamentsOptions} {...field} />
                    );
                  }}
                  rules={{required: false}}
                />
              </FormContentItemHalf>
            )}
            {(isGame || isOpponentTraining) && (
              <FormContentItemHalf>
                <Label>Команда соперника</Label>
                <Input
                  placeholder="Введите название команды соперника"
                  {...register('rival', {required: true, shouldUnregister: true})}
                />
                {errors.rival && <ErrorText className="ErrorText">Обязательное поле</ErrorText>}
              </FormContentItemHalf>
            )}
            {inputCommand && (
              <FormContentItemHalf>
                <Label>Команда</Label>
                <Input
                  placeholder="Введите наименование команды"
                  {...register('rival', {required: true, shouldUnregister: true})}
                />
                {errors.rival && <ErrorText className="ErrorText">Обязательное поле</ErrorText>}
              </FormContentItemHalf>
            )}

            {(watchEventType?.value === 'training' || watchEventType?.value === 'training_gpt') && (
              <FormContentItem>
                <Label>Амплуа</Label>
                <Controller
                  control={control}
                  name="playerRole"
                  render={({field: {value, onChange, ref}}) => (
                    <SelectDefault
                      isLoading={!allAmplua}
                      isMulti={true}
                      options={allAmplua}
                      ref={ref}
                      value={value}
                      onChange={(amplua) => {
                        onChange(amplua);
                      }}
                    />
                  )}
                  shouldUnregister={true}
                />
              </FormContentItem>
            )}
            {renderSchoolTeamPlayersCondition && (
              <FormContentItem>
                <Label>Игроки</Label>
                <Controller
                  control={control}
                  name="players"
                  render={({field: {value, onChange}}) => {
                    return (
                      <SelectDefault
                        isMulti
                        isSearchable
                        closeOnSelect={false}
                        errorText="Обязательное поле"
                        isInvalid={errors.players}
                        isLoading={playersState.isLoading}
                        options={playersState.options}
                        ref={selectRef}
                        value={value}
                        onBlurResetsInput={false}
                        onChange={(players) => {
                          onChange(players);
                        }}
                        onSelectResetsInput={false}
                      />
                    );
                  }}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItem>
            )}
          </FormContentItems>
          <FormContentItems>
            {renderRoomCondition && (
              <FormContentItemHalf>
                <Label>Место проведения</Label>
                <Controller
                  control={control}
                  name="room"
                  render={({field}) => (
                    <SelectDefault
                      errorText="Обязательное поле"
                      isInvalid={errors.room}
                      isMulti={false}
                      options={CommonHelper.getDropDownItemsByNamedList(rooms)}
                      {...field}
                    />
                  )}
                  rules={{required: true}}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}

            {renderLockerRoomCondition && (
              <FormContentItemHalf>
                <Label>Раздевалка</Label>
                <Controller
                  control={control}
                  name="lockerRoom"
                  render={({field}) => (
                    <SelectDefault
                      errorText="Обязательное поле"
                      isInvalid={errors.lockerRoom}
                      isMulti={true}
                      options={CommonHelper.getDropDownItemsByNamedListCustomProps(lockerRooms, 'name', 'name')}
                      {...field}
                    />
                  )}
                  shouldUnregister={true}
                />
              </FormContentItemHalf>
            )}
          </FormContentItems>
        </FormContent>

        <FormContentItems>
          {isGame && (
            <FormContentItemHalf>
              <Label>Ссылка на игру</Label>
              <Input
                placeholder="Введите ссылку на игру"
                {...register('streamingURL', {
                  shouldUnregister: true,
                  pattern:
                    /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-/])*)?/,
                })}
              />
              {errors.streamingURL && <ErrorText className="ErrorText">Некорректная ссылка</ErrorText>}
            </FormContentItemHalf>
          )}
        </FormContentItems>

        <ReactComponentModal
          buttons={
            <React.Fragment>
              <Button
                className={'DialogButton DialogButton_yes'}
                onClick={() => {
                  onCloseButtonClick();
                  setDeleteModalState(false);
                }}
              >
                Да
              </Button>
              <Button
                className={'DialogButton DialogButton_no'}
                onClick={() => {
                  setDeleteModalState(false);
                }}
              >
                Нет
              </Button>
            </React.Fragment>
          }
          className={'BodyMassEstimationCancelModal'}
          content={<DialogContentText>Вы уверены, что хотите удалить мероприятие?</DialogContentText>}
          visible={deleteModalState}
          onClose={() => {
            setDeleteModalState(false);
          }}
        />

        <BottomPanel className={'BottomPanel'}>
          <Buttons className={'Buttons'}>
            <CloseButton
              type="button"
              onClick={() => {
                isAdd ? onCloseButtonClick() : setDeleteModalState(true);
              }}
            >
              {isAdd ? 'Закрыть' : 'Удалить'}
            </CloseButton>
            <Button>{isAdd ? 'Добавить' : 'Сохранить'}</Button>
          </Buttons>
        </BottomPanel>
      </Form>
    </FormProvider>
  );
};

export default LessonForm;

const DEFAULT_INPUT_HEIGHT = 40;
const DEFAULT_INPUT_FONT_SIZE = 15;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: ${DEFAULT_INPUT_HEIGHT}px;
  line-height: ${DEFAULT_INPUT_HEIGHT}px;
  border: ${(props) => (props.isInvalid ? '1px solid #FF0000 !important' : '1px solid #ccc')};
  border-radius: 5px;
  outline: none;
  padding-left: 8px;
  font-family: 'Proxima Nova', serif;
  font-size: ${DEFAULT_INPUT_FONT_SIZE}px;
  color: #2c2c2c;
  background-color: #fff;
  :focus {
    border-color: #c4c4c4;
  }
  :hover {
    border-color: #c4c4c4;
  }
  ::placeholder {
    font: normal normal 12px/25px 'Proxima Nova';
    color: #c4c4c4;
  }
  .invalid {
    border: red;
  }
`;
const ErrorText = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  color: #ff0000;
`;
const DialogContentText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 18px/24px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: -0.02em;
`;
