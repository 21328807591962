import date from '@helpers/date';
import {Typography} from '@ui/MUI';
import React from 'react';

import {Item, Items} from './WeekDays';

const getWeekDays = () => {
  return new Array(7).fill(1).map((_, index, self) => {
    const currentDate = date(index, 'e');
    const isToday = currentDate.isSame(date().valueOf(), 'day');
    let isNextDayToday = false;

    if (!isToday) {
      isNextDayToday = date(index + 1, 'e').isSame(date().valueOf(), 'day');
    }

    return {
      isToday,
      isFirst: index === 0,
      isLast: index === self.length - 1,
      isNextDayToday,
      key: currentDate.valueOf(),
      fullDate: currentDate,
      formated: currentDate.format('dddd'),
    };
  });
};

const WeekDays = () => {
  const weekDaysNames = getWeekDays();
  return (
    <Items container>
      {weekDaysNames.map(({key, formated, isFirst, isLast, isNextDayToday, isToday}) => {
        return (
          <Item isFirst={isFirst} isLast={isLast} isNextDayToday={isNextDayToday} isToday={isToday} key={key}>
            <Typography color={isToday && '#8C1A1A'} variant="subtitle2">
              {formated}
            </Typography>
          </Item>
        );
      })}
    </Items>
  );
};

export default WeekDays;
