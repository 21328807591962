import GameField from '@components/apps/Tournaments/components/TournamentView/components/GameField';
import {gameMock} from '@components/apps/Tournaments/components/TournamentView/constants';
import {CurrentTournament, FormData} from '@components/apps/Tournaments/components/TournamentView/types';
import useDeepEffect from '@hooks/useDeepEffect';
import {Typography} from '@ui/MUI';
import React, {memo} from 'react';
import {FormProvider, useFormContext} from 'react-hook-form';

type StageProps = {
  index: number;
  maxLength: number;
  minLength: number;
  name: string;
  title: string;
  isLoading: CurrentTournament['isLoading'];
  teams: CurrentTournament['teams'];
  increment: number;
  games: FormData;
};
function Stage({maxLength, minLength, name, title, isLoading, teams, games, increment}: StageProps) {
  const form = useFormContext();
  const {setValue} = form;
  useDeepEffect(() => {
    name && setValue(name, games?.[name] ? games[name] : [...Array(increment || 1).fill(gameMock)]);
    return () => {
      setValue(name, []);
    };
  }, [games, increment, name]);
  return (
    <>
      <Typography variant="subtitle1">{title}</Typography>
      <FormProvider {...form}>
        <GameField isTeamsLoading={isLoading} maxLength={maxLength} minLength={minLength} name={name} teams={teams} />
      </FormProvider>
    </>
  );
}

export default memo(Stage);
