import {FormLabel} from '@mui/material';
import MUICheckbox from '@mui/material/Checkbox';
import {CheckboxProps} from '@mui/material/Checkbox/Checkbox';
import {FormLabelProps} from '@mui/material/FormLabel/FormLabel';
import React from 'react';
import styled from 'styled-components';

type CustomCheckboxProps = {
  label?: string;
  labelProps?: FormLabelProps;
} & CheckboxProps;

const CustomizedCheckbox = styled(({id, label, disabled, ...props}: CustomCheckboxProps) => (
  <Wrapper>
    <MUICheckbox {...props} disabled={disabled} id={id} />
    {label && (
      <FormLabel {...props.labelProps} disabled={disabled} htmlFor={id}>
        {label}
      </FormLabel>
    )}
  </Wrapper>
))``;

function Checkbox(
  {label = '', size = 'small', onChange = () => {}, ...props}: CustomCheckboxProps,
  ref: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined,
) {
  return (
    <CustomizedCheckbox
      label={label}
      size={size}
      onChange={onChange}
      {...props}
      inputProps={{'aria-label': label, ...props.inputProps}}
      labelProps={props.labelProps || {}}
      ref={ref}
    />
  );
}

export default React.forwardRef(Checkbox);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .MuiInputLabel {
    &-root {
      cursor: pointer;
      padding-top: 0;
      ${({theme}) => theme.typography.body1}
    }
  }
`;
