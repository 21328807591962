export const mock = [...Array(6)].fill({
  goals: 0,
  assists: 0,
  score: 0,
  plusMinus: 0,
  time: 0,
  shots: 0,
  blockedShots: 0,
  winBattles: 0,
  corsi: 0,
  date: '1691919900000',
  homeTeamName: 'homeTeamName',
  awayTeamName: 'awayTeamName',
});
