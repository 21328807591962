import React from 'react';

function ValueAppArrow() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M9.30784 4.22984L5.14091 0.0608195C5.10174 0.0216529 5.04885 0 4.99346 0C4.93807 0 4.88515 0.0220825 4.84601 0.0612295L0.691555 4.22984C0.631985 4.28941 0.614491 4.37895 0.64657 4.45685C0.679059 4.53474 0.754874 4.58515 0.839006 4.58515H3.1299V9.79175C3.1299 9.90671 3.22321 10 3.33817 10H6.6704C6.78536 10 6.87867 9.90671 6.87867 9.79175V4.58513H9.16082C9.24495 4.58513 9.32076 4.53431 9.35325 4.45642C9.38574 4.37854 9.36741 4.28941 9.30784 4.22984Z"
          fill="#598855"
        />
      </g>
    </React.Fragment>
  );
}
export default ValueAppArrow;
