import useEvent from '@hooks/useEvent';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {fetcherTrainingControl} from '@slices/trainingControlProcess';
import {useEffect, useMemo, useState} from 'react';

export const useSkillsFilters = (initialFilters) => {
  const [filters, setFilters] = useState(initialFilters);

  const update = useEvent(
    (name, value) => {
      setFilters({...filters, [name]: value});
    },
    [filters],
  );

  const dispatch = useStoreDispatch();

  useEffect(() => {
    dispatch(
      fetcherTrainingControl('skill', {
        age: initialFilters.age,
        search: filters.search,
        section: filters?.section?.id,
        category: filters?.category?.id,
      }),
    );
  }, [filters, dispatch]);

  return useMemo(() => ({filters, update}), [filters, update]);
};
