import React from 'react';

function WinCup() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M16.5492 1.86718H13.5484V1.45076C13.5484 1.20177 13.3467 1 13.0977 1H4.90233C4.65334 1 4.45157 1.20177 4.45157 1.45076V1.86718H1.45076C1.20177 1.86718 1 2.06895 1 2.31795V4.35712C1 4.91521 1.21894 5.43895 1.6139 5.8339C2.00885 6.22886 2.5326 6.4478 3.09069 6.4478H4.56748C5.00107 8.32384 6.59377 9.76199 8.55138 9.95517V11.9858H7.34505C6.64958 11.9858 6.07862 12.5524 6.07862 13.2522V14.8535C6.07862 15.1025 6.28039 15.3042 6.52938 15.3042H11.4749C11.7239 15.3042 11.9257 15.1025 11.9257 14.8535V13.2522C11.9257 12.5567 11.359 11.9858 10.6592 11.9858H9.45291V9.95517C11.4062 9.76199 13.0032 8.32384 13.4368 6.4478H14.9093C16.0598 6.4478 17 5.51193 17 4.35712V2.31795C17 2.06895 16.7982 1.86718 16.5492 1.86718ZM11.0199 13.2479V14.3941H6.97585V13.2436C6.97585 13.0418 7.13899 12.8787 7.34076 12.8787H10.6592C10.8567 12.883 11.0199 13.0461 11.0199 13.2479ZM4.45157 5.54627H3.09069C2.77301 5.54627 2.47679 5.42178 2.24926 5.19854C2.02603 4.97101 1.90153 4.6748 1.90153 4.35712V2.76871H4.45157V5.54627ZM12.6469 5.42607C12.6469 7.43519 11.0113 9.07511 8.99785 9.07511C6.98444 9.07511 5.3531 7.43948 5.3531 5.42607V1.90153H12.6469V5.42607ZM13.5484 2.76871H16.0985V4.35712C16.0985 5.01394 15.5661 5.54627 14.9093 5.54627H13.5441V2.76871H13.5484Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default WinCup;
