import LocalPreloader from '@components/preloader/LocalPreloader';
import {loadPastGames} from '@slices/managerDashboard';
import Box from '@ui/MUI/Box';
import Link from '@ui/MUI/Link';
import Stack from '@ui/MUI/Stack';
import TabsDefault from '@ui/MUI/Tabs';
import TypographyDefault from '@ui/MUI/Typography';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {BoxShadow} from '../../ManagerDashboard';
import Card from '../Card';
import NoData from '../NoData';

export default function PastGames({groupSelect}) {
  const dispatch = useDispatch();
  const {data, isLoad, isLoading} = useSelector((state) => state.managerDashboard.pastGames);
  const [groupId, setGroupId] = useState({index: 0, id: groupSelect?.groups?.[0]?.id});

  useEffect(() => {
    groupId?.id &&
      dispatch(
        loadPastGames({
          pastGamesFilter: groupId.id,
        }),
      );
  }, [groupId?.id]);

  useEffect(() => {
    groupSelect?.groups?.length && setGroupId({index: 0, id: groupSelect?.groups?.[0]?.id});
  }, [groupSelect.groups]);

  return (
    <>
      <Card height={(isLoading || groupSelect?.loading) && '300px'} title="прошедшие игры сезона">
        <LocalPreloader visible={isLoading || groupSelect?.loading} />
        <Box>
          <BoxShadow>
            <TabsDefault
              tabs={{
                headlineItems: groupSelect?.groups?.map((group) => ({
                  label: group.name,
                  value: group.id,
                })) || [{label: ''}],
              }}
              value={groupId.index}
              onChange={(index) => setGroupId({index, id: groupSelect?.groups[index].id})}
            />
          </BoxShadow>
        </Box>
        {isLoad && !data?.length ? (
          <NoData>На сегодня нет прошедших игр для команды</NoData>
        ) : (
          <Box>
            {data.map(
              (
                {
                  date,
                  timeStart,
                  timeEnd,
                  statisticsIsReady,
                  teams,
                  id,
                  broadcastLink,
                  statusEstimateGame,
                  statisticId,
                },
                index,
              ) => (
                <Stack
                  alignItems="center"
                  backgroundColor={index % 2 !== 0 && '#F3F3F3'}
                  direction="row"
                  gap={2}
                  justifyContent="space-between"
                  key={index}
                  p={3}
                  pb={2}
                  pt={2}
                >
                  <Date>
                    <TypographyDefault fontWeight="600" variant="h2">
                      {`${moment(date).format('DD')}`}
                    </TypographyDefault>
                    <TypographyDefault color="#686868" fontWeight="600" textTransform="uppercase" variant="caption1">
                      {`${moment(date).format('MMM')}`}
                    </TypographyDefault>
                  </Date>
                  <GameInfo direction="row" flexWrap={{xxs: 'wrap', sm: 'initial'}} gap={2} pl={2}>
                    <Box overflow="hidden" width={{xxs: '100%', sm: '"50%"'}}>
                      <TypographyDefault fontWeight="600" variant="body2">
                        {`${moment(timeStart).format('HH:mm')} - ${moment(timeEnd).format('HH:mm')}`}
                      </TypographyDefault>
                      <Link disabled={!statisticsIsReady} to={`/reports/stats_report/match_info/${statisticId || ''}`}>
                        Посмотреть статистику
                      </Link>
                    </Box>
                    <Box overflow="hidden" width={{xxs: '100%', sm: '"50%"'}}>
                      <TypographyDefault fontWeight="600" variant="body2">
                        {teams}
                      </TypographyDefault>
                      <Box direction="row">
                        {statusEstimateGame !== 'new' && (
                          <Link mr={0} to={`/game_estimation_summary/${id}`}>
                            Посмотреть оценки
                          </Link>
                        )}
                        <Link mr={0} to={`/lines?school=ITfD4PL0oy&team=${groupId.id}&history=true&gameId=${id}`}>
                          Посмотреть состав
                        </Link>
                        {broadcastLink && (
                          <Link href={`${broadcastLink}`} target="_blank">
                            Посмотреть трансляцию
                          </Link>
                        )}
                      </Box>
                    </Box>
                  </GameInfo>
                </Stack>
              ),
            )}
          </Box>
        )}
      </Card>
    </>
  );
}

const Date = styled(Box)`
  text-align: center;
  height: 100%;
  min-width: 35px;
`;

const GameInfo = styled(Stack)`
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &::before {
    margin-top: -${({theme}) => theme.spacing(2)};
    margin-bottom: -${({theme}) => theme.spacing(2)};
    position: absolute;
    content: '';
    height: calc(100% + ${({theme}) => theme.spacing(2)} * 2);
    width: 2px;
    left: 0;
    top: 0;
    background-color: #dddad4;
  }
`;
