import Widget from '@common/widget/Widget';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {getDashboard} from '../../../../redux/actions/DashboardsActions';
import LocalPreloader from '../../../preloader/LocalPreloader';
import {
  DOCTOR_STATUSES_OPTIONS,
  DOCTOR_SUB_STATUSES_OPTIONS,
  NOTAVILABLE_SUB_STATUSES_OPTIONS,
} from '../../../students/constants';
import PlayersHorizontalCard from '../../../students/tools/PlayersHorizontalCard';
import ChessTemplate from '../../../templates/ChessTemplate';
import TableEmptyBlock from '../../../ui/TableEmptyBlock';
import IllnessPlayers from '../../../widgets/IllnessPlayers';
import InjuredPlayers from '../../../widgets/InjuredPlayers';
import MissingPlayers from '../../../widgets/MissingPlayers';
import TodaySchedule from '../../../widgets/TodaySchedule/TodaySchedule';
import {
  BoxShadow,
  CardContainer,
  CardFlexWrapper,
  Column,
  Container,
  ContentBody,
  ContentRow,
  ContentRowText,
  ContentRowTextBlock,
  ContentWrapper,
  GroupName,
  Half,
  HeadlineColumnTitle,
  HeadlineSpaceBetween,
} from '../styles';

export default function TrainersPhysicalDashboardPanel() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboard());
  }, []);

  const {loading, dashboard} = useSelector((state) => {
    return {
      loading: state.dashboard.loading,
      dashboard: state.dashboard.dashboard,
    };
  }, shallowEqual);

  const allStatuses = DOCTOR_SUB_STATUSES_OPTIONS.concat(NOTAVILABLE_SUB_STATUSES_OPTIONS, DOCTOR_STATUSES_OPTIONS);

  const injuredPlayers = dashboard?.students.filter((student) => student.status === 'injury');
  const illnessPlayers = dashboard?.students.filter((student) => student.status === 'illness');
  const missingPlayers = dashboard?.students.filter((student) => student.status === 'notavailable');

  const contentBlock = (players, contentStatus, emptyText, statusText) => {
    return (
      <ContentWrapper>
        {players.length ? (
          <>
            <BoxShadow>
              <HeadlineSpaceBetween>
                <Column>
                  <HeadlineColumnTitle>ФИО игрока</HeadlineColumnTitle>
                </Column>
                <Column>
                  <HeadlineColumnTitle>Команда</HeadlineColumnTitle>
                </Column>
                <Column>
                  <HeadlineColumnTitle>{statusText ? statusText : 'Статус'}</HeadlineColumnTitle>
                </Column>
              </HeadlineSpaceBetween>
            </BoxShadow>
            <ContentBody>
              {players.map((item) => {
                return (
                  <ContentRow key={item.objectId}>
                    <CardContainer>
                      <Link target="_blank" to={`/student/${item.id}`}>
                        <CardFlexWrapper>
                          <PlayersHorizontalCard student={item} />
                        </CardFlexWrapper>
                      </Link>
                    </CardContainer>
                    <Column>
                      <GroupName>{item.group.name || '-'}</GroupName>
                    </Column>
                    <ContentRowTextBlock>
                      <ContentRowText>
                        {item.subStatus ? allStatuses.find((status) => status.value === item.subStatus)?.label : ''}
                      </ContentRowText>
                    </ContentRowTextBlock>
                  </ContentRow>
                );
              })}
            </ContentBody>
          </>
        ) : (
          <TableEmptyBlock text={emptyText} />
        )}
      </ContentWrapper>
    );
  };

  return (
    <ChessTemplate active="index">
      <Wrapper>
        <PageTitleDefault className="PageTitle" text="Рабочий стол тренера ОФП" />
        <PreloaderContainer>
          <LocalPreloader visible={loading} />
          <Container>
            <Half>
              <TodaySchedule
                hasGroupName={true}
                isPhysicalTrainerDashboard={true}
                todaySchedule={dashboard?.schedule}
              />
              {injuredPlayers?.length !== 0 && (
                <DashboardBlockContainer>
                  <InjuredPlayers
                    className="DashboardBlock"
                    contentBlock={contentBlock}
                    injuredPlayers={injuredPlayers}
                  />
                </DashboardBlockContainer>
              )}
            </Half>
            <Half>
              {illnessPlayers?.length !== 0 && (
                <DashboardBlockContainer>
                  <IllnessPlayers
                    className="DashboardBlock"
                    contentBlock={contentBlock}
                    illnessPlayers={illnessPlayers}
                  />
                </DashboardBlockContainer>
              )}
              {missingPlayers?.length !== 0 && (
                <DashboardBlockContainer>
                  <MissingPlayers
                    className="DashboardBlock"
                    contentBlock={contentBlock}
                    missingPlayers={missingPlayers}
                  />
                </DashboardBlockContainer>
              )}
              <Widget headerTitle="Прошедшие игры сезона" type="pastGames" withTabs={true} />
            </Half>
          </Container>
        </PreloaderContainer>
      </Wrapper>
    </ChessTemplate>
  );
}

const Wrapper = styled.div`
  padding-bottom: 32px;
  ${Column} {
    flex: 0 0 25%;
    max-width: 25%;
  }
`;
const PreloaderContainer = styled.div`
  position: relative;
  .LocalPreloader {
    border-radius: 6px;
  }
`;
const DashboardBlockContainer = styled.div`
  &:not(:last-child) {
    padding-bottom: 8px;
  }
  ${CardContainer},
  ${ContentRowTextBlock},
  ${Column} {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    @media screen and (max-width: 480px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-bottom: 8px;
    }

    &:last-child {
      @media screen and (max-width: 480px) {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
  ${ContentRowText} {
    max-width: none;
  }
  .DashboardBlock {
    &:not(:last-child) {
      padding-bottom: 0;
    }
  }
  .Headline {
    @media screen and (min-width: 361px) {
      padding-left: 24px;
    }
  }
  .LinesAvailablePlayerName {
    @media (min-width: 1200px) and (max-width: 1440px) {
      min-width: 90px;
      max-width: 90px;
    }
    @media (min-width: 481px) and (max-width: 575px) {
      min-width: 90px;
      max-width: 90px;
    }
  }
`;
