import MaterialApi from '@api/MaterialApi';
import AutoHeightScrollBar from '@common/AutoHeightScrollBar/index';
import Field from '@common/FormField';
import {Between} from '@components/apps/Materials/Materials';
import {Close} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Button, Divider, Grid, IconButton, Stack, Typography} from '@mui/material';
import TooltipDefault from '@ui/MUI/Tooltip';
import React, {Fragment, useState} from 'react';
import {FormProvider, useFieldArray, useForm} from 'react-hook-form';

import Preview from './components/Preview';

const MainMoments = ({moments = [], id, isPreview = false, students: students = []}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {control, handleSubmit, ...form} = useForm({
    defaultValues: {
      moments: moments.map((moment) => ({
        ...moment,
        students: moment.students.map((student) => ({label: student.name, value: student.id})),
      })),
    },
  });

  const {fields, append, remove} = useFieldArray({
    name: 'moments',
    control,
  });

  const isMoments = fields.length !== 0;

  const updateMoments = (data) => {
    setIsLoading(true);
    MaterialApi.createMaterialMoments(
      id,
      data.moments.map((moment) => ({
        ...moment,
        students: moment.students.map((student) => student.value),
      })),
    ).finally(() => setIsLoading(false));
  };

  return (
    <form>
      <FormProvider {...form} control={control}>
        <Stack borderRadius="6px" ml={3} mt={3} py={4} spacing={4} sx={{background: 'white'}} width="400px">
          <Stack mx={4}>
            <Typography variant="h3">Ключевые моменты</Typography>
            <Typography color="#2C2C2C" variant="body2">
              Основные игровые моменты
            </Typography>
          </Stack>
          <AutoHeightScrollBar maxHeight={300}>
            <Stack mx={4} spacing={4}>
              {fields.map((field, index) => {
                return (
                  <Fragment key={field.id}>
                    {isPreview ? (
                      <Preview {...field} />
                    ) : (
                      <div>
                        <Grid container spacing={3}>
                          <Grid container item alignItems="center" justifyContent="space-between">
                            <Field isRequired label="Время" md={3} name={`moments.${index}.time`} type="mask" />
                            <Grid item>
                              <TooltipDefault
                                content={
                                  <IconButton
                                    size="small"
                                    sx={{color: 'rgba(0, 0, 0, 0.43)'}}
                                    onClick={() => remove(index)}
                                  >
                                    <Close fontSize="small" />
                                  </IconButton>
                                }
                                placement="left"
                                title="Удалить"
                              />
                            </Grid>
                          </Grid>
                          <Field
                            isRequired
                            isSelectableAll
                            multiple
                            label="Игроки"
                            name={`moments.${index}.students`}
                            options={students.map((student) => ({
                              label: student.name,
                              value: student.id,
                            }))}
                            type="autocomplete"
                          />
                          <Field label="Комментарий" name={`moments.${index}.comment`} type="input" />
                        </Grid>
                      </div>
                    )}
                    <Divider />
                  </Fragment>
                );
              })}
            </Stack>
          </AutoHeightScrollBar>
          {!isPreview && (
            <div>
              <Stack mx={4}>
                <Between>
                  <Button
                    disableElevation
                    variant={!isMoments ? 'contained' : 'outlined'}
                    onClick={() => {
                      append({
                        time: '',
                        students: [],
                        description: '',
                      });
                    }}
                  >
                    Добавить
                  </Button>
                  {isMoments && (
                    <LoadingButton
                      disableElevation
                      loading={isLoading}
                      variant="contained"
                      onClick={handleSubmit(updateMoments)}
                    >
                      Сохранить
                    </LoadingButton>
                  )}
                </Between>
              </Stack>
            </div>
          )}
        </Stack>
      </FormProvider>
    </form>
  );
};

export default MainMoments;
