import TypeOfViewButtons from '@components/apps/DevelopmentJournal/components/PlayersDiary/components/TypeOfViewButtons';
import date from '@helpers/date';
import useQueryString from '@hooks/useQueryString';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {IconButton} from '@mui/material';
import {DEFAULT_DATE_SEND_FORMAT} from '@src/constants';
import {Box, Button, DatePicker, Divider, Stack, Typography} from '@ui/MUI';
import React, {useEffect} from 'react';

export const switchButtons = [
  {name: 'day', text: 'День'},
  {name: 'month', text: 'Месяц'},
];

function DayOrMonthFilter({
  activeTypeOfView,
  setActiveTypeOfView,
  activeMonthInterval,
  setActiveMonthInterval,
  datePickerValue,
  setDatePickerValue,
  selectedViewType,
  setSelectedViewType,
  isTypeOfViewShowed = true,
}) {
  const [qs, setQs] = useQueryString();

  const isMonth = selectedViewType === 'month';
  const isDay = selectedViewType === 'day';

  useEffect(() => {
    if (isMonth) {
      const activeMonth = date(activeMonthInterval?.[0]).locale('en').format('MMMM').toLowerCase();
      setQs({...qs, month: activeMonth, reportDate: null});
    }
  }, [isMonth, activeMonthInterval?.[0]]);

  useEffect(() => {
    if (isDay) {
      setActiveTypeOfView('table');
    }
  }, [isDay]);

  return (
    <Box bgcolor="#fff" borderRadius="6px" display="flex" mb={{xxs: 2, sm: 3}} overflow="hidden" p={{xxs: 2, sm: 3}}>
      <Stack direction="row" overflow="auto">
        {switchButtons.map((item, index) => (
          <Button
            color={selectedViewType === item.name ? 'primary' : 'secondary'}
            key={index}
            minWidth="fit-content"
            sx={{whiteSpace: 'nowrap', minWidth: 'fit-content'}}
            variant={selectedViewType === item.name ? 'contained' : 'text'}
            onClick={() => {
              setSelectedViewType(item.name);
            }}
          >
            {item.text}
          </Button>
        ))}
      </Stack>
      <Divider orientation="vertical" sx={{mx: 4, height: 24, alignSelf: 'center'}} />
      {isDay ? (
        <Box alignItems="center" display="flex" width={{xxs: '100%', xs: 205}}>
          <IconButton
            color="primary"
            sx={{p: '0px', mr: 2, minWidth: 32, minHeight: 32}}
            onClick={() => {
              setDatePickerValue(date(datePickerValue).subtract(1, 'day')?.format(DEFAULT_DATE_SEND_FORMAT));
              setQs({...qs, reportDate: date(datePickerValue).subtract(1, 'day')?.format(DEFAULT_DATE_SEND_FORMAT)});
            }}
          >
            <ChevronLeftIcon sx={{'&:nth-of-type(1)': {fontSize: '24px !important'}}} />
          </IconButton>
          <DatePicker
            label="Дата"
            value={datePickerValue}
            variant="standard"
            onChange={(newValue) => {
              setDatePickerValue(date(newValue)?.format(DEFAULT_DATE_SEND_FORMAT));
              setQs({...qs, reportDate: date(newValue)?.format(DEFAULT_DATE_SEND_FORMAT)});
            }}
          />
          <IconButton
            color="primary"
            sx={{p: '0px', ml: 2, minWidth: 32, minHeight: 32}}
            onClick={() => {
              setDatePickerValue(date(datePickerValue).add(1, 'day')?.format(DEFAULT_DATE_SEND_FORMAT));
              setQs({...qs, reportDate: date(datePickerValue).add(1, 'day')?.format(DEFAULT_DATE_SEND_FORMAT)});
            }}
          >
            <ChevronRightIcon sx={{'&:nth-of-type(1)': {fontSize: '24px !important'}}} />
          </IconButton>
        </Box>
      ) : (
        <Box alignItems="center" display="flex">
          <IconButton
            color="primary"
            sx={{p: '0px', minWidth: 24}}
            onClick={() => {
              const prevMonthNumber = Number(date(activeMonthInterval?.[0])?.format('M')) - 1;
              const prevYear = Number(date(activeMonthInterval?.[0])?.format('YYYY')) - 1;
              const prevDate = {
                monthNumber: prevMonthNumber < 1 ? 12 : Number(date(activeMonthInterval?.[0])?.format('M')) - 1,
                year: prevMonthNumber < 1 ? prevYear : Number(date(activeMonthInterval?.[0])?.format('YYYY')),
              };
              setActiveMonthInterval([
                `${prevDate.year}-${date(prevDate?.monthNumber, 'M').format('MM')}-01`,
                `${prevDate.year}-${date(prevDate?.monthNumber, 'M').format('MM')}-${date(prevDate?.monthNumber, 'M')
                  .endOf('month')
                  ?.format('DD')}`,
              ]);
            }}
          >
            <ChevronLeftIcon sx={{'&:nth-of-type(1)': {fontSize: '24px !important'}}} />
          </IconButton>
          <Typography sx={{px: 2}} variant="h4">
            {date(activeMonthInterval?.[0]).format('MMMM YYYY')}
          </Typography>
          <IconButton
            color="primary"
            sx={{p: '0px', minWidth: 24}}
            onClick={() => {
              const nextMonthNumber = Number(date(activeMonthInterval?.[0]).format('M')) + 1;
              const nextYear = Number(date(activeMonthInterval?.[0]).format('YYYY')) + 1;
              const nextDate = {
                monthNumber: nextMonthNumber > 12 ? 1 : Number(date(activeMonthInterval?.[0]).format('M')) + 1,
                year: nextMonthNumber > 12 ? nextYear : Number(date(activeMonthInterval?.[0]).format('YYYY')),
              };
              setActiveMonthInterval([
                `${nextDate.year}-${date(nextDate?.monthNumber, 'M').format('MM')}-${date(nextDate?.monthNumber, 'M')
                  .startOf('month')
                  ?.format('DD')}`,
                `${nextDate.year}-${date(nextDate?.monthNumber, 'M').format('MM')}-${date(nextDate?.monthNumber, 'M')
                  .endOf('month')
                  ?.format('DD')}`,
              ]);
            }}
          >
            <ChevronRightIcon sx={{'&:nth-of-type(1)': {fontSize: '24px !important'}}} />
          </IconButton>
        </Box>
      )}
      {isTypeOfViewShowed && (
        <TypeOfViewButtons activeTypeOfView={activeTypeOfView} setActiveTypeOfView={setActiveTypeOfView} />
      )}
    </Box>
  );
}

export default DayOrMonthFilter;
