import {Stack} from '@ui/MUI';
import React from 'react';

import {Filters} from './components/Filters';
import Table from './components/Table';

const ArchiveTab = () => {
  return (
    <Stack spacing={3}>
      <Filters />
      <Table />
    </Stack>
  );
};

export default ArchiveTab;
