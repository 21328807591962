import {useCurrentBreakPoint} from '@components/apps/MaterialsModule/hooks';
import useForceUpdate from '@hooks/useForceUpdate';
import {Stack, Tooltip, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import moment from 'moment';
import React, {useEffect, useMemo, useRef, useState} from 'react';

import {MAX_TITLE_HEIGHT, overflowStyles} from '../../constants';

const Content = ({name, createdAt}) => {
  const breakpoint = useCurrentBreakPoint();

  const [isOverflow, setIsOverflow] = useState(false);
  const update = useForceUpdate();
  const nameRef = useRef();

  useEffect(() => {
    const height = nameRef.current.clientHeight;
    if (height > MAX_TITLE_HEIGHT) {
      setIsOverflow(true);
    } else {
      update();
    }
  }, [nameRef, setIsOverflow, update]);

  const date = useMemo(() => moment(createdAt).format('DD.MM.YYYY'), [createdAt]);

  return (
    <Stack
      spacing="4px"
      sx={{
        pl: {
          xxs: '14px',
          xs: '0px',
        },
      }}
    >
      <Tooltip title={isOverflow && name}>
        <Typography
          ref={nameRef}
          sx={{
            wordBreak: 'break-word',
            minHeight: breakpoint !== 'xxs' && '40px',
            ...(isOverflow && overflowStyles(breakpoint)),
          }}
          variant="subtitle2"
        >
          {name}
        </Typography>
      </Tooltip>
      <Typography color={grey[38]} fontWeight="600">
        {date}
      </Typography>
    </Stack>
  );
};

export default Content;
