export const TYPES_OPTIONS = [
  {
    label: 'Травма',
    value: 'injury',
  },
  {
    label: 'Болезнь',
    value: 'illness',
  },
];
