import isEqual from 'lodash/isEqual';
import {useRef} from 'react';

function useDeepMemo<TValue, TKey extends unknown[] = []>(callback: () => TValue, deps: TKey): TValue {
  const ref = useRef<{deps: TKey; value: TValue}>();

  if (!ref.current || !isEqual(deps, ref.current.deps)) {
    ref.current = {deps, value: callback()};
  }

  return ref.current.value;
}
export default useDeepMemo;
