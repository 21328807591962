import {BMI_ITEMS} from '@components/apps/PlayerPersonalFile/constants';
import useDeepMemo from '@hooks/useDeepMemo';
import useFatAccess from '@hooks/useFatAccess';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectPlayerReports} from '@selectors/bodyMassIndex';
import {clearPlayerReports, loadPlayerReports} from '@slices/bodyMassIndex';
import {Box, Button, CircularProgress, Stack} from '@ui/MUI';
import {blue, darkRed, green, purple} from '@ui/MUI/colors';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import BMIChart from './components/BMIChart';
import {BoxWrapper} from './PlayerPage';

const PlayerPage = ({season, preload = true}) => {
  const [{bmiTab, ...qs}, set] = useQueryString();
  const currentUserId = useStore((state) => state.users.currentUser.id);
  const {data, isLoading} = useStore(selectPlayerReports);
  const {group} = useStore((state) => state.users?.student);
  const {id} = useParams();

  const {hasAccess} = useFatAccess(group?.id);
  const dispatch = useStoreDispatch();

  const tabs = useDeepMemo(() => {
    let result = [
      {
        label: 'Рост',
        value: (
          <BMIChart chartsData={data} color={darkRed['65']} max={210} min={140} type={BMI_ITEMS.height} unit="см" />
        ),
        id: BMI_ITEMS.height,
      },
      {
        label: 'Вес',
        value: <BMIChart chartsData={data} color={green['70']} max={120} min={45} type={BMI_ITEMS.weight} unit="кг" />,
        id: BMI_ITEMS.weight,
      },
      {
        label: '% жира',
        value: <BMIChart chartsData={data} color={blue['60']} max={100} min={0} type={BMI_ITEMS.fat} unit="%" />,
        id: BMI_ITEMS.fat,
      },
      {
        label: 'ИМТ',
        value: <BMIChart chartsData={data} color={purple['50']} max={100} min={0} type={BMI_ITEMS.bmi} unit="%" />,
        id: BMI_ITEMS.bmi,
      },
    ];
    if (!hasAccess) {
      result = result.slice(0, 2);
    }
    return result;
  }, [data, hasAccess]);

  useEffect(() => {
    preload && dispatch(loadPlayerReports({playerId: id || currentUserId, season, sort: 'date', order: 'ASC'}));
    return () => {
      dispatch(clearPlayerReports());
    };
  }, [preload, season]);

  return (
    <BoxWrapper>
      {isLoading && (
        <CircularProgress
          sx={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 5}}
        />
      )}
      {data?.length || isLoading ? (
        <>
          <ButtonsWrapper alignItems="center" direction="row" px={4} py={2}>
            {tabs.map(({label, id}, index) => {
              const isActive = bmiTab ? bmiTab === id : index === 0;
              return (
                <Button
                  color={isActive ? 'primary' : 'secondary'}
                  key={index}
                  size="small"
                  variant={isActive ? 'contained' : 'text'}
                  onClick={() => {
                    set({...qs, bmiTab: id});
                  }}
                >
                  {label}
                </Button>
              );
            })}
          </ButtonsWrapper>
          <Box p={4}>{bmiTab ? tabs?.find((item) => item?.id === bmiTab)?.value : tabs[0].value}</Box>
        </>
      ) : (
        <Box px={4}>
          <TableEmptyBlock
            text={!preload && !season ? 'Выберите сезон для отображения данных' : 'Данных по отчётам ещё нет'}
          />
        </Box>
      )}
    </BoxWrapper>
  );
};

export default PlayerPage;

const ButtonsWrapper = styled(Stack)`
  align-items: center;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
`;
