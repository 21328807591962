export const mock = [
  {
    previous: {
      playerId: '-GRPKrtIH2Ir',
      height: 157,
      weight: 48.9,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 19.84,
      firstName: 'Арсений',
      lastName: 'Уфимцев',
      playerNumber: '76',
    },
    current: {
      playerId: '-GRPKrtIH2Ir',
    },
  },
  {
    previous: {
      playerId: '1RbSb7H3HG',
      height: 166.5,
      weight: 51.5,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 18.58,
      firstName: 'Иван',
      lastName: 'Гранкин',
      playerNumber: '68',
    },
    current: {
      playerId: '1RbSb7H3HG',
    },
  },
  {
    previous: {
      playerId: '9DKotXbBtF',
      height: 149,
      weight: 35.5,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 15.99,
      firstName: 'Владислав',
      lastName: 'Никитин',
      playerNumber: '61',
    },
    current: {
      playerId: '9DKotXbBtF',
    },
  },
  {
    previous: {
      playerId: 'A3956K3yRG',
      height: 155,
      weight: 37.7,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 15.69,
      firstName: 'Алексей',
      lastName: 'Березняк',
      playerNumber: '80',
    },
    current: {
      playerId: 'A3956K3yRG',
    },
  },
  {
    previous: {
      playerId: 'AHkIUUW4Jw',
      height: 164,
      weight: 49.8,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 18.52,
      firstName: ' Матвей ',
      lastName: ' Желтоножко',
      playerNumber: '71',
    },
    current: {
      playerId: 'AHkIUUW4Jw',
    },
  },
  {
    previous: {
      playerId: 'FLQG8GafTN',
      height: 158.3,
      weight: 48.6,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 19.39,
      firstName: 'Матвей',
      lastName: 'Ахмутдинов',
      playerNumber: '77',
    },
    current: {
      playerId: 'FLQG8GafTN',
    },
  },
  {
    previous: {
      playerId: 'Go8MyZgYzO',
      height: 155,
      weight: 42.6,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 17.73,
      firstName: 'Александр',
      lastName: 'Панов',
      playerNumber: '74',
    },
    current: {
      playerId: 'Go8MyZgYzO',
    },
  },
  {
    previous: {
      playerId: 'Hg00VTgwW1',
      height: 154,
      weight: 48.3,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 20.37,
      firstName: 'Матвей',
      lastName: 'Чаунин',
      playerNumber: '66',
    },
    current: {
      playerId: 'Hg00VTgwW1',
    },
  },
  {
    previous: {
      playerId: 'RMC0hPyEe5',
      height: 156.5,
      weight: 41.5,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 16.94,
      firstName: 'Александр',
      lastName: 'Вашкевич',
      playerNumber: '79',
    },
    current: {
      playerId: 'RMC0hPyEe5',
    },
  },
  {
    previous: {
      playerId: 'SaEixzXm8w',
      height: 168,
      weight: 55.4,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 19.63,
      firstName: 'Кирилл',
      lastName: 'Воропаев',
      playerNumber: '81',
    },
    current: {
      playerId: 'SaEixzXm8w',
    },
  },
  {
    previous: {
      playerId: 'UtZVzXfJr8',
      height: 159,
      weight: 43.5,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 17.21,
      firstName: 'Александр',
      lastName: 'Гоголев',
      playerNumber: '97',
    },
    current: {
      playerId: 'UtZVzXfJr8',
    },
  },
  {
    previous: {
      playerId: 'WM2EQMjklY',
      height: 148,
      weight: 41.5,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 18.95,
      firstName: 'Даниил',
      lastName: 'Иванищев',
      playerNumber: '84',
    },
    current: {
      playerId: 'WM2EQMjklY',
    },
  },
  {
    previous: {
      playerId: 'XBCRjqzd8s',
      height: 153,
      weight: 42,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 17.94,
      firstName: 'Данил',
      lastName: 'Силаков',
      playerNumber: '69',
    },
    current: {
      playerId: 'XBCRjqzd8s',
    },
  },
  {
    previous: {
      playerId: 'ZXm5cxXZeHlP',
      height: 168,
      weight: 58.5,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 20.73,
      firstName: 'Евгений ',
      lastName: 'Бабич',
      playerNumber: '65',
    },
    current: {
      playerId: 'ZXm5cxXZeHlP',
    },
  },
  {
    previous: {
      playerId: 'cEuqQ9K8hX',
      height: 156,
      weight: 43.3,
      date: 1681265813707,
      caliperometry: 0,
      bmi: 17.79,
      firstName: 'Максим',
      lastName: 'Иванов',
      playerNumber: '89',
    },
    current: {
      playerId: 'cEuqQ9K8hX',
    },
  },
];
