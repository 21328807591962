import PlaceholderWrapper from '@common/FullHeightWrapper';
import ReactTableComponent from '@common/ReactTableComponent';
import {clearTabsData, loadGameStatisticsData} from '@slices/gameStatisticsParsingCheck';
import {Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {TableWrapper} from './Table';

function TableComponent({data = [], columns = [], isLoad, hasError, tab, ...props}) {
  const dispatch = useDispatch();
  const {id} = useParams();

  useEffect(() => {
    if (tab && id) dispatch(loadGameStatisticsData({tab, statId: id}));
    return () => {
      dispatch(clearTabsData(tab));
    };
  }, [tab, id]);

  return (
    <TableWrapper mt={2}>
      {isLoad && hasError ? (
        <PlaceholderWrapper
          alignItems="center"
          data-testid="no-filters"
          display="flex"
          justifyContent="center"
          px={{xxs: 2, sm: 4}}
          py={{xxs: 1, sm: 3}}
        >
          <Typography variant="subtitle1">Статистика отсутствует</Typography>
        </PlaceholderWrapper>
      ) : (
        <ReactTableComponent
          isAvailableHeight
          columns={columns}
          data={data}
          tableBodyCellProps={{
            sx: {
              boxShadow: `inset 0 -1px 0 0 ${grey[12]}`,
              py: {xxs: 1, sm: 3},
              px: {xxs: 2, sm: 4},
              height: 'auto !important',
              position: 'relative',
            },
          }}
          tableHeaderCellProps={{
            sx: {
              boxShadow: `inset 0 -1px 0 0 ${grey[12]}`,
              py: {xxs: 1, sm: 3},
              px: {xxs: 2, sm: 4},
              height: 'auto !important',
            },
          }}
          {...props}
        />
      )}
    </TableWrapper>
  );
}

TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  isLoad: PropTypes.bool,
  hasError: PropTypes.bool,
  tab: PropTypes.string.isRequired,
};

export default TableComponent;
