import useCustomForm from '@hooks/useCustomForm';
import useEvent from '@hooks/useEvent';
import useStoreDispatch from '@hooks/useStoreDispatch';
import useToggle from '@hooks/useToggle';
import {
  createTrainingControl,
  deleteTrainingControl,
  fetcherTrainingControl,
  updateTrainingControl,
} from '@slices/trainingControlProcess';
import {useMemo, useRef} from 'react';

export const useSkillsForm = () => {
  const form = useCustomForm({
    name: '',
    skills: [],
    section: null,
    category: null,
  });

  const dispatch = useStoreDispatch();

  const handleSubmit = (data) => {
    if (data.id) {
      const payload = {
        name: data.name,
        number: Number(data.number),
        category: data.category.id,
        section: data.section.id,
      };
      dispatch(updateTrainingControl({name: 'skill', payload, onSuccess: form.close, id: data.id}));
    } else {
      dispatch(
        createTrainingControl({
          name: 'skill',
          payload: {
            skills: data.skills.map((skill) => ({
              name: skill.name,
              number: Number(skill.number),
              category: skill.category.id,
              section: skill.section.id,
            })),
          },
          onSuccess: form.close,
        }),
      );
    }
  };

  return {...form, handleSubmit: form.methods.handleSubmit(handleSubmit)};
};

export const useDeleteModal = (age) => {
  const name = 'skill';
  const id = useRef(null);
  const dispatch = useStoreDispatch();
  const toggle = useToggle({
    onToggle: (payload) => (id.current = payload),
    onToggleOff: () => (id.current = null),
  });

  const handleDelete = useEvent(() => {
    dispatch(
      deleteTrainingControl({
        name,
        payload: id.current,
        onSuccess: () => {
          dispatch(fetcherTrainingControl(name, {age}));
          toggle.off();
        },
        skipFetch: true,
      }),
    );
  });

  return useMemo(() => ({toggle, handleDelete}), [toggle, handleDelete]);
};
