import Box from '@ui/MUI/Box';
import Stack from '@ui/MUI/Stack';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;
export const GameInfoWrapper = styled(Stack)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const MainStatsParameterLineContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:last-child {
    margin-bottom: 0 !important;
  }
`;
export const MainStatsParameterLine = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: 50%;
  width: 100%;
  height: 6px;
  background-color: #dadada;
  border-radius: 6px;
  overflow: hidden;
`;
export const MainStatsParameterLineTitleBlock = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const MainStatsParameterLineItem = styled(Box)`
  height: 100%;
  width: ${({width}) => width}%;
  background-color: ${({bg}) => bg};
  max-width: 100%;
  position: absolute;
  border-radius: 6px;
  transition: 0.3s ease-in-out;
`;
