import {BROWN} from '@components/apps/StatsReportForOneGame/constants';
import {green, grey, purple, yellow} from '@ui/MUI/colors';
import TooltipDefault from '@ui/MUI/Tooltip';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const BGC_TYPES = {
  attack: purple['15'],
  defense: green['15'],
  compare: yellow['15'],
};
function ColumnHeader({title, value, isIndividual, bgcType, hasIndicator = true}) {
  return (
    <TooltipDefault
      content={
        <div>
          <Wrapper>
            <TypographyDefault variant="subtitle2">{value}</TypographyDefault>
          </Wrapper>
          {bgcType && <Background color={BGC_TYPES?.[bgcType]} />}
          {hasIndicator && <Marker color={isIndividual && BROWN} />}
        </div>
      }
      placement="top"
      title={title}
    />
  );
}

ColumnHeader.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  isIndividual: PropTypes.bool,
  bgcType: PropTypes.oneOf(['attack', 'defense', 'compare']),
  hasIndicator: PropTypes.bool,
};

export default ColumnHeader;

const Wrapper = styled.div`
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  z-index: 1;
`;

const Marker = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: ${({color}) => color || grey[24]};
  z-index: 1;
`;

const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({color}) => color};
  z-index: -1;
`;
