import Box from '@ui/MUI/Box';
import Chip from '@ui/MUI/Chip';
import Stack from '@ui/MUI/Stack';
import React from 'react';

const Chips = ({chips = [], value = [], onChange}) => {
  return (
    <Stack direction="row" flexWrap="wrap" gap="12px 8px">
      {chips.map((chip) => {
        const isSelected = value.includes(chip.id);
        return (
          <Box key={chip.id} minWidth="50px">
            <Chip
              label={chip.name}
              variant={isSelected ? 'filled' : 'outlined'}
              onClick={() => {
                if (isSelected) {
                  const filtered = value.filter((id) => id !== chip.id);

                  onChange(filtered);
                } else {
                  onChange([...value, chip.id]);
                }
              }}
            />
          </Box>
        );
      })}
    </Stack>
  );
};

export default Chips;
