import {getBodyMassIndexParamsForPlayerDashboard} from '@actions/BMIActions';
import {loadStudentById} from '@actions/UsersActions';
import Content from '@components/apps/StudentDashboard/components/Content';
import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';

import ChessTemplate from '../../templates/ChessTemplate';

export default function StudentDashboard() {
  const dispatch = useStoreDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const {currentUserId, student, group, studentBMIParams} = useStore((state) => {
    const student = state.users.student;
    const group = student.team;
    return {
      student,
      group,
      currentUserId: state.users.currentUserId,
      studentBMIParams: state.bodyMassIndex.items,
    };
  }, shallowEqual);

  useEffect(() => {
    if (currentUserId) {
      dispatch(getBodyMassIndexParamsForPlayerDashboard(currentUserId));
      dispatch(loadStudentById(currentUserId));
    }
  }, [currentUserId]);

  return (
    <>
      <ChessTemplate active={'index'}>
        <PageTitleDefault
          breadCrumbs={[{label: student?.school?.name || '', path: null}]}
          withOutBreadCrumbs={isMobile}
        >
          Рабочий стол игрока
        </PageTitleDefault>
        <Content
          currentUserId={currentUserId}
          group={group}
          id={currentUserId}
          isMobile={isMobile}
          student={{
            ...student,
            height: studentBMIParams?.height,
            weight: studentBMIParams?.weight,
          }}
        />
      </ChessTemplate>
    </>
  );
}
