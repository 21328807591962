import DashboardsAPI from '@api/DashboardsAPI';

import PsychoAPI from '../../api/PsychoAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

const errorMessage = (err) => {
  let errorText = 'Ошибка при загрузке';
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
      }
    }
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

export const getInjuredPlayers = () => (dispatch) => {
  dispatch({
    type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
    payload: {
      name: 'injuredPlayers',
      isLoading: true,
      data: [],
    },
  });
  return PsychoAPI.getInjuredPlayers()
    .then((data) => {
      dispatch({
        type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
        payload: {
          name: 'injuredPlayers',
          isLoading: false,
          data: data.data || {},
        },
      });
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch({
        type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
        payload: {
          name: 'injuredPlayers',
          isLoading: false,
          data: [],
          isError: true,
        },
      });
    });
};

export const getPastGamesOfSeason = (filter) => (dispatch) => {
  dispatch({
    type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
    payload: {
      name: 'pastGames',
      isLoading: true,
      data: [],
    },
  });
  return DashboardsAPI.getPastGamesOfSeason(filter)
    .then((data) => {
      dispatch({
        type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
        payload: {
          name: 'pastGames',
          isLoading: false,
          data: data.data || [],
        },
      });
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch({
        type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
        payload: {
          name: 'pastGames',
          isLoading: false,
          data: [],
          isError: true,
        },
      });
    });
};

export const getPsychoDashboard = () => (dispatch) => {
  dispatch({
    type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
    payload: {
      name: 'psychoDashboard',
      isLoading: true,
      data: [],
    },
  });
  return PsychoAPI.getPsychoDashboard()
    .then((data) => {
      dispatch({
        type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
        payload: {
          name: 'psychoDashboard',
          isLoading: false,
          data: data.data || {},
        },
      });
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch({
        type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
        payload: {
          name: 'psychoDashboard',
          isLoading: false,
          data: [],
          isError: true,
        },
      });
    });
};

export const getRatingPlayers = (groupId) => (dispatch) => {
  dispatch({
    type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
    payload: {
      name: 'playersRating',
      isLoading: true,
      data: [],
      selectedGroupId: groupId,
    },
  });
  return PsychoAPI.getRatingPlayers(groupId)
    .then((data) => {
      dispatch({
        type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
        payload: {
          name: 'playersRating',
          isLoading: false,
          data: data.data || {},
          selectedGroupId: groupId,
        },
      });
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch({
        type: types.PSYCHO_DASHBOARD_WIDGET_UPDATE,
        payload: {
          name: 'playersRating',
          isLoading: false,
          data: [],
          isError: true,
          selectedGroupId: [],
        },
      });
    });
};
