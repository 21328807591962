import Field from '@common/FormField';
import {CustomTeamDto, FormData} from '@components/apps/Tournaments/components/CreateTournamentModal/types';
import {teamField} from '@components/apps/Tournaments/constants';
import AddIcon from '@mui/icons-material/Add';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {Box, Button, IconButton, Stack} from '@ui/MUI';
import {get} from 'lodash';
import React, {useMemo} from 'react';
import {FormProvider, useFieldArray, useFormContext, useWatch} from 'react-hook-form';

function TeamsFields({
  index,
  options,
  isLoading,
}: {
  index: number;
  options: {value: string; label: string}[];
  isLoading: boolean;
}) {
  const form = useFormContext<FormData>();
  const {control} = form;
  const {fields, append, remove} = useFieldArray({
    control,
    rules: {minLength: 2, maxLength: 25},
    name: `groups.${index}.teams`,
  });
  const school: Array<FormData['school']> = useWatch({control, name: 'school'});
  const teams: CustomTeamDto[] = useWatch({control, name: `groups.${index}.teams`});
  const filteredOptions = useMemo(() => {
    return options.filter(
      (option) =>
        !teams?.some(({team}) => {
          return option?.value === team?.value;
        }),
    );
  }, [options, teams]);

  return (
    <FormProvider {...form}>
      <Stack alignItems="flex-start" gap={2} width="100%">
        {fields?.map((field, ind) => {
          return (
            <Stack alignItems="flex-start" direction="row" gap={2} key={field.id} width={{xxs: '100%', xs: '50%'}}>
              <Box width="100%">
                <Field
                  disabled={!school?.length}
                  isLoading={isLoading}
                  isRequired={ind <= 1}
                  label={`Команда ${ind + 1}`}
                  name={`groups.${index}.teams.${ind}.team`}
                  options={filteredOptions}
                  type="autocomplete"
                  validate={(value: CustomTeamDto['team'], {school, groups}: FormData) => {
                    if (
                      value &&
                      !school?.find(
                        (school) => school?.value === value?.schoolId || school?.value === value?.school?.id,
                      )
                    ) {
                      return 'Команда не из списка школ';
                    }
                    if (
                      get<FormData['groups'], string>(groups, `${index}.teams`)?.filter(
                        (item: CustomTeamDto) => item?.team?.id === value?.id,
                      )?.length > 1
                    ) {
                      return 'Команда дублируется';
                    }
                  }}
                />
              </Box>
              {ind > 1 && (
                <IconButton size="small" onClick={() => remove(ind)}>
                  <CloseOutlinedIcon fontSize="inherit" />
                </IconButton>
              )}
            </Stack>
          );
        })}
        <Button
          color="primary"
          disabled={fields?.length === 6}
          size="small"
          startIcon={<AddIcon />}
          variant="text"
          onClick={() => {
            append(teamField);
          }}
        >
          Добавить
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default TeamsFields;
