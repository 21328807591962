import {
  getCategories,
  getEstimates,
  getEstimatesGroupedByCategories,
  getEstimatesGroupedByPlayers,
  getPersonalDevelopmentJournalPlayerSkills,
} from '@api/skill';
import {createSlice} from '@reduxjs/toolkit';
import {initialStateIndicators} from '@src/constants';

const initialState = {
  categories: {
    list: {
      ...initialStateIndicators,
    },
  },
  estimates: {
    modal: {
      isOpen: false,
      data: null,
      isLoading: false,
      type: null,
    },
    list: {
      ...initialStateIndicators,
    },
    players: {
      list: {
        ...initialStateIndicators,
      },
      filters: {
        school: null,
        group: null,
        categories: [],
        specialists: [],
      },
    },
    archive: {
      list: {
        ...initialStateIndicators,
      },
      filters: {
        school: null,
        group: null,
      },
    },
    categoriesEstimates: {
      list: {
        ...initialStateIndicators,
      },
      filters: {
        school: null,
        group: null,
        categories: [],
      },
    },
  },
  personalDevelopmentJournalSkills: {
    list: {
      ...initialStateIndicators,
    },
  },
};

const skillSlice = createSlice({
  name: 'skill',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      const {name, value, entityName} = action.payload;
      const filters = state.estimates[entityName].filters;
      if (filters.group && name === 'school') {
        filters.group = null;
      }

      filters[name] = value;
    },
    loadingData: (state, {payload}) => {
      const {entityName} = payload;
      const list = state?.estimates?.[entityName]?.list || state?.[entityName].list;
      list.isLoading = true;
      list.data = [];
      list.error = null;
      list.isLoad = false;
    },
    loadingDataError: (state, {payload}) => {
      const {entityName, error} = payload;
      const list = state?.estimates?.[entityName]?.list || state?.[entityName]?.list;
      list.isLoading = false;
      list.data = [];
      list.error = error;
      list.isLoad = true;
    },
    updateData: (state, {payload}) => {
      const {entityName, data} = payload;
      const list = state?.estimates?.[entityName]?.list || state?.[entityName]?.list;
      if (!list) {
        throw new Error(`${entityName} is not defined in state or list is not defined in ${entityName}`);
      }
      if (list.isLoading) {
        list.isLoading = false;
      }
      list.data = data;
      list.error = null;
      list.isLoad = true;
    },
    updateModal: (state, {payload}) => {
      const {isOpen, type, data, isLoading} = payload;
      if (isOpen !== undefined) {
        state.estimates.modal.isOpen = isOpen;
      }

      if (type !== undefined) {
        state.estimates.modal.type = type;
      }

      if (data !== undefined) {
        state.estimates.modal.data = data;
      }

      if (isLoading !== undefined) {
        state.estimates.modal.isLoading = isLoading;
      }
    },
  },
});

export const {updateFilters, loadingData, updateData, loadingDataError, updateModal} = skillSlice.actions;
export default skillSlice.reducer;

export const fetchCategories = (params) => async (dispatch) => {
  dispatch(loadingData({entityName: 'categories'}));
  try {
    const data = await getCategories(params);
    dispatch(updateData({entityName: 'categories', data}));
  } catch (error) {
    dispatch(loadingDataError({entityName: 'categories', error}));
  }
};

export const fetchPersonalDevelopmentJournalSkillsByPlayerId = (id) => async (dispatch) => {
  dispatch(loadingData({entityName: 'personalDevelopmentJournalSkills'}));
  try {
    const data = await getPersonalDevelopmentJournalPlayerSkills(id);
    dispatch(updateData({entityName: 'personalDevelopmentJournalSkills', data: data?.data}));
  } catch (error) {
    dispatch(loadingDataError({entityName: 'personalDevelopmentJournalSkills', error}));
  }
};

export const fetchEstimatesGroupedByPlayers = (params) => async (dispatch) => {
  dispatch(loadingData({entityName: 'players'}));
  try {
    const data = await getEstimatesGroupedByPlayers(params);
    dispatch(updateData({entityName: 'players', data}));
  } catch (error) {
    dispatch(loadingDataError({entityName: 'players', error}));
  }
};

export const fetchArchiveEstimates = (params) => async (dispatch) => {
  dispatch(loadingData({entityName: 'archive'}));
  try {
    const {estimates} = await getEstimates({isCompleted: true, ...params});
    const appendEstimatesToStudent = estimates.map((estimate) => {
      const studentId = estimate.student.id;
      const studentEstimates = estimates.filter((estimate) => estimate.student.id === studentId);
      return {
        ...estimate,
        student: {
          ...estimate.student,
          estimates: studentEstimates,
        },
      };
    });

    dispatch(updateData({entityName: 'archive', data: appendEstimatesToStudent}));
  } catch (error) {
    dispatch(loadingDataError({entityName: 'archive', error}));
  }
};

export const fetchEstimatesGroupedByCategory = (params) => async (dispatch) => {
  dispatch(loadingData({entityName: 'categoriesEstimates'}));
  try {
    const data = await getEstimatesGroupedByCategories(params);
    dispatch(updateData({entityName: 'categoriesEstimates', data}));
  } catch (error) {
    dispatch(loadingDataError({entityName: 'categoriesEstimates', error}));
  }
};

export const fetchEstimatesList = (params) => async (dispatch) => {
  dispatch(loadingData({entityName: 'estimates'}));
  try {
    const {estimates} = await getEstimates(params);
    dispatch(updateData({entityName: 'estimates', data: estimates}));
  } catch (error) {
    dispatch(loadingDataError({entityName: 'estimates', error}));
  }
};
