import {HAND_MAP, HOCKEY_ROLES, longDash, SCOUT_REPORT_TYPES} from '@src/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {Field, FixedInput, ModalTitle} from '../CommonScoutReport';
import OuterPlayerScoreFields from '../OuterPlayerScoreFields';
import ReportFields from '../ReportFields';

function ReportCreateContent({studentData, isEdit = false, reportType}) {
  return (
    <form>
      <ModalTitle>{isEdit ? 'Редактирование отчета' : 'Новый отчет'}</ModalTitle>
      {SCOUT_REPORT_TYPES.inner === reportType ? (
        <InnerReportFields studentData={studentData} />
      ) : (
        <OuterReportFields studentData={studentData} />
      )}
      <OuterPlayerScoreFields hockeyRole={studentData?.student?.hockeyRole} />
      <ReportFields />
    </form>
  );
}

ReportCreateContent.propTypes = {
  studentData: PropTypes.object,
  isEdit: PropTypes.bool,
  reportType: PropTypes.string,
};

export default ReportCreateContent;

function InnerReportFields({studentData}) {
  const {
    student: {firstName, lastName, hockeyRole, hand, weight, height},
    group,
  } = studentData;
  return (
    <>
      <Field align="flex-start" margin="0 0 24px 0">
        <FixedInput>
          <span className="label">Игрок</span>
          <span className="text">
            {firstName} {lastName}
          </span>
        </FixedInput>
        <FixedInput>
          <span className="label">Команда</span>
          <span className="text">{group?.name || longDash}</span>
        </FixedInput>
      </Field>

      <Field align="flex-start" margin="0 0 38px 0">
        <FixedInput>
          <span className="label">Амплуа</span>
          <span className="text">{HOCKEY_ROLES?.[hockeyRole] || longDash}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">Хват</span>
          <span className="text">{HAND_MAP?.[hand] || longDash}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">Вес</span>
          <span className="text">{weight ? `${weight} кг` : longDash}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">Рост</span>
          <span className="text">{height ? `${height} см` : longDash}</span>
        </FixedInput>
      </Field>
    </>
  );
}

function OuterReportFields({studentData}) {
  const {
    student: {firstName, lastName, playerNumber, hockeyRole, hand, birthday},
    group,
    school,
  } = studentData;
  return (
    <>
      <Field align="flex-start" margin="0 0 24px 0">
        <FixedInput>
          <span className="label">Игрок</span>
          <span className="text">{firstName && lastName ? `${firstName} ${lastName}` : ''}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">Дата рождения</span>
          <span className="text">{birthday ? `${moment(birthday).format('DD.MM.YYYY')} г.` : longDash}</span>
        </FixedInput>
      </Field>

      <Field align="flex-start" margin="0 0 24px 0">
        <FixedInput maxWidth="160px">
          <span className="label">Клуб</span>
          <span className="text">{school.name || longDash}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">Год</span>
          <span className="text">{group.name || longDash}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">№ игрока</span>
          <span className="text">{playerNumber || longDash}</span>
        </FixedInput>
      </Field>

      <Field align="flex-start" margin="0 0 38px 0">
        <FixedInput>
          <span className="label">Амплуа</span>
          <span className="text">{HOCKEY_ROLES[hockeyRole] || longDash}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">Хват</span>
          <span className="text">{HAND_MAP[hand] || longDash}</span>
        </FixedInput>
      </Field>
    </>
  );
}
