import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {playerHandFullNames, playerStatus} from '../../../../../constants';
import LocalPreloader from '../../../../preloader/LocalPreloader';
import PlayersHorizontalCard from '../../../../students/tools/PlayersHorizontalCard';
import DefaultSortTable from '../../../../ui/MUI/Table/Table';
import TableEmptyBlock from '../../../../ui/TableEmptyBlock';
import Table from '../table/index';

export default function PlayersComposition({group, loading}) {
  const players = group?.students;

  const playersTable = (hockeyRole) => {
    return (
      <DefaultSortTable
        columns={[
          {
            key: 'player',
            field: 'player',
            headerName: 'Игрок',
            minWidth: 275,
            align: 'left',
            headerAlign: 'left',
            sortComparator: (v1, v2, param1, param2) => {
              if (param1.value.lastName.toLowerCase() > param2.value.lastName.toLowerCase()) {
                return 1;
              }
              if (param1.value.lastName.toLowerCase() < param2.value.lastName.toLowerCase()) {
                return -1;
              }
              return 0;
            },
            renderCell: (params) => {
              if (window.innerWidth <= 360) {
                params.value.firstName = params.value.firstName[0] + '.';
              }
              return (
                <Link className="TableColumnLink" to={`/student/${params.value.id}`}>
                  <PlayersHorizontalCard student={params.value} />
                </Link>
              );
            },
          },
          {
            key: 'birthdayTimestamp',
            field: 'birthdayTimestamp',
            headerName: 'Дата рождения',
            minWidth: 275,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
              return params.value || 'Не указана';
            },
          },
          {
            key: 'status',
            field: 'status',
            headerName: 'Статус',
            minWidth: 275,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
              return params.value || 'Не указан';
            },
          },
          {
            key: 'hand',
            field: 'hand',
            headerName: 'Хват',
            minWidth: 275,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
              return params.value || 'Не указан';
            },
          },
        ]}
        hasMaxHeight={false}
        rows={players
          ?.filter((student) => student.hockeyRole === hockeyRole)
          ?.map((student) => {
            return {
              id: student.id,
              player: student,
              birthdayTimestamp: moment(student.birthdayTimestamp).format('DD.MM.YYYY'),
              status: playerStatus[student.status],
              hand: playerHandFullNames[student.hand],
            };
          })}
      />
    );
  };

  return (
    <Wrapper loading={loading}>
      <LocalPreloader visible={loading} />
      {players?.length > 0 ? (
        <>
          {players?.filter((student) => student.hockeyRole === 'winger').length > 0 && (
            <Table table={playersTable('winger')} title="Нападающие" />
          )}
          {players?.filter((student) => student.hockeyRole === 'defenseman').length > 0 && (
            <Table table={playersTable('defenseman')} title="Защитники" />
          )}
          {players?.filter((student) => student.hockeyRole === 'goaltender').length > 0 && (
            <Table table={playersTable('goaltender')} title="Вратари" />
          )}
          {players?.filter((student) => {
            return student.hockeyRole === '' || student.hockeyRole === null;
          }).length > 0 && <Table table={playersTable(null)} title="Без амплуа" />}
        </>
      ) : (
        <TableEmptyBlock text="К команде пока не привязано ни одного игрока." />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: ${(props) => (props.loading ? '80vh' : 'auto')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
  padding: 24px;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;
