import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import * as groupsActions from '../../redux/actions/GroupsActions';
import * as schoolsActions from '../../redux/actions/SchoolsActions';
import * as usersActions from '../../redux/actions/UsersActions';
import MedicalProfilePanel from '../medical-profile/panels/MedicalProfilePanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function MedicalProfileApp() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(schoolsActions.loadAllSchools());
    dispatch(groupsActions.loadAllGroups());
    dispatch(usersActions.loadAllUsers());
  }, []);
  return (
    <ChessTemplate active="index">
      <MedicalProfilePanel />
    </ChessTemplate>
  );
}
