import isObjectEmpty from '@helpers/isObjectEmpty';

export const getKeyByValue = (map, searchValue) => {
  for (let [key, value] of map.entries()) {
    if (value === searchValue) return key;
  }
};

export const formatTableData = (tableData) => {
  if (!tableData) {
    return [];
  }
  return tableData?.map((test) => {
    return {
      id: test.id,
      groupId: test.groupId,
      player: {
        id: test.playerId,
        firstName: test.firstName,
        lastName: test.lastName,
        playerNumber: test.playerNumber,
        avatar: test.avatar,
      },
      psycho: test.psycho,
      energy: test.energy,
      readiness: test.readiness,
      functional: test.functional,
    };
  });
};

export const hideEmptyPlayersInTableData = (isEmptyPlayersHide, tableData) => {
  if (!tableData) {
    return [];
  }
  if (isEmptyPlayersHide) {
    return tableData?.filter((row) => {
      const isAllEmpty = !row.energy.value && !row.functional.value && !row.psycho.value && !row.readiness.value;
      if (!isAllEmpty) {
        return row;
      }
    });
  }
  return tableData;
};

export const hideEmptyPlayersInFunctionalStateDynamic = (isEmptyPlayersHide, tableData) => {
  if (!tableData) {
    return [];
  }
  if (isEmptyPlayersHide) {
    return tableData?.filter((row) => {
      const dates = Object.keys(row).filter((key) => key !== 'player');
      const isAllFunctionalStateEmpty = !dates
        .map((date) => {
          return row[date] === 0;
        })
        .includes(false);
      if (!isAllFunctionalStateEmpty) {
        return row;
      }
    });
  }
  return tableData;
};

export const formatStatesOptions = (statesType) => {
  if (!statesType) {
    return [];
  }
  if (statesType) {
    return Object.keys(statesType)
      .filter((key) => key !== '0')
      .map((key) => {
        return {
          value: key,
          label: statesType[key],
        };
      });
  }
};

export const formatTableDataForSubmitForm = (form) => {
  if (!form || isObjectEmpty(form)) {
    return [];
  }
  return form?.tableData?.map((test) => {
    if (test.id) {
      return {
        id: test.id,
        playerId: test.player.id,
        groupId: test.groupId,
        functional: Number(test?.functional?.value) || 0,
        psycho: Number(test?.psycho?.value) || 0,
        energy: Number(test?.energy?.value) || 0,
        readiness: Number(test?.readiness?.value) || 0,
        date: form.date,
      };
    } else {
      return {
        playerId: test.player.id,
        groupId: test.groupId,
        functional: Number(test?.functional?.value) || 0,
        psycho: Number(test?.psycho?.value) || 0,
        energy: Number(test?.energy?.value) || 0,
        readiness: Number(test?.readiness?.value) || 0,
        date: form.date,
      };
    }
  });
};

export const formatTableDataForSubmitStagedTestingForm = (form) => {
  if (!form || isObjectEmpty(form)) {
    return [];
  }
  return form?.tableData?.map((test) => {
    if (test.id) {
      return {
        id: test.id,
        playerId: test.player.id,
        groupId: test.groupId,
        functional: Number(test?.functional?.value) || Number(test?.functional) || 0,
        date: form.date,
      };
    } else {
      return {
        playerId: test.player.id,
        groupId: test.groupId,
        functional: Number(test?.functional?.value) || Number(test?.functional) || 0,
        date: form.date,
      };
    }
  });
};
