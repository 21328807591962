import {Field, FixedInput, Half, StyledButton} from '@common/ScoutReport/CommonScoutReport';
import {isDateValid} from '@helpers/date';
import {AMPLUA_OPTIONS, DEFAULT_DATE_SEND_FORMAT, HAND_OPTIONS, HOCKEY_ROLES} from '@src/constants';
import DatePickerDefault from '@ui/MUI/DatePicker';
import SelectDefault from '@ui/MUI/Select';
import TextFieldDefault from '@ui/MUI/TextField';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Controller, FormProvider, useForm, useFormState} from 'react-hook-form';

import CancelModal from '../../../../common/ScoutReport/CancelModal';
import OuterPlayerScoreFields from '../../../../common/ScoutReport/OuterPlayerScoreFields';
import ReportFields from '../../../../common/ScoutReport/ReportFields';
import DrawerDefault from '../../../../ui/MUI/Modals/Drawer/Drawer';
import {ButtonsWrapper, Title} from './UserCreateModal';

function UserCreateModal({isOpen, setIsOpen = () => {}, info, onCreate}) {
  const [isCalncelModalVisible, setIsCalncelModalVisible] = useState(false);
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      description: '',
      potential: '',
      roleModel: '',
      strongSide: '',
      weakSide: '',
      championship: '',
      gameCount: null,
      student: {
        birthday: null,
        firstName: '',
        lastName: '',
        hand: '',
        hockeyRole: '',
        playerNumber: '',
      },
    },
  });

  const {control, handleSubmit, reset, getValues} = methods;

  const hockeyRole = getValues('student.hockeyRole');

  const {isDirty} = useFormState({
    control,
  });

  const handleClose = () => (isDirty ? setIsCalncelModalVisible(true) : setIsOpen());

  return (
    <>
      <DrawerDefault
        buttons={
          <ButtonsWrapper>
            <StyledButton
              color="primary"
              size="medium"
              variant="contained"
              onClick={() =>
                handleSubmit((data) => {
                  let result = {...data};
                  if (HOCKEY_ROLES[hockeyRole] !== HOCKEY_ROLES.goaltender) {
                    // eslint-disable-next-line unused-imports/no-unused-vars
                    const {deflected, impenetrability, ...rest} = result;
                    result = rest;
                  }
                  if (HOCKEY_ROLES[hockeyRole] === HOCKEY_ROLES.goaltender) {
                    // eslint-disable-next-line unused-imports/no-unused-vars
                    const {passPoint, goalPoint, ...rest} = result;
                    result = rest;
                  }
                  setIsOpen();
                  onCreate(
                    {
                      ...result,
                      student: {
                        ...result.student,
                        group: info?.group,
                        birthday: moment(result.student.birthday).format(DEFAULT_DATE_SEND_FORMAT),
                      },
                    },
                    () => {
                      reset({keepDirty: false});
                    },
                  );
                })()
              }
            >
              добавить
            </StyledButton>
            <StyledButton color="neutral" size="dark" variant="contained" onClick={handleClose}>
              Отменить
            </StyledButton>
          </ButtonsWrapper>
        }
        content={
          <FormProvider {...methods}>
            <form>
              <Title>Отчет по новому игроку</Title>
              <Field margin="0 0 38px 0">
                {info.school?.name && (
                  <FixedInput maxWidth="160px">
                    <span className="label">Клуб</span>
                    <span className="text">{info.school?.name}</span>
                  </FixedInput>
                )}
                {info.group?.name && (
                  <FixedInput>
                    <span className="label">Год</span>
                    <span className="text">{info.group?.name}</span>
                  </FixedInput>
                )}
              </Field>

              <Field margin="0 0 24px 0">
                <Half>
                  <Controller
                    control={control}
                    name="student.lastName"
                    render={({field: {value, onChange}, fieldState: {error}}) => (
                      <TextFieldDefault
                        error={!!error}
                        helperText={error?.message}
                        inputProps={{maxLength: 40}}
                        label={'Фамилия'}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    rules={{required: 'Заплоните поле'}}
                  />
                </Half>
                <Half>
                  <Controller
                    control={control}
                    name="student.firstName"
                    render={({field: {value, onChange}, fieldState: {error}}) => (
                      <TextFieldDefault
                        error={!!error}
                        helperText={error?.message}
                        inputProps={{maxLength: 40}}
                        label={'Имя'}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    rules={{required: 'Заплоните поле'}}
                  />
                </Half>
              </Field>

              <Field margin="0 0 24px 0">
                <Half>
                  <Controller
                    control={control}
                    name="student.birthday"
                    render={({field: {value, onChange}, fieldState: {error}}) => (
                      <DatePickerDefault
                        error={!!error}
                        helperText={error?.message}
                        label="Дата рождения"
                        mask="__/__/____"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    rules={{
                      required: 'Выберите значение',
                      validate: (value) => {
                        if (!isDateValid(value)) return 'Введите корректную дату';
                      },
                    }}
                  />
                </Half>
                <Half>
                  <Controller
                    control={control}
                    name="student.playerNumber"
                    render={({field: {value, onChange}, fieldState: {error}}) => (
                      <TextFieldDefault
                        error={!!error}
                        helperText={error?.message}
                        inputProps={{maxLength: 2}}
                        label={'№ игрока'}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    rules={{
                      required: 'Заплоните поле',
                      pattern: {
                        value: /^[0-9]\d*(\d+)?$/i,
                        message: 'Введите число',
                      },
                    }}
                  />
                </Half>
              </Field>

              <Field margin="0 0 24px 0">
                <Half>
                  <Controller
                    control={control}
                    name="student.hockeyRole"
                    render={({field: {value, onChange}, fieldState: {error}}) => (
                      <SelectDefault
                        error={!!error}
                        hasFixedLabel={false}
                        helperText={error?.message}
                        label="Амплуа"
                        options={[...AMPLUA_OPTIONS]}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          reset(
                            {
                              ...getValues(),
                              deflected: '',
                              impenetrability: '',
                              goalPoint: '',
                              passPoint: '',
                            },
                            {
                              keepDirty: true,
                            },
                          );
                        }}
                      />
                    )}
                    rules={{required: 'Выберите значение'}}
                  />
                </Half>
                <Half>
                  <Controller
                    control={control}
                    name="student.hand"
                    render={({field: {value, onChange}, fieldState: {error}}) => (
                      <SelectDefault
                        error={!!error}
                        hasFixedLabel={false}
                        helperText={error?.message}
                        label="Хват"
                        options={[...HAND_OPTIONS]}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    rules={{required: 'Выберите значение'}}
                  />
                </Half>
              </Field>
              <OuterPlayerScoreFields hockeyRole={hockeyRole} />
              <ReportFields />
            </form>
          </FormProvider>
        }
        drawerState={isOpen}
        hasFullWidth={'false'}
        setDrawerState={setIsOpen}
        onClose={handleClose}
      />
      <CancelModal
        isVisible={isCalncelModalVisible}
        onCancel={() => setIsCalncelModalVisible(false)}
        onClose={() => {
          reset({keepDirty: false});
          setIsOpen();
          setIsCalncelModalVisible(false);
        }}
      />
    </>
  );
}

UserCreateModal.propTypes = {
  isOpen: PropTypes.object,
  setIsOpen: PropTypes.func,
  info: PropTypes.object,
  onCreate: PropTypes.func,
};

export default UserCreateModal;
