import Field from '@common/FormField';
import {getOptionLabel, isMaterialOptionEqualToValue} from '@components/apps/MaterialsModule/helpers';
import {useMaterialsTypes} from '@components/apps/MaterialsModule/hooks';
import Grid from '@ui/MUI/Grid';
import React, {memo} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import GroupSelect from '../GroupSelect';
import SchoolSelect from '../SchoolSelect';

const MainContent = () => {
  const materialsTypes = useMaterialsTypes();
  const {setValue, resetField} = useFormContext();
  const type = useWatch({name: 'type'});
  const category = useWatch({name: 'category'});
  const tags = useWatch({name: 'tags'});
  const categoryTags = category?.tags || tags || [];

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} xxs={12}>
        <Field
          isRequired
          getOptionLabel={getOptionLabel}
          isLoading={materialsTypes?.isLoading}
          isOptionEqualToValue={isMaterialOptionEqualToValue}
          label="Тип материала"
          multiple={false}
          name="type"
          options={materialsTypes?.data}
          onChange={() => {
            resetField('storage');
            resetField('link');
          }}
        />
      </Grid>
      {type && (
        <>
          <Grid isRequired item xs={6} xxs={12}>
            <Field
              isRequired
              getOptionLabel={getOptionLabel}
              isLoading={materialsTypes.isLoading}
              isOptionEqualToValue={isMaterialOptionEqualToValue}
              label="Тема материала"
              multiple={false}
              name="category"
              options={type?.categories || []}
              onChange={() => setValue('tags', [])}
            />
          </Grid>
          {categoryTags.length !== 0 && (
            <Grid item xxs={12}>
              <Field
                multiple
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isMaterialOptionEqualToValue}
                label="Теги"
                name="tags"
                options={categoryTags}
              />
            </Grid>
          )}
          <Grid item xs={6} xxs={12}>
            <SchoolSelect />
          </Grid>
          <Grid item xs={6} xxs={12}>
            <GroupSelect />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default memo(MainContent);
