import LoadingButtonMui from '@mui/lab/LoadingButton';
import {LoadingButtonProps} from '@mui/lab/LoadingButton/LoadingButton';
import {Theme, useMediaQuery} from '@mui/material';
import React from 'react';

export default function LoadingButton(props: LoadingButtonProps) {
  const isSmResolution = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const {
    variant = 'outlined',
    size = isSmResolution ? 'small' : 'medium',
    color = 'secondary',
    children,
    disableElevation = true,
  } = props;
  return (
    <LoadingButtonMui
      {...props}
      color={color === 'secondary' && variant === 'text' ? 'secondaryInverse' : color}
      disableElevation={disableElevation}
      size={size}
      variant={variant}
    >
      {children}
    </LoadingButtonMui>
  );
}
