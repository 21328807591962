/**
 * Created by iam_r on 10.10.20.
 */

import * as types from '../ActionTypes.js';

const initialState = {
  loading: false,
  error: undefined,
  medicalProfileRecommendation: {
    comment: '',
  },
};

const MedicalProfileReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.MEDICAL_PROFILE_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case types.MEDICAL_PROFILE_LOADING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.MEDICAL_PROFILE_LOADING_FORM:
      return {
        ...state,
        loading: false,
        error: undefined,
        medicalProfileForm: {...action.medicalProfileForm},
      };
    case types.MEDICAL_PROFILE_LOADING_STAGES:
      return {
        ...state,
        loading: false,
        error: undefined,
        medicalProfileStages: [...action.medicalProfileStages],
      };
    case types.MEDICAL_PROFILE_LOADING_USER_DATA:
      return {
        ...state,
        loading: false,
        error: undefined,
        medicalProfileUserData: action.medicalProfileUserData,
        medicalProfileFiltersPlayers: action.medicalProfileFiltersPlayers,
      };
    case types.MEDICAL_PROFILE_GET_RECOMMENDATIONS:
    case types.MEDICAL_PROFILE_GET_RECOMMENDATIONS_FOR_CARD:
    case types.MEDICAL_PROFILE_CHANGE_RECOMMENDATION:
    case types.MEDICAL_PROFILE_SAVE_RECOMMENDATION:
      return {
        ...state,
        loading: false,
        error: undefined,
        medicalProfileRecommendation: action.medicalProfileRecommendation,
      };

    case types.MEDICAL_PROFILE_CHANGE_BOARD:
      return {
        ...state,
        loading: false,
        error: undefined,
        medicalProfileBoard: {...action.medicalProfileBoard},
      };
    case types.MEDICAL_PROFILE_CHANGE_FORM:
      return {
        ...state,
        loading: false,
        error: undefined,
        medicalProfileForm: {...action.medicalProfileForm},
      };
    case types.MEDICAL_PROFILE_CLEAN_FORM:
      return {
        ...state,
        loading: false,
        error: undefined,
        medicalProfileForm: {...action.medicalProfileBoard},
      };
    case types.MEDICAL_PROFILE_HISTORY_LOADING:
      return {
        ...state,
        loading: false,
        error: undefined,
        cardHistory: [...action.cardHistory],
      };
    case types.BMI_LOADING_FORM:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case types.BMI_LOADING_REPORT:
      return {
        ...state,
        loading: false,
        error: undefined,
        BMIReport: [...action.BMIReport],
        BMIReportForm: [...action.BMIReport],
      };
    case types.BMI_FORM_CHANGE:
      return {
        ...state,
        loading: false,
        error: undefined,
        BMIReportForm: [...action.BMIReportForm],
      };
    case types.TEAM_NUTRITION_LOADING:
      return {
        ...state,
        loading: false,
        error: undefined,
        teamNutrition: action.teamNutrition,
      };
    default:
      return state;
  }
};

export default MedicalProfileReducer;
