import ExerciseItem from '@common/TrainingControl/ExerciseItem';
import Box from '@components/Box';
import useStore from '@hooks/useStore';
import ButtonDefault from '@ui/MUI/Button';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import Stack from '@ui/MUI/Stack';
import React from 'react';

const ExerciseModal = ({isToggle, off}) => {
  const exercise = useStore((state) => state.trainingControlProcess.tempData);
  const {repeat, time, r, ...rest} = exercise || {};
  return (
    <DrawerDefault
      buttons={
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Box />
          <ButtonDefault disableElevation variant="contained" onClick={off}>
            закрыть
          </ButtonDefault>
        </Stack>
      }
      content={<ExerciseItem {...rest} border="none" p={0} />}
      isOpen={isToggle}
      setDrawerState={off}
    />
  );
};

export default ExerciseModal;
