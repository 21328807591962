import React from 'react';

function NotificationError() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M6.99992 3.66671C6.82311 3.66671 6.65354 3.73695 6.52851 3.86197C6.40349 3.98699 6.33325 4.15656 6.33325 4.33337V7.00004C6.33325 7.17685 6.40349 7.34642 6.52851 7.47145C6.65354 7.59647 6.82311 7.66671 6.99992 7.66671C7.17673 7.66671 7.3463 7.59647 7.47132 7.47145C7.59635 7.34642 7.66659 7.17685 7.66659 7.00004V4.33337C7.66659 4.15656 7.59635 3.98699 7.47132 3.86197C7.3463 3.73695 7.17673 3.66671 6.99992 3.66671ZM6.99992 9.00004C6.86806 9.00004 6.73917 9.03914 6.62954 9.11239C6.51991 9.18565 6.43446 9.28977 6.384 9.41158C6.33354 9.5334 6.32034 9.66745 6.34606 9.79677C6.37179 9.92609 6.43528 10.0449 6.52851 10.1381C6.62175 10.2313 6.74054 10.2948 6.86986 10.3206C6.99918 10.3463 7.13322 10.3331 7.25504 10.2826C7.37686 10.2322 7.48098 10.1467 7.55423 10.0371C7.62749 9.92745 7.66659 9.79856 7.66659 9.66671C7.66659 9.4899 7.59635 9.32033 7.47132 9.1953C7.3463 9.07028 7.17673 9.00004 6.99992 9.00004ZM13.4733 4.04004L9.95992 0.526707C9.83216 0.404885 9.6631 0.33583 9.48659 0.333374H4.51325C4.33674 0.33583 4.16767 0.404885 4.03992 0.526707L0.526585 4.04004C0.404763 4.1678 0.335708 4.33686 0.333252 4.51337V9.48671C0.335708 9.66322 0.404763 9.83229 0.526585 9.96004L4.03992 13.4734C4.16767 13.5952 4.33674 13.6643 4.51325 13.6667H9.48659C9.6631 13.6643 9.83216 13.5952 9.95992 13.4734L13.4733 9.96004C13.5951 9.83229 13.6641 9.66322 13.6666 9.48671V4.51337C13.6641 4.33686 13.5951 4.1678 13.4733 4.04004ZM12.3333 9.20671L9.20659 12.3334H4.79325L1.66659 9.20671V4.79337L4.79325 1.66671H9.20659L12.3333 4.79337V9.20671Z"
          fill="#990011"
        />
      </g>
    </React.Fragment>
  );
}
export default NotificationError;
