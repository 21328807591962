import date from '@helpers/date';

export function getDuration(item) {
  let duration = '00:00';
  if (item?.timeStart && item?.timeEnd) {
    const start = date(new Date('01.01.1990 ' + '00:' + item.timeStart));
    const end = date(new Date('01.01.1990 ' + '00:' + item.timeEnd));
    if (start.isValid() && end.isValid()) {
      duration = date(end.valueOf() - start.valueOf()).format('mm:ss');
    }
  }
  return duration;
}
