import LoadingBox from '@common/LoadingBox';
import ReactTableComponent from '@common/ReactTableComponent';
import {WrapperTableContainer} from '@components/apps/PhysicalTesting/components/PlayerProgress/components/PlayerProgressTable/PlayerProgressTable';
import {mock} from '@components/apps/PhysicalTesting/components/PlayerProgress/mock';
import {ofpTestsExercisesOrdersMatrix, sfpTestsExercisesOrdersMatrix} from '@components/apps/PhysicalTesting/constants';
import {grey} from '@mui/material/colors';
import {longDash} from '@src/constants';
import {Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

const PlayerProgressTable = ({tableData, isLoading}) => {
  const seasons = Object.keys(tableData);
  const columns = (isLoading ? mock.seasons : seasons)?.map((key) => {
    return {
      name: key,
      header: () => (
        <Typography
          fontWeight={600}
          isLoading={isLoading}
          preloaderWidthBetween={[70, 80]}
          sx={{zIndex: 1, display: 'flex', justifyContent: 'center'}}
          variant="caption1"
        >
          {key}
        </Typography>
      ),
      accessorKey: key,
      headerStyle: {
        textAlign: 'center',
        height: 'auto !important',
      },
      columns: [
        {
          accessorKey: `${key}.stage1`,
          header: (
            <Typography
              color={grey['68']}
              fontWeight="600"
              isLoading={isLoading}
              preloaderWidthBetween={[50, 60]}
              variant="caption1"
            >
              Этап 1
            </Typography>
          ),
          headerStyle: {
            height: 'auto !important',
            boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset !important',
            textAlign: 'center',
          },
          cellStyle: {height: 'auto !important', boxShadow: 'none', textAlign: 'center'},
          accessorFn: ({params}) => {
            return params;
          },
          cell: (info) => {
            return (
              <Typography isLoading={isLoading} preloaderWidthBetween={[20, 30]} variant="body2">
                {info.getValue().find((item) => item.season === key)?.stage1 || longDash}
              </Typography>
            );
          },
        },
        {
          accessorKey: `${key}.stage2`,
          header: (
            <Typography
              color={grey['68']}
              fontWeight="600"
              isLoading={isLoading}
              preloaderWidthBetween={[50, 60]}
              variant="caption1"
            >
              Этап 2
            </Typography>
          ),
          headerStyle: {
            height: 'auto !important',
            boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset !important',
            textAlign: 'center',
          },
          cellStyle: {height: 'auto !important', boxShadow: 'none', textAlign: 'center'},
          accessorFn: ({params}) => {
            return params;
          },
          cell: (info) => {
            return (
              <Typography isLoading={isLoading} preloaderWidthBetween={[20, 30]} variant="body2">
                {info.getValue().find((item) => item.season === key)?.stage2 || longDash}
              </Typography>
            );
          },
        },
        {
          accessorKey: `${key}.stage3`,
          header: (
            <Typography
              color={grey['68']}
              fontWeight="600"
              isLoading={isLoading}
              preloaderWidthBetween={[50, 60]}
              variant="caption1"
            >
              Этап 3
            </Typography>
          ),
          headerStyle: {
            height: 'auto !important',
            boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset !important',
            textAlign: 'center',
          },
          cellStyle: {height: 'auto !important', boxShadow: 'none', textAlign: 'center'},
          accessorFn: ({params}) => {
            return params;
          },
          cell: (info) => {
            return (
              <Typography isLoading={isLoading} preloaderWidthBetween={[20, 30]} variant="body2">
                {info.getValue().find((item) => item.season === key)?.stage3 || longDash}
              </Typography>
            );
          },
        },
        {
          accessorKey: `${key}.avg`,
          header: (
            <Typography
              color={grey['68']}
              fontWeight="600"
              isLoading={isLoading}
              preloaderWidthBetween={[50, 60]}
              variant="caption1"
            >
              Среднее
            </Typography>
          ),
          headerStyle: {
            height: 'auto !important',
            boxShadow: 'rgb(224, 224, 224) -1px -1px 0px 0px inset !important',
            textAlign: 'center',
          },
          cellStyle: {
            height: 'auto !important',
            boxShadow: 'rgb(224, 224, 224) -1px 0px 0px 0px inset !important',
            textAlign: 'center',
          },
          accessorFn: ({params}) => {
            return params;
          },
          cell: (info) => {
            return (
              <Typography isLoading={isLoading} preloaderWidthBetween={[20, 30]} variant="body2">
                {info.getValue().find((item) => item.season === key)?.avg || longDash}
              </Typography>
            );
          },
        },
      ],
    };
  });
  const formattedTableData = Object.values(tableData)?.flat();
  const exercises = formattedTableData?.reduce((a, c) => (a.map((e) => e.name).includes(c.name) || a.push(c), a), []);
  const sortedExercises = exercises
    ?.map((ex) => ({
      ...ex,
      order: ofpTestsExercisesOrdersMatrix[ex?.exercise] || sfpTestsExercisesOrdersMatrix[ex?.exercise],
    }))
    .sort((a, b) => a.order - b.order);
  const data = sortedExercises?.map((exercise) => {
    return {
      name: exercise.name,
      params: formattedTableData
        .filter((item) => item.name === exercise.name)
        .map((item) => {
          return {...item, id: exercise.id};
        }),
    };
  });
  return (
    <>
      <WrapperTableContainer canEdit={false}>
        <LoadingBox isLoading={isLoading}>
          <ReactTableComponent
            isAvailableHeight
            columns={[
              {
                header: 'Упражнение',
                id: 'exercise',
                enableRowSpan: true,
                sticky: {left: true},
                headerStyle: {textAlign: 'left !important'},
                columns: [
                  {
                    header: 'Not visible header',
                    accessorFn: (params) => {
                      return params.name;
                    },
                    headerStyle: {
                      display: 'none !important',
                    },
                    cellStyle: {height: 'auto !important'},
                    cell: ({getValue}) => {
                      return (
                        <Typography isLoading={isLoading} preloaderWidthBetween={[150, 170]} variant="subtitle2">
                          {getValue()}
                        </Typography>
                      );
                    },
                    sticky: {left: true},
                  },
                ],
                rowSpan: '2',
              },
              ...columns,
            ]}
            data={isLoading ? mock.data : data}
            tableBodyCellProps={{
              sx: {
                py: {xxs: 2, lg: 3},
                px: {xxs: 2, lg: 4},
                '&.lastRowCell': {
                  boxShadow: 'none',
                },
              },
            }}
            tableHeaderCellProps={{
              sx: {
                py: {xxs: '0', lg: 3},
                px: {xxs: 2, lg: 4},
              },
            }}
          />
        </LoadingBox>
      </WrapperTableContainer>
    </>
  );
};

PlayerProgressTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default PlayerProgressTable;
