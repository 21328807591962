import Box from '@ui/MUI/Box';
import {grey, primary} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import styled from 'styled-components';

export const FieldWrapper = styled.div`
  position: relative;

  ${({theme}) => ({
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '300px',
      margin: '0 auto',
    },
  })};
  &::before,
  &::after {
    position: absolute;
    top: 2px;
    content: '';
    height: calc(100% - 4px);
    width: 37.2%;
    filter: opacity(0.5);
    z-index: 4;
  }
  &::before {
    border-radius: 15.8% 0 0 14.8% / 10.7% 0 0 10.7%;
    left: 2px;
    background-color: ${({weathome}) => (!weathome ? primary['10'] : grey['14'])};
  }
  &::after {
    border-radius: 0 15.8% 14.8% 0 / 0 10.7% 10.7% 0;
    right: 2px;
    background-color: ${({weathome}) => (weathome ? primary['10'] : grey['14'])};
  }
  ${({theme}) => theme.breakpoints.down('xs')} {
    &::before,
    &::after {
      width: calc(100% - 4px);
      height: 37.2%;
    }
    &::before {
      border-radius: 19.8% 17.7% 0 0 / 18.8% 16.7% 0 0;
      left: 2px;
      top: 2px;
    }
    &::after {
      border-radius: 0 0 13% 14.7% / 0 0 23% 22.7%;
      left: 2px;
      top: unset;
      bottom: 2px;
    }
  }
`;

export const FieldImgBackground = styled.img`
  width: 100%;
  display: block;
  transition: 0.3s ease;
  filter: ${({nodata}) => `blur(${nodata ? 3 : 0}px)`};
`;

export const Chart = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  display: flex;
  padding: 5.7% 8.9% 5.7%;
  margin-bottom: ${({theme}) => theme.spacing(4)};
  ${({theme}) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    justify-content: space-between;
    padding: 18.4% 11.9% 18.4%;
  }
`;

export const Start = styled.div`
  display: flex;
  width: 34.5%;
  justify-content: space-between;
  flex-direction: column;
  ${({theme}) => theme.breakpoints.down('xs')} {
    width: 100%;
    height: 34.5%;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
`;
export const Middle = styled.div`
  display: flex;
  width: 31%;
  justify-content: center;
  flex-direction: column;
  ${({theme}) => theme.breakpoints.down('xs')} {
    width: 100%;
    height: 31%;
    justify-content: space-around;
    flex-direction: row-reverse;
  }
`;
export const End = styled.div`
  display: flex;
  flex-direction: column;
  width: 34.5%;
  justify-content: space-between;
  align-items: flex-end;
  ${({theme}) => theme.breakpoints.down('xs')} {
    width: 100%;
    height: 34.5%;
    flex-direction: row-reverse;
  }
`;

export const Circle = styled(Box)`
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  aspect-ratio: 1/1;
  opacity: ${({loading}) => (loading ? 0 : 1)};
  transition: 0.3s ease;
  background-color: ${({colors, score}) => {
    if (score?.[0] < score?.[1]) {
      return colors[0];
    }
    if (score?.[0] > score?.[1]) {
      return colors[1];
    }
    return grey['24'];
  }};
  & .MuiTypography-root {
    order: 1;
    position: absolute;
    color: #ffffff;
    padding: 0 ${({theme}) => theme.spacing(0)};
    border-radius: 2px;
    line-height: 16px;
    background-color: ${({score}) => (score?.[0] < score?.[1] ? primary['50'] : grey['38'])};
    left: 50%;
    top: calc(50% - 34px);
    transform: translate(-50%, -50%);
    margin-bottom: 4px;
    ${({theme}) => theme.breakpoints.down('sm')} {
      margin-bottom: 0;
    }
  }
  &::after {
    order: 2;
    position: absolute;
    content: '${({score}) => (score?.[2] || 0) + '%'}';
    color: #fff;
    text-align: center;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family:
      Proxima Nova,
      serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    left: 50%;
    top: calc(50% - 10px);
    transform: translate(-50%, -50%);
    ${({theme}) => theme.breakpoints.down('sm')} {
      font-size: 14px;
      line-height: 18px;
    }
  }
  &::before {
    order: 3;
    position: absolute;
    text-align: center;
    font-family:
      Bebas Neue,
      serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    color: #fff;
    content: '${({score}) => score?.slice(0, 2)?.join('-') || ''}';
    left: 50%;
    top: calc(50% + 10px);
    transform: translate(-50%, -50%);
    white-space: nowrap;
    ${({theme}) => theme.breakpoints.down('sm')} {
      font-size: 14px;
      line-height: 16px;
    }
  }
  ${({theme}) => theme.breakpoints.down('smallLaptop')} {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    & .MuiTypography-root,
    &::before,
    &::after {
      position: relative;
      left: unset;
      top: unset;
      transform: none;
    }
  }
`;

export const ZoneLabels = styled(Stack)`
  position: absolute;
  width: 100%;
  bottom: -${({theme}) => theme.spacing(4)};
  left: 0;
  z-index: 0;
  ${({theme}) => theme.breakpoints.down('xs')} {
    height: 109%;
  }
`;
