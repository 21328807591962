import {FUNCTIONAL_STATE_INDICATORS_MATRIX} from '@components/apps/FunctionalTesting/constants';
import React from 'react';
import styled from 'styled-components';

export default function Table(props) {
  const {table = React.ReactNode, children} = props;

  return (
    <TableWrapper>
      <TableContainer>{table || children}</TableContainer>
    </TableWrapper>
  );
}

const TableContainer = styled.div`
  position: relative;
  margin-right: -24px;
  margin-left: -24px;
`;
const TableWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const TestingDate = styled.p`
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
`;

export const FunctionalStateIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-right: 6px solid
    ${(props) =>
      FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.length > 1
        ? FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[1]
        : FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[0]};
  border-top: 6px solid
    ${(props) =>
      FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.length > 1
        ? FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[1]
        : FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[0]};
  border-left: 6px solid ${(props) => FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[0]};
  border-bottom: 6px solid ${(props) => FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[0]};
  transform: translate(-50%, -50%) rotate(45deg) !important;
`;

export const CellContainer = styled.div`
  margin-left: ${({theme}) => theme.spacing(6)};
  ${({theme}) => theme.breakpoints.down('sm')} {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
`;
