import Stack from '@ui/MUI/Stack';
import React, {memo} from 'react';

import List from '../../components/List';
import Preview from '../../components/Preview';
import {useMaterials} from '../../hooks';
import {PageBox} from '../../styles';
import LoadingSkeleton from './skeleton';

const CategoryTab = () => {
  const {data, isLoading} = useMaterials();
  return (
    <PageBox>
      <Preview />
      <Stack spacing={8}>
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          data.map((section) => {
            return (
              <List
                count={section.count}
                key={section.slug}
                materials={section.materials}
                name={section.name}
                type={section.slug}
              />
            );
          })
        )}
      </Stack>
    </PageBox>
  );
};

export default memo(CategoryTab);
