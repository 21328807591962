import React from 'react';
import styled from 'styled-components';
/**@deprecated не использовать этот компонент**/
export default function InputDefault(props) {
  return (
    <>
      <Input
        {...props}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
      {props.isInvalid && <ErrorText className="ErrorText">{props.errorText}</ErrorText>}
    </>
  );
}

const DEFAULT_INPUT_HEIGHT = 40;
const DEFAULT_INPUT_FONT_SIZE = 15;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: ${DEFAULT_INPUT_HEIGHT}px;
  line-height: ${DEFAULT_INPUT_HEIGHT}px;
  border: ${(props) => (props.isInvalid ? '1px solid #FF0000 !important' : '1px solid #ccc')};
  border-radius: 5px;
  outline: none;
  padding-left: 8px;
  font-family: 'Proxima Nova';
  font-size: ${DEFAULT_INPUT_FONT_SIZE}px;
  color: #2c2c2c;
  background-color: #fff;
  :focus {
    border-color: #c4c4c4;
  }
  :hover {
    border-color: #c4c4c4;
  }
  ::placeholder {
    font: normal normal 12px/25px 'Proxima Nova';
    color: #c4c4c4;
    border: none !important;
  }
  .invalid {
    border: red;
  }
`;
const ErrorText = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  color: #ff0000;
`;
