import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const PhysicalTestingAPI = {
  getPhysicalTestingStagesForComparison(groupId) {
    return axios.get(`${API_ENDPOINT}/v1/physical-testing/stages`, {params: {groupId}});
  },
};

export function getPlayerRang(playerId) {
  return axios.get(`${API_ENDPOINT}/v1/physical-testing/${playerId}/rang`);
}

export default PhysicalTestingAPI;
