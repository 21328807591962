import React from 'react';
import styled from 'styled-components';

import {Button, CloseButton} from './Button';
/**@deprecated не использовать этот компонент**/
export default function TableDefault(props) {
  return (
    <Wrapper className={props.className ? `TableDefaultContainer ${props.className}` : 'TableDefaultContainer'}>
      <Container className={'container'}>
        {(props.title || props.headlineTitleContent || props.oldHeadlineContent) && (
          <HeadLine className={'Headline'}>
            <HeadLineTitleContainer className={'HeadlineTitleContainer'}>
              {props.headLineContent ? (
                props.headLineContent
              ) : (
                <HeadLineTitle className={'HeadlineTitle'}>
                  {props.title && <HeadLineTitleText className={'HeadlineTitleText'}>{props.title}</HeadLineTitleText>}
                  <HeadLineTitleContent className={'HeadLineTitleContent'}>
                    {props.headlineTitleContent}
                  </HeadLineTitleContent>
                </HeadLineTitle>
              )}
            </HeadLineTitleContainer>
            {props.oldHeadlineContent && (
              <HeadLineContent className={'HeadLineContent'}>{props.oldHeadlineContent}</HeadLineContent>
            )}
          </HeadLine>
        )}
        <TableBody
          className={'TableBody'}
          isScrollable={props.isScrollable}
          withButtons={props.onSave || props.withSave}
          withOutOverFlow={props.withOutOverFlow}
        >
          {props.content}
        </TableBody>
        {(props.onSave || props.withSave) && !props.withOutButtons && (
          <TableBottom>
            <Button type="submit" onClick={props.onSave}>
              {props.saveButtonText || 'Сохранить'}
            </Button>
            {props.hideCloseButton ? null : (
              <CloseButton type="button" onClick={props.onClose}>
                {props.closeButtonText || 'Закрыть'}
              </CloseButton>
            )}
          </TableBottom>
        )}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
`;

const TableBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  height: 60px;
  padding: 0 24px;
  box-shadow: 0 -9px 10px -7px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;

  ${Button} {
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }

  ${CloseButton} {
    width: 120px;
  }
`;

const Container = styled.div``;
const HeadLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 34px;
  min-height: 60px;
  background: #fffaed;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.3);
`;
const HeadLineTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeadLineTitleText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 16px/20px 'Proxima Nova';
  text-transform: uppercase;
`;
const HeadLineTitleContainer = styled.div`
  width: 100%;
`;
const HeadLineTitleContent = styled.div``;
const HeadLineContent = styled.div``;
const TableBody = styled.div`
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: ${(props) => !props.withButtons && '0 0 6px 6px'};
  overflow: ${(props) => (props.isScrollable && 'scroll') || (!props.withOutOverFlow && 'hidden')};
`;
