import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {LoadingButton} from '@mui/lab';
import {createTrainingControl, fetcherTrainingControl, updateTrainingControl} from '@slices/trainingControlProcess';
import ButtonDefault from '@ui/MUI/Button';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React, {memo} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import MainContent from './components/MainContent';
import Skills from './components/Skills';

const formatBeforeSend = (data) => {
  const ages = data.ages?.map((item) => item?.id) || [];
  const section = data.section.id;
  const category = data.category.id;
  const number = Number(data.number);
  return {...data, category, section, ages, number};
};
const NAME = 'skill';
const send = (onSuccess, data, isEdit) => {
  const tempData = {
    name: NAME,
    id: data.id,
    onSuccess,
    payload: {
      ...data,
      skills: data?.skills?.map(formatBeforeSend),
    },
    skipFetch: true,
  };

  return isEdit ? updateTrainingControl(tempData) : createTrainingControl(tempData);
};

const SkillsForm = ({isOpen, onSubmitClose, onClose, age, isConfirmModalVisible, setIsConfirmModalVisible}) => {
  const {
    getValues,
    formState: {isDirty},
  } = useFormContext();

  const isEdit = Boolean(getValues('id'));
  const fields = useWatch({name: 'skills'});

  const isLoading = useStore((state) => state.trainingControlProcess.skill.form.isLoading);

  const dispatch = useStoreDispatch();

  const canSubmit = isEdit ? isDirty : !!fields?.length;

  const handleSubmit = () => {
    const data = getValues();
    dispatch(
      send(
        () => {
          dispatch(fetcherTrainingControl(NAME, {age}));
        },
        formatBeforeSend(data),
        isEdit,
      ),
    );
    onSubmitClose && onSubmitClose();
  };

  return (
    <DrawerDefault
      buttons={
        <Stack direction="row" justifyContent="space-between">
          <LoadingButton
            color="primary"
            disabled={!canSubmit}
            loading={isLoading}
            variant="contained"
            onClick={handleSubmit}
          >
            {isEdit ? 'Сохранить' : 'добавить'}
          </LoadingButton>
          <ButtonDefault
            disableElevation
            variant="contained"
            onClick={() => setIsConfirmModalVisible(!isConfirmModalVisible)}
          >
            Отменить
          </ButtonDefault>
        </Stack>
      }
      content={
        <>
          <TypographyDefault mb={2} variant="h3">
            {isEdit ? 'Редактирование навыка' : 'Создание навыка'}
          </TypographyDefault>
          <Stack spacing={4}>
            <MainContent age={age} isEdit={isEdit} />
            {!isEdit && <Skills />}
          </Stack>
        </>
      }
      isOpen={isOpen}
      setDrawerState={onClose}
    />
  );
};

export default memo(SkillsForm);
