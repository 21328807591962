import React from 'react';

function Delete() {
  return (
    <React.Fragment>
      <g clipPath="url(#clip0)">
        <path
          d="M9.56906 5.43433C9.37503 5.43433 9.21777 5.59158 9.21777 5.78561V12.425C9.21777 12.6189 9.37503 12.7762 9.56906 12.7762C9.76309 12.7762 9.92035 12.6189 9.92035 12.425V5.78561C9.92035 5.59158 9.76309 5.43433 9.56906 5.43433Z"
          fill="#2C2C2C"
        />
        <path
          d="M5.42355 5.43433C5.22952 5.43433 5.07227 5.59158 5.07227 5.78561V12.425C5.07227 12.6189 5.22952 12.7762 5.42355 12.7762C5.61759 12.7762 5.77484 12.6189 5.77484 12.425V5.78561C5.77484 5.59158 5.61759 5.43433 5.42355 5.43433Z"
          fill="#2C2C2C"
        />
        <path
          d="M2.40299 4.46528V13.1202C2.40299 13.6318 2.59057 14.1122 2.91825 14.4569C3.24443 14.8026 3.69836 14.9988 4.17342 14.9996H10.8199C11.2951 14.9988 11.749 14.8026 12.075 14.4569C12.4027 14.1122 12.5903 13.6318 12.5903 13.1202V4.46528C13.2417 4.29238 13.6638 3.66308 13.5767 2.99468C13.4894 2.32641 12.9201 1.82651 12.246 1.82637H10.4475V1.38726C10.4495 1.018 10.3035 0.66342 10.0421 0.402562C9.7807 0.141841 9.42557 -0.00333893 9.05631 -0.000320055H5.93699C5.56772 -0.00333893 5.2126 0.141841 4.95119 0.402562C4.68978 0.66342 4.54378 1.018 4.54584 1.38726V1.82637H2.74727C2.07324 1.82651 1.50391 2.32641 1.41664 2.99468C1.3295 3.66308 1.7516 4.29238 2.40299 4.46528ZM10.8199 14.2971H4.17342C3.5728 14.2971 3.10556 13.7811 3.10556 13.1202V4.49615H11.8877V13.1202C11.8877 13.7811 11.4205 14.2971 10.8199 14.2971ZM5.24841 1.38726C5.24608 1.20435 5.31798 1.02829 5.44779 0.899167C5.57747 0.770041 5.75393 0.699098 5.93699 0.702254H9.05631C9.23936 0.699098 9.41583 0.770041 9.5455 0.899167C9.67531 1.02815 9.74722 1.20435 9.74488 1.38726V1.82637H5.24841V1.38726ZM2.74727 2.52895H12.246C12.5952 2.52895 12.8783 2.81203 12.8783 3.16126C12.8783 3.51049 12.5952 3.79358 12.246 3.79358H2.74727C2.39805 3.79358 2.11496 3.51049 2.11496 3.16126C2.11496 2.81203 2.39805 2.52895 2.74727 2.52895Z"
          fill="#2C2C2C"
        />
        <path
          d="M7.49679 5.43433C7.30276 5.43433 7.14551 5.59158 7.14551 5.78561V12.425C7.14551 12.6189 7.30276 12.7762 7.49679 12.7762C7.69082 12.7762 7.84807 12.6189 7.84807 12.425V5.78561C7.84807 5.59158 7.69082 5.43433 7.49679 5.43433Z"
          fill="#2C2C2C"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="15" width="15" />
        </clipPath>
      </defs>
    </React.Fragment>
  );
}
export default Delete;
