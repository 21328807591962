import EstimateControlDate from '@common/DevelopmentJournal/components/EstimateControlDate';
import {getFullName} from '@common/DevelopmentJournal/helpers';
import {longDash} from '@src/constants';
import React from 'react';

export const columns = [
  {
    header: 'Специалист',
    accessorFn: (row) => (row?.specialists?.length ? getFullName(row?.specialists) : longDash),
  },
  {
    header: 'Что должен сделать игрок',
    accessorFn: (row) => row.todo,
  },
  {
    header: 'План работы с игроком',
    accessorFn: (row) => row.plan,
  },
  {
    header: 'Срок выполнения',
    accessorFn: (row) => row,
    cell: (info) => {
      const value = info.getValue();
      return <EstimateControlDate date={value.controlDate} />;
    },
  },
];
