import {useGroup, useSchool} from '@hooks/filters';
import {useSeasons} from '@hooks/seasonsHooks';
import {Grid} from '@mui/material';
import {userRoles} from '@src/constants';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import React, {useEffect} from 'react';

import {Filter} from './EditStatsFilter';

const EditStatsFilter = ({filters, onChange}) => {
  const school = useSchool({allSchoolsPermissionRoles: [userRoles.video_analyst]});
  const group = useGroup({connectorValue: filters?.school?.value});
  const needShortSeasons = true;
  const seasons = useSeasons(needShortSeasons, ({currentSeason}) => handleChange({}, currentSeason, 'season'));

  function handleChange(_, v, name) {
    onChange({...filters, [name]: v});
  }

  useEffect(() => {
    school?.value && onChange({...filters, school: school?.value});
  }, [school?.value?.value]);

  return (
    <Filter mt={1} px={{xxs: 2, sm: 4}} py={{xxs: 1, sm: 3}}>
      <Grid container spacing={{xxs: 2, xs: 4}}>
        <Grid item md={3} minWidth={{xxs: '100%', xs: '200px'}}>
          <AutocompleteDefault
            isLoading={school.isLoading}
            label="Школа"
            multiple={false}
            name="school"
            options={school.options}
            value={filters.school}
            onChange={(_, v) => {
              onChange({...filters, group: null, school: v});
            }}
          />
        </Grid>
        <Grid item md={3} minWidth={{xxs: '100%', xs: '200px'}}>
          <AutocompleteDefault
            disabled={!filters?.school?.value}
            isLoading={group.isLoading}
            label="Команда"
            multiple={false}
            name="group"
            options={group.options}
            value={filters.group}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={2} minWidth={{xxs: '100%', xs: '100px'}}>
          <AutocompleteDefault
            label="Сезон"
            multiple={false}
            name="season"
            options={seasons?.options}
            value={filters.season}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default EditStatsFilter;
