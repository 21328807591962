import React from 'react';

import GroupsPanel from '../groups/panels/GroupsPanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function GroupsApp() {
  return (
    <ChessTemplate active="teams">
      <GroupsPanel />
    </ChessTemplate>
  );
}
