import {
  getAllMKCTournaments,
  getCommonTab,
  getFiltersMatches,
  getFiltersTournaments,
  getGamesCards,
  getOpponentPlayersStat,
  getOpponentTeamList,
  getPastGames,
  getPlayersTables,
  getPlayerStatPlayTime,
  getPlayerTournaments,
  getPlayerTournamentStat,
  getTableTabNextGames,
  getTableTabTable,
} from '@api/mkcStatisticsAPI';
import {getActions, getActionsTypes, getPlayersStatistics} from '@api/StatsAPI';
import {getGames} from '@api/TournamentsAPI';
import {actionButtonsMock} from '@common/Statistics/ActionsMap/Buttons/mock';
import {mock as matchesMock} from '@components/apps/StatisticsWithMkcUral/components/MatchesFilter/mock';
import date from '@helpers/date';
import {actionsGetter, defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {createSlice} from '@reduxjs/toolkit';
import {goaltenderReducer, goaltendersInitialState, goaltendersStatePaths} from '@slices/statisticsMKC/goaltenders';
import {
  metricStatisticsReducer,
  shotsInitialState,
  shotsMapActionsButtons,
  shotsMapReducer,
  shotsMapStatePaths,
} from '@slices/statisticsMKC/shotsMap';
import {
  throwinsInitialState,
  throwinsMapReducer,
  throwinsMetricsReducer,
  throwinsStatePaths,
} from '@slices/statisticsMKC/throwins';
import {
  HOCKEY_ROLES_ENUM,
  initialStateIndicators,
  STATISTICS_PLAYERS_TABS as TABS,
  STATISTICS_TABLE_TYPES,
} from '@src/constants';
import {MAPS_SIZE} from '@src/constants/stitictics';
import {groupBy, isEmpty, isObject, values} from 'lodash';

import moduleNames from '../../moduleNames';

const section = 'action_map';
const statePaths = {
  gamesCards: {
    name: 'gamesCards',
    path: 'gamesCards',
  },
  pastGames: {
    name: 'pastGames',
    path: 'pastGames',
  },
  tableTabNextGames: {
    name: 'nextGames',
    path: 'tableTab.nextGames',
  },
  prepareForOpponentTabOpponents: {
    name: 'opponentsList',
    path: 'prepareForOpponentTab.opponentsList',
  },
  prepareForOpponentPlayersStats: {
    name: 'playersStats',
    path: 'prepareForOpponentTab.playersStats',
  },
  matchesFilterTournaments: {
    name: 'tournaments',
    path: 'matchesFilter.tournaments',
  },
  matchesFilterMatches: {
    name: 'matches',
    path: 'matchesFilter.matches',
  },
  commonTab: {
    name: 'commonTab',
    path: 'commonTab',
  },
  playerTournaments: {
    name: 'playerTournaments',
    path: 'playerTournaments',
  },
  playerTournamentStat: {
    name: 'playerTournamentStat',
    path: 'playerTournamentStat',
  },
  playerPlayTime: {
    name: 'playerPlayTime',
    path: 'playerPlayTime',
  },
  actionsMap: {
    name: 'actionsMap',
    path: 'actionsMap.data',
  },
  actionsMapFilters: {
    name: 'actionsMapFilters',
    path: 'actionsMap.filters',
  },
  actionsMapButtonTypes: {
    name: 'actionsMapButtonTypes',
    path: 'actionsMap.buttonTypes',
  },
  ...shotsMapStatePaths,
  ...throwinsStatePaths,
  ...goaltendersStatePaths,
};

const initialState = {
  [statePaths.gamesCards.name]: {
    ...initialStateIndicators,
  },
  playersTables: {
    [HOCKEY_ROLES_ENUM.winger]: {
      ...initialStateIndicators,
      data: {},
    },
    [HOCKEY_ROLES_ENUM.defenseman]: {
      ...initialStateIndicators,
      data: {},
    },
    [HOCKEY_ROLES_ENUM.goaltender]: {
      ...initialStateIndicators,
      data: {},
    },
  },
  pastGames: {
    ...initialStateIndicators,
    data: {
      games: [],
    },
  },
  tableTab: {
    table: {
      [STATISTICS_TABLE_TYPES.tournament]: {
        ...initialStateIndicators,
        data: {
          items: [],
        },
      },
      [STATISTICS_TABLE_TYPES.chess]: {
        ...initialStateIndicators,
        data: {
          columns: [],
          rows: [],
        },
      },
    },
    nextGames: {
      ...initialStateIndicators,
      data: {
        games: [],
      },
    },
  },
  prepareForOpponentTab: {
    opponentsList: {
      ...initialStateIndicators,
    },
    playersStats: {
      ...initialStateIndicators,
      data: [...Array(12)].fill({
        student: {
          firstName: '',
          lastName: '',
          avatar: '',
          hockeyRole: '',
          playerNumber: null,
        },
        score: {
          value: null,
          topFive: false,
          topFiveBackwards: false,
        },
        goals: {
          value: null,
          topFive: false,
          topFiveBackwards: false,
        },
        assists: {
          value: null,
          topFive: false,
          topFiveBackwards: false,
        },
        plusMinus: {
          value: null,
          percent: null,
        },
        plus: null,
        minus: null,
        blockedShots: {
          value: null,
          topFive: false,
          topFiveBackwards: false,
        },
      }),
    },
  },
  players: {
    [TABS.classic.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.time.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.shots.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.dekings.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.fouls.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.passes.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.puckBattle.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.powerStruggle.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.corsi.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
  },
  matchesFilter: {
    tournaments: {
      ...initialStateIndicators,
    },
    matches: {
      ...initialStateIndicators,
    },
    data: {},
    state: {},
    isOpen: false,
  },
  commonTab: {
    ...initialStateIndicators,
    data: {},
  },
  playerTournaments: {
    ...initialStateIndicators,
  },
  playerTournamentStat: {
    ...initialStateIndicators,
    data: {},
  },
  playerPlayTime: {
    initialStateIndicators,
  },
  actionsMap: {
    buttonTypes: {
      ...initialStateIndicators,
    },
    filters: {plrs: [], actionIds: [], importances: [], playerIds: [], types: [], statisticIds: []},
    data: {...initialStateIndicators, data: {true: [], false: []}},
    actionModal: {
      isOpen: false,
      data: {},
    },
  },
  ...shotsInitialState,
  ...throwinsInitialState,
  ...goaltendersInitialState,
};

//redusers
const index = createSlice({
  name: moduleNames.gameStatisticsMkc,
  initialState,
  reducers: {
    ...defaultSliceReducersConstructor(statePaths.gamesCards.name, statePaths.gamesCards.path, initialState).functions,
    tableTabTableLoading: ({tableTab: {table}}, {payload}) => {
      if (!table?.[payload]) return;
      table[payload].isLoading = true;
    },
    tableTabTableLoad: ({tableTab: {table}}, {payload: {data, tab}}) => {
      if (!table?.[tab]) return;
      table[tab].isLoading = false;
      table[tab].isLoad = true;
      table[tab].data = data?.data || initialState.table[tab];
    },
    tableTabTableError: ({tableTab: {table}}, {payload}) => {
      if (!table?.[payload]) return;
      table[payload].isLoading = false;
      table[payload].isLoad = true;
      table[payload].hasError = true;
    },
    tableTabTableClear: ({tableTab}) => {
      tableTab.table = initialState.tableTab.table;
    },

    playersTablesLoading: ({playersTables}, {payload}) => {
      playersTables[payload].isLoading = true;
    },
    playersTablesLoad: ({playersTables}, {payload: {data, role}}) => {
      playersTables[role].isLoading = false;
      playersTables[role].isLoad = true;
      playersTables[role].data = data?.data || initialState.playersTables[role];
    },
    playersTablesError: ({playersTables}, {payload}) => {
      playersTables[payload].isLoading = false;
      playersTables[payload].isLoad = true;
      playersTables[payload].hasError = true;
    },
    playersTablesClear: (state) => {
      state.playersTables = initialState.playersTables;
    },
    ...defaultSliceReducersConstructor(statePaths.pastGames.name, statePaths.pastGames.path, initialState).functions,
    ...defaultSliceReducersConstructor(
      statePaths.tableTabNextGames.name,
      statePaths.tableTabNextGames.path,
      initialState,
    ).functions,
    ...defaultSliceReducersConstructor(
      statePaths.prepareForOpponentTabOpponents.name,
      statePaths.prepareForOpponentTabOpponents.path,
      initialState,
    ).functions,
    ...defaultSliceReducersConstructor(
      statePaths.prepareForOpponentPlayersStats.name,
      statePaths.prepareForOpponentPlayersStats.path,
      initialState,
    ).functions,
    clearPlayerStatisticsTab: (state, {payload}) => {
      if (state.players?.[payload] && initialState.players?.[payload]) {
        state.players[payload] = initialState.players[payload];
      } else {
        state.players = initialState.players;
      }
    },
    playersLoading: ({players}, {payload}) => {
      if (!players?.[payload]) return;
      players[payload] = {...initialState.players?.[payload]};
      players[payload].isLoading = true;
    },
    playersLoad: ({players}, {payload: {data, tab}}) => {
      if (!players?.[tab]) return;
      players[tab] = {...initialState.players?.[tab]};
      players[tab].isLoad = true;
      players[tab].data = data.data?.items || initialState.players[tab].data;
    },
    playersError: ({players}, {payload}) => {
      if (!players?.[payload]) return;
      players[payload] = {...initialState.players?.[payload]};
      players[payload].isLoad = true;
      players[payload].hasError = true;
    },
    ...defaultSliceReducersConstructor(
      statePaths.matchesFilterTournaments.name,
      statePaths.matchesFilterTournaments.path,
      initialState,
    ).functions,
    ...defaultSliceReducersConstructor(
      statePaths.matchesFilterMatches.name,
      statePaths.matchesFilterMatches.path,
      initialState,
      {},
      matchesMock,
    ).functions,
    setMatchesFilterData: ({matchesFilter}, {payload}) => {
      matchesFilter.data = payload;
    },
    setMatchesFilterState: ({matchesFilter}, {payload}) => {
      matchesFilter.state = isEmpty(payload) ? initialState.matchesFilter.state : {...matchesFilter.state, ...payload};
    },
    setMatchesFilterOpen: ({matchesFilter}, {payload}) => {
      matchesFilter.isOpen = payload;
    },
    ...defaultSliceReducersConstructor(statePaths.commonTab.name, statePaths.commonTab.path, initialState).functions,
    ...defaultSliceReducersConstructor(
      statePaths.playerTournaments.name,
      statePaths.playerTournaments.path,
      initialState,
    ).functions,
    ...defaultSliceReducersConstructor(
      statePaths.playerTournamentStat.name,
      statePaths.playerTournamentStat.path,
      initialState,
      {},
    ).functions,
    ...defaultSliceReducersConstructor(statePaths.playerPlayTime.name, statePaths.playerPlayTime.path, initialState)
      .functions,
    ...defaultSliceReducersConstructor(statePaths.actionsMap.name, statePaths.actionsMap.path, initialState).functions,

    setActionsMapFilters: ({actionsMap}, {payload}) => {
      actionsMap.filters = {...actionsMap.filters, ...payload};
    },
    setActionsMapActionModal: ({actionsMap}, {payload}) => {
      actionsMap.actionModal = {...actionsMap.actionModal, ...payload};
    },
    clearActionsMapFilters: ({actionsMap}) => {
      actionsMap.filters = {...initialState.actionsMap.filters};
    },
    ...defaultSliceReducersConstructor(
      statePaths.actionsMapButtonTypes.name,
      statePaths.actionsMapButtonTypes.path,
      initialState,
      {},
      actionButtonsMock,
    ).functions,
    setEntityFilters: (state, {payload: {entity, ...payload}}) => {
      if (entity && state?.[entity]) state[entity].filters = {...state[entity].filters, ...payload};
    },
    clearEntityFilters: (state, {payload}) => {
      const entity = payload.entity;
      if (entity && state?.[entity]) state[entity].filters = {...initialState[entity].filters};
    },
    ...shotsMapReducer,
    ...shotsMapActionsButtons,
    ...metricStatisticsReducer,
    ...throwinsMetricsReducer,
    ...throwinsMapReducer,
    ...goaltenderReducer,
  },
});

//exports

export const {actions, reducer} = index;
const {
  tableTabTableLoading,
  tableTabTableLoad,
  tableTabTableError,
  tableTabTableClear,
  playersTablesLoading,
  playersTablesLoad,
  playersTablesError,
  playersTablesClear,
  playersLoading,
  playersLoad,
  playersError,
} = actions;

export {reducer as gameStatisticsMkcReducer};

//fetch fns
const gameCardsActions = actionsGetter(statePaths.gamesCards.name, actions);

const pastGamesActions = actionsGetter(statePaths.pastGames.name, actions);

const tableTabNextGamesActions = actionsGetter(statePaths.tableTabNextGames.name, actions);

const prepareForOpponentTabOpponentsActions = actionsGetter(statePaths.prepareForOpponentTabOpponents.name, actions);

const prepareForOpponentPlayersStatsActions = actionsGetter(statePaths.prepareForOpponentPlayersStats.name, actions);

const matchesFilterTournamentsActions = actionsGetter(statePaths.matchesFilterTournaments.name, actions);

const matchesFilterMatchesActions = actionsGetter(statePaths.matchesFilterMatches.name, actions);

const commonTabActions = actionsGetter(statePaths.commonTab.name, actions);

const playerTournamentsActions = actionsGetter(statePaths.playerTournaments.name, actions);

const playerTournamentStatActions = actionsGetter(statePaths.playerTournamentStat.name, actions);

const playerPlayTimeActions = actionsGetter(statePaths.playerPlayTime.name, actions);

const actionsMapActions = actionsGetter(statePaths.actionsMap.name, actions);

const actionsMapButtonTypesActions = actionsGetter(statePaths.actionsMapButtonTypes.name, actions);

export const goaltendersActions = actionsGetter(goaltendersStatePaths?.goaltenders?.name, actions);

export const shotsMapActions = actionsGetter(shotsMapStatePaths?.shotsMap?.name, actions);
export const metricStatisticsActions = actionsGetter(shotsMapStatePaths.metricStatistics.name, actions);
export const shotsMapButtonTypesActions = actionsGetter(shotsMapStatePaths.shotsMapButtonTypes?.name, actions);

export const throwinsMetricsActions = actionsGetter(throwinsStatePaths.throwinsMetrics.name, actions);
export const throwinsMapActions = actionsGetter(throwinsStatePaths.throwinsMap.name, actions);

export const clearGamesCards = () => (dispatch) => dispatch(gameCardsActions.clear());

export const loadGamesCards = (params) =>
  defaultLoader(
    () =>
      Promise.all([getGames(), getGamesCards(params)]).then(([games, mkcGames]) => {
        const groupedTournamentGames = values(groupBy(games?.data || [], 'tournament.id')).map((games) => {
          const gameInfo = games?.[0];
          return {
            games: games
              ?.sort((a, b) => b?.date?.localeCompare(a?.date))
              ?.map(({statisticId, ...game}) => {
                const result = game;
                if (game?.statisticsIsReady) {
                  result.statisticId = statisticId;
                }
                return result;
              }),
            tournamentName: gameInfo?.tournament?.name,
            tournamentId: gameInfo?.tournament?.id,
            notMkcTournament: true,
          };
        });
        return {data: [...groupedTournamentGames, ...(mkcGames?.data?.length ? mkcGames.data : [])]};
      }),
    gameCardsActions.loading,
    gameCardsActions.load,
    gameCardsActions.error,
  );

export const clearTableTabTable = () => (dispatch) => dispatch(tableTabTableClear());

export const loadTableTabTable = (params, tab) =>
  defaultLoader(
    () => getTableTabTable(params),
    () => tableTabTableLoading(tab),
    (data) => tableTabTableLoad({data, tab}),
    () => tableTabTableError(tab),
  );

export const clearPlayersTables = () => (dispatch) => dispatch(playersTablesClear());

export const loadPlayersTables = (params, role) =>
  defaultLoader(
    () => getPlayersTables(params),
    () => playersTablesLoading(role),
    (data) => playersTablesLoad({data, role}),
    () => playersTablesError(role),
  );

export const clearPastGames = () => (dispatch) => dispatch(pastGamesActions.clear());

export const loadPastGames = (params) =>
  defaultLoader(() => getPastGames(params), pastGamesActions.loading, pastGamesActions.load, pastGamesActions.error);

export const clearTableTabNextGames = () => (dispatch) => dispatch(tableTabNextGamesActions.clear());

export const loadTableTabNextGames = (params) =>
  defaultLoader(
    () => getTableTabNextGames(params),
    tableTabNextGamesActions.loading,
    tableTabNextGamesActions.load,
    tableTabNextGamesActions.error,
  );

export const clearOpponentsList = () => (dispatch) => dispatch(prepareForOpponentTabOpponentsActions.clear());

export const loadOpponentsList = (params, onSuccess = () => {}) =>
  defaultLoader(
    () => getOpponentTeamList(params),
    prepareForOpponentTabOpponentsActions.loading,
    ({data}) => {
      const result = data?.map((item) => ({
        ...item,
        label: `${item?.name || ''}${
          item?.nextGameDate ? ' | сл. игра: ' + date(item?.nextGameDate).format('DD.MM.YYYY') : ''
        }`,
        value: item?.teamId,
      }));
      onSuccess(result);
      return prepareForOpponentTabOpponentsActions.load({
        data: result,
      });
    },
    prepareForOpponentTabOpponentsActions.error,
  );

export const clearOpponentPlayersStats = () => (dispatch) => dispatch(prepareForOpponentPlayersStatsActions.clear());

export const loadOpponentPlayersStats = (params) =>
  defaultLoader(
    () => getOpponentPlayersStat(params),
    prepareForOpponentPlayersStatsActions.loading,
    prepareForOpponentPlayersStatsActions.load,
    prepareForOpponentPlayersStatsActions.error,
  );

export const clearPlayerStatisticsTab = (tab) => (dispatch) => dispatch(actions.clearPlayerStatisticsTab(tab));

export const loadPlayersStatisticsData = (params) =>
  defaultLoader(
    () => getPlayersStatistics(params),
    () => playersLoading(params?.tab),
    (data) => playersLoad({data, tab: params?.tab}),
    () => playersError(params?.tab),
  );

export const clearTournaments = () => (dispatch) => dispatch(matchesFilterTournamentsActions.clear());

export const loadTournaments = (params, onLoad = () => {}) =>
  defaultLoader(
    () => getFiltersTournaments(params),
    matchesFilterTournamentsActions.loading,
    ({data}) => {
      const result =
        data?.map(({tournamentId, name, isFinal, groupName, ...tournament}) => ({
          ...(isObject(tournament) ? {tournamentId, isFinal, ...tournament} : {}),
          label:
            name +
            (isFinal || groupName ? ` (${groupName?.replaceAll('&quot;', '"')}) ${isFinal ? '(финал)' : ''}` : ''),
          value: tournamentId,
        })) || [];
      onLoad(result);
      return matchesFilterTournamentsActions.load({
        data: result,
      });
    },
    matchesFilterTournamentsActions.error,
  );

export const setMatchesFilterData = (data) => (dispatch) => dispatch(actions.setMatchesFilterData(data));

export const setMatchesFilterState = (data) => (dispatch) => dispatch(actions.setMatchesFilterState(data));

export const setMatchesFilterOpen = (data) => (dispatch) => dispatch(actions.setMatchesFilterOpen(data));

export const loadAllMKCTournaments = (params, onLoad = () => {}) =>
  defaultLoader(
    () => getAllMKCTournaments(params),
    matchesFilterTournamentsActions.loading,
    ({data}) => {
      onLoad(data);
      return matchesFilterTournamentsActions.load({data: data});
    },
    matchesFilterTournamentsActions.error,
  );

export const clearMatches = () => (dispatch) => dispatch(matchesFilterMatchesActions.clear());

export const loadMatches = (params, onLoad = () => {}) =>
  defaultLoader(
    () => getFiltersMatches(params),
    matchesFilterMatchesActions.loading,
    ({data}) => {
      onLoad(data);
      return matchesFilterMatchesActions.load({data: data});
    },
    matchesFilterMatchesActions.error,
  );

export const loadCommonTab = (params, onCatch = () => {}) =>
  defaultLoader(
    () => getCommonTab(params).catch(onCatch),
    commonTabActions.loading,
    commonTabActions.load,
    commonTabActions.error,
  );

export const clearCommonTab = () => (dispatch) => dispatch(commonTabActions.clear());

export const loadPlayerTournaments = (params) =>
  defaultLoader(
    () => getPlayerTournaments(params),
    playerTournamentsActions.loading,
    ({data}) => {
      const tournaments = data?.map((tournament, index) => ({
        ...(isObject(tournament) ? tournament : {}),
        label: tournament?.name + (tournament?.isFinal ? ' (финал)' : ''),
        value: tournament?.tournamentId,
        groups: tournament?.teams?.map((group) => ({label: group?.name, value: group?.teamMkcId})),
        id: `${tournament?.tournamentId} - ${index}`,
      }));
      return playerTournamentsActions.load({data: tournaments});
    },
    playerTournamentsActions.error,
  );

export const clearPlayerTournaments = () => (dispatch) => dispatch(playerTournamentsActions.clear());

export const loadPlayerTournamentStat = (params) =>
  defaultLoader(
    () => getPlayerTournamentStat(params),
    playerTournamentStatActions.loading,
    playerTournamentStatActions.load,
    playerTournamentStatActions.error,
  );

export const clearPlayerTournamentStat = () => (dispatch) => dispatch(playerTournamentStatActions.clear());

export const loadPlayTime = (params) =>
  defaultLoader(
    () => getPlayerStatPlayTime(params),
    playerPlayTimeActions.loading,
    playerPlayTimeActions.load,
    playerPlayTimeActions.error,
  );

export const clearPlayTime = () => (dispatch) => dispatch(playerPlayTimeActions.clear());

export const loadActionsMap = (params = {}, isCancel) =>
  defaultLoader(
    () => getActions({...params, section}),
    actionsMapActions.loading,
    ({data}) => {
      const weAtHome = true;
      const result = groupBy(data, ({isOur}) => {
        if (isOur) {
          return weAtHome ? 'home' : 'away';
        }
        return weAtHome ? 'away' : 'home';
      });
      return actionsMapActions.load({
        data: {
          home: result?.home || [],
          away:
            result?.away?.map((item) => ({
              ...item,
              posX: MAPS_SIZE.x - item?.posX,
              posY: MAPS_SIZE.y - item?.posY,
              opponentPosX: MAPS_SIZE.x - item?.opponentPosX,
              opponentPosY: MAPS_SIZE.y - item?.opponentPosY,
            })) || [],
          weAtHome: data?.[0]?.weAtHome,
        },
      });
    },
    actionsMapActions.error,
    {isCancel, onCancelAction: actionsMapActions.loading},
  );
export const clearActionsMap = () => (dispatch) => dispatch(actionsMapActions.clear());

export const setActionsMapFilters = (data) => (dispatch) => dispatch(actions.setActionsMapFilters(data));

export const setActionsMapActionModal = (data) => (dispatch) => dispatch(actions.setActionsMapActionModal(data));
export const clearActionsMapFilters = () => (dispatch) => dispatch(actions.clearActionsMapFilters());

export const loadButtonTypes = (params = {}, onSuccess = () => {}) =>
  defaultLoader(
    () => getActionsTypes({...params, section}),
    actionsMapButtonTypesActions.loading,
    ({data}) => {
      onSuccess(data);
      return actionsMapButtonTypesActions.load({data});
    },
    actionsMapButtonTypesActions.error,
  );
export const clearButtonTypes = () => (dispatch) => dispatch(actionsMapButtonTypesActions.clear());
