/**
 * Created by iam_r on 10.10.20.
 */

import * as types from '../ActionTypes.js';

const initialState = {
  loading: false,
  error: undefined,
};

const PsychoTestingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.PSYCHO_TESTING_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case types.PSYCHO_TESTING_LOADING_FINISH:
      return {
        ...state,
        loading: false,
      };
    case types.PSYCHO_TESTING_LOADING_FORM:
      return {
        ...state,
        loading: false,
        error: undefined,
        testingForm: {...action.testingForm},
      };
    case types.PSYCHO_TESTING_LOAD_REPORT:
      return {
        ...state,
        loading: false,
        error: undefined,
        playerPsychotestReport: action.playerPsychotestReport,
      };
    case types.TYPOLOGICAL_PROFILE_LOAD_REPORT:
      return {
        ...state,
        loading: false,
        error: undefined,
        playerTypologicalReport: action.playerTypologicalProfileReport,
      };
    case types.TYPOLOGICAL_PROFILE_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case types.TYPOLOGICAL_PROFILE_LOADING_FINISH:
      return {
        ...state,
        loading: false,
      };
    case types.PSYCHO_TESTING_CHANGE_FORM:
      return {
        ...state,
        loading: false,
        error: undefined,
        testingForm: {...action.testingForm},
      };
    default:
      return state;
  }
};

export default PsychoTestingReducer;
