import useDeepEffect from '@hooks/useDeepEffect';
import useQueryString from '@hooks/useQueryString';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectPlayersStatisticsTab} from '@selectors/gameStatisticsMkc';
import {clearPlayerStatisticsTab, loadPlayersStatisticsData} from '@slices/statisticsMKC';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';

export const useLoadPlayersStatisticsData = ({tab, filter, matchFilter}) => {
  const {statisticId} = useParams();
  const [{hockeyRole, ...restQs}, _] = useQueryString();
  const dispatch = useStoreDispatch();
  const [hasMatchFilter, setHasMatchFilter] = useState(false);
  useDeepEffect(() => {
    setHasMatchFilter(!!matchFilter);

    const statisticsIdsForOneGame = [statisticId];
    const matchFilterGames = !matchFilter?.statisticIds?.length ? [] : matchFilter?.statisticIds;
    const statisticsIds = !statisticId ? matchFilterGames : statisticsIdsForOneGame;
    if (statisticsIds?.length > 0) {
      const requestObj = {
        statisticsIds: statisticsIds,
        hockeyRole,
        tab,
        ...filter,
      };
      dispatch(loadPlayersStatisticsData(requestObj));
    }
    return () => {
      dispatch(clearPlayerStatisticsTab(tab));
    };
  }, [statisticId, matchFilter, tab, filter, hockeyRole, restQs]);

  const statisticsTab = useSelector((state) => selectPlayersStatisticsTab(state, tab));
  return {...statisticsTab, hasMatchFilter};
};
