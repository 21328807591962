import styled from 'styled-components';

export const LinesTabLine = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
export const LinesTabLineNumber = styled.p`
  position: absolute;
  top: 40%;
  left: -11px;
  transform: rotate(-90deg) translateY(-50%);
  margin-top: 0;
  margin-bottom: 2px;
  font: bold normal 12px/25px 'Proxima Nova';
  color: #2c2c2c;
  text-transform: uppercase;
`;
export const TeacherHockeyLinesPlayerCards = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const LinesTabLineColumn = styled.div`
  border-right: 2px solid #dddad4;
  box-sizing: border-box;
  padding-left: 32px;
  :last-child {
    border-right: 0;
  }
  @media screen and (max-width: 1440px) {
    padding-left: 0;
  }
`;
export const LinesTabLineColumnWingers = styled(LinesTabLineColumn)`
  flex: 0 0 60%;
  max-width: 60%;
  border-right: 2px solid #dddad4;
  ${TeacherHockeyLinesPlayerCards} {
    .Card,
    .EmptyCard {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
  }
`;
export const LinesTabLineColumnDefensemen = styled(LinesTabLineColumn)`
  flex: 0 0 40%;
  max-width: 40%;
  .Card,
  .EmptyCard {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;
export const LinesTabLineColumnGoaltenders = styled(LinesTabLineColumn)`
  flex: 0 0 59%;
  max-width: 59%;
  padding: 12px 0 12px 64px;
  @media (min-width: 1201px) and (max-width: 1440px) {
    padding-left: 30px;
  }
  @media screen and (max-width: 1200px) {
    padding-left: 18px;
  }
  ${TeacherHockeyLinesPlayerCards} {
    .Card,
    .EmptyCard {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
    .CardHand {
      &_right {
        right: -8px;
      }
      &_left {
        left: -8px;
      }
    }
    .CardRemovePlayer {
      right: 4px;
    }
    .CardStatus {
      .CardImgWrapper {
        :before {
          left: 10px;
        }
      }
    }
  }
`;
