import qs from 'query-string';
import {useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import cleanObject from '../helpers/cleanObject';
import useEvent from './useEvent';

const useQueryString = () => {
  const history = useHistory();
  const query = useMemo(() => qs.parse(history.location.search), [history.location.search]);

  const update = useEvent((value) => {
    const cleanedValue = cleanObject(value);
    const stringifiedValue = qs.stringifyUrl({
      url: history.location.pathname,
      query: cleanedValue,
    });
    history.push(stringifiedValue);
  });

  return useMemo(() => [query, update], [query, update]);
};
export default useQueryString;
