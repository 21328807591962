import {userRoles} from '@src/constants';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {shallowEqual, useSelector} from 'react-redux';

import CommonHelper from '../../../../../helpers/CommonHelper';
import useDeepEffect from '../../../../../hooks/useDeepEffect';
import DefaultFilter from '../DefaultFilter';

const StudentFilter = (props) => {
  const {name, onChange, actions} = props;
  const {updateOptions, updateValue, updateDisable} = actions;

  const {watch} = useFormContext();

  const group = watch('group');
  const groupValue = group?.value?.value;

  const {users, role} = useSelector((state) => {
    const currentUser = state.users.currentUser;
    const role = currentUser.userRole;
    const allGroups = state.groups.groupsMap.toArray();
    const allUsers = state.users?.usersMap?.toArray();
    const findedGroup = allGroups.filter((group) => group.id === groupValue);

    if (role === userRoles.student) {
      return {users: allUsers, role};
    }
    if (role === userRoles.parent) {
      const groupUsers = allUsers.filter((user) => findedGroup.some((group) => group.studentsIds.includes(user.id)));
      return {
        users: groupUsers.filter((user) => currentUser.childrenIds.includes(user.id)),
        role,
      };
    }
    return {
      users: allUsers.filter((user) => findedGroup.some((group) => group.studentsIds.includes(user.id))),
      role,
    };
  }, shallowEqual);

  useDeepEffect(() => {
    const options = CommonHelper.getDropDownItemsByUsers(users);

    (async () => {
      if (options.length === 1) {
        await updateValue(name, options[0]);
        await updateDisable(name, true);
      }

      if (options.length > 1) {
        await updateOptions(name, options);
        await updateDisable(name, false);
      }
    })();
  }, [users, role]);

  return <DefaultFilter actions={actions} name={name} onChange={onChange} />;
};

export default StudentFilter;
