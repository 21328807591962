import React from 'react';

function Close() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M4.14132 3.50574L6.86698 0.779991C7.04437 0.602692 7.04437 0.316025 6.86698 0.138726C6.68969 -0.0385721 6.40302 -0.0385721 6.22572 0.138726L3.49997 2.86447L0.774301 0.138726C0.59692 -0.0385721 0.310335 -0.0385721 0.133036 0.138726C-0.0443454 0.316025 -0.0443454 0.602692 0.133036 0.779991L2.8587 3.50574L0.133036 6.23148C-0.0443454 6.40878 -0.0443454 6.69545 0.133036 6.87275C0.221395 6.96119 0.337573 7.00562 0.453669 7.00562C0.569764 7.00562 0.68586 6.96119 0.774301 6.87275L3.49997 4.147L6.22572 6.87275C6.31416 6.96119 6.43026 7.00562 6.54635 7.00562C6.66245 7.00562 6.77854 6.96119 6.86698 6.87275C7.04437 6.69545 7.04437 6.40878 6.86698 6.23148L4.14132 3.50574Z"
          fill="#c4c4c4"
        />
      </g>
    </React.Fragment>
  );
}
export default Close;
