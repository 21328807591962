import EstimateItemWithLabelHorizontal from '@common/DevelopmentJournal/components/EstimateItemWithLabelHorizontal';
import EstimateItemWithLabelVertical from '@common/DevelopmentJournal/components/EstimateItemWithLabelVertical';
import {useModal} from '@hooks/skill';
import {useCurrentUserRole} from '@hooks/user';
import {selectEstimateById} from '@selectors/skill';
import {userRoles} from '@src/constants';
import {Button, Stack} from '@ui/MUI';
import {secondary} from '@ui/MUI/colors';
import React from 'react';
import {useSelector} from 'react-redux';

const SkillWithCategory = ({skillName, categoryName}) => {
  return (
    <EstimateItemWithLabelVertical label={skillName} labelColor={secondary.contrastText} labelVariant="h4" spacing={1}>
      <EstimateItemWithLabelHorizontal label="Группа:" labelVariant="body1" spacing={1}>
        {categoryName}
      </EstimateItemWithLabelHorizontal>
    </EstimateItemWithLabelVertical>
  );
};

const Buttons = ({id}) => {
  const userRole = useCurrentUserRole();
  const estimate = useSelector(selectEstimateById(id));
  const edit = useModal('edit');
  const complete = useModal('complete');
  const isCompleted = estimate?.isCompleted;
  const canComplete = [userRoles.admin, userRoles.teacher].includes(userRole) && !isCompleted;
  const canEdit = [userRoles.admin, userRoles.teacher].includes(userRole) && !isCompleted;

  return (
    <Stack alignItems="flex-start" direction="row" spacing={4}>
      {canEdit && (
        <Button color="primary" onClick={() => edit.open(estimate)}>
          Редактировать
        </Button>
      )}
      {canComplete && (
        <Button color="primary" variant="contained" onClick={() => complete.open(estimate)}>
          завершить навык
        </Button>
      )}
    </Stack>
  );
};

const Header = ({skillName, categoryName, id}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <SkillWithCategory categoryName={categoryName} skillName={skillName} />
      <Buttons id={id} />
    </Stack>
  );
};

export default Header;
