import {FormHelperText as FormHelperTextMUI} from '@mui/material';
import {FormHelperTextProps} from '@mui/material/FormHelperText/FormHelperText';
import React from 'react';

function FormHelperText({children, error = true, ...props}: FormHelperTextProps) {
  return (
    <FormHelperTextMUI error={error} {...props}>
      {children}
    </FormHelperTextMUI>
  );
}

export default FormHelperText;
