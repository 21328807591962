import AddButton from '@common/AddButton';
import Stack from '@ui/MUI/Stack';
import TextFieldDefault from '@ui/MUI/TextField';
import React from 'react';
import {Controller, useFieldArray} from 'react-hook-form';

const FormContent = ({name}) => {
  const {fields, append} = useFieldArray({
    name,
    rules: {required: true},
    shouldUnregister: true,
  });

  return (
    <Stack spacing={4}>
      {fields.map((field, index) => (
        <Controller
          key={field.id}
          name={`${name}.${index}.name`}
          render={({field}) => {
            return <TextFieldDefault {...field} />;
          }}
          rules={{required: true}}
        />
      ))}
      <AddButton
        onClick={() => {
          append({name: ''});
        }}
      >
        Добавить еще
      </AddButton>
    </Stack>
  );
};

export default FormContent;
