export {FACELESS_AVATAR} from '@src/constants/config';

export const STATUSES_OPTIONS = [
  {
    label: 'Доступен',
    value: 'available',
  },
  {
    label: 'Отсутствует',
    value: 'notavailable',
  },
];
export const DOCTOR_STATUSES_OPTIONS = [
  {
    label: 'Травмирован',
    value: 'injury',
  },
  {
    label: 'Болен',
    value: 'illness',
  },
];
export const NOTAVILABLE_SUB_STATUSES_OPTIONS = [
  {
    label: 'Семейные обстоятельства',
    value: 'family_reasons',
  },
  {
    label: 'Вызов на сборы',
    value: 'camp',
  },
  {
    label: 'Прочее',
    value: 'other',
  },
];

export const DOCTOR_SUB_STATUSES_OPTIONS = [
  {
    value: 'not_allowed',
    label: 'Не допущен к тренировкам',
  },
  {
    value: 'individual_hall',
    label: 'Индивидуальная тренировка в зале',
  },
  {
    value: 'individual_ice',
    label: 'Индивидуальная тренировка на льду и в зале',
  },
  {
    value: 'without_team',
    label: 'Тренировка c командой, с ограничениями',
  },
  {
    value: 'with_team',
    label: 'Тренировка с командой, но без допуска на игру',
  },
  {
    value: 'recovered',
    label: 'Допуск на игру',
  },
];

export const HOCKEY_ROLES = {
  winger: 'Нападающий',
  goaltender: 'Вратарь',
  defenseman: 'Защитник',
};

export const HAND = {
  left: 'Левая',
  right: 'Правая',
};

export const HOCKEY_ROLES_OPTIONS = [
  {
    label: 'Вратарь',
    value: 'goaltender',
  },
  {
    label: 'Защитник',
    value: 'defenseman',
  },
  {
    label: 'Нападающий',
    value: 'winger',
  },
];
export const HANDS_OPTIONS = [
  {
    label: 'Левый',
    value: 'left',
  },
  {
    label: 'Правый',
    value: 'right',
  },
];
export const NUMBER_OF_WORKING_PARENTS = [
  {
    label: 'Оба родителя',
    value: 'both',
  },
  {
    label: 'Один родитель',
    value: 'one',
  },
  {
    label: 'Никто',
    value: 'no_one',
  },
];
export const FAMILY_TYPE_OPTIONS = [
  {
    label: 'Полная',
    value: 'full',
  },
  {
    label: 'Неполная',
    value: 'incomplete',
  },
];
export const NATIONAL_TEAM_OPTIONS = [
  {
    label: 'Да',
    value: true,
  },
  {
    label: 'Нет',
    value: false,
  },
];

export const GAMES_ESTIMATIONS_FIELD_PLAYERS_PARAMETERS = [
  {
    label: 'Атакующие действия',
    value: 'attackAction',
  },
  {
    label: 'Оборонительные действия',
    value: 'defensiveAction',
  },
];
export const GAMES_ESTIMATIONS_GOALTENDERS_PARAMETERS = [
  {
    label: 'Игра клюшкой',
    value: 'playing_with_a_stick',
  },
  {
    label: 'Игра до конца',
    value: 'play_to_the_end',
  },
  {
    label: 'Работа в траффике',
    value: 'working_in_traffic',
  },
  {
    label: 'Контроль отскоков',
    value: 'bounce_control',
  },
  {
    label: 'Коммуникация с защитниками',
    value: 'communication_with_the_defenders',
  },
  {
    label: 'Позиционная игра',
    value: 'positional_game',
  },
  {
    label: 'Технические навыки',
    value: 'technical_skill',
  },
];
