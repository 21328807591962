import {createSlice} from '@reduxjs/toolkit';
import moduleNames from '@src/redux/moduleNames';
import {MENU_CATEGORIES_ENUM} from '@src/types/menu';

const initialState = {
  isMenuCollapsed: false,
  menuItems: {
    [MENU_CATEGORIES_ENUM.default]: [],
    [MENU_CATEGORIES_ENUM.sport]: [],
    [MENU_CATEGORIES_ENUM.control]: [],
    [MENU_CATEGORIES_ENUM.health]: [],
  },
  isTutorialBtnExpanded: true,
}; //redusers
const index = createSlice({
  name: moduleNames.menu,
  initialState,
  reducers: {
    setMenuItems: (state, {payload}) => {
      state.menuItems = payload;
    },
    setMenuCollapsed: (state, {payload}) => {
      state.isMenuCollapsed = payload;
    },
    setTutorialBtnExpanded: (state, {payload}) => {
      state.isTutorialBtnExpanded = payload;
    },
  },
});

//exports

export const {actions, reducer} = index;

export const setMenuItems = (data) => (dispatch) => dispatch(actions.setMenuItems(data));
export const setMenuCollapsed = (data) => (dispatch) => dispatch(actions.setMenuCollapsed(data));
export const setTutorialBtnExpanded = (data) => (dispatch) => dispatch(actions.setTutorialBtnExpanded(data));
