import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectGroups} from '@selectors/videoAnalytics';
import {clearGroups, loadGroups} from '@slices/videoAnalytics';
import {Box, Link, Stack, Typography} from '@ui/MUI';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';
import styled from 'styled-components';

import {mock} from './mock';

export default function GroupList() {
  const dispatch = useStoreDispatch();
  const {data, isLoad, isLoading, error} = useStore(selectGroups);

  useEffect(() => {
    dispatch(loadGroups());
    return () => {
      dispatch(clearGroups());
    };
  }, []);

  return (
    <Wrapper>
      <PageTitleDefault className="PageTitle" text="Видеоаналитика" />
      {(isLoad && !data?.length) || error ? (
        <EmptyBlock>
          <EmptyBlockText>Видео еще не добавлены</EmptyBlockText>
        </EmptyBlock>
      ) : (
        (isLoading ? mock : data).map((school, index) => {
          return school?.groups?.length ? (
            <Box key={index} sx={{pointerEvents: isLoading && 'none'}}>
              <Typography
                {...{isLoading}}
                withoutWrapper
                color="#fff"
                mb={3}
                preloaderWidthBetween={[130, 200]}
                variant="h4"
              >
                {school?.name || 'Школа'}
              </Typography>
              <Stack alignItems="shrink" direction="row" flexWrap="wrap" gap={2} justifyContent="flex-start" mb={2}>
                {school?.groups?.map((group, index) => (
                  <GroupCard
                    component={Link}
                    key={index}
                    mb={2}
                    p={3}
                    to={`/videos/${group.schoolId}/${group.id}?count=${group.videos}`}
                    underline="none"
                    width={(theme) => ({
                      lg: `calc(100% / 4 - ${theme.spacing(2)} + (${theme.spacing(2)} / 4))`,
                      sm: `calc(100% / 3 - ${theme.spacing(2)} + (${theme.spacing(2)} / 3))`,
                      xs: `calc(50% - ${theme.spacing(2)} + (${theme.spacing(2)} / 2))`,
                      xxs: '100%',
                    })}
                  >
                    <GroupCardContentContainer>
                      <Typography
                        {...{isLoading}}
                        component="h4"
                        gutterBottom={false}
                        preloaderWidthBetween={[90, 180]}
                        variant="h4"
                      >
                        {group.name}
                      </Typography>
                      <Typography
                        {...{isLoading}}
                        display="block"
                        variant="caption1"
                        width={isLoading ? '50%' : void 0}
                      >
                        {group.videos} видео
                      </Typography>
                    </GroupCardContentContainer>
                    <GroupCardLinkText>Смотреть</GroupCardLinkText>
                  </GroupCard>
                ))}
              </Stack>
            </Box>
          ) : null;
        })
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .PageTitle {
    margin-left: 0;
  }
`;
const GroupCard = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 120px;
  background-color: white;
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  cursor: pointer;
  &:last-child {
    margin-right: auto;
  }
`;
const GroupCardContentContainer = styled.div``;
const GroupCardLinkText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: 600 normal 14px/22px 'Proxima Nova';
  color: #b51f18;
  letter-spacing: 0.46px;
  text-transform: uppercase;
`;
const EmptyBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75vh;
  background-color: white;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
`;
const EmptyBlockText = styled.p`
  margin-top: 0;
  margin-bottom: 20px;
  font: 600 normal 14px/20px 'Proxima Nova';
  color: #2c2c2c;
`;
