import React from 'react';

function Person() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M7.02103 10.0351C8.20026 10.0351 9.15609 9.07901 9.15609 7.8999C9.15609 6.72067 8.20026 5.76471 7.02103 5.76471C5.84167 5.76471 4.88584 6.72067 4.88584 7.8999C4.88726 9.07849 5.84231 10.0335 7.02103 10.0351ZM7.02103 6.59484C7.74403 6.59484 8.3301 7.1809 8.3301 7.90403C8.3301 8.62703 7.74403 9.21323 7.02103 9.21323C6.2979 9.21323 5.71183 8.62703 5.71183 7.90403C5.71196 7.18103 6.29803 6.59509 7.02103 6.59484Z"
          fill="#626262"
        />
        <path
          d="M7.02107 9.82391C6.14745 9.81746 5.30907 10.1681 4.70002 10.7945C4.08053 11.4263 3.74187 12.2854 3.74187 13.2187C3.74252 13.4465 3.92695 13.6311 4.15487 13.6317H9.88713C10.1151 13.6311 10.2995 13.4465 10.3001 13.2187C10.3001 12.2854 9.96147 11.4263 9.34198 10.7945C8.73306 10.1681 7.89455 9.81746 7.02107 9.82391ZM4.59678 12.8057C4.66737 12.2658 4.91091 11.7632 5.29061 11.3726C5.74762 10.9102 6.37085 10.6499 7.02107 10.6499C7.67115 10.6499 8.29439 10.9102 8.75139 11.3726C9.13032 11.7636 9.3736 12.266 9.44523 12.8057H4.59678Z"
          fill="#626262"
        />
        <path
          d="M10.9769 1.3999H3.06498C1.92472 1.40042 1.00039 2.32463 1 3.46488V14.5271C1.00039 15.6674 1.92472 16.5916 3.06498 16.5921H10.9769C12.1171 16.5916 13.0413 15.6674 13.0418 14.5271V3.46488C13.0413 2.32463 12.1171 1.40042 10.9769 1.3999ZM12.2159 14.5271C12.2139 15.2105 11.6602 15.7642 10.9769 15.7661H3.06498C2.3816 15.7642 1.82793 15.2105 1.82599 14.5271V3.46488C1.82793 2.7815 2.3816 2.22783 3.06498 2.22589H10.9769C11.6602 2.22783 12.2139 2.7815 12.2159 3.46488V14.5271Z"
          fill="#626262"
        />
        <path
          d="M6.29884 4.29087H7.60279C7.83084 4.29087 8.01579 4.10593 8.01579 3.87788C8.01579 3.64982 7.83084 3.46488 7.60279 3.46488H6.29884C6.07066 3.46488 5.88584 3.64982 5.88584 3.87788C5.88584 4.10593 6.07066 4.29087 6.29884 4.29087Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default Person;
