import UsersAPI from '@api/UsersAPI';
import {userRoles} from '@src/constants';
import axios from 'axios';
import {Map} from 'immutable';

import {API_ENDPOINT} from '../constants/config';
import CommonHelper from './CommonHelper';

const StateHelper = {
  getStudentGroup(state, studentId) {
    if (state) {
      let groups = state.groups.groupsMap
        .toArray()
        .filter((a) => a !== undefined && a.studentsIds !== undefined)
        .filter((a) => a.studentsIds.indexOf(studentId) > -1);
      if (groups.length === 0) {
        return undefined;
      }
      return groups[0];
    }
  },

  getTeacherSchools(state, teacherId) {
    return state.schools.schoolsMap
      .toArray()
      .filter((a) => a.teachersIds !== undefined && a.teachersIds.indexOf(teacherId) > -1);
  },

  getTeacherGroups(state, teacherId) {
    return state.groups.groupsMap
      .toArray()
      .filter((a) => a.teachersIds !== undefined && a.teachersIds.indexOf(teacherId) > -1);
  },

  getSchoolsAdminSchools(state, user) {
    if (user === undefined) {
      return [];
    }
    let {schoolsIds = []} = user;
    return schoolsIds.map((id) => state.schools.schoolsMap.get(id)).filter((a) => a !== undefined);
  },

  getSchoolAdminTeachers(state, user) {
    let schools = this.getSchoolsAdminSchools(state, user);
    let map = Map();
    for (let i in schools) {
      let {teachersIds = []} = schools[i];
      for (let j in teachersIds) {
        let tId = teachersIds[j];
        let tea = state.users.usersMap.get(tId);
        if (tea !== undefined) {
          map = map.set(tea.id, tea);
        }
      }
    }
    return map.toArray().sort((a, b) => +b.timestamp - +a.timestamp);
  },

  getSchoolAdminStudents(state, user) {
    let schoolsIds = this.getSchoolsAdminSchools(state, user).map((a) => a.id);
    return state.users.usersMap
      .toArray()
      .filter((a) => a.userRole === 'student')
      .filter((a) => schoolsIds.indexOf(a.schoolId) > -1);
  },
  getSchoolStudents(state, schoolId) {
    return state.users.usersMap.toArray().filter((a) => a.userRole === 'student' && a.schoolId === schoolId);
  },
  getGroupStudents(state, groupId) {
    if (!groupId) return [];
    let studentsIds = [];
    const allGroups = state.groups.groupsMap.toArray();
    const allUsers = state.users.usersMap.toArray();

    if (!allUsers.length || !allGroups.length) return [];

    if (Array.isArray(groupId)) {
      const groups = groupId?.map((id) => id?.value || id);
      studentsIds = allGroups?.reduce((prev, curr) => {
        if (groups?.includes(curr.id)) {
          prev.push(...curr.studentsIds);
        }
        return prev;
      }, []);
    } else {
      studentsIds = allGroups?.find((g) => g.id === groupId)?.studentsIds || [];
    }

    return allUsers?.filter((u) => studentsIds?.includes(u.id));
  },

  getGroupTeachers(state, groupId) {
    if (!groupId) return [];
    let teachersIds = [];
    const allGroups = state.groups.groupsMap.toArray();
    const allUsers = state.users.usersMap.toArray();

    if (!allUsers.length || !allGroups.length) return [];

    if (Array.isArray(groupId)) {
      const groups = groupId.map((id) => id?.value || id);
      teachersIds = allGroups.reduce((prev, curr) => {
        if (groups.includes(curr.id)) {
          prev.push(...curr.teachersIds);
        }
        return prev;
      }, []);
    } else {
      teachersIds = allGroups.find((g) => g.id === groupId).teachersIds;
    }

    return allUsers.filter((u) => teachersIds.includes(u.id));
  },

  getSchoolTeachers(state, school) {
    if (!school) return [];
    const allUsers = state.users.usersMap.toArray();
    const allSchools = state.schools.schoolsMap.toArray();
    if (Array.isArray(school)) {
      const teacherSchools = allSchools.filter((s) => school.map((school) => school.id).includes(s.id));
      return allUsers.filter((u) => {
        const teachersIds = teacherSchools.map((school) => school.teachersIds).flat();
        return teachersIds.includes(u.id);
      });
    }
  },

  getCurrentUser(state) {
    return state.users.currentUser;
  },

  getSchoolIdByStudentId(state, studentId) {
    let group = this.getStudentGroup(state, studentId);
    return group ? group.schoolId : undefined;
  },
  async getGroupsBySchoolId(schoolId) {
    let result = await axios.get(`${API_ENDPOINT}/filter/group-school?schoolId=${schoolId}`);
    return result.data;
  },
  async getStudentsByTeamId(teamId) {
    let {result} = await UsersAPI.getStudentsV2({
      active: true,
      groupId: teamId,
      page: 1,
      limit: 999,
    });
    return result.map((student) => {
      return {
        id: student.id,
        name: `${student.lastName} ${student.firstName}`,
        student,
      };
    });
  },
  async getGamesByTeamId(teamId) {
    let result = await axios.get(`${API_ENDPOINT}/filter/games?teamId=${teamId}`);
    return result.data;
  },

  getTeacherDefaultFilterOptions(state, teacherId) {
    if (!teacherId) return;

    const groups =
      state.groups.groupsMap
        .toArray()
        .filter((a) => a.teachersIds !== undefined && a.teachersIds.indexOf(teacherId) > -1) || [];
    const schools =
      state.schools.schoolsMap
        .toArray()
        .filter((a) => a.teachersIds !== undefined && a.teachersIds.indexOf(teacherId) > -1) || [];

    let defaultGroupOption = {};
    if (groups.length) {
      defaultGroupOption.label = groups[0].name;
      defaultGroupOption.value = groups[0].id;
    }

    let defaultSchoolOption = {};
    if (schools.length) {
      defaultSchoolOption.label = schools[0].name;
      defaultSchoolOption.value = schools[0].id;
    }

    return {
      group: defaultGroupOption,
      school: defaultSchoolOption,
    };
  },

  isTeacherHasSchool(state, teacherId) {
    const defaultFilterOptions = this.getTeacherDefaultFilterOptions(state, teacherId);

    return !!Object.keys(defaultFilterOptions.school).length;
  },

  getSchools(state, allSchoolsPermissionRoles = []) {
    const {id, userRole, schoolsIds, schoolId} = state.users.currentUser;
    const schools = state.schools.schoolsMap.toArray();
    if ([userRoles.admin, userRoles.methodist].includes(userRole)) return schools;

    if (userRole === 'teacher') return schools.filter((school) => school.teachersIds.includes(id));

    return schools.filter(
      (school) =>
        allSchoolsPermissionRoles?.includes(userRole) || school.id === schoolId || schoolsIds.includes(school.id),
    );
  },

  getGroups(state, schoolId, isGetAll) {
    const {id, userRole} = state.users.currentUser;

    if (!schoolId) {
      const schools = this.getSchools(state);
      if (!schools) return [];
      const sorted = CommonHelper.getDropDownItemsByNamedList(schools, 1);
      schoolId = sorted?.[0]?.value;
    }

    const groups = state.groups.groupsMap.toArray();
    const schoolGroups = groups.filter((group) =>
      Array.isArray(schoolId)
        ? schoolId.map((id) => id?.value || id.id || id).includes(group.schoolId)
        : group.schoolId === schoolId,
    );

    if (isGetAll) return groups;

    if (userRole === 'teacher') return schoolGroups.filter((group) => group.teachersIds.includes(id));

    if (userRole === 'student') return schoolGroups.filter((group) => group.studentsIds.includes(id));

    return schoolGroups;
  },

  getGroup(state) {
    const {id, userRole} = state.users.currentUser;
    const groups = state.groups.groupsMap.toArray();

    if (userRole === 'student') {
      return groups.find((group) => group.studentsIds.includes(id));
    }
  },

  getUsers(state, roles = []) {
    const users = state.users.usersMap.toArray();
    return users.filter((user) => roles.includes(user.userRole));
  },
};

export default StateHelper;
