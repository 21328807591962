import ChessTemplate from '@components/templates/ChessTemplate';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import Add from '@mui/icons-material/Add';
import {Box} from '@mui/material';
import {updateFormVisability} from '@slices/materials';
import Pagination from '@ui/MUI/Pagination';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import Form from '../../components/Form';
import List from '../../components/List';
import Preview from '../../components/Preview';
import {defaultPage, sectionLimit} from '../../constants';
import {getSlugs} from '../../helpers';
import {useCurrentBreakPoint, useMaterialSection} from '../../hooks';
import {PageBox, StyledButton} from '../../styles';
import Filters from './components/Filters';
import SkeletonLoading from './skeleton';

const MaterialsSectionPage = () => {
  const {
    data: {materials, total, name, pages},
    clear,
    refetch,
    isLoading,
  } = useMaterialSection();
  const breakPoint = useCurrentBreakPoint();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 9999,
    tags: [],
    types: [],
    category: null,
  });
  const {type} = useParams();

  const cantAdd = useStore((state) => ['student', 'schools_admin'].includes(state.users.currentUser.userRole));

  const limit = useMemo(() => {
    return sectionLimit?.[type]?.[breakPoint];
  }, [sectionLimit?.[type]?.[breakPoint]]);

  const dispatch = useStoreDispatch();

  const fetch = () => {
    const payload = {
      ...filters,
      tags: getSlugs(filters.tags),
      types: getSlugs(filters.types),
      category: filters?.category?.slug,
      limit: sectionLimit?.[type]?.[breakPoint] || 10,
    };

    refetch(payload);
  };

  useEffect(() => {
    if (breakPoint) {
      fetch();
    }
  }, [filters, breakPoint]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  return (
    <ChessTemplate active="materials">
      <Form refetcher={fetch} />
      <Preview />
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Материалы', path: '/materials'},
          {label: name || defaultPage.title, path: null},
        ]}
        text={name || defaultPage.title}
      />
      <Box>
        {!cantAdd && (
          <StyledButton
            color="primary"
            startIcon={<Add />}
            variant="contained"
            onClick={() => {
              dispatch(updateFormVisability(true));
            }}
          >
            Добавить материал
          </StyledButton>
        )}
        <Filters filters={filters} onChange={setFilters} />
        <PageBox breakpoint={breakPoint}>
          {isLoading ? (
            <SkeletonLoading />
          ) : (
            name && <List count={total} materials={materials} name={name} shouldShowTitle={false} type={type} />
          )}
          {total > limit && (
            <Box mt={5}>
              <Pagination
                color="secondary"
                count={pages}
                page={filters.page}
                textColor="rgba(0, 0, 0, 0.87)"
                onChange={(_, page) => {
                  setFilters({...filters, page});
                }}
              />
            </Box>
          )}
        </PageBox>
      </Box>
    </ChessTemplate>
  );
};

export default MaterialsSectionPage;
