import Stack from '@ui/MUI/Stack';
import styled from 'styled-components';

export const ThrowsCardWrapper = styled.div`
  position: relative;
`;
export const ThrowsCardArenaSchema = styled.div`
  position: relative;
  ${({theme, pb}) => ({
    paddingBottom: pb + 'px',
    margin: theme.spacing(5, '0'),
    [theme.breakpoints.down('xs')]: {
      maxWidth: '300px',
      margin: theme.spacing(5, 'auto'),
    },
  })};
`;
export const ThrowsCardArenaSchemaBackground = styled.img`
  width: 100%;
  display: block;
  transition: 0.3s ease-in-out;
  filter: ${({nodata}) => `blur(${nodata ? 3 : 0}px)`};
`;
export const ThrowsCardCharts = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;
export const Buttons = styled(Stack)`
  flex-wrap: wrap;
  gap: ${({theme}) => theme.spacing(3)};
  .MuiButton-root:first-child {
    ${({theme}) => theme.breakpoints.up('xs')} {
      margin-right: ${({theme}) => theme.spacing(3)};
      &::before {
        content: '';
        position: absolute;
        right: -${({theme}) => theme.spacing(3)};
        top: 0;
        height: 100%;
        width: 1px;
        background-color: rgba(33, 33, 33, 0.3);
      }
    }
  }
`;

export const ZoneLabels = styled(Stack)`
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
  z-index: 0;
`;
