import {Map} from 'immutable';

import * as types from '../ActionTypes.js';

const initialState = {
  loading: false,

  groupsMap: Map(),
  groups: [],
  group: {},
  error: undefined,
  selectedFilters: {
    ratingFilters: {
      selectedSeason: null,
      selectedSchool: null,
      selectedGroup: null,
    },
    statsReportFilters: {
      selectedSeason: null,
      selectedSchool: null,
      selectedGroup: null,
      selectedPeriod: null,
      selectedHockeyRole: null,
    },
  },
};

const GroupsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CREATE_GROUP:
    case types.UPDATE_GROUP:
    case types.DELETE_GROUP:
    case types.LOAD_GROUPS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case types.CREATE_GROUP_FAIL:
    case types.UPDATE_GROUP_FAIL:
    case types.DELETE_GROUP_FAIL:
    case types.LOAD_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.CREATE_GROUP_SUCCESS:
    case types.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupsMap: state.groupsMap.set(action.group.id, action.group),
        group: {...action.group},
      };

    case types.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupsMap: state.groupsMap.delete(action.id),
      };

    case types.LOAD_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        groupsMap: state.groupsMap.merge(action.groups.reduce((map, form) => map.set(form.id, form), Map())),
        groups: state.groups,
      };
    case types.LOAD_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        group: action.group,
      };
    case types.LOAD_GROUP_STATISTICS_METRICS:
      return {
        ...state,
        loading: false,
        groupStatsMetrics: action.groupStatsMetrics,
      };
    case types.CLEAN_GROUP_STATISTICS:
      return {
        ...state,
        loading: false,
        groupStatistics: [],
      };
    case types.LOAD_GROUP_RATE:
      return {
        ...state,
        loading: false,
        groupRate: action.groupRate,
      };
    case types.SAVE_RATING_FILTERS:
      return {
        ...state,
        selectedFilters: {
          ratingFilters: {
            selectedSeason: action.allSelectedFilters.selectedSeason,
            selectedSchool: action.allSelectedFilters.selectedSchool,
            selectedGroup: action.allSelectedFilters.selectedGroup,
          },
          statsReportFilters: {
            ...state.selectedFilters.statsReportFilters,
          },
        },
      };
    default:
      return state;
  }
};

export default GroupsReducer;
