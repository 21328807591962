import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import * as schoolsActions from '../../redux/actions/SchoolsActions';
import AdminAllSchoolsPanel from '../admin/panels/AdminAllSchoolsPanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function AdminSchoolsApp() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(schoolsActions.loadAllSchools());
  }, []);
  return (
    <ChessTemplate active={'schools'}>
      <AdminAllSchoolsPanel />
    </ChessTemplate>
  );
}
