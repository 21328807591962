import DatePickerDefault from '@ui/MUI/DatePicker';
import React from 'react';
import styled from 'styled-components';

import {Button} from '../../ui/Button';
import Icon from '../../ui/Icon/Icon';

export default function TrainingPlanLessonItem(props) {
  let {
    lesson = {},
    index = 0,
    onChange = () => {},
    addNewLesson = () => {},
    deleteLesson = () => {},
    formatDateTime,
    parseDateTime,
  } = props;

  const onDateChange = (name, newDate) => {
    onChange(index, name, newDate);
  };

  return (
    <PlanRow key={index}>
      <PlanColumn>
        <PlanLabel>Дата</PlanLabel>
        <DatePickerDefault
          inputFormat="DD.MM.yyyy"
          value={parseDateTime(lesson.date)}
          variant="outlined"
          onChange={(newDate) => {
            onDateChange('date', formatDateTime(newDate));
          }}
        />
      </PlanColumn>
      {index === 0 ? (
        <Button type="button" onClick={addNewLesson}>
          <Icon height={17} type={'Add'} viewBox={'0 0 17 17'} width={17} />
        </Button>
      ) : (
        <Button
          type="button"
          onClick={() => {
            deleteLesson();
          }}
        >
          <Icon height={17} type={'CloseRed'} viewBox={'0 0 17 17'} width={17} />
        </Button>
      )}
    </PlanRow>
  );
}

const PlanRow = styled.div`
  position: relative;
  margin: 0 -7px;
  padding-right: 34px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;

  ${Button} {
    position: absolute;
    top: auto;
    right: 8px !important;
    bottom: -4px;
    display: block;
  }
`;
const PlanColumn = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  flex: 0 0 100%;
  max-width: 100%;

  .react-datepicker {
    &-popper {
      z-index: 999 !important;
    }
    &__input-container {
      > input {
        padding-left: 8px;
        padding-right: 8px;
        text-align: left;
      }
    }
  }
`;
const PlanLabel = styled.label`
  display: block;
  margin-top: 9px;
  margin-bottom: 9px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;

  + .SelectContainer {
    margin-top: 9px;
    margin-bottom: 12px;
  }
`;
