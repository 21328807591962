import VideoPlayer from '@common/Statistics/VideoPlayer';
import {minuteInMs} from '@components/apps/StatsReportForOneGame/constants';
import {convertTimeMMSS} from '@helpers/dateHelper';
import {shallowEqual} from '@helpers/shallowEqual';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {selectVideoModal} from '@selectors/gameStatistics';
import {setVideoModal} from '@slices/statistics';
import {longDash} from '@src/constants';
import {Button, Stack, Tooltip, Typography} from '@ui/MUI';
import {yellow} from '@ui/MUI/colors';
import Drawer from '@ui/MUI/DrawerNew';
import {cloneDeep} from 'lodash';
import isEqual from 'lodash/isEqual';
import {array} from 'prop-types';
import React, {memo, useCallback} from 'react';

ShiftVideoModal.propTypes = {
  shifts: array,
};

function ShiftVideoModal({shifts}) {
  const dispatch = useStoreDispatch();
  const {isOpen, data} = useStore(selectVideoModal, shallowEqual);

  const {infoItems, formattedData} = useDeepMemo(() => {
    const [, start, end, total] = data.value || [];
    const infoItems = [
      {
        label: 'Продолжительность:',
        value: convertTimeMMSS(total),
        icon: total > minuteInMs && (
          <Tooltip title="Переигранная смена">
            <ErrorOutlineOutlinedIcon fontSize="inherit" sx={{fill: yellow['100']}} />
          </Tooltip>
        ),
      },
      {label: 'Время начала:', value: convertTimeMMSS(start)},
      {label: 'Время окончания:', value: convertTimeMMSS(end)},
    ];
    const formattedData = cloneDeep(data);
    if (formattedData?.videoOffsets?.end) {
      formattedData.videoOffsets.end = formattedData?.videoOffsets?.end + total >= 0 ? Math.round(total / 1000) : 0;
    }

    return {infoItems, formattedData};
  }, [data]);

  const playerShifts = useDeepMemo(
    () =>
      shifts?.length
        ? shifts
            .filter((shift) => shift?.value?.[0] === data?.value?.[0])
            .sort((a, b) => Number(a?.value?.[2]) - Number(b?.value?.[2]))
            ?.map((shift, index) => ({...shift, index}))
        : [],
    [shifts, data?.value?.[0]],
  );

  const currentIndex = useDeepMemo(() => {
    if (data?.index) {
      return data.index + 1;
    }
    return (playerShifts.findIndex((shift) => isEqual(shift?.value, data?.value)) || 0) + 1;
  }, [data?.index, playerShifts]);

  const onClose = useCallback(() => {
    dispatch(setVideoModal({isOpen: false, data: {}}));
  }, []);

  const setData = useCallback((data) => {
    dispatch(setVideoModal({isOpen: true, data}));
  }, []);

  return (
    <Drawer
      buttons={
        <Stack
          direction={{xxs: 'column', xs: 'row'}}
          flexWrap="wrap"
          gap={3}
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" gap={3} width={{xxs: '100%', xs: 'unset'}}>
            <Button
              color="primary"
              disabled={currentIndex === 1}
              startIcon={<ChevronLeftOutlinedIcon />}
              sx={{width: {xxs: '50%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => setData(playerShifts[currentIndex - 2])}
            >
              назад
            </Button>
            <Button
              color="primary"
              disabled={currentIndex === playerShifts?.length}
              endIcon={<ChevronLeftOutlinedIcon sx={{transform: 'rotate(180deg)'}} />}
              sx={{width: {xxs: '50%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => setData(playerShifts[currentIndex])}
            >
              вперед
            </Button>
          </Stack>
          <Button color="secondary" variant="contained" onClick={onClose}>
            Закрыть
          </Button>
        </Stack>
      }
      open={isOpen}
      title={`${playerShifts?.length ? `смена ${currentIndex}/${playerShifts?.length}.` : ''} ${data?.fullName || ''}`}
      onClose={onClose}
    >
      <Stack direction="column" gap={1} mb={4} mt={6}>
        {infoItems.map(({label, value, icon}, index) => (
          <Stack alignItems="center" direction="row" gap={0} key={index}>
            <Typography variant="body1">{label}</Typography>
            <Typography variant="subtitle1">{value || longDash}</Typography>
            {icon}
          </Stack>
        ))}
      </Stack>
      <VideoPlayer data={formattedData} key={data?.index} />
    </Drawer>
  );
}

export default memo(ShiftVideoModal);
