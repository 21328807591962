import date from '@helpers/date';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import useToggle from '@hooks/useToggle';
import {updateStep, updateTempData} from '@slices/trainingControlProcess';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import {PageBox, PageContentWrapper} from '../../TrainingControlProcess';
import ExerciseModal from '../modals/Exercise';
import BottomBlock from './components/BottomBlock';
import Exercises from './components/Exercises';
import MainInfoBlock from './components/MainInfoBlock';
import Preview from './components/Preview';
import SelectableList from './components/SelectableExercises';

const startTime = date().valueOf();
const endTime = date().add('1', 'h').valueOf();

const NewTraining = () => {
  const [qs] = useQueryString();
  const dispatch = useStoreDispatch();
  const modal = useToggle({
    onToggle: (payload) => dispatch(updateTempData(payload)),
    onToggleOff: () => dispatch(updateTempData(null)),
  });

  const data = useStore((state) => state.trainingControlProcess.tempData);
  const form = useForm({
    defaultValues: data || {
      name: '',
      dates: [{startTime, endTime, date: qs?.date ? date(qs.date, 'DD.MM.YYYY').valueOf() : date().valueOf()}],
      group: null,
      school: null,
      exercises: [],
    },
  });

  useEffect(() => {
    return () => {
      data && dispatch(updateTempData(null));
      step && dispatch(updateStep(0));
    };
  }, []);

  const step = useStore((state) => state.trainingControlProcess.step);
  return (
    <FormProvider {...form}>
      <ExerciseModal {...modal} />
      <PageBox>
        <PageContentWrapper>
          {step !== 2 && <MainInfoBlock />}
          {step === 0 && <SelectableList on={modal.on} />}
          {step === 1 && <Exercises on={modal.on} />}
          {step === 2 && <Preview />}
        </PageContentWrapper>
        <BottomBlock />
      </PageBox>
    </FormProvider>
  );
};

export default NewTraining;
