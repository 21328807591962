import useDeepMemo from '@hooks/useDeepMemo';
import PropTypes from 'prop-types';

import {HALF_OPTIONS, HALF_OPTIONS_WITH_OT, IMPORTANCE_OPTIONS, PLRS_OPTIONS} from '../constants';

function useDefaultFilterValues(hasOT = false, multiplyImportance = false) {
  return useDeepMemo(
    () => ({
      options: {
        plrs: [...PLRS_OPTIONS],
        importance: multiplyImportance ? [...IMPORTANCE_OPTIONS.slice(1, 3)] : IMPORTANCE_OPTIONS[0],
        half: hasOT ? HALF_OPTIONS_WITH_OT : HALF_OPTIONS,
      },
      forFetch: {
        plrs: [...PLRS_OPTIONS].map((item) => item.value),
        importance: multiplyImportance
          ? [...IMPORTANCE_OPTIONS.slice(1, 3)].map((item) => item?.value)
          : IMPORTANCE_OPTIONS[0].value,
        half: (hasOT ? HALF_OPTIONS_WITH_OT : HALF_OPTIONS).map((item) => item.value),
      },
    }),
    [hasOT, multiplyImportance],
  );
}

useDefaultFilterValues.propTypes = {
  hasOT: PropTypes.bool,
  weAtHome: PropTypes.bool,
};

export default useDefaultFilterValues;
