import useDeepEffect from './useDeepEffect';
import useEvent from './useEvent';

const {useState, useMemo} = require('react');

const useRequest = (fetcher, params) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const fetch = useEvent(async () => {
    setIsLoading(true);
    try {
      const result = await fetcher(params);
      setIsLoading(false);
      setData(result);
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  });

  useDeepEffect(() => {
    fetch();
  }, [fetch, params]);

  return useMemo(() => ({isLoading, data, error, reFetch: fetch}), [isLoading, data, error, fetch]);
};

export default useRequest;
