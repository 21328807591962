import React from 'react';

function Edit() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M0 10.5V13H2.5L9.87333 5.62667L7.37333 3.12667L0 10.5ZM11.8067 3.69334C12.0667 3.43334 12.0667 3.01334 11.8067 2.75334L10.2467 1.19334C9.98667 0.933337 9.56667 0.933337 9.30667 1.19334L8.08667 2.41334L10.5867 4.91334L11.8067 3.69334Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default Edit;
