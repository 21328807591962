import React from 'react';

function Thunderbolt() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M7.99806 5.35743H4.84416L7.56741 0.569108C7.63445 0.451279 7.63366 0.306572 7.56552 0.189339C7.49738 0.0721055 7.37192 0 7.23634 0H2.66602C2.50197 0 2.35627 0.105133 2.3046 0.260849L0.0194358 7.14162C-0.0191461 7.25776 0.000392812 7.38531 0.0720024 7.48459C0.143612 7.58378 0.258465 7.64259 0.380856 7.64259H3.61291L1.55398 12.4697C1.48069 12.6416 1.54278 12.8411 1.70067 12.941C1.85857 13.0409 2.06557 13.0114 2.18945 12.8717L8.2832 5.99081C8.50022 5.74563 8.32585 5.35743 7.99806 5.35743ZM3.17581 10.609L4.53977 7.4112C4.64669 7.16047 4.4626 6.88087 4.18946 6.88087H0.908605L2.94085 0.76172H6.58153L3.85839 5.54994C3.71437 5.80316 3.89736 6.11915 4.18946 6.11915H7.15203L3.17581 10.609Z"
          fill="#990011"
        />
      </g>
    </React.Fragment>
  );
}
export default Thunderbolt;
