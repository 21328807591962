import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Box, Button, Stack} from '@mui/material';
import styled from 'styled-components';

import {typeColors} from '../constants';

export const PageBox = styled(Box)`
  padding: 24px;
  margin-right: ${(props) => (props.breakpoint === 'xs' ? '12px' : '0px')};
  background: #ffffff;
  border-radius: 6px;
  flex: ${({flex}) => flex && flex};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
`;

export const PageFilters = styled.div``;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => typeColors[props.type]};
`;

export const FiltersWrapper = styled(Stack)`
  padding: 16px 24px;
  background: #ffffff;
  border-radius: 6px;
  @media screen and (max-width: 575px) {
    gap: 18px;
  }
`;

export const FilterWrapper = styled.div`
  max-width: 216px;
  min-width: 216px;
  @media screen and (max-width: 575px) {
    width: 100%;
    max-width: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.breakpoint === 'xs' ? 'center' : 'flex-start')};
`;

export const StyledButton = styled(Button)`
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.4px;
  background-color: #d32f2f;
  &:hover {
    background-color: #8c1a1a;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;
export const ButtonHide = styled.div`
  font: 600 normal 14px/20px 'Proxima Nova';
  letter-spacing: 0.1px;
  color: #b63830;
`;

export const Arrow = styled(KeyboardArrowUpIcon)`
  path {
    fill: ${(props) => (props.arrowColor ? props.arrowColor : '#b63830')};
  }
  transition: all 0.3s ease-out;
  transform: ${(props) => props.rotate && 'rotate(179deg)'};
`;
