export const InjuryHistoryMock = {
  tableData: [
    {
      date: '22-23',
      subRows: [
        {
          objectId: 'I8Lq59GToD',
          date: 0,
          type: '',
          recoveryDate: 0,
          season: '',
        },
        {
          objectId: 'I8Lq569GToD',
          date: 0,
          type: '',
          recoveryDate: 0,
          season: '',
        },
        {
          objectId: 'I8Lq549GToD',
          date: 0,
          type: '',
          recoveryDate: 0,
          season: '',
        },
        {
          objectId: 'I8Lq529GToD',
          date: 0,
          type: '',
          recoveryDate: 0,
          season: '',
        },
      ],
    },
    {
      date: '23-24',
      subRows: [
        {
          objectId: 'I8Lq59G00ToD',
          date: 0,
          type: '',
          recoveryDate: 0,
          season: '',
        },
        {
          objectId: 'I8Lq5954GToD',
          date: 0,
          type: '',
          recoveryDate: 0,
          season: '',
        },
        {
          objectId: 'I8234Lq59GToD',
          date: 0,
          type: '',
          recoveryDate: 0,
          season: '',
        },
        {
          objectId: 'I8Lq59G564ToD',
          date: 0,
          type: '',
          recoveryDate: 0,
          season: '',
        },
      ],
    },
  ],
  expandedRows: {
    0: true,
    1: true,
  },
};
