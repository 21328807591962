import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import SelectDefault from '../../../ui/Select';
import {FilterContainer, Label} from '../../styles';

const DefaultFilter = (props) => {
  const {getValues} = useFormContext();
  const {name, onChange, actions} = props;

  const updateFilters = (select, action) => {
    const filter = getValues(action.name);
    if (filter?.value?.value !== select.value) {
      onChange(action.name, select);
    }
  };

  return (
    <Controller
      name={name}
      render={({field: {value}}) => {
        const {
          value: selectValue,
          options,
          label,
          placeholder,
          isLoading,
          isDisabled,
          renderFilter,
          className,
          isVisable,
        } = value;
        if (!isVisable) return null;
        return (
          <FilterContainer className={className || 'filter-container'}>
            {label && <Label>{label}</Label>}
            {renderFilter ? (
              renderFilter({filter: value, actions})
            ) : (
              <SelectDefault
                isDisabled={isDisabled}
                isLoading={isLoading}
                name={name}
                options={options}
                placeholder={placeholder}
                value={selectValue}
                onChange={updateFilters}
              />
            )}
          </FilterContainer>
        );
      }}
    />
  );
};

export default DefaultFilter;
