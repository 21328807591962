import {loadMissedPlayers} from '@slices/managerDashboard';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import PlayerStatusTable from '../PlayerStatusTable';

function MissedPlayers() {
  const dispatch = useDispatch();
  const {data, isLoad, isLoading, error} = useSelector((state) => state.managerDashboard.missedPlayers);
  const [isAll, setIsAll] = useState(false);
  useEffect(() => {
    dispatch(loadMissedPlayers({isAll}));
  }, [isAll]);

  return (
    <PlayerStatusTable
      lastTableHeadline="Причина отсутствия"
      noData="отсутствующих игроков"
      title="отсутствующие игроки"
      {...{data, isLoad, isLoading, error, isAll, setIsAll}}
    />
  );
}

MissedPlayers.propTypes = {};

export default MissedPlayers;
