import {StyledTableCell} from '@common/ReactTableComponent/ReactTableComponent';
import {Wrapper} from '@ui/MUI/Autocomplete';
import {grey} from '@ui/MUI/colors';
import styled from 'styled-components';

import {FunctionalStateIndicator} from '../../ReportsInformation/ReportsInformationTable/ReportsInformation';
import {InfoBlock} from '../Report';

export const TableWrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
`;

export const TableContainer = styled.div`
  .MuiTable {
    &-root {
      width: auto;
    }
  }
  ${FunctionalStateIndicator} {
    position: absolute;
    top: 50%;
    left: ${({theme}) => theme.spacing(4)};
    transform: translateY(-50%);
    margin-left: 0;
    ${({theme}) => theme.breakpoints.down('md')} {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  ${StyledTableCell} {
    position: relative;
    width: 140px;
    &:first-child {
      width: 300px;
    }
    &.MuiTableCell-head {
      position: relative;
      &:not(:first-child) {
        svg {
          position: absolute;
          top: 50%;
          right: ${({theme}) => theme.spacing(4)};
          transform: translateY(-50%);
          fill: ${grey[26]};
          width: 16px;
          height: 16px;
          ${({theme}) => theme.breakpoints.down('xs')} {
            right: ${({theme}) => theme.spacing(2)};
          }
        }
      }
    }
  }
  ${InfoBlock} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    ${({theme}) => theme.breakpoints.down('xs')} {
      flex-direction: column;
      align-items: flex-start;
    }
    &:last-child {
      .MuiTypography {
        &-body2 {
          position: relative;
          padding-left: ${({theme}) => theme.spacing(4)};
          &:not(:last-child) {
            padding-right: ${({theme}) => theme.spacing(2)};
          }
          ${FunctionalStateIndicator} {
            left: 8px;
            width: 6px;
            height: 6px;
            ${({theme}) => theme.breakpoints.down('md')} {
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
        &-subtitle2 {
          width: 100%;
          margin-bottom: ${({theme}) => theme.spacing(1)};
        }
      }
    }
    .MuiFormControlLabel {
      &-root {
        &:not(:first-child) {
          padding-left: ${({theme}) => theme.spacing(4)};
          ${({theme}) => theme.breakpoints.down('xs')} {
            padding-left: 0;
          }
        }
      }
    }
    .MuiAutocomplete {
      &-root {
        width: 200px;
        ${({theme}) => theme.breakpoints.down('xs')} {
          padding-bottom: ${({theme}) => theme.spacing(2)};
          width: 270px;
        }
      }
    }
    ${Wrapper} {
      flex: ${(props) => (props.isStagedTesting ? '0 0 50%' : 'initial')};
      max-width: ${(props) => (props.isStagedTesting ? '50%' : 'initial')};
      ${({theme}) => theme.breakpoints.down('md')} {
        flex: ${(props) => (props.isStagedTesting ? '0 0 30%' : 'initial')};
        max-width: ${(props) => (props.isStagedTesting ? '30%' : 'initial')};
      }
      ${({theme}) => theme.breakpoints.down('sm')} {
        flex: initial;
        max-width: initial;
      }
    }
  }
`;

export const EmptyValue = styled.span`
  color: ${grey[54]};
`;
