import TreeColumn from '@common/Statistics/PlayOff/components/TreeColumn';
import {Stages} from '@common/Statistics/PlayOff/types';
import {StagesEnum} from '@src/types/tournament';
import {Box, Button, Stack} from '@ui/MUI';
import React, {memo, useState} from 'react';

export const playOffStages = {
  [StagesEnum.SIXTEENTH]: '1/16',
  [StagesEnum.EIGHTH]: '1/8',
  [StagesEnum.FOURTH]: '1/4',
  [StagesEnum.HALF]: '1/2',
  [StagesEnum.FINAL]: 'Финал',
};
function MobileViewStages({stages, isLoading}: {stages: Stages; isLoading?: boolean}) {
  const [currentStage, setCurrentStage] = useState<number>(0);
  return (
    <Box width="fit-content">
      <Stack direction="row" justifyContent="center" mb={3}>
        {stages.map((item, index) => (
          <Button
            color={currentStage === index ? 'primary' : 'secondary'}
            key={item.stage + index}
            size="small"
            variant={currentStage === index ? 'contained' : 'text'}
            onClick={() => setCurrentStage(index)}
          >
            {playOffStages[item.stage]}
          </Button>
        ))}
      </Stack>
      <TreeColumn
        isMobile
        columnIndex={currentStage}
        columnsCount={stages?.length}
        games={stages?.[currentStage]?.games}
        isLoading={isLoading}
        stage={stages?.[currentStage]?.stage}
      />
    </Box>
  );
}

export default memo(MobileViewStages);
