import ImageCropPanel from '@components/upload/ImageCropPanel';
import {UPLOAD_DO_ENDPOINT_URL} from '@src/constants/config';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import axios from 'axios';
import React from 'react';

export default function CropModal({cropModalState = false, onClose = () => {}, onChange = () => {}, value}) {
  return (
    <DialogDefault
      actionText="Выбрать"
      className="ReactComponentModalDefault LinesNotSaved"
      closeText="Продолжить без сохранения"
      content={<ImageCropPanel onChange={onChange} />}
      open={cropModalState}
      title="Выберите изображение"
      onActionButtonClick={() => {
        if (value) {
          axios
            .post(UPLOAD_DO_ENDPOINT_URL, value, {
              success: {message: 'Миниатюра успешно создана'},
              failed: {message: 'Ошибка при создании миниатюры'},
            })
            .then((d) => d.data.url || d.data.path)
            .then((url) => {
              onChange(url);
              onClose();
            });
        }
      }}
      onClose={onClose}
      onCloseDefault={onClose}
    />
  );
}
