import {getFormData} from '@components/apps/AddStats/helpers';
import cleanObject from '@helpers/cleanObject';
import useCustomForm from '@hooks/useCustomForm';
import useEvent from '@hooks/useEvent';
import useStoreDispatch from '@hooks/useStoreDispatch';
import useToggle from '@hooks/useToggle';
import {createTrainingControl, deleteTrainingControl, updateTrainingControl} from '@slices/trainingControlProcess';
import {useMemo, useRef} from 'react';

export const useDeleteModal = () => {
  const id = useRef(null);
  const dispatch = useStoreDispatch();
  const toggle = useToggle({
    onToggle: (payload) => (id.current = payload),
    onToggleOff: () => (id.current = null),
  });

  const handleDelete = useEvent(() => {
    dispatch(deleteTrainingControl({name: 'exercise', payload: id.current, onSuccess: toggle.off}));
  });

  return useMemo(() => ({toggle, handleDelete}), [toggle, handleDelete]);
};

export const useExercisesForm = () => {
  const form = useCustomForm({
    author: null,
    description: '',
    skills: [],
    theme: null,
    age: null,
    name: '',
    rules: '',
    highlights: null,
    schemesFile: [],
    videoFile: null,
  });

  const dispatch = useStoreDispatch();

  const updateForm = useEvent((payload) => {
    form.open();
    form.methods.reset(payload);
  });

  const handleSubmit = (data) => {
    const payload = getFormData(
      cleanObject({
        ...data,
        age: data.age.id,
        author: data.author.id,
        skills: data.skills.map((skill) => skill.id),
        theme: data.theme.id,
        schemesFile: data.schemesFile.map((file) => !file.id && file),
        schemes: data.schemesFile.map((file) => file.id && file.id),
        videoFile: !data.videoFile?.id && data.videoFile,
        video: data.videoFile?.id && data.videoFile.id,
      }),
    );

    if (!data.id) {
      dispatch(
        createTrainingControl({
          name: 'exercise',
          payload,
          onSuccess: form.close,
        }),
      );
    } else {
      dispatch(
        updateTrainingControl({
          name: 'exercise',
          payload,
          id: data.id,
          onSuccess: form.close,
        }),
      );
    }
  };

  return {...form, updateForm, handleSubmit: form.methods.handleSubmit(handleSubmit)};
};
