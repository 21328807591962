import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {SvgIcon} from '@mui/material';
import {Button, Skeleton, Stack} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

Buttons.propTypes = {
  filters: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
  loader: PropTypes.func.isRequired,
  cleanup: PropTypes.func,
  getter: PropTypes.func.isRequired,
};

function Buttons({filters = [], setFilters = () => {}, loader = () => {}, cleanup = () => {}, getter = () => {}}) {
  const {isLoading, data, isLoad} = useStore(getter);
  const dispatch = useStoreDispatch();
  useEffect(() => {
    dispatch(loader());
    return () => {
      dispatch(cleanup());
    };
  }, []);

  return (isLoad && data?.length) || isLoading ? (
    <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2}>
      {data?.map((item, index) => {
        const isActive = filters?.includes(item?.id) || (!filters?.length && item?.id === 'all');
        return (
          <Skeleton isLoading={isLoading} key={index}>
            <Button
              color={isActive ? 'primary' : 'secondary'}
              size="small"
              startIcon={item.icon ? <SvgIcon inheritViewBox component={item.icon} /> : null}
              variant="contained"
              onClick={() =>
                setFilters((prevState) => {
                  if (item?.id === 'all') return [];
                  if (isActive) return prevState?.filter((id) => id !== item?.id);
                  return [...prevState, item.id];
                })
              }
            >
              {item?.name}
            </Button>
          </Skeleton>
        );
      })}
    </Stack>
  ) : null;
}

export default Buttons;
