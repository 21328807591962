import {getTeamLinesHistory, handleChangeForm, setActualHistoryLines} from '@actions/LinesActions';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {Sync} from '@mui/icons-material';
import {Typography} from '@mui/material';
import ButtonDefault from '@ui/MUI/Button';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import SelectDefault from '@ui/MUI/Select';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ConfirmModalContent, ConfirmModalContentText} from '../Favorites/Favorites';
import {ButtonsBlock} from '../LinesMobile';
import LinesMobileTableComponent from '../LinesMobileTable';
import {TitleBlock, Wrapper} from './LinesHistory';

export default function LinesHistory({teamId, activeTab, setActiveTab}) {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    if (teamId) {
      dispatch(getTeamLinesHistory(teamId)).then(() => {
        setIsLoading(false);
      });
    }
  }, [teamId, activeTab]);

  const linesHistory = useSelector((state) => state.lines.linesHistory || []);

  //ToDo: засунуть lines history в memo

  const linesHistoryOptions = linesHistory
    ?.sort((a, b) => b.game.rooms[0].startTimestamp - a.game.rooms[0].startTimestamp)
    .map((lineHistory) => ({
      label: `${moment(lineHistory.game.rooms[0].startTimestamp).format('DD.MM.YYYY HH:mm')} ${
        lineHistory.game.type !== 'away_game'
          ? `${lineHistory.game.teamName || ''} — ${lineHistory.game.opponent || ''}`
          : `${lineHistory.game.opponent || ''} — ${lineHistory.game.teamName || ''}`
      }`,
      value: lineHistory.game.objectId,
    }));

  const [activeLineHistory, setActiveLineHistory] = useState();

  const [isActualModalShowed, setIsActualModalShowed] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (linesHistory?.length) {
      setActiveLineHistory(linesHistory[0]);
    }
    setIsLoading(false);
  }, [linesHistory?.length]);

  const cleanLines = () => {
    setIsLoading(true);
    let currentLinesForm = activeLineHistory ? JSON.parse(JSON.stringify(activeLineHistory)) : {};
    currentLinesForm.playersLines = [
      {
        name: 'playersLine_1',
        wingersLine: [],
        defensemansLine: [],
      },
      {
        name: 'playersLine_2',
        wingersLine: [],
        defensemansLine: [],
      },
      {
        name: 'playersLine_3',
        wingersLine: [],
        defensemansLine: [],
      },
      {
        name: 'playersLine_4',
        wingersLine: [],
        defensemansLine: [],
      },
    ];
    currentLinesForm.goaltendersLine = [];
    currentLinesForm.isFavorites = false;
    currentLinesForm.favoritesName = null;
    dispatch(handleChangeForm(currentLinesForm));
    setIsLoading(false);
  };

  return (
    <Wrapper isLoading={isLoading}>
      <LocalPreloader visible={isLoading} />
      {!activeLineHistory && (
        <TitleBlock isEmptyBlock={true}>
          <Typography color="#000" variant="subtitle2">
            Выберите игру для отображения состава
          </Typography>
        </TitleBlock>
      )}
      <SelectDefault
        hasFixedLabel={false}
        label="Игра"
        options={linesHistoryOptions}
        value={activeLineHistory?.game?.objectId}
        onChange={(e) => {
          setActiveLineHistory(linesHistory?.find((item) => item.game.objectId === e.target.value));
        }}
      />
      {linesHistoryOptions?.length > 0 && (
        <>
          <ButtonsBlock>
            <ButtonDefault
              startIcon={<Sync />}
              variant="text"
              onClick={() => {
                setIsActualModalShowed(true);
              }}
            >
              Сделать состав актуальным
            </ButtonDefault>
          </ButtonsBlock>
          <LinesMobileTableComponent isButtonsShowed={false} linesForm={activeLineHistory?.composition} />
        </>
      )}
      <DialogDefault
        actionText="Сделать актуальным"
        closeText="Вернуться к составу"
        content={
          <ConfirmModalContent>
            <ConfirmModalContentText>
              Вы хотите сделать состав актуальным на ближайшее мероприятие?
            </ConfirmModalContentText>
          </ConfirmModalContent>
        }
        handleClickOpen={() => {
          setIsActualModalShowed(true);
        }}
        open={isActualModalShowed}
        title="Сделать состав актуальным"
        onActionButtonClick={() => {
          setIsActualModalShowed(false);
          dispatch(setActualHistoryLines(activeLineHistory.objectId)).then(() => {
            cleanLines();
            setActiveTab('0');
          });
        }}
        onCloseDefault={() => {
          setIsActualModalShowed(false);
        }}
      />
    </Wrapper>
  );
}
