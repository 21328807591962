import ReactTableComponent from '@common/ReactTableComponent';
import {dataForTableMock} from '@components/apps/DevelopmentJournal/components/PlayersDiary/mock';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useDeepMemo from '@hooks/useDeepMemo';
import useQueryString from '@hooks/useQueryString';
import {useMediaQuery} from '@mui/material';
import {Box, Button, Typography} from '@ui/MUI';
import {darkRed} from '@ui/MUI/colors';
import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router';

const defaultAvgSorting = (rowA, rowB, columnId) => {
  const a = rowA?.getValue(columnId)?.avgScore;
  const b = rowB?.getValue(columnId)?.avgScore;
  if (a == undefined) return 1;
  if (b == undefined) return -1;
  if (a === b) return 0;
  return a < b ? -1 : 1;
};

function Table({data, isMonth = false, isPercentsTab = false, isLoading, reportDate}) {
  const history = useHistory();
  const [qs, setQs] = useQueryString();
  const [sorting, setSorting] = useState([{id: 'student', desc: true}]);
  const defaultColumns = useMemo(
    () => [
      {
        header: 'Утро',
        accessorKey: 'morning',
        cell: ({getValue}) => {
          const isBad = getValue()?.avgScore < 3;
          const isDayOff = getValue()?.isDayOff || false;
          return !isDayOff ? (
            <Typography
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
              sx={{position: 'relative'}}
              variant={!getValue()?.avgScore ? 'body2' : 'subtitle2'}
            >
              {getValue()?.avgScore || '–'}
              {getValue()?.avgScore > 0 && isBad && (
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    ml: 1,
                    width: 8,
                    height: 8,
                    backgroundColor: darkRed[65],
                    borderRadius: '50%',
                  }}
                />
              )}
            </Typography>
          ) : (
            <Typography isLoading={isLoading} preloaderWidthBetween={[70, 80]} variant="body2">
              Выходной
            </Typography>
          );
        },
        sortingFn: defaultAvgSorting,
      },
      {
        header: 'Групповая тренировка',
        accessorKey: 'trainingGroup',
        cell: ({getValue}) => {
          const isBad = getValue()?.avgScore < 3;
          const isDayOff = getValue()?.isDayOff || false;
          return !isDayOff ? (
            <Typography
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
              sx={{position: 'relative'}}
              variant={!getValue()?.avgScore ? 'body2' : 'subtitle2'}
            >
              {getValue()?.avgScore || '–'}
              {getValue()?.avgScore > 0 && isBad && (
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    ml: 1,
                    width: 8,
                    height: 8,
                    backgroundColor: darkRed[65],
                    borderRadius: '50%',
                  }}
                />
              )}
            </Typography>
          ) : (
            <Typography isLoading={isLoading} preloaderWidthBetween={[70, 80]} variant="body2">
              Выходной
            </Typography>
          );
        },
        sortingFn: defaultAvgSorting,
      },
      {
        header: 'Индивидуальная тренировка',
        accessorKey: 'trainingIndividual',
        cell: ({getValue}) => {
          const isBad = getValue()?.avgScore < 3;
          const isDayOff = getValue()?.isDayOff || false;
          return !isDayOff ? (
            <Typography
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
              sx={{position: 'relative'}}
              variant={!getValue()?.avgScore ? 'body2' : 'subtitle2'}
            >
              {getValue()?.avgScore || '–'}
              {getValue()?.avgScore > 0 && isBad && (
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    ml: 1,
                    width: 8,
                    height: 8,
                    backgroundColor: darkRed[65],
                    borderRadius: '50%',
                  }}
                />
              )}
            </Typography>
          ) : (
            <Typography isLoading={isLoading} preloaderWidthBetween={[70, 80]} variant="body2">
              Выходной
            </Typography>
          );
        },
        sortingFn: defaultAvgSorting,
      },
      {
        header: 'Игра',
        accessorKey: 'game',
        cell: ({getValue}) => {
          const isBad = getValue()?.avgScore < 3;
          const isDayOff = getValue()?.isDayOff || false;
          return !isDayOff ? (
            <Typography
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
              sx={{position: 'relative'}}
              variant={!getValue()?.avgScore ? 'body2' : 'subtitle2'}
            >
              {getValue()?.avgScore || '–'}
              {getValue()?.avgScore > 0 && isBad && (
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    ml: 1,
                    width: 8,
                    height: 8,
                    backgroundColor: darkRed[65],
                    borderRadius: '50%',
                  }}
                />
              )}
            </Typography>
          ) : (
            <Typography isLoading={isLoading} preloaderWidthBetween={[70, 80]} variant="body2">
              Выходной
            </Typography>
          );
        },
        sortingFn: defaultAvgSorting,
      },
    ],
    [isLoading],
  );
  const percentsColumns = useMemo(
    () => [
      {
        header: 'Утро',
        accessorKey: 'morning',
        accessorFn: (row) => row?.morning?.percentCompletion || 0,
        cell: ({getValue}) => {
          const isBad = !getValue() || getValue() < 31;
          return (
            <Typography
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
              sx={{position: 'relative'}}
              variant="subtitle2"
            >
              {getValue() || 0}%
              {isBad && (
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    ml: 1,
                    width: 8,
                    height: 8,
                    backgroundColor: darkRed[65],
                    borderRadius: '50%',
                  }}
                />
              )}
            </Typography>
          );
        },
      },
      {
        header: 'Групповая тренировка',
        accessorKey: 'trainingGroup',
        accessorFn: (row) => row?.trainingGroup?.percentCompletion || 0,
        cell: ({getValue}) => {
          const isBad = !getValue() || getValue() < 31;
          return (
            <Typography
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
              sx={{position: 'relative'}}
              variant="subtitle2"
            >
              {getValue() || 0}%
              {isBad && (
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    ml: 1,
                    width: 8,
                    height: 8,
                    backgroundColor: darkRed[65],
                    borderRadius: '50%',
                  }}
                />
              )}
            </Typography>
          );
        },
      },
      {
        header: 'Индивидуальная тренировка',
        accessorKey: 'trainingIndividual',
        accessorFn: (row) => row?.trainingIndividual?.percentCompletion || 0,
        cell: ({getValue}) => {
          const isBad = !getValue() || getValue() < 31;
          return (
            <Typography
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
              sx={{position: 'relative'}}
              variant="subtitle2"
            >
              {getValue() || 0}%
              {isBad && (
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    ml: 1,
                    width: 8,
                    height: 8,
                    backgroundColor: darkRed[65],
                    borderRadius: '50%',
                  }}
                />
              )}
            </Typography>
          );
        },
      },
      {
        header: 'Игра',
        accessorKey: 'game',
        accessorFn: (row) => row?.game?.percentCompletion || 0,
        cell: ({getValue}) => {
          const isBad = !getValue() || getValue() < 31;
          return (
            <Typography
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
              sx={{position: 'relative'}}
              variant="subtitle2"
            >
              {getValue() || 0}%
              {isBad && (
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    ml: 1,
                    width: 8,
                    height: 8,
                    backgroundColor: darkRed[65],
                    borderRadius: '50%',
                  }}
                />
              )}
            </Typography>
          );
        },
      },
    ],
    [isLoading],
  );
  const columns = useDeepMemo(
    () => [
      {
        id: 'student',
        accessorKey: 'student',
        header: 'Игрок',
        sticky: {left: true},
        cell: ({getValue}) => {
          const student = getValue();
          return (
            <Box alignItems="center" display="flex">
              <PlayersHorizontalCard isLoading={isLoading} preloaderWidthBetween={[100, 170]} student={student} />
            </Box>
          );
        },
        sortingFn: 'defaultSorting',
      },
      ...(!isPercentsTab ? defaultColumns : percentsColumns),
      {
        accessorKey: 'student.id',
        header: '',
        id: 'actionCell',
        cell: ({getValue}) => {
          const value = getValue();
          return (
            <Button
              color="primary"
              disabled={!value}
              sx={{px: 0}}
              variant="text"
              onClick={() => {
                if (!isMonth) {
                  history.push(`/development_journal/player/${value}`);
                  setQs({...qs, reportDate: reportDate});
                } else {
                  history.push(`/development_journal/player/${value}`);
                }
              }}
            >
              Смотреть
            </Button>
          );
        },
        enableSorting: false,
      },
    ],
    [isLoading, isPercentsTab, qs],
  );
  const isUpXl = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  return (
    <ReactTableComponent
      columns={columns}
      customScrollBarHeight={isUpXl && data?.length * 50}
      data={!isLoading ? data : dataForTableMock}
      isAvailableHeight={true}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
}

export default Table;
