import moduleNames from '../moduleNames';

export const selectGamesCards = (state) => state[moduleNames.gameStatisticsMkc].gamesCards;
export const selectTableTabTable = (state) => state[moduleNames.gameStatisticsMkc].tableTab.table;
export const selectPlayersTables = (state) => state[moduleNames.gameStatisticsMkc].playersTables;
export const selectPastGames = (state) => state[moduleNames.gameStatisticsMkc].pastGames;
export const selectTableTabNextGames = (state) => state[moduleNames.gameStatisticsMkc].tableTab.nextGames;
export const selectPrepareForOpponentOpponents = (state) =>
  state[moduleNames.gameStatisticsMkc].prepareForOpponentTab.opponentsList;
export const selectOpponentPlayersStats = (state) =>
  state[moduleNames.gameStatisticsMkc].prepareForOpponentTab.playersStats;
export const selectPlayersStatisticsTab = (state, tab) => state[moduleNames.gameStatisticsMkc].players?.[tab];
export const selectMatchesFilter = (state) => state[moduleNames.gameStatisticsMkc].matchesFilter;
export const selectCommonTab = (state) => state[moduleNames.gameStatisticsMkc].commonTab;
export const selectPlayerTournaments = (state) => state[moduleNames.gameStatisticsMkc].playerTournaments;
export const selectPlayerTournamentStat = (state) => state[moduleNames.gameStatisticsMkc].playerTournamentStat;
export const selectPlayerPlayTime = (state) => state[moduleNames.gameStatisticsMkc].playerPlayTime;
export const selectActionsMapData = (state) => state[moduleNames.gameStatisticsMkc].actionsMap.data;
export const selectActionsMapFilters = (state) => state[moduleNames.gameStatisticsMkc].actionsMap.filters;
export const selectActionsMapButtonTypes = (state) => state[moduleNames.gameStatisticsMkc].actionsMap.buttonTypes;

export const selectShotsMapData = (state) => state[moduleNames.gameStatisticsMkc].shotsMap.data;

export const selectShotsMapButtonTypes = (state) => state[moduleNames.gameStatisticsMkc].shotsMap.buttonTypes;

export const selectShotsMapFilters = (state) => state[moduleNames.gameStatisticsMkc].shotsMap.filters;

export const selectMetricStatistics = (state) => state[moduleNames.gameStatisticsMkc].shotsMap.metricStatistics;

export const selectThrowinsFilters = (state) => state[moduleNames.gameStatisticsMkc].throwins.filters;

export const selectThrowinsMetrics = (state) => state[moduleNames.gameStatisticsMkc].throwins.metrics;

export const selectThrowinsMap = (state) => state[moduleNames.gameStatisticsMkc].throwins.map;

export const selectGoaltendersData = (state) => state[moduleNames.gameStatisticsMkc].goaltenders.data;

export const selectGoaltendersFilters = (state) => state[moduleNames.gameStatisticsMkc].goaltenders.filters;

export const selectActionModal = (state) => state[moduleNames.gameStatisticsMkc].actionsMap.actionModal;
