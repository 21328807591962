import {NOTIFICATION_TYPES, UPLOAD_DO_ENDPOINT_URL} from '@src/constants/config';
import {Button} from '@ui/Button';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import axios from 'axios';
import React, {useState} from 'react';

import ReactComponentModal from '../ui/ReactComponent/ReactComponentModal/ReactComponentModal';
import ImageCropPanel from './ImageCropPanel';

export default function ModalCropUploadPanel({cropModalState = false, onClose = () => {}, onChange = () => {}}) {
  const [cropFormData, setCropFormData] = useState(null);

  return (
    <ReactComponentModal
      buttons={
        <>
          <Button
            className={'DialogButton DialogButton_no CropDialogButton'}
            onClick={() => {
              onClose();
            }}
          >
            Продолжить без сохранения
          </Button>
          <Button
            className={'DialogButton DialogButton_yes CropDialogButton'}
            disabled={!cropFormData}
            onClick={() => {
              if (!cropFormData) return;
              axios
                .post(UPLOAD_DO_ENDPOINT_URL, cropFormData)
                .then((d) => d.data.url || d.data.path)
                .then((url) => {
                  onChange(url);
                  onClose();
                })
                .catch(() => {
                  ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при создании миниатюры');
                });
            }}
          >
            Выбрать
          </Button>
        </>
      }
      className="ReactComponentModalDefault LinesNotSaved"
      content={
        <ImageCropPanel
          onChange={(fd) => {
            setCropFormData(fd);
          }}
        />
      }
      title="Выберите изображение"
      visible={cropModalState}
      onClose={onClose}
    />
  );
}
