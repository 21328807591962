import React from 'react';

function MedicinePlus() {
  return (
    <React.Fragment>
      <path
        d="M9.99997 4.34392V6.15608C9.99997 6.2925 9.88944 6.40319 9.75286 6.40319H6.40319V9.75286C6.40319 9.88946 6.2925 9.99997 6.15608 9.99997H4.34392C4.20758 9.99997 4.0968 9.88944 4.0968 9.75286V6.40319H0.747114C0.610588 6.40319 0.5 6.2925 0.5 6.15608V4.34392C0.5 4.20747 0.610588 4.0968 0.747114 4.0968H4.0968V0.747114C4.0968 0.610533 4.20756 0.5 4.34392 0.5H6.15608C6.2925 0.5 6.40319 0.610533 6.40319 0.747114V4.0968H9.75286C9.88947 4.0968 9.99997 4.20747 9.99997 4.34392Z"
        fill="#686868"
      />
    </React.Fragment>
  );
}
export default MedicinePlus;
