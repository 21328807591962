import {Delete, Info} from '@mui/icons-material';
import Box from '@ui/MUI/Box';
import Divider from '@ui/MUI/Divider';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

const ExerciseItemCompact = ({
  flex = '1',
  bgcolor = '#E3E3E3',
  borderRadius = 2,
  name,
  age,
  theme,
  endDecorator,
  minWidth,
  onInfo,
  onDelete,
}) => {
  return (
    <Stack alignItems="center" direction="row" flex={flex} minWidth={minWidth} spacing="4px">
      <Box bgcolor={bgcolor} borderRadius={borderRadius} flex={flex} p="6px 8px">
        <Stack alignItems="center" direction="row" spacing={4}>
          <Stack flex={flex}>
            <Stack p="4px 6px">
              <TypographyDefault variant="body2">{name}</TypographyDefault>
            </Stack>
            <Divider />
            <Stack p="4px 6px">
              <TypographyDefault variant="caption1">
                {theme?.name || 'Тема отсутствует'};{age?.name || 'Возраст отсутствует'}
              </TypographyDefault>
            </Stack>
          </Stack>
          {(endDecorator || onDelete || onInfo) && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                svg: {
                  color: '#0000008A',
                  cursor: 'pointer',
                  fontSize: 20,
                },
              }}
            >
              {onInfo && <Info onClick={onInfo} />}
              {onDelete && <Delete onClick={onDelete} />}
              {endDecorator}
            </Stack>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default ExerciseItemCompact;
