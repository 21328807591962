import moduleNames from '../moduleNames';

export const selectPlayersStatisticsTab = (state, tab) => state[moduleNames.gameStatistics].players?.[tab];

export const selectActionsMapData = (state) => state[moduleNames.gameStatistics].actionsMap.data;

export const selectActionsMapFilters = (state) => state[moduleNames.gameStatistics].actionsMap.filters;

export const selectActionsMapButtonTypes = (state) => state[moduleNames.gameStatistics].actionsMap.buttonTypes;

export const selectShotsMapData = (state) => state[moduleNames.gameStatistics].shotsMap.data;

export const selectShotsMapFilters = (state) => state[moduleNames.gameStatistics].shotsMap.filters;

export const selectShotsMapButtonTypes = (state) => state[moduleNames.gameStatistics].shotsMap.buttonTypes;

export const selectGoaltendersData = (state) => state[moduleNames.gameStatistics].goaltenders.data;

export const selectGoaltendersFilters = (state) => state[moduleNames.gameStatistics].goaltenders.filters;

export const selectMetricStatistics = (state) => state[moduleNames.gameStatistics].shotsMap.metricStatistics;

export const selectThrowinsFilters = (state) => state[moduleNames.gameStatistics].throwins.filters;

export const selectThrowinsMetrics = (state) => state[moduleNames.gameStatistics].throwins.metrics;

export const selectThrowinsMap = (state) => state[moduleNames.gameStatistics].throwins.map;

export const selectVideoModal = (state) => state[moduleNames.gameStatistics].actionModal;

export const selectGameActionDetails = (state) => state[moduleNames.gameStatistics].gameActionDetails;

export const selectTeamActionsSide = (state) => state[moduleNames.gameStatistics].teamActionsSide.isOur;
