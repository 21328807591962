import hockeyStick from '@assets/images/hockeyStick.svg';
import FallBackImage from '@common/FallBackImage';
import PropTypes from 'prop-types';
import React from 'react';

TeamLogo.propTypes = {
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

function TeamLogo({src = '', width = 45, fallbackImg = hockeyStick}) {
  return <FallBackImage alt="logo" fallbackImg={fallbackImg} src={src} width={width} />;
}

export default TeamLogo;
