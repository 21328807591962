import {longDash} from '@src/constants';
import Avatar from '@ui/MUI/Avatar';
import TypographyDefault from '@ui/MUI/Typography';
import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

function PlayersHorizontalCard({
  student,
  isShortName = false,
  hasImage = true,
  onlyNumber = false,
  avatarWidth = {xxs: 20, sm: 28},
  isLoading = false,
  preloaderWidthBetween = [100, 150],
}) {
  const playerName = useMemo(() => {
    let firstName = student?.firstName || '';
    const lastName = student?.lastName || '';
    if (onlyNumber) return '';
    if (!firstName && !lastName && !student?.playerNumber) return longDash;
    if (isShortName) firstName = firstName[0];
    return `${lastName} ${firstName}`;
  }, [student?.playerNumber, student?.lastName, student?.firstName, isShortName, onlyNumber]);

  return (
    <>
      {hasImage && !onlyNumber && (
        <Avatar isLoading={isLoading} src={student?.avatar} sx={{mr: 1}} width={avatarWidth} />
      )}
      <LinesAvailablePlayerName
        className="LinesAvailablePlayerName"
        isLoading={isLoading}
        preloaderWidthBetween={preloaderWidthBetween}
        variant="body2"
      >
        <LinesAvailablePlayerNumber variant="body2">{student?.playerNumber || ''}</LinesAvailablePlayerNumber>
        {playerName}
      </LinesAvailablePlayerName>
    </>
  );
}

export default memo(PlayersHorizontalCard);

const LinesAvailablePlayerName = styled(TypographyDefault)`
  display: inline-block;
  font-weight: 600;
  flex-wrap: wrap;
  z-index: 2;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
`;
const LinesAvailablePlayerNumber = styled(TypographyDefault)`
  display: inline-block;
  margin-right: ${({theme}) => theme.spacing(0)};
  font-weight: 600;
  color: #887755;
`;
