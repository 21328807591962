import React from 'react';

function GoalIcon(props) {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.72351 10.4034C6.36991 11.0178 7.16671 11.325 8.11391 11.325C9.17631 11.325 10.0563 10.9378 10.7539 10.1634V7.76341H7.72031V8.75221H9.63071V9.75061C9.46431 9.90421 9.24351 10.0386 8.96831 10.1538C8.69951 10.269 8.41471 10.3266 8.11391 10.3266C7.48031 10.3266 6.95231 10.109 6.52991 9.67381C6.11391 9.23221 5.90591 8.67541 5.90591 8.00341C5.90591 7.33141 6.11391 6.77781 6.52991 6.34261C6.95231 5.90741 7.48031 5.68981 8.11391 5.68981C8.45951 5.68981 8.77631 5.76981 9.06431 5.92981C9.35871 6.08981 9.59551 6.29461 9.77471 6.54421L10.6963 6.02581C10.0755 5.13621 9.21471 4.69141 8.11391 4.69141C7.16671 4.69141 6.36991 4.99861 5.72351 5.61301C5.07711 6.22101 4.75391 7.01781 4.75391 8.00341C4.75391 8.98901 5.07711 9.78901 5.72351 10.4034Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default GoalIcon;
