import {Stack, Typography} from '@ui/MUI';
import React from 'react';

import Table from '../Table';

const Category = ({name, estimates}) => {
  if (estimates.length === 0) return null;
  return (
    <Stack spacing={3}>
      <Stack px={4}>
        <Typography variant="caption2">Группа навыков</Typography>
        <Typography variant="h4">{name}</Typography>
      </Stack>
      <Table estimates={estimates} />
    </Stack>
  );
};

export default Category;
