import dateInterface from '@helpers/date';
import {TimerOutlined} from '@mui/icons-material';
import {Stack, Tooltip, Typography} from '@ui/MUI';
import {primary} from '@ui/MUI/colors';
import React from 'react';

const EstimateControlDate = ({date, ...props}) => {
  const formattedDate = dateInterface(date).format('DD.MM.YYYY');
  const isDateBeforeToday = dateInterface(date).isBefore(dateInterface().innerDate, 'day');

  const iconSx = {
    fontSize: '16px',
    color: primary.main,
  };
  return (
    <Stack alignItems="center" direction="row" spacing="6px" {...props}>
      <Typography variant="body2">{formattedDate}</Typography>
      {isDateBeforeToday && (
        <Tooltip title="Дата контроля прошла">
          <TimerOutlined sx={iconSx} />
        </Tooltip>
      )}
    </Stack>
  );
};
export default EstimateControlDate;
