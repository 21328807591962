import Accordion from '@common/Accordion';
import Stack from '@ui/MUI/Stack';
import React from 'react';
import styled from 'styled-components';

import AccordionContent from './components/AccordionContent';

const Container = styled(Stack)`
  & .MuiAccordion-root {
    border-radius: 0px;
  }
`;

const Accordions = ({data, open}) => {
  return (
    <Container flex="1">
      {data.map((section) => (
        <Accordion key={section.id} title={section.name}>
          <AccordionContent {...section} createCategory={open} />
        </Accordion>
      ))}
    </Container>
  );
};

export default Accordions;
