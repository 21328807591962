import LinesAPI from '@api/LinesAPI';
import {getMKCTournamentById} from '@api/mkcStatisticsAPI';
import {getTournamentById} from '@api/TournamentsAPI';
import avatar from '@assets/icons/person.svg';
import date from '@helpers/date';
import {useGroup, useSchool} from '@hooks/filters';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import {Button} from '@ui/Button';
import Icon from '@ui/Icon/Icon';
import InputDefault from '@ui/Input';
import PageTitleDefault from '@ui/PageTitleDefault';
import ReactComponentModal from '@ui/ReactComponent/ReactComponentModal/ReactComponentModal';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import TabsDefault from '@ui/ReactComponent/ReactComponentTabs/TabsDefault/TabsDefault';
import SelectDefault from '@ui/Select';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import queryString from 'query-string';
import React, {useEffect, useState} from 'react';
import {shallowEqual} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {defensemenPositions, userRoles, wingerPositions} from '../../../../constants';
import {NOTIFICATION_TYPES} from '../../../../constants/config';
import CommonHelper from '../../../../helpers/CommonHelper';
import StateHelper from '../../../../helpers/StateHelper';
import {
  cleanLinesForm,
  getLinesForm,
  getTeamFavoritesLines,
  getTeamLinesHistory,
  handleChangeForm,
  handleFavoriteLinesChangeList,
  saveLinesForm,
  setActualHistoryLines,
  setActualLines,
} from '../../../../redux/actions/LinesActions';
import LocalPreloader from '../../../preloader/LocalPreloader';
import {
  LinesTabLine,
  LinesTabLineColumnDefensemen,
  LinesTabLineColumnGoaltenders,
  LinesTabLineColumnWingers,
  LinesTabLineNumber,
  TeacherHockeyLinesPlayerCards,
} from '../../../shared/lines/styles/style';
import PlayersHorizontalCard from '../../../students/tools/PlayersHorizontalCard';
import BasketPart from '../../../teachers/panels/TeacherHockeyLinesPanel/components/BasketPart';
import TeamPart from '../../../teachers/panels/TeacherHockeyLinesPanel/components/TeamPart';
import {LinesFilter} from '../../tools/LinesFilter';
import TeacherHockeyLinesPlayerCard from '../TeacherHockeyLinesPanel/components/TeacherHockeyLinesPlayerCard';
import TeacherHockeyLinesPlayerEmptyCard from '../TeacherHockeyLinesPanel/components/TeacherHockeyLinesPlayerEmptyCard';
import LinesMobile from './components/LinesMobile';
import {PrintElem} from './helper';

export default function TeacherHockeyLinesPanel() {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const qs = queryString.parse(history.location.search);

  let [cancelModalState, setCancelModalState] = useState(false);
  let [clearLinesModalState, setClearLinesModalState] = useState(false);
  let [addToFavoritesModalState, setAddToFavoritesModalState] = useState(false);
  let [removeFromFavoritesModalState, setRemoveFromFavoritesModalState] = useState(false);
  let [makeRelevantModalState, setMakeRelevantModalState] = useState(false);
  let [linesNotSavedModalState, setLinesNotSavedModalState] = useState(false);
  let [basket, setBasket] = useState([]);
  const [nearestGame, setNearestGame] = useState();
  const [nearestTournament, setNearestTournament] = useState();
  useEffect(() => {
    if (nearestGame?.tournamentId) {
      getTournamentById(nearestGame?.tournamentId)?.then((res) => {
        setNearestTournament(res.data.name);
      });
    }
    if (nearestGame?.mkcTournamentId) {
      getMKCTournamentById(nearestGame?.mkcTournamentId)?.then((res) => {
        setNearestTournament(res.data.name);
      });
    }
  }, [nearestGame?.tournamentId, nearestGame?.mkcTournamentId]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const school = useSchool({});
  const group = useGroup({connectorValue: school?.value?.value});

  const [selectedSchool, setSelectedSchool] = useState();
  const [selectedTeam, setSelectedTeam] = useState();

  const getNearGame = async (teamId) => {
    const nearestGame = await LinesAPI.getNearestGame(teamId);
    setNearestGame(nearestGame.data);
    setTeamName(group.options.find((option) => option.id === nearestGame.data.groupId)?.name);
  };

  useEffect(() => {
    if (selectedTeam?.id) {
      getNearGame(selectedTeam?.id);
    }
  }, [selectedTeam?.id]);

  useEffect(() => {
    if (qs?.school && qs?.team && school?.options.length > 0) {
      setSelectedSchool(school.options.find((option) => option.value === qs.school));
      setSelectedTeam(group.options.find((option) => option.id === qs.team));
    }
  }, []);
  const [filters, setFilters] = useState({
    school: null,
    group: null,
  });

  const [activeHistory, setActiveHistory] = useState({});
  const [isActiveCard, setIsActiveCard] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [modalGroupOptions, setModalGroupOptions] = useState([]);
  const [modalSelectedGroup, setModalSelectedGroup] = useState('');
  const [modalSelectedPlayer, setModalSelectedPlayer] = useState('');
  const [modalPlayersOptions, setModalPlayersOptions] = useState([]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('0');
  const [linesName, setLinesName] = useState('');
  const [favoriteNameErrorText, setFavoriteNameErrorText] = useState('Необходимо ввести название состава');
  const [isFavoriteNameErrorState, setIsFavoriteNameErrorState] = useState(false);
  const today = date(new Date()).format('DD.MM.YY');
  const titleDate = 'на ' + today;
  const [teamName, setTeamName] = useState('Академия авангард');
  const {userRole, loading, linesForm, linesFavoriteList, linesHistory, state} = useStore((state) => {
    const currentUser = state.users.usersMap.get(state.users.currentUserId);
    const userRole = currentUser?.userRole;

    return {
      userRole: userRole,
      currentUser: currentUser,
      loading: state.lines.loading,
      linesForm: state.lines.linesForm,
      linesFavoriteList: state.lines.linesFavoriteList || [],
      linesHistory: state.lines.linesHistory || [],
      state: state,
    };
  }, shallowEqual);

  useEffect(() => {
    if (selectedSchool?.value && selectedTeam?.id) {
      setFilters({school: selectedSchool, group: selectedTeam});
    }
  }, [selectedSchool?.value, selectedTeam?.id]);
  const allGroupsOptions = useGroup({isGetAll: true}).options;
  useEffect(() => {
    if (modalIsVisible) {
      setModalGroupOptions(
        CommonHelper.getDropDownItemsByNamedList(allGroupsOptions).filter((option) => option.value !== selectedTeam.id),
      );
    }
  }, [modalIsVisible]);

  const getLinesData = () => {
    if (selectedTeam?.id) {
      dispatch(getLinesForm({...filters, team: selectedTeam?.id})).catch(() => {
        dispatch(cleanLinesForm());
      });
    } else {
      dispatch(cleanLinesForm());
    }
  };

  const cartPlayerToggle = (player) => {
    setIsActiveCard(!isActiveCard);
    setSelectedPlayer(player);
  };

  const isValidPlayerForLine = (player, playerType, lineIndex) => {
    let result = true;

    if (player === undefined) {
      ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при добавлении игрока в звено');
      return false;
    }

    if (playerType === 'goaltenders') {
      result = !linesForm.goaltendersLine.find((linePlayer) => (linePlayer ? linePlayer.id === player.id : false));
    } else {
      result =
        !!linesForm.playersLines[lineIndex]['wingersLine'].find((linePlayer) =>
          linePlayer ? linePlayer.id === player.id : false,
        ) ||
        !!linesForm.playersLines[lineIndex]['defensemansLine'].find((linePlayer) =>
          linePlayer ? linePlayer.id === player.id : false,
        );
      result = !result;
    }

    if (!result) {
      ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Игрок уже добавлен в звено');
    }

    return result;
  };

  const selectPlayerPosition = (playersType, lineIndex, playerPosition, selectedPlayer) => {
    if (!isValidPlayerForLine(selectedPlayer, playersType, lineIndex)) return;
    let clonePlayersLinesForm = linesForm ? JSON.parse(JSON.stringify(linesForm)) : [];
    clonePlayersLinesForm.playersLines[lineIndex][playersType][playerPosition] = selectedPlayer;
    dispatch(handleChangeForm(clonePlayersLinesForm));
    setIsActiveCard(false);
  };
  const deletePlayerFromPosition = (playersType, lineIndex, playerPosition) => {
    let clonePlayersLinesForm = linesForm ? JSON.parse(JSON.stringify(linesForm)) : [];
    clonePlayersLinesForm.playersLines[lineIndex][playersType][playerPosition] = null;
    dispatch(handleChangeForm(clonePlayersLinesForm));
  };
  const selectGoaltenderPlayerPosition = (playerPosition, selectedPlayer) => {
    if (!isValidPlayerForLine(selectedPlayer, 'goaltenders')) return;
    const cloneLinesForm = linesForm ? JSON.parse(JSON.stringify(linesForm)) : [];
    cloneLinesForm.goaltendersLine[playerPosition] = selectedPlayer;
    dispatch(handleChangeForm(cloneLinesForm));
    setIsActiveCard(false);
  };
  const deleteGoaltenderPlayerFromPosition = (playerPosition) => {
    let clonePlayersLinesForm = linesForm ? JSON.parse(JSON.stringify(linesForm)) : [];
    clonePlayersLinesForm.goaltendersLine[playerPosition] = null;
    dispatch(handleChangeForm(clonePlayersLinesForm));
  };

  const handleDeleteLine = (lineName) => {
    if (!lineName) return;

    let cloneLines = linesForm.playersLines ? [...linesForm.playersLines] : [];
    let currentLinesForm = linesForm ? {...linesForm} : {};

    cloneLines.splice(
      cloneLines.findIndex((i) => i.name === lineName),
      1,
    );

    currentLinesForm.playersLines = cloneLines;
    dispatch(handleChangeForm(currentLinesForm));
  };

  const handleAddLine = () => {
    let cloneLines = linesForm.playersLines ? [...linesForm.playersLines] : [];
    let currentLinesForm = linesForm ? {...linesForm} : {};

    cloneLines.push({
      name: 'playersLine_' + (Number(linesForm.playersLines.length) + 1),
      wingersLine: [],
      defensemansLine: [],
    });
    currentLinesForm.playersLines = cloneLines;
    dispatch(handleChangeForm(currentLinesForm));
  };
  useEffect(() => {
    if (modalSelectedGroup.value) {
      StateHelper.getStudentsByTeamId(modalSelectedGroup.value).then((options) => {
        setModalPlayersOptions(options.filter(isPlayerInBasket));
      });
    }
  }, [modalSelectedGroup.value]);

  useEffect(() => {
    if (qs.history && selectedTeam?.id) {
      changeTab('2');
    }
  }, [qs.history, selectedTeam?.id]);

  useEffect(() => {
    if (selectedSchool?.value && selectedTeam?.id) {
      getLinesData();
    }
  }, [selectedSchool?.value, selectedTeam?.id, activeTab]);

  const handleAddPlayerToBasket = (player) => {
    let cloneBasket = basket ? [...basket] : [];

    const student = state.users.usersMap.get(player.value);

    let result = Object.assign({}, {id: player.value, name: player.label}, {student: student});

    cloneBasket.unshift(result);

    setBasket(cloneBasket);
  };

  const modalVisibleToggle = () => {
    setModalIsVisible(!modalIsVisible);
  };

  function isPlayerInBasket(value) {
    const basketIds = basket.map((item) => item.id);
    return basketIds.indexOf(value.student.id) === -1;
  }

  const saveLines = (customSuccessText) => {
    let cloneLinesForm = JSON.parse(JSON.stringify(linesForm));

    cloneLinesForm.isFavorites = false;
    delete cloneLinesForm.objectId;
    cloneLinesForm.teamId = selectedTeam?.id;

    dispatch(saveLinesForm(cloneLinesForm, customSuccessText));
  };

  const addLinesToFavorite = () => {
    let currentLinesForm = linesForm ? {...linesForm, isFavorites: true, favoritesName: linesName.trim()} : {};

    dispatch(
      saveLinesForm(
        currentLinesForm,
        <>
          <NotificationText>Cостав «{linesName.trim()}» добавлен в Избранное</NotificationText>
          <NotificationSubText>
            Вы можете увидеть состав в{' '}
            <NotificationLink
              onClick={() => {
                changeTab('1');
              }}
            >
              списке избранных
            </NotificationLink>
          </NotificationSubText>
        </>,
      ),
    );
  };
  const deleteLinesFromFavorite = () => {
    let currentLinesForm = linesForm ? {...linesForm} : {};
    currentLinesForm.isFavorites = false;
    currentLinesForm.favoritesName = null;

    let newLinesFavoriteList = linesFavoriteList.filter((item) => item.objectId !== currentLinesForm.objectId);

    dispatch(saveLinesForm(currentLinesForm, 'Состав удален из избранного')).then(() => {
      if (activeTab === '1') {
        cleanLines();
        dispatch(handleFavoriteLinesChangeList(newLinesFavoriteList));
      }
    });
  };

  const changeTab = (tabIndex) => {
    setActiveTab(tabIndex);
    cleanLines();
    const teamId = selectedTeam?.id;
    if (tabIndex === '1') {
      dispatch(getTeamFavoritesLines(teamId));
    }
    if (tabIndex === '0') {
      getLinesData();
    }
    if (tabIndex === '2') {
      dispatch(getTeamLinesHistory(teamId));
    }
  };

  const cleanLines = () => {
    let currentLinesForm = linesForm ? JSON.parse(JSON.stringify(linesForm)) : {};
    currentLinesForm.playersLines = [
      {
        name: 'playersLine_1',
        wingersLine: [],
        defensemansLine: [],
      },
      {
        name: 'playersLine_2',
        wingersLine: [],
        defensemansLine: [],
      },
      {
        name: 'playersLine_3',
        wingersLine: [],
        defensemansLine: [],
      },
      {
        name: 'playersLine_4',
        wingersLine: [],
        defensemansLine: [],
      },
    ];
    currentLinesForm.goaltendersLine = [];
    currentLinesForm.isFavorites = false;
    currentLinesForm.favoritesName = null;
    dispatch(handleChangeForm(currentLinesForm));
  };

  useEffect(() => {
    if (activeTab === '2' && selectedTeam?.id) {
      dispatch(getTeamLinesHistory(selectedTeam?.id));
    }
  }, [activeTab, selectedTeam?.id]);

  const handleValidNameForFavorite = (name) => {
    let result = true;
    const minLength = 3;
    const maxLength = 100;
    if (!name) {
      result = false;
      setFavoriteNameErrorText('Необходимо ввести название состава');
      setIsFavoriteNameErrorState(true);

      return result;
    }

    if (name.length > maxLength || name.length < minLength) {
      result = false;
      setFavoriteNameErrorText(`Минимум ${minLength} и максимум ${maxLength} символов`);
      setIsFavoriteNameErrorState(true);

      return result;
    }

    return result;
  };

  const isEmptyLines = () => {
    let result = false;
    let countOfEmptyLines = 0;

    linesForm.playersLines.map((line) => {
      if (line.wingersLine.length === 0 && line.defensemansLine.length === 0) {
        countOfEmptyLines++;
      }
    });

    if (linesForm.goaltendersLine.length === 0 && countOfEmptyLines === linesForm.playersLines.length) {
      result = true;
    }

    return result;
  };

  useEffect(() => {
    if (linesFavoriteList.length && activeTab === '1') {
      dispatch(handleChangeForm(linesFavoriteList[0]));
    }
  }, [linesFavoriteList]);
  useEffect(() => {
    if (linesHistory.length && activeTab === '2') {
      dispatch(handleChangeForm(linesHistory[0].composition));
      setActiveHistory(linesHistory[0]);
    }
    if (qs.history && qs.gameId) {
      const gameLine = linesHistory.find((history) => history.game?.objectId === qs.gameId);
      if (gameLine) {
        dispatch(handleChangeForm(gameLine.composition));
        setActiveHistory(gameLine);
      }
    }
  }, [linesHistory]);

  const isCanEdit = userRole === userRoles.teacher || userRole === userRoles.franchisee;

  return (
    <Wrapper isMobile={isMobile}>
      <>
        <PageTitleDefault className="PageTitleDefault" text="Состав" />

        {/* TODO убрать onChangePlayers*/}
        <LinesFilter
          filters={filters}
          qs={qs}
          selectedSchool={selectedSchool}
          selectedTeam={selectedTeam}
          setSelectedSchool={setSelectedSchool}
          setSelectedTeam={setSelectedTeam}
          onChange={(filterData) => {
            setFilters(filterData);
          }}
          onChangePlayers={(players) => {
            setBasket(players);
          }}
        />

        {!isMobile ? (
          <Lines className={!isCanEdit ? 'read-only-lines' : ''}>
            <TabsDefault
              activeKey={activeTab ? activeTab : 0}
              className={`TabsDefault `}
              cssBackgroundModificator="withBg"
              cssPositionModificator="left"
              tabs={[
                {
                  label: 'Состав игроков',
                  content: (
                    <LinesBody className="LinesBody" loading={loading}>
                      <LocalPreloader visible={loading} />
                      <TeamPart
                        content={
                          <>
                            <LinesBlockHead className={'LinesBlockHead'}>
                              <LinesBlockHeadColumns className={'LinesBlockHeadColumns'}>
                                <LinesBlockHeadColumn className={'LinesBlockHeadColumn'}>
                                  <LinesBlockHeadFlexContainer className={'LinesBlockHeadFlexContainer'}>
                                    <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>№ звена</LinesBlockHeadTitle>
                                    <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                      Атакующая линия
                                    </LinesBlockHeadTitle>
                                  </LinesBlockHeadFlexContainer>
                                </LinesBlockHeadColumn>
                                <LinesBlockHeadColumn className={'LinesBlockHeadColumn'}>
                                  <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                    Оборонительная линия
                                  </LinesBlockHeadTitle>
                                </LinesBlockHeadColumn>
                              </LinesBlockHeadColumns>
                            </LinesBlockHead>
                            <LinesTabLines className={'LinesTabLines'}>
                              {linesForm
                                ? linesForm.playersLines
                                  ? linesForm.playersLines.map((it, lineIndex) => {
                                      return (
                                        <LinesTabLineContainer className={'LinesTabLineContainer'} key={lineIndex}>
                                          <LinesTabLine className={'LinesTabLine'}>
                                            <LinesTabLineNumber className={'LinesTabLineNumber'}>
                                              Звено №{lineIndex + 1}
                                            </LinesTabLineNumber>
                                            <LinesTabLineColumnWingers className={'LinesTabLineColumnWingers'}>
                                              <TeacherHockeyLinesPlayerCards
                                                className={'TeacherHockeyLinesPlayerCards'}
                                              >
                                                {['', '', ''].map((winger, wk) => {
                                                  if (it.wingersLine[wk]) {
                                                    return (
                                                      <TeacherHockeyLinesPlayerCard
                                                        className={'TeacherHockeyLinesPlayerCard'}
                                                        key={wk}
                                                        player={it.wingersLine[wk]}
                                                        position={wingerPositions[wk]}
                                                        onClick={() => {
                                                          if (isActiveCard) {
                                                            selectPlayerPosition(
                                                              'wingersLine',
                                                              lineIndex,
                                                              wk,
                                                              selectedPlayer,
                                                            );
                                                          }
                                                        }}
                                                        onDelete={() => {
                                                          deletePlayerFromPosition('wingersLine', lineIndex, wk);
                                                        }}
                                                      />
                                                    );
                                                  } else {
                                                    return (
                                                      <TeacherHockeyLinesPlayerEmptyCard
                                                        className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                        isActive={isActiveCard}
                                                        key={wk}
                                                        lineNumber={lineIndex + 1}
                                                        position={wingerPositions[wk]}
                                                        onClick={() => {
                                                          if (isActiveCard) {
                                                            selectPlayerPosition(
                                                              'wingersLine',
                                                              lineIndex,
                                                              wk,
                                                              selectedPlayer,
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    );
                                                  }
                                                })}
                                              </TeacherHockeyLinesPlayerCards>
                                            </LinesTabLineColumnWingers>
                                            <LinesTabLineColumnDefensemen className={'LinesTabLineColumnDefensemen'}>
                                              <TeacherHockeyLinesPlayerCards
                                                className={'TeacherHockeyLinesPlayerCards'}
                                              >
                                                {['', ''].map((defenseman, dk) => {
                                                  if (it.defensemansLine[dk]) {
                                                    return (
                                                      <TeacherHockeyLinesPlayerCard
                                                        className={'TeacherHockeyLinesPlayerCard'}
                                                        key={dk}
                                                        player={it.defensemansLine[dk]}
                                                        position={defensemenPositions[dk]}
                                                        onClick={() => {
                                                          if (isActiveCard) {
                                                            selectPlayerPosition(
                                                              'defensemansLine',
                                                              lineIndex,
                                                              dk,
                                                              selectedPlayer,
                                                            );
                                                          }
                                                        }}
                                                        onDelete={() => {
                                                          deletePlayerFromPosition('defensemansLine', lineIndex, dk);
                                                        }}
                                                      />
                                                    );
                                                  } else {
                                                    return (
                                                      <TeacherHockeyLinesPlayerEmptyCard
                                                        className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                        isActive={isActiveCard}
                                                        key={dk}
                                                        lineNumber={lineIndex + 1}
                                                        position={defensemenPositions[dk]}
                                                        onClick={() => {
                                                          if (isActiveCard) {
                                                            selectPlayerPosition(
                                                              'defensemansLine',
                                                              lineIndex,
                                                              dk,
                                                              selectedPlayer,
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    );
                                                  }
                                                })}
                                              </TeacherHockeyLinesPlayerCards>
                                            </LinesTabLineColumnDefensemen>
                                          </LinesTabLine>
                                          <ButtonDelete
                                            className={'hide-when-print'}
                                            onClick={() => {
                                              handleDeleteLine(it.name);
                                            }}
                                          >
                                            <Icon height={17} type={'CloseRed'} viewBox={'0 0 17 17'} width={17} />
                                          </ButtonDelete>
                                        </LinesTabLineContainer>
                                      );
                                    })
                                  : null
                                : null}
                              {linesForm && (
                                <LinesTabLineContainerGoaltenders className={'LinesTabLineContainerGoaltenders'}>
                                  <LinesTabLine className={'LinesTabLine'}>
                                    <LinesTabLineNumber className={'LinesTabLineNumber'}>Вратари</LinesTabLineNumber>
                                    <LinesTabLineColumnGoaltendersHead className={'LinesTabLineColumnGoaltendersHead'}>
                                      <LinesTabLineColumnGoaltendersHeadColumn
                                        className={'LinesTabLineColumnGoaltendersHeadColumn'}
                                      >
                                        <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                          Состав вратарей
                                        </LinesBlockHeadTitle>
                                      </LinesTabLineColumnGoaltendersHeadColumn>
                                    </LinesTabLineColumnGoaltendersHead>
                                    <LinesTabLineColumnGoaltenders className={'LinesTabLineColumnGoaltenders'}>
                                      <TeacherHockeyLinesPlayerCards className={'TeacherHockeyLinesPlayerCards'}>
                                        {['', '', ''].map((goaltender, gk) => {
                                          if (
                                            linesForm.goaltendersLine &&
                                            linesForm.goaltendersLine.length &&
                                            linesForm.goaltendersLine[gk]
                                          ) {
                                            return (
                                              <TeacherHockeyLinesPlayerCard
                                                className={'TeacherHockeyLinesPlayerCard'}
                                                key={gk}
                                                player={linesForm.goaltendersLine[gk]}
                                                position={'вр'}
                                                readOnly={!isCanEdit}
                                                onClick={() => {
                                                  if (isActiveCard) {
                                                    selectGoaltenderPlayerPosition(gk, selectedPlayer);
                                                  }
                                                }}
                                                onDelete={() => {
                                                  deleteGoaltenderPlayerFromPosition(gk);
                                                }}
                                              />
                                            );
                                          } else {
                                            return (
                                              <TeacherHockeyLinesPlayerEmptyCard
                                                className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                isActive={isActiveCard}
                                                key={gk}
                                                position={'вр'}
                                                readOnly={!isCanEdit}
                                                onClick={() => {
                                                  if (isActiveCard) {
                                                    selectGoaltenderPlayerPosition(gk, selectedPlayer);
                                                  }
                                                }}
                                              />
                                            );
                                          }
                                        })}
                                      </TeacherHockeyLinesPlayerCards>
                                    </LinesTabLineColumnGoaltenders>
                                  </LinesTabLine>
                                </LinesTabLineContainerGoaltenders>
                              )}
                            </LinesTabLines>
                          </>
                        }
                        headline={
                          isCanEdit ? (
                            <>
                              <LinesTabHeadLineButtons>
                                <Button
                                  className="AddLineButton hide-when-print"
                                  onClick={() => {
                                    handleAddLine();
                                  }}
                                >
                                  <Icon height={15} type={'AddCircle'} viewBox={'0 0 15 15'} width={15} />
                                  Добавить звено
                                </Button>
                                <Button
                                  className="ClearLineButton hide-when-print"
                                  onClick={() => {
                                    setClearLinesModalState(true);
                                  }}
                                >
                                  <Icon height={15} type={'Delete'} viewBox={'0 0 15 15'} width={15} />
                                  Очистить состав звеньев
                                </Button>
                                <Button
                                  className="AddLineToFavoritesButton hide-when-print"
                                  onClick={() => {
                                    if (isEmptyLines()) {
                                      ReactComponentNotification(
                                        NOTIFICATION_TYPES['error'],
                                        'Пустой состав нельзя добавлять в избранное',
                                      );
                                      return;
                                    }
                                    setAddToFavoritesModalState(true);
                                    setLinesName('');
                                  }}
                                >
                                  <Icon height={15} type={'StarFavorites'} viewBox={'0 0 15 15'} width={15} />
                                  Добавить в избранное
                                </Button>
                              </LinesTabHeadLineButtons>
                              {nearestGame ? (
                                <HeadlineTextBlock>
                                  <HeadlineTextBlockTitle>Ближайшая игра</HeadlineTextBlockTitle>
                                  <HeadlineTextBlockInfo>
                                    <HeadlineTextBlockInfoDate>
                                      {date(nearestGame.rooms[0].startTimestamp).format('DD.MM.YYYY')}{' '}
                                    </HeadlineTextBlockInfoDate>
                                    <HeadlineTextBlockInfoTime>
                                      {date(nearestGame.rooms[0].startTimestamp).format('HH:mm')}{' '}
                                    </HeadlineTextBlockInfoTime>
                                    {nearestGame.type === 'game' ? (
                                      <HeadlineTextBlockInfoDark>
                                        {nearestTournament && `"${nearestTournament}"`}{' '}
                                        {`${teamName} - ${nearestGame?.opponent}`}{' '}
                                      </HeadlineTextBlockInfoDark>
                                    ) : (
                                      <HeadlineTextBlockInfoDark>
                                        {nearestTournament && `"${nearestTournament}"`}{' '}
                                        {`${nearestGame?.opponent} - ${teamName}`}{' '}
                                      </HeadlineTextBlockInfoDark>
                                    )}
                                  </HeadlineTextBlockInfo>
                                </HeadlineTextBlock>
                              ) : null}
                            </>
                          ) : null
                        }
                      />
                      <BasketPart
                        content={
                          <LinesAvailablePlayersBlock className="LinesAvailablePlayersBlock sticky">
                            <TabsDefault
                              cssBackgroundModificator="withBg"
                              cssPositionModificator="left"
                              tabs={[
                                {
                                  label: 'Атакующая линия',
                                  content: (
                                    //TO DO сделать все через один компонент
                                    <LinesAvailablePlayersContainer>
                                      <LinesAvailablePlayers>
                                        {!basket.length ? (
                                          <TableEmptyBlock
                                            text={
                                              'Недостаточно данных. Для отображения формы выберите школу и команду.'
                                            }
                                          />
                                        ) : (
                                          basket
                                            .filter((student) => student.student.hockeyRole === 'winger')
                                            .sort((a, b) => {
                                              if (a.student.lastName.toLowerCase() > b.student.lastName.toLowerCase()) {
                                                return 1;
                                              }
                                              if (a.student.lastName.toLowerCase() < b.student.lastName.toLowerCase()) {
                                                return -1;
                                              }
                                              return 0;
                                            })
                                            .map((it, k) => {
                                              return (
                                                <BasketPartItem
                                                  data-player-name={`${it.student?.lastName} ${it.student?.firstName}`}
                                                  key={k}
                                                >
                                                  <LinesAvailablePlayerContain
                                                    className={
                                                      isActiveCard && selectedPlayer.id === it.student.id
                                                        ? 'active'
                                                        : ''
                                                    }
                                                    onClick={(e) => {
                                                      if (!isCanEdit) return;
                                                      if (it.student?.status !== 'available') {
                                                        ReactComponentNotification(
                                                          NOTIFICATION_TYPES['info'],
                                                          `Игрок недоступен для выбора`,
                                                        );
                                                        return;
                                                      }
                                                      cartPlayerToggle(it.student);
                                                      e.preventDefault();
                                                    }}
                                                  />
                                                  <Button className="LinesAvailablePlayerButton">
                                                    <Icon
                                                      height={9}
                                                      type="LeftArrowWithLine"
                                                      viewBox={'0 0 15 9'}
                                                      width={15}
                                                    />
                                                  </Button>
                                                  <PlayersHorizontalCard student={it.student} />
                                                  <LinesAvailablePlayerHand>
                                                    <LinesAvailablePlayerHandFlexContainer>
                                                      <LinesAvailablePlayerHandIconContainer>
                                                        <Icon
                                                          height={13}
                                                          type="HockeyStickForHand"
                                                          viewBox={'0 0 13 13'}
                                                          width={13}
                                                        />
                                                      </LinesAvailablePlayerHandIconContainer>
                                                      <span>{it.student.hand === 'right' ? 'Правая' : 'Левая'}</span>
                                                    </LinesAvailablePlayerHandFlexContainer>
                                                  </LinesAvailablePlayerHand>
                                                  <LinesAvailablePlayerStatus
                                                    className={
                                                      it.student.status === 'available'
                                                        ? `LinesAvailablePlayerStatus LinesAvailablePlayerStatus_available`
                                                        : `LinesAvailablePlayerStatus_notavailable LinesAvailablePlayerStatus`
                                                    }
                                                  >
                                                    {it.student.status === 'available' ? 'Доступен' : 'Недоступен'}
                                                  </LinesAvailablePlayerStatus>
                                                </BasketPartItem>
                                              );
                                            })
                                        )}
                                      </LinesAvailablePlayers>
                                    </LinesAvailablePlayersContainer>
                                  ),
                                },
                                {
                                  label: 'Оборонительная линия',
                                  content: (
                                    <LinesAvailablePlayersContainer>
                                      <LinesAvailablePlayers>
                                        {!basket.length ? (
                                          <TableEmptyBlock
                                            text={'Недостаточно данных. Для отображения формы выберите школу и команду'}
                                          />
                                        ) : (
                                          basket
                                            .filter((student) => student.student.hockeyRole === 'defenseman')
                                            .sort((a, b) => {
                                              if (a.student.lastName.toLowerCase() > b.student.lastName.toLowerCase()) {
                                                return 1;
                                              }
                                              if (a.student.lastName.toLowerCase() < b.student.lastName.toLowerCase()) {
                                                return -1;
                                              }
                                              return 0;
                                            })
                                            .map((it, k) => {
                                              return (
                                                <BasketPartItem key={k}>
                                                  <LinesAvailablePlayerContain
                                                    className={
                                                      isActiveCard && selectedPlayer.id === it.student.id
                                                        ? 'active'
                                                        : ''
                                                    }
                                                    onClick={(e) => {
                                                      if (!isCanEdit) return;
                                                      if (it.student?.status !== 'available') {
                                                        ReactComponentNotification(
                                                          NOTIFICATION_TYPES['info'],
                                                          `Игрок недоступен для выбора`,
                                                        );
                                                        return;
                                                      }
                                                      cartPlayerToggle(it.student);
                                                      e.preventDefault();
                                                    }}
                                                  />
                                                  <Button
                                                    //onClick={() => {}}
                                                    className="LinesAvailablePlayerButton"
                                                  >
                                                    <Icon
                                                      height={9}
                                                      type="LeftArrowWithLine"
                                                      viewBox={'0 0 15 9'}
                                                      width={15}
                                                    />
                                                  </Button>
                                                  <PlayersHorizontalCard student={it.student} />
                                                  <LinesAvailablePlayerHand>
                                                    <LinesAvailablePlayerHandFlexContainer>
                                                      <LinesAvailablePlayerHandIconContainer>
                                                        <Icon
                                                          height={13}
                                                          type="HockeyStickForHand"
                                                          viewBox={'0 0 13 13'}
                                                          width={13}
                                                        />
                                                      </LinesAvailablePlayerHandIconContainer>
                                                      <span>{it.student.hand === 'right' ? 'Правая' : 'Левая'}</span>
                                                    </LinesAvailablePlayerHandFlexContainer>
                                                  </LinesAvailablePlayerHand>
                                                  <LinesAvailablePlayerStatus
                                                    className={
                                                      it.student.status === 'available'
                                                        ? `LinesAvailablePlayerStatus LinesAvailablePlayerStatus_available`
                                                        : `LinesAvailablePlayerStatus_notavailable LinesAvailablePlayerStatus`
                                                    }
                                                  >
                                                    {it.student.status === 'available' ? 'Доступен' : 'Недоступен'}
                                                  </LinesAvailablePlayerStatus>
                                                </BasketPartItem>
                                              );
                                            })
                                        )}
                                      </LinesAvailablePlayers>
                                    </LinesAvailablePlayersContainer>
                                  ),
                                },
                                {
                                  label: 'Вратари',
                                  content: (
                                    <LinesAvailablePlayersContainer>
                                      <LinesAvailablePlayers>
                                        {!basket.length ? (
                                          <TableEmptyBlock
                                            text={
                                              'Недостаточно данных. Для отображения формы выберите школу и команду.'
                                            }
                                          />
                                        ) : (
                                          basket
                                            .filter((student) => student.student.hockeyRole === 'goaltender')
                                            .sort((a, b) => {
                                              if (a.student.lastName.toLowerCase() > b.student.lastName.toLowerCase()) {
                                                return 1;
                                              }
                                              if (a.student.lastName.toLowerCase() < b.student.lastName.toLowerCase()) {
                                                return -1;
                                              }
                                              return 0;
                                            })
                                            .map((it, k) => {
                                              return (
                                                <BasketPartItem key={k}>
                                                  <LinesAvailablePlayerContain
                                                    className={
                                                      isActiveCard && selectedPlayer.id === it.student.id
                                                        ? 'active'
                                                        : ''
                                                    }
                                                    onClick={(e) => {
                                                      if (!isCanEdit) return;
                                                      if (it.student?.status !== 'available') {
                                                        ReactComponentNotification(
                                                          NOTIFICATION_TYPES['info'],
                                                          `Игрок недоступен для выбора`,
                                                        );
                                                        return;
                                                      }
                                                      cartPlayerToggle(it.student);
                                                      e.preventDefault();
                                                    }}
                                                  />
                                                  <Button className="LinesAvailablePlayerButton">
                                                    <Icon
                                                      height={9}
                                                      type="LeftArrowWithLine"
                                                      viewBox={'0 0 15 9'}
                                                      width={15}
                                                    />
                                                  </Button>
                                                  <PlayersHorizontalCard student={it.student} />
                                                  <LinesAvailablePlayerHand>
                                                    <LinesAvailablePlayerHandFlexContainer>
                                                      <LinesAvailablePlayerHandIconContainer>
                                                        <Icon
                                                          height={13}
                                                          type="HockeyStickForHand"
                                                          viewBox={'0 0 13 13'}
                                                          width={13}
                                                        />
                                                      </LinesAvailablePlayerHandIconContainer>
                                                      <span>{it.student.hand === 'right' ? 'Правая' : 'Левая'}</span>
                                                    </LinesAvailablePlayerHandFlexContainer>
                                                  </LinesAvailablePlayerHand>
                                                  <LinesAvailablePlayerStatus
                                                    className={
                                                      it.student.status === 'available'
                                                        ? `LinesAvailablePlayerStatus LinesAvailablePlayerStatus_available`
                                                        : `LinesAvailablePlayerStatus_notavailable LinesAvailablePlayerStatus`
                                                    }
                                                  >
                                                    {it.student.status === 'available' ? 'Доступен' : 'Недоступен'}
                                                  </LinesAvailablePlayerStatus>
                                                </BasketPartItem>
                                              );
                                            })
                                        )}
                                      </LinesAvailablePlayers>
                                    </LinesAvailablePlayersContainer>
                                  ),
                                },
                              ]}
                            />
                            {selectedTeam ? (
                              <BasketPartItem className={'read-only-hide'}>
                                <LinesAvailablePlayerContain
                                  onClick={() => {
                                    modalVisibleToggle();
                                  }}
                                />
                                <Button
                                  //onClick={() => {}}
                                  className="LinesAvailablePlayerButton"
                                >
                                  <Icon height={9} type="LeftArrowWithLine" viewBox={'0 0 15 9'} width={15} />
                                </Button>
                                <LinesAvailablePlayerAvatar src={avatar} />
                                <LinesAddNewPlayer>Добавить игрока из другой команды</LinesAddNewPlayer>
                              </BasketPartItem>
                            ) : null}
                            <ReactComponentModal
                              buttons={
                                <React.Fragment>
                                  <Button
                                    className={'DialogButton DialogButton_yes'}
                                    onClick={() => {
                                      modalVisibleToggle();
                                    }}
                                  >
                                    Отменить
                                  </Button>
                                  <Button
                                    className={'DialogButton DialogButton_no'}
                                    onClick={() => {
                                      handleAddPlayerToBasket(modalSelectedPlayer);
                                      modalVisibleToggle();
                                      setModalSelectedPlayer('');
                                      setModalPlayersOptions([]);
                                      setModalSelectedGroup('');
                                    }}
                                  >
                                    Добавить
                                  </Button>
                                </React.Fragment>
                              }
                              className={'AddFromOtherTeamModal'}
                              content={
                                <DialogContentText>
                                  <ModalHeader>Добавить игрока из другой команды </ModalHeader>
                                  <Filter>
                                    <Column className={'Column'}>
                                      <Label>Команда</Label>
                                      <SelectDefault
                                        isLoading={!modalGroupOptions.length > 0}
                                        isMulti={false}
                                        options={CommonHelper.getDropDownTeamsByNamedList(modalGroupOptions)}
                                        placeholder={'Выберите команду'}
                                        value={modalSelectedGroup}
                                        onChange={(a) => {
                                          setModalPlayersOptions([]);
                                          setModalSelectedGroup(a);
                                        }}
                                      />
                                    </Column>
                                    <Column className={'Column'}>
                                      <Label>Игрок</Label>
                                      <SelectDefault
                                        isMulti={false}
                                        options={CommonHelper.getDropDownItemsByNamedList(modalPlayersOptions)}
                                        placeholder={'Выберите игрока'}
                                        value={modalSelectedPlayer}
                                        onChange={(a) => {
                                          setModalSelectedPlayer(a);
                                        }}
                                      />
                                    </Column>
                                  </Filter>
                                </DialogContentText>
                              }
                              visible={modalIsVisible}
                              onClose={() => {
                                modalVisibleToggle();
                              }}
                            />
                          </LinesAvailablePlayersBlock>
                        }
                      />
                      <LinesButtonsBlock className={'LinesButtonsBlock'}>
                        <LinesButtonsBlockContainer>
                          <Button
                            onClick={() => {
                              saveLines();
                              window.ym(80266396, 'reachGoal', 'save_for_lines');
                            }}
                          >
                            Сохранить
                          </Button>
                          <Button
                            onClick={() => {
                              PrintElem('printme', teamName, titleDate);

                              window.ym(80266396, 'reachGoal', 'print_for_lines');
                            }}
                          >
                            Распечатать
                          </Button>
                        </LinesButtonsBlockContainer>
                        <Button
                          onClick={() => {
                            setCancelModalState(true);
                          }}
                        >
                          Отменить изменения
                        </Button>
                      </LinesButtonsBlock>
                    </LinesBody>
                  ),
                },
                {
                  label: 'Избранное',
                  link: 'favorites',
                  content:
                    linesFavoriteList && linesFavoriteList.length > 0 && activeTab === '1' ? (
                      <>
                        <LinesBody className="LinesBody" loading={loading}>
                          <LocalPreloader visible={loading} />
                          <TeamPart
                            content={
                              <>
                                <LinesBlockHead className={'LinesBlockHead'}>
                                  <LinesBlockHeadColumns className={'LinesBlockHeadColumns'}>
                                    <LinesBlockHeadColumn className={'LinesBlockHeadColumn'}>
                                      <LinesBlockHeadFlexContainer className={'LinesBlockHeadFlexContainer'}>
                                        <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                          № звена
                                        </LinesBlockHeadTitle>
                                        <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                          Атакующая линия
                                        </LinesBlockHeadTitle>
                                      </LinesBlockHeadFlexContainer>
                                    </LinesBlockHeadColumn>
                                    <LinesBlockHeadColumn className={'LinesBlockHeadColumn'}>
                                      <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                        Оборонительная линия
                                      </LinesBlockHeadTitle>
                                    </LinesBlockHeadColumn>
                                  </LinesBlockHeadColumns>
                                </LinesBlockHead>
                                <LinesTabLines className={'LinesTabLines'}>
                                  {linesForm
                                    ? linesForm.playersLines
                                      ? linesForm.playersLines.map((it, lineIndex) => {
                                          return (
                                            <LinesTabLineContainer className={'LinesTabLineContainer'} key={lineIndex}>
                                              <LinesTabLine className={'LinesTabLine'}>
                                                <LinesTabLineNumber className={'LinesTabLineNumber'}>
                                                  Звено №{lineIndex + 1}
                                                </LinesTabLineNumber>
                                                <LinesTabLineColumnWingers className={'LinesTabLineColumnWingers'}>
                                                  <TeacherHockeyLinesPlayerCards
                                                    className={'TeacherHockeyLinesPlayerCards'}
                                                  >
                                                    {['', '', ''].map((winger, wk) => {
                                                      if (it.wingersLine[wk]) {
                                                        return (
                                                          <TeacherHockeyLinesPlayerCard
                                                            className={'TeacherHockeyLinesPlayerCard'}
                                                            key={wk}
                                                            player={it.wingersLine[wk]}
                                                            position={wingerPositions[wk]}
                                                            readOnly={true}
                                                            onDelete={() => {
                                                              deletePlayerFromPosition('wingersLine', lineIndex, wk);
                                                            }}
                                                          />
                                                        );
                                                      } else {
                                                        return (
                                                          <TeacherHockeyLinesPlayerEmptyCard
                                                            className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                            isActive={isActiveCard}
                                                            key={wk}
                                                            lineNumber={lineIndex + 1}
                                                            position={wingerPositions[wk]}
                                                            readOnly={true}
                                                            onClick={() => {
                                                              if (isActiveCard) {
                                                                selectPlayerPosition(
                                                                  'wingersLine',
                                                                  lineIndex,
                                                                  wk,
                                                                  selectedPlayer,
                                                                );
                                                              }
                                                            }}
                                                          />
                                                        );
                                                      }
                                                    })}
                                                  </TeacherHockeyLinesPlayerCards>
                                                </LinesTabLineColumnWingers>
                                                <LinesTabLineColumnDefensemen
                                                  className={'LinesTabLineColumnDefensemen'}
                                                >
                                                  <TeacherHockeyLinesPlayerCards
                                                    className={'TeacherHockeyLinesPlayerCards'}
                                                  >
                                                    {['', ''].map((defenseman, dk) => {
                                                      if (it.defensemansLine[dk]) {
                                                        return (
                                                          <TeacherHockeyLinesPlayerCard
                                                            className={'TeacherHockeyLinesPlayerCard'}
                                                            key={dk}
                                                            player={it.defensemansLine[dk]}
                                                            position={defensemenPositions[dk]}
                                                            readOnly={true}
                                                            onDelete={() => {
                                                              deletePlayerFromPosition(
                                                                'defensemansLine',
                                                                lineIndex,
                                                                dk,
                                                              );
                                                            }}
                                                          />
                                                        );
                                                      } else {
                                                        return (
                                                          <TeacherHockeyLinesPlayerEmptyCard
                                                            className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                            isActive={isActiveCard}
                                                            key={dk}
                                                            lineNumber={lineIndex + 1}
                                                            position={defensemenPositions[dk]}
                                                            readOnly={true}
                                                            onClick={() => {
                                                              if (isActiveCard) {
                                                                selectPlayerPosition(
                                                                  'defensemansLine',
                                                                  lineIndex,
                                                                  dk,
                                                                  selectedPlayer,
                                                                );
                                                              }
                                                            }}
                                                          />
                                                        );
                                                      }
                                                    })}
                                                  </TeacherHockeyLinesPlayerCards>
                                                </LinesTabLineColumnDefensemen>
                                              </LinesTabLine>
                                              {linesForm.playersLines.length < 2 || lineIndex === 0 ? null : (
                                                <ButtonDelete
                                                  className={'hide-when-print'}
                                                  onClick={() => {
                                                    handleDeleteLine(it.name);
                                                  }}
                                                >
                                                  <Icon
                                                    height={17}
                                                    type={'CloseRed'}
                                                    viewBox={'0 0 17 17'}
                                                    width={17}
                                                  />
                                                </ButtonDelete>
                                              )}
                                            </LinesTabLineContainer>
                                          );
                                        })
                                      : null
                                    : null}
                                  {linesForm && (
                                    <LinesTabLineContainerGoaltenders className={'LinesTabLineContainerGoaltenders'}>
                                      <LinesTabLine className={'LinesTabLine'}>
                                        <LinesTabLineNumber className={'LinesTabLineNumber'}>
                                          Вратари
                                        </LinesTabLineNumber>
                                        <LinesTabLineColumnGoaltendersHead
                                          className={'LinesTabLineColumnGoaltendersHead'}
                                        >
                                          <LinesTabLineColumnGoaltendersHeadColumn
                                            className={'LinesTabLineColumnGoaltendersHeadColumn'}
                                          >
                                            <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                              Состав вратарей
                                            </LinesBlockHeadTitle>
                                          </LinesTabLineColumnGoaltendersHeadColumn>
                                        </LinesTabLineColumnGoaltendersHead>
                                        <LinesTabLineColumnGoaltenders className={'LinesTabLineColumnGoaltenders'}>
                                          <TeacherHockeyLinesPlayerCards className={'TeacherHockeyLinesPlayerCards'}>
                                            {['', '', ''].map((goaltender, gk) => {
                                              if (
                                                linesForm.goaltendersLine &&
                                                linesForm.goaltendersLine.length &&
                                                linesForm.goaltendersLine[gk]
                                              ) {
                                                return (
                                                  <TeacherHockeyLinesPlayerCard
                                                    className={'TeacherHockeyLinesPlayerCard'}
                                                    key={gk}
                                                    player={linesForm.goaltendersLine[gk]}
                                                    position={'вр'}
                                                    readOnly={!isCanEdit}
                                                    onDelete={() => {
                                                      deleteGoaltenderPlayerFromPosition(gk);
                                                    }}
                                                  />
                                                );
                                              } else {
                                                return (
                                                  <TeacherHockeyLinesPlayerEmptyCard
                                                    className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                    isActive={isActiveCard}
                                                    key={gk}
                                                    position={'вр'}
                                                    readOnly={!isCanEdit}
                                                    onClick={() => {
                                                      if (isActiveCard) {
                                                        selectGoaltenderPlayerPosition(gk, selectedPlayer);
                                                      }
                                                    }}
                                                  />
                                                );
                                              }
                                            })}
                                          </TeacherHockeyLinesPlayerCards>
                                        </LinesTabLineColumnGoaltenders>
                                      </LinesTabLine>
                                    </LinesTabLineContainerGoaltenders>
                                  )}
                                </LinesTabLines>
                              </>
                            }
                            headline={
                              linesForm ? (
                                <>
                                  <LinesTabHeadLineButtons>
                                    <Button
                                      className={
                                        linesForm && linesForm.isActual ? 'AddLineButton disabled' : 'AddLineButton'
                                      }
                                      onClick={() => {
                                        if (linesForm && linesForm.isActual) return;
                                        setMakeRelevantModalState(true);
                                      }}
                                    >
                                      {linesForm && linesForm.isActual ? (
                                        <>
                                          <Icon height={12} type={'Refresh'} viewBox={'0 0 12 12'} width={12} /> Это
                                          актуальный состав
                                        </>
                                      ) : (
                                        <>
                                          <Icon height={12} type={'Refresh'} viewBox={'0 0 12 12'} width={12} /> Сделать
                                          состав актуальным
                                        </>
                                      )}
                                    </Button>
                                    <Button
                                      className="ClearLineButton"
                                      onClick={() => {
                                        setRemoveFromFavoritesModalState(true);
                                      }}
                                    >
                                      <Icon height={15} type={'Delete'} viewBox={'0 0 15 15'} width={15} />
                                      Удалить состав из избранных
                                    </Button>
                                  </LinesTabHeadLineButtons>
                                  <HeadlineTextBlock>
                                    <HeadlineTextBlockTitle>Избранный состав</HeadlineTextBlockTitle>
                                    <HeadlineTextBlockInfo>{linesForm?.favoritesName}</HeadlineTextBlockInfo>
                                  </HeadlineTextBlock>
                                </>
                              ) : null
                            }
                          />
                          <BasketPart
                            content={
                              <div className="sticky">
                                {linesFavoriteList
                                  ? linesFavoriteList.map((line, index) => {
                                      return (
                                        <BasketPartItem
                                          className={line.objectId === linesForm.objectId ? ' active ' : ''}
                                          key={index}
                                        >
                                          <LinesAvailablePlayerContain
                                            onClick={() => {
                                              dispatch(handleChangeForm(line));
                                            }}
                                          />
                                          <Button className="LinesAvailablePlayerButton">
                                            <Icon height={9} type="LeftArrowWithLine" viewBox={'0 0 15 9'} width={15} />
                                          </Button>
                                          <BasketPartItemText>{line?.favoritesName}</BasketPartItemText>
                                        </BasketPartItem>
                                      );
                                    })
                                  : null}
                              </div>
                            }
                          />
                        </LinesBody>
                      </>
                    ) : (
                      <TableEmptyBlock
                        text={[
                          'У вас пока нет избранных составов. ',
                          // eslint-disable-next-line react/jsx-key
                          <br></br>,
                          'Вы можете создать их из вкладки «Состав игроков».',
                        ]}
                      />
                    ),
                },
                {
                  label: 'История по играм',
                  content: linesHistory ? (
                    <LinesBody className="LinesBody">
                      <TeamPart
                        content={
                          <>
                            <LinesBlockHead className={'LinesBlockHead'}>
                              <LinesBlockHeadColumns className={'LinesBlockHeadColumns'}>
                                <LinesBlockHeadColumn className={'LinesBlockHeadColumn'}>
                                  <LinesBlockHeadFlexContainer className={'LinesBlockHeadFlexContainer'}>
                                    <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>№ звена</LinesBlockHeadTitle>
                                    <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                      Атакующая линия
                                    </LinesBlockHeadTitle>
                                  </LinesBlockHeadFlexContainer>
                                </LinesBlockHeadColumn>
                                <LinesBlockHeadColumn className={'LinesBlockHeadColumn'}>
                                  <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                    Оборонительная линия
                                  </LinesBlockHeadTitle>
                                </LinesBlockHeadColumn>
                              </LinesBlockHeadColumns>
                            </LinesBlockHead>
                            <LinesTabLines className={'LinesTabLines'}>
                              {linesForm
                                ? linesForm.playersLines
                                  ? linesForm.playersLines.map((it, lineIndex) => {
                                      return (
                                        <LinesTabLineContainer className={'LinesTabLineContainer'} key={lineIndex}>
                                          <LinesTabLine className={'LinesTabLine'}>
                                            <LinesTabLineNumber className={'LinesTabLineNumber'}>
                                              Звено №{lineIndex + 1}
                                            </LinesTabLineNumber>
                                            <LinesTabLineColumnWingers className={'LinesTabLineColumnWingers'}>
                                              <TeacherHockeyLinesPlayerCards
                                                className={'TeacherHockeyLinesPlayerCards'}
                                              >
                                                {['', '', ''].map((winger, wk) => {
                                                  if (it.wingersLine[wk]) {
                                                    return (
                                                      <TeacherHockeyLinesPlayerCard
                                                        className={'TeacherHockeyLinesPlayerCard'}
                                                        key={wk}
                                                        player={it.wingersLine[wk]}
                                                        position={wingerPositions[wk]}
                                                        readOnly={true}
                                                        onDelete={() => {
                                                          deletePlayerFromPosition('wingersLine', lineIndex, wk);
                                                        }}
                                                      />
                                                    );
                                                  } else {
                                                    return (
                                                      <TeacherHockeyLinesPlayerEmptyCard
                                                        className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                        isActive={isActiveCard}
                                                        key={wk}
                                                        lineNumber={lineIndex + 1}
                                                        position={wingerPositions[wk]}
                                                        readOnly={true}
                                                        onClick={() => {
                                                          if (isActiveCard) {
                                                            selectPlayerPosition(
                                                              'wingersLine',
                                                              lineIndex,
                                                              wk,
                                                              selectedPlayer,
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    );
                                                  }
                                                })}
                                              </TeacherHockeyLinesPlayerCards>
                                            </LinesTabLineColumnWingers>
                                            <LinesTabLineColumnDefensemen className={'LinesTabLineColumnDefensemen'}>
                                              <TeacherHockeyLinesPlayerCards
                                                className={'TeacherHockeyLinesPlayerCards'}
                                              >
                                                {['', ''].map((defenseman, dk) => {
                                                  if (it.defensemansLine[dk]) {
                                                    return (
                                                      <TeacherHockeyLinesPlayerCard
                                                        className={'TeacherHockeyLinesPlayerCard'}
                                                        key={dk}
                                                        player={it.defensemansLine[dk]}
                                                        position={defensemenPositions[dk]}
                                                        readOnly={true}
                                                        onDelete={() => {
                                                          deletePlayerFromPosition('defensemansLine', lineIndex, dk);
                                                        }}
                                                      />
                                                    );
                                                  } else {
                                                    return (
                                                      <TeacherHockeyLinesPlayerEmptyCard
                                                        className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                        isActive={isActiveCard}
                                                        key={dk}
                                                        lineNumber={lineIndex + 1}
                                                        position={defensemenPositions[dk]}
                                                        readOnly={true}
                                                        onClick={() => {
                                                          if (isActiveCard) {
                                                            selectPlayerPosition(
                                                              'defensemansLine',
                                                              lineIndex,
                                                              dk,
                                                              selectedPlayer,
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    );
                                                  }
                                                })}
                                              </TeacherHockeyLinesPlayerCards>
                                            </LinesTabLineColumnDefensemen>
                                          </LinesTabLine>
                                          {linesForm.playersLines.length < 2 || lineIndex === 0 ? null : (
                                            <ButtonDelete
                                              className={'hide-when-print'}
                                              onClick={() => {
                                                handleDeleteLine(it.name);
                                              }}
                                            >
                                              <Icon height={17} type={'CloseRed'} viewBox={'0 0 17 17'} width={17} />
                                            </ButtonDelete>
                                          )}
                                        </LinesTabLineContainer>
                                      );
                                    })
                                  : null
                                : null}
                              {linesForm && (
                                <LinesTabLineContainerGoaltenders className={'LinesTabLineContainerGoaltenders'}>
                                  <LinesTabLine className={'LinesTabLine'}>
                                    <LinesTabLineNumber className={'LinesTabLineNumber'}>Вратари</LinesTabLineNumber>
                                    <LinesTabLineColumnGoaltendersHead className={'LinesTabLineColumnGoaltendersHead'}>
                                      <LinesTabLineColumnGoaltendersHeadColumn
                                        className={'LinesTabLineColumnGoaltendersHeadColumn'}
                                      >
                                        <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                          Состав вратарей
                                        </LinesBlockHeadTitle>
                                      </LinesTabLineColumnGoaltendersHeadColumn>
                                    </LinesTabLineColumnGoaltendersHead>
                                    <LinesTabLineColumnGoaltenders className={'LinesTabLineColumnGoaltenders'}>
                                      <TeacherHockeyLinesPlayerCards className={'TeacherHockeyLinesPlayerCards'}>
                                        {['', '', ''].map((goaltender, gk) => {
                                          if (
                                            linesForm.goaltendersLine &&
                                            linesForm.goaltendersLine.length &&
                                            linesForm.goaltendersLine[gk]
                                          ) {
                                            return (
                                              <TeacherHockeyLinesPlayerCard
                                                className={'TeacherHockeyLinesPlayerCard'}
                                                key={gk}
                                                player={linesForm.goaltendersLine[gk]}
                                                position={'вр'}
                                                readOnly={!isCanEdit}
                                                onDelete={() => {
                                                  deleteGoaltenderPlayerFromPosition(gk);
                                                }}
                                              />
                                            );
                                          } else {
                                            return (
                                              <TeacherHockeyLinesPlayerEmptyCard
                                                className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                isActive={isActiveCard}
                                                key={gk}
                                                position={'вр'}
                                                readOnly={!isCanEdit}
                                                onClick={() => {
                                                  if (isActiveCard) {
                                                    selectGoaltenderPlayerPosition(gk, selectedPlayer);
                                                  }
                                                }}
                                              />
                                            );
                                          }
                                        })}
                                      </TeacherHockeyLinesPlayerCards>
                                    </LinesTabLineColumnGoaltenders>
                                  </LinesTabLine>
                                </LinesTabLineContainerGoaltenders>
                              )}
                            </LinesTabLines>
                          </>
                        }
                        headline={
                          isCanEdit ? (
                            <>
                              <LinesTabHeadLineButtons>
                                <Button
                                  className="AddLineButton"
                                  onClick={() => {
                                    setMakeRelevantModalState(true);
                                  }}
                                >
                                  <Icon height={12} type={'Refresh'} viewBox={'0 0 12 12'} width={12} />
                                  Сделать состав актуальным
                                </Button>
                              </LinesTabHeadLineButtons>
                              <HeadlineTextBlock>
                                <HeadlineTextBlockTitle>Состав на игру</HeadlineTextBlockTitle>
                                {Object.keys(activeHistory).length ? (
                                  <HeadlineTextBlockInfo>
                                    <HeadlineTextBlockInfoDate>
                                      {date(activeHistory.game.rooms[0].startTimestamp).format('DD.MM.YYYY')}{' '}
                                    </HeadlineTextBlockInfoDate>
                                    <HeadlineTextBlockInfoTime>
                                      {date(activeHistory.game.rooms[0].startTimestamp).format('HH:mm')}
                                    </HeadlineTextBlockInfoTime>
                                    <HeadlineTextBlockInfoDark>{activeHistory.game.fullName}</HeadlineTextBlockInfoDark>
                                  </HeadlineTextBlockInfo>
                                ) : null}
                              </HeadlineTextBlock>
                            </>
                          ) : null
                        }
                      />
                      <BasketPart
                        content={
                          <div className="sticky">
                            {linesHistory
                              .sort((a, b) => {
                                const aTime = a.game.rooms[0].startTimestamp;
                                const bTime = b.game.rooms[0].startTimestamp;
                                if (aTime > bTime) {
                                  return -1;
                                }
                                if (aTime < bTime) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map((lineHistory, index) => {
                                return (
                                  <BasketPartItem
                                    className={lineHistory.objectId === activeHistory.objectId ? ' active ' : ''}
                                    key={index}
                                  >
                                    <LinesAvailablePlayerContain
                                      onClick={() => {
                                        dispatch(
                                          handleChangeForm({
                                            ...lineHistory.composition,
                                            favoritesName: lineHistory.game.fullName,
                                          }),
                                        );
                                        setActiveHistory(lineHistory);
                                      }}
                                    />
                                    <Button className="LinesAvailablePlayerButton">
                                      <Icon height={9} type="LeftArrowWithLine" viewBox={'0 0 15 9'} width={15} />
                                    </Button>
                                    <BasketPartItemText>
                                      <BasketPartItemTextItem>
                                        {date(lineHistory.game.rooms[0].startTimestamp).format('DD.MM.YYYY HH:mm')}
                                      </BasketPartItemTextItem>
                                      <BasketPartItemTextItemName>
                                        {lineHistory.game.type !== 'away_game'
                                          ? `${lineHistory.game.teamName || ''} — ${lineHistory.game.opponent || ''}`
                                          : `${lineHistory.game.opponent || ''} — ${lineHistory.game.teamName || ''}`}
                                      </BasketPartItemTextItemName>
                                    </BasketPartItemText>
                                  </BasketPartItem>
                                );
                              })}
                          </div>
                        }
                      />
                    </LinesBody>
                  ) : null,
                },
              ]}
              onActiveKeyChange={changeTab}
            />
          </Lines>
        ) : (
          <LinesMobile
            activeTab={activeTab}
            addToFavorites={() => {
              if (isEmptyLines()) {
                ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Пустой состав нельзя добавлять в избранное');
                return;
              }
              setAddToFavoritesModalState(true);
              setLinesName('');
            }}
            basket={basket}
            deleteGoaltenderPlayerFromPosition={deleteGoaltenderPlayerFromPosition}
            deletePlayerFromPosition={deletePlayerFromPosition}
            handleAddLine={handleAddLine}
            isActiveCard={isActiveCard}
            isCanEdit={isCanEdit}
            linesForm={linesForm}
            modalGroupOptions={modalGroupOptions}
            modalPlayersOptions={modalPlayersOptions}
            modalSelectedGroup={modalSelectedGroup}
            modalSelectedPlayer={modalSelectedPlayer}
            nearestGame={nearestGame}
            nearestTournament={nearestTournament}
            saveLines={saveLines}
            selectGoaltenderPosition={selectGoaltenderPlayerPosition}
            selectPlayerPosition={selectPlayerPosition}
            setActiveTab={setActiveTab}
            setCancelModalState={setCancelModalState}
            setClearLinesModalState={setClearLinesModalState}
            setIsActiveCard={setIsActiveCard}
            setModalIsVisible={setModalIsVisible}
            setModalPlayersOptions={setModalPlayersOptions}
            setModalSelectedGroup={setModalSelectedGroup}
            setModalSelectedPlayer={setModalSelectedPlayer}
            teamId={selectedTeam?.id}
            teamName={teamName}
          />
        )}

        <ReactComponentModal
          buttons={
            <React.Fragment>
              <Button
                className={'DialogButton DialogButton_no'}
                onClick={() => {
                  setCancelModalState(false);
                }}
              >
                Вернуться к списку
              </Button>
              <Button
                className={'DialogButton DialogButton_yes'}
                onClick={() => {
                  getLinesData();
                  setCancelModalState(false);
                }}
              >
                Отменить изменения
              </Button>
            </React.Fragment>
          }
          className="ReactComponentModalDefault"
          content={
            <>
              <DialogContentText className="ReactComponentModalDefaultText">
                Вы хотите отменить последние изменения состава звеньев?
              </DialogContentText>
            </>
          }
          title="Отменить изменения"
          visible={cancelModalState}
          onClose={() => {
            setCancelModalState(false);
          }}
        />
        <ReactComponentModal
          buttons={
            <React.Fragment>
              <Button
                className={'DialogButton DialogButton_no'}
                onClick={() => {
                  setClearLinesModalState(false);
                }}
              >
                Отменить
              </Button>
              <Button
                className={'DialogButton DialogButton_yes'}
                onClick={() => {
                  cleanLines();
                  setClearLinesModalState(false);
                }}
              >
                Очистить
              </Button>
            </React.Fragment>
          }
          className="ReactComponentModalDefault"
          content={
            <>
              <DialogContentText className="ReactComponentModalDefaultText">
                Вы хотите очистить текущий состав звеньев?
              </DialogContentText>
            </>
          }
          title="Очистить состав звеньев"
          visible={clearLinesModalState}
          onClose={() => {
            setClearLinesModalState(false);
          }}
        />
        <ReactComponentModal
          buttons={
            <React.Fragment>
              <Button
                className={'DialogButton DialogButton_no'}
                onClick={() => {
                  setAddToFavoritesModalState(false);
                }}
              >
                Отменить
              </Button>
              <Button
                className={'DialogButton DialogButton_yes'}
                onClick={() => {
                  if (!handleValidNameForFavorite(linesName)) return;
                  addLinesToFavorite();
                  setAddToFavoritesModalState(false);
                }}
              >
                Добавить
              </Button>
            </React.Fragment>
          }
          className="ReactComponentModalDefault AddToFavoritesModal"
          content={
            <>
              <p className="AddToFavoritesModalTitle">Название состава</p>
              <InputDefault
                errorText={favoriteNameErrorText}
                isInvalid={isFavoriteNameErrorState}
                placeholder="Введите название состава"
                value={linesName}
                onChange={(a) => {
                  setLinesName(a.target.value);
                }}
                onFocus={() => {
                  setIsFavoriteNameErrorState(false);
                }}
              />
            </>
          }
          title="Добавить в избранное"
          visible={addToFavoritesModalState}
          onClose={() => {
            setAddToFavoritesModalState(false);
          }}
        />
        <ReactComponentModal
          buttons={
            <React.Fragment>
              <Button
                className={'DialogButton DialogButton_no'}
                onClick={() => {
                  setRemoveFromFavoritesModalState(false);
                }}
              >
                Вернуться к составу
              </Button>
              <Button
                className={'DialogButton DialogButton_yes'}
                onClick={() => {
                  deleteLinesFromFavorite();

                  setRemoveFromFavoritesModalState(false);
                }}
              >
                Удалить
              </Button>
            </React.Fragment>
          }
          className="ReactComponentModalDefault"
          content={
            <>
              <DialogContentText className="ReactComponentModalDefaultText">
                Вы хотите удалить {linesForm?.favoritesName ? `состав «${linesForm.favoritesName}»` : 'состав'} из
                списка избранных?
              </DialogContentText>
            </>
          }
          title="Удалить состав из избранных"
          visible={removeFromFavoritesModalState}
          onClose={() => {
            setRemoveFromFavoritesModalState(false);
          }}
        />
        <ReactComponentModal
          buttons={
            <React.Fragment>
              <Button
                className={'DialogButton DialogButton_no'}
                onClick={() => {
                  setLinesNotSavedModalState(false);
                }}
              >
                Продолжить без сохранения
              </Button>
              <Button
                // onClick={() => {
                //
                // }}
                className={'DialogButton DialogButton_yes'}
              >
                Сохранить
              </Button>
            </React.Fragment>
          }
          className="ReactComponentModalDefault LinesNotSaved"
          content={
            <>
              <DialogContentText className="ReactComponentModalDefaultText">
                Вы покидаете страницу не сохранив изменения в составе. Хотите сохранить внесенные изменения?
              </DialogContentText>
            </>
          }
          title="Состав не сохранен"
          visible={linesNotSavedModalState}
          onClose={() => {
            setLinesNotSavedModalState(false);
          }}
        />
        <ReactComponentModal
          buttons={
            <React.Fragment>
              <Button
                className={'DialogButton DialogButton_no'}
                onClick={() => {
                  setMakeRelevantModalState(false);
                }}
              >
                Вернуться к составу
              </Button>
              <Button
                className={'DialogButton DialogButton_yes'}
                onClick={() => {
                  if (activeTab === '2') {
                    dispatch(setActualHistoryLines(activeHistory.objectId));
                  } else {
                    dispatch(setActualLines(linesForm.objectId));
                  }
                  setTimeout(() => {
                    changeTab('0');
                  }, 0);
                  setMakeRelevantModalState(false);
                }}
              >
                Сделать актуальным
              </Button>
            </React.Fragment>
          }
          className="ReactComponentModalDefault"
          content={
            <>
              <DialogContentText className="ReactComponentModalDefaultText">
                Вы хотите следать состав {linesForm?.favoritesName ? `«${linesForm.favoritesName}»` : ''} актуальным на
                ближайшее мероприятие?
              </DialogContentText>
            </>
          }
          title="Сделать состав актуальным"
          visible={makeRelevantModalState}
          onClose={() => {
            setMakeRelevantModalState(false);
          }}
        />
      </>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  .Tabs {
    .rc-tabs {
      &-nav-list {
        padding-left: 32px;
        padding-right: 32px;
        box-shadow: 0px 14px 30px -20px rgba(0, 0, 0, 0.4);
        z-index: 1000;
        position: relative;
      }
    }
  }
  .MuiGrid {
    &-container {
      margin-left: ${(props) => (props.isMobile ? 0 : '-24px')};
    }
    &-item {
      &:first-child {
        padding-left: ${(props) => (props.isMobile ? 0 : '24px')};
      }
    }
  }
  .PageTitleDefault {
    @media screen and (max-width: 767px) {
      align-items: center;
      margin-left: 0;
    }
  }
`;
const Lines = styled.div`
  margin-top: 6px;
  margin-bottom: 0;
  .Tabs {
    box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.3);
    .rc-tabs {
      &-tab {
        font-weight: 600;
      }
      &-nav {
        border-radius: 6px 6px 0 0;
      }
    }
  }
`;
const LinesBody = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 46px;
  max-height: ${(props) => (props.loading ? '60vh' : '100%')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
`;
const LinesBlockHeadTitle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 12px/28px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
  text-align: center;
`;
const LinesBlockHead = styled.div`
  border-top: 1px solid #dddad4;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  > ${LinesBlockHeadTitle} {
    padding-left: 32px;
    padding-right: 0;
    text-align: left;
  }
`;
const LinesBlockHeadColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
const LinesBlockHeadFlexContainer = styled.div`
  display: flex;
  @media screen and (max-width: 1440px) {
    padding: 0 16px;
  }
  ${LinesBlockHeadTitle} {
    &:last-child {
      width: calc(100% - 52px);
    }
  }
`;
const LinesBlockHeadColumn = styled.div`
  box-sizing: border-box;
  &:last-child {
    padding-right: 0;
    padding-left: 0;
    flex: 0 0 41%;
    max-width: 41%;
  }
  &:first-child {
    padding-right: 0;
    padding-left: 32px;
    flex: 0 0 59%;
    max-width: 59%;
    @media screen and (max-width: 1440px) {
      padding-left: 0;
    }
  }
`;
const LinesAvailablePlayersBlock = styled.div`
  &.sticky {
    max-height: 700px;
  }
  .Tabs {
    border-top: 1px solid #dddad4;
    border-radius: 0;
    .rc-tabs {
      &-nav {
        background-color: white !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        &-list {
          padding-right: 12px;
          padding-left: 12px;
          justify-content: space-between !important;
        }
      }
      &-tab {
        position: relative;
        padding-top: 18px;
        padding-bottom: 18px;
        background-color: transparent;
        border-radius: 0;
        font-weight: 600 !important;
        color: #747474 !important;
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: transparent;
          transition: background-color 0.33s;
          content: '';
        }
        &:focus {
          &:after {
            background-color: transparent;
          }
        }
        &:hover {
          &:after {
            background-color: #990011;
          }
        }
        @media screen and (max-width: 1440px) {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
        }
        &-active {
          color: #2c2c2c !important;
          &:after {
            background-color: #990011;
          }
        }
        &-btn {
          @media screen and (max-width: 1440px) {
            text-align: center;
          }
        }
      }
    }
  }
  .AddFromOtherTeamModal {
    z-index: 9999;
  }
`;
const LinesButtonsBlock = styled.div`
  max-width: 100%;
  width: 100%;
  position: relative;
  bottom: 0;
  padding: 8px 26px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 0 0 6px 6px;
  box-shadow: 4px 0 12px rgba(0, 0, 0, 0.1);
  z-index: 3;
  ${Button} {
    &:last-child {
      background-color: transparent;
      border: 1px solid #ee1122;
      color: #ee1122;
    }
  }
`;
const LinesButtonsBlockContainer = styled.div`
  ${Button} {
    margin-right: 8px;
    margin-left: 0;
    &:last-child {
      margin-right: 0;
      margin-left: 8px;
      background-color: transparent;
      border: 1px solid #ee1122;
      color: #ee1122;
    }
  }
`;
const LinesTabHeadLineButtons = styled.div`
  padding: 12px 32px;
  /* max-height: 16px; */
  background-color: white;
  border-top: 1px solid #dddad4;
  border-bottom: 1px solid #dddad4;
  ${Button} {
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    height: auto;
    background-color: transparent;
    border-radius: 0;
    font: bold normal 16px/18px 'Bebas Neue';
    color: #2c2c2c;
    text-transform: uppercase;
    &.disabled {
      cursor: not-allowed;
      opacity: 1;
      &:focus,
      &:hover {
        color: #2c2c2c;
      }
    }
    svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`;
const HeadlineTextBlock = styled.div`
  padding: 12px 32px;
  background-color: white;
`;
const HeadlineTextBlockTitle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: 600 normal 14px/16px 'Proxima Nova';
  color: #747474;
  text-transform: uppercase;
`;
const HeadlineTextBlockInfo = styled.p`
  margin-top: 8px;
  margin-bottom: 0;
  font: bold normal 18px/1 'Bebas Neue';
  color: black;
`;
const HeadlineTextBlockInfoDate = styled.span`
  padding-left: 0;
  padding-right: 4px;
  color: #887755;
`;
const HeadlineTextBlockInfoTime = styled.span`
  padding-left: 4px;
  padding-right: 8px;
  color: #990011;
`;
const HeadlineTextBlockInfoDark = styled.span`
  color: #2c2c2c;
`;

const LinesTabLines = styled.div``;
const LinesTabLineContainer = styled.div`
  position: relative;
  padding: 10px 32px;
  background-color: #f3f3f3;
  @media screen and (max-width: 1200px) {
    padding: 10px 18px;
  }
  &:nth-child(even) {
    background-color: white;
  }
`;
const LinesTabLineContainerGoaltenders = styled(LinesTabLineContainer)`
  padding: 0;
  @media screen and (max-width: 1200px) {
    padding: 0;
  }
  ${LinesTabLine} {
    ${LinesTabLineNumber} {
      top: 50%;
      left: 33px;
      @media screen and (max-width: 1200px) {
        left: 0;
      }
    }
  }
`;
const LinesTabLineColumnGoaltendersHead = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;
const LinesTabLineColumnGoaltendersHeadColumn = styled.div`
  flex: 0 0 60%;
  max-width: 60%;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 0;
  text-align: center;
  @media screen and (max-width: 1440px) {
    padding-left: 16px;
  }
`;
const LinesAvailablePlayers = styled.div`
  max-height: 600px;
  overflow: auto;
  .TableEmptyBlock {
    border-radius: 0;
    > p {
      text-align: center;
    }
  }
`;
const BasketPartItemText = styled.p`
  margin: 0 0 0 12px;
  font: 600 normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
`;
const BasketPartItemTextItem = styled.span`
  padding-left: 8px;
  padding-right: 8px;
  color: #2c2c2c;
  text-transform: uppercase;
  &:last-child {
    padding-right: 0;
  }
  &:first-child {
    padding-left: 0;
  }
`;
const BasketPartItemTextItemName = styled(BasketPartItemTextItem)`
  color: #808080;
  text-transform: capitalize;
`;
const BasketPartItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 4px 12px;
  background-color: white;
  border-bottom: 1px solid #c0c0c0;
  cursor: pointer;
  position: relative;
  @media screen and (min-width: 1881px) {
    padding: 4px 28px 4px 12px;
  }
  &.active {
    background-color: #f3f3f3;
  }
  :first-child {
    ${BasketPartItemTextItemName} {
    }
    ${BasketPartItemTextItem} {
    }
  }
  .LinesAvailablePlayerButton {
    position: relative;
    background-color: transparent;
    border-radius: 0;
    border: none;
    padding-left: 16px;
    padding-right: 0;
    > svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`;
const LinesAvailablePlayerContain = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  &.active {
    background-color: #00ff003f;
  }
`;
const LinesAvailablePlayersContainer = styled.div`
  position: relative;
  :after {
    position: absolute;
    bottom: -16px;
    display: block;
    width: 100%;
    height: 16px;
    background-color: white;
    border-bottom-right-radius: 6px;
    content: '';
  }
`;
const LinesAvailablePlayerAvatar = styled.img`
  display: block;
  margin-left: 12px;
  margin-right: 12px;
  width: 28px;
  height: 28px;
  background: url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
`;
const LinesAddNewPlayer = styled.p`
  font: bold normal 13px/18px 'Bebas Neue';
  margin-top: 0;
  margin-bottom: 0;
  min-width: 170px;
  color: #2c2c2c;
  letter-spacing: 0.02em;
  transition: all 0.33s;
`;
const LinesAvailablePlayerHandIconContainer = styled.div`
  position: relative;
  display: block;
  margin-right: 8px;
  margin-left: 12px;
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const LinesAvailablePlayerHand = styled.div`
  min-width: 105px;
  @media screen and (max-width: 1440px) {
    padding-left: 16px;
    margin-top: 4px;
  }
  span {
    margin: 0 12px 0 0;
    font: 600 normal 14px/20px 'Proxima Nova';
    letter-spacing: 0.02em;
    color: #747474;
  }
`;
const LinesAvailablePlayerHandFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
const LinesAvailablePlayerStatus = styled.p`
  position: relative;
  display: block;
  margin: 0 0 0 12px;
  padding-left: 16px;
  font: 600 normal 14px/20px 'Proxima Nova';
  letter-spacing: 0.02em;
  color: #747474;
  @media screen and (max-width: 1440px) {
    margin-top: 4px;
    font-size: 12px;
  }
  :before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-60%);
    display: block;
    width: 8px;
    height: 8px;
    border: 2px solid white;
    border-radius: 50%;
    content: '';
  }
  &.LinesAvailablePlayerStatus {
    &_available {
      :before {
        background-color: #6dc26d;
      }
    }
    &_notavailable {
      :before {
        background-color: #990011;
      }
    }
    &_traumatized {
      :before {
        background-color: #c69534;
      }
    }
  }
`;
const ButtonDelete = styled.button`
  border: none;
  background: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  svg {
    path {
      fill: #c4c4c4;
    }
  }
  &:focus {
    svg {
      path {
        fill: #c4c4c4;
      }
    }
  }
  &:hover {
    svg {
      path {
        fill: #990011;
      }
    }
  }
`;
const Filter = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -14px;
  margin-left: -14px;
  box-sizing: border-box;
  min-width: 450px;
`;
const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font: 600 normal 12px/16px 'Proxima Nova';
  color: #747474;
  text-transform: uppercase;
`;
const Column = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 14px;
  padding-left: 14px;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1199px) {
    margin-bottom: 12px;

    :nth-child(4),
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
    :nth-child(4) {
      margin-bottom: 12px;
    }
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    :nth-child(4) {
      margin-bottom: 12px;
    }
  }
`;
const ModalHeader = styled.h2`
  width: 350px;
  margin-top: -50px;
  margin-bottom: 13px;
`;
const DialogContentText = styled.div`
  margin-top: 16px;
  margin-bottom: 0;
  font: normal normal 16px/18px 'Proxima Nova';
  color: #2c2c2c;
`;

const NotificationText = styled.p`
  position: relative;
  margin-top: 0;
  margin-bottom: 8px;
  font: bold normal 16px/18px 'Proxima Nova';
  color: #595959;
`;
const NotificationSubText = styled.p`
  margin-top: 8px;
  margin-bottom: 0;
  font: normal normal 16px/18px 'Proxima Nova';
  color: #595959;
  line-height: 16px;
`;
const NotificationLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
