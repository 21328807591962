/* eslint-disable react/jsx-key */
import {getRoleLabel} from '@helpers/roles';
import useResize from '@hooks/useResize';
import {FACELESS_AVATAR} from '@src/constants/config';
import {convertImgTo200} from '@src/helpers';
import DefaultSortTable from '@ui/MUI/Table/Table';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const UsersTableContent = (props) => {
  const {items, userRole, schoolsIds} = props;

  const {screenWidth} = useResize();

  const [containerWidth, setContainerWidth] = useState(0);

  const formatedItems = useMemo(() => {
    const isAdmin = userRole === 'admin';

    if (items.length) {
      return items.map((user) => {
        const isVisableByRole = user.schools.map((school) => school.id).some((id) => schoolsIds.includes(id));
        return {
          id: user.id,
          user: {
            id: user.id,
            lastName: user.lastName,
            firstName: user.firstName,
            img: user.avatar ? convertImgTo200(user.avatar) : FACELESS_AVATAR,
            isVisable: isVisableByRole || isAdmin,
          },
          role: getRoleLabel(user.userRole),
          school: user.schools.length
            ? user.schools.map((school) => school.name).join(',')
            : 'Пользователь не привязан к школе',
        };
      });
    }

    return [];
  }, [items, userRole, schoolsIds]);

  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current) {
      setContainerWidth(ref?.current.offsetWidth);
    }
  }, [ref?.current, screenWidth]);

  return (
    <Container containerWidth={containerWidth} ref={ref}>
      <DefaultSortTable
        columns={[
          {
            key: 'user',
            field: 'user',
            headerName: 'ФИО пользователя',
            flex: 0.25,
            align: 'left',
            headerAlign: 'left',
            sortComparator: (v1, v2, param1, param2) => {
              if (param1.value.lastName.toLowerCase() > param2.value.lastName.toLowerCase()) {
                return 1;
              }
              if (param1.value.lastName.toLowerCase() < param2.value.lastName.toLowerCase()) {
                return -1;
              }
              return 0;
            },
            renderCell: (params) => {
              return (
                <UserCardLink to={`/users/${params.value.id}`}>
                  <UserInfoContainer>
                    <UserAvatarContainer>
                      <UserAvatar src={params.value.img} />
                    </UserAvatarContainer>
                    <UserFullName>
                      {params.value.lastName} {params.value.firstName}
                    </UserFullName>
                  </UserInfoContainer>
                </UserCardLink>
              );
            },
          },
          {
            key: 'role',
            field: 'role',
            headerName: 'Роль',
            flex: 0.25,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
              return params?.value || '-';
            },
          },
          {
            key: 'school',
            field: 'school',
            headerName: 'Школа',
            flex: 0.25,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
              return params?.value || '-';
            },
          },
          {
            headerName: 'Действия',
            field: 'actions',
            type: 'actions',
            align: 'left',
            headerAlign: 'left',
            flex: 0.25,
            getActions: (params) => {
              if (params.row.user.isVisable) {
                return [
                  <UserActionLink
                    to={`/users/${params.id}?edit=true`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    Редактировать
                  </UserActionLink>,
                ];
              } else {
                // eslint-disable-next-line react/no-unescaped-entities
                return [<UserAction>'-'</UserAction>];
              }
            },
          },
        ]}
        customTableHeight="58vh"
        defaultSortModel={[
          {
            field: 'user',
            sort: 'asc',
          },
        ]}
        rows={formatedItems}
      />
    </Container>
  );
};

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0.35;
`;
const UserAvatarContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;
const UserAvatar = styled.div`
  width: 37px;
  height: 37px;
  background-color: #e7e7e7;
  border-radius: 50%;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;
const UserFullName = styled.div`
  font-weight: 600;
`;

const UserAction = styled.div`
  flex: 0.2;
  color: #990011;
  font-weight: 600;
  cursor: pointer;
`;

const UserCardLink = styled((props) => <Link {...props} />)`
  color: initial;
  text-decoration: none;
  cursor: pointer;
`;

const UserActionLink = styled((props) => <Link {...props} />)`
  flex: 0.2;
  color: #990011;
  font-weight: 600;
  cursor: pointer;
`;

const Container = styled.div`
  .MuiDataGrid {
    &-root {
      .MuiDataGrid {
        &-columnHeader {
          padding: 0 34px;
        }
        &-cell {
          padding: 0 34px;
        }
      }
    }
  }
`;

export default UsersTableContent;
