import HockeyStickAndPuck from '@common/Icons/HockeyStickAndPuck';
import ScoutReportBlock from '@components/apps/PlayerPersonalFile/components/MainInfo/components/ScoutReportBlock';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {HANDS_OPTIONS} from '@components/students/constants';
import date from '@helpers/date';
import useStore from '@hooks/useStore';
import {HOCKEY_ROLES, longDash, userRoles} from '@src/constants';
import {Box, Stack, Typography} from '@ui/MUI';
import Avatar from '@ui/MUI/Avatar';
import {grey} from '@ui/MUI/colors';
import React from 'react';

function PlayerInfo({student = {}, isLoading = false, ...props}) {
  const {avatar, firstName, lastName, playerNumber, group, hockeyRole, cityOfBirth, hand, birthdayTimestamp} = student;
  const userRole = useStore((state) => state.users?.currentUser?.userRole);
  return (
    <Wrapper flex={2} minWidth={{xxs: '100%', xs: 370}} px={4} py={3} width="fill-available" {...props}>
      <Stack direction="column" height="100%" justifyContent="center">
        <Stack direction="row" gap={3}>
          <Avatar isLoading={isLoading} src={avatar} width={100} />
          <Box>
            {(lastName || isLoading) && (
              <Typography isLoading={isLoading} preloaderWidthBetween={[140, 200]} variant="h1" width="min-content">
                {lastName}
              </Typography>
            )}
            {(firstName || isLoading) && (
              <Typography isLoading={isLoading} preloaderWidthBetween={[140, 200]} variant="h1" width="min-content">
                {firstName}
              </Typography>
            )}
            <Stack alignItems="center" direction="row" gap={3}>
              <HockeyStickAndPuck />
              <Box>
                {(hockeyRole || isLoading) && (
                  <Typography
                    isLoading={isLoading}
                    lineHeight="14px"
                    preloaderWidthBetween={[80, 110]}
                    variant="overline2"
                  >
                    {HOCKEY_ROLES?.[hockeyRole]}
                  </Typography>
                )}
                {(playerNumber || isLoading) && (
                  <Typography
                    display="block"
                    isLoading={isLoading}
                    lineHeight="14px"
                    preloaderWidthBetween={[30, 40]}
                    variant="overline2"
                  >
                    #{playerNumber}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Box>
        </Stack>
        <UserInfoRow isLoading={isLoading} mb={1} title="Команда:" value={group?.name} />
        <UserInfoRow isLoading={isLoading} mb={1} title="Город:" value={cityOfBirth} />
        <Stack
          alignItems="flex-end"
          direction="row"
          flexWrap="wrap"
          gap={2}
          justifyContent="space-between"
          width="100%"
        >
          <Box flex={1} whiteSpace="nowrap">
            <UserInfoRow
              flexWrap="no-wrap"
              isLoading={isLoading}
              mb={1}
              title="Хват:"
              value={HANDS_OPTIONS?.find((item) => item?.value === hand)?.label}
            />
            <UserInfoRow
              flexWrap="no-wrap"
              isLoading={isLoading}
              title="Возраст:"
              value={
                birthdayTimestamp
                  ? date(date().innerDate).diff(date(birthdayTimestamp).innerDate, 'years') + ' лет'
                  : longDash
              }
            />
          </Box>
          {userRole !== userRoles.doctor && <ScoutReportBlock />}
        </Stack>
      </Stack>
    </Wrapper>
  );
}

export default PlayerInfo;

function UserInfoRow({title, value, isLoading, ...props}) {
  return (
    <Stack direction="row" flexWrap="wrap" gap={0} {...props}>
      <Typography color={grey['54']} variant="body1">
        {title}
      </Typography>
      <Typography color={grey['54']} isLoading={isLoading} preloaderWidthBetween={[100, 120]} variant="subtitle1">
        {value || longDash}
      </Typography>
    </Stack>
  );
}
