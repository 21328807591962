import ReactTableComponent from '@common/ReactTableComponent';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import momentDate from '@helpers/date';
import useStore from '@hooks/useStore';
import {selectFuncInjuryHistory} from '@selectors/playerPersonalFile';
import {PLAYER_STATUSES} from '@src/constants';
import {Box, Divider, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {isEmpty} from 'lodash';
import React, {useMemo} from 'react';

function setHeadingCellStyle(theme, rowIndex, rows, showText) {
  const isHeading = rows[rowIndex]?.depth === 0;
  if (isHeading) {
    return {
      backgroundColor: grey['8'] + '!important',
      boxShadow: 'none',
      paddingTop: theme.spacing(2) + '!important',
      paddingBottom: theme.spacing(2) + '!important',
      height: 'auto !important',
      ...(showText
        ? {}
        : {
            fontSize: 0,
            '& *': {
              display: 'none',
            },
          }),
    };
  }
}
function InjuryHistory() {
  const {
    data: {tableData, expandedRows},
    isLoading,
    isLoad,
    error,
  } = useStore(selectFuncInjuryHistory);
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row,
        header: 'Дата',
        cell: (params) => {
          const {date, season} = params.getValue();
          return date ? (
            <Typography isLoading={isLoading} preloaderWidthBetween={[60, 80]} variant="subtitle2">
              {momentDate(date).format('DD.MM.YYYY')}
            </Typography>
          ) : (
            <Typography isLoading={isLoading} preloaderWidthBetween={[60, 80]} variant="caption1">
              {season ? `Сезон ${season}` : ''}
            </Typography>
          );
        },
        cellStyle: (theme, rowIndex, rows) => setHeadingCellStyle(theme, rowIndex, rows, true),
      },
      {
        accessorKey: 'type',
        header: 'Статус',
        cell: (params) => {
          const value = params?.getValue();
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[60, 80]} variant="subtitle2">
              {PLAYER_STATUSES[value] || ''}
            </Typography>
          );
        },
        cellStyle: setHeadingCellStyle,
      },

      {
        accessorKey: 'date',
        header: 'С какого числа',
        cell: (params) => {
          const value = params?.getValue();
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[60, 80]} variant="subtitle2">
              {value ? momentDate(value).format('DD.MM.YYYY') : ''}
            </Typography>
          );
        },
        cellStyle: setHeadingCellStyle,
      },
      {
        accessorKey: 'recoveryDate',
        header: 'По какое число',
        cell: (params) => {
          const value = params?.getValue();
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[60, 80]} variant="subtitle2">
              {value ? momentDate(value).format('DD.MM.YYYY') : ''}
            </Typography>
          );
        },
        cellStyle: setHeadingCellStyle,
      },
      {
        accessorFn: (row) => row,
        header: 'Кол-во дней',
        cell: (params) => {
          const value = params?.getValue();
          const startDate = value?.date;
          const recoveryDate = value?.recoveryDate;
          if (!startDate && !recoveryDate) return '';
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[20, 30]} variant="subtitle2">
              {recoveryDate && startDate
                ? momentDate(recoveryDate).startOf('day').diff(momentDate(startDate).startOf('day'), 'days')
                : ''}
            </Typography>
          );
        },
        cellStyle: setHeadingCellStyle,
      },
    ],
    [isLoading],
  );

  return (
    <Wrapper p="0">
      <Box px={4} py={3}>
        <Typography variant="h4">История болезней и травм</Typography>
      </Box>
      <Divider borderColor={grey['12']} />
      {(isLoad && (isEmpty(tableData) || isEmpty(expandedRows))) || error ? (
        <Stack alignItems="center" justifyContent="center" p={4} width="100%">
          <Typography variant="subtitle2">Нет данных</Typography>{' '}
        </Stack>
      ) : (
        <ReactTableComponent
          columns={columns}
          data={tableData}
          getSubRows={(row) => row.subRows}
          state={{expanded: expandedRows}}
          onExpandedChange={() => {}}
        />
      )}
    </Wrapper>
  );
}

export default InjuryHistory;
