import InputDefault from '../../ui/Input';
import GroupSelect from '../defaultComponents/GroupSelect/index';
import SchoolSelect from '../defaultComponents/SchoolSelect/index';

const patterns = {
  email: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Введите адрес электронной почты в формате mail@mail.ru',
  },
};

export const fields = {
  school: {
    component: SchoolSelect,
  },
  group: {
    component: GroupSelect,
  },
  email: {
    component: InputDefault,
    getter: 'target.value',
    placeholder: 'Электронная почта',
    pattern: patterns.email,
  },
  password: {
    component: InputDefault,
    getter: 'target.value',
    placeholder: 'Пароль',
    isPassword: true,
    minLength: {
      value: 8,
      message: 'Пароль должен содержать не менее 8 символов',
    },
  },
};
