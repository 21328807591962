import PlayerProgressFilters from '@components/apps/PhysicalTesting/components/PlayerProgress/components/PlayerProgressFilters';
import PlayerProgressTable from '@components/apps/PhysicalTesting/components/PlayerProgress/components/PlayerProgressTable';
import Filters from '@components/Filters/Filters';
import isObjectEmpty from '@helpers/isObjectEmpty';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Box, useMediaQuery} from '@mui/material';
import {userRoles} from '@src/constants';
import {clearPhysicalPlayerState, fetchPlayerProgressData} from '@src/redux/slices/PhysicalTestingPlayerProgress';
import TabsNew from '@ui/MUI/TabsNew';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import {uniqBy} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';

import {exercisesForMobileTabs, ofpTestsExercisesOrdersMatrix, sfpTestsExercisesOrdersMatrix} from '../../constants';
import PlayerProgressChart from './components/PlayerProgressChart';

const PlayerProgress = (props) => {
  const dispatch = useStoreDispatch();
  const {items, isLoading} = useStore((state) => state.physicalTestingPlayerProgress.playerProgressInfo);
  const isStudent = useStore((state) => state.users.currentUser.userRole === userRoles.student);
  const currentUserId = useStore((state) => state.users.currentUserId);
  const currentUserGroupId = useStore((state) => state.users.currentUser?.groups?.[0]?.objectId);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [filtersState, setFiltersState] = useState({});

  const filtersValue = useMemo(() => {
    return isStudent
      ? [
          {name: 'season', isMultiple: true, limitTags: 1, isSelectableAll: true},
          {name: 'stage', isMultiple: true, limitTags: 1, isSelectableAll: true},
          {name: 'typeOfTests'},
        ]
      : [
          {name: 'school'},
          {name: 'group'},
          {name: 'student'},
          {name: 'season', isMultiple: true, limitTags: 1, isSelectableAll: true},
          {name: 'stage', isMultiple: true, limitTags: 1, isSelectableAll: true},
          {name: 'typeOfTests'},
        ];
  }, [isStudent]);

  const allExercisesResults = Object.keys(items)
    ?.reduce((res, currentSeason) => {
      res.push(items[currentSeason]);
      return res;
    }, [])
    .flat()
    ?.sort();
  const uniqueExercises = uniqBy(allExercisesResults, (item) => item.exercise)
    .map((item) => ({
      id: item.exercise,
      order: ofpTestsExercisesOrdersMatrix[item.exercise] || sfpTestsExercisesOrdersMatrix[item.exercise],
    }))
    .sort((a, b) => a.order - b.order);
  const formattedInfoForStudentMobileVersion = uniqueExercises.reduce((res, current) => {
    const exercise = allExercisesResults?.filter((result) => {
      return result.exercise === current?.id;
    });
    res[current?.id] = exercise;
    return res;
  }, {});

  const fetchData = () => {
    if (
      (filtersState.student || currentUserId) &&
      filtersState.season?.length &&
      filtersState.stage?.length &&
      filtersState.typeOfTests
    ) {
      const data = {
        groupId: filtersState?.group || currentUserGroupId,
        playerId: isStudent ? currentUserId : filtersState.student,
        season: !isStudent ? filtersState.season : filtersState?.season?.map((item) => item.value),
        stage: !isStudent ? filtersState.stage : filtersState?.stage?.map((item) => item.value),
        type: !isStudent ? filtersState.typeOfTests : filtersState?.typeOfTests?.value,
      };
      dispatch(fetchPlayerProgressData(data));
    }
  };

  const [mobileTabs, setMobileTabs] = useState([]);

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(clearPhysicalPlayerState());
    };
  }, [filtersState]);

  useEffect(() => {
    if (isMobile && filtersState?.typeOfTests?.value) {
      const constructExerciseMarkUp = (tab) =>
        tab?.exerciseIds
          .map((id) => {
            if (formattedInfoForStudentMobileVersion[id]) {
              return (
                <PlayerProgressChart
                  colors={tab.colors}
                  data={formattedInfoForStudentMobileVersion[id] || []}
                  key={id}
                />
              );
            }
          })
          ?.filter((item) => item);
      setMobileTabs(
        exercisesForMobileTabs[filtersState?.typeOfTests?.value]?.map((tab) => {
          return {
            label: tab.name,
            value: !constructExerciseMarkUp(tab)?.length ? (
              <TableEmptyBlock
                data-testid="no-data"
                text="Отсутствуют результаты тестов по выбранному типу упражнений."
              />
            ) : (
              <Box>{constructExerciseMarkUp(tab)}</Box>
            ),
          };
        }),
      );
    }
  }, [isMobile, filtersState?.typeOfTests?.value, isObjectEmpty(formattedInfoForStudentMobileVersion)]);
  return (
    <>
      <Box mb={2}>
        {!isStudent ? (
          <Filters isStage filters={filtersValue} sizes={{xxs: 2}} onChange={setFiltersState} />
        ) : (
          <PlayerProgressFilters filtersState={filtersState} isStudent={isStudent} setFiltersState={setFiltersState} />
        )}
      </Box>
      {isObjectEmpty(items) && !isLoading ? (
        <TableEmptyBlock
          data-testid="no-data"
          text={
            (filtersState.student || currentUserId) &&
            filtersState.season?.length &&
            filtersState.stage?.length &&
            filtersState.typeOfTests
              ? 'Нет данных'
              : 'Выберите вид тестов, сезон и этап, чтобы посмотреть результаты тестирования'
          }
        />
      ) : isStudent && isMobile && mobileTabs?.length > 0 ? (
        <TabsNew withoutWrapper tabs={mobileTabs} />
      ) : (
        <PlayerProgressTable isLoading={isLoading} tableData={items} />
      )}
      {props?.children && React.cloneElement(props.children, {setParentState: setFiltersState})}
    </>
  );
};

export default PlayerProgress;
