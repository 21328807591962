import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Table = styled.div`
  max-width: 700px;
  min-width: 700px;
  min-height: 100vh;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
`;

const Cell = styled.div`
  display: flex;
  padding: ${(props) => !props.noPadding && '5px 0'};
  flex-basis: ${(props) => props.size && `${100 / props.size}%`};
  border-right: ${(props) => !props.noBorder && '1px solid black'};
  &:first-child {
    border-left: ${(props) => !props.noBorder && '1px solid black'};
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.center && 'center'};
  text-align: ${(props) => props.center && 'center'};
  align-items: center;
  margin: ${(props) => props.margin};
`;

export {Cell, Container, Content, Row, Table};
