import FunctionalTestingAPI from '@api/FunctionalTestingAPI';
import UsersAPI from '@api/UsersAPI';
import DeleteModal from '@common/DeleteModal';
import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {
  ButtonsWrapper,
  PlayerCellContainer,
  Wrapper,
} from '@components/apps/FunctionalTesting/components/CreateEditReport/EditCreateTable/EditCreateTable';
import {formatStatesOptions, formatTableDataForSubmitForm} from '@components/apps/FunctionalTesting/helpers';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import {errorMessageNotification} from '@helpers/CommonHelper';
import date from '@helpers/date';
import {useSeasons} from '@hooks/seasonsHooks';
import useElementSize from '@hooks/useElementSize';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import {selectTableEditCreate} from '@selectors/functionalTesting';
import {editFunctionalTestingReport, fetchStates, saveFunctionalTestingReport} from '@slices/functionalTesting';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import ButtonDefault from '@ui/MUI/Button';
import DatePickerDesktop from '@ui/MUI/DatePickerDesktop';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import Typography from '@ui/MUI/Typography';
import React, {useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router';

const TableEditCreate = ({isEdit, tableData}) => {
  const {teamId, typeAction, reportDate} = useParams();

  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isToggled, setToggleUser] = useState(false);

  const {handleSubmit, ...form} = useForm();

  const dispatch = useStoreDispatch();

  const history = useHistory();

  const [sorting, setSorting] = useState([{id: 'player', desc: true}]);

  const [dateOfTesting, setDateOfTesting] = useState(reportDate);

  const [data, setData] = useState();

  const [wasEdit, setWasEdit] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const [bottomBlockRef, {height}] = useElementSize();

  useEffect(() => {
    if (!isEdit && teamId) {
      dispatch(fetchStates());
      UsersAPI.getStudentsV2({
        active: true,
        groupId: teamId,
        page: 1,
        limit: 999,
      }).then((players) => {
        setData(
          players?.result?.map((player) => {
            return {
              player: {
                id: player.id,
                firstName: player.firstName,
                lastName: player.lastName,
                playerNumber: player?.playerNumber || player?.number,
                avatar: player.avatar,
              },
              psycho: 0,
              energy: 0,
              functionalReady: 0,
              functionalState: 0,
            };
          }),
        );
      });
    }
  }, [isEdit, teamId]);

  useEffect(() => {
    if (isEdit) {
      setData(tableData);
    }
  }, [isEdit, tableData?.length]);

  const {states} = useStore(selectTableEditCreate);

  const options = {
    psycho: states && formatStatesOptions(states.psychologic),
    energy: states && formatStatesOptions(states.energy),
    readiness: states && formatStatesOptions(states.readiness),
    functional: states && formatStatesOptions(states.functional),
  };

  const EditableCell = ({getValue, table, row: {index}, column: {id}}) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);

    const onChange = (_, newValue) => {
      setWasEdit(true);
      setValue(newValue);
      table.options.meta.updateData(index, id, newValue);
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    if (id !== 'player') {
      return (
        <Controller
          name={id}
          render={() => {
            return (
              <AutocompleteDefault
                multiple={false}
                options={options[id]}
                placeholder="Выберите"
                value={value?.value !== 0 ? value : null}
                onChange={onChange}
              />
            );
          }}
        />
      );
    } else {
      return (
        <PlayerCellContainer isToggled={isToggled}>
          <PlayersHorizontalCard
            hasImage={!isToggled}
            isShortName={isSmResolution}
            onlyNumber={isToggled}
            student={value}
          />
        </PlayerCellContainer>
      );
    }
  };

  const defaultColumn = {
    cell: EditableCell,
  };

  const columns = [
    {
      accessorKey: 'player',
      header: (
        <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
          Игрок
        </ToggledHeader>
      ),
      sortingFn: 'defaultSorting',
      sticky: {left: true},
    },
    {
      accessorKey: 'psycho',
      header: 'Психоэмоциональная активность',
    },
    {
      accessorKey: 'energy',
      header: 'Уровень энергетических ресурсов',
    },
    {
      accessorKey: 'readiness',
      header: 'Функциональная готовность',
    },
    {
      accessorKey: 'functional',
      header: 'Функциональное состояние',
    },
  ];

  const submitForm = () => {
    if (!dateOfTesting) {
      const title = null;
      errorMessageNotification('Для сохранения отчета, заполните дату контроля', title);
      return false;
    }
    const isDataEmpty = !data.filter((item) => {
      const isAllEmpty = !item.energy && !item.functional && !item.psycho && !item.readiness;
      if (!isAllEmpty) {
        return item;
      }
    }).length;
    if (isDataEmpty) {
      const title = null;
      errorMessageNotification('Для сохранения отчета, внесите данные в таблицу', title);
      return false;
    }
    if (!isEdit) {
      dispatch(
        saveFunctionalTestingReport(
          formatTableDataForSubmitForm({tableData: data.map((test) => ({...test, groupId: teamId})), date: reportDate}),
        ),
      ).then(() => {
        history.push(`/functional_testing/${teamId}/${reportDate}`);
      });
    } else {
      dispatch(editFunctionalTestingReport(formatTableDataForSubmitForm({tableData: data, date: reportDate}))).then(
        () => {
          history.push(`/functional_testing/${teamId}`);
        },
      );
    }
  };

  const deleteReport = (teamId, dateOfTesting) => {
    FunctionalTestingAPI.deleteReport(teamId, dateOfTesting);
  };

  const currentSeason = useSeasons()?.currentSeason;

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Wrapper>
            <DatePickerDesktop
              label="Дата контроля"
              shouldDisableDate={(value) => {
                if (currentSeason?.id) {
                  const getDatesInRange = (startDate, endDate) => {
                    const date = new Date(startDate.getTime());

                    const dates = [];

                    while (date <= endDate) {
                      dates.push(new Date(date));
                      date.setDate(date.getDate() + 1);
                    }

                    return dates;
                  };
                  const start = new Date(currentSeason.startDate);
                  const end = new Date(currentSeason.endDate);
                  const allDatesInSeason = getDatesInRange(start, end).map((dateInRange) =>
                    date(dateInRange).format('YYYY-MM-DD'),
                  );
                  return !allDatesInSeason.includes(date(value).format('YYYY-MM-DD'));
                }
              }}
              value={dateOfTesting}
              onChange={(newValue) => {
                history.push(
                  `/functional_testing_create/${teamId}/${typeAction}/${date(newValue).format('YYYY-MM-DD')}`,
                );
                setDateOfTesting(date(newValue).format('YYYY-MM-DD'));
              }}
            />
          </Wrapper>
          {data?.length > 0 && (
            <ReactTableComponent
              bottomBlockHeight={!isSmResolution ? height + 16 : height + 36}
              columns={columns}
              data={data}
              defaultColumn={defaultColumn}
              isAvailableHeight={true}
              setSorting={setSorting}
              sorting={sorting}
              tableBodyCellProps={{
                sx: {
                  boxShadow: 'rgb(224, 224, 224) -1px 0 0px 0px inset',
                  minWidth: 240,
                  '@media screen and (max-width: 767px)': {
                    width: !isToggled ? '170px !important' : '56px !important',
                    minWidth: 'initial',
                    maxWidth: !isToggled ? 170 : 56,
                    fontSize: '12px !important',
                    '&:not(:first-child)': {
                      minWidth: 'initial',
                      width: '160px !important',
                      maxWidth: 160,
                    },
                  },
                },
              }}
              tableHeaderCellProps={{
                sx: {
                  minWidth: 240,
                  '@media screen and (max-width: 767px)': {
                    minWidth: 'initial',
                    width: !isToggled ? '170px !important' : '56px !important',
                    maxWidth: !isToggled ? 170 : 56,
                    fontSize: '12px !important',
                    '&:not(:first-child)': {
                      minWidth: 'initial',
                      width: '160px !important',
                      maxWidth: 160,
                    },
                  },
                  '&:first-child': {
                    display: 'flex',
                  },
                },
              }}
              updateData={(rowIndex, columnId, value) => {
                setData((old) =>
                  old.map((row, index) => {
                    if (index === rowIndex) {
                      return {...old[rowIndex], [columnId]: value};
                    }
                    return row;
                  }),
                );
              }}
            />
          )}
          <ButtonsWrapper ref={bottomBlockRef}>
            <ButtonDefault color="primary" type="submit" variant="contained">
              Сохранить
            </ButtonDefault>
            <div>
              {isEdit && (
                <ButtonDefault
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                  }}
                >
                  Удалить
                </ButtonDefault>
              )}
              <ButtonDefault
                color="secondary"
                variant="contained"
                onClick={() => {
                  if (wasEdit) {
                    setIsConfirmModalVisible(true);
                  } else {
                    history.goBack();
                  }
                }}
              >
                Отменить
              </ButtonDefault>
            </div>
          </ButtonsWrapper>
        </form>
      </FormProvider>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        text={`Вы уверены, что хотите удалить отчет за ${dateOfTesting} ?`}
        title="Удаление отчета"
        onCancel={() => setIsDeleteModalVisible(false)}
        onDelete={() => {
          deleteReport(teamId, dateOfTesting);
          setIsDeleteModalVisible(false);
          history.push(`/functional_testing/${teamId}`);
        }}
      />
      <DialogDefault
        actionText="Продолжить"
        closeText="Отменить"
        content={<Typography>При отмене, изменения не будут сохранены</Typography>}
        open={isConfirmModalVisible}
        title="Отменить изменения"
        onActionButtonClick={() => {
          setIsConfirmModalVisible(false);
          history.push(`/functional_testing/${teamId}`);
        }}
        onCloseDefault={() => {
          setIsConfirmModalVisible(false);
        }}
      />
    </>
  );
};

export default TableEditCreate;
