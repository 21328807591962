import {addNewVideo} from '@api/videoAnalytics';
import Field from '@common/FormField';
import ToggleBlock from '@common/ToggleBlock';
import {useOpponents} from '@components/apps/TechnicalSkillsVideoEstimation/hooks/useOpponents';
import {useTournaments} from '@components/apps/TechnicalSkillsVideoEstimation/hooks/useTournaments';
import {useVideoTags} from '@components/apps/TechnicalSkillsVideoEstimation/hooks/useVideoTags';
import {useGroup, useSchool, useStudent, useTeacher} from '@hooks/filters';
import {useSeasons} from '@hooks/seasonsHooks';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {LoadingButton} from '@mui/lab';
import {Button, createFilterOptions, Stack, Typography, useMediaQuery} from '@mui/material';
import {editVideo} from '@slices/videoAnalytics';
import {getNormalizedOptions, parseFormToGetValue} from '@src/helpers';
import {Box} from '@ui/MUI';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';

const linkPattern = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
function getVideoCopy(video) {
  if (!video) return {};

  return {
    ...video,
    groups: getNormalizedOptions(video?.groups)?.[0],
    students: video.students?.map(({name, id}) => ({
      label: name,
      value: id,
    })),
    teachers: video.teachers?.map(({name, id}) => ({
      label: name,
      value: id,
    })),
    schoolId: {label: video.schools?.[0]?.name, value: video.schools?.[0]?.id},
    tag: video?.tag ? {label: video.tag?.name, value: video.tag?.slug} : null,
    tournament: !video?.tournament ? null : {value: video.tournament, label: video.tournament},
    opponent: !video?.opponent ? null : {value: video.opponent, label: video.opponent},
  };
}
AddEditModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  video: PropTypes.object,
  getVideoList: PropTypes.func,
  isEdit: PropTypes.bool,
};

const filter = createFilterOptions();

function AddEditModal({isOpen, onClose, title, video, getVideoList, isEdit}) {
  const {
    watch,
    reset,
    handleSubmit,
    resetField,
    formState: {isDirty},
    ...form
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useStoreDispatch();
  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const school = watch('schoolId');
  const group = watch('groups');

  const {schoolId, groupId} = useParams();

  const schools = useSchool({});
  const groups = useGroup({connectorValue: isEdit ? schoolId : school?.value});
  const students = useStudent({connectorValue: group?.value});
  const teachers = useTeacher({connectorValue: group?.value});
  const tags = useVideoTags();

  useEffect(() => {
    if (!isEdit && (!isOpen || !isDirty)) {
      resetToInitialParams();
    }
    if (isEdit && !isOpen) reset(getVideoCopy(video));
  }, [isEdit, isOpen, schools?.options, groups?.options, students?.options]);

  useEffect(() => {
    if (isEdit && !isEmpty(video)) {
      reset(getVideoCopy(video));
    }
  }, [video, isEdit]);

  const onSubmit = (data) => {
    setIsLoading(true);
    const {moments, ...parsed} = parseFormToGetValue(data);

    if (isEdit && video) {
      dispatch(
        editVideo(
          video.id,
          {
            ...parsed,
            opponent: parsed?.opponent?.trim() || null,
            tournament: parsed?.tournament?.trim() || null,
            groups: [parsed.groups],
          },
          (data) => {
            onClose();
            reset(data);
          },
          () => {
            setIsLoading(false);
          },
        ),
      );
    } else {
      addNewVideo({
        ...parsed,
        opponent: parsed?.opponent?.trim(),
        tournament: parsed?.tournament?.trim(),
        groups: [parsed.groups],
      })
        .then(() => {
          getVideoList();
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const currentSeason = useSeasons()?.currentSeason;
  const tournaments = useTournaments({seasons: [currentSeason?.value], groups: [groupId]});
  const tournamentsOptions =
    tournaments?.data?.map((tournament) => {
      return {label: tournament, value: tournament};
    }) || [];
  function resetToInitialParams() {
    const school = schools.options?.find((school) => school.value === schoolId);
    const group = groups.options?.find((group) => group.id === groupId);
    reset({
      schoolId: school,
      groups: group,
      tournament: tournamentsOptions?.[0],
      students: students.options,
    });
  }

  const opponents = useOpponents({seasons: [currentSeason?.value], groups: [groupId]});
  const opponentsOptions =
    opponents?.data?.map((opponent) => {
      return {label: opponent, value: opponent};
    }) || [];

  return (
    <FormProvider {...form}>
      <DrawerDefault
        buttons={
          <Stack direction="row" justifyContent="space-between">
            <LoadingButton disableElevation loading={isLoading} variant="contained" onClick={handleSubmit(onSubmit)}>
              {isEdit ? 'Сохранить' : 'Добавить'}
            </LoadingButton>
            <Button
              disableElevation
              color="secondary"
              variant="contained"
              onClick={() => {
                onClose();
              }}
            >
              Отменить
            </Button>
          </Stack>
        }
        content={
          <Stack>
            <Stack spacing={4}>
              <Typography variant="h3">{title}</Typography>
              <Stack spacing={4}>
                <Box width="100%">
                  <Field isRequired label="Название" name="name" type="input" />
                </Box>
                <Stack direction="row" spacing={4}>
                  <Box width="100%">
                    <Field
                      isRequired
                      disabled={schools.isLoading}
                      isLoading={schools.isLoading}
                      label="Школа"
                      md={6}
                      name="schoolId"
                      options={schools.options}
                      onChange={() => {
                        resetField('groups', {defaultValue: ''});
                        resetField('students', {defaultValue: null});
                        resetField('teachers', {defaultValue: null});
                      }}
                    />
                  </Box>
                  <Box width="100%">
                    <Field
                      isRequired
                      disabled={!school || groups.isLoading}
                      isLoading={groups.isLoading}
                      label="Команда"
                      name="groups"
                      options={groups.options}
                      onChange={() => {
                        resetField('teachers', {defaultValue: null});
                      }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={4}>
                  <Box width="100%">
                    <Field
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            value: params.inputValue,
                            label: params.inputValue,
                          });
                        }

                        return filtered;
                      }}
                      isRequired={false}
                      label="Турнир"
                      limitTags={1}
                      multiple={false}
                      name="tournament"
                      noOptionsText={`Нет доступных вариантов. Вы можете добавить новый турнир, введя его название в поле`}
                      options={tournamentsOptions}
                      placeholder="Введите название турнира"
                    />
                  </Box>
                  <Box width="100%">
                    <Field
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            value: params.inputValue,
                            label: params.inputValue,
                          });
                        }

                        return filtered;
                      }}
                      isRequired={false}
                      label="Соперник"
                      limitTags={1}
                      multiple={false}
                      name="opponent"
                      noOptionsText={`Нет доступных вариантов. Вы можете добавить нового соперника, введя его название в поле`}
                      options={opponentsOptions}
                      placeholder="Введите название соперника"
                    />
                  </Box>
                </Stack>
                <Box width="100%">
                  <Field
                    disabled={tags.isLoading}
                    isLoading={tags.isLoading}
                    label="Тег"
                    multiple={false}
                    name="tag"
                    options={tags.data}
                  />
                </Box>
                <Box width="100%">
                  <Field
                    multiple
                    disabled={!group || students.isLoading}
                    isLoading={students.isLoading}
                    label="Игроки"
                    limitTags={isXsResolution ? 1 : 2}
                    name="students"
                    options={students.options}
                  />
                </Box>
                <ToggleBlock
                  closeText="Дополнительные параметры"
                  contentProps={{width: '100%', mb: '0'}}
                  showText="Дополнительные параметры"
                  wrapperProps={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-start',
                    transition: 'none',
                  }}
                >
                  <Box mt={1} width="100%">
                    <Field
                      multiple
                      disabled={!group || teachers.isLoading}
                      isLoading={teachers.isLoading}
                      label="Тренеры"
                      limitTags={isXsResolution ? 1 : 2}
                      name="teachers"
                      options={teachers.options}
                    />
                  </Box>
                </ToggleBlock>

                <Box width="100%">
                  <Field label="Описание" name="description" type="input" />
                </Box>
                <Box width="100%">
                  <Field
                    isRequired
                    label="Ссылка"
                    name="link"
                    pattern={{value: linkPattern, message: 'Введите корректную ссылку'}}
                    type="input"
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>
        }
        isOpen={isOpen}
        setDrawerState={() => {
          onClose();
        }}
      />
    </FormProvider>
  );
}

export default AddEditModal;
