import {colors} from '@components/apps/DevelopmentJournal/components/PlayersDiary/components/QuestionnaireStatistics';
import BarChart from '@components/charts/BarChart';
import date from '@helpers/date';
import useDeepMemo from '@hooks/useDeepMemo';
import {QUESTIONNAIRE_QUESTION_TYPES} from '@src/constants/playerDiary';
import {Box, Button, Stack} from '@ui/MUI';
import {darkRed, grey} from '@ui/MUI/colors';
import React, {useState} from 'react';

const DevelopmentJournalBarChart = ({
  id = 'morning',
  data,
  questions = [
    QUESTIONNAIRE_QUESTION_TYPES.sleep,
    QUESTIONNAIRE_QUESTION_TYPES.mood,
    QUESTIONNAIRE_QUESTION_TYPES.willToTraining,
  ],
  isLoading,
}) => {
  const [currentQuestions, setCurrentQuestions] = useState(questions?.[0]);

  const richDefaultStyles = {width: 8, height: 8, borderRadius: [50, 50]};

  const formattedData = useDeepMemo(
    () =>
      data?.map((item) => {
        return {
          value: item?.[id]?.[currentQuestions?.value] || 0,
          itemStyle: {
            color: darkRed[100],
            borderRadius: [6, 6, 0, 0],
          },
        };
      }),
    [data, currentQuestions?.value, id],
  );

  return (
    <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" overflow="hidden">
      <BarChart
        options={{
          grid: {
            top: '2%',
            left: '4px',
            right: '4%',
            bottom: '3%',
            containLabel: true,
            borderWidth: 0.5,
            shadowColor: grey[12],
          },
          xAxis: {
            type: 'category',
            data: data?.map((item) => {
              return date(item.date)?.format('DD.MM.YYYY');
            }),
            axisLabel: {
              fontWeight: 600,
              rotate: 45,
              formatter: (value) => {
                const isDayOff =
                  data?.find((item) => date(item.date)?.format('DD.MM.YYYY') === value)?.[id]?.isDayOff || false;
                const isNoValue = !data?.find((item) => date(item.date)?.format('DD.MM.YYYY') === value)?.[id]?.[
                  currentQuestions?.value
                ];
                const text = isDayOff ? 'Выходной' : 'Не заполнено';

                return isNoValue || isDayOff ? `{default| ${value}}\n{empty| ${text}}` : `{default| ${value}}`;
              },
              padding: [75, 54, 30, 4],
              align: 'center',
              rich: {
                default: {
                  color: grey[68],
                },
                empty: {
                  color: grey[54],
                },
              },
            },
            axisLine: {
              lineStyle: {
                color: grey[12],
              },
            },
          },
          yAxis: {
            axisLabel: {
              formatter: (value) => `{${value}|}  ${value}`,
              rich: {
                0: {
                  ...richDefaultStyles,
                  backgroundColor: [...colors]?.[0],
                },
                1: {
                  ...richDefaultStyles,
                  backgroundColor: [...colors]?.[1],
                },
                2: {
                  ...richDefaultStyles,
                  backgroundColor: [...colors]?.[2],
                },
                3: {
                  ...richDefaultStyles,
                  backgroundColor: [...colors]?.[3],
                },
                4: {
                  ...richDefaultStyles,
                  backgroundColor: [...colors]?.[4],
                },
                5: {
                  ...richDefaultStyles,
                  backgroundColor: [...colors]?.[5],
                },
              },
            },
            type: 'value',
            min: 0,
            max: 5,
          },
          series: [
            {
              data: formattedData,
              barWidth: '16px',
              type: 'bar',
            },
          ],
        }}
      />
      <Stack direction="row" flexWrap="wrap" justifyContent="center" maxWidth="80%">
        {questions.map((item, index) => {
          const isActive = currentQuestions?.value === item?.value;
          return (
            <Button
              color={isActive ? 'primary' : 'secondary'}
              disabled={isLoading}
              key={index}
              size="small"
              variant={isActive ? 'contained' : 'text'}
              onClick={() => {
                setCurrentQuestions({...item});
              }}
            >
              {item?.label}
            </Button>
          );
        })}
      </Stack>
    </Box>
  );
};

export default DevelopmentJournalBarChart;
