import date from '@helpers/date';
import {DEFAULT_DATE_SEND_FORMAT} from '@src/constants';
import {Button, RangePicker, Stack} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

IntervalFilter.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

function IntervalFilter({
  value = [date().innerDate.format(DEFAULT_DATE_SEND_FORMAT), date().innerDate.format(DEFAULT_DATE_SEND_FORMAT)],
  onChange = () => {},
  buttons = [
    {
      label: 'Сегодня',
      name: 'day',
      interval: [date().innerDate.format(DEFAULT_DATE_SEND_FORMAT), date().innerDate.format(DEFAULT_DATE_SEND_FORMAT)],
      onClick() {
        return this.interval;
      },
    },
    {
      label: 'Неделя',
      name: 'week',
      interval: [
        date(date().innerDate).subtract(1, 'week').format(DEFAULT_DATE_SEND_FORMAT),
        date().innerDate.format(DEFAULT_DATE_SEND_FORMAT),
      ],
      onClick() {
        return this.interval;
      },
    },
    {
      label: 'Месяц',
      name: 'month',
      interval: [
        date(date().innerDate).subtract(1, 'month').format(DEFAULT_DATE_SEND_FORMAT),
        date().innerDate.format(DEFAULT_DATE_SEND_FORMAT),
      ],
      onClick() {
        return this.interval;
      },
    },
    {
      label: 'Промежуток',
      name: 'interval',
      interval: [
        date(date().innerDate).subtract(1, 'month').format(DEFAULT_DATE_SEND_FORMAT),
        date().innerDate.format(DEFAULT_DATE_SEND_FORMAT),
      ],
    },
  ],
}) {
  const [active, setActive] = useState(buttons?.[0]?.name);
  function handleChange(v) {
    onChange({...value, interval: v || null});
  }
  return (
    <Stack alignItems="center" direction="row" gap={4}>
      <Stack direction="row">
        {buttons.map(({label, name, interval}, index) => {
          const isActive = active === name;
          return (
            <Button
              color={isActive ? 'primary' : 'secondary'}
              key={index}
              size="small"
              variant={isActive ? 'contained' : 'text'}
              onClick={() => {
                setActive(name);
                handleChange(interval);
              }}
            >
              {label}
            </Button>
          );
        })}
      </Stack>
      {active === buttons[buttons.length - 1].name && (
        <RangePicker
          endLabel="До"
          maxDate={date().innerDate}
          startLabel="От"
          value={value?.interval || value}
          variant="standard"
          onChange={(value) => {
            const [start, end] = value;
            if (date(start).isValid() && date(end).isValid())
              handleChange([date(start).format(DEFAULT_DATE_SEND_FORMAT), date(end).format(DEFAULT_DATE_SEND_FORMAT)]);
          }}
        />
      )}
    </Stack>
  );
}

export default IntervalFilter;
