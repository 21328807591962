import {Stack} from '@ui/MUI';
import React, {useEffect} from 'react';

import EstimateNumber from '../EstimateNumber';

const getNumsRange = (min, max) => {
  return Array.from({length: max - min + 1}, (_, i) => min + i);
};

const EstimateRange = ({min = 1, max = 10, value, onChange, disabledFrom = 0}) => {
  const range = getNumsRange(min, max);

  useEffect(() => {
    if (onChange) {
      if (value < disabledFrom) onChange(null);
    }
  }, [disabledFrom, value]);
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      {range.map((item) => {
        const isSelected = item === value;
        const isDisabled = item <= disabledFrom;
        return (
          <EstimateNumber isSelectable isDisabled={isDisabled} isPrimary={isSelected} key={item} onClick={onChange}>
            {item}
          </EstimateNumber>
        );
      })}
    </Stack>
  );
};

export default EstimateRange;
