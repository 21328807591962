import React from 'react';

function User() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M6.49846 0.5C4.36958 0.5 2.63505 2.29948 2.63505 4.51521C2.63813 6.72453 4.36958 8.52722 6.49846 8.53042C8.62734 8.53042 10.3588 6.73094 10.3588 4.51521C10.3588 2.29948 8.62734 0.5 6.49846 0.5ZM6.49846 7.20482C5.0751 7.20482 3.91977 6.0009 3.91977 4.52482C3.91977 3.04873 5.07818 1.84481 6.49846 1.84481C7.92182 1.84481 9.07715 3.04873 9.07715 4.52482C9.07715 6.0009 7.92182 7.20482 6.49846 7.20482Z"
          fill="#887755"
        />
        <path
          d="M6.50154 9.37252C6.48614 9.37252 6.47073 9.37252 6.45841 9.37252C4.88408 9.37252 3.35289 10.0449 2.2561 11.2168C1.12542 12.4176 0.5 14.0569 0.5 15.834C0.5 16.199 0.789602 16.5 1.14082 16.5H11.8592C12.2104 16.5 12.5 16.199 12.5 15.834C12.5 14.0569 11.8777 12.4176 10.747 11.2168C9.63787 10.0353 8.09127 9.35972 6.50154 9.37252ZM11.1814 15.168H1.81553L1.83402 15.0143C1.96958 13.9449 2.44095 12.9267 3.16804 12.1518C4.043 11.2328 5.25687 10.7045 6.49846 10.7045C7.74005 10.7045 8.95392 11.2328 9.82888 12.1486C10.5529 12.9235 11.0273 13.9417 11.1629 15.0111L11.1814 15.168Z"
          fill="#887755"
        />
      </g>
    </React.Fragment>
  );
}
export default User;
