import {
  getTeamFavoritesLines,
  handleChangeForm,
  handleFavoriteLinesChangeList,
  saveLinesForm,
  setActualLines,
} from '@actions/LinesActions';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {DeleteOutline, Sync} from '@mui/icons-material';
import {Typography} from '@mui/material';
import ButtonDefault from '@ui/MUI/Button';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import SelectDefault from '@ui/MUI/Select';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ButtonsBlock} from '../LinesMobile';
import LinesMobileTableComponent from '../LinesMobileTable';
import {ConfirmModalContent, ConfirmModalContentText, TitleBlock, Wrapper} from './Favorites';

export default function FavoritesMobile({teamId, activeTab}) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    if (teamId) {
      //ToDo: вынести из then в callback
      dispatch(getTeamFavoritesLines(teamId)).then(() => {
        setIsLoading(false);
      });
    }
  }, [teamId, activeTab]);

  const linesFavoriteList = useSelector((state) => state.lines.linesFavoriteList || []);

  const [linesOptions, setLinesOptions] = useState([]);
  const [activeFavorite, setActiveFavorite] = useState();

  const [isDeleteModalShowed, setIsDeleteModalShowed] = useState(false);
  const [isActualModalShowed, setIsActualModalShowed] = useState(false);

  const cleanLines = () => {
    //ToDo: зарефакторить клонирование
    const currentLinesForm = activeFavorite ? JSON.parse(JSON.stringify(activeFavorite)) : {};

    currentLinesForm.playersLines = [
      {
        name: 'playersLine_1',
        wingersLine: [],
        defensemansLine: [],
      },
      {
        name: 'playersLine_2',
        wingersLine: [],
        defensemansLine: [],
      },
      {
        name: 'playersLine_3',
        wingersLine: [],
        defensemansLine: [],
      },
      {
        name: 'playersLine_4',
        wingersLine: [],
        defensemansLine: [],
      },
    ];
    currentLinesForm.goaltendersLine = [];
    currentLinesForm.isFavorites = false;
    currentLinesForm.favoritesName = null;
    dispatch(handleChangeForm(currentLinesForm));
  };

  const deleteLinesFromFavorite = () => {
    let currentLinesForm = activeFavorite ? {...activeFavorite, isFavorites: false, favoritesName: null} : {};
    let newLinesFavoriteList = linesFavoriteList.filter((item) => item.objectId !== currentLinesForm.objectId);
    //ToDo: вынести из then в callback
    dispatch(saveLinesForm(currentLinesForm, 'Состав удален из избранного')).then(() => {
      cleanLines();
      dispatch(handleFavoriteLinesChangeList(newLinesFavoriteList));
    });
  };

  useEffect(() => {
    cleanLines();
  }, []);

  useEffect(() => {
    if (linesFavoriteList?.length > 0) {
      setLinesOptions(
        linesFavoriteList.map((favoriteItem) => ({
          value: favoriteItem.objectId,
          label: favoriteItem.favoritesName,
        })),
      );
      setActiveFavorite(linesFavoriteList[0]);
    }
  }, [linesFavoriteList?.length]);

  return (
    <Wrapper>
      <LocalPreloader visible={isLoading} />
      {!activeFavorite && (
        <TitleBlock isEmptyBlock={true}>
          <Typography color="#000" variant="subtitle2">
            Выберите избранный состав
          </Typography>
        </TitleBlock>
      )}
      <SelectDefault
        hasFixedLabel={false}
        label="Состав"
        options={linesOptions}
        value={activeFavorite?.objectId}
        onChange={(e) => {
          setActiveFavorite(linesFavoriteList.find((item) => item.objectId === e.target.value));
        }}
      />
      {activeFavorite && (
        <>
          <TitleBlock>
            <Typography color="#757575" component="p" variant="caption1">
              Избранный состав
            </Typography>
            <Typography color="#000" variant="subtitle2">
              {activeFavorite?.favoritesName}
            </Typography>
          </TitleBlock>
          <ButtonsBlock>
            <ButtonDefault
              disabled={activeFavorite?.isActual || false}
              startIcon={<Sync />}
              variant="text"
              onClick={() => {
                setIsActualModalShowed(true);
              }}
            >
              {!activeFavorite?.isActual ? 'Сделать состав актуальным' : 'Это актуальный состав'}
            </ButtonDefault>
            <ButtonDefault
              startIcon={<DeleteOutline />}
              variant="text"
              onClick={() => {
                setIsDeleteModalShowed(true);
              }}
            >
              Удалить состав из избранных
            </ButtonDefault>
          </ButtonsBlock>
          <LinesMobileTableComponent isButtonsShowed={false} linesForm={activeFavorite} />
        </>
      )}
      <DialogDefault
        actionText="Удалить"
        closeText="Вернуться к составу"
        content={
          <ConfirmModalContent>
            <ConfirmModalContentText>
              Вы хотите удалить состав «{activeFavorite?.favoritesName}» из списка избранных?
            </ConfirmModalContentText>
          </ConfirmModalContent>
        }
        handleClickOpen={() => {
          setIsDeleteModalShowed(true);
        }}
        open={isDeleteModalShowed}
        title="Удалить состав из избранных"
        onActionButtonClick={() => {
          deleteLinesFromFavorite();
          setIsDeleteModalShowed(false);
        }}
        onCloseDefault={() => {
          setIsDeleteModalShowed(false);
        }}
      />
      <DialogDefault
        actionText="Сделать актуальным"
        closeText="Вернуться к составу"
        content={
          <ConfirmModalContent>
            <ConfirmModalContentText>
              Вы хотите сделать состав «{activeFavorite?.favoritesName}» актуальным на ближайшее мероприятие?
            </ConfirmModalContentText>
          </ConfirmModalContent>
        }
        handleClickOpen={() => {
          setIsActualModalShowed(true);
        }}
        open={isActualModalShowed}
        title="Сделать состав актуальным"
        onActionButtonClick={() => {
          dispatch(setActualLines(activeFavorite.objectId)).then(() => {
            cleanLines();
          });
          setIsActualModalShowed(false);
        }}
        onCloseDefault={() => {
          setIsActualModalShowed(false);
        }}
      />
    </Wrapper>
  );
}
