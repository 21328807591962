import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import useElementSize from '@hooks/useElementSize';
import {Box, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

NamedCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

function NamedCard({title, children, p = 3, ...props}) {
  const [ref, {height}] = useElementSize();
  return (
    <Wrapper p="0" {...props}>
      <TitleWrapper ref={ref}>
        <Typography p={p} variant="h4">
          {title}
        </Typography>
      </TitleWrapper>
      <Box height={`calc(100% - ${height}px)`} p={p}>
        {children}
      </Box>
    </Wrapper>
  );
}

export default NamedCard;

const TitleWrapper = styled(Box)`
  border-bottom: 1px solid var(--border-12, ${grey['12']});
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
`;
