import React from 'react';

import {Button, ButtonContainer, Indicator} from './LoadingButton';

const LoadingButton = ({loading, label, disabled}) => {
  return (
    <ButtonContainer>
      <Button disabled={disabled || loading} loading={loading} type="submit">
        {!loading && label}
      </Button>
      {loading && <Indicator size={25} sx={{color: 'grey.500'}} />}
    </ButtonContainer>
  );
};

export default LoadingButton;
