import {Wrapper} from '@components/apps/NewBodyMassIndex/BodyMassIndex';
import date from '@helpers/date';
import {useGroup, useSchool} from '@hooks/filters';
import useDeepEffect from '@hooks/useDeepEffect';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import AddIcon from '@mui/icons-material/Add';
import {selectCommonReportFilters} from '@selectors/bodyMassIndex';
import {clearCommonReportFilter, setCommonReportFilter} from '@slices/bodyMassIndex';
import {Autocomplete, Box, Button, RangePicker, Stack, Tooltip} from '@ui/MUI';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router';

Filters.propTypes = {};

function Filters({canEdit = true}) {
  const history = useHistory();
  const dispatch = useStoreDispatch();
  const schools = useSchool({});
  const filters = useStore(selectCommonReportFilters);
  const groups = useGroup({connectorValue: filters?.schools?.value, isGetAll: !filters?.schools?.value});
  const [qs, set] = useQueryString();
  useEffect(() => {
    if (schools?.value && !qs?.groupId) {
      setFilter('schools', schools.value);
    }
  }, [schools?.value]);

  useEffect(() => {
    return () => {
      dispatch(clearCommonReportFilter());
    };
  }, []);

  useDeepEffect(() => {
    if (!filters.groups?.value && qs?.groupId && groups?.options?.length && schools?.options?.length) {
      const group = groups.options.find((item) => item?.value === qs.groupId);
      const school = schools.options.find((school) => school?.value === group?.schoolId);
      if (group?.value && school?.value) {
        dispatch(setCommonReportFilter({...filters, schools: school, groups: group}));
      }
    }
  }, [qs?.groupId, filters?.groups?.value, groups, schools]);

  function setFilter(name, value) {
    return dispatch(setCommonReportFilter({...filters, [name]: value}));
  }

  useEffect(() => {
    if (groups.options.length === 1) {
      setFilter('groups', groups.options?.[0]);
    }
  }, [groups.options.length]);

  return (
    <Wrapper px={{xxs: 2, sm: 4}} py={{xxs: 1, sm: 3}}>
      <Stack alignItems="flex-end" direction="row" flexWrap={{xxs: 'wrap', sm: 'nowrap'}} gap={1}>
        <Box width={{xxs: '100%', sm: 220}}>
          <Autocomplete
            disabled={(groups.isLoading && qs?.groupId) || schools.isLoading}
            isLoading={(groups.isLoading && qs?.groupId) || schools.isLoading}
            label="Школа"
            multiple={false}
            options={schools.options}
            value={filters?.schools}
            onChange={(e, v) => {
              dispatch(setCommonReportFilter({...filters, schools: v, groups: null}));
              set({groupId: ''});
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: 220}}>
          <Autocomplete
            disabled={groups.isLoading || !filters?.schools}
            isLoading={groups.isLoading}
            label="Команда"
            multiple={false}
            options={groups.options}
            value={filters?.groups}
            onChange={(e, v) => {
              setFilter('groups', v);
              set({groupId: v?.value});
            }}
          />
        </Box>
        <RangePicker
          endLabel="До"
          inputFormat={'DD.MM.YYYY'}
          maxDate={date().currentDate}
          startLabel="От"
          value={filters.period}
          variant="standard"
          onChange={(v) => {
            setFilter('period', v);
          }}
        />
        {canEdit && (
          <Tooltip placement="bottom" title={!filters?.groups ? 'Заполните фильтр "Команда"' : ''}>
            <Box ml={{xxs: 'unset', xs: 'auto !important'}}>
              <Button
                color="primary"
                data-testid="add-report"
                disabled={!filters?.groups}
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={() => filters.groups?.value && history.push(`/bmi/add/${filters.groups.value}`)}
              >
                Добавить отчет
              </Button>
            </Box>
          </Tooltip>
        )}
      </Stack>
    </Wrapper>
  );
}

export default Filters;
