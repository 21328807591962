import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {fetcherTrainingControl} from '@slices/trainingControlProcess';
import {userRoles} from '@src/constants';
import {useEffect} from 'react';

export const useInitialLoading = () => {
  const dispatch = useStoreDispatch();

  const userRole = useStore((state) => state?.users?.currentUser?.userRole);
  const isStudent = userRole === userRoles.student;

  useEffect(() => {
    if (!isStudent) {
      dispatch(fetcherTrainingControl('exercise'));
      dispatch(fetcherTrainingControl('skill'));
      dispatch(fetcherTrainingControl('theme'));
      dispatch(fetcherTrainingControl('section'));
      dispatch(fetcherTrainingControl('category'));
      dispatch(fetcherTrainingControl('age'));
    }
  }, [dispatch, isStudent]);
};

export const useExercise = () => {
  return useStore((state) => state.trainingControlProcess.exercise);
};

export const useAge = () => useStore((state) => state.trainingControlProcess.age);

export const useSkill = () => useStore((state) => state.trainingControlProcess.skill);

export const useSection = () => useStore((state) => state.trainingControlProcess.section);

export const useTheme = () => useStore((state) => state.trainingControlProcess.theme);

export const useCategory = () => useStore((state) => state.trainingControlProcess.category);

export const useNotEstimatedTraining = () => useStore((state) => state.trainingControlProcess.training_not_estimated);

export const useOneTraining = () => useStore((state) => state.trainingControlProcess.training);

export const useEstimatedTraining = () => useStore((state) => state.trainingControlProcess.training_estimated);
