import {getStatsForOneGame} from '@api/StatsAPI';
import AddStatsForm from '@common/AddStatsForm';
import {longDash} from '@src/constants';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {useHistory} from 'react-router-dom';

interface GameInfo {
  date?: string;
  label?: string;
  value?: string;
}

interface FormDataInfo {
  teamNames?: string[];
  gameInfo?: GameInfo;
}

type FormData = {
  isLoading: boolean;
  data: FormDataInfo;
};

function EditStatsForm() {
  const history = useHistory();
  const statisticId = useParams<{id: string}>()?.id;
  const [formData, setFormData] = useState<FormData>({isLoading: false, data: {}});

  useEffect(() => {
    if (statisticId) {
      setFormData((prevState) => ({...prevState, isLoading: true}));
      getStatsForOneGame({statisticId})
        .then(({data}) => setFormData({isLoading: false, data}))
        .catch(() => setFormData((prevState) => ({...prevState, isLoading: false})));
    }
  }, [statisticId]);
  const title = useMemo(
    () =>
      formData?.data?.teamNames?.length
        ? `Игра "${formData.data.teamNames.join(` ${longDash} `)} " ${formData?.data?.gameInfo?.date || ''}`
        : 'Редактирование статистики',
    [formData?.data?.teamNames],
  );
  return (
    <>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Редактирование статистики', path: '/edit_stats'},
          {label: 'Редактирование данных', path: null, isDynamic: true},
        ]}
        isLoading={formData?.isLoading}
        text={title}
      />
      <AddStatsForm
        isEdit
        formData={formData?.data || {}}
        isLoading={formData?.isLoading}
        onSuccess={() => history.push('/edit_stats')}
      />
    </>
  );
}

export default EditStatsForm;
