import {useArchiveEstimates} from '@common/DevelopmentJournal/hooks';
import FullHeightWrapper from '@common/FullHeightWrapper';
import ReactTableComponent from '@common/ReactTableComponent';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {Typography} from '@ui/MUI';
import React from 'react';

import {columns} from './constants';

const Table = () => {
  const {isLoading, isNotFilledFilters, data, isNoData} = useArchiveEstimates();
  if (isNotFilledFilters)
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
        <Typography variant="subtitle1">Заполните фильтры, чтобы увидеть данные</Typography>
      </FullHeightWrapper>
    );

  if (isLoading)
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center" position="relative">
        <LocalPreloader visible />
      </FullHeightWrapper>
    );

  if (isNoData) {
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
        <Typography variant="subtitle1">Нет данных</Typography>
      </FullHeightWrapper>
    );
  }

  return <ReactTableComponent columns={columns} data={data} />;
};

export default Table;
