import CommonHelper from '@helpers/CommonHelper';
import date from '@helpers/date';
import {Add, DeleteOutline, StarOutline} from '@mui/icons-material';
import {Typography} from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import ButtonDefault from '@ui/MUI/Button';
import SelectDefault from '@ui/MUI/Select';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import React, {useState} from 'react';

import {TABS} from './constants';
import FavoritesMobile from './Favorites';
import LinesHistory from './LinesHistory';
import {
  AddPlayerFromOtherTeam,
  ButtonsBlock,
  ButtonsBottomBlock,
  Container,
  GlobalStyle,
  MobileSelectWrapper,
  NearestGame,
  Puller,
  SelectContainer,
  StyledSelect,
  Wrapper,
} from './LinesMobile';
import LinesMobileTableComponent from './LinesMobileTable';
import LinesPlayersList from './LinesPlayersList';

export default function LinesMobile({
  activeTab,
  setActiveTab,
  isCanEdit,
  nearestGame,
  nearestTournament,
  teamName,
  teamId,
  linesForm,
  basket,
  isActiveCard,
  selectPlayerPosition,
  selectGoaltenderPosition,
  handleAddLine,
  setClearLinesModalState,
  saveLines,
  setCancelModalState,
  modalGroupOptions,
  modalSelectedGroup,
  setModalPlayersOptions,
  setModalSelectedGroup,
  modalPlayersOptions,
  modalSelectedPlayer,
  setModalSelectedPlayer,
  setModalIsVisible,
  deletePlayerFromPosition,
  deleteGoaltenderPlayerFromPosition,
  addToFavorites,
}) {
  const [isPlayersListModalOpen, setIsPlayersListModalOpen] = useState(false);
  const [isAddFromOtherTeamModalOpen, setIsAddFromOtherTeamModalOpen] = useState(false);

  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [selectedPlayerFromOtherTeam, setSelectedPlayerFromOtherTeam] = useState({});

  return (
    <Wrapper>
      <MobileSelectWrapper>
        <StyledSelect
          hasFixedLabel
          label={TABS[activeTab].label}
          options={TABS}
          onChange={(e) => {
            setActiveTab(e.target.value);
          }}
        />
      </MobileSelectWrapper>
      <Container>
        {activeTab === '0' && (
          <>
            <NearestGame>
              <Typography color="#757575" component="p" variant="caption1">
                Ближайшее мероприятие
              </Typography>
              {nearestGame ? (
                <>
                  <Typography color="#B63830" variant="subtitle2">
                    {date(nearestGame.rooms[0].startTimestamp).format('DD.MM.YYYY')}{' '}
                    {date(nearestGame.rooms[0].startTimestamp).format('HH:mm')}
                  </Typography>
                  {nearestGame.type === 'game' ? (
                    <>
                      <Typography color="#000" variant="subtitle2">
                        {nearestTournament && `"${nearestTournament}"`}
                      </Typography>
                      <Typography color="#000" variant="subtitle2">
                        {`${teamName} - ${nearestGame?.opponent}`}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography color="#000" variant="subtitle2">
                        {nearestTournament && `"${nearestTournament}"`}
                      </Typography>
                      <Typography color="#000" variant="subtitle2">
                        {`${nearestGame?.opponent} - ${teamName}`}
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <Typography color="#000" variant="subtitle2">
                  Отсутствует ближайшее мероприятие
                </Typography>
              )}
            </NearestGame>
            <ButtonsBlock>
              <ButtonDefault
                color={'primary'}
                startIcon={<Add />}
                variant="text"
                onClick={() => {
                  handleAddLine && handleAddLine();
                }}
              >
                Добавить звено
              </ButtonDefault>
              <ButtonDefault
                color={'primary'}
                startIcon={<DeleteOutline />}
                variant="text"
                onClick={() => {
                  setClearLinesModalState(true);
                }}
              >
                Очистить состав
              </ButtonDefault>
              <ButtonDefault
                color={'primary'}
                startIcon={<StarOutline />}
                variant="text"
                onClick={() => {
                  addToFavorites && addToFavorites();
                }}
              >
                Добавить в избранное
              </ButtonDefault>
            </ButtonsBlock>
            <LinesMobileTableComponent
              deleteGoaltenderPlayerFromPosition={deleteGoaltenderPlayerFromPosition}
              deletePlayerFromPosition={deletePlayerFromPosition}
              isActiveCard={isActiveCard}
              isCanEdit={isCanEdit}
              linesForm={linesForm}
              saveLines={saveLines}
              setCancelModalState={setCancelModalState}
              setIsPlayersListModalOpen={setIsPlayersListModalOpen}
              setSelectedPlayer={setSelectedPlayer}
            />
          </>
        )}
        {activeTab === '1' && <FavoritesMobile activeTab={activeTab} teamId={teamId} />}
        {activeTab === '2' && <LinesHistory activeTab={activeTab} setActiveTab={setActiveTab} teamId={teamId} />}
      </Container>
      <GlobalStyle />
      <SwipeableDrawer
        ModalProps={{
          keepMounted: true,
        }}
        anchor="bottom"
        disableSwipeToOpen={false}
        open={isPlayersListModalOpen}
        swipeAreaWidth={0}
        onClose={() => {
          setIsPlayersListModalOpen(false);
        }}
        onOpen={() => {
          setIsPlayersListModalOpen(true);
        }}
      >
        <Puller />
        <LinesPlayersList
          basket={basket}
          selectGoaltenderPosition={selectGoaltenderPosition}
          selectPlayerPosition={selectPlayerPosition}
          selectedPlayer={selectedPlayer}
          setIsPlayersListModalOpen={setIsPlayersListModalOpen}
        />
        <AddPlayerFromOtherTeam>
          <ButtonDefault
            onClick={() => {
              setIsPlayersListModalOpen(false);
              setModalIsVisible(true);
              setIsAddFromOtherTeamModalOpen(!isAddFromOtherTeamModalOpen);
            }}
          >
            <Typography color="#B63830" component="p" variant="subtitle2">
              Добавить игрока из другой команды
            </Typography>
          </ButtonDefault>
        </AddPlayerFromOtherTeam>
      </SwipeableDrawer>
      <SwipeableDrawer
        ModalProps={{
          keepMounted: true,
        }}
        anchor="bottom"
        disableSwipeToOpen={false}
        open={isAddFromOtherTeamModalOpen}
        swipeAreaWidth={0}
        onClose={() => {
          setModalIsVisible(false);
          setIsAddFromOtherTeamModalOpen(false);
        }}
        onOpen={() => {
          setModalIsVisible(true);
          setIsAddFromOtherTeamModalOpen(true);
        }}
      >
        <Typography color="#000" component="p" variant="subtitle2">
          Добавление игрока из другой команды
        </Typography>
        <SelectContainer>
          <SelectDefault
            hasFixedLabel={false}
            isMulti={false}
            label="Команда"
            options={CommonHelper.getDropDownTeamsByNamedList(modalGroupOptions)}
            value={modalSelectedGroup?.value}
            onChange={(e) => {
              setModalPlayersOptions && setModalPlayersOptions([]);
              setModalSelectedGroup && setModalSelectedGroup({value: e.target.value});
            }}
          />
        </SelectContainer>
        <SelectContainer>
          <SelectDefault
            hasFixedLabel={false}
            isMulti={false}
            label="Игрок"
            options={CommonHelper.getDropDownItemsByNamedList(modalPlayersOptions)}
            value={modalSelectedPlayer?.value}
            onChange={(e) => {
              setModalSelectedPlayer && setModalSelectedPlayer({value: e.target.value});
              setSelectedPlayerFromOtherTeam(modalPlayersOptions.find((player) => player.id === e.target.value));
            }}
          />
        </SelectContainer>
        <ButtonsBottomBlock>
          <ButtonDefault
            color="primary"
            variant="contained"
            onClick={() => {
              if (selectedPlayerFromOtherTeam.student?.status !== 'available') {
                ReactComponentNotification(NOTIFICATION_TYPES['info'], `Игрок недоступен для выбора`);
                return;
              } else {
                if (selectedPlayer.playersType !== 'goaltendersLine') {
                  selectPlayerPosition &&
                    selectPlayerPosition(
                      selectedPlayer.playersType,
                      selectedPlayer.lineIndex,
                      selectedPlayer.playerPosition,
                      selectedPlayerFromOtherTeam.student,
                    );
                } else {
                  selectGoaltenderPosition &&
                    selectGoaltenderPosition(selectedPlayer.playerPosition, selectedPlayerFromOtherTeam.student);
                }
                setModalIsVisible(false);
                setIsAddFromOtherTeamModalOpen(false);
              }
            }}
          >
            Добавить
          </ButtonDefault>
          <ButtonDefault
            color="neutral"
            variant="contained"
            onClick={() => {
              setModalIsVisible(false);
              setIsAddFromOtherTeamModalOpen(false);
            }}
          >
            Отменить
          </ButtonDefault>
        </ButtonsBottomBlock>
      </SwipeableDrawer>
    </Wrapper>
  );
}
