import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ReactComponentModal from '../../../ui/ReactComponent/ReactComponentModal/ReactComponentModal';
import {StyledButton} from '../CommonScoutReport';

function CancelModal({isVisible, onClose, onCancel}) {
  return (
    <ReactComponentModal
      buttons={
        <>
          <StyledButton color="primary" margin={0} size="medium" variant="contained" onClick={onClose}>
            Закрыть
          </StyledButton>
          <StyledButton color="neutral" margin={0} size="dark" variant="contained" onClick={onCancel}>
            отменить
          </StyledButton>
        </>
      }
      className="ReactComponentModalDefault LinesNotSaved"
      content={<ModalBodyText className="ErrorText">Вы уверены, что хотите закрыть окно без сохранения?</ModalBodyText>}
      title={'Закрыть без сохранения'}
      visible={isVisible}
      onClose={onCancel}
    />
  );
}

CancelModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CancelModal;

const ModalBodyText = styled.p`
  margin-bottom: 34px;
`;
