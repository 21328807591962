import styled from 'styled-components';

export const Track = styled.div`
  background: rgba(196, 196, 196, 0.4) !important;
  border-radius: 3px !important;
  width: 6px !important;
`;

export const Thumb = styled.div`
  width: 4px !important;
  background: #c4c4c4 !important;
  border-radius: 2px !important;
`;
