import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilterContainer = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 14px;

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0;
  }

  box-sizing: border-box;
  @media screen and (max-width: 1440px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1199px) {
    margin-bottom: 12px;

    :nth-child(4),
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
    :nth-child(4) {
      margin-bottom: 12px;
    }
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    :nth-child(4) {
      margin-bottom: 12px;
    }
  }
`;

export const Wrapper = styled.div`
  padding: 12px 34px 20px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 6px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font: 600 normal 12px/16px 'Proxima Nova';
  color: rgb(116, 116, 116);
  text-transform: uppercase;
`;
