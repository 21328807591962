import {FiltersWrapper, InputWrapper} from '@components/apps/PhysicalTesting/components/CompareStages/CompareStages';
import Filters from '@components/Filters/Filters';
import stateHelper from '@helpers/StateHelper';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {FormControlLabel} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {selectCompareInfo} from '@selectors/physicalTesting';
import {userRoles} from '@src/constants';
import {clearPhysicalCompareState, fetchCompareData} from '@src/redux/slices/PhysicalTestingCompare';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';

import CompareStagesTable from './components/CompareStagesTable';

const CompareStages = (props) => {
  const dispatch = useStoreDispatch();
  const history = useHistory();

  const isStudent = useStore((state) => state.users.currentUser.userRole === userRoles.student);
  const {items, isLoading} = useStore(selectCompareInfo);
  const state = useStore((state) => state);
  const [isPlayersHide, setHidePlayers] = useState(false);
  const [filters, setFilters] = useState({});
  const studentGroup = stateHelper.getGroups(state);

  const filtersValue = useMemo(() => {
    return isStudent
      ? [{name: 'typeOfTests'}, {name: 'season'}, {name: 'stage'}, {name: 'stageSeasons'}]
      : [
          {name: 'school'},
          {name: 'group'},
          {name: 'typeOfTests'},
          {name: 'season'},
          {name: 'stage'},
          {name: 'stageSeasons'},
        ];
  }, [isStudent]);

  const fetchData = () => {
    if (
      (filters.group || studentGroup) &&
      filters.stageSeasons &&
      filters.stage &&
      filters.season &&
      filters.typeOfTests
    ) {
      const season = filters.stageSeasons?.season;
      const seasonForComparsing = filters.season;
      const stage = filters.stageSeasons?.stage;
      const stageForComparsing = filters.stage;

      dispatch(
        fetchCompareData({
          groupId: isStudent ? studentGroup[0].id : filters.group,
          season: season,
          stage: Number(stage),
          stageForComparsing: Number(stageForComparsing),
          seasonForComparsing: seasonForComparsing,
          type: filters.typeOfTests,
        }),
      );
    }
  };

  const isAllFiltersNotEmpty =
    !!filters?.group &&
    !!filters?.school &&
    !!filters?.season &&
    !!filters?.stage &&
    !!filters?.stageSeasons &&
    !!filters?.typeOfTests;

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(clearPhysicalCompareState());
    };
  }, [filters]);

  const formattedTableData = useMemo(() => {
    return items?.filter((player) => {
      if (isPlayersHide) {
        return player?.vector !== null && player?.params?.length;
      }
      return true;
    });
  }, [isPlayersHide, items]);

  return (
    <>
      <>
        <FiltersWrapper>
          <Filters
            isStage
            filters={filtersValue}
            sizes={{xxs: 3}}
            onChange={(filters) => {
              setFilters(filters);
              if (filters?.group) {
                history.push(`/physical_testing_new/compare_stages?groupId=${filters?.group}`);
              } else {
                history.push(`/physical_testing_new/compare_stages`);
              }
            }}
          />
          <InputWrapper>
            <FormControlLabel
              checked={isPlayersHide}
              control={<Checkbox />}
              label="Скрыть игроков без данных"
              labelPlacement="end"
              value="isPlayersHidden"
              onChange={() => setHidePlayers((prev) => !prev)}
            />
          </InputWrapper>
        </FiltersWrapper>
        {!isAllFiltersNotEmpty ? (
          <TableEmptyBlock text="Выберите вид тестов, сезон и этап, чтобы посмотреть результаты тестирования." />
        ) : (
          <>
            {!isLoading && !items?.length ? (
              <TableEmptyBlock text="Нет данных." />
            ) : (
              <CompareStagesTable filters={filters} isLoading={isLoading} tableData={formattedTableData} />
            )}
          </>
        )}
      </>
      {props?.children && React.cloneElement(props.children, {setParentState: setFilters})}
    </>
  );
};

export default CompareStages;
