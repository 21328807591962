import ContentWrapper from '@common/ContentWrapper';
import ReactTableComponent from '@common/ReactTableComponent';
import {mock} from '@common/Statistics/PlayerStatistics/Throwins/ThrowinsVideoTable/mock';
import ThrowinVideoModal from '@common/Statistics/PlayerStatistics/Throwins/ThrowinVideoModal';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import {useMediaQuery} from '@mui/material';
import {selectVideoModal} from '@selectors/gameStatistics';
import {setVideoModal} from '@slices/statistics';
import {longDash} from '@src/constants';
import {ZONE_LABELS} from '@src/constants/stitictics';
import {Autocomplete, Box, Button, IconButton, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {sortBy} from 'lodash';
import React, {useState} from 'react';

ThrowinsVideoTable.propTypes = {};

const options = Object.values(ZONE_LABELS);
function ThrowinsVideoTable({getter}) {
  const dispatch = useStoreDispatch();
  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {data, isLoading} = useStore(getter);
  const [throwinPointFilter, setThrowinPointFilter] = useState(null);
  const filteredData = useDeepMemo(() => {
    const result = throwinPointFilter?.value
      ? data?.table?.filter((item) => item?.zone === throwinPointFilter?.value)
      : data?.table || [];
    return [...result]?.sort((a, b) => a?.videoTime?.localeCompare(b?.videoTime));
  }, [data, throwinPointFilter?.value]);
  const columns = useDeepMemo(() => {
    const columns = [
      {
        order: 1,
        accessorKey: 'time',
        header: 'Время',
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[70, 100]} variant="body2">
              {getValue()}
            </Typography>
          );
        },
      },
      {
        order: 2,
        accessorKey: 'zone',
        header: 'Точка вбрасывания',
        cell: ({getValue}) => {
          const value = getValue();
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[100, 140]} variant="body2">
              {value ? ZONE_LABELS?.[value]?.label : longDash}
            </Typography>
          );
        },
      },
      {
        order: 3,
        accessorKey: 'isWeWin',
        header: 'Результат',
        cell: ({getValue}) => {
          const value = getValue();
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[100, 140]} variant="body2">
              {value ? 'Выигранное' : 'Проигранное'}
            </Typography>
          );
        },
      },
      {
        order: isXsResolution ? 0 : 4,
        sticky: {left: isXsResolution},
        accessorFn: (data, index) => {
          return {...data, index: index + 1};
        },
        header: 'Видео',
        cell: ({getValue}) => {
          const value = getValue();
          const commonProps = {
            color: 'primary',
            disabled: isLoading,
            onClick: () => {
              dispatch(
                setVideoModal({
                  isOpen: true,
                  data: value,
                }),
              );
            },
          };
          return value ? (
            isXsResolution ? (
              <IconButton sx={{mx: 'auto', display: 'flex'}} {...commonProps}>
                <SmartDisplayIcon />
              </IconButton>
            ) : (
              <Button {...commonProps} variant="text">
                смотреть видео
              </Button>
            )
          ) : (
            longDash
          );
        },
      },
    ];

    return sortBy(columns, 'order');
  }, [isLoading, isXsResolution]);
  return (
    <ContentWrapper p="0">
      <ContentWrapper>
        <Typography mb={2} variant="h3">
          Видео вбрасываний
        </Typography>
        <Box maxWidth={300} mb={3} width="100%">
          <Autocomplete
            label="Точка вбрасывания"
            multiple={false}
            options={options}
            value={throwinPointFilter}
            onChange={(_, value) => setThrowinPointFilter(value)}
          />
        </Box>
      </ContentWrapper>
      {(filteredData?.length && filteredData?.[0]?.linkToVideo) || isLoading ? (
        <Box borderTop={`1px solid ${grey['12']}`}>
          <ReactTableComponent
            columns={columns}
            customScrollBarHeight={400}
            data={isLoading ? mock : filteredData || []}
          />
        </Box>
      ) : (
        <Stack alignItems="center" height={300} justifyContent="center">
          <Typography variant="subtitle1">{throwinPointFilter ? 'Нет данных' : 'Видео еще не загружено'}</Typography>
        </Stack>
      )}
      <ThrowinVideoModal
        itemsCount={filteredData?.length}
        setData={(index) => {
          const value = filteredData?.[index - 1];
          value &&
            dispatch(
              setVideoModal({
                isOpen: true,
                data: {index, ...value},
              }),
            );
        }}
        stateGetter={selectVideoModal}
        stateSetter={setVideoModal}
        onClose={() =>
          dispatch(
            setVideoModal({
              isOpen: false,
              data: {},
            }),
          )
        }
      />
    </ContentWrapper>
  );
}

export default ThrowinsVideoTable;
