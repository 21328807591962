import {DIAGRAM_TYPES} from '@components/apps/StatisticsWithMkcUral/constants';
import {setTechResultScore} from '@components/apps/StatisticsWithMkcUral/helpers';
import {MiniDiagram} from '@components/apps/StatisticsWithMkcUral/UralStatistics';
import {longDash} from '@src/constants';
import {Tooltip, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import PropTypes from 'prop-types';
import React from 'react';

ChessTableCell.propTypes = {
  data: PropTypes.array.isRequired,
  scoreView: PropTypes.bool,
};

function ChessTableCell({data = [], scoreView}) {
  return (
    <Stack direction="row" gap={1} justifyContent="space-around" width="100%">
      {data && data?.length
        ? data?.map((item, index) => {
            let children = <Typography variant="caption2">{longDash}</Typography>;

            if (item?.score?.length && scoreView && item?.type !== DIAGRAM_TYPES.NP) {
              const [first, second] = item.score;
              children = (
                <span>
                  <Typography color={first < second && grey['54']} component="span" display="inline" variant="caption2">
                    {setTechResultScore(first, item?.techResult)}
                  </Typography>
                  <Typography color={grey['54']} component="span" display="inline" variant="caption2">
                    :
                  </Typography>
                  <Typography color={first > second && grey['54']} component="span" display="inline" variant="caption2">
                    {setTechResultScore(second, item?.techResult)}
                  </Typography>
                </span>
              );
            }
            if (item?.type && !scoreView) {
              children = <MiniDiagram size={12} type={item.type} />;
            }
            return (
              <Tooltip
                key={index}
                placement="top"
                title={
                  item?.type === DIAGRAM_TYPES.NP ? (
                    <>
                      Не сыграна. <br /> {`Дата: ${item?.date}`}
                    </>
                  ) : (
                    <>
                      {!scoreView && (
                        <>
                          {`Счет: ${setTechResultScore(item.score[0], item?.techResult)}
                          :${setTechResultScore(item.score[1], item?.techResult)}`}
                          <br />
                        </>
                      )}
                      Дата: {item?.date}
                      <br />
                      {[item?.homeName, item?.awayName].join(' - ')}
                    </>
                  )
                }
              >
                {children}
              </Tooltip>
            );
          })
        : longDash}
    </Stack>
  );
}
export default React.memo(ChessTableCell);
