import LinesAPI from '../../api/LinesAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

export const defaultLines = {
  isFavorites: false,
  favoritesName: null,
  playersLines: [
    {
      name: 'playersLine_1',
      wingersLine: [],
      defensemansLine: [],
    },
    {
      name: 'playersLine_2',
      wingersLine: [],
      defensemansLine: [],
    },
    {
      name: 'playersLine_3',
      wingersLine: [],
      defensemansLine: [],
    },
    {
      name: 'playersLine_4',
      wingersLine: [],
      defensemansLine: [],
    },
  ],
  goaltendersLine: [],
};

const errorMessage = (err) => {
  let errorText = 'Ошибка при загрузке';
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
      }
    }
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

export function handleChangeForm(body) {
  return (dispatch) => {
    dispatch({
      type: types.LINES_CHANGE_FORM,
      linesForm: body,
    });
  };
}
export function handleFavoriteLinesChangeList(body) {
  return (dispatch) => {
    dispatch({
      type: types.LINES_LOADING_FAVORITES_LIST,
      linesFavoriteList: body,
    });
  };
}

export function saveLinesForm(form, customSuccessText) {
  return (dispatch) => {
    dispatch({
      type: types.LINES_LOADING_START,
    });
    return LinesAPI.postLinesForm(form)
      .then((data) => {
        ReactComponentNotification(
          NOTIFICATION_TYPES['success'],
          customSuccessText ? customSuccessText : 'Изменения в составе сохранены',
        );
        dispatch({
          type: types.LINES_SAVE_FORM,
          linesForm: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.LINES_LOADING_ERROR,
        });
      });
  };
}
export function updateLinesForm(form) {
  return (dispatch) => {
    dispatch({
      type: types.LINES_LOADING_START,
    });
    return LinesAPI.updateLinesForm(form)
      .then(() => {
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Изменения в составе сохранены');
        dispatch({
          type: types.LINES_LOADING_END,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.LINES_LOADING_ERROR,
        });
      });
  };
}
export function getLinesForm(filter) {
  return (dispatch) => {
    dispatch(handleChangeForm(defaultLines));

    dispatch({
      type: types.LINES_LOADING_START,
    });
    return LinesAPI.getLinesForm(filter)
      .then((data) => {
        dispatch({
          type: types.LINES_LOADING_FORM,
          linesForm: data.data ? data.data : {...defaultLines, teamId: filter.team},
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.LINES_LOADING_ERROR,
        });
      });
  };
}
export function deleteFromFavorite(form) {
  const teamId = form.teamId;
  return (dispatch) => {
    dispatch(cleanLinesForm());
    dispatch({
      type: types.LINES_LOADING_START,
    });
    return LinesAPI.deleteLinesFromFavorite(form.objectId)
      .then(() => {
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Состав удален из избранного');
        dispatch({
          type: types.LINES_LOADING_END,
        });
        dispatch(getTeamFavoritesLines(teamId));
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.LINES_LOADING_ERROR,
        });
      });
  };
}

export function cleanLinesForm() {
  return (dispatch) => {
    return dispatch(handleChangeForm(defaultLines));
  };
}
export function cleanFavoritesList() {
  return (dispatch) => {
    return dispatch({
      type: types.LINES_LOADING_FAVORITES_LIST,
      linesFavoriteList: [],
    });
  };
}

export function getPlayerLinesHistory(filter) {
  return (dispatch) => {
    dispatch({
      type: types.LINES_LOADING_START,
    });
    return LinesAPI.getLinesHistoryByPlayer(filter)
      .then((data) => {
        dispatch({
          type: types.LINES_LOADING_PLAYER_HISTORY,
          playerLinesHistory: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.LINES_LOADING_ERROR,
        });
      });
  };
}
export function getTeamFavoritesLines(teamId) {
  return (dispatch) => {
    dispatch({
      type: types.LINES_LOADING_START,
    });
    return LinesAPI.getTeamFavoritesLinesById(teamId)
      .then((data) => {
        dispatch({
          type: types.LINES_LOADING_FAVORITES_LIST,
          linesFavoriteList: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.LINES_LOADING_ERROR,
        });
      });
  };
}
export function getTeamLinesHistory(teamId) {
  return (dispatch) => {
    dispatch({
      type: types.LINES_LOADING_START,
    });
    return LinesAPI.getTeamLinesHistoryById(teamId)
      .then((data) => {
        dispatch({
          type: types.LINES_HISTORY_LOADING,
          linesHistory: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.LINES_LOADING_ERROR,
        });
      });
  };
}
export function setActualLines(objectId) {
  return (dispatch) => {
    dispatch({
      type: types.LINES_LOADING_START,
    });
    return LinesAPI.setActual(objectId)
      .then(() => {
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Актуальный состав изменен');
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.LINES_LOADING_ERROR,
        });
      });
  };
}
export function setActualHistoryLines(objectId) {
  return (dispatch) => {
    dispatch({
      type: types.LINES_LOADING_START,
    });
    dispatch(cleanLinesForm());
    return LinesAPI.setHistoryActual(objectId)
      .then(() => {
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Актуальный состав изменен');
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.LINES_LOADING_ERROR,
        });
      });
  };
}
