import useDeepEffect from '@hooks/useDeepEffect';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectGroups} from '@selectors/groups';
import {selectSchools} from '@selectors/schools';
import {clearGroups, loadGroups} from '@slices/groups';
import {clearSchools, loadSchools} from '@slices/schools';
import {userRoles} from '@src/constants';
import {State} from '@src/redux/types';
import {Params, UserRoles} from '@src/types';
import {SchoolDto, TeamDto} from '@src/types/generated';
import {GroupsParams} from '@src/types/groupsAPIV2';
import {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

const getValue = (options: SchoolDto[], role: UserRoles, type: 'group' | 'student' | 'school') => {
  if (!options.length) return null;
  const isSingle = options.length === 1;
  const option = {value: null, isDisabled: isSingle};

  if (role === userRoles.admin) {
    return option;
  }

  if ((type === 'group' && role === userRoles.teacher) || role === userRoles.student) {
    option.value = options[0];
  }

  if (type === 'student' && role === userRoles.student) {
    option.value = options[0];
  }

  if ((role !== userRoles.student || role !== userRoles.teacher) && type === 'school') {
    option.value = options[0];
  }

  return option;
};

type ExtendedSchoolDto = SchoolDto & {label: string; value: string};
/**
 * Используется для получения списка школ.
 * @param {Object} [options={}] - Опции функции.
 * @param {string} [options.allSchoolsPermissionRoles] - Массив ролей для условия добавления всех школ в options.
 * @param {Params & TParams} [options.params] - Параметры запроса.
 * @template TParams - Тип дополнительных параметров.
 */
export const useSchoolsV2 = <TParams>({
  allSchoolsPermissionRoles = [],
  params,
}: {allSchoolsPermissionRoles?: UserRoles[]; params?: Params & TParams} = {}): {
  options: ExtendedSchoolDto[];
  isLoading: boolean;
  isDisabled: boolean;
  value: ExtendedSchoolDto | null;
} => {
  const dispatch = useStoreDispatch();
  const {options, isLoading, userRole} = useSelector((state: State) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {id, userRole, schoolsIds, schoolId} = state.users.currentUser;
    const {data, isLoading} = selectSchools(state);

    let schools = data.filter(
      (school) =>
        allSchoolsPermissionRoles?.includes(userRole) || school.id === schoolId || schoolsIds.includes(school.id),
    );

    if ([userRoles.admin, userRoles.methodist].includes(userRole)) schools = data;
    // if (userRole === userRoles.teacher) schools = data.filter((school) => school.teachersIds.includes(id));

    return {
      options: schools
        ?.map((school) => ({label: school?.name, value: school?.id, ...school}))
        ?.sort((a, b) => a?.name?.localeCompare(b?.name)),
      isLoading,
      userRole,
    };
  }, shallowEqual);

  const defaultValue = useMemo(() => getValue(options, userRole, 'school'), [options, userRole]);

  useDeepEffect(() => {
    dispatch(loadSchools<TParams>(params));
    return () => {
      dispatch(clearSchools());
    };
  }, [params]);

  return {options, ...defaultValue, isLoading};
};

type ExtendedTeamDto = TeamDto & {label: string; value: string};
/**
 * Используется для получения списка групп.
 * @param {Object} [options={}] - Опции функции.
 * @param {string} [options.schoolId] - ID школы для фильтрации групп по выбранной школе.
 * @param {Params & TParams} [options.params] - Параметры запроса.
 * @template TParams - Тип дополнительных параметров.
 */

export const useGroupsV2 = <TParams>({
  schoolIds,
  params,
}: {schoolIds?: string[]; params?: GroupsParams & TParams} = {}): {
  options: ExtendedTeamDto[];
  isLoading: boolean;
  isDisabled: boolean;
  value: ExtendedTeamDto | null;
} => {
  const dispatch = useStoreDispatch();
  const {options, isLoading, userRole} = useSelector((state: State) => {
    const {userRole} = state.users.currentUser;
    const {data, isLoading} = selectGroups(state);

    return {
      options: data
        ?.map((group) => ({label: group?.name, value: group?.id, ...group}))
        ?.sort((a, b) => a?.name?.localeCompare(b?.name)),
      isLoading,
      userRole,
    };
  }, shallowEqual);

  useDeepEffect(() => {
    dispatch(loadGroups<TParams>(params, schoolIds));
    return () => {
      dispatch(clearGroups());
    };
  }, [params, schoolIds]);

  const defaultValue = useMemo(() => getValue(options, userRole, 'group'), [options, userRole]);

  return {options, ...defaultValue, isLoading};
};
