import React from 'react';

function RightArrowDark() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M6.47332 5.07078L1.92844 0.17573C1.82333 0.0624222 1.683 -9.15119e-08 1.53338 -7.84315e-08C1.38376 -6.53511e-08 1.24344 0.0624222 1.13832 0.17573L0.803621 0.536134C0.585831 0.770977 0.585831 1.15267 0.803621 1.38715L4.62007 5.49772L0.799388 9.61285C0.69427 9.72616 0.636231 9.8772 0.636231 10.0383C0.636231 10.1995 0.69427 10.3506 0.799388 10.464L1.13409 10.8243C1.23929 10.9376 1.37953 11 1.52915 11C1.67877 11 1.81909 10.9376 1.92421 10.8243L6.47332 5.92475C6.57869 5.81108 6.63656 5.65932 6.63623 5.49799C6.63656 5.33603 6.57869 5.18436 6.47332 5.07078Z"
          fill="#686868"
        />
      </g>
    </React.Fragment>
  );
}
export default RightArrowDark;
