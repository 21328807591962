import React from 'react';

function ForkAndKnife() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M5.21506 0H4.34908C3.75155 0.00070361 3.26731 0.484949 3.2666 1.08248V3.19352C3.26731 3.68946 3.38275 4.17849 3.6039 4.62239C3.80754 5.03043 3.90735 5.48242 3.89444 5.93825L3.72124 11.902C3.70076 12.4876 4.15878 12.9788 4.74431 12.9993C5.32982 13.0198 5.82111 12.5618 5.84159 11.9762C5.84246 11.9515 5.84246 11.9268 5.84159 11.902L5.6684 5.93887C5.65554 5.48323 5.75535 5.03149 5.95893 4.62366C6.1807 4.17955 6.29661 3.69011 6.29753 3.19371V1.08248C6.29683 0.484949 5.81261 0.000730672 5.21506 0ZM5.86454 3.19374C5.864 3.62251 5.76419 4.04532 5.57292 4.42906C5.33735 4.90083 5.22188 5.42348 5.23671 5.95059L5.4099 11.9129C5.41488 12.0823 5.35061 12.2465 5.23194 12.3675C4.98216 12.612 4.58284 12.612 4.33306 12.3675C4.21423 12.2469 4.14966 12.083 4.15423 11.9137L4.32743 5.95081C4.34236 5.42364 4.22695 4.90094 3.99143 4.42906C3.80007 4.04527 3.70019 3.62237 3.69959 3.19352V1.08248C3.70073 0.808231 3.87401 0.564241 4.13258 0.472826V3.03093H4.56557V0.432991H4.99856V3.03093H5.43155V0.472826C5.69013 0.564241 5.8634 0.808231 5.86454 1.08248V3.19374Z"
          fill="#686868"
        />
        <path
          d="M9.54563 6.49057V0.216535C9.54563 0.0969754 9.44869 3.96564e-05 9.32913 3.96564e-05C8.58607 -0.00231473 7.91732 0.450431 7.64351 1.14119C7.04601 2.63519 7.01521 4.29606 7.55691 5.81121L7.81324 6.52867L7.62792 11.9004C7.60749 12.4817 8.06221 12.9695 8.64352 12.9899C9.22487 13.0104 9.71268 12.5557 9.73311 11.9743C9.73398 11.9497 9.73398 11.925 9.73311 11.9004L9.54563 6.49057ZM8.04554 1.30204C8.22512 0.848945 8.63107 0.524716 9.11264 0.4497V6.2784H8.18171L7.96284 5.66572C7.45713 4.24979 7.48652 2.69781 8.04554 1.30204ZM9.12563 12.3675C8.87837 12.6115 8.48094 12.6115 8.23367 12.3675C8.11655 12.2467 8.05398 12.0832 8.06048 11.9151L8.2393 6.71139H9.12L9.29948 11.9151C9.30581 12.0833 9.243 12.2468 9.12563 12.3675Z"
          fill="#686868"
        />
      </g>
    </React.Fragment>
  );
}
export default ForkAndKnife;
