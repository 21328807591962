import Metric from '@components/apps/PlayerPersonalFile/components/Statistics/components/Metric';
import {metricsMock} from '@components/apps/PlayerPersonalFile/components/Statistics/components/Metrics/mock';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {STATISTIC_METRICS} from '@components/apps/PlayerPersonalFile/constants';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectPlayerTournamentStat} from '@selectors/gameStatisticsMkc';
import {clearPlayerTournamentStat, loadPlayerTournamentStat} from '@slices/statisticsMKC';
import {Stack, Typography} from '@ui/MUI';
import {isEmpty, merge, values} from 'lodash';
import React from 'react';
import {useParams} from 'react-router-dom';

function Metrics({requestFilters, isYoungerAgeGroup}) {
  const playerId = useParams()?.id;
  const dispatch = useStoreDispatch();
  const {data, isLoading} = useStore(selectPlayerTournamentStat);
  const metrics = useDeepMemo(() => {
    const result = values(merge(STATISTIC_METRICS, isLoading ? metricsMock : data));
    if (isYoungerAgeGroup) return result?.filter((item) => item?.isYoungerAgeGroup);
    return result;
  }, [data, isLoading]);
  useDeepEffect(() => {
    const {isFilled, ...params} = requestFilters;
    if (isFilled && playerId) dispatch(loadPlayerTournamentStat({playerId, ...params}));
    return () => {
      dispatch(clearPlayerTournamentStat());
    };
  }, [requestFilters, playerId]);

  return (isEmpty(data) && !isLoading) || !requestFilters?.teamMkcId ? (
    <Wrapper>
      <Stack alignItems="center" height={250} justifyContent="center">
        <Typography variant="subtitle1">
          {!requestFilters?.teamMkcId ? 'Заполните фильтры, чтобы увидеть статистику по команде' : 'Данные отсутствуют'}
        </Typography>
      </Stack>
    </Wrapper>
  ) : (
    <Stack direction="row" flexWrap="wrap" gap={3}>
      {metrics?.map((item, index) => (
        <Metric isLoading={isLoading} key={index} {...item} />
      ))}
    </Stack>
  );
}

export default Metrics;
