import {getValues} from '@components/apps/MaterialsModule/helpers';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {fetcherTrainingControl} from '@slices/trainingControlProcess';
import {Box, Table, Typography} from '@ui/MUI';
import React, {useEffect, useMemo, useState} from 'react';

import {useEstimatedTraining} from '../../hooks/api';
import {NoData} from '../Control/Control';
import LoadingHolder from '../LoadingHolder';
import Filters from './components/Filters';
import {columns} from './constants';

const Scored = () => {
  const {data, isLoading} = useEstimatedTraining();
  const [filters, setFilters] = useState({schools: [], groups: [], period: [null, null]});
  const dispatch = useStoreDispatch();

  const component = useMemo(() => {
    if (isLoading) return <LoadingHolder />;
    if (data.length) return <Table stickyHeaders columns={columns} rows={data} />;
    return (
      <NoData>
        <Typography>Данные не найдены</Typography>
      </NoData>
    );
  }, [data, isLoading]);

  useEffect(() => {
    const [startDate, endDate] = filters.period;
    if (startDate && !endDate) return;
    const payload = {
      schools: getValues(filters.schools),
      groups: getValues(filters.groups),
      startDate: startDate?.startOf('day')?.valueOf(),
      endDate: endDate?.endOf('day')?.valueOf(),
    };

    dispatch(fetcherTrainingControl('training_estimated', payload));
  }, [filters]);

  return (
    <>
      <Filters filters={filters} updateFilters={setFilters} />
      <Box bgcolor="#fff" mt={1}>
        {component}
      </Box>
    </>
  );
};

export default Scored;
