import {Cancel} from '@mui/icons-material';
import {useMediaQuery} from '@mui/material';
import {FACELESS_AVATAR} from '@src/constants/config';
import {convertImgTo200} from '@src/helpers';
import {Button} from '@ui/Button';
import Icon from '@ui/Icon/Icon';
import React from 'react';
import styled from 'styled-components';

export default function TeacherHockeyLinesPlayerCard(props) {
  let {player = null, position = '', readOnly = false, onDelete = () => {}, onClick = () => {}, className} = props;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Card className={className ? `Card ${className}` : ''} isMobile={isMobile} onClick={onClick}>
      <CardImgContainer
        className={
          player.status === 'available'
            ? `CardStatus_available`
            : `CardStatus_notavailable CardStatus CardImgContainer hide-when-print`
        }
        isMobile={isMobile}
      >
        <CardImgWrapper className="CardImgWrapper" isMobile={isMobile}>
          <CardImg
            className={'CardImg'}
            src={player.avatar ? convertImgTo200(player.avatar) : FACELESS_AVATAR}
            style={{backgroundImage: `url(${convertImgTo200(player.avatar)})`}}
          />
          {!readOnly ? (
            <Button
              className="CardRemovePlayer"
              data-player-lastname={player.lastName}
              data-player-position={position}
              onClick={() => {
                onDelete(player.id);
              }}
            >
              {!isMobile ? <Icon height={8} type="Close" viewBox={'0 0 8 8'} width={8} /> : <Cancel fontSize="small" />}
            </Button>
          ) : null}
          {isMobile && <CardPosition>{position}</CardPosition>}
        </CardImgWrapper>
      </CardImgContainer>
      <CardPlayerName className={'CardPlayerName'} isMobile={isMobile}>
        <CardPlayerNumber className={'CardPlayerNumber'} isMobile={isMobile}>
          {player.number || player.playerNumber}{' '}
        </CardPlayerNumber>
        <br className={'add-when-print'} />
        {!isMobile && (
          <>
            <span>{player.lastName}</span> <span>{player.firstName}</span>
          </>
        )}
      </CardPlayerName>
    </Card>
  );
}

const CardPlayerName = styled.p`
  margin-top: ${(props) => (props.isMobile ? 0 : '8px')};
  margin-bottom: 0;
  position: ${(props) => (props.isMobile ? 'absolute' : 'initial')};
  top: ${(props) => (props.isMobile ? 0 : 'initial')};
  left: ${(props) => (props.isMobile ? 0 : 'initial')};
  font: bold normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
  text-align: center;
  transition: all 0.33s;
`;
const CardPlayerNumber = styled.span`
  padding: ${(props) => (props.isMobile ? '2px 4px' : 'inherit')};
  background-color: ${(props) => (props.isMobile ? '#35353590' : 'transparent')};
  border-radius: ${(props) => (props.isMobile ? '4px' : 0)};
  font-weight: ${(props) => (props.isMobile ? '400' : 'inherit')};
  font-size: ${(props) => (props.isMobile ? '12px' : 'inherit')};
  line-height: ${(props) => (props.isMobile ? '16px' : 'inherit')};
  color: ${(props) => (props.isMobile ? 'white' : '#887755')};
`;
const Card = styled.div`
  position: ${(props) => (props.isMobile ? 'relative' : 'initial')};
  padding-left: ${(props) => (props.isMobile ? 0 : '20px')};
  padding-right: ${(props) => (props.isMobile ? '28px' : '20px')};
  min-width: 64px;
  box-sizing: border-box;
  cursor: pointer;
  @media (min-width: 768px) and (max-width: 1300px) {
    padding-right: 8px;
    padding-left: 8px;
  }
`;
const CardImg = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  overflow: hidden;
  z-index: 0;
`;
const CardImgWrapper = styled.div`
  position: relative;
  min-height: 64px;
  width: 100%;
  max-width: 84px;
  .CardRemovePlayer {
    position: absolute;
    top: 0;
    right: ${(props) => (props.isMobile ? 0 : '8px')};
    padding-left: 0;
    padding-right: 0;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      path {
        fill: ${(props) => (props.isMobile ? '#949494' : '#686868')};
      }
    }
  }
`;
const CardImgContainer = styled.div`
  position: relative;
  min-height: 60px;
  min-width: 64px;
  display: ${(props) => (props.isMobile ? 'block' : 'flex')};
  justify-content: ${(props) => (props.isMobile ? 'initial' : 'center')};
  ${CardImgWrapper} {
    &:before {
      position: absolute;
      top: 0;
      left: 8px;
      display: ${(props) => (props.isMobile ? 'none' : 'block')};
      width: 12px;
      height: 12px;
      border: 2px solid white;
      border-radius: 50%;
      z-index: 1;
      content: '';
    }
  }
  &.CardStatus {
    &_available {
      ${CardImgWrapper} {
        :before {
          background-color: #6dc26d;
        }
      }
    }
    &_notavailable {
      ${CardImgWrapper} {
        :before {
          background-color: #990011;
        }
      }
    }
    &_traumatized {
      ${CardImgWrapper} {
        :before {
          background-color: #c69534;
        }
      }
    }
  }
`;
const CardPosition = styled.p`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 8px;
  width: 100%;
  max-width: 32px;
  background-color: #b63830;
  border-radius: 10px;
  font: normal normal 12px/16px 'Proxima Nova';
  color: #fffaed;
  text-align: center;
  text-transform: uppercase;
`;
