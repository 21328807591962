import React from 'react';

function Folder() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M16.8742 7.16014C16.7516 6.90315 16.564 6.70617 16.3129 6.56949C16.0614 6.43254 15.7848 6.36414 15.4831 6.36414H13.8738V5.02305C13.8738 4.50899 13.6894 4.06756 13.3207 3.69876C12.9518 3.32998 12.5103 3.14563 11.9964 3.14563H7.43691V2.87743C7.43691 2.36337 7.25246 1.92194 6.88377 1.55325C6.51494 1.18448 6.07354 1 5.55948 1H2.87743C2.36337 1 1.92194 1.18445 1.55313 1.55325C1.18436 1.92197 1 2.3634 1 2.87746V10.9237C1 11.4376 1.18436 11.8791 1.55313 12.2478C1.92194 12.6166 2.36337 12.8011 2.87743 12.8011H11.9966C12.3708 12.8011 12.7619 12.7047 13.17 12.5118C13.5779 12.3191 13.9018 12.0746 14.142 11.7784L16.6146 8.73601C16.8716 8.41201 17 8.07688 17 7.73027C17.0001 7.52906 16.9582 7.33927 16.8742 7.16014ZM2.07289 2.87757C2.07289 2.65414 2.15106 2.46412 2.30753 2.30765C2.46388 2.15127 2.65391 2.073 2.87746 2.073H5.5596C5.78315 2.073 5.97305 2.15118 6.12952 2.30765C6.2859 2.46412 6.36417 2.65414 6.36417 2.87757V3.41403C6.36417 3.63761 6.44246 3.82757 6.59884 3.98395C6.75522 4.14034 6.94519 4.21863 7.16871 4.21863H11.9964C12.2198 4.21863 12.41 4.29689 12.5665 4.45327C12.7228 4.60963 12.801 4.79965 12.801 5.0232V6.36426H6.36414C5.98424 6.36426 5.59298 6.46066 5.19068 6.65345C4.78832 6.84623 4.46428 7.09065 4.21839 7.38694L2.07289 10.027V2.87757ZM15.7764 8.05729L13.3122 11.0997C13.1727 11.2729 12.9743 11.4209 12.7171 11.5437C12.4601 11.6667 12.2198 11.7282 11.9964 11.7282H2.87743C2.58128 11.7282 2.43324 11.6303 2.43324 11.4348C2.43324 11.3454 2.48352 11.2337 2.58413 11.0994L5.04824 8.05709C5.19352 7.8838 5.39318 7.73722 5.64749 7.61707C5.90166 7.49691 6.14056 7.43682 6.36411 7.43682H15.4831C15.7792 7.43682 15.9272 7.53466 15.9272 7.73018C15.9273 7.82544 15.8772 7.93432 15.7764 8.05729Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default Folder;
