import Puck from '@common/Icons/PuckIcon';
import CommonHelper from '@helpers/CommonHelper';
import {Stack, Typography} from '@ui/MUI';
import {grey, primary} from '@ui/MUI/colors';
import {bool, number, string} from 'prop-types';
import React, {useMemo} from 'react';
import styled from 'styled-components';

Goal.propTypes = {
  firstAssistPlayerFirstName: string,
  firstAssistPlayerLastName: string,
  firstAssistPlayerNumber: number,
  isFinalGoal: bool,
  majority: bool,
  minority: bool,
  playerFirstName: string,
  playerLastName: string,
  playerNumber: number,
  secondAssistPlayerNumber: number,
  time: string,
};
const setShortName = (firstName, lastName) => CommonHelper.getShortNameByUser({firstName, lastName});
function Goal({
  firstAssistPlayerFirstName,
  firstAssistPlayerLastName,
  secondAssistPlayerFirstName,
  secondAssistPlayerLastName,
  isFinalGoal,
  majority,
  minority,
  playerFirstName,
  playerLastName,
  time,
}) {
  const assists = useMemo(() => {
    const result = [];
    if (firstAssistPlayerFirstName || firstAssistPlayerLastName)
      result.push(setShortName(firstAssistPlayerFirstName, firstAssistPlayerLastName));
    if (secondAssistPlayerFirstName || secondAssistPlayerLastName)
      result.push(setShortName(secondAssistPlayerFirstName, secondAssistPlayerLastName));

    return result.join(' + ');
  }, [firstAssistPlayerFirstName, firstAssistPlayerLastName, secondAssistPlayerFirstName, secondAssistPlayerLastName]);
  return time === '...' ? (
    <Typography variant="subtitle2">{time}</Typography>
  ) : (
    <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Puck fontSize="16px" sx={{color: isFinalGoal ? primary.main : grey['54']}} />
        <Typography color={grey['68']} variant="subtitle2">
          {time}
        </Typography>
        <Typography variant="subtitle2">{setShortName(playerFirstName, playerLastName)}</Typography>
      </Stack>
      {assists && <Typography variant="caption2">{assists}</Typography>}
      {(majority || minority) && (
        <LineupLabel>
          <Typography color="#fff" variant="overline2">
            {majority && 'БЛШ'}
            {minority && 'МНШ'}
          </Typography>
        </LineupLabel>
      )}
    </Stack>
  );
}

export default Goal;
const LineupLabel = styled(Stack)`
  border-radius: 4px;
  padding: ${({theme}) => theme.spacing('0', 0, '0', 0)};
  background-color: ${({theme}) => theme.palette.primary.main};
`;
