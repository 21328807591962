import {useAge, useTheme} from '@components/apps/TrainingControlProcess/hooks/api';
import {PageContentWrapper} from '@components/apps/TrainingControlProcess/TrainingControlProcess';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {createTrainingControl, deleteTrainingControl, updateTrainingControl} from '@slices/trainingControlProcess';
import Stack from '@ui/MUI/Stack';
import React from 'react';

import LoadingHolder from '../../../LoadingHolder';
import Section from './components/Section';

const ExercisesCategoryTab = () => {
  const age = useAge();
  const theme = useTheme();
  const dispatch = useStoreDispatch();
  const isAgeLoading = age.isLoading || age.form.isLoading;
  const isThemeLoading = theme.isLoading || theme.form.isLoading;

  return (
    <PageContentWrapper>
      {isAgeLoading || isThemeLoading ? (
        <LoadingHolder />
      ) : (
        <Stack direction="row">
          <Section
            initialItems={theme.data}
            side="left"
            title="Тематика"
            onAccept={(isNew, value, id) => {
              if (isNew) {
                dispatch(
                  createTrainingControl({
                    name: 'theme',
                    payload: {name: value, id},
                  }),
                );
              } else {
                dispatch(
                  updateTrainingControl({
                    name: 'theme',
                    payload: {name: value},
                    id,
                  }),
                );
              }
            }}
            onDelete={(id) => {
              dispatch(deleteTrainingControl({name: 'theme', payload: id}));
            }}
          />
          <Section
            initialItems={age.data}
            side="right"
            title="Возраст"
            onAccept={(isNew, value, id) => {
              if (isNew) {
                dispatch(
                  createTrainingControl({
                    name: 'age',
                    payload: {name: value},
                  }),
                );
              } else {
                dispatch(
                  updateTrainingControl({
                    name: 'age',
                    payload: {name: value},
                    id,
                  }),
                );
              }
            }}
            onDelete={(id) => {
              dispatch(deleteTrainingControl({name: 'age', payload: id}));
            }}
          />
        </Stack>
      )}
    </PageContentWrapper>
  );
};

export default ExercisesCategoryTab;
