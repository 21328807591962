import {grey} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import React from 'react';

const Item = ({label, description}) => {
  return (
    <Stack spacing={0}>
      <Typography color={grey[54]} variant="caption1">
        {label}
      </Typography>
      <Typography>{description}</Typography>
    </Stack>
  );
};

export default Item;
