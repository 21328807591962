import ChildrenField from '@common/ChildrenField';
import {getOptionLabel, isOptionEqualToValue} from '@components/apps/TrainingControlProcess/constant';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {updateTab} from '@slices/trainingControlProcess';
import {Autocomplete, Button, Stack} from '@ui/MUI';
// import TimeRange from '@common/TimeRange';
import Box from '@ui/MUI/Box';
import TextFieldDefault from '@ui/MUI/TextField';
import TypographyDefault from '@ui/MUI/Typography';
import React, {useEffect, useMemo} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import Dates from './components/Dates';

// const minDate = moment();
// const maxDate = moment().add(1, 'month');

const MainInfoBlock = () => {
  const schoolWatcher = useWatch({name: 'school'});
  const isEdit = useWatch({name: 'id'});
  const {school, group} = useStore((state) => {
    const {schools, groups} = state.users.currentUser;
    const formatedSchools = schools.map((school) => ({...school, id: school.objectId}));
    const formatedGroups = groups.map((group) => ({...group, id: group.objectId}));

    return {
      school: {value: formatedSchools[0], options: formatedSchools},
      group: {value: formatedGroups[0], options: formatedGroups},
    };
  });

  const {setValue} = useFormContext();

  const dispatch = useStoreDispatch();

  const schoolGroups = useMemo(() => {
    return group.options.filter((group) => group.schoolId === schoolWatcher?.id);
  }, [schoolWatcher, group]);

  useEffect(() => {
    if (!isEdit) {
      setValue('school', school.value);
      setValue('group', group.value);
    }
  }, []);

  return (
    <Box>
      {!isEdit && (
        <Box
          mb={5}
          onClick={() => {
            dispatch(updateTab('training_templates'));
          }}
        >
          <Button color="primary">выбрать готовую из шаблонов</Button>
        </Box>
      )}
      <TypographyDefault mb={3} variant="h4">
        Информация о тренировке
      </TypographyDefault>
      <Stack direction="row" flexWrap="wrap" gap={4}>
        <Box minWidth="190px">
          <ChildrenField isRequired name="school" onChange={() => setValue('group', null)}>
            <Autocomplete
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isOptionEqualToValue}
              label="Школа"
              multiple={false}
              options={school.options}
            />
          </ChildrenField>
        </Box>
        <Box minWidth="204px">
          <ChildrenField isRequired name="group">
            <Autocomplete
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isOptionEqualToValue}
              label="Команда"
              multiple={false}
              options={schoolGroups}
            />
          </ChildrenField>
        </Box>
        <Box flex="1" minWidth="200px">
          <ChildrenField isRequired name="name">
            <TextFieldDefault label="Название тренировки" />
          </ChildrenField>
        </Box>
      </Stack>
      <Box mt={5}>
        <Dates />
      </Box>
    </Box>
  );
};

export default MainInfoBlock;
