import Box from '@ui/MUI/Box';
import styled from 'styled-components';

export const ChartWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 991px) {
    flex: 0 0 50%;
    max-width: 50%;

    &:not(:last-child) {
      padding-bottom: 8px;
    }
  }
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  h4 {
    text-align: center;
  }
  .DonutChart {
    &__values {
      display: flex;
      justify-content: space-between;
      position: relative;
      .MuiTypography {
        &-subtitle2 {
          position: absolute;
          bottom: 0;
          &:first-child {
            left: 0;
            transform: translateX(-50%);
          }
          &:last-child {
            right: 0;
            transform: translateX(50%);
          }
        }
      }
    }
  }
`;
export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
