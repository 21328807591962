import {Skeleton as MuiSkeleton} from '@mui/material';
import {SkeletonProps} from '@mui/material/Skeleton/Skeleton';
import React from 'react';

function Skeleton({
  animation = 'wave',
  width,
  children,
  isLoading = true,
  ...props
}: {
  isLoading?: boolean;
} & SkeletonProps) {
  return isLoading ? (
    <MuiSkeleton animation={animation} width={width} {...props}>
      {children}
    </MuiSkeleton>
  ) : (
    <>{children}</>
  );
}

export default Skeleton;
