import {isEmpty} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import {getRoleLabel} from '../../../../helpers/roles';
import LocalPreloader from '../../../preloader/LocalPreloader';
import DrawerDefault from '../../../ui/MUI/Modals/Drawer/Drawer';
import DeleteModal from '../../DeleteModal';
import {Field, FixedInput, ModalTitle, StyledButton} from '../CommonScoutReport';
import {PERMISSIONS} from '../constants';
import GroupReportCreateContent from '../GroupReportCreateContent';
import {ButtonsWrapper} from './GroupReportViewModal';

function GroupReportViewModal({isOpen, setIsOpen = () => {}, report, onClose, onEdit, onDelete}) {
  const methods = useForm({
    mode: 'onTouched',
  });
  const {handleSubmit, reset} = methods;

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    if ((!isEmpty(report.error) && isOpen.right) || (report.isLoad && isEmpty(report.data))) setIsOpen(false);
  }, [report.error, isOpen.right]);

  useEffect(() => {
    if (!isEmpty(report.data)) {
      reset({
        ...report.data,
      });
    }
    return () => {
      reset();
      setIsEdit(false);
    };
  }, [report.isLoad]);

  const [isEdit, setIsEdit] = useState(false);

  return (
    <>
      <DrawerDefault
        buttons={
          <ButtonsWrapper>
            {report.isLoad && (
              <>
                {isEdit ? (
                  <>
                    {report.permissions?.[PERMISSIONS.canEdit] && (
                      <StyledButton
                        color="primary"
                        size="medium"
                        variant="contained"
                        onClick={() =>
                          handleSubmit((data) => {
                            onEdit(report.data.id, data);
                            onClose();
                          })()
                        }
                      >
                        сохранить
                      </StyledButton>
                    )}
                    {report.permissions?.[PERMISSIONS.canRemove] && (
                      <StyledButton
                        color="primary"
                        margin={report.permissions?.[PERMISSIONS.canEdit] ? '0 0 0 auto' : ''}
                        size="medium"
                        variant="outlined"
                        onClick={() => setIsDeleteModalVisible(true)}
                      >
                        удалить
                      </StyledButton>
                    )}
                  </>
                ) : (
                  (report.permissions?.[PERMISSIONS.canEdit] || report.permissions?.[PERMISSIONS.canRemove]) && (
                    <StyledButton color="primary" size="medium" variant="contained" onClick={() => setIsEdit(true)}>
                      редактировать
                    </StyledButton>
                  )
                )}
                <StyledButton color="neutral" size="medium" variant="contained" onClick={onClose}>
                  Закрыть
                </StyledButton>
              </>
            )}
          </ButtonsWrapper>
        }
        content={
          <>
            <LocalPreloader visible={report.loading} />
            {isEmpty(report.data) ? (
              <></>
            ) : !isEdit ? (
              <>
                <ModalTitle>{report.group?.name || 'Отчет'}</ModalTitle>
                <Field align="flex-start" margin="0 0 24px 0">
                  <FixedInput>
                    <span className="label">Дата создания</span>
                    <span className="text">
                      <b>{report.data?.createdAt ? moment(report.data?.createdAt).format('DD.MM.YYYY') : ''}</b>
                    </span>
                  </FixedInput>
                </Field>
                <Field align="flex-start" margin="0 0 24px 0">
                  <FixedInput>
                    <span className="label">Отчет</span>
                    <span className="text">{report.data?.description}</span>
                  </FixedInput>
                </Field>
                <Field align="flex-start" margin="0 0 24px 0">
                  <FixedInput>
                    <span className="label">Автор отчета</span>
                    <span className="text">{`
                  ${report.data?.author?.firstName}
                  ${report.data?.author?.lastName}, 
                  ${getRoleLabel(
                    report.data?.author?.userRole,
                    report.data?.author?.teacherRole,
                  )?.toLowerCase()}`}</span>
                  </FixedInput>
                </Field>
              </>
            ) : (
              <FormProvider {...methods}>
                <GroupReportCreateContent group={report?.group} isEdit={true} />
              </FormProvider>
            )}
          </>
        }
        drawerState={isOpen}
        hasFullWidth={'false'}
        setDrawerState={setIsOpen}
        onClose={onClose}
      />
      <DeleteModal
        isVisible={isDeleteModalVisible}
        text="Вы уверены, что хотите удалить отчет?"
        title="Удаление отчета"
        onCancel={() => setIsDeleteModalVisible(false)}
        onDelete={() => {
          setIsDeleteModalVisible(false);
          onDelete(report.data?.id);
          onClose();
        }}
      />
    </>
  );
}

GroupReportViewModal.propTypes = {
  isOpen: PropTypes.shape({
    top: PropTypes.bool,
    left: PropTypes.bool,
    bottom: PropTypes.bool,
    right: PropTypes.bool,
  }),
  setIsOpen: PropTypes.func,
  report: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
};

export default GroupReportViewModal;
