import {getThrowinsMapPlayers} from '@api/StatsAPI';
import useDeepEffect from '@hooks/useDeepEffect';
import {Divider} from '@mui/material';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import Chip from '@ui/MUI/Chip';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React, {memo, useState} from 'react';

PlayersFilter.propTypes = {
  statisticsIds: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.shape({label: PropTypes.string.isRequired, value: PropTypes.string.isRequired}),
  onChange: PropTypes.func.isRequired,
};

function PlayersFilter({statisticsIds, value, onChange = () => {}}) {
  const [options, setOptions] = useState([]);
  useDeepEffect(() => {
    statisticsIds?.length &&
      getThrowinsMapPlayers({statisticsIds}).then(({data}) =>
        setOptions(
          data?.players?.map((item) => ({
            value: item.id,
            label: item.lastName + (item.firstName ? ` ${item.firstName?.substring(0, 1)}.` : ''),
            percent: (item?.percent || 0) + '%',
            ect: `-${item?.win || 0}-${item?.lost || 0}`,
          })),
        ),
      );
  }, [statisticsIds]);
  return (
    <AutocompleteDefault
      label="Игроки"
      limitTags={1}
      options={options}
      placeholder={''}
      renderOption={(props, option) => (
        <Stack
          component="li"
          direction="row"
          divider={<Divider flexItem orientation="vertical" />}
          spacing={1}
          {...props}
        >
          <Stack direction="row">
            <TypographyDefault variant="body1">{option.percent}</TypographyDefault>
            <TypographyDefault color="#666" variant="body1">
              {option.ect}
            </TypographyDefault>
          </Stack>
          <TypographyDefault variant="body1">{option.label}</TypographyDefault>
        </Stack>
      )}
      renderTags={(items, getTagProps) =>
        items.map((item, index) => (
          <Chip
            key={index}
            label={
              <Stack direction="row" divider={<Divider flexItem orientation="vertical" />} spacing={1}>
                <Stack alignItems="center" direction="row">
                  <TypographyDefault variant="body2">{item.percent}</TypographyDefault>
                  <TypographyDefault color="#666" variant="body2">
                    {item.ect}
                  </TypographyDefault>
                </Stack>
                <TypographyDefault variant="body2">{item.label}</TypographyDefault>
              </Stack>
            }
            size="small"
            variant="outlined"
            {...getTagProps({index})}
          />
        ))
      }
      value={value}
      onChange={(_, value) => onChange(value)}
    />
  );
}

export default memo(PlayersFilter);
