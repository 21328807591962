export const mock = {
  exercises: [
    {
      valueForComparison: 0,
      exercise: 'YPVZqpzfcI',
      value: 13.7,
      delta: 0,
      name: 'Прыжок в длину с места (см)',
      order: 3,
    },
    {
      valueForComparison: 0,
      exercise: 'Xx9OYrarH5',
      value: 44.73,
      delta: 0,
      name: 'Прыжок в высоту (см)',
      order: 4,
    },
    {
      valueForComparison: 0,
      exercise: '-JuGyneCYBL1',
      value: 13.7,
      delta: 0,
      name: 'Пятикратный прыжок толчками двумя ногами (м)',
      order: 5,
    },
    {
      valueForComparison: 0,
      exercise: 'i6ss9z3iip',
      value: 3.16,
      delta: 0,
      name: 'Бег 20 м (сек)',
      order: 6,
    },
    {
      valueForComparison: 0,
      exercise: 'uNcHvpkPFg4t',
      value: 4.15,
      delta: 0,
      name: 'Бег 30 м (сек)',
      order: 7,
    },
    {
      valueForComparison: 0,
      exercise: 'ncEDMKZVMM',
      value: 5,
      delta: 0,
      name: 'Pro agility 5-10-5 (сек)',
      order: 8,
    },
    {
      valueForComparison: 0,
      exercise: 'ice6a13iur',
      value: 21,
      delta: 0,
      name: 'Sit and reach (см)',
      order: 9,
    },
    {
      valueForComparison: 0,
      exercise: 'V0syxg-0GTJt',
      value: 85,
      delta: 0,
      name: 'Пресс по метроному до отказа (количество раз)',
      order: 10,
    },
    {
      valueForComparison: 0,
      exercise: 'ft6g9B2yLv03',
      value: 32,
      delta: 0,
      name: 'Отжимания по метроному до отказа (количество раз)',
      order: 11,
    },
    {
      valueForComparison: 0,
      exercise: 'ip84hl3ivl',
      value: 0,
      delta: 0,
      name: 'Beep-test (уровень)',
      order: 12,
    },
    {
      valueForComparison: 0,
      exercise: 'cc_yDyptaY6b',
      value: 0,
      delta: 0,
      name: 'Beep-test (номер отрезка)',
      order: 13,
    },
    {
      valueForComparison: 0,
      exercise: 'wMhqP7HzsSgC',
      value: 116.67,
      delta: 0,
      name: 'Y-balance test (YBT) левая',
      order: 14,
    },
    {
      valueForComparison: 0,
      exercise: '22O83kcCgWIE',
      value: 119.26,
      delta: 0,
      name: 'Y-balance test (YBT) правая',
      order: 15,
    },
    {
      valueForComparison: 0,
      exercise: 'u7F-wrG6GD6h',
      value: 32,
      delta: 0,
      name: 'Подъем ног в висе на перекладине (количество раз)',
      order: 17,
    },
  ],
  data: [...Array(20)].fill({
    vector: 0,
    playerId: '0Cj_ahcsYb6G',
    params: {
      cc_yDyptaY6b: {
        valueForComparison: 0,
        exercise: 'cc_yDyptaY6b',
        value: 5,
        delta: 0,
        name: 'Beep-test (номер отрезка)',
      },
      ncEDMKZVMM: {
        valueForComparison: 0,
        exercise: 'ncEDMKZVMM',
        value: 4.98,
        delta: 0,
        name: 'Pro agility 5-10-5 (сек)',
      },
      YPVZqpzfcI: {
        valueForComparison: 0,
        exercise: 'YPVZqpzfcI',
        value: 2.45,
        delta: 0,
        name: 'Прыжок в длину с места (см)',
      },
      ice6a13iur: {
        valueForComparison: 0,
        exercise: 'ice6a13iur',
        value: 18,
        delta: 0,
        name: 'Sit and reach (см)',
      },
      Xx9OYrarH5: {
        valueForComparison: 0,
        exercise: 'Xx9OYrarH5',
        value: 49.75,
        delta: 0,
        name: 'Прыжок в высоту (см)',
      },
      ip84hl3ivl: {
        valueForComparison: 0,
        exercise: 'ip84hl3ivl',
        value: 14,
        delta: 0,
        name: 'Beep-test (уровень)',
      },
    },
    firstName: 'Родион ',
    lastName: 'Сафин',
    playerNumber: '58',
  }),
};
