import styled from 'styled-components';

export const InputDefaultWrapper = styled.div`
  .MuiInput {
    &-root {
      width: 100%;
      &:before,
      &:after {
        border-color: rgba(0, 0, 0, 0.42) !important;
      }
    }
    &-input {
      padding-top: 6px;
      padding-bottom: 6px;
      font: 400 normal 16px/24px 'Proxima Nova';
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.15px;

      &::placeholder {
        opacity: 1;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .Mui-error {
    &:before,
    &:after {
      border-color: #d32f2f !important;
    }
  }
  .MuiInputLabel {
    &-root {
      padding-top: 0;
      padding-bottom: 4px;
      font: 400 normal 14px/1 'Proxima Nova';
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.15px;
    }
  }
`;
