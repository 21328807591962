import {Stack} from '@ui/MUI';
import React from 'react';

import Header from './components/Header';
import Scores from './components/Scores';
import Table from './components/Table';
import Time from './components/Time';

const Estimate = ({
  categoryName,
  skillName,
  createdAt,
  controlDate,
  score,
  expectedScore,
  completedScore,
  specialists,
  todo,
  plan,
  id,
  isArchived,
}) => {
  return (
    <Stack spacing={4}>
      <Header categoryName={categoryName} id={id} skillName={skillName} />
      <Time controlDate={controlDate} createdAt={createdAt} />
      <Scores completedScore={completedScore} expectedScore={expectedScore} isArchived={isArchived} score={score} />
      <Table controlDate={controlDate} plan={plan} specialists={specialists} todo={todo} />
    </Stack>
  );
};

export default Estimate;
