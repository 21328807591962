import ContentWrapper from '@common/ContentWrapper';
import {StateFilters} from '@components/apps/Tournaments/components/TournamentView/types';
import {Autocomplete, Box, Stack} from '@ui/MUI';
import Checkbox from '@ui/MUI/Checkbox';
import React, {useCallback} from 'react';

import {playOffStagesoptions} from '../../constants';

type FiltersProps = {
  filters: StateFilters;
  setFilters: React.Dispatch<React.SetStateAction<StateFilters>>;
  isLoading: boolean;
};

function Filters({filters, setFilters, isLoading}: FiltersProps) {
  const setFiltersCallback = useCallback(
    (key: string, value: Pick<StateFilters, 'playOffStages'> | boolean): void =>
      setFilters((prevState) => ({...prevState, [key]: value})),
    [setFilters],
  );
  return (
    <ContentWrapper>
      <Stack direction="row" gap={5}>
        <Checkbox
          checked={filters.isGroupTournament}
          disabled={isLoading}
          id="isGroupTournament"
          label="Групповой турнир"
          onChange={(_, checked) => setFiltersCallback('isGroupTournament', checked)}
        />
        <Checkbox
          checked={filters.isPlayOff}
          disabled={isLoading}
          id="isPlayOff"
          label="Плей-офф"
          onChange={(_, checked) => setFiltersCallback('isPlayOff', checked)}
        />
        {filters?.isPlayOff && (
          <Box width={150}>
            <Autocomplete
              disabled={isLoading}
              isLoading={isLoading}
              multiple={false}
              options={playOffStagesoptions}
              placeholder="Выберите"
              value={filters.playOffStages}
              onChange={(_, value) => setFiltersCallback('playOffStages', value)}
            />
          </Box>
        )}
      </Stack>
    </ContentWrapper>
  );
}

export default Filters;
