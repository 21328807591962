import {NOTIFICATION_TYPES} from '@src/constants/config';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';

import ParseAPI from '../../api/ParseAPI';
import UsersAPI from '../../api/UsersAPI';
import AuthHelper from '../../helpers/AuthHelper';
import * as types from '../ActionTypes';

//LOGIN
let startLoggingIn = () => {
  return {
    type: types.LOGIN,
  };
};
let onLoggedIn = (user) => {
  return {
    type: types.LOGIN_SUCCESS,
    user: user,
  };
};
let onLoginFailed = (error) => {
  return {
    type: types.LOGIN_FAIL,
    error: error,
  };
};

export const clearErrors = () => (dispatch) => {
  dispatch({type: types.LOGIN_CLEAR_ERRORS});
};

export const recoverySuccess = () => (dispatch) => {
  dispatch({type: types.RECOVERY_PASSWORD_SUCCESS});
};

export const recoveryFailed = (err) => (dispatch) => {
  dispatch({
    type: types.RECOVERY_PASSWORD_FAILED,
    error:
      err ||
      'Почта не зарегистрирована в платформе. Укажите другой адрес почты или свяжитесь с администратором: suchkov.ms@hc-avangard.com',
  });
};

export const recoveryPassword = (email) => (dispatch) => {
  dispatch({type: types.RECOVERY_PASSWORD});
  return UsersAPI.recoveryPassword(email);
};

export const validateToken = (token, push) => (dispatch) => {
  if (!token) {
    push('/');
    return;
  }

  dispatch({type: types.VALIDATE_TOKEN});
  return UsersAPI.validateToken(token)
    .then(({data}) => {
      dispatch({type: types.VALIDATE_TOKEN_SUCCESS, user: data});
    })
    .catch(() => {
      dispatch({type: types.VALIDATE_TOKEN_FAILED});
      push('/');
    });
};

export const resetError = (err) => (dispatch) => {
  dispatch({type: types.RESET_PASSWORD_FAILED});
  ReactComponentNotification(NOTIFICATION_TYPES['error'], err || 'Ошибка сервера');
};

export const resetPassword = (id, push, password) => (dispatch) => {
  dispatch({type: types.RESET_PASSWORD});
  return UsersAPI.resetPassword(password, id)
    .then(() => {
      push('/');
      dispatch({type: types.RESET_PASSWORD_SUCCESS});
      ReactComponentNotification(
        NOTIFICATION_TYPES.success,
        'Войдите в систему используя новый пароль.',
        'Пароль успешно изменен',
      );
    })
    .catch(() => {
      dispatch({type: types.RESET_PASSWORD_FAILED});
      ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка сервера');
    });
};

export const throwError = (error) => (dispatch) => {
  dispatch({type: types.LOGIN_THROW_ERROR, error});
};

//thunk
export function logIn(data) {
  return (dispatch) => {
    dispatch(startLoggingIn());
    return ParseAPI.logInAsPromise(data.email, data.password).then(
      (user) => dispatch(onLoggedIn(user)),
      () => dispatch(onLoginFailed('Некорректная почта или пароль')),
    );
  };
}

//SIGNUP
let startSigningUp = () => {
  return {
    type: types.SIGNUP,
  };
};
let onSignedUp = (user) => {
  return {
    type: types.SIGNUP_SUCCESS,
    user: user,
  };
};
let onSignUpFail = (error) => {
  return {
    type: types.SIGNUP_FAIL,
    error: error,
  };
};

//thunk

export function signUp(data) {
  return (dispatch) => {
    dispatch(startSigningUp());
    return ParseAPI.signUpAsPromise(data).then(
      (user) => dispatch(onSignedUp(user)),
      (error) => dispatch(onSignUpFail(error)),
    );
  };
}

//LOGOUT
let startLoggingOut = () => {
  return {
    type: types.LOGOUT,
  };
};
let onLogoutFail = () => {
  return {
    type: types.LOGOUT_FAIL,
  };
};
let onLoggedOut = () => {
  return {
    type: types.LOGOUT_SUCCESS,
  };
};

//thunk
export function logOut() {
  return (dispatch, getState) => {
    var usersState = getState().users;
    if (usersState.currentUserId === undefined) {
      return Promise.resolve();
    }
    dispatch(startLoggingOut());
    AuthHelper.destroyToken();
    return ParseAPI.logOutAsPromise().then(
      () => dispatch(onLoggedOut()),
      () => dispatch(onLogoutFail()),
    );
  };
}

//AUTH_INIT
let startAuthInit = () => {
  return {
    type: types.INITIALIZE_AUTH,
  };
};
let authInitFailed = (err) => {
  return {
    type: types.INITIALIZE_AUTH_FAIL,
    error: err,
  };
};
let authInitSuccess = (user) => {
  return {
    type: types.INITIALIZE_AUTH_SUCCESS,
    user: user,
  };
};

//thunk
export function initializeAuthorization() {
  return (dispatch) => {
    dispatch(startAuthInit());
    return ParseAPI.fetchCurrentUserAsPromise().then(
      (user) => {
        return dispatch(authInitSuccess(user));
      },
      () => dispatch(authInitFailed()),
    );
  };
}

//USERS
let loadUsers_ = () => {
  return {
    type: types.LOAD_USERS,
  };
};

let loadStudent_ = () => {
  return {
    type: types.STUDENT_LOADING,
  };
};

let loadStudentFail = (error) => {
  return {
    type: types.LOAD_STUDENT_FAIL,
    error: error,
  };
};

let loadStudentSuccess = (student) => {
  return {
    type: types.LOAD_STUDENT_SUCCESS,
    student: student?.data || student,
  };
};

let loadUsersFail = (error) => {
  return {
    type: types.LOAD_USERS_FAIL,
    error: error,
  };
};

let loadUsersSuccess = (users, links) => {
  return {
    type: types.LOAD_USERS_SUCCESS,
    users: users,
    links: links === undefined ? [] : links,
  };
};

// update user

let updateUser_ = () => {
  return {
    type: types.UPDATE_USER,
  };
};

let updateUserFail = (err) => {
  return {
    type: types.UPDATE_USER_FAIL,
    error: err,
  };
};

let updateUserSuccess = (user) => {
  return {
    type: types.UPDATE_USER_SUCCESS,
    user: user,
  };
};

export const updateUserLocal = updateUserSuccess;

//thunk
export function updateUser(data) {
  return (dispatch) => {
    dispatch(updateUser_());
    return UsersAPI.updateUser(data).then(
      (user) => {
        dispatch(updateUserSuccess(user));
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Пользователь сохранен');
      },
      (err) => dispatch(updateUserFail(err)),
    );
  };
}
export function updateStudent(data, onSuccess = () => {}, onError = () => {}) {
  return (dispatch) => {
    dispatch(updateUser_());
    return UsersAPI.updateStudent(data)
      .then((user) => {
        dispatch(updateUserSuccess(user));
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Пользователь сохранен');
        onSuccess(user);
      })
      .catch((err) => {
        dispatch(updateUserFail(err));
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], err.response.data.message);
            }
          }
        } else {
          ReactComponentNotification(NOTIFICATION_TYPES['error'], 'При редактировании игрока произошла ошибка.');
        }
        onError();
      });
  };
}

export function changeUserEmail(data) {
  return (dispatch) => {
    dispatch(updateUser_());
    return UsersAPI.changeUserEmail(data).then(
      (user) => dispatch(updateUserSuccess(user)),
      (err) => dispatch(updateUserFail(err)),
    );
  };
}

//load all users
export function loadAllUsers() {
  return (dispatch) => {
    dispatch(loadUsers_());
    return UsersAPI.getAllUsers().then(
      (users) => dispatch(loadUsersSuccess(users)),
      (err) => dispatch(loadUsersFail(err)),
    );
  };
}

export function loadUserById(userId) {
  return (dispatch) => {
    dispatch(loadUsers_());
    return UsersAPI.getUserById(userId).then(
      (u) => dispatch(loadUsersSuccess([u])),
      (err) => dispatch(loadUsersFail(err)),
    );
  };
}

export function loadStudentByIdV2(id) {
  return (dispatch) => {
    dispatch(loadStudent_());
    return UsersAPI.getStudentByIdV2(id).then(
      (student) => dispatch(loadStudentSuccess(student)),
      (err) => dispatch(loadStudentFail(err)),
    );
  };
}
export function loadStudentById(id) {
  return (dispatch) => {
    dispatch(loadStudent_());
    return UsersAPI.getStudentById(id).then(
      (student) => dispatch(loadStudentSuccess(student)),
      (err) => dispatch(loadStudentFail(err)),
    );
  };
}

export function cleanStudentState() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAN_STUDENT_STATE,
      student: {},
    });
  };
}

let searchUsers_ = () => {
  return {
    type: types.SEARCH_USERS,
  };
};

let searchUsersFail = (err) => {
  return {
    type: types.SEARCH_USERS_FAIL,
    error: err,
  };
};
let searchUsersSuccess = (users) => {
  return {
    type: types.SEARCH_USERS_SUCCESS,
    users: users,
    links: [],
  };
};

//thunk
export function searchUsers(searchText) {
  return (dispatch) => {
    dispatch(searchUsers_());
    let data = {text: searchText};
    return ParseAPI.runCloudFunctionAsPromise('searchUsers', data).then(
      (users) => dispatch(searchUsersSuccess(users)),
      (err) => dispatch(searchUsersFail(err)),
    );
  };
}

let createUser_ = () => {
  return {
    type: types.CREATE_USER,
  };
};
let createUserSuccess = (user) => {
  ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Пользователь успешно создан');
  return {
    type: types.CREATE_USER_SUCCESS,
    user: user,
  };
};

//thunk
export function createUser(data) {
  return (dispatch) => {
    dispatch(createUser_());
    return UsersAPI.createUser(data)
      .then((user) => dispatch(createUserSuccess(user)))
      .catch(function (err) {
        const errorsTranslations = {
          'Account already exists for this username.': 'Аккаунт с такой электронной почтой уже существует.',
        };
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(
                NOTIFICATION_TYPES['error'],
                errorsTranslations[err.response.data.message] || err.response.data.message,
              );
            }
          }
        }
      });
  };
}

let deleteUser_ = () => {
  return {
    type: types.DELETE_USER,
  };
};
let deleteUserSuccess = (id) => {
  return {
    type: types.DELETE_USER_SUCCESS,
    id: id,
  };
};
let deleteUserFailed = (error) => {
  return {
    type: types.DELETE_USER_FAIL,
    error: error,
  };
};

//thunk
export function deleteUser(id) {
  return (dispatch) => {
    dispatch(deleteUser_());
    return UsersAPI.deleteUser(id).then(
      () => dispatch(deleteUserSuccess(id)),
      (error) => dispatch(deleteUserFailed(error)),
    );
  };
}

//USERS PARAMS
export function loadAllCitizenships() {
  return (dispatch) => {
    return UsersAPI.getAllCitizenships().then((data) =>
      dispatch({
        type: types.INITIALIZE_CITIZENSHIPS,
        citizenships: data.data,
      }),
    );
  };
}
export function loadAllCountries() {
  return (dispatch) => {
    return UsersAPI.getAllCountries().then((data) =>
      dispatch({
        type: types.INITIALIZE_COUNTRIES,
        countries: data.data,
      }),
    );
  };
}
export function loadAllAmplua() {
  return (dispatch) => {
    return UsersAPI.getAllAmplua().then((data) => {
      dispatch({
        type: types.INITIALIZE_AMPLUA,
        amplua: data.data,
      });
    });
  };
}
export function loadUserStatistics(filter) {
  return (dispatch) => {
    return UsersAPI.getUserStatistics(filter)
      .then((data) => {
        dispatch({
          type: types.LOAD_PLAYER_STATISTICS,
          userStatistics: data.data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке cтатистики';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(
                NOTIFICATION_TYPES['error'],
                `${errorText} + ': ' + ${err.response.data.message}`,
              );
            }
          }
        }
      });
  };
}
export function loadUserStatisticsLoading(filter) {
  return (dispatch) => {
    dispatch({
      type: types.STUDENT_LOADING,
    });

    return UsersAPI.getUserStatistics(filter)
      .then((data) => {
        dispatch({
          type: types.LOAD_PLAYER_STATISTICS_WITHOUT,
          userStatistics: data.data,
        });
      })
      .catch(function (err) {
        dispatch({
          type: types.STOP_STUDENT_LOADING,
        });
        let errorText = 'Ошибка при загрузке cтатистики';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(
                NOTIFICATION_TYPES['error'],
                `${errorText} + ': ' + ${err.response.data.message}`,
              );
            }
          }
        }
      });
  };
}

export function loadStudents(filters) {
  return (dispatch) => {
    dispatch({
      type: types.STUDENT_LOADING,
    });

    return UsersAPI.getStudents(filters)
      .then((data) => {
        dispatch({
          type: types.LOAD_STUDENTS,
          students: data.data,
          studentsCount: data.total,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке игроков';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(
                NOTIFICATION_TYPES['error'],
                `${errorText} + ': ' + ${err.response.data.message}`,
              );
            }
          }
        }
      })
      .finally(() => {
        dispatch({
          type: types.STOP_STUDENT_LOADING,
        });
      });
  };
}

export function loadStudentsV2(filters) {
  return (dispatch) => {
    dispatch({
      type: types.STUDENT_LOADING,
    });

    return UsersAPI.getStudentsV2(filters)
      .then((data) => {
        dispatch({
          type: types.LOAD_STUDENTS,
          students: data.result,
          studentsCount: data.total,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке игроков';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(
                NOTIFICATION_TYPES['error'],
                `${errorText} + ': ' + ${err.response.data.message}`,
              );
            }
          }
        }
      })
      .finally(() => {
        dispatch({
          type: types.STOP_STUDENT_LOADING,
        });
      });
  };
}

export function loadArchiveStudents(filters) {
  return (dispatch) => {
    dispatch({
      type: types.STUDENT_LOADING,
    });

    return UsersAPI.getArchiveStudents(filters)
      .then((data) => {
        dispatch({
          type: types.LOAD_STUDENTS,
          students: data.data,
          studentsCount: data.total,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке игроков';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(
                NOTIFICATION_TYPES['error'],
                `${errorText} + ': ' + ${err.response.data.message}`,
              );
            }
          }
        }
      })
      .finally(() => {
        dispatch({
          type: types.STOP_STUDENT_LOADING,
        });
      });
  };
}
export function loadTeachers(filter) {
  return (dispatch) => {
    dispatch({
      type: types.TEACHERS_LOADING,
    });

    return UsersAPI.getTeachers(filter)
      .then((data) => {
        dispatch({
          type: types.LOAD_TEACHERS,
          teachers: data.data,
          teachersCount: data.total,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке тренеров';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(
                NOTIFICATION_TYPES['error'],
                `${errorText} + ': ' + ${err.response.data.message}`,
              );
            }
          }
        }
      })
      .finally(() => {
        dispatch({
          type: types.STOP_STUDENT_LOADING,
        });
      });
  };
}

export function cleanRatingPlayerStatistics() {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_RATING_PLAYER_STATISTICS,
      ratingPlayerStatistics: null,
    });
  };
}

export function handleChangeDefaultTeacherFilters(defaultFilters) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_TRAINER_DEFAULT_FILTERS,
      defaultFilters: defaultFilters || {},
    });
  };
}

export function saveSelectedFilters(allSelectedFilters) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_STUDENTS_FILTERS,
      allSelectedFilters: allSelectedFilters,
    });
  };
}

export function saveStudentProfileFilters(allSelectedFilters) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_STUDENT_PROFILE_FILTERS,
      allSelectedFilters: allSelectedFilters,
    });
  };
}
