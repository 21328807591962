import ContentWrapper from '@common/ContentWrapper';
import Stage from '@components/apps/Tournaments/components/TournamentView/components/Stage';
import {CurrentTournament, FormData, StateFilters} from '@components/apps/Tournaments/components/TournamentView/types';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import {selectCurrentTournament} from '@selectors/tournaments';
import {playOffStages as stages} from '@src/constants/tournament';
import {StagesEnum} from '@src/types/tournament';
import {Box, Typography} from '@ui/MUI';
import {entries} from 'lodash';
import uniqBy from 'lodash/uniqBy';
import React, {memo} from 'react';
import {FormProvider, useFormContext} from 'react-hook-form';

function StagesTournamentContainer({
  playOffStages,
  games,
}: {
  playOffStages: StateFilters['playOffStages'];
  games: FormData;
}) {
  const form = useFormContext<FormData>();
  const {isLoading, teams} = useStore<CurrentTournament>(selectCurrentTournament);
  const {keys, values, increments} = useDeepMemo(() => {
    const increments: number[] = [];
    const keys: string[] = [];
    const values: string[] = [];
    const stagesObjEntries = entries(stages);
    const currentStagePos = stagesObjEntries?.findIndex(([key]) => key === playOffStages.value);
    stagesObjEntries?.map(([key, value], index) => {
      if (index >= currentStagePos) {
        if (key !== StagesEnum.FINAL) {
          increments.push(Number(key.split('/')[1]));
        } else increments.push(1);
        keys?.push(key);
        values?.push(value);
      }
    });
    return {keys, values, increments};
  }, [playOffStages]);

  return (
    <FormProvider {...form}>
      <ContentWrapper>
        <Typography variant="h3">Плей-офф</Typography>
        {values?.map((item, index) => {
          return (
            <Box key={`playOffItem_${item}_${index}`} mt={4}>
              <Stage
                games={games}
                increment={increments[index]}
                index={index}
                isLoading={isLoading}
                maxLength={increments[index] * 7}
                minLength={1}
                name={`${keys[index]}`}
                teams={uniqBy(teams, 'id')}
                title={item}
              />
            </Box>
          );
        })}
      </ContentWrapper>
    </FormProvider>
  );
}

export default memo(StagesTournamentContainer);
