import {clearStatisticsFilesList, loadStatisticsFilesList} from '@actions/StatsActions';
import useStoreDispatch from '@hooks/useStoreDispatch';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';

import EditStatsFilter from './components/EditStatsFilter';
import EditStatsTable from './components/EditStatsTable';

type Select = {
  value: string;
  label: string;
};

type Filters = {
  school: Select | null;
  group: Select | null;
  season: Select | null;
};

const EditStatsApp = () => {
  const dispatch = useStoreDispatch();

  const [filters, setFilters] = useState<Filters>({
    school: null,
    group: null,
    season: null,
  });

  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    if (selectedPage && filters?.school?.value && filters?.group?.value && filters?.season?.value) {
      dispatch(
        loadStatisticsFilesList({
          teamId: filters.group.value,
          season: filters?.season?.value,
          limit: 20,
          page: selectedPage,
        }),
      );
    } else {
      return () => {
        dispatch(clearStatisticsFilesList());
      };
    }
  }, [selectedPage, filters?.school?.value, filters?.group?.value, filters?.season?.value]);

  const onPaginationChange = (event: React.SyntheticEvent, value: number) => {
    setSelectedPage(value);
  };

  return (
    <>
      <PageTitleDefault className="PageTitle" text="Редактирование статистики" />
      <EditStatsFilter
        filters={filters}
        onChange={(filters: Filters) => {
          setFilters(filters);
          setSelectedPage(1);
        }}
      />
      <EditStatsTable filters={filters} selectedPage={selectedPage} onPaginationChange={onPaginationChange} />
    </>
  );
};

export default EditStatsApp;
