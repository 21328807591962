import {OUTSIDERS} from '@components/apps/StatsReportForOneGame/constants';
import {longDash} from '@src/constants';
import {grey} from '@ui/MUI/colors';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

function ShiftsProgressCell({percent, value, ...props}) {
  const replayedTimeWidth = percent > 60 ? percent - 60 : 0;
  return (
    <>
      <Wrapper>
        <TypographyDefault variant="subtitle2">{value === 0 ? value : value || longDash}</TypographyDefault>
      </Wrapper>
      <Marker
        replayedTimeWidth={props?.replayedTimeWidth >= 0 ? props?.replayedTimeWidth : replayedTimeWidth}
        width={percent}
      />
    </>
  );
}

ShiftsProgressCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ShiftsProgressCell;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Marker = styled.div`
  position: absolute;
  bottom: 0;
  left: 6px;
  min-width: 2px;
  max-width: calc(100% - 12px);
  width: ${({width}) => width + '%'};
  height: 8px;
  background-color: ${grey[24]};
  &:after {
    position: absolute;
    right: 0;
    display: block;
    width: ${({replayedTimeWidth}) => replayedTimeWidth + '%'};
    height: 100%;
    background-color: ${OUTSIDERS};
    content: '';
  }
`;
