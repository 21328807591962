import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';

export function getCommonReport(params) {
  return axios.get(
    `${API_ENDPOINT}/v1/nutrition`,
    {params},
    {
      failed: {message: 'Ошибка при получении данных'},
    },
  );
}

export function postCommonReport(params) {
  return axios.post(`${API_ENDPOINT}/v1/nutrition`, params, {
    success: {message: 'Отчет успешно сохранен'},
    failed: {message: 'Ошибка при сохранении отчета'},
  });
}

export function deleteCommonReport(data) {
  return axios.delete(`${API_ENDPOINT}/v1/nutrition`, {
    data,
    success: {message: 'Отчет успешно удален'},
    failed: {message: 'Ошибка при удалении отчета'},
  });
}

export function getTeamCommonReport(params) {
  return axios.get(
    `${API_ENDPOINT}/v1/nutrition/previous-team-report`,
    {params},
    {
      failed: {message: 'Ошибка при получении данных'},
    },
  );
}

export function getNorms(ageGroup) {
  return axios.get(
    `${API_ENDPOINT}/v1/nutrition/report-norm`,
    {params: {ageGroup}},
    {
      failed: {message: 'Ошибка при получении данных'},
    },
  );
}

export function putNorms(params) {
  return axios.put(`${API_ENDPOINT}/v1/nutrition/report-norm`, params, {
    success: {message: 'Нормы успешно отредактированы'},
    failed: {message: 'Ошибка при редактировании норм'},
  });
}

export function getPlayerReports(params) {
  return axios.get(
    `${API_ENDPOINT}/v1/nutrition/all`,
    {params},
    {
      failed: {message: 'Ошибка при получении данных'},
    },
  );
}
