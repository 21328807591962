import {useCategories} from '@common/DevelopmentJournal/hooks';
import {PageWrapper} from '@common/Page';
import {useGroup, useSchool, useUsersWithRequest} from '@hooks/filters';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectFilters} from '@selectors/skill';
import {updateFilters} from '@slices/skill';
import {userRoles} from '@src/constants';
import {Autocomplete, Box} from '@ui/MUI';
import React, {useEffect} from 'react';

const SchoolFilter = (props) => {
  const school = useSchool();
  const value = useStore(selectFilters({entityName: 'players', name: 'school'}));
  return <Autocomplete options={school.options} {...props} value={value} />;
};

const GroupFilter = (props) => {
  const school = useStore(selectFilters({entityName: 'players', name: 'school'}));
  const group = useGroup({connectorValue: school?.value});
  const value = useStore(selectFilters({entityName: 'players', name: 'group'}));
  return <Autocomplete {...props} options={group.options} value={value} />;
};

const SpecialistsFilter = (props) => {
  const value = useStore(selectFilters({entityName: 'players', name: 'specialists'}));
  return <Autocomplete {...props} options={props?.options || {}} value={value} />;
};

const CategoriesFilter = (props) => {
  const value = useStore(selectFilters({entityName: 'players', name: 'categories'}));
  const {data, isLoading} = useCategories();
  return <Autocomplete {...props} isLoading={isLoading} options={data} value={value} />;
};

const filters = [
  {label: 'Школа', maxWidth: 190, component: SchoolFilter, name: 'school', multiple: false},
  {label: 'Команда', maxWidth: 204, component: GroupFilter, name: 'group', multiple: false},
  {label: 'Группа навыков', maxWidth: 204, component: CategoriesFilter, name: 'categories'},
  {label: 'Специалисты', maxWidth: 204, component: SpecialistsFilter, name: 'specialists'},
];

export const Filters = () => {
  const [qs, _] = useQueryString();
  const dispatch = useStoreDispatch();
  const handleChange = (name, value) => {
    dispatch(updateFilters({name, value, entityName: 'players'}));
  };
  const school = useSchool();
  const group = useGroup({connectorValue: useStore(selectFilters({entityName: 'players', name: 'school'}))?.value});
  useEffect(() => {
    if (school?.options?.length === 1) {
      dispatch(updateFilters({name: 'school', value: school?.options?.[0], entityName: 'players'}));
    }
  }, [school?.options?.length]);
  useEffect(() => {
    if (group?.options?.length === 1) {
      dispatch(updateFilters({name: 'group', value: group?.options?.[0], entityName: 'players'}));
    }
  }, [group?.options?.length]);
  useEffect(() => {
    if (qs?.school) {
      dispatch(
        updateFilters({
          name: 'school',
          value: school?.options?.find((option) => option.value === qs?.school),
          entityName: 'players',
        }),
      );
    }
    if (qs?.group) {
      dispatch(
        updateFilters({
          name: 'group',
          value: group?.options?.find((option) => option.value === qs?.group),
          entityName: 'players',
        }),
      );
    }
  }, [qs?.school, qs?.group]);

  const users = useUsersWithRequest([userRoles?.teacher, userRoles?.psycho]);

  return (
    <PageWrapper alignItems="end" direction="row" gap={4} px={4} py={3}>
      {filters.map((filter) => {
        return (
          <Box flex="1" key={filter.label} maxWidth={filter.maxWidth}>
            {filter.component(
              filter?.name !== 'specialists'
                ? {
                    label: filter.label,
                    onChange: (_, value) => handleChange(filter.name, value),
                    multiple: filter?.multiple,
                  }
                : {
                    label: filter.label,
                    onChange: (_, value) => handleChange(filter.name, value),
                    multiple: filter?.multiple,
                    options: users?.options,
                  },
            )}
          </Box>
        );
      })}
    </PageWrapper>
  );
};
