import React from 'react';

function HockeyMask() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M18.7086 30.8762H16.6521C14.8465 30.8762 13.3418 29.3715 13.3418 27.5658V24.9075H14.4452V27.5658C14.4452 28.7696 15.4484 29.7727 16.6521 29.7727H18.7086C19.9123 29.7727 20.9155 28.7696 20.9155 27.5658V24.8573H22.0189V27.5658C22.0189 29.4216 20.5142 30.8762 18.7086 30.8762Z"
          fill="#354546"
        />
        <path
          d="M14.6458 24.8574C14.6458 25.2586 14.2947 25.6097 13.8935 25.6097C13.4922 25.6097 13.1411 25.2586 13.1411 24.8574C13.1411 24.4561 13.4922 24.105 13.8935 24.105C14.2947 24.105 14.6458 24.4561 14.6458 24.8574Z"
          fill="#354546"
        />
        <path
          d="M22.2195 24.8573C22.2195 25.2586 21.8684 25.6097 21.4672 25.6097C21.0659 25.6097 20.7148 25.2586 20.7148 24.8573C20.7148 24.4561 21.0659 24.105 21.4672 24.105C21.8684 24.105 22.2195 24.4561 22.2195 24.8573Z"
          fill="#354546"
        />
        <path
          d="M20.3137 17.4843H14.6962C13.4422 17.4843 12.4893 18.4875 12.4893 19.6912V24.2555C12.4893 25.5094 13.4924 26.4624 14.6962 26.4624H20.2636C21.5175 26.4624 22.4704 25.4593 22.4704 24.2555V19.6912C22.5206 18.4875 21.5175 17.4843 20.3137 17.4843ZM20.2134 23.5031C20.2134 24.1552 19.6617 24.7069 19.0096 24.7069H15.9501C15.298 24.7069 14.7463 24.1552 14.7463 23.5031V20.7947C14.7463 20.1426 15.298 19.5909 15.9501 19.5909H19.0096C19.6617 19.5909 20.2134 20.1426 20.2134 20.7947V23.5031Z"
          fill="#990011"
        />
        <path
          d="M27.7366 17.4843C27.7366 18.7382 27.4859 20.0423 26.9341 21.2461C26.7837 21.6473 26.3824 21.8981 25.931 21.8981H2.15672C1.75546 21.8981 1.35421 21.6473 1.15358 21.2461C0.601858 20.0423 0.351074 18.7884 0.351074 17.4843C0.351074 11.7163 5.91847 6.95141 12.9404 6.55016C13.3417 6.5 13.6928 6.5 14.0439 6.5C16.1504 6.5 18.1567 6.90125 19.9624 7.55329C24.5768 9.35893 27.7366 13.1207 27.7366 17.4843Z"
          fill="#C52E25"
        />
        <path
          d="M27.7366 17.4843C27.7366 13.1207 24.5265 9.35894 19.9623 7.60345C15.4983 9.30878 12.3886 12.9201 12.188 17.1332H26.8839C27.1849 17.1332 27.4858 17.2335 27.7366 17.4843Z"
          fill="#D53D33"
        />
        <path
          d="M12.439 19.5909C12.5894 20.1426 12.7399 20.6944 12.9907 21.2461C13.1412 21.6473 13.5424 21.8981 13.9938 21.8981H25.9813C26.3825 21.8981 26.7838 21.6473 26.9844 21.2461C27.2352 20.6442 27.4358 20.0423 27.5863 19.3903C27.3857 19.5408 27.185 19.5909 26.9343 19.5909H12.439Z"
          fill="#D53D33"
        />
        <path
          d="M28.0879 18.337C28.0879 17.9859 27.9374 17.6849 27.7368 17.4342C27.7368 17.4342 27.7368 17.4342 27.7368 17.4843C27.7368 18.1364 27.6866 18.7382 27.5361 19.3903C27.8872 19.1896 28.0879 18.7884 28.0879 18.337Z"
          fill="#C52E25"
        />
        <path
          d="M12.1881 17.1332H1.25392C0.551724 17.1332 0 17.685 0 18.3872C0 19.0894 0.551724 19.6411 1.25392 19.6411H12.4389C12.2884 18.9389 12.1881 18.2367 12.1881 17.5345C12.1379 17.384 12.1881 17.2336 12.1881 17.1332Z"
          fill="#B51F18"
        />
        <path
          d="M27.7366 17.4843C27.5359 17.2837 27.1849 17.1332 26.8839 17.1332H12.188C12.188 17.2837 12.188 17.384 12.188 17.5345C12.188 18.2367 12.2883 18.9389 12.4388 19.6411H26.8839C27.1347 19.6411 27.3353 19.5909 27.5359 19.4404C27.6864 18.7884 27.7366 18.1364 27.7366 17.4843Z"
          fill="#C52E25"
        />
        <path
          d="M11.8368 19.5909H6.2694C5.01548 19.5909 4.0625 20.594 4.0625 21.7978V26.3621C4.0625 27.616 5.06563 28.569 6.2694 28.569H11.8368C13.0907 28.569 14.0437 27.5658 14.0437 26.3621V21.7978C14.0437 20.594 13.0406 19.5909 11.8368 19.5909ZM11.7365 25.6097C11.7365 26.2617 11.1848 26.8135 10.5327 26.8135H7.52331C6.87128 26.8135 6.31955 26.2617 6.31955 25.6097V22.9012C6.31955 22.2492 6.87128 21.6975 7.52331 21.6975H10.5829C11.2349 21.6975 11.7866 22.2492 11.7866 22.9012V25.6097H11.7365Z"
          fill="#C52E25"
        />
        <path
          d="M5.76794 21.9984C5.76794 22.7508 5.16606 23.3527 4.41371 23.3527H4.01246C4.01246 23.3025 4.01246 23.2523 4.01246 23.2523C4.01246 22.5 3.41058 21.8981 2.65823 21.8981H2.30713C2.35729 21.547 2.50776 21.2461 2.70838 21.0454C2.95917 20.7947 3.31026 20.6442 3.66136 20.6442H4.36356C5.06575 20.6442 5.66763 21.1959 5.71779 21.8981C5.76794 21.8981 5.76794 21.9483 5.76794 21.9984Z"
          fill="#C52E25"
        />
        <path
          d="M27.3352 14.1238C27.3352 14.5251 27.0343 14.826 26.6331 14.826L8.7271 14.826C8.32584 14.826 8.0249 14.5251 8.0249 14.1238C8.0249 13.7226 8.32584 13.4216 8.7271 13.4216L26.6331 13.4216C27.0343 13.4216 27.3352 13.7727 27.3352 14.1238Z"
          fill="#FFFAED"
        />
        <path
          d="M18.3073 16.0298C18.3073 16.2304 18.1569 16.3809 17.9562 16.3809H9.02834C8.82772 16.3809 8.67725 16.2304 8.67725 16.0298C8.67725 15.8292 8.82772 15.6787 9.02834 15.6787H17.9562C18.1569 15.6787 18.3073 15.8292 18.3073 16.0298Z"
          fill="#E54D42"
        />
        <path
          d="M26.9342 16.0298C26.9342 16.2304 26.7837 16.3809 26.5831 16.3809H17.605C17.4044 16.3809 17.2539 16.2304 17.2539 16.0298C17.2539 15.8292 17.4044 15.6787 17.605 15.6787H26.5831C26.7837 15.6787 26.9342 15.8292 26.9342 16.0298Z"
          fill="#E54D42"
        />
        <path
          d="M28.0876 16.0298C28.0876 16.2304 27.9371 16.3809 27.7365 16.3809H18.8086C18.608 16.3809 18.4575 16.2304 18.4575 16.0298C18.4575 15.8292 18.608 15.6787 18.8086 15.6787H27.7365C27.9371 15.6787 28.0876 15.8292 28.0876 16.0298Z"
          fill="#FFFAED"
        />
        <path
          d="M10.1318 33.1332H8.02519C6.21955 33.1332 4.71484 31.6285 4.71484 29.8229V27.1646H5.81829V29.8229C5.81829 31.0266 6.82143 32.0298 8.02519 32.0298H10.0816C11.2854 32.0298 12.2885 31.0266 12.2885 29.8229V27.1144H13.392V29.8229C13.4421 31.6285 11.9374 33.1332 10.1318 33.1332Z"
          fill="#354546"
        />
        <path
          d="M6.06916 27.1144C6.06916 27.5157 5.71806 27.8668 5.3168 27.8668C4.91555 27.8668 4.56445 27.5157 4.56445 27.1144C4.56445 26.7131 4.91555 26.362 5.3168 26.362C5.71806 26.3119 6.06916 26.663 6.06916 27.1144Z"
          fill="#354546"
        />
        <path
          d="M13.6424 27.1144C13.6424 27.5157 13.2913 27.8668 12.89 27.8668C12.4888 27.8668 12.1377 27.5157 12.1377 27.1144C12.1377 26.7132 12.4888 26.3621 12.89 26.3621C13.2913 26.3621 13.6424 26.663 13.6424 27.1144Z"
          fill="#354546"
        />
        <path
          d="M17.9058 38.5C17.8055 38.5 17.7052 38.5 17.6049 38.5C16.7021 38.4498 15.8996 37.9984 15.2977 37.2962L11.2852 32.5815L12.3384 31.7288L16.351 36.4436C16.7021 36.8448 17.2037 37.0956 17.7052 37.1458C18.2569 37.1959 18.7585 37.0455 19.1598 36.6944L20.0626 35.942C20.9153 35.2398 21.0156 33.9357 20.3134 33.0831L18.3573 30.7759L19.3604 29.9232L21.3165 32.2304C22.5203 33.6348 22.3698 35.7915 20.9153 36.9953L20.0626 37.6975C19.4607 38.1991 18.6582 38.5 17.9058 38.5Z"
          fill="#41555E"
        />
        <path
          d="M12.79 32.1301C13.091 32.4812 13.091 33.0329 12.7399 33.3338C12.3888 33.6348 11.8371 33.6348 11.5361 33.2837L10.7838 32.4812C10.4828 32.1301 10.4828 31.5784 10.8339 31.2774C11.185 30.9765 11.7367 30.9765 12.0377 31.3276L12.79 32.1301Z"
          fill="#303030"
        />
        <path
          d="M19.9121 30.0736C20.213 30.4247 20.213 30.9765 19.8619 31.2774C19.5108 31.5783 18.9591 31.5783 18.6582 31.2272L17.9058 30.4247C17.6049 30.0736 17.6049 29.5219 17.956 29.221C18.3071 28.92 18.8588 28.92 19.1598 29.2711L19.9121 30.0736Z"
          fill="#303030"
        />
      </g>
    </React.Fragment>
  );
}
export default HockeyMask;
