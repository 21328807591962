import Accordion from '@common/Accordion';
import ChildrenField from '@common/ChildrenField';
import EstimateItemWithLabelHorizontal from '@common/DevelopmentJournal/components/EstimateItemWithLabelHorizontal';
import EstimateItemWithLabelVertical from '@common/DevelopmentJournal/components/EstimateItemWithLabelVertical';
import EstimateRange from '@common/DevelopmentJournal/components/EstimateRange';
import {useCategories} from '@common/DevelopmentJournal/hooks';
import {useUsersWithRequest} from '@hooks/filters';
import {Check} from '@mui/icons-material';
import {userRoles} from '@src/constants';
import {Box, DatePicker, Divider, Stack, TextField, Typography} from '@ui/MUI';
import AutocompleteWithVirtualization from '@ui/MUI/Autocomplete/AutocompleteWithVirtualization';
import moment from 'moment';
import React from 'react';
import {useWatch} from 'react-hook-form';

const SkillItem = ({skill, onSelect, value}) => {
  const handleSelect = () => {
    onSelect(skill);
  };

  const isSelected = value?.id === skill.id;
  const color = isSelected ? 'primary' : 'text.primary';
  const variant = isSelected ? 'subtitle1' : 'body1';
  const cursor = isSelected ? 'default' : 'pointer';
  const sx = {cursor};

  return (
    <Stack spacing={1} sx={sx} onClick={handleSelect}>
      <Stack direction="row" justifyContent="space-between">
        <Typography color={color} variant={variant}>
          {skill.name}
        </Typography>
        {isSelected && <Check color="primary" />}
      </Stack>
      <Divider />
    </Stack>
  );
};
const AccordionList = ({categories, onChange, value}) => {
  return (
    <Stack>
      {categories.map((category) => {
        return (
          <Accordion key={category.id} title={category.name}>
            <Stack spacing={4}>
              {category.skills.map((skill) => {
                return <SkillItem key={skill.id} skill={skill} value={value} onSelect={onChange} />;
              })}
            </Stack>
          </Accordion>
        );
      })}
    </Stack>
  );
};
const Categories = () => {
  const categories = useCategories();
  return (
    <ChildrenField isRequired name="skill">
      <AccordionList categories={categories.data} />
    </ChildrenField>
  );
};

const Details = ({form}) => {
  const score = useWatch({name: 'score'});
  const tomorrow = moment().add(1, 'day');

  return (
    <EstimateItemWithLabelVertical label="Опишите детали:" labelVariant="body1" spacing={2}>
      <Stack spacing={4}>
        <ChildrenField
          isRequired
          name="todo"
          validate={(value) => {
            if (value?.length > 255) {
              form?.setError('todo', {message: 'Введите не более 255 символов'});
            } else {
              form?.clearErrors('todo');
            }
          }}
        >
          <TextField label="Что должен сделать игрок" />
        </ChildrenField>
        <ChildrenField
          isRequired
          name="plan"
          validate={(value) => {
            if (value?.length > 255) {
              form?.setError('plan', {message: 'Введите не более 255 символов'});
            } else {
              form?.clearErrors('plan');
            }
          }}
        >
          <TextField label="План работы с игроком" />
        </ChildrenField>
        <Box maxWidth="150px">
          <ChildrenField isRequired name="controlDate">
            <DatePicker label="Дата контроля" minDate={tomorrow} variant="standard" />
          </ChildrenField>
        </Box>
        <Stack spacing={4}>
          <EstimateItemWithLabelHorizontal label="Текущий результат" labelVariant="body1" spacing={2}>
            <ChildrenField isRequired name="score">
              <EstimateRange max={10} min={1} />
            </ChildrenField>
          </EstimateItemWithLabelHorizontal>
          <EstimateItemWithLabelHorizontal label="Целевой результат" labelVariant="body1" spacing={2}>
            <ChildrenField isRequired name="expectedScore">
              <EstimateRange disabledFrom={score} max={10} min={1} />
            </ChildrenField>
          </EstimateItemWithLabelHorizontal>
        </Stack>
      </Stack>
    </EstimateItemWithLabelVertical>
  );
};

const Specialists = ({form}) => {
  const {setValue} = form;
  const users = useUsersWithRequest([userRoles.teacher, userRoles.psycho]);
  const specialists = useWatch({name: 'specialists'});
  return (
    <EstimateItemWithLabelVertical
      label="Укажите задействованных специалистов:"
      labelColor="#2C2C2C"
      labelVariant="body1"
      spacing={4}
    >
      <ChildrenField name="specialists">
        <AutocompleteWithVirtualization
          label="Специалисты"
          options={users.options}
          value={specialists}
          onChange={(_, newValue) => {
            setValue('specialists', newValue);
          }}
        />
      </ChildrenField>
    </EstimateItemWithLabelVertical>
  );
};

const Form = ({form}) => {
  return (
    <Stack spacing={5}>
      <Categories />
      <Details form={form} />
      <Specialists form={form} />
    </Stack>
  );
};

export default Form;
