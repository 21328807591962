import React from 'react';

function RatingPuck() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M14.2664 0.265005C12.3688 -0.175948 10.0359 -0.0638508 7.69768 0.580654C5.35945 1.22516 3.28466 2.32797 1.85555 3.68592C0.36427 5.1029 -0.2596 6.64103 0.0987736 8.01688L1.10707 11.8879C1.46544 13.2637 2.75333 14.2749 4.73356 14.735C6.63124 15.1759 8.96408 15.0639 11.3023 14.4193C13.6405 13.7748 15.7153 12.672 17.1444 11.3141C18.6357 9.8971 19.2596 8.359 18.9012 6.98312L17.8929 3.11214C17.5346 1.73629 16.2467 0.725106 14.2664 0.265005ZM17.699 5.28464L18.1923 7.17853C18.4767 8.27028 17.9277 9.54203 16.6465 10.7595C15.3031 12.0359 13.3378 13.0767 11.1124 13.6901C8.88695 14.3035 6.679 14.413 4.89524 13.9986C3.19393 13.6032 2.10037 12.7842 1.816 11.6925L1.32269 9.79857C1.93485 10.2738 2.74503 10.6363 3.72526 10.864C5.62294 11.305 7.95579 11.1929 10.294 10.5484C12.6323 9.90386 14.707 8.80104 16.1362 7.4431C16.8744 6.74164 17.3998 6.01106 17.699 5.28464ZM15.6382 6.88849C14.2948 8.16488 12.3295 9.20568 10.1041 9.81909C7.87866 10.4325 5.6707 10.542 3.88695 10.1276C2.18563 9.73224 1.09208 8.91323 0.807704 7.82147C0.523328 6.72971 1.07229 5.45797 2.35352 4.24052C3.69686 2.96414 5.66224 1.92333 7.88764 1.30993C10.113 0.696522 12.321 0.586998 14.1048 1.00144C15.8061 1.39678 16.8996 2.21578 17.184 3.30754C17.4684 4.3993 16.9194 5.67105 15.6382 6.88849Z"
          fill="black"
        />
      </g>
    </React.Fragment>
  );
}
export default RatingPuck;
