import {
  ProgressBar,
  ProgressIndicator,
  ProgressNumber,
} from '@components/apps/PlayerPersonalFile/components/MainInfo/components/SkillCard/SkillCard';
import date from '@helpers/date';
import useDeepMemo from '@hooks/useDeepMemo';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import {longDash} from '@src/constants';
import {Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import NamedCard from '../NamedCard';

SkillCard.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  index: PropTypes.number,
};

function SkillCard({isLoading, error, data, index}) {
  const skill = useDeepMemo(() => data?.[index] || {}, [index, data]);
  return (
    <NamedCard flex={1} minWidth="fit-content" title="Навык" width="fill-available">
      <Stack direction="column" height="100%">
        {!isLoading && (error || isEmpty(skill)) ? (
          <Typography m="auto" variant="subtitle1">
            Навык для развития не выбран
          </Typography>
        ) : (
          <>
            <Typography isLoading={isLoading} preloaderWidthBetween={[100, 150]} variant="h4">
              {skill?.skill?.name || longDash}
            </Typography>
            <Stack direction="row" gap={0}>
              <Typography color={grey[54]} variant="body1">
                Дата создания:
              </Typography>
              <Typography color={grey[54]} isLoading={isLoading} preloaderWidthBetween={[40, 60]} variant="subtitle1">
                {skill?.createdAt ? date(skill.createdAt).format('DD.MM.YYYY') : longDash}
              </Typography>
            </Stack>
            <ProgressBar my={3} percent={skill?.score || 0}>
              <ProgressIndicator percent={skill?.score || 0}>
                <Typography color={grey['26']} variant="caption2" zIndex={1}>
                  {skill?.score || 0}
                </Typography>
              </ProgressIndicator>
            </ProgressBar>
            <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                <ProgressNumber
                  current={skill?.score === item}
                  expected={skill?.expectedScore === item}
                  key={index}
                  variant="subtitle1"
                >
                  {item}
                </ProgressNumber>
              ))}
            </Stack>
            <Stack alignItems="center" direction="row" gap={0} mt="auto">
              <Typography color={grey[54]} variant="body1">
                Дата завершения:
              </Typography>
              <Typography color={grey[54]} isLoading={isLoading} preloaderWidthBetween={[40, 60]} variant="subtitle1">
                {skill?.controlDate ? date(skill.controlDate).format('DD.MM.YYYY') : longDash}
              </Typography>
              {skill?.controlDate && date(date().innerDate).isAfter(date(skill?.controlDate).innerDate) && (
                <TimerOutlinedIcon color="primary" sx={{width: 12, height: 12}} />
              )}
            </Stack>
          </>
        )}
      </Stack>
    </NamedCard>
  );
}

export default SkillCard;
