import React from 'react';

function Stats() {
  return (
    <React.Fragment>
      <g filter="url(#filter0_d_19464_17327)">
        <path
          d="M16.5689 3.03518H13.5414C13.3034 3.03518 13.1103 3.22824 13.1103 3.46628V6.39731H10.9449V1.4311C10.9449 1.19306 10.7519 1 10.5138 1H7.48621C7.24817 1 7.05511 1.19306 7.05511 1.4311V9.58843H4.88974V5.92397C4.88974 5.68586 4.69668 5.49287 4.45864 5.49287H1.4311C1.19299 5.49287 1 5.68586 1 5.92397V14.1769C1 14.415 1.19306 14.608 1.4311 14.608H4.45864H7.48618H10.5138H13.5414H16.5689C16.807 14.608 17 14.4149 17 14.1769V3.46628C17 3.22824 16.807 3.03518 16.5689 3.03518ZM4.02757 13.7458H1.8622V6.35507H4.02757V13.7458ZM7.05518 13.7458H4.88981V10.4506H7.05518V13.7458ZM10.0828 6.82841V13.7458H7.91735V1.8622H10.0828V6.82841ZM13.1104 13.7458H10.945V7.25951H13.1104V13.7458ZM16.1379 13.7458H13.9726V6.82841V3.89738H16.1379V13.7458H16.1379Z"
          fill="black"
          fillOpacity="0.9"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="15.6079"
          id="filter0_d_19464_17327"
          width="18"
          x="0"
          y="0.5"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_19464_17327" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_19464_17327" mode="normal" result="shape" />
        </filter>
      </defs>
    </React.Fragment>
  );
}
export default Stats;
