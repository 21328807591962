import React from 'react';

function Documents() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M10.9769 0.899902H3.06498C1.92472 0.900419 1.00039 1.82463 1 2.96488V13.0271C1.00039 14.1674 1.92472 15.0916 3.06498 15.0921H10.9769C12.1171 15.0916 13.0413 14.1674 13.0418 13.0271V2.96488C13.0413 1.82463 12.1171 0.900419 10.9769 0.899902ZM12.2159 13.0271C12.2139 13.7105 11.6602 14.2642 10.9769 14.2661H3.06498C2.3816 14.2642 1.82793 13.7105 1.82599 13.0271V2.96488C1.82793 2.2815 2.3816 1.72783 3.06498 1.72589H10.9769C11.6602 1.72783 12.2139 2.2815 12.2159 2.96488V13.0271Z"
          fill="#626262"
        />
        <path
          d="M4.29884 5.3999H9.60279C9.83084 5.3999 10.0158 5.21496 10.0158 4.98691C10.0158 4.75886 9.83084 4.57391 9.60279 4.57391H4.29884C4.07066 4.57391 3.88584 4.75886 3.88584 4.98691C3.88584 5.21496 4.07066 5.3999 4.29884 5.3999Z"
          fill="#626262"
        />
        <path
          d="M4.29884 7.3999H9.60279C9.83084 7.3999 10.0158 7.21496 10.0158 6.98691C10.0158 6.75886 9.83084 6.57391 9.60279 6.57391H4.29884C4.07066 6.57391 3.88584 6.75886 3.88584 6.98691C3.88584 7.21496 4.07066 7.3999 4.29884 7.3999Z"
          fill="#626262"
        />
        <path
          d="M4.29884 9.3999H9.60279C9.83084 9.3999 10.0158 9.21496 10.0158 8.98691C10.0158 8.75886 9.83084 8.57391 9.60279 8.57391H4.29884C4.07066 8.57391 3.88584 8.75886 3.88584 8.98691C3.88584 9.21496 4.07066 9.3999 4.29884 9.3999Z"
          fill="#626262"
        />
        <path
          d="M4.29884 11.3999H9.60279C9.83084 11.3999 10.0158 11.215 10.0158 10.9869C10.0158 10.7589 9.83084 10.5739 9.60279 10.5739H4.29884C4.07066 10.5739 3.88584 10.7589 3.88584 10.9869C3.88584 11.215 4.07066 11.3999 4.29884 11.3999Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default Documents;
