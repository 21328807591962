import React from 'react';
import styled from 'styled-components';

export default function TableEmptyBlock(props) {
  const {text = React.ReactNode, content} = props;
  return (
    <Wrapper className={`${props.className} TableEmptyBlock`} data-testid="no-data">
      <p>{text}</p>
      {content || null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  min-height: 500px;
  background-color: white;
  border-radius: 6px;
  box-sizing: border-box;

  p {
    font: bold normal 14px/16px 'Proxima Nova';
    margin-top: 0;
    margin-bottom: 0;
    color: #2c2c2c;

    @media screen and (max-width: 992px) {
      text-align: center;
    }
  }
`;
