import {
  FiltersWrapper,
  FilterWrapper,
} from '@components/apps/PhysicalTesting/components/CreateEdit/components/Filters/Filters';
import {stageOptions, typeOfTestsOptions} from '@components/apps/PhysicalTesting/constants';
import {useGroup, useSchool} from '@hooks/filters';
import {useSeasons} from '@hooks/seasonsHooks';
import {Autocomplete} from '@ui/MUI';
import React, {useEffect} from 'react';

const Filters = ({filters, isStudent, updateFilters}) => {
  const school = useSchool({});
  const group = useGroup({connectorValue: filters?.school?.value});
  const seasons = useSeasons(isStudent);
  useEffect(() => {
    if (school.options.length === 1) {
      updateFilters({...filters, school: school.options?.[0], group: null});
    }
  }, [school.options.length]);
  useEffect(() => {
    if (filters?.school && group.options.length === 1) {
      updateFilters({...filters, group: group.options?.[0]});
    }
  }, [filters?.school, group.options.length]);
  return (
    <FiltersWrapper mb={'12px'}>
      {!isStudent && (
        <FilterWrapper minWidth="204px">
          <Autocomplete
            isLoading={school.isLoading}
            label="Школа"
            multiple={false}
            options={school.options}
            value={filters?.school}
            onChange={(_, newValue) => {
              updateFilters({...filters, school: newValue, group: null});
            }}
          />
        </FilterWrapper>
      )}
      {!isStudent && (
        <FilterWrapper minWidth="204px">
          <Autocomplete
            disabled={!filters?.school}
            isLoading={group.isLoading}
            label="Команда"
            multiple={false}
            options={group.options}
            value={filters?.group}
            onChange={(_, newValue) => {
              updateFilters({...filters, group: newValue});
            }}
          />
        </FilterWrapper>
      )}
      <FilterWrapper minWidth={!isStudent ? '204px' : '120px'}>
        <Autocomplete
          label="Вид тестов"
          multiple={false}
          options={typeOfTestsOptions}
          value={filters?.typeOfTests}
          onChange={(_, newValue) => {
            updateFilters({...filters, typeOfTests: newValue});
          }}
        />
      </FilterWrapper>
      <FilterWrapper minWidth={!isStudent ? '204px' : '110px'}>
        <Autocomplete
          label="Сезон"
          multiple={false}
          options={seasons?.options}
          value={filters?.season}
          onChange={(_, newValue) => {
            updateFilters({...filters, season: newValue});
          }}
        />
      </FilterWrapper>
      <FilterWrapper minWidth={!isStudent ? '204px' : '70px'}>
        <Autocomplete
          label="Этап"
          multiple={false}
          options={stageOptions}
          value={filters?.stage}
          onChange={(_, newValue) => {
            updateFilters({...filters, stage: newValue});
          }}
        />
      </FilterWrapper>
    </FiltersWrapper>
  );
};

export default Filters;
