import React from 'react';
import {useSelector} from 'react-redux';

import AdminSchoolsApp from './AdminSchoolsApp';
import FranchiseeSchoolsApp from './FranchiseeSchoolsApp';

const SchoolsApp = () => {
  const currentUser = useSelector((state) => state.users.currentUser);

  return currentUser.userRole && currentUser.userRole === 'admin' ? <AdminSchoolsApp /> : <FranchiseeSchoolsApp />;
};

export default SchoolsApp;
