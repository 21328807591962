import {
  createAge,
  createCategory,
  createExercise,
  createSections,
  createSkill,
  createTheme,
  createTraining,
  deleteAge,
  deleteCategory,
  deleteExercise,
  deleteSections,
  deleteSkill,
  deleteTheme,
  deleteTraining,
  estimateTraining,
  getAges,
  getCategories,
  getEstimatedTrainingList,
  getExercises,
  getNotEstimatedTrainingList,
  getOneTraining,
  getSections,
  getSkills,
  getThemes,
  getTrainingList,
  updateAge,
  updateCategory,
  updateExercise,
  updateSections,
  updateSkill,
  updateTheme,
  updateTraining,
} from '@api/TrainingControlProcess';
import {createSlice} from '@reduxjs/toolkit';

const defaultValue = {
  data: [],
  isLoading: false,
  isLoad: false,
  isError: false,
  error: null,
  tab: null,
  form: {
    isLoading: false,
    isError: false,
    isEdit: false,
    isOpen: false,
  },
};

const initialState = {
  age: defaultValue,
  theme: defaultValue,
  section: defaultValue,
  category: defaultValue,
  exercise: defaultValue,
  skill: defaultValue,
  training_templates: defaultValue,
  training_library: defaultValue,
  training_not_estimated: defaultValue,
  training_estimated: defaultValue,
  new_training: defaultValue,
  training: {...defaultValue, data: {}},
  step: 0,
  tempData: null, // данные для (модалок)
  tab: null,
};

const fetchers = {
  age: getAges,
  theme: getThemes,
  section: getSections,
  category: getCategories,
  exercise: getExercises,
  skill: getSkills,
  training_templates: getTrainingList,
  training_library: getTrainingList,
  training_not_estimated: getNotEstimatedTrainingList,
  training_estimated: getEstimatedTrainingList,
  training: getOneTraining,
};

const creators = {
  age: createAge,
  theme: createTheme,
  section: createSections,
  category: createCategory,
  exercise: createExercise,
  skill: createSkill,
  training_library: createTraining,
  training_templates: createTraining,
  training: estimateTraining,
};

const deleters = {
  age: deleteAge,
  theme: deleteTheme,
  section: deleteSections,
  category: deleteCategory,
  exercise: deleteExercise,
  skill: deleteSkill,
  training: deleteTraining,
};

const updaters = {
  age: updateAge,
  theme: updateTheme,
  section: updateSections,
  category: updateCategory,
  exercise: updateExercise,
  skill: updateSkill,
  training_library: updateTraining,
  training_templates: updateTraining,
};

const trainingControlProcess = createSlice({
  name: 'trainingControlProcess',
  initialState,
  reducers: {
    loading: (state, {payload}) => {
      const {name} = payload;
      const currentState = state[name];
      currentState.isLoading = true;
      currentState.data = initialState[name].data || [];
      currentState.isError = false;
      currentState.error = null;
      currentState.isLoad = false;
    },
    updateData: (state, {payload}) => {
      const {name, data} = payload;
      const currentState = state[name];
      currentState.data = data;
      currentState.isLoading = false;
      currentState.isLoad = true;
    },
    onError: (state, {payload}) => {
      const {name, err} = payload;
      const currentState = state[name];
      currentState.error = err;
      currentState.isError = true;
      currentState.isLoading = false;
    },
    formLoading: (state, {payload}) => {
      const {name} = payload;
      const currentState = state[name];
      currentState.form.isError = false;
      currentState.form.isLoading = true;
    },
    formLoadingEnd: (state, {payload}) => {
      const {name} = payload;
      const currentState = state[name];
      currentState.form.isLoading = false;
    },
    updateStep: (state, {payload}) => {
      state.step = payload;
    },
    updateTempData: (state, {payload}) => {
      state.tempData = payload;
    },
    updateTab: (state, {payload}) => {
      state.tab = payload;
    },
    updateSectionTab: (state, {payload}) => {
      state[payload.name].tab = payload.tab;
    },
    reset: (state, {payload}) => {
      state[payload.name] = initialState[payload.name];
    },
  },
});

export const {
  actions: {
    loading,
    updateData,
    onError,
    formLoadingEnd,
    formLoading,
    updateTab,
    updateTempData,
    updateStep,
    updateSectionTab,
    reset,
  },
  reducer,
} = trainingControlProcess;
export {reducer as trainingControlProcessReducer};

export const fetcherTrainingControl =
  (name, params = {}) =>
  (dispatch) => {
    if (name in fetchers) {
      dispatch(loading({name}));
      fetchers[name](params)
        .then((res) => {
          dispatch(updateData({name, data: res}));
        })
        .catch((err) => {
          dispatch(onError({name, err}));
        });
    } else {
      console.warn(`${name} нету в fetchers ${JSON.stringify(fetchers)}`);
    }
  };

export const updateTrainingControl =
  ({name, payload, connectores, onSuccess, id, skipFetch}) =>
  (dispatch) => {
    if (name in updaters) {
      dispatch(formLoading({name}));
      updaters[name](payload, id)
        .then(() => {
          onSuccess && onSuccess();
          if (connectores) {
            connectores.forEach((connector) => dispatch(fetcherTrainingControl(connector)));
          }

          !skipFetch && dispatch(fetcherTrainingControl(name));
        })
        .finally(() => dispatch(formLoadingEnd({name})));
    }
  };

export const createTrainingControl =
  ({name, payload, connectores, onSuccess, skipFetch}) =>
  (dispatch) => {
    if (name in creators) {
      dispatch(formLoading({name}));
      creators[name](payload)
        .then(() => {
          onSuccess && onSuccess();
          if (connectores) {
            connectores.forEach((connector) => dispatch(fetcherTrainingControl(connector)));
          }

          !skipFetch && dispatch(fetcherTrainingControl(name));
        })
        .finally(() => dispatch(formLoadingEnd({name})));
    }
  };

export const deleteTrainingControl =
  ({name, payload, connectores, onSuccess, skipFetch}) =>
  (dispatch) => {
    if (name in deleters) {
      dispatch(formLoading({name}));
      deleters[name](payload)
        .then(() => {
          onSuccess && onSuccess();
          if (connectores) {
            connectores.forEach((connector) => dispatch(fetcherTrainingControl(connector)));
          }

          !skipFetch && dispatch(fetcherTrainingControl(name));
        })
        .finally(() => dispatch(formLoadingEnd({name})));
    }
  };
