import axios from 'axios';
import Parse from 'parse';
import validator from 'validator';

import {API_ENDPOINT} from '../constants/config';
import * as config from '../constants/config.js';
import AuthHelper from '../helpers/AuthHelper';

const ParseAPI = {
  initParse() {
    Parse.initialize(config.PARSE_APP_ID, config.PARSE_JS_KEY);
    Parse.serverURL = config.PARSE_SERVER_URL;
  },

  transformUser(u) {
    if (u === undefined) {
      return undefined;
    }

    return {
      id: u.id,
      timestamp: new Date(u.createdAt).getTime(),
      updatedTimestamp: new Date(u.updatedAt).getTime(),

      email: u.get('email'),
      password: u.get('password'),

      phone: u.get('phone'),
      gender: u.get('gender'),

      name: u.get('name'), // for franchisee

      avatar: u.get('avatar'),
      firstName: u.get('firstName'),
      lastName: u.get('lastName'),

      userRole: u.get('userRole'),

      franchiseeId: u.get('franchiseeId'),
      schoolId: u.get('schoolId'),

      birthdayTimestamp: u.get('birthdayTimestamp'),

      parentName: u.get('parentName'),
      parentPhone: u.get('parentPhone'),
      parentEmail: u.get('parentEmail'),
      parentSkype: u.get('parentSkype'),

      height: u.get('height'),
      weight: u.get('weight'),
      hand: u.get('hand'),
      hockeyRole: u.get('hockeyRole'),
      passwordConfirmed: u.get('passwordConfirmed') === undefined ? true : u.get('passwordConfirmed'),
    };
  },

  fetchCurrentUserAsPromise() {
    let currentUser = Parse.User.current();
    if (currentUser === undefined || currentUser?.id === undefined) {
      return Promise.resolve(undefined);
    }

    return new Promise((resolve, reject) => {
      currentUser.fetch().then(
        (user) => {
          axios
            .get(`${API_ENDPOINT}/user/${user.id}`)
            .then((d) => d.data)
            .then((u) => {
              resolve(u);
            })
            .catch((exc) => reject(exc));
        },
        (err) => {
          reject(err);
        },
      );
    });
  },

  logIn(email, password, success, error) {
    if (validator.isEmail(email) === false) {
      error({message: 'email is not valid'});
      return;
    }
    if (password === undefined || password.trim() === '') {
      error({message: 'password is not defined'});
      return;
    }
    Parse.User.logIn(email, password)
      .then((pUser) => {
        AuthHelper.setToken(pUser.attributes.sessionToken);
        const token = pUser.attributes.sessionToken ? pUser.attributes.sessionToken : '';
        axios.defaults.headers.Authorization = `Bearer ${token}`;
        success(this.transformUser(pUser));
      })
      .catch((err) => {
        error(err);
      });
  },

  signUp: function (data, success, error) {
    if (data === undefined) {
      data = {};
    }

    if (validator.isEmail(data.email) === false) {
      error({message: 'Incorrect email'});
      return;
    }

    if (data.password === undefined || data.password.trim() === '') {
      error({message: 'Incorrect email'});
      return;
    }

    data.email = data.email.toLowerCase();
    var user = new Parse.User();

    for (var key in data) {
      user.set(key, data[key]);
    }
    user.set('username', data.email);
    user.signUp(null, {
      success: function (u) {
        AuthHelper.setToken(u.attributes.sessionToken);
        const token = u.attributes.sessionToken ? u.attributes.sessionToken : '';
        axios.defaults.headers.Authorization = `Bearer ${token}`;
        success(this.transformUser(u));
      },
      error: function (user, err) {
        error(err);
      },
    });
  },

  logOutAsPromise: function () {
    return Parse.User.logOut();
  },

  logInAsPromise(email, password) {
    //TODO пофиксить правило
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await this.logIn(
        email,
        password,
        (user) => {
          const token = AuthHelper.getToken() ? AuthHelper.getToken() : '';
          axios
            .get(`${API_ENDPOINT}/user/${user.id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((d) => d.data)
            .then((u) => {
              resolve(u);
            })
            .catch((exc) => reject(exc));
        },
        (err) => {
          reject(err);
        },
      );
    });
  },

  signUpAsPromise(data) {
    var promise = new Promise(function (resolve, reject) {
      this.signUp(
        data,
        function (user) {
          axios
            .get(`${API_ENDPOINT}/user/${user.id}`)
            .then((d) => d.data)
            .then((u) => {
              resolve(u);
            })
            .catch((exc) => reject(exc));
        },
        function (error) {
          reject(error);
        },
      );
    });
    return promise;
  },

  runCloudFunctionAsPromise(functionName, data) {
    var promise = new Promise(function (resolve, reject) {
      this.runCloudFunction(
        functionName,
        data,
        function (res) {
          resolve(res);
        },
        function (err) {
          reject(err);
        },
      );
    });
    return promise;
  },
};

export default ParseAPI;
