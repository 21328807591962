import date from '@helpers/date';
import {useRoleAccess} from '@hooks/useRoleAccess';
import {East} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {useMediaQuery} from '@mui/material';
import {userRoles} from '@src/constants';
import {Box, Button, Divider, Stack, Typography} from '@ui/MUI';
import React, {useMemo} from 'react';
import {useHistory} from 'react-router';

import {Container, NoEvents, Text, Wrapper} from './Date';

const SmallEvent = ({name, openTraining, id}) => {
  return (
    <Stack flex="1" justifyContent="space-between" py={2}>
      <Wrapper>
        <Text flex="1" px={2} variant="body2">
          {name}
        </Text>
      </Wrapper>
      <div>
        <Divider />
        <Stack alignItems="center" mt={2}>
          <Button color="primary" endIcon={<East />} size="small" variant="text" onClick={() => openTraining(id)}>
            Смотреть
          </Button>
        </Stack>
      </div>
    </Stack>
  );
};

const Events = ({events, openTraining}) => {
  return (
    <>
      {events.map((event, index) => (
        <>
          <Box key={index} pt={1} px={2}>
            <Stack direction="row" justifyContent="space-between" mb={1}>
              <Typography variant="subtitle2">{event.fullTime}</Typography>
            </Stack>
            <Divider />
            <Box mt="2px">
              <Button
                color="primary"
                endIcon={<East />}
                size="small"
                variant="text"
                onClick={() => openTraining(event.id)}
              >
                Смотреть
              </Button>
            </Box>
          </Box>
          {events.length - 1 !== index && <Divider />}
        </>
      ))}
    </>
  );
};

const Date = ({isStudent, isGame, isNoEvents, isNotInCurrentMount, events, currentDate}) => {
  const isMdResolution = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isOnlyOneEvent = events.length === 1;
  const history = useHistory();
  const {formatedDate, formatedEvents} = useMemo(() => {
    return {
      isSunday: date(currentDate).format('d') === 6,
      formatedDate: !isMdResolution ? date(currentDate).format('DD.MM') : date(currentDate).format('dd DD.MM'),
      formatedEvents: events
        .map((event) => ({
          ...event,
          fullTime: `${event.startTime} - ${event.endTime}`,
        }))
        .slice(0, 2),
    };
  }, [isMdResolution]);

  const openTraining = (id) => {
    history.push(`/training_control_process/training/${id}`);
  };

  const canEdit = useRoleAccess([userRoles.teacher, userRoles.methodist]);

  return (
    <Container isGame={isGame} isNoEvents={isNoEvents} isNotInCurrentMount={isNotInCurrentMount}>
      <Stack direction="row" justifyContent="space-between" p={2}>
        <Typography sx={{textTransform: 'uppercase'}} variant="subtitle2">
          {formatedDate}
        </Typography>
        {isOnlyOneEvent && (
          <Typography variant="subtitle2">
            {events[0]?.startTime}-{events[0]?.endTime}
          </Typography>
        )}
      </Stack>
      <Divider />
      {isOnlyOneEvent ? (
        <SmallEvent {...events[0]} openTraining={openTraining} />
      ) : (
        <Events events={formatedEvents} openTraining={openTraining} />
      )}
      {(isNoEvents || isGame) && (
        <NoEvents>
          <Stack direction="row" justifyContent="space-between" mb={1}>
            <Typography variant={isGame ? 'subtitle2' : 'body2'}>{isNoEvents ? 'Не назначено' : 'Игра'}</Typography>
          </Stack>
          {isNoEvents && !isStudent && (
            <Box textAlign="center" width="100%">
              <Divider />
              {canEdit && (
                <Box mt={2}>
                  <Button
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                    variant="text"
                    onClick={() =>
                      history.push(`/training_control_process/1?date=${date(currentDate).format('DD.MM.YYYY')}`)
                    }
                  >
                    Добавить
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </NoEvents>
      )}
    </Container>
  );
};

export default Date;
