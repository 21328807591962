import ChildrenField from '@common/ChildrenField';
import TextFieldDefault from '@ui/MUI/TextField';
import TooltipDefault from '@ui/MUI/Tooltip';
import React from 'react';

// мейби будет еще контент поэтому вынес в отдельный компонент
const FormContentBottom = () => {
  return (
    <TooltipDefault
      forceElement
      placement="bottom"
      title="Укажите моменты, на которые надо обращать внимание при выполнении упражнения"
    >
      <ChildrenField name="highlights">
        <TextFieldDefault label="Важные моменты" />
      </ChildrenField>
    </TooltipDefault>
  );
};

export default FormContentBottom;
