import React from 'react';

function PencilWhite() {
  return (
    <React.Fragment>
      <g clipPath="url(#clip0_9297_14194)">
        <path
          d="M4.32288 1.17572L0.471379 5.02749C0.452002 5.04693 0.438011 5.07147 0.431348 5.09784L0.00444663 6.81133C-0.00832324 6.86291 0.00683404 6.91777 0.0444773 6.95541C0.0729597 6.98389 0.111769 6.99966 0.151467 6.99966C0.163626 6.99966 0.176062 6.99816 0.188166 6.99511L1.90166 6.56815C1.92836 6.56149 1.95263 6.54755 1.972 6.52817L5.82384 2.67668L4.32288 1.17572Z"
          fill="white"
        />
        <path
          d="M6.77803 0.650558L6.34929 0.221823C6.06275 -0.0647212 5.56334 -0.0644436 5.27713 0.221823L4.75195 0.746998L6.25285 2.2479L6.77803 1.72273C6.92116 1.57965 7 1.38921 7 1.18667C7 0.984128 6.92116 0.793691 6.77803 0.650558Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9297_14194">
          <rect fill="white" height="7" width="7" />
        </clipPath>
      </defs>
    </React.Fragment>
  );
}
export default PencilWhite;
