import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import styled from 'styled-components';

import {getWindowDimensions} from '../../helpers/CommonHelper';
import * as dashboardsActions from '../../redux/actions/DashboardsActions';
import ChessTemplate from '../templates/ChessTemplate';
import Tabs, {TabItem} from '../ui/Tabs';
import ParentProfilePanel from './panels/ParentProfilePanel';

export default function ParentProfileApp() {
  const dispatch = useDispatch();
  const isMobile = getWindowDimensions().width <= 768;
  const isEdit = true;
  const {url} = useRouteMatch();

  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const groups = useSelector((state) => state.groups.groupsMap.toArray());
  const currentUser = useSelector((state) => state.users.currentUser);

  const allPlayers = useMemo(() => {
    if (dashboard) {
      const dashboardToArray = Object.keys(dashboard);
      const players = dashboardToArray.map((el) => dashboard[el]);
      return players.sort((a, b) => a.player.firstName.localeCompare(b.player.firstName));
    }
  }, [dashboard]);

  useEffect(() => {
    dispatch(dashboardsActions.getDashboard());
  }, []);

  const tabs = useMemo(() => {
    return allPlayers?.map((el, index) => {
      return {
        label: `${el.player.firstName} ${el.player.lastName}`,
        content: (
          <TabItem>
            <HeadLine>
              <ParentProfilePanel
                groups={groups}
                hockeyRole={el.player.hockeyRole}
                id={el.player.id}
                isMobile={isMobile}
                nextGame={el.nextGame}
                pastGames={el.pastGames}
                schedule={el.schedule}
                student={el.player}
              />
            </HeadLine>
          </TabItem>
        ),
        link: index === 0 ? '' : el.player.id,
      };
    });
  }, [allPlayers]);

  return (
    <Wrapper>
      <ChessTemplate active="index">
        <div className={'StudentPanelContainer'}>
          <StyledPageTitle withOutBreadCrumbs={isMobile}>Рабочий стол родителя</StyledPageTitle>
          <Tabs currentUser={currentUser} isEdit={isEdit} isMobile={isMobile} tabs={tabs} url={url} />
        </div>
      </ChessTemplate>
    </Wrapper>
  );
}

const StyledPageTitle = styled((props) => <PageTitleDefault {...props} />)`
  display: flex;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    margin-left: 0;
    margin-top: 0;
  }
`;
const Wrapper = styled.div`
  .StudentPanelContainer {
    position: relative;

    .TabNamesItems {
      border-bottom: none;
      margin-bottom: 0;
      @media screen and (max-width: 480px) {
        padding-bottom: 40px;
      }
      @media screen and (max-width: 1440px) {
        padding-right: 16px;
        padding-left: 16px;
      }
      .TabName {
        color: #fffaed;
      }
    }
    .Tab .ContentPlaceholder .StudentLessonsCalendarWrapper {
      padding-top: 0;
    }
  }

  .StudentPanelContainer > .StudentTitle {
    margin: 0;
    position: absolute;
    margin-top: 20px;
    margin-left: 20px;
    z-index: 101;
    font-family: Bebas Neue;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    color: #fffaed;
    text-transform: uppercase;
    @media screen and (max-width: 991px) {
      position: absolute;
      margin-top: 0;
      margin-left: 0;
      top: 20px;
      left: 20px;
    }
    @media screen and (max-width: 576px) {
      width: fit-content;
      left: 50%;
      transform: translate(-50%);
    }
  }
  .StudentPanelContainer .StudentCalendar {
    max-width: 70%;
    max-height: 60%;
  }
`;
const HeadLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  position: relative;
`;
