import React, {useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {Container, Wrapper} from '../styles';
import DefaultFilter from './components/DefaultFilter';
import GroupFilter from './components/filters/GroupFilter';
import SchoolFilter from './components/filters/SchoolFilter';
import StudentFilter from './components/filters/StudentFilter';
import defaultFilters from './constants';
import handleErrors from './helpers/handleErrors';

const SpecialFilters = {
  school: SchoolFilter,
  group: GroupFilter,
  student: StudentFilter,
};

const Filters = (props) => {
  const {filters, onChange = () => {}, apiRef, withReduxStore, filtersName, className} = props;

  useEffect(() => {
    handleErrors(withReduxStore, filtersName, apiRef);
  }, [withReduxStore, filtersName, apiRef]);

  const reduxFilters = useSelector((state) => {
    if (withReduxStore) {
      return state.form[filtersName];
    }
  });

  const {formated, keys} = useMemo(() => {
    if (filters && filters.length) {
      const formated = filters.reduce((prev, curr) => {
        const {
          name,
          options,
          value,
          label,
          isLoading,
          placeholder,
          isDisabled,
          renderFilter,
          className: classNameField,
          isVisable,
        } = curr;
        const reduxValue = reduxFilters && reduxFilters[curr.name];
        const defaultFilter = defaultFilters[name];
        return {
          ...prev,
          [name]: {
            options: options || defaultFilter?.options || [],
            value: reduxValue || value || defaultFilter?.value || null,
            label: label !== null ? label || defaultFilter?.label || 'Опция' : label,
            isDisabled: isDisabled || false,
            isLoading: isLoading || false,
            className: classNameField || className,
            placeholder: placeholder || 'Выберите',
            renderFilter,
            isVisable: Object.prototype.hasOwnProperty.call(curr, 'isVisable') ? isVisable : true,
          },
        };
      }, {});

      return {formated, keys: Object.keys(formated)};
    }

    return {formated: {}, keys: []};
  }, [filters]);

  useEffect(() => {
    // инциализация сторы в редаксе
    if (withReduxStore) {
      // const formatedForRedux = keys.reduce((prev, curr) => {
      //   return {...prev, [curr]: formated[curr]?.value};
      // }, {});
      // временно убираю поддержку редакса
      //   dispatch({
      //     type: UPDATE_FORM_VALUE,
      //     payload: { values: formatedForRedux, filtersName: filtersName },
      //   });
    }
  }, [withReduxStore, filtersName]);

  const methods = useForm({defaultValues: formated});

  const handelChange = (name, select) => {
    updateValue(name, select);
  };

  const updateValue = (name, value) => {
    updateFilters('value', name, value);
    const filters = methods.getValues();
    const keys = Object.keys(filters);
    const formatedValues = keys.reduce((prev, curr) => {
      return {...prev, [curr]: filters[curr]?.value?.value || filters[curr]?.value};
    }, {});
    // синхронизация с редакс сторой
    if (withReduxStore) {
      // const formatedValuesForRedux = keys.reduce((prev, curr) => {
      //   return {...prev, [curr]: filters[curr]?.value};
      // }, {});
      // временно убираю поддержку редакса
      // dispatch({
      //   type: UPDATE_FORM_VALUE,
      //   payload: { values: formatedValuesForRedux, filtersName: filtersName },
      // });
    }

    onChange(formatedValues, filters);
  };

  const updateOptions = (name, options) => {
    return updateFilters('options', name, options);
  };

  const updateLoading = (name, isLoading) => {
    return updateFilters('isLoading', name, isLoading);
  };

  const updateDisable = (name, isDisable) => {
    return updateFilters('isDisabled', name, isDisable);
  };

  const updateFilters = (query, name, value) => {
    const filter = methods.getValues(name);
    methods.setValue(name, {...filter, [query]: value});
    // делаем setValue асинхронным, иногда вызывается сразу 2 метода например updateOptions и updateValue
    // из-за этого достаётся filter без новых options
    // так js будет синхронным выполнит функции друг за другом
    return Promise.resolve();
  };

  const updateVisability = (name, isVisable) => {
    updateFilters('isVisable', name, isVisable);
  };

  const actions = {
    updateFilters,
    updateDisable,
    updateLoading,
    updateOptions,
    updateValue,
    updateVisability,
  };

  useEffect(() => {
    if (apiRef) {
      apiRef.current = actions;
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <Wrapper className={className}>
        <Container className="filters-container">
          {keys.map((key) => {
            const SpecialFilter = SpecialFilters[key];
            return SpecialFilter ? (
              <SpecialFilter
                actions={actions}
                filtersName={filtersName}
                key={key}
                name={key}
                value={formated[key].value}
                onChange={handelChange}
              />
            ) : (
              <DefaultFilter
                actions={actions}
                filtersName={filtersName}
                key={key}
                name={key}
                withReduxStore={withReduxStore}
                onChange={handelChange}
              />
            );
          })}
        </Container>
      </Wrapper>
    </FormProvider>
  );
};

export default Filters;
