import AcceptInput from '@common/AcceptInput';
import Stack from '@ui/MUI/Stack';
import React from 'react';
import styled from 'styled-components';

const Container = styled(Stack)`
  padding-top: 32px;
  padding-left: ${(props) => props.side === 'right' && '64px'};
  padding-right: ${(props) => props.side === 'left' && '64px'};
`;

const SectionBotttom = ({side, items, onAccept, onDelete}) => {
  return (
    <Container side={side} spacing={4}>
      {items.map((item) => (
        <AcceptInput
          initialValue={item.name}
          key={item.id}
          onAccept={(isNew, value) => onAccept(isNew, value, item.id)}
          onDelete={(isNew) => onDelete(isNew, item.id)}
        />
      ))}
    </Container>
  );
};

export default SectionBotttom;
