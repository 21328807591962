export const mock = [
  {
    playerNumber: 43,
    playerFirstName: 'Данил',
    playerLastName: 'Сторчак',
    overallTOI: '22:58',
    firstHalfTOI: '07:29',
    secondHalfTOI: '07:55',
    thirdHalfTOI: '07:33',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: false,
    isSecondLowest: false,
    isThirdLowest: false,
  },
  {
    playerNumber: 42,
    playerFirstName: 'Даниил',
    playerLastName: 'Моружов',
    overallTOI: '22:20',
    firstHalfTOI: '07:35',
    secondHalfTOI: '07:40',
    thirdHalfTOI: '07:04',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: false,
    isSecondLowest: false,
    isThirdLowest: false,
  },
  {
    playerNumber: 44,
    playerFirstName: 'Владимир',
    playerLastName: 'Малинин',
    overallTOI: '21:24',
    firstHalfTOI: '07:19',
    secondHalfTOI: '06:20',
    thirdHalfTOI: '07:44',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: false,
    isSecondLowest: false,
    isThirdLowest: false,
  },
  {
    playerNumber: 53,
    playerFirstName: 'Илья',
    playerLastName: 'Григорьев',
    overallTOI: '21:12',
    firstHalfTOI: '08:57',
    secondHalfTOI: '05:57',
    thirdHalfTOI: '06:17',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: false,
    isSecondLowest: false,
    isThirdLowest: false,
  },
  {
    playerNumber: 52,
    playerFirstName: 'Лев',
    playerLastName: 'Панжаев',
    overallTOI: '20:55',
    firstHalfTOI: '07:03',
    secondHalfTOI: '08:08',
    thirdHalfTOI: '05:43',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: false,
    isSecondLowest: false,
    isThirdLowest: false,
  },
  {
    playerNumber: 62,
    playerFirstName: 'Александр',
    playerLastName: 'Белодедов',
    overallTOI: '20:25',
    firstHalfTOI: '05:04',
    secondHalfTOI: '08:37',
    thirdHalfTOI: '06:44',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: true,
    isSecondLowest: false,
    isThirdLowest: false,
  },
  {
    playerNumber: 59,
    playerFirstName: 'Данила',
    playerLastName: 'Пугачев',
    overallTOI: '19:31',
    firstHalfTOI: '06:31',
    secondHalfTOI: '06:31',
    thirdHalfTOI: '06:28',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: false,
    isSecondLowest: false,
    isThirdLowest: false,
  },
  {
    playerNumber: 50,
    playerFirstName: 'Данил',
    playerLastName: 'Фиськов',
    overallTOI: '19:30',
    firstHalfTOI: '07:27',
    secondHalfTOI: '05:59',
    thirdHalfTOI: '06:02',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: false,
    isSecondLowest: false,
    isThirdLowest: false,
  },
  {
    playerNumber: 58,
    playerFirstName: 'Родион ',
    playerLastName: 'Сафин',
    overallTOI: '19:01',
    firstHalfTOI: '06:37',
    secondHalfTOI: '04:53',
    thirdHalfTOI: '07:29',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: false,
    isSecondLowest: true,
    isThirdLowest: false,
  },
  {
    playerNumber: 49,
    playerFirstName: 'Денис',
    playerLastName: 'Пилия',
    overallTOI: '18:10',
    firstHalfTOI: '06:29',
    secondHalfTOI: '06:21',
    thirdHalfTOI: '05:19',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: false,
    isSecondLowest: false,
    isThirdLowest: true,
  },
  {
    playerNumber: 51,
    playerFirstName: 'Никита',
    playerLastName: 'Чемоданов',
    overallTOI: '17:34',
    firstHalfTOI: '05:00',
    secondHalfTOI: '06:44',
    thirdHalfTOI: '05:49',
    overTimeTOI: '00:00',
    isOverallLowest: false,
    isFirstLowest: true,
    isSecondLowest: false,
    isThirdLowest: false,
  },
  {
    playerNumber: 41,
    playerFirstName: 'Станислав ',
    playerLastName: 'Смагин',
    overallTOI: '17:21',
    firstHalfTOI: '05:39',
    secondHalfTOI: '06:30',
    thirdHalfTOI: '05:11',
    overTimeTOI: '00:00',
    isOverallLowest: true,
    isFirstLowest: false,
    isSecondLowest: false,
    isThirdLowest: true,
  },
  {
    playerNumber: 55,
    playerFirstName: 'Артемий',
    playerLastName: 'Иваненко',
    overallTOI: '16:25',
    firstHalfTOI: '05:20',
    secondHalfTOI: '05:44',
    thirdHalfTOI: '05:20',
    overTimeTOI: '00:00',
    isOverallLowest: true,
    isFirstLowest: false,
    isSecondLowest: true,
    isThirdLowest: true,
  },
  {
    playerNumber: 87,
    playerFirstName: 'Глеб',
    playerLastName: 'Бурлака',
    overallTOI: '15:25',
    firstHalfTOI: '04:51',
    secondHalfTOI: '05:01',
    thirdHalfTOI: '05:32',
    overTimeTOI: '00:00',
    isOverallLowest: true,
    isFirstLowest: true,
    isSecondLowest: true,
    isThirdLowest: false,
  },
  {
    playerNumber: 88,
    playerFirstName: 'Никита',
    playerLastName: 'Коротенко',
    overallTOI: '12:19',
    firstHalfTOI: '04:14',
    secondHalfTOI: '04:06',
    thirdHalfTOI: '03:59',
    overTimeTOI: '00:00',
    isOverallLowest: true,
    isFirstLowest: true,
    isSecondLowest: true,
    isThirdLowest: true,
  },
  {
    playerNumber: 35,
    playerFirstName: 'Егор',
    playerLastName: 'Пронин',
    overallTOI: '02:44',
    firstHalfTOI: '01:05',
    secondHalfTOI: '00:54',
    thirdHalfTOI: '00:44',
    overTimeTOI: '00:00',
    isOverallLowest: true,
    isFirstLowest: true,
    isSecondLowest: true,
    isThirdLowest: true,
  },
];
