import React from 'react';

import ChessTemplate from '../../templates/ChessTemplate';
import GroupList from './components/GroupList';

export default function VideosGroupListApp() {
  return (
    <ChessTemplate active={'video'}>
      <GroupList />
    </ChessTemplate>
  );
}
