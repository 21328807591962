import useEvent from '@hooks/useEvent';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {updateStep} from '@slices/trainingControlProcess';
import React, {useMemo} from 'react';

import Exercises from '../components/Exercises';
import MainInfoBlock from '../components/MainInfoBlock';
import Preview from '../components/Preview';
import SelectableExercises from '../components/SelectableExercises';

const MAX_STEP = 2;
const MIN_STEP = 0;

const stepMap = {
  0: {
    content: [<MainInfoBlock key="0" />, <SelectableExercises key="1" />],
  },
  1: {content: [<MainInfoBlock key="0" />, <Exercises key="1" />]},
  2: {content: [<Preview key="0" />]},
};

const useStep = () => {
  const step = useStore((state) => state.trainingControlProcess.step);
  const currentStep = useMemo(() => stepMap[step], [step]);

  const dispatch = useStoreDispatch();

  const next = useEvent(() => {
    const nextStep = step + 1;
    if (nextStep > MAX_STEP) return;
    dispatch(updateStep(nextStep));
  });

  const prev = useEvent(() => {
    const nextStep = step - 1;
    if (nextStep < MIN_STEP) return;
    dispatch(updateStep(nextStep));
  });

  return useMemo(() => ({currentStep, next, prev, step}), [currentStep, next, prev, step]);
};

export default useStep;
