import {Button, Stack, Typography} from '@ui/MUI';
import React from 'react';

const buttons: {label: string; isOur: boolean}[] = [
  {
    label: 'Наша команда',
    isOur: true,
  },
  {
    label: 'Соперник',
    isOur: false,
  },
];
type VideoModalTeamPickProps = {
  onClick: (isOur: boolean) => boolean;
  label?: string;
  wrapperProps?: React.ComponentProps<typeof Stack> | object;
};
function VideoModalTeamPick({onClick, label = 'Смотреть все броски:', wrapperProps = {}}: VideoModalTeamPickProps) {
  return (
    <Stack alignItems="center" direction="row" flexWrap="wrap" gap={{xxs: 1, md: 3}} {...wrapperProps}>
      <Typography variant="subtitle2">{label}</Typography>
      <Stack alignItems="center" direction="row" gap={1}>
        {buttons.map(({label, isOur}, index) => (
          <Button color="primary" key={index} size="small" variant="text" onClick={() => onClick(isOur)}>
            {label}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
}

export default VideoModalTeamPick;
