import {useMediaQuery} from '@mui/material';
import Divider from '@ui/MUI/Divider';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

import Block from '../Block';

const AdditionalInfoBlock = ({highlights, description}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return (
    <Stack direction="row" flexWrap="wrap" spacing={5}>
      <Block flex={!isMobile ? '1' : '0 0 100%'} title="Описание">
        <TypographyDefault>{description || '-'}</TypographyDefault>
      </Block>
      {!isMobile && <Divider orientation="vertical" />}
      <Block
        flex={!isMobile ? '1' : '0 0 100%'}
        sx={{ml: isMobile && '0px !important', pt: isMobile && 4}}
        title="Важные моменты"
      >
        <TypographyDefault>{highlights || '-'}</TypographyDefault>
      </Block>
    </Stack>
  );
};

export default AdditionalInfoBlock;
