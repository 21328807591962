import React, {useState} from 'react';
import styled from 'styled-components';

import {DARK_SIDE} from './Colors';
/**@deprecated не использовать этот компонент**/
export default function OldTabs(props) {
  const [selectedTabNumber, setSelectedTabNumber] = useState(0);
  let tabs = !props.tabs ? [] : props.tabs;
  const {extraTopButton = null, safeMode = false} = props;
  const goodTabs = tabs.filter((a) => a !== undefined);
  let selectedTab = goodTabs.length === 0 ? undefined : goodTabs[selectedTabNumber];
  const {isCalendarTabs} = props;
  if (goodTabs.length === 0) {
    return null;
  }
  if (goodTabs.length === 1) {
    return goodTabs[0].content;
  }

  return (
    <Wrapper className={selectedTab.label === 'Рекомендации' ? 'Wrapper Wrapper_recommendations' : 'Wrapper'}>
      <TabNamesItems
        className={isCalendarTabs ? 'TabNamesItems TabItemsBlock CalendarTabs' : 'TabNamesItems TabItemsBlock'}
      >
        {tabs.header ? <h1>{tabs.header}</h1> : null}
        {goodTabs.map((tb, k) => {
          let {label} = tb;
          return (
            <TabName
              className={selectedTabNumber === k ? 'TabName tab-active' : 'TabName'}
              key={k}
              selected={+selectedTabNumber === +k}
              onClick={() => {
                setSelectedTabNumber(k);
              }}
            >
              {label}
            </TabName>
          );
        })}
        {!extraTopButton ? null : <ExtraPlaceholder>{extraTopButton}</ExtraPlaceholder>}
      </TabNamesItems>

      {safeMode ? null : (
        <div className={'Tab'}>
          {!selectedTab ? null : (
            <ContentPlaceholder className={'ContentPlaceholder'} key={`num_${selectedTabNumber}`}>
              {selectedTab.content}
            </ContentPlaceholder>
          )}
        </div>
      )}

      {!safeMode ? null : (
        <div>
          {tabs.map((a, k) => {
            const isVisible = selectedTabNumber === k;
            return (
              <ContentPlaceholder
                className={'ContentPlaceholder'}
                key={k}
                style={{display: isVisible === true ? 'block' : 'none'}}
              >
                {a.content}
              </ContentPlaceholder>
            );
          })}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  &.Wrapper {
    & ~ .BottomPanel {
      .Buttons {
        .DeleteRecommendationButton {
          display: none;
          transition: all 0.33s;
        }
      }
    }
    &_recommendations {
      & ~ .BottomPanel {
        .Buttons {
          .DeleteRecommendationButton {
            display: block;
          }
        }
      }
    }
  }
`;

const TabNamesItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 7px;
  background: transparent;
  // position: sticky;
  // top: 0;
  // border: none;
  z-index: 100;

  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 10px 20px 10px;
    height: auto;
    justify-content: flex-end;
  }
  @media screen and (max-width: 991px) {
    position: relative;
    top: auto;
    padding: 48px 20px 10px;
    height: auto;
    justify-content: flex-start;
    align-items: flex-end;
  }
  @media screen and (max-width: 576px) {
    position: relative;
    top: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

const TabName = styled.div`
  display: inline-block;
  vertical-align: top;
  cursor: ${(props) => (props.selected === true ? 'default' : 'pointer')};
  margin-right: 5px;
  border-bottom: ${(props) => (props.selected === true ? `2px solid ${DARK_SIDE}` : 'none')};
  white-space: nowrap;
  //font-weight: ${(props) => (props.selected === true ? 'bold' : 'normal')};
  :hover {
    opacity: 0.8;
  }
  :last-of-type {
    margin-right: 20px;
  }
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #fffaed;
  padding: 2px 10px;

  @media screen and (max-width: 777px) {
    margin-top: 10px;
  }
  @media screen and (max-width: 576px) {
    margin-right: 0;
    margin-left: 0;
    :last-of-type {
      margin-right: 0;
    }
  }
`;

const ExtraPlaceholder = styled.div`
  margin-left: auto;
`;

const ContentPlaceholder = styled.div`
  width: calc(100% - 4px);
`;

export const TabItem = styled.div`
  // width: 100%;
  // padding-top: 30px;
`;
