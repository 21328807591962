export const defaultValues = {
  type: '',
  category: '',
  schools: [],
  groups: [],
  memberRoles: [],
  students: [],
  teachers: [],
  name: '',
  description: '',
  tags: [],
  storage: '',
};

export const cardStylesBySectionType = {
  links_and_files: {
    width: 200,
    height: 180,
    padding: '12px 16px',
    border: '1px solid #E0E0E0',
    gap: 3,
    borderRadius: '12px',
  },
  images: {
    width: 144,
    height: 260,
    gap: '40px',
  },
  video: {
    width: 260,
    gap: 3,
  },
};

export const typeColors = {
  study: '#FFE194',
  psychology: '#D6B1DF',
  nutrition: '#81C280',
  medicine: '#89A9D9',
  sport: '#D99493',
  other: '#C2C2C2',
};

export const maxItemsLimit = {
  links_and_files: {
    xxs: 4,
    xs: 4,
    sm: 3,
    md: 4,
    lg: 5,
    xl: 7,
    xxl: 10,
  },
  images: {
    xxs: 4,
    xs: 4,
    sm: 4,
    md: 5,
    lg: 6,
    xl: 8,
    xxl: 12,
  },
  video: {
    xxs: 4,
    xs: 4,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
    xxl: 8,
  },
  video_analytics: {
    xxs: 999,
    xs: 999,
    sm: 999,
    md: 999,
    lg: 999,
    xl: 999,
    xxl: 999,
  },
};

export const sectionLimit = {
  links_and_files: {
    sm: 9,
    md: 12,
    lg: 15,
    xl: 21,
    xxl: 30,
  },
  images: {
    sm: 12,
    md: 15,
    lg: 18,
    xl: 27,
    xxl: 39,
  },
  video: {
    sm: 6,
    md: 9,
    lg: 12,
    xl: 15,
    xxl: 24,
  },
};

export const sectionGaps = {
  links_and_files: 3,
  images: 5,
  video: '32px 16px',
};

export const defaultPage = {
  title: 'Материалы',
  breadCrumbs: [
    {label: 'Главная', path: '/'},
    {label: 'Материалы', path: null},
  ],
};

export const sectionTypesFilters = {
  links_and_files: ['video', 'image'],
};

export const sectionThemeFilters = {
  images: ['video_analytics'],
  video: ['video_analytics'],
};
