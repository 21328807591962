import eagle from '@assets/img/logo.svg';
import TeamLogo from '@common/Statistics/PlayerStatistics/TeamLogo';
import {Score, TeamName} from '@common/Statistics/PlayerStatistics/Throwins/Metrics/Throwins';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {longDash, TEAM_COLORS} from '@src/constants';
import {Box, Divider} from '@ui/MUI';
import Stack from '@ui/MUI/Stack';
import PropTypes from 'prop-types';
import React, {memo, useMemo} from 'react';

Metrics.propTypes = {
  weAtHome: PropTypes.bool,
  statisticsIds: PropTypes.array,
  getter: PropTypes.func.isRequired,
  loader: PropTypes.func.isRequired,
  cleanup: PropTypes.func,
  filters: PropTypes.object,
  homeTeamNamePlaceholder: PropTypes.string,
};

function Metrics({
  weAtHome = false,
  statisticsIds = [],
  getter = () => {},
  loader = () => {},
  cleanup = () => {},
  filters = {},
  isMKC = false,
  homeTeamNamePlaceholder = 'Домашняя команда',
  ...props
}) {
  const dispatch = useStoreDispatch();
  const teamColors = useMemo(() => (weAtHome ? [...TEAM_COLORS].reverse() : TEAM_COLORS), [weAtHome]);

  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [statisticsIds]});

  const {data, isLoading} = useStore(getter);
  useDeepEffect(() => {
    statisticsIds?.length && dispatch(loader({statisticsIds, ...filters, cancelToken: newCancelToken()}, isCancel));
    return () => {
      dispatch(cleanup());
    };
  }, [loader, statisticsIds, filters]);
  return (
    <Stack alignItems="center" direction="column">
      <Stack backgroundColor="#fff" borderRadius="6px" overflow="hidden" pb={2} position="relative">
        <Stack
          alignItems="center"
          direction={{xxs: 'column', mobileM: 'row'}}
          justifyContent="center"
          pb={6}
          pt={4}
          px={{xxs: 2, xs: 4}}
          spacing={{xxs: 2, xs: 3}}
        >
          <Stack
            alignItems="center"
            direction={{xxs: 'column', sm: 'row'}}
            justifyContent="flex-end"
            spacing={{xxs: 0, sm: 1}}
            width={{xxs: 'auto', xs: '100%'}}
          >
            <TeamLogo src={isMKC && weAtHome ? eagle : data?.homeTeamLogo} width={40} />
            <TeamName
              bgc={teamColors[0]}
              isLoading={isLoading || props?.isLoading}
              minWidth={(isLoading || props?.isLoading) && {xxs: 50, xs: 100, sm: 200}}
              ml="auto"
              variant="h2"
            >
              {data?.homeTeamName || homeTeamNamePlaceholder}
            </TeamName>
          </Stack>
          <Stack alignItems="center" flex="1">
            <Stack direction="row" spacing={1}>
              <Score
                color={data?.home < data?.away && '#666666'}
                isLoading={isLoading || props?.isLoading}
                minWidth={40}
                percent={data?.homePercent ? data?.homePercent + '%' : ''}
              >
                {data?.home || 0}
              </Score>
              <Score>{longDash}</Score>
              <Score
                color={data?.away < data?.home && '#666666'}
                isLoading={isLoading || props?.isLoading}
                minWidth={40}
                percent={data?.awayPercent ? data?.awayPercent + '%' : ''}
              >
                {data?.away || 0}
              </Score>
            </Stack>
          </Stack>
          <Stack
            alignItems="center"
            direction={{xxs: 'column-reverse', sm: 'row'}}
            spacing={{xxs: 0, sm: 1}}
            width={{xxs: 'auto', xs: '100%'}}
          >
            <TeamName
              bgc={teamColors[1]}
              className="lastTeamName"
              isLoading={isLoading || props?.isLoading}
              minWidth={(isLoading || props?.isLoading) && {xxs: 50, xs: 100, sm: 200}}
              variant="h2"
            >
              {data?.awayTeamName || 'Cоперник'}
            </TeamName>
            <TeamLogo src={data?.awayTeamLogo} width={40} />
          </Stack>
        </Stack>
      </Stack>
      <Box maxWidth="70%" width="100%">
        <Divider />
      </Box>
    </Stack>
  );
}

export default memo(Metrics);
