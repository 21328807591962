import TeamsFields from '@components/apps/Tournaments/components/CreateTournamentModal/components/TeamsFields';
import {groupFieldsDefault} from '@components/apps/Tournaments/constants';
import AddIcon from '@mui/icons-material/Add';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {Box, Button, Divider, IconButton, Stack, Typography} from '@ui/MUI';
import React from 'react';
import {FormProvider, useFieldArray, useFormContext} from 'react-hook-form';

function GroupFields({options, isLoading}: {options: unknown[]; isLoading: boolean}) {
  const form = useFormContext();
  const {control} = form;
  const {fields, append, remove} = useFieldArray({
    control,
    rules: {minLength: 1, maxLength: 10},
    name: 'groups',
  });

  return (
    <FormProvider {...form}>
      <Box mt={4} width="100%">
        <Box mb={6}>
          {fields?.map((field, index) => {
            return (
              <React.Fragment key={field.id}>
                <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between" width="100%">
                  <Box mb={3} width="100%">
                    <Typography variant="subtitle1">{`Группа ${index + 1}`}</Typography>
                  </Box>
                  {index > 0 && (
                    <IconButton size="small" onClick={() => remove(index)}>
                      <CloseOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Stack>

                <TeamsFields index={index} isLoading={isLoading} options={options} />
                <Divider sx={{mt: 3, mb: 3, borderStyle: 'dashed', width: '100%'}} />
              </React.Fragment>
            );
          })}
        </Box>

        <Button
          color="primary"
          size="medium"
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => {
            append(groupFieldsDefault);
          }}
        >
          Добавить группу
        </Button>
      </Box>
    </FormProvider>
  );
}

export default GroupFields;
