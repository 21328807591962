import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import {ClickAwayListener} from '@mui/material';
import {selectIsTutorialBtnExpanded} from '@selectors/menu';
import {setTutorialBtnExpanded} from '@slices/menu';
import {Button} from '@ui/MUI';
import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

function TutorialButton() {
  const dispatch = useStoreDispatch();
  const isTutorialBtnExpanded = useStore(selectIsTutorialBtnExpanded);
  const [useAnimation, setUseAnimation] = useState(false);
  const setIsExpanded = useCallback((state) => {
    setUseAnimation(true);
    dispatch(setTutorialBtnExpanded(state));
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
      <StyledButton
        className={`${isTutorialBtnExpanded ? 'expand' : 'contract'} ${useAnimation ? 'animate' : ''}`}
        color="secondary"
        href="https://www.youtube.com/watch?v=2x8FSteZMKU"
        size="small"
        startIcon={!isTutorialBtnExpanded && <CastForEducationIcon color="inherit" fontSize="20px" />}
        target="_blank"
        variant="contained"
        onMouseEnter={() => setIsExpanded(true)}
      >
        <span className="text">Посмотреть видео инструкцию</span>
      </StyledButton>
    </ClickAwayListener>
  );
}

export default memo(TutorialButton);

const StyledButton = styled(Button)`
  box-shadow: rgba(0, 0, 0, 0.25) 2px 4px 6px 1px;
  z-index: 100;
  position: absolute;
  margin: ${({theme}) => theme.spacing(3)};
  right: 0;
  bottom: 0;
  & .text {
    overflow: hidden;
    white-space: nowrap;
  }
  @keyframes expand {
    from {
      border-radius: 50%;
      max-width: 0;
      overflow: hidden;
      white-space: nowrap;
    }
    to {
      max-width: 100%;
      border-radius: 4px;
      overflow: unset;
      white-space: break-spaces;
    }
  }
  @keyframes contract {
    from {
      overflow: hidden;
      white-space: nowrap;
      opacity: 1;
      border-radius: initial;
    }
    to {
      border-radius: 50%;
      overflow: auto;
      white-space: break-spaces;
      opacity: 0.7;
      padding: 0;
    }
  }
  &.expand {
    overflow: auto;
    max-width: 100%;
    border-radius: 4px;
    overflow: unset;
    white-space: break-spaces;
    &.animate {
      animation: 0.3s ease-in-out 1 forwards expand;
    }
    & .text {
      opacity: 1;
      max-width: inherit;
    }
  }
  &.contract {
    & .text {
      opacity: 0;
      max-width: 0;
    }
    & .MuiButton-startIcon {
      margin: 8px;
      transition: 0.3s ease-in-out;
    }
    border-radius: 50%;
    overflow: unset;
    white-space: break-spaces;
    opacity: 0.7;
    padding: 0;
    min-width: initial;
    &.animate {
      animation: 0.3s ease-in-out 1 forwards contract;
    }
  }
`;
