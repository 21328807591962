import React from 'react';

function Man() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M8 7.82505C9.77892 7.82505 11.2212 6.07337 11.2212 3.91255C11.2212 1.75168 10.7477 0 8 0C5.25235 0 4.77875 1.75168 4.77875 3.91255C4.77875 6.07337 6.22099 7.82505 8 7.82505Z"
          fill="#990011"
        />
        <path d="M1.91586 13.799C1.91531 13.6672 1.91476 13.7619 1.91586 13.799V13.799Z" fill="#990011" />
        <path d="M14.0839 13.9019C14.0856 13.8658 14.0845 13.6517 14.0839 13.9019V13.9019Z" fill="#990011" />
        <path
          d="M14.077 13.641C14.0173 9.8768 13.5257 8.80421 9.76377 8.12526C9.76377 8.12526 9.23422 8.80005 7.99993 8.80005C6.76565 8.80005 6.236 8.12526 6.236 8.12526C2.51509 8.7968 1.99358 9.85348 1.92501 13.5187C1.91938 13.818 1.91678 13.8337 1.91577 13.799C1.916 13.8641 1.91627 13.9845 1.91627 14.1945C1.91627 14.1945 2.81191 16 7.99993 16C13.1879 16 14.0836 14.1945 14.0836 14.1945C14.0836 14.0596 14.0837 13.9658 14.0838 13.9019C14.0828 13.9234 14.0808 13.8818 14.077 13.641Z"
          fill="#990011"
        />
      </g>
    </React.Fragment>
  );
}
export default Man;
