import {sectionThemeFilters, sectionTypesFilters} from '@components/apps/MaterialsModule/constants';
import {getOptionLabel, isMaterialOptionEqualToValue} from '@components/apps/MaterialsModule/helpers';
import {useCurrentBreakPoint, useMaterialsTypes} from '@components/apps/MaterialsModule/hooks';
import {Arrow, ButtonHide, ButtonWrapper, FiltersWrapper, FilterWrapper} from '@components/apps/MaterialsModule/styles';
import {Collapse} from '@mui/material';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import uniqBy from 'lodash/uniqBy';
import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

const Filters = ({filters, onChange}) => {
  const {data, isLoading, refetch} = useMaterialsTypes();
  const {type} = useParams();

  const breakpoint = useCurrentBreakPoint();

  const filteredData = data.filter((materialType) => !sectionTypesFilters[type]?.includes(materialType.slug));

  const allCategories = useMemo(() => {
    return uniqBy(filteredData.map((type) => type.categories).flat(), 'slug');
  }, [filteredData]).filter((materialTheme) => !sectionThemeFilters[type]?.includes(materialTheme.slug));

  const isFileAndLinkType = type === 'links_and_files';

  const categories = uniqBy(filters?.types?.map((type) => type.categories)?.flat() || [], 'slug')?.filter(
    (materialTheme) => !sectionThemeFilters[type]?.includes(materialTheme.slug),
  );

  const tags = filters?.category?.tags || [];

  const [isShowedMoreFilters, setIsShowedMoreFilters] = useState();

  useEffect(() => {
    if (!data.length && !isLoading) {
      refetch();
    }
  }, []);

  return (
    <FiltersWrapper alignItems="flex-end" breakpoint={breakpoint} direction="row" flexWrap="wrap" gap={4} mb={1} mt={4}>
      {isFileAndLinkType && (
        <FilterWrapper>
          <AutocompleteDefault
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isMaterialOptionEqualToValue}
            label="Тип материала"
            name="types"
            options={filteredData}
            value={filters.types}
            onChange={(_, v, name) => {
              onChange({...filters, [name]: v});
            }}
          />
        </FilterWrapper>
      )}
      <FilterWrapper>
        <AutocompleteDefault
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isMaterialOptionEqualToValue}
          label="Тема материала"
          multiple={false}
          name="category"
          options={isFileAndLinkType ? categories : allCategories}
          value={filters.category}
          onChange={(_, v, name) => {
            onChange({...filters, [name]: v, tags: []});
          }}
        />
      </FilterWrapper>
      {!!tags.length && (
        <FilterWrapper>
          {breakpoint === 'xxs' ? (
            <Collapse unmountOnExit in={isShowedMoreFilters} timeout="auto">
              <AutocompleteDefault
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isMaterialOptionEqualToValue}
                label="Теги"
                name="tags"
                options={tags}
                value={filters.tags}
                onChange={(_, v, name) => {
                  onChange({...filters, [name]: v});
                }}
              />
            </Collapse>
          ) : (
            <AutocompleteDefault
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isMaterialOptionEqualToValue}
              label="Теги"
              name="tags"
              options={tags}
              value={filters.tags}
              onChange={(_, v, name) => {
                onChange({...filters, [name]: v});
              }}
            />
          )}
        </FilterWrapper>
      )}
      {!!tags.length && breakpoint === 'xxs' && (
        <ButtonWrapper
          isShowedMoreFilters={isShowedMoreFilters}
          onClick={() => setIsShowedMoreFilters(!isShowedMoreFilters)}
        >
          <ButtonHide>{isShowedMoreFilters ? 'Скрыть' : 'Другие фильтры'}</ButtonHide>
          <Arrow rotate={!isShowedMoreFilters} />
        </ButtonWrapper>
      )}
    </FiltersWrapper>
  );
};

export default Filters;
