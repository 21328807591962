import {clearStatisticsFilesList, loadStatisticsFilesList} from '@actions/StatsActions';
import preview from '@assets/img/video-preview.jpg';
import ActionVideoModalForPersonalFile from '@common/Statistics/ActionsMap/ActionVideoModalForPersonalFile';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import Card from '@components/apps/TechnicalSkillsVideoEstimation/components/VideoList/components/Card';
import {mock} from '@components/apps/TechnicalSkillsVideoEstimation/components/VideoList/mock';
import {shallowEqual} from '@helpers/shallowEqual';
import {useSeasons} from '@hooks/seasonsHooks';
import useDeepMemo from '@hooks/useDeepMemo';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Theme} from '@mui/material';
import {clearStatisticsFilter} from '@slices/playerPersonalFile';
import {Autocomplete, Box, Stack, Typography} from '@ui/MUI';
import Pagination from '@ui/MUI/Pagination';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';

const limit = 12;

type Select = {
  value: string;
  label: string;
};

type Filters = {
  season?: Select | null;
  teamId?: Select | null;
};

type FiltersProps = {
  filter: Filters;
  setFilter: Dispatch<SetStateAction<Filters>>;
};

function Filters({filter, setFilter}: FiltersProps) {
  const dispatch = useStoreDispatch();
  const seasons = useSeasons(false, ({currentSeason}) => {
    setFilter({season: {value: currentSeason?.value, label: currentSeason?.label}});
  });

  const {group} = useStore((state) => state.users?.student, shallowEqual);

  useEffect(() => {
    return () => {
      dispatch(clearStatisticsFilter());
    };
  }, []);

  return (
    <Wrapper p={{xxs: 3, xs: 4}}>
      <Stack alignItems="center" direction={{xxs: 'column', sm: 'row'}} gap={3}>
        <Box width={{xxs: '100%', sm: 160}}>
          <Autocomplete
            isLoading={seasons?.isLoading}
            label="Сезон"
            multiple={false}
            options={seasons?.options}
            value={filter?.season}
            onChange={(_, newValue) => {
              setFilter({
                ...filter,
                season: newValue,
                teamId: null,
              });
            }}
          />
        </Box>
        <Box width={{xxs: '100%', sm: 220}}>
          <Autocomplete
            disabled={!filter?.season}
            label="Команда"
            multiple={false}
            options={[{value: group?.id, label: group?.name}]}
            value={filter?.teamId}
            onChange={(_, value) => {
              setFilter({...filter, teamId: value});
            }}
          />
        </Box>
      </Stack>
    </Wrapper>
  );
}

export default function PersonalFileVideoList() {
  const [filter, setFilter] = useState<Filters>({
    season: null,
    teamId: null,
  });
  const dispatch = useStoreDispatch();

  const [qs, set] = useQueryString();
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (qs?.page) {
      setPage(Number(qs?.page));
    }
  }, [qs?.page]);

  useEffect(() => {
    if (filter?.teamId?.value && filter?.season?.value && page) {
      dispatch(
        loadStatisticsFilesList({
          teamId: filter?.teamId?.value,
          season: filter?.season?.value,
          limit: limit,
          page: page,
        }),
      );
    }
    return () => {
      dispatch(clearStatisticsFilesList());
    };
  }, [filter?.teamId?.value, filter?.season?.value, page]);

  const {loading, error, result, total} = useStore((state) => state.stats.filesList);

  const memoMock = useDeepMemo(() => mock(qs.count), [qs]);

  const hasAllFiltersValues = filter?.season?.value && filter?.teamId?.value;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [activeGame, setActiveGame] = useState();

  return (
    <>
      <Filters filter={filter} setFilter={setFilter} />
      <Stack mt={2} spacing={1}>
        <Wrapper p={4}>
          {!hasAllFiltersValues && (
            <EmptyBlock>
              <Typography variant="body1">Заполните фильтры, чтобы увидеть данные</Typography>
            </EmptyBlock>
          )}
          {(!loading && !result?.length) || error ? (
            <EmptyBlock>
              <Typography variant="body1">Видео еще не добавлены</Typography>
            </EmptyBlock>
          ) : (
            <Stack direction="row" flexWrap="wrap" gap={2} sx={{pointerEvents: loading ? 'none' : void 0}}>
              {(loading ? memoMock : result)?.map((video, index) => {
                return (
                  <Card
                    isLoading={loading}
                    isPersonalFile={true}
                    key={index}
                    light={preview}
                    video={{...video, name: `${video.team} - ${video.opponent}`}}
                    width={(theme: Theme) => ({
                      lg: `calc(100% / 4 - ${theme.spacing(2)}  + (${theme.spacing(2)} / 4))`,
                      md: `calc(100% / 3 - ${theme.spacing(2)}  + (${theme.spacing(2)} / 3))`,
                      xs: `calc(50% - ${theme.spacing(2)}  + (${theme.spacing(2)} / 2))`,
                      xxs: '100%',
                    })}
                    onCardClick={() => {
                      setActiveGame(video);
                      setIsModalOpen(true);
                    }}
                  />
                );
              })}
            </Stack>
          )}
          {total > 1 && (
            <Pagination
              count={Math.ceil(total / limit)}
              page={page}
              onChange={(e, page) => {
                set({...qs, page: page});
              }}
            />
          )}
        </Wrapper>
        <ActionVideoModalForPersonalFile data={activeGame} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      </Stack>
    </>
  );
}

const EmptyBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75vh;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;

  .MuiButton {
    &-root {
      svg {
        path {
          fill: white;
        }
      }
    }
  }
`;
