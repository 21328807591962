import React from 'react';

function LeftArrow() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M0.217211 6.9147L6.27705 0.239632C6.4172 0.0851211 6.6043 -1.22016e-07 6.8038 -1.04575e-07C7.00329 -8.71348e-08 7.19039 0.0851212 7.33055 0.239632L7.77681 0.731092C8.0672 1.05133 8.0672 1.57182 7.77681 1.89157L2.68822 7.49689L7.78246 13.1084C7.92261 13.2629 8 13.4689 8 13.6885C8 13.9084 7.92261 14.1144 7.78246 14.269L7.33619 14.7604C7.19592 14.9149 7.00894 15 6.80944 15C6.60995 15 6.42285 14.9149 6.28269 14.7604L0.217211 8.0792C0.0767219 7.9242 -0.000441272 7.71725 1.25192e-06 7.49726C-0.000441234 7.2764 0.076722 7.06958 0.217211 6.9147Z"
          fill="#2C2C2C"
          fillOpacity="0.2"
        />
      </g>
    </React.Fragment>
  );
}
export default LeftArrow;
