import FunctionalStateTable from '@components/apps/FunctionalTesting/components/Report/FunctionalStateTable';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {getTeamName} from '@helpers/CommonHelper';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectDynamicOfTests} from '@selectors/functionalTesting';
import {fetchFunctionalTestsDynamic} from '@slices/functionalTesting';
import {userRoles} from '@src/constants';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {LocalPreloaderWrapper, TableWrapper} from '../Report/Report';

const FunctionalStateDynamic = () => {
  const isDoctor = useStore((state) => state.users.currentUser.userRole === userRoles.doctor);
  const isAdmin = useStore((state) => state.users.currentUser.userRole === userRoles.admin);
  const {items, isLoading} = useStore(selectDynamicOfTests);
  const dynamic = items;

  const {teamId} = useParams();

  const [teamName, setTeamName] = useState();

  useEffect(() => {
    getTeamName(teamId).then((res) => {
      setTeamName(res);
    });
  }, [teamId]);

  const dispatch = useStoreDispatch();

  const [selectedSeason, setSelectedSeason] = useState();

  useEffect(() => {
    if (!selectedSeason?.length) {
      dispatch(fetchFunctionalTestsDynamic(teamId));
    } else {
      dispatch(fetchFunctionalTestsDynamic(teamId, {season: selectedSeason.map((option) => option.value).join(',')}));
    }
  }, [teamId, selectedSeason?.length]);

  return (
    <>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {
            label: 'Функциональное тестирование',
            path: '/functional_testing',
          },
          {
            label: teamName,
            path: `/functional_testing/${teamId}`,
          },
          {
            path: `/functional_testing_dynamic/${teamId}`,
            label: `Динамика функционального состояния`,
          },
        ]}
      >
        Динамика функционального состояния
      </PageTitleDefault>
      <TableWrapper>
        {isLoading ? (
          <LocalPreloaderWrapper isLoading={isLoading} marginBottom="8px" padding={'0 24px'}>
            <LocalPreloader visible={true} />
          </LocalPreloaderWrapper>
        ) : (
          <TableWrapper>
            <FunctionalStateTable
              canEdit={isDoctor || isAdmin}
              selectedSeason={selectedSeason}
              setSelectedSeason={setSelectedSeason}
              tableData={dynamic}
            />
          </TableWrapper>
        )}
      </TableWrapper>
    </>
  );
};

export default FunctionalStateDynamic;
