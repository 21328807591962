import React, {useState} from 'react';

import DashboardsBlock from '../dashboards/trainers-dashboard/components/DashboardsBlock';

const InjuredPlayers = (props) => {
  const {contentBlock = () => {}, injuredPlayers = []} = props;
  const [isInjuredPlayersShowed, setIsInjuredPlayersShowed] = useState(false);

  let players = [];
  if (!isInjuredPlayersShowed && window.innerWidth >= 1025) {
    players = injuredPlayers.slice(0, 5);
  } else {
    players = injuredPlayers;
  }
  return (
    <DashboardsBlock
      buttonText={!isInjuredPlayersShowed ? 'Показать всех игроков' : 'Скрыть'}
      buttonsBlockType="button"
      content={contentBlock(
        players,
        isInjuredPlayersShowed,
        'В данный момент в команде нет травмированных игроков',
        'Статус травмы',
      )}
      isButtonsShowed={window.innerWidth >= 1025 && injuredPlayers.length > 5}
      marginBottom="8px !important"
      title="Травмированные игроки"
      onButtonClick={() => {
        setIsInjuredPlayersShowed((prevState) => !prevState);
      }}
    />
  );
};

export default InjuredPlayers;
