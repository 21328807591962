import AdapterMoment from '@mui/lab/AdapterMoment';
import MUIDateRangePicker from '@mui/lab/DateRangePicker';
import {DateRangePickerProps} from '@mui/lab/DateRangePicker/DateRangePicker';
import {ExportedDateRangePickerInputProps} from '@mui/lab/DateRangePicker/DateRangePickerInput';
import {DateRange} from '@mui/lab/DateRangePicker/RangeTypes';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Theme} from '@mui/material';
import TextField from '@mui/material/TextField';
import {TextFieldProps, TextFieldVariants} from '@mui/material/TextField/TextField';
import FormHelperText from '@ui/MUI/FormHelperText';
import moment from 'moment';
import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';

type InputsProps = {
  start: TextFieldProps;
  end: TextFieldProps;
};

type CustomDateRangePickerProps = {
  skipFirstValueOnChange?: boolean;
  inputsProps?: InputsProps;
  hasBottomBorder?: boolean;
  disableRestoreFocus?: boolean;
  helperText?: string;
  startLabel?: string;
  endLabel?: string;
  variant?: TextFieldVariants;
  renderInput?: ExportedDateRangePickerInputProps['renderInput'];
} & DateRangePickerProps<AdapterMoment>;

const CustomizedDateRangePicker = styled(({hasBottomBorder = false, ...props}: CustomDateRangePickerProps) => (
  <>
    <GlobalStyle hasBottomBorder={hasBottomBorder} />
    <MUIDateRangePicker {...props} />
  </>
))`
  margin-top: 16px;
`;

export default function DateRangePicker({
  inputsProps,
  value,
  maxDate,
  minDate,
  onChange,
  disableRestoreFocus = false,
  helperText,
  hasBottomBorder = false,
  startLabel,
  endLabel,
  variant,
  cancelText = 'Закрыть',
  okText = 'Выбрать',
  skipFirstValueOnChange = false,
  ...props
}: CustomDateRangePickerProps) {
  const [open, setOpen] = React.useState(false);
  const [isAccept, setIsAccept] = React.useState(false);
  const [isTextField, setIsTextField] = React.useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CustomizedDateRangePicker
        {...props}
        allowSameDateSelection={true}
        calendars={1}
        cancelText={cancelText}
        disableMaskedInput={true}
        hasBottomBorder={hasBottomBorder}
        maxDate={maxDate}
        minDate={minDate}
        okText={okText}
        open={open}
        renderInput={(startProps, endProps) => {
          return (
            <IputsWrapper>
              <IputsWrapperInner>
                <TextField
                  {...startProps}
                  variant={variant}
                  onBlur={() => {
                    setIsTextField(false);
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                  onFocus={() => {
                    setIsTextField(true);
                  }}
                  {...inputsProps?.start}
                  inputProps={{
                    ...startProps.inputProps,
                    placeholder: 'Выберите дату',
                    ...inputsProps?.start.inputProps,
                  }}
                  label={startLabel}
                />
                <TextField
                  {...endProps}
                  variant={variant}
                  onBlur={() => {
                    setIsTextField(false);
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                  onFocus={() => {
                    setIsTextField(true);
                  }}
                  {...inputsProps?.end}
                  inputProps={{
                    ...endProps.inputProps,
                    placeholder: 'Выберите дату',
                    ...inputsProps?.end?.inputProps,
                  }}
                  label={endLabel}
                />
              </IputsWrapperInner>
              {helperText && <FormHelperText error>{helperText}</FormHelperText>}
            </IputsWrapper>
          );
        }}
        showDaysOutsideCurrentMonth={true}
        showToolbar={false}
        toolbarTitle=""
        value={value}
        onAccept={() => {
          if (disableRestoreFocus) {
            setIsAccept(true);
          }
        }}
        onChange={(newValue: DateRange<AdapterMoment>) => {
          if (skipFirstValueOnChange) {
            const secondPeriod = moment(value[1]).format('DD.MM.YYYY');
            const secondValue = moment(newValue[1]).format('DD.MM.YYYY');
            if (secondPeriod === secondValue) return;
            onChange(newValue);
          } else {
            onChange(newValue);
          }
        }}
        onClose={() => {
          if (disableRestoreFocus) {
            setIsAccept(false);
            if (!isTextField) {
              setOpen(false);
              setIsTextField(false);
            }
          } else {
            setOpen(false);
          }
        }}
        onOpen={() => {
          if (disableRestoreFocus) {
            if (!isAccept) {
              setOpen(true);
            }
          } else {
            setOpen(true);
          }
        }}
      />
    </LocalizationProvider>
  );
}

const GlobalStyle = createGlobalStyle<{theme: Theme; hasBottomBorder: boolean}>`
  
  .MuiDateRangePickerDay {

    &-root {
      margin-right: 0 !important;
      margin-left: 0 !important;

      &.MuiDateRangePickerDay {
        &-rangeIntervalDayHighlight {
          background-color: #e12;

          &:focus {
            background-color: #e12;
          }

          &:hover {
            background-color: #ee1122c2;
          }

          .MuiDateRangePickerDay-dayInsideRangeInterval {
            color: white;
          }
        }

        &-rangeIntervalDayHighlightStart {
          &.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
            .MuiPickersDay-root.MuiDateRangePickerDay-day.Mui-selected {
              border-radius: 0 50% 50% 0;
            }
          }
        }
      }
    }

    &-day {
      &.MuiDateRangePickerDay-dayInsideRangeInterval {
        background-color: #e12;
        color: white;
      }
    }
  }

  .MuiPickersDay-root {
    &.MuiDateRangePickerDay {
      &-root {
        margin-right: 0;
        margin-left: 0;
      }

      &-day {
        &:hover {
          background-color: #ee1122c2;
        }

        &:not(.Mui-selected) {
          margin-left: 0;
          border: 0;
          border-radius: 0;
        }

        &.Mui-selected {
          background-color: #e12;

          &:focus {
            background-color: #e12;
          }

          &:hover {
            background-color: #ee1122c2;
          }
        }
      }
    }
  }

  .MuiInput {
    &-root {
      width: 100%;

      &:before,
      &:after {
        border-color: rgba(0, 0, 0, .42) !important;
      }
    }
  }

  .Mui-error:after {
    border-color: #d32f2f !important;
  }

  .MuiOutlinedInput {
    &-notchedOutline {
      border-color: #f3f3f3 !important;
      border-radius: ${(props) => props.hasBottomBorder && '0 !important'};
      border: ${(props) => props.hasBottomBorder && 'none !important'};
      border-bottom: ${(props) => props.hasBottomBorder && '1px solid rgba(0, 0, 0, 0.42) !important'};
      &:hover,
      &:focus {
        border-color: #b3b3b3 !important;
      }
    }

    &-input.MuiInputBase-input {
      padding: 8px;
      margin-top: ${(props) => props.hasBottomBorder && '8px !important'};
      font: normal normal 16px/18px "Proxima Nova";
      color: #2c2c2c;
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    &:first-child {
      margin-right: 8px;
    }
  }
`;
const IputsWrapper = styled.div`
  display: block;
`;
const IputsWrapperInner = styled.div`
  display: flex;
  align-items: center;
`;
