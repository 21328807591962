import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import moment from 'moment';
import React from 'react';

import BlockItem from '../BlockItem';

const MainInfoBlock = ({age, theme, time, repeat, name, author, createdAt}) => {
  const createDate = moment(createdAt).format('DD.MM.YYYY');

  return (
    <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
      <Stack spacing={3}>
        <TypographyDefault variant="h3">{name}</TypographyDefault>
        <Stack spacing="4px">
          <BlockItem description={theme?.name || '-'} title="Тематика:" />
          <BlockItem description={age?.name || '-'} title="Возраст:" />
          <BlockItem description={author?.name || '-'} title="Автор:" />
          {createdAt && <BlockItem description={createDate} title="Дата создания:" />}
        </Stack>
      </Stack>
      {time && repeat && (
        <Stack spacing="4px">
          <TypographyDefault>Повторов: {repeat}</TypographyDefault>
          <TypographyDefault>Время: {time}</TypographyDefault>
        </Stack>
      )}
    </Stack>
  );
};

export default MainInfoBlock;
