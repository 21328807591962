/**
 * Created by sabir on 19.07.17.
 */

import {Map} from 'immutable';

import * as types from '../ActionTypes.js';

const initialState = {
  loading: false,

  roomsMap: Map(),
  error: undefined,
};

const RoomsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CREATE_ROOM:
    case types.UPDATE_ROOM:
    case types.DELETE_ROOM:
    case types.LOAD_ROOMS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case types.CREATE_ROOM_FAIL:
    case types.UPDATE_ROOM_FAIL:
    case types.DELETE_ROOM_FAIL:
    case types.LOAD_ROOMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.CREATE_ROOM_SUCCESS:
    case types.UPDATE_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        roomsMap: state.roomsMap.set(action.room.id, action.room),
      };

    case types.DELETE_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        roomsMap: state.roomsMap.delete(action.id),
      };

    case types.LOAD_ROOMS_SUCCESS:
      return {
        ...state,
        loading: false,
        roomsMap: state.roomsMap.merge(action.rooms.reduce((map, form) => map.set(form.id, form), Map())),
      };
    case types.LOAD_DRESSING_ROOMS:
      return {
        ...state,
        loading: false,
        dressingRooms: action.dressingRooms,
      };

    default:
      return state;
  }
};

export default RoomsReducer;
