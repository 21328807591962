//файл для экспорта названия поля в rootReducer, selector, и slice
export default {
  users: 'users',
  groups: 'groups',
  rooms: 'rooms',
  schools: 'schools',
  lessons: 'lessons',
  gameEstimate: 'gameEstimate',
  guides: 'guides',
  skillsEstimate: 'skillsEstimate',
  physicalTesting: 'physicalTesting',
  psychoTesting: 'psychoTesting',
  medicalProfile: 'medicalProfile',
  individualPlan: 'individualPlan',
  lines: 'lines',
  dashboard: 'dashboard',
  form: 'form',
  psychoProfile: 'psychoProfile',
  tvSchedule: 'tvSchedule',
  goals: 'goals',
  skillVideo: 'skillVideo',
  scoutReport: 'scoutReport',
  stats: 'stats',
  filters: 'filters',
  bodyMassIndex: 'bodyMassIndex',
  materialsModule: 'materialsModule',
  physicalTestingOverall: 'physicalTestingOverall',
  physicalTestingCompare: 'physicalTestingCompare',
  physicalTestingPlayerProgress: 'physicalTestingPlayerProgress',
  physicalTestingEditCreate: 'physicalTestingEditCreate',
  throwsStatistics: 'throwsStatistics',
  managerDashboard: 'managerDashboard',
  trainingControlProcess: 'trainingControlProcess',
  functionalTesting: 'functionalTesting',
  gameStatistics: 'gameStatistics',
  gameStatisticsParsingCheck: 'gameStatisticsParsingCheck',
  gameStatisticsMkc: 'gameStatisticsMkc',
  physicalTestingSirius: 'physicalTestingSirius',
  videoAnalytics: 'videoAnalytics',
  bmi: 'bmi',
  playerDiary: 'playerDiary',
  skill: 'skill',
  tournamentsList: 'tournamentsList',
  playerPersonalFile: 'playerPersonalFile',
  menu: 'menu',
  schoolsV2: 'schoolsV2',
  groupsV2: 'groupsV2',
};
