import React from 'react';

import PsychotestPanel from '../psychotest/panels/PsychotestPanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function PsychotestApp() {
  return (
    <ChessTemplate active="psychotest">
      <PsychotestPanel />
    </ChessTemplate>
  );
}
