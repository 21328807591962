import {Box, Stack, Typography} from '@ui/MUI';
import {grey, yellow} from '@ui/MUI/colors';
import styled from 'styled-components';

const border = `1px solid ${grey['12']}`;
export const Buttons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({theme}) => theme.spacing(3)};
  ${({theme}) => theme.breakpoints.down('xs')} {
    gap: ${({theme}) => theme.spacing(1)};
  }
  .MuiButton-root:first-child {
    ${({theme}) => theme.breakpoints.up('xs')} {
      margin-right: ${({theme}) => theme.spacing(3)};
      &::before {
        content: '';
        position: absolute;
        right: -${({theme}) => theme.spacing(3)};
        top: 0;
        height: 100%;
        width: 1px;
        background-color: rgba(33, 33, 33, 0.3);
      }
    }
  }
`;
export const Td = styled.td`
  position: relative;
  padding: ${({theme, py}) => theme.spacing(py || 2, 3)};
  max-width: 190px;
  &:first-child {
    border-top: none !important;
  }
`;

export const ShiftTimeProgress = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: ${({time}) =>
    time && `linear-gradient(90deg, transparent ${100 - time}%, ${yellow['60']} ${100 - time}%)`};

  ${({theme}) => theme.breakpoints.down('xs')} {
    height: 100%;
  }
`;
export const Th = styled.th`
  padding: ${({theme}) => theme.spacing(2, 3)};
  height: 61px;
  text-align: left;
  max-width: ${({width}) => width + 'px'};
  h6 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.sticky {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
    transform: translateY(1px);
    box-shadow: inset 0px -1px 0px 0px ${grey[12]};
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      top: -2px;
      left: 0;
      background-color: #fff;
    }
  }
`;
export const Table = styled.table`
  border-collapse: collapse;

  ${Td},${Th} {
    border: ${border};
  }
`;

export const ChartTableWrapper = styled(Box)`
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
`;
export const ChartTableRightPartScrollWrapper = styled.div`
  transform: translateX(-1px);
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  border-collapse: collapse;
  padding-bottom: ${(props) => props.paddingBottom};
`;

export const ChartTableRightPartScrollInner = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  min-width: 800px;
`;

export const ToggleWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: sticky;
  top: 0;
  width: 20px;
`;

export const TableWrapper = styled(Stack)`
  border-bottom: ${border};
`;

export const EllipsisTypography = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
