import TeamLogo from '@components/common/Statistics/PlayerStatistics/TeamLogo';
import dateFn from '@helpers/date';
import {useMediaQuery} from '@mui/material';
import {Box, Divider, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

FutureGame.propTypes = {
  game: PropTypes.object,
};
const borderStyle = `1px solid ${grey['24']}`;
function FutureGame({game}) {
  const {homeTeamName, awayTeamName, homeTeamAge, awayTeamAge, date, homeTeamLogo, awayTeamLogo} = game;
  const mobileM = useMediaQuery((theme) => theme.breakpoints.down('mobileM'));

  return (
    <Wrapper
      direction="row"
      divider={!mobileM && <Divider flexItem orientation="vertical" />}
      flexWrap={{xxs: 'wrap', mobileM: 'initial'}}
      maxWidth={(theme) => ({
        xxs: '100%',
        sm: `calc(100% / 2 - ${theme.spacing(2)} / 2)`,
        lg: `calc(100% / 3 - ${theme.spacing(3)} * 2 / 3)`,
      })}
      spacing={{xxs: '0', mobileM: 2}}
    >
      <Stack alignItems="center" flex={!mobileM && 1}>
        <TeamLogo src={homeTeamLogo} width={50} />
        <Typography
          borderRight={{xxs: borderStyle, mobileM: 'unset'}}
          order={{xxs: 1, mobileM: 'initial'}}
          padding={{xxs: 1, mobileM: 'initial'}}
          textAlign="center"
          variant="subtitle2"
          width={(theme) => ({xxs: `calc(50% - ${theme.spacing(2)} / 2)`, mobileM: 'auto'})}
        >
          {homeTeamName + ` ${homeTeamAge}`}
        </Typography>
      </Stack>
      <Box
        borderTop={{xxs: borderStyle, mobileM: 'unset'}}
        flex={!mobileM && 1}
        order={{xxs: 3, mobileM: 'initial'}}
        padding={{xxs: 1, mobileM: 'unset'}}
        textAlign="center"
        width={{xxs: '100%', mobileM: 'unset'}}
      >
        <Typography mb={1} textAlign="center" variant="subtitle2">
          {dateFn(date).format('DD MMM YYYY')}
        </Typography>
      </Box>
      <Stack alignItems="center" flex={!mobileM && 1}>
        <TeamLogo src={awayTeamLogo} width={50} />
        <Typography
          order={{xxs: 2, mobileM: 'initial'}}
          padding={{xxs: 1, mobileM: 'unset'}}
          textAlign="center"
          variant="subtitle2"
          width={(theme) => ({xxs: `calc(50% - ${theme.spacing(2)} / 2)`, mobileM: 'auto'})}
        >
          {awayTeamName + ` ${awayTeamAge}`}
        </Typography>
      </Stack>
    </Wrapper>
  );
}

export default FutureGame;

const Wrapper = styled(Stack)`
  border: ${borderStyle};
  border-radius: 6px;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  ${({theme}) => ({
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('mobileM')]: {
      padding: 0,
    },
  })}
`;
