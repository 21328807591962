import {useSchool} from '@hooks/filters';
import {userRoles} from '@src/constants';
import React from 'react';

import SelectDefault from '../../../ui/Select';

const SchoolSelect = ({value, onChange}) => {
  const hook = useSchool({value, allSchoolsPermissionRoles: [userRoles.video_analyst]});
  return <SelectDefault options={hook.options || []} value={value} onChange={onChange} />;
};

export default SchoolSelect;
