import {sectionLimit} from '@components/apps/MaterialsModule/constants';
import {useCurrentBreakPoint} from '@components/apps/MaterialsModule/hooks';
import Skeleton from '@ui/MUI/Skeleton';
import Stack from '@ui/MUI/Stack';
import React, {useMemo} from 'react';

const LoadingSkeleton = () => {
  const breakPoint = useCurrentBreakPoint();
  const skeletons = useMemo(() => {
    return new Array(sectionLimit.video[breakPoint]).fill(1);
  }, [breakPoint]);

  return (
    <Stack direction="row" flexWrap="wrap" gap="32px 16px">
      {skeletons.map((_, index) => (
        <Skeleton height="260px" key={index} variant="rounded" width="260px" />
      ))}
    </Stack>
  );
};

export default LoadingSkeleton;
