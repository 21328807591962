import React from 'react';

function CheckGray() {
  return (
    <React.Fragment>
      <path
        d="M9.00003 16.17L4.83003 12L3.41003 13.41L9.00003 19L21 7.00003L19.59 5.59003L9.00003 16.17Z"
        fill="#383838"
      />
    </React.Fragment>
  );
}
export default CheckGray;
