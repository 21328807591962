import {longDash} from '@src/constants';
import {noDataNumberValue} from '@src/helpers';
import {get} from 'lodash';

export const setCellData = (item, valueKey = '', isNumber = true) => {
  const value = get(item, valueKey);
  return {
    value: isNumber ? noDataNumberValue(value) : value || longDash,
    isNext: item?.isNext || false,
    isOurTeam: item?.isOurTeam,
  };
};

export const normalizeStatisticIds = (statisticIds) => {
  return statisticIds?.filter((item) => item?.value).map((item) => item?.statisticId);
};

export const setTechResultScore = (scoreItem, isTechResult) => {
  if (isTechResult) {
    return scoreItem ? '+' : '-';
  }
  return scoreItem;
};
