import {getTournaments} from '@api/videoAnalytics';
import useDeepEffect from '@hooks/useDeepEffect';
import {useState} from 'react';

export function useTournaments({seasons, groups}) {
  const [tournaments, setTournaments] = useState({isLoading: false, data: []});
  useDeepEffect(() => {
    setTournaments({data: [], isLoading: true});
    getTournaments({seasons: seasons, groups: groups}).then(({data}) => {
      setTournaments({isLoading: false, data: data});
    });
  }, [seasons, groups]);
  return tournaments;
}
