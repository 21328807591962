import ContentWrapper from '@common/ContentWrapper';
import Filters from '@common/Statistics/ThrowsMap/Filters';
import MatchesFilter from '@components/apps/StatisticsWithMkcUral/components/MatchesFilter';
import DonutCharts from '@components/apps/StatisticsWithMkcUral/components/ShotsMap/components/DonutCharts';
import Map from '@components/apps/StatisticsWithMkcUral/components/ShotsMap/components/Map';
import {useMatches} from '@components/apps/StatisticsWithMkcUral/hooks/useMatches';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectShotsMapFilters} from '@selectors/gameStatisticsMkc';
import {
  clearMetricStatistics,
  clearShotsMap,
  clearShotsMapFilters,
  setShotsMapFilters,
} from '@slices/statisticsMKC/shotsMap';
import {Stack} from '@ui/MUI';
import React, {useCallback} from 'react';

function ShotsMap() {
  const dispatch = useStoreDispatch();

  const {statisticIds, isMatchesLoading} = useMatches();

  const setFilters = useCallback((data = {}) => dispatch(setShotsMapFilters(data)), []);
  return (
    <Stack spacing={1}>
      <MatchesFilter
        wrapperProps={{mb: '0'}}
        onClear={() => {
          setFilters({playerIds: []});
          dispatch(clearMetricStatistics());
          dispatch(clearShotsMap());
        }}
      />
      <ContentWrapper>
        <Filters hasOT cleanup={clearShotsMapFilters} getter={selectShotsMapFilters} setter={setShotsMapFilters} />
      </ContentWrapper>
      <DonutCharts weAtHome />

      <Map weAtHome isLoading={isMatchesLoading} setFilters={setFilters} statisticsIds={statisticIds} />
    </Stack>
  );
}

export default ShotsMap;
