import Gate from '@common/Statistics/PlayerStatistics/Gate';
import GoaltenderZone from '@components/apps/PlayerPersonalFile/components/Statistics/components/GoaltenderZone';
import {selectGateZones} from '@selectors/playerPersonalFile';
import {clearGateZones, loadGateZones} from '@slices/playerPersonalFile';
import React from 'react';

function GateZones({...props}) {
  return (
    <GoaltenderZone
      Component={Gate}
      cleaner={clearGateZones}
      loader={loadGateZones}
      selector={selectGateZones}
      title="Зоны ворот"
      {...props}
    />
  );
}

export default GateZones;
