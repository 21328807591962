/**
 * Created by sabir on 19.07.17.
 */

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const LOAD_FORMS = 'LOAD_FORMS';
export const LOAD_FORMS_SUCCESS = 'LOAD_FORMS_SUCCESS';
export const LOAD_FORMS_FAIL = 'LOAD_FORMS_FAIL';

export const CREATE_FORM = 'CREATE_FORM';
export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS';
export const CREATE_FORM_FAIL = 'CREATE_FORM_FAIL';

export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS';
export const UPDATE_FORM_FAIL = 'UPDATE_FORM_FAIL';

export const DELETE_FORM = 'DELETE_FORM';
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS';
export const DELETE_FORM_FAIL = 'DELETE_FORM_FAIL';

export const SELECT_FORM = 'SELECT_FORM';
export const UNSELECT_FORM = 'UNSELECT_FORM';

export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION';
export const UNSELECT_ORGANIZATION = 'UNSELECT_ORGANIZATION';

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAIL = 'CREATE_ORGANIZATION_FAIL';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAIL = 'UPDATE_ORGANIZATION_FAIL';

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_FAIL = 'DELETE_ORGANIZATION_FAIL';

export const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS';
export const LOAD_ORGANIZATIONS_SUCCESS = 'LOAD_ORGANIZATIONS_SUCCESS';
export const LOAD_ORGANIZATIONS_FAIL = 'LOAD_ORGANIZATIONS_FAIL';

//upload
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL';

export const LOAD_USER_LINKS = 'LOAD_USER_LINKS';
export const LOAD_USER_LINKS_SUCCESS = 'LOAD_USER_LINKS_SUCCESS';
export const LOAD_USER_LINKS_FAIL = 'LOAD_USER_LINKS_FAIL';

export const CREATE_USER_LINK = 'CREATE_USER_LINK';
export const CREATE_USER_LINK_SUCCESS = 'CREATE_USER_LINK_SUCCESS';
export const CREATE_USER_LINK_FAIL = 'CREATE_USER_LINK_FAIL';

export const UPDATE_USER_LINK = 'UPDATE_USER_LINK';
export const UPDATE_USER_LINK_SUCCESS = 'UPDATE_USER_LINK_SUCCESS';
export const UPDATE_USER_LINK_FAIL = 'UPDATE_USER_LINK_FAIL';

export const DELETE_USER_LINK = 'DELETE_USER_LINK';
export const DELETE_USER_LINK_SUCCESS = 'DELETE_USER_LINK_SUCCESS';
export const DELETE_USER_LINK_FAIL = 'DELETE_USER_LINK_FAIL';

//users
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_CLEAR_ERRORS = 'LOGIN_CLEAR_ERRORS';
export const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';
export const RECOVERY_PASSWORD_SUCCESS = 'RECOVERY_PASSWORD_SUCCESS';
export const RECOVERY_PASSWORD_FAILED = 'RECOVERY_PASSWORD_FAILED';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL';

export const LOAD_STUDENT = 'LOAD_STUDENT';
export const LOAD_STUDENT_SUCCESS = 'LOAD_STUDENT_SUCCESS';
export const LOAD_STUDENT_FAIL = 'LOAD_STUDENT_FAIL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const INITIALIZE_AUTH_SUCCESS = 'INITIALIZE_AUTH_SUCCESS';
export const INITIALIZE_AUTH_FAIL = 'INITIALIZE_AUTH_FAIL';

export const SEARCH_USERS = 'SEARCH_USERS';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAIL = 'SEARCH_USERS_FAIL';

export const INITIALIZE_CITIZENSHIPS = 'INITIALIZE_CITIZENSHIPS';
export const INITIALIZE_COUNTRIES = 'INITIALIZE_COUNTRIES';
export const INITIALIZE_AMPLUA = 'INITIALIZE_AMPLUA';

export const CHANGE_TRAINER_DEFAULT_FILTERS = 'CHANGE_TRAINER_DEFAULT_FILTERS';

export const SAVE_STUDENTS_FILTERS = 'SAVE_STUDENTS_FILTERS';
export const SAVE_STUDENT_PROFILE_FILTERS = 'SAVE_STUDENT_PROFILE_FILTERS';

//stations
export const CREATE_SCHOOL = 'CREATE_SCHOOL';
export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS';
export const CREATE_SCHOOL_FAIL = 'CREATE_SCHOOL_FAIL';

export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS';
export const UPDATE_SCHOOL_FAIL = 'UPDATE_SCHOOL_FAIL';

export const LOAD_SCHOOLS = 'LOAD_SCHOOLS';
export const LOAD_SCHOOLS_SUCCESS = 'LOAD_SCHOOLS_SUCCESS';
export const LOAD_SCHOOLS_FAIL = 'LOAD_SCHOOLS_FAIL';

export const DELETE_SCHOOL = 'DELETE_SCHOOL';
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS';
export const DELETE_SCHOOL_FAIL = 'DELETE_SCHOOL_FAIL';

//groups
export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL';

export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL';

export const CLEAN_GROUP_STATISTICS = 'CLEAN_GROUP_STATISTICS';
export const LOAD_GROUPS = 'LOAD_GROUPS';
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS';
export const LOAD_GROUP_SUCCESS = 'LOAD_GROUP_SUCCESS';
export const LOAD_GROUPS_FAIL = 'LOAD_GROUPS_FAIL';
export const LOAD_GROUP_STATISTICS_METRICS = 'LOAD_GROUP_STATISTICS_METRICS';
export const LOAD_GROUP_RATE = 'LOAD_GROUP_RATE';

export const SAVE_RATING_FILTERS = 'SAVE_RATING_FILTERS';

// rooms

export const CREATE_ROOM = 'CREATE_ROOM';
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS';
export const CREATE_ROOM_FAIL = 'CREATE_ROOM_FAIL';

export const UPDATE_ROOM = 'UPDATE_ROOM';
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS';
export const UPDATE_ROOM_FAIL = 'UPDATE_ROOM_FAIL';

export const DELETE_ROOM = 'DELETE_ROOM';
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
export const DELETE_ROOM_FAIL = 'DELETE_ROOM_FAIL';

export const LOAD_ROOMS = 'LOAD_ROOMS';
export const LOAD_ROOMS_SUCCESS = 'LOAD_ROOMS_SUCCESS';
export const LOAD_ROOMS_FAIL = 'LOAD_ROOMS_FAIL';
export const LOAD_DRESSING_ROOMS = 'LOAD_DRESSING_ROOMS';

//lessons

export const CREATE_LESSON = 'CREATE_LESSON';
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS';
export const CREATE_LESSON_FAIL = 'CREATE_LESSON_FAIL';

export const UPDATE_LESSON = 'UPDATE_LESSON';
export const UPDATE_LESSON_SUCCESS = 'UPDATE_LESSON_SUCCESS';
export const UPDATE_LESSON_FAIL = 'UPDATE_LESSON_FAIL';

export const DELETE_LESSON = 'DELETE_LESSON';
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS';
export const DELETE_LESSON_FAIL = 'DELETE_LESSON_FAIL';

export const LOAD_LESSONS = 'LOAD_LESSONS';
export const LOAD_LESSONS_SUCCESS = 'LOAD_LESSONS_SUCCESS';
export const LOAD_LESSONS_FAIL = 'LOAD_LESSONS_FAIL';

export const LOAD_LESSONS_TYPES = 'LOAD_LESSONS_TYPES';

export const LOAD_LESSONS_STATISTICS = 'LOAD_LESSONS_STATISTICS';
export const LOAD_FILTERED_LESSONS = 'LOAD_FILTERED_LESSONS';

// game
export const LOAD_GAME_ESTIMATE = 'LOAD_GAME_ESTIMATE';
export const LOAD_PERIOD_GAME_ESTIMATE_REPORT = 'LOAD_PERIOD_GAME_ESTIMATE_REPORT';
export const CLEAN_PERIOD_GAME_ESTIMATE_REPORT = 'CLEAN_PERIOD_GAME_ESTIMATE_REPORT';
export const LOAD_GAME_ESTIMATE_REPORT = 'LOAD_GAME_ESTIMATE_REPORT';
export const LOAD_ALL_GAME_ESTIMATE = 'LOAD_ALL_GAME_ESTIMATE';
export const LOAD_GAME_WITHOUT_ESTIMATE_REPORT = 'LOAD_GAME_WITHOUT_ESTIMATE_REPORT';
export const CLEAN_GAME_ESTIMATE = 'CLEAN_GAME_ESTIMATE';
export const LOAD_PLAYER_ESTIMATE_CHART_DATA = 'LOAD_PLAYER_ESTIMATE_CHART_DATA';
export const LOAD_GROUP_ESTIMATE_CHART_DATA = 'LOAD_GROUP_ESTIMATE_CHART_DATA';
export const LOAD_SCHOOL_ESTIMATE_CHART_DATA = 'LOAD_SCHOOL_ESTIMATE_CHART_DATA';
export const LOAD_PLAYER_GAMES_ESTIMATE = 'LOAD_PLAYER_GAMES_ESTIMATE';
export const LOAD_GAME_ESTIMATE_LOADING = 'LOAD_GAME_ESTIMATE_LOADING';
export const LOAD_GAME_ESTIMATE_ERROR = 'LOAD_GAME_ESTIMATE_ERROR';
export const LOAD_GAME_PERIOD_ESTIMATE_REPORT = 'LOAD_GAME_PERIOD_ESTIMATE_REPORT';
export const LOAD_GAME_ESTIMATIONS_FOR_PLAYER_PERSONAL_FILE = 'LOAD_GAME_ESTIMATIONS_FOR_PLAYER_PERSONAL_FILE';
export const LOAD_GAME_ESTIMATIONS_FOR_PLAYER_PERSONAL_FILE_LOADING =
  'LOAD_GAME_ESTIMATIONS_FOR_PLAYER_PERSONAL_FILE_LOADING';
export const LOAD_GAME_ESTIMATIONS_FOR_PLAYER_PERSONAL_FILE_ERROR =
  'LOAD_GAME_ESTIMATIONS_FOR_PLAYER_PERSONAL_FILE_ERROR';

export const LOAD_GAMES_KEY_PARAMS = 'LOAD_GAMES_KEY_PARAMS';

// guides
export const LOAD_ALL_GUIDES = 'LOAD_ALL_GUIDES';
export const LOAD_GUIDE_ITEMS = 'LOAD_GUIDE_ITEMS';
export const LOAD_TEAM_PARAMS_GUIDE_ITEMS = 'LOAD_TEAM_PARAMS_GUIDE_ITEMS';
export const LOAD_INDIVIDUAL_PARAMS_GUIDE_ITEMS = 'LOAD_INDIVIDUAL_PARAMS_GUIDE_ITEMS';
export const LOAD_SKILLS_GUIDE = 'LOAD_SKILLS_GUIDE';

// skills estimate
export const LOAD_PLAYER_SKILLS_ESTIMATE = 'LOAD_PLAYER_SKILLS_ESTIMATE';
export const LOAD_PLAYER_SKILLS_ESTIMATE2 = 'LOAD_PLAYER_SKILLS_ESTIMATE2';
export const LOADING_PLAYER_SKILLS_ESTIMATE_START = 'LOADING_PLAYER_SKILLS_ESTIMATE_START';
export const LOADING_PLAYER_SKILLS_ESTIMATE_FINISH = 'LOADING_PLAYER_SKILLS_ESTIMATE_FINISH';
export const LOAD_PLAYER_SKILLS_ESTIMATE_REPORT = 'LOAD_PLAYER_SKILLS_ESTIMATE_REPORT';
export const LOAD_PLAYER_INDIVIDUAL_SKILLS_ESTIMATE = 'LOAD_PLAYER_INDIVIDUAL_SKILLS_ESTIMATE';
export const LOAD_PLAYER_INDIVIDUAL_SKILLS_ESTIMATE_REPORT = 'LOAD_PLAYER_INDIVIDUAL_SKILLS_ESTIMATE_REPORT';
export const LOAD_PLAYER_RATING_SKILLS_ESTIMATE_REPORT = 'LOAD_PLAYER_RATING_SKILLS_ESTIMATE_REPORT';
export const LOAD_ESTIMATED_PLAYERS = 'LOAD_ESTIMATED_PLAYERS';

// change individual player form
export const CHANGE_FORM = 'CHANGE_FORM';

//students
export const LOAD_PLAYER_STATISTICS = 'LOAD_PLAYER_STATISTICS';
export const LOAD_PLAYER_STATISTICS_WITHOUT = 'LOAD_PLAYER_STATISTICS_WITHOUT';
export const LOAD_RATING_PLAYER_STATISTICS = 'LOAD_RATING_PLAYER_STATISTICS';
export const STUDENT_LOADING = 'STUDENT_LOADING';
export const TEACHERS_LOADING = 'TEACHERS_LOADING';
export const STOP_STUDENT_LOADING = 'STOP_STUDENT_LOADING';
export const LOAD_STUDENTS = 'LOAD_STUDENTS';
export const CLEAN_STUDENT_STATE = 'CLEAN_STUDENT_STATE';
export const LOAD_TEACHERS = 'LOAD_TEACHERS';

// physical testing
export var PHYSICAL_TESTING_LOAD = 'PHYSICAL_TESTING_LOAD';
export const LOAD_PHYSICAL_TESTING_FORM = 'LOAD_PHYSICAL_TESTING_FORM';
export const LOAD_TEAM_PHYSICAL_TESTING_REPORT = 'LOAD_TEAM_PHYSICAL_TESTING_REPORT';
export const LOAD_PERSONAL_PHYSICAL_TESTING_REPORT = 'LOAD_PERSONAL_PHYSICAL_TESTING_REPORT';
export const POST_PHYSICAL_TESTING_FORM = 'POST_PHYSICAL_TESTING_FORM';
export const PHYSICAL_TESTING_ERROR = 'PHYSICAL_TESTING_ERROR';
export const LOAD_RATING_PHYSICAL_TESTING_REPORT = 'LOAD_RATING_PHYSICAL_TESTING_REPORT';
// opposing teams
export const LOAD_OPPOSING_TEAMS = 'LOAD_OPPOSING_TEAMS';

// psycho
export const PSYCHO_TESTING_LOADING_START = 'PSYCHO_TESTING_LOADING_START';
export const PSYCHO_TESTING_LOADING_FINISH = 'PSYCHO_TESTING_LOADING_FINISH';
export const PSYCHO_TESTING_LOADING_FORM = 'PSYCHO_TESTING_LOADING_FORM';
export const PSYCHO_TESTING_CHANGE_FORM = 'PSYCHO_TESTING_CHANGE_FORM';
export const PSYCHO_TESTING_LOAD_REPORT = 'PSYCHO_TESTING_LOAD_REPORT';
export const LOAD_PLAYER_PHYSICAL_TESTING_RECOMMENDATION = 'LOAD_PLAYER_PHYSICAL_TESTING_RECOMMENDATION';
export const CHANGE_PLAYER_PHYSICAL_TESTING_RECOMMENDATIONS = 'CHANGE_PLAYER_PHYSICAL_TESTING_RECOMMENDATIONS';
export const TYPOLOGICAL_PROFILE_LOAD_REPORT = 'TYPOLOGICAL_PROFILE_LOAD_REPORT';
export const TYPOLOGICAL_PROFILE_LOADING_START = 'TYPOLOGICAL_PROFILE_LOADING_START';
export const TYPOLOGICAL_PROFILE_LOADING_FINISH = 'TYPOLOGICAL_PROFILE_LOADING_FINISH';

// medical profile
export const MEDICAL_PROFILE_CHANGE_FORM = 'MEDICAL_PROFILE_CHANGE_FORM';
export const MEDICAL_PROFILE_CLEAN_FORM = 'MEDICAL_PROFILE_CLEAN_FORM';
export const MEDICAL_PROFILE_LOADING_START = 'MEDICAL_PROFILE_LOADING_START';
export const MEDICAL_PROFILE_LOADING_FORM = 'MEDICAL_PROFILE_LOADING_FORM';
export const MEDICAL_PROFILE_LOADING_STAGES = 'MEDICAL_PROFILE_LOADING_STAGES';
export const MEDICAL_PROFILE_LOADING_USER_DATA = 'MEDICAL_PROFILE_LOADING_USER_DATA';
export const MEDICAL_PROFILE_LOADING_ERROR = 'MEDICAL_PROFILE_LOADING_ERROR';
export const TEAM_NUTRITION_LOADING = 'TEAM_NUTRITION_LOADING';
export const MEDICAL_PROFILE_CHANGE_BOARD = 'MEDICAL_PROFILE_CHANGE_BOARD';
export const MEDICAL_PROFILE_HISTORY_LOADING = 'MEDICAL_PROFILE_HISTORY_LOADING';
export const BMI_LOADING_FORM = 'BMI_LOADING_FORM';
export const BMI_LOADING_REPORT = 'BMI_LOADING_REPORT';
export const BMI_FORM_CHANGE = 'BMI_FORM_CHANGE';
export const MEDICAL_PROFILE_GET_RECOMMENDATIONS = 'MEDICAL_PROFILE_GET_RECOMMENDATIONS';
export const MEDICAL_PROFILE_GET_RECOMMENDATIONS_FOR_CARD = 'MEDICAL_PROFILE_GET_RECOMMENDATIONS_FOR_CARD';
export const MEDICAL_PROFILE_SAVE_RECOMMENDATION = 'MEDICAL_PROFILE_SAVE_RECOMMENDATION';
export const MEDICAL_PROFILE_CHANGE_RECOMMENDATION = 'MEDICAL_PROFILE_CHANGE_RECOMMENDATION';

// psycho profile

export const PSYCHO_DASHBOARD_WIDGET_UPDATE = 'PSYCHO_DASHBOARD_WIDGET_UPDATE';

//Lines
export const LINES_LOADING_START = 'LINES_LOADING_START';
export const LINES_LOADING_END = 'LINES_LOADING_END';
export const LINES_LOADING_FORM = 'LINES_LOADING_FORM';
export const LINES_SAVE_FORM = 'LINES_SAVE_FORM';
export const LINES_LOADING_ERROR = 'LINES_LOADING_ERROR';
export const LINES_CHANGE_FORM = 'LINES_CHANGE_FORM';
export const LINES_LOADING_PLAYER_HISTORY = 'LINES_LOADING_PLAYER_HISTORY';
export const LINES_LOADING_FAVORITES_LIST = 'LINES_LOADING_FAVORITES_LIST';
export const LINES_HISTORY_LOADING = 'LINES_HISTORY_LOADING';

//Dashboards

export const DASHBOARD_LOADING_START = 'DASHBOARD_LOADING_START';
export const DASHBOARD_LOADING_END = 'DASHBOARD_LOADING_END';
export const STUDENT_DASHBOARD_LOADING_END = 'STUDENT_DASHBOARD_LOADING_END';
export const DASHBOARD_LOADING_ERROR = 'DASHBOARD_LOADING_ERROR';

//TV schedule

export const TV_SCHEDULE_LOADING_START = 'DASHBOARD_LOADING_START';
export const TV_SCHEDULE_LOADING_END = 'DASHBOARD_LOADING_END';
export const TV_SCHEDULE_LOADING_ERROR = 'DASHBOARD_LOADING_ERROR';

// Form Actions
export const GET_FORM_VALUE = 'GET_FORM_VALUE';
export const UPDATE_FORM_VALUE = 'UPDATE_FORM_VALUE';
export const CREATE_DEFAULT_FORM_VALUES = 'CREATE_DEFAULT_FORM_VALUES';

// Goals
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_SUCCESS = 'SET_CURRENT_USER_SUCCESS';
export const SET_CURRENT_USER_ERROR = 'SET_CURRENT_USER_ERROR';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const SET_CURRENT_GOAL = 'SET_CURRENT_GOAL';
export const CLEAR_CURRENT_GOAL = 'CLEAR_CURRENT_GOAL';
export const SET_CURRENT_USER_GOALS = 'SET_CURRENT_USER_GOALS';
export const SET_CURRENT_USER_GOALS_SUCCESS = 'SET_CURRENT_USER_GOALS_SUCCESS';
export const SET_CURRENT_USER_GOALS_ERROR = 'SET_CURRENT_USER_GOALS_ERROR';
export const CLEAR_CURRENT_USER_GOALS = 'CLEAR_CURRENT_USER_GOALS';
export const UPDATE_GOAL = 'UPDATE_GOAL';
export const UPDATE_GOAL_SUCCESS = 'UPDATE_GOAL_SUCCESS';
export const UPDATE_GOAL_ERROR = 'UPDATE_GOAL_ERROR';
export const DELETE_GOAL = 'DELETE_GOAL';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_ERROR = 'DELETE_GOAL_ERROR';
export const ADD_GOAL = 'ADD_GOAL';
export const ADD_GOAL_SUCCESS = 'ADD_GOAL_SUCCESS';
export const ADD_GOAL_ERROR = 'ADD_GOAL_ERROR';
export const COMPLETE_GOAL = 'COMPLETE_GOAL';
export const COMPLETE_GOAL_SUCCESS = 'COMPLETE_GOAL_SUCCESS';
export const COMPLETE_GOAL_ERROR = 'COMPLETE_GOAL_ERROR';

//Scout-report
export const GET_CLUBS = 'GET_CLUBS';
export const GET_CLUBS_SUCCESS = 'GET_CLUBS_SUCCESS';
export const GET_CLUBS_ERROR = 'GET_CLUBS_ERROR';
export const CLEAN_CLUBS = 'CLEAN_CLUBS';
export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_ERROR = 'GET_GROUPS_ERROR';
export const CLEAN_GROUPS = 'CLEAN_GROUPS';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const CLEAN_USERS = 'CLEAN_USERS';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const GET_USER_REPORTS = 'GET_USER_REPORTS';
export const GET_USER_REPORTS_SUCCESS = 'GET_USER_REPORTS_SUCCESS';
export const GET_USER_REPORTS_ERROR = 'GET_USER_REPORTS_ERROR';
export const CLEAN_USER_REPORTS = 'CLEAN_USER_REPORTS';
export const CREATE_REPORT = 'CREATE_REPORT';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_ERROR = 'CREATE_REPORT_ERROR';
export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';
export const CLEAN_REPORT = 'CLEAN_REPORT';
export const EDIT_REPORT = 'EDIT_REPORT';
export const EDIT_REPORT_SUCCESS = 'EDIT_REPORT_SUCCESS';
export const EDIT_REPORT_ERROR = 'EDIT_REPORT_ERROR';
export const DELETE_REPORT = 'DELETE_REPORT';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_ERROR = 'DELETE_REPORT_ERROR';
export const GET_GROUP_REPORTS = 'GET_GROUP_REPORTS';
export const GET_GROUP_REPORTS_SUCCESS = 'GET_GROUP_REPORTS_SUCCESS';
export const GET_GROUP_REPORTS_ERROR = 'GET_GROUP_REPORTS_ERROR';
export const CLEAN_GROUP_REPORTS = 'CLEAN_GROUP_REPORTS';
export const CREATE_GROUP_REPORT = 'CREATE_GROUP_REPORT';
export const CREATE_GROUP_REPORT_SUCCESS = 'CREATE_GROUP_REPORT_SUCCESS';
export const CREATE_GROUP_REPORT_ERROR = 'CREATE_GROUP_REPORT_ERROR';
export const GET_GROUP_REPORT = 'GET_GROUP_REPORT';
export const GET_GROUP_REPORT_SUCCESS = 'GET_GROUP_REPORT_SUCCESS';
export const GET_GROUP_REPORT_ERROR = 'GET_GROUP_REPORT_ERROR';
export const CLEAN_GROUP_REPORT = 'CLEAN_REPORT';
export const EDIT_GROUP_REPORT = 'EDIT_GROUP_REPORT';
export const EDIT_GROUP_REPORT_SUCCESS = 'EDIT_GROUP_REPORT_SUCCESS';
export const EDIT_GROUP_REPORT_ERROR = 'EDIT_GROUP_REPORT_ERROR';
export const DELETE_GROUP_REPORT = 'DELETE_GROUP_REPORT';
export const DELETE_GROUP_REPORT_SUCCESS = 'DELETE_GROUP_REPORT_SUCCESS';
export const DELETE_GROUP_REPORT_ERROR = 'DELETE_GROUP_REPORT_ERROR';

// RecoveryPassword
export const LOGIN_THROW_ERROR = 'LOGIN_THROW_ERROR';

// reset
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

//ValidateTOKEN
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const VALIDATE_TOKEN_FAILED = 'VALIDATE_TOKEN_FAILED';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';

// Stage Filters

export const UPDATE_FILTERS = 'UPDATE_FILTERS';

//Stats

export const LOAD_STATS_FOR_ONE_GAME = 'LOAD_STATS_FOR_ONE_GAME';
export const CLEAR_STATS_FOR_ONE_GAME = 'CLEAR_STATS_FOR_ONE_GAME';
export const LOAD_STATS_ERROR = 'LOAD_STATS_ERROR';
export const LOAD_STATS_FOR_ONE_GAME_SUCCESS = 'LOAD_STATS_FOR_ONE_GAME_SUCCESS';

export const LOAD_STATS_MATCH_SHIFTS = 'LOAD_STATS_MATCH_SHIFTS';
export const CLEAR_STATS_MATCH_SHIFTS = 'CLEAR_STATS_MATCH_SHIFTS';
export const LOAD_STATS_MATCH_SHIFTS_ERROR = 'LOAD_STATS_MATCH_SHIFTS_ERROR';
export const LOAD_STATS_MATCH_SHIFTS_SUCCESS = 'LOAD_STATS_MATCH_SHIFTS_SUCCESS';

export const LOAD_STATS_MATCH_GOALTENDER_INFO = 'LOAD_STATS_MATCH_GOALTENDER_INFO';
export const CLEAR_STATS_MATCH_GOALTENDER_INFO = 'CLEAR_STATS_MATCH_GOALTENDER_INFO';
export const LOAD_STATS_MATCH_GOALTENDER_INFO_ERROR = 'LOAD_STATS_MATCH_GOALTENDER_INFO_ERROR';
export const LOAD_STATS_MATCH_GOALTENDER_INFO_SUCCESS = 'LOAD_STATS_MATCH_GOALTENDER_INFO_SUCCESS';

export const LOAD_STATISTICS_FILES_LIST = 'LOAD_STATISTICS_FILES_LIST';
export const LOAD_STATISTICS_FILES_LIST_SUCCESS = 'LOAD_STATISTICS_FILES_LIST_SUCCESS';
export const LOAD_STATISTICS_FILES_LIST_ERROR = 'LOAD_STATISTICS_FILES_LIST_ERROR';
export const CLEAR_STATISTICS_FILES_LIST = 'CLEAR_STATISTICS_FILES_LIST';

// BMI

export const GET_BMI_DATA = 'GET_BMI_DATA';
export const BMI_LOADING_START = 'BMI_LOADING_START';
export const BMI_LOADING_END = 'BMI_LOADING_END';
export const BMI_LOADING_ERROR = 'BMI_LOADING_ERROR';

export const GET_BMI_DATA_CREATE_EDIT = 'GET_BMI_DATA_CREATE_EDIT';
export const BMI_DATA_CREATE_EDIT_LOADING_START = 'BMI_DATA_CREATE_EDIT_LOADING_START';
export const BMI_DATA_CREATE_EDIT_LOADING_END = 'BMI_DATA_CREATE_EDIT_LOADING_END';
export const BMI_DATA_CREATE_EDIT_LOADING_ERROR = 'BMI_DATA_CREATE_EDIT_LOADING_ERROR';
