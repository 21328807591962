import {getVideo, getVideoAnalytics, getVideoAnalyticsGroups, updateVideo} from '@api/videoAnalytics';
import {defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {createSlice} from '@reduxjs/toolkit';
import {initialStateIndicators} from '@src/constants';

import moduleNames from '../moduleNames';

const statePaths = {
  groups: {
    name: 'groups',
    path: 'groups',
  },
  videos: {
    name: 'videos',
    path: 'videos',
  },
  video: {
    name: 'video',
    path: 'video',
  },
};

const initialState = {
  groups: {
    ...initialStateIndicators,
  },
  videos: {
    ...initialStateIndicators,
    data: {
      totalItems: 1,
      totalPages: 1,
      videoAnalytics: [],
    },
  },
  video: {
    ...initialStateIndicators,
    data: {},
  },
};

//redusers
const videoAnalytics = createSlice({
  name: moduleNames.videoAnalytics,
  initialState,
  reducers: {
    ...defaultSliceReducersConstructor(statePaths.groups.name, statePaths.groups.path, initialState).functions,
    ...defaultSliceReducersConstructor(statePaths.videos.name, statePaths.videos.path, initialState).functions,
    ...defaultSliceReducersConstructor(statePaths.video.name, statePaths.video.path, initialState).functions,
    videoEdit: (state, action) => {
      state.video.data = action.payload;
    },
  },
});

//exports

const {actions, reducer} = videoAnalytics;
const {videoEdit} = actions;
export {reducer as videoAnalyticsReducer};

//fetch fns
const groupsActions = defaultSliceReducersConstructor(
  statePaths.groups.name,
  statePaths.groups.path,
  initialState,
  actions,
).actionsValues;

const videosActions = defaultSliceReducersConstructor(
  statePaths.videos.name,
  statePaths.videos.path,
  initialState,
  actions,
).actionsValues;

const videoActions = defaultSliceReducersConstructor(
  statePaths.video.name,
  statePaths.video.path,
  initialState,
  actions,
).actionsValues;

export const clearGroups = () => (dispatch) => dispatch(groupsActions.clear());

export const loadGroups = (params) =>
  defaultLoader(() => getVideoAnalyticsGroups(params), groupsActions.loading, groupsActions.load, groupsActions.error);

export const clearVideos = () => (dispatch) => dispatch(videosActions.clear());

export const loadVideos = (params) =>
  defaultLoader(() => getVideoAnalytics(params), videosActions.loading, videosActions.load, videosActions.error);

export const clearVideo = () => (dispatch) => dispatch(videoActions.clear());

export const loadVideo = (params) =>
  defaultLoader(() => getVideo(params), videoActions.loading, videoActions.load, videoActions.error);

export const editVideo = (id, data, onSuccess, onFinally) => {
  return async (dispatch) => {
    await updateVideo(id, data)
      .then((data) => {
        onSuccess(data.data);
        dispatch(videoEdit(data.data));
      })
      .finally(() => onFinally());
  };
};
