import ChildrenField from '@common/ChildrenField';
import FormItem from '@common/TrainingControl/FormItem';
import RadioRange from '@common/TrainingControl/RadioRange';
import {Stack, Typography} from '@ui/MUI';
import React from 'react';

const Item = ({name, label}) => {
  return (
    <>
      <FormItem alignItems="flex-end" label={label} maxWidth={440} spacing={2} titleMinWidth={328}>
        <ChildrenField isRequired name={name}>
          <RadioRange max={3} />
        </ChildrenField>
      </FormItem>
    </>
  );
};

const FormBlock = () => {
  return (
    <>
      <Typography variant="h3">Общее по тренировке:</Typography>
      <Stack direction="row" spacing={8}>
        <Item
          label={
            <>
              Согласованность работы <br /> между главным тренером и помощником
            </>
          }
          name="connectionEstimate"
        />
        <Item
          label={
            <>
              Использование в тренировке <br /> всех заявленных в конспекте навыков
            </>
          }
          name="skillEstimate"
        />
      </Stack>
      <Item label="Соблюдение чёткой структуры тренировки" name="structureEstimate" />
    </>
  );
};

export default FormBlock;
