import CitizenshipField from '@components/apps/PlayerPersonalFile/components/OtherTab/components/CitizenshipField';
import CountryField from '@components/apps/PlayerPersonalFile/components/OtherTab/components/CounrtyField';
import DateField from '@components/apps/PlayerPersonalFile/components/OtherTab/components/DateField';
import GroupField from '@components/apps/PlayerPersonalFile/components/OtherTab/components/GroupField';
import PhoneField from '@components/apps/PlayerPersonalFile/components/OtherTab/components/PhoneField';
import SchoolField from '@components/apps/PlayerPersonalFile/components/OtherTab/components/SchoolField';
import {
  DOCTOR_STATUSES_OPTIONS,
  HANDS_OPTIONS,
  HOCKEY_ROLES_OPTIONS,
  NATIONAL_TEAM_OPTIONS,
  STATUSES_OPTIONS,
} from '@components/students/constants';
import React from 'react';

const emailPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  message: 'Введите корректный e-mail',
};
export function generateUserFields(isEdit, status) {
  return {
    personalDataFields: [
      {name: 'firstName', label: 'ИМЯ', isRequired: true},
      {name: 'lastName', label: 'ФАМИЛИЯ', isRequired: true},
      {name: 'middleName', label: 'ОТЧЕСТВО'},
      {
        name: 'birthdayTimestamp',
        label: 'ДАТА РОЖДЕНИЯ',
        isRequired: true,
        Component: (props) => <DateField isEdit={isEdit} {...props} />,
      },
      {name: 'age', label: 'ВОЗРАСТ', showWhenEdit: false},
      {
        name: 'citizenshipId',
        type: 'autocomplete',
        label: 'ГРАЖДАНСТВО',
        Component: (props) => <CitizenshipField isEdit={isEdit} {...props} />,
      },
      {
        name: 'countryId',
        type: 'autocomplete',
        label: 'СТРАНА РОЖДЕНИЯ',
        Component: (props) => <CountryField isEdit={isEdit} {...props} />,
      },
      {name: 'cityOfBirth', label: 'ГОРОД РОЖДЕНИЯ'},
      {name: 'phone', label: 'ТЕЛЕФОН', Component: (props) => <PhoneField isEdit={isEdit} {...props} />},
      {name: 'vk', label: 'VK'},
      {name: 'nerosoftId', label: 'ID НЕЙРОСОФТА'},
      {name: 'avangardId', label: 'ID АВАНГАРДА'},
      {
        name: 'email',
        label: 'E-MAIL',
        pattern: emailPattern,
        isRequired: true,
      },
      {
        name: 'newPassword',
        payloadName: 'password',
        label: 'НОВЫЙ ПАРОЛЬ',
        pattern: {
          value: /[a-zA-Z0-9]+$/i,
          message: 'Некорректный пароль',
        },
        hideWhenNotEdit: true,
      },
    ].filter((item) => {
      if (isEdit) {
        return item.showWhenEdit !== false;
      }
      return !(!isEdit && item.hideWhenNotEdit);
    }),
    teamDataFields: [
      {
        name: 'school',
        payloadName: 'schoolId',
        label: 'ШКОЛА',
        Component: (props) => <SchoolField isEdit={isEdit} {...props} />,
        isRequired: true,
      },
      {
        name: 'group',
        payloadName: 'groupId',
        label: 'КОМАНДА',
        Component: (props) => <GroupField isEdit={isEdit} {...props} />,
        isRequired: true,
      },
      {
        name: 'hockeyRole',
        label: 'АМПЛУА',
        options: HOCKEY_ROLES_OPTIONS,
        type: 'autocomplete',
        isRequired: true,
      },
      {
        name: 'status',
        label: 'СТАТУС',
        type: 'autocomplete',
        options: !isEdit ? [...STATUSES_OPTIONS, ...DOCTOR_STATUSES_OPTIONS] : STATUSES_OPTIONS,
        isDisabled: DOCTOR_STATUSES_OPTIONS?.map(({value}) => value)?.includes(status) || false,
      },
      {name: 'hand', label: 'ХВАТ', type: 'autocomplete', options: HANDS_OPTIONS, isRequired: true},
      {name: 'playerNumber', label: 'ИГРОВОЙ НОМЕР', inputType: 'number', isRequired: true, minValue: 1, min: 1},
      {
        name: 'dateJoinedAcademy',
        label: 'ДАТА ЗАЧИСЛЕНИЯ В АКАДЕМИЮ',
        Component: (props) => <DateField isEdit={isEdit} {...props} />,
      },
      {
        name: 'contractPeriod',
        label: 'ДАТА ОКОНЧАНИЯ КОНТРАКТА',
        Component: (props) => <DateField isEdit={isEdit} {...props} />,
      },
      {
        name: 'participationInTheNationalTeam',
        label: 'УЧАСТИЕ В НАЦИОНАЛЬНОЙ СБОРНОЙ',
        type: 'autocomplete',
        options: NATIONAL_TEAM_OPTIONS,
      },
    ],
    firstRepresentativeFields: [
      {name: 'parentName', label: 'ПРЕДСТАВИТЕЛЬ 1'},
      {
        name: 'parentPhone',
        label: 'ТЕЛЕФОН ПРЕДСТАВИТЕЛЯ',
        Component: (props) => <PhoneField isEdit={isEdit} {...props} />,
      },
      {
        name: 'parentEmail',
        label: 'E-MAIL',
        pattern: emailPattern,
      },
      {
        name: 'parentsAdress',
        label: 'ГОРОД',
      },
    ],
    secondRepresentativeFields: [
      {name: 'secondRepresentativeName', label: 'ПРЕДСТАВИТЕЛЬ 2'},
      {
        name: 'secondRepresentativePhone',
        label: 'ТЕЛЕФОН ПРЕДСТАВИТЕЛЯ',
        Component: (props) => <PhoneField isEdit={isEdit} {...props} />,
      },
      {
        name: 'secondRepresentativeEmail',
        label: 'E-MAIL',
        pattern: emailPattern,
      },
      {
        name: 'parentsAdress',
        label: 'ГОРОД',
      },
    ],
  };
}
