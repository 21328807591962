import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import {LightButton} from '../../ui/Button';
import Icon from '../../ui/Icon/Icon';
import TrainingPlan from './TrainingPlanItem';

const TaskList = (props) => {
  //ToDo
  //Rename components && variables

  const {
    tasks = [],
    onChange,
    formatDateTime = (dateTime) => moment(dateTime).format('DD.MM.YYYY'),
    parseDateTime = (dateTime) => moment(dateTime, 'DD.MM.YYYY').toDate(),
    showErrorMessage = true,
  } = props;

  const emptyLesson = {
    date: formatDateTime(new Date()),
  };

  const emptyTrainingPlan = {
    title: '',
    tasks: [{...emptyLesson}],
  };

  const addNewTrainingPlan = () => {
    onChange((prev) => [...prev, {...emptyTrainingPlan}]);
  };

  const deleteTrainingPlan = (index) => {
    onChange((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const handleTrainingPlanChange = (index, name, value) => {
    onChange((prev) => {
      prev[index][name] = value;
      return [...prev];
    });
  };

  return (
    <TrainingPlansWrapper>
      <TrainingPlansContainer>
        {tasks.length ? (
          tasks.map((trainingPlan, index) => (
            <TrainingPlan
              addNewTrainingPlan={addNewTrainingPlan}
              deleteTrainingPlan={deleteTrainingPlan}
              emptyLesson={emptyLesson}
              formatDateTime={formatDateTime}
              handleTrainingPlanChange={handleTrainingPlanChange}
              index={index}
              key={index}
              parseDateTime={parseDateTime}
              showErrorMessage={showErrorMessage}
              trainingPlan={trainingPlan}
            />
          ))
        ) : (
          <ButtonWrapper>
            <LightButton type="button" onClick={addNewTrainingPlan}>
              <IconContainer>
                <Icon height="12" type="Plus" viewBox="0 0 12 12" width="12" />
              </IconContainer>
              Добавить задачу
            </LightButton>
          </ButtonWrapper>
        )}
      </TrainingPlansContainer>
    </TrainingPlansWrapper>
  );
};

export default TaskList;

const TrainingPlansWrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  box-sizing: border-box;
`;
const TrainingPlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 12px;
  height: 22px;

  @media screen and (max-width: 320px) {
    left: 24px;
  }
`;
const ButtonWrapper = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  ${LightButton} {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 38px;
    margin-top: 0;
    margin-bottom: 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 320px;
    height: 24px;
    font: normal normal 14px/25px 'Proxima Nova';
    color: #747474;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      max-width: none;
    }
    @media screen and (max-width: 360px) {
      font-size: 12px !important;
    }

    svg {
      right: auto;
      path {
        fill: #c4c4c4;
      }
    }
  }
`;
