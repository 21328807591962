import {Stack as MuiStack} from '@mui/material';
import {StackProps} from '@mui/material/Stack/Stack';
import React, {forwardRef} from 'react';

function Stack(
  {children = null, ...props}: StackProps,
  ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined,
) {
  return (
    <MuiStack ref={ref} {...props}>
      {children}
    </MuiStack>
  );
}

export default forwardRef(Stack);
