import {Box, Typography} from '@ui/MUI';
import TypographyDefault from '@ui/MUI/Typography';
import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  img {
    width: 100%;
    display: block;
  }
`;

export const Map = styled(Box)`
  margin: 0 auto;
  position: relative;
`;

export const Grid = styled.div`
  padding: 13% 0 10%;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
`;
export const Cell = styled.div`
  width: calc(100% / 3);
  height: calc(100% / 3);

  &:nth-child(2) .cell__inner {
    align-items: flex-end;
  }
`;

export const Percent = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  ${({theme}) => theme.breakpoints.down('smallLaptop')} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({theme}) => theme.breakpoints.down('xs')} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const Score = styled(TypographyDefault)`
  ${({theme}) => theme.typography.h4}
  ${({theme}) => theme.breakpoints.down('xs')} {
    font-size: 14px;
    line-height: 16px;
  }
`;
