import * as locales from '@mui/material/locale';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import React from 'react';

import {grey, primary, secondary} from './colors';

export const themeConfigObj = createTheme(
  {
    spacing: [4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 80, 96],
    breakpoints: {
      values: {
        mobileM: 375,
        xxs: 0,
        xs: 576,
        sm: 768,
        md: 1025,
        smallLaptop: 1280,
        lg: 1440,
        xl: 1920,
      },
    },
    palette: {
      primary: {...primary},
      secondary: {...secondary},
      secondaryInverse: {
        contrastText: '#EFEFEF',
        dark: '#D1D1D1',
        main: '#2C2C2C',
        light: '#B3B3B3',
      },
      text: {
        primary: 'rgba(0, 0, 0, .87)',
        light: '#fff',
        error: 'rgba(213, 61, 51, 1)',
        disabled: 'rgba(0, 0, 0, .4)',
      },
      white: {
        main: '#fff',
      },
      gray: {
        main: '#00000099',
        contrastText: '#FFFFFF',
      },
      neutral: {
        main: '#F5F5F5',
      },
      darkText: {
        main: 'rgba(0, 0, 0, .87)',
      },
      loadingCircle: {
        primary: 'rgba(0, 0, 0, 0.26)',
      },
      error: {
        main: '#C05B54',
      },
      success: {
        main: '#4BA849',
      },
      warning: {
        main: '#FBAA0C',
      },
      info: {
        main: '#1976D2',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            textDecorationColor: 'inherit !important',
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            alignSelf: 'flex-end',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: ({error, theme}) => ({
            color: error && theme.palette.error.main,
            '.MuiRadio-root, .MuiTypography-root': {
              color: 'inherit',
            },
          }),
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            position: 'relative',
            borderRadius: 4,
            textTransform: 'uppercase',
          },
        },
        variants: [
          {
            props: {size: 'large'},
            style: ({theme}) => {
              const {fontWeight, fontSize, lineHeight, fontFamily, textTransform} = theme.typography.overline0;
              return {
                padding: theme.spacing(1, 4),
                ...{fontWeight, fontSize, lineHeight, fontFamily, textTransform},
              };
            },
          },
          {
            props: {size: 'medium'},
            style: ({theme}) => {
              const {fontWeight, fontSize, lineHeight, fontFamily, textTransform} = theme.typography.overline1;
              return {
                padding: theme.spacing(1, 3),
                ...{fontWeight, fontSize, lineHeight, fontFamily, textTransform},
              };
            },
          },
          {
            props: {size: 'small'},
            style: ({theme}) => {
              const {fontWeight, fontSize, lineHeight, fontFamily, textTransform} = theme.typography.overline2;
              return {
                padding: theme.spacing(1, 2),
                ...{fontWeight, fontSize, lineHeight, fontFamily, textTransform},
              };
            },
          },
        ],
      },
      MuiAutocomplete: {
        variants: [
          {
            props: {readOnly: true},
            style: {
              pointerEvents: 'none',
            },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '.MuiInputLabel-root': {
              color: grey['42'],
            },
          },
        },
        variants: [
          {
            props: {readOnly: true},
            style: {
              pointerEvents: 'none',
              '.MuiFormLabel-root': {
                pointerEvents: 'none',
              },
            },
          },
        ],
      },
      MuiInput: {
        variants: [
          {
            props: {variant: 'standard'},
            style: {
              width: '100%',
              borderColor: 'rgba(0, 0, 0, .42)',
              font: '400 normal 16px/24px "Proxima Nova"',
              color: 'rgba(0, 0, 0, 0.6)',
              letterSpacing: '.15px',
            },
          },
        ],
      },
      MuiSelect: {
        variants: [
          {
            props: {variant: 'standard'},
            style: {
              width: '100%',
              borderColor: 'rgba(0, 0, 0, .42)',
              font: '400 normal 16px/24px "Proxima Nova"',
              color: 'rgba(0, 0, 0, 0.6)',
              letterSpacing: '.15px',
            },
          },
        ],
      },
      MuiCheckbox: {
        variants: [
          {
            props: {variant: 'standard', color: 'secondary'},
            font: '400 normal 16px/24px "Proxima Nova"',
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: '.15px',
          },
        ],
      },
      MuiLink: {
        variants: [
          {
            props: {variant: 'button'},
            style: {
              textTransform: 'uppercase',
              font: '600 normal 14px/22px "Proxima Nova"',
              color: this?.palette.text.secondary,
              cursor: 'pointer',
            },
          },
          {
            props: {variant: 'text'},
            style: {
              font: '400 normal 14px/22px "Proxima Nova"',
              color: this?.palette.text.primary,
            },
          },
        ],
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            paddingRight: 12,
            paddingLeft: 12,
            font: "600 normal 14px/16px 'Proxima Nova'",
            color: '#2c2c2c',
            letterSpacing: '0.02em',
            textTransform: 'uppercase',
          },
          indicator: {
            backgroundColor: '#C62828',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          label: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            color: grey[68],
            letterSpacing: '0.1px',
          },
          icon: {
            marginLeft: '8px',
          },
          sizeSmall: {
            minHeight: '26px',
            paddingRight: '4px',
          },
          outlined: {
            border: `1px solid ${grey[12]}`,
          },
        },
      },
    },
  },
  locales['ruRU'],
);

themeConfigObj.typography = {
  ...themeConfigObj.typography,
  fontFamily: 'Proxima Nova, Arial, sans-serif',
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '32px',
    fontFamily: 'Bebas Neue',
    textTransform: 'uppercase',
    color: themeConfigObj.palette.text.primary,
    [themeConfigObj.breakpoints.down('sm')]: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '30px',
    },
  },
  h2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '20px',
    fontFamily: 'Bebas Neue',
    textTransform: 'uppercase',
    color: themeConfigObj.palette.text.primary,
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 20,
      lineHeight: '20px',
    },
  },
  h3: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24px',
    fontFamily: 'Proxima Nova',
    textTransform: 'uppercase',
    color: themeConfigObj.palette.text.primary,
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '22px',
    },
  },
  h4: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Proxima Nova',
    textTransform: 'uppercase',
    color: themeConfigObj.palette.text.primary,
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '22px',
    },
  },
  body1: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Proxima Nova',
    color: themeConfigObj.palette.text.primary,
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '22px',
    },
  },
  body2: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'Proxima Nova',
    color: themeConfigObj.palette.text.primary,
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Proxima Nova',
    color: themeConfigObj.palette.text.primary,
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'Proxima Nova',
    color: themeConfigObj.palette.text.primary,
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  caption1: {
    display: 'block',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: 'Proxima Nova',
    color: themeConfigObj.palette.text.primary,
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
  },
  caption2: {
    display: 'block',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: 'Proxima Nova',
    color: themeConfigObj.palette.text.primary,
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
  },
  overline0: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Proxima Nova',
    color: themeConfigObj.palette.text.primary,
    textTransform: 'uppercase',
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  overline1: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '22px',
    fontFamily: 'Proxima Nova',
    color: themeConfigObj.palette.text.primary,
    textTransform: 'uppercase',
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  overline2: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '20px',
    fontFamily: 'Proxima Nova',
    color: themeConfigObj.palette.text.primary,
    textTransform: 'uppercase',
    [themeConfigObj.breakpoints.down('sm')]: {
      fontSize: 11,
      lineHeight: '16px',
    },
  },
};

export default function Theme({children}) {
  return <ThemeProvider theme={themeConfigObj}>{children}</ThemeProvider>;
}
