import ChessTemplate from '@components/templates/ChessTemplate';
import {useRoleAccess} from '@hooks/useRoleAccess';
import {Add} from '@mui/icons-material';
import {fetchMaterials, fetchVideoAnalytics, updateFormVisability} from '@slices/materials';
import {userRoles} from '@src/constants';
import Box from '@ui/MUI/Box';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import ControlledTabs from '../../components/ControlledTabs';
import Form from '../../components/Form';
import {defaultPage, maxItemsLimit} from '../../constants';
import {getSlugs} from '../../helpers';
import {useCurrentBreakPoint} from '../../hooks';
import {ButtonContainer, StyledButton} from '../../styles';
import CategoryTab from '../../tabs/CategoryTab';
import VideoAnalytics from '../../tabs/VideoAnalytics';

const MaterialsPage = () => {
  const breakPoint = useCurrentBreakPoint();

  const [filters, setFilters] = useState({category: 'all', group: null, school: null, tags: []});
  const isVideoAnalytics = filters.category === 'video_analytics';
  const cantAdd = useRoleAccess([userRoles.student]);

  const dispatch = useDispatch();

  const fetch = () => {
    const payload = {
      ...filters,
      category: filters.category === 'all' ? null : filters.category,
      fileAndLinksLimit: maxItemsLimit.links_and_files[breakPoint],
      imagesLimit: maxItemsLimit.images[breakPoint],
      videosLimit: maxItemsLimit.video[breakPoint],
      limit: maxItemsLimit.video_analytics[breakPoint],
      page: 1,
      school: filters?.school?.id,
      group: filters.group?.id,
      tags: getSlugs(filters.tags),
    };

    dispatch(isVideoAnalytics ? fetchVideoAnalytics(payload) : fetchMaterials(payload));
  };

  const openForm = () => {
    dispatch(updateFormVisability(true));
  };

  useEffect(() => {
    if (breakPoint) {
      fetch();
    }
  }, [filters, breakPoint]);

  return (
    <ChessTemplate active="materials">
      <Form refetcher={fetch} />
      <PageTitleDefault breadCrumbs={defaultPage.breadCrumbs} text={defaultPage.title} />
      <Box>
        {!cantAdd && (
          <ButtonContainer breakpoint={breakPoint}>
            <StyledButton startIcon={<Add />} variant="contained" onClick={openForm}>
              Добавить материал
            </StyledButton>
          </ButtonContainer>
        )}
        <Box mb={3} mt={4}>
          <ControlledTabs value={filters.category} onChange={(value) => setFilters({...filters, category: value})} />
        </Box>
        {isVideoAnalytics ? <VideoAnalytics filters={filters} onFiltersChange={setFilters} /> : <CategoryTab />}
      </Box>
    </ChessTemplate>
  );
};

export default MaterialsPage;
