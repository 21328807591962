import TextFieldDefault from '@ui/MUI/TextField';
import React from 'react';

const onlyDigits = /\D/g;

const NumberInput = ({label, onChange, value = '', min, max, maxLength, ...rest}) => {
  const handleChange = (e) => {
    const value = e.target.value.replace(onlyDigits, '');
    const valueAsNumber = Number(value);

    if (value === '') return onChange(value);
    if (max) {
      if (valueAsNumber > max) return;
    }
    if (min) {
      if (valueAsNumber < min) return;
    }

    if (maxLength) {
      if (maxLength < value.length) return;
    }
    onChange(value);
  };

  return <TextFieldDefault {...rest} label={label} value={value} onChange={handleChange} />;
};

export default NumberInput;
