import useStore from '@hooks/useStore';
import {userRoles} from '@src/constants';

export function usePageTitle() {
  const userRole = useStore((state) => state?.users?.currentUser?.userRole);
  const isStudent = userRole === userRoles.student;
  let pageTitle = 'Контроль тренировочного процесса';
  if (isStudent) {
    pageTitle = 'Тренировки';
  }
  return pageTitle;
}

export function useTrainingControlProcessBreadCrumbs() {
  let breadCrumbs = [
    {
      label: 'Главная',
      path: '/',
    },
    {
      label: 'Контроль тренировочного процесса',
      path: '/',
    },
  ];
  const userRole = useStore((state) => state?.users?.currentUser?.userRole);
  const isStudent = userRole === userRoles.student;
  if (isStudent) {
    breadCrumbs = [
      {
        label: 'Главная',
        path: '/',
      },
      {
        label: 'Тренировки',
        path: '/',
      },
    ];
  }
  return breadCrumbs;
}
