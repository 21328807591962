import {getPastGamesOfSeason} from '@actions/PsychoProfileActions';
import LocalPreloader from '@components/preloader/LocalPreloader';
import PastGamesOfSeason from '@components/widgets/PastGamesOfSeason';
import React, {memo, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import PsychoTabs from '../../psycho-profile/components/PsychoTabs';

const fonts = {
  primary: 'Proxima Nova',
  secondary: 'Bebas Neue',
};
const Widget = (props) => {
  const {headerTitle = '', withTabs = false, type} = props;

  const [groupId, setGroupId] = useState();

  const dispatch = useDispatch();
  const handelChange = useCallback((value) => {
    setGroupId(value);
    //TODO: перенести на нормальную точку на бэке, когда отрефакторим дашборды
    dispatch(getPastGamesOfSeason({pastGamesFilter: value}));
  }, []);

  const pastGames = useSelector((state) => state.psychoProfile.pastGames);

  const getWidgetFromType = (type) => {
    if (type === 'pastGames') {
      return (
        <Wrapper isLoading={pastGames?.isLoading}>
          <LocalPreloader visible={pastGames?.isLoading} />
          <PastGamesOfSeason
            groupId={groupId}
            hasHeadlineBlock={false}
            isFranchisee={false}
            isWithoutButtons={true}
            pastGames={pastGames?.data}
          />
        </Wrapper>
      );
    }
  };

  return (
    <Container>
      <Header>{headerTitle.toUpperCase()}</Header>
      {withTabs && <PsychoTabs onChange={handelChange} />}
      {getWidgetFromType(type) || <EmtyBlock>Данные отсутствуют</EmtyBlock>}
    </Container>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-height: ${(props) => (props.isLoading ? '250px' : 'initial')};
`;

const EmtyBlock = styled.div`
  display: flex;
  padding: 50px 0;
  font-size: 20px;
  width: 100%;
  font-weight: bold;
  color: #2c2c2c;
  font-family: ${fonts.secondary};
  justify-content: center;
`;

const Container = styled.div`
  flex: 0 0 50%;
  max-width: 100%;
  background: white;
  margin-bottom: 10px;
  border-radius: 6px;
  overflow: hidden;
`;

const Header = styled.div`
  font-family: 'Bebas Neue';
  padding: 12px 24px;
  background: #fffaed;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #dddad4;
  border-radius: 6px 6px 0 0;
`;

export default memo(Widget);
