import {getOpponents} from '@api/videoAnalytics';
import useDeepEffect from '@hooks/useDeepEffect';
import {useState} from 'react';

export function useOpponents({seasons, groups}) {
  const [opponents, setOpponents] = useState({isLoading: false, data: []});
  useDeepEffect(() => {
    setOpponents({data: [], isLoading: true});
    getOpponents({seasons: seasons, groups: groups}).then(({data}) => {
      setOpponents({isLoading: false, data: data});
    });
  }, [seasons, groups]);
  return opponents;
}
