import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

const Block = ({children, title = '', spacing = '4px', flex, sx, ...props}) => {
  return (
    <Stack {...props} flex={flex} spacing={spacing} sx={sx}>
      <TypographyDefault variant="subtitle1">{title}</TypographyDefault>
      {children}
    </Stack>
  );
};

export default Block;
