import {useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';

import useEvent from './useEvent';

const useCustomForm = (defaultValue) => {
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm({mode: 'onChange', defaultValues: defaultValue});
  const id = methods.watch('id');
  const isEdit = Boolean(id);

  const open = useEvent((payload) => {
    if (payload && !payload?.target) {
      methods.reset(payload);
    }
    setIsOpen(true);
  });

  const close = useEvent(() => {
    methods.reset(defaultValue);
    setIsOpen(false);
  });

  return useMemo(() => ({isOpen, methods, open, close, isEdit}), [isOpen, methods, open, close, isEdit]);
};

export default useCustomForm;
