import SkillItem from '@common/TrainingControl/SkillItem';
import Stack from '@ui/MUI/Stack';
import React from 'react';

import Block from '../Block';

const SkillsBlock = ({skills = []}) => {
  return (
    <Block spacing={2} title="Навыки">
      <Stack spacing={2}>
        {skills.length === 0
          ? '-'
          : skills.map((skill) => {
              return <SkillItem {...skill} key={skill.id} />;
            })}
      </Stack>
    </Block>
  );
};

export default SkillsBlock;
