import React from 'react';

function ValueDownArrow() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M0.692163 5.77016L4.85909 9.93918C4.89826 9.97835 4.95115 10 5.00654 10C5.06193 10 5.11485 9.97792 5.15399 9.93877L9.30845 5.77016C9.36801 5.71059 9.38551 5.62105 9.35343 5.54315C9.32094 5.46526 9.24513 5.41485 9.16099 5.41485H6.8701L6.8701 0.208251C6.8701 0.0932894 6.77679 -1.90735e-05 6.66183 -1.90735e-05H3.3296C3.21464 -1.90735e-05 3.12133 0.0932894 3.12133 0.208251V5.41487H0.839184C0.755052 5.41487 0.679237 5.46569 0.646748 5.54358C0.614259 5.62146 0.632593 5.71059 0.692163 5.77016Z"
          fill="#990011"
        />
      </g>
    </React.Fragment>
  );
}
export default ValueDownArrow;
