import AdapterMoment from '@mui/lab/AdapterMoment';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DateTimePicker as DateTimePickerMui} from '@mui/x-date-pickers/DateTimePicker';
import {DateTimePickerProps} from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';
import {Moment} from 'moment';
import React from 'react';
import {createGlobalStyle} from 'styled-components';

import ButtonDefault from '../Button';
import Stack from '../Stack';
import TextFieldDefault from '../TextField';

type DateTimePickerCustomProps = Pick<TextFieldProps, 'size' | 'variant' | 'helperText' | 'error'> &
  DateTimePickerProps<Moment, AdapterMoment> & {cancelText: string; okText: string};

const DateTimePicker = ({
  label,
  value = null,
  onChange = () => {},
  open,
  onClose,
  onAccept,
  toolbarTitle = '',
  inputFormat = 'DD.MM.yyyy HH:mm',
  mask = '__.__.____ __:__',
  error,
  helperText,
  variant,
  disablePast = true,
  cancelText = 'Отменить',
  okText = 'Выбрать',
  renderInput,
  ...props
}: DateTimePickerCustomProps) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <GlobalStyle />
        <DateTimePickerMui
          components={{
            ActionBar: (params) =>
              params?.actions?.length ? (
                <Stack direction="row" gap={2} justifyContent="space-around" p={2}>
                  <ButtonDefault color="primary" onClick={params.onCancel}>
                    {cancelText}
                  </ButtonDefault>
                  <ButtonDefault color="primary" variant="contained" onClick={params.onAccept}>
                    {okText}
                  </ButtonDefault>
                </Stack>
              ) : null,
          }}
          disablePast={disablePast}
          inputFormat={inputFormat}
          label={label}
          mask={mask}
          open={open}
          renderInput={
            renderInput ||
            ((params) => {
              return (
                <TextFieldDefault
                  {...params}
                  error={error}
                  helperText={helperText}
                  inputProps={{...params.inputProps, placeholder: mask}}
                  variant={variant}
                />
              );
            })
          }
          showDaysOutsideCurrentMonth={true}
          showToolbar={false}
          toolbarTitle={toolbarTitle}
          value={value}
          onAccept={onAccept}
          onChange={onChange}
          onClose={onClose}
          {...props}
        />
      </LocalizationProvider>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  .MuiCalendarPicker {
    &-root {
      .Mui-selected {
        background: #e12 !important;
      }
    }
  }
`;

export default DateTimePicker;
