import ContentWrapper from '@common/ContentWrapper';
import Filters from '@common/Statistics/ThrowsMap/Filters';
import ShotVideoModal from '@common/Statistics/ThrowsMap/ShotVideoModal';
import ThrowsCard from '@common/Statistics/ThrowsMap/ThrowsCard';
import ThrowsDonutCharts from '@common/Statistics/ThrowsMap/ThrowsDonutCharts';
import useStore from '@hooks/useStore';
import {selectMetricStatistics, selectShotsMapFilters, selectVideoModal} from '@selectors/gameStatistics';
import {setVideoModal} from '@slices/statistics';
import {
  clearMetricStatistics,
  clearShotsMapFilters,
  loadMetricStatistics,
  setShotsMapFilters,
} from '@slices/statistics/shotsMap';
import {parseFormToGetValue} from '@src/helpers';
import Stack from '@ui/MUI/Stack';
import PropTypes from 'prop-types';
import React, {memo, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

function ThrowsStatistics({hasOT, weAtHome}) {
  useEffect(() => {
    window.ym(80266396, 'reachGoal', 'statistika_broski');
  }, []);

  const statisticIds = useParams().statisticId;
  const filter = useStore(selectShotsMapFilters);
  return (
    <Stack spacing={1}>
      <ContentWrapper>
        <Filters
          cleanup={clearShotsMapFilters}
          getter={selectShotsMapFilters}
          hasOT={hasOT}
          setter={setShotsMapFilters}
        />
      </ContentWrapper>
      <Wrapper>
        <ThrowsDonutCharts
          cleanup={clearMetricStatistics}
          filters={{statisticsIds: statisticIds ? [statisticIds] : [], ...parseFormToGetValue(filter)}}
          getter={selectMetricStatistics}
          loader={loadMetricStatistics}
          weAtHome={weAtHome}
        />
      </Wrapper>
      <Wrapper>
        <ThrowsCard
          filters={{...parseFormToGetValue(filter)}}
          setModalState={setVideoModal}
          statisticsIds={statisticIds ? [statisticIds] : []}
          weAtHome={weAtHome}
        />
        <ShotVideoModal stateGetter={selectVideoModal} stateSetter={setVideoModal} />
      </Wrapper>
    </Stack>
  );
}

ThrowsStatistics.propTypes = {hasOT: PropTypes.bool};

export default memo(ThrowsStatistics);

const Wrapper = styled.div`
  position: relative;
  padding: ${({theme}) => theme.spacing(6, 4)};
  height: ${(props) => (props.isLoading === true ? '75vh' : 'initial')};
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  ${({theme}) => ({
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  })};
  ${({theme}) => ({
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  })};
`;
