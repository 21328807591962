import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {Box, Stack, Typography} from '@ui/MUI';
import React from 'react';

function Tournaments() {
  return (
    <Wrapper p="0">
      <Box p={{xxs: 3, xs: 4}} pb={0}>
        <Typography variant="h4">Турниры</Typography>
      </Box>
      <Stack alignItems="center" height={200} justifyContent="center" p={4}>
        <Typography variant="subtitle1">Раздел пока в работе, данные появятся позже.</Typography>
      </Stack>
    </Wrapper>
  );
}

export default Tournaments;
