import React from 'react';

const Zip = () => {
  return (
    <>
      <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_8309_7343)">
          <path
            d="M4.66675 1.33325V8.66659H6.66675V14.6666L11.3334 6.66659H8.66675L11.3334 1.33325H4.66675Z"
            fill="#990011"
          />
        </g>
        <defs>
          <filter
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
            height="15.3333"
            id="filter0_d_8309_7343"
            width="8.66675"
            x="3.66675"
            y="0.333252"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_8309_7343" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_8309_7343" mode="normal" result="shape" />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default Zip;
