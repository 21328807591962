import Pagination from '@ui/MUI/Pagination';
import styled from 'styled-components';

export const RoundedBox = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: ${(props) => props.isLoading && '70vh'};
  margin-bottom: ${(props) => props.marginBottom || '8px'};
  background: #ffffff;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 16px;
  box-sizing: border-box;
`;

export const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    color: #fff;
  }
`;
