import UsersAPI from '@api/UsersAPI';
import Chart from '@components/apps/PlayerPersonalFile/components/OFP/components/Chart';
import Filter from '@components/apps/PlayerPersonalFile/components/OFP/components/Filter';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {errorMessageNotification} from '@helpers/CommonHelper';
import useDeepEffect from '@hooks/useDeepEffect';
import {useMediaQuery} from '@mui/material';
import {Box, Stack, Typography} from '@ui/MUI';
import {darkRed, green, grey, primary} from '@ui/MUI/colors';
import TabsNew from '@ui/MUI/TabsNew';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router';

import {svg, TITLES_MATRIX} from './constants';
import {usePlayerPersonalOfpResults} from './hooks';

function OFP() {
  const isMdResolution = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const {id} = useParams();

  const [filter, setFilter] = useState({hasTheBestResults: false});

  const [groupId, setGroupId] = useState();
  useEffect(() => {
    if (id) {
      UsersAPI.getUserV2(id)
        ?.then(({data}) => {
          setGroupId(data?.group?.id);
        })
        ?.catch((err) => {
          return errorMessageNotification(err, '');
        });
    }
  }, [id]);
  const {results} = usePlayerPersonalOfpResults(id, {
    groupId: groupId,
    season: filter?.season?.map(({value}) => value)?.join(','),
    type: filter?.type?.value,
    categoryId: filter?.categoryId?.value,
  });
  const [exercises, setExercises] = useState([]);
  useDeepEffect(() => {
    if (results?.length > 0 && filter?.categoryId) {
      setExercises([...new Map(results?.map((result) => [result['exerciseId'], result])).values()]);
      setActiveTab(0);
    } else {
      setExercises([]);
    }
  }, [results]);
  const tabs = useMemo(
    () =>
      !exercises?.length
        ? []
        : exercises?.map(({exerciseName, exerciseId}, index) => {
            const doubleParamsIds = ['ypjjV_ydGQrE', 'Kf3AdBTfKHZI', 'cc_yDyptaY6b', 'wMhqP7HzsSgC'];
            const isInverse = exerciseName?.includes('сек');
            const isBeepTest = exerciseId === 'cc_yDyptaY6b';
            const isYbalance = exerciseId === 'wMhqP7HzsSgC';
            return {
              key: index,
              value: !results?.filter((item) => item.exerciseId === exerciseId)?.length ? (
                <TableEmptyBlock text="Нет данных по показателю за данный период." />
              ) : (
                <Chart
                  exerciseId={exerciseId}
                  options={{
                    isInverse: isInverse,
                    isDoubleParam: doubleParamsIds?.includes(exerciseId),
                    series: filter?.hasTheBestResults
                      ? [
                          {
                            data: !doubleParamsIds?.includes(exerciseId)
                              ? results?.filter((item) => item.exerciseId === exerciseId)?.map(({value}) => value) || []
                              : results
                                  ?.filter((item) => item.exerciseId === exerciseId)
                                  ?.map(({additionParam, value}) => {
                                    if (isYbalance) {
                                      return +Number(additionParam?.value + value).toFixed(2);
                                    } else if (isBeepTest) {
                                      return `${additionParam?.value}.${value}`;
                                    } else {
                                      return additionParam?.value;
                                    }
                                  }) || [],
                            type: 'line',
                            color: darkRed[65],
                            symbolSize: 8,
                            markPoint: {
                              data: !doubleParamsIds?.includes(exerciseId)
                                ? results
                                    ?.filter((item) => item.exerciseId === exerciseId)
                                    ?.map(({value}, index) => ({value: value, yAxis: value, xAxis: index})) || []
                                : results
                                    ?.filter((item) => item.exerciseId === exerciseId)
                                    ?.map(({additionParam, value}, index) => {
                                      if (isYbalance) {
                                        return {
                                          value: +Number(additionParam?.value + value).toFixed(2),
                                          yAxis: +Number(additionParam?.value + value).toFixed(2),
                                          xAxis: index,
                                        };
                                      }
                                      return {
                                        value: isBeepTest ? `${additionParam?.value}.${value}` : additionParam?.value,
                                        yAxis: isBeepTest ? `${additionParam?.value}.${value}` : additionParam?.value,
                                        xAxis: index,
                                      };
                                    }) || [],
                              symbol: 'circle',
                              symbolSize: 36,
                              itemStyle: {
                                borderColor: darkRed[100],
                                padding: 4,
                                borderWidth: 2,
                                borderRadius: 16,
                                color: primary.contrastText,
                              },
                            },
                          },
                          {
                            data: !doubleParamsIds?.includes(exerciseId)
                              ? results
                                  ?.filter((item) => item.exerciseId === exerciseId)
                                  ?.map(({maxValue}) => maxValue) || []
                              : results
                                  ?.filter((item) => item.exerciseId === exerciseId)
                                  ?.map(({additionParam, maxValue}) => {
                                    if (isYbalance) {
                                      return +Number(additionParam?.maxValue + maxValue).toFixed(2);
                                    }
                                    return isBeepTest
                                      ? `${additionParam?.maxValue}.${maxValue}`
                                      : additionParam?.maxValue;
                                  }) || [],
                            type: 'line',
                            color: green[60],
                            symbolSize: 8,
                          },
                          {
                            data: !doubleParamsIds?.includes(exerciseId)
                              ? results
                                  ?.filter((item) => item.exerciseId === exerciseId)
                                  ?.map(({averageValue}) => averageValue) || []
                              : results
                                  ?.filter((item) => item.exerciseId === exerciseId)
                                  ?.map(({additionParam, averageValue}) => {
                                    if (isYbalance) {
                                      return +Number(additionParam?.averageValue + averageValue).toFixed(2);
                                    }
                                    return isBeepTest
                                      ? `${additionParam?.averageValue}.${averageValue}`
                                      : additionParam?.averageValue;
                                  }) || [],
                            type: 'line',
                            color: grey[24],
                            symbolSize: 8,
                          },
                        ]
                      : [
                          {
                            data: !doubleParamsIds?.includes(exerciseId)
                              ? results?.filter((item) => item.exerciseId === exerciseId)?.map(({value}) => value) || []
                              : results
                                  ?.filter((item) => item.exerciseId === exerciseId)
                                  ?.map(({additionParam, value}) => {
                                    if (isYbalance) {
                                      return +Number(value + additionParam?.value).toFixed(2);
                                    }
                                    return isBeepTest ? `${additionParam?.value}.${value}` : additionParam?.value;
                                  }) || [],
                            type: 'line',
                            color: darkRed[65],
                            symbolSize: 8,
                            markPoint: {
                              data: !doubleParamsIds?.includes(exerciseId)
                                ? results
                                    ?.filter((item) => item.exerciseId === exerciseId)
                                    ?.map(({value}, index) => ({value: value, yAxis: value, xAxis: index})) || []
                                : results
                                    ?.filter((item) => item.exerciseId === exerciseId)
                                    ?.map(({additionParam, value}, index) => {
                                      if (isYbalance) {
                                        return {
                                          value: +Number(value + additionParam?.value).toFixed(2),
                                          yAxis: +Number(value + additionParam?.value).toFixed(2),
                                          xAxis: index,
                                        };
                                      }
                                      return {
                                        value: isBeepTest ? `${additionParam?.value}.${value}` : additionParam?.value,
                                        yAxis: isBeepTest ? `${additionParam?.value}.${value}` : additionParam?.value,
                                        xAxis: index,
                                      };
                                    }) || [],
                              symbol: 'circle',
                              symbolSize: 36,
                              itemStyle: {
                                borderColor: darkRed[100],
                                padding: 4,
                                borderWidth: 2,
                                borderRadius: 16,
                                color: primary.contrastText,
                              },
                            },
                          },
                        ],
                    xAxis: {
                      data: !doubleParamsIds?.includes(exerciseId)
                        ? results
                            ?.filter((item) => item.exerciseId === exerciseId)
                            ?.map(({stage, season}) => {
                              return `Этап ${stage};(${season})`;
                            }) || []
                        : results
                            ?.filter((item) => item.exerciseId === exerciseId)
                            ?.map(({additionParam, value}) => {
                              return `Этап ${additionParam?.stage} (${additionParam?.season});${
                                filter?.type?.value === 'sfp' ? value : ''
                              }`;
                            }) || [],
                    },
                  }}
                  title={{y: TITLES_MATRIX?.[exerciseId]?.y || '', x: TITLES_MATRIX?.[exerciseId]?.x || ''}}
                />
              ),
              label: !doubleParamsIds?.includes(exerciseId) ? exerciseName : exerciseName?.split('(')?.[0],
            };
          }),
    [exercises, filter?.hasTheBestResults],
  );
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Stack direction="column" mr={5} spacing={1}>
      <Filter filter={filter} setFilter={setFilter} />
      <Wrapper sx={{p: '0px'}}>
        {!tabs?.length ? (
          <TableEmptyBlock
            text={!filter?.categoryId ? 'Выберите группу навыков.' : 'Отсутствуют данные по выбранной группе навыков.'}
          />
        ) : (
          <TabsNew
            tabs={tabs}
            value={activeTab}
            withoutWrapper={false}
            onChange={(_, newValue) => {
              setActiveTab(newValue?.key);
            }}
          />
        )}
      </Wrapper>
      <Wrapper sx={{marginTop: 2}}>
        <Box
          alignItems={isMdResolution ? 'flex-start' : 'center'}
          display="flex"
          flexDirection={isMdResolution && 'column'}
        >
          <Stack sx={{pr: 1, py: isMdResolution && 0}}>
            <Typography variant="subtitle2">Цвет линии</Typography>
          </Stack>
          <Stack sx={{py: isMdResolution && 0, pr: 1, pl: 7, position: 'relative'}}>
            {svg(darkRed[100])}
            <Typography variant="body2">Показатель</Typography>
          </Stack>
          <Stack sx={{position: 'relative', pr: 1, pl: 7, py: isMdResolution && 0}}>
            {svg(green[60])}
            <Typography variant="body2">Лучший показатель по команде</Typography>
          </Stack>
          <Stack sx={{position: 'relative', pl: 7, py: isMdResolution && 0}}>
            {svg(grey[24])}
            <Typography variant="body2">Средний показатель по команде</Typography>
          </Stack>
        </Box>
      </Wrapper>
    </Stack>
  );
}

export default OFP;
