import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import StudentLines from './StudentLines';
import TeacherHockeyLinesApp from './TeacherHockeyLinesApp';

const HockeyLinesApp = () => {
  const {currentUser} = useSelector((state) => {
    return {
      currentUser: state.users.currentUser,
    };
  }, shallowEqual);

  return currentUser.userRole && currentUser.userRole === 'student' ? (
    <StudentLines />
  ) : currentUser.userRole === 'parent' ? (
    <StudentLines />
  ) : (
    <TeacherHockeyLinesApp />
  );
};

export default HockeyLinesApp;
