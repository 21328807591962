import useQueryString from '@hooks/useQueryString';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

import useDeepCallback from './useDeepCallback';

useQueryStringTabsWithSubTabs.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({label: PropTypes.string.isRequired, value: PropTypes.node.isRequired, id: PropTypes.string}),
  ),
};
function useQueryStringTabsWithSubTabs({tabs = []}) {
  const [{tab, subTab, ...rest}, set] = useQueryString();
  const value = useMemo(() => {
    const idIndex = tabs?.findIndex((item, index) => subTab === item.id || Number(subTab) === index);
    return idIndex >= 0 ? idIndex : 0;
  }, [tab, subTab, tabs]);
  const onChange = useDeepCallback(
    (tabIndex) => set({...rest, tab: tab, subTab: tabs[tabIndex]?.id || tabIndex}),
    [rest],
  );

  return {value, onChange};
}

export default useQueryStringTabsWithSubTabs;
