import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';

export function getPlayerDiary(params) {
  return axios.get(`${API_ENDPOINT}/v1/player-diary`, {params});
}

export function postPlayerDiary(
  params,
  config = {
    success: {message: 'Анкета успешно обновлена'},
    failed: {message: 'Ошибка при сохранении анкеты'},
  },
) {
  return axios.post(`${API_ENDPOINT}/v1/player-diary`, params, config);
}

export function getPlayerDiaryReport(params) {
  return axios.get(`${API_ENDPOINT}/v1/player-diary/report`, {params});
}

export function getPlayerDiaryOverall(params) {
  return axios.get(`${API_ENDPOINT}/v1/player-diary/overall`, {params});
}

export function getPlayerDiaryOverallAverage(params) {
  return axios.get(`${API_ENDPOINT}/v1/player-diary/overall/player`, {params});
}

export function getPlayerDiaryList(params) {
  return axios.get(`${API_ENDPOINT}/v1/player-diary/list`, {params});
}
