import ShiftsProgressCell from '@common/Statistics/PlayerStatistics/ShiftsProgressCell';
import TableTemplate from '@common/Statistics/PlayerStatistics/TableTemplate';
import TopFiveItem from '@common/Statistics/PlayerStatistics/TopFiveItem';
import {customTableComparator, customTableTimeComparator} from '@src/helpers';
import {Tooltip, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

import SingleProgressCell from '../../SingleProgressCell';

const columns = [
  {
    accessorKey: 'toi',
    header: () => {
      return (
        <Tooltip title="Время">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            ВР
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      const timePercent =
        (Number(getValue()?.value.split(':')?.[0]) * 60 + Number(getValue()?.value.split(':')?.[1])) / 54;
      return <SingleProgressCell percent={Math.abs(timePercent.toFixed(2))} value={getValue()?.value} />;
    },
    cellStyle: {position: 'relative', textAlign: 'center'},
    sortingFn: customTableTimeComparator,
  },
  {
    accessorKey: 'replayedTimePercent',
    header: () => {
      return (
        <Tooltip title="Переигранное время %">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            ВР%
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return (
        <ShiftsProgressCell
          percent={100}
          replayedTimeWidth={!getValue || getValue() === 0 ? 0 : getValue()}
          value={getValue()}
        />
      );
    },
    cellStyle: {position: 'relative', textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'shifts',
    header: () => {
      return (
        <Tooltip title="Смены">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            См
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
  },
  {
    accessorKey: 'averageShiftDuration',
    header: () => {
      return (
        <Tooltip title="Средняя длина смены">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            СрСм
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return (
        <ShiftsProgressCell
          percent={Math.abs(getValue()?.percent) || 0}
          percentThreshold={55}
          value={getValue()?.value}
        />
      );
    },
    cellStyle: {position: 'relative', textAlign: 'center'},
    sortingFn: customTableTimeComparator,
  },
  {
    accessorKey: 'replayedShifts',
    header: () => {
      return (
        <Tooltip title="Переигранные смены">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            СM-
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'replayedShiftsPercent',
    header: () => {
      return (
        <Tooltip title="Переигранные смены %">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            СМ-%
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    sortable: true,
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
];
function TimeTab({isLoad, isLoading, data, hasError, hasMatchFilter}) {
  const [sorting, setSorting] = useState([{desc: true, id: 'toi'}]);
  const rowsData = useMemo(
    () =>
      data?.map((item) => {
        return {
          student: item?.student,
          toi: item?.toi,
          shifts: item?.shifts,
          averageShiftDuration: item?.averageShiftDuration || {value: '00:00'},
          replayedShifts: item?.replayedShifts,
          replayedShiftsPercent: item?.replayedShiftsPercent || {value: 0},
          replayedTimePercent: item?.replayedTimePercent || 0,
        };
      }),
    [data],
  );

  return (
    <TableTemplate
      columns={columns}
      hasError={hasError}
      hasMatchFilter={hasMatchFilter}
      hasShifts={true}
      isLoad={isLoad}
      isLoading={isLoading}
      rowsData={rowsData}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
}

TimeTab.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TimeTab;
