import RangePicker from '@ui/MUI/RangePicker';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import MedicalProfileAPI from '../../../api/MedicalProfileAPI';
import {NOTIFICATION_TYPES} from '../../../constants/config';
import {generateId} from '../../../helpers/MedicalProfileHelper';
import {handleChangeForm} from '../../../redux/actions/MedicalProfileActions';
import {Button, LightButton} from '../../ui/Button';
import Icon from '../../ui/Icon/Icon';
import InputDefault from '../../ui/Input';
import {ReactComponentNotification} from '../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import SelectDefault from '../../ui/Select';

export default function Training() {
  const medicalProfileForm = useSelector((state) =>
    state.medicalProfile.medicalProfileForm ? state.medicalProfile.medicalProfileForm : {},
  );

  const dispatch = useDispatch();

  const [typesOptions, setTypesOptions] = useState([]);

  useEffect(() => {
    MedicalProfileAPI.getTrainingRecommendations()
      .then((data) => {
        setTypesOptions(data.data);
      })
      .catch(function () {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при загрузке рекомендаций к тренировкам');
      });
  }, []);

  typesOptions.sort((a, b) => {
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    return 0;
  });

  const handleDeleteTraining = (trainingId) => {
    if (!trainingId) return;

    let cloneTrainings = medicalProfileForm.training ? [...medicalProfileForm.training] : [];
    let currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    cloneTrainings.splice(
      cloneTrainings.findIndex((i) => i.idFront === trainingId),
      1,
    );

    currentMedicalProfileForm.training = cloneTrainings;
    dispatch(handleChangeForm(currentMedicalProfileForm));
  };

  const handleAddTraining = () => {
    const cloneTrainings = medicalProfileForm?.training?.length ? [...medicalProfileForm.training] : [];
    const currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    dispatch(
      handleChangeForm({
        ...currentMedicalProfileForm,
        training: [
          ...cloneTrainings,
          {
            idFront: generateId(),
            type: '',
            startDate: '',
            endDate: '',
            comments: '',
          },
        ],
      }),
    );
  };
  const handleTrainingChange = (trainingId, name, value) => {
    const cloneTrainings = medicalProfileForm.training ? [...medicalProfileForm.training] : [];
    const currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};
    dispatch(
      handleChangeForm({
        ...currentMedicalProfileForm,
        training: cloneTrainings.map((i) => {
          if (i.idFront === trainingId) {
            return {...i, [name]: value};
          }
          return i;
        }),
      }),
    );
  };
  const defaultDatePeriod = (id) => {
    const startDate = new Date().getTime();
    let endDate = new Date().getTime() + 86400000;

    handleTrainingChange(id, 'startDate', startDate);
    handleTrainingChange(id, 'endDate', endDate);

    return [startDate, endDate];
  };

  return (
    <Wrapper>
      <Title>Рекомендации по тренировке</Title>
      {medicalProfileForm?.training?.map((training, index) => {
        let period = [];

        if (training?.startDate && training?.endDate) {
          period = [training.startDate, training.endDate];
        }

        return (
          <RecommendationContainer key={index}>
            <Recommendation>
              <Field>
                <Label>Тип рекомендации</Label>
                <SelectDefault
                  errorText="Обязательное поле для заполнения"
                  isInvalid={!typesOptions.filter((option) => option.value === training.type)[0]}
                  isMulti={false}
                  options={typesOptions}
                  placeholder={'Выберите тип рекомендации'}
                  value={
                    typesOptions.filter((option) => option.value === training.type).length
                      ? typesOptions.filter((option) => option.value === training.type)[0]
                      : ''
                  }
                  onChange={(type) => {
                    handleTrainingChange(training.idFront, 'type', type.value);
                  }}
                />
              </Field>
              <Field>
                <Label>Период</Label>
                <RangePicker
                  format={'dd.MM.y'}
                  value={period.length ? period : defaultDatePeriod(training.idFront)}
                  variant="outlined"
                  onChange={(a) => {
                    const startDate = new Date(a[0]).getTime();
                    const endDate = new Date(a[1]).getTime();
                    handleTrainingChange(training.idFront, 'startDate', startDate);
                    handleTrainingChange(training.idFront, 'endDate', endDate);
                  }}
                />
              </Field>
              <Field style={{paddingRight: 100}}>
                <Label>Комментарий</Label>
                <InputDefault
                  errorText="Обязательное поле для заполнения"
                  isInvalid={!training.comments}
                  value={training.comments ? training.comments : ''}
                  onChange={(evt) => {
                    handleTrainingChange(training.idFront, 'comments', evt.target.value);
                  }}
                />
              </Field>
              <Button
                onClick={() => {
                  handleDeleteTraining(training.idFront);
                }}
              >
                <Icon height={17} type={'CloseRed'} viewBox={'0 0 17 17'} width={17} />
              </Button>
            </Recommendation>
          </RecommendationContainer>
        );
      })}
      <LightButton className="ButtonWithIcon mt10" onClick={handleAddTraining}>
        <IconContainer>
          <Icon height="12" type="Plus" viewBox="0 0 12 12" width="12" />
          Добавить рекомендацию к тренировке
        </IconContainer>
      </LightButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  @media screen and (max-width: 767px) {
    padding-bottom: 120px;
  }

  ${LightButton} {
    flex: 0 0 calc(66.666% - 10px);
    max-width: calc(66.666% - 10px);
    width: 100%;

    @media screen and (max-width: 1199px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @media screen and (max-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;
const Title = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  font: 600 normal 16px/1 'Proxima Nova';
  color: #990011;
  letter-spacing: 0.02em;
`;
const RecommendationContainer = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid #c0c0c0;
  margin: 0 -20px;

  :last-of-type {
    border-bottom: none;
  }
  :first-of-type {
    border-bottom: 1px solid #c0c0c0;
  }
`;
const Recommendation = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  ${Button} {
    position: absolute;
    top: 34px;
    right: 0;
    border-radius: 0;
    background-color: transparent;
    z-index: 1;
    @media screen and (min-width: 1201px) {
      right: 36px;
    }
    @media screen and (max-width: 1199px) {
      top: 105px;
    }
    @media screen and (max-width: 768px) {
      top: 172px;
      right: -14px;
    }
  }
`;
const Field = styled.div`
  margin-top: 12px;
  margin-bottom: 0;
  padding-right: 20px;
  padding-left: 20px;
  flex: 0 0 33.333%;
  max-width: 33.333%;

  @media screen and (max-width: 1199px) {
    flex: 0 0 50%;
    max-width: 50%;

    :nth-of-type(3) {
      flex: 0 0 95%;
      max-width: 95%;
    }
  }
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
const Label = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
`;
const IconContainer = styled.div`
  position: relative;
  font: normal normal 12px/25px 'Proxima Nova';
  color: #747474;
  svg {
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
  }
`;
