import {OUTSIDERS} from '@components/apps/StatsReportForOneGame/constants';
import {grey} from '@ui/MUI/colors';
import IconButton from '@ui/MUI/IconButton';
import styled from 'styled-components';

export const LegendDotItem = styled.span`
  white-space: nowrap;
  ${({theme}) => theme.typography.caption1}
  position: relative;
  padding-left: ${({theme}) => theme.spacing(2)};
  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({color}) => color};
  }
`;

export const LegendShiftItem = styled.span`
  white-space: nowrap;
  ${({theme}) => theme.typography.caption1}
  position: relative;
  padding-right: ${({theme}) => theme.spacing(2)};
  padding-left: ${({theme}) => theme.spacing(10)};
  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 45px;
    height: 6px;
    background-color: ${grey[24]};
  }
  &::after {
    position: absolute;
    content: '';
    left: 45px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 6px;
    background-color: ${OUTSIDERS};
  }
`;

export const TableWrapper = styled.div`
  border-top: 1px solid ${grey['14']};
`;
export const StyledIconButton = styled(IconButton)`
  background-color: ${({theme, color}) => theme.palette[color]?.main};
  &:hover {
    background-color: ${({theme, color}) => theme.palette[color]?.main};
  }
  transition: 0.3s ease;
  transform: ${({collapsed}) => !collapsed && 'rotate(-180deg)'};
`;
