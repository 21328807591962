import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-family: 'Proxima Nova';
  font-weight: 400;
  align-items: center;
  background: rgb(229, 229, 229);
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-height: 540px;
  max-width: 934px;
  background: #ffffff;
  border-radius: 6px;
`;

export const CircleContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  width: 18px;
  height: 18px;
  background-color: #00000099;
  border-radius: 50%;

  & > svg {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(-50%, -50%);
  }
`;

export const Form = styled.form`
  padding: 100px 52px 48px;
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  @media (max-width: 500px) {
    flex: 1;
  }
`;

export const Contacts = styled.div`
  padding-top: 84px;
  width: 100%;
  text-align: left;
  .MuiTypography {
    &-caption {
      margin-top: 0;
      margin-bottom: 14px;
      display: block;
    }
    &-body2 {
      position: relative;
      display: block;
      padding-left: 28px;
      text-decoration: none;
      cursor: pointer;
      transition: text-decoration 0.33s;
      &:hover {
        text-decoration: underline;
      }
      & > svg {
        position: absolute;
        top: 50%;
        left: -2px;
        transform: translateY(-50%);
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
`;
