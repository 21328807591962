import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, {useState} from 'react';
import styled from 'styled-components';

import Theme from '../Theme';
/**@deprecated не использовать этот компонент, заменить на ./TabsNew**/
const TabsDefault = (props) => {
  const {
    ariaLabel = '',
    onChange,
    isExternalControl,
    tabs = {
      headlineItems: [{label: ''}],
      contentItems: [React.ReactNode],
    },
    ...otherProp
  } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    onChange && onChange(newValue, tabs.headlineItems[newValue]);
    !isExternalControl && setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
      <div
        aria-labelledby={`simple-tab-${index}`}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        role="tabpanel"
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    <Wrapper>
      <Box>
        <Theme>
          <Tabs
            aria-label={ariaLabel}
            indicatorColor="secondary"
            scrollButtons="auto"
            value={props.value || value}
            variant="scrollable"
            onChange={handleChange}
            {...otherProp}
          >
            {tabs.headlineItems.map((headlineItem, index) => {
              return <Tab key={index} label={headlineItem.label} {...a11yProps(index)} />;
            })}
          </Tabs>
        </Theme>
        {tabs?.contentItems?.map((contentItem, index) => {
          return (
            <TabPanel index={index} key={index} value={props.value || value}>
              {contentItem}
            </TabPanel>
          );
        })}
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .MuiTabs {
    &-root {
      padding-right: 24px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      .MuiTabScrollButton-root.Mui-disabled {
        display: none;
      }
    }
    &-indicator {
      height: 3px;
    }
  }
  .MuiTab {
    &-root {
      padding-right: 0;
      padding-left: 0;
      margin-right: 24px;
      margin-left: 24px;
      ${({theme}) => theme.typography.overline1};
      min-height: 40px;
      padding-top: ${({theme}) => theme.spacing(1)};
      padding-bottom: ${({theme}) => theme.spacing(1)};
      &.Mui-selected {
        color: #2c2c2c;
      }
      &:hover {
        color: ${({theme}) => theme.palette.primary.main};
        opacity: 0.8;
      }
    }
  }
`;

export default React.memo(TabsDefault);
