import LineChart from '@components/charts/LineChart';
import * as echarts from 'echarts';
import PropTypes from 'prop-types';
import React, {memo, useMemo} from 'react';

BMIItemChart.propTypes = {
  seriesData: PropTypes.array,
  color: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
  dates: PropTypes.array,
  minValue: PropTypes.number,
};

function BMIItemChart({dates, minValue, color = {primary: '#fff', secondary: '#fff'}, seriesData}) {
  const lastItem = useMemo(() => seriesData?.[seriesData?.length - 1] || {}, [seriesData?.length]);
  const markPointCoord = [lastItem?.id?.toString(), lastItem?.value];
  return (
    <LineChart
      height={120}
      options={{
        xAxis: {
          show: false,
          type: 'category',
          data: dates.map((currDate) => currDate?.toString()),
        },
        yAxis: {
          type: 'value',
          max: function (value) {
            return Math.round(value.max + 2);
          },
          min: function (value) {
            return Math.round(minValue || minValue === 0 ? minValue : value.min - 4);
          },
        },
        grid: {
          left: '15%',
          top: '5%',
          right: 5,
          bottom: '5%',
        },
        series: [
          {
            color: color.primary,
            showSymbol: false,
            data: seriesData,
            type: 'line',
            smooth: true,
            markPoint: {
              data: [
                {
                  symbol: 'circle',
                  symbolSize: 12,
                  coord: [...markPointCoord],
                  emphasis: {disabled: true},
                },
                {
                  symbol: 'circle',
                  symbolSize: 6,
                  itemStyle: {color: '#fff'},
                  coord: [...markPointCoord],
                },
              ],
            },
            areaStyle: {
              // eslint-disable-next-line import/namespace
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: color?.secondary || '#fff',
                },
                {
                  offset: 1,
                  color: '#fff',
                },
              ]),
            },
          },
        ],
      }}
      width="100%"
    />
  );
}

export default memo(BMIItemChart);
