import {getExerciseCategories, getPlayerPersonalOfpResults} from '@api/newPhysicalTestingAPI';
import {errorMessageNotification} from '@helpers/CommonHelper';
import useDeepEffect from '@hooks/useDeepEffect';
import {useState} from 'react';

export const useExerciseCategories = (params) => {
  const [categoriesState, setCategoriesState] = useState({isLoading: false, categories: []});

  useDeepEffect(() => {
    setCategoriesState((prev) => ({...prev, isLoading: true}));
    getExerciseCategories(params)
      ?.then(({data}) => {
        setCategoriesState((prev) => ({...prev, categories: data}));
      })
      .catch((err) => {
        errorMessageNotification(err);
      })
      .finally(() => setCategoriesState((prev) => ({...prev, isLoading: false})));
  }, [params]);

  return {...categoriesState};
};

export const usePlayerPersonalOfpResults = (playerId, params) => {
  const [resultsState, setResultsState] = useState({isLoading: false, results: []});

  useDeepEffect(() => {
    setResultsState((prev) => ({...prev, isLoading: true}));
    if (params?.groupId) {
      getPlayerPersonalOfpResults(playerId, params)
        ?.then(({data}) => {
          setResultsState((prev) => ({...prev, results: data}));
        })
        .catch((err) => {
          errorMessageNotification(err);
        })
        .finally(() => setResultsState((prev) => ({...prev, isLoading: false})));
    }
  }, [playerId, params]);

  return {...resultsState};
};
