import styled from 'styled-components';

import {CloseButton} from '../../../../ui/Button';

export const DrawerInner = styled.div`
  position: relative;
  height: calc(100% - 78px);
  width: 100%;
  box-sizing: border-box;
  display: block;
`;

export const DrawerContentContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 32px 78px 32px 32px;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    padding: 42px 32px;
  }
  @media screen and (max-width: 480px) {
    padding: 24px 12px;
  }
`;
export const DrawerCloser = styled.button`
  position: absolute;
  top: 32px;
  right: 32px;
  padding: 0;
  width: 24px;
  height: 24px;
  background-color: #f3f3f3;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }

  @media screen and (max-width: 480px) {
    top: 22px;
  }

  & > svg {
    position: absolute;
    top: 55%;
    left: 55%;
    transform: translate(-50%, -50%);
    path {
      fill: #2c2c2c;
    }
  }
`;
export const DrawerButtonsBlock = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 78px;
  overflow: hidden;
  background-color: white;
  box-shadow: -4px 0 12px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  width: 100%;
  z-index: 999;
`;
export const DrawerButtonsBlockContent = styled.div`
  padding-right: 34px;
  padding-left: 34px;
  width: 100%;
  display: ${(props) => props.isWithButtons && 'flex'};
  justify-content: ${(props) => props.isWithButtons && 'space-between'};

  @media screen and (max-width: 575px) {
    padding-right: 12px;
    padding-left: 12px;
  }

  ${CloseButton} {
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    width: 120px;
  }
`;
