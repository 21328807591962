import {Typography} from '@ui/MUI';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import PropTypes from 'prop-types';
import React from 'react';

ErrorModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

function ErrorModal({open, onClose, onSubmit, isLoading, ...props}) {
  return (
    <DialogDefault
      {...props}
      closeText="Редактировать"
      content={
        <Typography variant="body2">
          <>Введенный показатель ниже предыдущего.</>
          <br />
          <>Исправьте значение, если допущена ошибка или сохраните без изменений, если все верно.</>
        </Typography>
      }
      open={open}
      title="Рекомендуется проверка данных"
      onActionButtonClick={onSubmit}
      onCloseDefault={onClose}
    />
  );
}

export default ErrorModal;
