import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {grey} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {GOAL_TYPES} from '../../constants';

function GoalCell({type, isOponent, isLoading}) {
  if (type === GOAL_TYPES.goal) {
    return (
      <Stack alignItems="center" direction="row">
        <Typography isLoading={isLoading} preloaderWidthBetween={[80, 100]} variant="subtitle2">
          Гол
        </Typography>
        {!isLoading && isOponent && <OponentIcon />}
      </Stack>
    );
  }
  return (
    <Typography isLoading={isLoading} preloaderWidthBetween={[80, 100]} variant="body2">
      <SubdirectoryArrowRightIcon fontSize="inherit" sx={{color: grey[38]}} /> Передача
    </Typography>
  );
}

GoalCell.propTypes = {
  type: PropTypes.string,
  isOponent: PropTypes.bool,
};

export default GoalCell;

const OponentIcon = styled.div`
  position: relative;
  display: inline-block;
  width: 11px;
  height: 12px;
  padding: 0 2px;
  background: rgba(151, 137, 107, 0.7);
  border-radius: 2px;
  margin-left: ${({theme}) => theme.spacing(0)};
  color: #fff;
  &::before {
    content: 'c';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Proxima Nova', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
  }
`;
