import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import {selectMatchesFilter} from '@selectors/gameStatisticsMkc';

export function useMatches() {
  const {data, matches, tournaments, isOpen} = useStore((state) => selectMatchesFilter(state), shallowEqual);

  return {
    isMatchesLoading: !isOpen && (matches?.isLoading || tournaments?.isLoading),
    statisticIds: data?.statisticIds,
  };
}
