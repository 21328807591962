import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

const ConfirmModal = ({
  actionText = 'Да, отменить',
  closeText = 'Отмена',
  title = 'Отмена',
  isToggle,
  onActionButtonClick,
  onClose,
  content = (
    <>
      <TypographyDefault>Вы уверены что хотите отменить изменения?</TypographyDefault>
      <TypographyDefault>Новые данные будут потеряны.</TypographyDefault>
    </>
  ),
}) => {
  return (
    <DialogDefault
      actionText={actionText}
      closeText={closeText}
      content={content}
      open={isToggle}
      sx={{
        '.MuiDialog-paper': {
          p: 4,
          minWidth: '428px',
        },
        '.MuiDialogContent-root': {
          pt: 1,
          px: '0px',
          pb: 5,
        },
      }}
      title={title}
      onActionButtonClick={onActionButtonClick}
      onCloseDefault={onClose}
    />
  );
};

export default ConfirmModal;
