import React from 'react';

const NoteBook = () => {
  return (
    <>
      <g filter="url(#filter0_d_9352_9205)">
        <path
          d="M10.9769 0.900391H3.06498C1.92472 0.900907 1.00039 1.82511 1 2.96537V13.0276C1.00039 14.1678 1.92472 15.0921 3.06498 15.0926H10.9769C12.1171 15.0921 13.0413 14.1678 13.0418 13.0276V2.96537C13.0413 1.82511 12.1171 0.900907 10.9769 0.900391ZM12.2159 13.0276C12.2139 13.711 11.6602 14.2646 10.9769 14.2666H3.06498C2.3816 14.2646 1.82793 13.711 1.82599 13.0276V2.96537C1.82793 2.28199 2.3816 1.72832 3.06498 1.72638H10.9769C11.6602 1.72832 12.2139 2.28199 12.2159 2.96537V13.0276Z"
          fill="#626262"
        />
        <path
          d="M4.29884 5.40039H9.60279C9.83084 5.40039 10.0158 5.21545 10.0158 4.9874C10.0158 4.75935 9.83084 4.5744 9.60279 4.5744H4.29884C4.07066 4.5744 3.88584 4.75935 3.88584 4.9874C3.88584 5.21545 4.07066 5.40039 4.29884 5.40039Z"
          fill="#626262"
        />
        <path
          d="M4.29884 7.40039H9.60279C9.83084 7.40039 10.0158 7.21545 10.0158 6.9874C10.0158 6.75935 9.83084 6.5744 9.60279 6.5744H4.29884C4.07066 6.5744 3.88584 6.75935 3.88584 6.9874C3.88584 7.21545 4.07066 7.40039 4.29884 7.40039Z"
          fill="#626262"
        />
        <path
          d="M4.29884 9.40039H9.60279C9.83084 9.40039 10.0158 9.21545 10.0158 8.9874C10.0158 8.75935 9.83084 8.5744 9.60279 8.5744H4.29884C4.07066 8.5744 3.88584 8.75935 3.88584 8.9874C3.88584 9.21545 4.07066 9.40039 4.29884 9.40039Z"
          fill="#626262"
        />
        <path
          d="M4.29884 11.4004H9.60279C9.83084 11.4004 10.0158 11.2154 10.0158 10.9874C10.0158 10.7593 9.83084 10.5744 9.60279 10.5744H4.29884C4.07066 10.5744 3.88584 10.7593 3.88584 10.9874C3.88584 11.2154 4.07066 11.4004 4.29884 11.4004Z"
          fill="#626262"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="16.1921"
          id="filter0_d_9352_9205"
          width="14.0418"
          x="0"
          y="0.400391"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_9352_9205" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_9352_9205" mode="normal" result="shape" />
        </filter>
      </defs>
    </>
  );
};

export default NoteBook;
