import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

export function getGamesCards(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mkc/main`, {params});
}

export function getTableTabTable(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mkc/tablepage/table`, {params});
}

export function getPlayersTables(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mkc/tablepage/players`, {params});
}

export function getPastGames(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mkc/tablepage/past-games`, {params});
}

export function getTableTabNextGames(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mkc/tablepage/next-games`, {params});
}

export function getOpponentTeamList(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mkc/enemyprepare/teamlist`, {params});
}

export function getOpponentPlayersStat(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mkc/enemyprepare/players-stats`, {params});
}

export function getAllMKCTournaments(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mkc/tournaments/all`, {params});
}

export function getMKCTournamentById(id) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mkc/tournaments/all/${id}`);
}

export function getFiltersTournaments(params) {
  return axios.get(
    `${API_ENDPOINT}/v1/statistics/mkc/tournaments`,
    {params},
    {
      failed: {message: 'Ошибка при загрузке турниров'},
    },
  );
}

export function getFiltersMatches(params) {
  return axios.get(
    `${API_ENDPOINT}/v1/statistics/mkc/matches`,
    {params},
    {
      failed: {message: 'Ошибка при загрузке матчей'},
    },
  );
}

export function getCommonTab(data) {
  const {cancelToken, ...params} = data;
  return axios.get(`${API_ENDPOINT}/v1/statistics/get/matches`, {params, cancelToken});
}

export function getPlayerTournaments(params) {
  const playerId = params?.playerId;
  return axios.get(`${API_ENDPOINT}/v1/statistics/player/${playerId}/mkc/tournaments`, {params});
}

export function getPlayerTournamentStat(params) {
  const playerId = params?.playerId;
  return axios.get(`${API_ENDPOINT}/v1/statistics/player/${playerId}/statistics/general-metrics`, {params});
}

export function getPlayerStatPlayTime(params) {
  const playerId = params?.playerId;
  return axios.get(`${API_ENDPOINT}/v1/statistics/player/${playerId}/statistics/time`, {params});
}

export function getPlayerGateZones(params) {
  const playerId = params?.playerId;
  return axios.get(`${API_ENDPOINT}/v1/statistics/player/${playerId}/statistics/gates-map`, {params});
}

export function getPlayerShotsZones(params) {
  const playerId = params?.playerId;
  return axios.get(`${API_ENDPOINT}/v1/statistics/player/${playerId}/statistics/shots-zones`, {params});
}
