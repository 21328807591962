import React from 'react';

function NotificationInfo() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M6.99992 0.333374C5.68138 0.333374 4.39245 0.724367 3.29612 1.45691C2.19979 2.18945 1.34531 3.23064 0.840725 4.44882C0.336141 5.66699 0.204118 7.00744 0.461353 8.30064C0.718588 9.59385 1.35353 10.7817 2.28588 11.7141C3.21823 12.6464 4.40611 13.2814 5.69932 13.5386C6.99253 13.7958 8.33297 13.6638 9.55114 13.1592C10.7693 12.6547 11.8105 11.8002 12.5431 10.7038C13.2756 9.60751 13.6666 8.31858 13.6666 7.00004C13.6646 5.23253 12.9616 3.53798 11.7118 2.28817C10.462 1.03835 8.76743 0.33534 6.99992 0.333374ZM6.99992 12.3334C5.94509 12.3334 4.91394 12.0206 4.03688 11.4345C3.15982 10.8485 2.47623 10.0156 2.07256 9.04102C1.6689 8.06648 1.56328 6.99412 1.76907 5.95956C1.97485 4.92499 2.48281 3.97468 3.22869 3.2288C3.97457 2.48292 4.92487 1.97497 5.95944 1.76919C6.99401 1.5634 8.06636 1.66902 9.0409 2.07268C10.0154 2.47635 10.8484 3.15994 11.4344 4.037C12.0205 4.91406 12.3333 5.94521 12.3333 7.00004C12.3316 8.41403 11.7692 9.76965 10.7694 10.7695C9.76953 11.7693 8.41391 12.3318 6.99992 12.3334ZM6.99992 6.66671C6.82311 6.66671 6.65354 6.73695 6.52852 6.86197C6.40349 6.98699 6.33325 7.15656 6.33325 7.33337V9.33337C6.33325 9.51019 6.40349 9.67976 6.52852 9.80478C6.65354 9.9298 6.82311 10 6.99992 10C7.17673 10 7.3463 9.9298 7.47133 9.80478C7.59635 9.67976 7.66659 9.51019 7.66659 9.33337V7.33337C7.66659 7.15656 7.59635 6.98699 7.47133 6.86197C7.3463 6.73695 7.17673 6.66671 6.99992 6.66671ZM6.99992 4.00004C6.8351 4.00004 6.67399 4.04891 6.53695 4.14048C6.39991 4.23205 6.2931 4.3622 6.23002 4.51447C6.16695 4.66674 6.15045 4.8343 6.1826 4.99595C6.21475 5.1576 6.29412 5.30609 6.41067 5.42263C6.52721 5.53917 6.67569 5.61854 6.83735 5.6507C6.999 5.68285 7.16655 5.66635 7.31882 5.60327C7.4711 5.5402 7.60124 5.43339 7.69281 5.29635C7.78438 5.15931 7.83325 4.99819 7.83325 4.83337C7.83325 4.61236 7.74546 4.4004 7.58918 4.24412C7.4329 4.08784 7.22094 4.00004 6.99992 4.00004Z"
          fill="#014361"
        />
      </g>
    </React.Fragment>
  );
}
export default NotificationInfo;
