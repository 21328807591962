import {IS_PRODUCTION} from '@src/constants';
import React from 'react';

export const stages = {
  login: {
    title: 'Вход',
    description: IS_PRODUCTION ? '' : 'Данный домен является тестовым. Перейдите на основную версию сайта.',
    fields: [
      {component: 'email', name: 'email'},
      {component: 'password', name: 'password', withOutMinLength: true},
    ],
    button: {
      label: 'Войти',
    },
    link: {
      label: 'Забыли пароль?',
      goTo: 'recovery',
    },
  },
  recovery: {
    title: 'Восстановление пароля',
    description: 'Ссылка для сброса пароля будет отправлена на вашу электронную почту.',
    fields: [{component: 'email', name: 'email'}],
    button: {
      label: 'Отправить',
    },
    link: {
      label: 'Вспомнили пароль?',
      goTo: 'login',
    },
  },

  complete: {
    title: 'Восстановление пароля',
    description: (email) => (
      <>
        Ссылка для сброса пароля отправлена на электронную почту {email}. <br />
        Если вы не увидели письмо на своей электронной почте, проверьте папку «Спам».
      </>
    ),
    link: {
      label: 'Вернуться к авторизации',
      goTo: 'login',
    },
  },

  reset: {
    title: 'Восстановление пароля',
    description: 'Для входа на платформу введите новый пароль. Старый пароль будет сброшен.',
    fields: [
      {name: 'password', component: 'password', placeholder: 'Новый пароль'},
      {
        name: 'repeat',
        component: 'password',
        placeholder: 'Повторите пароль',
        withOutMinLength: true,
      },
    ],
    button: {
      label: 'Сохранить',
    },
    link: {
      label: 'Вспомнили пароль?',
      goTo: 'login',
    },
  },
};
