import styled from 'styled-components';

const Typography = styled.p`
  margin: ${(props) => props.margin || 0};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  cursor: ${(props) => props.isAction && 'pointer'};
`;

export default Typography;
