import {Cancel, History} from '@mui/icons-material';
import Divider from '@ui/MUI/Divider';
import Stack from '@ui/MUI/Stack';
import TooltipDefault from '@ui/MUI/Tooltip';
import TypographyDefault from '@ui/MUI/Typography';
import moment from 'moment';
import React, {useMemo} from 'react';

const SkillItem = ({
  name,
  section,
  category,
  number,
  onDelete,
  onEdit,
  bgcolor = '#E3E3E3',
  borderRadius = 2,
  flex = '1',
  updatedBy,
  updatedAt,
  createdBy,
  createdAt,
  onClick,
  showInfo = false,
}) => {
  const createInfo = useMemo(() => {
    if (!createdAt || !createdBy) return;
    return `Создат.: ${createdBy.name}, ${moment(createdAt).format('DD.MM.YYYY')}`;
  }, [createdBy, createdAt]);

  const updateInfo = useMemo(() => {
    if (!updatedBy || !updatedAt) return;
    return `Редакт.: ${updatedBy.name}, ${moment(updatedAt).format('DD.MM.YYYY')}`;
  }, [updatedBy, updatedAt]);

  const showIconSlots = onEdit || onDelete || (showInfo && (createInfo || updateInfo));

  return (
    <Stack alignItems="center" direction="row" flex={flex} spacing={4}>
      <Stack
        alignItems="center"
        bgcolor={bgcolor}
        borderRadius={borderRadius}
        direction="row"
        flex={flex}
        p="4px 8px"
        spacing={4}
        sx={
          onClick && {
            cursor: 'pointer',
            userSelect: 'none',
          }
        }
        onClick={onClick}
      >
        <Stack flex={flex}>
          <Stack direction="row" p="4px 6px" spacing={1}>
            <TypographyDefault variant="subtitle2">{number || '-'}</TypographyDefault>
            <TypographyDefault variant="body2">{name || '-'}</TypographyDefault>
          </Stack>
          <Divider />
          <Stack p="4px 6px">
            <TypographyDefault variant="caption1">
              {section?.name || 'Раздел отсутствует'}; {category?.name || 'Категория отсутствует'}
            </TypographyDefault>
          </Stack>
        </Stack>
        {showIconSlots && (
          <Stack alignItems="center" direction="row" spacing={2}>
            {(createInfo || updateInfo) && (
              <TooltipDefault
                title={
                  <Stack>
                    {updateInfo && (
                      <TypographyDefault color="rgba(255, 255, 255, 1)" variant="caption1">
                        {updateInfo}
                      </TypographyDefault>
                    )}
                    <TypographyDefault color="rgba(255, 255, 255, 1)" variant="caption1">
                      {createInfo}
                    </TypographyDefault>
                  </Stack>
                }
              >
                <History color="rgba(82, 82, 82, 1)" sx={{fontSize: '24px'}} />
              </TooltipDefault>
            )}
            {onDelete && <Cancel color="rgba(82, 82, 82, 1)" sx={{fontSize: '24px'}} />}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default SkillItem;
