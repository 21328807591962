import UsersAPI from '@api/UsersAPI';
import SkillsWork from '@components/apps/DevelopmentJournalPlayerSkills/components/SkillsWork';
import {Box} from '@ui/MUI';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import {HttpStatusCode} from 'axios';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';

import DevelopmentJournalPlayerReport from './components/DevelopmentJournalPlayerReport';

const useStudent = (id) => {
  const [student, setStudent] = useState({
    isLoading: false,
    isLoad: false,
    isError: false,
    error: null,
    data: {},
  });

  const fetchUser = async () => {
    setStudent({...student, isLoading: true});
    try {
      const {data} = await UsersAPI.getStudentByIdV2(id);
      setStudent({...student, isLoading: false, isLoad: true, data});
    } catch (error) {
      setStudent({...student, isLoading: false, isError: true, error});
    }
  };

  useEffect(() => {
    fetchUser(id);
  }, [id]);

  return student;
};

const DevelopmentJournalPlayer = () => {
  const {id} = useParams();
  const student = useStudent(id);

  const history = useHistory();
  const statusCode = student.error?.response?.status;
  const studentFullName = student.data?.lastName + ' ' + student.data?.firstName;

  useEffect(() => {
    if (statusCode === HttpStatusCode.NotFound) {
      history.push('/');
    }
  }, [statusCode]);

  return (
    <Box>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Журнал развития', path: `/development_journal${history?.location?.search}`},
          {label: studentFullName, path: null},
        ]}
      >
        Журнал развития
      </PageTitleDefault>
      <TabsNew
        withoutWrapper
        tabs={[
          {value: <DevelopmentJournalPlayerReport />, label: 'Общий отчет'},
          {value: <SkillsWork />, label: 'Работа над навыком'},
        ]}
      />
    </Box>
  );
};

export default DevelopmentJournalPlayer;
