import TeamLogo from '@common/Statistics/PlayerStatistics/TeamLogo';
import {longDash} from '@src/constants';
import {Typography} from '@ui/MUI';
import Avatar from '@ui/MUI/Avatar';
import Box from '@ui/MUI/Box';
import {grey} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import styled from 'styled-components';

PlayersTable.propTypes = {
  reverseAccentIndication: PropTypes.bool,
  players: PropTypes.shape({
    ourTopFive: PropTypes.arrayOf(
      PropTypes.shape({
        avatar: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        playerNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ).isRequired,
    topFive: PropTypes.arrayOf(
      PropTypes.shape({
        avatar: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        playerNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ).isRequired,
  }),
  accentColors: PropTypes.string.isRequired,
};

function PlayersTable({
  players = {
    ourTopFive: [],
    topFive: [],
  },
  accentColors,
}) {
  const bestPlayer = useMemo(
    () => [...(players?.ourTopFive || []), ...(players?.topFive || [])].find((item) => item.markAsBest),
    [players],
  );
  return (
    <Stack direction="row" minHeight={150} px={{xxs: 2, sm: 3}} py={{xxs: 1, sm: 2}} spacing={3}>
      {!players?.ourTopFive?.length && !players?.topFive?.length ? (
        <Typography my="auto" textAlign="center" variant="body2" width="100%">
          Нет данных
        </Typography>
      ) : (
        <>
          <Stack alignItems="center">
            <Avatar isHighResolution src={bestPlayer?.avatar} width={{xxs: 48, md: 76}} />
            <Typography mt={1} variant="h4">
              {bestPlayer?.value || bestPlayer?.value === bestPlayer?.value ? bestPlayer?.value : longDash}
            </Typography>
          </Stack>
          <Box width="100%">
            {players?.topFive?.map((item, index) => {
              return (
                <PlayerWrapper
                  active={item.markAsBest}
                  bgc={item?.isOurPlayer && accentColors ? accentColors[0] : ''}
                  direction="row"
                  indicatorcolor={accentColors[1]}
                  key={index}
                  px={{xxs: 1, sm: 2}}
                  py={{xxs: 0, sm: 1}}
                  spacing={1}
                >
                  <Stack direction="row" gap={0}>
                    <Stack direction="column" justifyContent="center">
                      <TeamLogo src={item?.teamLogo} width={12} />
                    </Stack>
                    <Typography component="span" variant="body2">
                      <Typography color={grey['54']} component="span" variant="body2">
                        {item?.position}
                      </Typography>
                      {` ${item?.lastName} ${item?.firstName}`}
                    </Typography>
                  </Stack>

                  <Typography variant="subtitle2">
                    {item?.value || item?.value === item?.value ? item?.value : longDash}
                  </Typography>
                </PlayerWrapper>
              );
            })}
            <PlayerWrapper direction="row" pt="0 !important" px={{xxs: 1, sm: 2}} py={{xxs: 0, sm: 1}} spacing={1}>
              <Typography variant="body2">...</Typography>
            </PlayerWrapper>

            {players?.ourTopFive?.map((item, index) => {
              return (
                <PlayerWrapper
                  active={item.markAsBest}
                  bgc={item?.isOurPlayer && accentColors ? accentColors[0] : ''}
                  direction="row"
                  indicatorcolor={accentColors[1]}
                  key={index}
                  px={{xxs: 1, sm: 2}}
                  py={{xxs: 0, sm: 1}}
                  spacing={1}
                >
                  <Stack direction="row" gap={0}>
                    <Stack direction="column" justifyContent="center">
                      <TeamLogo src={item?.teamLogo} width={12} />
                    </Stack>
                    <Typography component="span" display="inline" variant="caption1">
                      <Typography color={grey['54']} component="span" display="inline" variant="caption1">
                        {item?.position}
                      </Typography>
                      {` ${item?.lastName} ${item?.firstName}`}
                    </Typography>
                  </Stack>
                  <Typography variant="caption1">
                    {item?.value || item?.value === item?.value ? item?.value : longDash}
                  </Typography>
                </PlayerWrapper>
              );
            })}
          </Box>
        </>
      )}
    </Stack>
  );
}

export default React.memo(PlayersTable);

const PlayerWrapper = styled(Stack)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${grey['12']};
  position: relative;
  background-color: ${({bgc}) => bgc};

  &::before {
    position: absolute;
    content: '';
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({indicatorcolor}) => indicatorcolor || grey['54']};
    opacity: ${({active}) => (active ? 1 : 0)};
    transition: 0.3s ease;
  }
`;
