import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const LessonsAPI = {
  getAllLessons() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/lessons/all`)
        .then((d) => d.data)
        .then((exercises) => {
          if (exercises.error !== undefined) {
            return reject(exercises.error);
          }
          resolve(exercises);
        })
        .catch((err) => reject(err));
    });
  },

  createLesson(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}/lessons/create`, data)
        .then((d) => d.data)
        .then((ex) => {
          if (ex.error !== undefined) {
            return reject(ex.error);
          }
          resolve(ex);
        })
        .catch((err) => reject(err));
    });
  },

  updateLesson(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}/lessons/update`, data)
        .then((d) => d.data)
        .then((ex) => {
          if (ex.error !== undefined) {
            return reject(ex.error);
          }
          resolve(ex);
        })
        .catch((err) => reject(err));
    });
  },

  deleteLesson(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}/lessons/delete/${id}`)
        .then((d) => d.data)
        .then((ex) => {
          if (ex.error !== undefined) {
            return reject(ex.error);
          }
          resolve(ex);
        })
        .catch((err) => reject(err));
    });
  },

  getAllLessonsTypes() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/event-type`)
        .then((d) => d.data)
        .then((exercises) => {
          if (exercises.error !== undefined) {
            return reject(exercises.error);
          }
          resolve(exercises);
        })
        .catch((err) => reject(err));
    });
  },
  getLessonStatistics(request) {
    return axios.get(
      `${API_ENDPOINT}/statistics/game?schoolId=${request.schoolId}&teamId=${request.teamId}&gameDate=${request.lessonId}`,
    );
  },
  getLessonsByFilter(filter) {
    const requestFilterString = Object.entries(filter)
      .map(([key, value]) => {
        return key + '=' + value;
      })
      .join('&');

    return axios.get(`${API_ENDPOINT}/lessons?${requestFilterString}`);
  },
};

export default LessonsAPI;
