import React from 'react';

function Camera() {
  return (
    <React.Fragment>
      <g clipPath="url(#clip0)">
        <path
          d="M8.63165 0.0166931H1.66957C0.751304 0.0166931 0 0.767998 0 1.68626V7.31269C0 8.23095 0.751304 8.98226 1.66957 8.98226H8.63165C9.54991 8.98226 10.3012 8.23095 10.3012 7.31269V1.68626C10.3012 0.751302 9.54991 0.0166931 8.63165 0.0166931Z"
          fill="#686868"
        />
        <path
          d="M14.0243 0.934942C13.9242 0.951638 13.824 1.00173 13.7405 1.05181L11.136 2.55442V6.42781L13.7572 7.93042C14.2414 8.21425 14.8424 8.04729 15.1262 7.56312C15.2097 7.41286 15.2598 7.2459 15.2598 7.06225V1.90329C15.2598 1.28555 14.6755 0.784682 14.0243 0.934942Z"
          fill="#686868"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="9" width="15.2609" />
        </clipPath>
      </defs>
    </React.Fragment>
  );
}
export default Camera;
