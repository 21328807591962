import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import {TEACHER_SUBROLES, userRoles} from '@src/constants';

export default function useFatAccess(groupId) {
  const {
    currentUser: {userRole, teacherRole, groups},
    loading,
  } = useStore((state) => state.users, shallowEqual);
  return {
    hasAccess:
      userRole === userRoles.doctor ||
      (teacherRole === TEACHER_SUBROLES.main &&
        (groups?.length ? groups : []).find((group) => group.objectId === groupId)),
    loading,
  };
}
