import {arrayOf, number, object, shape, string} from 'prop-types';
import React from 'react';

import {Bold, Count, CountItem, Statistics, StatisticsItem, Title, Wrapper} from './TeamCard';

function TeamCard({
  onClick,
  item: {
    id,
    name,
    statistic: {leagues, newReportCount, reportCount, studentCount, percentageReports},
  },
}) {
  return (
    <Wrapper>
      <Title underline="none" variant="button" onClick={() => onClick(id)}>
        {name}
      </Title>
      <Count>
        <CountItem>
          Игроки <Bold>{studentCount}</Bold>
        </CountItem>
        <CountItem>
          Отчеты <Bold>{reportCount}</Bold>
          {percentageReports > 0 && (
            <Bold color="#747474" fontWeight="400" marginLeft="4px">
              ({percentageReports}%)
            </Bold>
          )}
          {newReportCount > 0 && (
            <Bold color="#39BBA7" marginLeft="4px">
              +{newReportCount}
            </Bold>
          )}
        </CountItem>
      </Count>
      <Statistics>
        {leagues.map(({name, value}, index) => (
          <StatisticsItem key={index}>
            <p className="title">{name}</p>
            <p className="count">{value}</p>
          </StatisticsItem>
        ))}
      </Statistics>
    </Wrapper>
  );
}

TeamCard.propTypes = {
  name: string,
  statistic: shape({
    leagues: arrayOf([object]),
    newReportCount: number,
    reportCount: number,
    studentCount: number,
    percentageReports: number,
  }),
};

export default TeamCard;
