import ContentWrapper from '@common/ContentWrapper';
import useElementSize from '@hooks/useElementSize';
import {BoxProps} from '@mui/material/Box/Box';
import React from 'react';

type FullHeightWrapperProps = {
  height?: ((availableHeight: number) => Pick<BoxProps, 'height'>) | Pick<BoxProps, 'height'> | number;
  children?: React.ReactElement;
} & BoxProps;

function FullHeightWrapper({children, height, ...props}: FullHeightWrapperProps) {
  const [ref, {availableHeight}] = useElementSize();
  return (
    <ContentWrapper
      height={(typeof height === 'function' ? height(availableHeight) : height) || availableHeight}
      p="0"
      {...props}
      ref={ref}
    >
      {children}
    </ContentWrapper>
  );
}

export default FullHeightWrapper;
