import FullHeightWrapper from '@common/FullHeightWrapper';
import PlayersTable from '@common/Statistics/PlayerStatistics/VideoModal/PlayersTable';
import VideoPlayer from '@common/Statistics/VideoPlayer';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {parseFormToGetValue} from '@helpers/index';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import {selectGameActionDetails} from '@selectors/gameStatistics';
import {loadGameActionDetails} from '@slices/statistics';
import {longDash} from '@src/constants';
import {ZONE_LABELS} from '@src/constants/stitictics';
import {StatisticsGetFaceoffMapResponseDto, TableItemDto} from '@src/types/generated';
import {Box, Button, Chip, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {Cancel} from 'axios';
import React, {useEffect, useState} from 'react';

type ThrowinItem = {
  label: string;
  value: string;
};

type AboutFaceOffProps = {
  throwinItems: Array<ThrowinItem>;
};

type FaceOffsProps = {
  selectedPlayerName: string;
  statistic: {
    loading?: boolean;
    isLoading?: boolean;
    error?: object | null;
    data: StatisticsGetFaceoffMapResponseDto;
  };
  statisticsIds: Array<string>;
  cleaner?: () => (dispatch: unknown) => unknown;
  loader?: (params: object, isCancel?: (value: unknown) => value is Cancel) => (dispatch: unknown) => Promise<void>;
};

const AboutFaceOff = ({throwinItems}: AboutFaceOffProps) => (
  <>
    <Typography mb={2} mt={4} variant="h3">
      О вбрасывании
    </Typography>
    <Stack direction="column" gap={2} p={{xxs: 3, sm: 4}} sx={{backgroundColor: grey['4'], borderRadius: '6px'}}>
      {throwinItems?.map(({label, value}: ThrowinItem, index: number) => (
        <Stack direction="row" flexWrap="wrap" gap={0} key={index}>
          <Typography variant="body1">{label}</Typography>
          <Typography variant="subtitle1">{value || longDash}</Typography>
        </Stack>
      ))}
    </Stack>
  </>
);

function FaceOffs({selectedPlayerName, statistic, statisticsIds, cleaner, loader}: FaceOffsProps) {
  const dispatch = useStoreDispatch();

  const filterChips = [
    {label: 'Все', isWeWin: [true, false], key: 'all'},
    {label: 'Выигранные', isWeWin: [true], key: 'weWin'},
    {label: 'Проигранные', isWeWin: [false], key: 'weLost'},
  ];
  const [selectedChip, setSelectedChip] = useState(filterChips?.[0]);

  const [selectedActionIndex, setSelectedActionIndex] = useState(0);

  const filterFaceOffsBySelectedChip = (faceOffs: Array<TableItemDto>) => {
    if (selectedChip?.key === 'weWin') {
      return faceOffs?.filter((faceOff) => faceOff?.isWeWin === true);
    }
    if (selectedChip?.key === 'weLost') {
      return faceOffs?.filter((faceOff) => faceOff?.isWeWin === false);
    }
    return faceOffs;
  };

  const faceOffs =
    statistic?.data?.table?.filter(({playerName}: {playerName: string}) => {
      const isNameAndSurnameSame =
        playerName?.includes(selectedPlayerName?.split(' ')?.[0]) &&
        playerName?.includes(selectedPlayerName?.split(' ')?.[1]);
      return isNameAndSurnameSame;
    }) || [];
  const selectedFaceOff = filterFaceOffsBySelectedChip(faceOffs)?.[selectedActionIndex];

  const isNoData = !faceOffs?.length;

  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [selectedFaceOff?.id]});

  useDeepEffect(() => {
    if (loader && statisticsIds?.length) {
      dispatch(
        loader(
          {
            ...parseFormToGetValue({cancelToken: newCancelToken()}),
            statisticsIds,
          },
          isCancel,
        ),
      );
    }
    return () => {
      cleaner && dispatch(cleaner());
    };
  }, [statisticsIds, loader]);

  useEffect(() => {
    selectedFaceOff?.id &&
      dispatch(loadGameActionDetails(selectedFaceOff?.id, {cancelToken: newCancelToken(), isCancel: !!isCancel}));
  }, [selectedFaceOff?.id]);

  const {faceOffsInfoItems, throwinItems} = useDeepMemo(() => {
    const faceOffsInfoItems = [
      {label: 'Результат:', value: !selectedFaceOff?.isWeWin ? 'Проигранное' : 'Выигранное'},
      {label: 'Время:', value: selectedFaceOff?.videoTime},
      {label: 'Игрок:', value: selectedFaceOff?.playerName},
    ];
    const throwinItems = [{label: 'Зона:', value: ZONE_LABELS?.[selectedFaceOff?.zone]?.zone || ''}];

    return {faceOffsInfoItems, throwinItems};
  }, [selectedFaceOff]);

  const video = {data: selectedFaceOff, key: selectedFaceOff?.id};

  const shotDetails = useStore(selectGameActionDetails);
  const playersOnIce = shotDetails?.data?.playersOnIce || [];

  return (
    <>
      {!isNoData && (
        <Box display="flex" flexWrap="wrap" gap={3}>
          {filterChips?.map((chip) => {
            const isSelected = selectedChip?.key === chip?.key;
            return (
              <Chip
                clickable
                color={isSelected ? 'primary' : 'secondary'}
                key={chip?.key}
                label={chip?.label}
                sx={{
                  '& > span': {
                    color: isSelected && '#fff',
                  },
                }}
                onClick={() => {
                  setSelectedChip(chip);
                  setSelectedActionIndex(0);
                }}
              />
            );
          })}
        </Box>
      )}
      {!isNoData && (
        <Stack
          alignItems="center"
          direction="row"
          flexWrap={{xxs: 'wrap', xs: 'nowrap'}}
          gap={3}
          justifyContent="space-between"
          sx={{position: 'sticky', top: '56px', pt: 5, pb: 3, backgroundColor: '#fff', zIndex: 2}}
          width={{xxs: '100%', xs: 'unset'}}
        >
          <Typography
            isLoading={statistic?.isLoading || statistic?.loading}
            preloaderWidthBetween={[100, 120]}
            variant="h3"
          >
            Вбрасывание. {selectedActionIndex + 1}/{filterFaceOffsBySelectedChip(faceOffs)?.length}
          </Typography>
          <Box>
            <Button
              color="primary"
              disabled={selectedActionIndex === 0 || statistic?.isLoading || statistic?.loading}
              startIcon={<ChevronLeftOutlinedIcon />}
              sx={{width: {xxs: '45%', xs: 'unset'}, mr: 3}}
              variant="outlined"
              onClick={() => {
                setSelectedActionIndex(selectedActionIndex - 1);
              }}
            >
              назад
            </Button>
            <Button
              color="primary"
              disabled={
                statistic?.isLoading ||
                statistic?.loading ||
                selectedActionIndex + 1 === filterFaceOffsBySelectedChip(faceOffs)?.length
              }
              endIcon={<ChevronLeftOutlinedIcon sx={{transform: 'rotate(180deg)'}} />}
              sx={{width: {xxs: '45%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => {
                setSelectedActionIndex(selectedActionIndex + 1);
              }}
            >
              вперед
            </Button>
          </Box>
        </Stack>
      )}
      {statistic?.isLoading || statistic?.loading ? (
        <LocalPreloader visible={true} />
      ) : (
        <>{video?.data && <VideoPlayer data={video?.data} key={video?.key} />}</>
      )}
      <Box sx={{mt: 3, width: '100%'}}>
        {isNoData ? (
          <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
            <Typography variant="subtitle1">
              Игрок не принимал участие ни в одном вбрасывании в данном матче.
            </Typography>
          </FullHeightWrapper>
        ) : (
          faceOffsInfoItems?.map(({label, value}, index) => {
            return (
              <Typography key={index} mb={1} sx={{position: 'relative'}} variant="body1">
                {label}{' '}
                <Typography component="span" variant="subtitle1">
                  {value}
                </Typography>
              </Typography>
            );
          })
        )}
      </Box>
      {!isNoData && (
        <Box sx={{mt: 5}}>
          <Typography mb={2} mt={4} variant="h4">
            Игроки на льду
          </Typography>
          <PlayersTable isLoading={shotDetails?.isLoading} playersOnIce={playersOnIce} />
        </Box>
      )}
      {filterFaceOffsBySelectedChip(faceOffs)?.length > 0 && <AboutFaceOff throwinItems={throwinItems} />}
    </>
  );
}
export default FaceOffs;
