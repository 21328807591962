import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {BoxWrapper} from '@components/apps/StatsReportForOneGame/StatsReportForOneGame';
import LocalPreloader from '@components/preloader/LocalPreloader';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useQueryString from '@hooks/useQueryString';
import {useMediaQuery} from '@mui/material';
import {customTableComparator, defaultSort} from '@src/helpers';
import {Box, Tooltip} from '@ui/MUI';
import CheckboxDefault from '@ui/MUI/Checkbox';
import {grey, yellow} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';

import {LegendDotItem, LegendShiftItem, TableWrapper} from './TableTemplate';

function TableTemplate({
  columns = [],
  hasMatchFilter = false,
  rowsData = [],
  isLoading,
  isLoad,
  hasError,
  hasMaxHeight,
  hasShifts = false,
  showAnnotation = true,
  ...props
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isToggled, setToggleUser] = useState(false);
  const [hasWingers, setHasWingers] = useState(true);
  const [hasDefensemen, setHasDefensemen] = useState(true);

  const [{hockeyRole, ...restQs}, set] = useQueryString();

  useEffect(() => {
    if (hasWingers && !hasDefensemen) {
      set({...restQs, hockeyRole: ['winger']});
    }
    if (!hasWingers && hasDefensemen) {
      set({...restQs, hockeyRole: ['defenseman']});
    }
    if ((hasWingers && hasDefensemen) || (!hasWingers && !hasDefensemen)) {
      set({...restQs});
    }
  }, [hasWingers, hasDefensemen]);

  const defaultColumns = useMemo(
    () =>
      !hasMatchFilter
        ? [
            {
              id: 'student',
              accessorKey: 'student',
              header: (
                <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
                  Игрок
                </ToggledHeader>
              ),
              headerStyle: {
                height: '44px !important',
                maxHeight: 44,
                width: !isToggled ? 280 : 80,
                maxWidth: !isToggled ? 280 : 80,
                '.MuiBox-root': {
                  display: 'flex',
                  boxShadow: 'none',
                  padding: 0,
                },
              },
              sticky: {left: true},
              sortable: !isMobile,
              sortingFn: (rowA, rowB, columnId) => {
                const a = rowA?.getValue(columnId).lastName;
                const b = rowB?.getValue(columnId).lastName;
                return defaultSort(a, b);
              },
              cell: ({getValue}) => {
                return (
                  <Box alignItems="center" display="flex" justifyContent={isToggled && 'center'}>
                    <PlayersHorizontalCard
                      hasImage={!isToggled}
                      isLoading={isLoading}
                      isShortName={isMobile}
                      onlyNumber={isToggled}
                      preloaderWidthBetween={[100, 170]}
                      student={getValue()}
                    />
                  </Box>
                );
              },
              cellStyle: {width: !isToggled ? 220 : 80, maxWidth: !isToggled ? 220 : 80},
            },
            ...columns,
          ]
        : [
            {
              id: 'student',
              accessorKey: 'student',
              header: (
                <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
                  Игрок
                </ToggledHeader>
              ),
              headerStyle: {
                height: '44px !important',
                maxHeight: 44,
                width: !isToggled ? 280 : 80,
                maxWidth: !isToggled ? 280 : 80,
                '.MuiBox-root': {
                  display: 'flex',
                  boxShadow: 'none',
                  padding: 0,
                },
              },
              sticky: {left: true},
              sortable: !isMobile,
              sortingFn: (rowA, rowB, columnId) => {
                const a = rowA?.getValue(columnId).lastName;
                const b = rowB?.getValue(columnId).lastName;
                return defaultSort(a, b);
              },
              cell: ({getValue}) => {
                return (
                  <Box alignItems="center" display="flex" justifyContent={isToggled && 'center'}>
                    <PlayersHorizontalCard
                      hasImage={!isToggled}
                      isLoading={isLoading}
                      isShortName={isMobile}
                      onlyNumber={isToggled}
                      preloaderWidthBetween={[100, 170]}
                      student={getValue()}
                    />
                  </Box>
                );
              },
              cellStyle: {width: !isToggled ? 220 : 80, maxWidth: !isToggled ? 220 : 80},
            },
            {
              accessorKey: 'gamesCount',
              header: () => {
                return (
                  <Tooltip title="Игры">
                    <Typography sx={{display: 'inline-block'}} variant="subtitle2">
                      И
                    </Typography>
                  </Tooltip>
                );
              },
              headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
              cellStyle: {position: 'relative', textAlign: 'center'},
              sortingFn: customTableComparator,
            },
            ...columns,
          ],
    [isToggled, isLoading, isMobile, hasMatchFilter, columns],
  );
  const rows = useMemo(
    () =>
      rowsData.map((item, id) => {
        const student = item?.student;
        const defaultRows = {
          id,
          student,
          gamesCount: student?.gamesCount,
        };
        return {...item, ...(student ? defaultRows : {})};
      }),
    [rowsData],
  );
  return isLoading || !rowsData?.length || hasError ? (
    <BoxWrapper alignItems="center" display="falex" height={300} justifyContent="center" position="relative">
      {isLoading && <LocalPreloader visible />}
      {!isLoading && (isLoad || !rowsData?.length) && (
        <Typography variant="body1">Нет статистики по выбранному табу</Typography>
      )}
    </BoxWrapper>
  ) : (
    <>
      {showAnnotation && (
        <BoxWrapper backgroundColor="transparent !important" px="0 !important">
          <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2} max-width="100%" px={{xxs: 2, sm: 4}}>
            <Stack
              alignItems={{xxs: 'initial', xs: 'center'}}
              direction={{xxs: 'column', xs: 'row'}}
              flexWrap="wrap"
              gap={{xxs: 3, xs: 4, sm: 5}}
              justifyContent={'space-between'}
              width="100%"
            >
              <Stack direction="row" spacing={1}>
                <CheckboxDefault
                  checked={hasWingers}
                  label="Нападающие"
                  size="small"
                  onChange={() => {
                    setHasWingers(!hasWingers);
                  }}
                />
                <CheckboxDefault
                  checked={hasDefensemen}
                  label="Защитники"
                  size="small"
                  onChange={() => {
                    setHasDefensemen(!hasDefensemen);
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={1}>
                {hasShifts && <LegendShiftItem>Время смены / переигранное время</LegendShiftItem>}
                <LegendDotItem color={grey['68']}>Лидеры</LegendDotItem>
                <LegendDotItem color={yellow['100']}>Аутсайдеры</LegendDotItem>
              </Stack>
            </Stack>
          </Stack>
        </BoxWrapper>
      )}
      <TableWrapper collapled={isMobile}>
        <ReactTableComponent
          isAvailableHeight
          columns={defaultColumns}
          customScrollBarHeight={600}
          data={rows}
          tableBodyCellProps={{sx: {'&:not(:first-child)': {width: '140px !important', maxWidth: '140px !important'}}}}
          tableHeaderCellProps={{
            sx: {py: 1, '&:not(:first-child)': {width: '140px !important', maxWidth: '140px !important'}},
          }}
          {...props}
        />
      </TableWrapper>
    </>
  );
}
TableTemplate.propTypes = {
  columns: PropTypes.array,
  rowsData: PropTypes.array,
  isLoading: PropTypes.bool,
  isLoad: PropTypes.bool,
  hasShifts: PropTypes.bool,
  hasError: PropTypes.bool,
  hasData: PropTypes.bool,
  hasTopFive: PropTypes.bool,
  fitAvailableHeight: PropTypes.bool,
  hasMatchFilter: PropTypes.bool,
};
export default React.memo(TableTemplate);
