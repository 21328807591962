import styled from 'styled-components';

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

export const SelectWrapper = styled.div`
  max-width: 180px;
  width: 100%;
`;
