import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const PsychoAPI = {
  getPsychoDashboard() {
    return axios.get(`${API_ENDPOINT}/psycho/dashboard/schedule`);
  },
  getInjuredPlayers() {
    return axios.get(`${API_ENDPOINT}/psycho/dashboard/injured-players`);
  },
  getRatingPlayers(groupId) {
    return axios.get(`${API_ENDPOINT}/psycho/dashboard/players-rating?groupId=${groupId}`);
  },
};

export default PsychoAPI;
