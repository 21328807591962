import queryString from 'query-string';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';

import {ROOMS} from '../../../constants';
import useInterval from '../../../hooks/useInterval';
import {getTVScheduleForHall, getTVScheduleForRoom} from '../../../redux/actions/TVScheduleActions';
import ScheduleHeader from '../../schedule-for-tv/tools/ScheduleHeader';
import ScheduleTableDefaultWithRooms from '../../schedule-for-tv/tools/scheduleTableDefaultWithRooms';
import TableEmptyBlock from '../../ui/TableEmptyBlock';

export default function ScheduleForTVPanel() {
  const dispatch = useDispatch();
  const history = useHistory();
  const qs = queryString.parse(history.location.search);

  const [progress, setProgress] = useState(0);
  const tvSchedule = useSelector((state) => state.tvSchedule?.schedule);

  useEffect(() => {
    if (qs.room) {
      if (qs.room === 'hall') {
        dispatch(getTVScheduleForHall());
      } else {
        dispatch(getTVScheduleForRoom(qs.room));
      }
    }
  }, []);

  useInterval(() => {
    if (qs.room) {
      if (qs.room === 'hall') {
        dispatch(getTVScheduleForHall());
      } else {
        dispatch(getTVScheduleForRoom(qs.room));
      }
    }
  }, 60000);

  let roomsIds = [];

  if (tvSchedule && !Array.isArray(tvSchedule)) {
    roomsIds = Object.keys(tvSchedule);
  }

  const [activeRoomId, setActiveRoomId] = useState('RnanlyjQOi');

  const changeSchedule = () => {
    for (let i = 0; i !== roomsIds.length; i++) {
      setActiveRoomId(roomsIds[i]);
    }
  };

  useEffect(() => {
    if (progress === 100) {
      setProgress(0);
      changeSchedule();
    }
  }, [progress]);

  const addFreePeriods = (events) => {
    if (tvSchedule) {
      let freePeriods = [];

      for (let i = 0; i < events.length; i++) {
        if (events[i + 1]) {
          if (events[i + 1]?.startDate - events[i]?.endDate > 60) {
            freePeriods.push({
              startDate: events[i]?.endDate + 60,
              endDate: events[i + 1]?.startDate - 60,
            });
          }
        }
      }
      return [...events, ...freePeriods].sort((a, b) => a.startDate - b.startDate);
    }
  };

  return (
    <>
      <GlobalStyle />
      {!Array.isArray(tvSchedule) ? (
        <Wrapper>
          {tvSchedule && roomsIds.length > 0 ? (
            <>
              <ScheduleHeader
                arenaName={tvSchedule[activeRoomId]?.name || 'Большая арена'}
                hasProgressBar={roomsIds.length > 1}
                progress={progress}
                setProgress={setProgress}
              />
              {tvSchedule[activeRoomId]?.events.length > 0 ? (
                <ScheduleTableDefaultWithRooms tvSchedule={addFreePeriods(tvSchedule[activeRoomId]?.events)} />
              ) : (
                <TableEmptyBlock text="Мероприятий пока нет" />
              )}
              <Footer>
                <FooterText>
                  Полное расписание на сайте <span>stars.hawk.ru</span> или по qr коду
                  <QR alt="qr code" src="/img/qr.gif" />
                </FooterText>
              </Footer>
            </>
          ) : (
            <>
              <ScheduleHeader
                arenaName={tvSchedule?.activeRoomId?.name || 'Большая арена'}
                hasProgressBar={roomsIds.length > 1}
                progress={progress}
                setProgress={setProgress}
              />
              <TableEmptyBlock text="Мероприятий пока нет" />
              <Footer>
                <FooterText>
                  Полное расписание на сайте <span>stars.hawk.ru</span> или по qr коду
                  <QR alt="qr code" src="/img/qr.gif" />
                </FooterText>
              </Footer>
            </>
          )}
        </Wrapper>
      ) : (
        <Wrapper>
          <ScheduleHeader arenaName={ROOMS.find((room) => room.id === qs.room).name} hasProgressBar={false} />
          {tvSchedule.length > 0 ? (
            <ScheduleTableDefaultWithRooms hasLockerRoom={false} tvSchedule={addFreePeriods(tvSchedule)} />
          ) : (
            <TableEmptyBlock text="Мероприятий пока нет" />
          )}
          <Footer>
            <FooterText>
              Полное расписание на сайте <span>stars.hawk.ru</span> или по qr коду
              <QR alt="qr code" src="/img/qr.gif" />
            </FooterText>
          </Footer>
        </Wrapper>
      )}
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 1vw;
  }
`;
const Wrapper = styled.div`
  padding: 7.125rem 0.625rem 7.1875rem;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url(/src/assets/images/dark-bg.png) no-repeat center;
  background-size: cover;

  .MuiCircularProgress {
    &-root {
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%) rotate(-90deg) !important;
    }
  }

  .TableEmptyBlock {
    & > p {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
`;
const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0.75rem;
  width: calc(100% - 0.75rem - 0.75rem);
  background-color: #353535;
`;
const FooterText = styled.p`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 3.5rem/4rem 'Bebas Neue';
  color: white;
  text-transform: uppercase;

  > span {
    padding: 0.875rem 0.875rem 0.5rem;
    margin: 0 1.75rem 0.5rem;
    background-color: #990011;
  }
`;
const QR = styled.img`
  margin-left: 1.75rem;
  margin-right: 0;
  width: 4rem;
  height: 4rem;
  background-color: white;
`;
