import React from 'react';
import styled from 'styled-components';

import {Button, LightButton} from '../../ui/Button';
import Icon from '../../ui/Icon/Icon';
import InputDefault from '../../ui/Input';
import TrainingPlanLessonList from './TrainingPlanLessonList';

export default function TrainingPlan(props) {
  let {
    trainingPlan = {},
    index = 0,
    addNewTrainingPlan = () => {},
    deleteTrainingPlan = () => {},
    handleTrainingPlanChange = () => {},
    emptyLesson = {},
    formatDateTime,
    parseDateTime,
    showErrorMessage,
  } = props;

  const handleLessonsChange = (newLessonsList) => {
    handleTrainingPlanChange(index, 'tasks', newLessonsList);
  };

  return (
    <Plan>
      <PlanInfo>
        <Button
          className={'delete-training-plan'}
          type="button"
          onClick={() => {
            deleteTrainingPlan(index);
          }}
        >
          <Icon height={17} type={'CloseRed'} viewBox={'0 0 17 17'} width={17} />
        </Button>
        <PlanTitle>{`Задача №${index + 1}`}</PlanTitle>
        <PlanLabel>Название задачи</PlanLabel>
        <InputDefault
          errorText={showErrorMessage && 'Обязательное поле для заполнения'}
          isInvalid={!trainingPlan.title?.trim() && showErrorMessage}
          placeholder={'Введите название задачи'}
          value={trainingPlan.title}
          onChange={(a) => {
            handleTrainingPlanChange(index, 'title', a.target.value);
          }}
        />
        <TrainingPlanLessonList
          emptyLesson={emptyLesson}
          formatDateTime={formatDateTime}
          lessonsList={trainingPlan.tasks}
          parseDateTime={parseDateTime}
          onChange={handleLessonsChange}
        />

        <LightButton type="button" onClick={addNewTrainingPlan}>
          <IconContainer>
            <Icon height="12" type="Plus" viewBox="0 0 12 12" width="12" />
          </IconContainer>
          <span>Еще одна задача</span>
        </LightButton>
      </PlanInfo>
    </Plan>
  );
}

const IconContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 34px;
  transform: translateY(-50%);
  width: 12px;
  height: 22px;

  @media screen and (max-width: 320px) {
    left: 24px;
  }
`;
const Plan = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  :last-of-type {
    ${LightButton} {
      display: flex;
      padding-left: 0;
      padding-right: 0;
      ${IconContainer} {
        @media screen and (max-width: 768px) {
          left: 28px;
        }
      }
    }
  }

  ${LightButton} {
    display: none;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 46px;
    margin-top: 12px;
    margin-bottom: 0;
    box-sizing: border-box;
    width: 100%;
    height: 24px;
    font: normal normal 14px/25px 'Proxima Nova';
    color: #747474;
    text-transform: uppercase;

    @media screen and (max-width: 320px) {
      font-size: 12px;
    }

    svg {
      right: auto;
      path {
        fill: #c4c4c4;
      }
    }
  }
  ${Button} {
    position: absolute;
    top: -6px;
    right: 18px;
    display: block;
    padding-right: 0;
    padding-left: 0;
    width: 17px;
    height: 30px;
    background-color: transparent;
    border-radius: 0;
    @media (min-width: 769px) and (max-width: 1200px) {
      right: 0;
    }
    @media screen and (max-width: 768px) {
      right: 0;
    }
  }
`;
const PlanInfo = styled.div`
  min-width: 280px;
  max-width: 80%;

  @media (min-width: 481px) and (max-width: 1200px) {
    min-width: 300px;
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    min-width: 300px;
  }
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
`;
const PlanTitle = styled.p`
  margin-top: 0;
  margin-bottom: 22px;
  font: bold normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
`;
const PlanLabel = styled.label`
  display: block;
  margin-top: 9px;
  margin-bottom: 9px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;

  + input {
    margin-top: 6px;
    margin-bottom: 8px;
    font: normal normal 14px/25px 'Proxima Nova';
    color: #2c2c2c;

    ::placeholder {
      font: normal normal 14px/25px 'Proxima Nova';
      color: #2c2c2c;
    }
  }
  + .SelectContainer {
    margin-top: 9px;
    margin-bottom: 12px;
  }
`;
