import {postPlayerDiary} from '@api/playerDiary';
import {DIARY_REQUEST_DATE} from '@components/apps/StudentDashboard/constants';
import {setDiaryEntityLoadingState, setPlayerDiary} from '@slices/playerDiary';
import {cloneDeep} from 'lodash';

export function updateDiaryEntity(
  formValues = {},
  currentData = {},
  entity = '',
  dispatch = () => {},
  onFinally = () => {},
) {
  const result = {date: DIARY_REQUEST_DATE, ...cloneDeep(formValues)};
  if (currentData?.id) result.id = currentData.id;
  if (currentData?.[entity]?.id) result[entity] = {...(currentData[entity] || {}), ...result[entity]};
  dispatch(setDiaryEntityLoadingState({name: entity, data: true}));
  postPlayerDiary(result)
    .then(({data}) => {
      dispatch(setPlayerDiary(data, entity));
      dispatch(setDiaryEntityLoadingState({name: entity, data: false}));
    })
    .catch(() => {
      result[entity] = currentData?.[entity] || null;
      dispatch(setPlayerDiary(result?.[entity], entity));
    })
    .finally(() => {
      onFinally();
    });
}

export function updateDiaryEntityDayOff(
  currentData = {},
  isDayOff = false,
  entity = '',
  dispatch = () => {},
  onFinally = () => {},
) {
  const result = {date: DIARY_REQUEST_DATE, [entity]: {}};
  if (currentData?.id) result.id = currentData.id;
  if (currentData?.[entity]?.id) result[entity] = {...(currentData[entity] || {}), ...result[entity]};
  result[entity].isDayOff = isDayOff;
  dispatch(setDiaryEntityLoadingState({name: entity, data: true}));
  postPlayerDiary(result, {failed: {message: 'Ошибка при сохранении'}})
    .then(({data}) => {
      dispatch(setPlayerDiary(data || null, entity));
      dispatch(setDiaryEntityLoadingState({name: entity, data: false}));
    })
    .catch(() => {
      result[entity] = currentData?.[entity] || null;
      dispatch(setPlayerDiary(result?.[entity], entity));
    })
    .finally(() => {
      onFinally();
    });
}
