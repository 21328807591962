import React from 'react';

const ForkAndKnifeDark = () => {
  return (
    <>
      <g>
        <path
          d="M19.4798 17.0049L11.6374 9.1625L2.47488 0H1.75883L1.61262 0.371523C1.22672 1.35215 1.06184 2.35512 1.13586 3.27188C1.22024 4.31687 1.61723 5.2216 2.28398 5.88836L9.43672 13.0411L10.3802 12.0977L16.9928 19.4919C17.6507 20.1497 18.7829 20.1888 19.4798 19.4919C20.1655 18.8062 20.1655 17.6906 19.4798 17.0049Z"
          fill="#686868"
        />
        <path
          d="M6.12219 11.3844L0.5202 16.9864C-0.165425 17.672 -0.165425 18.7877 0.5202 19.4733C1.17153 20.1246 2.29829 20.1821 3.00711 19.4733L8.60915 13.8713L6.12219 11.3844Z"
          fill="#686868"
        />
        <path
          d="M19.1505 3.3301L15.9727 6.50783L15.1437 5.67885L18.3215 2.50107L17.4925 1.67209L14.3148 4.84982L13.4858 4.02084L16.6636 0.843105L15.8346 0.0141602L11.6897 4.15908C11.1826 4.66615 10.8798 5.33943 10.837 6.05498C10.8262 6.23627 10.7851 6.41459 10.7169 6.5842L13.4095 9.27674C13.5791 9.2085 13.7575 9.16744 13.9387 9.15662C14.6543 9.11392 15.3275 8.81107 15.8346 8.304L19.9795 4.15912L19.1505 3.3301Z"
          fill="#686868"
        />
      </g>
    </>
  );
};

export default ForkAndKnifeDark;
