import React from 'react';

const PlusLarge = () => {
  return (
    <g>
      <path
        d="M18.9999 7.68784V11.3122C18.9999 11.585 18.7789 11.8064 18.5057 11.8064H11.8064V18.5057C11.8064 18.7789 11.585 18.9999 11.3122 18.9999H7.68784C7.41517 18.9999 7.19361 18.7789 7.19361 18.5057V11.8064H0.494228C0.221175 11.8064 0 11.585 0 11.3122V7.68784C0 7.41495 0.221175 7.19361 0.494228 7.19361H7.19361V0.494228C7.19361 0.221066 7.41511 0 7.68784 0H11.3122C11.585 0 11.8064 0.221066 11.8064 0.494228V7.19361H18.5057C18.7789 7.19361 18.9999 7.41495 18.9999 7.68784Z"
        fill="#686868"
      />
    </g>
  );
};

export default PlusLarge;
