import Skill from '@common/Skill';
import Close from '@mui/icons-material/Close';
import {Divider} from '@mui/material';
import Stack from '@ui/MUI/Stack';
import React from 'react';

const SkillList = ({fields, remove}) => {
  return (
    <Stack spacing={4}>
      <Divider />
      <Stack spacing={4}>
        {fields.map((field, index) => (
          <Skill {...field} endIcon={<Close onClick={() => remove(index)} />} key={field.id} />
        ))}
      </Stack>
    </Stack>
  );
};

export default SkillList;
