export const selectFilters =
  ({name, entityName}) =>
  (state) =>
    name ? state.skill.estimates[entityName].filters[name] : state.skill.estimates[entityName].filters;

export const selectCategories = (state) => state.skill.categories.list;

export const selectPlayers = (state) => state.skill.estimates.players.list;

export const selectArchivePlayers = (state) => state.skill.estimates.archive.list;

export const selectCategoriesEstimates = (state) => state.skill.estimates.categoriesEstimates.list;

export const selectEstimatesList = (state) => state.skill.estimates.list;

export const selectEstimateById = (id) => (state) =>
  state.skill.estimates.list.data.find((estimate) => estimate.id === id);

export const selectPersonalDevelopmentJournalPlayerSkills = (state) =>
  state.skill.personalDevelopmentJournalSkills.list;

export const selectModal = (state) => state.skill.estimates.modal;
