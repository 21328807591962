import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const FunctionalTestingAPI = {
  getGeneralState() {
    return axios.get(`${API_ENDPOINT}/v1/functional-testing/current/common-state`);
  },
  getStates() {
    return axios.get(`${API_ENDPOINT}/v1/functional-testing/current/states`);
  },
  getReportsForGroup(groupId) {
    return axios.get(`${API_ENDPOINT}/v1/functional-testing/current/${groupId}`);
  },
  saveReport(data) {
    return axios.post(`${API_ENDPOINT}/v1/functional-testing/current`, data, {
      success: {message: 'Новый отчет создан'},
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при создании отчета'};
      },
    });
  },
  editReport(data) {
    return axios.put(`${API_ENDPOINT}/v1/functional-testing/current`, data, {
      success: {message: 'Отчет успешно отредактирован'},
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при редактировании отчета'};
      },
    });
  },
  deleteReport(groupId, date) {
    return axios.delete(`${API_ENDPOINT}/v1/functional-testing/current/${groupId}/${date}`, {
      success: {message: 'Отчет успешно удален'},
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при удалении отчета'};
      },
    });
  },
  getReportByDate(date, groupId) {
    return axios.get(`${API_ENDPOINT}/v1/functional-testing/current/${groupId}/${date}`);
  },
  getFunctionalTestingDynamic(groupId, seasons) {
    return axios.get(
      `${API_ENDPOINT}/v1/functional-testing/current/${groupId}/dynamic`,
      {params: seasons},
      {
        failed: (err) => {
          const message = err?.data?.message;
          return {message: message || 'Ошибка при загрузке динамики функционального состояния'};
        },
      },
    );
  },
  getStageTestsByGroupId(groupId, seasons) {
    return axios.get(
      `${API_ENDPOINT}/v1/functional-testing/stage/${groupId}`,
      {params: seasons},
      {
        failed: (err) => {
          const message = err?.data?.message;
          return {message: message || 'Ошибка при загрузке этапного тестирования'};
        },
      },
    );
  },
  getStageTestsByDate(groupId, date) {
    return axios.get(`${API_ENDPOINT}/v1/functional-testing/stage/${groupId}/${date}`, {
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при загрузке отчета этапного тестирования'};
      },
    });
  },
  saveStagedTesting(data) {
    return axios.post(`${API_ENDPOINT}/v1/functional-testing/stage`, data, {
      success: {message: 'Новый отчет создан'},
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при создании отчета'};
      },
    });
  },
  editStagedTesting(data) {
    return axios.put(`${API_ENDPOINT}/v1/functional-testing/stage`, data, {
      success: {message: 'Отчет успешно отредактирован'},
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при редактировании отчета'};
      },
    });
  },
  deleteStagedTesting(groupId, date) {
    return axios.delete(`${API_ENDPOINT}/v1/functional-testing/stage/${groupId}/${date}`, {
      success: {message: 'Отчет успешно удален'},
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при удалении отчета'};
      },
    });
  },
};

export function getLastTestReport(playerId) {
  return axios.get(`${API_ENDPOINT}/v1/functional-testing/current/${playerId}/last-test`);
}

export function getCurrentFuncTesting(params) {
  return axios.get(`${API_ENDPOINT}/v1/functional-testing/current`, {params});
}

export default FunctionalTestingAPI;
