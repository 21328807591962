import {useEstimates} from '@common/DevelopmentJournal/hooks';
import Questionnaire from '@components/apps/StudentDashboard/components/Questionnaire';
import {DIARY_REQUEST_DATE, trainingsQuestions} from '@components/apps/StudentDashboard/constants';
import {updateDiaryEntity} from '@components/apps/StudentDashboard/helpers';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectPlayerDiary} from '@selectors/playerDiary';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import GameTypeSection from '../GameTypeSection';

const entity = 'trainingGroup';
function GroupTraining() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useStoreDispatch();
  const methods = useForm();
  const {handleSubmit} = methods;
  const {reset} = methods;
  const diary = useStore(selectPlayerDiary);
  const currentUser = useStore((state) => state.users.currentUser);
  const playerId = currentUser?.id;
  const skills = useEstimates({playersIds: [playerId], isCompleted: false})?.data;

  return (
    <>
      <GameTypeSection
        diary={diary}
        entity={entity}
        icon="bustsInSilhouette"
        name="Тренировка групповая"
        nullCheckValue="gymMood"
        onCancel={() => {}}
        onFillClick={() => setIsOpen(true)}
      />
      <FormProvider {...methods}>
        <Questionnaire
          content={trainingsQuestions.map((item) => {
            item.category.value = entity;
            item.subCategories = item.subCategories.map((subCategory) => ({...subCategory, nested: true}));
            return item;
          })}
          open={isOpen}
          skills={skills}
          title="Анкета. Групповая тренировка"
          onClose={() => {
            setIsOpen(false);
            reset();
          }}
          onSubmit={handleSubmit((formValues) => {
            const formattedForm = {
              ...formValues,
              trainingGroup: {
                ...formValues.trainingGroup,
                comments: !formValues?.trainingGroup?.comments
                  ? []
                  : Object?.keys(formValues?.trainingGroup?.comments)?.map((key) => {
                      return {
                        comment: formValues.trainingGroup.comments[key],
                        date: DIARY_REQUEST_DATE,
                        estimate: {
                          id: key,
                        },
                      };
                    }),
              },
            };
            setIsOpen(false);
            updateDiaryEntity(formattedForm, diary.data, entity, dispatch);
          })}
        />
      </FormProvider>
    </>
  );
}

export default GroupTraining;
