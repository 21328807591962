export const metricsMock = {
  goals: {
    count: '0',
    placeInTournament: '0',
    countInMajority: '0',
  },
  assists: {
    count: '0',
    placeInTournament: '0',
    countInMajority: '0',
  },
  score: {
    count: '0',
    placeInTournament: '0',
    countInMajority: '0',
  },
  plusMinus: {
    count: '0',
    placeInTournament: '0',
  },
  shotsOnGoals: {
    count: '0',
  },
  blockedShots: {
    count: '0',
  },
  winBattles: {
    percent: '0',
  },
  realizationShots: {
    percent: '0',
  },
};
