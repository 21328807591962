import ContentWrapper from '@common/ContentWrapper';
import Buttons from '@common/Statistics/ThrowsMap/Buttons';
import PlayersFilter from '@common/Statistics/ThrowsMap/PlayersFilter';
import FieldScatterChart from '@components/apps/StatsReportForOneGame/components/FieldScatterChart';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import {useMediaQuery} from '@mui/material';
import {selectShotsMapButtonTypes, selectShotsMapData, selectShotsMapFilters} from '@selectors/gameStatisticsMkc';
import {clearButtonTypes, clearShotsMap, loadButtonTypes, loadShotsMap} from '@slices/statisticsMKC/shotsMap';
import {MAPS_SIZE} from '@src/constants/stitictics';
import {parseFormToGetValue} from '@src/helpers';
import {Box} from '@ui/MUI';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {memo, useState} from 'react';
import {useDispatch} from 'react-redux';

Map.propTypes = {
  weAtHome: PropTypes.bool,
  setFilters: PropTypes.func.isRequired,
};

const xDimension = MAPS_SIZE.x;
const yDimension = MAPS_SIZE.y;
function getPos(data = []) {
  return data?.reduce((acc, el) => {
    const x = Math.round(el?.isOur ? el?.posX : xDimension - el?.posX);
    const y = Math.round(el?.isOur ? el?.posY : yDimension - el?.posY);
    const key = x?.toString() + '-' + y?.toString();

    acc[key] = [x, y, (acc[key]?.[2] || 0) + 1];

    return acc;
  }, {});
}

function generateData(filledData = {}, isXsRes) {
  const xData = [];
  const yData = [...Array(MAPS_SIZE.y)].map((_, index) => index);
  const data = [];
  let max = 1;

  if (!isEmpty(filledData)) {
    for (let x = 0; x <= xDimension; x++) {
      for (let y = 0; y <= yDimension; y++) {
        const shot = filledData[x.toString() + '-' + y.toString()];
        if (max < shot?.[2]) max = shot[2];

        data.push(
          shot && shot?.[0] >= 0 && shot?.[1] >= 0 && shot?.[2] >= 0
            ? [shot[isXsRes ? 1 : 0], shot[isXsRes ? 0 : 1], shot[2]]
            : [...(isXsRes ? [y, x] : [x, y]), '-'],
        );
      }
      xData.push(x);
    }
  }

  return {seriesData: data, xData, yData, min: 0, max};
}
function Map({weAtHome = true, setFilters = () => {}, statisticsIds = [], ...props}) {
  const dispatch = useDispatch();
  const [actionIds, setActionIds] = useState([]);
  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {data, isLoading, isLoad, error} = useStore(selectShotsMapData);
  const filterValues = useStore(selectShotsMapFilters);
  const filters = useDeepMemo(() => parseFormToGetValue(filterValues), [filterValues]);
  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [filters, actionIds]});

  const shotsPositions = useDeepMemo(() => (data?.length ? getPos(data) : {}), [data]);

  const {seriesData, xData, yData, min, max} = useDeepMemo(
    () => generateData(shotsPositions, isXsResolution),
    [shotsPositions, isXsResolution],
  );
  useDeepEffect(() => {
    statisticsIds?.length &&
      dispatch(
        loadShotsMap(
          {
            ...filters,
            statisticsIds,
            actionIds,
            cancelToken: newCancelToken(),
          },
          isCancel,
        ),
      );
    return () => {
      dispatch(clearShotsMap());
    };
  }, [filters, statisticsIds, actionIds]);
  return (
    <ContentWrapper>
      <PlayersFilter
        disabled={!statisticsIds?.length}
        setUsersFilter={(value) => setFilters({playerIds: value})}
        statisticsIds={statisticsIds}
        usersFilter={filterValues?.playerIds}
        valueEntity="avangardId"
      />
      <Buttons
        cleanup={clearButtonTypes}
        filters={actionIds}
        getter={selectShotsMapButtonTypes}
        loader={loadButtonTypes}
        setFilters={setActionIds}
      />
      <Box position="relative">
        <FieldScatterChart
          error={error}
          isLoad={isLoad}
          isLoading={isLoading || props?.isLoading}
          noData={(isLoad && !seriesData?.length) || !statisticsIds?.length}
          options={{
            legend: {
              show: false,
            },
            universalTransition: {
              enabled: true,
            },
            visualMap: {
              show: !isLoading && isLoad,
              min,
              max,
              calculable: true,
              realtime: false,
              orient: 'horizontal',
              itemHeight: '140',
              text: [max, min],
              left: 'center',
              bottom: -20,
              align: 'center',
            },
            [isXsResolution ? 'yAxis' : 'xAxis']: {
              type: 'category',
              data: xData,
              show: false,
              inverse: isXsResolution,
            },
            [isXsResolution ? 'xAxis' : 'yAxis']: {
              type: 'category',
              data: yData,
              show: false,
            },
            series: [
              {
                type: 'heatmap',
                data: seriesData || [],
                itemStyle: {
                  opacity: 0.7,
                },
                progressive: 600,
                animation: true,
              },
            ],
          }}
          weAtHome={weAtHome}
        />
      </Box>
    </ContentWrapper>
  );
}

export default memo(Map);
