import {FUNCTIONAL_STATE_INDICATORS_MATRIX} from '@components/apps/FunctionalTesting/constants';
import styled from 'styled-components';

export const TableCellFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isToggled ? 'center' : 'flex-start')};
`;

export const FunctionalStateIndicator = styled.div`
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border-right: 4px solid
    ${(props) =>
      FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.length > 1
        ? FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[1]
        : FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[0]};
  border-top: 4px solid
    ${(props) =>
      FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.length > 1
        ? FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[1]
        : FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[0]};
  border-left: 4px solid ${(props) => FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[0]};
  border-bottom: 4px solid ${(props) => FUNCTIONAL_STATE_INDICATORS_MATRIX[props.type]?.[0]};
  transform: rotate(45deg);
`;
