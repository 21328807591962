import ChildrenField from '@common/ChildrenField';
import date from '@helpers/date';
import {Add, Close} from '@mui/icons-material';
import {Box, Button, DatePicker, Stack, TimePicker} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React, {memo} from 'react';
import {useFieldArray, useWatch} from 'react-hook-form';
import styled from 'styled-components';

const Circle = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${grey[8]};
  border-radius: 50%;
`;

const Divider = styled.div`
  width: 10px;
  height: 1px;
  background-color: #000000;
`;

const startTime = date().valueOf();
const endTime = date().add('1', 'h').valueOf();

const Dates = () => {
  const {fields, append, remove} = useFieldArray({name: 'dates', keyName: '_innerId'});
  const isEdit = useWatch({name: 'id'});
  const isOnlyOne = fields.length === 1;

  const createOne = () => {
    const prevDate = fields[fields.length - 1];
    append({date: prevDate ? date(prevDate.date).add('1', 'd').valueOf() : date().valueOf(), startTime, endTime});
  };

  return (
    <>
      <Stack spacing={3}>
        {fields.map((field, index) => (
          <Stack alignItems="center" direction="row" key={field._innerId}>
            <Box maxWidth="110px" mr={5}>
              <ChildrenField isRequired name={`dates.${index}.date`}>
                {(field) => {
                  return (
                    <DatePicker
                      inputFormat="DD.MM.YY"
                      label="Дата"
                      mask="__.__.__"
                      value={field.value}
                      variant="standard"
                      onChange={field.onChange}
                    />
                  );
                }}
              </ChildrenField>
            </Box>
            <Stack alignItems="center" direction="row" mr={!isOnlyOne && 4} spacing={2}>
              <Box maxWidth="60px">
                <ChildrenField isRequired name={`dates.${index}.startTime`}>
                  {(field) => {
                    return (
                      <TimePicker
                        disableOpenPicker
                        label="Начало"
                        value={field.value || null}
                        variant="standard"
                        onChange={field.onChange}
                      />
                    );
                  }}
                </ChildrenField>
              </Box>
              <Divider />
              <Box maxWidth="60px">
                <ChildrenField isRequired name={`dates.${index}.endTime`}>
                  {(field) => {
                    return (
                      <TimePicker
                        disableOpenPicker
                        label="Конец"
                        value={field.value || null}
                        variant="standard"
                        onChange={field.onChange}
                      />
                    );
                  }}
                </ChildrenField>
              </Box>
            </Stack>
            {!isOnlyOne && (
              <Circle onClick={() => remove(index)}>
                <Close sx={{color: grey[68], fontSize: 18}} />
              </Circle>
            )}
          </Stack>
        ))}
      </Stack>
      {!isEdit && (
        <Box mt={4}>
          <Button color="primary" size="small" startIcon={<Add />} variant="text" onClick={createOne}>
            добавить еще дату
          </Button>
        </Box>
      )}
    </>
  );
};

export default memo(Dates);
