import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({theme}) => theme.spacing(3)};
`;

export const WrapperInner = styled.div`
  margin-top: ${({theme}) => theme.spacing(3)};
  margin-bottom: ${({theme}) => theme.spacing(4)};
  margin-left: 0;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  height: ${(props) => props.loading && '70vh'};
  gap: ${({theme}) => theme.spacing(1)};
  box-sizing: border-box;

  ${({theme}) => theme.breakpoints.down('sm')} {
    padding-right: ${({theme}) => theme.spacing(0)};
    padding-left: ${({theme}) => theme.spacing(0)};
  }
`;

export const SchoolName = styled.div`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.9);
  margin-top: ${({theme}) => theme.spacing(4)};
`;
