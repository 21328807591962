import React from 'react';

function HockeyPitch() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M14.1959 0.5H3.80412C2.25793 0.5 1 1.75793 1 3.30412V10.5336C1 12.0797 2.25793 13.3377 3.80412 13.3377H14.1959C15.7421 13.3377 17 12.0797 17 10.5336V3.30412C17 1.75793 15.7421 0.5 14.1959 0.5ZM4.29897 8.29311C4.86458 8.08966 5.27019 7.548 5.27019 6.91318C5.27019 6.27836 4.86458 5.7367 4.29897 5.53325V1.48966H8.50516V5.16789C7.74451 5.38388 7.18557 6.08425 7.18557 6.91318C7.18557 7.74211 7.74451 8.44245 8.50516 8.65847V12.348H4.29897V8.29311ZM9 7.73792C8.54524 7.73792 8.17526 7.36794 8.17526 6.91318C8.17526 6.45842 8.54524 6.08844 9 6.08844C9.45476 6.08844 9.82474 6.45842 9.82474 6.91318C9.82474 7.36794 9.45476 7.73792 9 7.73792ZM9.49485 8.65847C10.2555 8.44248 10.8144 7.74211 10.8144 6.91318C10.8144 6.08425 10.2555 5.38391 9.49485 5.16789V1.48966H13.701V5.53325C13.1354 5.7367 12.7298 6.27836 12.7298 6.91318C12.7298 7.548 13.1354 8.08966 13.701 8.29311V12.348H9.49485V8.65847ZM1.98969 10.5335V3.30412C1.98969 2.47519 2.54864 1.77485 3.30928 1.55884V12.2788C2.54864 12.0628 1.98969 11.3624 1.98969 10.5335ZM16.0103 10.5335C16.0103 11.3624 15.4514 12.0628 14.6907 12.2788V1.55884C15.4514 1.77482 16.0103 2.47519 16.0103 3.30412V10.5335Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default HockeyPitch;
