import TablePaginationDefault from '@ui/MUI/TablePagination';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import UsersAPI from '../../../api/UsersAPI';
import LocalPreloader from '../../preloader/LocalPreloader';
import ChessTemplate from '../../templates/ChessTemplate';
import TableDefault from '../../ui/TableDefault';
import UsersFilters from './components/UsersFilters';
import UsersTableContent from './components/UsersTableContent';

const breadCrumbs = [
  {label: 'Главная', path: '/'},
  {label: 'Пользователи', path: '/users'},
];

const Users = () => {
  const [filters, setFilters] = useState({
    schoolId: null,
    q: null,
    userRole: null,
    limit: 25,
    page: 1,
  });

  const [{users, count}, setUsers] = useState({users: [], count: null});
  const [isLoading, setIsLoading] = useState(false);

  const [filterData, setFilterData] = useState({selectedPage: 0});
  const [selectedCount, setSelectedCount] = useState(25);
  const userRole = useSelector((state) => state.users.currentUser.userRole);
  const schoolsIds = useSelector((state) => state.users.currentUser.schoolsIds);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const {data} = await UsersAPI.getUsersByQueryV2(filters);
      setUsers({users: data.result, count: data.total});
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, [filters]);

  const handleChange = (value, name) => {
    setFilters((prev) => ({...prev, [name]: value}));
  };

  const headlineContent = useMemo(
    () => <UsersFilters userRole={userRole} onChange={handleChange} />,
    [handleChange, userRole],
  );
  return (
    <ChessTemplate active="users">
      <PageTitleDefault breadCrumbs={breadCrumbs} text="Пользователи" />
      <StyledTableDefault
        withOutOverFlow
        content={
          <Container loading={isLoading}>
            <LocalPreloader visible={isLoading} />
            {headlineContent}
            <UsersTableContent items={users} schoolsIds={schoolsIds} userRole={userRole} />
            <TablePaginationDefault
              count={count}
              page={filterData?.selectedPage}
              rowsPerPage={selectedCount}
              onPageChange={(event, newPage) => {
                setFilterData((prev) => {
                  return {
                    ...prev,
                    selectedPage: newPage,
                  };
                });
                setFilters({...filters, page: newPage});
              }}
              onRowsPerPageChange={(e) => {
                setSelectedCount(e.target.value);
                setFilters({...filters, limit: e.target.value});
                setFilterData({selectedPage: 0});
              }}
            />
          </Container>
        }
        title="Пользователи платформы"
        onChange={handleChange}
      />
    </ChessTemplate>
  );
};

const Container = styled.div`
  position: relative;
  min-height: 500px;
  max-height: ${(props) => (props.loading ? '60vh' : '100%')};
  border-radius: ${(props) => (props.loading ? '0 0 6px 6px' : '6px')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};

  .ScrollbarsCustom {
    max-height: none;
  }
`;

const StyledTableDefault = styled(TableDefault)`
  margin-top: 0px;
  margin-bottom: 0px;
`;

export default Users;
