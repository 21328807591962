import React from 'react';

function StarFavorites() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M3.35732 14.6962C3.19298 14.6962 3.02978 14.645 2.8905 14.5443C2.63106 14.3562 2.50986 14.033 2.58047 13.7212L3.54546 9.4704L0.272828 6.60037C0.032267 6.39037 -0.0596314 6.0578 0.0391337 5.75349C0.137899 5.44976 0.406613 5.23471 0.724767 5.2053L5.05486 4.81219L6.76683 0.805852C6.89306 0.511502 7.18054 0.321411 7.49996 0.321411C7.81937 0.321411 8.10685 0.511502 8.23308 0.805165L9.94505 4.81219L14.2745 5.2053C14.5933 5.23403 14.862 5.44976 14.9608 5.75349C15.0595 6.05722 14.9682 6.39037 14.7277 6.60037L11.455 9.46982L12.42 13.7205C12.4907 14.033 12.3694 14.3562 12.1101 14.5437C11.8513 14.7312 11.5063 14.7456 11.2338 14.5818L7.49996 12.3505L3.76611 14.5831C3.63988 14.658 3.49923 14.6962 3.35732 14.6962ZM7.49996 11.3893C7.64187 11.3893 7.7824 11.4274 7.90875 11.5023L11.4326 13.6099L10.5218 9.59789C10.4568 9.31235 10.5538 9.01422 10.7744 8.82104L13.8645 6.11101L9.77625 5.73976C9.4819 5.71286 9.22875 5.52781 9.11373 5.25657L7.49996 1.47581L5.88424 5.25715C5.77048 5.52655 5.51733 5.7116 5.22367 5.7385L1.13482 6.10975L4.2248 8.81978C4.44614 9.01354 4.54296 9.31109 4.47738 9.59731L3.56732 13.6093L7.09116 11.5023C7.2174 11.4274 7.35805 11.3893 7.49996 11.3893ZM5.02179 4.88967C5.02179 4.88967 5.02179 4.89035 5.0211 4.89093L5.02179 4.88967ZM9.97687 4.88784L9.97755 4.88909C9.97755 4.88841 9.97755 4.88841 9.97687 4.88784Z"
          fill="#2C2C2C"
        />
      </g>
    </React.Fragment>
  );
}
export default StarFavorites;
