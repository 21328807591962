import TooltipDefault from '@ui/MUI/Tooltip';
import React, {useEffect, useState} from 'react';

import RadarChart from '../../charts/RadarChart';

export default function TypologicalProfileChart(props) {
  const {playerTypologicalReport} = props;
  const [chartData, setChartData] = useState({});
  const generateTypologicalChartData = (data) => {
    let radius;

    if (window.innerWidth <= 575) {
      radius = 140;
    } else {
      radius = 170;
    }
    if (window.innerWidth <= 480) {
      radius = 110;
    }
    const chartData = Object.values(data);
    const chartSeasons = Object.keys(data);
    const chartRadarOptions = {
      legend: {
        icon: 'circle',
        data: chartSeasons.map((season) => `Отчет за ${data[season][0].date} (Сезон ${season})`),
        bottom: '6%',
      },
      triggerEvent: true,
      radar: {
        center: window.innerWidth >= 481 ? ['50%', '250px'] : ['50%', '160px'],
        radius: radius,
        splitNumber: 5,
        axisName: {
          formatter: (params) => {
            let result = '';

            if (params.length > 17) {
              let words = params.split(' ');
              words.map((word, index) => {
                if (index !== 0 && index % 2 === 0) {
                  result += '\n';
                }
                result += word + ' ';
              });
            } else {
              result = params;
            }
            return result;
          },
          padding: window.innerWidth >= 481 ? 8 : [0, -10, 0, -10],
          textStyle: {
            fontFamily: 'Proxima Nova',
            fontWeight: 'normal',
            fontSize: 14,
            lineHeight: 16,
            color: '#2C2C2C',
          },
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            color: '#C4C4C4',
          },
        },
        indicator: [],
      },
      series: [
        {
          type: 'radar',
          data: [],
        },
      ],
    };
    chartData.map((radar, index) => {
      const reversedRadar = radar.reverse();
      const convertedRadar = reversedRadar.filter((item) => item.id !== 'VIII').concat([reversedRadar[0]]);
      chartRadarOptions.series[0].data?.push({
        areaStyle: {opacity: 0.2},
        name: chartRadarOptions.legend.data[index],
        value: convertedRadar.map((param) => param.value),
      });
      const shortNames = {
        I: 'Экстр.',
        II: 'Сп.',
        III: 'Стен.',
        IV: 'Риг.',
        V: 'Интр.',
        VI: 'Сенз.',
        VII: 'Тр.',
        VIII: 'Лаб.',
      };
      chartRadarOptions.radar.indicator = convertedRadar.map((item) => ({
        text: window.innerWidth >= 481 ? item.label : shortNames[item.id],
        max: 10,
        key: item.id,
        color: 'black',
      }));
    });

    if (window.innerWidth <= 480) {
      chartRadarOptions.radar.name.textStyle = {
        fontFamily: 'Proxima Nova',
        fontWeight: 600,
        fontSize: 12,
        lineHeight: 12,
        color: 'black',
      };
    }
    setChartData(chartRadarOptions);
  };

  useEffect(() => {
    if (Object.values(playerTypologicalReport).length) {
      generateTypologicalChartData(playerTypologicalReport);
    }
  }, [playerTypologicalReport]);

  return (
    <div className="ColumnRadar">
      <RadarChart
        options={{
          ...chartData,
          label: {show: true},
        }}
      />
      <div className="ColumnRadarIntermediateIndicators">
        <TooltipDefault arrow={false} title="Стремление к самоутверждению сопровождается избеганием конфликта.">
          <p className="ColumnRadarIntermediateIndicator">Компромиссность</p>
        </TooltipDefault>
        <TooltipDefault arrow={false} title="Склонность к широким социальным контактам и коммуникабельность.">
          <p className="ColumnRadarIntermediateIndicator">Коммуникативность</p>
        </TooltipDefault>
        <TooltipDefault
          arrow={false}
          title="Самостоятельность в принятии решений, предприимчивость и стремление быть ведущим."
        >
          <p className="ColumnRadarIntermediateIndicator">Лидерство</p>
        </TooltipDefault>
        <TooltipDefault
          arrow={false}
          title="Преобладание значимости собственного мнения над значимостью мнения группы,
          общественных норм и стереотипов. Ориентация на двигательную активность (спортивная деятельность,
          путешествия, профессии, связанные с повышенными физическими нагрузками)."
        >
          <p className="ColumnRadarIntermediateIndicator">Некомформность</p>
        </TooltipDefault>
        <TooltipDefault
          arrow={false}
          title="Сочетание устойчивости собственного мнения
          с тенденцией к агрессивной манере самоутверждения вопреки интересам окружающих."
        >
          <p className="ColumnRadarIntermediateIndicator">Конфликтность</p>
        </TooltipDefault>
        <TooltipDefault
          arrow={false}
          title="Устойчивость собственного мнения, временами достаточно самобытного и субъективного.
          Системный, синтетический, прагматичный стиль мышления."
        >
          <p className="ColumnRadarIntermediateIndicator">Индивидуализм</p>
        </TooltipDefault>
        <TooltipDefault
          arrow={false}
          title="Чувствительность и ранимость в отношении среды, с другой стороны,
          выраженной потребностью в глубокой и постоянной привязанности и в защите со стороны более сильной личности,
          что создает почву для поведения, направленного на избегание конфликта и поиска щадящей социальной ниши."
        >
          <p className="ColumnRadarIntermediateIndicator">Зависимость</p>
        </TooltipDefault>
        <TooltipDefault arrow={false} title="Избыточная ориентированность на общепринятые нормы поведения.">
          <p className="ColumnRadarIntermediateIndicator">Комформность</p>
        </TooltipDefault>
      </div>
    </div>
  );
}
