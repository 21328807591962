import TextFieldDefault from '@ui/MUI/TextField';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {Field, FixedInput, ModalTitle} from '../CommonScoutReport';

function GroupReportCreateContent({group, isEdit = false}) {
  const {control} = useFormContext();

  return (
    <form>
      <ModalTitle>{isEdit ? 'Редактирование отчета' : 'Новый отчет'}</ModalTitle>
      <Field align="flex-start" margin="0 0 24px 0">
        <FixedInput>
          <span className="label">Команда</span>
          <span className="text">{group?.name}</span>
        </FixedInput>
      </Field>
      <Field margin="0 0 24px 0">
        <Controller
          control={control}
          name="description"
          render={({field: {value, onChange}, fieldState: {error}}) => (
            <TextFieldDefault
              multiline
              error={!!error}
              helperText={error?.message}
              inputProps={{maxLength: 2000}}
              label={'Отчет'}
              maxRows={4}
              value={value}
              onChange={onChange}
            />
          )}
          rules={{required: 'Заполните поле'}}
        />
      </Field>
    </form>
  );
}

export default GroupReportCreateContent;
