import Stack from '@ui/MUI/Stack';
import React, {memo} from 'react';

import MaterialCard from '../../components/MaterialCard';
import {useMaterialSection} from '../../hooks';
import {PageBox} from '../../styles';
import Filters from './components/Filters';
import LoadingSkeleton from './skeleton';

const VideoAnalytics = ({filters, onFiltersChange}) => {
  const {data, isLoading} = useMaterialSection();
  const {materials = []} = data || {};
  return (
    <>
      <Filters filters={filters} onChange={onFiltersChange} />
      <PageBox>
        <Stack direction="row" flexWrap="wrap" gap="32px 16px">
          {isLoading ? (
            <LoadingSkeleton type="video" />
          ) : (
            materials.map((material) => <MaterialCard {...material} key={material.id} sectionType="video" />)
          )}
        </Stack>
      </PageBox>
    </>
  );
};

export default memo(VideoAnalytics);
