import useCustomForm from '@hooks/useCustomForm';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {createTrainingControl} from '@slices/trainingControlProcess';

export const useSectionForm = () => {
  const {methods, ...form} = useCustomForm({sections: []});

  const dispatch = useStoreDispatch();
  const handleSubmit = ({sections}) => {
    dispatch(createTrainingControl({name: 'section', payload: {sections}, onSuccess: form.close}));
  };

  return {methods, form, handleSubmit: methods.handleSubmit(handleSubmit)};
};

export const useCategoryForm = () => {
  const {methods, ...form} = useCustomForm({categories: []});

  const dispatch = useStoreDispatch();
  const handleSubmit = ({categories, sectionId}) => {
    dispatch(
      createTrainingControl({
        name: 'category',
        payload: {categories, sectionId},
        connectores: ['section'],
        onSuccess: form.close,
      }),
    );
  };
  return {methods, form, handleSubmit: methods.handleSubmit(handleSubmit)};
};
