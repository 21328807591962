import {Stack, Typography} from '@mui/material';
import React from 'react';

const Preview = ({comment, time, students}) => {
  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography color="#747474" variant="caption1">
          Время
        </Typography>
        <Typography color="#990011" variant="subtitle2">
          {time}
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography color="#747474" variant="caption1">
          Студенты
        </Typography>
        <Typography variant="subtitle2">{students.map((student) => student.label).join(', ')}</Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography color="#747474" variant="caption1">
          Комментарий
        </Typography>
        <Typography variant="body2">{comment}</Typography>
      </Stack>
    </Stack>
  );
};

export default Preview;
