import Tabs, {TabPane} from 'rc-tabs';
import React from 'react';

import {TabsContainer} from '../TabsStyledComponent';

export default function TabsDefault(props) {
  const {
    tabs = [],
    activeKey,
    onActiveKeyChange = () => {},
    defaultActiveKey = 0,
    cssThemeModificator = 'light',
    cssPositionModificator = 'right',
    cssBackgroundModificator = 'withoutBg',
  } = props;

  return (
    <TabsContainer>
      <Tabs
        activeKey={activeKey}
        className={`Tabs Tabs_${cssThemeModificator} Tabs_${cssPositionModificator} Tabs_${cssBackgroundModificator}`}
        defaultActiveKey={defaultActiveKey}
        moreIcon="Еще"
        onChange={(key) => {
          onActiveKeyChange(key);
        }}
      >
        {tabs.map((tab, index) => {
          return (
            <TabPane key={index} tab={tab.label}>
              {tab.content}
            </TabPane>
          );
        })}
      </Tabs>
    </TabsContainer>
  );
}
