import styled from 'styled-components';

import Field from '../../../../Field';

export const FormField = styled.div`
  margin-bottom: 16px;
`;

export const Title = styled.h3`
  margin: 0 0px 16px 0px;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: #2c2c2c;
`;

export const Description = styled.p`
  margin: 0 0px 32px 0px;
  max-width: 304px;
  font-size: 14px;
  text-align: center;
  color: #747474;
`;

export const StyledField = styled(Field)`
  margin-bottom: 8px;
  height: 50px;
  font-family: 'Proxima Nova';
  padding: 0 8px;
  font-size: 16px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
  }
  text-align: center;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FieldsCover = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

export const Link = styled.button`
  margin-bottom: 16px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: ${(props) => (props.loading ? 'rgba(0, 0, 0, 0.26)' : '#1976d2')};
  &:disabled {
    cursor: progress;
  }
  cursor: pointer;
`;

export const Error = styled.p`
  margin: 0 0px 16px 0px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.17px;
  color: rgba(213, 61, 51, 1);
  max-width: 302px;
`;
