import {Box} from '@ui/MUI';
import {blue, darkRed, green, yellow} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

CellProgress.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  num: PropTypes.number,
  color: PropTypes.oneOf(['green', 'red', 'blue', 'yellow']),
};

const colors = {
  green: green['70'],
  red: darkRed['65'],
  blue: blue['60'],
  yellow: yellow['60'],
};

function CellProgress({min, max, num, color, isNegative, half, ...props}) {
  const width = (num - min) / ((max - min) / 100) / (half ? 2 : 1) + '%';
  return <Progress color={color} left={0} maxWidth="100%" width={width} {...props} />;
}

export default CellProgress;

const Progress = styled(Box)`
  position: absolute;
  bottom: 0;
  height: 5px;
  z-index: 1;
  background-color: ${({color}) => colors?.[color]};
`;
