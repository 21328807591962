import React from 'react';

import Control from '../components/Control';
import Exercises from '../components/Exercises';
import ExercisesCategoryTab from '../components/Exercises/components/ExercisesCategoryTab';
import ExercisesLibaryTab from '../components/Exercises/components/ExercisesLibaryTab';
import NewTraining from '../components/NewTraining';
import Scored from '../components/Scored';
import Skills from '../components/Skills';
import TrainingPlan from '../components/TrainingPlan';
import TrainingSkillsImprovementReport from '../components/TrainingSkillsImprovementReport';
import TrainingTemplates from '../components/TrainingTemplates';

export const generateRandomId = () => {
  return Math.random().toString(16).slice(2);
};

export const methodistPageTabs = [
  {label: 'Упражнения', value: <Exercises />},
  {label: 'Навыки', value: <Skills />},
  {label: 'Контроль', value: <Control />},
  {label: 'оцененные', value: <Scored />},
];

export const adminPageTabs = [
  {label: 'план тренировок', value: <TrainingPlan />},
  {label: 'Упражнения', value: <Exercises />},
  {label: 'Навыки', value: <Skills />},
  {label: 'Контроль', value: <Control />},
  {label: 'оцененные', value: <Scored />},
  {
    label: 'Отчет по развитию навыков',
    value: <TrainingSkillsImprovementReport />,
  },
];

export const tearcherPageTabs = [
  {label: 'план тренировок', value: <TrainingPlan />},
  {label: 'Новая тренировка', value: <NewTraining />},
  {label: 'упражнения', value: <ExercisesLibaryTab />},
  {label: 'Шаблоны тренировок', value: <TrainingTemplates />},
  {
    label: 'Отчет по развитию навыков',
    value: <TrainingSkillsImprovementReport />,
  },
];

export const methodistExercisesContentTabs = [
  {label: 'Библиотека', value: <ExercisesLibaryTab />},
  {label: 'направления подготовки', value: <ExercisesCategoryTab />},
];

export const skillMock = [
  {
    id: generateRandomId(),
    name: 'Прием и передачи шайбы с удобной/неудобной стороны-на месте, в движении',
    number: 22,
    section: {name: 'Техника владения клюшкой', id: generateRandomId()},
    category: {name: 'Владение клюшкой и контроль шайбой в движении', id: generateRandomId()},
  },
  {
    id: generateRandomId(),
    name: 'Прием и передачи шайбы с удобной/неудобной стороны-на месте, в движении',
    number: 22,
    section: {name: 'Техника владения клюшкой', id: generateRandomId()},
    category: {name: 'Владение клюшкой и контроль шайбой в движении', id: generateRandomId()},
  },
  {
    id: generateRandomId(),
    name: 'Прием и передачи шайбы с удобной/неудобной стороны-на месте, в движении',
    number: 22,
    section: {name: 'Техника владения клюшкой', id: generateRandomId()},
    category: {name: 'Владение клюшкой и контроль шайбой в движении', id: generateRandomId()},
  },
  {
    id: generateRandomId(),
    name: 'Прием и передачи шайбы с удобной/неудобной стороны-на месте, в движении',
    number: 22,
    section: {name: 'Техника владения клюшкой', id: generateRandomId()},
    category: {name: 'Владение клюшкой и контроль шайбой в движении', id: generateRandomId()},
  },
];

export const sectionsMock = [
  {
    name: 'Техника владения клюшкой',
    id: generateRandomId(),
    categories: [{name: 'Владение клюшкой и контроль шайбой в движении', id: generateRandomId()}],
  },
];
export const themesMock = [{name: 'На малом пространстве', id: generateRandomId()}];
export const agesMock = [{name: 'U20', id: generateRandomId()}];

export const exercisesMock = [
  {
    author: {
      name: 'John',
      id: generateRandomId(),
    },
    name: 'Владение клюшкой',
    theme: themesMock[0],
    age: agesMock[0],
    description: `Текст описания, информация  выполнении. Описание упражнения, информация о выполнении, правила:
    Передачи в парах удобной и неудобной х 2 поля 
    В парах разворот на 180 градусов из-за спины х 4 поля 
    Длинные передачи в парах прием одной-отдать другой`,
    mainMoments: `Правильная постановка ног, следить за положением корпуса во время выполнения упражнения.Обратить особое внимание на навык 48 и на технику выполнения.`,
    skills: skillMock,
  },
];

export const isOptionEqualToValue = (option, value) => option.id === value.id;
export const getOptionLabel = (option) => option.name;
