import ContentWrapper from '@common/ContentWrapper';
import FullHeightWrapper from '@common/FullHeightWrapper';
import Table from '@components/apps/Tournaments/components/Table';
import {mock} from '@components/apps/Tournaments/components/Table/mock';
import {useSeasons} from '@hooks/seasonsHooks';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import AddIcon from '@mui/icons-material/Add';
import {selectTournaments} from '@selectors/tournaments';
import {clearTournamentsData, loadCurrentEditTournament, loadTournamentsList} from '@slices/tournaments';
import {InitialStateIndicators} from '@src/types';
import {Box, Select, Stack, Typography} from '@ui/MUI';
import ButtonDefault from '@ui/MUI/Button';
import React, {useCallback, useEffect, useState} from 'react';

import CreateTournamentModal from './components/CreateTournamentModal';

function Tournaments() {
  const {options, ...seasons} = useSeasons(
    false,
    ({currentSeason}) => currentSeason?.value && setSelectedSeason(currentSeason?.value),
  );
  const [selectedSeason, setSelectedSeason] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useStoreDispatch();
  const {data, isLoading, isLoad} = useStore<InitialStateIndicators<unknown[]>>(selectTournaments);

  const loading = isLoading || seasons?.isLoading;

  const handleStartEdit = useCallback((id) => {
    dispatch(loadCurrentEditTournament(id));
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    if (selectedSeason) {
      dispatch(loadTournamentsList({season: selectedSeason}));
    }
    return () => {
      dispatch(clearTournamentsData());
    };
  }, [selectedSeason]);
  return (
    <Stack direction="column" gap={{xxs: 2, sm: 3}} position="relative">
      <ContentWrapper>
        <Stack direction="row" gap={1} justifyContent="space-between">
          <Box width={{xxs: '100%', xs: 180}}>
            <Select
              label="Сезон"
              options={options}
              value={selectedSeason}
              onChange={(e) => {
                setSelectedSeason(e.target.value);
              }}
            />
          </Box>
          <ButtonDefault
            color="primary"
            size="medium"
            startIcon={<AddIcon />}
            sx={{width: {xxs: '100%', xs: 'auto'}, mt: {xs: '0px', xxs: 3}}}
            variant="contained"
            onClick={() => {
              setIsModalOpen(!isModalOpen);
            }}
          >
            Добавить
          </ButtonDefault>
        </Stack>
      </ContentWrapper>
      {loading || (isLoad && data.length) ? (
        <Table data={loading ? mock : data} isLoading={loading} onEdit={handleStartEdit} />
      ) : (
        <ContentWrapper p="0" position="relative" width="100%">
          <FullHeightWrapper alignItems="center" display="flex" justifyContent="center" width="100%">
            <Typography variant="subtitle1">Турниры еще не добавлены</Typography>
          </FullHeightWrapper>
        </ContentWrapper>
      )}
      <CreateTournamentModal
        isOpen={isModalOpen}
        loadTournaments={() => dispatch(loadTournamentsList({season: selectedSeason}))}
        setIsOpen={setIsModalOpen}
      />
    </Stack>
  );
}

export default Tournaments;
