import useEvent from '@hooks/useEvent';
import {selectTournament, selectTournamentById, selectTournaments} from '@selectors/tournaments';
import {loadTournamentItem, loadTournamentsList} from '@slices/tournaments';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const subscribers = new Set();
export const action = () => {
  subscribers.forEach((cb) => cb());
};
export const watcher = (cb) => {
  subscribers.forEach(cb);
};

export const clearCachedTournaments = () => {};

export const useTournaments = ({season = '', preFetch = false} = {}) => {
  const dispatch = useDispatch();
  const fetcher = useEvent((params) => {
    dispatch(loadTournamentsList(params));
  });
  const {isLoading, error, isLoad, data, cache} = useSelector(selectTournaments);
  const isNoData = isLoad && !data.length;

  // если сезон был уже выбран, то данные уже загружены и нет смысла делать запрос на сервер
  const isCached = useMemo(() => Boolean(cache?.[season]), [cache?.[season]]);

  const shouldSkipFetch = !preFetch || isCached || isLoading;

  useEffect(() => {
    if (!shouldSkipFetch) {
      fetcher({season});
    }
  }, [season, shouldSkipFetch]);

  if (isCached) {
    return {
      isLoading: false,
      error: null,
      isLoad: true,
      data: cache[season] || [],
      fetcher,
      isNoData: false,
    };
  }

  return {
    isLoading,
    error,
    isLoad,
    data,
    fetcher,
    isNoData,
  };
};

const tournamentCache = {};

export const useTournament = (id, {preFetch = true, optimistic = true} = {}) => {
  const dispatch = useDispatch();
  const fetcher = useEvent((id, params) => {
    dispatch(loadTournamentItem(id, params));
  });

  const {isLoading, error, isLoad, data} = useSelector(selectTournament);
  const optimisticTournament = useSelector(selectTournamentById(id));
  const isTournamentsLoading = useSelector(selectTournaments).isLoading;
  // если id турнира не изменился, то данные уже загружены и нет смысла делать запрос на сервер
  let isDataCached = tournamentCache[id];
  if (!isDataCached && data?.id === id) {
    tournamentCache[id] = data;
    isDataCached = true;
  }

  // если optimistic === true, то мы не делаем запрос на сервер, а берем данные из redux (иногда данных по турнирам нету и нужно дождаться загрузки турниров)
  // скипаем прифетч если id не передан или preFetch === false или данные уже загружены или данные закешированы или турнир уже загружается
  const isOptimistic = optimistic && (optimisticTournament || isTournamentsLoading);
  const isSkipFetch = !id || !preFetch || isOptimistic || isDataCached || isLoading;
  const isNoData = isLoad && !data;

  useEffect(() => {
    if (!isSkipFetch) {
      fetcher(id);
    }
  }, [id, preFetch, isSkipFetch]);

  if (isDataCached) {
    return {
      isLoading: false,
      error: null,
      isLoad: true,
      data: tournamentCache[id],
      isNoData: false,
    };
  }

  if (optimistic && optimisticTournament) {
    return {
      isLoading: false,
      error: null,
      isLoad: true,
      data: optimisticTournament,
      isNoData: false,
    };
  }

  return {
    isLoading,
    error,
    isLoad,
    data,
    isNoData,
  };
};
