import BodyMassIndexAPI from '@api/BodyMassIndexAPI';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import * as types from '@src/redux/ActionTypes';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';

const errorMessage = (err) => {
  let errorText = 'Ошибка при загрузке';
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
      }
    }
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

const setBMIData = (data) => {
  return {
    type: types.GET_BMI_DATA,
    data: data,
  };
};
const setLoadingStart = () => {
  return {
    type: types.BMI_LOADING_START,
  };
};

const setLoadingError = () => {
  return {
    type: types.BMI_LOADING_ERROR,
  };
};

export const getBodyMassIndexReportForPlayer = (params) => async (dispatch) => {
  dispatch(setLoadingStart());
  const playerDataForRangeOfTwoDates = await BodyMassIndexAPI.getBodyMassIndexReportForPlayer(params).then((res) => {
    return res.data;
  });

  const splitDataForCharts = () => {
    const heightValues = [];
    const weightValues = [];
    const caliperometryValues = [];
    const bmiValues = [];
    const paramsDates = [];

    playerDataForRangeOfTwoDates?.params.forEach(function ({height, weight, caliperometry, bmi, date}) {
      heightValues.push({
        value: height,
        id: date,
      });
      weightValues.push({
        value: weight,
        id: date,
      });
      caliperometryValues.push({
        value: caliperometry,
        id: date,
      });
      bmiValues.push({
        value: bmi,
        id: date,
      });
      paramsDates.push(date);
    });

    return {
      heightValues,
      weightValues,
      caliperometryValues,
      bmiValues,
      paramsDates,
    };
  };

  const splitData = splitDataForCharts();

  const summaryData = {
    splitData,
  };

  dispatch(setBMIData(summaryData));
};

export const getBodyMassIndexParamsForPlayerDashboard = (playerId) => {
  return (dispatch) => {
    dispatch(setLoadingStart);
    return BodyMassIndexAPI.getBodyMassIndexParamsForPlayerDashboard(playerId)
      .then((res) => {
        dispatch(setBMIData(res.data));
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch(setLoadingError);
      });
  };
};
