import AdapterMoment from '@mui/lab/AdapterMoment';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {LocalizationProvider, TimePicker as TimePickerMui} from '@mui/x-date-pickers';
import {TimePickerProps} from '@mui/x-date-pickers/TimePicker/TimePicker';
import {Moment} from 'moment';
import React from 'react';

import ButtonDefault from '../Button';
import Stack from '../Stack';
import TextFieldDefault from '../TextField';

type CustomTimePickerProps = {
  renderInput?: TimePickerProps<Moment, AdapterMoment>['renderInput'];
  cancelText?: string;
  okText?: string;
} & TimePickerProps<Moment, AdapterMoment> &
  Pick<TextFieldProps, 'variant' | 'helperText' | 'error'>;

export default function TimePicker(props: CustomTimePickerProps) {
  const {
    label,
    disableOpenPicker,
    views = ['hours', 'minutes'],
    openTo = 'hours',
    inputFormat = 'HH:mm',
    mask = '__:__',
    variant,
    helperText,
    error,
    cancelText = 'Отменить',
    okText = 'Выбрать',
    renderInput = (params) => {
      return (
        <TextFieldDefault
          {...params}
          error={error}
          helperText={helperText}
          inputProps={{...params.inputProps, placeholder: mask}}
          variant={variant}
        />
      );
    },
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePickerMui
        ampm={false}
        components={{
          ActionBar: (params) =>
            params?.actions?.length ? (
              <Stack direction="row" gap={2} justifyContent="space-around" p={2}>
                <ButtonDefault color="primary" onClick={params.onCancel}>
                  {cancelText}
                </ButtonDefault>
                <ButtonDefault color="primary" variant="contained" onClick={params.onAccept}>
                  {okText}
                </ButtonDefault>
              </Stack>
            ) : null,
        }}
        disableOpenPicker={disableOpenPicker}
        inputFormat={inputFormat}
        label={label}
        mask={mask}
        openTo={openTo}
        views={views}
        {...props}
        renderInput={renderInput}
      />
    </LocalizationProvider>
  );
}
