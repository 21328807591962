import {
  clearErrors,
  logIn,
  recoveryFailed,
  recoveryPassword,
  recoverySuccess,
  resetPassword,
  throwError,
  validateToken,
} from '@actions/UsersActions';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import useEvent from '../../../../hooks/useEvent';
import useQueryString from '../../../../hooks/useQueryString';
import {stages} from '../constants';

const useLoginStages = (isRecovery) => {
  const {loading, error, user} = useSelector((state) => {
    const {loading, error} = state.users;
    return {loading, error, user: state.users.currentUser};
  }, shallowEqual);

  const history = useHistory();

  const dispatch = useDispatch();

  const [stage, setStage] = useState(isRecovery ? 'reset' : 'login');
  const [isDisabled, setIsDisabled] = useState(false);

  const [{token}] = useQueryString();

  const updateDisable = useCallback(setIsDisabled);

  useEffect(() => {
    dispatch(validateToken(token, history.push));
  }, [token]);

  const email = useRef(null);

  const handleSubmit = useEvent(async (data) => {
    data.email = data?.email?.toLowerCase();
    if (stage === 'login') {
      dispatch(logIn(data));
    }

    if (stage === 'recovery') {
      email.current = data.email;
      try {
        await dispatch(recoveryPassword(data.email));
        dispatch(recoverySuccess());
        setStage('complete');
      } catch (error) {
        setIsDisabled(true);
        dispatch(recoveryFailed());
      }
    }

    if (stage === 'reset') {
      if (data.password !== data.repeat) {
        dispatch(throwError('Пароли не совпадают'));
        return;
      }

      dispatch(resetPassword(user.id, history.push, data.password));
    }
  });

  const currentStage = useMemo(() => {
    return stages[stage];
  }, [stage]);

  const updateStage = useCallback(
    (link) => {
      dispatch(clearErrors());
      setStage(link);

      if (isDisabled) {
        setIsDisabled(false);
      }

      if (email.current) {
        email.current = null;
      }
    },
    [isDisabled],
  );

  return {
    handleSubmit,
    currentStage,
    stage,
    updateStage,
    email: email.current,
    loading,
    error,
    isDisabled,
    updateDisable,
  };
};

export default useLoginStages;
