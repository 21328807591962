import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectAges} from '@selectors/groups';
import {clearAges, loadAges} from '@slices/groups';
import {useEffect} from 'react';

export function useGroupAges() {
  const dispatch = useStoreDispatch();
  const ages = useStore(selectAges);

  useEffect(() => {
    dispatch(loadAges());
    return () => {
      dispatch(clearAges());
    };
  }, []);

  return ages;
}
