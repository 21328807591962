import StorageApi from '@api/StorageApi';
import useToggle from '@hooks/useToggle';
import {Add} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React, {useState} from 'react';

import File from './components/File';

const UploadMaterialFile = ({value, onChange, label, accept, description, maxSize, disabled}) => {
  const toggle = useToggle();
  const [isToLarge, setIsToLarge] = useState(false);

  const handleChange = (e) => {
    toggle.on();
    const file = e.target.files[0];
    const maxSizeInBytyes = maxSize * (1024 * 1024);

    if (maxSize && maxSizeInBytyes < file.size) {
      setIsToLarge(true);
      return;
    }

    StorageApi.uploadFile(file)
      .then((res) => onChange(res.data))
      .finally(toggle.off);

    setIsToLarge(false);
  };

  return value ? (
    <File file={value} onDelete={onChange} />
  ) : (
    <Stack>
      <div>
        <LoadingButton
          color="primary"
          component="label"
          disabled={disabled}
          loading={toggle.isToggle}
          startIcon={<Add />}
          variant="outlined"
        >
          {label}
          <input hidden accept={accept} type="file" onChange={handleChange} />
        </LoadingButton>
      </div>
      {description && (
        <Typography color={grey[54]} fontWeight={400} mt={1} variant="caption2">
          {description}
        </Typography>
      )}
      {isToLarge && (
        <Typography color="error" fontWeight={400}>
          Файл слишком большой, выберите файл меньше {maxSize} мб
        </Typography>
      )}
    </Stack>
  );
};

export default UploadMaterialFile;
