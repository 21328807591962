import useStore from '@hooks/useStore';
import useToggle from '@hooks/useToggle';
import {LoadingButton} from '@mui/lab';
import {createMaterial, deleteMaterial, updateFormData, updateFormVisability, updateMaterial} from '@slices/materials';
import Button from '@ui/MUI/Button';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import React, {memo, useEffect} from 'react';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import {defaultValues} from '../../constants';
import {getIds, getSlugs} from '../../helpers';
import {useMaterialsForm, useMaterialsTypes} from '../../hooks';
import AdditionalContent from './components/AdditionalContent';
import CloseConfirm from './components/CloseConfirm';
import DeleteConfirm from './components/DeleteConfirm';
import Description from './components/Description';
import MainContent from './components/MainContent';
import UploadOrLink from './components/UploadOrLink';

const DrawerContent = () => {
  const {getValues} = useFormContext();
  const {data, refetch, isLoading} = useMaterialsTypes();
  const isEdit = Boolean(getValues('id'));

  useEffect(() => {
    if (!data.length && !isLoading) {
      refetch();
    }
  }, [refetch, data, isLoading]);

  return (
    <Stack spacing={4}>
      <Typography variant="h3">{isEdit ? 'Редактирование материала' : 'Создание материала'}</Typography>
      <MainContent />
      <AdditionalContent />
      <Description />
      <UploadOrLink />
    </Stack>
  );
};

const Form = ({refetcher, onClose, onDelete}) => {
  const {data, isOpen} = useMaterialsForm();
  const methods = useForm({defaultValues: defaultValues});
  const {
    reset,
    getValues,
    handleSubmit,
    formState: {isDirty, isValid},
  } = methods;

  const isEdit = Boolean(getValues('id'));
  const closeConfirm = useToggle();
  const deleteConfirm = useToggle();
  const isFormLoading = useStore((state) => state.materialsModule.form.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen && data) {
      reset(data);
    }
  }, [isOpen, data]);

  const close = () => {
    if (onClose) {
      onClose(reset);
    } else {
      dispatch(updateFormData({}));
      dispatch(updateFormVisability(false));
      reset(defaultValues);
    }
  };

  const handleCreateOrUpdate = (data) => {
    const payload = {
      ...data,
      category: data?.category?.slug,
      groups: getIds(data.groups),
      schools: getIds(data.schools),
      students: getIds(data.students),
      teachers: getIds(data.teachers),
      type: data?.type?.slug,
      tags: getSlugs(data.tags),
      memberRoles: data.memberRoles?.map((member) => member.userRole) || [],
      link: data.link || null,
    };

    isEdit
      ? dispatch(
          updateMaterial({
            id: data.id,
            payload,
            onSuccess: () => {
              close();
              refetcher();
            },
          }),
        )
      : dispatch(
          createMaterial({
            payload,
            onSuccess: () => {
              close();
              refetcher();
            },
          }),
        );
  };

  const handleDelete = () => {
    const id = getValues('id');
    dispatch(
      deleteMaterial({
        id,
        onSuccess: () => {
          if (onDelete) {
            onDelete();
          }
          refetcher();
          deleteConfirm.off();
          close();
        },
      }),
    );
  };

  return (
    <DrawerDefault
      buttons={
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-between"
          sx={{
            py: {
              xxs: '12px',
              xs: 0,
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
            sx={{
              pb: {
                xxs: isEdit ? '12px' : '0px',
                xs: '0px',
              },
              pr: {
                xxs: isEdit ? '0px' : '16px',
              },
              width: {
                xxs: isEdit ? '100%' : '50%',
                xs: isEdit ? '75%' : 'auto',
              },
            }}
          >
            <LoadingButton
              color="primary"
              disabled={!isDirty || !isValid}
              loading={isFormLoading}
              sx={{
                width: {
                  xxs: isEdit ? '50%' : '100%',
                },
              }}
              variant="contained"
              onClick={handleSubmit(handleCreateOrUpdate)}
            >
              {isEdit ? 'Сохранить' : 'добавить'}
            </LoadingButton>
            {isEdit && (
              <Button
                color="primary"
                sx={{
                  width: {
                    xxs: '50%',
                  },
                }}
                variant="outlined"
                onClick={deleteConfirm.on}
              >
                Удалить
              </Button>
            )}
          </Stack>
          <Button
            disableElevation
            color="secondary"
            sx={{
              width: {
                xxs: isEdit ? '100%' : '50%',
                xs: 'auto',
              },
            }}
            variant="contained"
            onClick={isDirty ? closeConfirm.on : close}
          >
            Отменить
          </Button>
        </Stack>
      }
      content={
        <FormProvider {...methods}>
          <DeleteConfirm close={deleteConfirm.off} open={deleteConfirm.isToggle} onConfirm={handleDelete} />
          <CloseConfirm
            close={closeConfirm.off}
            isEdit={isEdit}
            open={isDirty && closeConfirm.isToggle}
            onConfirm={() => {
              closeConfirm.off();
              close();
            }}
          />
          <DrawerContent />
        </FormProvider>
      }
      isOpen={isOpen}
      setDrawerState={isDirty ? closeConfirm.on : close}
    />
  );
};

export default memo(Form);
