export const mock = {
  morning: {
    notCompleted: [...Array(10)].fill({
      id: 'QwQPPT-S6NtU',
      fullName: 'Белодедов Александр',
    }),
    dayOffPlayers: [],
    sleep: [],
    mood: [],
    willToTraining: [],
  },
  game: {
    notCompleted: [...Array(10)].fill({
      id: 'QwQPPT-S6NtU',
      fullName: 'Белодедов Александр',
    }),
    dayOffPlayers: [],
    activity: [],
    stress: [],
    health: [],
    attention: [],
    fellingIceAndGoal: [],
    comunicationWithTrainer: [],
    selfScore: [],
  },
  trainingGroup: {
    notCompleted: [...Array(10)].fill({
      id: 'QwQPPT-S6NtU',
      fullName: 'Белодедов Александр',
    }),
    dayOffPlayers: [],
    iceMood: [],
    iceHealth: [],
    gymMood: [],
    gymHealth: [],
  },
  trainingIndividual: {
    notCompleted: [...Array(10)].fill({
      id: 'QwQPPT-S6NtU',
      fullName: 'Белодедов Александр',
    }),
    dayOffPlayers: [],
    iceMood: [],
    iceHealth: [],
    gymMood: [],
    gymHealth: [],
  },
};

export const overallMock = [
  {
    date: '2023-12-01',
    completed: 0,
    notCompleted: 24,
    percentCompleted: 0,
  },
  {
    date: '2023-12-02',
    completed: 0,
    notCompleted: 24,
    percentCompleted: 0,
  },
  {
    date: '2023-12-03',
    completed: 0,
    notCompleted: 24,
    percentCompleted: 0,
  },
];

export const dataForTableMock = [
  {
    student: {
      id: '0Cj_ahcsYb6G',
      firstName: 'Родион ',
      lastName: 'Сафин',
      middleName: 'Маликович',
      avatar: 'https://stars-ural.hawk.ru:8081/sport/images/players/3c058b25c2e73db71255a786d18f6735.jpg',
      phone: '7 (950) 745-01-86',
      email: 'rodionesafin@yandex.ru',
      birthdayTimestamp: 1150528242000,
      userRole: 'student',
      active: true,
      hockeyRole: 'winger',
      playerNumber: '58',
      hand: 'left',
      group: {
        id: 'ylMJ1l7b63',
        name: 'Авангард-ЮХЛ',
        description: 'Регулярный чемпионат Урала - 36 матчей в группе \nПлей-офф - Финал России 7-8 матчей ',
        studentsCount: 20,
        birthYear: '2005',
        ageGroup: 'U19',
        avangardId: 43787337,
      },
      school: {
        id: 'ITfD4PL0oy',
        name: '"Авангард" Академия ',
      },
      citizenshipId: 'w5kSOc2tTG',
      cityOfBirth: 'Челябинская обл.',
      countryId: 'd9HbKDSwWo',
      vk: '',
      participationInTheNationalTeam: null,
      status: 'illness',
      subStatus: 'not_allowed',
      typeOfBody: null,
      parentName: 'Сафина Нэлли Сергеевна',
      parentPhone: '8 (951) 261-18-78',
      parentEmail: '',
      parentSkype: '',
      parentsAdress: 'Челябинская обл.',
      secondRepresentativeName: 'Сафин Малик Фангатович',
      secondRepresentativePhone: '8 (902) 611-02-17',
      secondRepresentativeEmail: '',
      secondRepresentativeSkype: '',
      parentsWork: 'both',
      agent: '',
      agentPhone: '',
      agentEmail: '',
      instatId: '',
      avangardId: 32476213,
      nerosoftId: '62e4f77d-d025-43a2-a439-8b70b1672aae',
    },
  },
  {
    student: {
      id: '0Cj_ahcsYb6G',
      firstName: 'Родион ',
      lastName: 'Сафин',
      middleName: 'Маликович',
      avatar: 'https://stars-ural.hawk.ru:8081/sport/images/players/3c058b25c2e73db71255a786d18f6735.jpg',
      phone: '7 (950) 745-01-86',
      email: 'rodionesafin@yandex.ru',
      birthdayTimestamp: 1150528242000,
      userRole: 'student',
      active: true,
      hockeyRole: 'winger',
      playerNumber: '58',
      hand: 'left',
      group: {
        id: 'ylMJ1l7b63',
        name: 'Авангард-ЮХЛ',
        description: 'Регулярный чемпионат Урала - 36 матчей в группе \nПлей-офф - Финал России 7-8 матчей ',
        studentsCount: 20,
        birthYear: '2005',
        ageGroup: 'U19',
        avangardId: 43787337,
      },
      school: {
        id: 'ITfD4PL0oy',
        name: '"Авангард" Академия ',
      },
      citizenshipId: 'w5kSOc2tTG',
      cityOfBirth: 'Челябинская обл.',
      countryId: 'd9HbKDSwWo',
      vk: '',
      participationInTheNationalTeam: null,
      status: 'illness',
      subStatus: 'not_allowed',
      typeOfBody: null,
      parentName: 'Сафина Нэлли Сергеевна',
      parentPhone: '8 (951) 261-18-78',
      parentEmail: '',
      parentSkype: '',
      parentsAdress: 'Челябинская обл.',
      secondRepresentativeName: 'Сафин Малик Фангатович',
      secondRepresentativePhone: '8 (902) 611-02-17',
      secondRepresentativeEmail: '',
      secondRepresentativeSkype: '',
      parentsWork: 'both',
      agent: '',
      agentPhone: '',
      agentEmail: '',
      instatId: '',
      avangardId: 32476213,
      nerosoftId: '62e4f77d-d025-43a2-a439-8b70b1672aae',
    },
  },
  {
    student: {
      id: '0Cj_ahcsYb6G',
      firstName: 'Родион ',
      lastName: 'Сафин',
      middleName: 'Маликович',
      avatar: 'https://stars-ural.hawk.ru:8081/sport/images/players/3c058b25c2e73db71255a786d18f6735.jpg',
      phone: '7 (950) 745-01-86',
      email: 'rodionesafin@yandex.ru',
      birthdayTimestamp: 1150528242000,
      userRole: 'student',
      active: true,
      hockeyRole: 'winger',
      playerNumber: '58',
      hand: 'left',
      group: {
        id: 'ylMJ1l7b63',
        name: 'Авангард-ЮХЛ',
        description: 'Регулярный чемпионат Урала - 36 матчей в группе \nПлей-офф - Финал России 7-8 матчей ',
        studentsCount: 20,
        birthYear: '2005',
        ageGroup: 'U19',
        avangardId: 43787337,
      },
      school: {
        id: 'ITfD4PL0oy',
        name: '"Авангард" Академия ',
      },
      citizenshipId: 'w5kSOc2tTG',
      cityOfBirth: 'Челябинская обл.',
      countryId: 'd9HbKDSwWo',
      vk: '',
      participationInTheNationalTeam: null,
      status: 'illness',
      subStatus: 'not_allowed',
      typeOfBody: null,
      parentName: 'Сафина Нэлли Сергеевна',
      parentPhone: '8 (951) 261-18-78',
      parentEmail: '',
      parentSkype: '',
      parentsAdress: 'Челябинская обл.',
      secondRepresentativeName: 'Сафин Малик Фангатович',
      secondRepresentativePhone: '8 (902) 611-02-17',
      secondRepresentativeEmail: '',
      secondRepresentativeSkype: '',
      parentsWork: 'both',
      agent: '',
      agentPhone: '',
      agentEmail: '',
      instatId: '',
      avangardId: 32476213,
      nerosoftId: '62e4f77d-d025-43a2-a439-8b70b1672aae',
    },
  },
  {
    student: {
      id: '0Cj_ahcsYb6G',
      firstName: 'Родион ',
      lastName: 'Сафин',
      middleName: 'Маликович',
      avatar: 'https://stars-ural.hawk.ru:8081/sport/images/players/3c058b25c2e73db71255a786d18f6735.jpg',
      phone: '7 (950) 745-01-86',
      email: 'rodionesafin@yandex.ru',
      birthdayTimestamp: 1150528242000,
      userRole: 'student',
      active: true,
      hockeyRole: 'winger',
      playerNumber: '58',
      hand: 'left',
      group: {
        id: 'ylMJ1l7b63',
        name: 'Авангард-ЮХЛ',
        description: 'Регулярный чемпионат Урала - 36 матчей в группе \nПлей-офф - Финал России 7-8 матчей ',
        studentsCount: 20,
        birthYear: '2005',
        ageGroup: 'U19',
        avangardId: 43787337,
      },
      school: {
        id: 'ITfD4PL0oy',
        name: '"Авангард" Академия ',
      },
      citizenshipId: 'w5kSOc2tTG',
      cityOfBirth: 'Челябинская обл.',
      countryId: 'd9HbKDSwWo',
      vk: '',
      participationInTheNationalTeam: null,
      status: 'illness',
      subStatus: 'not_allowed',
      typeOfBody: null,
      parentName: 'Сафина Нэлли Сергеевна',
      parentPhone: '8 (951) 261-18-78',
      parentEmail: '',
      parentSkype: '',
      parentsAdress: 'Челябинская обл.',
      secondRepresentativeName: 'Сафин Малик Фангатович',
      secondRepresentativePhone: '8 (902) 611-02-17',
      secondRepresentativeEmail: '',
      secondRepresentativeSkype: '',
      parentsWork: 'both',
      agent: '',
      agentPhone: '',
      agentEmail: '',
      instatId: '',
      avangardId: 32476213,
      nerosoftId: '62e4f77d-d025-43a2-a439-8b70b1672aae',
    },
  },
  {
    student: {
      id: '0Cj_ahcsYb6G',
      firstName: 'Родион ',
      lastName: 'Сафин',
      middleName: 'Маликович',
      avatar: 'https://stars-ural.hawk.ru:8081/sport/images/players/3c058b25c2e73db71255a786d18f6735.jpg',
      phone: '7 (950) 745-01-86',
      email: 'rodionesafin@yandex.ru',
      birthdayTimestamp: 1150528242000,
      userRole: 'student',
      active: true,
      hockeyRole: 'winger',
      playerNumber: '58',
      hand: 'left',
      group: {
        id: 'ylMJ1l7b63',
        name: 'Авангард-ЮХЛ',
        description: 'Регулярный чемпионат Урала - 36 матчей в группе \nПлей-офф - Финал России 7-8 матчей ',
        studentsCount: 20,
        birthYear: '2005',
        ageGroup: 'U19',
        avangardId: 43787337,
      },
      school: {
        id: 'ITfD4PL0oy',
        name: '"Авангард" Академия ',
      },
      citizenshipId: 'w5kSOc2tTG',
      cityOfBirth: 'Челябинская обл.',
      countryId: 'd9HbKDSwWo',
      vk: '',
      participationInTheNationalTeam: null,
      status: 'illness',
      subStatus: 'not_allowed',
      typeOfBody: null,
      parentName: 'Сафина Нэлли Сергеевна',
      parentPhone: '8 (951) 261-18-78',
      parentEmail: '',
      parentSkype: '',
      parentsAdress: 'Челябинская обл.',
      secondRepresentativeName: 'Сафин Малик Фангатович',
      secondRepresentativePhone: '8 (902) 611-02-17',
      secondRepresentativeEmail: '',
      secondRepresentativeSkype: '',
      parentsWork: 'both',
      agent: '',
      agentPhone: '',
      agentEmail: '',
      instatId: '',
      avangardId: 32476213,
      nerosoftId: '62e4f77d-d025-43a2-a439-8b70b1672aae',
    },
  },
];
