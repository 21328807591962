import React, {useState} from 'react';

import DashboardsBlock from '../dashboards/trainers-dashboard/components/DashboardsBlock';

const MissingPlayers = (props) => {
  const {contentBlock = () => {}, missingPlayers = []} = props;
  const [isMissingPlayersShowed, setIsMissingPlayersShowed] = useState(false);
  return (
    <DashboardsBlock
      buttonText={!isMissingPlayersShowed ? 'Показать всех игроков' : 'Скрыть'}
      buttonsBlockType="button"
      content={contentBlock(
        missingPlayers,
        isMissingPlayersShowed,
        'В данный момент в команде нет отсутствующих игроков',
        'Причина',
      )}
      isButtonsShowed={missingPlayers.length > 5}
      title="Отсутствующие игроки"
      onButtonClick={() => {
        setIsMissingPlayersShowed((prevState) => !prevState);
      }}
    />
  );
};

export default MissingPlayers;
