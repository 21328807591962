// список не асинхронных дефолтных значений для фильтров;

import {AMPLUA_OPTIONS, roleOptions} from '../../../../constants';
import {ESTIMATION_STAGES_REPORT} from '../../../../constants/config';

const season = {
  label: 'Сезон',
};

const stage = {
  label: 'Этап',
  options: ESTIMATION_STAGES_REPORT,
  value: ESTIMATION_STAGES_REPORT[0],
};

const role = {
  label: 'Роль',
  options: roleOptions,
};

const school = {
  label: 'Школа',
};

const group = {
  label: 'Команда',
};

const student = {
  label: 'Игрок',
};

const amplua = {
  label: 'Амплуа',
  options: AMPLUA_OPTIONS,
};

export default {season, stage, role, school, group, student, amplua};
