import {getCurrentFuncTesting} from '@api/FunctionalTestingAPI';
import {getUserMedicalData} from '@api/MedicalProfileAPI';
import {getPlayerGateZones, getPlayerShotsZones} from '@api/mkcStatisticsAPI';
import {getPastGames} from '@api/PersonalFileAPI';
import {InjuryHistoryMock} from '@components/apps/PlayerPersonalFile/components/FunctionalTesting/components/InjuryHistory/mock';
import {mock} from '@components/apps/PlayerPersonalFile/components/MainInfo/components/PastGamesTable/mock';
import date from '@helpers/date';
import {defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {createSlice} from '@reduxjs/toolkit';
import {initialStateIndicators} from '@src/constants';
import {groupBy, isObject} from 'lodash';

import moduleNames from '../moduleNames';

const statePaths = {
  mainTabPastGames: {
    name: 'mainTabPastGames',
    path: 'mainTab.pastGames',
  },
  funcTestingStatuses: {
    name: 'dynamicsStatuses',
    path: 'funcTesting.dynamicsStatuses',
  },
  funcTestingInjuryHistory: {
    name: 'injuryHistory',
    path: 'funcTesting.injuryHistory',
  },
  statisticsGateZones: {
    name: 'statisticsGateZones',
    path: 'statistics.gateZones',
  },
  statisticsShotsZones: {
    name: 'statisticsShotsZones',
    path: 'statistics.shotsZones',
  },
};

export const initialState = {
  mainTab: {
    pastGames: {...initialStateIndicators},
  },
  funcTesting: {
    dynamicsStatuses: {...initialStateIndicators, data: {dates: [], valued: []}},
    injuryHistory: {...initialStateIndicators, data: {tableData: [], expandedRows: {}, responseData: []}},
  },
  statistics: {
    filters: {
      season: '',
      tournamentId: '',
      teamMkcId: '',
      onlyAgainstTopThree: false,
      isFilled: false,
    },
    gateZones: {...initialStateIndicators, data: {}},
    shotsZones: {...initialStateIndicators, data: {}},
  },
};

//redusers
const personalFile = createSlice({
  name: moduleNames.playerPersonalFile,
  initialState,
  reducers: {
    ...defaultSliceReducersConstructor(
      statePaths.mainTabPastGames.name,
      statePaths.mainTabPastGames.path,
      initialState,
      {},
      mock,
    ).functions,
    ...defaultSliceReducersConstructor(
      statePaths.funcTestingStatuses.name,
      statePaths.funcTestingStatuses.path,
      initialState,
    ).functions,
    ...defaultSliceReducersConstructor(
      statePaths.funcTestingInjuryHistory.name,
      statePaths.funcTestingInjuryHistory.path,
      initialState,
      {},
      InjuryHistoryMock,
    ).functions,
    setStatisticsFilter: ({statistics}, {payload}) => {
      statistics.filters = {...statistics.filters, ...(isObject(payload) ? payload : {})};
    },
    clearStatisticsFilter: ({statistics}) => {
      statistics.filters = initialState.statistics.filters;
    },
    ...defaultSliceReducersConstructor(
      statePaths.statisticsGateZones.name,
      statePaths.statisticsGateZones.path,
      initialState,
    ).functions,
    ...defaultSliceReducersConstructor(
      statePaths.statisticsShotsZones.name,
      statePaths.statisticsShotsZones.path,
      initialState,
    ).functions,
  },
});

//exports

const {actions, reducer} = personalFile;
export {reducer as playerPersonalFileReducer};

//fetch fns
const mainTabPastGamesActions = defaultSliceReducersConstructor(
  statePaths.mainTabPastGames.name,
  statePaths.mainTabPastGames.path,
  initialState,
  actions,
).actionsValues;

const funcTestingStatusesActions = defaultSliceReducersConstructor(
  statePaths.funcTestingStatuses.name,
  statePaths.funcTestingStatuses.path,
  initialState,
  actions,
).actionsValues;

const funcTestingInjuryHistoryActions = defaultSliceReducersConstructor(
  statePaths.funcTestingInjuryHistory.name,
  statePaths.funcTestingInjuryHistory.path,
  initialState,
  actions,
).actionsValues;

const gateZonesActions = defaultSliceReducersConstructor(
  statePaths.statisticsGateZones.name,
  statePaths.statisticsGateZones.path,
  initialState,
  actions,
).actionsValues;

const shotsZonesActions = defaultSliceReducersConstructor(
  statePaths.statisticsShotsZones.name,
  statePaths.statisticsShotsZones.path,
  initialState,
  actions,
).actionsValues;

export const clearMainTabPastGames = () => (dispatch) => dispatch(mainTabPastGamesActions.clear());

export const loadMainTabPastGames = (params) =>
  defaultLoader(
    () => getPastGames(params),
    mainTabPastGamesActions.loading,
    ({data}) => mainTabPastGamesActions.load({data: data?.games ?? []}),
    mainTabPastGamesActions.error,
  );
export const clearFuncTestingStatuses = () => (dispatch) => dispatch(funcTestingStatusesActions.clear());
export const loadFuncTestingStatuses = (params) =>
  defaultLoader(
    () => getCurrentFuncTesting(params),
    funcTestingStatusesActions.loading,
    ({data}) => {
      const dates = [];
      const values = [];
      data
        ?.filter((item) => item?.functional > 0)
        ?.forEach((item) => {
          dates.push(date(item?.date).format('DD.MM'));
          values.push({
            value: item?.functional,
            id: item?.date,
            date: item?.date,
            season: item?.season,
            tooltip: {
              show: true,
              formatter: () => `Дата измерения: ${date(item?.date).format('DD.MM.YYYY')}</br> Сезон: ${item?.season}`,
            },
          });
        });
      return funcTestingStatusesActions.load({data: {dates, values}});
    },
    funcTestingStatusesActions.error,
  );

export const loadInjuryHistory = (params) =>
  defaultLoader(
    () => getUserMedicalData(params),
    funcTestingInjuryHistoryActions.loading,
    ({data}) => {
      const expandedRows = {};
      const tableData = Object.entries(groupBy(data, 'season'))
        ?.map(([key, value], index) => {
          expandedRows[index] = true;
          return {season: key, subRows: value};
        })
        ?.sort((a, b) => b?.season?.replace(/[^0-9]/g, '') - a?.season?.replace(/[^0-9]/g, ''));
      return funcTestingInjuryHistoryActions.load({data: {tableData, expandedRows, responseData: data}});
    },
    funcTestingInjuryHistoryActions.error,
  );

export const setStatisticsFilter = (data) => (dispatch) => dispatch(actions.setStatisticsFilter(data));
export const clearStatisticsFilter = () => (dispatch) => dispatch(actions.clearStatisticsFilter());

export const clearGateZones = () => (dispatch) => dispatch(gateZonesActions.clear());

export const loadGateZones = (params) =>
  defaultLoader(
    () => getPlayerGateZones(params),
    gateZonesActions.loading,
    gateZonesActions.load,
    gateZonesActions.error,
  );

export const clearShotsZones = () => (dispatch) => dispatch(shotsZonesActions.clear());

export const loadShotsZones = (params) =>
  defaultLoader(
    () => getPlayerShotsZones(params),
    shotsZonesActions.loading,
    shotsZonesActions.load,
    shotsZonesActions.error,
  );
