import {useCategoriesEstimates} from '@common/DevelopmentJournal/hooks';
import FullHeightWrapper from '@common/FullHeightWrapper';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {Stack, Typography} from '@ui/MUI';
import React from 'react';

import Category from '../Category';

const Categories = ({groupId}) => {
  const groupsIds = groupId && [groupId];
  const {data: categories, isLoading, isNoData} = useCategoriesEstimates({groupsIds});
  if (isLoading) {
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center" position="relative">
        <LocalPreloader visible />
      </FullHeightWrapper>
    );
  }

  if (isNoData) {
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
        <Typography variant="subtitle1">Нет данных</Typography>
      </FullHeightWrapper>
    );
  }

  return (
    <Stack mt={5} spacing={5}>
      {categories.map((category) => (
        <Category estimates={category.estimates} key={category.id} name={category.name} />
      ))}
    </Stack>
  );
};

export default Categories;
