import ActionText from '@common/ActionText';
import {useRoleAccess} from '@hooks/useRoleAccess';
import Add from '@mui/icons-material/Add';
import {userRoles} from '@src/constants';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';
import styled from 'styled-components';

const TopContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  padding-bottom: 12px;
  padding-left: ${(props) => props.side === 'right' && '64px'};
  padding-right: ${(props) => props.side === 'left' && '64px'};
  display: flex;
  justify-content: space-between;
`;

const SectionTop = ({side, title, onAdd}) => {
  const canEdit = useRoleAccess([userRoles?.teacher, userRoles?.methodist]);
  return (
    <TopContainer side={side}>
      <TypographyDefault variant="h4">{title}</TypographyDefault>
      {canEdit && (
        <ActionText startIcon={<Add />} onClick={onAdd}>
          Добавить
        </ActionText>
      )}
    </TopContainer>
  );
};

export default SectionTop;
