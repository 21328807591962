import {Stack} from '@ui/MUI';
import React from 'react';

export const PageWrapper = ({children, sx, py, px, gap, direction, alignItems, ...props}) => {
  const styles = {
    p: 4,
    background: 'white',
    borderRadius: '6px',
    py,
    px,
    ...sx,
  };

  return (
    <Stack alignItems={alignItems} direction={direction} gap={gap} sx={styles} {...props}>
      {children}
    </Stack>
  );
};
