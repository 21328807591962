import {updateUsersInfo} from '@api/UsersAPI';
import PlaceholderWrapper from '@common/FullHeightWrapper';
import {TYPES_OF_BODY_ENUM} from '@components/apps/NewBodyMassIndex/constants';
import date from '@helpers/date';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectCommonReport, selectCommonReportFilters} from '@selectors/bodyMassIndex';
import {clearCommonReport, editCommonReportTypeOfBody, loadCommonReport} from '@slices/bodyMassIndex';
import {userRoles} from '@src/constants';
import {Stack, Typography} from '@ui/MUI';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import Filters from './components/Filters';
import Glossary from './components/Glossary';
import Table from './components/Table';

CommonReport.propTypes = {};

function CommonReport() {
  const dispatch = useStoreDispatch();
  const filters = useStore(selectCommonReportFilters);
  const canEdit = useStore((state) => state.users?.currentUser?.userRole === userRoles.doctor);
  const {data, isLoad, isLoading, error} = useStore(selectCommonReport);
  const methods = useForm();
  const {reset, handleSubmit} = methods;
  useDeepEffect(() => {
    if (filters.groups?.value && filters.period?.length) {
      dispatch(
        loadCommonReport(
          {
            groupId: filters.groups?.value,
            startDate: date(filters.period[0]).valueOf(),
            endDate: date(filters.period[1]).valueOf(),
          },
          reset,
        ),
      );
    }

    return () => {
      dispatch(clearCommonReport());
    };
  }, [filters]);

  const onSave = (onLoad) => {
    handleSubmit(({form}) => {
      const result = form.map(({id, typeOfBody}) => ({id, typeOfBody: typeOfBody?.value || null}));
      updateUsersInfo(result)
        .then(({data}) => {
          const editedData = form.map((item) => {
            const fetchedUser = data?.find((user) => user?.id === item?.id);
            if (fetchedUser?.typeOfBody) {
              return {
                ...item,
                typeOfBody: TYPES_OF_BODY_ENUM?.[fetchedUser?.typeOfBody]
                  ? TYPES_OF_BODY_ENUM[fetchedUser.typeOfBody]
                  : null,
              };
            }
            return item;
          });
          reset({form: editedData});
          dispatch(editCommonReportTypeOfBody(editedData));
        })
        .catch(() => reset(data))
        .finally(() => onLoad());
    })();
  };

  return (
    <Stack data-testid="common-report" spacing={1}>
      <Filters canEdit={canEdit} />
      <Glossary groupId={filters.groups?.value} />
      {!filters.groups?.value || !filters.period?.length ? (
        <PlaceholderWrapper
          alignItems="center"
          data-testid="no-filters"
          display="flex"
          justifyContent="center"
          px={{xxs: 2, sm: 4}}
          py={{xxs: 1, sm: 3}}
        >
          <Typography variant="subtitle1">Для отображения данных заполните фильтры</Typography>
        </PlaceholderWrapper>
      ) : (
        <FormProvider {...methods}>
          <Table
            canEdit={canEdit}
            data={data}
            group={filters.groups}
            hasError={error}
            hasNoData={isLoad && (!data.students?.length || !data.dates?.length)}
            isLoading={isLoading}
            onSave={onSave}
          />
        </FormProvider>
      )}
    </Stack>
  );
}

export default CommonReport;
