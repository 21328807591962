import {getLastTestReport} from '@api/FunctionalTestingAPI';
import {initialStateIndicators} from '@src/constants';
import {useEffect, useState} from 'react';

const initialState = {...initialStateIndicators, data: {}};

export function useFuncTestingLastTest(playerId) {
  const [data, setData] = useState({...initialState});
  useEffect(() => {
    setData((prevState) => ({...prevState, isLoading: true}));
    playerId &&
      getLastTestReport(playerId)
        .then(({data}) => setData((prevState) => ({...prevState, isLoad: true, isLoading: false, data})))
        .catch(() => setData({...initialState, isLoad: true}));
  }, [playerId]);
  return {...data};
}
