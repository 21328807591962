import {getStatisticsFromTab} from '@api/gameStatisticsParsingCheckAPI';
import {defaultLoader} from '@helpers/slices';
import {createSlice} from '@reduxjs/toolkit';
import {STATISTICS_PARSING_CHECK as TABS} from '@src/constants';

import moduleNames from '../moduleNames';

const initialState = {
  tabs: {
    [TABS.lineup.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.goals.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.fouls.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.faceoffs.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      selected: {},
      hasError: false,
    },
    [TABS.toi.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
      hasOT: false,
    },
    [TABS.shortShifts.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.longShifts.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.goaltenders.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
      hasOT: false,
    },
  },
};

//redusers
const gameStatisticsParsingCheck = createSlice({
  name: moduleNames.gameStatisticsParsingCheck,
  initialState,
  reducers: {
    clearTab: (state, {payload}) => {
      if (state.tabs?.[payload] && initialState.tabs?.[payload]) {
        state.tabs[payload] = initialState.tabs[payload];
      } else {
        state.tabs = initialState.tabs;
      }
    },
    tabsLoading: ({tabs}, {payload}) => {
      if (!tabs?.[payload]) return;
      tabs[payload].isLoading = true;
    },
    tabsLoad: ({tabs}, {payload: {data, tab}}) => {
      if (!tabs?.[tab]) return;
      tabs[tab].isLoading = false;
      tabs[tab].isLoad = true;
      tabs[tab].data = data?.data?.items || initialState.tabs[tab].data;
      tabs[tab].hasOT = Boolean(data?.data?.hasOT);
    },
    tabsError: ({tabs}, {payload}) => {
      if (!tabs?.[payload]) return;
      tabs[payload].isLoading = false;
      tabs[payload].isLoad = true;
      tabs[payload].hasError = true;
    },
    setCurrentThrowinData: ({tabs}, {payload}) => {
      tabs[TABS.faceoffs.value].selected = payload;
    },
    clearCurrentThrowinData: ({tabs}) => {
      tabs[TABS.faceoffs.value].selected = initialState.tabs[TABS.faceoffs.value].selected;
    },
  },
});

//exports

const {actions, reducer} = gameStatisticsParsingCheck;

const {clearTab, tabsLoading, tabsLoad, tabsError} = actions;

export {reducer as gameStatisticsParsingCheckReducer};

//fetch fns

export const clearTabsData = (tab) => (dispatch) => dispatch(clearTab(tab));

export const loadGameStatisticsData = (params) =>
  defaultLoader(
    () => getStatisticsFromTab(params),
    () => tabsLoading(params?.tab),
    (data) => tabsLoad({data, tab: params?.tab}),
    () => tabsError(params?.tab),
  );

export const setCurrentThrowinData = (payload) => (dispatch) => dispatch(actions.setCurrentThrowinData(payload));
export const clearCurrentThrowinData = () => (dispatch) => dispatch(actions.clearCurrentThrowinData);
