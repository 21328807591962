import ReactPlayer from 'react-player';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Image = styled.img`
  width: 100%;
  object-fit: contain;
  max-height: 360px;
`;

export const Video = styled(ReactPlayer)`
  max-height: 620px;
`;

export const ContentWrapper = styled.div`
  padding: ${(props) => (props.breakpoint === 'xxs' ? '32px 12px' : '32px 0px')};
  width: 100%;
  max-width: ${(props) => (props.breakpoint === 'xxs' ? 'none' : '540px')};
  max-height: 484px;
`;

export const ContentContainer = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  max-height: 300px;
`;

export const ButtonFixedWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 16px 12px;
  width: 100%;
  background-color: white;
  box-shadow: 4px 0 12px rgb(0 0 0 / 10%);
  @media screen and (max-width: 480px) {
    bottom: 18px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 540px;
  @media screen and (max-width: 575px) {
    max-width: none;
  }
`;

export const Circle = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;
  border-radius: 50%;
  cursor: pointer;
`;
