import Box from '@ui/MUI/Box';
import React from 'react';

export default function Wrapper({children, ...props}) {
  return (
    <Box bgcolor="#fff" borderRadius="6px" overflow="hidden" p={{xxs: 2, sm: 3}} {...props}>
      {children}
    </Box>
  );
}
