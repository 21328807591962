import useStore from './useStore';

const useUsers = () => {
  const users = useStore((state) => ({
    data: state.users.usersMap.toArray(),
    isLoading: state.users.isLoading,
  }));
  return users;
};

export default useUsers;
