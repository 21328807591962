import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const BodyMassIndexAPI = {
  getBodyMassIndexReportForPlayer(params) {
    return axios.get(`${API_ENDPOINT}/v1/nutrition/player-report`, {params});
  },
  getBodyMassIndexParamsForPlayerDashboard(playerId) {
    return axios.get(`${API_ENDPOINT}/v1/nutrition/actual-player-report/${playerId}`);
  },
};

export default BodyMassIndexAPI;
