import gate from '@assets/images/goaltender_gate.png';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import {Stack} from '@ui/MUI';
import Box from '@ui/MUI/Box';
import {grey} from '@ui/MUI/colors';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

import {Cell, Grid, ImageWrapper, Map, Percent, Score} from './Gate';

const gateZones = ['topLeft', 'topMid', 'topRight', 'midLeft', 'center', 'midRight', 'botLeft', 'botMid', 'botRight'];

function Gate({gatesMap, isLoading}) {
  return (
    <Box m="0 auto" maxWidth="500px">
      <Map>
        <ImageWrapper>
          <img alt="Ворота" src={gate} />
        </ImageWrapper>
        <Grid>
          {gateZones.map((item, index) => {
            const data = gatesMap?.[item];
            return (
              <Cell className={item === 'center' && 'center-cell'} key={index}>
                <Box
                  alignItems="center"
                  display="flex"
                  height="100%"
                  justifyContent="center"
                  overflow="hidden"
                  width="100%"
                >
                  <Box textAlign="center">
                    {(data?.percentValue || isLoading) && (
                      <Stack alignItems="center" direction="row" spacing={0}>
                        {data?.isLowestPercent && <LocalFireDepartmentIcon color="primary" fontSize="13px" />}
                        <Percent
                          color={data?.isLowestPercent && 'primary'}
                          isLoading={isLoading}
                          preloaderWidthBetween={[50, 70]}
                        >
                          {data?.percentValue + '%'}
                        </Percent>
                      </Stack>
                    )}
                    <Score color={grey['68']} isLoading={isLoading} preloaderWidthBetween={[30, 40]} variant="h4">
                      {data?.value}
                    </Score>
                  </Box>
                </Box>
              </Cell>
            );
          })}
        </Grid>
      </Map>
      <Stack alignItems="center" direction="row" gap={0} justifyContent="center">
        <LocalFireDepartmentIcon color="primary" fontSize="13px" />
        <TypographyDefault color={grey['68']} variant="body2">
          зоны с худшим % сейвов
        </TypographyDefault>
      </Stack>
    </Box>
  );
}

export default Gate;
