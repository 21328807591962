import {longDash} from '@src/constants';
import {grey, yellow} from '@ui/MUI/colors';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import styled from 'styled-components';

function ProgressCell({percent, value, allowPositiveSign = false}) {
  const formatedValue = useMemo(() => {
    if (!value && value !== 0) return longDash;
    if (value > 0) return allowPositiveSign ? '+' + value : value;
    if (value < 0) return value;
    return 0;
  }, [value]);

  return (
    <>
      <Wrapper>
        <TypographyDefault variant="subtitle2">{formatedValue}</TypographyDefault>
      </Wrapper>
      <NegativeMarker width={value < 0 ? Math.abs(percent) : 0} />
      <PositiveMarker width={value > 0 ? Math.abs(percent) : 0} />
    </>
  );
}

ProgressCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  allowPositiveSign: PropTypes.bool,
};

export default ProgressCell;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PositiveMarker = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% - 1px);
  min-width: 2px;
  max-width: calc(100% / 2 - 6px);
  width: ${({width}) => `calc(${width}% / 2 - 6px)`};
  height: 8px;
  background-color: ${grey[68]};
`;
const NegativeMarker = styled(PositiveMarker)`
  width: ${({width}) => `calc(${width}% / 2 - 6px)`};
  right: calc(50% + 1px);
  left: initial;
  background-color: ${yellow[100]};
`;
