import {grey} from '@ui/MUI/colors';
import styled from 'styled-components';

export const FileContainer = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${grey[12]};
`;

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
`;
