export const mock = {
  data: [...Array(20)].fill({
    playerId: '0Cj_ahcsYb6G',
    params: [
      {
        id: '7Gm7MTdkfsYa',
        name: '30 метров спринт без шайбы (сек)',
        value: 4.049,
        playerValue: 4.21,
        diff: 0.1609999999999996,
      },
      {
        id: 'AFcqyTRvguE-',
        name: '30 метров спринт с шайбой (сек)',
        value: 4.261,
        diff: null,
      },
      {
        id: '_MDdw9tjWKh1',
        name: '30 метров спиной вперед без шайбы (сек)',
        value: 4.792,
        diff: null,
      },
      {
        id: '54tGNLW9J7jV',
        name: '30 метров спиной вперед с шайбой (сек)',
        value: 4.894,
        diff: null,
      },
      {
        id: 'eDjMWZEDA0Sb',
        name: 'Комплексный тест на реакцию без шайбы (сек)',
        value: 8.456,
        diff: null,
      },
      {
        id: '4BZYbd168KaK',
        name: 'Комплексный тест на реакцию с шайбой (сек)',
        value: 8.426,
        diff: null,
      },
      {
        id: 'mI1idaZOiABd',
        name: 'Слаломный бег на коньках без шайбы (сек)',
        value: 10.368,
        diff: null,
      },
      {
        id: 'afPSPXMFcpH1',
        name: 'Слаломный бег на коньках с шайбой (сек)',
        value: 10.598,
        diff: null,
      },
      {
        id: 'LGMFVmqjjd_l',
        name: 'Переходы по диагонали без шайбы (сек)',
        value: 13.485,
        diff: null,
      },
      {
        id: 'AYq86kLnrMDh',
        name: 'Переходы по диагонали с шайбой (сек)',
        value: 13.556,
        diff: null,
      },
    ],
    avgRang: 2,
    place: 1,
    firstName: 'Родион ',
    lastName: 'Сафин',
    playerNumber: '58',
  }),
  exercises: [
    {
      id: '7Gm7MTdkfsYa',
      name: '30 метров спринт без шайбы (сек)',
      value: 4.049,
      playerValue: 4.17,
      order: 1,
    },
    {
      id: 'AFcqyTRvguE-',
      name: '30 метров спринт с шайбой (сек)',
      value: 4.261,
      playerValue: 4.21,
      order: 2,
    },
    {
      id: '_MDdw9tjWKh1',
      name: '30 метров спиной вперед без шайбы (сек)',
      value: 4.792,
      playerValue: 4.98,
      order: 3,
    },
    {
      id: '54tGNLW9J7jV',
      name: '30 метров спиной вперед с шайбой (сек)',
      value: 4.894,
      playerValue: 5.06,
      order: 4,
    },
    {
      id: 'eDjMWZEDA0Sb',
      name: 'Комплексный тест на реакцию без шайбы (сек)',
      value: 8.456,
      playerValue: 9.48,
      order: 5,
    },
    {
      id: '4BZYbd168KaK',
      name: 'Комплексный тест на реакцию с шайбой (сек)',
      value: 8.426,
      playerValue: 8.43,
      order: 6,
    },
    {
      id: 'mI1idaZOiABd',
      name: 'Слаломный бег на коньках без шайбы (сек)',
      value: 10.368,
      playerValue: 10.39,
      order: 7,
    },
    {
      id: 'afPSPXMFcpH1',
      name: 'Слаломный бег на коньках с шайбой (сек)',
      value: 10.598,
      playerValue: 10.86,
      order: 8,
    },
    {
      id: 'LGMFVmqjjd_l',
      name: 'Переходы по диагонали без шайбы (сек)',
      value: 13.485,
      playerValue: 13.64,
      order: 9,
    },
    {
      id: 'AYq86kLnrMDh',
      name: 'Переходы по диагонали с шайбой (сек)',
      value: 13.556,
      playerValue: 14.49,
      order: 10,
    },
  ],
};
