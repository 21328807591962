import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {Button} from '../../ui/Button';

export default function DashboardsBlockPsycho(props) {
  const {
    title = '',
    titleSpan,
    content,
    isButtonsShowed = true,
    buttonsBlockType = 'link',
    link = '',
    linkText = '',
    onButtonClick = () => {},
    buttonText = '',
    withOutButtons = false,
    className,
  } = props;

  return (
    <Wrapper className={className}>
      <Headline>
        <HeadlineTitle>
          {title}
          {titleSpan && <span>{titleSpan}</span>}
        </HeadlineTitle>
      </Headline>
      <Content className="dashboard-content">{content}</Content>
      {!withOutButtons && (
        <ButtonsBlock>
          <ButtonsBlockContainer className={isButtonsShowed ? 'ButtonsBlockContainer show' : 'ButtonsBlockContainer'}>
            {buttonsBlockType === 'link' ? (
              <Link to={link}>{linkText}</Link>
            ) : (
              <Button onClick={onButtonClick}>{buttonText}</Button>
            )}
          </ButtonsBlockContainer>
        </ButtonsBlock>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 0 0 6px 6px;
  &:not(:last-child) {
    padding-bottom: 8px;
  }
`;
const Headline = styled.div`
  padding: 12px 24px;
  background-color: #fffaed;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 360px) {
    padding: 12px;
  }
`;
const HeadlineTitle = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 20px/1 'Bebas Neue';
  color: #2c2c2c;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  @media screen and (max-width: 360px) {
    font-size: 18px;
  }
  > span {
    font-weight: 600;
    font-size: 16px;
    font-family: 'Proxima Nova';
    @media screen and (max-width: 360px) {
      font-size: 14px;
    }
  }
`;
const Content = styled.div`
  background-color: white;
`;
const ButtonsBlock = styled.div`
  padding: 12px 24px;
  background-color: white;
  border-radius: 0 0 6px 6px;
  @media screen and (max-width: 360px) {
    padding: 12px;
  }
`;
const ButtonsBlockContainer = styled.div`
  display: none;
  &.show {
    display: block;
  }
  > a,
  > ${Button} {
    padding: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
    border-radius: 0;
    font: 600 normal 14px/16px 'Proxima Nova';
    color: #990011;
    text-decoration: none;
    transition: text-decoration 0.33s;
    cursor: pointer;
    &:focus {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;
