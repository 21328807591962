export const mock = {
  data: [...Array(20)].fill({
    playerId: 'Vz5b0oOkrJsY',
    params: {
      cM8_RO8XeFo_: {
        id: 'noSAvrOSPDrS',
        value: 56,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Динамометрия левой кисти (кг)',
        exercise: 'cM8_RO8XeFo_',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 2,
        avgValue: 49.17,
      },
      'ICv0HbxEN8Y-': {
        id: 'Mi2-j88TKnxS',
        value: 58,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Динамометрия правой кисти (кг)',
        exercise: 'ICv0HbxEN8Y-',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 1,
        avgValue: 51.5,
      },
      uNcHvpkPFg4t: {
        id: 'qJMNuYi6BaIo',
        value: 4.21,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Бег 30 м (сек)',
        exercise: 'uNcHvpkPFg4t',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 1,
        avgValue: 4.32,
      },
      'u7F-wrG6GD6h': {
        id: 'DlnnL4tMFCDj',
        value: 15,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Подъем ног в висе на перекладине (количество раз)',
        exercise: 'u7F-wrG6GD6h',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 4,
        avgValue: 14.23,
      },
      ft6g9B2yLv03: {
        id: 'XoU6yWk9RthG',
        value: 30,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Отжимания по метроному до отказа (количество раз)',
        exercise: 'ft6g9B2yLv03',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 7,
        avgValue: 35,
      },
      '22O83kcCgWIE': {
        id: 'SeiOtZDKaeFf',
        value: 96.41,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Y-balance test (YBT) правая',
        exercise: '22O83kcCgWIE',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 8,
        avgValue: 96.98,
      },
      'V0syxg-0GTJt': {
        id: 'YEYXyQ0i3vrY',
        value: 52,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Пресс по метроному до отказа (количество раз)',
        exercise: 'V0syxg-0GTJt',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 8,
        avgValue: 63.33,
      },
      ncEDMKZVMM: {
        id: 'V342AulDQnGF',
        value: 5.13,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Pro agility 5-10-5 (сек)',
        exercise: 'ncEDMKZVMM',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 6,
        avgValue: 5.22,
      },
      wMhqP7HzsSgC: {
        id: '0PKUFfaxJ7ys',
        value: 95.48,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Y-balance test (YBT) левая',
        exercise: 'wMhqP7HzsSgC',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 7,
        avgValue: 96.4,
      },
      YPVZqpzfcI: {
        id: 'CQgXhDp5aTi0',
        value: 2.62,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Прыжок в длину с места (см)',
        exercise: 'YPVZqpzfcI',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 1,
        avgValue: 2.44,
      },
      ice6a13iur: {
        id: 'ZYwk1dx3cVdO',
        value: 25,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Sit and reach (см)',
        exercise: 'ice6a13iur',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 4,
        avgValue: 22,
      },
      i6ss9z3iip: {
        id: 'FKvpeBbXYChG',
        value: 3.06,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Бег 20 м (сек)',
        exercise: 'i6ss9z3iip',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 2,
        avgValue: 3.14,
      },
      Xx9OYrarH5: {
        id: 'rXdl8_xkKDiz',
        value: 59.06,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Прыжок в высоту (см)',
        exercise: 'Xx9OYrarH5',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 1,
        avgValue: 48.6,
      },
      ip84hl3ivl: {
        id: 'SvAsGPaoKYog',
        value: 12.02,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Beep-test (уровень)',
        exercise: 'ip84hl3ivl',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 6,
        avgValue: 12.04,
      },
      '-JuGyneCYBL1': {
        id: 'HmszDRwQl4rg',
        value: 13.71,
        stage: 2,
        playerId: 'Vz5b0oOkrJsY',
        name: 'Пятикратный прыжок толчками двумя ногами (м)',
        exercise: '-JuGyneCYBL1',
        type: 'ofp',
        season: '22-23',
        groupId: 'L9S6kVTnGs',
        rang: 3,
        avgValue: 13.08,
      },
    },
    avgRang: 4.07,
    place: 1,
    firstName: 'Лев',
    lastName: 'Панжаев',
    playerNumber: '52',
  }),
  exercises: [
    {
      id: 'cM8_RO8XeFo_',
      name: 'Динамометрия левой кисти (кг)',
      type: 'ofp',
      hasReverseIndication: false,
      order: 1,
    },
    {
      id: 'ICv0HbxEN8Y-',
      name: 'Динамометрия правой кисти (кг)',
      type: 'ofp',
      hasReverseIndication: false,
      order: 2,
    },
    {
      id: 'YPVZqpzfcI',
      name: 'Прыжок в длину с места (см)',
      type: 'ofp',
      hasReverseIndication: false,
      order: 3,
    },
    {
      id: 'Xx9OYrarH5',
      name: 'Прыжок в высоту (см)',
      type: 'ofp',
      hasReverseIndication: false,
      order: 4,
    },
    {
      id: '-JuGyneCYBL1',
      name: 'Пятикратный прыжок толчками двумя ногами (м)',
      type: 'ofp',
      hasReverseIndication: false,
      order: 5,
    },
    {
      id: 'i6ss9z3iip',
      name: 'Бег 20 м (сек)',
      type: 'ofp',
      hasReverseIndication: true,
      order: 6,
    },
    {
      id: 'uNcHvpkPFg4t',
      name: 'Бег 30 м (сек)',
      type: 'ofp',
      hasReverseIndication: true,
      order: 7,
    },
    {
      id: 'ncEDMKZVMM',
      name: 'Pro agility 5-10-5 (сек)',
      type: 'ofp',
      hasReverseIndication: true,
      order: 8,
    },
    {
      id: 'ice6a13iur',
      name: 'Sit and reach (см)',
      type: 'ofp',
      hasReverseIndication: false,
      order: 9,
    },
    {
      id: 'V0syxg-0GTJt',
      name: 'Пресс по метроному до отказа (количество раз)',
      type: 'ofp',
      hasReverseIndication: false,
      order: 10,
    },
    {
      id: 'ft6g9B2yLv03',
      name: 'Отжимания по метроному до отказа (количество раз)',
      type: 'ofp',
      hasReverseIndication: false,
      order: 11,
    },
    {
      id: 'cc_yDyptaY6b',
      name: 'Beep-test',
      type: 'ofp',
      order: 12,
    },
    {
      id: '22O83kcCgWIE',
      name: 'Y-balance test',
      type: 'ofp',
      order: 15,
    },
    {
      id: 'MS2_YS08eCPP',
      name: 'Присед с собственным весом (мощность)',
      type: 'ofp',
      hasReverseIndication: false,
      order: 16,
    },
    {
      id: 'u7F-wrG6GD6h',
      name: 'Подъем ног в висе на перекладине (количество раз)',
      type: 'ofp',
      hasReverseIndication: false,
      order: 17,
    },
  ],
};
