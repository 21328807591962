import Field from '@common/FormField';
import {useSchoolsV2} from '@hooks/filtersV2';
import {userRoles} from '@src/constants';
import React from 'react';
import {FormProvider, useFormContext} from 'react-hook-form';

function SchoolField() {
  const form = useFormContext();
  const {isLoading, options} = useSchoolsV2({allSchoolsPermissionRoles: [userRoles.video_analyst]});
  return (
    <FormProvider {...form}>
      <Field
        isRequired
        multiple
        disabled={isLoading}
        isLoading={isLoading}
        label="Школа"
        limitTags={1}
        name="school"
        options={options}
        type="autocomplete"
      />
    </FormProvider>
  );
}

export default SchoolField;
