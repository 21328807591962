import React from 'react';

function ManPlus() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M8.72727 2.90909C8.72727 1.30182 7.42545 0 5.81818 0C4.21091 0 2.90909 1.30182 2.90909 2.90909C2.90909 4.51636 4.21091 5.81818 5.81818 5.81818C7.42545 5.81818 8.72727 4.51636 8.72727 2.90909ZM10.1818 4.36364V5.81818H12.3636V8H13.8182V5.81818H16V4.36364H13.8182V2.18182H12.3636V4.36364H10.1818ZM0 10.1818V11.6364H11.6364V10.1818C11.6364 8.24727 7.76 7.27273 5.81818 7.27273C3.87636 7.27273 0 8.24727 0 10.1818Z"
          fill="white"
          fillOpacity="0.8"
        />
      </g>
    </React.Fragment>
  );
}
export default ManPlus;
