import FullHeightWrapper from '@common/FullHeightWrapper';
import TrainingItem from '@common/TrainingControl/TrainingItem';
import dateInterface from '@helpers/date';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {fetcherTrainingControl, updateStep, updateTab, updateTempData} from '@slices/trainingControlProcess';
import {Typography} from '@ui/MUI';
import ButtonDefault from '@ui/MUI/Button';
import Stack from '@ui/MUI/Stack';
import React, {useEffect} from 'react';

import {PageBox, PageContentWrapper} from '../../TrainingControlProcess';
import LoadingHolder from '../LoadingHolder';

const TrainingTemplates = () => {
  const dispatch = useStoreDispatch();
  const {data, isLoading} = useStore((state) => state.trainingControlProcess.training_templates);

  useEffect(() => {
    dispatch(fetcherTrainingControl('training_templates', {type: 'template'}));
  }, []);

  return (
    <Stack spacing={4}>
      {isLoading && <LoadingHolder />}
      {data?.length ? (
        data?.map(({id, ...training}) => (
          <PageBox key={id}>
            <PageContentWrapper>
              <TrainingItem
                {...training}
                actionSlot={
                  <ButtonDefault
                    color="primary"
                    onClick={() => {
                      const {startTime, endTime, date} = training;
                      const dates = [
                        {
                          date,
                          startTime: dateInterface(startTime, 'hh:mm').valueOf(),
                          endTime: dateInterface(endTime, 'hh:mm').valueOf(),
                        },
                      ];

                      dispatch(
                        updateTempData({
                          ...training,
                          dates,
                          isTemplate: true,
                        }),
                      );
                      dispatch(updateTab('new_training'));
                      dispatch(updateStep(1));
                    }}
                  >
                    выбрать шаблон
                  </ButtonDefault>
                }
              />
            </PageContentWrapper>
          </PageBox>
        ))
      ) : (
        <FullHeightWrapper
          alignItems="center"
          data-testid="no-filters"
          display="flex"
          justifyContent="center"
          px={{xxs: 2, sm: 4}}
          py={{xxs: 1, sm: 3}}
        >
          <Typography variant="subtitle1">Шаблоны тренировок отсутствуют</Typography>
        </FullHeightWrapper>
      )}
    </Stack>
  );
};

export default TrainingTemplates;
