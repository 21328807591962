import FieldZones from '@components/apps/PlayerPersonalFile/components/Statistics/components/FieldZones';
import GateZones from '@components/apps/PlayerPersonalFile/components/Statistics/components/GateZones';
import {Stack} from '@ui/MUI';
import React from 'react';

function GoaltenderZones() {
  return (
    <Stack direction={{xxs: 'column', sm: 'row'}} gap={3}>
      <GateZones width={{xxs: '100%', sm: '50%'}} />
      <FieldZones width={{xxs: '100%', sm: '50%'}} />
    </Stack>
  );
}

export default GoaltenderZones;
