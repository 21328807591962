import useStore from '@hooks/useStore';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import Typography from '@ui/MUI/Typography';
import React, {memo} from 'react';

const CloseConfirm = ({open, close, onConfirm, isEdit}) => {
  const isDialogLoading = useStore((state) => state.materialsModule.form.isLoading);
  return (
    <DialogDefault
      actionText="Закрыть"
      closeText="Отменить"
      content={
        <>
          <Typography>Уверены что хотите закрыть окно без сохранения?</Typography>
        </>
      }
      isLoading={isDialogLoading}
      open={open}
      title={isEdit ? 'Редактирование материала' : 'Создание материала'}
      onActionButtonClick={onConfirm}
      onCloseDefault={close}
    />
  );
};

export default memo(CloseConfirm);
