import ChildrenField from '@common/ChildrenField';
import UploadFile from '@components/UploadFile';
import {validateUrl} from '@helpers/index';
import Stack from '@ui/MUI/Stack';
import TextFieldDefault from '@ui/MUI/TextField';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';
import {useWatch} from 'react-hook-form';

const FormContentVideo = () => {
  const link = useWatch({name: 'videoLink'});
  const file = useWatch({name: 'videoFile'});

  return (
    <Stack spacing={1}>
      <ChildrenField name="videoFile">
        <UploadFile accept="video/*" disabled={link} label="Загрузить видео" maxSizeMb={300} />
      </ChildrenField>
      <TypographyDefault>или</TypographyDefault>
      <ChildrenField
        name="videoLink"
        validate={(value) => {
          return !value || validateUrl(value) || 'Пожалуйста укажите корректную ссылку на видео';
        }}
      >
        <TextFieldDefault disabled={file} label="Ссылка на видео" />
      </ChildrenField>
    </Stack>
  );
};

export default FormContentVideo;
