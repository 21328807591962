import Field from '@common/FormField';
import CommonHelper from '@helpers/CommonHelper';
import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import React, {memo} from 'react';

function CountryField({isEdit, ...props}) {
  const {countries, loading} = useStore((state) => state.users, shallowEqual);

  return (
    <Field
      readOnly={!isEdit}
      size="small"
      textFieldVariant="outlined"
      type="autocomplete"
      {...props}
      isLoading={loading}
      options={countries ? CommonHelper.getDropDownItemsByNamedList(countries) : []}
    />
  );
}

export default memo(CountryField);
