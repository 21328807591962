import {Box} from '@ui/MUI';
import {darkRed, green} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React from 'react';
import styled, {css} from 'styled-components';

CellDoubleProgress.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  num: PropTypes.number,
};

function CellDoubleProgress({min, max, num}) {
  const width = (Math.abs(num) - min) / ((max - min) / 100) / 2 + '%';
  return <Progress negative={num < 0} width={width} />;
}

export default CellDoubleProgress;

const Progress = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 5px;
  max-width: 100%;
  z-index: 1;
  background-color: ${({negative}) => (negative ? darkRed['65'] : green['70'])};
  ${({negative}) =>
    negative &&
    css`
      transform: translateX(-100%);
    `}
`;
