import {ICE_TEAM_EVENTS} from './constants';

export const getSortedIceTeamEvents = (lessons = []) => {
  if (Array.isArray(lessons)) {
    return lessons
      .filter((el) => ICE_TEAM_EVENTS.includes(el.type))
      .sort((a, b) => a.rooms[0].startTimestamp - b.rooms[0].startTimestamp);
  }
  return [];
};
