export const mock = [
  {
    deletedPlayerNumber: 3,
    deletedPlayerFirstName: 'Name3',
    deletedPlayerLastName: 'Surname3',
    time: '00:03',
    videoTime: '0:19:11',
    deletionType: 'Hooking',
    offendedPlayerNumber: 58,
    offendedPlayerFirstName: 'Родион',
    offendedPlayerLastName: 'Сафин',
  },
  {
    deletedPlayerNumber: 4,
    deletedPlayerFirstName: 'Name4',
    deletedPlayerLastName: 'Surname4',
    time: '02:41',
    videoTime: '0:23:32',
    deletionType: 'Unsportsmanlike conduct',
    offendedPlayerFirstName: 'Оппоннент',
    offendedPlayerLastName: 'Оппоннент',
  },
  {
    deletedPlayerNumber: 51,
    deletedPlayerFirstName: 'Никита',
    deletedPlayerLastName: 'Чемоданов',
    time: '10:11',
    videoTime: '0:34:47',
    deletionType: 'Tripping',
    offendedPlayerFirstName: 'Оппоннент',
    offendedPlayerLastName: 'Оппоннент',
  },
  {
    deletedPlayerNumber: 41,
    deletedPlayerFirstName: 'Станислав',
    deletedPlayerLastName: 'Смагин',
    time: '15:45',
    videoTime: '0:43:02',
    deletionType: 'Tripping',
    offendedPlayerFirstName: 'Оппоннент',
    offendedPlayerLastName: 'Оппоннент',
  },
  {
    deletedPlayerNumber: 5,
    deletedPlayerFirstName: 'Name5',
    deletedPlayerLastName: 'Surname5',
    time: '19:39',
    videoTime: '0:48:37',
    deletionType: 'High sticking',
    offendedPlayerNumber: 42,
    offendedPlayerFirstName: 'Даниил',
    offendedPlayerLastName: 'Моружов',
  },
  {
    deletedPlayerNumber: 51,
    deletedPlayerFirstName: 'Никита',
    deletedPlayerLastName: 'Чемоданов',
    time: '24:23',
    videoTime: '1:11:15',
    deletionType: 'Interference',
    offendedPlayerFirstName: 'Оппоннент',
    offendedPlayerLastName: 'Оппоннент',
  },
  {
    deletedPlayerNumber: 42,
    deletedPlayerFirstName: 'Даниил',
    deletedPlayerLastName: 'Моружов',
    time: '26:54',
    videoTime: '1:15:30',
    deletionType: 'Tripping',
    offendedPlayerFirstName: 'Оппоннент',
    offendedPlayerLastName: 'Оппоннент',
  },
  {
    deletedPlayerNumber: 5,
    deletedPlayerFirstName: 'Name5',
    deletedPlayerLastName: 'Surname5',
    time: '36:31',
    videoTime: '1:29:11',
    deletionType: 'Obstruction',
    offendedPlayerNumber: 51,
    offendedPlayerFirstName: 'Никита',
    offendedPlayerLastName: 'Чемоданов',
  },
  {
    deletedPlayerNumber: 49,
    deletedPlayerFirstName: 'Денис',
    deletedPlayerLastName: 'Пилия',
    time: '39:52',
    videoTime: '1:34:46',
    deletionType: 'Roughing',
    offendedPlayerFirstName: 'Оппоннент',
    offendedPlayerLastName: 'Оппоннент',
  },
  {
    deletedPlayerNumber: 49,
    deletedPlayerFirstName: 'Денис',
    deletedPlayerLastName: 'Пилия',
    time: '45:58',
    videoTime: '1:59:27',
    deletionType: 'Holding',
    offendedPlayerFirstName: 'Оппоннент',
    offendedPlayerLastName: 'Оппоннент',
  },
  {
    deletedPlayerNumber: 4,
    deletedPlayerFirstName: 'Name4',
    deletedPlayerLastName: 'Surname4',
    time: '47:51',
    videoTime: '2:01:57',
    deletionType: 'Holding',
    offendedPlayerNumber: 87,
    offendedPlayerFirstName: 'Глеб',
    offendedPlayerLastName: 'Бурлака',
  },
  {
    deletedPlayerNumber: 5,
    deletedPlayerFirstName: 'Name5',
    deletedPlayerLastName: 'Surname5',
    time: '49:31',
    videoTime: '2:06:23',
    deletionType: 'Elbowing',
    offendedPlayerNumber: 35,
    offendedPlayerFirstName: 'Егор',
    offendedPlayerLastName: 'Пронин',
  },
  {
    deletedPlayerNumber: 3,
    deletedPlayerFirstName: 'Name3',
    deletedPlayerLastName: 'Surname3',
    time: '50:29',
    videoTime: '2:07:50',
    deletionType: 'Interference',
    offendedPlayerNumber: 49,
    offendedPlayerFirstName: 'Денис',
    offendedPlayerLastName: 'Пилия',
  },
  {
    deletedPlayerNumber: 43,
    deletedPlayerFirstName: 'Данил',
    deletedPlayerLastName: 'Сторчак',
    time: '56:27',
    videoTime: '2:17:14',
    deletionType: 'Tripping',
    offendedPlayerFirstName: 'Оппоннент',
    offendedPlayerLastName: 'Оппоннент',
  },
];
