import {mock} from '@components/apps/GameStatisticsParsingCheck/components/PlayTime/mock';
import TableComponent from '@components/apps/GameStatisticsParsingCheck/components/TableComponent';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useDeepMemo from '@hooks/useDeepMemo';
import {selectGameStatisticsParsingCheckTabs} from '@selectors/gameStatisticsParsingCheck';
import {longDash} from '@src/constants';
import {defaultSort} from '@src/helpers';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import PlayTimeCell from '../PlayTimeCell';

PlayTime.propTypes = {
  tab: PropTypes.string.isRequired,
};

function sortColumn(rowA, rowB, columnId) {
  const a = Number(rowA.getValue(columnId).value?.replace(':', ''));
  const b = Number(rowB.getValue(columnId).value?.replace(':', ''));
  return defaultSort(a, b);
}

function PlayTime({tab}) {
  const {data, isLoading, isLoad, hasError, hasOT} = useSelector(
    (state) => selectGameStatisticsParsingCheckTabs(state, tab),
    shallowEqual,
  );
  const [sorting, setSorting] = useState([{desc: true, id: 'totalTime'}]);
  const memoColumns = useMemo(() => {
    const total = {
      id: 'totalTime',
      accessorKey: 'totalTime',
      header: 'Общее игровое время',
      sortingFn: sortColumn,
      cell: (params) => (
        <PlayTimeCell
          hasRegress={params.getValue()?.hasRegress}
          isLoading={isLoading}
          value={params.getValue()?.value}
        />
      ),
    };
    const columns = [
      {
        id: 'player',
        accessorKey: 'player',
        header: 'Игрок',
        sticky: {left: true},
        sortingFn: (rowA, rowB, columnId) => {
          const a = rowA.getValue(columnId).lastName;
          const b = rowB.getValue(columnId).lastName;
          return defaultSort(a, b);
        },
        cell: (params) => {
          const student = params?.getValue();
          return (
            <PlayersHorizontalCard
              hasImage={false}
              isLoading={isLoading}
              preloaderWidthBetween={[100, 170]}
              student={{...student, playerNumber: student?.number}}
            />
          );
        },
      },
      {
        id: 'firstPeriodTime',
        accessorKey: 'firstPeriodTime',
        header: 'Игровое время в 1 периоде',
        sortingFn: sortColumn,
        cell: (params) => (
          <PlayTimeCell
            hasRegress={params.getValue()?.hasRegress}
            isLoading={isLoading}
            value={params.getValue()?.value}
          />
        ),
      },
      {
        id: 'secondPeriodTime',
        accessorKey: 'secondPeriodTime',
        header: 'Игровое время в 2 периоде',
        sortingFn: sortColumn,
        cell: (params) => (
          <PlayTimeCell
            hasRegress={params.getValue()?.hasRegress}
            isLoading={isLoading}
            value={params.getValue()?.value}
          />
        ),
      },
      {
        id: 'thirdPeriodTime',
        accessorKey: 'thirdPeriodTime',
        header: 'Игровое время в 3 периоде',
        sortingFn: sortColumn,
        cell: (params) => {
          const value = params.getValue();
          return <PlayTimeCell hasRegress={value?.hasRegress} isLoading={isLoading} value={value?.value} />;
        },
      },
    ];
    return hasOT
      ? [
          ...columns,
          {
            id: 'OTTime',
            accessorKey: 'OTTime',
            header: 'Игровое время в овертайме',
            sortingFn: sortColumn,
            cell: (params) => (
              <PlayTimeCell
                hasRegress={params.getValue()?.hasRegress}
                isLoading={isLoading}
                value={params.getValue()?.value}
              />
            ),
          },
          total,
        ]
      : [...columns, total];
  }, [hasOT, isLoading]);

  const rows = useDeepMemo(
    () =>
      (isLoading ? mock : data).map((item, id) => ({
        id,
        player: {
          firstName: item?.playerFirstName,
          lastName: item?.playerLastName,
          number: item?.playerNumber,
        },
        totalTime: {
          value: item?.overallTOI || longDash,
          hasRegress: Boolean(item?.isOverallLowest),
        },
        firstPeriodTime: {
          value: item?.firstHalfTOI || longDash,
          hasRegress: Boolean(item?.isFirstLowest),
        },
        secondPeriodTime: {
          value: item?.secondHalfTOI || longDash,
          hasRegress: Boolean(item?.isSecondLowest),
        },
        thirdPeriodTime: {
          value: item?.thirdHalfTOI || longDash,
          hasRegress: Boolean(item?.isThirdLowest),
        },
        OTTime: {
          value: item?.overTimeTOI || longDash,
          hasRegress: false,
        },
      })),
    [isLoading, data],
  );
  return (
    <TableComponent
      columns={memoColumns}
      data={rows}
      hasError={hasError || !data?.length}
      isLoad={isLoad}
      isLoading={isLoading}
      setSorting={setSorting}
      sorting={sorting}
      tab={tab}
    />
  );
}

export default PlayTime;
