import {BoxProps} from '@mui/material/Box/Box';
import {Box} from '@ui/MUI';
import React, {forwardRef} from 'react';

function ContentWrapper(
  {children, bgcolor = '#fff', ...props}: {bgcolor?: string} & BoxProps,
  ref: React.Ref<BoxProps>,
) {
  return (
    <Box bgcolor={bgcolor} borderRadius="6px" overflow="hidden" p={4} {...props} ref={ref}>
      {children}
    </Box>
  );
}

export default forwardRef(ContentWrapper);
