import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';

export function getPhysicalTestingOverall(data) {
  return axios.get(`${API_ENDPOINT}/v1/physical-testing/report/common`, {
    params: {
      groupId: data.groupId,
      season: data.season,
      stage: data.stage,
      type: data.typeOfTests,
    },
  });
}

export function getPhysicalTestingSirius(uId) {
  return axios.get(`${API_ENDPOINT}/v1/physical-testing/sirius/${uId}`);
}

export function getPhysicalTestingCompare(data) {
  return axios.get(
    `${API_ENDPOINT}/v1/physical-testing/report/stage-comparison?season=${data.season}&groupId=${data.groupId}&seasonForComparison=${data.seasonForComparsing}&stageForComparison=${data.stageForComparsing}&stage=${data.stage}&type=${data.type}`,
    data,
  );
}

export function getPhysicalPlayerProgress(data) {
  return axios.get(
    `${API_ENDPOINT}/v1/physical-testing/report/player-progress?groupId=${data.groupId}&playerId=${data.playerId}&season=${data.season}&stage=${data.stage}&type=${data.type}`,
    data,
  );
}

export function getTestsForEditCreate(params) {
  return axios.get(`${API_ENDPOINT}/v1/physical-testing/exercises`, {params: params});
}

export function getExerciseCategories(params) {
  return axios.get(
    `${API_ENDPOINT}/v1/physical-testing/exercises/categories`,
    {params: params},
    {
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при загрузке групп навыков'};
      },
    },
  );
}

export function getPlayerPersonalOfpResults(playerId, params) {
  return axios.get(
    `${API_ENDPOINT}/v1/physical-testing/players/${playerId}/profile`,
    {params: params},
    {
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при загрузке результатов тестов'};
      },
    },
  );
}

export function createEditPhysicalReport(data) {
  return axios.post(`${API_ENDPOINT}/v1/physical-testing`, data, {});
}
