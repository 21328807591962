import TreeColumn from '@common/Statistics/PlayOff/components/TreeColumn';
import {Stages} from '@common/Statistics/PlayOff/types';
import {StagesEnum} from '@src/types/tournament';
import React, {memo} from 'react';

function DesktopViewStages({
  stages = [],
  stagesArr = [],
  isLoading,
}: {
  stages: Stages;
  stagesArr: StagesEnum[];
  isLoading?: boolean;
}) {
  return isLoading
    ? stagesArr.map((item, index) => (
        <TreeColumn isLoading columnIndex={index} games={[]} key={item + index} stage={item} />
      ))
    : stages.map((item, index, arr) => (
        <TreeColumn
          columnIndex={index}
          columnsCount={arr?.length}
          games={item.games}
          key={item.stage + index}
          stage={item.stage}
        />
      ));
}

export default memo(DesktopViewStages);
