import Tooltip from '@mui/material/Tooltip';
import {TooltipProps} from '@mui/material/Tooltip/Tooltip';
import React, {memo} from 'react';

function TooltipDefault({
  content,
  children,
  forceElement = false,
  sx,
  enterTouchDelay = 0,
  ...props
}: {forceElement?: boolean} & TooltipProps) {
  const element = forceElement ? <div>{children || content}</div> : children || content;
  return (
    <Tooltip {...props} enterTouchDelay={enterTouchDelay}>
      {element}
    </Tooltip>
  );
}
export default memo(TooltipDefault);
