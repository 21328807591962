import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import CommonHelper from '../../../../helpers/CommonHelper';
import {cleanGameEstimateReport, loadStudentGameEstimateReport} from '../../../../redux/actions/GameEstimateActions';
import FullPagePreloader from '../../../preloader/FullPagePreloader';
import EstimationTableForStudent from '../../tools/estimation-table/EstimationTableForStudent/index';

export default function GameEstimationSummaryPanel() {
  let id = CommonHelper.lastPartOfUrl();

  const dispatch = useDispatch();

  const studentId = useSelector((state) => state.users.currentUser.id);
  const loading = useSelector((state) => state.gameEstimate.loading);
  const gameEstimateReport = useSelector((state) => state.gameEstimate.gameEstimateReport);

  const [lessonId, setLessonId] = useState(CommonHelper.lastPartOfUrl());

  useEffect(() => {
    dispatch(cleanGameEstimateReport());
  }, []);

  useEffect(() => {
    if (lessonId) {
      const request = {
        lessonId: lessonId,
        studentId: studentId,
      };
      dispatch(loadStudentGameEstimateReport(request));
    }
  }, [lessonId]);

  useEffect(() => {
    if (lessonId !== id) {
      setLessonId(id);
    }
  }, [id]);

  const gameTitle = CommonHelper.generateGameTitle(gameEstimateReport?.lesson);

  if (gameEstimateReport) {
    return (
      <Wrapper>
        <FullPagePreloader visible={loading} />
        <PageTitleDefault>{gameTitle ? `Оценка игры "${gameTitle}"` : 'Оценка игры'}</PageTitleDefault>
        <EstimationTableForStudent gameEstimateReport={gameEstimateReport} />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <FullPagePreloader visible={true} />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: #353535;
`;
