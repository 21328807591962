import LocalPreloader from '@components/preloader/LocalPreloader';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Stack} from '@ui/MUI';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import StatsComponentsChart from '../../StatsComponentsChart';

ThrowsDonutCharts.propTypes = {
  loader: PropTypes.func.isRequired,
  getter: PropTypes.func.isRequired,
  cleanup: PropTypes.func,
  weAtHome: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    statisticsIds: PropTypes.array.isRequired,
  }),
};
function ThrowsDonutCharts({filters, weAtHome, loader = () => {}, getter = () => {}, cleanup = () => {}, ...props}) {
  const dispatch = useStoreDispatch();
  const {data, isLoading, isLoad, error} = useStore(getter);
  const donutCharts = useDeepMemo(
    () => [
      {
        name: 'Броски',
        seriesData: data?.shots,
      },
      {
        name: 'Броски в створ',
        seriesData: data?.shotsOnGoal,
      },
      {
        name: 'Броски в створ из опасной зоны',
        seriesData: data?.shotsOnGoalDangerous,
      },
      {
        name: 'Заблокированные броски',
        seriesData: data?.blockedShots,
      },
    ],
    [data],
  );

  useDeepEffect(() => {
    filters?.statisticsIds?.length && dispatch(loader({...filters, statisticIds: filters?.statisticsIds || []}));
    return () => {
      dispatch(cleanup());
    };
  }, [filters]);

  return isLoading || props?.isLoading ? (
    <Wrapper>
      <LocalPreloader visible={isLoading || props?.isLoading} />
    </Wrapper>
  ) : isLoad && !error ? (
    <StatsChartsWrapper alignItems="flex-start" direction="row">
      {donutCharts?.map((chart, index) => {
        return (
          <StatsComponentsChart
            chart={{...chart, width: 112, height: 112}}
            key={index}
            title={chart.name}
            weAtHome={weAtHome}
          />
        );
      })}
    </StatsChartsWrapper>
  ) : (
    <TypographyDefault textAlign="center" variant="subtitle2">
      Нет данных
    </TypographyDefault>
  );
}

export default React.memo(ThrowsDonutCharts);

const Wrapper = styled.div`
  height: 200px;
  width: 100%;
`;

const StatsChartsWrapper = styled(Stack)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: ${(props) => props.alignItems || 'center'};
  flex-wrap: wrap;
`;
