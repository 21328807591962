import useEvent from '@hooks/useEvent';
import useStoreDispatch from '@hooks/useStoreDispatch';
import useToggle from '@hooks/useToggle';
import {deleteTrainingControl, updateTab} from '@slices/trainingControlProcess';
import {useMemo, useRef} from 'react';
import {useHistory} from 'react-router';

const useDeleteModal = () => {
  const id = useRef(null);
  const history = useHistory();
  const dispatch = useStoreDispatch();
  const toggle = useToggle({
    onToggle: (payload) => (id.current = payload),
    onToggleOff: () => (id.current = null),
  });

  const handleDelete = useEvent(() => {
    dispatch(
      deleteTrainingControl({
        name: 'training',
        payload: id.current,
        onSuccess: () => {
          dispatch(updateTab('plan'));
          history.push('/training_control_process');
        },
      }),
    );
  });

  return useMemo(() => ({toggle, handleDelete}), [toggle, handleDelete]);
};

export default useDeleteModal;
