import MaterialsApi from '@api/MaterialApi';
import {createSlice} from '@reduxjs/toolkit';

const defaultValue = {
  data: [],
  isLoading: false,
  isLoaded: false,
};

const form = {
  isLoading: false,
  isOpen: false,
  data: null,
};

const preview = {
  isOpen: false,
  data: null,
};

const filters = {
  category: 'all',
};

const dialog = {
  isLoading: false,
};

const initialState = {
  form,
  preview,
  filters,
  dialog,
  materials: {...defaultValue},
  material: {...defaultValue, data: {}},
  materialTypes: defaultValue,
  materialTabs: defaultValue,
  materialSection: {...defaultValue, data: {}},
};

const materialsSlice = createSlice({
  name: 'materialsModule',
  initialState,
  reducers: {
    loadingMaterial: (state) => {
      state.material.data = {};
      state.material.isLoaded = false;
      state.material.isLoading = true;
    },
    loadedMaterial: (state, {payload}) => {
      state.material.isLoading = false;
      state.material.isLoaded = true;
      state.material.data = payload.data;
    },
    updateDialogState: (state, {payload}) => {
      state.dialog.isLoading = payload;
    },
    loadingMaterialTabs: (state) => {
      state.materialTabs.data = [];
      state.materialTabs.isLoaded = false;
      state.materialTabs.isLoading = true;
    },
    loadedMaterialTabs: (state, {payload}) => {
      state.materialTabs.isLoading = false;
      state.materialTabs.isLoaded = true;
      state.materialTabs.data = payload.data;
    },
    loadingMaterialTypes: (state) => {
      state.materialTypes.data = [];
      state.materialTypes.isLoaded = false;
      state.materialTypes.isLoading = true;
    },
    loadedMaterialTypes: (state, {payload}) => {
      state.materialTypes.isLoading = false;
      state.materialTypes.isLoaded = true;
      state.materialTypes.data = payload.data;
    },
    loadingMaterials: (state) => {
      state.materials.isLoading = true;
      state.materials.isLoaded = false;
      state.materials.data = [];
    },
    loadedMaterials: (state, {payload}) => {
      state.materials.isLoading = false;
      state.materials.isLoaded = true;
      state.materials.data = payload.data;
    },
    loadingMaterialSection: (state) => {
      state.materialSection.isLoading = true;
      state.materialSection.isLoaded = false;
      state.materialSection.data = [];
    },
    loadedMaterialSection: (state, {payload}) => {
      state.materialSection.isLoading = false;
      state.materialSection.isLoaded = true;
      state.materialSection.data = payload.data;
    },

    updateFormData: (state, {payload}) => {
      state.form.data = payload;
    },

    updateFormVisability: (state, {payload}) => {
      state.form.isOpen = payload;
    },

    updatePreviewData: (state, {payload}) => {
      state.preview.data = payload;
    },

    updateFormLoadingState: (state, {payload}) => {
      state.form.isLoading = payload;
    },

    updatePreviewVisability: (state, {payload}) => {
      state.preview.isOpen = payload;
    },

    updateFilters: (state, {payload}) => {
      state.filters = {...state.filters, ...payload};
    },
  },
});

export const {
  loadedMaterials,
  loadingMaterials,
  updateFormData,
  updateFormVisability,
  updatePreviewVisability,
  updatePreviewData,
  loadingMaterialTypes,
  loadedMaterialTypes,
  loadingMaterialTabs,
  loadedMaterialTabs,
  updateFilters,
  loadedMaterialSection,
  loadingMaterialSection,
  updateFormLoadingState,
  updateDialogState,
  loadedMaterial,
  loadingMaterial,
} = materialsSlice.actions;

export const materialReducer = materialsSlice.reducer;

export const fetchVideoAnalytics = (payload) => (dispatch) => {
  dispatch(loadingMaterialSection());
  MaterialsApi.getMaterialVideoAnalytics(payload).then((sections) => {
    dispatch(loadedMaterialSection({data: sections}));
  });
};

export const fetchMaterials = (payload) => (dispatch) => {
  dispatch(loadingMaterials());
  MaterialsApi.getAllMaterials(payload).then((sections) => dispatch(loadedMaterials({data: sections})));
};

export const fetchMaterial = (id) => (dispatch) => {
  dispatch(loadingMaterial());
  MaterialsApi.getMaterialById(id).then((res) => {
    dispatch(loadedMaterial({data: res}));
  });
};

export const fetchMaterialSection = (params, section) => (dispatch) => {
  dispatch(loadingMaterialSection());
  MaterialsApi.getMaterialsBySection(params, section).then((section) =>
    dispatch(loadedMaterialSection({data: section})),
  );
};

export const updateMaterial =
  ({id, payload, onSuccess}) =>
  (dispatch) => {
    dispatch(updateFormLoadingState(true));
    MaterialsApi.updateMaterialById(id, payload)
      .then(() => {
        onSuccess();
      })
      .finally(() => dispatch(updateFormLoadingState(false)));
  };

export const createMaterial =
  ({payload, onSuccess}) =>
  (dispatch) => {
    dispatch(updateFormLoadingState(true));
    MaterialsApi.createMaterial(payload)
      .then(() => {
        onSuccess();
      })
      .finally(() => dispatch(updateFormLoadingState(false)));
  };

export const deleteMaterial =
  ({id, onSuccess}) =>
  (dispatch) => {
    dispatch(updateDialogState(true));
    MaterialsApi.deleteMaterialById(id)
      .then(() => {
        onSuccess();
        dispatch(updateFormVisability(false));
        dispatch(updateFormData(null));
      })
      .finally(() => {
        dispatch(updateDialogState(false));
      });
  };

export const fetchMaterialTypes = () => (dispatch) => {
  dispatch(loadingMaterialTypes());
  MaterialsApi.getMaterialTypes().then((types) => dispatch(loadedMaterialTypes({data: types})));
};

export const fetchMaterialTabs = () => (dispatch) => {
  dispatch(loadingMaterialTabs());
  MaterialsApi.getMaterialTabs().then((tabs) => dispatch(loadedMaterialTabs({data: tabs})));
};
