import TextFieldDefault from '@ui/MUI/TextField';
import {throttle} from 'lodash';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';

function SearchInput({time = 700, onChange, label, inputProps, InputProps}) {
  const [searchValue, setSearchValue] = useState('');
  const throttledFilter = useCallback(throttle(onChange, time), []);
  return (
    <TextFieldDefault
      InputProps={InputProps}
      label={label}
      placeholder="Поиск"
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.currentTarget.value);
        throttledFilter(e.currentTarget.value);
      }}
      {...inputProps}
    />
  );
}

SearchInput.propTypes = {
  time: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.object,
};

export default SearchInput;
