import {typeOfTestsOptions} from '@components/apps/PhysicalTesting/constants';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {useSeasons} from '@hooks/seasonsHooks';
import {Autocomplete, Box, Typography} from '@ui/MUI';
import Switch from '@ui/MUI/Switch';
import React from 'react';

import {useExerciseCategories} from '../../hooks';

function Filter({filter, setFilter}) {
  const {options, isLoading} = useSeasons(false, ({currentSeason}) =>
    setFilter({...filter, season: [currentSeason], type: typeOfTestsOptions?.[0]}),
  );
  const {categories, ...restExerciseCategories} = useExerciseCategories({type: filter?.type?.value});
  const categoriesOptions = categories?.map(({id, name}) => ({value: id, label: name})) || [];
  return (
    <Wrapper display="flex" mb={1} py={3}>
      <Box pr={5} sx={{width: 312}}>
        <Autocomplete
          multiple
          isLoading={isLoading}
          label="Сезон"
          limitTags={2}
          options={options}
          value={filter?.season}
          onChange={(_, value) => setFilter({...filter, season: value})}
        />
      </Box>
      <Box pr={5} sx={{width: 184}}>
        <Autocomplete
          label="Вид тестов"
          multiple={false}
          options={typeOfTestsOptions}
          value={filter?.type}
          onChange={(_, value) => setFilter({...filter, type: value, categoryId: null})}
        />
      </Box>
      <Box sx={{width: 284}}>
        <Autocomplete
          isLoading={restExerciseCategories?.isLoading}
          label="Группа"
          multiple={false}
          options={categoriesOptions}
          value={filter?.categoryId}
          onChange={(_, value) => setFilter({...filter, categoryId: value})}
        />
      </Box>
      <Box alignItems="center" display="flex" sx={{pl: 5}}>
        <Switch
          checked={filter?.hasTheBestResults}
          color="primary"
          defaultChecked={false}
          onChange={() => {
            setFilter({...filter, hasTheBestResults: !filter?.hasTheBestResults});
          }}
        />
        <Typography variant="body2">Лучший и средний показатели по команде</Typography>
      </Box>
    </Wrapper>
  );
}

export default Filter;
