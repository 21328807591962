import GamesCountButtons from '@components/apps/PlayerPersonalFile/components/Statistics/components/GamesCountButtons';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {GAMES_COUNT_BUTTONS} from '@components/apps/PlayerPersonalFile/constants';
import LineChart from '@components/charts/LineChart';
import date from '@helpers/date';
import {convertTimeMMSS} from '@helpers/dateHelper';
import {mmSSToMs} from '@helpers/time';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectPlayerPlayTime} from '@selectors/gameStatisticsMkc';
import {clearPlayTime, loadPlayTime} from '@slices/statisticsMKC';
import {Box, Stack, Typography} from '@ui/MUI';
import CircularProgress from '@ui/MUI/CircularProgress';
import {grey, yellow} from '@ui/MUI/colors';
import {cloneDeep, isEmpty} from 'lodash';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

const axisCommonConf = {
  axisTick: {
    show: false,
  },
  axisLine: {
    show: false,
  },
};
const seriesTooltip = ({data: {homeTeamName, awayTeamName, overplayedPercent, overplayedTime, time}}) => {
  return `
    ${homeTeamName} - ${awayTeamName}</br>
    Время ${time}</br>
    Переигранное время ${overplayedTime}</br>
    Процент переигранного времени ${overplayedPercent}%
  `;
};

function Time({requestFilters}) {
  const playerId = useParams()?.id;
  const dispatch = useStoreDispatch();
  const [gameCount, setGameCount] = useState(GAMES_COUNT_BUTTONS[0].value);
  const {data, isLoading} = useStore(selectPlayerPlayTime);
  const {dates, time, overplayedTime} = useDeepMemo(() => {
    const time = [];
    const overplayedTime = [];
    const dates = [];
    cloneDeep(data)
      ?.sort((a, b) => a?.date - b?.date)
      ?.forEach((item) => {
        dates.push(item?.date);
        time.push({...item, value: mmSSToMs(item?.time), id: item?.date});
        overplayedTime.push({...item, value: mmSSToMs(item?.overplayedTime), id: item?.date});
      });
    return {time, overplayedTime, dates};
  }, [data]);
  useDeepEffect(() => {
    if (playerId && requestFilters?.isFilled) dispatch(loadPlayTime({...requestFilters, gameCount, playerId}));
    return () => {
      dispatch(clearPlayTime());
    };
  }, [requestFilters, gameCount, playerId]);

  return (
    <Wrapper p="0">
      <Stack alignItems="center" direction="row" justifyContent="space-between" p={4} pb={5}>
        <Typography variant="h4">Время</Typography>
        <GamesCountButtons gamesCount={gameCount} setGamesCount={setGameCount} />
      </Stack>
      <ChartWrapper p={4} pt="0">
        {isLoading && <CircularProgress />}
        {!isEmpty(data) || isLoading ? (
          <LineChart
            options={{
              tooltip: {
                show: true,
                backgroundColor: grey['68'],
                borderColor: 'transparent',
                textStyle: {
                  color: '#fff',
                  fontSize: 12,
                  fontFamily: 'Proxima Nova',
                },
              },
              grid: {
                right: 5,
                top: 5,
                left: 20,
                bottom: 12,
                containLabel: true,
              },
              xAxis: {
                type: 'category',
                data: dates,
                boundaryGap: false,
                offset: 12,
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: grey['24'],
                    type: [7, 5],
                    dashOffset: 40,
                  },
                },
                axisLabel: {
                  color: grey['54'],
                  fontSize: '12px',
                  fontWeight: 600,
                  fontFamily: 'Proxima Nova',
                  rotate: 50,
                  formatter: (value) => date(Number(value)).format('DD.MM.YY'),
                },
                ...axisCommonConf,
              },
              yAxis: {
                type: 'value',
                min: 0,
                offset: 20,
                axisLabel: {
                  color: grey['54'],
                  fontSize: '12px',
                  fontWeight: 600,
                  fontFamily: 'Proxima Nova',
                  formatter: (value) => convertTimeMMSS(Number(value)),
                },
                splitLine: {
                  show: false,
                },
                ...axisCommonConf,
              },

              series: [
                {
                  data: time,
                  type: 'line',
                  smooth: true,
                  color: grey['42'],
                  symbolSize: 7,
                  areaStyle: {
                    color: grey['8'],
                  },
                  tooltip: {
                    formatter: seriesTooltip,
                  },
                },
                {
                  data: overplayedTime,
                  type: 'line',
                  smooth: true,
                  color: yellow['100'],
                  symbolSize: 7,
                  areaStyle: {
                    color: yellow['15'],
                  },
                  tooltip: {
                    formatter: seriesTooltip,
                  },
                },
              ],
            }}
          />
        ) : (
          <Typography py={4} variant="subtitle1">
            {!requestFilters?.isFilled ? 'Заполните фильтры' : 'Нет данных по времени'}
          </Typography>
        )}
      </ChartWrapper>
    </Wrapper>
  );
}

export default Time;

const ChartWrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
