import PageTitleDefault from '@ui/PageTitleDefault';
import React, {memo} from 'react';

import Calendar from '../../calendar/index';

const SchedulePanel = () => {
  return (
    <>
      <PageTitleDefault text="Расписание" />
      <Calendar />
    </>
  );
};

export default memo(SchedulePanel);
