import {getTeamNames} from '@api/gameStatisticsParsingCheckAPI';
import Lineup from '@components/apps/GameStatisticsParsingCheck/components/Lineup';
import {STATISTICS_PARSING_CHECK as LABELS} from '@src/constants';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import Ejection from './components/Ejection';
import Goals from './components/Goals';
import Goaltenders from './components/Goaltenders';
import LongShifts from './components/LongShifts';
import PlayTime from './components/PlayTime';
import ShortShifts from './components/ShortShifts';
import Throwins from './components/Throwins';

export default function GameStatisticsParsingCheck() {
  const {id} = useParams();
  const [teamsInfo, setTeamsInfo] = useState({
    homeTeamName: 'Команда',
    awayTeamName: 'Команда',
    date: moment(),
  });
  useEffect(() => {
    getTeamNames({statId: id}).then(({data}) => setTeamsInfo(data));
  }, []);

  return (
    <>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Редактирование статистики', path: '/edit_stats'},
          {
            label: `Просмотр «${teamsInfo?.homeTeamName} – ${teamsInfo?.awayTeamName}»`,
            path: null,
          },
        ]}
        text={`Игра «${teamsInfo?.homeTeamName} – ${teamsInfo?.awayTeamName}»  ${moment(teamsInfo?.date).format(
          'DD.MM.YYYY',
        )}`}
      />
      <TabsNew
        hasMobileView
        withoutWrapper
        tabs={[
          {label: LABELS.lineup.label, value: <Lineup tab={LABELS.lineup.value} />},
          {label: LABELS.goals.label, value: <Goals tab={LABELS.goals.value} />},
          {label: LABELS.fouls.label, value: <Ejection tab={LABELS.fouls.value} />},
          {label: LABELS.faceoffs.label, value: <Throwins tab={LABELS.faceoffs.value} />},
          {label: LABELS.toi.label, value: <PlayTime tab={LABELS.toi.value} />},
          {
            label: LABELS.shortShifts.label,
            value: <ShortShifts tab={LABELS.shortShifts.value} />,
          },
          {label: LABELS.longShifts.label, value: <LongShifts tab={LABELS.longShifts.value} />},
          {
            label: LABELS.goaltenders.label,
            value: <Goaltenders tab={LABELS.goaltenders.value} />,
          },
        ]}
        tabsWrapperProps={{px: '0'}}
      />
    </>
  );
}
