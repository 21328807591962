import PreviewImage from '@assets/img/video-preview.jpg';
import {Article, Link} from '@mui/icons-material';
import {grey} from '@ui/MUI/colors';

export const MAX_TITLE_HEIGHT = 40; // px

export const iconMap = {
  link: Link,
  file: Article,
};

export const iconStyles = {
  position: {
    xs: 'initial',
    xxs: 'absolute',
  },
  top: {
    xs: 'initial',
    xxs: '18px',
  },
  left: {
    xs: '14px',
    xxs: '14px',
  },
  fontSize: 28,
  color: grey[42],
};

export const overflowStyles = (breakpoint) => ({
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: breakpoint === 'xxs' ? 'auto' : '40px',
});

export const border = '1px solid #E0E0E0';

export const PREVIEW_IMAGE_PATH = PreviewImage;
