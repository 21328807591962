import React from 'react';

function Ticket() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M4.0484 6.31405L7.94789 6.31405L7.94789 9.86072L4.0484 9.86072L4.0484 6.31405ZM3.24136 5.51138L3.24136 10.6634L8.75493 10.6634L8.75493 5.51138L3.24136 5.51138Z"
          fill="#626262"
        />
        <path d="M3.24136 13.0167L3.24136 11.9185L4.34554 11.9185L4.34554 13.0167L3.24136 13.0167Z" fill="#626262" />
        <path d="M5.44974 13.0167L5.44974 11.9185L6.55392 11.9185L6.55392 13.0167L5.44974 13.0167Z" fill="#626262" />
        <path d="M7.65443 13.0167L7.65443 11.9185L8.75861 11.9185L8.75861 13.0167L7.65443 13.0167Z" fill="#626262" />
        <path
          d="M5.16361 1.31558L0.999999 1.31558L1 16.7002L5.16361 16.7002L5.16361 16.2624C5.16361 15.8027 5.53778 15.4305 6 15.4305C6.46222 15.4305 6.83639 15.8027 6.83639 16.2624L6.83639 16.7002L11 16.7002L11 1.31558L6.83639 1.31558L6.83639 1.7534C6.83639 2.21312 6.46221 2.58527 6 2.58527C5.53778 2.58527 5.16361 2.21312 5.16361 1.7534L5.16361 1.31558ZM4.33822 2.19488L4.3639 2.2715C4.59134 2.98661 5.24798 3.46456 6.00367 3.46456C6.75569 3.46456 7.41599 2.98661 7.64343 2.2715L7.66911 2.19488L10.1233 2.19488L10.1233 15.8209L7.66911 15.8209L7.64343 15.7443C7.41599 15.0292 6.75935 14.5512 6.00367 14.5512C5.25165 14.5512 4.59134 15.0292 4.3639 15.7443L4.33822 15.8209L1.88408 15.8209L1.88408 2.19123L4.33822 2.19123L4.33822 2.19488Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default Ticket;
