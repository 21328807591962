import dateInterface from '@helpers/date';
import {longDash, rolesMap} from '@src/constants';
import Grid from '@ui/MUI/Grid';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import moment from 'moment';
import React from 'react';

const Item = ({label, description}) => {
  return (
    <Stack direction="row" spacing="4px">
      <Typography color="#747474">{label}</Typography>
      {typeof description === 'string' ? <Typography color="#000000DE">{description}</Typography> : description}
    </Stack>
  );
};

const MainInfoBlock = ({name, group, date, startTime, endTime, author, dates}) => {
  return (
    <div>
      <Typography mb={3} variant="h3">
        {name}
      </Typography>
      <Grid container maxWidth="700px" spacing={{sm: 4, xs: 4, xxs: 1}}>
        <Grid item xs={6}>
          <Item description={group?.name || '-'} label="Команда:" />
        </Grid>
        {!dates && (
          <Grid item xs={6}>
            <Item description={moment(date).format('DD.MM.YYYY')} label="Дата:" />
          </Grid>
        )}
        <Grid item xs={6}>
          <Item description={author?.name || '-'} label={`${rolesMap[author.userRole]}:`} />
        </Grid>
        <Grid item xs={dates ? 12 : 6}>
          {dates ? (
            <Item
              description={
                <Stack direction="row" flexWrap="wrap" gap="4px 16px" maxWidth="650px">
                  {dates.map(({date, startTime, endTime}, index) => {
                    return (
                      <Typography color="#000000DE" key={index}>
                        {dateInterface(date).format('DD.MM.YY')} {dateInterface(startTime).format('HH:mm')}
                        {` ${longDash} `}
                        {dateInterface(endTime).format('HH:mm')};
                      </Typography>
                    );
                  })}
                </Stack>
              }
              label="Даты:"
            />
          ) : (
            <Item description={`${startTime} - ${endTime}`} label="Время:" />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default MainInfoBlock;
