import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import {MobileDatePickerProps} from '@mui/lab/MobileDatePicker/MobileDatePicker';
import TextField from '@mui/material/TextField';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';

type CustomDatePickerMobile = {
  hiddenInput?: boolean;
  isDatePickerOpen?: boolean;
  handleChange?: () => void;
  submitDay?: () => void;
  closeDataPicker?: () => void;
} & MobileDatePickerProps<AdapterMoment>;

const CustomizedDateRangePicker = styled((props: CustomDatePickerMobile) => (
  <>
    <GlobalStylesDataPicker />
    <MobileDatePicker {...props} />
  </>
))``;

const DatePickerMobile = ({
  hiddenInput = true,
  renderInput,
  isDatePickerOpen = false,
  handleChange,
  submitDay,
  closeDataPicker,
  onChange,
  ...props
}: CustomDatePickerMobile) => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CustomizedDateRangePicker
          allowSameDateSelection
          disableMaskedInput
          showDaysOutsideCurrentMonth
          showToolbar
          cancelText="Отменить"
          inputFormat="MM/dd/yyyy"
          label="Дата"
          okText="Выбрать"
          open={isDatePickerOpen}
          renderInput={renderInput || ((params: TextFieldProps) => (hiddenInput ? null : <TextField {...params} />))}
          toolbarTitle=""
          onAccept={submitDay}
          onChange={onChange || handleChange}
          onClose={closeDataPicker}
          {...props}
        />
      </LocalizationProvider>
    </div>
  );
};

const GlobalStylesDataPicker = createGlobalStyle`
  .MuiCalendarPicker {
    &-root {
      .Mui-selected {
        background: #e12 !important;
      }
    }
  }
`;

export default DatePickerMobile;
