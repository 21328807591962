export const fetchOverallMapper = (arrayOfPlayers, isAdditionParamSeparate = true) => {
  return arrayOfPlayers.map((player) => {
    return {
      ...player,
      params: player.params.reduce((obj, item) => {
        let res = {
          ...obj,
          [item?.exercise]: item,
        };
        if (item.additionParam && isAdditionParamSeparate) {
          res = {
            ...obj,
            [item?.exercise]: item,
            [item?.additionParam?.exercise]: item.additionParam,
          };
        }
        return res;
      }, {}),
    };
  });
};
