export const tableDataMock = [...Array(10)].fill({
  id: 'bG2x9LxxNgb0',
  createdAt: '2023-09-02T12:04:05.057Z',
  updatedAt: '2023-09-02T12:05:10.666Z',
  playerId: 'QwQPPT-S6NtU',
  date: '2023-09-02',
  trainingGroup: {
    id: 'DHK1JT0hBDgx',
    createdAt: '2023-09-02T12:04:05.057Z',
    updatedAt: '2023-09-02T12:04:05.057Z',
    gymMood: undefined,
    gymHealth: undefined,
    iceMood: undefined,
    iceHealth: undefined,
    skillMood: undefined,
    skillHealth: undefined,
    isDayOff: false,
    comments: [],
  },
  trainingIndividual: {
    id: 'FoSbs7zluGnQ',
    createdAt: '2023-09-02T12:05:10.666Z',
    updatedAt: '2023-09-02T12:05:10.666Z',
    gymMood: undefined,
    gymHealth: undefined,
    iceMood: undefined,
    iceHealth: undefined,
    skillMood: undefined,
    skillHealth: undefined,
    isDayOff: false,
    comments: [],
  },
});
