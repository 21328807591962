import Filters from '@components/apps/PhysicalTesting/components/CreateEdit/components/Filters';
import OverallReportTable from '@components/apps/PhysicalTesting/components/OverallReport/components/OverallReportTable';
import stateHelper from '@helpers/StateHelper';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectOverallInfo, selectPhysicalTestingEditCreate} from '@selectors/physicalTesting';
import {setFiltersValue} from '@slices/PhysicalTestingEditCreate';
import {clearPhysicalOverallState, fetchOverallData} from '@src/redux/slices/PhysicalTestingOverall';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

const OverallReport = () => {
  const dispatch = useStoreDispatch();
  const isStudent = useSelector((state) => state.users.currentUser.userRole === 'student');
  const {items, isLoading} = useStore(selectOverallInfo);
  const state = useStore((state) => state);
  const {filters} = useStore(selectPhysicalTestingEditCreate);
  const studentGroup = stateHelper.getGroups(state);
  const isAllFiltersValuesExist =
    (!isStudent ? !!filters.group?.id : !!studentGroup?.[0]?.id) &&
    !!filters?.season?.value &&
    !!filters?.stage?.value &&
    !!filters?.typeOfTests?.value;

  const fetchData = () => {
    if ((filters?.group || studentGroup?.[0]?.id) && filters.season && filters.stage?.value && filters.typeOfTests) {
      dispatch(
        fetchOverallData({
          groupId: !isStudent ? filters?.group?.value : studentGroup?.[0]?.id,
          season: filters.season.value,
          stage: filters.stage.value,
          typeOfTests: filters.typeOfTests.value,
        }),
      );
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(clearPhysicalOverallState());
    };
  }, [filters]);

  const updateFilters = (data) => {
    dispatch(setFiltersValue(data));
  };

  return (
    <>
      <Filters filters={filters} isStudent={isStudent} updateFilters={updateFilters} />
      {!isAllFiltersValuesExist ? (
        <div data-testid="overall-report-empty-filters">
          <TableEmptyBlock text="Для отображения данных заполните фильтры" />
        </div>
      ) : (
        <>
          {!isLoading && !items?.length ? (
            <TableEmptyBlock data-testid="overall-report-no-data" text={'Данные отсутствуют'} />
          ) : (
            <OverallReportTable isLoading={isLoading} tableData={items} />
          )}
        </>
      )}
    </>
  );
};

export default OverallReport;
