import ReactTableComponent from '@common/ReactTableComponent';
import React, {useMemo} from 'react';
import styled from 'styled-components';

import {columns} from './constants';

const StyledTable = styled(ReactTableComponent)`
  border: 1px solid #e0e0e0;
`;

const Table = ({specialists, controlDate, todo, plan}) => {
  const rows = useMemo(
    () => [
      {
        specialists,
        controlDate,
        todo,
        plan,
      },
    ],
    [specialists, controlDate, todo, plan],
  );
  return <StyledTable columns={columns} data={rows} />;
};

export default Table;
