import {green, yellow} from '@ui/MUI/colors';

export const BMI_ITEMS = {
  height: 'height',
  weight: 'weight',
  fat: 'caliperometry',
  bmi: 'bmi',
};

export const playerStatusesChartConfig = {
  injury: {
    short: 'ТР',
    full: 'Травмирован',
    color: yellow['60'],
  },
  illness: {
    short: 'БЛ',
    full: 'Болен',
    color: green['70'],
  },
};

export const GAMES_COUNT_BUTTONS = [
  {
    label: '6 игр',
    value: '6',
  },
  {
    label: '12 Игр',
    value: '12',
  },
  {
    label: 'все игры',
    value: '',
  },
];

export const STATISTIC_METRICS = {
  score: {
    label: 'Очки',
    value: 'score',
    isYoungerAgeGroup: true,
  },
  goals: {
    label: 'голы',
    value: 'goals',
    isYoungerAgeGroup: true,
  },
  assists: {
    label: 'Передачи',
    value: 'assists',
    isYoungerAgeGroup: true,
  },
  plusMinus: {
    label: '+/-',
    value: 'plusMinus',
    unit: 'полезность',
    isYoungerAgeGroup: true,
  },
  blockedShots: {
    label: 'Блокшоты',
    value: 'blockedShots',
  },
  shotsOnGoals: {
    label: 'Броски в створ',
    value: 'shotsOnGoals',
  },
  realizationShots: {
    label: 'Реализация бросков',
    value: 'realizationShots',
    unit: '%',
  },
  winBattles: {
    label: 'единоборства',
    value: 'winBattles',
    unit: '%',
  },
};

export const GOALTENDER_STATISTIC_METRICS = {
  games: {
    label: 'Игры',
    value: 'games',
    valueLabel: 'отыграно',
    isYoungerAgeGroup: true,
    playerTimeInMinutes: null,
  },
  wins: {
    label: 'Победы',
    value: 'wins',
    valueLabel: 'выиграно',
    isYoungerAgeGroup: true,
  },
  shotsOnGoals: {
    label: 'Броски в створ',
    value: 'shotsOnGoals',
    countUnit: 'бросков',
    isYoungerAgeGroup: true,
  },
  reliabilityCoefficient: {
    label: 'Коэф. надежности',
    value: 'reliabilityCoefficient',
    isYoungerAgeGroup: true,
  },
  saves: {
    label: '% сейвов',
    value: 'saves',
    countUnit: '%',
    isYoungerAgeGroup: true,
  },
  savesDangerous: {
    label: '% сейвов из опасной зоны',
    value: 'savesDangerous',
  },
  savesNotDangerous: {
    label: '% сейвов из не опасной зоны',
    value: 'savesNotDangerous',
  },
};
