export const getCategoriesMock = [
  {
    id: 'Z5k6zmZkGU12',
    createdAt: '2023-09-15T08:16:14.210Z',
    updatedAt: '2023-09-15T08:16:14.210Z',
    name: 'Катание',
    skills: [
      {
        id: 'gcJ6upAFR5fs',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Ускорение',
        category: {
          id: 'Z5k6zmZkGU12',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Катание',
        },
      },
      {
        id: 'xUBDWONqbBmQ',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Скорость',
        category: {
          id: 'Z5k6zmZkGU12',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Катание',
        },
      },
      {
        id: '5ba0uG_GPefb',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Баланс',
        category: {
          id: 'Z5k6zmZkGU12',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Катание',
        },
      },
      {
        id: 'Fg6nu5wy_NEr',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Мобильность',
        category: {
          id: 'Z5k6zmZkGU12',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Катание',
        },
      },
      {
        id: 'PnhtRcXvjCtB',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Катание спиной',
        category: {
          id: 'Z5k6zmZkGU12',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Катание',
        },
      },
      {
        id: 'Esjwb6nYeFMR',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Обманные действия',
        category: {
          id: 'Z5k6zmZkGU12',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Катание',
        },
      },
    ],
  },
  {
    id: 'jAHmBlO-u-5r',
    createdAt: '2023-09-15T08:16:14.210Z',
    updatedAt: '2023-09-15T08:16:14.210Z',
    name: 'Владение шайбой',
    skills: [
      {
        id: '8WNJaR2wyvKC',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Точность броска',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: 'Ay6efG-fTL1S',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Сила броска',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: 'uJPfpE-LJjH3',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Релиз',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: 'dWqxSSYqlth3',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Обладает разными техниками броска',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: 'uv4jXyzJAiwI',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Владение клюшкой',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: 'tUhHPFjwjvMg',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Укрывание шайбы',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: 'lYll1vpWy4nC',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Вбрасывания',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: 'eb1OQOKM_kwF',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Передачи',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: 'hngozLtTyarn',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Прием передач',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: 'J471-iRJQBO-',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Умение забить',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: '9RrZTpt9glYQ',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Дальняя передача с обострением',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: '0t_REdouMh9z',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Расположение клюшки в атаке',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
      {
        id: 'Pak36ZV1FAZr',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Обманные движения',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
    ],
  },
  {
    id: 'P_hUkQHiPtiz',
    createdAt: '2023-09-15T08:16:14.210Z',
    updatedAt: '2023-09-15T08:16:14.210Z',
    name: 'Соревновательность',
    skills: [
      {
        id: 'i9-AAVieIZE9',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Желание забивать',
        category: {
          id: 'P_hUkQHiPtiz',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Соревновательность',
        },
      },
      {
        id: 'hE8R_JGFU1ri',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Трудовая этика',
        category: {
          id: 'P_hUkQHiPtiz',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Соревновательность',
        },
      },
      {
        id: 'EJE3uRnBXdCG',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Отношение',
        category: {
          id: 'P_hUkQHiPtiz',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Соревновательность',
        },
      },
      {
        id: 'zimqpNDWvGaH',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Стабильность',
        category: {
          id: 'P_hUkQHiPtiz',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Соревновательность',
        },
      },
      {
        id: '5bieebcntdur',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Подборы',
        category: {
          id: 'P_hUkQHiPtiz',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Соревновательность',
        },
      },
    ],
  },
  {
    id: 'Mm-qeefyNlpk',
    createdAt: '2023-09-15T08:16:14.210Z',
    updatedAt: '2023-09-15T08:16:14.210Z',
    name: 'Силовая борьба',
    skills: [
      {
        id: 'E8KQKczJxRqo',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Игра в углах и на бортах',
        category: {
          id: 'Mm-qeefyNlpk',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Силовая борьба',
        },
      },
      {
        id: 'NK-5SHqsSNU9',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Физическое присутствие',
        category: {
          id: 'Mm-qeefyNlpk',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Силовая борьба',
        },
      },
      {
        id: 'YPez5laOJkik',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Подготовленность',
        category: {
          id: 'Mm-qeefyNlpk',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Силовая борьба',
        },
      },
      {
        id: 'U_IHoQY7azEx',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Силовые приемы',
        category: {
          id: 'Mm-qeefyNlpk',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Силовая борьба',
        },
      },
      {
        id: '2zls8sOc6BJ2',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Готовность постоять за себя и партнеров',
        category: {
          id: 'Mm-qeefyNlpk',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Силовая борьба',
        },
      },
    ],
  },
  {
    id: 'u3yFadFovFuY',
    createdAt: '2023-09-15T08:16:14.210Z',
    updatedAt: '2023-09-15T08:16:14.210Z',
    name: 'Игровой интеллект',
    skills: [
      {
        id: 'dfS7Wl8sApJZ',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Плеймейкер',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: 'pWh2tp2RQSlG',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Предвидение в атаке',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: 'wWt6iRMaRk3Y',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Дисциплина',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: 'EOHmI7-V8Nyn',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Принятие решений',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: '7VfTmn8KSd8S',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Игра под давлением',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: 'IQ8IgzRenDor',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Универсальность',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: 'eWOusT3fABBP',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Видение площадки',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: '3RIaMI8g7LC_',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Первое касание',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: 'pqmzeDqcyrEf',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Потери',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: 'BJl6uw_DSrHJ',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Понимание игры в атаке',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: 'PLa3vkCNuxUk',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Подключения к атаке',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
      {
        id: 'V-HEzZUVNaQL',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Игра на чужой синей линии',
        category: {
          id: 'u3yFadFovFuY',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игровой интеллект',
        },
      },
    ],
  },
  {
    id: 'Vnu2I2m5yp-J',
    createdAt: '2023-09-15T08:16:14.210Z',
    updatedAt: '2023-09-15T08:16:14.210Z',
    name: 'Игра в обороне',
    skills: [
      {
        id: 'E34ejIsqkqkT',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Предвидение в обороне',
        category: {
          id: 'Vnu2I2m5yp-J',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игра в обороне',
        },
      },
      {
        id: 'gSDaWAOFeK-w',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Позиционная игра',
        category: {
          id: 'Vnu2I2m5yp-J',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игра в обороне',
        },
      },
      {
        id: 'wdlnfrZx6_Q-',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Надежность в обороне',
        category: {
          id: 'Vnu2I2m5yp-J',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игра в обороне',
        },
      },
      {
        id: 'IOhVMv8abZhZ',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Расположение клюшки в обороне',
        category: {
          id: 'Vnu2I2m5yp-J',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игра в обороне',
        },
      },
      {
        id: 'GoPgHO4RCTIz',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Блокирование бросков',
        category: {
          id: 'Vnu2I2m5yp-J',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игра в обороне',
        },
      },
      {
        id: 'I47M6u0xh-I2',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Игра на пятаке',
        category: {
          id: 'Vnu2I2m5yp-J',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Игра в обороне',
        },
      },
    ],
  },
  {
    id: 'GIQRZ291hJT-',
    createdAt: '2023-09-15T08:16:14.210Z',
    updatedAt: '2023-09-15T08:16:14.210Z',
    name: 'Психология',
    skills: [
      {
        id: 'clz-1Z-5CPmC',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Лидерство',
        category: {
          id: 'GIQRZ291hJT-',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Психология',
        },
      },
      {
        id: 'q6jG0mhRz80M',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Коммуникации',
        category: {
          id: 'GIQRZ291hJT-',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Психология',
        },
      },
      {
        id: 'Q76pKabiMBSE',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Уверенность в себе',
        category: {
          id: 'GIQRZ291hJT-',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Психология',
        },
      },
    ],
  },
];
export const getPersonalDevelopmentJournalPlayerSkillsMock = {
  percentForGroup: {
    'Владение шайбой': {
      all: 1,
      completed: 0,
      percent: 10,
    },
    Психология: {
      all: 1,
      completed: 1,
      percent: 100,
    },
  },
  active: [
    {
      id: 'UaQR_LHKo5UP',
      createdAt: '2023-10-09T13:01:50.164Z',
      updatedAt: '2023-10-09T13:01:50.164Z',
      playerId: 'ad5jbFOCHD',
      todo: 'Что нужно сделать',
      plan: 'Что нужно сделать',
      controlDate: '2023-10-12',
      score: 3,
      expectedScore: 7,
      completedScore: null,
      isCompleted: false,
      skill: {
        id: 'Ay6efG-fTL1S',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Сила броска',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
    },
    {
      id: 'FmxtoDJwppAr',
      createdAt: '2023-10-06T08:19:45.356Z',
      updatedAt: '2023-10-06T08:19:45.356Z',
      playerId: 'ad5jbFOCHD',
      todo: 'лидировать',
      plan: 'лидировать',
      controlDate: '2023-10-19',
      score: 4,
      expectedScore: 5,
      completedScore: null,
      isCompleted: false,
      skill: {
        id: 'clz-1Z-5CPmC',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Лидерство',
        category: {
          id: 'GIQRZ291hJT-',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Психология',
        },
      },
    },
  ],
  finished: [
    {
      id: 'CggLnlMqEI6V',
      createdAt: '2023-10-13T08:47:39.220Z',
      updatedAt: '2023-10-13T08:51:32.760Z',
      playerId: 'yKZAmXvELI',
      todo: 'тест',
      plan: 'тест',
      controlDate: '2023-10-14',
      score: 5,
      expectedScore: 6,
      completedScore: 8,
      isCompleted: true,
      skill: {
        id: '8WNJaR2wyvKC',
        createdAt: '2023-09-15T08:16:21.678Z',
        updatedAt: '2023-09-15T08:16:21.678Z',
        name: 'Точность броска',
        category: {
          id: 'jAHmBlO-u-5r',
          createdAt: '2023-09-15T08:16:14.210Z',
          updatedAt: '2023-09-15T08:16:14.210Z',
          name: 'Владение шайбой',
        },
      },
    },
  ],
};
