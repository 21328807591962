import UsersAPI from '@api/UsersAPI';
import PlayerInfo from '@common/DevelopmentJournal/components/PlayerInfo';
import {useCategoriesEstimates} from '@common/DevelopmentJournal/hooks';
import useQueryString from '@hooks/useQueryString';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Box, IconButton, Stack} from '@ui/MUI';
import PageTitleDefault from '@ui/PageTitleDefault';
import {HttpStatusCode} from 'axios';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';

import EstimateList from './components/EstimateList';

const useStudent = (id) => {
  const [student, setStudent] = useState({
    isLoading: false,
    isLoad: false,
    isError: false,
    error: null,
    data: {},
  });

  const fetchUser = async () => {
    setStudent({...student, isLoading: true});
    try {
      const {data} = await UsersAPI.getStudentByIdV2(id);
      setStudent({...student, isLoading: false, isLoad: true, data});
    } catch (error) {
      setStudent({...student, isLoading: false, isError: true, error});
    }
  };

  useEffect(() => {
    fetchUser(id);
  }, [id]);

  return student;
};

const generateBreadCrumbs = (student) => {
  const studentFullName = student.data?.lastName + ' ' + student.data?.firstName;

  return [
    {label: 'Главная', path: '/'},
    {label: 'Журнал развития', path: '/development_journal'},
    {label: `Навыки.${studentFullName}`, path: null},
  ];
};

const DevelopmentJournalPlayerSkills = () => {
  const {id} = useParams();
  const student = useStudent(id);

  const history = useHistory();
  const statusCode = student.error?.response?.status;

  useEffect(() => {
    if (statusCode === HttpStatusCode.NotFound) {
      history.push('/');
    }
  }, [statusCode]);

  const breadCrumbs = generateBreadCrumbs(student);

  const [{groupId}, _] = useQueryString();
  const {data: categories} = useCategoriesEstimates({groupsIds: [groupId]});
  const studentsIds = [
    ...new Set(
      categories
        ?.map((category) => category?.estimates)
        ?.flat()
        ?.map(({student}) => {
          return student?.id;
        }),
    ),
  ];

  return (
    <Box>
      <PageTitleDefault breadCrumbs={breadCrumbs}>Журнал развития</PageTitleDefault>
      <Stack spacing={4}>
        <Box alignItems="center" display="flex" minWidth="950px">
          <IconButton
            color="primary"
            sx={{p: 2, pl: '0px', minWidth: 24}}
            onClick={() => {
              const activeStudentIndex = studentsIds?.findIndex((el) => el === id);
              if (activeStudentIndex <= 0) {
                history?.push(
                  `/development_journal/player_skills/${studentsIds?.[studentsIds?.length - 1]}?groupId=${groupId}`,
                );
              } else {
                history?.push(
                  `/development_journal/player_skills/${studentsIds?.[activeStudentIndex - 1]}?groupId=${groupId}`,
                );
              }
            }}
          >
            <ChevronLeftIcon sx={{'&:nth-of-type(1)': {fontSize: '24px !important'}}} />
          </IconButton>
          <PlayerInfo
            avatar={student.data?.avatar}
            firstName={student.data?.firstName}
            lastName={student.data.lastName}
            playerColor="#FFFFFF"
            playerVariant="h4"
            sx={{justifyContent: 'center', minWidth: 200}}
          />
          <IconButton
            color="primary"
            sx={{p: 2, minWidth: 24}}
            onClick={() => {
              const activeStudentIndex = studentsIds?.findIndex((el) => el === id);
              if (activeStudentIndex < studentsIds?.length - 1) {
                history?.push(
                  `/development_journal/player_skills/${studentsIds?.[activeStudentIndex + 1]}?groupId=${groupId}`,
                );
              } else {
                history?.push(`/development_journal/player_skills/${studentsIds?.[0]}?groupId=${groupId}`);
              }
            }}
          >
            <ChevronRightIcon sx={{'&:nth-of-type(1)': {fontSize: '24px !important'}}} />
          </IconButton>
        </Box>
        <EstimateList />
      </Stack>
    </Box>
  );
};

export default DevelopmentJournalPlayerSkills;
