import PlaceholderWrapper from '@common/FullHeightWrapper';
import {Wrapper} from '@components/apps/NewBodyMassIndex/BodyMassIndex';
import Table from '@components/apps/NewBodyMassIndex/components/CommonReportEditCreate/components/Table';
import {mock} from '@components/apps/NewBodyMassIndex/components/CommonReportEditCreate/mock';
import {ACTION_TYPES} from '@components/apps/NewBodyMassIndex/constants';
import momentDate from '@helpers/date';
import {useGroup} from '@hooks/filters';
import useDeepEffect from '@hooks/useDeepEffect';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectTeamCommonReport} from '@selectors/bodyMassIndex';
import {clearTeamCommonReport, loadTeamCommonReport} from '@slices/bodyMassIndex';
import {Box, Button, Stack, Typography} from '@ui/MUI';
import DatePickerDefault from '@ui/MUI/DatePicker';
import PageTitleDefault from '@ui/PageTitleDefault';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router';

CommonReportEditCreate.propTypes = {
  type: PropTypes.oneOf(['add', 'edit']).isRequired,
};

function CommonReportEditCreate({type}) {
  const {groupId} = useParams();
  const [query] = useQueryString();
  const history = useHistory();
  const group = useGroup({value: groupId});
  const [date, setDate] = useState(
    type === ACTION_TYPES.add || !query?.date ? momentDate().currentDate : momentDate(Number(query?.date)).innerDate,
  );
  const dispatch = useStoreDispatch();
  const {
    data: {data, currentLength, previousLength},
    isLoading,
    isLoad,
    error,
  } = useStore(selectTeamCommonReport);
  const methods = useForm({reValidateMode: 'onSubmit'});
  const {reset} = methods;

  const placeholderData = useMemo(() => {
    if (isLoad && (!data?.length || error))
      return (
        <Typography data-testid="no-data" variant="subtitle1">
          Данные отсутствуют
        </Typography>
      );

    if (!date) return <Typography variant="subtitle1">Для отображения данных укажите дату отчета</Typography>;
    if (isLoad && type === ACTION_TYPES.add && currentLength)
      return (
        <Stack alignItems="center" spacing={2}>
          <Typography data-testid="has-report-title" variant="subtitle1">
            Для выбранной даты уже есть отчет.
          </Typography>
          <Button
            color="primary"
            data-testid="has-report-btn"
            variant="contained"
            onClick={() => history.push(`/bmi/edit/${groupId}?date=${momentDate(date).valueOf()}`)}
          >
            Редактировать
          </Button>
        </Stack>
      );
    if (isLoad && type === ACTION_TYPES.edit && !currentLength)
      return (
        <Stack alignItems="center" spacing={2}>
          <Typography data-testid="no-report" variant="subtitle1">
            Для данной даты еще нет отчета.
          </Typography>
        </Stack>
      );
    return null;
  }, [currentLength, previousLength, type, isLoad, error]);

  useDeepEffect(() => {
    date && dispatch(loadTeamCommonReport({groupId, date: momentDate(Number(date)).valueOf()}, reset));

    return () => {
      dispatch(clearTeamCommonReport());
    };
  }, [groupId, date]);

  useEffect(() => {
    if (!date)
      setDate((prev) => {
        if (!prev)
          return momentDate(type === ACTION_TYPES.edit ? Number(query?.date) : momentDate().currentDate).innerDate;
      });
  }, [query?.dete, date, type]);

  return (
    <>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Антропометрия', path: `/bmi?groupId=${groupId}`},
          {label: group.value?.name || 'Команда'},
        ]}
        text={type === ACTION_TYPES.add ? 'Новый отчет' : 'Редактирование отчета'}
      />
      <Wrapper mb={0} px={{xxs: 2, sm: 4}} py={{xxs: 1, sm: 3}}>
        <Box maxWidth={{xxs: '100%', xs: 220}}>
          <DatePickerDefault
            data-testid="datepicker"
            disabled={type === ACTION_TYPES.edit}
            inputFormat="DD.MM.YYYY"
            maxDate={momentDate().currentDate}
            value={date}
            variant="standard"
            onChange={(v) => {
              if (momentDate(v).isValid()) {
                return setDate(v);
              } else {
                setDate(null);
              }
            }}
          />
        </Box>
      </Wrapper>
      {placeholderData ? (
        <PlaceholderWrapper
          alignItems="center"
          data-testid="placeholder-wrapper"
          display="flex"
          justifyContent="center"
          px={{xxs: 2, sm: 4}}
          py={{xxs: 1, sm: 3}}
        >
          {placeholderData}
        </PlaceholderWrapper>
      ) : (
        <FormProvider {...methods}>
          <Table
            data={isLoading ? mock : data || []}
            date={momentDate(date).valueOf()}
            isLoading={isLoading}
            type={type}
          />
        </FormProvider>
      )}
    </>
  );
}

export default CommonReportEditCreate;
