import axios from 'axios';

import {API_ENDPOINT, PARSE_APP_ID} from '../constants/config';
import AuthHelper from '../helpers/AuthHelper';

const UsersAPI = {
  forceLogout() {
    window.localStorage.removeItem(`Parse/${PARSE_APP_ID}/currentUser`);
    window.localStorage.removeItem(`Parse/${PARSE_APP_ID}/installationId`);
  },

  getUsersByQueryV2(filters) {
    return axios.get(
      `${API_ENDPOINT}/v1/users`,
      {params: filters},
      {
        failed: (err) => {
          const message = err?.data?.message;
          return {message: message || 'Ошибка при загрузке пользователей'};
        },
      },
    );
  },

  async getTeachersBySchool(schoolId) {
    return await axios.get(`${API_ENDPOINT}/v1/users/teacher/bySchool/${schoolId}`);
  },

  async getUserV2(id) {
    return await axios.get(
      `${API_ENDPOINT}/v1/users/${id}`,
      {},
      {
        failed: {message: 'Ошибка при получении данных'},
      },
    );
  },

  async deleteUser(id) {
    return await axios.delete(`${API_ENDPOINT}/v1/users/${id}`);
  },

  async createUserV2(postData, role) {
    return await axios.post(`${API_ENDPOINT}/v1/users/${role}`, postData);
  },

  async updateUserV2(postData, role, id) {
    return await axios.put(`${API_ENDPOINT}/v1/users/${role}/${id}`, postData);
  },

  createUser(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}/users/create`, data)
        .then((d) => d.data)
        .then((user) => {
          if (user.error !== undefined) {
            return reject(user.error);
          }
          resolve(user);
        })
        .catch((err) => reject(err));
    });
  },

  updateUser(data) {
    return new Promise((resolve, reject) => {
      const token = AuthHelper.getToken() ? AuthHelper.getToken() : '';
      axios.defaults.headers.Authorization = `Bearer ${token}`;
      axios
        .post(`${API_ENDPOINT}/users/update`, data)
        .then((d) => d.data)
        .then((user) => {
          if (user.error !== undefined) {
            return reject(user.error);
          }
          resolve(user);
        })
        .catch((err) => reject(err));
    });
  },

  updateStudent(data) {
    return new Promise((resolve, reject) => {
      const token = AuthHelper.getToken() ? AuthHelper.getToken() : '';
      axios.defaults.headers.Authorization = `Bearer ${token}`;
      axios
        .put(`${API_ENDPOINT}/v1/users/student/${data.id}`, data)
        .then((d) => d.data)
        .then((user) => {
          if (user.error !== undefined) {
            return reject(user.error);
          }
          resolve(user);
        })
        .catch((err) => reject(err));
    });
  },

  changeUserEmail(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}/users/update/email`, data)
        .then((d) => d.data)
        .then((user) => {
          if (user.error !== undefined) {
            return reject(user.error);
          }
          resolve(user);
        })
        .catch((err) => reject(err));
    });
  },

  getUsersByQueryMap(queryMap = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}/users/query`, {
          query: queryMap,
        })
        .then((d) => d.data)
        .then((users) => resolve(users))
        .catch((err) => reject(err));
    });
  },

  getAllUsers() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/users/all`)
        .then((d) => d.data)
        .then((users) => {
          resolve(users);
        })
        .catch((err) => reject(err));
    });
  },

  getAllStudents() {
    return new Promise((resolve, reject) => {
      let queryMap = {
        equalTo: [['userRole', 'student']],
      };
      UsersAPI.getUsersByQueryMap(queryMap)
        .then((users) => {
          resolve(users.sort((a, b) => +b.tiemsatmp - +a.timestamp));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllTeachers() {
    return new Promise((resolve, reject) => {
      let queryMap = {
        equalTo: [['userRole', 'teacher']],
      };
      UsersAPI.getUsersByQueryMap(queryMap)
        .then((users) => {
          resolve(users.sort((a, b) => +b.timestamp - +a.timestamp));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getUserById(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/user/${userId}`)
        .then((d) => d.data)
        .then((user) => {
          if (user.error !== undefined) {
            return reject(user.error);
          }
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getStudentByIdV2(id) {
    return axios.get(`${API_ENDPOINT}/v1/users/student/${id}`);
  },
  getStudentById(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/players/${userId}?include=school,team`)
        .then((d) => d.data)
        .then((student) => {
          if (student.error !== undefined) {
            return reject(student.error);
          }
          resolve(student);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addStudentToArchive(playerId) {
    return axios.patch(
      `${API_ENDPOINT}/v1/users/student/archive/${playerId}`,
      {},
      {
        success: {message: 'Игрок успешно архивирован'},
        failed: (err) => ({message: err.data.message || 'Ошибка при архивации игрока'}),
      },
    );
  },
  removeStudentFromArchive(playerId) {
    return axios.patch(
      `${API_ENDPOINT}/v1/users/student/unarchive/${playerId}`,
      {},
      {
        success: {message: 'Игрок успешно разархивирован'},
        failed: (err) => ({message: err.data.message || 'Ошибка при разархивации игрока'}),
      },
    );
  },
  getAllCitizenships() {
    return axios.get(`${API_ENDPOINT}/citizenships/all`);
  },

  getAllCountries() {
    return axios.get(`${API_ENDPOINT}/countries/all`);
  },

  getAllAmplua() {
    return axios.get(`${API_ENDPOINT}/guides/1`);
  },
  getUserStatistics(filter) {
    if (filter.season) {
      return axios.get(
        `${API_ENDPOINT}/statistics/${filter.id}?timeStart=${filter.startTime}&timeEnd=${filter.endTime}&season=${filter.season}`,
      );
    } else {
      return axios.get(
        `${API_ENDPOINT}/statistics/${filter.id}?timeStart=${filter.startTime}&timeEnd=${filter.endTime}`,
      );
    }
  },
  getStudents(filters) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/players`, {params: filters})
        .then((d) => d.data)
        .then((user) => {
          if (user.error !== undefined) {
            return reject(user.error);
          }
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getStudentsV2(filters, {cancelToken, ...params} = {}) {
    return new Promise((resolve, reject) => {
      if (typeof filters === 'string') {
        axios
          .get(
            `${API_ENDPOINT}/v1/users/student${filters ? '?' + filters : ''}`,
            {params, cancelToken},
            {
              failed: () => ({message: 'Ошибка сервера, попробуйте позже'}),
            },
          )
          .then((d) => d.data)
          .then((user) => {
            if (user.error !== undefined) {
              return [];
            }
            resolve(user);
          })
          .catch((err) => {
            if (err?.code && err.code !== 'ERR_CANCELED') reject(err);
          });
      } else {
        axios
          .get(
            `${API_ENDPOINT}/v1/users/student`,
            {params: filters},
            {
              failed: () => ({message: 'Ошибка сервера, попробуйте позже'}),
            },
          )
          .then((d) => d.data)
          .then((user) => {
            if (user.error !== undefined) {
              return [];
            }
            resolve(user);
          })
          .catch((err) => {
            if (err?.code && err.code !== 'ERR_CANCELED') reject(err);
          });
      }
    });
  },
  getArchiveStudents(filters) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/players/archive`, {params: filters})
        .then((d) => d.data)
        .then((user) => {
          if (user.error !== undefined) {
            return reject(user.error);
          }
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTeachers(filter) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/teachers?${filter}`)
        .then((d) => d.data)
        .then((user) => {
          if (user.error !== undefined) {
            return reject(user.error);
          }
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getPlayerById(playerId) {
    let result = await axios.get(`${API_ENDPOINT}/players/${playerId}`);
    return result.data;
  },

  recoveryPassword(email) {
    return axios.post(`${API_ENDPOINT}/v1/auth/forgot-password`, {email});
  },

  validateToken(token) {
    return axios.get(`${API_ENDPOINT}/v1/auth/validate-token`, {params: {token}});
  },

  resetPassword(password, id) {
    return axios.patch(`${API_ENDPOINT}/v1/auth/change-password`, {
      password,
      id,
    });
  },
};

export default UsersAPI;

export function updateUsersInfo(params) {
  return axios.put(`${API_ENDPOINT}/v1/users/student/many`, params, {
    success: {message: 'Данные успешно обновлены'},
    failed: {message: 'Ошибка при обновление данных пользователей'},
  });
}
