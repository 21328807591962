import {darkRed} from '@ui/MUI/colors';
import TypographyDefault from '@ui/MUI/Typography';
import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  img {
    width: 100%;
    display: block;
  }
`;

export const Map = styled.div`
  width: 100%;
  position: relative;
`;

export const Grid = styled.div`
  padding: 4% 10%;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
`;
export const Cell = styled.div`
  width: calc(100% / 3);
  height: calc(100% / 3);
  padding: ${({theme}) => theme.spacing(0)};
  ${({theme}) => theme.breakpoints.down('xs')} {
    padding: 0;
  }

  &:nth-child(4),
  &:nth-child(6) {
    position: relative;
    z-index: 4;
    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 7px;
      height: 7px;
      background-color: #fff;
    }
    &::before {
      top: -3px;
    }
    &::after {
      bottom: -3px;
    }
  }
  &:nth-child(4) {
    &::before,
    &::after {
      right: -4px;
    }
  }
  &:nth-child(6) {
    &::before,
    &::after {
      left: -4px;
    }
  }
  &:nth-child(5) {
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 300%;
      height: 2px;
      background-color: ${darkRed['100']};
      top: 0;
      left: -100%;
    }
    &::after {
      top: unset;
      bottom: 0;
    }
  }
  &:nth-child(2) {
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: '';
      height: 300%;
      width: 2px;
      background-color: ${darkRed['100']};
      bottom: -200%;
      left: 0;
    }
    &::after {
      left: unset;
      right: 0;
    }
  }
`;

export const Percent = styled(TypographyDefault)`
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  ${({theme}) => theme.breakpoints.down('smallLaptop')} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({theme}) => theme.breakpoints.down('xs')} {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Score = styled(TypographyDefault)`
  ${({theme}) => theme.typography.h4}
  ${({theme}) => theme.breakpoints.down('xs')} {
    font-size: 12px;
    line-height: 16px;
  }
`;
