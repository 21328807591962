import BarChart from '@components/charts/BarChart';
import {Stack, Typography} from '@ui/MUI';
import {green, grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React from 'react';

PlayerProgressChart.propTypes = {
  data: PropTypes.array.isRequired,
};

function PlayerProgressChart({data, colors = [green['15'], green['70'], green['100'], grey['24']]}) {
  const sortedData = data.sort((a, b) => {
    return Number(a.season.split('-')[0]) - Number(b.season.split('-')[0]);
  });
  return (
    <Stack backgroundColor="white.main" borderRadius="6px" mb={3} px={2} py={3} spacing={3}>
      <Typography sx={{mb: 2, ml: 0}} variant="subtitle2">
        {sortedData?.[0]?.name}
      </Typography>
      {sortedData?.map((item, index) => {
        const filteredChartData = [
          {value: item?.stage1 || ''},
          {value: item?.stage2 || ''},
          {value: item?.stage3 || ''},
          {value: item?.avg || ''},
        ].filter((i) => {
          return i?.value !== '';
        });
        const stage1 = item?.stage1 ? '1 этап' : '';
        const stage2 = item?.stage2 ? '2 этап' : '';
        const stage3 = item?.stage3 ? '3 этап' : '';
        const avg = item?.avg ? 'сред...' : '';
        const filteredCategoryData = [{value: stage1}, {value: stage2}, {value: stage3}, {value: avg}]?.filter(
          (i) => i?.value !== '',
        );
        return (
          <Stack key={index}>
            <Typography sx={{mb: 2, ml: 0}} variant="subtitle2">
              {'Сезон ' + item.season}
            </Typography>
            <BarChart
              minHeight={100}
              options={{
                grid: {
                  left: 3,
                  bottom: 0,
                  right: 0,
                  top: 0,
                  containLabel: true,
                },
                xAxis: {
                  max: 'dataMax',
                  show: false,
                  axisLabel: {
                    show: false,
                  },
                  axisTick: {
                    show: false,
                  },
                },
                yAxis: [
                  {
                    type: 'category',
                    inverse: true,
                    show: false,
                    axisLabel: {
                      show: false,
                    },
                    axisTick: {
                      show: false,
                    },
                  },
                  {
                    type: 'category',
                    data: filteredCategoryData,
                    inverse: true,
                    axisLabel: {
                      show: true,
                      fontFamily: 'Proxima Nova',
                      fontWeight: 600,
                      fontSize: '12px',
                    },
                    axisLine: {
                      show: false,
                    },
                    axisTick: {
                      show: false,
                    },
                  },
                ],
                series: [
                  {
                    colorBy: 'data',
                    type: 'bar',
                    data: filteredChartData,
                    label: {
                      show: true,
                      fontFamily: 'Proxima Nova',
                      fontSize: 14,
                    },
                    barCategoryGap: '0%',
                  },
                ],
                color: colors,
              }}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}

export default PlayerProgressChart;
