import AddIcon from '@mui/icons-material/Add';
import {Button} from '@mui/material';
import {userRoles} from '@src/constants';
import Pagination from '@ui/MUI/Pagination';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import GroupsAPI from '../../../api/GroupsAPI';
import LocalPreloader from '../../preloader/LocalPreloader';
import DefaultSortTable from '../../ui/MUI/Table/Table';
import CreateModal from '../components/group/modals/CreateModal';

export default function GroupsPanel() {
  const history = useHistory();

  const userRole = useSelector((state) => state.users.currentUser?.userRole);

  const [addModalState, setAddModalState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleAddModal = (anchor, open) => {
    setAddModalState({...addModalState, [anchor]: open});
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  const [selectedCount, setSelectedCount] = useState(20);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState({result: [], total: 0});

  const getGroupsList = async () => {
    setIsLoading(true);
    const groups = await GroupsAPI.getAllGroupsV2({limit: selectedCount, page: selectedPage});
    setGroups({result: groups.data.result, total: groups.data.total});
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedPage) {
      getGroupsList();
    }
  }, [selectedPage]);

  let pagesCount = 1;
  const groupsCount = groups.total;
  if (groupsCount > 0) {
    pagesCount = Math.ceil(groupsCount / selectedCount);
  }
  const onPaginationChange = (event, value) => {
    setSelectedPage(value);
  };

  const formatGroupsForRows = (groups) => {
    if (groups.length > 0) {
      return groups.map((group) => {
        return {
          id: group.id,
          name: group.name,
          school: group.school?.name,
          studentsCount: group.studentsCount,
          ageGroup: group.ageGroup,
        };
      });
    }
  };

  return (
    <Wrapper>
      <PageTitleDefault text={'Команды'} />
      <TableWrapper loading={isLoading}>
        <LocalPreloader visible={isLoading} />
        <TableWrapperHeadline>
          <TableWrapperHeadlineTitle>Команды академии</TableWrapperHeadlineTitle>
          {![userRoles.video_analyst, userRoles.teacher].includes(userRole) && (
            <Button
              disableElevation
              startIcon={<AddIcon />}
              text="Добавить команду"
              variant="contained"
              onClick={() => {
                toggleAddModal('right', true);
              }}
            >
              Добавить команду
            </Button>
          )}
        </TableWrapperHeadline>
        <DefaultSortTable
          noScrollX
          columns={[
            {
              key: 'name',
              field: 'name',
              headerName: 'Команда',
              minWidth: 270,
              align: 'left',
              headerAlign: 'left',
              renderCell: (params) => {
                return params.value || '-';
              },
            },
            {
              key: 'school',
              field: 'school',
              headerName: 'Школа',
              minWidth: 270,
              align: 'left',
              headerAlign: 'left',
              renderCell: (params) => {
                return params.value || '-';
              },
            },
            {
              key: 'studentsCount',
              field: 'studentsCount',
              headerName: 'Кол-во игроков',
              minWidth: 270,
              align: 'center',
              headerAlign: 'center',
              renderCell: (params) => {
                return params.value || 0;
              },
            },
            {
              key: 'ageGroup',
              field: 'ageGroup',
              headerName: 'Возрастная группа',
              minWidth: 270,
              align: 'center',
              headerAlign: 'center',
              renderCell: (params) => {
                return params.value || '-';
              },
            },
          ]}
          defaultSortModel={[
            {
              field: 'name',
              sort: 'asc',
            },
          ]}
          hasMaxHeight={false}
          rows={formatGroupsForRows(groups.result)}
          onRowClick={(params) => {
            history.push(`/teams/${params.id}`);
          }}
        />
        <CreateModal
          getGroupsList={getGroupsList}
          modalState={addModalState}
          setModalState={setAddModalState}
          toggleModal={toggleAddModal}
        />
        {pagesCount > 1 && <Pagination count={pagesCount} page={selectedPage} onChange={onPaginationChange} />}
      </TableWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
const TableWrapper = styled.div`
  position: relative;
  height: ${(props) => (props.loading ? '80vh' : 'auto')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
  padding-top: 18px;
  padding-bottom: 8px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  .MuiDataGrid {
    &-columnSeparator {
      display: none !important;
    }
    &-root {
      border-top: 0;
      border-bottom: 0;
      .MuiDataGrid {
        &-columnHeader {
          border-top: 0;
          border-bottom: 0;
        }
        &-columnHeaderTitle {
          font: 600 normal 14px/47px 'Proxima Nova';
          color: rgba(0, 0, 0, 0.87);
          letter-spacing: 0.17px;
        }
        &-cell {
          font: 400 normal 14px/47px 'Proxima Nova';
          color: rgba(0, 0, 0, 0.87);
          border-bottom: 0;
          &:first-child {
            font-weight: 600;
          }
        }
      }
    }
    &-virtualScrollerContent {
      .MuiDataGrid-row:first-child {
        .MuiDataGrid-cell:first-child {
          border-top: 0;
        }
      }
    }
    &-cell {
      &:first-child {
        margin-top: 0;
      }
    }
    &-row {
      cursor: pointer;
    }
  }
`;
const TableWrapperHeadline = styled.div`
  padding: 0 12px 20px;
  display: flex;
  justify-content: space-between;

  .MuiButton {
    &-root {
      svg {
        path {
          fill: white;
        }
      }
    }
  }
`;
const TableWrapperHeadlineTitle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: 600 normal 18px/28px 'Proxima Nova';
  color: black;
`;
