import AddButton from '@common/AddButton';
import Form from '@common/Form';
import {useCategory, useSection} from '@components/apps/TrainingControlProcess/hooks/api';
import {PageContentWrapper} from '@components/apps/TrainingControlProcess/TrainingControlProcess';
import {useRoleAccess} from '@hooks/useRoleAccess';
import {userRoles} from '@src/constants';
import Stack from '@ui/MUI/Stack';
import React from 'react';
import {FormProvider} from 'react-hook-form';

import LoadingHolder from '../../../LoadingHolder';
import Accordions from './components/Accordions';
import FormContent from './components/FormContent';
import {useCategoryForm, useSectionForm} from './hooks';

const SkillsCategoryTab = () => {
  const section = useSection();
  const category = useCategory();
  const categoryForm = useCategoryForm();
  const sectionForm = useSectionForm();

  const isLoading = section.isLoading || section.form.isLoading || category.form.isLoading || category.isLoading;

  const canEdit = useRoleAccess([userRoles?.teacher, userRoles?.methodist]);

  return (
    <PageContentWrapper>
      <FormProvider {...sectionForm.methods}>
        <Form
          content={<FormContent name="sections" />}
          form={sectionForm.form}
          name="section"
          title="Добавление раздела"
          onSubmit={sectionForm.handleSubmit}
        />
      </FormProvider>
      <FormProvider {...categoryForm.methods}>
        <Form
          content={<FormContent name="categories" />}
          form={categoryForm.form}
          name="category"
          title="Добавление категории"
          onSubmit={categoryForm.handleSubmit}
        />
      </FormProvider>
      {isLoading ? (
        <LoadingHolder />
      ) : (
        <Stack direction="row" flexWrap="wrap" gap="8px 32px">
          {section.data.length !== 0 && <Accordions data={section.data} open={categoryForm.form.open} />}
          {canEdit && (
            <Stack>
              <AddButton onClick={sectionForm.form.open}>добавить раздел</AddButton>
            </Stack>
          )}
        </Stack>
      )}
    </PageContentWrapper>
  );
};

export default SkillsCategoryTab;
