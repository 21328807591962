import Dialog from 'rc-dialog';
import React from 'react';
import styled from 'styled-components';

export default function ReactComponentModal(props) {
  const {title = '', onClose = () => {}, visible = false, className = '', content = '', buttons = ''} = props;

  return (
    <Dialog
      className={className ? className : null}
      title={title ? title : null}
      visible={visible}
      onClose={() => {
        onClose();
      }}
    >
      <DialogContentWrapper>
        <DialogContent className="DialogContent">
          {content}
          <DialogButtons className="DialogButtons">{buttons}</DialogButtons>
        </DialogContent>
      </DialogContentWrapper>
    </Dialog>
  );
}

const DialogContentWrapper = styled.div``;
const DialogContent = styled.div`
  p:not(.ErrorText) {
    margin-top: 0;
    margin-bottom: 0;
    font: normal normal 18px/24px 'Proxima Nova';
    color: #2c2c2c;
    letter-spacing: -0.02em;
  }
`;
const DialogButtons = styled.div`
  margin-top: 10px;
  position: absolute;
  bottom: 20px;
  left: 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
  .DialogButton {
    &:not(:last-child) {
      @media screen and (max-width: 480px) {
        margin-bottom: 8px;
      }
    }
  }
`;
