import IconButtonGroup from '@common/IconButtonGroup';
import SkillItem from '@common/TrainingControl/SkillItem';
import {useRoleAccess} from '@hooks/useRoleAccess';
import {userRoles} from '@src/constants';
import Stack from '@ui/MUI/Stack';
import React from 'react';

const SkillList = ({items = [], onEdit, onDelete}) => {
  const canEdit = useRoleAccess([userRoles?.teacher, userRoles?.methodist]);
  return (
    <Stack spacing={1}>
      {items.map((skill) => (
        <Stack direction="row" key={skill.id} spacing={4}>
          <SkillItem {...skill} showInfo flex="1" />
          {canEdit && <IconButtonGroup onDelete={() => onDelete && onDelete(skill.id)} onEdit={() => onEdit(skill)} />}
        </Stack>
      ))}
    </Stack>
  );
};

export default SkillList;
