import SiriusCompare from '@components/apps/PhysicalTesting/components/SiriusCompare';
import {
  CompareStages,
  CreateEditPhysicalTesting,
  OverallReport,
  PlayerProgress,
} from '@components/apps/PhysicalTesting/index';
import {darkRed, green, grey, purple, yellow} from '@ui/MUI/colors';
import React from 'react';

const allTabs = {
  overall_report: {
    link: 'overall_report',
    label: 'Общий отчёт',
    value: <OverallReport />,
  },
  compare_stages: {
    link: 'compare_stages',
    label: 'Сравнение этапов',
    value: <CompareStages />,
  },
  player_progress: {
    link: 'player_progress',
    label: 'Прогресс игрока',
    value: <PlayerProgress />,
  },
  sirius: {
    link: 'sirius',
    label: 'Сравнение с эталоном',
    value: <SiriusCompare />,
  },
  add: {
    link: 'add',
    label: 'Добавление данных',
    value: <CreateEditPhysicalTesting key="4" />,
  },
};

export const defaultTabs = [allTabs.overall_report, allTabs.player_progress, allTabs.compare_stages, allTabs.sirius];
export const methodistTabs = [...defaultTabs, allTabs?.add];

export const playerTabs = [allTabs.overall_report, {...allTabs.player_progress, label: 'Мой прогресс'}];

export const parentTabs = [allTabs.overall_report, allTabs.player_progress];

export const vectors = {
  equal: {
    value: 0,
    color: '#757575',
  },
  bigger: {
    value: 1,
    color: '#05A800',
  },
  less: {
    value: -1,
    color: '#A80000',
  },
};

export const numberSign = {
  negative: '#A80000',
  positive: '#05A800',
  zero: '#00000099',
};

export const typesOfTest = {
  ofp: 'ofp',
  sfp: 'sfp',
};

export const siriusOptions = [
  {
    label: 'U15',
    value: 'U15',
  },
  {
    label: 'U16',
    value: 'U16',
  },
  {
    label: 'U17',
    value: 'U17',
  },
  {
    label: 'U18',
    value: 'U18',
  },
];

export const typeOfTestsOptions = [
  {value: 'ofp', label: 'ОФП'},
  {value: 'sfp', label: 'СФП'},
];

export const stageOptions = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
];

export const ofpTestsExercisesOrdersMatrix = {
  cM8_RO8XeFo_: 1,
  'ICv0HbxEN8Y-': 2,
  YPVZqpzfcI: 3,
  Xx9OYrarH5: 4,
  '-JuGyneCYBL1': 5,
  i6ss9z3iip: 6,
  uNcHvpkPFg4t: 7,
  ncEDMKZVMM: 8,
  ice6a13iur: 9,
  'V0syxg-0GTJt': 10,
  ft6g9B2yLv03: 11,
  ip84hl3ivl: 12,
  cc_yDyptaY6b: 13,
  wMhqP7HzsSgC: 14,
  '22O83kcCgWIE': 15,
  MS2_YS08eCPP: 16,
  'u7F-wrG6GD6h': 17,
  iRM8gLDomawq: 18,
  cLt2smcRpGA8: 19,
};

export const sfpTestsExercisesOrdersMatrix = {
  '7Gm7MTdkfsYa': 1,
  'AFcqyTRvguE-': 2,
  _MDdw9tjWKh1: 3,
  '54tGNLW9J7jV': 4,
  eDjMWZEDA0Sb: 5,
  '4BZYbd168KaK': 6,
  mI1idaZOiABd: 7,
  afPSPXMFcpH1: 8,
  LGMFVmqjjd_l: 9,
  AYq86kLnrMDh: 10,
  Kf3AdBTfKHZI: 11,
  jwlBqthsXffG: 12,
  ypjjV_ydGQrE: 13,
  d6f5LbadXcWk: 14,
  pE5xHMcCzju5: 15,
};

export const exercisesForMobileTabs = {
  ofp: [
    {
      name: 'Бег',
      exerciseIds: ['i6ss9z3iip', 'uNcHvpkPFg4t', 'ncEDMKZVMM', 'cc_yDyptaY6b', 'ip84hl3ivl'],
      colors: [green['15'], green['70'], green['100'], grey['24']],
    },
    {
      name: 'Прыжки',
      exerciseIds: ['YPVZqpzfcI', 'Xx9OYrarH5', '-JuGyneCYBL1'],
      colors: [yellow['15'], yellow['60'], yellow['100'], grey['24']],
    },
    {
      name: 'Силовые',
      exerciseIds: [
        'cM8_RO8XeFo_',
        'ICv0HbxEN8Y-',
        'V0syxg-0GTJt',
        'ft6g9B2yLv03',
        'MS2_YS08eCPP',
        'u7F-wrG6GD6h',
        'iRM8gLDomawq',
        'cLt2smcRpGA8',
      ],
      colors: [purple['15'], purple['50'], purple['100'], grey['24']],
    },
    {
      name: 'Координация',
      exerciseIds: ['ice6a13iur', 'wMhqP7HzsSgC', '22O83kcCgWIE'],
      colors: [darkRed['20'], darkRed['65'], darkRed['100'], grey['24']],
    },
  ],
  sfp: [
    {name: 'Спринт', exerciseIds: ['7Gm7MTdkfsYa', 'AFcqyTRvguE-']},
    {name: 'Спиной вперед', exerciseIds: ['_MDdw9tjWKh1', '54tGNLW9J7jV']},
    {name: 'Реакция', exerciseIds: ['eDjMWZEDA0Sb', '4BZYbd168KaK']},
    {name: 'Слалом', exerciseIds: ['mI1idaZOiABd', 'afPSPXMFcpH1', 'pE5xHMcCzju5']},
    {name: 'Переходы', exerciseIds: ['LGMFVmqjjd_l', 'AYq86kLnrMDh']},
    {name: 'Точность', exerciseIds: ['ypjjV_ydGQrE', 'd6f5LbadXcWk', 'Kf3AdBTfKHZI', 'jwlBqthsXffG']},
  ],
};
