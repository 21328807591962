import PlayerPage from '@common/BodyMassIndex/PlayerPage';
import useStore from '@hooks/useStore';
import {userRoles} from '@src/constants';
import TabsNew from '@ui/MUI/TabsNew';
import React from 'react';

import {TABS} from './constants';

const BodyMassIndex = () => {
  const userRole = useStore((state) => state?.users?.currentUser?.userRole);
  return userRole === userRoles.student ? (
    <PlayerPage />
  ) : (
    <TabsNew withoutWrapper tabs={TABS[userRole] || TABS.default} />
  );
};

export default BodyMassIndex;
