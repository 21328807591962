import {PageWrapper} from '@common/Page';
import StarIcon from '@mui/icons-material/Star';
import {useMediaQuery} from '@mui/material';
import {getCategoriesMock, getPersonalDevelopmentJournalPlayerSkillsMock} from '@src/mocks/api/skill/responseMock';
import {Box, Divider, Tooltip, Typography} from '@ui/MUI';
import React from 'react';

function InfoBlock({categories, percentForGroup, isLoading}) {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const percentOfSuccess = !isLoading
    ? percentForGroup
    : getPersonalDevelopmentJournalPlayerSkillsMock?.percentForGroup;
  return (
    <PageWrapper maxHeight={!isMd && '224px'} width="100%">
      <Typography data-testid="title" mb={4} variant="h3">
        Количество и процент успешности проработанных навыков по группам
      </Typography>
      <Box display="flex" flexDirection="column" flexWrap="wrap" mx={-4}>
        {(!isLoading ? categories?.data : getCategoriesMock)?.map((category) => {
          return (
            <Box
              data-testid="category"
              flex={!isMd ? '0 0 33.333%' : 'initial'}
              key={category.id}
              maxHeight="50px"
              maxWidth={!isMd ? '33.333%' : 'none'}
              position="relative"
              px={4}
              sx={{
                '&:nth-child(3n)': {
                  '& > .MuiDivider-root': {maxHeight: 28},
                },
              }}
            >
              <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                <Typography
                  flex="1 0 0"
                  isLoading={isLoading}
                  position="relative"
                  preloaderWidthBetween={[20, 30]}
                  variant="subtitle1"
                >
                  {category.name}{' '}
                  {percentOfSuccess?.[category.name]?.percent === 100 && (
                    <Tooltip arrow={false} sx={{}} title="Лучший в освоении навык">
                      <StarIcon
                        color="primary"
                        data-testid="star"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          pl: 1,
                          width: '28px',
                          height: '28px',
                        }}
                      />
                    </Tooltip>
                  )}
                </Typography>
                {percentOfSuccess?.[category.name]?.percent > 0 ? (
                  <Typography
                    data-testid="percent"
                    isLoading={isLoading}
                    preloaderWidthBetween={[20, 30]}
                    px={3}
                    textAlign="right"
                    variant="body1"
                  >
                    {percentOfSuccess?.[category.name]?.percent}%
                  </Typography>
                ) : null}
                <Typography
                  isLoading={isLoading}
                  preloaderWidthBetween={[70, 100]}
                  textAlign="right"
                  variant="subtitle1"
                >
                  {percentOfSuccess?.[category.name]?.all || 0}
                </Typography>
              </Box>
              {!isMd && <Divider orientation="vertical" sx={{position: 'absolute', top: 0, right: -16}} />}
            </Box>
          );
        })}
      </Box>
    </PageWrapper>
  );
}

export default InfoBlock;
