import React from 'react';

function NotificationClose() {
  return (
    <React.Fragment>
      <g>
        <path
          clipRule="evenodd"
          d="M0.37028 0.66066C0.209907 0.821023 0.209907 1.08104 0.37028 1.24141L3.12887 4L0.37028 6.7586C0.209907 6.91894 0.209907 7.17893 0.37028 7.33939L0.660661 7.62974C0.821034 7.79009 1.08104 7.79009 1.24141 7.62974L4.00002 4.87114L6.75861 7.62974C6.91903 7.79009 7.17903 7.79009 7.33938 7.62974L7.62973 7.33939C7.79009 7.17893 7.79009 6.91894 7.62973 6.7586L4.87115 4L7.62973 1.24141C7.79009 1.08104 7.79009 0.821023 7.62973 0.66066L7.33938 0.37028C7.17903 0.209907 6.91903 0.209907 6.75861 0.37028L4.00002 3.12887L1.24141 0.37028C1.08104 0.209907 0.821034 0.209907 0.660661 0.37028L0.37028 0.66066Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
    </React.Fragment>
  );
}
export default NotificationClose;
