import styled from 'styled-components';

import {Button} from '../../../ui/Button';

export const EstimationTable = styled.table`
  background-color: white;
  border-radius: 6px;
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
export const EstimationTableColumnPlayerContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .LinesAvailablePlayerAvatar {
    margin-left: 0;
  }
  .LinesAvailablePlayerAvatar {
    width: 38px;
    height: 38px;
  }
`;
export const EstimationTableGoaltenders = styled(EstimationTable)`
  ${EstimationTableColumnPlayerContainer} {
    justify-content: center;
  }
  .LinesAvailablePlayerName {
    text-align: left;
  }
`;
export const EstimationTableRow = styled.tr`
  border-bottom: ${(props) => (!props.isCommentsShowed ? '1px' : '0px')} solid #dddad4;
`;
export const StudentEstimationTableHeadColumn = styled.th`
  padding: 7px 14px;
  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
  box-sizing: border-box;
  font: normal normal 14px/32px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
`;
export const EstimationTableHeadColumn = styled(StudentEstimationTableHeadColumn)`
  &:first-child {
    text-align: left;
  }
`;
export const EstimationTableHead = styled.thead`
  ${EstimationTableRow} {
    &:last-child {
      background-color: white;
      box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;
      ${EstimationTableHeadColumn} {
        padding-top: 8px;
        padding-bottom: 8px;
        height: 32px;
        line-height: 1;
      }
    }
    &:first-child {
      background-color: #fffaed;
      box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.3);
      ${EstimationTableHeadColumn} {
        font: bold normal 16px/42px 'Bebas Neue';
        color: #2c2c2c;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        &:first-child {
          font-size: 20px;
          text-align: left;
        }
      }
    }
  }
`;
export const EstimationTableBody = styled.tbody``;
export const EstimationTableHeadColumnWithColSpanTripple = styled(EstimationTableHeadColumn).attrs({
  colSpan: 3,
})``;
export const EstimationTableHeadColumnWithColSpanDefault = styled(EstimationTableHeadColumn).attrs({
  colSpan: 2,
})``;
export const EstimationTableColumn = styled.td`
  text-align: ${(props) => props.align};
  padding: 12px 14px;
  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
  & > ${Button} {
    background-color: transparent;
    border-radius: 0;
    border: none;
  }
  &:last-child {
    text-align: center;
  }
`;
export const EstimationTableComments = styled.tr`
  display: ${(props) => (props.isCommentsShowed ? 'table-row' : 'none')};
  border-bottom: 1px solid #dddad4;
`;
export const EstimationTableCommentsColumn = styled.td`
  padding: 0 24px 8px;
  font: 600 normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 12px 24px;
    text-align: left;
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      margin-bottom: 18px;
    }
  }
`;

export const CommentTitle = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  margin-bottom: 18px;
`;
export const CommentText = styled(CommentTitle)`
  font-weight: 400;
  margin-bottom: 0 !important;
`;

export const ColumnValueWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
export const ColumnValue = styled.p`
  margin: 0;
  width: 46px;
  height: 46px;
  border-radius: 2px;
  font: 600 normal 14px/46px 'Proxima Nova';
  color: #2c2c2c;
  text-align: center;
  letter-spacing: -0.02em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.WithoutEstimation {
    background-color: #ccc;
  }
  &.LowEstimation {
    background-color: #e0b2b7;
  }
  &.MiddleEstimation {
    background-color: #f5dcb2;
  }
  &.HighEstimation {
    background-color: #cddbcc;
  }
  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;
export const TableScrollWrapper = styled.div`
  max-width: 100%;
  overflow-x: scroll;
`;
export const TableContainer = styled.div`
  min-width: 600px;
  width: 100%;
`;
export const SkillName = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: 600 normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
`;
