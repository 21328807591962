import React from 'react';

function HockeyStick() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M31.9416 6.10352e-05L27.1939 8.11637L23.8265 13.9554L20.7756 19.2426L17.1382 25.5481L17.0429 25.7124L13.6469 28.1456C13.4472 28.173 13.2444 28.1874 13.0402 28.1874H1.16511C0.55323 28.1874 0.0581055 27.6805 0.0581055 27.0549V23.8435C0.0581055 23.2192 0.55323 22.7124 1.16511 22.7124H12.3539C14.198 22.7124 15.9046 21.7143 16.842 20.0887L18.2549 17.6405L22.0861 10.9991L25.4535 5.16262L26.9852 2.507L31.9416 6.10352e-05Z"
          fill="#D53D33"
        />
        <path
          d="M17.1382 25.5479L17.0429 25.7122L13.6469 28.1454C13.4472 28.1728 13.2444 28.1872 13.0402 28.1872H11.8784C11.5868 27.9262 11.4224 27.6301 11.4224 27.317C11.4224 26.2628 13.2841 25.4089 15.5804 25.4089C16.131 25.4089 16.6567 25.4579 17.1382 25.5479Z"
          fill="#990011"
        />
        <path
          d="M21.7898 27.6013V30.0919C21.7898 31.1454 19.9286 32 17.6322 32C17.3486 32 17.0714 31.9869 16.8032 31.9622C14.9043 31.7854 13.4751 31.015 13.4751 30.0919V27.6013H21.7898Z"
          fill="#455256"
        />
        <path
          d="M16.8032 27.6013V31.9622C14.9043 31.7854 13.4751 31.015 13.4751 30.0919V27.6013H16.8032Z"
          fill="#333F42"
        />
        <path
          d="M17.6326 29.5096C19.9287 29.5096 21.7901 28.6554 21.7901 27.6016C21.7901 26.5478 19.9287 25.6935 17.6326 25.6935C15.3365 25.6935 13.4751 26.5478 13.4751 27.6016C13.4751 28.6554 15.3365 29.5096 17.6326 29.5096Z"
          fill="#616D70"
        />
        <path d="M11.1531 22.7119L10.4841 28.1873H3.45471L2.78564 22.7119" fill="#EAEAE4" />
        <path d="M4.02854 28.1874H3.04216L2.35791 22.7124H3.3436L4.02854 28.1874Z" fill="#D6D6D2" />
        <path d="M6.4841 22.7124L5.81479 28.1874H4.8291L5.49841 22.7124H6.4841Z" fill="#D6D6D2" />
        <path
          d="M31.9414 0L27.1942 8.11606L23.8266 13.9551L20.7756 19.2423L18.2549 17.6402L22.0864 10.9987L25.4533 5.16231L28.396 0.060625L31.9414 0Z"
          fill="#EFBC4B"
        />
        <path d="M9.10939 28.1874H8.1237L7.45508 22.7124H8.44077L9.10939 28.1874Z" fill="#D6D6D2" />
        <path d="M11.6455 22.7124L10.9768 28.1874H9.99121L10.6598 22.7124H11.6455Z" fill="#D6D6D2" />
      </g>
    </React.Fragment>
  );
}
export default HockeyStick;
