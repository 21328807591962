import styled from 'styled-components';

export const PageBox = styled.div`
  background: #ffffff;
  border-radius: 6px;
`;

export const PageContentWrapper = styled.div`
  padding: ${(props) => props.theme.spacing(props.p || 4)};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 24px;
  background-color: #fff;
  gap: 24px;
  border-radius: 6px;
  margin-bottom: ${(props) => props.mb && props.theme.spacing(props.mb)};
`;

export const FilterWrapper = styled.div`
  min-width: ${(props) => props.minWidth};
  ${({theme}) => theme.breakpoints.down('sm')} {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
