import React from 'react';
import {useController} from 'react-hook-form';

const ChildrenField = ({
  name,
  children,
  defaultValue,
  isRequired = false,
  rules = {},
  maxLength,
  minLength,
  validate,
  shouldUnregister = false,
  required,
  onChange: updateField,
}) => {
  const childrenAsArray = Array.isArray(children) ? children : [children];

  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({
    name: name,
    defaultValue,
    shouldUnregister,
    rules: {
      ...rules,
      required: isRequired ? {value: isRequired, message: 'Обязательное поле'} : required,
      maxLength,
      validate,
      minLength,
    },
  });

  const handleChange = (e, newValue) => {
    if (newValue) {
      onChange(newValue);
    } else {
      onChange(e?.target?.value || e);
    }

    updateField && updateField(e, newValue);
  };

  return typeof children === 'function'
    ? children({value, onChange})
    : childrenAsArray.map(
        (children, index) =>
          React.cloneElement(children, {
            onChange: handleChange,
            value,
            key: index,
            error: !!error,
            helperText: error?.message,
          }), // Может использовать контекст в этом случае? (key еще возможно лучше генерить)
      );
};

export default ChildrenField;
