export const mock = [
  {
    ageGroup: 'U22',
    typeOfBody: {
      name: 'Астеник',
      value: 'asthenic',
    },
    fatProcent: {
      firstHalf: [null, null],
      secondHalf: [null, null],
    },
    bmi: {
      firstHalf: [null, null],
      secondHalf: [null, null],
    },
  },
  {
    ageGroup: 'U22',
    typeOfBody: {
      name: 'Нормостеник',
      value: 'normosthenic',
    },
    fatProcent: {
      firstHalf: [null, null],
      secondHalf: [null, null],
    },
    bmi: {
      firstHalf: [null, null],
      secondHalf: [null, null],
    },
  },
  {
    ageGroup: 'U22',
    typeOfBody: {
      name: 'Гиперстеник',
      value: 'hypersthenic',
    },
    fatProcent: {
      firstHalf: [null, null],
      secondHalf: [null, null],
    },
    bmi: {
      firstHalf: [null, null],
      secondHalf: [null, null],
    },
  },
];
