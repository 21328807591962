import styled from 'styled-components';

export const Image = styled.img`
  max-height: 400px;
  max-width: 400px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 437px;
  @media (max-width: 1100px) {
    height: 300px;
  }
  @media (max-width: 800px) {
    width: 250px;
    height: 250px;
  }
`;
