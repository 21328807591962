import orderBy from 'lodash/orderBy';

import MedicalProfileAPI from '../../api/MedicalProfileAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

const errorMessage = (err) => {
  let errorText = 'Ошибка при загрузке';
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
      }
    }
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

export function handleChangeForm(body) {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_CHANGE_FORM,
      medicalProfileForm: body,
    });
  };
}
export function cleanMedicalProfileForm(value) {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_CLEAN_FORM,
      medicalProfileBoard: value,
    });
  };
}
export function handleMedicalProfileBoard(board) {
  const sortedCards = board.columns.map((column) =>
    column.value === 'recovered' ? {...column, cards: orderBy(column.cards, 'timestamp', 'desc')} : column,
  );
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_CHANGE_BOARD,
      medicalProfileBoard: {...board, columns: sortedCards},
    });
  };
}
export function handleChangeMedicalProfileRecommendation(recommendation) {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_CHANGE_RECOMMENDATION,
      medicalProfileRecommendation: recommendation,
    });
  };
}

export function postPlayerMedicalProfileForm(medicalProfileForm, resetFormAfterUpdate = true) {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_LOADING_START,
    });
    return MedicalProfileAPI.postMedicalProfileCard(medicalProfileForm)
      .then((data) => {
        if (resetFormAfterUpdate) {
          dispatch({
            type: types.MEDICAL_PROFILE_LOADING_FORM,
            medicalProfileForm: {},
          });
        }
        return data;
      })
      .catch(function (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message.length) {
              if (typeof err.response.data.message === 'string') {
                ReactComponentNotification(
                  NOTIFICATION_TYPES['error'],
                  `Ошибка при загрузке: ${err.response.data.message}`,
                );
              } else {
                ReactComponentNotification(
                  NOTIFICATION_TYPES['error'],
                  `Ошибка при загрузке: ${Object.values(err.response.data.message[0])[0]}`,
                );
              }
            }
          }
        } else {
          ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
        }
        dispatch({
          type: types.MEDICAL_PROFILE_LOADING_ERROR,
        });
      });
  };
}
export function getMedicalProfileStages() {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_LOADING_START,
    });
    return MedicalProfileAPI.getMedicalProfileStages()
      .then((data) => {
        dispatch({
          type: types.MEDICAL_PROFILE_LOADING_STAGES,
          medicalProfileStages: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.MEDICAL_PROFILE_LOADING_ERROR,
        });
      });
  };
}
export function getMedicalProfileUserData(filter) {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_LOADING_START,
    });
    return MedicalProfileAPI.getMedicalProfileUserData(filter)
      .then((data) => {
        dispatch({
          type: types.MEDICAL_PROFILE_LOADING_USER_DATA,
          medicalProfileUserData: data.data.data,
          medicalProfileFiltersPlayers: data.data.players,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.MEDICAL_PROFILE_LOADING_ERROR,
        });
      });
  };
}
export function cleanMedicalProfileUserData() {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_LOADING_USER_DATA,
      medicalProfileUserData: [],
    });
  };
}
export function getMedicalProfileHistory(playerId, cardId) {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_LOADING_START,
    });
    return MedicalProfileAPI.getCardHistory(playerId, cardId)
      .then((data) => {
        dispatch({
          type: types.MEDICAL_PROFILE_HISTORY_LOADING,
          cardHistory: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.MEDICAL_PROFILE_LOADING_ERROR,
        });
      });
  };
}

export function cleanMedicalProfileHistory() {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_HISTORY_LOADING,
      cardHistory: [],
    });
  };
}

export function postPlayerBMIForm(BMIForm) {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_LOADING_START,
    });
    return MedicalProfileAPI.postPlayerBMIForm(BMIForm)
      .then(() => {
        dispatch({
          type: types.BMI_LOADING_FORM,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.MEDICAL_PROFILE_LOADING_ERROR,
        });
      });
  };
}
export function getPlayerBMIReport(filter) {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_LOADING_START,
    });
    return MedicalProfileAPI.getPlayerBMIReport(filter)
      .then((data) => {
        dispatch({
          type: types.BMI_LOADING_REPORT,
          BMIReport: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.MEDICAL_PROFILE_LOADING_ERROR,
        });
      });
  };
}

export function cleanPlayerBMIReport() {
  return (dispatch) => {
    dispatch({
      type: types.BMI_LOADING_REPORT,
      BMIReport: [],
    });
  };
}

export function BMIFormChange(newForm) {
  return (dispatch) => {
    dispatch({
      type: types.BMI_FORM_CHANGE,
      BMIReportForm: newForm,
    });
  };
}

export function getNutritionByTeam(filter) {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_LOADING_START,
    });
    return MedicalProfileAPI.getNutritionRecommendationsByTeam(filter)
      .then((data) => {
        dispatch({
          type: types.TEAM_NUTRITION_LOADING,
          teamNutrition: data.data,
        });
      })
      .catch(function () {
        dispatch({
          type: types.MEDICAL_PROFILE_LOADING_ERROR,
        });
      });
  };
}
export function postTeamBMIForm(BMIForm) {
  return (dispatch) => {
    dispatch({
      type: types.MEDICAL_PROFILE_LOADING_START,
    });
    return MedicalProfileAPI.postTeamBMIForm(BMIForm)
      .then(() => {
        dispatch({
          type: types.BMI_LOADING_FORM,
        });
        ReactComponentNotification(NOTIFICATION_TYPES['success'], `Оценка сохранена`);
      })
      .catch(function (error) {
        dispatch({
          type: types.MEDICAL_PROFILE_LOADING_ERROR,
        });
        ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка сервера: ${error}`);
      });
  };
}

export function savePlayerMedicalProfileRecommendation(cardId, medicalProfileRecommendation) {
  return (dispatch) => {
    return MedicalProfileAPI.saveMedicalProfileRecommendation(cardId, medicalProfileRecommendation)
      .then(() => {
        dispatch({
          type: types.MEDICAL_PROFILE_SAVE_RECOMMENDATION,
          medicalProfileRecommendation: {
            comment: '',
            taskGroups: [],
          },
        });
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Карточка успешно сохранена');
      })
      .catch(function (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message.length) {
              ReactComponentNotification(
                NOTIFICATION_TYPES['error'],
                `Ошибка при загрузке: ${Object.values(err.response.data.message[0])[0]}`,
              );
            }
          }
        } else {
          ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
        }
      });
  };
}

export function getAllMedicalProfileRecommendationsByPlayerId(playerId) {
  return (dispatch) => {
    return MedicalProfileAPI.getMedicalProfileRecommendationsByPlayerId(playerId)
      .then((data) => {
        dispatch({
          type: types.MEDICAL_PROFILE_GET_RECOMMENDATIONS,
          medicalProfileRecommendation: data.data,
        });
      })
      .catch(function (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message.length) {
              ReactComponentNotification(
                NOTIFICATION_TYPES['error'],
                `Ошибка при загрузке: ${Object.values(err.response.data.message[0])[0]}`,
              );
            }
          }
        } else {
          ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
        }
      });
  };
}

export function getMedicalProfileRecommendationsForCard(cardId) {
  return (dispatch) => {
    return MedicalProfileAPI.getMedicalProfileRecommendationByCardId(cardId)
      .then((data) => {
        dispatch({
          type: types.MEDICAL_PROFILE_GET_RECOMMENDATIONS_FOR_CARD,
          medicalProfileRecommendation: data.data,
        });
      })
      .catch(function (err) {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message.length && err.response.data.message !== 'Рекомендация не найдена') {
              ReactComponentNotification(
                NOTIFICATION_TYPES['error'],
                `Ошибка при загрузке: ${err.response.data.message}`,
              );
            }
          }
        } else {
          ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
        }
      });
  };
}

// export function editPlayerMedicalProfileRecommendation(objectId, editedPlayerMedicalProfileRecommendation) {
//   return (dispatch) => {
//     return MedicalProfileAPI.editMedicalProfileRecommendation(objectId, editedPlayerMedicalProfileRecommendation)
//       .then(
//         data => {
//           dispatch({
//             type: types.MEDICAL_PROFILE_EDIT_RECOMMENDATION,
//             medicalProfileRecommendation: {}
//           });
//           ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Рекомендация успешно отредактирована');
//         }
//       ).catch(function (err) {
//         if(err.response) {
//           if(err.response.data) {
//             if(err.response.data.message.length) {
//               ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка при загрузке: ${Object.values(err.response.data.message[0])[0]}`);
//             }
//           }
//         } else {
//           ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
//         }
//       })
//   }
// }

// export function deleteMedicalProfileRecommendation(objectId) {
//   return (dispatch) => {
//     return MedicalProfileAPI.deleteMedicalProfileRecommendation(objectId)
//       .then(
//         data => {
//           dispatch({
//             type: types.MEDICAL_PROFILE_DELETE_RECOMMENDATION,
//             medicalProfileRecommendation: {}
//           });
//           ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Рекомендация успешно удалена');
//         }
//       ).catch(function(err) {
//         if (err.response) {
//           if (err.response.data) {
//             if (err.response.data.message) {
//               ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при удалении рекомендации. Возможно она не была сохранена ранее.')
//             }
//           }
//         }
//       });
//   }
// }
