import schema from '@assets/images/arena-schema.svg';
import schemaVertical from '@assets/images/arena-schema-vertical.svg';
import {
  ThrowsCardArenaSchema,
  ThrowsCardArenaSchemaBackground,
  ThrowsCardCharts,
  ZoneLabels,
} from '@common/Statistics/ThrowsMap/ThrowsCard/ThrowsCard';
import {PLAY_ZONES} from '@components/apps/StatsReportForOneGame/constants';
import ScatterChart from '@components/charts/ScatterChart';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {useMediaQuery} from '@mui/material';
import {MAPS_SIZE} from '@src/constants/stitictics';
import {Box} from '@ui/MUI';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React, {memo} from 'react';

FieldScatterChart.propTypes = {
  series: PropTypes.array,
  options: PropTypes.object,
  isLoading: PropTypes.bool,
  isLoad: PropTypes.bool,
  error: PropTypes.bool,
  weAtHome: PropTypes.bool.isRequired,
};

function FieldScatterChart({
  isLoading,
  isLoad,
  error,
  tooltip,
  series,
  weAtHome = false,
  legend = {},
  noData,
  xAxis = {},
  yAxis = {},
  options,
}) {
  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const mapPb = 48;
  return (
    <ThrowsCardArenaSchema pb={mapPb}>
      <Box p="15px" position="relative">
        <ThrowsCardArenaSchemaBackground
          draggable={false}
          nodata={(isLoad && (error || noData)) || isLoading}
          src={isXsResolution ? schemaVertical : schema}
        />
        <LocalPreloader bgcolor="transparent" visible={isLoading} />
        {isLoad && (error || noData) && (
          <TypographyDefault
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
            }}
            top={0}
            variant="subtitle2"
          >
            Данные отсутствуют
          </TypographyDefault>
        )}
      </Box>

      <ThrowsCardCharts>
        <ScatterChart
          height="100%"
          options={{
            tooltip,
            [isXsResolution ? 'yAxis' : 'xAxis']: {
              splitLine: {
                show: false,
              },
              show: false,
              min: 0,
              max: MAPS_SIZE.x,
              position: 'bottom',
              inverse: isXsResolution,
              ...xAxis,
            },

            [isXsResolution ? 'xAxis' : 'yAxis']: {
              splitLine: {
                show: false,
              },
              show: false,
              position: 'left',
              min: 0,
              max: MAPS_SIZE.y,
              ...yAxis,
            },
            grid: {
              left: 17.5,
              top: 17.5,
              bottom: mapPb + 17.5,
              right: 17.5,
            },
            colorBy: 'series',
            series,
            legend: {
              show: true,
              left: 'center',
              bottom: 0,
              pageButtonItemGap: 0,
              textStyle: {
                fontSize: 14,
                fontFamily: 'Proxima Nova',
              },
              itemGap: 32,
              ...legend,
            },
            ...options,
          }}
          width={'100%'}
        />
      </ThrowsCardCharts>
      <ZoneLabels
        direction={{xxs: 'column', xs: 'row'}}
        height={{xxs: `calc(100% + 20px - ${mapPb}px)`, xs: 'auto'}}
        justifyContent="space-between"
        px="8%"
        top={{xxs: -10, xs: 'unset'}}
      >
        {(weAtHome ? [...PLAY_ZONES].reverse() : [...PLAY_ZONES]).map((item, index) => (
          <TypographyDefault key={index} textAlign={{xxs: 'center', xs: 'left'}} variant="body1">
            {item}
          </TypographyDefault>
        ))}
      </ZoneLabels>
    </ThrowsCardArenaSchema>
  );
}

export default memo(FieldScatterChart);
