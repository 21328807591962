import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

function Puck({...props}) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M8.00016 4.00006C4.3335 4.00006 1.3335 5.04673 1.3335 6.33339C1.3335 7.62006 4.3335 8.66673 8.00016 8.66673C11.6668 8.66673 14.6668 7.62006 14.6668 6.33339C14.6668 5.04673 11.6668 4.00006 8.00016 4.00006ZM1.3335 7.66673V11.0001C1.3335 12.2867 4.3335 13.3334 8.00016 13.3334C11.6668 13.3334 14.6668 12.2867 14.6668 11.0001V7.66673C14.2202 7.97339 13.7202 8.38006 13.1535 8.57339C11.7468 9.06673 9.96683 9.33339 8.00016 9.33339C6.0335 9.33339 4.2535 9.06673 2.84683 8.57339C2.28016 8.38006 1.78016 7.97339 1.3335 7.66673Z" />
    </SvgIcon>
  );
}

export default Puck;
