import ReactTableComponent from '@common/ReactTableComponent';
import {renderCondition} from '@components/apps/FunctionalTesting/constants';
import date from '@helpers/date';
import {useMediaQuery} from '@mui/material';
import React from 'react';
import {useHistory} from 'react-router';

import {CellContainer, FunctionalStateIndicator, TestingDate} from './ReportsInformation';

const ReportsInformationTable = ({teamId, tableData}) => {
  const history = useHistory();

  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const columns = [
    {
      accessorKey: 'date',
      header: () => (!isXsResolution ? 'Дата тестирования' : 'Дата...'),
      cell: (info) => {
        return (
          <TestingDate
            onClick={() => {
              history.push(`/functional_testing/${teamId}/${info.getValue()}`);
            }}
          >
            {date(info.getValue()).format('DD.MM.YYYY')}
          </TestingDate>
        );
      },
    },
    {
      accessorKey: 'players',
      header: 'Игроки',
      cell: (info) => {
        return `${info.getValue()} из ${info.row.original.allPlayersInTeam}`;
      },
    },
    {
      accessorKey: 'functionalState',
      header: 'Функциональное состояние',
      cell: (info) =>
        info.getValue() ? (
          <CellContainer>
            <FunctionalStateIndicator type={renderCondition(info.getValue())} />
          </CellContainer>
        ) : (
          <CellContainer>-</CellContainer>
        ),
    },
  ];
  return (
    <ReactTableComponent
      columnWidth="calc(100% / 3)"
      columns={columns}
      data={tableData}
      tableBodyCellProps={{
        sx: {
          boxShadow: 'rgb(224, 224, 224) 0 -1px 0px 0px inset',
          '@media screen and (max-width: 575px)': {
            width: '100px !important',
            maxWidth: 100,
            fontSize: '12px !important',
          },
          '&.lastRowCell': {
            boxShadow: 'none',
          },
        },
      }}
      tableHeaderCellProps={{
        sx: {
          boxShadow: 'rgb(224, 224, 224) 0 -1px 0px 0px inset',
          '@media screen and (max-width: 575px)': {
            width: '100px !important',
            maxWidth: 100,
            fontSize: '12px !important',
          },
        },
      }}
    />
  );
};

export default ReportsInformationTable;
