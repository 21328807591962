import {Stack, Typography} from '@ui/MUI';
import React from 'react';

const FormItem = ({label, children, spacing = 1, mt, mb, titleMinWidth, maxWidth, alignItems}) => {
  return (
    <Stack alignItems={alignItems} direction="row" maxWidth={maxWidth} mb={mb} mt={mt} spacing={spacing}>
      <Typography sx={{minWidth: titleMinWidth}}>{label}:</Typography>
      {children}
    </Stack>
  );
};

export default FormItem;
