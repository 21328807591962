import PlayerInfo from '@components/apps/PlayerPersonalFile/components/MainInfo/components/PlayerInfo';
import {TitleWrapper} from '@components/apps/PlayerPersonalFile/components/TrainerReport/TrainerReport';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import useStore from '@hooks/useStore';
import {Box, Stack, Typography} from '@ui/MUI';
import React from 'react';

import ScoutReport from './components/ScoutReport';

function TrainerReport() {
  const {student, loading} = useStore((state) => state.users);
  return (
    <Stack direction="column" gap={3}>
      <Stack direction={{xxs: 'column', sm: 'row'}} gap={3}>
        <Box>
          <PlayerInfo isLoading={loading} student={student} />
        </Box>

        <Wrapper p="0" position="relative" width="fill-available">
          <TitleWrapper px={4} py={3}>
            <Typography variant="h4">Статистика</Typography>
          </TitleWrapper>
          <Typography
            left="50%"
            position={{xxs: 'relative', sm: 'absolute'}}
            py={4}
            sx={{transform: 'translate(-50%, 0%)'}}
            textAlign="center"
            top="50%"
            variant="subtitle1"
          >
            Блок в разработке
          </Typography>
        </Wrapper>
      </Stack>
      <ScoutReport />
    </Stack>
  );
}

export default TrainerReport;
