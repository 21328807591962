import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

const List = ({children, label, actionText, onActionClick}) => {
  return (
    <div>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <TypographyDefault variant="body2">{label}</TypographyDefault>
        <TypographyDefault color="primary" sx={{cursor: 'pointer'}} variant="caption1" onClick={onActionClick}>
          {actionText}
        </TypographyDefault>
      </Stack>
      {children}
    </div>
  );
};

export default List;
