import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React from 'react';
import {Scrollbar as CustomScrollbar} from 'react-scrollbars-custom';
import styled from 'styled-components';

function Scrollbar({
  children,
  mobileNative,
  translateContentSizesToHolder,
  translateContentSizeYToHolder,
  translateContentSizeXToHolder,
  style,
  width,
  height,
  withoutWrapper,
  ...props
}) {
  return (
    <StyledScrollbar
      {...props}
      mobileNative={mobileNative}
      style={{...style, width, height}}
      translateContentSizeXToHolder={translateContentSizeXToHolder}
      translateContentSizeYToHolder={translateContentSizeYToHolder}
      translateContentSizesToHolder={translateContentSizesToHolder}
      withoutwrapper={withoutWrapper}
    >
      {children}
    </StyledScrollbar>
  );
}

Scrollbar.propTypes = {
  children: PropTypes.node,
};

export default Scrollbar;

const StyledScrollbar = styled(CustomScrollbar)`
  .ScrollbarsCustom-Thumb {
    background: ${({withoutwrapper}) => grey[withoutwrapper ? 54 : 68]} !important;
  }
  .ScrollbarsCustom-Track {
    background: ${grey['26']} !important;
  }
`;
