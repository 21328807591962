import React from 'react';

function HockeyGates() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M6.91537 33.1907C6.54034 33.1907 6.23633 32.8866 6.23633 32.5116V10.7427C6.23633 10.3676 6.54034 10.0636 6.91537 10.0636C7.2904 10.0636 7.59441 10.3677 7.59441 10.7427V32.5115C7.59441 32.8866 7.2904 33.1907 6.91537 33.1907Z"
          fill="#A9AAAC"
        />
        <path
          d="M9.74936 33.1907C9.37433 33.1907 9.07031 32.8866 9.07031 32.5116V10.7427C9.07031 10.3676 9.37433 10.0636 9.74936 10.0636C10.1244 10.0636 10.4284 10.3677 10.4284 10.7427V32.5115C10.4284 32.8866 10.1244 33.1907 9.74936 33.1907Z"
          fill="#A9AAAC"
        />
        <path
          d="M12.5829 33.1907C12.2078 33.1907 11.9038 32.8866 11.9038 32.5116V10.7427C11.9038 10.3676 12.2078 10.0636 12.5829 10.0636C12.9579 10.0636 13.2619 10.3677 13.2619 10.7427V32.5115C13.2619 32.8866 12.9579 33.1907 12.5829 33.1907Z"
          fill="#A9AAAC"
        />
        <path
          d="M15.4163 33.1907C15.0413 33.1907 14.7373 32.8866 14.7373 32.5116V10.7427C14.7373 10.3676 15.0413 10.0636 15.4163 10.0636C15.7914 10.0636 16.0954 10.3677 16.0954 10.7427V32.5115C16.0955 32.8866 15.7915 33.1907 15.4163 33.1907Z"
          fill="#A9AAAC"
        />
        <path
          d="M18.2498 33.1907C17.8748 33.1907 17.5708 32.8866 17.5708 32.5116V10.7427C17.5708 10.3676 17.8748 10.0636 18.2498 10.0636C18.6249 10.0636 18.9289 10.3677 18.9289 10.7427V32.5115C18.929 32.8866 18.6249 33.1907 18.2498 33.1907Z"
          fill="#A9AAAC"
        />
        <path
          d="M26.7508 33.1907C26.3758 33.1907 26.0718 32.8866 26.0718 32.5116V10.7427C26.0718 10.3676 26.3758 10.0636 26.7508 10.0636C27.1258 10.0636 27.4299 10.3677 27.4299 10.7427V32.5115C27.4299 32.8866 27.1258 33.1907 26.7508 33.1907Z"
          fill="#A9AAAC"
        />
        <path
          d="M29.5843 33.1907C29.2093 33.1907 28.9053 32.8866 28.9053 32.5116V10.7427C28.9053 10.3676 29.2093 10.0636 29.5843 10.0636C29.9593 10.0636 30.2634 10.3677 30.2634 10.7427V32.5115C30.2634 32.8866 29.9593 33.1907 29.5843 33.1907Z"
          fill="#A9AAAC"
        />
        <path
          d="M32.4178 33.1907C32.0428 33.1907 31.7388 32.8866 31.7388 32.5116V10.7427C31.7388 10.3676 32.0428 10.0636 32.4178 10.0636C32.7928 10.0636 33.0969 10.3677 33.0969 10.7427V32.5115C33.0969 32.8866 32.7928 33.1907 32.4178 33.1907Z"
          fill="#A9AAAC"
        />
        <path
          d="M35.2513 33.1907C34.8763 33.1907 34.5723 32.8866 34.5723 32.5116V10.7427C34.5723 10.3676 34.8763 10.0636 35.2513 10.0636C35.6264 10.0636 35.9304 10.3677 35.9304 10.7427V32.5115C35.9304 32.8866 35.6264 33.1907 35.2513 33.1907Z"
          fill="#A9AAAC"
        />
        <path
          d="M38.0848 33.1907C37.7098 33.1907 37.4058 32.8866 37.4058 32.5116V10.7427C37.4058 10.3676 37.7098 10.0636 38.0848 10.0636C38.4598 10.0636 38.7639 10.3677 38.7639 10.7427V32.5115C38.7639 32.8866 38.4598 33.1907 38.0848 33.1907Z"
          fill="#A9AAAC"
        />
        <path
          d="M41.3323 13.6128H3.6683C3.29327 13.6128 2.98926 13.3087 2.98926 12.9337C2.98926 12.5586 3.29327 12.2547 3.6683 12.2547H41.3323C41.7074 12.2547 42.0114 12.5587 42.0114 12.9337C42.0114 13.3088 41.7074 13.6128 41.3323 13.6128Z"
          fill="#A9AAAC"
        />
        <path
          d="M41.3323 16.4463H3.6683C3.29327 16.4463 2.98926 16.1423 2.98926 15.7673C2.98926 15.3923 3.29327 15.0883 3.6683 15.0883H41.3323C41.7074 15.0883 42.0114 15.3923 42.0114 15.7673C42.0114 16.1423 41.7074 16.4463 41.3323 16.4463Z"
          fill="#A9AAAC"
        />
        <path
          d="M41.3323 19.2799H3.6683C3.29327 19.2799 2.98926 18.9758 2.98926 18.6008C2.98926 18.2258 3.29327 17.9218 3.6683 17.9218H41.3323C41.7074 17.9218 42.0114 18.2258 42.0114 18.6008C42.0114 18.9758 41.7074 19.2799 41.3323 19.2799Z"
          fill="#A9AAAC"
        />
        <path
          d="M41.3323 22.1135H3.6683C3.29327 22.1135 2.98926 21.8095 2.98926 21.4345C2.98926 21.0594 3.29327 20.7554 3.6683 20.7554H41.3323C41.7074 20.7554 42.0114 21.0595 42.0114 21.4345C42.0114 21.8095 41.7074 22.1135 41.3323 22.1135Z"
          fill="#A9AAAC"
        />
        <path
          d="M41.3323 24.947H3.6683C3.29327 24.947 2.98926 24.643 2.98926 24.268C2.98926 23.893 3.29327 23.589 3.6683 23.589H41.3323C41.7074 23.589 42.0114 23.893 42.0114 24.268C42.0114 24.643 41.7074 24.947 41.3323 24.947Z"
          fill="#A9AAAC"
        />
        <path
          d="M41.3323 27.7805H3.6683C3.29327 27.7805 2.98926 27.4765 2.98926 27.1015C2.98926 26.7265 3.29327 26.4225 3.6683 26.4225H41.3323C41.7074 26.4225 42.0114 26.7265 42.0114 27.1015C42.0114 27.4765 41.7074 27.7805 41.3323 27.7805Z"
          fill="#A9AAAC"
        />
        <path
          d="M41.3323 30.6142H3.6683C3.29327 30.6142 2.98926 30.3102 2.98926 29.9352C2.98926 29.5602 3.29327 29.2561 3.6683 29.2561H41.3323C41.7074 29.2561 42.0114 29.5602 42.0114 29.9352C42.0114 30.3102 41.7074 30.6142 41.3323 30.6142Z"
          fill="#A9AAAC"
        />
        <path d="M24.1041 32.5115V9.53366H20.8957V32.5115H1.75879V34.8733H43.2409V32.5115H24.1041Z" fill="#EDF6F9" />
        <path d="M24.1044 9.53349H20.896V12.5288H24.1044V9.53349Z" fill="#DAE7EA" />
        <path d="M5.29814 32.5116H1.75879V34.8734H5.29814V32.5116Z" fill="#DAE7EA" />
        <path d="M43.2415 32.5116H39.7021V34.8734H43.2415V32.5116Z" fill="#DAE7EA" />
        <path
          d="M43.166 37.9252C42.153 37.9252 41.332 37.1042 41.332 36.0912V12.6171C41.332 11.5836 40.4911 10.7428 39.4576 10.7428H5.54238C4.50888 10.7428 3.66794 11.5836 3.66794 12.6171V36.0912C3.66794 37.1042 2.84687 37.9252 1.83393 37.9252C0.820986 37.9252 0 37.1042 0 36.0912V12.6171C0 9.56109 2.48634 7.07475 5.54238 7.07475H39.4576C42.5137 7.07475 45 9.561 45 12.6171V36.0912C45 37.1042 44.1789 37.9252 43.166 37.9252Z"
          fill="#D53D33"
        />
        <path
          d="M42.9831 12.6087V37.9159C42.056 37.8243 41.3325 37.0429 41.3325 36.0912V12.617C41.3325 11.5836 40.4916 10.7426 39.4581 10.7426H5.54203C4.50861 10.7426 3.66768 11.5835 3.66768 12.617V36.0912C3.66768 37.043 2.94416 37.8243 2.01709 37.9159V12.6087C2.01709 10.6243 3.62549 9.01591 5.609 9.01591H39.3912C41.3746 9.01591 42.9831 10.6243 42.9831 12.6087Z"
          fill="#990011"
        />
        <path
          d="M9.69258 9.69494H7.00277C6.62774 9.69494 6.32373 9.39093 6.32373 9.0159C6.32373 8.64087 6.62774 8.33685 7.00277 8.33685H9.69267C10.0677 8.33685 10.3717 8.64087 10.3717 9.0159C10.3717 9.39093 10.0676 9.69494 9.69258 9.69494Z"
          fill="#EDF6F9"
        />
        <path
          d="M37.9973 9.69494H35.3075C34.9324 9.69494 34.6284 9.39093 34.6284 9.0159C34.6284 8.64087 34.9324 8.33685 35.3075 8.33685H37.9973C38.3723 8.33685 38.6763 8.64087 38.6763 9.0159C38.6763 9.39093 38.3723 9.69494 37.9973 9.69494Z"
          fill="#EDF6F9"
        />
      </g>
    </React.Fragment>
  );
}
export default HockeyGates;
