import {HOCKEY_ROLES_OPTIONS} from '../components/students/constants';
import StateHelper from './StateHelper';

export const convertUserDataToCard = (userData, state) => {
  if (!state) return;
  if (!userData) return [];

  // TODO: удалить этот кусок кода когда поправят точку
  const usersIds = state.users.usersMap.toArray().map((item) => item.id);
  const filteredUserData = userData.filter((item) => usersIds.includes(item.playerId));
  //

  let result = filteredUserData.map((item) => {
    const group = StateHelper.getStudentGroup(state, item.playerId);
    const amplua = HOCKEY_ROLES_OPTIONS.find(
      (role) => role.value === state.users.usersMap.get(item.playerId)?.hockeyRole,
    );
    return {
      id: item.objectId,
      timestamp: item.date ? item.date : '',
      recoveryDate: item.recoveryDate ? item.recoveryDate : '',
      number: state.users.usersMap.get(item.playerId)?.playerNumber
        ? state.users.usersMap.get(item.playerId).playerNumber
        : '',
      name:
        state.users.usersMap.get(item.playerId)?.firstName + ' ' + state.users.usersMap.get(item.playerId)?.lastName,
      crew: group ? group.name : '',
      amplua: amplua ? amplua.label : '',
      title: item.diseaseName,
      description: item.description,
      status: item.status,
      injuryType: item.injuryType,
    };
  });
  return result;
};
export const sortCardIntoColumns = (cards) => {
  let columns = [
    {
      id: 'not_allowed',
      title: 'Не допущен к тренировкам',
      value: 'not_allowed',
      cards: [],
    },
    {
      id: 'individual_hall',
      title: 'Индивидуальная тренировка в зале',
      value: 'individual_hall',
      cards: [],
    },
    {
      id: 'individual_ice',
      title: 'Индивидуальная тренировка на льду и в зале',
      value: 'individual_ice',
      cards: [],
    },
    {
      id: 'without_team',
      title: 'Тренировка с командой, с ограничениями',
      value: 'without_team',
      cards: [],
    },
    {
      id: 'with_team',
      title: 'Тренировка с командой, но без допуска на игру',
      value: 'with_team',
      cards: [],
    },
    {
      id: 'recovered',
      title: 'Допуск на игру',
      value: 'recovered',
      cards: [],
    },
  ];
  if (!cards) {
    cards = [];
  }

  cards.map((card) => {
    let columnIndex = null;
    columns.find((column, index) => {
      if (column.value === card.status) columnIndex = index;
    });
    columns[columnIndex].cards.push(card);
  });
  return columns;
};

export const generateBoardByUserData = (userData, state) => {
  let result = {};

  const cards = convertUserDataToCard(userData, state);
  const columns = sortCardIntoColumns(cards);
  result.columns = columns;

  return result;
};
export const emptyBoard = () => {
  return generateBoardByUserData([]);
};

export const generateId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};
