import 'echarts/lib/chart/scatter';

import * as echarts from 'echarts';
import React, {useEffect, useRef, useState} from 'react';

export default function ScatterChart(props) {
  const {width, height, options, isResponsitive = true} = props;

  const defaultOption = {
    xAxis: {},
    yAxis: {},
    series: [
      {
        symbolSize: 20,
        data: [],
        type: 'scatter',
      },
    ],
  };

  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  /**
   * Инициализация графика
   */
  const init = (opt) => {
    // eslint-disable-next-line import/namespace
    const chart = echarts.init(chartRef.current);

    chart.setOption(opt ? opt : defaultOption);
    setChartInstance(chart);
  };
  useEffect(() => {
    init(options);
  }, []);
  /**
   * Реинициализация графика
   */
  const reInit = (options) => {
    if (!chartInstance) return;
    chartInstance.dispose();
    init(options);
  };

  useEffect(() => {
    reInit(options);
  }, [options]);

  useEffect(() => {
    if (isResponsitive && chartInstance) {
      window.addEventListener('resize', function () {
        chartInstance.resize();
      });
      return () => document.removeEventListener('resize', () => {});
    }
  }, [isResponsitive, chartInstance]);

  return (
    <>
      <div
        className={props.className || 'ScatterChart'}
        style={{
          textAlign: 'center',
          width: width,
          height: height,
        }}
      >
        <div
          ref={chartRef}
          style={{
            width: width,
            minHeight: height,
            height: height,
            maxHeight: height,
          }}
        ></div>
      </div>
    </>
  );
}
