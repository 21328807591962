export const reportsMock = [...Array(9)].fill({
  id: 'b965VH3vFm5F',
  student: 'QwQPPT-S6NtU',
  author: {
    id: 'fJJOq9ZgzU',
    firstName: 'Игорь',
    lastName: 'Еронко',
    userRole: 'franchisee',
  },
  authors: [
    {
      id: 'fJJOq9ZgzU',
      firstName: 'Игорь',
      lastName: 'Еронко',
      userRole: 'franchisee',
    },
  ],
  potential: '',
  roleModel: '',
  createdAt: '',
  weakSide: '',
});
