import React from 'react';

function LeftArrowWithLine() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M14.8281 4.08524C14.828 4.08507 14.8278 4.08486 14.8276 4.08469L11.766 1.03781C11.5366 0.809561 11.1656 0.81041 10.9373 1.0398C10.709 1.26917 10.7099 1.64016 10.9393 1.86844L12.9949 3.91406H0.585938C0.262324 3.91406 0 4.17639 0 4.5C0 4.82361 0.262324 5.08594 0.585938 5.08594H12.9948L10.9393 7.13156C10.7099 7.35984 10.7091 7.73083 10.9373 7.9602C11.1657 8.18962 11.5367 8.19041 11.766 7.96219L14.8276 4.91531C14.8278 4.91514 14.828 4.91493 14.8282 4.91476C15.0577 4.68571 15.0569 4.31353 14.8281 4.08524Z"
          fill="#C4C4C4"
        />
      </g>
    </React.Fragment>
  );
}
export default LeftArrowWithLine;
