import {getFullName} from '@common/DevelopmentJournal/helpers';
import isObjectEmpty from '@helpers/isObjectEmpty';
import {useModal} from '@hooks/skill';
import Drawer from '@ui/MUI/DrawerNew';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import Buttons from './components/Buttons';
import Form from './components/Form';

const EditModal = () => {
  const {isOpen, close, data} = useModal('edit');
  const fullName = getFullName(data?.student);
  const form = useForm();
  useEffect(() => {
    if (!isObjectEmpty(data)) {
      form.reset({
        ...data,
        specialists: data?.specialists?.map((specialist) => ({
          ...specialist,
          label: `${specialist.lastName} ${specialist.firstName}`,
        })),
      });
    }
  }, [data, isOpen]);

  return (
    <FormProvider {...form}>
      <Drawer buttons={<Buttons />} open={isOpen} title={`РАЗВИТИЕ НАВЫКА. ${fullName}`} onClose={close}>
        <Form form={form} />
      </Drawer>
    </FormProvider>
  );
};

export default EditModal;
