import ContentWrapper from '@common/ContentWrapper';
import ActionVideoModal from '@common/Statistics/ActionsMap/ActionVideoModal';
import Buttons from '@common/Statistics/ActionsMap/Buttons';
import Field from '@common/Statistics/ActionsMap/Field';
import Filters from '@common/Statistics/ActionsMap/Filters';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {
  selectActionsMapButtonTypes,
  selectActionsMapData,
  selectActionsMapFilters,
  selectVideoModal,
} from '@selectors/gameStatistics';
import {setVideoModal} from '@slices/statistics';
import {
  clearActionsMap,
  clearActionsMapFilters,
  clearButtonTypes,
  loadActionsMap,
  loadButtonTypes,
  setActionsMapFilters,
} from '@slices/statistics/actionsMap';
import {Stack} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';
import {useParams} from 'react-router-dom';

ActionsMap.propTypes = {
  hasOT: PropTypes.bool,
};

function ActionsMap({hasOT, weAtHome}) {
  const {statisticId} = useParams();
  const dispatch = useStoreDispatch();
  return (
    <Stack direction="column" gap={1}>
      <Filters
        getter={selectActionsMapFilters}
        hasOT={hasOT}
        setter={setActionsMapFilters}
        statisticsIds={statisticId ? [statisticId] : []}
      />
      <ContentWrapper>
        <Buttons
          hasVideo
          cleanup={() => {
            dispatch(clearButtonTypes());
            dispatch(clearActionsMapFilters());
          }}
          filterGetter={selectActionsMapFilters}
          getter={selectActionsMapButtonTypes}
          loader={loadButtonTypes}
          setter={setActionsMapFilters}
          typesGetter={selectActionsMapButtonTypes}
        />
        <Field
          cleaner={clearActionsMap}
          filtersGetter={selectActionsMapFilters}
          loader={loadActionsMap}
          mapDataGetter={selectActionsMapData}
          setModalState={setVideoModal}
          statisticsIds={statisticId ? [statisticId] : []}
          weAtHome={weAtHome}
        />
        <ActionVideoModal stateGetter={selectVideoModal} stateSetter={setVideoModal} />
      </ContentWrapper>
    </Stack>
  );
}

export default ActionsMap;
