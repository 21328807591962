import React, {useState} from 'react';

import {Button} from '../../../../../../ui/Button';
import Icon from '../../../../../../ui/Icon/Icon';
import {generateClassNameByEstimation} from '../../../../../helper';
import {
  ColumnValue,
  ColumnValueWrapper,
  CommentText,
  CommentTitle,
  EstimationTable,
  EstimationTableBody,
  EstimationTableColumn,
  EstimationTableComments,
  EstimationTableCommentsColumn,
  EstimationTableHead,
  EstimationTableHeadColumn,
  EstimationTableRow,
  StudentEstimationTableHeadColumn,
} from '../../../styles';
import {MobieHeadColumn, MobileColumn, Range} from './PlayersTable';

export default function PlayersTable(props) {
  const {
    gameEstimateReport,
    size: {screenWidth},
  } = props;
  const [isCommentsShowed, setIsCommentsShowed] = useState(false);
  return screenWidth > 768 ? (
    <EstimationTable>
      <EstimationTableHead>
        <EstimationTableRow>
          <EstimationTableHeadColumn colSpan="2">
            {gameEstimateReport?.student?.lastName} {gameEstimateReport?.student?.firstName}
          </EstimationTableHeadColumn>
          {gameEstimateReport?.parameters?.map((param, index) => {
            return (
              <EstimationTableHeadColumn colSpan="2" key={index}>
                {param.name}
              </EstimationTableHeadColumn>
            );
          })}
          <EstimationTableHeadColumn></EstimationTableHeadColumn>
        </EstimationTableRow>
        <EstimationTableRow>
          <StudentEstimationTableHeadColumn>Ср. оц. игрока</StudentEstimationTableHeadColumn>
          <StudentEstimationTableHeadColumn>Ср. оц. тренера</StudentEstimationTableHeadColumn>
          {gameEstimateReport?.parameters?.map((param, index) => {
            return (
              <React.Fragment key={index}>
                <StudentEstimationTableHeadColumn>Оц. игрока</StudentEstimationTableHeadColumn>
                <StudentEstimationTableHeadColumn>Оц. тренера</StudentEstimationTableHeadColumn>
              </React.Fragment>
            );
          })}
          <StudentEstimationTableHeadColumn>Коммент.</StudentEstimationTableHeadColumn>
        </EstimationTableRow>
      </EstimationTableHead>
      <EstimationTableBody>
        <EstimationTableRow isCommentsShowed={isCommentsShowed}>
          <EstimationTableColumn>
            <ColumnValueWrapper>
              <ColumnValue className={generateClassNameByEstimation(gameEstimateReport?.avgStudentsValue)}>
                {gameEstimateReport?.avgStudentsValue ? gameEstimateReport?.avgStudentsValue : 'Б/О'}
              </ColumnValue>
            </ColumnValueWrapper>
          </EstimationTableColumn>
          <EstimationTableColumn>
            <ColumnValueWrapper>
              <ColumnValue className={generateClassNameByEstimation(gameEstimateReport?.avgTeachersValue)}>
                {gameEstimateReport?.avgTeachersValue ? gameEstimateReport?.avgTeachersValue : 'Б/О'}
              </ColumnValue>
            </ColumnValueWrapper>
          </EstimationTableColumn>
          {gameEstimateReport?.parameters?.map((param) => {
            return (
              <React.Fragment key={param.id}>
                <EstimationTableColumn>
                  <ColumnValueWrapper>
                    <ColumnValue className={generateClassNameByEstimation(param.studentsValue)}>
                      {param.studentsValue ? param.studentsValue : 'Б/О'}
                    </ColumnValue>
                  </ColumnValueWrapper>
                </EstimationTableColumn>
                <EstimationTableColumn>
                  <ColumnValueWrapper>
                    <ColumnValue className={generateClassNameByEstimation(param.teachersValue)}>
                      {param.teachersValue ? param.teachersValue : 'Б/О'}
                    </ColumnValue>
                  </ColumnValueWrapper>
                </EstimationTableColumn>
              </React.Fragment>
            );
          })}
          <EstimationTableColumn>
            <Button
              onClick={() => {
                setIsCommentsShowed(!isCommentsShowed);
              }}
            >
              <Icon height={17} type="Comment" viewBox={'0 0 19 17'} width={19} />
            </Button>
          </EstimationTableColumn>
        </EstimationTableRow>
        <EstimationTableComments isCommentsShowed={isCommentsShowed}>
          <EstimationTableCommentsColumn colSpan="2"></EstimationTableCommentsColumn>
          <EstimationTableCommentsColumn colSpan="4">
            {gameEstimateReport?.comment ? gameEstimateReport?.comment : 'Комментарий отсутствует'}
          </EstimationTableCommentsColumn>
        </EstimationTableComments>
      </EstimationTableBody>
    </EstimationTable>
  ) : (
    <EstimationTable>
      <EstimationTableHead>
        <EstimationTableRow>
          <EstimationTableHeadColumn colSpan="3">
            {gameEstimateReport?.student?.lastName} {gameEstimateReport?.student?.firstName}
          </EstimationTableHeadColumn>
        </EstimationTableRow>
        <EstimationTableRow>
          <MobieHeadColumn>Параметр</MobieHeadColumn>
          <MobieHeadColumn align="center">Оц. игрока</MobieHeadColumn>
          <MobieHeadColumn align="center">Оц. тренера</MobieHeadColumn>
        </EstimationTableRow>
      </EstimationTableHead>
      <EstimationTableBody>
        <EstimationTableRow>
          <MobileColumn>Средняя оценка</MobileColumn>
          <MobileColumn align="center">
            <Range className={generateClassNameByEstimation(gameEstimateReport?.avgStudentsValue)}>
              {gameEstimateReport?.avgStudentsValue ? gameEstimateReport?.avgStudentsValue : 'Б/О'}
            </Range>
          </MobileColumn>
          <MobileColumn align="center">
            <Range className={generateClassNameByEstimation(gameEstimateReport?.avgTeachersValue)}>
              {gameEstimateReport?.avgTeachersValue ? gameEstimateReport?.avgTeachersValue : 'Б/О'}
            </Range>
          </MobileColumn>
        </EstimationTableRow>
        {gameEstimateReport?.parameters?.map((param) => {
          return (
            <EstimationTableRow key={param.id}>
              <MobileColumn>{param.name}</MobileColumn>
              <MobileColumn align="center">
                <Range className={generateClassNameByEstimation(param.studentsValue)}>
                  {param.studentsValue ? param.studentsValue : 'Б/О'}
                </Range>
              </MobileColumn>
              <MobileColumn align="center">
                <Range className={generateClassNameByEstimation(param.teachersValue)}>
                  {param.teachersValue ? param.teachersValue : 'Б/О'}
                </Range>
              </MobileColumn>
            </EstimationTableRow>
          );
        })}
        <EstimationTableComments isCommentsShowed={true}>
          <MobileColumn colSpan="3">
            <CommentTitle>Комментарий от тренера</CommentTitle>
            <CommentText>{gameEstimateReport?.comment || '-'}</CommentText>
          </MobileColumn>
        </EstimationTableComments>
      </EstimationTableBody>
    </EstimationTable>
  );
}
