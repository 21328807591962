import ContentWrapper from '@common/ContentWrapper';
import Filters from '@common/Statistics/PlayerStatistics/Throwins/Filters';
import Metrics from '@common/Statistics/PlayerStatistics/Throwins/Metrics';
import ThrowinsMap from '@common/Statistics/PlayerStatistics/Throwins/ThrowinsMap';
import MatchesFilter from '@components/apps/StatisticsWithMkcUral/components/MatchesFilter';
import {useMatches} from '@components/apps/StatisticsWithMkcUral/hooks/useMatches';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectThrowinsFilters, selectThrowinsMap, selectThrowinsMetrics} from '@selectors/gameStatisticsMkc';
import {
  clearThrowinsFilters,
  clearThrowinsMap,
  clearThrowinsMetrics,
  loadThrowinsMap,
  loadThrowinsMetrics,
  setThrowinsFilters,
} from '@slices/statisticsMKC/throwins';
import {parseFormToGetValue} from '@src/helpers';
import {Stack} from '@ui/MUI';
import React, {useCallback} from 'react';

export default function Throwins() {
  const dispatch = useStoreDispatch();
  const {statisticIds, isMatchesLoading} = useMatches();
  const {statisticsIds, ...filters} = useStore(selectThrowinsFilters);

  const setFilters = useCallback((data = {}) => dispatch(setThrowinsFilters(data)), []);
  const requestFilters = useDeepMemo(() => parseFormToGetValue(filters), [filters]);

  return (
    <Stack direction="column" spacing={1}>
      <MatchesFilter
        wrapperProps={{mb: '0'}}
        onClear={() => {
          setFilters({playerIds: []});
          dispatch(clearThrowinsMetrics());
          dispatch(clearThrowinsMap());
        }}
      />
      <ContentWrapper>
        <Filters
          hasOT
          cleanup={clearThrowinsFilters}
          getter={selectThrowinsFilters}
          setter={setThrowinsFilters}
          statisticsIds={statisticIds}
        />
      </ContentWrapper>

      <ContentWrapper>
        <Metrics
          isMKC
          weAtHome
          cleanup={clearThrowinsMetrics}
          filters={requestFilters}
          getter={selectThrowinsMetrics}
          homeTeamNamePlaceholder="Авангард"
          isLoading={isMatchesLoading}
          loader={loadThrowinsMetrics}
          statisticsIds={statisticIds}
        />
        <ThrowinsMap
          weAtHome
          cleanup={clearThrowinsMap}
          filters={requestFilters}
          getter={selectThrowinsMap}
          isLoading={isMatchesLoading}
          loader={loadThrowinsMap}
          statisticsIds={statisticIds}
        />
      </ContentWrapper>
    </Stack>
  );
}
