import {TextField} from '@ui/MUI';
import React from 'react';
import {Controller} from 'react-hook-form';
import InputMask from 'react-input-mask';

function PhoneField({isEdit, name, label, isRequired}) {
  return (
    <Controller
      name={name}
      render={({field, fieldState}) => {
        return (
          <InputMask mask="9 (999) 999-99-99" readOnly={!isEdit} {...field}>
            {(inputProps) => (
              <TextField
                label={label}
                readOnly={!isEdit}
                variant="outlined"
                {...inputProps}
                error={fieldState.error}
                helperText={fieldState.error ? 'Поле заполнено неверно' : ''}
              />
            )}
          </InputMask>
        );
      }}
      rules={{
        required: isRequired,
        validate: (v) => {
          if (!v) return true;
          return v?.replace(/\D+/g, '')?.length === 11;
        },
      }}
    />
  );
}

export default PhoneField;
