const mood = {
  label: 'Настроение',
  value: 'mood',
  questions: [
    {label: 'Унылое', value: 1, icon: '☠️'},
    {label: 'Грустное', value: 2, icon: '👎'},
    {label: 'Спокойное', value: 3, icon: '🆗'},
    {label: 'Веселое', value: 4, icon: '👍'},
    {label: 'Радостное', value: 5, icon: '🔥'},
  ],
};
const skillMood = {
  label: 'Настроение при работе над навыком',
  value: 'skillMood',
  questions: mood.questions,
};

const health = {
  label: 'Самочувствие',
  value: 'health',
  questions: [
    {label: 'Нет сил', value: 1, icon: '☠️'},
    {label: 'Хочется отдохнуть', value: 2, icon: '👎'},
    {label: 'Легкая усталость, могу пойти погулять', value: 3, icon: '🆗'},
    {label: 'Чувствую прилив сил', value: 4, icon: '👍'},
    {label: 'Полон сил и энергии', value: 5, icon: '🔥'},
  ],
};
const skillHealth = {
  label: 'Самочувствие при работе над навыком',
  value: 'skillHealth',
  questions: health.questions,
};
export const QUESTIONNAIRE_QUESTION_TYPES = {
  sleep: {
    label: 'Сон',
    value: 'sleep',
    questions: [
      {label: 'Ощущение, что совсем не спал', value: 1, icon: '☠️'},
      {label: 'Нет бодрости после пробуждения', value: 2, icon: '👎'},
      {label: 'Выспался, легко встаю', value: 3, icon: '🆗'},
      {label: 'Очень хорошо выспался', value: 4, icon: '👍'},
      {label: 'После пробуждения заряжен на 100%', value: 5, icon: '🔥'},
    ],
  },
  mood: {...mood},
  iceMood: {...mood, value: 'iceMood'},
  gymMood: {...mood, value: 'gymMood'},
  skillMood: {...skillMood, value: 'skillMood'},
  willToTraining: {
    label: 'Желание тренироваться/играть',
    value: 'willToTraining',
    questions: [
      {label: 'Нет особого желания', value: 1, icon: '☠️'},
      {label: 'Тренируюсь/играю, потому что надо', value: 2, icon: '👎'},
      {label: 'Тренировка/игра – привычное дело', value: 3, icon: '🆗'},
      {label: 'Получаю удовольствие от тренировки/игры', value: 4, icon: '👍'},
      {label: 'Кайфую от тренировки/игры', value: 5, icon: '🔥'},
    ],
  },

  health: {...health},
  iceHealth: {...health, value: 'iceHealth'},
  gymHealth: {...health, value: 'gymHealth'},
  skillHealth: {...skillHealth, value: 'skillHealth'},
  activity: {
    label: 'Активность',
    value: 'activity',
    questions: [
      {label: 'Сложно играть без контроля тренера', value: 1, icon: '☠️'},
      {label: 'В сложных ситуациях жду поддержки от тренера', value: 2, icon: '👎'},
      {label: 'В сложных ситуациях стараюсь справиться самостоятельно', value: 3, icon: '🆗'},
      {
        label: 'Чаще активен, понимаю над чем необходимо работать и стараюсь это делать',
        value: 4,
        icon: '👍',
      },
      {
        label: 'Максимально активен, на каждой игре работаю над своим мастерством',
        value: 5,
        icon: '🔥',
      },
    ],
  },
  stress: {
    label: 'Напряжение',
    value: 'stress',
    questions: [
      {label: 'Зажат, ничего не получается', value: 1, icon: '☠️'},
      {label: 'Напряжен, это мешает играть', value: 2, icon: '👎'},
      {label: 'Контролирую напряжение', value: 3, icon: '🆗'},
      {label: 'Больше расслаблен, чем напряжен', value: 4, icon: '👍'},
      {label: 'Расслаблен', value: 5, icon: '🔥'},
    ],
  },
  attention: {
    label: 'Внимательность',
    value: 'attention',
    questions: [
      {label: 'Рассеян, не могу собраться', value: 1, icon: '☠️'},
      {label: 'Внимание появляется только иногда', value: 2, icon: '👎'},
      {label: 'Удерживаю внимание при среднем темпе игры', value: 3, icon: '🆗'},
      {label: 'Удерживаю внимание при повышении темпа игры', value: 4, icon: '👍'},
      {label: 'Отслеживаю каждый момент в игре', value: 5, icon: '🔥'},
    ],
  },
  fellingIceAndGoal: {
    label: 'Чувство льда и шайбы',
    value: 'fellingIceAndGoal',
    questions: [
      {label: 'Не чувствую лед, шайба постоянно сходит с крюка', value: 1, icon: '☠️'},
      {label: 'Есть моменты, когда плохо чувствую лед и шайбу', value: 2, icon: '👎'},
      {label: 'Чувствую лед и контролирую шайбу', value: 3, icon: '🆗'},
      {label: 'Чаще чувствую лед и шайбу, чем нет', value: 4, icon: '👍'},
      {label: 'Максимально чувствую лед и шайбу', value: 5, icon: '🔥'},
    ],
  },
  comunicationWithTrainer: {
    label: 'Коммуникация с тренером',
    value: 'comunicationWithTrainer',
    questions: [
      {label: 'Не понимаем друг друга', value: 1, icon: '☠️'},
      {label: 'Чаще не понимаем, чем понимаем', value: 2, icon: '👎'},
      {label: 'Коннект нормальный', value: 3, icon: '🆗'},
      {label: 'Чаще понимаем, чем не понимаем', value: 4, icon: '👍'},
      {label: 'Понимаем с полуслова', value: 5, icon: '🔥'},
    ],
  },
  selfScore: {
    label: 'Оценка своей игры',
    value: 'selfScore',
    questions: [
      {label: 'Совсем не доволен', value: 1, icon: '☠️'},
      {label: 'Больше недоволен, чем доволен', value: 2, icon: '👎'},
      {label: 'Нормально', value: 3, icon: '🆗'},
      {label: 'Больше доволен, чем недоволен', value: 4, icon: '👍'},
      {label: 'Максимально доволен', value: 5, icon: '🔥'},
    ],
  },
};

export const trainingQuestions = [
  {
    ...QUESTIONNAIRE_QUESTION_TYPES.gymMood,
    label: 'Зал:Настроение',
  },
  {
    ...QUESTIONNAIRE_QUESTION_TYPES.gymHealth,
    label: 'Зал:самочувствие',
  },
  {
    ...QUESTIONNAIRE_QUESTION_TYPES.iceMood,
    label: 'Лед:Настроение',
  },
  {
    ...QUESTIONNAIRE_QUESTION_TYPES.iceHealth,
    label: 'Лед:Самочувствие',
  },
];

export const QUESTIONNAIRE_ITEMS_TYPES = {
  morning: 'morning',
  trainingGroup: 'trainingGroup',
  trainingIndividual: 'trainingIndividual',
  game: 'game',
};

export const QUESTIONNAIRE_ITEMS = [
  {
    id: QUESTIONNAIRE_ITEMS_TYPES.morning,
    title: 'Анкета. Утро',
    questions: [
      QUESTIONNAIRE_QUESTION_TYPES.sleep,
      QUESTIONNAIRE_QUESTION_TYPES.mood,
      QUESTIONNAIRE_QUESTION_TYPES.willToTraining,
    ],
  },
  {
    id: QUESTIONNAIRE_ITEMS_TYPES.trainingIndividual,
    title: 'Анкета. Индивидуальная тренировка',
    questions: [...trainingQuestions],
  },
  {
    id: QUESTIONNAIRE_ITEMS_TYPES.trainingGroup,
    title: 'Анкета. Групповая тренировка',
    questions: [...trainingQuestions],
  },
  {
    id: QUESTIONNAIRE_ITEMS_TYPES.game,
    title: 'Анкета. игра',
    questions: [
      QUESTIONNAIRE_QUESTION_TYPES.activity,
      QUESTIONNAIRE_QUESTION_TYPES.stress,
      QUESTIONNAIRE_QUESTION_TYPES.health,
      QUESTIONNAIRE_QUESTION_TYPES.attention,
      QUESTIONNAIRE_QUESTION_TYPES.fellingIceAndGoal,
      QUESTIONNAIRE_QUESTION_TYPES.comunicationWithTrainer,
      QUESTIONNAIRE_QUESTION_TYPES.selfScore,
    ],
  },
];
