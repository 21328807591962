import DashboardsAPI from '../../api/DashboardsAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

const errorMessage = (err) => {
  let errorText = 'Ошибка при загрузке';
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
      }
    }
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

export function getTeachersDashboard() {
  return (dispatch) => {
    dispatch({
      type: types.DASHBOARD_LOADING_START,
    });
    return DashboardsAPI.getTeachersDashboard()
      .then((data) => {
        dispatch({
          type: types.DASHBOARD_LOADING_END,
          dashboard: data.data || {},
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.DASHBOARD_LOADING_ERROR,
        });
      });
  };
}

export function getManagerDashboard(filter) {
  return (dispatch) => {
    dispatch({
      type: types.DASHBOARD_LOADING_START,
    });
    return DashboardsAPI.getManagerDashboard(filter)
      .then((data) => {
        dispatch({
          type: types.DASHBOARD_LOADING_END,
          dashboard: data.data || {},
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.DASHBOARD_LOADING_ERROR,
        });
      });
  };
}

export function getDashboard() {
  return (dispatch) => {
    dispatch({
      type: types.DASHBOARD_LOADING_START,
    });
    return DashboardsAPI.getDashboard()
      .then((data) => {
        dispatch({
          type: types.STUDENT_DASHBOARD_LOADING_END,
          dashboard: data.data || {},
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.DASHBOARD_LOADING_ERROR,
        });
      });
  };
}

export function cleanTeachersDashboard() {
  return (dispatch) => {
    return dispatch({
      type: types.DASHBOARD_LOADING_END,
      dashboard: {},
    });
  };
}
