import React from 'react';

function Coach() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M15.6575 12.7003C14.2081 10.7212 12.4016 10.2626 11.4482 9.96149V9.26158C12.0905 8.62525 12.4891 7.7429 12.4891 6.76904C12.4891 6.39296 12.4891 3.42127 12.4891 3.04679C12.4891 1.69767 11.3915 0.600098 10.0424 0.600098H7.92491C6.57582 0.600098 5.47825 1.69767 5.47825 3.04676C5.47825 3.42124 5.47825 6.39293 5.47825 6.76901C5.47825 7.72754 5.86443 8.59741 6.48908 9.23129V9.98129C5.59981 10.2621 3.79134 10.7219 2.34253 12.7003C1.46422 13.8996 1 15.3196 1 16.8066C1 17.0767 1.219 17.2957 1.48913 17.2957H16.5109C16.781 17.2957 17 17.0767 17 16.8066C17 15.3196 16.5358 13.8996 15.6575 12.7003ZM10.4699 10.177L9.06155 12.3814C9.00155 12.3776 8.95864 12.3776 8.89564 12.3821L7.46731 10.1943V9.93205C8.41296 10.388 9.51973 10.3932 10.4699 9.94617V10.177ZM6.79358 5.56242C6.39599 4.28775 6.46603 4.51206 6.45651 4.48229V4.30402H11.5109V4.47671C11.5004 4.50815 11.5751 4.27816 11.1585 5.56249C8.80361 5.21579 9.18276 5.21064 6.79358 5.56242ZM6.45651 3.04676C6.45651 2.23709 7.11524 1.57836 7.92491 1.57836H10.0424C10.8521 1.57836 11.5109 2.23709 11.5109 3.04676V3.32576H6.45651V3.04676ZM6.45651 6.50354C6.55831 6.5397 6.66064 6.55532 6.73 6.55532C6.78109 6.55532 6.66846 6.56964 8.28269 6.33195C9.13759 6.20621 9.60308 6.32227 11.1505 6.55007C11.2641 6.5668 11.4042 6.53807 11.5109 6.49803V6.76901C11.5109 8.1642 10.3772 9.29931 8.98368 9.29931C7.59618 9.29931 6.45651 8.16974 6.45651 6.76901V6.50354ZM6.77154 10.918L8.02714 12.8413C7.32155 13.7276 8.107 15.0299 9.22828 14.8012V15.2615C9.22828 15.5316 9.44728 15.7506 9.71741 15.7506C9.98754 15.7506 10.2065 15.5316 10.2065 15.2615V13.6022C10.2065 13.311 10.1042 13.0434 9.93386 12.8331L11.1693 10.8993C11.8859 11.1256 12.2652 11.2263 12.8261 11.5186V16.3175H5.17391V11.5186C5.73436 11.2265 6.12318 11.1228 6.77154 10.918ZM4.19565 12.1575V16.3175H1.99789C2.13165 14.6578 2.94113 13.1662 4.19565 12.1575ZM13.8043 16.3175V12.1575C15.0588 13.1662 15.8683 14.6578 16.0021 16.3175H13.8043Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default Coach;
