export const generateTablesContentHeight = (rows, hasMaxHeight, marginBottom, hasDoubleHead) => {
  const titlesHeight = 80;
  let filtersHeight = 95;
  if (window.innerWidth >= 992 && window.innerWidth <= 1440) {
    filtersHeight = 57;
  }
  if (window.innerWidth >= 768 && window.innerWidth <= 992) {
    filtersHeight = 273;
  }
  if (window.innerWidth <= 767) {
    filtersHeight = 496;
  }
  const tablesTopHeightWithMargins = 14 + 60 + 14;
  const heightOfRow = 52;
  const heightOfHeadlineRow = !hasDoubleHead ? 66 : 66 * 2;
  const heightOfFreeSpace =
    window.innerHeight - (titlesHeight + filtersHeight + tablesTopHeightWithMargins + marginBottom);
  const contentHeight = rows?.length > 0 ? rows.length * heightOfRow + heightOfHeadlineRow : 0;
  let tablesContentHeight = 0;
  if (window.innerWidth >= 993) {
    if (contentHeight <= heightOfFreeSpace) {
      tablesContentHeight = contentHeight;
    } else {
      tablesContentHeight = heightOfFreeSpace;
    }
  } else {
    tablesContentHeight = contentHeight;
  }
  if (!hasMaxHeight) {
    tablesContentHeight = contentHeight;
  }
  return tablesContentHeight;
};
