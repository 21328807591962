import './index.css';
import 'moment/locale/ru';

import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {IS_PRODUCTION} from '@src/constants';
import {setSentryUser} from '@src/helpers';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

if (IS_PRODUCTION) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_SLUG,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event) {
      setSentryUser(event);
      return event;
    },
  });
}
moment.locale('ru');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
