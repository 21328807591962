import {Theme} from '@mui/material';
import {grey} from '@ui/MUI/colors';
import SelectDefault from '@ui/MUI/Select';
import {CustomTabsProps} from '@ui/MUI/TabsNew/index';
import styled, {css} from 'styled-components';

export const StyledSelect = styled(SelectDefault)<{theme: Theme} & Pick<CustomTabsProps, 'withoutWrapper'>>`
  ${({theme}) => theme.typography.overline1}
  background-color: ${({withoutWrapper}) => (withoutWrapper ? grey[48] : grey[14])};
  border-radius: 8px;
  .MuiSelect-select {
    min-height: unset;
    padding: ${({theme}) => theme.spacing(2)};
  }

  .MuiSelect-select,
  .MuiSvgIcon-root {
    color: ${({withoutWrapper}) => (withoutWrapper ? '#fff' : grey[85])} !important;
  }
`;

export const Wrapper = styled.div<{theme: Theme} & Pick<CustomTabsProps, 'withoutWrapper' | 'labelColor'>>`
  //TODO вынести с глобальную область в файл theme
  .MuiTabs {
    &-root {
      min-height: unset !important;
      box-shadow: none !important;
      padding-left: 0;
      padding-right: 0;
      padding-top: ${({theme}) => theme.spacing(1)};
      color: ${({withoutWrapper}) => (withoutWrapper ? '#fff' : 'rgba(0, 0, 0, 0.87)')};
      .MuiTabScrollButton-root {
        color: inherit;
        transition: width 0.2s ease-in-out;
        border-radius: 50%;
        &.Mui-disabled {
          width: 0 !important;
        }
      }
    }
    &-indicator {
      height: 2px;
    }
  }
  //TODO вынести с глобальную область в файл theme
  .MuiTab {
    &-root {
      ${({theme}) => theme.typography.overline1};
      min-height: 30px;
      padding: ${({theme}) => theme.spacing('0', 2)} !important;
      margin: 0;
      color: ${({withoutWrapper, labelColor}) => labelColor || (withoutWrapper && '#fff')};
    }
    ${({withoutWrapper, theme}) =>
      withoutWrapper &&
      css`
        margin: ${theme.spacing(0, 0, 2)} !important;
        box-shadow: none !important;
        .MuiTab {
          &-root {
            margin-left: 12px;
            margin-right: 12px;
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            color: #fff;
          }
        }
        & .MuiTabs-scrollButtons {
          color: #ffffff !important;
        }
      `}
  }
`;
