export const mock = {
  winByOT: false,
  halfsScores: ['3:0', '1:2', '4:4'],
  score: {
    data: {
      home: 8,
      away: 6,
    },
    name: 'Счёт',
  },
  teamNames: ['Авангард-ЮХЛ', 'Соперники'],
  metrics: [
    {
      data: {
        home: '150',
        away: '83',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Броски',
    },
    {
      data: {
        home: '83',
        away: '54',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Броски в створ',
    },
    {
      data: {
        home: '19',
        away: '39',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Заблокированные броски',
    },
    {
      data: {
        home: '82',
        away: '41',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Вбрасывания',
    },
    {
      data: {
        home: '25',
        away: '35',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Хиты',
    },
    {
      data: {
        home: '51',
        away: '42',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Отборы',
    },
    {
      data: {
        home: '16',
        away: '5',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Обыгрыши',
    },
    {
      data: {
        home: '18:07',
        away: '11:18',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Время в большинстве',
    },
    {
      data: {
        home: '3/11',
        away: '3/8',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Реализация',
    },
    {
      data: {
        home: '3/11',
        away: '3/8',
        homePercent: 0,
        awayPercent: 0,
      },
      name: 'Реализация',
    },
  ],
  numberOfMatches: 2,
};
