import React from 'react';

function BlockedShotsIcon(props) {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.94314 6.4451H10.332C10.7598 6.4451 11.1098 6.7951 11.1098 7.22287V11.1118C11.1098 11.5395 10.7598 11.8895 10.332 11.8895H5.66536C5.23759 11.8895 4.88759 11.5395 4.88759 11.1118V7.22287C4.88759 6.7951 5.23759 6.4451 5.66536 6.4451H6.05425V5.66732C6.05425 4.59398 6.92536 3.72287 7.9987 3.72287C9.07203 3.72287 9.94314 4.59398 9.94314 5.66732V6.4451ZM7.9987 4.50065C7.35314 4.50065 6.83203 5.02176 6.83203 5.66732V6.4451H9.16537V5.66732C9.16537 5.02176 8.64425 4.50065 7.9987 4.50065ZM5.66536 7.22287V11.1118H10.332V7.22287H5.66536ZM8.77648 9.16732C8.77648 9.5951 8.42648 9.9451 7.9987 9.9451C7.57092 9.9451 7.22092 9.5951 7.22092 9.16732C7.22092 8.73954 7.57092 8.38954 7.9987 8.38954C8.42648 8.38954 8.77648 8.73954 8.77648 9.16732Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default BlockedShotsIcon;
