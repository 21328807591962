import * as usersActions from '@actions/UsersActions';
import {loadStudentsV2} from '@actions/UsersActions';
import Filters from '@components/Filters/Filters';
import StateHelper from '@helpers/StateHelper';
import {useCurrentUserRole} from '@hooks/user';
import useStore from '@hooks/useStore';
import {userRoles} from '@src/constants';
import InputDefault from '@ui/Input';
import ButtonDefault from '@ui/MUI/Button';
import DefaultSortTable from '@ui/MUI/Table/Table';
import TablePaginationDefault from '@ui/MUI/TablePagination';
import PageTitleDefault from '@ui/PageTitleDefault';
import TabsDefault from '@ui/ReactComponent/ReactComponentTabs/TabsDefault/TabsDefault';
import TableDefault from '@ui/TableDefault';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import {isEmpty} from 'lodash';
import queryString from 'query-string';
import React, {useEffect, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import useDebouncedCallback from 'use-debounce/lib/callback';

import LocalPreloader from '../../preloader/LocalPreloader';
import PlayersHorizontalCard from '../../students/tools/PlayersHorizontalCard';
import ReturnFromArchiveModal from '../../students/tools/returnFromArchiveModal';

const filters = [{name: 'school'}, {name: 'group'}, {name: 'amplua'}];
const activeFilters = [{name: 'school'}, {name: 'group'}, {name: 'amplua'}];

export default function AllStudentsPanel(props) {
  const {titleVisible} = props;
  const apiRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const qs = queryString.parse(history.location.search);
  const [activeTab, setActiveTab] = useState(qs.tab ? (qs.tab === 'active' ? '0' : '1') : '0');
  const [searchString, setSearchString] = useState('');
  const [filterData, setFilterData] = useState({selectedPage: 0});
  const [selectedCount, setSelectedCount] = useState(25);
  const [returnFromArchiveModalState, setReturnFromArchiveModalState] = useState(false);
  const [activePlayer, setActivePlayer] = useState({});
  const {selectedPage} = filterData;
  const [debouncedSearch] = useDebouncedCallback(
    (a) => {
      setSearchString(a);
    },
    1000,
    [],
  );
  const [filter, setFilter] = useState({});
  const [activeFilter, setActiveFilter] = useState({});

  const userRole = useCurrentUserRole();

  const schools = useStore((state) => StateHelper.getSchools(state), shallowEqual);
  const schoolsOptions = schools?.map((school) => {
    return {value: school.id, label: school.name};
  });

  useEffect(() => {
    if (userRole === userRoles.methodist && apiRef?.current) {
      apiRef?.current?.updateOptions('school', schoolsOptions);
    }
  }, [userRole, apiRef?.current, schools?.length]);

  useEffect(() => {
    if (userRole === userRoles.methodist && apiRef?.current) {
      apiRef?.current?.updateValue('school', schoolsOptions?.[0]);
    }
  }, []);

  useEffect(() => {}, [userRole, schools?.length]);

  const {loading, isTeacherHasSchool, students, studentsCount} = useSelector((state) => {
    const currentUser = state.users.usersMap.get(state.users.currentUserId);
    const isTeacherHasSchool =
      userRole === userRoles.teacher ? StateHelper.isTeacherHasSchool(state, currentUser.id) : false;

    return {
      loading: state.users.loading,
      isTeacherHasSchool,
      students: state.users.students,
      studentsCount: state.users.studentsCount,
    };
  }, shallowEqual);

  useEffect(() => {
    setFilterData({selectedPage: 0});
  }, [filter.group, activeFilter.group]);

  useEffect(() => {
    const page_size = selectedCount;
    const page = selectedPage + 1;
    if (activeTab === '0') {
      if (userRole !== userRoles.admin && !isEmpty(activeFilter) && activeFilter?.school) {
        dispatch(
          loadStudentsV2({
            active: true,
            schoolId: activeFilter?.school,
            groupId: activeFilter?.group,
            hockeyRole: activeFilter?.amplua,
            q: searchString || null,
            page: page,
            limit: page_size,
          }),
        );
      }

      if (userRole === userRoles.admin) {
        dispatch(
          loadStudentsV2({
            active: true,
            schoolId: activeFilter?.school,
            groupId: activeFilter?.group,
            hockeyRole: activeFilter?.amplua,
            q: searchString || null,
            page: page,
            limit: page_size,
          }),
        );
      }
    }
    if (activeTab === '1') {
      if (userRole === userRoles.admin) {
        dispatch(
          loadStudentsV2({
            active: false,
            schoolId: filter?.school,
            groupId: filter?.group,
            hockeyRole: filter?.amplua,
            q: searchString || null,
            page: page,
            limit: page_size,
          }),
        );
      } else {
        dispatch(
          loadStudentsV2({
            active: false,
            schoolId: filter?.school,
            groupId: filter?.group,
            hockeyRole: filter?.amplua,
            q: searchString || null,
            page: page,
            limit: page_size,
          }),
        );
      }
    }
  }, [filter, activeFilter, selectedPage, selectedCount, searchString, activeTab]);

  const createTableTitle = () => {
    let result = 'Все игроки';
    if (userRole === userRoles.admin || userRole === userRoles.schools_admin) {
      result = (
        <TabsDefault
          defaultActiveKey={activeTab}
          tabs={[
            {
              label: 'Активные игроки',
            },
            {
              label: 'Архивированные игроки',
            },
          ]}
          onActiveKeyChange={(newTabIndex) => {
            if (newTabIndex === '0') {
              history.push('/students?tab=active');
            } else {
              history.push('/students?tab=archive');
            }
            setActiveTab(newTabIndex);
          }}
        />
      );
    } else {
      result += ` (${studentsCount || 0})`;
    }

    return result;
  };

  const columns = [
    {
      key: 'player',
      field: 'player',
      headerName: 'ФИО',
      minWidth: window.innerWidth >= 361 ? 306 : 200,
      align: 'left',
      headerAlign: 'left',
      sortable: true,
      sortComparator: (v1, v2, param1, param2) => {
        if (param1.value.lastName.toLowerCase() > param2.value.lastName.toLowerCase()) {
          return 1;
        }
        if (param1.value.lastName.toLowerCase() < param2.value.lastName.toLowerCase()) {
          return -1;
        }
        return 0;
      },
      renderCell: (params) => {
        if (window.innerWidth <= 360) {
          params.value.firstName = params.value.firstName[0] + '.';
        }
        return (
          <Link className="TableColumnLink" to={`/student/${params.value.id}`}>
            <PlayersHorizontalCard student={params.value} />
          </Link>
        );
      },
    },
    {
      key: 'school',
      field: 'school',
      headerName: 'Школа',
      flex: 0.5,
      minWidth: 310,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        if (param1.value.name.toLowerCase() > param2.value.name.toLowerCase()) {
          return 1;
        }
        if (param1.value.name.toLowerCase() < param2.value.name.toLowerCase()) {
          return -1;
        }
        return 0;
      },
      renderCell: (params) => {
        return params?.value?.name || '-';
      },
    },
    {
      key: 'group',
      field: 'group',
      headerName: 'Команда',
      flex: 0.5,
      minWidth: 220,
      align: 'left',
      headerAlign: 'left',
      sortComparator: (v1, v2, param1, param2) => {
        if (!param1?.value) {
          param1 = {...param1, value: {name: ''}};
        }
        if (!param2?.value) {
          param2 = {...param2, value: {name: '-'}};
        }
        if (param1.value.name.toLowerCase() > param2.value.name.toLowerCase()) {
          return 1;
        }
        if (param1.value.name.toLowerCase() < param2.value.name.toLowerCase()) {
          return -1;
        }
        return 0;
      },
      renderCell: (params) => {
        return params?.value?.name || '-';
      },
    },
  ];

  return (
    <Wrapper>
      {userRole !== userRoles.teacher || (userRole === userRoles.teacher && isTeacherHasSchool) ? (
        <>
          {titleVisible ? <PageTitleDefault text={'Игроки'} /> : null}
          <TableDefault
            className={'players-table'}
            content={
              <StudentsBlock loading={loading}>
                <LocalPreloader visible={loading} />
                <TopSearchPlaceholder>
                  <InputDefault
                    className={['Search', activeTab === '1' ? 'full-width-search' : '']}
                    placeholder={'Поиск'}
                    style={{
                      height: 40,
                      lineHeight: `20px`,
                      fontSize: 16,
                    }}
                    onChange={(evt) => {
                      debouncedSearch(evt.target.value);
                    }}
                  />
                </TopSearchPlaceholder>
                <FilterPlaceholder>
                  {activeTab === '0' ? (
                    <CustomFilters
                      withReduxStore
                      apiRef={apiRef}
                      filters={activeFilters}
                      filtersName="active_players"
                      isLegacy={true}
                      onChange={(filterValue, name) => {
                        if (activeTab === '0') {
                          setActiveFilter((prevState) => {
                            return {
                              ...prevState,
                              [name]: filterValue?.value,
                            };
                          });
                        }
                      }}
                    />
                  ) : null}
                  {activeTab === '1' ? (
                    <CustomFilters
                      withReduxStore
                      apiRef={apiRef}
                      filters={filters}
                      filtersName="archived_players"
                      isLegacy={true}
                      onChange={(filterValue, name) => {
                        if (activeTab === '1') {
                          setFilter((prevState) => ({
                            ...prevState,
                            [name]: filterValue?.value,
                          }));
                        }
                      }}
                    />
                  ) : null}
                </FilterPlaceholder>
                {studentsCount > 0 ? (
                  <>
                    <DefaultSortTable
                      columns={
                        activeTab === '0'
                          ? columns
                          : [
                              ...columns,
                              {
                                field: 'actions',
                                type: 'actions',
                                minWidth: 320,
                                getActions: (params) => {
                                  return [
                                    <ButtonDefault
                                      key="0"
                                      text="Разархивировать"
                                      variant="text"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setActivePlayer(params.row);
                                        setReturnFromArchiveModalState(true);
                                      }}
                                    />,
                                  ];
                                },
                              },
                            ]
                      }
                      hasMaxHeight={false}
                      noScrollX={window.innerWidth > 768}
                      rows={students?.map((student) => {
                        return {
                          id: student.id,
                          player: {
                            id: student.id,
                            firstName: student.firstName,
                            lastName: student.lastName,
                            playerNumber: student.playerNumber,
                            avatar: student.avatar,
                          },
                          school: student.school,
                          group: student.group,
                        };
                      })}
                    />
                    <TablePaginationDefault
                      count={studentsCount}
                      page={selectedPage}
                      rowsPerPage={selectedCount}
                      onPageChange={(event, newPage) => {
                        setFilterData((prev) => {
                          return {
                            ...prev,
                            selectedPage: newPage,
                          };
                        });
                      }}
                      onRowsPerPageChange={(e) => {
                        setSelectedCount(parseInt(e.target.value, 10));
                        setFilterData({selectedPage: 0});
                      }}
                    />
                  </>
                ) : (
                  <TableEmptyBlock text="Список игроков пуст" />
                )}
              </StudentsBlock>
            }
            title={createTableTitle()}
          />
        </>
      ) : (
        <TableEmptyBlock text="Список игроков пуст" />
      )}
      <ReturnFromArchiveModal
        returnFromArchiveModalState={returnFromArchiveModalState}
        onClose={() => {
          setReturnFromArchiveModalState(false);
        }}
        onSave={async () => {
          setReturnFromArchiveModalState(false);
          await dispatch(
            usersActions.updateStudent({
              active: true,
              id: activePlayer.id,
              schoolId: activePlayer?.school?.id,
              groupId: activePlayer?.group?.id,
            }),
          );
          await dispatch(
            loadStudentsV2({
              active: false,
              schoolId: activeFilter?.school,
              groupId: activeFilter?.group,
              hockeyRole: activeFilter?.amplua,
              q: searchString || null,
              page: 1,
              limit: selectedCount,
            }),
          );
          setActivePlayer({});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .TableColumnLink {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .LinesAvailablePlayerAvatar {
    margin-left: 0;
  }
  .MuiDataGrid {
    &-root {
      .MuiDataGrid {
        &-columnSeparator {
          display: none;
        }
        &-columnHeader {
          padding-left: 34px;
          padding-right: 34px;
        }
        &-cell {
          padding-left: 34px;
          padding-right: 34px;
        }
        &-actionsCell {
          .MuiButton {
            &-root {
              font: 600 normal 14px/40px Proxima Nova !important;
              color: #901 !important;
              text-decoration: underline;
              text-transform: none;
              border: 0;
              transition: text-decoration 0.33s;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
  .ScrollbarsCustom {
    @media screen and (min-width: 993px) {
      max-height: calc(100vh - 375px);
    }
  }
`;
const StudentsBlock = styled.div`
  position: relative;
  max-height: ${(props) => (props.loading ? '60vh' : '100%')};
  border-radius: ${(props) => (props.loading ? '0 0 6px 6px' : '6px')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
`;

const TopSearchPlaceholder = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px 34px 10px;
  background: white;

  & > .Search {
    flex: 0 0 70%;
    max-width: 70%;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 60px;
    font: normal normal 14px/20px 'Proxima Nova';
    color: #cab791;
    @media (min-width: 576px) and (max-width: 767px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media screen and (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &:focus {
      border: 1px solid #cab791;
    }

    &:hover {
      border: 1px solid #cab791;
    }

    &::placeholder {
      font: normal normal 14px/20px 'Proxima Nova';
      color: #cab791;
      opacity: 1;
    }
  }
`;

const FilterPlaceholder = styled.div`
  background: white;
`;

const CustomFilters = styled(Filters)`
  box-shadow: 0 0 0 0;
`;
