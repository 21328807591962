import React from 'react';
import styled from 'styled-components';

export default function TeamPart(props) {
  const {headline, content} = props;
  return (
    <LinesViewBlock className={'LinesViewBlock'} id={'printme'}>
      <LinesTab className={'LinesTab'}>
        <>{headline}</>
        {content}
      </LinesTab>
    </LinesViewBlock>
  );
}

const LinesViewBlock = styled.div`
  background-color: #fff;
  flex: 0 0 65%;
  max-width: 65%;
  border-right: 1px solid #dddad4;
  box-sizing: border-box;
  @media screen and (max-width: 1440px) {
    flex: 0 0 70%;
    max-width: 70%;
  }
`;
const LinesTab = styled.div``;
