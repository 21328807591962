import {
  cleanPsychotestProfile,
  loadPlayerPsychoTestingForm,
  postPlayerPsychoTestingForm,
} from '@actions/PsychoTestingActions';
import {Button} from '@ui/Button';
import {RED} from '@ui/Colors';
import PageTitleDefault from '@ui/PageTitleDefault';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import PsychoTestingAPI from '../../../api/PsychoTestingAPI';
import Filters from '../../Filters/Filters';
import LocalPreloader from '../../preloader/LocalPreloader';
import PsychoTestFormItem from '../tools/PsychoTestFormItem';

export default function IndividualPlanEstimationPanel() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    group: null,
    school: null,
    student: null,
    report_dates: null,
  });

  useEffect(() => {
    dispatch(cleanPsychotestProfile());
  }, []);

  const loading = useSelector((state) => state.psychoTesting.loading);
  const testingForm = useSelector((state) => state.psychoTesting.testingForm || {});
  const student = useSelector((state) => state.form?.psychoTest?.student);

  useEffect(() => {
    if (filter.report_dates && filter.student) {
      const request = {
        playerId: filter.student,
        dates: filter.report_dates,
      };
      dispatch(loadPlayerPsychoTestingForm(request));
    }
  }, [filter.report_dates, filter.student]);

  const formDataValidation = () => {
    dispatch(postPlayerPsychoTestingForm(filter, testingForm));
  };

  const getTabs = () => {
    let content = (
      <TableContent loading={loading}>
        <LocalPreloader visible={loading} />
        <TableHeadline>
          <TableColumn>
            <ColumnName>Навык психологического профиля</ColumnName>
          </TableColumn>
          <TableColumn>
            <ColumnName>Оценка</ColumnName>
          </TableColumn>
        </TableHeadline>

        <TableBody>
          {testingForm.parameters?.map((skill, index) => {
            return <PsychoTestFormItem filter={filter} key={index} skill={skill} skillIndex={index} tab={'default'} />;
          })}
        </TableBody>

        <BottomPanel>
          <Buttons>
            <ButtonsBlock>
              <Button
                style={{
                  marginRight: 17,
                }}
                onClick={() => {
                  formDataValidation();
                }}
              >
                Сохранить
              </Button>
            </ButtonsBlock>
            <ButtonsBlock>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  border: `1px solid ${RED}`,
                  color: `${RED}`,
                }}
                onClick={() => {
                  history.push('/');
                }}
              >
                Закрыть
              </Button>
            </ButtonsBlock>
          </Buttons>
        </BottomPanel>
      </TableContent>
    );
    return content;
  };

  const apiRef = useRef(null);

  const filters = [
    {name: 'school'},
    {name: 'group'},
    {name: 'student'},
    {
      name: 'report_dates',
      value: null,
      label: 'Дата отчета',
      placeholder: 'Выберите дату отчета',
      options: [],
      isLoading: false,
      isVisable: !!student,
    },
  ];

  useEffect(() => {
    if (filter.student) {
      if (!filter.report_dates) {
        apiRef.current.updateVisability('report_dates', true);
      }
      apiRef.current.updateLoading('report_dates', true);
      PsychoTestingAPI.getPlayerReportDates({student: filter.student}).then((data) => {
        apiRef.current.updateOptions(
          'report_dates',
          data.data?.dates.map((date) => ({label: date, value: date})) || [],
        );
        apiRef.current.updateLoading('report_dates', false);
      });
    }
    if (!filter.student) {
      if (filter.report_dates) {
        apiRef.current.updateVisability('report_dates', false);
      }
    }
  }, [filter.student]);

  return (
    <Wrapper>
      <PageTitleDefault text="Психологический профиль" />
      <CustomFilters
        withReduxStore
        apiRef={apiRef}
        filters={filters}
        filtersName="psychoTest"
        isLegacy={true}
        onChange={(filterValue, name) => {
          if (filterValue) {
            if (!filterValue.value && filterValue.length > 3) return;
            setFilter((prevState) => ({
              ...prevState,
              [name]: filterValue.value || filterValue.map((date) => date.value).join(','),
            }));
          }
        }}
      />

      {Object.values(testingForm).length ? (
        getTabs()
      ) : (
        <TableEmptyBlock text={'Недостаточно данных. Для отображения формы выберите школу, команду и игрока.'} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .PhysicalTestingFilter {
    margin-bottom: 5px;
    border-radius: 6px;
  }
  .TabNamesItems {
    justify-content: flex-start;
    margin-bottom: 0;
    padding-left: 34px;
    background: #fffaed;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.3);
    border-radius: 6px 6px 0px 0px;
    .TabName {
      margin-right: 24px;
      color: #990011;
      text-transform: uppercase;
    }
    @media screen and (max-width: 991px) {
      padding-top: 10px;
    }
  }
  .Tab {
    .ContentPlaceholder {
      width: 100%;
    }
  }
`;
const CustomFilters = styled(Filters)`
  margin-bottom: 5px;
`;

const TableContent = styled.div`
  position: relative;
  max-height: ${(props) => (props.loading ? '55vh' : 'none')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
  border-radius: ${(props) => (props.loading ? '6px' : 0)};
`;

const TableHeadline = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: 30px;
  background-color: white;
  border-top: 1px solid #dddad4;
  border-bottom: 1px solid #dddad4;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 576px) {
    display: none;
  }
`;
const TableColumn = styled.div`
  box-sizing: border-box;
  padding-left: 34px;
  padding-right: 30px;
  &:last-child {
    padding-left: 0;
  }
  &:first-child {
    flex: 0 0 30%;
    max-width: 30%;
    @media (min-width: 1025px) and (max-width: 1200px) {
      flex: 0 0 40%;
      max-width: 40%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media screen and (max-width: 480px) {
      flex: 0 0 70%;
      max-width: 70%;
    }
  }
  &:nth-child(2) {
    flex: 0 0 70%;
    max-width: 70%;
    @media (min-width: 1025px) and (max-width: 1200px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      flex: 0 0 40%;
      max-width: 40%;
      text-align: center;
    }
    @media screen and (max-width: 480px) {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }
`;
const ColumnName = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 12px/30px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
  @media screen and (max-width: 768px) {
    line-height: 14px;
  }
`;
const TableRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 18px 0 34px;
  margin: 0 -30px;
  background-color: #fafafa;

  :nth-child(even) {
    background-color: white;
  }
`;
const TableBody = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  ${TableRow} {
    ${TableColumn} {
      margin-bottom: 14px;
    }
  }
`;
const BottomPanel = styled.div`
  position: relative;
  width: 100%;
  background: white;
  box-shadow: 4px 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;
`;
const ButtonsBlock = styled.div`
  @media screen and (max-width: 768px) {
    text-align: center;
  }
  > ${Button} {
    &.hiddenButton {
      display: none;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 220px;
      margin-bottom: 15px;
    }
    :last-of-type {
      @media screen and (max-width: 768px) {
        margin-left: 0 !important;
      }
    }
  }
`;
const Buttons = styled.div`
  padding: 10px 20px;
  padding-left: 34px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    padding: 20px 20px 5px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  > ${ButtonsBlock} ${Button} {
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 220px;
      margin-right: 0 !important;
    }
  }
`;
