import Field from '@common/FormField';
import {Box} from '@ui/MUI';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import React, {useMemo} from 'react';
import {Controller, useWatch} from 'react-hook-form';

import UploadMaterialFile from './components/UploadMaterialFile';

const imageTypes = '.png, .jpg, .jpeg, .webp';
const fileTypes = '.csv, .docx, .pdf, .xls, .xlsx, .pptx';
const videoTypes = '.mp4, .webm';

const UploadOrLink = () => {
  const type = useWatch({name: 'type'});
  const file = useWatch({name: 'storage'});
  const link = useWatch({name: 'link'});
  const {canHaveFile, canHaveLink, canHaveVideo} = type || {};
  const isImage = type?.slug === 'image';
  const isFile = type?.slug === 'file';
  const isVideo = type?.slug === 'video';

  const description = useMemo(() => {
    let type = '';
    if (isFile) {
      type = fileTypes;
    }

    if (isVideo) {
      type = videoTypes;
    }

    if (isImage) {
      type = imageTypes;
    }

    if (!type) return null;

    return `Доступные форматы: ${type}`.replace(/\./g, '');
  }, [isImage, isFile, isVideo]);

  const accept = useMemo(() => {
    if (isFile) return fileTypes;
    if (isImage) return imageTypes;
    if (isVideo) return videoTypes;
  }, [isImage, isFile, isVideo]);

  return type ? (
    <Stack spacing={3}>
      {canHaveLink && (
        <Box>
          <Field
            disabled={!!file}
            isRequired={!file && canHaveLink}
            label="Ссылка на материал"
            name="link"
            type="input"
          />
          {(canHaveFile || canHaveVideo) && <Typography variant="body">или</Typography>}
        </Box>
      )}
      {(canHaveFile || canHaveVideo) && (
        <Controller
          name="storage"
          render={({field, fieldState: {error, ...fieldState}}) => (
            <UploadMaterialFile
              accept={accept}
              description={description}
              disabled={!!link}
              label="загрузить файл"
              maxSize={isVideo && 300}
              {...field}
              {...fieldState}
            />
          )}
          rules={{
            required: !link && (canHaveFile || canHaveVideo),
          }}
        />
      )}
    </Stack>
  ) : null;
};

export default UploadOrLink;
