import ProgressCell from '@common/Statistics/PlayerStatistics/ProgressCell';
import TableTemplate from '@common/Statistics/PlayerStatistics/TableTemplate';
import TopFiveItem from '@common/Statistics/PlayerStatistics/TopFiveItem';
import {customTableComparator} from '@src/helpers';
import {Tooltip, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

import SingleProgressCell from '../../SingleProgressCell';

const columns = [
  {
    id: 'passesCount',
    accessorKey: 'passesCount',
    header: () => {
      return (
        <Tooltip title="Передачи">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            П
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    id: 'accuratePasses',
    accessorKey: 'accuratePasses',
    header: () => {
      return (
        <Tooltip title="Обострающие передачи">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            П+
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {position: 'relative', textAlign: 'center'},
    cell: ({getValue}) => {
      return <SingleProgressCell percent={Math.abs(getValue()?.value)} value={getValue()?.value} />;
    },
    sortingFn: customTableComparator,
  },
  {
    id: 'accuratePassesPercent',
    accessorKey: 'accuratePassesPercent',
    header: () => {
      return (
        <Tooltip title="% обостряющих передач от всех передач">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            П+%
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {position: 'relative', textAlign: 'center'},
    cell: ({getValue}) => <ProgressCell isIndividual percent={getValue()?.percent || 0} value={getValue()?.value} />,
    sortingFn: customTableComparator,
  },
  {
    id: 'dangerousPassesTaken',
    accessorKey: 'dangerousPassesTaken',
    header: () => {
      return (
        <Tooltip title="Приемов обостряющих передач">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            ПП
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    id: 'accurateOZPasses',
    accessorKey: 'accurateOZPasses',
    header: () => {
      return (
        <Tooltip title="Обостряющие передачи в опасную зону">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            П+ Оз
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    id: 'OZPassesCount',
    accessorKey: 'OZPassesCount',
    header: () => {
      return (
        <Tooltip title="Всего передач в опасную зону">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            П Оз
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    id: 'accurateOZPassesPercent',
    accessorKey: 'accurateOZPassesPercent',
    header: () => {
      return (
        <Tooltip title="% обостряющих передач в опасную зону от всех передач в опасную зону">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            П+% Оз
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => <ProgressCell isIndividual percent={getValue()?.percent || 0} value={getValue()?.value} />,
    cellStyle: {position: 'relative'},
    sortingFn: customTableComparator,
  },
  {
    id: 'dangerousOZPassesTaken',
    accessorKey: 'dangerousOZPassesTaken',
    header: () => {
      return (
        <Tooltip title="Приемов обостряющих передач в опасной зоне">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            ПП Оз
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
];
function PassesTab({isLoad, isLoading, data, hasError, hasMatchFilter}) {
  const [sorting, setSorting] = useState([{asc: true, id: 'accuratePasses'}]);
  const rowsData = useMemo(
    () =>
      data?.map((item) => {
        return {
          student: item?.student,
          accuratePasses: item?.accuratePasses,
          passesCount: item?.passesCount,
          accuratePassesPercent: item?.accuratePassesPercent || {value: 0},
          dangerousPassesTaken: item?.dangerousPassesTaken,
          accurateOZPasses: item?.accurateOZPasses,
          OZPassesCount: item?.OZPassesCount,
          accurateOZPassesPercent: item?.accurateOZPassesPercent,
          dangerousOZPassesTaken: item?.dangerousOZPassesTaken,
        };
      }),
    [data],
  );

  return (
    <TableTemplate
      columns={columns}
      hasError={hasError}
      hasMatchFilter={hasMatchFilter}
      isLoad={isLoad}
      isLoading={isLoading}
      rowsData={rowsData}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
}

PassesTab.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PassesTab;
