import GamesCountButtons from '@components/apps/PlayerPersonalFile/components/Statistics/components/GamesCountButtons';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {GAMES_COUNT_BUTTONS} from '@components/apps/PlayerPersonalFile/constants';
import {shallowEqual} from '@helpers/shallowEqual';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectStatFilters} from '@selectors/playerPersonalFile';
import {parseFormToGetValue} from '@src/helpers';
import {Box, Stack, Typography} from '@ui/MUI';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';

GoaltenderZone.propTypes = {
  title: PropTypes.string,
  Component: PropTypes.func,
};

function GoaltenderZone({
  title,
  Component = <></>,
  loader = () => {},
  cleaner = () => {},
  selector = () => {},
  ...props
}) {
  const [gameCount, setGameCount] = useState(GAMES_COUNT_BUTTONS[0].value);
  const playerId = useParams()?.id;
  const dispatch = useStoreDispatch();
  const filters = useStore(selectStatFilters, shallowEqual);
  const requestFilters = parseFormToGetValue({...filters, isFinal: Boolean(filters?.tournamentId?.isFinal)});
  const {data, isLoading} = useStore(selector);
  useDeepEffect(() => {
    if (playerId && requestFilters?.isFilled) dispatch(loader({playerId, ...requestFilters, gameCount}));
    return () => {
      dispatch(cleaner());
    };
  }, [playerId, requestFilters, gameCount]);
  return (
    <Wrapper {...props} p="0">
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={1}
        justifyContent="space-between"
        p={{xxs: 3, xs: 4}}
      >
        <Typography variant="h4">{title}</Typography>
        <GamesCountButtons gamesCount={gameCount} setGamesCount={setGameCount} />
      </Stack>
      <Box p={{xxs: 3, xs: 4}} pt={1}>
        <Component isLoading={isLoading} {...(!isEmpty(data) ? data : {})} />
      </Box>
    </Wrapper>
  );
}

export default GoaltenderZone;
