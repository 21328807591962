import Field from '@common/FormField';
import {getOptionLabel, isOptionEqualToValue} from '@components/apps/TrainingControlProcess/constant';
import {useAge, useTheme} from '@components/apps/TrainingControlProcess/hooks/api';
import useStore from '@hooks/useStore';
import useUsers from '@hooks/useUsers';
import {rolesMap, userRoles} from '@src/constants';
import Grid from '@ui/MUI/Grid';
import React, {useEffect, useMemo} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

const FormContentMain = () => {
  const theme = useTheme();
  const age = useAge();
  const users = useUsers();
  const id = useWatch({name: 'id'});
  const {userRole, id: userId} = useStore((state) => state.users.currentUser);
  const {setValue} = useFormContext();

  const canEditAuthor = (!id && userRole !== userRoles.teacher) || [userRoles.admin].includes(userRole);

  // так лучше не делать:)
  // вынесу потом в рефакторинг
  const teachers = useMemo(() => {
    if (users.isLoading) return [];

    const filtered = users.data.filter((user) => user.userRole === 'teacher');
    return filtered.map((user) => ({
      id: user.id,
      userRole: user.userRole,
      name: `${rolesMap[user.userRole]}, ${user.firstName} ${user.lastName}`,
    }));
  }, [users]);

  useEffect(() => {
    if (userRole === userRoles.teacher && !id) {
      setValue('author', {id: userId});
    }
  }, []);

  return (
    <Grid container spacing={4}>
      {canEditAuthor && (
        <Field
          isRequired
          getOptionLabel={getOptionLabel}
          isLoading={users.isLoading}
          isOptionEqualToValue={isOptionEqualToValue}
          label="Автор упражнения"
          md={6}
          name="author"
          options={teachers}
        />
      )}
      <Grid item xs={6} />
      <Field isRequired label="Название упражнения" name="name" type="input" />
      <Field
        isRequired
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        label="Тематика"
        md={6}
        name="theme"
        options={theme.data}
      />
      <Field
        isRequired
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        label="Возраст"
        md={3}
        name="age"
        options={age.data}
      />
      <Field isRequired label="Правила выполнения" name="description" type="input" />
    </Grid>
  );
};

export default FormContentMain;
