import {getGroupById} from '@actions/GroupsActions';
import preview from '@assets/img/video-preview.jpg';
import AddEditModal from '@components/apps/TechnicalSkillsVideoEstimation/components/AddEditModal';
import Card from '@components/apps/TechnicalSkillsVideoEstimation/components/VideoList/components/Card';
import {mock} from '@components/apps/TechnicalSkillsVideoEstimation/components/VideoList/mock';
import {shallowEqual} from '@helpers/shallowEqual';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectVideos} from '@selectors/videoAnalytics';
import {clearVideos, loadVideos} from '@slices/videoAnalytics';
import {parseFormToGetValue} from '@src/helpers';
import {Box, Stack, Typography} from '@ui/MUI';
import Pagination from '@ui/MUI/Pagination';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import Filters from '../Filters';

const limit = 12;
export default function VideoList({isStudent, isPersonalFile}) {
  const {schoolId, groupId} = useParams();
  const [qs, set] = useQueryString();
  const dispatch = useStoreDispatch();
  const {group, id} = useStore((state) => state.users?.student);
  const currentGroup = useStore((state) => {
    if (isStudent) {
      return state.users.currentUser.groups[0];
    }
    if (isPersonalFile) {
      return group;
    }
    return state.groups.group;
  }, shallowEqual);

  const user = useStore((state) => {
    if (isStudent) {
      return state.users.currentUser;
    }
  }, shallowEqual);

  const memoMock = useDeepMemo(() => mock(qs.count), [qs]);

  const {data, isLoading, isLoad, error} = useStore(selectVideos, shallowEqual);

  const [filter, setFilter] = useState({
    students: [],
    tags: [],
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (qs?.page) {
      setPage(Number(qs?.page));
    }
  }, [qs?.page]);

  useEffect(() => {
    if (groupId) {
      dispatch(getGroupById(groupId));
    }
  }, [groupId]);

  useDeepEffect(() => {
    getVideoList();
    return () => {
      dispatch(clearVideos());
    };
  }, [filter, page]);

  function getVideoList() {
    const parsedRequest = parseFormToGetValue(filter);
    dispatch(
      loadVideos({
        ...parsedRequest,
        groups: [groupId],
        students: (user?.id && [user?.id]) || [id] || parsedRequest?.students,
        page,
        limit,
      }),
    );
  }

  return (
    <>
      {!isPersonalFile && (
        <StyledPageTitleDefault className="PageTitle" text={!isStudent ? currentGroup.name : 'Видеоаналитика'} />
      )}
      <Stack spacing={1}>
        {!isPersonalFile && (
          <Filters
            filters={filter}
            groupId={groupId}
            isStudent={isStudent}
            onChange={setFilter}
            onClick={() => setIsModalOpen(true)}
          />
        )}
        <Wrapper p={4}>
          {(isLoad && !data.videoAnalytics?.length) || error ? (
            <EmptyBlock>
              <Typography variant="body1">Видео еще не добавлены</Typography>
            </EmptyBlock>
          ) : (
            <Stack direction="row" flexWrap="wrap" gap={2} sx={{pointerEvents: isLoading ? 'none' : void 0}}>
              {(isLoading ? memoMock : data.videoAnalytics).map((video, index) => (
                <Card
                  isLoading={isLoading}
                  key={index}
                  light={isPersonalFile ? true : preview}
                  previewLink={
                    isPersonalFile
                      ? `/videos/${schoolId}/${groupId}/${video.id}?studentId=${id}&isPreview=${isPersonalFile}`
                      : `/videos/${schoolId}/${groupId}/${video.id}?isStudent=${isStudent || ''}`
                  }
                  video={video}
                  videoLink={
                    isPersonalFile
                      ? `/videos/${schoolId}/${groupId}/${video.id}?studentId=${id}&isPreview=${isPersonalFile}`
                      : `/videos/${!user ? schoolId : user?.schoolId}/${
                          !user ? groupId : user?.groups?.[0]?.objectId
                        }/${video.id}?isStudent=${isStudent || ''}`
                  }
                  width={(theme) => ({
                    lg: `calc(100% / 4 - ${theme.spacing(2)}  + (${theme.spacing(2)} / 4))`,
                    md: `calc(100% / 3 - ${theme.spacing(2)}  + (${theme.spacing(2)} / 3))`,
                    xs: `calc(50% - ${theme.spacing(2)}  + (${theme.spacing(2)} / 2))`,
                    xxs: '100%',
                  })}
                />
              ))}
            </Stack>
          )}
          {data?.totalPages > 1 && (
            <Pagination
              count={data.totalPages}
              page={page}
              onChange={(e, page) => {
                set({...qs, page: page});
              }}
            />
          )}
        </Wrapper>
        <AddEditModal
          getVideoList={getVideoList}
          groupId={groupId}
          isOpen={isModalOpen}
          title="Новое видео"
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      </Stack>
    </>
  );
}

const EmptyBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75vh;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;

  .MuiButton {
    &-root {
      svg {
        path {
          fill: white;
        }
      }
    }
  }
`;

const Wrapper = styled(Box)`
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
`;

const StyledPageTitleDefault = styled((props) => <PageTitleDefault {...props} />)`
  margin-left: 0;
`;
