import {Stack} from '@ui/MUI';
import React from 'react';

import CreateModal from './components/Create';
import {Filters} from './components/Filters';
import {Table} from './components/Table';

const PlayersTab = () => {
  return (
    <Stack spacing={3}>
      <CreateModal />
      <Filters />
      <Table />
    </Stack>
  );
};

export default PlayersTab;
