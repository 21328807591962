import React, {useEffect, useState} from 'react';

import RadarChart from '../../charts/RadarChart';

export default function PsychoProfileChart(props) {
  const {playerPsychotestReport} = props;

  const [chartData, setChartData] = useState({});
  const generateChartData = (data) => {
    let radius;

    if (window.innerWidth <= 576) {
      radius = 160;
    } else {
      radius = 150;
    }
    if (window.innerWidth <= 480) {
      radius = 120;
    }

    const chartData = data.map((item) => item.parameters);

    const chartSeasons = data.map((item) => item.date);

    const chartRadarOptions = {
      legend: {
        icon: 'circle',
        data: chartSeasons.map((date) => `Отчет за ${date}`),
        bottom: '10%',
      },
      triggerEvent: true,
      radar: {
        center: window.innerWidth <= 480 ? ['50%', '160px'] : ['50%', '250px'],
        radius: radius,
        splitNumber: 5,
        name: {
          formatter: (params) => {
            let result = '';

            if (params.length > 28) {
              let words = [];
              if (window.innerWidth <= 576 && params.includes('-')) {
                words = params.split(/ |-/);
              } else {
                words = params.split(' ');
              }
              words.map((word, index) => {
                if (index !== 0 && index % 2 === 0) {
                  result += '\n';
                }
                result += word + ' ';
              });
            } else {
              result = params;
            }
            return result;
          },
          padding: window.innerWidth >= 481 ? 8 : [0, 0, 0, -10],
          textStyle: {
            fontFamily: 'Proxima Nova',
            fontWeight: 'normal',
            fontSize: 14,
            lineHeight: 16,
            color: '#2C2C2C',
          },
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            color: '#C4C4C4',
          },
        },
        indicator: [],
      },
      color: ['#2f4554', '#c23531', '#54DE00'],
      series: [
        {
          type: 'radar',
          data: [],
        },
      ],
    };
    chartData.map((radar, index) => {
      chartRadarOptions.series[0].data.push({
        areaStyle: {
          opacity: 0.5,
        },
        name: chartRadarOptions.legend.data[index],
        value: radar.map((param) => param.value),
        valuesForLabels: radar.map((param) => {
          return {
            key: param.key,
            label: param.label,
            notConvertedValue: param.value !== null ? param.notConvertedValue : '',
            value: param.value,
          };
        }),
      });
      const shortNames = {
        simple_incentive: 'РПС',
        complex_incentive: 'РСС',
        foresight: 'ПВП',
        attention: 'Вним.',
        thinking: 'Мышл.',
      };
      chartRadarOptions.radar.indicator = radar.map((item) => ({
        text: window.innerWidth >= 481 ? ` ${item.label} ` : shortNames[item.key],
        max: 10,
        key: item.key,
      }));
    });
    if (window.innerWidth <= 480) {
      chartRadarOptions.radar.name.textStyle.fontSize = 12;
      chartRadarOptions.radar.name.textStyle.lineHeight = 12;
      chartRadarOptions.legend = {
        ...chartRadarOptions.legend,
        bottom: 0,
      };
    }
    setChartData(chartRadarOptions);
  };

  useEffect(() => {
    if (Object.values(playerPsychotestReport).length) {
      generateChartData(playerPsychotestReport);
    }
  }, [playerPsychotestReport]);

  return (
    <div className="ColumnRadar">
      <RadarChart
        options={{
          ...chartData,
          label: {show: true},
          emphasis: {
            label: {
              padding: 4,
              backgroundColor: 'rgba(44, 44, 44, 0.85)',
              borderRadius: 6,
              fontWeight: 600,
              fontSize: 12,
              lineHeight: 18,
              color: 'white',
              formatter: (params) => {
                const key = params.data.valuesForLabels[params.dimensionIndex].key;
                return key !== 'thinking'
                  ? `${params.value} \r\n ${params.data.valuesForLabels[params.dimensionIndex].notConvertedValue}`
                  : params.value;
              },
            },
          },
        }}
      />
    </div>
  );
}
