import {useMemo, useState} from 'react';

import useEvent from './useEvent';

const useToggle = ({onToggle, onToggleOff, initialState = false} = {}) => {
  const [isToggle, setIsToggle] = useState(initialState);

  const on = useEvent((payload) => {
    setIsToggle(true);
    if (payload && !payload.target) {
      onToggle && onToggle(payload);
    }
  });

  const off = useEvent((payload) => {
    setIsToggle(false);
    if (payload && !payload.target) {
      onToggleOff && onToggleOff(payload);
    }
  });

  return useMemo(() => ({isToggle, off, on}), [isToggle, on, off]);
};

export default useToggle;
