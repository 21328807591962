import BlockedShotsIcon from '@common/Statistics/ThrowsMap/BlockedShotsIcon';
import GoalIcon from '@common/Statistics/ThrowsMap/GoalIcon';
import MissedShotsIcon from '@common/Statistics/ThrowsMap/MissedShotsIcon';
import ShotInTargetIcon from '@common/Statistics/ThrowsMap/ShotInTargetIcon';

export const SHOTS_SW_BUTTONS = [
  {
    id: 'all',
    name: 'Все броски',
  },
  {
    engNameShort: 'GL',
    icon: GoalIcon,
  },
  {
    engNameShort: 'SG',
    icon: ShotInTargetIcon,
  },
  {
    engNameShort: 'BS',
    icon: BlockedShotsIcon,
  },
  {
    engNameShort: 'SW',
    icon: MissedShotsIcon,
  },
  {
    engNameShort: 'USA',
    icon: MissedShotsIcon,
  },
];

export const MAPS_SIZE = {
  x: 60,
  y: 28,
};

export const ZONE_LABELS = {
  circleOne: {label: 'Точка 1', value: 'circleOne', zone: 'Левая'},
  circleTwo: {label: 'Точка 2', value: 'circleTwo', zone: 'Левая'},
  circleThree: {label: 'Точка 3', value: 'circleThree', zone: 'Средняя'},
  circleFour: {label: 'Точка 4', value: 'circleFour', zone: 'Средняя'},
  circleFive: {label: 'Точка 5', value: 'circleFive', zone: 'Средняя'},
  circleSix: {label: 'Точка 6', value: 'circleSix', zone: 'Средняя'},
  circleSeven: {label: 'Точка 7', value: 'circleSeven', zone: 'Средняя'},
  circleEight: {label: 'Точка 8', value: 'circleEight', zone: 'Правая'},
  circleNine: {label: 'Точка 9', value: 'circleNine', zone: 'Правая'},
};
