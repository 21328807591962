import ChildrenField from '@common/ChildrenField';
import {Box, Stack, TextField, Typography} from '@ui/MUI';
import React, {memo} from 'react';

import FormItem from '../../../FormItem';
import RadioRange from '../../../RadioRange';
import InputMax from './components/InputMax';

const FormBlock = ({index, time, repeat}) => {
  return (
    <Box>
      <Stack direction="row" spacing={5}>
        <FormItem label="Повторов">
          <ChildrenField isRequired name={`exercises.${index}.repeatEstimate`}>
            <InputMax endSlot={<Typography>/ {repeat}</Typography>} maxLength={3} width={40} />
          </ChildrenField>
        </FormItem>
        <FormItem label="Время, мин">
          <ChildrenField isRequired name={`exercises.${index}.timeEstimate`}>
            <InputMax endSlot={<Typography>/ {time}</Typography>} maxLength={3} width={40} />
          </ChildrenField>
        </FormItem>
      </Stack>
      <FormItem label="Качество выполнения воспитанниками заявленных навыков" mb={5} mt={2} spacing={2}>
        <ChildrenField isRequired name={`exercises.${index}.qualityEstimate`}>
          <RadioRange max={3} />
        </ChildrenField>
      </FormItem>
      <ChildrenField name={`exercises.${index}.comment`}>
        <TextField label="Комментарий" />
      </ChildrenField>
    </Box>
  );
};

export default memo(FormBlock);
