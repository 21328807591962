import React from 'react';

function Schedule() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M14.6357 10.814C14.452 10.6327 14.154 10.6327 13.9702 10.814L13.4657 11.3119L12.9612 10.814C12.7774 10.6327 12.4795 10.6327 12.2957 10.814C12.1119 10.9953 12.1119 11.2894 12.2957 11.4707L12.8002 11.9686L12.2957 12.4665C12.1119 12.6478 12.1119 12.9418 12.2957 13.1232C12.4795 13.3046 12.7774 13.3046 12.9612 13.1232L13.4657 12.6253L13.9702 13.1232C14.154 13.3046 14.4519 13.3046 14.6357 13.1232C14.8195 12.9419 14.8195 12.6478 14.6357 12.4665L14.1312 11.9686L14.6357 11.4707C14.8195 11.2894 14.8195 10.9953 14.6357 10.814Z"
          fill="#626262"
        />
        <path
          d="M15.5882 1.4745H14.1608V0.964389C14.1608 0.707922 13.9501 0.5 13.6902 0.5C13.4303 0.5 13.2196 0.707922 13.2196 0.964389V1.4745H11.034V0.964389C11.034 0.707922 10.8233 0.5 10.5634 0.5C10.3035 0.5 10.0928 0.707922 10.0928 0.964389V1.4745H7.90717V0.964389C7.90717 0.707922 7.69647 0.5 7.43658 0.5C7.17669 0.5 6.96599 0.707922 6.96599 0.964389V1.4745H4.78039V0.964389C4.78039 0.707922 4.56969 0.5 4.3098 0.5C4.04991 0.5 3.83922 0.707922 3.83922 0.964389V1.4745H2.41176C1.63332 1.4745 1 2.09948 1 2.86767V14.1068C1 14.875 1.63332 15.5 2.41176 15.5H15.5882C16.3667 15.5 17 14.875 17 14.1068C17 13.8894 17 3.41717 17 2.86767C17 2.09945 16.3667 1.4745 15.5882 1.4745ZM1.94118 2.86767C1.94118 2.61161 2.15228 2.40328 2.41176 2.40328H3.83922V2.84256C3.83922 3.09903 4.04991 3.30695 4.3098 3.30695C4.56969 3.30695 4.78039 3.09903 4.78039 2.84256V2.40328H6.96602V2.84256C6.96602 3.09903 7.17672 3.30695 7.43661 3.30695C7.6965 3.30695 7.9072 3.09903 7.9072 2.84256V2.40328H10.0928V2.84256C10.0928 3.09903 10.3035 3.30695 10.5634 3.30695C10.8233 3.30695 11.034 3.09903 11.034 2.84256V2.40328H13.2196V2.84256C13.2196 3.09903 13.4303 3.30695 13.6902 3.30695C13.9501 3.30695 14.1608 3.09903 14.1608 2.84256V2.40328H15.5882C15.8477 2.40328 16.0588 2.61161 16.0588 2.86767V4.23483H1.94118V2.86767ZM15.5882 14.5712H2.41176C2.15228 14.5712 1.94118 14.3629 1.94118 14.1068V5.16361H16.0588V14.1068C16.0588 14.3629 15.8477 14.5712 15.5882 14.5712Z"
          fill="#626262"
        />
        <path
          d="M4.56273 6.2869C3.68696 6.2869 2.97449 6.98999 2.97449 7.85421C2.97449 8.71844 3.68696 9.42152 4.56273 9.42152C5.43849 9.42152 6.15097 8.71844 6.15097 7.85421C6.15097 6.98999 5.43846 6.2869 4.56273 6.2869ZM4.56273 8.49275C4.20593 8.49275 3.91567 8.20631 3.91567 7.85421C3.91567 7.50211 4.20593 7.21568 4.56273 7.21568C4.91953 7.21568 5.20979 7.50211 5.20979 7.85421C5.20979 8.20631 4.9195 8.49275 4.56273 8.49275Z"
          fill="#626262"
        />
        <path
          d="M8.99752 6.2869C8.12176 6.2869 7.40929 6.98999 7.40929 7.85421C7.40929 8.71844 8.12176 9.42152 8.99752 9.42152C9.87329 9.42152 10.5858 8.71844 10.5858 7.85421C10.5858 6.98999 9.87329 6.2869 8.99752 6.2869ZM8.99752 8.49275C8.64072 8.49275 8.35046 8.20631 8.35046 7.85421C8.35046 7.50211 8.64072 7.21568 8.99752 7.21568C9.35432 7.21568 9.64458 7.50211 9.64458 7.85421C9.64458 8.20631 9.35432 8.49275 8.99752 8.49275Z"
          fill="#626262"
        />
        <path
          d="M13.4323 6.2869C12.5566 6.2869 11.8441 6.98999 11.8441 7.85421C11.8441 8.71844 12.5566 9.42152 13.4323 9.42152C14.3081 9.42152 15.0206 8.71844 15.0206 7.85421C15.0206 6.98999 14.3081 6.2869 13.4323 6.2869ZM13.4323 8.49275C13.0755 8.49275 12.7853 8.20631 12.7853 7.85421C12.7853 7.50211 13.0755 7.21568 13.4323 7.21568C13.7891 7.21568 14.0794 7.50211 14.0794 7.85421C14.0794 8.20631 13.7891 8.49275 13.4323 8.49275Z"
          fill="#626262"
        />
        <path
          d="M4.56273 10.4291C3.68696 10.4291 2.97449 11.1321 2.97449 11.9964C2.97449 12.8606 3.68696 13.5637 4.56273 13.5637C5.43849 13.5637 6.15097 12.8606 6.15097 11.9964C6.15097 11.1321 5.43846 10.4291 4.56273 10.4291ZM4.56273 12.6349C4.20593 12.6349 3.91567 12.3485 3.91567 11.9964C3.91567 11.6443 4.20593 11.3578 4.56273 11.3578C4.91953 11.3578 5.20979 11.6443 5.20979 11.9964C5.20979 12.3485 4.9195 12.6349 4.56273 12.6349Z"
          fill="#626262"
        />
        <path
          d="M8.99752 10.4291C8.12176 10.4291 7.40929 11.1321 7.40929 11.9964C7.40929 12.8606 8.12176 13.5637 8.99752 13.5637C9.87329 13.5637 10.5858 12.8606 10.5858 11.9964C10.5858 11.1321 9.87329 10.4291 8.99752 10.4291ZM8.99752 12.6349C8.64072 12.6349 8.35046 12.3485 8.35046 11.9964C8.35046 11.6443 8.64072 11.3578 8.99752 11.3578C9.35432 11.3578 9.64458 11.6443 9.64458 11.9964C9.64458 12.3485 9.35432 12.6349 8.99752 12.6349Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default Schedule;
