import {defaultSort} from '@helpers/index';

export const constructColumnsByExercise = (exercise) => {
  const defaultColumns = [
    {value: 'value', label: 'Результат'},
    {value: 'rang', label: 'Ранг'},
  ];
  if (exercise?.name?.includes('Beep-test')) {
    return [
      {value: 'value', label: 'Уровень'},
      {value: 'segmentNumber', label: '№ отрезка'},
      {value: 'rang', label: 'Ранг'},
    ];
  }
  if (exercise?.name?.includes('Y-balance')) {
    return [
      {value: 'value', label: 'Левая'},
      {value: 'rightValue', label: 'Правая'},
      {value: 'sum', label: 'Сумма'},
      {value: 'rang', label: 'Ранг'},
    ];
  }
  if (exercise?.name?.includes('Точность')) {
    return [
      {value: 'value', label: 'Результат'},
      {value: 'time', label: 'Секунды'},
      {value: 'rang', label: 'Ранг'},
    ];
  }
  return defaultColumns;
};

export const sortColumn = (rowA, rowB, columnId) => {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);
  return defaultSort(a, b);
};
