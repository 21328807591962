export const filterUsersConfig = {
  admin: {
    hasFilter: true,
    school: true,
    groups: true,
    teachers: true,
    players: true,
    rooms: true,
  },
  franchisee: {
    hasFilter: true,
    school: true,
    groups: true,
    teachers: true,
    players: true,
    rooms: true,
  },
  schools_admin: {
    hasFilter: true,
    school: true,
    groups: true,
    teachers: true,
    players: true,
    rooms: true,
  },
  teacher: {
    hasFilter: true,
    school: true,
    groups: true,
    teachers: true,
    players: true,
    rooms: true,
  },
  doctor: {
    hasFilter: true,
    school: true,
    groups: true,
    teachers: true,
    players: true,
    rooms: true,
  },
  psycho: {
    hasFilter: true,
    school: true,
    groups: true,
    teachers: true,
    players: true,
    rooms: true,
  },
  student: {
    hasFilter: false,
    school: false,
    groups: false,
    teachers: false,
    players: false,
    rooms: false,
  },
  parent: {
    hasFilter: false,
    school: false,
    groups: false,
    teachers: false,
    players: false,
    rooms: false,
  },
  guest: {
    hasFilter: false,
    school: false,
    groups: false,
    teachers: false,
    players: false,
    rooms: false,
  },
  methodist: {
    hasFilter: true,
    school: true,
    groups: true,
    teachers: true,
    players: true,
    rooms: true,
  },
  video_analyst: {
    hasFilter: true,
    school: true,
    groups: true,
    teachers: true,
    players: true,
    rooms: true,
  },
};

export const EVENT_TYPES = [
  {
    label: 'Воспитательное мероприятие',
    value: 'educational_event',
  },
  {
    label: 'Доп образование',
    value: 'additional_education',
  },
  {
    label: 'Завтрак',
    value: 'breakfast',
  },
  {
    label: 'Выезд из Академии',
    value: 'exit_from_academy',
  },
  {
    label: 'Игра команды (выездная)',
    value: 'away_game',
  },
  {
    label: 'Игра команды (домашняя)',
    value: 'game',
  },
  {
    label: 'Консультация групповая',
    value: 'group_consultation',
  },
  {
    label: 'Консультация индивидуальная',
    value: 'personal_consultation',
  },
  {
    label: 'Мастер класс',
    value: 'master',
  },
  {
    label: 'Недоступность площадки',
    value: 'room_inaccessibility',
  },
  {
    label: 'Обед',
    value: 'lunch',
  },
  {
    label: 'Раскатка',
    value: 'warmup',
  },
  {
    label: 'Тренировка индивидуальная',
    value: 'personal',
  },
  {
    label: 'Тренировка групповая',
    value: 'training',
  },
  {
    label: 'Тренировка по ОФП',
    value: 'training_gpt',
  },
  {
    label: 'Ужин',
    value: 'dinner',
  },
  {
    label: 'Школьные уроки',
    value: 'school_lessons',
  },
  {
    label: 'Прием врача',
    value: 'doctor_visit',
  },
  {
    label: 'Реабилитация на льду',
    value: 'rehabilitation_on_ice',
  },
  {
    label: 'Техника катания',
    value: 'riding_technique',
  },
  {
    label: 'Тренировка команды ВХЛ',
    value: 'training_vhl',
  },
  {
    label: 'Тренировка коммерческой группы',
    value: 'training_tkg',
  },
  {
    label: 'Тренировка Черри',
    value: 'training_cherry',
  },
  {
    label: 'Тренировка групповая (команды соперника)',
    value: 'training_opponent',
  },
  {
    label: 'Реабилитация ВХЛ',
    value: 'rehabilitation_vhl',
  },
  {
    label: 'Домашние игры ВХЛ',
    value: 'home_games_vhl',
  },
  {
    label: 'Тренировка тренеров ХА',
    value: 'training_trainers_ha',
  },
  {
    label: 'Видеоурок хоккея',
    value: 'training_video_hockey',
  },
].sort(function (a, b) {
  const labelA = a.label.toUpperCase();
  const labelB = b.label.toUpperCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
});

const COLORS = {
  blue: '#4782ad',
  red: '#F85F5F',
  green: '#39BBA7',
  yellow: '#fcbd47',
  grey: '#bfbfbf',
  dark_grey: '#404040',
  purple: '#5f418a',
};

export const EVENT_TYPES_COLOR = {
  rehabilitation_on_ice: COLORS.yellow,
  riding_technique: COLORS.yellow,
  training_tkg: COLORS.grey,
  training_vhl: COLORS.grey,
  training_cherry: COLORS.grey,
  training_opponent: COLORS.grey,
  educational_event: COLORS.blue,
  additional_education: COLORS.blue,
  breakfast: COLORS.green,
  exit_from_academy: COLORS.grey,
  away_game: COLORS.dark_grey,
  game: COLORS.red,
  group_consultation: COLORS.blue,
  personal_consultation: COLORS.blue,
  master: COLORS.blue,
  room_inaccessibility: COLORS.grey,
  lunch: COLORS.green,
  warmup: COLORS.yellow,
  personal: COLORS.yellow,
  training: COLORS.yellow,
  training_gpt: COLORS.yellow,
  dinner: COLORS.green,
  school_lessons: COLORS.blue,
  doctor_visit: COLORS.purple,
  rehabilitation_vhl: COLORS.grey,
  home_games_vhl: COLORS.grey,
  training_trainers_ha: COLORS.grey,
  training_video_hockey: COLORS.grey,
};

export const EVENT_GROUP_TYPES_COLOR = [
  {
    id: 'education',
    name: 'Образование',
    color: COLORS.blue,
  },
  {
    id: 'training',
    name: 'Регулярные тренировки',
    color: COLORS.yellow,
  },
  {
    id: 'game',
    name: 'Игра команды (Домашняя)',
    color: COLORS.red,
  },
  {
    id: 'away_game',
    name: 'Игра команды (Выездная)',
    color: COLORS.dark_grey,
  },
  {
    id: 'eating',
    name: 'Питание',
    color: COLORS.green,
  },
  {
    id: 'doctor',
    name: 'Прием врача',
    color: COLORS.purple,
  },
  {
    id: 'exit_from_academy',
    name: 'Технические',
    color: COLORS.grey,
  },
];

export const ADDITIONAL_EDUCATION_LESSONS = [
  {
    label: 'Английский в раздевалке',
    value: 'locker_room_english',
  },
  {
    label: 'Английский язык',
    value: 'english',
  },
  {
    label: 'Биология',
    value: 'biology',
  },
  {
    label: 'География',
    value: 'geography',
  },
  {
    label: 'Психология',
    value: 'psychology_class',
  },
  {
    label: 'ЗОЖ',
    value: 'health_promotion',
  },
  {
    label: 'Информатика',
    value: 'computer_science',
  },
  {
    label: 'Куборо',
    value: 'cuboro',
  },
  {
    label: 'Математика',
    value: 'math',
  },
  {
    label: 'Математический практикум',
    value: 'mathematica_workshop',
  },
  {
    label: 'Ментальная арифметика',
    value: 'mental_arithmetic',
  },
  {
    label: 'Навигатор',
    value: 'navigator',
  },
  {
    label: 'Немецкий язык',
    value: 'german',
  },
  {
    label: 'Проектная деятельность',
    value: 'project_activities',
  },
  {
    label: 'Русский язык',
    value: 'russian',
  },
  {
    label: 'Физика',
    value: 'physics',
  },
  {
    label: 'Химия',
    value: 'chemistry',
  },
  {
    label: 'Медиаобразование',
    value: 'media_education',
  },
  {
    label: 'IT в практике',
    value: 'IT',
  },
  {
    label: 'Автодело',
    value: 'auto_engineering',
  },
  {
    label: 'МедиаЦентр',
    value: 'media_center',
  },
  {
    label: 'Зеленая шайба',
    value: 'green_puck',
  },
  {
    label: 'Китайский',
    value: 'chinese',
  },
  {
    label: 'Растяжка',
    value: 'stretching',
  },
  {
    label: 'Школа арбитра',
    value: 'referee_school',
  },
  {
    label: 'Спорт без политики',
    value: 'sports_without_politics',
  },
  {
    label: 'Геоквест',
    value: 'geoquest',
  },
  {
    label: 'Омск - хоккейная столица',
    value: 'omsk_is_a_capital_of_hockey',
  },
  {
    label: 'Моя малая родина',
    value: 'my_small_homeland',
  },
  {
    label: 'Школа выживания',
    value: 'survival_school',
  },
  {
    label: 'Вперед, к звездам',
    value: 'forward_to_the_stars',
  },
  {
    label: 'Современное искусство',
    value: 'contemporary_art',
  },
  {
    label: 'Театральная суббота',
    value: 'theater_saturday',
  },
  {
    label: 'Литературное кафе',
    value: 'literary_cafe',
  },
  {
    label: 'Киноклуб',
    value: 'cinema_club',
  },
  {
    label: 'Театр',
    value: 'theatre',
  },
  {
    label: 'Профориентация',
    value: 'сareer_guidance',
  },
  {
    label: 'Лектор PRO',
    value: 'lecturer_pro',
  },
  {
    label: 'Финансовая грамотность',
    value: 'financial_literacy',
  },
  {
    label: 'Стиль вне льда',
    value: 'off-ice_style',
  },
  {
    label: 'Дипломатия',
    value: 'diplomacy',
  },
  {
    label: 'Волонтерство',
    value: 'volunteerism',
  },
  {
    label: 'Проектный офис',
    value: 'projects_office',
  },
  {
    label: 'Совет Академии',
    value: 'academy_board',
  },
  {
    label: 'Обществознание',
    value: 'social_theory',
  },
  {
    label: 'Литература',
    value: 'literature',
  },
].sort(function (a, b) {
  const labelA = a.label.toUpperCase();
  const labelB = b.label.toUpperCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
});

export const SCHOOL_LESSONS = [
  {
    label: 'Английский язык',
    value: 'english',
  },
  {
    label: 'Биология',
    value: 'biology',
  },
  {
    label: 'География',
    value: 'geography',
  },
  {
    label: 'Математика',
    value: 'math',
  },
  {
    label: 'Обществознание',
    value: 'social_theory',
  },
  {
    label: 'Проектная деятельность',
    value: 'project_activities',
  },
  {
    label: 'Русский язык',
    value: 'russian',
  },
  {
    label: 'Физика',
    value: 'physics',
  },
  {
    label: 'Химия',
    value: 'chemistry',
  },
  {
    label: 'ОБЖ',
    value: 'basic_safety',
  },
  {
    label: 'История',
    value: 'history',
  },
  {
    label: 'Литература',
    value: 'Literature',
  },
  {
    label: 'Алгебра',
    value: 'Algebra',
  },
  {
    label: 'Технология',
    value: 'technology',
  },
  {
    label: 'Информатика',
    value: 'computer_science',
  },
  {
    label: 'Немецкий язык',
    value: 'german',
  },
  {
    label: 'Геометрия',
    value: 'geometry',
  },
  {
    label: 'ИЗО',
    value: 'art_class',
  },
  {
    label: 'Музыка',
    value: 'music_class',
  },
  {
    label: 'Физкультура',
    value: 'physical_education',
  },
  {
    label: 'Экономика',
    value: 'economics_class',
  },
  {
    label: 'Астрономия',
    value: 'astronomy',
  },
  {
    label: 'Родной язык',
    value: 'native_language',
  },
  {
    label: 'Родная литература',
    value: 'native_literature',
  },
  {
    label: 'ОДНКНР',
    value: 'ODNKNR',
  },
  {
    label: 'Классный час',
    value: 'class_hour',
  },
  {
    label: 'Основы финансовой грамотности',
    value: 'financial_literacy',
  },
  {
    label: 'Решение метаматических задач',
    value: 'math_tasks',
  },
  {
    label: 'Решение физических задач',
    value: 'physical_tasks',
  },
  {
    label: 'Вероятность и статистика',
    value: 'probability_and_statistics',
  },
  {
    label: 'Психология',
    value: 'psychology_class',
  },
  {
    label: 'Разговоры о важном',
    value: 'talking_about_important_things',
  },
].sort(function (a, b) {
  const labelA = a.label.toUpperCase();
  const labelB = b.label.toUpperCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
});

export const ymEvenTypes = {
  breakfast: 'Add_eating',
  lunch: 'Add_eating',
  dinner: 'Add_eating',
  game: 'Add_home_game',
  away_game: 'Add_away_game',
  doctor_visit: 'Add_doctor_visit',
  additional_education: 'Add_education',
  school_lessons: 'Add_education',
  group_consultation: 'Add_education',
  educational_event: 'Add_education',
  personal_consultation: 'Add_education',
  master: 'Add_education',
  training: 'Add_regular_training',
  personal: 'Add_regular_training',
  warmup: 'Add_regular_training',
};

export const ARCHIVED_SCHOOL_LESSONS = [
  {
    label: 'Английский язык',
    value: 'english',
  },
  {
    label: 'Английский в раздевалке',
    value: 'locker_room_english',
  },
  {
    label: 'Биология',
    value: 'biology',
  },
  {
    label: 'География',
    value: 'geography',
  },
  {
    label: 'Занятие по психологии',
    value: 'psychology_class',
  },
  {
    label: 'Здоровый образ жизни',
    value: 'health_promotion',
  },
  {
    label: 'Когнитивная подготовка',
    value: 'cognitive_training',
  },
  {
    label: 'Куборо',
    value: 'cuboro',
  },
  {
    label: 'Математика',
    value: 'math',
  },
  {
    label: 'Навигатор',
    value: 'navigator',
  },
  {
    label: 'Обществознание',
    value: 'social_theory',
  },
  {
    label: 'Проектная деятельность',
    value: 'project_activities',
  },
  {
    label: 'Русский язык',
    value: 'russian',
  },
  {
    label: 'Физика',
    value: 'physics',
  },
  {
    label: 'Шаг в будущее',
    value: 'step_into_the_future',
  },
  {
    label: 'Шахматы',
    value: 'chess',
  },
  {
    label: 'Химия',
    value: 'chemistry',
  },
  {
    label: 'ОБЖ',
    value: 'basic_safety',
  },
  {
    label: 'История',
    value: 'history',
  },
  {
    label: 'Литература',
    value: 'Literature',
  },
  {
    label: 'Алгебра',
    value: 'Algebra',
  },
  {
    label: 'Технология',
    value: 'technology',
  },
  {
    label: 'Технология',
    value: 'technology',
  },
  {
    label: 'Информатика',
    value: 'computer_science',
  },
  {
    label: 'Немецкий язык',
    value: 'german',
  },
  {
    label: 'Геометрия',
    value: 'geometry',
  },
  {
    label: 'ИЗО',
    value: 'art_class',
  },
  {
    label: 'Музыка',
    value: 'music_class',
  },
  {
    label: 'Физкультура',
    value: 'physical_education',
  },
  {
    label: 'Экономика',
    value: 'economics_class',
  },
  {
    label: 'Нутрициология',
    value: 'nutrition',
  },
  {
    label: 'Астрономия',
    value: 'astronomy',
  },
  {
    label: 'Родной язык',
    value: 'native_language',
  },
  {
    label: 'Родная литература',
    value: 'native_literature',
  },
  {
    label: 'Ментальная арифметика',
    value: 'mental_arithmetic',
  },
  {
    label: 'Математический практикум',
    value: 'mathematica_workshop',
  },
];
