import moment from 'moment';
import React, {useEffect} from 'react';
import styled from 'styled-components';

import CircularDeterminate from '../../../components/ui/MUI/Progressbars/CircularProgressBar';

export default function ScheduleHeader(props) {
  let {arenaName = '', hasProgressBar = true, progress, setProgress} = props;

  const tick = () => {
    setClock(nowTime());
  };

  useEffect(() => {
    let timerID = setInterval(() => {
      tick();
    }, 10000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, []);

  const nowTime = () => {
    const now = moment().unix();
    return moment.unix(now).format('HH:mm');
  };

  const [clock, setClock] = React.useState(nowTime());

  const currMonthName = moment().format('D MMMM');
  const dayOfTheWeek = moment().format('dd');

  return (
    <Header>
      <ScheduleColumnCurrentTime>{clock}</ScheduleColumnCurrentTime>
      <ScheduleColumnCurrentDate>
        {currMonthName}, {dayOfTheWeek}
      </ScheduleColumnCurrentDate>
      <ScheduleColumnLogo>
        <Logo alt="logo" src="/img/logo.svg" />
      </ScheduleColumnLogo>
      <ScheduleColumn>&quot;{arenaName}&quot;</ScheduleColumn>
      <ScheduleColumnSlideNumber>
        {hasProgressBar && <CircularDeterminate progress={progress} setProgress={setProgress} />}
      </ScheduleColumnSlideNumber>
    </Header>
  );
}

const Header = styled.div`
  position: fixed;
  top: 0.875rem;
  left: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 0 2.5rem;
  margin-top: 0;
  margin-bottom: 0.625rem;
  box-sizing: border-box;
  width: calc(100% - 0.625rem - 0.625rem);
  height: 5.625rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;
const ScheduleColumn = styled.div`
  padding-top: 0.625rem;
  font: bold normal 5rem/6.25rem 'Bebas Neue';
  color: black;
  text-transform: uppercase;
  :last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
    overflow: hidden;
  }
  :first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
    overflow: hidden;
  }
`;
const ScheduleColumnCurrentTime = styled(ScheduleColumn)`
  color: #887755;
`;
const ScheduleColumnCurrentDate = styled(ScheduleColumn)`
  color: #990011;
`;
const ScheduleColumnSlideNumber = styled(ScheduleColumn)`
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 5rem;
  color: #747474;
`;
const ScheduleColumnLogo = styled(ScheduleColumn)`
  padding-top: 0;
`;
const Logo = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;
