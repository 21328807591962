import {mock} from '@components/apps/GameStatisticsParsingCheck/components/Ejection/mock';
import TableComponent from '@components/apps/GameStatisticsParsingCheck/components/TableComponent';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useDeepMemo from '@hooks/useDeepMemo';
import {selectGameStatisticsParsingCheckTabs} from '@selectors/gameStatisticsParsingCheck';
import {longDash} from '@src/constants';
import {Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';

Ejection.propTypes = {
  tab: PropTypes.string.isRequired,
};

function Ejection({tab}) {
  const {data, isLoading, isLoad, hasError} = useSelector(
    (state) => selectGameStatisticsParsingCheckTabs(state, tab),
    shallowEqual,
  );
  const columns = useDeepMemo(
    () => [
      {
        id: 'ejectedPlayer',
        accessorKey: 'ejectedPlayer',
        header: 'Кого удалили',
        sticky: {left: true},
        cell: (params) => {
          const student = params?.getValue();
          return (
            <PlayersHorizontalCard
              hasImage={false}
              isLoading={isLoading}
              preloaderWidthBetween={[100, 170]}
              student={{...student, playerNumber: student?.number}}
            />
          );
        },
      },
      {
        id: 'time',
        accessorKey: 'time',
        header: 'Время',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
      {
        id: 'videoTime',
        accessorKey: 'videoTime',
        header: 'Видео-тайм',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 80]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
      {
        id: 'ejectionType',
        accessorKey: 'ejectionType',
        header: 'Тип удаления',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[60, 120]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
      {
        id: 'injuredPlayer',
        accessorKey: 'injuredPlayer',
        header: 'Заработал удаление',
        cell: (params) => {
          const student = params?.getValue();
          return (
            <PlayersHorizontalCard
              hasImage={false}
              isLoading={isLoading}
              preloaderWidthBetween={[100, 170]}
              student={{...student, playerNumber: student?.number}}
            />
          );
        },
      },
    ],
    [isLoading],
  );

  const rows = useDeepMemo(
    () =>
      (isLoading ? mock : data)?.map((item, id) => ({
        id,
        ejectedPlayer: {
          number: item?.deletedPlayerNumber,
          lastName: item?.deletedPlayerLastName,
          firstName: item?.deletedPlayerFirstName,
        },
        injuredPlayer: {
          number: item?.offendedPlayerNumber,
          lastName: item?.offendedPlayerLastName !== 'Оппоннент' ? item?.offendedPlayerLastName : '',
          firstName: item?.offendedPlayerFirstName,
        },
        time: item?.time,
        videoTime: item?.videoTime,
        ejectionType: item?.deletionType,
      })),
    [isLoading, data],
  );
  return (
    <TableComponent
      columns={columns}
      data={rows}
      hasError={hasError || !data?.length}
      isLoad={isLoad}
      isLoading={isLoading}
      tab={tab}
    />
  );
}

export default Ejection;
