import GamesList from '@components/apps/StatisticsWithMkcUral/components/GamesList';
import PastGame from '@components/apps/StatisticsWithMkcUral/components/PastGame';
import PlayersTableWrapper from '@components/apps/StatisticsWithMkcUral/components/PlayersTableWrapper';
import PlayerStatisticsContent from '@components/apps/StatisticsWithMkcUral/components/PlayerStatisticsContent';
import Wrapper from '@components/apps/StatisticsWithMkcUral/components/Wrapper';
import {GAME_TYPES} from '@components/apps/StatisticsWithMkcUral/constants';
import date from '@helpers/date';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import {selectPrepareForOpponentOpponents} from '@selectors/gameStatisticsMkc';
import {clearOpponentsList, clearPlayersTables, loadOpponentsList} from '@slices/statisticsMKC';
import {HOCKEY_ROLES_ENUM} from '@src/constants';
import {Autocomplete, Box, Button, Divider, Stack, Typography} from '@ui/MUI';
import {blue, grey} from '@ui/MUI/colors';
import Scrollbar from '@ui/Scrollbar';
import React, {useEffect, useState} from 'react';
import {shallowEqual} from 'react-redux';
import {useParams} from 'react-router-dom';

PreparingForOpponent.propTypes = {};
const borderStyle = `1px solid ${grey['14']}`;
function PreparingForOpponent() {
  const {teamMKCId} = useParams();
  const dispatch = useStoreDispatch();
  const opponentsOptions = useStore(selectPrepareForOpponentOpponents, shallowEqual);
  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [isOpen, setIsOpen] = useState(false);
  const [opponent, setOpponent] = useState(null);
  const [qs] = useQueryString();
  useEffect(() => {
    dispatch(
      loadOpponentsList({teamId: teamMKCId, ...(qs || {})}, (data) => data?.[0]?.teamId && setOpponent(data?.[0])),
    );
    return () => {
      dispatch(clearOpponentsList());
    };
  }, [teamMKCId, qs?.isFinal, qs?.season]);

  useEffect(() => {
    return () => {
      dispatch(clearPlayersTables());
    };
  }, []);

  return (
    <>
      <Wrapper mb={1}>
        <Box maxWidth={450} width="100%">
          <Autocomplete
            disableClearable
            isLoading={opponentsOptions?.isLoading}
            label="Соперник"
            multiple={false}
            options={opponentsOptions?.data}
            renderOption={(props, option) => (
              <Stack
                component="li"
                direction="row"
                divider={<Divider flexItem color={grey[24]} orientation="vertical" />}
                spacing={1}
                {...props}
              >
                <Typography variant="body1">{option?.name}</Typography>
                {option?.nextGameDate && (
                  <Typography color={grey[24]} variant="body1">{`сл. игра: ${date(option?.nextGameDate).format(
                    'DD.MM.YYYY',
                  )}`}</Typography>
                )}
              </Stack>
            )}
            value={opponent}
            onChange={(e, value) => setOpponent(value)}
          />
        </Box>
      </Wrapper>
      <Wrapper mb={4} p="0">
        <Scrollbar
          disableTrackXWidthCompensation
          translateContentSizeYToHolder
          contentProps={!isMd && {style: {display: 'block', float: 'left'}}}
          native={isMd}
        >
          <Stack alignItems="stretch" direction={{xxs: 'column', xs: 'row'}} pb={{xxs: '0', md: 2}} width="100%">
            {[HOCKEY_ROLES_ENUM.winger, HOCKEY_ROLES_ENUM.defenseman, HOCKEY_ROLES_ENUM.goaltender].map(
              (item, index) => (
                <Box
                  borderBottom={borderStyle}
                  borderRight={borderStyle}
                  key={index}
                  minWidth={270}
                  width={{xxs: '100%', xs: 'calc(100% / 3)'}}
                >
                  <PlayersTableWrapper
                    accentColors={[blue['15'], blue['60']]}
                    hockeyRole={item}
                    isFinal={qs?.isFinal}
                    isLoading={opponentsOptions?.isLoading}
                    season={qs?.season}
                    teamMKCId={opponent?.teamId}
                  />
                </Box>
              ),
            )}
          </Stack>
        </Scrollbar>

        <Box p={{xxs: 1, sm: 4}} textAlign={{xxs: 'center', xs: 'initial'}}>
          <Button
            color="primary"
            disabled={!opponentsOptions?.data?.length}
            variant="outlined"
            onClick={() => setIsOpen(true)}
          >
            Смотреть статистику по игрокам
          </Button>
        </Box>
      </Wrapper>

      <Wrapper p={{xxs: 2, sm: 3}}>
        <Typography mb={3} variant="h4">
          История игр
        </Typography>
        <Divider
          sx={{
            transform: 'translateY(1px)',
            backgroundColor: grey['24'],
            mx: {xxs: -2, sm: -3},
            zIndex: '99',
            position: 'relative',
          }}
        />
        <Box position="relative">
          <GamesList
            Component={PastGame}
            gamesWrapper={{p: '0 !important', position: 'initial'}}
            isFinal={qs?.isFinal}
            isLoading={opponentsOptions?.isLoading}
            placeholder="Нет данных по прошедшим играм"
            season={qs?.season}
            teamMKCId={opponent?.teamId}
            type={GAME_TYPES.pastGames}
          />
        </Box>
      </Wrapper>
      <PlayerStatisticsContent
        disabled={!opponent?.teamId}
        isFinal={qs?.isFinal}
        isOpen={isOpen}
        season={qs?.season}
        setIsOpen={setIsOpen}
        teamId={opponent?.teamId}
      />
    </>
  );
}

export default React.memo(PreparingForOpponent);
