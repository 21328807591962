import styled from 'styled-components';

export const Between = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: ${(props) => props.isLoading && '200px'};
`;
