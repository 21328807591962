import FullHeightWrapper from '@common/FullHeightWrapper';
import PlayersTable from '@common/Statistics/PlayerStatistics/VideoModal/PlayersTable';
import VideoPlayer from '@common/Statistics/VideoPlayer';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {parseFormToGetValue} from '@helpers/index';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import {selectGameActionDetails} from '@selectors/gameStatistics';
import {loadGameActionDetails} from '@slices/statistics';
import {GameActionDto} from '@src/types/generated';
import {Box, Button, Chip, Stack, Typography} from '@ui/MUI';
import {Cancel} from 'axios';
import {isEmpty} from 'lodash';
import React, {useEffect, useState} from 'react';

type ActionsProps = {
  selectedPlayerName: string;
  statistic: {
    loading?: boolean;
    isLoading?: boolean;
    error?: object | null;
    data: {
      home: Array<GameActionDto>;
      away: Array<GameActionDto>;
      weAtHome?: boolean;
    };
  };
  statisticsIds: Array<string>;
  cleaner?: () => (dispatch: unknown) => unknown;
  loader?: (params: object, isCancel?: (value: unknown) => value is Cancel) => (dispatch: unknown) => Promise<void>;
};

function Actions({selectedPlayerName, statistic, statisticsIds, loader, cleaner}: ActionsProps) {
  const dispatch = useStoreDispatch();

  const actions = [statistic?.data?.home || [], statistic?.data?.away || []]
    ?.flat()
    ?.filter(({playerName}: {playerName: string}) => {
      const isNameAndSurnameSame =
        playerName?.includes(selectedPlayerName?.split(' ')?.[0]) &&
        playerName?.includes(selectedPlayerName?.split(' ')?.[1]);
      return isNameAndSurnameSame;
    });

  const actionsNames = actions?.map((action) => action?.name);
  const filterChips = [...new Set(actionsNames)]?.map((name) => {
    return {label: name, engName: actions?.find((action) => action.name === name)?.engName};
  });
  const [selectedChip, setSelectedChip] = useState({label: 'Все', engName: 'all'});

  const [selectedActionIndex, setSelectedActionIndex] = useState(0);

  const filterActionsBySelectedChip = (actions: Array<GameActionDto>) => {
    if (selectedChip?.engName === 'all') {
      return actions;
    } else {
      return actions?.filter((action) => action?.engName === selectedChip?.engName);
    }
  };

  const selectedAction = filterActionsBySelectedChip(actions)?.[selectedActionIndex];

  const isNoData = filterActionsBySelectedChip(actions)?.length === 0;

  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [selectedAction?.id]});

  useDeepEffect(() => {
    if (loader && statisticsIds?.length) {
      dispatch(
        loader(
          {
            ...parseFormToGetValue({cancelToken: newCancelToken()}),
            statisticsIds,
          },
          isCancel,
        ),
      );
    }
    return () => {
      cleaner && dispatch(cleaner());
    };
  }, [statisticsIds, loader]);

  useEffect(() => {
    selectedAction?.id &&
      dispatch(loadGameActionDetails(selectedAction?.id, {cancelToken: newCancelToken(), isCancel: !!isCancel}));
  }, [selectedAction?.id]);

  const video = {data: selectedAction, key: selectedAction?.id};

  const shotDetails = useStore(selectGameActionDetails);
  const playersOnIce = shotDetails?.data?.playersOnIce || [];

  return (
    <>
      {!isNoData && (
        <Box display="flex" flexWrap="wrap" gap={3}>
          {[{label: 'Все', engName: 'all'}, ...filterChips]?.map((chip) => {
            const isSelected = chip?.engName === selectedChip?.engName;
            return (
              <Chip
                clickable
                color={isSelected ? 'primary' : 'secondary'}
                key={chip?.engName}
                label={chip?.label}
                sx={{
                  '& > span': {
                    color: isSelected && '#fff',
                  },
                  gap: 2,
                }}
                onClick={() => {
                  setSelectedChip(chip);
                  setSelectedActionIndex(0);
                }}
              />
            );
          })}
        </Box>
      )}
      {!isNoData && (
        <Stack
          alignItems="center"
          direction="row"
          flexWrap={{xxs: 'wrap', xs: 'nowrap'}}
          gap={3}
          justifyContent="space-between"
          marginBottom={3}
          sx={{position: 'sticky', top: '56px', pt: 5, pb: 3, backgroundColor: '#fff', zIndex: 2}}
          width={{xxs: '100%', xs: 'unset'}}
        >
          <Typography
            isLoading={statistic?.isLoading || statistic?.loading}
            preloaderWidthBetween={[100, 120]}
            variant="h3"
          >
            {selectedAction?.name}. {selectedAction?.time || ''}
          </Typography>
          <Box>
            <Button
              color="primary"
              disabled={selectedActionIndex === 0 || statistic?.isLoading || statistic?.loading}
              startIcon={<ChevronLeftOutlinedIcon />}
              sx={{width: {xxs: '45%', xs: 'unset'}, mr: 3}}
              variant="outlined"
              onClick={() => {
                setSelectedActionIndex(selectedActionIndex - 1);
              }}
            >
              назад
            </Button>
            <Button
              color="primary"
              disabled={
                statistic?.isLoading ||
                statistic?.loading ||
                selectedActionIndex + 1 === filterActionsBySelectedChip(actions)?.length
              }
              endIcon={<ChevronLeftOutlinedIcon sx={{transform: 'rotate(180deg)'}} />}
              sx={{width: {xxs: '45%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => {
                setSelectedActionIndex(selectedActionIndex + 1);
              }}
            >
              вперед
            </Button>
          </Box>
        </Stack>
      )}
      {statistic?.isLoading || statistic?.loading ? (
        <LocalPreloader visible={true} />
      ) : (
        <>{video?.data && <VideoPlayer data={video?.data} key={video?.key} />}</>
      )}
      <Box sx={{mt: 3, width: '100%'}}>
        {!actions?.length ? (
          <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
            <Typography variant="subtitle1">В данном матче игрок не совершил ни одного действия.</Typography>
          </FullHeightWrapper>
        ) : (
          <>
            <Typography mb={1} variant="body1">
              Тип:{' '}
              <Typography component="span" variant="subtitle1">
                {selectedAction?.type?.name || 'Не указано'}
              </Typography>
            </Typography>
            <Typography mb={1} variant="body1">
              Игрок:{' '}
              <Typography component="span" variant="subtitle1">
                {selectedAction?.playerName}
              </Typography>
            </Typography>
            <Typography variant="body1">
              Команда:{' '}
              <Typography component="span" variant="subtitle1">
                {selectedAction?.teamName}
              </Typography>
            </Typography>
          </>
        )}
      </Box>
      {!isNoData && !isEmpty(playersOnIce) && (
        <Box sx={{mt: 5}}>
          <Typography mb={2} mt={4} variant="h4">
            Игроки на льду
          </Typography>
          <PlayersTable isLoading={shotDetails?.isLoading} playersOnIce={playersOnIce} />
        </Box>
      )}
    </>
  );
}
export default Actions;
