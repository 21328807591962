import {getVideoTags} from '@api/videoAnalytics';
import {useEffect, useState} from 'react';

export function useVideoTags() {
  const [tags, setTags] = useState({isLoading: false, data: []});
  useEffect(() => {
    setTags({data: [], isLoading: true});
    getVideoTags().then(({data}) => {
      setTags({isLoading: false, data: data?.map(({name, slug}) => ({value: slug, label: name}))});
    });
  }, []);
  return tags;
}
