import {HALF_OPTIONS, HALF_OPTIONS_WITH_OT, IMPORTANCE_OPTIONS, PLRS_OPTIONS} from '@src/constants';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import Box from '@ui/MUI/Box';
import Stack from '@ui/MUI/Stack';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

function Filter({
  hasOT,
  weAtHome,
  filter = [],
  setFilter = () => {},
  customFilters,
  multiplyImportance = false,
  ...props
}) {
  const filters = useMemo(() => {
    const initialFilters = [
      {
        name: 'plrs',
        options: [...PLRS_OPTIONS],
        multiple: true,
        label: 'Составы',
        isSelectableAll: true,
      },
      {
        name: 'importance',
        options: multiplyImportance ? [...IMPORTANCE_OPTIONS.slice(1, 3)] : [...IMPORTANCE_OPTIONS],
        multiple: multiplyImportance,
        label: 'Важность',
        isSelectableAll: multiplyImportance,
      },
      {
        name: 'half',
        options: [...(hasOT ? HALF_OPTIONS_WITH_OT : HALF_OPTIONS)],
        multiple: true,
        limitTags: 3,
        label: 'Период',
        isSelectableAll: true,
      },
    ];
    if (customFilters) {
      return customFilters;
    }
    return props?.addFilters?.([...initialFilters]) || initialFilters;
  }, [hasOT, customFilters, weAtHome, props?.addFilters]);

  return (
    <Stack alignItems="flex-end" direction={{xxs: 'column', sm: 'row'}} spacing={{xxs: 1, md: 2, lg: 5}}>
      {filters.map(({options, name, multiple, label, isSelectableAll, limitTags, ...rest}, index) => (
        <Box key={index} width={{xxs: '100%', sm: `calc(100% / ${filters.length > 4 ? 4 : filters.length})`}}>
          <AutocompleteDefault
            {...{options, multiple, label, isSelectableAll, limitTags, ...rest}}
            fullWidth
            filterSelectedOptions={true}
            id={name}
            limitTags={limitTags | 1}
            placeholder=""
            value={filter.options?.[name]}
            onChange={(_, value) =>
              setFilter({
                options: {...filter.options, [name]: value},
                forFetch: {
                  ...filter.forFetch,
                  [name]: multiple ? value?.map((item) => item.value) : value?.value,
                },
              })
            }
          />
        </Box>
      ))}
    </Stack>
  );
}

Filter.propTypes = {
  hasOT: PropTypes.bool,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  customFilters: PropTypes.array,
  addFilters: PropTypes.func,
};

export default Filter;
