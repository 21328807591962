import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useMediaQuery} from '@mui/material';
import {Box, Button} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from 'styled-components';

ToggleBlock.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isnitialState: PropTypes.bool,
  showText: PropTypes.string,
  closeText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  wrapperProps: PropTypes.object,
  contentProps: PropTypes.object,
};

function ToggleBlock({
  isOpen = false,
  setIsOpen,
  isnitialState = false,
  showText = 'Показать фильтры',
  closeText = 'Скрыть фильтры',
  children,
  hasToggleBreakpoint,
  wrapperProps = {},
  contentProps = {},
  disabled = false,
}) {
  const [isOpenState, setIsOpenState] = useState(isnitialState);
  const toggleBreakpoint = useMediaQuery((theme) => theme.breakpoints.down(hasToggleBreakpoint));
  const showToggler = hasToggleBreakpoint ? toggleBreakpoint : true;
  return (
    <Box {...wrapperProps}>
      <Wrapper open={isOpen || isOpenState || !showToggler} {...contentProps}>
        {children}
      </Wrapper>
      {showToggler && (
        <Button
          color="primary"
          disabled={disabled}
          endIcon={
            isOpen || isOpenState ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />
          }
          variant="text"
          onClick={() => (setIsOpen ? setIsOpen(!isOpen) : setIsOpenState(!isOpenState))}
        >
          {isOpen || isOpenState ? closeText : showText}
        </Button>
      )}
    </Box>
  );
}

export default React.memo(ToggleBlock);

const Wrapper = styled(Box)`
  overflow: hidden;
  max-height: ${({open}) => (open ? '100vh' : '0px')};
  padding-bottom: ${({theme, open}) => (open ? theme.spacing(3) : '0px')};
  transition: 0.3s ease-in-out;
`;
