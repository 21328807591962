import {getSkillsImprovementReport} from '@api/TrainingControlProcess';
import DonutChart from '@components/charts/DonutChart';
import date from '@helpers/date';
import useRequest from '@hooks/useRequest';
import {ErrorRounded} from '@mui/icons-material';
import {Box, Divider, Stack, Typography} from '@ui/MUI';
import React, {useState} from 'react';

import LoadingHolder from '../LoadingHolder';
import Filters from './components/Filters';
import SectionsTable from './components/SectionsTable';
import {diffProcent} from './helpers';
import {NoDataOrError} from './style';

const colors = ['#C05B54', '#FFC633', '#89A9D9', '#AB60BE', '#D99493', '#1976D2', '#81C280'];

const Exceeding = ({data = []}) => {
  const exceeding = data.filter((item) => item.isOvered);
  if (!exceeding.length) return null;

  return (
    <Stack mt={4} spacing={1}>
      <Divider />
      <Stack alignItems="center" direction="row" spacing={1}>
        <ErrorRounded color="primary" fontSize="20px" />
        <Typography color="#8C1A1A" variant="subtitle1">
          Есть превышение времени в разделах, относительно плана:
        </Typography>
      </Stack>
      <Stack px={4} spacing={1}>
        {exceeding.map((item) => {
          return (
            <Typography key={item.id} variant="body1">
              {item.name}: {item.timeEstimate}%/{item.time}%
            </Typography>
          );
        })}
      </Stack>
    </Stack>
  );
};

const ImprovementChart = ({data}) => {
  const formatedData = data?.result?.map((item) => {
    const total = data.total;
    const time = diffProcent(item.periodTime, total?.periodTime);
    const timeEstimate = diffProcent(item.periodTimeEstimate, total?.periodTimeEstimate);
    const isOvered = Number(timeEstimate) > Number(time);
    return {
      name: item.name,
      value: item.periodTime,
      time: isNaN(time) ? 0 : Number(time?.toFixed(0)),
      timeEstimate: isNaN(timeEstimate) ? 0 : Number(timeEstimate?.toFixed(0)),
      isOvered,
      label: {
        color: isOvered ? '#8C1A1A' : '#525252',
      },
      labelLine: {
        lineStyle: {
          color: isOvered ? '#8C1A1A' : '#525252',
        },
      },
    };
  });
  const noPeriod = !data?.total?.periodCount;
  return (
    <Box minHeight={200} position="relative">
      {noPeriod && (
        <Typography sx={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
          Данные отсутствуют
        </Typography>
      )}
      <DonutChart
        hideHistory
        color={colors}
        height={300}
        options={{
          tooltip: {
            trigger: 'item',
          },
          legend: {
            show: true,
            orient: 'vertical',
            top: '10%',
            left: '40%',
          },
          label: {
            fontSize: 16,
            formatter: (params) => {
              const data = params.data;
              return `${data.timeEstimate}% / ${data.time}%`;
            },
          },
          series: [
            {
              showEmptyCircle: false,
              center: ['16%', '50%'],
              type: 'pie',
              radius: '50%',
              data: formatedData,
            },
          ],
        }}
        width="100%"
      />
      <Exceeding data={formatedData} />
    </Box>
  );
};

const getValues = (filters) => {
  const [start, end] = filters.period;
  return {
    school: filters?.school?.value,
    group: filters?.group?.value,
    startTime: date(start).valueOf(),
    endTime: date(end).valueOf(),
  };
};

const TrainingSkillsImprovementReport = () => {
  const [filters, setFilters] = useState({
    school: null,
    group: null,
    period: [date().startOf('month').toDate(), date().endOf('month').toDate()],
  });

  const {isLoading, data} = useRequest(getSkillsImprovementReport, getValues(filters));
  const noData = data?.total?.count === 0;
  const hasGroupAndSchoolFilters = filters?.school?.value && filters?.group?.value;

  return (
    <Stack spacing={2}>
      <Box bgcolor="#fff" borderRadius={1} px={4} py={2}>
        <Filters filters={filters} isLoading={isLoading} updateFilters={setFilters} />
      </Box>
      {isLoading ? (
        <LoadingHolder />
      ) : (
        <>
          <Box bgcolor="#fff" borderRadius={1} p={4}>
            <>
              <Typography variant="h3">распределение тренировочной деятельности по разделам подготовки (%)</Typography>
              {!noData && hasGroupAndSchoolFilters ? (
                <ImprovementChart data={data} />
              ) : (
                <NoDataOrError>
                  <Typography>
                    {!hasGroupAndSchoolFilters ? ' Выберите школу и команду' : 'Данные отсутствуют'}
                  </Typography>
                </NoDataOrError>
              )}
            </>
          </Box>
          <Box bgcolor="#fff" borderRadius={1} overflow="hidden">
            {!noData && hasGroupAndSchoolFilters ? (
              <SectionsTable data={data} />
            ) : (
              <NoDataOrError>
                <Typography>
                  {!hasGroupAndSchoolFilters ? ' Выберите школу и команду' : 'Данные отсутствуют'}
                </Typography>
              </NoDataOrError>
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};

export default TrainingSkillsImprovementReport;
