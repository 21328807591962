import {pluralize} from '@src/helpers';
import {Stack} from '@ui/MUI';
import Skeleton from '@ui/MUI/Skeleton';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

export default function AdminStatsPanel() {
  const history = useHistory();

  const teachers = useSelector((state) => ({
    data: [...state.users.usersMap.toArray().filter((a) => a.userRole === 'teacher')],
    loading: state.users.loading,
  }));
  const schools = useSelector((state) => ({
    data: [...state.schools.schoolsMap.toArray().sort((a, b) => +b.timestamp - +a.timestamp)],
    loading: state.schools.loading,
  }));
  const students = useSelector((state) => ({
    data: [...state.users.usersMap.toArray().filter((a) => a.userRole === 'student')],
    loading: state.users.loading,
  }));
  const lessons = useSelector((state) => ({
    data: [...state.lessons.lessonsMap.toArray().sort((a, b) => +b.startTimestamp - +b.startTimestamp)],
    loading: state.lessons.loading,
  }));

  const dashboardData = useMemo(
    () => [
      {
        link: '/students',
        data: students,
        getText() {
          return 'Все игроки';
        },
      },
      {
        link: '/teachers',
        data: teachers,
        getText() {
          return pluralize(teachers.data.length, ['Тренер', 'Тренера', 'Тренеров']);
        },
      },
      {
        link: '/schools',
        data: schools,
        getText() {
          return pluralize(schools.data.length, ['Школа', 'Школы', 'Школ']);
        },
      },
      {
        data: lessons,
        getText() {
          return pluralize(lessons.data.length, ['Занятие', 'Занятия', 'Занятий']);
        },
      },
    ],
    [students, teachers, schools, lessons],
  );
  return (
    <>
      <StyledPageTitle breadCrumbs={[{label: 'Главная', path: null}]}>Рабочий стол администратора</StyledPageTitle>
      <Row gap={1}>
        {dashboardData.map((item, index) => (
          <Box key={index}>
            <BoxInner
              hoverable={item.link}
              onClick={() => {
                item?.link && history.push(item.link);
              }}
            >
              <ParamValue>{item.data?.loading ? <Skeleton width={120} /> : item.data.data.length}</ParamValue>
              <ParamName>{item.data.loading ? <Skeleton width={200} /> : item.getText()}</ParamName>
            </BoxInner>
          </Box>
        ))}
      </Row>
    </>
  );
}

const StyledPageTitle = styled((props) => <PageTitleDefault {...props} />)`
  margin-left: 0;
`;

const Row = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Box = styled.div`
  flex: 1;
`;

const ParamName = styled.div`
  white-space: nowrap;
  font-size: 24px;
  line-height: 32px;
`;

const ParamValue = styled.div`
  font-size: 64px;
  line-height: 64px;
`;

const BoxInner = styled.div`
  background: white;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  align-content: space-between;
  cursor: ${(props) => (props.hoverable === true ? 'pointer' : 'default')};
  :hover {
    opacity: ${(props) => (props.hoverable === true ? 0.7 : 1)};
  }
`;
