import ConfirmModal from '@common/ConfirmModal';
import useStore from '@hooks/useStore';
import {LoadingButton} from '@mui/lab';
import ButtonDefault from '@ui/MUI/Button';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React, {useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

const Form = ({form, content, onSubmit, name, title}) => {
  const formState = useStore((state) => state.trainingControlProcess[name]?.form);
  const {
    formState: {isDirty, isValid},
  } = useFormContext();

  const isEdit = useWatch({name: 'id'});

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  return (
    <>
      <DrawerDefault
        buttons={
          <Stack direction="row" justifyContent="space-between">
            <LoadingButton
              color="primary"
              disabled={!isValid || !isDirty}
              loading={formState?.isLoading}
              variant="contained"
              onClick={onSubmit}
            >
              {isEdit ? 'Сохранить' : 'Добавить'}
            </LoadingButton>
            <ButtonDefault
              disableElevation
              variant="contained"
              onClick={() => {
                if (isDirty) {
                  setIsConfirmModalVisible(true);
                } else {
                  form.close();
                }
              }}
            >
              Отменить
            </ButtonDefault>
          </Stack>
        }
        content={
          <Stack spacing={5}>
            <TypographyDefault variant="h3">{title}</TypographyDefault>
            {content}
          </Stack>
        }
        isOpen={form.isOpen}
        setDrawerState={form.close}
        onClose={() => {
          if (isDirty) {
            setIsConfirmModalVisible(!isConfirmModalVisible);
          } else {
            form.close();
          }
        }}
      />
      <ConfirmModal
        isToggle={isConfirmModalVisible}
        onActionButtonClick={() => {
          setIsConfirmModalVisible(false);
          form.close();
        }}
        onClose={() => {
          setIsConfirmModalVisible(!isConfirmModalVisible);
        }}
      />
    </>
  );
};

export default Form;
