import PlayersDiary from '@components/apps/DevelopmentJournal/components/PlayersDiary';
import useQueryStringTabs from '@hooks/useQueryStringTabs';
import TabsNew from '@ui/MUI/TabsNew';
import React from 'react';

import ArchiveTab from './components/Archive';
import CategoryEstimateTab from './components/CategoryEstimate';
import PlayersTab from './components/Players';

const tabs = [
  {id: 'playersDiary', label: 'Дневник хоккеистов', value: <PlayersDiary />},
  {
    id: 'players',
    label: 'Игроки',
    value: <PlayersTab />,
  },
  {
    id: 'playersSkills',
    label: 'Навыки Игроков',
    value: <CategoryEstimateTab />,
  },
  {
    id: 'archive',
    label: 'Архив',
    value: <ArchiveTab />,
  },
];

function DevelopmentJournal() {
  const {value, onChange} = useQueryStringTabs({tabs});
  return <TabsNew withoutWrapper tabs={tabs} value={value} onChange={onChange} />;
}

export default DevelopmentJournal;
