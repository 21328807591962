import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

export function getStatsForOneGame(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/index`, {params});
}
export function getStatsMapInfo(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mapinfo`, {params});
}
export function getStatsThrowsStatistics(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/shotsinfo`, {params});
}
export function getPlayersStatistics(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/matchplayers`, {params});
}
export function getStatsMatchShifts(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/matchshifts`, {params});
}
export function getMatchgoaltendersnames(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/matchgoaltendersnames`, {params});
}
export function getMatchgoaltendersinfo(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/matchgoaltendersinfo`, {params});
}
export function postStatsFile(params, resetFn = () => {}) {
  return axios.post(`${API_ENDPOINT}/v1/statistics/upload`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    success: (res) => {
      resetFn();
      const message = res?.data?.message || 'Файл успешно загружен';
      return {message: message};
    },
    failed: (err) => {
      resetFn();
      const message = err?.data?.message || 'Что-то пошло не так';
      return {message: message};
    },
  });
}
export function getStatisticsGamesList(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/upload/matches`, {params});
}
export function getStatisticsFilesList(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/list`, {params});
}
export function deleteStatsFile(id) {
  return axios.delete(`${API_ENDPOINT}/v1/statistics/${id}`, {
    success: () => {
      return {message: 'Файл успешно удален'};
    },
    failed: (err) => {
      const message = err?.data?.message || 'Произошла ошибка при удалении файла';
      return {message: message};
    },
  });
}
export function getStatisticsTeamNames(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/teamnames`, {params});
}
export function getThrowsMapPlayers(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/mapinfo/players`, {params});
}
export function getActionsMapPlayers(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/games/players`, {params});
}
export function getThrowsinsStatistics({cancelToken, ...params}) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/faceoffs`, {cancelToken, params});
}
export function getThrowinsMap({cancelToken, ...params}) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/faceoffs/map`, {cancelToken, params});
}
export function getThrowinsMapPlayers(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/faceoffs/players`, {params});
}

export function getActions({cancelToken, ...params}) {
  return axios.get(
    `${API_ENDPOINT}/v1/statistics/games/actions`,
    {cancelToken, params},
    {
      failed: () => ({message: 'Ошибка при получении данных о действиях'}),
    },
  );
}

export function getActionsTypes(params) {
  return axios.get(
    `${API_ENDPOINT}/v1/statistics/games/actions/types`,
    {params},
    {
      failed: () => ({message: 'Ошибка при получении данных действий'}),
    },
  );
}

export function getGameActionById(actionId, cancelToken) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/games/actions/action/${actionId}`, {cancelToken});
}
