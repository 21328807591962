import {getPlayerRang} from '@api/PhysicalTestingAPI';
import NamedCard from '@components/apps/PlayerPersonalFile/components/MainInfo/components/NamedCard';
import useDeepMemo from '@hooks/useDeepMemo';
import {longDash} from '@src/constants';
import {Box, Divider, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {isEmpty} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';

function GPTCard({...props}) {
  const [tests, setTests] = useState({isLoading: false, data: {}, error: false});
  const {id} = useParams();
  useEffect(() => {
    setTests((prev) => ({...prev, isLoading: true}));
    getPlayerRang(id)
      .then(({data}) => setTests((prev) => ({...prev, isLoading: false, data})))
      .catch(() => setTests((prev) => ({...prev, isLoading: false, error: true})));
  }, [id]);
  const {ofp, sfp} = tests?.data || {ofp: {}, sfp: {}};
  const isLoading = useMemo(() => props?.isLoading || tests?.isLoading, [props?.isLoading, tests?.isLoading]);
  const currentTests = useDeepMemo(() => {
    const result = [];
    if (ofp) result.push({...ofp, itemTitle: 'ОФП'});
    if (sfp) result.push({...sfp, itemTitle: 'СФП'});
    return result;
  }, [ofp, sfp]);
  return (
    <NamedCard flex={1} minWidth={240} title="Тесты офп/сфП" width="fill-available">
      {((isEmpty(ofp) && isEmpty(sfp)) || tests?.error) && !isLoading ? (
        <Stack alignItems="center" height={100} justifyContent="center">
          <Typography variant="subtitle1">Нет данных</Typography>
        </Stack>
      ) : (
        <>
          <Stack
            direction="column"
            divider={<Divider orientation="horizontal" />}
            height="100%"
            justifyContent="center"
            spacing={3}
          >
            {currentTests?.map((item, index) => (
              <Box key={index}>
                <Stack direction="row" gap={0} mb={0}>
                  <Typography color={grey['54']} variant="body1">
                    Сезон:
                  </Typography>
                  <Typography
                    color={grey['54']}
                    isLoading={isLoading}
                    preloaderWidthBetween={[30, 45]}
                    variant="subtitle1"
                  >
                    {item?.season || longDash}
                  </Typography>
                </Stack>
                <Stack direction="row" gap={5}>
                  <Box>
                    <Typography mb={1} variant="h4">
                      {item?.itemTitle || longDash}
                    </Typography>
                    <Stack direction="row" gap={0} mb={0}>
                      <Typography color={grey['54']} variant="body1">
                        Этап:
                      </Typography>
                      <Typography
                        color={grey['54']}
                        isLoading={isLoading}
                        preloaderWidthBetween={[10, 25]}
                        variant="subtitle1"
                      >
                        {item?.stage || longDash}
                      </Typography>
                    </Stack>
                  </Box>
                  {item?.rang && (
                    <Box>
                      <Stack alignItems="flex-end" direction="row" gap={0} mb={0}>
                        <Score color="primary" isLoading={isLoading} preloaderWidthBetween={[30, 45]}>
                          {item?.rang}
                        </Score>
                        <Typography color="primary" variant="subtitle1">
                          общий
                        </Typography>
                      </Stack>
                      <Typography variant="body2">ранг</Typography>
                    </Box>
                  )}
                </Stack>
              </Box>
            ))}
          </Stack>
        </>
      )}
    </NamedCard>
  );
}

export default GPTCard;

const Score = styled(Typography)`
  font-family:
    Proxima Nova,
    serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
`;
