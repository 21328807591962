import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import CommonHelper from '../../../helpers/CommonHelper';
import StateHelper from '../../../helpers/StateHelper';
import {cleanGameWithoutEstimateReport} from '../../../redux/actions/GameEstimateActions';
import SelectDefault from '../../ui/Select';

export default function ListOfParticipantsWithoutEstimationFilter(props) {
  const {schools, onChange = () => {}} = props;

  let {defaultFilterOptions} = useSelector((state) => {
    let currentUser = StateHelper.getCurrentUser(state);
    let userRole = currentUser?.userRole;

    let defaultFilterOptions = {
      group: '',
      school: '',
    };
    if (userRole === 'teacher') {
      defaultFilterOptions = StateHelper.getTeacherDefaultFilterOptions(state, currentUser.id);
    }

    return {
      defaultFilterOptions,
    };
  });

  const dispatch = useDispatch();

  const [schoolsOptions, setSchoolsOptions] = useState([]);
  const [school, setSchool] = useState(
    Object.keys(defaultFilterOptions.school).length ? defaultFilterOptions.school : '',
  );
  const [sortedGroups, setSortedGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(
    Object.keys(defaultFilterOptions.group).length ? defaultFilterOptions.group : '',
  );
  const [sortedLessons, setSortedLessons] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState('');

  const getDropDownMatchesByNamedList = (arr) => {
    return arr
      .map((a) => {
        let eventStartTimestamp = 0;
        if (a.rooms.length) {
          if (a.rooms[0].startTimestamp) {
            eventStartTimestamp = a.rooms[0].startTimestamp;
          } else {
            eventStartTimestamp = a.startTimestamp;
          }
        }
        const startTimestampInSec = eventStartTimestamp / 1000;
        const labelDate = moment.unix(startTimestampInSec).format('DD.M');

        return {
          value: a.id,
          label: `${labelDate} - ${a.name}`,
          date: a.startTimestamp,
        };
      })
      .sort((a, b) => +a.date - +b.date)
      .reverse();
  };

  let {matches} = useSelector((state) => {
    return {
      matches: state.lessons.lessonsMap.toArray() || [],
    };
  });

  //TODO вынести convertSchoolToOptions в helpers
  const convertSchoolToOptions = (schools) => {
    if (!schools) return;
    let result = schools.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

    result.sort((a, b) => {
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      return 0;
    });

    return result;
  };
  useEffect(() => {
    if (!schoolsOptions?.length && schools.length) setSchoolsOptions(convertSchoolToOptions(schools));
  }, [schools]);
  useEffect(() => {
    if (school.value) {
      StateHelper.getGroupsBySchoolId(school.value).then((options) => {
        setSortedGroups(options);
      });
    }
  }, [school.value]);

  useEffect(() => {
    if (selectedGroup) {
      setSortedLessons(matches.filter((el) => el.groupId === selectedGroup.value && el.startTimestamp < new Date()));
    }
  }, [selectedGroup]);

  return (
    <Wrapper>
      <Filter>
        <Column>
          <Label>Школа</Label>
          <SelectDefault
            isLoading={!schoolsOptions.length > 0}
            isMulti={false}
            options={schoolsOptions}
            placeholder={'Выберите школу'}
            value={school}
            onChange={(school) => {
              setSchool(school);
              setSortedLessons(matches.filter((el) => el.schoolId === school.id));
              setSelectedMatch('');
              setSelectedGroup('');
              dispatch(cleanGameWithoutEstimateReport());
            }}
          />
        </Column>
        <Column>
          <Label>Команда</Label>
          <SelectDefault
            isDisabled={school ? false : true}
            isMulti={false}
            options={sortedGroups ? CommonHelper.getDropDownTeamsByNamedList(sortedGroups) : []}
            placeholder={school ? 'Выберите команду' : 'Вы не выбрали школу'}
            value={selectedGroup}
            onChange={(group) => {
              setSelectedGroup(group);
              setSelectedMatch('');
              setSortedLessons(matches.filter((el) => el.groupId === group.value && el.startTimestamp < new Date()));
              dispatch(cleanGameWithoutEstimateReport());
            }}
          />
        </Column>
        <Column>
          <Label>Матч</Label>
          <SelectDefault
            isDisabled={selectedGroup ? false : true}
            isMulti={false}
            options={sortedLessons ? getDropDownMatchesByNamedList(sortedLessons) : []}
            placeholder={selectedGroup ? 'Выберите матч' : 'Вы не выбрали команду'}
            value={selectedMatch}
            onChange={(match) => {
              setSelectedMatch(match);
              onChange(match);
            }}
          />
        </Column>
      </Filter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 12px 32px;
  background-color: white;
  @media screen and (max-width: 767px) {
    padding: 12px 18px;
  }
`;

const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -14px;
  margin-left: -14px;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    margin-right: 0;
    margin-left: 0;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font: 600 normal 12px/16px 'Proxima Nova';
  color: #747474;
  text-transform: uppercase;
`;
const Column = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 14px;
  padding-left: 14px;
  box-sizing: border-box;

  .RangePicker {
    .react-daterange-picker__inputGroup__input,
    .react-daterange-picker__inputGroup__leadingZero {
      font-weight: bold !important;
    }
  }

  @media screen and (max-width: 1199px) {
    margin-bottom: 12px;

    &:nth-child(4),
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (min-width: 993px) and (max-width: 1199px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
    &:nth-child(4) {
      margin-bottom: 12px;
    }
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    &:nth-child(4) {
      margin-bottom: 12px;
    }
  }
`;
