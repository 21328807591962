import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import PageTitleDefault from '@ui/PageTitleDefault';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {NOTIFICATION_TYPES} from '../../../constants/config';
import StateHelper from '../../../helpers/StateHelper';
import * as psychoProfileActions from '../../../redux/actions/PsychoProfileActions';
import LessonView from '../../calendar/components/sidebar/components/LessonView';
import Widget from '../../common/widget/Widget';
import LocalPreloader from '../../preloader/LocalPreloader';
import {
  DOCTOR_STATUSES_OPTIONS,
  DOCTOR_SUB_STATUSES_OPTIONS,
  NOTAVILABLE_SUB_STATUSES_OPTIONS,
} from '../../students/constants';
import PlayersHorizontalCard from '../../students/tools/PlayersHorizontalCard';
import {ReactComponentNotification} from '../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import TableEmptyBlock from '../../ui/TableEmptyBlock';
import InjuredPlayers from '../../widgets/InjuredPlayers';
import DashboardsBlockPsycho from '../tools/DashboardsBlockPsycho';

export default function PsychoProfilePanel() {
  const dispatch = useDispatch();
  const psychoProfile = useSelector((state) => state.psychoProfile);
  const allStatuses = DOCTOR_SUB_STATUSES_OPTIONS.concat(NOTAVILABLE_SUB_STATUSES_OPTIONS, DOCTOR_STATUSES_OPTIONS);
  const injuredPlayers = psychoProfile?.injuredPlayers.data;
  const schedule = psychoProfile?.psychoDashboard.data.data;
  const sortedSchedule = schedule?.sort((a, b) => a.startTimestamp - b.startTimestamp);
  const [lessonViewVisible, setLessonViewVisible] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });
  const [lessonViewLoading, setLessonViewLoading] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState({});

  const [fullLessonInformation, setFullLessonInformation] = useState({
    players: [],
  });

  const calculateTimeEnd = (timeStart, duration) => {
    return timeStart + duration;
  };

  const block = (players, contentStatus, emptyText, statusText, teamText) => {
    return (
      <ContentWrapper>
        {players.length ? (
          <>
            <BoxShadow>
              <HeadlineSpaceBetween>
                <Column flexSize="33.333%">
                  <HeadlineColumnTitle>ФИО игрока</HeadlineColumnTitle>
                </Column>
                <Column flexSize="33.333%">
                  <HeadlineColumnTitle>{teamText ? teamText : 'Команда'}</HeadlineColumnTitle>
                </Column>
                <Column flexSize="33.333%">
                  <HeadlineColumnTitle>{statusText ? statusText : 'Статус'}</HeadlineColumnTitle>
                </Column>
              </HeadlineSpaceBetween>
            </BoxShadow>
            <ContentBody>
              {players.map((item) => {
                return (
                  <ContentRow key={item.playerId}>
                    <CardContainer>
                      <Link target="_blank" to={`/student/${item.student.playerId}`}>
                        <CardFlexWrapper>
                          <PlayersHorizontalCard isShortName student={item.student} />
                        </CardFlexWrapper>
                      </Link>
                    </CardContainer>

                    <ContentRowTextTeam>{item.group.name}</ContentRowTextTeam>

                    <ContentRowTextBlock>
                      <ContentRowText>
                        {item.status ? allStatuses.find((status) => status.value === item.status)?.label : ''}
                      </ContentRowText>
                    </ContentRowTextBlock>
                  </ContentRow>
                );
              })}
            </ContentBody>
          </>
        ) : (
          <TableEmptyBlock text={emptyText} />
        )}
      </ContentWrapper>
    );
  };

  useEffect(() => {
    dispatch(psychoProfileActions.getInjuredPlayers());
    dispatch(psychoProfileActions.getPsychoDashboard());
  }, []);
  return (
    <Wrapper>
      <PageTitleDefault>Рабочий стол психолога</PageTitleDefault>
      <Widgets>
        <Half>
          <InjuredPlayers contentBlock={block} injuredPlayers={injuredPlayers} />
          <DrawerDefault
            content={
              <>
                <LocalPreloader visible={lessonViewLoading} />
                <ModalTitle>Просмотр мероприятия</ModalTitle>
                <LessonView
                  groups={[selectedLesson.group]}
                  isButtonsShowed={false}
                  players={fullLessonInformation.players}
                  selectedLesson={selectedLesson}
                />
              </>
            }
            drawerState={lessonViewVisible}
            setDrawerState={setLessonViewVisible}
          />
        </Half>
        <Half>
          <DashboardsBlockPsycho
            buttonsBlockType="link"
            content={
              <ContentWrapperSchedule>
                {sortedSchedule ? (
                  <>
                    <BoxShadow>
                      <HeadlineSpaceBetween>
                        <Column>
                          <HeadlineColumnTitle>Время</HeadlineColumnTitle>
                        </Column>
                        <Column>
                          <HeadlineColumnTitle>Тип мероприятия</HeadlineColumnTitle>
                        </Column>
                        <Column>
                          <HeadlineColumnTitle>Команда</HeadlineColumnTitle>
                        </Column>
                        <Column>
                          <HeadlineColumnTitle>Помещение</HeadlineColumnTitle>
                        </Column>
                      </HeadlineSpaceBetween>
                    </BoxShadow>
                    <ContentBody>
                      {sortedSchedule.map((item) => {
                        const timeEnd = item.startTimestamp
                          ? calculateTimeEnd(item.startTimestamp, item.rooms[0].duration)
                          : calculateTimeEnd(item.rooms[0].startTimestamp, item.rooms[0].duration);
                        return (
                          <ScheduleItem
                            key={item.objectId}
                            onClick={() => {
                              setSelectedLesson(item);
                              if (item.group) {
                                setLessonViewLoading(true);
                                StateHelper.getStudentsByTeamId(item.groupId)
                                  .then((res) => {
                                    setFullLessonInformation({
                                      players: res,
                                    });
                                    setLessonViewLoading(false);
                                  })
                                  .catch((error) => {
                                    setLessonViewLoading(false);
                                    ReactComponentNotification(
                                      NOTIFICATION_TYPES['error'],
                                      `Ошибка сервера: ${error}, попробуйте позже`,
                                    );
                                  });
                              }
                              setLessonViewVisible({...lessonViewVisible, right: true});
                            }}
                          >
                            <Column>
                              <ContentTextScheduleItem>
                                {moment(item.startTimestamp).format('HH:mm')} - {moment(timeEnd).format('HH:mm')}
                              </ContentTextScheduleItem>
                            </Column>
                            <Column>
                              <ContentText>{item.fullName}</ContentText>
                            </Column>
                            <Column>
                              <ContentText>{item.group.name}</ContentText>
                            </Column>
                            <Column>
                              <ScheduleItemRoom>{item.rooms[0].name || ''}</ScheduleItemRoom>
                            </Column>
                          </ScheduleItem>
                        );
                      })}
                    </ContentBody>
                  </>
                ) : (
                  <TableEmptyBlock text="На сегодня мероприятий больше нет" />
                )}
              </ContentWrapperSchedule>
            }
            link="/schedule?viewMode=week"
            linkText="Посмотреть расписание на неделю"
            title="Расписание на сегодня"
            titleSpan={`${moment().format('DD MMMM, dd')}`}
          />
          <Widget headerTitle="Прошедшие игры сезона" type="pastGames" withTabs={true} />
        </Half>
      </Widgets>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 8px;
  padding-right: 6px;
  .EventModal {
    max-width: 60%;
    @media screen and (max-width: 767px) {
      max-width: none;
      width: 100% !important;
    }
    .sidebar__header {
      border-bottom: 0;
    }
    .Heading {
      @media screen and (max-width: 767px) {
        top: -8px;
      }
    }
  }
  .LocalPreloader {
    @media screen and (max-width: 767px) {
      top: 20px;
    }
  }
`;
const ModalTitle = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  font: bold normal 16px/20px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;
const CardContainer = styled.div`
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media screen and (max-width: 480px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  & > a {
    display: block;
    text-decoration: none;
    .LinesAvailablePlayerName {
      transition: text-decoration 0.33s;
    }
    &:focus {
      .LinesAvailablePlayerName {
        text-decoration: none;
      }
    }
    &:hover {
      .LinesAvailablePlayerName {
        text-decoration: underline;
      }
    }
  }
`;
const CardFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  .LinesAvailablePlayerAvatar {
    margin-left: 0;
  }
`;
const ContentText = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font: 600 normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
  span {
    color: #626262;
  }
`;
const ContentTextScheduleItem = styled(ContentText)`
  color: #626262;
  letter-spacing: -0.02em;
`;
const Half = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 0 8px;
  @media screen and (max-width: 1440px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 8px 0 0;
  }
  &:first-child {
    @media screen and (max-width: 1199px) {
      padding-top: 0;
    }
  }
`;
const ContentWrapper = styled.div`
  .TableEmptyBlock {
    padding-left: 24px;
    padding-right: 24px;
    border-top: 1px solid #dddad4;
    border-bottom: 1px solid #dddad4;
    justify-content: flex-start;
    min-height: 80px;
    @media screen and (max-width: 360px) {
      padding-left: 12px;
      padding-right: 12px;
    }
    p {
      @media screen and (max-width: 992px) {
        text-align: left;
      }
    }
  }
`;
const Headline = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 4px 24px;
  background-color: white;
  border-top: 1px solid #dddad4;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
`;
const Column = styled.div`
  flex: ${(props) => (props.flexSize ? `0 0 ${props.flexSize}` : '0 0 50%')};
  max-width: ${(props) => (props.flexSize ? props.flexSize : '25%')};
  box-sizing: border-box;
  &:nth-child(2) {
    @media screen and (min-width: 481px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  &:first-child {
    ${ContentText} {
      &:last-child {
        padding-left: 12px;
      }
    }
  }
`;
const HeadlineColumnTitle = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
`;
const HeadlineSpaceBetween = styled(Headline)`
  /* justify-content: space-between; */
  ${Column} {
    &:last-child {
      ${HeadlineColumnTitle} {
        text-align: right;
      }
    }
  }
`;
const ContentBody = styled.div`
  background-color: white;
`;
const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px 24px;
  min-height: 53px;
  background-color: white;
  border-bottom: 1px solid #dddad4;
  @media screen and (max-width: 475px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 360px) {
    padding: 12px;
  }
`;
const ContentRowTextBlock = styled.div`
  flex: 0 0 33.333%;
  max-width: 33.333%;
  text-align: right;
  @media screen and (max-width: 480px) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left;
  }
  &:last-child {
    @media screen and (max-width: 480px) {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
`;

const ContentRowText = styled(HeadlineColumnTitle)`
  color: #626262;
  @media screen and (max-width: 480px) {
    max-width: none;
  }
`;

const ContentRowTextTeam = styled(HeadlineColumnTitle)`
  color: #626262;
  @media screen and (min-width: 481px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding-left: 16px;
    padding-right: 16px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 8px;
    max-width: none;
  }
`;
const ScheduleItem = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #f3f3f350;
  padding-right: 24px;
  padding-left: 24px;
  cursor: pointer;
  @media screen and (max-width: 360px) {
    padding-left: 12px;
    padding-right: 12px;
  }
  &:first-child {
    ${Column} {
      .ScheduleItemText {
        color: #990011;
      }
    }
  }
  ${Column} {
    padding-top: 12px;
    padding-bottom: 12px;
    flex: 0 0 50%;
    max-width: 25%;
    &:nth-child(2) {
      @media screen and (max-width: 480px) {
        padding-top: 0;
      }
    }
    &:last-child {
      @media screen and (max-width: 480px) {
        padding-left: 0;
      }
    }
  }
`;

const Widgets = styled.div`
  display: flex;
  @media screen and (max-width: 1440px) {
    flex-direction: column;
  }
`;

const ScheduleItemRoom = styled(HeadlineColumnTitle)`
  color: #626262;
  text-align: right;
`;
const ContentWrapperSchedule = styled(ContentWrapper)`
  ${HeadlineSpaceBetween},
  ${ScheduleItem} {
    ${Column} {
      &:last-child {
        flex: 0 0 32%;
        max-width: 32%;
        @media screen and (max-width: 480px) {
          flex: 0 0 50%;
          max-width: 50%;
          order: 2;
        }
      }
      &:first-child {
        flex: 0 0 18%;
        max-width: 18%;
        @media screen and (max-width: 480px) {
          flex: 0 0 50%;
          max-width: 50%;
          order: 1;
        }
        ${ContentText} {
          &:last-child {
            padding-left: 0;
            color: #626262;
          }
          &:first-child {
            @media screen and (max-width: 360px) {
              padding-left: 0;
              color: #626262;
            }
          }
        }
      }
      &:nth-child(2) {
        @media screen and (max-width: 480px) {
          flex: 0 0 100%;
          max-width: 100%;
          order: 3;
        }
      }
    }
  }
`;
const BoxShadow = styled.div`
  position: relative;
  @media screen and (max-width: 480px) {
    display: none;
  }
  &:after {
    display: block;
    width: 100%;
    height: 2px;
    box-shadow: 0 5px 5px -5px #333;
    content: '';
  }
`;
