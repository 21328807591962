import {LinearProgress} from '@mui/material';
import {Box} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

LoadingBox.propTypes = {
  isLoading: PropTypes.bool,
};

function LoadingBox({isLoading, children, sx = {}}) {
  return (
    <Box
      sx={{
        pointerEvents: isLoading ? 'none' : 'all',
        opacity: isLoading ? 0.5 : 1,
        transition: '.3s ease-in-out',
        position: 'relative',
        ...sx,
      }}
    >
      {isLoading && <LinearProgress color="primary" data-testid="linear-progress" />}
      {children}
    </Box>
  );
}

export default LoadingBox;
