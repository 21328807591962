import {createTournament, deleteTournament} from '@api/TournamentsAPI';
import ConfirmModal from '@common/ConfirmModal';
import Field from '@common/FormField';
import GroupFields from '@components/apps/Tournaments/components/CreateTournamentModal/components/GroupFields';
import SchoolField from '@components/apps/Tournaments/components/CreateTournamentModal/components/SchoolField';
import SeasonField from '@components/apps/Tournaments/components/CreateTournamentModal/components/SeasonField';
import {groupFieldsDefault} from '@components/apps/Tournaments/constants';
import {useGroupsV2} from '@hooks/filtersV2';
import {useSeasons} from '@hooks/seasonsHooks';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectCurrentTournament} from '@selectors/tournaments';
import {clearCurrentEditTournament} from '@slices/tournaments';
import {parseFormToGetValue} from '@src/helpers';
import {Box, Button, Stack} from '@ui/MUI';
import Drawer from '@ui/MUI/DrawerNew';
import LoadingButton from '@ui/MUI/LoadingButton';
import TypographyDefault from '@ui/MUI/Typography';
import {isEmpty} from 'lodash';
import uniqBy from 'lodash/uniqBy';
import React, {memo, useCallback, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';

const defaultValues = {name: '', school: null, season: null, groups: [groupFieldsDefault]};
const CreateTournamentModal = ({isOpen, setIsOpen, loadTournaments = () => {}}) => {
  const dispatch = useStoreDispatch();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const history = useHistory();
  const form = useForm({defaultValues});
  const {
    watch,
    reset,
    formState: {isDirty},
  } = form;
  const groups = useGroupsV2({schoolIds: watch('school')?.map((school) => school?.value || school?.id)});
  const seasons = useSeasons();
  const {data} = useStore(selectCurrentTournament);

  const handleClearForm = useCallback(() => {
    reset(defaultValues);
    dispatch(clearCurrentEditTournament());
  }, [reset]);
  const onFormSubmit = async (form) => {
    setIsLoading(false);
    const formData = parseFormToGetValue(form);
    formData.groups = form.groups?.map((group, index) => {
      return {
        ...group,
        name: `Группа ${index + 1}`,
        teams: group?.teams?.map((team) => team?.team?.value || team),
      };
    });
    createTournament(formData)
      .then(() => {
        loadTournaments();
        setIsOpen(false);
        handleClearForm();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useDeepEffect(() => {
    if (!isEmpty(data) && isOpen) {
      const season = seasons?.options?.find((season) => season?.value === data?.season);
      const school = [];
      const groups = data?.groups?.map((group) => ({
        ...group,
        teams: group?.teams?.map((team) => {
          if (team?.school?.id) {
            school.push({...team.school, value: team.school.id, label: team.school.name});
          }
          return {team: {...(team || {}), value: team?.id, label: team?.name}};
        }),
      }));
      reset({...data, season, groups, school: uniqBy(school, 'value')});
    }
  }, [data, seasons?.options, isOpen]);

  return (
    <>
      <Drawer
        buttons={
          <Stack direction="row" gap={2} justifyContent="space-between">
            <LoadingButton
              color="primary"
              loading={isLoading}
              variant="contained"
              onClick={form.handleSubmit(onFormSubmit)}
            >
              Сохранить
            </LoadingButton>
            <LoadingButton
              color="primary"
              disabled={!data?.id}
              loading={isRemoving}
              sx={{ml: 'auto'}}
              variant="outlined"
              onClick={() => {
                setIsRemoveModalOpen(true);
              }}
            >
              удалить турнир
            </LoadingButton>
            <Button
              variant="contained"
              onClick={() => {
                if (isDirty) {
                  setIsConfirmModalVisible(!isConfirmModalVisible);
                } else {
                  setIsOpen(false);
                  history.push('/tournaments');
                }
              }}
            >
              Отменить
            </Button>
          </Stack>
        }
        open={isOpen}
        title="Создание турнира"
        width="700px"
        onClose={() => {
          if (isDirty) {
            setIsConfirmModalVisible(!isConfirmModalVisible);
          } else {
            setIsOpen(false);
            handleClearForm();
          }
        }}
      >
        <FormProvider {...form}>
          <form>
            <Box mt={5}>
              <Stack direction="column" gap={3} width="100%">
                <Box width="100%">
                  <Field isRequired label="Название турнира" name="name" type="input" />
                </Box>
                <Stack direction="row" spacing={4} width="100%">
                  <Box width="50%">
                    <Field
                      isRequired
                      label="Сезон"
                      multiple={false}
                      name="season"
                      options={seasons?.options}
                      type="autocomplete"
                    />
                    <SeasonField />
                  </Box>
                  <Box width={(theme) => `calc(50% - ${theme.spacing(4)})`}>
                    <SchoolField />
                  </Box>
                </Stack>
              </Stack>
              <GroupFields isLoading={groups?.isLoading} options={groups?.options} />
            </Box>
          </form>
        </FormProvider>
      </Drawer>
      <ConfirmModal
        actionText="Да"
        closeText="Нет"
        content={
          <>
            <TypographyDefault>Удаление турнира приведет к потере всех данных по нему!</TypographyDefault>
            <TypographyDefault>Удалить все равно?</TypographyDefault>
          </>
        }
        isToggle={isRemoveModalOpen}
        title="Подтвердите удаление турнира"
        onActionButtonClick={() => {
          setIsRemoving(true);
          deleteTournament(data?.id)
            .then(() => {
              loadTournaments();
              setIsOpen(false);
              handleClearForm();
            })
            .finally(() => {
              setIsRemoveModalOpen(false);
              setIsRemoving(false);
            });
        }}
        onClose={() => {
          setIsRemoveModalOpen(false);
        }}
      />
      <ConfirmModal
        actionText="Продолжить"
        isToggle={isConfirmModalVisible}
        onActionButtonClick={() => {
          setIsConfirmModalVisible(false);
          setIsOpen(false);
          handleClearForm();
        }}
        onClose={() => {
          setIsConfirmModalVisible(false);
        }}
      />
    </>
  );
};

export default memo(CreateTournamentModal);
