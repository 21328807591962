import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';

export default {
  createMaterial: (data) => {
    return axios.post(`${API_ENDPOINT}/v1/material`, data, {
      success: {message: 'Материал успешно добавлен'},
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при загрузке'};
      },
    });
  },

  getMaterialsBySection: (params, section) => {
    return axios
      .get(`${API_ENDPOINT}/v1/material/section/${section}`, {
        params,
      })
      .then((res) => res.data);
  },

  getAllMaterials: (params) => {
    return axios.get(`${API_ENDPOINT}/v1/material`, {params}).then((res) => res.data);
  },

  getMaterialById: (id, params) => {
    return axios.get(`${API_ENDPOINT}/v1/material/${id}`, {params}).then((res) => res.data);
  },

  getMaterialTypes: () => {
    return axios.get(`${API_ENDPOINT}/v1/material/material-types`).then((res) => res.data);
  },

  getMaterialVideoAnalytics: (params) => {
    return axios.get(`${API_ENDPOINT}/v1/material/video-analytics`, {params}).then((res) => res.data);
  },

  updateMaterialById: (id, data) => {
    return axios.put(`${API_ENDPOINT}/v1/material/${id}`, data, {
      success: {message: 'Материал успешно обновлен'},
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при обновление'};
      },
    });
  },

  deleteMaterialById: (id) => {
    return axios.delete(`${API_ENDPOINT}/v1/material/${id}`, {
      success: {message: 'Материал успешно удален'},
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при удаление'};
      },
    });
  },

  createMaterialMoments: (id, data) => {
    return axios.put(`${API_ENDPOINT}/v1/material/${id}/moment`, data, {
      success: {message: 'Ключевые моменты успешно обновлены'},
      failed: (err) => {
        const message = err?.data?.message;
        return {message: message || 'Ошибка при обновление'};
      },
    });
  },

  getMaterialTabs: () => {
    return axios.get(`${API_ENDPOINT}/v1/material/tabs`).then((res) => res.data);
  },
};
