import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';

export function getVideoAnalytics(filter) {
  return axios.get(`${API_ENDPOINT}/v1/video-analytics`, {params: filter});
}

export function getVideoAnalyticsGroups(filter) {
  return axios.get(`${API_ENDPOINT}/v1/video-analytics/groups`, {params: filter});
}

export function addNewVideo(videoForm) {
  return axios.post(`${API_ENDPOINT}/v1/video-analytics`, videoForm, {
    success: {message: 'Новое видео успешно добавлено'},
    failed: {message: 'Ошибка при добавлении видео'},
  });
}

export function getOpponents({seasons, groups}) {
  return axios.get(`${API_ENDPOINT}/v1/video-analytics/opponents`, {params: {seasons: seasons, groups: groups}});
}

export function getTournaments({seasons, groups}) {
  return axios.get(`${API_ENDPOINT}/v1/video-analytics/tournaments`, {params: {seasons: seasons, groups: groups}});
}

export function getVideoTags() {
  return axios.get(`${API_ENDPOINT}/v1/video-analytics/tags`);
}

export function getVideo(id) {
  return axios.get(`${API_ENDPOINT}/v1/video-analytics/${id}`);
}

export function deleteVideo(id) {
  return axios.delete(`${API_ENDPOINT}/v1/video-analytics/${id}`, {
    success: {message: 'Видео успешно удалено'},
    failed: {message: 'Ошибка при удалении видео'},
  });
}

export function updateVideo(id, data) {
  return axios.put(`${API_ENDPOINT}/v1/video-analytics/${id}`, data, {
    success: {message: 'Видео успешно отредактировано'},
    failed: {message: 'Ошибка при редактировании видео'},
  });
}
