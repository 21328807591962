import ContentWrapper from '@common/ContentWrapper';
import GameField from '@components/apps/Tournaments/components/TournamentView/components/GameField';
import {CurrentTournament, FormData} from '@components/apps/Tournaments/components/TournamentView/types';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import {selectCurrentTournament} from '@selectors/tournaments';
import {StagesEnum} from '@src/types/tournament';
import {Box, Typography} from '@ui/MUI';
import React, {memo} from 'react';
import {FormProvider, useFieldArray, useFormContext} from 'react-hook-form';

import {gameMock} from '../../constants';

function GroupTournamentContainer({games}: {games: FormData}) {
  const form = useFormContext<FormData>();
  const {control, setValue, reset} = form;
  const {isLoading, teams, data} = useStore<CurrentTournament>(selectCurrentTournament);
  const {fields} = useFieldArray({
    control,
    name: 'group',
  });

  useDeepEffect(() => {
    setValue(
      StagesEnum.GROUP,
      games?.[StagesEnum.GROUP]
        ? games[StagesEnum.GROUP]
        : [...Array(data?.groups?.length || 1)].fill([gameMock, gameMock]),
    );
    return () => {
      reset((formValues) => {
        const {group, ...rest} = formValues;
        return rest;
      });
    };
  }, [data?.groups?.length, games?.group]);

  return (
    <FormProvider {...form}>
      <ContentWrapper>
        <Typography mb={4} variant="h3">
          Групповой турнир
        </Typography>
        {fields?.map((group, index) => {
          return (
            <Box key={group.id}>
              <Typography variant="subtitle1">Игры группы {index + 1}</Typography>
              <GameField
                isTeamsLoading={isLoading}
                minLength={1}
                name={`group[${index}]`}
                requiredFields={['date', 'team1', 'team2']}
                teams={teams?.filter((team) => team?.groupIndex === index)}
              />
            </Box>
          );
        })}
      </ContentWrapper>
    </FormProvider>
  );
}

export default memo(GroupTournamentContainer);
