import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {TEACHERS_ROLES_OPTIONS} from '../../../constants/config';
import {sortTable} from '../../../helpers/sort-table.min';
import {
  cleanGameWithoutEstimateReport,
  loadGameWithoutEstimateReport,
} from '../../../redux/actions/GameEstimateActions';
import * as lessonsActions from '../../../redux/actions/LessonsActions';
import {HOCKEY_ROLES_OPTIONS} from '../../students/constants';
import ListOfParticipantsWithoutEstimationFilter from '../tools/ListOfParticipantsWithoutEstimationFilter';

export default function ListOfParticipantsWithoutEstimationTable() {
  const dispatch = useDispatch();
  let {usersWithoutEstimations, schools} = useSelector((state) => {
    const data = state.gameEstimate.gameWithoutEstimateReport;

    let acc =
      data && Object.prototype.hasOwnProperty.call(data, 'players')
        ? data.players.concat(data.teachers).filter((est) => est.estimated === false)
        : null;
    let usersWithoutEstimations = [];

    if (acc) {
      while (acc.length > 0) {
        usersWithoutEstimations.push(acc.splice(0, 3));
      }
    }

    return {
      schools: state.schools.schoolsMap.toArray(),
      usersWithoutEstimations,
      loading: state.gameEstimate.loading,
    };
  }, shallowEqual);

  const loadWithoutEstimationData = (match) => {
    dispatch(loadGameWithoutEstimateReport(match.value));
  };

  const [selectedMatch, setSelectedMatch] = useState(null);
  useEffect(() => {
    if (usersWithoutEstimations) {
      if (document.getElementById('UsersWithoutEstimations') !== null) {
        sortTable(document.getElementById('UsersWithoutEstimations'), 0, 1);
        sortTable.init();
      }
    }
  }, [usersWithoutEstimations]);
  useEffect(() => {
    dispatch(lessonsActions.loadAllLessons());
  }, []);
  useEffect(() => {
    dispatch(cleanGameWithoutEstimateReport());
  }, []);
  return (
    <Container>
      {/* <FullPagePreloader visible={loading} /> */}
      <ListOfParticipantsWithoutEstimationFilter
        schools={schools}
        onChange={(data) => {
          setSelectedMatch(data);
          loadWithoutEstimationData(data);
        }}
      />
      {usersWithoutEstimations.length ? (
        <Wrapper>
          <Table className={'js-sort-table js-sort-asc'} id={'UsersWithoutEstimations'}>
            <TableHead>
              <TableRow>
                <ColumnHead className={'js-sort-active-default js-sort-asc'}>Амплуа</ColumnHead>
                <ColumnHead className={'js-sort-asc'}>ФИО</ColumnHead>
                <ColumnHead className={'js-sort-asc'}>Амплуа</ColumnHead>
                <ColumnHead className={'js-sort-asc'}>ФИО</ColumnHead>
                <ColumnHead className={'js-sort-asc'}>Амплуа</ColumnHead>
                <ColumnHead className={'js-sort-asc'}>ФИО</ColumnHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersWithoutEstimations.map((row, key) => {
                if (row.length < 3) {
                  row.push({
                    estimated: false,
                    firstName: '',
                    id: '',
                    lastName: '',
                    middleName: '',
                    teacherRole: '',
                  });
                }
                return (
                  <TableRow key={key}>
                    {row.map((col) => {
                      return (
                        <React.Fragment key={col.id}>
                          <Column>
                            {col.hockeyRole
                              ? HOCKEY_ROLES_OPTIONS.filter((hockeyRole) => hockeyRole.value === col.hockeyRole)[0]
                                  .label
                              : TEACHERS_ROLES_OPTIONS.filter((hockeyRole) => hockeyRole.value === col.teacherRole)[0]
                                ? TEACHERS_ROLES_OPTIONS.filter((hockeyRole) => hockeyRole.value === col.teacherRole)[0]
                                    .label
                                : ''}
                          </Column>
                          <Column>
                            {`${col.lastName ? col.lastName : ''} ${col.firstName ? col.firstName : ''} ${
                              col.middleName ? col.middleName : ''
                            }`}
                          </Column>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Wrapper>
      ) : null}
      {!usersWithoutEstimations.length && !selectedMatch ? (
        <TableEmptyBlock>
          <p>Для просмотра отчета выберите матч</p>
        </TableEmptyBlock>
      ) : null}
      {!usersWithoutEstimations.length && selectedMatch ? (
        <Wrapper>
          <InfoRow>
            <p>Все участники выбранной игры завершили оценку</p>
          </InfoRow>
        </Wrapper>
      ) : null}
    </Container>
  );
}

const Container = styled.div``;
const TableEmptyBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  margin-top: 14px;
  min-height: 500px;
  background-color: #eee;
  border-radius: 6px;

  p {
    font: normal normal 18px/24px 'Proxima Nova';
    color: #2c2c2c;
  }
`;

const Wrapper = styled.div`
  overflow-x: scroll;
`;
const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;
const TableRow = styled.tr``;
const InfoRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  margin-top: 14px;
  min-height: 500px;
  background-color: #fff;
  border-radius: 6px;

  p {
    margin-top: 0;
    margin-bottom: 0;
    font: normal normal 18px/24px 'Proxima Nova';
    color: #2c2c2c;
  }
`;
const Column = styled.td`
  padding-right: 34px;
  padding-left: 34px;
  height: 40px;
  font: 600 normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
  @media screen and (max-width: 767px) {
    padding-right: 18px;
    padding-left: 18px;
  }
`;
const ColumnHead = styled.th``;
const TableHead = styled.thead`
  border-top: 2px solid #c0c0c050;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  tr > th {
    position: relative;
    padding-right: 34px;
    padding-left: 34px;
    font: normal normal 14px/20px 'Proxima Nova';
    color: #747474;
    letter-spacing: 0.02em;
    text-align: left;
    @media screen and (max-width: 767px) {
      padding-right: 18px;
      padding-left: 18px;
    }
  }
  @media screen and (max-width: 992px) {
    ${Column} {
      :nth-child(2),
      :nth-child(3) {
        display: none;
      }
    }
  }
`;
const TableBody = styled.tbody`
  border-radius: 0 0 6px 6px;
  ${Column} {
    border-right: 1px solid #dddad4;
    :nth-child(odd) {
      border-right: none;
    }
    :last-child {
      border-right: none;
    }
    @media screen and (max-width: 992px) {
      border-right: none;
    }
  }
`;
