import date from '@helpers/date';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Add} from '@mui/icons-material';
import {selectStagedTests} from '@selectors/functionalTesting';
import {fetchStageTestsByGroupId} from '@slices/functionalTesting';
import ButtonDefault from '@ui/MUI/Button';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';

import {actions} from '../../constants';
import FunctionalStateTableInStagedTesting from '../FunctionalStateTableInStagedTesting';

const StagedTestingReport = ({canEdit}) => {
  const history = useHistory();
  const dispatch = useStoreDispatch();

  const {teamId} = useParams();
  const currentDate = date().format('YYYY-MM-DD');

  const [selectedSeason, setSelectedSeason] = useState();

  useEffect(() => {
    if (!selectedSeason?.length) {
      dispatch(fetchStageTestsByGroupId(teamId));
    } else {
      dispatch(fetchStageTestsByGroupId(teamId, {season: selectedSeason.map((option) => option.value).join(',')}));
    }
  }, [teamId, selectedSeason?.length]);

  const {items} = useStore(selectStagedTests);

  return items?.length || selectedSeason?.length ? (
    <FunctionalStateTableInStagedTesting
      canEdit={canEdit}
      selectedSeason={selectedSeason}
      setSelectedSeason={setSelectedSeason}
      tableData={items}
    />
  ) : (
    <TableEmptyBlock
      content={
        <>
          {canEdit && (
            <ButtonDefault
              color="primary"
              size="medium"
              startIcon={<Add />}
              variant="contained"
              onClick={() => {
                history.push(`/functional_testing_create/staged_testing/${teamId}/${actions.actionAdd}/${currentDate}`);
              }}
            >
              Добавить отчет
            </ButtonDefault>
          )}
        </>
      }
      text="Отчетов пока нет."
    />
  );
};

export default StagedTestingReport;

StagedTestingReport.propTypes = {
  canEdit: PropTypes.bool,
};
