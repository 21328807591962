import {getFullName} from '@common/DevelopmentJournal/helpers';
import {CircularProgress} from '@mui/material';
import MUIAutocomplete, {AutocompleteValue} from '@mui/material/Autocomplete';
import {ChipTypeMap} from '@mui/material/Chip';
import {Box, Chip, TextField, Typography} from '@ui/MUI';
import {CustomAutocompleteProps, OptionType} from '@ui/MUI/Autocomplete/types';
import React, {forwardRef, memo, useMemo} from 'react';
import styled from 'styled-components';

const Autocomplete = forwardRef(function Autocomplete<
  Value extends Multiple extends true ? OptionType[] : OptionType,
  Multiple extends boolean = true,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    id,
    multiple,
    isSelectableAll,
    isLoading,
    options = [],
    disabled = false,
    getOptionLabel = (option) =>
      (option as OptionType).label ||
      (option as OptionType).fullName ||
      (option as OptionType).name ||
      getFullName(option),
    isOptionEqualToValue = (option: Value, value: Value) => {
      const opt = option as OptionType;
      const val = value as OptionType;
      if (opt.id && val.id) {
        return opt.id === val.id;
      }

      return opt.value === val.value;
    },
    textFieldVariant = 'standard',
    value,
    label = '',
    placeholder = 'Поиск...',
    onChange = (_event: React.SyntheticEvent, _value: ReadonlyArray<Value>, _name: string): void => {},
    error = false,
    helperText,
    name,
    limitTags,
    onFocus,
    inputRef,
    chipProps = {},
    wrapperProps = {},
    readOnly,
    ...props
  }: CustomAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  ref: React.Ref<unknown> | undefined,
) {
  const resolvedMultiple = multiple !== undefined ? multiple : true;
  const formattedOptions = useMemo(() => {
    if (isSelectableAll && options.length) {
      if ((value as OptionType[])?.length === options.length) return options;
      return [{value: 'all', label: 'Все'}, ...options];
    }

    return options;
  }, [value, options, isSelectableAll]);

  const handleChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>,
  ) => {
    if (isSelectableAll && Array.isArray(value) && value.some((option) => (option as OptionType).value === 'all')) {
      onChange(event, options, name);
    } else {
      onChange(event, value, name);
    }
  };
  return (
    <Wrapper width={props?.width} {...wrapperProps}>
      <MUIAutocomplete
        filterSelectedOptions
        disabled={disabled}
        getOptionLabel={getOptionLabel}
        id={id}
        isOptionEqualToValue={isOptionEqualToValue}
        limitTags={limitTags}
        multiple={resolvedMultiple}
        options={formattedOptions}
        ref={ref}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: {minHeight: 39},
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            disabled={disabled}
            error={error}
            helperText={helperText}
            inputRef={inputRef}
            label={label}
            placeholder={(value as OptionType[])?.length ? '' : placeholder}
            readOnly={readOnly}
            sx={{
              '.MuiAutocomplete-inputRoot': {
                flexWrap: limitTags < 2 && 'nowrap !important',
              },
              '.Mui-focused.MuiAutocomplete-inputRoot ': {
                flexWrap: 'wrap !important',
              },
              '.MuiAutocomplete-input': {
                minWidth: resolvedMultiple && '0px !important',
                flexGrow: resolvedMultiple && '0 !important',
              },
              '.Mui-focused': {
                '.MuiAutocomplete-input': {
                  minWidth: '40px !important',
                  flexGrow: '1 !important',
                },
              },
              '.MuiInputBase-root': {
                flexWrap: 'nowrap',
              },
              '.MuiInputBase-root.Mui-focused': {
                flexWrap: 'wrap',
              },
            }}
            variant={textFieldVariant}
            onFocus={onFocus}
          />
        )}
        renderTags={(tagValue, getTagProps, {limitTags, focused, value}) => {
          const tagsLeftCount =
            Math.abs(limitTags - (value as OptionType[])?.length) > 99
              ? 99
              : Math.abs(limitTags - (value as OptionType[])?.length);
          const tagsLeft =
            !(value as OptionType[])?.length || (value as OptionType[])?.length <= limitTags ? 0 : tagsLeftCount;
          return (
            <>
              <Box
                sx={{
                  all: 'inherit',
                  padding: '0',
                  margin: '0',
                  maxHeight: 200,
                  overflow: 'hidden auto',
                  width: 'fit-content',
                  flexWrap: 'wrap',
                }}
              >
                {tagValue
                  .slice(0, !focused && limitTags ? limitTags : tagValue?.length)
                  .map((option: Value, index: number) => {
                    return (
                      <Chip
                        key={index}
                        {...chipProps}
                        {...getTagProps({index})}
                        label={(option as OptionType)?.label || (option as OptionType).name}
                        size="small"
                        variant="outlined"
                      />
                    );
                  })}
              </Box>
              {!focused && limitTags && tagsLeft ? <Typography variant="body2">{`+${tagsLeft}`}</Typography> : null}
            </>
          );
        }}
        value={resolvedMultiple ? value || [] : value || null}
        onChange={handleChange}
        {...props}
      />
    </Wrapper>
  );
});
export default memo(Autocomplete);

export const Wrapper = styled(Box)`
  .MuiAutocomplete {
    &-root {
      .MuiAutocomplete {
        &-inputRoot {
          .MuiAutocomplete-input {
            min-width: 40px;
          }
        }
      }
    }
  }
`;
