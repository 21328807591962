import FieldScatterChart from '@components/apps/StatsReportForOneGame/components/FieldScatterChart';
import {DATA_TYPES, ICON_TYPES} from '@components/apps/StatsReportForOneGame/constants';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import {loadGameActionDetails} from '@slices/statistics';
import {parseFormToGetValue} from '@src/helpers';
import {grey, primary} from '@ui/MUI/colors';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {memo, useMemo} from 'react';

const reverseArray = (arr, condition) => (condition ? [...arr] : [...arr].reverse());
const teamColors = [primary.main, grey['68']];
const legendArr = [
  {name: 'наша команда', itemStyle: {color: teamColors[0]}},
  {name: 'соперник', itemStyle: {color: teamColors[1]}},
];

Field.propTypes = {
  weAtHome: PropTypes.bool,
  filtersGetter: PropTypes.func.isRequired,
  mapDataGetter: PropTypes.func.isRequired,
  statisticsIds: PropTypes.arrayOf(PropTypes.string),
  loader: PropTypes.func.isRequired,
  cleaner: PropTypes.func,
};

function Field({
  weAtHome,
  statisticsIds,
  filtersGetter = () => {},
  mapDataGetter = () => {},
  loader = () => {},
  cleaner = () => {},
  setModalState = () => {},
  ...props
}) {
  const dispatch = useStoreDispatch();
  const {actionIds, playerIds, ...filters} = useStore(filtersGetter);
  const {data, isLoading, isLoad, error} = useStore(mapDataGetter);
  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [actionIds, filters?.types]});

  const series = useDeepMemo(() => {
    if (actionIds?.includes(1000700) || actionIds?.includes(1000800))
      return [data.home, data.away].map((lines, index) => {
        return {
          id: index,
          name: legendArr[index].name,
          symbolSize: 10,
          coordinateSystem: 'cartesian2d',
          data: lines?.map((line) => {
            return {
              tooltip: {
                show: true,
              },
              ...line,
              lineStyle: {
                opacity: 1,
                width: 2,
                color: teamColors[line?.isOur ? 0 : 1],
                type: actionIds?.includes(1000800) ? 'dashed' : '',
                dashOffset: 1,
              },
              symbol: ['none', 'arrow'],
              symbolSize: [0, 10],
              coords: [
                reverseArray([line?.posX, line?.posY], !isXsResolution),
                reverseArray([line?.opponentPosX, line?.opponentPosY], !isXsResolution),
              ],
            };
          }),
          type: 'lines',
          symbolRotate: 90,
        };
      });
    return [data.home, data.away].map((scatters, index) => {
      const colorIndex = weAtHome ? index : Math.abs(index - 1);
      return {
        id: index,
        name: legendArr[index],
        symbolSize: 24,
        data:
          (scatters?.length ? scatters : [])?.map((item) => ({
            ...item,
            itemStyle: {
              opacity: 1,
            },
            tooltip: {
              show: true,
            },
            label: {
              show: item?.engNameShort,
              fontSize: 12,
              fontFamily: 'Proxima Nova',
              color: '#fff',
              borderColor: '#fff',
              formatter: () => `${item?.engNameShort}`,
            },
            value: reverseArray([item.posX, item.posY], !isXsResolution),
          })) || [],
        type: 'scatter',
        color: teamColors[colorIndex],
        symbol: (value, {data}) => ICON_TYPES?.[data?.type || DATA_TYPES.miss],
      };
    });
  }, [data, actionIds, isXsResolution, weAtHome]);
  const legendData = useMemo(() => reverseArray(legendArr, weAtHome), [weAtHome]);

  useDeepEffect(() => {
    if (actionIds?.length && statisticsIds?.length) {
      dispatch(
        loader(
          {
            ...parseFormToGetValue({cancelToken: newCancelToken(), ...filters, playerIds}),
            actionIds,
            statisticsIds,
          },
          isCancel,
        ),
      );
    }
    return () => {
      dispatch(cleaner());
    };
  }, [actionIds, playerIds, filters, statisticsIds]);

  return (
    <FieldScatterChart
      error={error}
      isLoad={isLoad}
      isLoading={isLoading || props?.isLoading}
      legend={{data: legendData, icon: 'circle', itemGap: 32}}
      noData={isEmpty(data?.home) && isEmpty(data?.away) && isLoad}
      series={series}
      tooltip={{
        enterable: true,
        show: true,
        triggerOn: 'click',
        backgroundColor: grey['68'],
        extraCssText: 'text-align: left; border: none; overflow: hidden; min-width: 152px; width: fit-content',
        textStyle: {
          color: '#fff',
          fontSize: 12,
          fontFamily: 'Proxima Nova',
        },
        formatter: ({data}) => {
          window.showGoals = function () {
            dispatch(setModalState({isOpen: true, data}));
          };
          data?.id && dispatch(loadGameActionDetails(data?.id));
          return `
            <div style="padding-bottom: ${data?.linkToVideo ? '30px' : ''}">
                Игрок: <span style="font-weight: 600">${data?.playerName}</span> <br>
                Действие: <span style="font-weight: 600">${data?.name}</span> <br>
                ${data?.type?.name ? `Тип:<span style='font-weight: 600'>${data?.type?.name}</span>  <br>` : ''}
                Время: <span style='font-weight: 600'>${data?.time}</span>
                ${
                  data?.linkToVideo
                    ? `<button
                      style="position: absolute;
                               left: 0; bottom: 0;
                               text-align: center;
                               min-width: 100%;
                               display: block;
                               padding: 6px 8px;
                               color: #fff;
                               text-transform: uppercase;
                               background-color:${grey['100']};
                               border: none;
                               font-weight: 600;
                               line-height: 14px;
                               cursor: ${data?.linkToVideo ? 'pointer' : 'not-allowed'};
                               pointer-events: ${data?.linkToVideo ? 'all' : 'none'};"
                      onClick="showGoals()"
                    >
                      смотреть видео
                    </button>`
                    : ''
                }
            </div>
              `;
        },
      }}
      weAtHome={weAtHome}
      yAxis={{max: 28}}
    />
  );
}

export default memo(Field);
