import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormProvider, useForm, useFormState} from 'react-hook-form';

import DrawerDefault from '../../../ui/MUI/Modals/Drawer/Drawer';
import CancelModal from '../CancelModal';
import {StyledButton} from '../CommonScoutReport';
import GroupReportCreateContent from '../GroupReportCreateContent';
import {ButtonsWrapper} from './GroupReportCreateModal';

function GroupReportCreateModal({isOpen, setIsOpen = () => {}, onCreate, group}) {
  const [isCalncelModalVisible, setIsCalncelModalVisible] = useState(false);
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      description: '',
    },
  });
  const {control, handleSubmit, reset} = methods;
  const {isDirty} = useFormState({
    control,
  });

  const handleClose = () => {
    if (isDirty) return setIsCalncelModalVisible(true);
    reset();
    setIsOpen(false);
  };

  return (
    <>
      <DrawerDefault
        buttons={
          <ButtonsWrapper>
            <StyledButton
              color="primary"
              size="medium"
              variant="contained"
              onClick={() =>
                handleSubmit((data) => {
                  setIsOpen(false);
                  onCreate(
                    {
                      ...data,
                      group: group?.id,
                    },
                    reset,
                  );
                })()
              }
            >
              добавить
            </StyledButton>
            <StyledButton color="neutral" size="dark" variant="contained" onClick={handleClose}>
              Отменить
            </StyledButton>
          </ButtonsWrapper>
        }
        content={
          <FormProvider {...methods}>
            <GroupReportCreateContent group={group} />
          </FormProvider>
        }
        drawerState={isOpen}
        hasFullWidth={'false'}
        setDrawerState={setIsOpen}
        onClose={handleClose}
      />
      <CancelModal
        isVisible={isCalncelModalVisible}
        onCancel={() => setIsCalncelModalVisible(false)}
        onClose={() => {
          reset();
          setIsOpen(false);
          setIsCalncelModalVisible(false);
        }}
      />
    </>
  );
}

GroupReportCreateModal.propTypes = {
  isOpen: PropTypes.object,
  setIsOpen: PropTypes.func,
  onCreate: PropTypes.func,
  group: PropTypes.object.isRequired,
};

export default GroupReportCreateModal;
