import TableTemplate from '@common/Statistics/PlayerStatistics/TableTemplate';
import {longDash} from '@src/constants';
import {customTableComparator} from '@src/helpers';
import {Tooltip, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

import ProgressCell from '../../ProgressCell';
import SingleProgressCell from '../../SingleProgressCell';
import TopFiveItem from '../../TopFiveItem';

const columns = [
  {
    accessorKey: 'score',
    header: () => {
      return (
        <Tooltip title="Очки">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            О
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <SingleProgressCell percent={Math.abs(getValue()?.value)} value={getValue()?.value} />;
    },
    cellStyle: {position: 'relative', textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'goals',
    header: () => {
      return (
        <Tooltip title="Голы">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Г
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <TopFiveItem value={getValue()} />;
    },
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'assists',
    header: () => {
      return (
        <Tooltip title="Ассисты">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            А
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <TopFiveItem value={getValue()} />;
    },
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'plusMinus',
    header: () => {
      return (
        <Typography sx={{display: 'inline-block'}} variant="subtitle2">
          +/-
        </Typography>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <ProgressCell allowPositiveSign percent={Math.abs(getValue()?.percent)} value={getValue()?.value} />;
    },
    cellStyle: {position: 'relative', textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'plus',
    header: () => {
      return (
        <Typography sx={{display: 'inline-block'}} variant="subtitle2">
          +
        </Typography>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
  },
  {
    accessorKey: 'minus',
    header: () => {
      return (
        <Typography sx={{display: 'inline-block'}} variant="subtitle2">
          -
        </Typography>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
  },
  {
    accessorKey: 'blockedShots',
    header: () => {
      return (
        <Tooltip title="Блокшоты">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            БлБ
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <TopFiveItem value={getValue()} />;
    },
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
];
function ClassicTab({isLoad, isLoading, data, hasError, hasMatchFilter}) {
  const [sorting, setSorting] = useState([{asc: true, id: 'score'}]);

  const rowsData = useMemo(
    () =>
      data?.map((item) => {
        return {
          student: item?.student,
          score: item?.score,
          goals: item?.goals,
          assists: item?.assists,
          plusMinus: item?.plusMinus,
          plus: item?.plus || item?.plus === 0 ? item?.plus : longDash,
          minus: item?.minus || item?.minus === 0 ? item?.minus : longDash,
          blockedShots: item?.blockedShots,
        };
      }),
    [data],
  );

  return (
    <TableTemplate
      columns={columns}
      hasError={hasError}
      hasMatchFilter={hasMatchFilter}
      isLoad={isLoad}
      isLoading={isLoading}
      rowsData={rowsData}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
}

ClassicTab.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ClassicTab;
