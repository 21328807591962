import FullHeightWrapper from '@common/FullHeightWrapper';
import {PageWrapper} from '@common/Page';
import {useGroup} from '@hooks/filters';
import {selectFilters} from '@selectors/skill';
import {Typography} from '@ui/MUI';
import TabsNew from '@ui/MUI/TabsNew';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import Categories from '../Categories';

const useTabs = () => {
  const school = useSelector(selectFilters({entityName: 'categoriesEstimates', name: 'school'}));
  const {options} = useGroup({connectorValue: school?.value});
  const tabs = useMemo(() => {
    if (options?.length) {
      return [
        {label: 'Все', value: <Categories />},
        ...options.map((option) => ({label: option.label, value: <Categories groupId={option.value} />})),
      ];
    }
    return [];
  }, [options]);

  return tabs;
};

const Tabs = () => {
  const tabs = useTabs();

  if (!tabs.length) {
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
        <Typography variant="subtitle1">Заполните фильтры, чтобы увидеть данные</Typography>
      </FullHeightWrapper>
    );
  }

  return (
    <PageWrapper px={0} py={0}>
      <TabsNew withoutWrapper labelColor="#2C2C2C" tabs={tabs} variant="scrollable" />
    </PageWrapper>
  );
};

export default Tabs;
