import {getMKCTournamentById} from '@api/mkcStatisticsAPI';
import {getTournamentById} from '@api/TournamentsAPI';
import useDeepEffect from '@hooks/useDeepEffect';
import {useCurrentUserRole} from '@hooks/user';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import {DrawerContentContainer, DrawerInner} from '@ui/MUI/Modals/Drawer/styles';
import React, {useEffect, useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components';

import {ROOMS, userRoles} from '../../../../constants';
import {NOTIFICATION_TYPES} from '../../../../constants/config';
import StateHelper from '../../../../helpers/StateHelper';
import LocalPreloader from '../../../preloader/LocalPreloader';
import {ReactComponentNotification} from '../../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {parseLessonForEdit} from '../../helper';
import LessonForm from './components/LessonForm';
import LessonView from './components/LessonView';
import {Form} from './style';

const Sidebar = (props) => {
  const {
    sidebarVisible,
    setSidebarVisible,
    onFormSubmit = () => {},
    updateLesson = () => {},
    allAmplua,
    lockerRooms,
    schools,
    isAdd,
    selectedLesson,
    defaultValue,
    handleDeleteLessonFrom,
  } = props;

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const userRole = useCurrentUserRole();

  const [fullLessonInformation, setFullLessonInformation] = useState({
    groups: [],
    players: [],
  });
  const [lessonTournament, setLessonTournament] = useState();
  useDeepEffect(() => {
    if (selectedLesson?.tournamentId) {
      getTournamentById(selectedLesson?.tournamentId).then((res) => {
        setLessonTournament(res.data);
      });
    }
    if (selectedLesson?.mkcTournamentId) {
      getMKCTournamentById(selectedLesson?.mkcTournamentId).then((res) => {
        setLessonTournament(res.data);
      });
    }
  }, [selectedLesson]);

  useEffect(() => {
    setIsEdit(false);
    if (selectedLesson) {
      setLoading(true);
      if (selectedLesson.groupId) {
        const requests =
          userRole !== userRoles?.student
            ? [
                StateHelper.getGroupsBySchoolId(selectedLesson.schoolId),
                StateHelper.getStudentsByTeamId(selectedLesson.groupId),
              ]
            : [StateHelper.getGroupsBySchoolId(selectedLesson.schoolId)];

        Promise.all(requests)
          .then((res) => {
            const fullLessonInformation =
              userRole !== userRoles?.student
                ? {
                    groups: res[0],
                    players: res[1].map((item) => {
                      return {
                        id: item.id,
                        ...item.student,
                      };
                    }),
                  }
                : {
                    groups: res[0],
                  };
            setFullLessonInformation(fullLessonInformation);
            setLoading(false);
          })
          .catch((error) => {
            ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка сервера: ${error}, попробуйте позже`);
            setLoading(false);
          });
      } else {
        setFullLessonInformation({
          groups: [],
          players: selectedLesson.students,
        });
        setLoading(false);
      }
    }
  }, [selectedLesson?.id, lessonTournament?.id]);
  const SidebarContent = () => {
    return isAdd ? (
      <>
        <LocalPreloader visible={loading} />
        <ModalTitle>Добавить мероприятие</ModalTitle>
        <LessonForm
          isAdd
          allAmplua={allAmplua}
          defaultValues={defaultValue}
          lockerRooms={lockerRooms}
          rooms={ROOMS}
          schools={schools}
          setLoading={setLoading}
          onCloseButtonClick={() => {
            setSidebarVisible({...sidebarVisible, right: false});
          }}
          onFormSubmit={onFormSubmit}
        />
      </>
    ) : isEdit ? (
      <>
        <LocalPreloader visible={loading} />
        <ModalTitle>Редактирование мероприятия</ModalTitle>
        <LessonForm
          allAmplua={allAmplua}
          defaultGroups={fullLessonInformation.groups}
          defaultPlayers={fullLessonInformation.players}
          defaultValues={parseLessonForEdit(
            selectedLesson,
            lessonTournament,
            schools,
            fullLessonInformation.groups,
            selectedLesson.students,
          )}
          lockerRooms={lockerRooms}
          rooms={ROOMS}
          schools={schools}
          setLoading={setLoading}
          onCloseButtonClick={() => {
            handleDeleteLessonFrom(selectedLesson.id);
          }}
          onFormSubmit={updateLesson}
        />
      </>
    ) : (
      <>
        <LocalPreloader visible={loading} />
        <ModalTitle>Просмотр мероприятия</ModalTitle>
        <LessonView
          lessonTournament={lessonTournament}
          selectedLesson={selectedLesson}
          onCLoseButtonClick={() => {
            setIsEdit(false);
            setSidebarVisible({...sidebarVisible, right: false});
          }}
          onEditButtonClick={() => {
            setIsEdit(true);
          }}
        />
      </>
    );
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <>
        <GlobalStyles />
        <DrawerDefault
          content={<SidebarContent />}
          drawerState={sidebarVisible}
          isButtonsShowed={false}
          setDrawerState={setSidebarVisible}
        />
      </>
    </Form>
  );
};

const ModalTitle = styled.span`
  ${({theme}) => theme.typography.h3}
  text-transform: uppercase;
  text-transform: uppercase;
  text-transform: uppercase;
`;
const GlobalStyles = createGlobalStyle`
  .MuiDrawer {
    &-root{
      z-index: 9998;
    }
    &-paper {
      max-width: 60% !important; 
      ${({theme}) => theme.breakpoints.down('sm')} {
        max-width: 100% !important;
      }
      ${DrawerInner} {
        height: 100%;
        ${DrawerContentContainer} {
          padding-bottom: 140px;
          ${({theme}) => theme.breakpoints.up('sm')} {
            padding-bottom: 80px;
          }
          ${({theme}) => theme.breakpoints.down('mobileM')} {
            padding-bottom: 500px;
          }
        }
      }
      .BottomPanel {
        position: fixed;
        margin: 0 -32px;
        z-index: 9999;
        ${({theme}) => theme.breakpoints.up('sm')} {
          max-width: 60%; 
        }
        .Buttons {
          display: flex;
          justify-content: space-between;
          width: auto;
          & > button {
            ${({theme}) => theme.breakpoints.up('sm')} {
              margin: ${({theme}) => theme.spacing('0', 2)};  
            }
            height: 40px;
            width: 100%;
            max-width: 160px;
          }  
        }
      }
    }
  } 
`;

export default Sidebar;
