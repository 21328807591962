import {playerStatusesChartConfig} from '@components/apps/PlayerPersonalFile/constants';
import date from '@helpers/date';
import useDeepMemo from '@hooks/useDeepMemo';
import {uniqBy} from 'lodash';

export function useDynamicsGraphMarkPoints(responseData, values) {
  return useDeepMemo(() => {
    const points = [];
    values?.forEach((item, index, array) => {
      const responseDataItems = uniqBy(
        responseData?.filter((responseItem) => {
          return (
            date(item?.date)
              .startOf('day')
              .add(1, 'hour')
              .isBetween(
                date(responseItem?.date).startOf('day'),
                date(responseItem?.recoveryDate || date().currentDate)
                  .startOf('day')
                  .add(2, 'hour'),
              ) && item?.season === responseItem?.season
          );
        }),
        'type',
      );
      responseDataItems?.forEach((responseDataItem, responseDataItemIndex) => {
        if (responseDataItem?.type) {
          const configElement = playerStatusesChartConfig?.[responseDataItem?.type];
          points.push({
            coord: [index, 1],
            symbolOffset: [(index === array?.length - 1 ? -30 : 30) * (responseDataItemIndex + 1), 11],
            label: {
              show: true,
              formatter: () => configElement?.short,
              color: '#fff',
              fontSize: 12,
              verticalAlign: 'middle',
            },
            itemStyle: {
              color: configElement?.color,
            },
            tooltip: {
              show: true,
              formatter: () =>
                `${configElement?.full} </br> Дата: ${date(item?.date).format('DD.MM.YYYY')}  </br> Сезон: ${
                  item?.season
                }`,
            },
          });
        }
      });
    });
    return points;
  }, [responseData, values]);
}
