import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

const DeleteModal = ({title, modal, onDelete, elementName, isLoading}) => {
  return (
    <DialogDefault
      actionText="Удалить"
      closeText="Отменить"
      content={
        <>
          <TypographyDefault>Вы уверены что хотите удалить {elementName}?</TypographyDefault>
          <TypographyDefault>Все данные по нему будут потеряны.</TypographyDefault>
        </>
      }
      isLoading={isLoading}
      open={modal.isToggle}
      title={title}
      onActionButtonClick={onDelete}
      onCloseDefault={modal.off}
    />
  );
};

export default DeleteModal;
