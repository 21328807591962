import {Typography} from '@ui/MUI';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

ArchiveModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isPlayerActive: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

function ArchiveModal({isOpen, setIsOpen, isPlayerActive, handleSubmit}) {
  const actionText = useMemo(() => (isPlayerActive ? 'Архивировать' : 'Разархивировать'), [isPlayerActive]);
  return (
    <DialogDefault
      actionText={actionText}
      closeText="Отмена"
      content={<Typography variant="body1">{`Вы уверены, что хотите ${actionText.toLowerCase()} игрока?`}</Typography>}
      open={isOpen}
      title="Архивация игрока"
      onActionButtonClick={handleSubmit}
      onCloseDefault={setIsOpen}
    />
  );
}

export default ArchiveModal;
