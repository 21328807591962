import {grey} from '@ui/MUI/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  @media screen and (min-width: 1025px) {
    padding-right: 8px;
  }

  .TableEmptyBlock {
    border-radius: 0 0 6px 6px;
  }

  .ColumnRadar {
    position: relative;
    background: #fff;
    min-height: 535px;
    padding: 20px 0 0 0;
    @media screen and (max-width: 480px) {
      padding: 0;
      min-height: 380px;
    }
    .ColumnRadarIntermediateIndicators {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      max-height: 300px;
      width: 100%;
      max-width: 350px;
      z-index: 4;
      @media screen and (max-width: 575px) {
        display: none;
      }
      .ColumnRadarIntermediateIndicator {
        position: absolute;
        margin-top: 0;
        margin-bottom: 0;
        font: normal normal 13px/16px 'Proxima Nova';
        color: ${grey[54]};
        letter-spacing: -0.02em;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        &:first-child {
          top: -32px;
          right: 20px;
          transform: rotate(23deg);
        }
        &:nth-child(2) {
          top: 28%;
          right: -65px;
          transform: translateY(-90%) rotate(71deg);
        }
        &:nth-child(3) {
          right: -40px;
          bottom: 22%;
          transform: translateY(-50%) rotate(-70deg);
        }
        &:nth-child(4) {
          bottom: -35px;
          right: 25px;
          transform: rotate(-31deg);
        }
        &:nth-child(5) {
          bottom: -35px;
          left: 27px;
          transform: rotate(26deg);
        }
        &:nth-child(6) {
          top: 70%;
          left: -10px;
          transform: translateX(-50%) rotate(68deg);
        }
        &:nth-child(7) {
          top: 25%;
          left: -18px;
          transform: translateX(-50%) rotate(-68deg);
        }
        &:last-child {
          top: -11%;
          left: 10%;
          transform: translateX(-8%) rotate(-28deg);
        }
      }
    }
    .RadarChart {
      position: relative;
      display: flex;
      justify-content: center;
      min-height: 500px;
      background: transparent !important;
      z-index: 3;
      @media screen and (max-width: 1024px) {
        max-height: 535px;
      }
      canvas {
        position: absolute;
        top: 10px !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
      &_empty {
        position: absolute;
        top: 36px;
        transform: rotate(-20deg);
        background: transparent !important;
        z-index: 2;
      }
    }
  }

  .PhysicalTestingFilter {
    margin-bottom: 5px;
    border-radius: 6px;
  }
  .AddComment {
    max-width: 65%;
    @media (min-width: 576px) and (max-width: 1199px) {
      max-width: 75%;
    }
    @media screen and (max-width: 575px) {
      max-width: none;
    }
    .sidebar__inner {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
`;
export const TableContent = styled.div`
  border-radius: 0 0 6px 6px;
  overflow: hidden;
`;
export const ColumnName = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 12px/25px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
`;
export const ColumnNameMobile = styled(ColumnName)`
  @media screen and (min-width: 769px) {
    display: none;
  }
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
`;
export const EstimationValueContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 38px;
`;
export const EstimationValue = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  width: 36px;
  height: 36px;
  background: #f5dcb2;
  border-radius: 2px;
  font: bold normal 12px/36px 'Proxima Nova';
  color: #2c2c2c;
  text-align: center;
`;
export const EstimationDesc = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  max-width: 410px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: -0.02em;
  text-align: left;
  @media screen and (max-width: 768px) {
    max-width: none;
    text-align: center;
  }
`;
export const Half = styled.div`
  box-sizing: border-box;
  flex: 0 0 50%;
  max-width: 50%;
  &:nth-child(odd) {
    border-right: 1px solid #dddad4;
  }
`;
export const TableHeadline = styled.div`
  display: flex;
  height: 25px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 9px 10px -7px;

  @media screen and (max-width: 768px) {
    display: none;
  }

  ${Half} {
    box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
    &:last-child {
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    &:first-child {
      @media screen and (max-width: 991px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &:nth-child(odd) {
      border-right: 0;
    }
  }
`;
export const SkillName = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 14px/1 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: -0.02em;
`;
export const RecommendationLabel = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 14px/1 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: -0.02em;
  padding-bottom: 15px;
`;
export const Info = styled.div`
  padding-left: 60px;
`;
export const TrainerComment = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 14px/15px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: -0.02em;
`;
export const ViewMore = styled.button`
  padding: 8px 0;
  background-color: transparent;
  border: none;
  font: bold normal 14px/25px 'Proxima Nova';
  color: #990011;
  transition: text-decoration 0.33s;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    line-height: 1;
    text-align: left;
  }

  :focus {
    text-decoration: none;
    outline: none;
  }
  :hover {
    text-decoration: underline;
  }
`;
export const TableColumn = styled.div`
  padding-right: 12px;
  padding-left: 12px;
  box-sizing: border-box;
  text-align: center;
  flex: 0 0 30%;
  max-width: 30%;

  @media (min-width: 769px) and (max-width: 991px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }

  :nth-child(2n) {
    flex: 0 0 70%;
    max-width: 70%;
    text-align: left;

    @media (min-width: 769px) and (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media screen and (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    ${ColumnNameMobile} {
      @media screen and (max-width: 768px) {
        text-align: center;
      }
      + ${SkillName} {
        @media screen and (max-width: 768px) {
          text-align: center;
        }
      }
    }
  }
`;

export const TableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  &:last-child {
    padding-bottom: 8px;
  }
  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;
export const TableRowComment = styled(TableRow)`
  justify-content: flex-end;
  @media screen and (max-width: 991px) {
    justify-content: center;
  }

  ${TableColumn} {
    flex: 0 0 70%;
    max-width: 70%;
    text-align: left;

    @media (min-width: 769px) and (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media screen and (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
    }
  }
`;
export const TableHeadComment = styled(TableRow)`
  background: #fff;
  padding: 30px 0px;

  ${TableColumn} {
    flex: 0 0 70%;
    max-width: 70%;
    text-align: left;

    @media (min-width: 769px) and (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media screen and (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
    }
  }
`;
export const TableRowDesc = styled(TableRow)`
  justify-content: flex-end;
  @media screen and (max-width: 991px) {
    justify-content: center;
  }
  ${TableColumn} {
    flex: 0 0 70%;
    max-width: 70%;
    @media (min-width: 769px) and (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media screen and (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;
export const IndividualPlanCard = styled.div`
  height: 100%;
  ${TableRow} {
    padding-top: 8px;
    padding-bottom: 8px;
    background: #f3f3f350;
    align-items: center;
    &:nth-child(even) {
      background-color: white;
    }
    &:last-child {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    @media screen and (max-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  ${TableRowComment} {
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
  }
`;
export const TableBody = styled.div`
  width: 100%;
  background-color: white;
  ${TableRow} {
    ${Half} {
      :nth-child(even) {
        ${IndividualPlanCard} {
          ${TableRow} {
            @media screen and (max-width: 991px) {
              background: white;
            }
          }
        }
      }
      :nth-child(odd) {
        ${IndividualPlanCard} {
          ${TableRow} {
            + ${TableRowComment} {
              @media screen and (max-width: 991px) {
                background: #f3f3f350;
              }
            }
          }
        }
      }
      @media screen and (max-width: 991px) {
        flex: 0 0 100%;
        max-width: 100%;
        border-right: 0;
        border-left: 0;
      }
    }
  }
`;
export const Comment = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  word-break: break-word;
`;
export const EventsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-right: -20px;
  margin-left: -20px;
  @media (min-width: 577px) and (max-width: 767px) {
    margin-right: -10px;
    margin-left: -10px;
  }
  @media screen and (max-width: 576px) {
    margin-right: 0;
    margin-left: 0;
  }
`;
export const Event = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;
export const EventDateContainer = styled.div``;
export const EventDate = styled.p`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  margin: 0 5px 0 0;
  max-width: 65px;
  background: #fffaed;
  border-radius: 6px;
  font: bold normal 16px/18px 'Proxima Nova';
  color: #990011;
  text-align: center;

  span {
    font-size: 12px;
    text-transform: uppercase;
  }
`;
export const EventInfo = styled.div`
  margin-right: 0;
  margin-left: 5px;
  max-width: 160px;
`;
export const EventName = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
`;
export const EventDesc = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 12px/16px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
`;
export const EventContainer = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: 25px;
  flex: 0 0 33.333%;
  max-width: 33.333%;
  box-sizing: border-box;

  @media screen and (max-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 767px) {
    padding-right: 10px;
    padding-left: 10px;
  }
  @media screen and (max-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  :first-of-type {
    ${EventDate} {
      background-color: #990011;
      color: white;
    }
  }
`;
