import React from 'react';

function Spaces() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M11.4503 0.5H3.55949C2.42226 0.50051 1.50039 1.41303 1.5 2.53886V10.4991C1.50039 11.6249 2.42226 12.5374 3.55949 12.538H11.4503C12.5876 12.5374 13.5093 11.6249 13.5098 10.4991V2.53886C13.5093 1.41303 12.5876 0.50051 11.4503 0.5ZM12.686 10.4991C12.6841 11.1738 12.1319 11.7205 11.4503 11.7224H3.55949C2.87792 11.7205 2.32572 11.1738 2.32379 10.4991V2.53886C2.32572 1.86412 2.87792 1.31745 3.55949 1.31554H11.4503C12.1319 1.31745 12.6841 1.86412 12.686 2.53886V10.4991Z"
          fill="#626262"
        />
        <path
          d="M14.4424 3.46205H6.55151C5.41428 3.46256 4.49241 4.37508 4.49202 5.50091V13.4611C4.49241 14.587 5.41428 15.4995 6.55151 15.5H14.4424C15.5796 15.4995 16.5013 14.587 16.5018 13.4611V5.50091C16.5013 4.37508 15.5796 3.46256 14.4424 3.46205ZM15.678 13.4611C15.6761 14.1359 15.1239 14.6825 14.4424 14.6845H6.55151C5.86995 14.6825 5.31775 14.1359 5.31582 13.4611V5.50091C5.31775 4.82617 5.86995 4.2795 6.55151 4.27759H14.4424C15.1239 4.2795 15.6761 4.82617 15.678 5.50091V13.4611Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default Spaces;
