import EstimateItemWithLabelVertical from '@common/DevelopmentJournal/components/EstimateItemWithLabelVertical';
import moment from 'moment';
import React from 'react';

const Time = ({controlDate, createdAt}) => {
  const controlDateFormatted = moment(controlDate).format('DD.MM.YYYY');
  const createdAtFormatted = moment(createdAt).format('DD.MM.YYYY');
  const date = `${createdAtFormatted} - ${controlDateFormatted}`;
  return (
    <EstimateItemWithLabelVertical label="Сроки развития навыка" spacing="5px">
      {date}
    </EstimateItemWithLabelVertical>
  );
};

export default Time;
