import Field from '@common/FormField';
import ReactTableComponent from '@common/ReactTableComponent';
import {
  StyledButton,
  TableBottom,
  WrapperTableContainer,
} from '@components/apps/PhysicalTesting/components/CreateEdit/components/EditCreateTable/EditCreateTable';
import {ofpTestsExercisesOrdersMatrix, sfpTestsExercisesOrdersMatrix} from '@components/apps/PhysicalTesting/constants';
import {sortColumn} from '@components/apps/PhysicalTesting/helpers';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useElementSize from '@hooks/useElementSize';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {editCreateOverallData} from '@slices/PhysicalTestingEditCreate';
import {Stack} from '@ui/MUI';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

const TableEditCreate = ({allTests, filters, tableData, isLoading}) => {
  const dispatch = useStoreDispatch();
  const methods = useForm();
  const {handleSubmit, reset} = methods;
  const [sorting, setSorting] = useState([{id: 'player', asc: true}]);
  const [buttomBlockRef, {height}] = useElementSize();
  const additionTests = allTests?.filter((test) => test?.additionParam).map((test) => test.additionParam);
  const sortedTests = [...allTests, ...additionTests]
    ?.map((test) => ({
      ...test,
      order: ofpTestsExercisesOrdersMatrix[test?.id] || sfpTestsExercisesOrdersMatrix[test?.id],
    }))
    ?.sort((a, b) => a.order - b.order);

  useDeepEffect(() => {
    reset({form: tableData});
  }, [tableData, reset]);

  const columns = useDeepMemo(
    () => [
      {
        header: 'Игрок',
        id: 'player',
        accessorKey: 'lastName',
        enableRowSpan: true,
        sticky: {left: true},
        columns: [
          {
            header: 'Not visible header',
            accessorFn: ({params, ...data}) => {
              return data;
            },
            headerStyle: {display: 'none !important'},
            cellStyle: {transition: '.3s ease-in-out'},
            sticky: {left: true},
            cell: (params) => {
              const student = params?.getValue();
              return (
                <Stack
                  alignItems="center"
                  direction="row"
                  onClick={() => (student?.playerId ? history.push(`/student/${student?.playerId}`) : void 0)}
                >
                  <PlayersHorizontalCard isLoading={isLoading} student={student} />
                </Stack>
              );
            },
          },
        ],
        rowSpan: '2',
        sortingFn: sortColumn,
      },
      ...sortedTests.map((exercise) => ({
        header: exercise.name,
        headerStyle: {textAlign: 'center'},
        columns: [
          {
            id: `value.${exercise?.id}`,
            value: 'value',
            label: 'Результат',
            editable: true,
          },
        ]?.map(({label, value, editable}) => {
          return {
            accessorFn: ({params}) => {
              return params?.[exercise.id]?.[value] || params?.[exercise?.additionParam?.id]?.[value] || 0;
            },
            id: `${value}.${exercise.id}`,
            header: label,
            cell: ({getValue, row}) => {
              const name = `form.${row.id}.params.${exercise.id}.value`;
              const cellValue = getValue();
              return editable && !isLoading ? (
                <Field
                  data-testid={value + '-value-data'}
                  hasHandleWheel={true}
                  helperText=""
                  inputType="number"
                  name={name}
                  type="input"
                />
              ) : (
                cellValue
              );
            },
            cellStyle: {minWidth: 120},
          };
        }),
      })),
    ],
    [sortedTests, tableData],
  );
  const submitForm = ({form}) => {
    const allTestsIds = allTests?.map((test) => test.id);
    const additionTestsIds = additionTests?.map((test) => test.id);
    const formattedForm = form
      ?.map((player) => {
        const params = [...allTestsIds, ...additionTestsIds]?.reduce((res, currentId) => {
          if (player.params[currentId]?.value) {
            const resParams = {
              value: Number(player.params[currentId]?.value),
              stage: Number(player.params[currentId]?.stage || filters?.stage?.value),
              playerId: player.playerId,
              groupId: player.params[currentId]?.groupId || filters?.group?.value,
              exercise: currentId,
              season: player.params[currentId]?.season || filters?.season?.value,
            };
            if (!player.params[currentId]?.id) {
              res[currentId] = {
                ...resParams,
              };
            } else {
              res[currentId] = {
                id: player.params[currentId]?.id,
                ...resParams,
              };
            }
          }
          return Object.values(res);
        }, {});
        return {params: params};
      })
      ?.filter((player) => {
        if (player.params.length) {
          return player.params;
        }
      })
      ?.map((item) => item.params)
      .flat();
    const formattedData = {
      season: formattedForm?.[0]?.season,
      groupId: formattedForm?.[0]?.groupId,
      stage: Number(formattedForm?.[0]?.stage),
      type: filters?.typeOfTests?.value,
      data: formattedForm,
    };
    if (formattedData?.season && formattedData?.groupId && formattedData?.stage && formattedData?.type) {
      dispatch(editCreateOverallData(formattedData));
    }
  };

  return (
    <FormProvider {...methods} data-testid="table">
      <form onSubmit={handleSubmit(submitForm)}>
        <WrapperTableContainer canEdit={false}>
          <ReactTableComponent
            isAvailableHeight
            bottomBlockHeight={height - 55}
            columns={columns}
            data={tableData}
            setSorting={setSorting}
            sorting={sorting}
            tableBodyCellProps={{
              sx: {
                py: {xxs: 2, lg: 3},
                px: {xxs: 2, lg: 4},
              },
            }}
            tableHeaderCellProps={{
              sx: {
                py: {xxs: '0', lg: 3},
                px: {xxs: 2, lg: 4},
              },
            }}
          />
        </WrapperTableContainer>
        <TableBottom ref={buttomBlockRef}>
          <StyledButton data-testid="save-button" type="submit">
            Сохранить
          </StyledButton>
        </TableBottom>
      </form>
    </FormProvider>
  );
};

export default TableEditCreate;
