import {loadInjuredPlayers} from '@slices/managerDashboard';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import PlayerStatusTable from '../PlayerStatusTable';

function InjuredPlayers() {
  const dispatch = useDispatch();
  const {data, isLoad, isLoading, error} = useSelector((state) => state.managerDashboard.injuredPlayers);
  const [isAll, setIsAll] = useState(false);
  useEffect(() => {
    dispatch(loadInjuredPlayers({isAll}));
  }, [isAll]);

  return (
    <PlayerStatusTable
      lastTableHeadline="Статус травмы"
      noData="травмированных игроков"
      title="травмированные игроки"
      {...{data, isLoad, isLoading, error, isAll, setIsAll}}
    />
  );
}
export default InjuredPlayers;
