import {useEstimateMutation, useModal} from '@hooks/skill';
import {Button, Stack} from '@ui/MUI';
import LoadingButton from '@ui/MUI/LoadingButton';
import React from 'react';
import {useFormContext} from 'react-hook-form';

const Buttons = () => {
  const {update} = useEstimateMutation();
  const {isLoading, close} = useModal('complete');
  const form = useFormContext();
  const {isDirty, isValid} = form.formState;
  const isDisabled = !isDirty || !isValid;

  return (
    <Stack direction="row" justifyContent="space-between">
      <LoadingButton
        color="primary"
        disabled={isDisabled}
        loading={isLoading}
        variant="contained"
        onClick={form.handleSubmit(update)}
      >
        завершить навык
      </LoadingButton>
      <Button variant="contained" onClick={close}>
        Отменить
      </Button>
    </Stack>
  );
};

export default Buttons;
