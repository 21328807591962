import TableComponent from '@components/apps/GameStatisticsParsingCheck/components/TableComponent';
import {mock} from '@components/apps/GameStatisticsParsingCheck/components/Throwins/mock';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useDeepMemo from '@hooks/useDeepMemo';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {selectGameStatisticsParsingCheckTabs} from '@selectors/gameStatisticsParsingCheck';
import {setCurrentThrowinData} from '@slices/gameStatisticsParsingCheck';
import {longDash} from '@src/constants';
import {noDataNumberValue} from '@src/helpers';
import {IconButton} from '@ui/MUI';
import Box from '@ui/MUI/Box';
import {grey} from '@ui/MUI/colors';
import DrawerNew from '@ui/MUI/DrawerNew';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

Throwins.propTypes = {
  tab: PropTypes.string.isRequired,
};
const DETAILED_INFO_TITLES = [
  {label: 'Тип', value: 'type'},
  {label: 'Время', value: 'time'},
  {label: 'Видеотайм', value: 'videoTime'},
  {label: 'Зона', value: 'zone'},
];
const FACEOFF_TYPES = {
  win: 'Выигранное',
  lost: 'Проигранное',
};

function Throwins({tab}) {
  const dispatch = useDispatch();

  const {data, selected, isLoading, isLoad, hasError} = useSelector(
    (state) => selectGameStatisticsParsingCheckTabs(state, tab),
    shallowEqual,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = useMemo(
    () => [
      {
        id: 'player',
        accessorKey: 'player',
        header: 'Игрок',
        sticky: {left: true},
        cell: (params) => {
          const student = params?.getValue();
          return (
            <PlayersHorizontalCard
              hasImage={false}
              isLoading={isLoading}
              preloaderWidthBetween={[100, 170]}
              student={{...student, playerNumber: student?.number}}
            />
          );
        },
      },
      {
        id: 'win',
        accessorKey: 'win',
        header: 'Кол-во выиграных вбрасываний',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue()}
          </Typography>
        ),
      },
      {
        id: 'lose',
        accessorKey: 'lose',
        header: 'Кол-во проигранных вбрасываний',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue()}
          </Typography>
        ),
      },
      {
        id: 'total',
        accessorKey: 'total',
        header: 'Общее кол-во вбрасываний',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue()}
          </Typography>
        ),
      },
      {
        id: 'action',
        accessorKey: 'action',
        header: '',
        cellStyle: {py: 0},
        cell: ({getValue}) => {
          const value = getValue();
          return (
            <IconButton
              disabled={isLoading}
              onClick={() => {
                dispatch(setCurrentThrowinData(value));
                setIsModalOpen(true);
              }}
            >
              <InfoOutlinedIcon color={value?.faceoffsCount <= 2 ? 'error' : grey[54]} />
            </IconButton>
          );
        },
      },
    ],
    [isLoading],
  );
  const rows = useDeepMemo(
    () =>
      (isLoading ? mock : data).map((item, id) => ({
        id,
        player: {
          firstName: item?.playerFirstName,
          lastName: item?.playerLastName,
          number: item?.playerNumber,
        },
        win: noDataNumberValue(item?.faceoffsWonCount),
        lose: noDataNumberValue(item?.faceoffsLoseCount),
        total: noDataNumberValue(item?.faceoffsCount),
        action: item,
      })),
    [isLoading, data],
  );
  return (
    <>
      <TableComponent
        columns={columns}
        data={rows}
        hasError={hasError || !data?.length}
        isLoad={isLoad}
        isLoading={isLoading}
        tab={tab}
      />
      <DrawerNew open={isModalOpen} onClose={setIsModalOpen}>
        <Box>
          <Box mb={5}>
            <Typography color="#97896B" component="span" variant="h3">
              {selected?.playerNumber + ' '}
            </Typography>
            <Typography component="span" variant="h3">
              {`${selected?.playerLastName} ${
                selected?.playerFirstName?.[0] ? selected?.playerFirstName?.[0] + '.' : ''
              }`}
            </Typography>
          </Box>

          <Typography mb={3} variant="subtitle1">{`Общее количество вбрасываний: ${noDataNumberValue(
            selected?.faceoffsCount,
          )}`}</Typography>
          <Typography mb={2} variant="body2">{`${longDash} Выигранные вбрасывания: ${noDataNumberValue(
            selected?.faceoffsWonCount,
          )}`}</Typography>
          <Typography mb={4} variant="body2">{`${longDash} Проигранные вбрасывания: ${noDataNumberValue(
            selected?.faceoffsLoseCount,
          )}`}</Typography>
          {selected?.detailedInfo?.map((item, index) => (
            <Stack direction="row" flexWrap="wrap" key={index} mb={3} spacing={{xxs: 2, sm: 7}}>
              {DETAILED_INFO_TITLES?.map((elem, ind) => {
                if (ind === 0) {
                  return (
                    <Box flex={1} key={ind} mr={{xxs: 2, sm: 7}}>
                      <Stack direction="row" mb={0}>
                        <SubdirectoryArrowRightIcon fontSize="10px" sx={{color: grey[38]}} />
                        <Typography variant="subtitle2">{elem.label}</Typography>
                      </Stack>
                      <Typography variant="body2">{FACEOFF_TYPES?.[item?.[elem.value]] ?? longDash}</Typography>
                    </Box>
                  );
                }
                return (
                  <Box flex={1} key={ind}>
                    <Typography mb={0} variant="subtitle2">
                      {elem.label}
                    </Typography>
                    <Typography variant="body2">{item?.[elem.value]}</Typography>
                  </Box>
                );
              })}
            </Stack>
          ))}
        </Box>
      </DrawerNew>
      ;
    </>
  );
}

export default Throwins;
