import {roleOptions, rolesWithSubrolesMap, STUDENT_SUBROLES, userRoles} from '@src/constants';
import {FACELESS_AVATAR, NOTIFICATION_TYPES, TEACHERS_ROLES_OPTIONS} from '@src/constants/config';
import {convertImgTo200} from '@src/helpers';
import {Button} from '@ui/MUI';
import DatePickerDefault from '@ui/MUI/DatePicker';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import moment from 'moment';
import React, {useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import InputMask from 'react-input-mask';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import useDebouncedCallback from 'use-debounce/lib/callback';

import SchoolsApi from '../../../../api/SchoolsAPI';
import UsersAPI from '../../../../api/UsersAPI';
import CommonHelper from '../../../../helpers/CommonHelper';
import StateHelper from '../../../../helpers/StateHelper';
import {HANDS_OPTIONS, HOCKEY_ROLES_OPTIONS} from '../../../students/constants';
import Icon from '../../../ui/Icon/Icon';
import InputDefault from '../../../ui/Input';
import ReactComponentModal from '../../../ui/ReactComponent/ReactComponentModal/ReactComponentModal';
import Select from '../../../ui/Select';
import TableDefault from '../../../ui/TableDefault';
import ModalCropUploadPanel from '../../../upload/ModalCropUploadPanel';

const mailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const UserForm = (props) => {
  const {isUserAdmin, user, isCreate, getUser, setIsEdit, setIsLoading, isFromTable} = props;

  const history = useHistory();

  const [schools, setSchools] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isCrop, setIsCrop] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [childrens, setChildrens] = useState([]);

  const {control, watch, handleSubmit, setValue, reset} = useForm();
  const role = watch('role')?.value;
  const subRole = watch('subRole')?.value;
  const subRolesOptions = useMemo(
    () =>
      Object.entries(rolesWithSubrolesMap?.[role]?.subRole || {})?.map(([value, label]) => ({
        value,
        label,
      })),
    [role],
  );
  const formatedForForm = useMemo(() => {
    if (user) {
      const userSchool = user.schools
        ? user.schools.map((school) => ({label: school.name, value: school.id}))
        : user.school
          ? [{label: user?.school?.name, value: user?.school?.id}]
          : [];
      const userGroup = user.groups
        ? user.groups.map((group) => ({label: group.name, value: group.id}))
        : user.group
          ? [{label: user?.group?.name, value: user?.group?.id}]
          : [];

      return {
        ...user,
        role: roleOptions.find((role) => role.value === user?.userRole) || null,
        school: userSchool,
        group: userGroup,
        amplua: HOCKEY_ROLES_OPTIONS.find((role) => role.value === user?.hockeyRole) || null,
        hand: HANDS_OPTIONS.find((hand) => hand.value === user?.hand) || null,
        userRole: TEACHERS_ROLES_OPTIONS.find((role) => role.value === user?.teacherRole) || null,
        subRole: subRolesOptions?.find((subRole) => subRole.value === user?.subRole),
      };
    }

    return {};
  }, [user, subRolesOptions]);

  const userSchool = useSelector((state) => state.users.currentUser.schoolsIds[0]);

  useEffect(() => {
    if (user) {
      reset(formatedForForm);
      getGroupsBySchoolId(formatedForForm.school);
    }
  }, [formatedForForm, userSchool]);

  useEffect(() => {
    if (formatedForForm.children && formatedForForm.children.length) {
      reset({
        ...formatedForForm,
        childrens: formatedForForm.children.map((element) => ({
          label: element.firstName + ' ' + element.lastName,
          value: element.id,
        })),
      });
    }
  }, [formatedForForm, userSchool]);

  useEffect(() => {
    if (!user && schools.length && !isUserAdmin) {
      const currentSchool = schools.find((school) => school.value === userSchool);
      setValue('school', currentSchool);
      getGroupsBySchoolId(currentSchool);
    }
  }, [schools, userSchool, isUserAdmin]);

  // roles
  const isStudent = role === userRoles.student;
  const isDoctor = role === userRoles.doctor;
  const isFranchisee = role === userRoles.franchisee;
  const isTeacher = role === userRoles.teacher;
  const isPsycho = role === userRoles.psycho;
  const isSchoolAdmin = role === userRoles.schools_admin;
  const isParent = role === userRoles.parent;
  const isVideoAnalyst = role === userRoles.video_analyst;

  const [filters, setFilters] = useState({
    userRole: userRoles.student,
    q: null,
    limit: 25,
    page: 1,
    active: true,
  });

  const getSchools = async () => {
    const schools = await SchoolsApi.getAllSchools();
    setSchools(schools.map((school) => ({label: school.name, value: school.id})));
  };

  const getChildrens = async (value) => {
    setIsLoading(true);
    try {
      const {data} = await UsersAPI.getUsersByQueryV2(value);
      setChildrens(
        data.result.map((element) => ({
          label: element.firstName + ' ' + element.lastName,
          value: element.id,
        })),
      );
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getChildrens(filters);
  }, [filters]);

  const handleChange = (value, name) => {
    setFilters((prev) => ({...prev, [name]: value}));
  };

  const [handleSearch] = useDebouncedCallback(
    (value) => {
      handleChange(value, 'q');
    },
    300,
    [],
  );

  const getGroupsBySchoolId = async (schools) => {
    let groups = null;

    if (Array.isArray(schools)) {
      groups = await Promise.all(
        schools.map(async (school) => {
          return await StateHelper.getGroupsBySchoolId(school.value);
        }),
      );
    } else {
      groups = await StateHelper.getGroupsBySchoolId(schools.value);
    }

    setGroups(CommonHelper.getDropDownTeamsByNamedList(groups?.flat()));
  };

  useEffect(() => {
    getSchools();
  }, []);

  const handelSave = async (data) => {
    const selectedRole = data?.role?.value;
    let currentSchool = null;
    let currentGroup = null;
    if (selectedRole === 'student') {
      currentSchool = Array.isArray(data.school)
        ? {name: data?.school[0]?.label, id: data?.school[0]?.value}
        : {name: data?.school?.label, id: data?.school?.value};
      currentGroup = Array.isArray(data.group)
        ? {name: data?.group[0]?.label, id: data?.group[0]?.value}
        : {name: data?.group?.label, id: data?.group?.value};
    } else {
      currentSchool = Array.isArray(data?.school) ? data?.school.map((school) => school.value) : [data?.school?.value];
      currentGroup = Array.isArray(data?.group) ? data.group.map((group) => group.value) : [data?.group?.value];
    }

    const formatedData = {
      hockeyRole: data?.amplua?.value,
      groupId: selectedRole === userRoles.student ? currentGroup?.id : null,
      groupsIds: selectedRole !== userRoles.student ? currentGroup : null,
      hand: data?.hand?.value,
      [selectedRole === userRoles.teacher ? 'teacherRole' : 'subRole']: data?.subRole?.value,
      schoolId: (selectedRole === userRoles.student && currentSchool?.id) || null,
      schoolsIds: (selectedRole !== userRoles.student && currentSchool) || null,
      birthdayTimestamp: data?.birthdayTimestamp,
      email: data?.email?.toLowerCase(),
      childrenIds: data?.childrens?.map((el) => el.value) || [],
      firstName: data?.firstName?.trim(),
      lastName: data?.lastName?.trim(),
      middleName: data?.middleName?.trim(),
      password: data?.password,
      phone: data.phone,
      playerNumber: data?.playerNumber,
    };

    setIsLoading(true);
    try {
      if (isCreate) {
        const {data} = await UsersAPI.createUserV2(formatedData, role?.replace('_', '-'));

        if (data) {
          history.push(`/users/${data._id}`);
        }
        ReactComponentNotification(NOTIFICATION_TYPES['success'], `Пользователь создан`);
      } else {
        const {data} = await UsersAPI.updateUserV2(
          {...formatedData, userRole: role},
          user.userRole?.replace('_', '-'),
          user.id,
        );

        ReactComponentNotification(NOTIFICATION_TYPES['success'], `Пользователь сохранен`);
        if (data) {
          setIsEdit(false);
          getUser(user.id);
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data) {
          if (err.response.data.message) {
            ReactComponentNotification(NOTIFICATION_TYPES['error'], `${err.response.data.message}`);
          }
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <form autoComplete="new-form" onSubmit={handleSubmit(handelSave)}>
      <ReactComponentModal
        buttons={
          <React.Fragment>
            <Button
              className={'DialogButton DialogButton_no'}
              color="primary"
              variant="contained"
              onClick={() => {
                setIsAlert(false);
              }}
            >
              Отменить
            </Button>
            <Button
              className={'DialogButton DialogButton_yes'}
              color="primary"
              variant="outlined"
              onClick={() => {
                if (isCreate) {
                  history.push(`/users`);
                } else {
                  if (isFromTable) {
                    history.push('/users');
                    return;
                  }
                  setIsEdit(false);
                }
              }}
            >
              Закрыть
            </Button>
          </React.Fragment>
        }
        className="ReactComponentModalDefault"
        content={
          <>
            <p>Все введенные данные не будут сохранены - вы уверены?</p>
          </>
        }
        title={!isCreate ? 'Редактирование' : 'Создание'}
        visible={isAlert}
        onClose={() => {
          setIsAlert(false);
        }}
      />
      <ModalCropUploadPanel
        cropModalState={isCrop}
        onChange={(url) => {
          setValue('avatar', url);
        }}
        onClose={() => {
          setIsCrop(false);
        }}
      />
      <TableDefault
        withOutOverFlow
        withSave
        content={
          <>
            <Container>
              <HeaderTitle>Личные данные</HeaderTitle>
              <Wrapper>
                <Controller
                  control={control}
                  name="avatar"
                  render={({field}) => (
                    <AvatarContainer
                      onClick={() => {
                        setIsCrop(true);
                      }}
                    >
                      <Avatar src={field.value ? convertImgTo200(field.value) : FACELESS_AVATAR} />
                      <BadgeContainer
                        onClick={() => {
                          setIsCrop(true);
                        }}
                      >
                        <BadgeIcon>
                          {field.value ? (
                            <Icon height={10} type="PencilWhite" viewBox={'0 0 8 8'} width={10} />
                          ) : (
                            <Icon height="12" type="Plus" viewBox="0 0 12 12" width="12" />
                          )}
                        </BadgeIcon>
                      </BadgeContainer>
                    </AvatarContainer>
                  )}
                  rules={{required: false}}
                />
              </Wrapper>
              <CellsContainer>
                {isCreate && (
                  <Cell>
                    <Label>Роль</Label>
                    <Controller
                      control={control}
                      name="role"
                      render={({field, fieldState}) => {
                        return (
                          <Select
                            errorText="Обязательное поле"
                            isInvalid={fieldState.error}
                            options={isUserAdmin ? roleOptions : roleOptions.filter((role) => role.value !== 'admin')}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        );
                      }}
                      rules={{required: true}}
                    />
                  </Cell>
                )}
                <Cell>
                  <Label>Имя</Label>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({field, fieldState}) => {
                      return (
                        <InputDefault
                          errorText="Обязательное поле"
                          isInvalid={fieldState.error}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                    rules={{required: true}}
                  />
                </Cell>
                <Cell>
                  <Label>Фамилия</Label>
                  <Controller
                    control={control}
                    name="lastName"
                    render={({field, fieldState}) => {
                      return (
                        <InputDefault
                          errorText="Обязательное поле"
                          isInvalid={fieldState.error}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                    rules={{required: true}}
                  />
                </Cell>
                <Cell>
                  <Label>Отчество</Label>
                  <Controller
                    control={control}
                    name="middleName"
                    render={({field, fieldState}) => {
                      return (
                        <InputDefault isInvalid={fieldState.error} value={field.value} onChange={field.onChange} />
                      );
                    }}
                    rules={{required: false}}
                  />
                </Cell>
                <Cell>
                  <Label>Дата рождения</Label>
                  <Controller
                    control={control}
                    name="birthdayTimestamp"
                    render={({field: {value, onChange, ...field}, fieldState}) => {
                      return (
                        <DatePickerDefault
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          inputFormat="DD.MM.yyyy"
                          maxDate={moment()}
                          size="small"
                          {...field}
                          value={value}
                          onChange={(newBirthdayTimestamp) => {
                            onChange(newBirthdayTimestamp ? +new Date(newBirthdayTimestamp) : null);
                          }}
                        />
                      );
                    }}
                    rules={{required: subRole === STUDENT_SUBROLES.amateur ? false : 'Обязательное поле'}}
                  />
                </Cell>
                <Cell>
                  <Label>Телефон</Label>
                  <Controller
                    control={control}
                    name="phone"
                    render={({field, fieldState}) => {
                      return (
                        <InputMask mask="9 (999) 999-99-99" value={field.value} onChange={field.onChange}>
                          {(inputProps) => (
                            <InputDefault
                              {...inputProps}
                              errorText="Поле заполнено неверно"
                              isInvalid={fieldState.error}
                            />
                          )}
                        </InputMask>
                      );
                    }}
                    rules={{
                      required: false,
                      validate: (v) => {
                        if (!v) return true;
                        return v?.replace(/\D+/g, '')?.length === 11;
                      },
                    }}
                  />
                </Cell>
                {isParent && (
                  <>
                    <Cell>
                      <Label>ДЕТИ</Label>
                      <Controller
                        control={control}
                        name="childrens"
                        render={({field, fieldState}) => {
                          return (
                            <Select
                              isMulti
                              errorText="Обязательное поле"
                              isInvalid={fieldState.error}
                              options={childrens}
                              placeholder="Выберите"
                              value={field.value}
                              onChange={(v) => {
                                field.onChange(v);
                              }}
                              onInputChange={handleSearch}
                            />
                          );
                        }}
                        rules={{
                          required: true,
                        }}
                      />
                    </Cell>
                  </>
                )}
                {(isPsycho ||
                  isDoctor ||
                  isTeacher ||
                  isSchoolAdmin ||
                  isStudent ||
                  isFranchisee ||
                  isVideoAnalyst) && (
                  <Cell>
                    <Label>Школа</Label>
                    <Controller
                      control={control}
                      name="school"
                      render={({field, fieldState}) => {
                        return (
                          <Select
                            errorText="Обязательное поле"
                            isDisabled={!isUserAdmin}
                            isInvalid={fieldState.error}
                            isMulti={(() => {
                              if (isUserAdmin) {
                                return !isStudent;
                              }

                              if (field?.value?.length > 1) {
                                return true;
                              }

                              return false;
                            })()}
                            options={schools}
                            placeholder="Выберите"
                            value={field.value}
                            onChange={(v) => {
                              if (isStudent) {
                                setValue('group', null);
                              }
                              getGroupsBySchoolId(v);
                              field.onChange(v);
                            }}
                          />
                        );
                      }}
                      rules={{
                        required: true,
                      }}
                    />
                  </Cell>
                )}
                {(isTeacher || isStudent) && (
                  <Cell>
                    <Label>Команда</Label>
                    <Controller
                      control={control}
                      name="group"
                      render={({field, fieldState}) => {
                        return (
                          <Select
                            errorText="Обязательное поле"
                            isInvalid={fieldState.error}
                            isMulti={isTeacher}
                            options={groups}
                            placeholder="Выберите"
                            value={field.value}
                            onChange={(select) => {
                              field.onChange(select);
                            }}
                          />
                        );
                      }}
                      rules={{required: true}}
                    />
                  </Cell>
                )}
                {(isTeacher || isStudent) && (
                  <Cell>
                    <Label>Тип</Label>
                    <Controller
                      control={control}
                      name="subRole"
                      render={({field, fieldState}) => {
                        return (
                          <Select
                            isClearable
                            errorText="Обязательное поле"
                            isInvalid={fieldState.error}
                            options={subRolesOptions}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        );
                      }}
                      rules={{required: isTeacher}}
                    />
                  </Cell>
                )}
                {isStudent && (
                  <Cell>
                    <Label>Амплуа</Label>
                    <Controller
                      control={control}
                      name="amplua"
                      render={({field, fieldState}) => {
                        return (
                          <Select
                            errorText="Обязательное поле"
                            isInvalid={fieldState.error}
                            options={HOCKEY_ROLES_OPTIONS}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        );
                      }}
                      rules={{required: true}}
                    />
                  </Cell>
                )}
                {isStudent && (
                  <Cell>
                    <Label>Хват</Label>
                    <Controller
                      control={control}
                      name="hand"
                      render={({field, fieldState}) => {
                        return (
                          <Select
                            isInvalid={fieldState.error}
                            options={HANDS_OPTIONS}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        );
                      }}
                      rules={{required: false}}
                    />
                  </Cell>
                )}
                {isStudent && (
                  <Cell>
                    <Label>Игровой номер</Label>
                    <Controller
                      control={control}
                      name="playerNumber"
                      render={({field, fieldState}) => {
                        return (
                          <InputDefault isInvalid={fieldState.error} value={field.value} onChange={field.onChange} />
                        );
                      }}
                      rules={{required: false}}
                    />
                  </Cell>
                )}
              </CellsContainer>
            </Container>
            <Container>
              <HeaderTitle>Данные для входа</HeaderTitle>
              <CellsContainer pb="5px" pt="5px">
                <Cell>
                  <Label>Логин</Label>
                  <Controller
                    control={control}
                    name="email"
                    render={({field, fieldState}) => {
                      return (
                        <InputDefault
                          autoComplete="new-login" // выключение autocomplete на форме...
                          errorText={
                            fieldState?.error?.type === 'pattern' ? fieldState?.error?.message : 'Обязательное поле'
                          }
                          isInvalid={fieldState.error}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                    rules={{
                      required: true,
                      pattern: {
                        value: mailRegExp,
                        message: 'Введите корректный e-mail',
                      },
                    }}
                  />
                </Cell>
                <Cell>
                  <Label>{isCreate ? 'Пароль' : 'Новый пароль'}</Label>
                  <Controller
                    control={control}
                    name="password"
                    render={({field, fieldState}) => {
                      return (
                        <InputDefault
                          autoComplete="new-password"
                          errorText="Обязательное поле"
                          isInvalid={fieldState.error}
                          type="password"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                    rules={{required: isCreate}}
                  />
                </Cell>
              </CellsContainer>
            </Container>
          </>
        }
        title="Анкета пользователя"
        onClose={() => {
          setIsAlert(true);
        }}
      />
    </form>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 16px 34px;
`;

const Avatar = styled.div`
  width: 65px;
  height: 65px;
  background-color: #e7e7e7;
  border-radius: 50%;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const AvatarContainer = styled.div`
  position: relative;
  display: flex;
`;

const BadgeContainer = styled.div`
  position: absolute;
  display: flex;
  width: 20px;
  height: 20px;
  background-color: #990011;
  border: 1px solid white;
  border-radius: 50%;
  right: -5px;
  bottom: 4px;
`;

const BadgeIcon = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const HeaderTitle = styled.div`
  padding: 15px 34px;
  border-bottom: 1.3px solid #dddad4;
  border-top: 1.3px solid #dddad4;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  font-size: 20px;
`;

const Container = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
`;
const Cell = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 22%;
  min-width: 21%;
  padding: 10px;

  @media (max-width: 1300px) {
    min-width: 30%;
    max-width: 30%;
  }

  @media (max-width: 700px) {
    min-width: 40%;
    max-width: 40%;
  }
  @media (max-width: 550px) {
    min-width: 90%;
    max-width: 90%;
  }
`;

const CellsContainer = styled.div`
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};
  display: flex;
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  flex-wrap: wrap;
  padding-left: 34px;
`;

const Label = styled.div`
  margin-top: 0;
  margin-bottom: 4px;
  font: 12px / 18px 'Proxima Nova';
  color: rgb(116, 116, 116);
  text-transform: uppercase;
`;

export default UserForm;
