import {loadStatsMatchShifts} from '@actions/StatsActions';
import eagle from '@assets/images/square_logo.svg';
import Echart from '@common/Echart';
import Annotation from '@components/apps/StatsReportForOneGame/components/ShiftsTimeline/components/Annotation';
import PlayersTable from '@components/apps/StatsReportForOneGame/components/ShiftsTimeline/components/PlayersTable';
import ShiftVideoModal from '@components/apps/StatsReportForOneGame/components/ShiftsTimeline/components/ShiftVideoModal';
import {
  calculateZoom,
  renderGoalTooltip,
  renderMarkArea,
  renderTimelineItem,
} from '@components/apps/StatsReportForOneGame/components/ShiftsTimeline/helpers';
import {minuteInMs} from '@components/apps/StatsReportForOneGame/constants';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {convertTimeMMSS} from '@helpers/dateHelper';
import useElementSize from '@hooks/useElementSize';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import {setVideoModal} from '@slices/statistics';
import {PERIOD_VALUES} from '@src/constants';
import {Button, Typography} from '@ui/MUI';
import Box from '@ui/MUI/Box';
import {grey, primary, yellow} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import {isEmpty} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {ChartTableRightPartScrollInner, ChartTableRightPartScrollWrapper, ChartTableWrapper} from './ShiftsTimeline';

function itemTooltip(data, isMobile) {
  return `
            <div style="padding-bottom: ${data?.linkToVideo ? '30px' : ''}">${
              data.value[3] > minuteInMs
                ? `<span style="color:${yellow['100']}">${isMobile ? 'Переиг. смена' : 'Переигранная смена'}</span><br>`
                : ''
            }
   ${isMobile ? 'Продолжит.' : 'Продолжительность'}: <span style="color:#fff;">${convertTimeMMSS(
     data.value[3],
   )}</span><br>
   Начало: <span style="color:#fff;">${convertTimeMMSS(data.value[1])}</span><br>
   Конец: <span style="color:#fff;">${convertTimeMMSS(data.value[2])}</span><br>
   ${
     data?.linkToVideo
       ? `<button
         style="position: absolute;
                  left: 0; bottom: 0;
                  text-align: center;
                  min-width: 100%;
                  display: block;
                  padding: 6px 8px;
                  color: #fff;
                  text-transform: uppercase;
                  background-color:${grey['100']};
                  border: none;
                  font-weight: 600;
                  line-height: 14px;
                  cursor: ${data?.linkToVideo ? 'pointer' : 'not-allowed'};
                  pointer-events: ${data?.linkToVideo ? 'all' : 'none'};"
         onClick="showGoals()"
       >
         смотреть видео
       </button>`
       : ''
   }</div>
`;
}

const goalSeries = {
  type: 'scatter',
  itemStyle: {
    opacity: 1,
  },
  encode: {
    x: 0,
    y: 1,
  },
  data: [],
  symbolSize: 20,
  color: grey['24'],
};

const ShiftsTimeline = ({hasOT, teams: {away, home, weAtHome}}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const {loading, error, isLoad, players, shifts, goals, gaps} = useStore((state) => state.stats.matchShifts);

  const {statisticId} = useParams();

  const xAxisMaxNumber = (value) => {
    if (hasOT) {
      return Math.ceil(value.max / minuteInMs) * minuteInMs;
    }
    return minuteInMs * 60;
  };
  const dispatch = useStoreDispatch();
  const [isSidebarToggled, setIsSidebarToggled] = useState(true);
  const switchButtons = useMemo(() => {
    const buttons = [
      {
        name: PERIOD_VALUES.all,
        text: 'Все',
      },
      {
        name: PERIOD_VALUES[1],
        text: '1 период',
      },
      {
        name: PERIOD_VALUES[2],
        text: '2 период',
      },
      {
        name: PERIOD_VALUES[3],
        text: '3 период',
      },
      {
        name: PERIOD_VALUES.OT,
        text: 'Овертайм',
      },
    ];
    return hasOT ? buttons : buttons.slice(0, buttons.length - 1);
  }, [hasOT]);
  const [selectedHalf, setSelectedHalf] = useState(PERIOD_VALUES.all);

  const [chartWrapperRef, {availableHeight}] = useElementSize();

  const [annotationWrapperRef, annotationWrapperSize] = useElementSize();
  const chartWrapperHeight =
    availableHeight - annotationWrapperSize.height < 400 ? 400 : availableHeight - annotationWrapperSize.height;

  const teamNamesOurFirst = useMemo(() => {
    return weAtHome ? [home, away] : [away, home];
  }, [home, away, weAtHome]);
  useEffect(() => {
    dispatch(loadStatsMatchShifts({statisticId, half: selectedHalf}));
  }, [statisticId, selectedHalf]);

  useEffect(() => {
    window.ym(80266396, 'reachGoal', 'statistika_smeny');
  }, []);

  return (
    <>
      <Box backgroundColor="#fff" borderRadius="6px" mb={1} px={{xxs: 3, xs: 4}} py={2}>
        <Stack direction="row" overflow="auto">
          {switchButtons.map((item, index) => (
            <Button
              color={selectedHalf === item.name ? 'primary' : 'secondary'}
              key={index}
              minWidth="fit-content"
              sx={{whiteSpace: 'nowrap', minWidth: 'fit-content'}}
              variant={selectedHalf === item.name ? 'contained' : 'text'}
              onClick={() => setSelectedHalf(item.name)}
            >
              {item.text}
            </Button>
          ))}
        </Stack>
      </Box>

      <ChartTableWrapper>
        <Box height={chartWrapperHeight} overflow="auto" position="relative" ref={chartWrapperRef}>
          <Stack alignItems="stretch" direction="row">
            <LocalPreloader visible={loading} />
            {isLoad && (!isEmpty(error) || (isEmpty(players) && isEmpty(shifts) && isEmpty(goals))) ? (
              <Stack alignItems="center" height={300} justifyContent="center" width="100%">
                <Typography variant="subtitle2">Данные по сменам отсутствуют</Typography>
              </Stack>
            ) : (
              <>
                <PlayersTable
                  availableHeight={chartWrapperHeight}
                  isMobile={isMobile}
                  isSidebarToggled={isSidebarToggled}
                  players={players}
                  setIsSidebarToggled={setIsSidebarToggled}
                />
                <ChartTableRightPartScrollWrapper>
                  <ChartTableRightPartScrollInner>
                    <Echart
                      isResponsitive
                      height="100%"
                      instaceSx={{width: 'inherit'}}
                      options={{
                        tooltip: {
                          enterable: true,
                          show: true,
                          triggerOn: 'click',
                          trigger: 'item',
                          backgroundColor: '#383838',
                          borderColor: '#383838',
                          textStyle: {
                            color: 'rgba(255, 255, 255, 0.7)',
                            fontSize: 12,
                            fontFamily: 'Proxima Nova',
                          },
                        },
                        dataZoom: [
                          {
                            show: false,
                            ...calculateZoom(selectedHalf, hasOT),
                          },
                        ],
                        grid: {
                          left: 0,
                          top: 61,
                          bottom: 0,
                          right: 0,
                        },
                        xAxis: [
                          {
                            position: 'top',
                            min: 0,
                            max: xAxisMaxNumber,
                            interval: minuteInMs * 5,
                            axisLabel: {
                              show: false,
                            },
                            axisTick: {
                              show: true,
                            },
                            axisLine: {
                              show: true,
                              lineStyle: {
                                color: grey['12'],
                              },
                            },
                            splitLine: {
                              lineStyle: {
                                type: 'dashed',
                                color: grey['12'],
                              },
                            },
                            zLevel: 5,
                          },
                          {
                            position: 'top',
                            offset: 30,
                            id: 1,
                            min: 0,
                            max: xAxisMaxNumber,
                            interval: minuteInMs * 20,
                            axisLabel: {
                              show: true,
                              formatter: (val) => {
                                const values = {
                                  0: '1-й период',
                                  [minuteInMs * 20]: '2-й период',
                                  [minuteInMs * 40]: '3-й период',
                                  [minuteInMs * 60]: 'ОТ',
                                };
                                return values[val];
                              },
                              color: '#000',
                              fontFamily: 'Proxima Nova',
                              fontSize: 13,
                              fontWeight: 600,
                              align: 'left',
                              padding: [0, 0, 0, 20],
                              alignWithLabel: false,
                            },
                            axisTick: {
                              show: true,
                              length: 35,
                            },
                            axisLine: {
                              show: true,
                              onZero: 0,
                              lineStyle: {
                                color: '#E0E0E0',
                              },
                            },
                            splitLine: {
                              show: true,
                              lineStyle: {
                                type: 'solid',
                                color: '#9F9F9F',
                              },
                            },
                          },
                          {
                            position: 'top',
                            min: 2,
                            max: xAxisMaxNumber,
                            interval: minuteInMs * 20,
                            splitLine: {
                              show: true,
                              lineStyle: {
                                type: 'solid',
                                color: grey['42'],
                              },
                            },
                            axisLabel: {
                              formatter: (val) => convertTimeMMSS(val),
                              color: '#000',
                              fontFamily: 'Proxima Nova',
                              fontSize: 11,
                              showMaxLabel: false,
                              showMinLabel: false,
                              align: 'center',
                            },
                          },
                        ],
                        yAxis: {
                          show: false,
                          axisLine: {
                            show: false,
                          },
                          data: [...players].reverse(),
                        },
                        series: [
                          ...[...Array(2)].map((item, xAxisIndex) => {
                            return {
                              xAxisIndex,
                              type: 'custom',
                              renderItem: (params, api) => renderTimelineItem(params, api, shifts),
                              itemStyle: {
                                opacity: 1,
                              },
                              encode: {
                                x: [1, 2],
                                y: 0,
                              },
                              data: shifts,
                              tooltip: {
                                formatter: ({data}) => {
                                  window.showGoals = function () {
                                    dispatch(setVideoModal({isOpen: true, data}));
                                  };
                                  return itemTooltip(data, isMobile);
                                },
                              },
                              markArea: {
                                itemStyle: {
                                  opacity: 1,
                                },
                                data: [
                                  ...(gaps?.majority || []).map((item) =>
                                    renderMarkArea(item, isMobile ? 'больш.' : 'большинстве', primary['10']),
                                  ),
                                  ...(gaps?.minority || []).map((item) =>
                                    renderMarkArea(item, isMobile ? 'меньш.' : 'меньшинстве', grey['8']),
                                  ),
                                ],
                              },
                            };
                          }),
                          ...goals.map((item) => ({
                            ...goalSeries,
                            symbol:
                              item?.teamLogo || item?.isOur
                                ? 'image://' + (item?.isOur && !item.teamLogo ? eagle : item.teamLogo)
                                : 'roundRect',
                            symbolKeepAspect: true,
                            color: grey['68'],
                            label: {
                              fontFamily: 'Proxima Nova',
                              show: true,
                              formatter: ({data}) => (data?.teamLogo || data?.isOur ? '' : teamNamesOurFirst[1]?.[0]),
                            },
                            tooltip: {
                              formatter: renderGoalTooltip(item, players, teamNamesOurFirst),
                            },
                            data: [
                              {
                                ...item,
                                value: [item.time, players[0]?.value],
                              },
                            ],

                            symbolOffset: [0, '-200%'],
                            markLine: {
                              symbolSize: 0,
                              tooltip: {
                                formatter: renderGoalTooltip(item, players, teamNamesOurFirst),
                              },
                              lineStyle: {
                                type: 'dashed',
                                color: grey['42'],
                                width: 2,
                                cap: {
                                  show: false,
                                },
                              },
                              label: {show: false},
                              data: [{type: 'min'}, {type: 'min'}],
                            },
                          })),
                        ],
                      }}
                      width="100%"
                      wrapperSx={{width: 'inherit'}}
                    />
                  </ChartTableRightPartScrollInner>
                </ChartTableRightPartScrollWrapper>
              </>
            )}
          </Stack>
        </Box>
      </ChartTableWrapper>
      <Box pt={0} ref={annotationWrapperRef}>
        <Annotation />
      </Box>
      <ShiftVideoModal shifts={shifts} />
    </>
  );
};

export default ShiftsTimeline;
