export const getFormData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    const item = object[key];
    if (Array.isArray(item)) {
      item.forEach((v) => {
        const isFile = v?.storage?.mimeType || v instanceof File;
        formData.append(`${key}${!isFile ? '[]' : ''}`, v);
      });
    } else {
      formData.append(key, item);
    }

    return formData;
  }, new FormData());
