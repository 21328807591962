import React from 'react';

function CloseRed() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M8.5 0C3.80202 0 0 3.80166 0 8.5C0 13.198 3.80166 17 8.5 17C13.198 17 17 13.1983 17 8.5C17 3.80199 13.1983 0 8.5 0ZM8.5 15.6719C4.53608 15.6719 1.32812 12.4642 1.32812 8.5C1.32812 4.53605 4.53578 1.32812 8.5 1.32812C12.4639 1.32812 15.6719 4.53578 15.6719 8.5C15.6719 12.464 12.4642 15.6719 8.5 15.6719Z"
          fill="#990011"
        />
        <path
          d="M11.4076 10.469L9.43862 8.5L11.4076 6.53099C11.6669 6.27167 11.667 5.85122 11.4077 5.59187C11.1483 5.33252 10.7278 5.33256 10.4685 5.59187L8.49951 7.56089L6.53047 5.59187C6.27119 5.33252 5.85067 5.33252 5.59135 5.59187C5.33204 5.85122 5.33204 6.27167 5.59139 6.53099L7.56039 8.5L5.59139 10.469C5.33204 10.7284 5.332 11.1488 5.59135 11.4081C5.85077 11.6675 6.27122 11.6674 6.53047 11.4081L8.49951 9.43912L10.4685 11.4081C10.7278 11.6674 11.1483 11.6675 11.4077 11.4081C11.667 11.1488 11.667 10.7283 11.4076 10.469Z"
          fill="#990011"
        />
      </g>
    </React.Fragment>
  );
}
export default CloseRed;
