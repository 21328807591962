import React from 'react';

import AddCircle from './AddCircle';
import AddRounded from './AddRounded';
import ArrowBack from './ArrowBack';
import ArrowUp from './ArrowUp';
import AverageLengthOfShift from './AverageLengthOfShift';
import BlackMan from './BlackMan';
import Calendar from './Calendar';
import Camera from './Camera';
import CheckGray from './CheckGray';
import ChevronDown from './ChevronDown';
import ChevronUp from './ChevronUp';
import Clear from './Clear';
import Close from './Close';
import CloseRed from './CloseRed';
import Coach from './Coach';
import Comment from './Comment';
import Delete from './Delete';
import Documents from './Documents';
import DoubleArrows from './DoubleArrows';
import Dumbbell from './Dumbbell';
import Edit from './Edit';
import EditWithBorder from './EditWithBorder';
import Error from './Error';
import Eye from './Eye';
import Folder from './Folder';
import ForkAndKnife from './ForkAndKnife';
import ForkAndKnifeDark from './ForkAndKnifeDark';
import Gear from './Gear';
import Goals from './Goals';
import Heart from './Heart';
import Help from './Help';
import HockeyGates from './HockeyGates';
import HockeyMask from './HockeyMask';
import HockeyPitch from './HockeyPitch';
import HockeyPitchColorful from './HockeyPitchColorful';
import HockeyPitchColorfulLarge from './HockeyPitchColorfulLarge';
import HockeyPitchColorfulLargeVertical from './HockeyPitchColorfulLargeVertical';
import HockeyPlayer from './HockeyPlayer';
import HockeyStick from './HockeyStick';
import HockeyStickForBasicParams from './HockeyStickForBasicParams';
import HockeyStickForHand from './HockeyStickForHand';
import HockeySticks from './HockeySticks';
import Info from './Info';
import LeftArrow from './LeftArrow';
import LeftArrowWithLine from './LeftArrowWithLine';
import Man from './Man';
import ManPlus from './ManPlus';
import MedicinePlus from './MedicinePlus';
import MenuTitle from './MenuTitle';
import NewHockeyStick from './NewHockeyStick';
import NoteBook from './NoteBook';
import NotificationClose from './NotificationClose';
import NotificationError from './NotificationError';
import NotificationInfo from './NotificationInfo';
import NotificationSuccess from './NotificationSuccess';
import NotificationWarning from './NotificationWarning';
import PencilGray from './PencilGray';
import PencilWhite from './PencilWhite';
import Percent from './Percent';
import Person from './Person';
import Play from './Play';
import Player from './Player';
import PlayerColorful from './PlayerColorful';
import Plus from './Plus';
import PlusLarge from './PlusLarge';
import PossessionOfThePuck from './PossessionOfThePuck';
import Print from './Print';
import Psychologist from './Psychologist';
import Puck from './Puck';
import RatingBarbell from './RatingBarbell';
import RatingHockeyPlayer from './RatingHockeyPlayer';
import RatingPlus from './RatingPlus';
import RatingPuck from './RatingPuck';
import RatingSticks from './RatingSticks';
import Recommendation from './Recommendation';
import Refresh from './Refresh';
import ReplaceArrows from './ReplaceArrows';
import Report from './Report';
import RightArrow from './RightArrow';
import RightArrowDark from './RightArrowDark';
import Schedule from './Schedule';
import Search from './Search';
import SearchGrey from './SearchGrey';
import Sort from './Sort';
import Spaces from './Spaces';
import Star from './Star';
import StarFavorites from './StarFavorites';
import Statistics from './Statistics';
import Stats from './Stats';
import Success from './Success';
import Team from './Team';
import Thunderbolt from './Thunderbolt';
import Ticket from './Ticket';
import TooltipQuestion from './TooltipQuestion';
import TopArrow from './TopArrow';
import TrendDown from './TrendDown';
import TrendUp from './TrendUp';
import TShirt from './Tshirt';
import UnFillInfo from './UnFillInfo';
import UralStatistics from './UralStatistics';
import User from './User';
import ValueAppArrow from './ValueAppArrow';
import ValueDownArrow from './ValueDownArrow';
import Warning from './Warning';
import Whistle from './Whistle';
import WinCup from './WinCup';
import Xls from './Xls';
import Zip from './Zip';

const map = {
  AddCircle: <AddCircle />,
  AddRounded: <AddRounded />,
  AverageLengthOfShift: <AverageLengthOfShift />,
  Calendar: <Calendar />,
  Camera: <Camera />,
  Clear: <Clear />,
  Close: <Close />,
  CloseRed: <CloseRed />,
  Coach: <Coach />,
  Comment: <Comment />,
  Delete: <Delete />,
  Documents: <Documents />,
  DoubleArrows: <DoubleArrows />,
  Dumbbell: <Dumbbell />,
  Edit: <Edit />,
  EditWithBorder: <EditWithBorder />,
  Error: <Error />,
  Folder: <Folder />,
  ForkAndKnife: <ForkAndKnife />,
  ForkAndKnifeDark: <ForkAndKnifeDark />,
  Gear: <Gear />,
  Heart: <Heart />,
  Help: <Help />,
  HockeyGates: <HockeyGates />,
  HockeyMask: <HockeyMask />,
  HockeyPitch: <HockeyPitch />,
  HockeyPitchColorful: <HockeyPitchColorful />,
  HockeyPitchColorfulLarge: <HockeyPitchColorfulLarge />,
  HockeyPitchColorfulLargeVertical: <HockeyPitchColorfulLargeVertical />,
  HockeyPlayer: <HockeyPlayer />,
  HockeyStick: <HockeyStick />,
  HockeyStickForBasicParams: <HockeyStickForBasicParams />,
  HockeyStickForHand: <HockeyStickForHand />,
  HockeySticks: <HockeySticks />,
  InfoAlert: <Info />,
  LeftArrow: <LeftArrow />,
  LeftArrowWithLine: <LeftArrowWithLine />,
  Man: <Man />,
  ManPlus: <ManPlus />,
  MedicinePlus: <MedicinePlus />,
  MenuTitle: <MenuTitle />,
  NotificationError: <NotificationError />,
  NotificationWarning: <NotificationWarning />,
  NotificationSuccess: <NotificationSuccess />,
  NotificationInfo: <NotificationInfo />,
  NotificationClose: <NotificationClose />,
  PencilWhite: <PencilWhite />,
  Percent: <Percent />,
  Person: <Person />,
  Play: <Play />,
  Player: <Player />,
  PlayerColorful: <PlayerColorful />,
  Plus: <Plus />,
  PlusLarge: <PlusLarge />,
  PossessionOfThePuck: <PossessionOfThePuck />,
  Print: <Print />,
  RightArrow: <RightArrow />,
  RightArrowDark: <RightArrowDark />,
  Puck: <Puck />,
  RatingBarbell: <RatingBarbell />,
  RatingHockeyPlayer: <RatingHockeyPlayer />,
  RatingPlus: <RatingPlus />,
  RatingPuck: <RatingPuck />,
  RatingSticks: <RatingSticks />,
  Report: <Report />,
  Sort: <Sort />,
  Schedule: <Schedule />,
  Search: <Search />,
  SearchGrey: <SearchGrey />,
  Spaces: <Spaces />,
  Star: <Star />,
  StarFavorites: <StarFavorites />,
  Recommendation: <Recommendation />,
  Refresh: <Refresh />,
  ReplaceArrows: <ReplaceArrows />,
  Stats: <Stats />,
  Statistics: <Statistics />,
  Success: <Success />,
  Team: <Team />,
  Thunderbolt: <Thunderbolt />,
  Ticket: <Ticket />,
  TooltipQuestion: <TooltipQuestion />,
  TopArrow: <TopArrow />,
  TrendUp: <TrendUp />,
  TrendDown: <TrendDown />,
  TShirt: <TShirt />,
  User: <User />,
  Warning: <Warning />,
  Whistle: <Whistle />,
  ValueAppArrow: <ValueAppArrow />,
  ValueDownArrow: <ValueDownArrow />,
  WinCup: <WinCup />,
  Xls: <Xls />,
  UnFillInfo: <UnFillInfo />,
  Zip: <Zip />,
  NewHockeyStick: <NewHockeyStick />,
  NoteBook: <NoteBook />,
  ChevronDown: <ChevronDown />,
  ChevronUp: <ChevronUp />,
  Goals: <Goals />,
  Eye: <Eye />,
  ArrowBack: <ArrowBack />,
  ArrowUp: <ArrowUp />,
  CheckGray: <CheckGray />,
  PencilGray: <PencilGray />,
  Psychologist: <Psychologist />,
  UralStatistics: <UralStatistics />,
  BlackMan: <BlackMan />,
};
/**@deprecated не использовать этот компонент. использовать иконки из пакета mui icons или через mui icon component с кастомной иконкой**/
function Icon({type, width = 24, height = 24, viewBox = '0 0 26 26', className}) {
  return (
    <svg className={className} height={height} viewBox={viewBox} width={width} xmlns="http://www.w3.org/2000/svg">
      {map[type]}
    </svg>
  );
}

export default Icon;
