import {cleanUsers, getUsers} from '@actions/ScoutReportActions';
import ContentWrapper from '@common/ContentWrapper';
import FullHeightWrapper from '@common/FullHeightWrapper';
import {NoData} from '@common/ScoutReport/CommonScoutReport';
import {SCOUT_REPORT_TYPES} from '@src/constants';
import {Stack, Typography} from '@ui/MUI';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Table from '../../../../groups/components/group/table';
import LocalPreloader from '../../../../preloader/LocalPreloader';
import ChessTemplate from '../../../../templates/ChessTemplate';
import InnerPlayersTable from '../InnerPlayersTable';

const tableTypes = {
  winger: 'Нападающие',
  defenseman: 'Защитники',
  goaltender: 'Вратари',
};
export default function InnerPlayers() {
  const dispatch = useDispatch();
  const {users} = useSelector(({scoutReport}) => ({users: scoutReport.users}));
  const {teamid} = useParams();
  useEffect(() => {
    if (teamid) dispatch(getUsers(teamid, SCOUT_REPORT_TYPES.inner));
    return () => dispatch(cleanUsers());
  }, [teamid]);
  return (
    <ChessTemplate active="trainer_report">
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Отчет тренера', path: '/trainer_report/inner/teams'},
          {label: users?.group?.name || 'Клуб', path: ''},
        ]}
        marginBottom="24px"
        text="Отчет тренера"
      />
      <Stack direction="column" gap={1}>
        {users.loading ? (
          <FullHeightWrapper sx={{position: 'relative'}}>
            <LocalPreloader visible={true} />
          </FullHeightWrapper>
        ) : !users?.data?.length ? (
          <FullHeightWrapper>
            <ContentWrapper alignItems="center" display="flex" height="inherit" justifyContent="center">
              <Typography variant="subtitle1">Нет данных по игрокам</Typography>
            </ContentWrapper>
          </FullHeightWrapper>
        ) : (
          users.data?.map((item, index) => (
            <ContentWrapper key={index}>
              <Table title={tableTypes[item.hockeyRole]}>
                {item.students?.length ? (
                  <InnerPlayersTable students={item.students} />
                ) : (
                  <NoData minHeight="unset">Нет игроков</NoData>
                )}
              </Table>
            </ContentWrapper>
          ))
        )}
      </Stack>
    </ChessTemplate>
  );
}
