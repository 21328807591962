import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import {Button, Stack} from '@ui/MUI';
import React from 'react';

function TypeOfViewButtons({activeTypeOfView = 'table', setActiveTypeOfView}) {
  return (
    <Stack alignItems="center" direction="row" gap={2} ml="auto">
      <Button
        color={activeTypeOfView === 'table' ? 'primary' : 'secondary'}
        size="small"
        startIcon={<TableViewOutlinedIcon />}
        sx={{
          minWidth: 32,
          width: 32,
          height: 32,
          '& .MuiButton-startIcon': {
            mx: '0px',
          },
        }}
        variant="contained"
        onClick={() => {
          setActiveTypeOfView('table');
        }}
      />
      <Button
        color={activeTypeOfView === 'chart' ? 'primary' : 'secondary'}
        size="small"
        startIcon={<DonutSmallOutlinedIcon />}
        sx={{
          minWidth: 32,
          width: 32,
          height: 32,
          '& .MuiButton-startIcon': {
            mx: '0px',
          },
        }}
        variant="contained"
        onClick={() => {
          setActiveTypeOfView('chart');
        }}
      />
    </Stack>
  );
}

export default TypeOfViewButtons;
