import ButtonDefault from '@ui/MUI/Button';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import InputDefault from '../../ui/Input';

export default function UpdateUserInfoForm(props) {
  const {isEditModalOpen, setIsEditModalOpen, onSave = () => {}} = props;

  const [phone, setPhone] = useState(!props.phone ? '' : props.phone);
  const [firstName, setFirstName] = useState(!props.firstName ? '' : props.firstName);
  const [lastName, setLastName] = useState(!props.lastName ? '' : props.lastName);
  const [email, setEmail] = useState(!props.email ? '' : props.email);
  const [visibleEmail, setVisibleEmail] = useState(!props.email ? '' : props.email);
  const [password, setPassword] = useState(!props.password ? '' : props.password);
  const [userName, setUserName] = useState(!props.userName ? '' : props.userName);

  const changeLoginToLowerCase = (e) => {
    setVisibleEmail(e);
    setEmail(e.toLowerCase());
  };

  return (
    <DrawerDefault
      buttons={
        <ButtonsWrapper>
          <StyledButton
            color="primary"
            size="medium"
            variant="contained"
            onClick={() => {
              onSave({
                phone,
                firstName,
                lastName,
                password,
                email,
                userName,
              });
              setIsEditModalOpen({...isEditModalOpen, right: false});
            }}
          >
            сохранить
          </StyledButton>
          <StyledButton
            color="neutral"
            size="dark"
            variant="contained"
            onClick={() => {
              setIsEditModalOpen({...isEditModalOpen, right: false});
            }}
          >
            Отменить
          </StyledButton>
        </ButtonsWrapper>
      }
      content={
        <Wrapper>
          <ModalTitle>Редактирование профиля</ModalTitle>
          <Field>
            <Label>Имя</Label>
            <InputDefault value={firstName} onChange={(evt) => setFirstName(evt.target.value)} />
          </Field>

          <Field>
            <Label>Фамилия</Label>
            <InputDefault value={lastName} onChange={(evt) => setLastName(evt.target.value)} />
          </Field>

          <Field>
            <Label>Телефон</Label>
            <InputMask
              className={'my_phone'}
              mask="9 (999) 999-99-99"
              value={phone}
              onChange={(evt) => setPhone(evt.target.value)}
            />
          </Field>
          <Field>
            <Label>Логин</Label>
            <InputDefault placeholder={'Логин'} value={userName} onChange={(evt) => setUserName(evt.target.value)} />
          </Field>
          <Field>
            <Label>Email</Label>
            <InputDefault
              placeholder={'Email'}
              value={visibleEmail}
              onChange={(evt) => changeLoginToLowerCase(evt.target.value)}
            />
          </Field>

          <Field>
            <Label>Пароль</Label>
            <InputDefault
              placeholder={'Пароль'}
              type={'password'}
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </Field>
        </Wrapper>
      }
      drawerState={isEditModalOpen}
      setDrawerState={setIsEditModalOpen}
    />
  );
}

const Wrapper = styled.div``;

const ModalTitle = styled.p`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #2c2c2c;
  margin: 0;
  margin-bottom: 24px;
`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-family: 'Proxima Nova';
  font-size: 13px;
  line-height: 16px;
  color: #747474;
  display: inline-block;
  margin-bottom: 4px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(ButtonDefault)`
  margin-top: 12px !important;
  margin-bottom: 16px !important;
`;
