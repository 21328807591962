import {getMatchgoaltendersinfo} from '@api/StatsAPI';
import {defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {actions, goaltendersActions} from '@slices/statisticsMKC';
import {HALF_OPTIONS, IMPORTANCE_OPTIONS, initialStateIndicators, PLRS_OPTIONS} from '@src/constants';

export const goaltendersStatePaths = {
  goaltenders: {
    name: 'goaltenders',
    path: 'goaltenders.data',
  },
  goaltendersFilters: {
    name: 'goaltendersFilters',
    path: 'goaltenders.filters',
  },
};
export const goaltendersInitialState = {
  goaltenders: {
    filters: {
      plrs: [...PLRS_OPTIONS],
      importance: IMPORTANCE_OPTIONS[0],
      half: [...HALF_OPTIONS],
      playerIds: [],
    },
    data: {
      ...initialStateIndicators,
      data: {
        gaps: [],
        gatesMap: {},
        topStats: [],
        shotsByZones: {},
      },
    },
  },
};
export const goaltenderReducer = defaultSliceReducersConstructor(
  goaltendersStatePaths.goaltenders.name,
  goaltendersStatePaths.goaltenders.path,
  goaltendersInitialState,
).functions;

export const loadGoaltendersStat = (params) =>
  defaultLoader(
    () => getMatchgoaltendersinfo(params),
    goaltendersActions.loading,
    goaltendersActions.load,
    goaltendersActions.error,
  );
export const clearGoaltendersStat = () => (dispatch) => dispatch(goaltendersActions.clear());

export const setGoaltendersFilters = (data) => (dispatch) =>
  dispatch(actions.setEntityFilters({...data, entity: goaltendersStatePaths.goaltenders.name}));

export const clearGoaltendersFilters = () => (dispatch) =>
  dispatch(actions.clearEntityFilters({entity: goaltendersStatePaths.goaltenders.name}));
