import {Box} from '@mui/material';
import SelectDefault from '@ui/MUI/Select';
import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .MuiDrawer {
    &-paper {
      top: 58px;  
      border-radius: 6px 6px 0px 0px !important;
    }
  } 
  .MuiPaper {
    &-root {
        max-width: none !important;
        min-width: initial !important;
        .MuiTypography {
          &-subtitle2 {
            padding: 16px 16px;
            font-size: 16px;
          }
        }
        .MuiList-root {
          .MuiMenuItem-root {
            white-space: pre-wrap;
          }
        }
    }
  }
`;
export const Puller = styled(Box)`
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: 104px;
  height: 3px;
  background-color: #c2c2c2;
  border-radius: 4px;
`;
export const Wrapper = styled.div``;
export const MobileSelectWrapper = styled.div`
  .MuiInputLabel {
    &-root {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      padding-bottom: 0;
      font: 600 normal 12px/16px 'Proxima Nova';
      color: white !important;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      z-index: 1;
    }
  }
  .MuiSvgIcon {
    &-root {
      color: white;
    }
  }
`;
export const StyledSelect = styled(SelectDefault)`
  position: relative;
  margin-bottom: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  min-height: 36px;
`;
export const Container = styled.div`
  background-color: white;
  border-radius: 6px;
`;
export const LinesMobileTableContainer = styled.div`
  padding-bottom: ${(props) => (props.isButtonsShowed ? '70px' : 0)};

  .ScrollbarsCustom-Track {
    height: 8px !important;
    bottom: 8px !important;
    left: 44px !important;
  }
  .ScrollbarsCustom-TrackX {
    width: calc(100% - 48px) !important;
  }
`;
export const NearestGame = styled.div`
  padding: 12px;
  .MuiTypography {
    &-subtitle2 {
      &:last-child {
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }
`;
export const LinesMobileTable = styled.table`
  background-color: white;
  border-collapse: collapse;
  width: 100%;
`;

//ToDO: убрать лишние константы

export const LinesMobileTableHead = styled.thead``;
export const LinesMobileTableBody = styled.tbody``;
export const LinesMobileTableTh = styled.th`
  padding: 12px;
  font: 600 normal 14px/18px 'Proxima Nova';
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.16px;
  text-align: left;
  border-right: 1px solid #e5e5e5;
  @media screen and (max-width: 320px) {
    max-width: 280px;
  }
  &:first-child {
    position: sticky;
    left: 0;
    background-color: white;
    box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.12);
    z-index: 2;
  }
`;
export const LinesMobileTableTr = styled.tr`
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
`;
export const LinesMobileTableTd = styled.td`
  position: relative;
  padding: ${(props) => (props.hasIndicator ? '12px 12px 12px 20px' : '12px')};
  min-height: 38px;
  border-right: 1px solid #e5e5e5;
  font: 600 normal 14px/18px 'Proxima Nova';
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  @media screen and (max-width: 320px) {
    max-width: 280px;
  }
  &:first-child {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid #c5c5c5;
    box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.12);
    z-index: 2;
  }
`;
export const LinesMobileTableTdFlexWrapper = styled.div`
  display: flex;
  .Card,
  .EmptyCard {
    min-width: 96px;
  }
`;
export const LinesMobileTableGoaltenders = styled(LinesMobileTable)``;
export const LinesMobileTableGoaltendersHead = styled(LinesMobileTableHead)``;
export const LinesMobileTableGoaltendersTr = styled(LinesMobileTableTr)``;
export const LinesMobileTableGoaltendersTh = styled.th`
  padding: 12px 10px;
  font: 600 normal 14px/18px 'Proxima Nova';
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.16px;
  text-align: left;
  border-right: 1px solid #e5e5e5;
`;
export const LinesMobileTableGoaltendersTd = styled.td`
  padding: 12px 8px;
  border-right: 1px solid #e5e5e5;
  font: 600 normal 12px/38px 'Proxima Nova';
  color: rgba(0, 0, 0, 0.87);
  text-align: left;

  .Card,
  .EmptyCard {
    min-width: 85px;
  }
`;
export const LinesMobilePlayerNumber = styled.span`
  color: rgba(136, 119, 85, 0.87);
`;
export const StatusIndicator = styled.span`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;
export const ButtonsBlock = styled.div`
  padding: 0 12px 12px;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
  .MuiButton {
    &-root {
      &:not(:last-child) {
        @media screen and (min-width: 481px) {
          margin-right: 8px;
        }
        @media screen and (max-width: 480px) {
          margin-bottom: 8px;
        }
      }
    }
  }
`;
export const ButtonsBottomBlock = styled.div`
  position: fixed;
  bottom: 0;
  padding: 24px 12px 16px;
  width: calc(100% - 30px);
  background: white;
  z-index: 2;
  .MuiButton {
    &-root {
      min-width: 128px;
      height: 36px;
      font: 600 normal 15px/24px 'Proxima Nova';
      @media screen and (max-width: 480px) {
        width: calc(50% - 4px);
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
`;
export const AddPlayerFromOtherTeam = styled.div`
  padding: 16px 12px 30px;
  .MuiButton {
    &-root {
      padding-right: 0;
      padding-left: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      text-transform: none;
      .MuiTypography {
        &-subtitle2 {
          padding: 0 !important;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
`;
export const SelectContainer = styled.div`
  padding: 0 16px 32px;
  & + ${ButtonsBottomBlock} {
    width: 100%;
    .MuiButton-root {
      min-width: 144px;
    }
  }
`;
