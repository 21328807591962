import React from 'react';

function TrendUp() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M9.54607 16.0925H10.4979C10.9038 16.0925 11.2358 15.7604 11.2358 15.3545V9.66476H13.4092C14.066 9.66476 14.3981 8.86777 13.9331 8.40286L10.5459 4.01565C10.4081 3.87746 10.2209 3.7998 10.0257 3.7998C9.83047 3.7998 9.64328 3.87746 9.50541 4.01565L6.1182 8.40286C5.65329 8.86777 5.97799 9.66476 6.63477 9.66476H8.80812V15.3545C8.80812 15.7604 9.1402 16.0925 9.54607 16.0925Z"
          fill="#54DE00"
        />
      </g>
    </React.Fragment>
  );
}
export default TrendUp;
