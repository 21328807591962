import BMIReducer from '@reducers/BMIReducer';
import {bmiReducer} from '@slices/bodyMassIndex';
import {functionalTestingReducer} from '@slices/functionalTesting';
import {gameStatisticsParsingCheckReducer} from '@slices/gameStatisticsParsingCheck';
import {groupsNewReducer} from '@slices/groups';
import {managerDashboardReducer} from '@slices/managerDashboard.js';
import {materialReducer} from '@slices/materials/slice.js';
import {reducer as menuReducer} from '@slices/menu';
import {physicalTestingEditCreateReducer} from '@slices/PhysicalTestingEditCreate';
import {physicalSiriusReducer} from '@slices/PhysicalTestingSirius';
import {playerDiaryReducer} from '@slices/playerDiary';
import {playerPersonalFileReducer} from '@slices/playerPersonalFile';
import {schoolsV2Reducer} from '@slices/schools';
import {default as skillReducer} from '@slices/skill.js';
import {reducer as gameStatisticsReducer} from '@slices/statistics';
import {gameStatisticsMkcReducer} from '@slices/statisticsMKC';
import {tournamentsReducer} from '@slices/tournaments.js';
import {trainingControlProcessReducer} from '@slices/trainingControlProcess.js';
import {videoAnalyticsReducer} from '@slices/videoAnalytics';
import moduleNames from '@src/redux/moduleNames';
import {physicalTestingCompareReducer} from '@src/redux/slices/PhysicalTestingCompare';
import {physicalTestingOverallReducer} from '@src/redux/slices/PhysicalTestingOverall';
import {physicalTestingPlayerProgressReducer} from '@src/redux/slices/PhysicalTestingPlayerProgress';

import {throwsStatisticsReducer} from '../slices/throwsStatistics.js';
import DashboardsReducer from './DashboardsReducer.js';
import filtersReducer from './FiltersReducer.js';
import FormReducer from './FormReducer.js';
import GroupsReducer from './GroupsReducer.js';
import LessonsReducer from './LessonsReducer.js';
import LineReducer from './LinesReducer.js';
import MedicalProfileReducer from './MedicalProfileReducer';
import PhysicalTestingReducer from './PhysicalTestingReducer';
import PsychoProfileReducer from './PsychoProfileReducer';
import PsychoTestingReducer from './PsychoTestingReducer';
import RoomsReducer from './RoomsReducer.js';
import SchoolsReducer from './SchoolsReducer.js';
import ScoutReportReducer from './ScoutReportReducer.js';
import StatsReducer from './StatsReducer.js';
import TVScheduleReducer from './TVScheduleReducer';
import UsersReducer from './UsersReducer.js';

export const reducer = {
  [moduleNames.users]: UsersReducer,
  [moduleNames.groups]: GroupsReducer,
  [moduleNames.rooms]: RoomsReducer,
  [moduleNames.schools]: SchoolsReducer,
  [moduleNames.lessons]: LessonsReducer,
  [moduleNames.physicalTesting]: PhysicalTestingReducer,
  [moduleNames.psychoTesting]: PsychoTestingReducer,
  [moduleNames.medicalProfile]: MedicalProfileReducer,
  [moduleNames.lines]: LineReducer,
  [moduleNames.dashboard]: DashboardsReducer,
  [moduleNames.form]: FormReducer,
  [moduleNames.psychoProfile]: PsychoProfileReducer,
  [moduleNames.tvSchedule]: TVScheduleReducer,
  [moduleNames.scoutReport]: ScoutReportReducer,
  [moduleNames.stats]: StatsReducer,
  [moduleNames.filters]: filtersReducer,
  [moduleNames.bodyMassIndex]: BMIReducer,
  [moduleNames.materialsModule]: materialReducer,
  [moduleNames.physicalTestingOverall]: physicalTestingOverallReducer,
  [moduleNames.physicalTestingCompare]: physicalTestingCompareReducer,
  [moduleNames.physicalTestingPlayerProgress]: physicalTestingPlayerProgressReducer,
  [moduleNames.throwsStatistics]: throwsStatisticsReducer,
  [moduleNames.managerDashboard]: managerDashboardReducer,
  [moduleNames.trainingControlProcess]: trainingControlProcessReducer,
  [moduleNames.functionalTesting]: functionalTestingReducer,
  [moduleNames.gameStatistics]: gameStatisticsReducer,
  [moduleNames.gameStatisticsParsingCheck]: gameStatisticsParsingCheckReducer,
  [moduleNames.physicalTestingEditCreate]: physicalTestingEditCreateReducer,
  [moduleNames.gameStatisticsMkc]: gameStatisticsMkcReducer,
  [moduleNames.physicalTestingSirius]: physicalSiriusReducer,
  [moduleNames.videoAnalytics]: videoAnalyticsReducer,
  [moduleNames.bmi]: bmiReducer,
  [moduleNames.playerDiary]: playerDiaryReducer,
  [moduleNames.skill]: skillReducer,
  [moduleNames.tournamentsList]: tournamentsReducer,
  [moduleNames.playerPersonalFile]: playerPersonalFileReducer,
  [moduleNames.menu]: menuReducer,
  [moduleNames.groupsV2]: groupsNewReducer,
  [moduleNames.schoolsV2]: schoolsV2Reducer,
};
