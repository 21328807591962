import PageTitleDefault from '@ui/PageTitleDefault';
import queryString from 'query-string';
import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import UsersAPI from '../../../api/UsersAPI';
import ChessTemplate from '../../templates/ChessTemplate';
import UserForm from './components/UserForm';
import UserView from './components/UserView';

const User = () => {
  const {id} = useParams();
  const history = useHistory();
  const qs = queryString.parse(history.location.search);

  const isCreate = id === 'create';

  const [isEdit, setIsEdit] = useState(qs?.edit);

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const userRole = useSelector((state) => state.users.currentUser.userRole);
  const schoolsIds = useSelector((state) => state.users.currentUser.schoolsIds);

  // может ли пользователь редактировать;
  const isEditable = useMemo(() => {
    const isAdmin = userRole === 'admin';

    if (isAdmin) return true;

    if (user) {
      const schools = Array.isArray(user.school || user.schools) ? user.schools : [user.school];
      const userSchoolsIds = schools.map((school) => school?.id);
      return userSchoolsIds.some((id) => schoolsIds.includes(id));
    }

    return false;

    // if (isAdmin) return true;
  }, [userRole, schoolsIds, user]);

  useEffect(() => {
    if (id && !isCreate) {
      getUser(id);
    }
  }, [id]);

  const getUser = async (id) => {
    setIsLoading(true);
    try {
      const {data} = await UsersAPI.getUserV2(id);
      setUser(data);
    } catch (error) {
      return;
    }
    setIsLoading(false);
  };

  return (
    <ChessTemplate active="users">
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Пользователи', path: '/users'},
          {
            label:
              isEdit && isEditable
                ? 'Редактирование пользователя'
                : isCreate
                  ? 'Создание пользователя'
                  : 'Пользователь',
            path: '/users',
          },
        ]}
        text={isCreate ? 'Создание пользователя' : 'Пользователь'}
      />
      {isCreate || (isEdit && isEditable) ? (
        <UserForm
          getUser={getUser}
          isCreate={isCreate}
          isFromTable={qs.edit}
          isUserAdmin={userRole === 'admin'}
          setIsEdit={setIsEdit}
          setIsLoading={setIsLoading}
          setUser={setUser}
          user={user}
        />
      ) : (
        <UserView isEditable={isEditable} isLoading={isLoading} setIsEdit={setIsEdit} setUser={setUser} user={user} />
      )}
    </ChessTemplate>
  );
};

export default User;
