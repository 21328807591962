import React from 'react';
import styled from 'styled-components';

export default function BasketPart(props) {
  const {content} = props;
  return (
    <BasketPartWrapper>
      <BasketPartContainer className="BasketPartContainer">{content}</BasketPartContainer>
    </BasketPartWrapper>
  );
}

const BasketPartWrapper = styled.div`
  flex: 0 0 35%;
  max-width: 35%;
  border-left: 1px solid #dddad4;
  box-sizing: border-box;
  min-height: 600px;
  @media screen and (max-width: 1440px) {
    flex: 0 0 30%;
    max-width: 30%;
  }
`;
const BasketPartContainer = styled.div`
  min-height: 600px;
  height: 100%;
  background-color: white;
  .sticky {
    position: sticky;
    top: 0;
    max-height: 645px;
    overflow: auto;
  }
`;
