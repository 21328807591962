import {generateUserFields} from '@components/apps/PlayerPersonalFile/helpers';
import {useMemo, useState} from 'react';

function usePersonalDataFields() {
  const [isEdit, setIsEdit] = useState(false);
  const fields = useMemo(() => generateUserFields(isEdit), [isEdit]);
  return {isEdit, setIsEdit, fields};
}

export default usePersonalDataFields;
