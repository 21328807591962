import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  .MuiPagination {
    &-root {
      background-color: white;
      border-radius: 0 0 6px 6px;
    }
  }
`;
