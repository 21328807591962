import Goal from '@components/apps/StatisticsWithMkcUral/components/PastGame/components/Goal';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Button, Typography} from '@ui/MUI';
import {cloneDeep} from 'lodash';
import {array} from 'prop-types';
import React, {useMemo, useState} from 'react';

Goals.propTypes = {
  goals: array.isRequired,
};

function Goals({goals}) {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [isToggled, setIsToggled] = useState(false);

  const currentViewGoals = useMemo(() => {
    let result = cloneDeep(goals);
    const finalGoalIndex = goals.findIndex((item) => item?.isFinalGoal);
    if (goals?.length > 3 && !isToggled) {
      result =
        finalGoalIndex <= 2 ? [...result.slice(0, 3)] : [...result.slice(0, 2), {time: '...'}, result[finalGoalIndex]];
    }
    return result;
  }, [goals, isToggled]);

  return goals?.length ? (
    <>
      {currentViewGoals.map((goal, index) => (
        <Goal key={index} {...goal} />
      ))}
      {goals?.length > 3 && (
        <Button
          disableRipple
          endIcon={isToggled ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          size="small"
          sx={{width: 'fit-content', '&:hover': {background: 'transparent'}}}
          variant="text"
          onClick={() => setIsToggled((prevState) => !prevState)}
        >
          {isToggled ? 'Свернуть' : 'Развернуть'}
        </Button>
      )}
    </>
  ) : (
    <Typography variant="caption2">Нет голов</Typography>
  );
}

export default Goals;
