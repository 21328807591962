import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OverallResults = styled.div`
  margin-top: 8px;
  display: flex;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;

export const WrapperTableContainer = styled.div`
  background: white;
  box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
  border-radius: 6px;
  overflow: hidden;
`;

export const AllTestResults = styled.div`
  font-family: 'Proxima Nova', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.17px;
  margin-left: 15px;
  color: rgba(0, 0, 0, 0.6);
  ${({theme}) => ({
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
    },
  })}
`;

export const StatusWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font: normal normal 14px/16px 'Proxima Nova';
`;

export const ValueOverall = styled.p`
  position: relative;
  padding-left: 25px;
  margin-left: ${(props) => props.marginLeft};
  margin-top: 0;
  margin-bottom: 0;

  width: ${(props) => props.width || '60px'};
  height: 17px;
  font-family: 'Proxima Nova', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.87);

  &.Value {
    &:after {
      position: absolute;
      left: ${(props) => props.left || null};
      top: ${(props) => (props.top ? '10px' : '50%')};
      transform: translateY(-50%);
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      content: '';
    }

    &_bad {
      &:after {
        background-color: #ff8787;
      }
    }

    &_normal {
      &:after {
        background-color: #fbe082;
      }
    }

    &_good {
      &:after {
        background-color: #a0dd9e;
      }
    }

    &_empty {
      &:after {
        background-color: #c4c4c4;
      }
    }
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({theme}) => theme.spacing(1)};
  ${({theme}) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const Value = styled.div`
  position: relative;
  padding-right: 8px;

  &.Value {
    &:after {
      position: absolute;
      right: ${(props) => props.right || '8px'};
      top: 50%;
      transform: translateY(-50%);
      display: ${(props) => (props.isLoading ? 'none' : 'block')};
      width: 8px;
      height: 8px;
      border-radius: 50%;
      content: '';
    }

    &_bad {
      padding-right: ${({theme}) => theme.spacing(4)};
      &:after {
        background-color: #ff8787;
      }
    }

    &_normal {
      padding-right: ${({theme}) => theme.spacing(4)};
      &:after {
        background-color: #fbe082;
      }
    }

    &_good {
      padding-right: ${({theme}) => theme.spacing(4)};
      &:after {
        background-color: #a0dd9e;
      }
    }

    &_empty {
      padding-right: ${({theme}) => theme.spacing(4)};
      &:after {
        background-color: #c4c4c4;
      }
    }
  }
`;
