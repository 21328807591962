import React from 'react';

function Comment() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M15.5 6.75C15.5 7.025 15.275 7.25 15 7.25H4C3.725 7.25 3.5 7.025 3.5 6.75C3.5 6.475 3.725 6.25 4 6.25H15C15.275 6.25 15.5 6.475 15.5 6.75ZM11.95 8.75H4C3.725 8.75 3.5 8.975 3.5 9.25C3.5 9.525 3.725 9.75 4 9.75H11.95C12.225 9.75 12.45 9.525 12.45 9.25C12.45 8.975 12.225 8.75 11.95 8.75ZM15 3.75H4C3.725 3.75 3.5 3.975 3.5 4.25C3.5 4.525 3.725 4.75 4 4.75H15C15.275 4.75 15.5 4.525 15.5 4.25C15.5 3.975 15.275 3.75 15 3.75ZM19 0.75V12.75C19 13.025 18.775 13.25 18.5 13.25H8.75L5.35 16.6C5.225 16.75 4.5 16.95 4.5 16.25V13.25H0.5C0.225 13.25 0 13.025 0 12.75V0.75C0 0.475 0.225 0.25 0.5 0.25H18.5C18.775 0.25 19 0.475 19 0.75ZM18 1.25H1V12.25H5C5.275 12.25 5.5 12.475 5.5 12.75V15.05L8.2 12.4C8.3 12.3 8.425 12.25 8.55 12.25H18V1.25Z"
          fill="#747474"
        />
      </g>
    </React.Fragment>
  );
}
export default Comment;
