import {getActions, getActionsTypes, getStatsThrowsStatistics} from '@api/StatsAPI';
import {actionButtonsMock} from '@common/Statistics/ActionsMap/Buttons/mock';
import {defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {actions, metricStatisticsActions, shotsMapActions, shotsMapButtonTypesActions} from '@slices/statisticsMKC';
import {HALF_OPTIONS_WITH_OT, IMPORTANCE_OPTIONS, initialStateIndicators, PLRS_OPTIONS} from '@src/constants';
import {SHOTS_SW_BUTTONS} from '@src/constants/stitictics';
import {keyBy, merge, values} from 'lodash';

const section = 'throws_map';
export const shotsMapStatePaths = {
  shotsMap: {
    name: 'shotsMap',
    path: 'shotsMap.data',
  },
  shotsMapFilters: {
    name: 'shotsMapFilters',
    path: 'shotsMap.filters',
  },
  shotsMapButtonTypes: {
    name: 'shotsMapButtonTypes',
    path: 'shotsMap.buttonTypes',
  },
  metricStatistics: {
    name: 'metricStatistics',
    path: 'shotsMap.metricStatistics',
  },
};

export const shotsInitialState = {
  shotsMap: {
    filters: {
      plrs: [...PLRS_OPTIONS],
      importance: IMPORTANCE_OPTIONS[0],
      half: [...HALF_OPTIONS_WITH_OT],
      actionIds: [],
      playerIds: [],
      statisticsIds: [],
    },
    buttonTypes: {
      ...initialStateIndicators,
    },
    data: {...initialStateIndicators, data: []},
    metricStatistics: {
      ...initialStateIndicators,
      data: {
        shots: {},
        shotsOnGoal: {},
        shotsOnGoalDangerous: {},
        blockedShots: {},
      },
    },
  },
};

export const shotsMapReducer = defaultSliceReducersConstructor(
  shotsMapStatePaths?.shotsMap?.name,
  shotsMapStatePaths?.shotsMap?.path,
  shotsInitialState,
).functions;

export const metricStatisticsReducer = defaultSliceReducersConstructor(
  shotsMapStatePaths.metricStatistics.name,
  shotsMapStatePaths.metricStatistics.path,
  shotsInitialState,
).functions;

export const shotsMapActionsButtons = defaultSliceReducersConstructor(
  shotsMapStatePaths.shotsMapButtonTypes?.name,
  shotsMapStatePaths.shotsMapButtonTypes?.path,
  shotsInitialState,
  {},
  actionButtonsMock,
).functions;

export const loadShotsMap = (params, isCancel) =>
  defaultLoader(
    () => getActions({...params, section}),
    shotsMapActions.loading,
    shotsMapActions.load,
    shotsMapActions.error,
    {isCancel, onCancelAction: shotsMapActions.loading},
  );
export const clearShotsMap = () => (dispatch) => dispatch(shotsMapActions.clear());

export const setShotsMapFilters = (data) => (dispatch) =>
  dispatch(actions.setEntityFilters({...data, entity: shotsMapStatePaths.shotsMap.name}));

export const clearShotsMapFilters = () => (dispatch) =>
  dispatch(actions.clearEntityFilters({entity: shotsMapStatePaths.shotsMap.name}));

export const loadButtonTypes = (params, onSuccess = () => {}) =>
  defaultLoader(
    () => getActionsTypes({...params, section}),
    shotsMapButtonTypesActions.loading,
    ({data}) => {
      onSuccess(data);
      return shotsMapButtonTypesActions.load({
        data: values(merge(keyBy(SHOTS_SW_BUTTONS, 'engNameShort'), keyBy(data, 'engNameShort'))),
      });
    },
    shotsMapButtonTypesActions.error,
  );
export const clearButtonTypes = () => (dispatch) => dispatch(shotsMapButtonTypesActions.clear());

export const loadMetricStatistics = (params) =>
  defaultLoader(
    () => getStatsThrowsStatistics(params),
    metricStatisticsActions.loading,
    ({data}) => metricStatisticsActions.load({data: data?.result || shotsInitialState.shotsMap.metricStatistics.data}),
    metricStatisticsActions.error,
  );
export const clearMetricStatistics = () => (dispatch) => dispatch(metricStatisticsActions.clear());
