import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {emptyBoard} from '../../../helpers/MedicalProfileHelper';
import {
  cleanMedicalProfileUserData,
  getMedicalProfileUserData,
  handleMedicalProfileBoard,
} from '../../../redux/actions/MedicalProfileActions';
import MedicalProfileBoard from '../tools/MedicalProfileBoard';
import MedicalProfileFilter from '../tools/MedicalProfileFilter';

export default function MedicalProfilePanel() {
  const [filter, setFilter] = useState({});

  const dispatch = useDispatch();

  const getUserData = () => {
    if (filter.school) {
      const requestBody = () => {
        let result = {};
        for (let key in Object.keys(filter)) {
          if (filter[Object.keys(filter)[key]] !== '') {
            result[Object.keys(filter)[key]] = filter[Object.keys(filter)[key]];
          }
        }
        return result;
      };
      dispatch(getMedicalProfileUserData(requestBody()));
      dispatch(handleMedicalProfileBoard(emptyBoard()));
    }
  };

  const updateUserData = () => {
    getUserData();
  };

  useEffect(() => {
    dispatch(cleanMedicalProfileUserData());
  }, []);

  useEffect(() => {
    getUserData();
  }, [filter.playerId, filter.school, filter.group, filter.type]);
  return (
    <>
      <PageTitleDefault>Рабочий стол врача</PageTitleDefault>
      <MedicalProfileFilter
        updateUserData={updateUserData}
        onChange={(filter) => {
          setFilter({
            ...filter,
          });
        }}
      />
      <MedicalProfileBoard updateUserData={updateUserData} />
    </>
  );
}
