// использование
// <Typography color={grey[14]}>крутой цвет!</Typography>
export const grey = {
  4: '#F5F5F5',
  8: '#ebebeb',
  12: '#E0E0E0',
  14: '#DBDBDB',
  24: '#C2C2C2',
  26: '#BDBDBD',
  38: '#9E9E9E',
  42: '#949494',
  48: '#0000007A',
  54: '#757575',
  68: '#525252',
  78: '#000000C7',
  85: '#000000D9',
  100: '#2B2B2B',
};

export const green = {
  100: '#4BA849',
  70: '#81C280',
  60: '#93CB92',
  15: '#E4F2E4',
};

export const darkRed = {
  100: '#C05B54',
  65: '#D99493',
  20: '#F6E7E6',
};

export const yellow = {
  100: '#FFCD4D',
  60: '#FFE194',
  20: '#FFF8E5',
};

export const blue = {
  100: '#1976D2',
  60: '#89A9D9',
  15: '#DDEBF8',
};

export const purple = {
  100: '#AB60BE',
  50: '#D6B1DF',
  15: '#F3E7F5',
};

export const olive = {
  100: '#97896B',
  85: '#887755DE',
};

export const orange = {
  20: '#E7CDB7',
  60: '#DD9F6D',
  100: '#EF6C00',
};

export const bg = {
  yellow: '#FFFAED',
};

export const primary = {
  main: '#B63830',
  dark: '#8C1A1A',
  contrastText: '#FFFFFF',
  light: '#CC746F',
  50: 'rgba(182, 56, 48, 0.50)',
  10: '#F2D8D7',
};
export const secondary = {
  main: '#E0E0E0',
  dark: '#9E9E9E',
  contrastText: '#2C2C2C',
  light: '#EBEBEB',
};
