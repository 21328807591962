import {getTrainingSchedule} from '@api/TrainingControlProcess';
import date from '@helpers/date';
import {useSeasons} from '@hooks/seasonsHooks';
import useStore from '@hooks/useStore';
import {userRoles} from '@src/constants';
import React, {useEffect, useMemo, useState} from 'react';

import LoadingHolder from '../LoadingHolder';
import Calendar from './components/Calendar';
import Filters from './components/Filters';

const TrainingPlan = () => {
  const month = date().startOf('month');
  const [schedule, setSchedule] = useState({
    isLoading: false,
    data: [],
  });

  const [filters, setFilters] = useState({
    school: null,
    group: null,
    season: null,
    month: {label: month.format('MMMM'), value: month.valueOf()},
  });

  const currentUser = useStore((state) => state.users.currentUser);
  const userRole = useStore((state) => state?.users?.currentUser?.userRole);
  const isStudent = userRole === userRoles.student;

  const currentSeason = useSeasons()?.currentSeason;

  useEffect(() => {
    if (currentSeason?.value) {
      setFilters({...filters, season: currentSeason});
    }
  }, [currentSeason?.value]);

  useEffect(() => {
    setSchedule({...schedule, data: [], isLoading: true});
    if (filters?.season?.value) {
      getTrainingSchedule({
        season: filters?.season?.value,
        month: date(filters?.month?.value).format('M'),
        school: !isStudent ? filters?.school?.value : currentUser?.schoolId,
        group: !isStudent ? filters?.group?.value : currentUser?.groups?.[0]?.objectId,
      }).then((data) => {
        setSchedule({data, isLoading: false});
      });
    }
  }, [filters]);

  const component = useMemo(() => {
    return schedule.isLoading ? <LoadingHolder /> : <Calendar events={schedule.data} />;
  }, [schedule.isLoading]);

  return (
    <div>
      <Filters filters={filters} updateFilters={setFilters} />
      {component}
    </div>
  );
};

export default TrainingPlan;
