import TrainingItem from '@common/TrainingControl/TrainingItem';
import dateInterface from '@helpers/date';
import useStore from '@hooks/useStore';
import React, {useMemo} from 'react';
import {useWatch} from 'react-hook-form';

const Preview = () => {
  const form = useWatch();
  const {dates, author, startTime, endTime, date, ...rest} = form;
  const user = useStore((state) => state.users.currentUser);
  const {
    dates: formatedDates,
    startTime: formatedStartTime,
    endTime: formatedEndTime,
    date: formatedDate,
  } = useMemo(() => {
    if (dates.length) {
      if (dates.length > 1) {
        return {dates};
      }

      const {startTime, endTime, date} = dates[0];
      return {
        startTime: dateInterface(startTime).format('HH:mm'),
        endTime: dateInterface(endTime).format('HH:mm'),
        date: typeof date === 'string' ? dateInterface(date, 'DD.MM.YY').valueOf() : date,
      };
    }

    return {startTime, endTime, date};
  }, [dates, startTime, endTime, date]);

  return (
    <div>
      <TrainingItem
        {...rest}
        author={author || {name: `${user.lastName} ${user.firstName}`, userRole: user.userRole}}
        date={formatedDate}
        dates={formatedDates}
        endTime={formatedEndTime}
        startTime={formatedStartTime}
      />
    </div>
  );
};

export default Preview;
