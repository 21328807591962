export const mock = [...Array(15)].fill({
  playerId: 25960075,
  filePlayerInfo: {
    firstName: 'Тимофей',
    lastName: 'Давыдов',
    playerNumber: 75,
  },
  playerInfo: {
    firstName: 'Тимофей',
    lastName: 'Давыдов',
    playerNumber: 75,
  },
});
