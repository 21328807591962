import Box from '@ui/MUI/Box';
import TextFieldDefault from '@ui/MUI/TextField';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

EditableCellComponent.propTypes = {
  getValue: PropTypes.func.isRequired,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
  }),
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  table: PropTypes.object.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  EditComponent: PropTypes.node,
  InitialComponent: PropTypes.node,
  isActive: PropTypes.bool,
  setIsActive: PropTypes.func,
};

function EditableCellComponent({
  getValue,
  row: {index},
  column: {id},
  table,
  EditComponent = TextFieldDefault,
  InitialComponent = Box,
  ...props
}) {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const [isActive, setIsActive] = useState(false);

  const onEdit = () => {
    table?.options?.meta?.updateData?.(index, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  function setActiveState(state) {
    props?.setIsActive ? props.setIsActive(state) : setIsActive(state);
  }
  return (
    <Box
      data-testid={props?.['data-testid'] || 'editable-cell'}
      onClick={(e) => {
        e.stopPropagation();
        setActiveState(true);
      }}
    >
      {(props.isActive || props.setIsActive ? props.isActive : isActive) ? (
        <EditComponent
          {...props}
          value={value}
          onChange={(v) => {
            props?.onChange ? props.onChange(setValue) : setValue(v);
          }}
          onEdit={onEdit}
        />
      ) : (
        <InitialComponent {...props}>{value}</InitialComponent>
      )}
    </Box>
  );
}

export default EditableCellComponent;
