import {getPhysicalTestingCompare} from '@api/newPhysicalTestingAPI';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  compareInfo: {
    items: [],
    isLoading: false,
    isLoad: false,
    error: '',
  },
};

const physicalTestingCompare = createSlice({
  name: 'physicalTestingCompare',
  initialState,
  reducers: {
    physicalTestingCompareLoading: ({compareInfo}) => {
      compareInfo.isLoading = true;
    },
    physicalTestingCompareLoad: ({compareInfo}, action) => {
      compareInfo.isLoading = false;
      compareInfo.isLoad = true;
      compareInfo.items = action.payload;
    },
    physicalTestingCompareError: ({compareInfo}) => {
      compareInfo.isLoading = false;
      compareInfo.isLoad = true;
      compareInfo.error = 'Ошибка при загрузке данных';
    },
    physicalTestingCompareClear: ({compareInfo}) => {
      compareInfo.items = [];
    },
  },
});

const {actions, reducer} = physicalTestingCompare;

const {
  physicalTestingCompareLoading,
  physicalTestingCompareLoad,
  physicalTestingCompareError,
  physicalTestingCompareClear,
} = actions;

export {reducer as physicalTestingCompareReducer};

export const fetchCompareData = (data) => async (dispatch) => {
  dispatch(physicalTestingCompareLoading());
  dispatch(physicalTestingCompareClear());
  const physicalTestingData = await getPhysicalTestingCompare(data)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      dispatch(physicalTestingCompareError({error}));
    });
  dispatch(physicalTestingCompareLoad(physicalTestingData));
};

export const clearPhysicalCompareState = () => (dispatch) => dispatch(physicalTestingCompareClear());
