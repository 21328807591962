import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from 'styled-components';

import {BROWN, DARK_SIDE} from './Colors';
/**@deprecated не использовать этот компонент**/
export const Button = styled.button`
  position: relative;
  width: ${({width}) => (width ? `${width}%` : 'auto')};
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  cursor: ${(props) => (props.disabled === true ? 'not-allowed' : 'pointer')};
  background: ${(props) => (props.disabled === true ? 'lightgrey' : DARK_SIDE)};
  border: none;
  display: inline-block;
  vertical-align: top;
  :focus {
    outline: none;
  }
  :hover {
    opacity: ${(props) => (props.disabled === true ? 1 : 0.8)};
  }
  font-family: 'Proxima Nova';
  font-size: 14px;
  color: #fff;
  background: ${({background}) => (background ? `${background}` : '#EE1122')};
  border-radius: 30px;
  height: 30px;
  line-height: 30px;

  &.ButtonWithIcon {
    padding-left: 45px;
    padding-right: 20px;
  }
`;
export const MediumButton = styled(Button)`
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
`;
export const DeleteButton = styled(Button)`
  background-color: transparent;
  border: 1px solid #ee1122;
  color: #ee1122;
`;
export const CloseButton = styled(MediumButton)`
  width: 95px;
  height: 30px;
  background-color: white;
  border: 1px solid #ee1122;
  color: #ee1122;
`;
export const LightButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({width}) => (width ? `${width}%` : 'auto')};
  border: 1px solid #e5e5e5;
  color: #808080;
  background: none;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
  font-size: 10px;
  min-height: 38px;
  :hover {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    padding: 15px 25px;
    font-size: 20px;
  }
  > svg {
    margin-right: 5px;
  }

  &.ButtonWithIcon {
    padding-left: 45px;
    padding-right: 20px;

    svg {
      path {
        fill: #c4c4c4;
      }
    }
  }
`;

export const OutlinedButton = styled.div`
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: ${(props) => (props.disabled === true ? 'default' : 'pointer')};
  background: transparent;
  color: ${BROWN};
  border: 1px solid ${BROWN};
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  margin: 4px;
  :hover {
    opacity: 0.8;
  }
`;

export const DangerButton = ({
  onClick = () => {},
  placeholder = 'Вы уверены?',
  width = null,
  loading = false,
  children = null,
}) => {
  const [isAsking, setIsAsking] = useState(false);

  const handleDelete = () => {
    setIsAsking(true);
  };

  const handleDecline = () => {
    setIsAsking(false);
  };

  const handleConfirm = () => {
    onClick();
  };

  if (isAsking === true) {
    return (
      <AskingPlaceholder>
        <Placeholder>{placeholder}</Placeholder>
        <div>
          <AskSpan style={{fontWeight: 'bold'}} onClick={handleConfirm}>
            Да
          </AskSpan>{' '}
          <AskSpan onClick={handleDecline}>Нет</AskSpan>
        </div>
      </AskingPlaceholder>
    );
  }

  return (
    <DangerButtonStyle width={width} onClick={handleDelete}>
      {loading && <CircularProgress color="secondary" size={12} />}
      {loading === true ? null : children}
    </DangerButtonStyle>
  );
};

DangerButton.propTypes = {
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

DangerButton.defaultProps = {
  onClick: () => {},
  placeholder: 'Вы уверены?',
  width: null,
  loading: false,
  children: null,
};

const DangerButtonStyle = styled.div`
  width: ${({width}) => (width ? `${width}%` : 'auto')};
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 30px;
  cursor: ${({disabled}) => (disabled === true ? 'default' : 'pointer')};
  display: inline-block;
  vertical-align: top;
  text-align: center;
  border: 1px solid #ee1122;
  :hover {
    opacity: 0.8;
  }

  font-family: 'Proxima Nova';
  font-size: 16px;
  color: #ee1122;

  height: 40px;
  line-height: 40px;
`;

const AskingPlaceholder = styled.div`
  color: black;
`;

const AskSpan = styled.span`
  cursor: pointer;
  color: black;
  margin-right: 10px;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

const Placeholder = styled.div`
  color: black;
  font-style: italic;
  font-size: 16px;
`;
