import {ExpandMore} from '@mui/icons-material';
import {Accordion as MuiAccordion, AccordionDetails, AccordionSummary} from '@mui/material';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';
import styled from 'styled-components';

const StyledAccordion = styled(MuiAccordion)`
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  & .MuiAccordionSummary-root {
    background-color: #0000000f;
    padding: 16px 20px;
    min-height: 0px;
    & .MuiAccordionSummary-content {
      margin: 0;
    }
  }
  & .MuiAccordionDetails-root {
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    /* background-color: #fff; */
  }

  & .Mui-expanded {
    margin: 0px;
  }
`;

const Accordion = ({title, children}) => {
  return (
    <StyledAccordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <TypographyDefault variant="h4">{title}</TypographyDefault>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

export default Accordion;
