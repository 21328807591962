import React from 'react';

function HockeyPlayer() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M5.08014 0C3.82507 0 2.80347 1.0243 2.80347 2.28009C2.80347 3.54466 3.82546 4.56364 5.08014 4.56364C6.34244 4.56364 7.37088 3.5447 7.37088 2.28009C7.37088 1.0243 6.3428 0 5.08014 0Z"
          fill="#686868"
        />
        <path
          d="M1.32979 17.9864C1.13704 18.3302 1.3218 18.6092 1.74155 18.6092H3.00915C3.42929 18.6092 3.76956 18.3305 3.76956 17.9864C3.76956 17.9826 3.76841 17.9788 3.76805 17.9754L6.36678 12.5898C6.61464 12.5894 6.86295 12.4925 7.04886 12.3001L9.60083 9.66185C9.62099 9.64129 9.63618 9.61775 9.65407 9.59533L10.6483 10.5436L7.41613 13.4761C7.18039 13.6898 7.04469 13.9924 7.04203 14.3107C7.03936 14.6289 7.16978 14.9342 7.40134 15.152L12.4185 19.8666C12.6387 20.0734 12.9193 20.176 13.1995 20.176C13.5036 20.176 13.8067 20.0555 14.0314 19.8168C14.463 19.3574 14.4401 18.6355 13.9812 18.2043L9.86393 14.3357L11.8254 12.556L12.5444 14.8368C12.6075 15.0371 12.7254 15.2162 12.8839 15.3542L17.8578 19.6791C18.333 20.0924 19.0539 20.0422 19.4668 19.5669C19.8801 19.0913 19.8299 18.3712 19.3547 17.9579L14.6234 13.8441L14.0648 12.0723V8.54172C14.0648 7.91208 13.6747 7.07143 13.1934 6.665L9.53734 3.13859C9.37082 2.99375 9.156 2.90361 8.91799 2.90361C8.39292 2.90361 7.96748 3.32908 7.96748 3.85412V8.61659L5.68282 10.9784C5.33035 11.3426 5.33114 11.9149 5.67105 12.2825L3.20727 17.3884C3.14377 17.3743 3.07801 17.3644 3.00883 17.3644H2.43851C2.01912 17.364 1.52255 17.6427 1.32979 17.9864Z"
          fill="#686868"
        />
        <path
          d="M2.80352 21C3.25875 21 3.62779 20.631 3.62779 20.1757C3.62779 19.7205 3.25875 19.3515 2.80352 19.3515C2.34829 19.3515 1.97925 19.7205 1.97925 20.1757C1.97925 20.631 2.34829 21 2.80352 21Z"
          fill="#686868"
        />
      </g>
    </React.Fragment>
  );
}
export default HockeyPlayer;
