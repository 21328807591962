import ReactTableComponent from '@common/ReactTableComponent';
import {mock} from '@common/Statistics/PlayerStatistics/VideoModal/PlayersTable/mock';
import ToggledHeader from '@common/ToggledHeader';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import {Theme, useMediaQuery} from '@mui/material';
import {longDash} from '@src/constants';
import {PlayerOnIceDto} from '@src/types/generated';
import {Box, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React, {useState} from 'react';

type PlayersTableProps = {
  playersOnIce: Array<PlayerOnIceDto>;
  isLoading: boolean;
};
function PlayersTable({playersOnIce = [], isLoading = false}: PlayersTableProps) {
  const [isToggled, setToggleUser] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return playersOnIce?.length || isLoading ? (
    <Box sx={{overflow: 'hidden', border: `1px solid ${grey['12']}`, borderBottom: 'none', borderRadius: '6px'}}>
      <ReactTableComponent
        columns={[
          {
            id: 'player',
            header: (
              <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
                Игрок
              </ToggledHeader>
            ),
            headerStyle: {
              width: !isToggled ? 280 : 80,
              maxWidth: !isToggled ? 280 : 80,
            },
            accessorFn: (data) => data,
            cell: ({getValue}) => {
              const value = getValue();
              return (
                <Stack alignItems="center" direction="row">
                  <PlayersHorizontalCard
                    hasImage={!isToggled}
                    isLoading={isLoading}
                    isShortName={isMobile}
                    onlyNumber={isToggled}
                    student={value}
                  />
                </Stack>
              );
            },
            cellStyle: {width: !isToggled ? 220 : 80, maxWidth: !isToggled ? 220 : 80},
            sticky: {left: true},
          },
          {
            accessorKey: 'shiftDuration',
            header: 'Длина смены',
            cell: ({getValue}) => (
              <Typography isLoading={isLoading} preloaderWidthBetween={[100, 120]} variant="body2">
                {getValue() || longDash}
              </Typography>
            ),
          },
          {
            accessorKey: 'timeOnIce',
            header: 'Время события',
            cell: ({getValue}) => (
              <Typography isLoading={isLoading} preloaderWidthBetween={[100, 120]} variant="body2">
                {getValue() || longDash}
              </Typography>
            ),
          },
        ]}
        data={isLoading ? mock : playersOnIce}
      />
    </Box>
  ) : (
    <Typography variant="body2">Игроки отсутствовали</Typography>
  );
}

export default PlayersTable;
