import styled from 'styled-components';

const Grid = styled.div`
  display: flex;
  z-index: ${(props) => props.zIndex};
  flex-direction: ${(props) => props.direction};
  flex: ${(props) => props.flexSize};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  width: ${(props) => props.width};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex-wrap: ${(props) => props.wrap && 'wrap'};
  // margins
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};

  //paddings
  padding: ${(props) => props.padding};
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: ${(props) => props.paddingBottom};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};

  text-align: ${(props) => (props.justify === 'flex-end' ? 'right' : 'initial')};
`;

export default Grid;
