import {
  getIllnessPlayers,
  getInjuredPlayers,
  getMissedPlayers,
  getNextGames,
  getPastGames,
  getSchedule,
} from '@api/managerDashboardAPI';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  todaySchedule: {
    isLoading: false,
    isLoad: false,
    data: [],
    error: '',
  },
  nextGames: {
    isLoading: false,
    isLoad: false,
    data: [],
    error: '',
  },
  missedPlayers: {
    isLoading: false,
    isLoad: false,
    data: {notEnough: false, players: []},
    error: '',
  },
  pastGames: {
    isLoading: false,
    isLoad: false,
    data: [],
    error: '',
  },
  injuredPlayers: {
    isLoading: false,
    isLoad: false,
    data: {notEnough: false, players: []},
    error: '',
  },
  illnessPlayers: {
    isLoading: false,
    isLoad: false,
    data: {notEnough: false, players: []},
    error: '',
  },
};

//reducers
const userDashboard = createSlice({
  name: 'studentDashboard',
  initialState,
  reducers: {
    clearData: () => initialState,
    todayScheduleLoading: ({todaySchedule}) => {
      todaySchedule.isLoading = true;
    },
    todayScheduleLoad: ({todaySchedule}, action) => {
      todaySchedule.isLoading = false;
      todaySchedule.isLoad = true;
      todaySchedule.data = action.payload.data;
    },
    todayScheduleError: ({todaySchedule}) => {
      todaySchedule.isLoading = false;
      todaySchedule.isLoad = true;
      todaySchedule.error = 'Ошибка при загрузке данных расписания';
    },
    nextGamesLoading: ({nextGames}) => {
      nextGames.isLoading = true;
    },
    nextGamesLoad: ({nextGames}, action) => {
      nextGames.isLoading = false;
      nextGames.isLoad = true;
      nextGames.data = action.payload.data;
    },
    nextGamesError: ({nextGames}) => {
      nextGames.isLoading = false;
      nextGames.isLoad = true;
      nextGames.error = 'Ошибка при загрузке данных расписания';
    },
    missedPlayersLoading: ({missedPlayers}) => {
      missedPlayers.isLoading = true;
    },
    missedPlayersLoad: ({missedPlayers}, action) => {
      missedPlayers.isLoading = false;
      missedPlayers.isLoad = true;
      missedPlayers.data = action.payload.data;
    },
    missedPlayersError: ({missedPlayers}) => {
      missedPlayers.isLoading = false;
      missedPlayers.isLoad = true;
      missedPlayers.error = 'Ошибка при загрузке данных расписания';
    },
    pastGamesLoading: ({pastGames}) => {
      pastGames.isLoading = true;
    },
    pastGamesLoad: ({pastGames}, action) => {
      pastGames.isLoading = false;
      pastGames.isLoad = true;
      pastGames.data = action.payload.data;
    },
    pastGamesError: ({pastGames}) => {
      pastGames.isLoading = false;
      pastGames.isLoad = true;
      pastGames.error = 'Ошибка при загрузке данных расписания';
    },
    injuredPlayersLoading: ({injuredPlayers}) => {
      injuredPlayers.isLoading = true;
    },
    injuredPlayersLoad: ({injuredPlayers}, action) => {
      injuredPlayers.isLoading = false;
      injuredPlayers.isLoad = true;
      injuredPlayers.data = action.payload.data;
    },
    injuredPlayersError: ({injuredPlayers}) => {
      injuredPlayers.isLoading = false;
      injuredPlayers.isLoad = true;
      injuredPlayers.error = 'Ошибка при загрузке данных расписания';
    },
    illnessPlayersLoading: ({illnessPlayers}) => {
      illnessPlayers.isLoading = true;
    },
    illnessPlayersLoad: ({illnessPlayers}, action) => {
      illnessPlayers.isLoading = false;
      illnessPlayers.isLoad = true;
      illnessPlayers.data = action.payload.data;
    },
    illnessPlayersError: ({illnessPlayers}) => {
      illnessPlayers.isLoading = false;
      illnessPlayers.isLoad = true;
      illnessPlayers.error = 'Ошибка при загрузке данных расписания';
    },
  },
});

const {actions, reducer} = userDashboard;

const {
  clearData,
  todayScheduleLoading,
  todayScheduleLoad,
  todayScheduleError,
  nextGamesLoading,
  nextGamesLoad,
  nextGamesError,
  missedPlayersLoading,
  missedPlayersLoad,
  missedPlayersError,
  pastGamesLoading,
  pastGamesLoad,
  pastGamesError,
  injuredPlayersLoading,
  injuredPlayersLoad,
  injuredPlayersError,
  illnessPlayersLoading,
  illnessPlayersLoad,
  illnessPlayersError,
} = actions;

export {reducer as managerDashboardReducer};

//fetch fns
function loadData(fetchAPI, loadingAction, loadAction, errorAction) {
  return (dispatch) => {
    dispatch(loadingAction());
    return fetchAPI()
      .then((data) => dispatch(loadAction({data: data.data})))
      .catch((error) => {
        dispatch(errorAction({error}));
        dispatch(loadAction({data: []}));
      });
  };
}
export const clearDashboardData = () => (dispatch) => dispatch(clearData());

export const loadTodaySchedule = (params) =>
  loadData(() => getSchedule(params), todayScheduleLoading, todayScheduleLoad, todayScheduleError);

export const loadNextGames = () => loadData(getNextGames, nextGamesLoading, nextGamesLoad, nextGamesError);

export const loadMissedPlayers = (params) =>
  loadData(() => getMissedPlayers(params), missedPlayersLoading, missedPlayersLoad, missedPlayersError);

export const loadPastGames = (params) =>
  loadData(() => getPastGames(params), pastGamesLoading, pastGamesLoad, pastGamesError);

export const loadInjuredPlayers = (params) =>
  loadData(() => getInjuredPlayers(params), injuredPlayersLoading, injuredPlayersLoad, injuredPlayersError);

export const loadillnessPlayers = (params) =>
  loadData(() => getIllnessPlayers(params), illnessPlayersLoading, illnessPlayersLoad, illnessPlayersError);
