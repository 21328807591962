import EmojiIcon from '@components/apps/StudentDashboard/components/EmojiIcon';
import {updateDiaryEntityDayOff} from '@components/apps/StudentDashboard/helpers';
import useStoreDispatch from '@hooks/useStoreDispatch';
import CircleIcon from '@mui/icons-material/Circle';
import {Button, Chip, Stack, Typography} from '@ui/MUI';
import CircularProgress from '@ui/MUI/CircularProgress';
import {grey} from '@ui/MUI/colors';
import {isNull} from 'lodash';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

GameTypeSection.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onFillClick: PropTypes.func.isRequired,
  entity: PropTypes.string.isRequired,
  diary: PropTypes.object.isRequired,
  nullCheckValue: PropTypes.string.isRequired,
};

function GameTypeSection({icon, name, onFillClick, entity, diary = {}, nullCheckValue}) {
  const {data, ...restDiary} = diary;
  const dispatch = useStoreDispatch();
  const isLoading = useMemo(() => {
    return restDiary?.isLoading || data?.[entity]?.isLoading;
  }, [restDiary?.isLoading, data?.[entity]?.isLoading]);
  const isDayOff = useMemo(
    () => data?.[entity]?.isDayOff || data?.morning?.isDayOff,
    [data?.[entity]?.isDayOff, data?.morning?.isDayOff],
  );

  const controlButtons = useMemo(() => {
    if (isLoading) {
      return <CircularProgress color="primary" size={20} />;
    }
    if (isDayOff) {
      return (
        <Chip
          data-testid="fill-skip-indicator"
          icon={<CircleIcon fontSize="inherit" sx={(theme) => ({fill: theme.palette.info.main})} />}
          label="Не было"
          variant="outlined"
        />
      );
    }
    if (!data?.[entity] || isNull(data?.[entity]?.[nullCheckValue])) {
      return (
        <>
          <Button color="primary" data-testid="fill-button" variant="contained" onClick={onFillClick}>
            заполнить
          </Button>
          <Button
            color="secondary"
            data-testid="skip-button"
            variant="contained"
            onClick={() => {
              updateDiaryEntityDayOff(data, true, entity, dispatch);
            }}
          >
            не было
          </Button>
        </>
      );
    }
    return (
      <Chip
        data-testid="filled-state-indicator"
        icon={<CircleIcon fontSize="inherit" sx={(theme) => ({fill: theme.palette.success.main})} />}
        label="Заполнено"
        variant="outlined"
      />
    );
  }, [isDayOff, isDayOff, isLoading, onFillClick, nullCheckValue]);

  return (
    <Stack
      alignItems={{xxs: 'stretch', mobileM: 'center'}}
      border={`1px solid ${grey['12']}`}
      borderRadius="6px"
      direction={{xxs: 'column', xs: 'row'}}
      justifyContent="space-between"
      p={3}
      spacing={2}
    >
      <Stack alignItems="center" direction="flex-start" gap={1}>
        <EmojiIcon name={icon} />
        <Typography variant="subtitle1">{name}</Typography>
      </Stack>
      <Stack
        alignItems={{xxs: 'stretch', mobileM: 'center'}}
        direction={{xxs: 'column', mobileM: 'row'}}
        spacing={{xxs: 2, sm: 4}}
      >
        {controlButtons}
      </Stack>
    </Stack>
  );
}

export default GameTypeSection;
