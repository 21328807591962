import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {handleChangeForm} from '../../../redux/actions/PsychoTestingActions';
import InputDefault from '../../ui/Input';

export default function PsychoTestFormItem(props) {
  const {skill = {}, skillIndex = 0} = props;
  const testingForm = useSelector((state) => state.psychoTesting.testingForm);
  const dispatch = useDispatch();

  const handleValueChange = (content) => {
    const parameters = testingForm?.parameters?.length ? testingForm.parameters : [];

    dispatch(
      handleChangeForm({
        ...testingForm,
        parameters: parameters.map((item, index) => {
          if (index === skillIndex) {
            return {...item, value: content};
          }
          return item;
        }),
      }),
    );
  };

  return (
    <TableRow>
      <TableColumn>
        <SkillName>{skill.label}</SkillName>
      </TableColumn>
      {skill.value === null ? null : (
        <TableColumn>
          <EstimationValue>{skill.value}</EstimationValue>
        </TableColumn>
      )}
      <EditorContainer>
        {skill.key === 'thinking' ? (
          <>
            <PlanLabel>Оцените показатель</PlanLabel>
            <InputDefault
              max={10}
              min={1}
              placeholder={'Оценка'}
              type={'number'}
              value={skill.value !== null ? skill.value : ''}
              onChange={(a) => {
                let value = a.target.value;
                if ((value < 1 || value > 10) && value) return;
                handleValueChange(value);
              }}
            />
          </>
        ) : null}
      </EditorContainer>
    </TableRow>
  );
}

const TableColumn = styled.div`
  box-sizing: border-box;
  padding-left: 34px;
  padding-right: 30px;
  &:first-child {
    flex: 0 0 30%;
    max-width: 30%;
    @media (min-width: 1025px) and (max-width: 1200px) {
      flex: 0 0 40%;
      max-width: 40%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media screen and (max-width: 480px) {
      flex: 0 0 70%;
      max-width: 70%;
    }
  }
  &:nth-child(2) {
    flex: 0 0 70%;
    max-width: 70%;
    padding-left: 0;
    @media (min-width: 1025px) and (max-width: 1200px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      flex: 0 0 40%;
      max-width: 40%;
      text-align: center;
    }
    @media screen and (max-width: 480px) {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }
`;
const TableRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-right: -30px;
  margin-left: -30px;
  padding: 18px 0 34px;
  background-color: #fafafa;

  :nth-child(even) {
    background-color: white;
  }
`;
const SkillName = styled.p`
  margin-top: 0;
  margin-bottom: 2px;
  font: bold normal 16px/1 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;

  @media screen and (max-width: 576px) {
    margin-bottom: 8px;
  }
`;
const EstimationValue = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  width: 36px;
  height: 36px;
  background: #f5dcb2;
  border-radius: 2px;
  font: bold normal 12px/36px 'Proxima Nova';
  color: #2c2c2c;
  text-align: center;
`;
const EditorContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 10px;
  flex: 0 0 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
  padding-left: 34px;
`;

const PlanLabel = styled.label`
  display: block;
  margin-top: 9px;
  margin-bottom: 9px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;

  & + input {
    width: 100px;
    margin-top: 6px;
    margin-bottom: 8px;
    font: normal normal 14px/25px 'Proxima Nova';
    color: #2c2c2c;

    &::placeholder {
      font: normal normal 14px/25px 'Proxima Nova';
      color: #2c2c2c;
    }
  }
  & + .SelectContainer {
    margin-top: 9px;
    margin-bottom: 12px;
  }
`;
