import Notification from 'rc-notification';
import React from 'react';
import styled from 'styled-components';

import Icon from '../../../ui/Icon/Icon';

const NotificationTypeDefaultTitle = (type) => {
  switch (type) {
    case 'Success':
      return 'Успех';
    case 'Error':
      return 'Ошибка';
    case 'Warning':
      return 'Предупреждение';
    case 'Info':
      return 'Информация';
    default:
      return '';
  }
};

export const ReactComponentNotification = (type, text, title) => {
  Notification.newInstance({}, (notification) => {
    notification.notice({
      content: (
        <NotificationContent className={`NotificationContent NotificationContent_${type}`}>
          <NotificationContainer>
            <Icon height={16} type={`Notification${type}`} viewBox={'0 0 16 16'} width={16} />
            <NotificationTitle>{title ? title : NotificationTypeDefaultTitle(type)}</NotificationTitle>
            <NotificationText>{text}</NotificationText>
          </NotificationContainer>
        </NotificationContent>
      ),
      onClose: () => {
        notification.destroy();
      },
      duration: 4,
      closable: true,
    });
  });
};

const NotificationContent = styled.div`
  position: relative;
  min-height: 48px;
  height: 100%;
  border-left: 3px solid transparent;
   &.NotificationContent {
       border-radius: 6px;
    &_Success {
      background-color: #E1FFD2;
      
      h2 {
      color: #628E5E
      }
    }
    &_Error {
      background-color: #FFE0E0;
      
      h2 {
      color: #990011
      }
    }
    &_Warning {
      background-color: #FFF4E5;
      
      h2 {
      color: 
      }
    }
    &_Info {
      background-color: #D9F1FF;
      
      h2 {
      color: #004360
      }
    }

  
  svg {
    position: absolute;    
    top: 20px;
    left: 13px;
    transform: translateY(-50%);
  }
`;
const NotificationContainer = styled.div`
  padding: 8px 15px 8px 32px;
  min-height: 48px;
`;
const NotificationTitle = styled.h2`
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 1px;
  margin-bottom: 0px;
`;
const NotificationText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #2c2c2c;
  line-height: 22px;
`;
