import LocalPreloader from '@components/preloader/LocalPreloader';
import {useMediaQuery} from '@mui/material';
import {GAME_TYPES, longDash} from '@src/constants';
import Box from '@ui/MUI/Box';
import Link from '@ui/MUI/Link';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import moment from 'moment';
import React from 'react';
import {useSelector} from 'react-redux';

import {BoxShadow} from '../../ManagerDashboard';
import Card from '../Card';
import NoData from '../NoData';

const headlines = [
  {
    title: 'Дата и время',
    width: {xxs: '100%', sm: '15%'},
    display: {xxs: 'none', sm: 'block'},
  },
  {title: 'Команды', width: {xxs: '60%', sm: '25%'}, order: {xxs: 2, sm: ''}},
  {
    title: 'Тип мероприятия',
    width: {xxs: '100%', sm: '20%'},
    display: {xxs: 'none', sm: 'block'},
  },
  {
    title: 'Помещение',
    width: {xxs: '100%', sm: '15%'},
    display: {xxs: 'none', sm: 'block'},
  },
  {title: 'Действие', width: {xxs: '40%', sm: '25%'}, textAlign: 'right'},
];
function Wrapper({hasWrapper, children}) {
  return hasWrapper ? (
    <Stack direction="column" width="60%">
      {children}
    </Stack>
  ) : (
    <>{children}</>
  );
}
function NextGames() {
  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {data, isLoad, isLoading, error} = useSelector((state) => state.managerDashboard.nextGames);
  return (
    <Card height={isLoading && '250px'} title="ближайшая игра">
      <LocalPreloader visible={isLoading} />
      {(isLoad && !data?.length) || error ? (
        <NoData>На сегодня игр больше нет</NoData>
      ) : (
        <>
          <BoxShadow>
            <Box p={3} pb={0} pt={0}>
              <Stack direction="row" gap={1} justifyContent="space-between">
                {headlines.map((item, index) => (
                  <Box display={item.display} key={index} width={item.width}>
                    <TypographyDefault textAlign={item.textAlign} variant="body2">
                      {item.title}
                    </TypographyDefault>
                  </Box>
                ))}
              </Stack>
            </Box>
          </BoxShadow>
          <Box>
            {data?.map(
              (
                {date, timeStart, timeEnd, homeTeamName, awayTeamName, type, room, broadcastLink, schoolId, id},
                index,
              ) => (
                <Stack
                  alignItems="center"
                  borderBottom="1px solid #DDDAD4"
                  direction="row"
                  gap={1}
                  justifyContent="space-between"
                  key={index}
                  p={3}
                  pb={2}
                  pt={2}
                >
                  <Wrapper hasWrapper={isSmResolution}>
                    <Box overflow="hidden" width={headlines[0].width}>
                      <TypographyDefault fontWeight="600" variant="body2">
                        {`${moment(date).format('DD.MM.YYYY')} ${moment(timeStart).format('HH:mm')}-${moment(
                          timeEnd,
                        ).format('HH:mm')}`}
                      </TypographyDefault>
                    </Box>
                    <Box overflow="hidden" width={headlines[1].width}>
                      <TypographyDefault
                        fontWeight="600"
                        variant="body2"
                      >{`${homeTeamName} - ${awayTeamName}`}</TypographyDefault>
                    </Box>
                    <Box overflow="hidden" width={headlines[2].width}>
                      <TypographyDefault variant="body2">{GAME_TYPES[type]}</TypographyDefault>
                    </Box>
                    <Box overflow="hidden" width={headlines[3].width}>
                      <TypographyDefault variant="body2">{room || longDash}</TypographyDefault>
                    </Box>
                  </Wrapper>

                  <Stack width={headlines[4].width}>
                    {broadcastLink && (
                      <Link href={broadcastLink} target="_blank" textAlign="right">
                        Посмотреть трансляцию
                      </Link>
                    )}
                    <Link textAlign="right" to={`/lines?school=${schoolId}&team=${id}`}>
                      Посмотреть состав
                    </Link>
                  </Stack>
                </Stack>
              ),
            )}
          </Box>
        </>
      )}
    </Card>
  );
}

export default NextGames;
