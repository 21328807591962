import AdapterMoment from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import React from 'react';
import styled from 'styled-components';
/**@deprecated не использовать этот компонент**/
const DatePickerDesktop = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CustomizedDatePicker
        {...props}
        disabled={props.isDisabled}
        inputFormat="DD/MM/YYYY"
        label={props.label}
        renderInput={(params) => (
          <TextField {...params} inputProps={{...params.inputProps, placeholder: 'дд/мм/гггг'}} variant="standard" />
        )}
        value={props.value}
        onChange={props.onChange}
      />
    </LocalizationProvider>
  );
};

export default DatePickerDesktop;

const CustomizedDatePicker = styled((props) => <DesktopDatePicker {...props} />)``;
