import {loadillnessPlayers} from '@slices/managerDashboard';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import PlayerStatusTable from '../PlayerStatusTable';

function IllnessPlayers() {
  const dispatch = useDispatch();
  const {data, isLoad, isLoading, error} = useSelector((state) => state.managerDashboard.illnessPlayers);
  const [isAll, setIsAll] = useState(false);
  useEffect(() => {
    dispatch(loadillnessPlayers({isAll}));
  }, [isAll]);

  return (
    <PlayerStatusTable
      lastTableHeadline="Статус болезни"
      noData="игроков на больничном"
      title="игроки на больничном"
      {...{data, isLoad, isLoading, error, isAll, setIsAll}}
    />
  );
}
export default IllnessPlayers;
