import PlaceholderWrapper from '@common/FullHeightWrapper';
import {Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

NoDataFullHeight.propTypes = {
  wrapperProps: PropTypes.object,
};

function NoDataFullHeight({children = 'Данные отсутствуют', wrapperProps = {}}) {
  return (
    <PlaceholderWrapper
      alignItems="center"
      data-testid="no-filters"
      display="flex"
      justifyContent="center"
      px={{xxs: 2, sm: 4}}
      py={{xxs: 1, sm: 3}}
      width="100%"
      {...wrapperProps}
    >
      {typeof children === 'string' ? <Typography variant="subtitle1">{children}</Typography> : children}
    </PlaceholderWrapper>
  );
}

export default NoDataFullHeight;
