import {Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import styled from 'styled-components';

export const Container = styled(Stack)`
  background-color: ${(props) => {
    if (props.isNoEvents) return grey[12];
    if (props.isGame) return '#DB9C98';
    return '#fff';
  }};
  min-width: 140px;
  min-height: 182px;
  border-radius: 5px;
  opacity: ${(props) => props.isNotInCurrentMount && '0.6'};
  ${({theme}) => theme.breakpoints.down('md')} {
    min-width: 100%;
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
`;

export const Text = styled(Typography)`
  flex: 1;
`;

export const NoEvents = styled(Stack)`
  padding-top: 0;
  padding-bottom: 12px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > .MuiStack-root {
    margin-bottom: 0;
    align-items: center;
    height: 100%;
  }
`;
