/* eslint-disable unused-imports/no-unused-vars */
import {mock} from '@components/apps/GameStatisticsParsingCheck/components/Lineup/mock';
import TableComponent from '@components/apps/GameStatisticsParsingCheck/components/TableComponent';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useDeepMemo from '@hooks/useDeepMemo';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import {selectGameStatisticsParsingCheckTabs} from '@selectors/gameStatisticsParsingCheck';
import {longDash} from '@src/constants';
import {Tooltip, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';

Lineup.propTypes = {
  tab: PropTypes.string,
};

function validate(current, file) {
  const errors = [];
  if (current?.firstName?.trim() !== file?.firstName?.trim()) errors.push('имени');
  if (current?.lastName?.trim() !== file?.lastName?.trim()) errors.push('фамилии');
  if (current?.playerNumber !== file?.playerNumber) errors.push('номере');
  if (errors?.length) return `Не совпадают данные в ${errors.join(', ')} игрока!`;
}

function Lineup({tab}) {
  const {data, isLoading, isLoad, hasError} = useSelector(
    (state) => selectGameStatisticsParsingCheckTabs(state, tab),
    shallowEqual,
  );
  const columns = useDeepMemo(
    () => [
      {
        id: 'playerInfo',
        accessorKey: 'playerInfo',
        header: 'Имя игрока',
        cell: (params) => {
          const student = params?.getValue();
          return (
            <PlayersHorizontalCard
              hasImage={false}
              isLoading={isLoading}
              preloaderWidthBetween={[100, 170]}
              student={student}
            />
          );
        },
      },
      {
        id: 'filePlayerInfo',
        accessorKey: 'filePlayerInfo',
        header: 'Имя игрока в файле',
        cell: (params) => {
          const student = params?.getValue();
          return (
            <PlayersHorizontalCard
              hasImage={false}
              isLoading={isLoading}
              preloaderWidthBetween={[100, 170]}
              student={student}
            />
          );
        },
      },
      {
        id: 'playerId',
        accessorKey: 'playerId',
        header: 'ID игрока',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
      {
        id: 'warning',
        accessorFn: ({playerInfo, filePlayerInfo}) => ({playerInfo, filePlayerInfo}),
        header: ' ',
        cell: ({getValue}) => {
          const {playerInfo, filePlayerInfo} = getValue();
          const invalid = validate(playerInfo, filePlayerInfo);
          return invalid ? (
            <Tooltip sx={{textAlign: 'center'}} title={invalid}>
              <ErrorOutlineRoundedIcon color="primary" />
            </Tooltip>
          ) : null;
        },
      },
    ],
    [isLoading],
  );

  return (
    <TableComponent
      columns={columns}
      data={isLoading ? mock : data}
      hasError={hasError || !data?.length}
      isLoad={isLoad}
      isLoading={isLoading}
      tab={tab}
    />
  );
}

export default Lineup;
