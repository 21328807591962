import Questionnaire from '@components/apps/StudentDashboard/components/Questionnaire';
import {updateDiaryEntity} from '@components/apps/StudentDashboard/helpers';
import momentDate from '@helpers/date';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectPlayerDiary} from '@selectors/playerDiary';
import {QUESTIONNAIRE_QUESTION_TYPES} from '@src/constants/playerDiary';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import GameTypeSection from '../GameTypeSection';

const entity = 'game';
export const questions = [
  {
    category: {
      label: '1. общее самочувствие',
      value: entity,
    },
    subCategories: [
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.activity,
        isRequired: true,
        nested: true,
      },
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.stress,
        isRequired: true,
        nested: true,
      },
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.health,
        isRequired: true,
        nested: true,
      },
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.attention,
        isRequired: true,
        nested: true,
      },
    ],
  },
  {
    category: {
      label: '2. Игра',
      value: entity,
    },
    subCategories: [
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.fellingIceAndGoal,
        isRequired: true,
        nested: true,
      },
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.comunicationWithTrainer,
        isRequired: true,
        nested: true,
      },
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.selfScore,
        isRequired: true,
        nested: true,
      },
    ],
  },
];

function Game() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useStoreDispatch();
  const methods = useForm();
  const {handleSubmit} = methods;
  const {reset} = methods;
  const diary = useStore(selectPlayerDiary);
  return (
    <>
      <GameTypeSection
        diary={diary}
        entity={entity}
        icon="goalNet"
        name="Игра"
        nullCheckValue="health"
        onFillClick={() => setIsOpen(true)}
      />
      <FormProvider {...methods}>
        <Questionnaire
          content={questions}
          date={`День игры ${momentDate().innerDate.format('DD.MM.YYYY')}`}
          open={isOpen}
          title="Анкета. Игра"
          onClose={() => {
            setIsOpen(false);
            reset();
          }}
          onSubmit={handleSubmit((formValues) => {
            setIsOpen(false);
            updateDiaryEntity(formValues, diary.data, entity, dispatch);
          })}
        />
      </FormProvider>
    </>
  );
}

export default Game;
