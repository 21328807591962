import ChildrenField from '@common/ChildrenField';
import NumberInput from '@common/NumberInput';
import ExerciseItemCompact from '@common/TrainingControl/ExerciseItemCompact';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';
import {useFieldArray} from 'react-hook-form';

const Exercises = ({on}) => {
  const {fields: exercises, remove} = useFieldArray({name: 'exercises', keyName: 'innerId'});

  return (
    <>
      <TypographyDefault mb={2} mt={4} variant="h4">
        Упражнения
      </TypographyDefault>
      <Stack maxWidth="80%" spacing={2}>
        {exercises.map((exercise, index) => (
          <Stack direction="row" flexWrap="wrap" gap="5px 24px" key={exercise.id}>
            <ExerciseItemCompact
              {...exercise}
              flex="1"
              minWidth="400px"
              onDelete={() => remove(index)}
              onInfo={() => on(exercise)}
            />
            <Stack direction="row" flex="0.2" minWidth="200px" spacing={4}>
              <ChildrenField isRequired name={`exercises.${index}.time`}>
                <NumberInput label="Время" max={60} min={1} />
              </ChildrenField>
              <ChildrenField isRequired name={`exercises.${index}.repeat`}>
                <NumberInput label="Повторы" max={999} />
              </ChildrenField>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default Exercises;
