import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {
  AllTestResults,
  ContentWrapper,
  OverallResults,
  Status,
  StatusWrapper,
  Value,
  ValueOverall,
  WrapperTableContainer,
} from '@components/apps/PhysicalTesting/components/OverallReport/components/OverallReportTable/OverallReportTable';
import {ofpTestsExercisesOrdersMatrix, sfpTestsExercisesOrdersMatrix} from '@components/apps/PhysicalTesting/constants';
import {constructColumnsByExercise, sortColumn} from '@components/apps/PhysicalTesting/helpers';
import LoadingBox from '@components/common/LoadingBox';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useElementSize from '@hooks/useElementSize';
import useStore from '@hooks/useStore';
import {useMediaQuery} from '@mui/material';
import {longDash} from '@src/constants';
import {defaultSort} from '@src/helpers';
import {Stack, Typography} from '@ui/MUI';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import PropTypes from 'prop-types';
import React, {memo, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {mock} from '../../mock';

const calculateQualityTestsClassName = (hasReverseIndication, avgValue, value) => {
  let result = 'Value';
  if (!hasReverseIndication) {
    if (value === 0) {
      result += ' Value_empty';
    }
    if (value >= avgValue) {
      result += ' Value_good';
    }
    if (value < avgValue) {
      result += ' Value_bad';
    }
  } else {
    if (value === 0) {
      result += ' Value_empty';
    }
    if (value > avgValue) {
      result += ' Value_bad';
    }
    if (value <= avgValue) {
      result += ' Value_good';
    }
  }
  return result;
};

const isBeepTest = (exercise) => exercise === 'ip84hl3ivl';
const isYBalance = (exercise) => exercise === 'wMhqP7HzsSgC';
const isThrowsAccuracy = (exercise) => exercise === 'ypjjV_ydGQrE';
const isPassesAccuracy = (exercise) => exercise === 'Kf3AdBTfKHZI';

const calculateQualityTestsClassNameForSpecialIndication = (cell, tableData) => {
  let result = 'Value';
  if (isYBalance(cell?.exercise)) {
    if (cell?.sum === 0) {
      result += ' Value_empty';
    }
    if (cell?.sum < cell?.avgValue) {
      result += ' Value_bad';
    }
    if (cell?.sum >= cell?.avgValue) {
      result += ' Value_good';
    }
  }
  if (isBeepTest(cell?.exercise)) {
    if (cell?.value === 0) {
      result += ' Value_empty';
    }
    if (cell?.value < cell?.avgValue) {
      result += ' Value_bad';
    }
    if (cell?.value >= cell?.avgValue) {
      result += ' Value_good';
    }
  }
  if (isThrowsAccuracy(cell?.exercise)) {
    const allGoodResults = tableData
      .map((player) => player.params[cell.exercise])
      .filter((item) => {
        if (item) {
          return item.value >= 4;
        }
      });
    const summOfAllGoodResultsTime = allGoodResults.reduce((accum, currentValue) => {
      return accum + currentValue?.time;
    }, 0);
    const avgTimeValue = (summOfAllGoodResultsTime / allGoodResults.length).toFixed(2);
    if (cell?.value === 0 || cell?.time === 0) {
      result += ' Value_empty';
    }
    if (cell?.value < 4) {
      result += ' Value_bad';
    }
    if (cell?.value >= 4 && cell?.time > avgTimeValue) {
      result += ' Value_bad';
    }
    if (cell?.value >= 4 && cell?.time <= avgTimeValue) {
      result += ' Value_good';
    }
  }
  if (isPassesAccuracy(cell?.exercise)) {
    const allGoodResults = tableData
      .map((player) => player.params[cell.exercise])
      .filter((item) => {
        if (item) {
          return item.value >= 5;
        }
      });
    const summOfAllGoodResultsTime = allGoodResults.reduce((accum, currentValue) => {
      return accum + currentValue?.time;
    }, 0);
    const avgTimeValue = (summOfAllGoodResultsTime / allGoodResults.length).toFixed(2);
    if (cell?.value === 0 || cell?.time === 0) {
      result += ' Value_empty';
    }
    if (cell?.value < 5) {
      result += ' Value_bad';
    }
    if (cell?.value >= 5 && cell?.time > avgTimeValue) {
      result += ' Value_bad';
    }
    if (cell?.value >= 5 && cell?.time <= avgTimeValue) {
      result += ' Value_good';
    }
  }
  return result;
};

const sortSubColumn = (value, exercise) => (rowA, rowB, columnId) => {
  let a = rowA.original.params?.[columnId?.split('.')?.[1]]?.[value];
  let b = rowB.original.params?.[columnId?.split('.')?.[1]]?.[value];
  if (isBeepTest(exercise?.additionParam?.id)) {
    a = rowA.original.params?.[exercise?.additionParam?.id]?.[value];
    b = rowB.original.params?.[exercise?.additionParam?.id]?.[value];
  }
  if (a && b) {
    return defaultSort(a, b);
  }
};

const OverallReportTable = ({tableData, isLoading = true}) => {
  const [isToggled, setToggleUser] = useState(false);
  const history = useHistory();
  const exercises = useStore((state) => {
    return state.physicalTestingOverall.overallInfo.exercises;
  });
  const formattedExercises = exercises?.map((exercise) => {
    return {
      ...exercise,
      name: exercise?.additionParam ? exercise.name.split('(')[0] : exercise.name,
      hasReverseIndication: !!exercise?.name?.includes('(сек)'),
      order: ofpTestsExercisesOrdersMatrix[exercise?.id] || sfpTestsExercisesOrdersMatrix[exercise?.id],
    };
  });
  const sortedExercises = formattedExercises?.sort((a, b) => a.order - b.order);
  const [sorting, setSorting] = useState([{id: 'place', asc: true}]);
  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [bottomBlockRef, {height}] = useElementSize();

  const columns = useMemo(
    () => [
      {
        header: (
          <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
            Игрок
          </ToggledHeader>
        ),
        id: 'player',
        accessorKey: 'lastName',
        enableRowSpan: true,
        sticky: {left: true},
        sortable: !isSmResolution,
        headerStyle: {
          '.MuiBox-root': {
            position: 'relative',
            '.MuiStack-root + .MuiButtonBase-root:last-child': {
              position: 'absolute',
              top: '50%',
              left: '60px',
              transform: 'translateY(-50%)',
            },
          },
        },
        columns: [
          {
            header: 'Not visible header',
            accessorFn: ({params, ...data}) => {
              return data;
            },
            headerStyle: {display: 'none !important'},
            cellStyle: {
              transition: '.3s ease-in-out',
              height: 'auto !important',
              textAlign: 'center',
            },
            sortable: !isSmResolution,
            sticky: {left: true},
            cell: (params) => {
              const student = params?.getValue();
              return (
                <Stack
                  alignItems="center"
                  direction="row"
                  sx={{height: '100%', cursor: 'pointer'}}
                  onClick={() => {
                    history.push(`/student/${student.playerId}`);
                  }}
                >
                  <PlayersHorizontalCard
                    hasImage={!isToggled}
                    isLoading={isLoading}
                    isShortName={isSmResolution}
                    onlyNumber={isToggled}
                    student={student}
                  />
                </Stack>
              );
            },
          },
        ],
        rowSpan: '2',
        sortingFn: sortColumn,
      },
      ...(isLoading ? mock.exercises : sortedExercises).map((exercise) => {
        return {
          id: exercise.name,
          header: () => (
            <Typography
              fontWeight={600}
              isLoading={isLoading}
              preloaderWidthBetween={[100, 180]}
              sx={{zIndex: 1}}
              variant="caption1"
            >
              {exercise.name}
            </Typography>
          ),
          headerStyle: {
            padding: `${({theme}) => theme.spacing(1, 2)} !important`,
            height: '68px !important',
            textAlign: exercise?.additionParam ? 'center' : 'left',
            whiteSpace: 'pre-wrap !important',
          },
          cellStyle: {
            height: 'auto !important',
            textAlign: exercise?.additionParam ? 'center' : 'left',
            whiteSpace: 'pre-wrap !important',
          },
          columns: constructColumnsByExercise(exercise)?.map(({label, value}) => {
            return {
              accessorFn: ({params}) => {
                return isBeepTest(exercise?.additionParam?.id)
                  ? params?.[exercise?.additionParam?.id]
                  : params?.[exercise.id];
              },
              id: `params.${exercise.id}.${value}`,
              header: () => (
                <Typography
                  isLoading={isLoading}
                  preloaderWidthBetween={[50, 70]}
                  sx={{zIndex: 1, textAlign: 'center'}}
                  variant="caption1"
                >
                  {label}
                </Typography>
              ),
              cell: ({getValue}) => {
                const cellData = getValue();
                return value !== 'rang' && cellData?.[value] !== '-' ? (
                  <Value
                    className={
                      exercise?.additionParam
                        ? calculateQualityTestsClassNameForSpecialIndication(cellData, tableData)
                        : calculateQualityTestsClassName(
                            exercise?.hasReverseIndication,
                            cellData?.avgValue,
                            cellData?.value || cellData,
                          )
                    }
                    isLoading={isLoading}
                  >
                    <Typography isLoading={isLoading} preloaderWidthBetween={[30, 40]} sx={{zIndex: 1}} variant="body2">
                      {cellData?.[value] === 0 ? cellData?.[value] : cellData?.[value] || '-'}
                    </Typography>
                  </Value>
                ) : cellData?.[value] === 0 ? (
                  <Typography isLoading={isLoading} preloaderWidthBetween={[30, 40]} sx={{zIndex: 1}} variant="body2">
                    {cellData?.[value]}
                  </Typography>
                ) : (
                  <Typography isLoading={isLoading} preloaderWidthBetween={[30, 40]} sx={{zIndex: 1}} variant="body2">
                    {cellData?.[value] || '-'}
                  </Typography>
                );
              },
              headerStyle: {
                '.MuiBox-root': {
                  display: 'flex',
                  justifyContent: 'center',
                  boxShadow: 'none',
                  padding: '0px',
                },
                height: '48px !important',
                boxShadow:
                  value === 'rang'
                    ? 'rgb(224, 224, 224) -1px -1px 0px 0px inset'
                    : 'rgb(224, 224, 224) 0px -1px 0px 0px inset',
              },
              cellStyle: {
                height: 'auto !important',
                boxShadow: value === 'rang' ? 'rgb(224, 224, 224) -1px 0px 0px 0px inset !important' : 'none',
                textAlign: 'center',
              },
              sortingFn: sortSubColumn(value, exercise),
            };
          }),
        };
      }),
      {
        header: 'Ранг',
        accessorKey: 'place',
        rowSpan: '2',
        enableRowSpan: true,
        sticky: {right: true},
        cellStyle: {height: 'auto !important', textAlign: 'center'},
        columns: [
          {
            header: 'Not visible header 2',
            headerStyle: {
              display: 'none !important',
            },
            accessorFn: ({params, ...data}) => {
              return data;
            },
            cellStyle: {
              height: 'auto !important',
              textAlign: 'center',
            },
            sticky: {right: true},
            cell: ({getValue}) => {
              const cellData = getValue();
              return (
                <Typography isLoading={isLoading} preloaderWidthBetween={[20, 30]} sx={{zIndex: 1}} variant="body2">
                  {cellData?.place || longDash}
                </Typography>
              );
            },
          },
        ],
        sortingFn: sortColumn,
      },
    ],
    [isToggled, isSmResolution, isLoading],
  );
  return (
    <ContentWrapper data-testid="overall-report-table">
      <WrapperTableContainer canEdit={true}>
        {columns?.length <= 2 ? (
          <TableEmptyBlock
            data-testid="overall-report-no-data"
            text={'Для данной возрастной категории пока нет тестов.'}
          />
        ) : (
          <LoadingBox isLoading={isLoading}>
            <ReactTableComponent
              bottomBlockHeight={!isSmResolution ? height + 16 : height + 36}
              columns={columns}
              customScrollBarHeight={isSmResolution && tableData?.length > 5 && 500}
              data={isLoading ? mock.data : tableData}
              isAvailableHeight={true}
              setSorting={setSorting}
              sorting={sorting}
              tableBodyCellProps={{
                sx: {
                  py: {xxs: 1, lg: 3},
                  px: {xxs: 2, lg: 4},
                  maxWidth: 180,
                  '&:first-child.sticky-left': {
                    minWidth: !isSmResolution ? 300 : 'initial',
                    maxWidth: !isSmResolution ? 300 : 'initial',
                  },
                  '&.lastRowCell': {
                    boxShadow: 'none',
                  },
                },
              }}
              tableHeaderCellProps={{
                sx: {
                  py: {xxs: '0', lg: 3},
                  px: {xxs: 2, lg: 4},
                  maxWidth: 180,
                  '&:first-child.sticky-left': {
                    minWidth: !isSmResolution ? 300 : 'initial',
                    maxWidth: !isSmResolution ? 300 : 'initial',
                  },
                  '&.lastRowCell': {
                    boxShadow: 'none',
                  },
                },
              }}
            />
          </LoadingBox>
        )}
      </WrapperTableContainer>
      <OverallResults ref={bottomBlockRef}>
        <StatusWrapper>
          <Status>
            <AllTestResults>Результаты по всем тестам:</AllTestResults>
            <ValueOverall className="Value Value_good" left={'8px'} right={'0'} width={'188px'}>
              Выше среднего по команде
            </ValueOverall>
            <ValueOverall className="Value Value_bad" left={'8px'} right={'0'} width={'188px'}>
              Ниже среднего по команде
            </ValueOverall>
          </Status>
        </StatusWrapper>
      </OverallResults>
    </ContentWrapper>
  );
};

OverallReportTable.propTypes = {
  isLoading: PropTypes.bool,
  tableData: PropTypes.array.isRequired,
};

export default memo(OverallReportTable);
