import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const PsychoTestingAPI = {
  getPlayerPsychoTestingForm(request) {
    return axios.get(`${API_ENDPOINT}/psycho-test/user-data/${request.playerId}?seasons=${request.seasons}`);
  },

  postPsychoTestingForm(request, data) {
    return axios.post(`${API_ENDPOINT}/psycho-test/${request.student}/${request.report_dates}`, data);
  },
  getPlayerPsychoTestReport(request) {
    return axios.get(`${API_ENDPOINT}/psycho-test/report/${request.playerId}?dates=${request.dates}`);
  },
  getPlayerTypologicalProfileReport(request) {
    return axios.get(`${API_ENDPOINT}/psycho-test/user-data/typological-profile/${request.season}/${request.playerId}`);
  },
  getPlayerReportDates(request) {
    return axios.get(`${API_ENDPOINT}/psycho-test/dates/${request.student}`);
  },
};
export default PsychoTestingAPI;
