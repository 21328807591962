import {
  getOptionLabel,
  isMaterialOptionEqualToValue,
  isOptionEqualToValue,
} from '@components/apps/MaterialsModule/helpers';
import {useMaterialsTypes} from '@components/apps/MaterialsModule/hooks';
import {FiltersWrapper, FilterWrapper} from '@components/apps/MaterialsModule/styles';
import {useGroup, useSchool} from '@hooks/filters';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import React, {useEffect, useMemo} from 'react';

const Filters = ({filters, onChange}) => {
  const {data, isLoading, refetch} = useMaterialsTypes();
  const schools = useSchool({keepOriginalValues: true});
  const groups = useGroup({keepOriginalValues: true, connectorValue: filters?.school?.id});

  useEffect(() => {
    if (!data.length || !isLoading) {
      refetch();
    }
  }, []);

  const tagsOptions = useMemo(() => {
    if (!data.length) return [];
    return (
      data.find((item) => item.slug === 'video')?.categories?.find((category) => category.slug === 'video_analytics')
        ?.tags || []
    );
  }, [data]);

  return (
    <FiltersWrapper direction="row" flexWrap="wrap" gap={4} mb={1}>
      <FilterWrapper>
        <AutocompleteDefault
          getOptionLabel={getOptionLabel}
          isLoading={schools.isLoading}
          isOptionEqualToValue={isOptionEqualToValue}
          label="Школа"
          multiple={false}
          name="school"
          options={schools.options}
          value={filters.school}
          onChange={(_, v, name) => {
            onChange({...filters, [name]: v, group: null});
          }}
        />
      </FilterWrapper>
      <FilterWrapper>
        <AutocompleteDefault
          disabled={!filters.school}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          label="Команда"
          multiple={false}
          name="group"
          options={groups.options}
          value={filters.group}
          onChange={(_, v, name) => {
            onChange({...filters, [name]: v});
          }}
        />
      </FilterWrapper>
      <FilterWrapper>
        <AutocompleteDefault
          getOptionLabel={getOptionLabel}
          isLoading={isLoading}
          isOptionEqualToValue={isMaterialOptionEqualToValue}
          label="Теги"
          name="tags"
          options={tagsOptions}
          value={filters.tags}
          onChange={(_, v, name) => {
            onChange({...filters, [name]: v});
          }}
        />
      </FilterWrapper>
    </FiltersWrapper>
  );
};

export default Filters;
