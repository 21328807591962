import {
  getPlayerDiary,
  getPlayerDiaryList,
  getPlayerDiaryOverall,
  getPlayerDiaryOverallAverage,
  getPlayerDiaryReport,
} from '@api/playerDiary';
import {defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {createSlice} from '@reduxjs/toolkit';
import {initialStateIndicators} from '@src/constants';

import moduleNames from '../moduleNames';

const statePaths = {
  diary: {
    name: 'diary',
    path: 'diary',
  },
  report: {
    name: 'report',
    path: 'report',
  },
  reportAverage: {
    name: 'reportAverage',
    path: 'reportAverage',
  },
  list: {
    name: 'list',
    path: 'list',
  },
  currentUserDiary: {
    name: 'currentUserDiary',
    path: 'currentUserDiary',
  },
};
const diaryInitialState = {
  ...initialStateIndicators,
  data: {
    morning: null,
    trainingGroup: null,
    trainingIndividual: null,
    game: null,
  },
};
const initialState = {
  diary: {...diaryInitialState},
  currentUserDiary: {...diaryInitialState},
  report: {...initialStateIndicators, data: {}},
  reportAverage: {...initialStateIndicators, data: {}},
  list: {...initialStateIndicators, data: []},
};

//redusers
const playerDiary = createSlice({
  name: moduleNames.playerDiary,
  initialState,
  reducers: {
    ...defaultSliceReducersConstructor(statePaths.diary.name, statePaths.diary.path, initialState).functions,
    setDiaryEntityLoading: (state, {payload}) => {
      state.diary.data[payload?.name] = {...(state.diary.data?.[payload?.name] || {}), isLoading: payload.data};
    },
    setPlayerDiaryAction: (state, {payload}) => {
      const payloadId = payload.data?.id;
      if (payloadId && payloadId !== state.diary.data?.id) state.diary.data.id = payloadId;
      state.diary.data[payload?.entity] = payload.data?.[payload?.entity];
    },
    ...defaultSliceReducersConstructor(statePaths.report.name, statePaths.report.path, initialState).functions,
    ...defaultSliceReducersConstructor(statePaths.reportAverage.name, statePaths.reportAverage.path, initialState)
      .functions,
    ...defaultSliceReducersConstructor(statePaths.list.name, statePaths.list.path, initialState).functions,
    ...defaultSliceReducersConstructor(statePaths.currentUserDiary.name, statePaths.currentUserDiary.path, initialState)
      .functions,
  },
});

//exports

const {actions, reducer} = playerDiary;
export {reducer as playerDiaryReducer};

const {setDiaryEntityLoading, setPlayerDiaryAction} = actions;

//fetch fns
const diaryActions = defaultSliceReducersConstructor(
  statePaths.diary.name,
  statePaths.diary.path,
  initialState,
  actions,
).actionsValues;

const reportActions = defaultSliceReducersConstructor(
  statePaths.report.name,
  statePaths.report.path,
  initialState,
  actions,
).actionsValues;

const reportAverageActions = defaultSliceReducersConstructor(
  statePaths.reportAverage.name,
  statePaths.reportAverage.path,
  initialState,
  actions,
).actionsValues;

const listActions = defaultSliceReducersConstructor(
  statePaths.list.name,
  statePaths.list.path,
  initialState,
  actions,
).actionsValues;

const currentUserDiaryActions = defaultSliceReducersConstructor(
  statePaths.currentUserDiary.name,
  statePaths.currentUserDiary.path,
  initialState,
  actions,
).actionsValues;

export const setPlayerDiary = (data, entity) => (dispatch) => {
  dispatch(setPlayerDiaryAction({data, entity}));
};

export const setDiaryEntityLoadingState = (state) => (dispatch) => dispatch(setDiaryEntityLoading(state));

export const loadPlayerDiary = (params, entity = '') => {
  return async (dispatch) => {
    !entity ? dispatch(diaryActions.loading()) : dispatch(setDiaryEntityLoading({name: entity, data: true}));
    await getPlayerDiary(params)
      .then(({data}) => {
        dispatch(diaryActions.load({data: data, needToSet: Boolean(data)}));
      })
      .catch(() => {
        dispatch(diaryActions.error());
      })
      .finally(() => {
        entity && dispatch(setDiaryEntityLoading({name: entity, data: false}));
      });
  };
};

export const loadPlayerDiaryReport = (params) =>
  defaultLoader(() => getPlayerDiaryReport(params), reportActions.loading, reportActions.load, reportActions.error);

export const loadPlayerDiaryOverall = (params) =>
  defaultLoader(() => getPlayerDiaryOverall(params), reportActions.loading, reportActions.load, reportActions.error);

export const loadPlayerDiaryOverallAverage = (params) =>
  defaultLoader(
    () => getPlayerDiaryOverallAverage(params),
    reportAverageActions.loading,
    reportAverageActions.load,
    reportAverageActions.error,
  );

export const loadPlayerDiaryList = (params) =>
  defaultLoader(() => getPlayerDiaryList(params), listActions.loading, listActions.load, listActions.error);

export const loadCurrentUserDiary = (params) =>
  defaultLoader(
    () => getPlayerDiary(params),
    currentUserDiaryActions.loading,
    currentUserDiaryActions.load,
    currentUserDiaryActions.error,
  );
