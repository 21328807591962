import EstimateItemWithLabelHorizontal from '@common/DevelopmentJournal/components/EstimateItemWithLabelHorizontal';
import EstimateItemWithLabelVertical from '@common/DevelopmentJournal/components/EstimateItemWithLabelVertical';
import EstimateNumber from '@common/DevelopmentJournal/components/EstimateNumber';
import {Stack} from '@ui/MUI';
import React from 'react';

const Scores = ({score, expectedScore, completedScore, isArchived}) => {
  return (
    <EstimateItemWithLabelVertical label="Результат" spacing={0}>
      <Stack direction="row" spacing={4}>
        <EstimateItemWithLabelHorizontal
          label={!isArchived ? 'Текущий' : 'Исходный результат'}
          labelVariant="subtitle1"
          spacing={2}
        >
          <EstimateNumber isArchived isPrimary={!isArchived}>
            {score}
          </EstimateNumber>
        </EstimateItemWithLabelHorizontal>
        <EstimateItemWithLabelHorizontal
          label={!isArchived ? 'Целевой' : 'Итоговый результат'}
          labelVariant="subtitle1"
          spacing={2}
        >
          <EstimateNumber isPrimary>{!isArchived ? expectedScore : completedScore}</EstimateNumber>
        </EstimateItemWithLabelHorizontal>
      </Stack>
    </EstimateItemWithLabelVertical>
  );
};

export default Scores;
