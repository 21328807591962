import React from 'react';

function Psychologist() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M11.3334 5.45111C10.6312 5.45111 10.0623 6.02 10.0623 6.72222C10.0623 7.42444 10.6312 7.99333 11.3334 7.99333C12.0357 7.99333 12.6045 7.42444 12.6045 6.72222C12.6045 6.02 12.0357 5.45111 11.3334 5.45111Z"
          fill="#686868"
        />
        <path
          d="M11.3334 0.5C8.00009 0.5 5.28898 3.11333 5.12898 6.40222L3.42232 8.67778C3.20009 8.97111 3.41343 9.38889 3.77787 9.38889H5.11121V12.0556C5.11121 13.0333 5.91121 13.8333 6.88898 13.8333H7.77787V16.5H14.0001V12.34C16.0979 11.3444 17.5556 9.20222 17.5556 6.72222C17.5556 3.28222 14.7734 0.5 11.3334 0.5ZM14.0001 6.72222C14.0001 6.83778 13.9912 6.95333 13.9823 7.06889L14.7201 7.65556C14.7912 7.70889 14.809 7.79778 14.7645 7.87778L14.0534 9.11333C14.009 9.19333 13.9112 9.22 13.8401 9.19333L12.9601 8.83778C12.7734 8.98 12.5779 9.09556 12.3645 9.18445L12.2223 10.1267C12.2134 10.2156 12.1334 10.2778 12.0445 10.2778H10.6223C10.5334 10.2778 10.4623 10.2156 10.4445 10.1267L10.3112 9.18445C10.089 9.09556 9.89343 8.98 9.70676 8.83778L8.82676 9.19333C8.74676 9.22 8.64898 9.19333 8.60454 9.11333L7.89343 7.87778C7.84898 7.80667 7.86676 7.70889 7.93787 7.65556L8.68454 7.06889C8.67565 6.95333 8.66676 6.83778 8.66676 6.72222C8.66676 6.60667 8.68454 6.48222 8.70232 6.37556L7.94676 5.78889C7.87565 5.73556 7.85787 5.64667 7.90232 5.55778L8.61343 4.33111C8.65787 4.25111 8.74676 4.22444 8.82676 4.25111L9.71565 4.60667C9.89343 4.47333 10.0979 4.34889 10.3112 4.26L10.4445 3.31778C10.4623 3.22889 10.5334 3.16667 10.6223 3.16667H12.0445C12.1334 3.16667 12.2045 3.22889 12.2223 3.31778L12.3556 4.26C12.569 4.34889 12.7645 4.46444 12.9512 4.60667L13.8401 4.25111C13.9201 4.22444 14.0179 4.25111 14.0534 4.33111L14.7645 5.55778C14.809 5.63778 14.7912 5.73556 14.7201 5.78889L13.9645 6.37556C13.9912 6.48222 14.0001 6.59778 14.0001 6.72222Z"
          fill="#686868"
        />
      </g>
    </React.Fragment>
  );
}
export default Psychologist;
