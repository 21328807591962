import RoomsAPI from '../../api/RoomsAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

let loadRooms_ = () => {
  return {
    type: types.LOAD_ROOMS,
  };
};
let loadRoomsSuccess = (rooms) => {
  return {
    type: types.LOAD_ROOMS_SUCCESS,
    rooms: rooms,
  };
};
let loadRoomsFailed = (error) => {
  return {
    type: types.LOAD_ROOMS_FAIL,
    error: error,
  };
};

//thunk
export function loadAllRooms() {
  return (dispatch) => {
    dispatch(loadRooms_());
    return RoomsAPI.getAllRooms().then(
      (rooms) => dispatch(loadRoomsSuccess(rooms)),
      (error) => dispatch(loadRoomsFailed(error)),
    );
  };
}

export function loadRoomById(id) {
  return (dispatch) => {
    dispatch(loadRooms_());
    return RoomsAPI.getRoom(id).then(
      (room) => dispatch(loadRoomsSuccess([room])),
      (error) => dispatch(loadRoomsFailed(error)),
    );
  };
}

let createRoom_ = () => {
  return {
    type: types.CREATE_ROOM,
  };
};
let createRoomSuccess = (room) => {
  return {
    type: types.CREATE_ROOM_SUCCESS,
    room: room,
  };
};
let createRoomFailed = (error) => {
  return {
    type: types.CREATE_ROOM_FAIL,
    error: error,
  };
};

//thunk
export function createRoom(data) {
  return (dispatch) => {
    dispatch(createRoom_());
    return RoomsAPI.createRoom(data).then(
      (room) => dispatch(createRoomSuccess(room)),
      (error) => dispatch(createRoomFailed(error)),
    );
  };
}

let updateRoom_ = () => {
  return {
    type: types.UPDATE_ROOM,
  };
};
let updateRoomSuccess = (room) => {
  return {
    type: types.UPDATE_ROOM_SUCCESS,
    room: room,
  };
};
let updateRoomFailed = (error) => {
  return {
    type: types.UPDATE_ROOM_FAIL,
    error: error,
  };
};

//thunk
export function updateRoom(data) {
  return (dispatch) => {
    dispatch(updateRoom_());
    return RoomsAPI.updateRoom(data).then(
      (room) => dispatch(updateRoomSuccess(room)),
      (error) => dispatch(updateRoomFailed(error)),
    );
  };
}

let deleteRoom_ = () => {
  return {
    type: types.DELETE_ROOM,
  };
};
let deleteRoomSuccess = (id) => {
  return {
    type: types.DELETE_ROOM_SUCCESS,
    id: id,
  };
};
let deleteRoomFailed = (error) => {
  return {
    type: types.DELETE_ROOM_FAIL,
    error: error,
  };
};

//thunk
export function deleteRoom(id) {
  return (dispatch) => {
    dispatch(deleteRoom_());
    return RoomsAPI.deleteRoom(id).then(
      () => dispatch(deleteRoomSuccess(id)),
      (error) => dispatch(deleteRoomFailed(error)),
    );
  };
}

export function loadAllDressingRooms() {
  return (dispatch) => {
    return RoomsAPI.loadAllDressingRooms()
      .then((data) => {
        dispatch({
          type: types.LOAD_DRESSING_ROOMS,
          dressingRooms: data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке раздевалок';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
