import {LocalizationProvider} from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {DesktopDatePickerProps} from '@mui/lab/DesktopDatePicker/DesktopDatePicker';
import {InputProps} from '@mui/material/Input/Input';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {TextField} from '@ui/MUI';
import React, {forwardRef} from 'react';
import {createGlobalStyle} from 'styled-components';

type DatePickerCustomProps = Pick<TextFieldProps, 'size' | 'variant' | 'helperText' | 'error'> &
  DesktopDatePickerProps & {
    textFieldStyles: Pick<InputProps, 'sx'>;
    inputStyles: Pick<InputProps, 'sx'>;
    ['data-testid']: string;
  } & HTMLDivElement;

const DatePickerDefault = (
  {
    label,
    value = null,
    onChange = () => {},
    open,
    onClose,
    onAccept,
    toolbarTitle = '',
    inputFormat = 'DD.MM.YYYY',
    mask = '__.__.____',
    error,
    helperText,
    variant,
    size,
    renderInput,
    ...props
  }: DatePickerCustomProps,
  ref: React.Ref<HTMLDivElement> | undefined,
) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <GlobalStyle />
        <DesktopDatePicker
          allowSameDateSelection
          inputFormat={inputFormat}
          label={label}
          mask={mask}
          open={open}
          ref={ref}
          renderInput={
            renderInput ||
            ((params) => {
              return (
                <TextField
                  sx={props?.textFieldStyles}
                  {...params}
                  data-testid={props?.['data-testid']}
                  error={error}
                  helperText={helperText}
                  inputProps={{...params.inputProps, placeholder: mask, sx: props?.inputStyles}}
                  readOnly={props?.readOnly}
                  size={size}
                  variant={variant}
                />
              );
            })
          }
          showDaysOutsideCurrentMonth={true}
          showToolbar={false}
          toolbarTitle={toolbarTitle}
          value={value}
          onAccept={onAccept}
          onChange={onChange}
          onClose={onClose}
          {...props}
        />
      </LocalizationProvider>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  .MuiCalendarPicker {
    &-root {
      .Mui-selected {
        background: #e12 !important;
      }
    }
  }
`;

export default forwardRef(DatePickerDefault);
