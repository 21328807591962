import React from 'react';

function AddCircle() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M7.5 0C3.36425 0 0 3.36425 0 7.5C0 11.6358 3.36425 15 7.5 15C11.6358 15 15 11.6352 15 7.5C15 3.36483 11.6358 0 7.5 0ZM7.5 13.8381C4.00562 13.8381 1.16188 10.995 1.16188 7.5C1.16188 4.00503 4.00562 1.16188 7.5 1.16188C10.9944 1.16188 13.8381 4.00503 13.8381 7.5C13.8381 10.995 10.995 13.8381 7.5 13.8381Z"
          fill="#2C2C2C"
        />
        <path
          d="M10.4051 6.86652H8.08129V4.54275C8.08129 4.22207 7.8216 3.96179 7.50033 3.96179C7.17906 3.96179 6.91937 4.22207 6.91937 4.54275V6.86652H4.59561C4.27434 6.86652 4.01465 7.12679 4.01465 7.44748C4.01465 7.76816 4.27434 8.02843 4.59561 8.02843H6.91937V10.3522C6.91937 10.6729 7.17906 10.9332 7.50033 10.9332C7.8216 10.9332 8.08129 10.6729 8.08129 10.3522V8.02843H10.4051C10.7263 8.02843 10.986 7.76816 10.986 7.44748C10.986 7.12679 10.7263 6.86652 10.4051 6.86652Z"
          fill="#2C2C2C"
        />
      </g>
    </React.Fragment>
  );
}
export default AddCircle;
