import Add from '@mui/icons-material/Add';
import ButtonDefault from '@ui/MUI/Button';
import React from 'react';

const AddButton = ({children, startIcon, onClick}) => {
  return (
    <div>
      <ButtonDefault color="primary" startIcon={startIcon || <Add />} onClick={onClick}>
        {children}
      </ButtonDefault>
    </div>
  );
};

export default AddButton;
