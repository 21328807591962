import SchoolsAPI from '../../api/SchoolsAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

let loadSchools_ = () => {
  return {
    type: types.LOAD_SCHOOLS,
  };
};
let loadSchoolsSuccess = (schools) => {
  return {
    type: types.LOAD_SCHOOLS_SUCCESS,
    schools: schools,
  };
};
let loadSchoolsFailed = (error) => {
  return {
    type: types.LOAD_SCHOOLS_FAIL,
    error: error,
  };
};

//thunk
export function loadAllSchools() {
  return (dispatch) => {
    dispatch(loadSchools_());
    return SchoolsAPI.getAllSchools().then(
      (schools) => dispatch(loadSchoolsSuccess(schools)),
      (error) => dispatch(loadSchoolsFailed(error)),
    );
  };
}

export function loadSchoolById(id) {
  return (dispatch) => {
    dispatch(loadSchools_());
    return SchoolsAPI.getSchool(id).then(
      (school) => dispatch(loadSchoolsSuccess([school])),
      (error) => dispatch(loadSchoolsFailed(error)),
    );
  };
}

let createSchool_ = () => {
  return {
    type: types.CREATE_SCHOOL,
  };
};
let createSchoolSuccess = (school) => {
  return {
    type: types.CREATE_SCHOOL_SUCCESS,
    school: school,
  };
};
let createSchoolFailed = (error) => {
  return {
    type: types.CREATE_SCHOOL_FAIL,
    error: error,
  };
};

//thunk
export function createSchool(data) {
  return (dispatch) => {
    dispatch(createSchool_());
    return SchoolsAPI.createSchool(data).then(
      (school) => dispatch(createSchoolSuccess(school)),
      (error) => dispatch(createSchoolFailed(error)),
    );
  };
}

let updateSchool_ = () => {
  return {
    type: types.UPDATE_SCHOOL,
  };
};
let updateSchoolSuccess = (school) => {
  return {
    type: types.UPDATE_SCHOOL_SUCCESS,
    school: school,
  };
};
let updateSchoolFailed = (error) => {
  return {
    type: types.UPDATE_SCHOOL_FAIL,
    error: error,
  };
};

//thunk
export function updateSchool(data) {
  return (dispatch) => {
    dispatch(updateSchool_());
    return SchoolsAPI.updateSchool(data).then(
      (school) => dispatch(updateSchoolSuccess(school)),
      (error) => dispatch(updateSchoolFailed(error)),
    );
  };
}

let deleteSchool_ = () => {
  return {
    type: types.DELETE_SCHOOL,
  };
};
let deleteSchoolSuccess = (id) => {
  return {
    type: types.DELETE_SCHOOL_SUCCESS,
    id: id,
  };
};
let deleteSchoolFailed = (error) => {
  return {
    type: types.DELETE_SCHOOL_FAIL,
    error: error,
  };
};

//thunk
export function deleteSchool(id) {
  return (dispatch) => {
    dispatch(deleteSchool_());
    return SchoolsAPI.deleteSchool(id).then(
      () => dispatch(deleteSchoolSuccess(id)),
      (error) => dispatch(deleteSchoolFailed(error)),
    );
  };
}

export function loadAllOpposingTeams() {
  return (dispatch) => {
    return SchoolsAPI.getAllOpposingTeams()
      .then((data) => {
        dispatch({
          type: types.LOAD_OPPOSING_TEAMS,
          opposingTeams: data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке команд соперников';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
