import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {StyledButton} from '../../../../common/ScoutReport/CommonScoutReport';
import {PERMISSIONS} from '../../../../common/ScoutReport/constants';
import GroupReportList from '../../../../common/ScoutReport/GroupReportList';

function GroupScoutReport({groupId}) {
  const {groupReports} = useSelector(({scoutReport}) => ({
    groupReports: scoutReport.groupReports,
  }));

  const [isModalOpen, setIsModalOpen] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  return (
    <>
      {groupReports?.permissions?.[PERMISSIONS?.canCreate] && (
        <StyledButton
          color="primary"
          margin="0 0 24px 0"
          variant="contained"
          onClick={() => setIsModalOpen({...isModalOpen, right: !isModalOpen.right})}
        >
          <span className="plus" />
          Добавить отчет
        </StyledButton>
      )}
      <GroupReportList
        groupId={groupId}
        isOpen={isModalOpen}
        perView={8}
        reportType={'inner'}
        setIsOpen={(state) =>
          setIsModalOpen({
            ...isModalOpen,
            right: typeof state === 'boolean' ? state : !isModalOpen.right,
          })
        }
      />
    </>
  );
}

GroupScoutReport.propTypes = {
  groupId: PropTypes.string,
};

export default GroupScoutReport;
