import IconButtonGroup from '@common/IconButtonGroup';
import Stack from '@ui/MUI/Stack';
import React from 'react';

import ExercisesBlock from './components/ExercisesBlock';
import FormBlock from './components/FormBlock';
import MainInfoBlock from './components/MainInfoBlock';

const TrainingItem = ({
  name,
  group,
  author,
  date,
  exercises,
  startTime,
  endTime,
  actionSlot,
  onEdit,
  onDelete,
  isForm,
  dates,
  isStudent,
}) => {
  return (
    <Stack spacing={4}>
      <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
        <MainInfoBlock
          author={author}
          date={date}
          dates={dates}
          endTime={endTime}
          group={group}
          name={name}
          startTime={startTime}
        />
        {!isStudent && (onEdit || onDelete) ? <IconButtonGroup onDelete={onDelete} onEdit={onEdit} /> : actionSlot}
      </Stack>
      <ExercisesBlock exercises={exercises} isForm={isForm} />
      {isForm && <FormBlock />}
    </Stack>
  );
};

export default TrainingItem;
