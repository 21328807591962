import 'react-quill/dist/quill.snow.css';

import {Box, IconButton, Stack} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import FormHelperText from '@ui/MUI/FormHelperText';
import PropTypes from 'prop-types';
import React from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components';

const MarkdownEditor = React.forwardRef(function MarkdownEditor(
  {placeholder, onChange = () => {}, error, helperText, readOnly, ...props},
  ref,
) {
  return (
    <Box width="100%">
      <StyledBox className="text-editor" error={error} readonly={readOnly}>
        <Stack direction="row" display={readOnly && 'none'} flexWrap="wrap" gap={0} id="toolbar">
          <select className="ql-header" defaultValue={''} onChange={(e) => e.persist()}>
            <option value="1">H1</option>
            <option value="2">H2</option>
            <option value="3">H3</option>
            <option value="4">H4</option>
            <option selected />
          </select>
          <select className="ql-color">
            <option value="red" />
            <option value="green" />
            <option value="blue" />
            <option value="orange" />
            <option value="violet" />
            <option value="#d0d1d2" />
            <option selected />
          </select>
          <span className="ql-formats">
            <IconButton className="ql-bold" color="primary" />
            <IconButton className="ql-italic" color="primary" />
            <IconButton className="ql-underline" color="primary" />
            <IconButton className="ql-strike" color="primary" />
            <IconButton className="ql-link" color="primary" />
          </span>
          <span className="ql-formats">
            <IconButton className="ql-list" value="ordered" />
            <IconButton className="ql-list" value="bullet" />
            <IconButton className="ql-script" color="primary" value="sub" />
            <IconButton className="ql-script" color="primary" value="super" />
          </span>
          <IconButton className="ql-indent" value="-1" />
          <IconButton className="ql-indent" value="+1" />
          <select className="ql-align">
            <option selected />
            <option value="center" />
            <option value="justify" />
            <option value="right" />
          </select>
        </Stack>
        <ReactQuill
          modules={modules}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          theme={'snow'}
          onChange={(e, v, source, editor) => {
            onChange(e, v, source, editor);
          }}
          {...props}
        />
      </StyledBox>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  );
});

MarkdownEditor.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
};

const modules = {
  toolbar: {
    container: '#toolbar',
  },
  clipboard: {
    matchVisual: false,
  },
};

export default MarkdownEditor;

const StyledBox = styled(Box)`
  ${({theme, error, readonly}) => ({
    width: '100%',
    '.ql-container.ql-snow': {
      borderColor: error ? theme.palette.error.main : '',
      border: readonly && 'none',
    },
    '.ql-editor': {
      padding: readonly && '0',
      minHeight: readonly && 'unset',
    },
    '.ql-tooltip': {
      left: '0 !important',
    },
    '.ql-toolbar.ql-snow': {
      borderBottom: 'none',
    },
    '.ql-blank::before': {
      ...theme.typography.body1,
      color: grey['24'],
    },
    h1: {
      ...theme.typography.h1,
    },
    h2: {
      ...theme.typography.h2,
    },
    h3: {
      ...theme.typography.h3,
    },
    h4: {
      ...theme.typography.h4,
    },
    p: {
      ...theme.typography.body1,
    },
  })}
`;
