import useClickAway from '@hooks/useClickAway';
import useSearch from '@hooks/useSearch';
import useToggle from '@hooks/useToggle';
import CircularProgress from '@ui/MUI/CircularProgress';
import Paper from '@ui/MUI/Paper';
import Stack from '@ui/MUI/Stack';
import TextFieldDefault from '@ui/MUI/TextField';
import TypographyDefault from '@ui/MUI/Typography';
import React, {useEffect, useMemo, useRef} from 'react';

const SelectableList = ({options = [], onInputChange, onChange, value = [], children, label, isLoading = false}) => {
  const {search, debounsedValue, updateValue} = useSearch();

  const selectControl = useToggle();
  const ref = useClickAway(selectControl.off);
  const isSecondRender = useRef(false);

  useEffect(() => {
    // чтобы вызывался только тогда когда человек начнет что-то писать
    if (isSecondRender.current) {
      onInputChange && onInputChange(debounsedValue);
    }
    isSecondRender.current = true;
  }, [isSecondRender, debounsedValue]);

  const handleSelect = ({isSelected, ...option}) => {
    if (isSelected) {
      const filteredOptions = value.filter(({id}) => id !== option.id);
      onChange && onChange(filteredOptions);
    } else {
      const pushed = [...value, option];
      onChange && onChange(pushed);
    }
  };

  const OptionsComponent = useMemo(() => {
    if (!options.length) {
      return <TypographyDefault>{isLoading ? 'Загрузка...' : 'Данные отсутствуют'}</TypographyDefault>;
    }

    return (
      <Paper elevation={8} position="absolute" sx={{zIndex: '9999', maxHeight: 200, overflow: 'auto'}}>
        <Stack py={1}>
          {options.map((option) => {
            const isSelected = value.some((select) => select.id === option.id);
            return children({
              ...option,
              isSelected,
              handleSelect: () => handleSelect({...option, isSelected}),
            });
          })}
        </Stack>
      </Paper>
    );
  }, [options, isLoading, value, children]);

  return (
    <Stack position="relative" ref={ref}>
      <TextFieldDefault
        InputProps={{
          endAdornment: isLoading && <CircularProgress color="inherit" size={20} />,
        }}
        autoComplete="off"
        label={label}
        value={search}
        onChange={(e) => updateValue(e.target.value)}
        onFocus={selectControl.on}
      />
      {selectControl.isToggle && OptionsComponent}
    </Stack>
  );
};

export default SelectableList;
