import {Person, PersonAdd} from '@mui/icons-material';
import {useMediaQuery} from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export default function TeacherHockeyLinesPlayerEmptyCard(props) {
  let {position = '', isActive = false, lineNumber = '', onClick = () => {}, readOnly} = props;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <EmptyCard
      className="EmptyCard"
      data-player-line-number={lineNumber}
      data-player-position={position}
      isMobile={isMobile}
      onClick={onClick}
    >
      <EmptyCardContainer className="EmptyCardContainer">
        <EmptyCardIconWrapper className={isActive ? 'isHighlight' : 'EmptyCardIconWrapper'}>
          {!readOnly ? <PersonAdd /> : <Person />}
          <CardPosition>{position}</CardPosition>
        </EmptyCardIconWrapper>
      </EmptyCardContainer>
    </EmptyCard>
  );
}

const EmptyCard = styled.div`
  padding-left: ${(props) => (props.isMobile ? 0 : '20px')};
  padding-right: ${(props) => (props.isMobile ? '28px' : '20px')};
  box-sizing: border-box;
  cursor: pointer;
`;
const EmptyCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EmptyCardIconWrapper = styled.div`
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  background-color: #ebebeb;
  border-radius: 50%;
  &.isHighlight {
    background-color: #949494;

    svg path {
      fill: #949494;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -60%);
    path {
      fill: #949494;
    }
  }
`;
const CardPosition = styled.p`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 8px;
  width: 100%;
  max-width: 32px;
  background-color: #b63830;
  border-radius: 10px;
  font: normal normal 12px/16px 'Proxima Nova';
  color: #fffaed;
  text-align: center;
  text-transform: uppercase;
`;
