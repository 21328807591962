import PropTypes from 'prop-types';
import React from 'react';

import {NoData} from '../../../../common/ScoutReport/CommonScoutReport';
import LocalPreloader from '../../../../preloader/LocalPreloader';
import {RoundedBox} from '../../ScoutReport';
import TeamCard from '../TeamCard';
import {WrapperInner} from './TeamCards';

function TeamCards({onClick = () => {}, cards}) {
  return (
    <>
      {cards?.loading ? (
        <RoundedBox isLoading={cards?.loading}>
          <LocalPreloader visible={cards?.loading} />
        </RoundedBox>
      ) : cards?.data?.result?.length ? (
        <WrapperInner>
          {cards?.data?.result?.map((item, index) => (
            <TeamCard item={item} key={index} onClick={onClick} />
          ))}
        </WrapperInner>
      ) : (
        <RoundedBox>
          <NoData>Нет данных</NoData>
        </RoundedBox>
      )}
    </>
  );
}

TeamCards.propTypes = {
  cards: PropTypes.object,
  onClick: PropTypes.func,
};

export default TeamCards;
