export const mock = [
  {
    playerNumber: 31,
    playerFirstName: 'Никита',
    playerLastName: 'Климахин',
    missedGoals: 5,
    overallTOI: '26:54',
    firstHalfTOI: '19:52',
    secondHalfTOI: '07:01',
    thirdHalfTOI: '00:00',
    overTimeTOI: '00:00',
  },
  {
    playerNumber: 34,
    playerFirstName: 'Егор',
    playerLastName: 'Мосейкин',
    missedGoals: 2,
    overallTOI: '32:41',
    firstHalfTOI: '00:00',
    secondHalfTOI: '12:52',
    thirdHalfTOI: '19:48',
    overTimeTOI: '00:00',
  },
  {
    playerNumber: 34,
    playerFirstName: 'Егор',
    playerLastName: 'Мосейкин',
    missedGoals: 2,
    overallTOI: '32:41',
    firstHalfTOI: '00:00',
    secondHalfTOI: '12:52',
    thirdHalfTOI: '19:48',
    overTimeTOI: '00:00',
  },
];
