import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';

export function getStatisticsFromTab(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/quality`, {params});
}

export function getTeamNames(params) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/quality/teamnames`, {params});
}
