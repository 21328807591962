import GroupsAPI from '@api/GroupsAPI';
import SchoolsAPI from '@api/SchoolsAPI';
import Field from '@common/FormField';
import {getOptionLabel, isOptionEqualToValue} from '@components/apps/MaterialsModule/helpers';
import useToggle from '@hooks/useToggle';
import React, {useEffect, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

const TeacherSelect = () => {
  const school = useWatch({name: 'schools'});
  const group = useWatch({name: 'groups'});
  const {setValue} = useFormContext();
  const toggle = useToggle();
  const [options, setOptions] = useState([]);

  const getSchoolOptions = () => {
    toggle.on();
    SchoolsAPI.getSchoolsTeachers(school.map((school) => school.id))
      .then((teachers) => {
        setOptions(teachers);
        setValue('teachers', teachers);
      })
      .finally(toggle.off);
  };

  const getGroupOptions = () => {
    toggle.on();
    GroupsAPI.getGroupsTeachers(group.map((group) => group.id))
      .then((teachers) => {
        setOptions(teachers);
        setValue('teachers', teachers);
      })
      .finally(toggle.off);
  };

  useEffect(() => {
    if (group.length) {
      getGroupOptions();
      return;
    }

    if (school.length) {
      getSchoolOptions();
    }
  }, [school, group]);

  return (
    <Field
      multiple
      getOptionLabel={getOptionLabel}
      isLoading={toggle.isToggle}
      isOptionEqualToValue={isOptionEqualToValue}
      label="Тренеры"
      limitTags={2}
      name="teachers"
      options={options}
    />
  );
};

export default TeacherSelect;
