import {
  CLEAR_STATISTICS_FILES_LIST,
  CLEAR_STATS_FOR_ONE_GAME,
  CLEAR_STATS_MATCH_GOALTENDER_INFO,
  CLEAR_STATS_MATCH_SHIFTS,
  LOAD_STATISTICS_FILES_LIST,
  LOAD_STATISTICS_FILES_LIST_ERROR,
  LOAD_STATISTICS_FILES_LIST_SUCCESS,
  LOAD_STATS_ERROR,
  LOAD_STATS_FOR_ONE_GAME,
  LOAD_STATS_FOR_ONE_GAME_SUCCESS,
  LOAD_STATS_MATCH_GOALTENDER_INFO,
  LOAD_STATS_MATCH_GOALTENDER_INFO_ERROR,
  LOAD_STATS_MATCH_GOALTENDER_INFO_SUCCESS,
  LOAD_STATS_MATCH_SHIFTS,
  LOAD_STATS_MATCH_SHIFTS_ERROR,
  LOAD_STATS_MATCH_SHIFTS_SUCCESS,
} from '../ActionTypes.js';

const initialState = {
  gameStatistics: {
    loading: false,
    error: {},
    data: {},
  },
  mapInfo: {
    loading: false,
    error: {},
    data: {},
  },
  matchPlayersStats: {
    loading: false,
    error: {},
    data: {},
  },
  matchShifts: {
    loading: false,
    isLoad: false,
    error: {},
    goals: [],
    shifts: [],
    players: [],
  },
  goaltenderInfo: {
    loading: false,
    isLoad: false,
    error: '',
    data: {
      gaps: [],
      gatesMap: {},
      topStats: [],
      shotsByZones: {},
    },
  },
  filesList: {
    loading: false,
    error: {},
    data: {},
  },
};

const StatsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_STATS_FOR_ONE_GAME:
      return {
        ...state,
        gameStatistics: {
          loading: true,
        },
      };

    case LOAD_STATS_FOR_ONE_GAME_SUCCESS:
      return {
        ...state,
        loading: false,
        gameStatistics: {...action.gameStatistics, loading: false},
      };

    case CLEAR_STATS_FOR_ONE_GAME:
      return {
        ...state,
        gameStatistics: {...initialState.gameStatistics},
      };

    case LOAD_STATS_ERROR:
      return {
        ...state,
        gameStatistics: {
          ...initialState.gameStatistics,
          loading: false,
          error: action.payload,
        },
      };

    case LOAD_STATS_MATCH_SHIFTS:
      return {
        ...state,
        matchShifts: {
          ...initialState.matchShifts,
          loading: true,
        },
      };

    case LOAD_STATS_MATCH_SHIFTS_SUCCESS:
      return {
        ...state,
        matchShifts: {...initialState.matchShifts, ...action.matchShifts, isLoad: true, isLoading: false},
      };

    case CLEAR_STATS_MATCH_SHIFTS:
      return {
        ...state,
        matchShifts: {...initialState.matchShifts},
      };

    case LOAD_STATS_MATCH_SHIFTS_ERROR:
      return {
        ...state,
        matchShifts: {
          ...initialState.matchShifts,
          isLoad: true,
          error: action.payload,
        },
      };

    case LOAD_STATS_MATCH_GOALTENDER_INFO:
      return {
        ...state,
        goaltenderInfo: {
          ...initialState.goaltenderInfo,
          loading: true,
        },
      };

    case LOAD_STATS_MATCH_GOALTENDER_INFO_SUCCESS:
      return {
        ...state,
        goaltenderInfo: {
          ...initialState.goaltenderInfo,
          data: {...state.goaltenderInfo.data, ...action.payload},
          isLoad: true,
        },
      };

    case LOAD_STATS_MATCH_GOALTENDER_INFO_ERROR:
      return {
        ...state,
        goaltenderInfo: {
          ...initialState.goaltenderInfo,
          isLoad: true,
          error: 'Ошибка при загрузке',
        },
      };

    case CLEAR_STATS_MATCH_GOALTENDER_INFO:
      return {
        ...state,
        goaltenderInfo: {...initialState.goaltenderInfo},
      };

    case LOAD_STATISTICS_FILES_LIST:
      return {
        ...state,
        filesList: {
          ...state.filesList,
          loading: true,
        },
      };

    case LOAD_STATISTICS_FILES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        filesList: {...action.filesList, loading: false},
      };

    case LOAD_STATISTICS_FILES_LIST_ERROR:
      return {
        ...state,
        filesList: {
          ...state.filesList,
          loading: false,
          error: action.payload,
        },
      };

    case CLEAR_STATISTICS_FILES_LIST:
      return {
        ...state,
        filesList: {...initialState.filesList},
      };

    default:
      return state;
  }
};

export default StatsReducer;
