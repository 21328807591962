import {mock} from '@components/apps/GameStatisticsParsingCheck/components/ShortShifts/mock';
import TableComponent from '@components/apps/GameStatisticsParsingCheck/components/TableComponent';
import {borderRightOptions} from '@components/apps/GameStatisticsParsingCheck/constants';
import {getDuration} from '@components/apps/GameStatisticsParsingCheck/helpers';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useDeepMemo from '@hooks/useDeepMemo';
import {selectGameStatisticsParsingCheckTabs} from '@selectors/gameStatisticsParsingCheck';
import {longDash} from '@src/constants';
import {Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

LongShifts.propTypes = {
  tab: PropTypes.string.isRequired,
};

function LongShifts({tab}) {
  const {data, isLoading, isLoad, hasError} = useSelector(
    (state) => selectGameStatisticsParsingCheckTabs(state, tab),
    shallowEqual,
  );

  const columns = useMemo(
    () => [
      {
        id: 'player',
        accessorKey: 'player',
        header: 'Игрок',
        sticky: {left: true},
        cell: (params) => {
          const student = params?.getValue();
          return (
            <PlayersHorizontalCard
              hasImage={false}
              isLoading={isLoading}
              preloaderWidthBetween={[100, 170]}
              student={{...student, playerNumber: student?.number}}
            />
          );
        },
        ...borderRightOptions,
      },
      {
        id: 'duration',
        accessorKey: 'duration',
        header: 'Продолжительность смены',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
        ...borderRightOptions,
      },
      {
        id: 'startTime',
        accessorKey: 'startTime',
        header: 'Время начала',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
      {
        id: 'startVideoTime',
        accessorKey: 'startVideoTime',
        header: 'Видеотайм начала ',
        ...borderRightOptions,
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
      {
        id: 'endTime',
        accessorKey: 'endTime',
        header: 'Время конца',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
      {
        id: 'endVideoTime',
        accessorKey: 'endVideoTime',
        header: 'Видеотайм конца',
        ...borderRightOptions,
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
    ],
    [isLoading],
  );
  const rows = useDeepMemo(
    () =>
      (isLoading ? mock : data).map((item, id) => {
        return {
          id,
          player: {
            firstName: item?.playerFirstName,
            lastName: item?.playerLastName,
            number: item?.playerNumber,
          },
          duration: getDuration(item),
          startTime: item?.timeStart,
          startVideoTime: item?.videoStart,
          endTime: item?.timeEnd,
          endVideoTime: item?.videoEnd,
        };
      }),
    [isLoading, data],
  );

  return (
    <TableComponent
      columns={columns}
      data={rows}
      hasError={hasError || !data?.length}
      isLoad={isLoad}
      isLoading={isLoading}
      tab={tab}
    />
  );
}

export default LongShifts;
