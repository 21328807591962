import {deleteStatsFile, getStatisticsGamesList, postStatsFile} from '@api/StatsAPI';
import ContentWrapper from '@common/ContentWrapper';
import DeleteModal from '@common/DeleteModal';
import Field from '@common/FormField';
import LoadingBox from '@common/LoadingBox';
import date from '@helpers/date';
import {useGroup, useSchool} from '@hooks/filters';
import useDeepEffect from '@hooks/useDeepEffect';
import AddIcon from '@mui/icons-material/Add';
import {ListSubheader} from '@mui/material';
import {userRoles} from '@src/constants';
import {parseFormToGetValue, validateUrl} from '@src/helpers';
import {Box, Divider, Stack} from '@ui/MUI';
import Button from '@ui/MUI/Button';
import TypographyDefault from '@ui/MUI/Typography';
import {isEmpty} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';

import {getFormData} from '../../apps/AddStats/helpers';
import {AddStatsFormContainer, Form, FormButtonContainer} from './AddStatsForm';

const gameTypes = {
  current: {
    label: 'Последний месяц',
    value: 'current',
  },
  rest: {
    label: 'Остальные прошедшие игры',
    value: 'rest',
  },
};
const initialPagination = {limit: 20, total: 0};
const monthAgo = date().currentDate.subtract(1, 'month').format('DD.MM.YY');

const AddStatsForm = ({formData = {}, isEdit = false, isLoading = false, onSuccess = () => {}}) => {
  const history = useHistory();
  const {handleSubmit, watch, reset, ...form} = useForm();
  const selectedSchool = watch('school');
  const selectedGroup = watch('group');
  const selectedStatistics = watch('statistics');

  const schools = useSchool({allSchoolsPermissionRoles: [userRoles.video_analyst]});
  const groups = useGroup({connectorValue: selectedSchool?.value});

  const [games, setGames] = useState([]);
  const [pagination, setPagination] = useState(initialPagination);
  const [isButtonBlocked, setIsButtonBlocked] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    if (selectedGroup?.value) {
      getStatisticsGamesList({
        teamId: selectedGroup?.value,
        limit: pagination?.limit,
      }).then((res) => {
        const result = res.data?.result?.map((item) => {
          const isBefore = date(monthAgo, 'DD.MM.YY').valueOf() <= date(item?.date, 'DD.MM.YY').valueOf();
          return {
            ...item,
            type: isBefore ? gameTypes.current.value : gameTypes.rest.value,
          };
        });
        setPagination((prevState) => ({...prevState, total: res.data?.total || 0}));
        setGames(result || []);
      });
    }
    if (!selectedGroup?.value && games?.length) {
      setPagination(initialPagination);
      setGames([]);
    }
  }, [selectedGroup?.value, pagination?.limit]);

  useDeepEffect(() => {
    if (!isEmpty(formData)) {
      const {file, school, group, gameInfo, linkToVideo, linkToVideoHighlights} = formData;
      reset({
        statistics: {...file, value: file.link, name: file.fileName},
        school,
        group: {value: group.id, label: group.name},
        statisticId: gameInfo,
        linkToVideo,
        linkToVideoHighlights,
      });
    }
  }, [formData]);

  const addStatsFormSubmit = useCallback(
    (form) => {
      setIsButtonBlocked(true);
      postStatsFile(
        getFormData({
          ...parseFormToGetValue(form),
          statisticId: form?.statisticId?.value,
          statistics: form?.statistics,
          linkToVideoHighlights: form?.linkToVideoHighlights || null,
        }),
      )
        .then(() => {
          onSuccess && onSuccess();
        })
        .finally(() => {
          !isEdit && reset();
          setIsButtonBlocked(false);
        });
    },
    [isEdit],
  );
  return (
    <ContentWrapper p="0">
      <LoadingBox isLoading={isLoading || isButtonBlocked}>
        <AddStatsFormContainer p={{xxs: 2, md: 4}}>
          <FormProvider {...form}>
            <Form onSubmit={handleSubmit(addStatsFormSubmit)}>
              <TypographyDefault gutterBottom component="h4" variant="h4">
                Данные об игре
              </TypographyDefault>
              <Stack direction={{xxs: 'column', sm: 'row'}} flexWrap={'wrap'} gap={2}>
                <Box minWidth={{xxs: '100%', xs: 290}}>
                  <Field
                    isRequired
                    disabled={isEdit}
                    isLoading={schools?.isLoading}
                    label="Школа"
                    name="school"
                    options={schools?.options}
                    onChange={() => {
                      reset((formValues) => ({...formValues, statisticId: null, group: null}));
                    }}
                  />
                </Box>
                <Box minWidth={{xxs: '100%', xs: 290}}>
                  <Field
                    isRequired
                    disabled={!selectedSchool || isEdit}
                    isLoading={groups?.isLoading}
                    label="Команда"
                    name="group"
                    options={groups?.options}
                    onChange={() => {
                      reset((formValues) => ({...formValues, statisticId: null}));
                    }}
                  />
                </Box>
                <Box minWidth={{xxs: '100%', xs: 290}}>
                  <Field
                    isRequired
                    ListboxProps={{
                      onScroll: (event) => {
                        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
                        if (scrollTop + clientHeight === scrollHeight) {
                          if (pagination?.limit < pagination?.total)
                            setPagination(({total, limit}) => ({
                              total,
                              limit: limit + (limit + 10 <= total ? 10 : total - limit),
                            }));
                        }
                      },
                    }}
                    disabled={!selectedGroup}
                    groupBy={(option) => option.type}
                    label="Игра"
                    multiple={false}
                    name="statisticId"
                    options={games}
                    renderGroup={(params) => (
                      <>
                        <ListSubheader
                          sx={(theme) => ({
                            top: '-8px',
                            py: theme.spacing(0),
                          })}
                        >
                          <TypographyDefault variant="subtitle1">{gameTypes[params?.group].label}</TypographyDefault>
                        </ListSubheader>
                        {params.children}
                      </>
                    )}
                    renderOption={(props, option) => (
                      <Stack
                        alignItems="flex-start !important"
                        component="li"
                        direction="row"
                        gap={0}
                        {...props}
                        key={option.value}
                      >
                        <TypographyDefault color="primary" variant="body1">
                          {option.date}
                        </TypographyDefault>
                        <TypographyDefault variant="body1">{option.label}</TypographyDefault>
                      </Stack>
                    )}
                  />
                </Box>
              </Stack>

              <Divider orientation="horizontal" sx={{my: 5}} />
              <Box mb={5}>
                <TypographyDefault mb={2} variant="h4">
                  Статистика
                </TypographyDefault>
                <Field
                  isRequired
                  accept=".csv"
                  color="primary"
                  label="Добавить файл"
                  name="statistics"
                  showButtonWhenFileSelected={false}
                  size="medium"
                  startIcon={!selectedStatistics && <AddIcon />}
                  type="upload"
                  validate={(value) => {
                    if (value.type !== 'text/csv' && !(selectedStatistics?.link && isEdit))
                      return 'Неверный формат файла!';
                  }}
                  variant="outlined"
                />
              </Box>

              <Box mb={5}>
                <TypographyDefault variant="h4">Видео игры</TypographyDefault>
                <Box maxWidth={{xxs: '100%', xs: 480}}>
                  <Field
                    isRequired
                    label="Ссылка"
                    name="linkToVideo"
                    type="input"
                    validate={(value) => {
                      if (value && !validateUrl(value)) return 'Введите корректную ссылку';
                    }}
                  />
                </Box>
              </Box>

              <TypographyDefault variant="h4">Хайлайты</TypographyDefault>
              <TypographyDefault variant="caption2">Опционально</TypographyDefault>
              <Box maxWidth={{xxs: '100%', xs: 480}}>
                <Field
                  label="Ссылка"
                  name="linkToVideoHighlights"
                  type="input"
                  validate={(value) => {
                    if (value && !validateUrl(value)) return 'Введите корректную ссылку';
                  }}
                />
              </Box>

              <FormButtonContainer
                mb={{xxs: -2, md: -4}}
                mt={{xxs: '40px', md: '160px'}}
                mx={{xxs: -2, md: -4}}
                px={{xxs: 2, md: 4}}
                py={4}
              >
                <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between">
                  <Stack direction="row" gap={2}>
                    {isEdit && (
                      <>
                        <Button
                          color="secondary"
                          disabled={isLoading || isButtonBlocked}
                          variant="contained"
                          onClick={() => history.push('/edit_stats')}
                        >
                          Отменить
                        </Button>
                        <Button
                          color="primary"
                          disabled={isLoading || isButtonBlocked}
                          variant="outlined"
                          onClick={() => setIsDeleteModalVisible(true)}
                        >
                          Удалить
                        </Button>
                      </>
                    )}
                  </Stack>

                  <Button color="primary" disabled={isLoading || isButtonBlocked} type="submit" variant="contained">
                    Сохранить
                  </Button>
                </Stack>
              </FormButtonContainer>
            </Form>
          </FormProvider>
          <DeleteModal
            isVisible={isDeleteModalVisible}
            text="Вы уверены, что хотите удалить файл статистики?"
            title="Удаление файла статистики"
            onCancel={() => setIsDeleteModalVisible(false)}
            onDelete={() => {
              if (formData.id) {
                setIsButtonBlocked(true);
                deleteStatsFile(formData.id)
                  .then(() => {
                    history.push('/edit_stats');
                  })
                  .finally(() => {
                    setIsButtonBlocked(false);
                    setIsDeleteModalVisible(false);
                  });
              } else {
                setIsDeleteModalVisible(false);
              }
            }}
          />
        </AddStatsFormContainer>
      </LoadingBox>
    </ContentWrapper>
  );
};

export default AddStatsForm;
