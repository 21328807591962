import React from 'react';
import {FormProvider, useFormContext} from 'react-hook-form';

function SeasonField() {
  const form = useFormContext();

  return <FormProvider {...form}></FormProvider>;
}

export default SeasonField;
