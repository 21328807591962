import {Tab, Tabs} from '@mui/material';
import React, {memo, useEffect, useMemo} from 'react';
import styled from 'styled-components';

import {useMaterialTabs} from '../../hooks';

const StyledTabs = styled(Tabs)`
  .MuiTab-root {
    color: white;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    font-weight: 600;
    min-width: 0;
    padding: 4px 12px;
  }
`;

const ControlledTabs = ({value, onChange}) => {
  const {data, isLoading, refetch} = useMaterialTabs();

  useEffect(() => {
    if (!data.length && !isLoading) {
      refetch();
    }
  }, [data, isLoading]);

  const formatedData = useMemo(() => {
    if (data.length) return [{name: 'Все', slug: 'all'}, ...data];
    return data;
  }, [data]);

  return (
    <StyledTabs
      scrollButtons="auto"
      sx={{}}
      value={value}
      variant="scrollable"
      onChange={(_, value) => {
        onChange && onChange(value);
      }}
    >
      {formatedData.map((tab) => (
        <Tab key={tab.slug} label={tab.name} value={tab.slug} />
      ))}
    </StyledTabs>
  );
};

export default memo(ControlledTabs);
