import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {useDynamicsGraphMarkPoints} from '@components/apps/PlayerPersonalFile/hooks/functionalTestting/useDynamicsGraphMarkPoints';
import LineChart from '@components/charts/LineChart';
import {useSeasons} from '@hooks/seasonsHooks';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectFuncInjuryHistory, selectFuncTestingStatuses} from '@selectors/playerPersonalFile';
import {clearFuncTestingStatuses, loadFuncTestingStatuses} from '@slices/playerPersonalFile';
import {Autocomplete, Box, Divider, Stack, Typography} from '@ui/MUI';
import {darkRed, green, grey, yellow} from '@ui/MUI/colors';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

const axisCommonConf = {
  splitLine: {
    show: true,
    lineStyle: {
      color: grey['12'],
    },
  },
  axisTick: {
    show: false,
  },
  axisLine: {
    show: false,
  },
};
const graphParams = [
  {
    label: 'Оптимальное',
    colors: [green['70'], green['70']],
  },
  {
    label: 'Оптимальное/Напряжение',
    colors: [green['70'], yellow['60']],
  },
  {
    label: 'Напряжение',
    colors: [yellow['60']],
  },
  {
    label: 'Напряжение/Переутомление',
    colors: [yellow['60'], darkRed['65']],
  },
  {
    label: 'Переутомление',
    colors: [darkRed['65']],
  },
  {
    label: 'Перетренирова...',
    colors: [grey['78']],
  },
];

function DynamicsStatuses() {
  const [seasonsFilter, setSeasonsFilter] = useState(null);
  const {options, ...seasonsData} = useSeasons(false, ({currentSeason}) => setSeasonsFilter([currentSeason]));

  const {id} = useParams();
  const dispatch = useStoreDispatch();
  const {
    data: {dates, values},
  } = useStore(selectFuncTestingStatuses);
  const {
    data: {responseData},
  } = useStore(selectFuncInjuryHistory);

  const markPoints = useDynamicsGraphMarkPoints(responseData, values);

  useDeepEffect(() => {
    if (seasonsFilter?.length && id) {
      const season = seasonsFilter.map((item) => item.value).join(',');
      dispatch(loadFuncTestingStatuses({playerId: id, season, order: 'ASC', sort: 'date'}));
    }
    return () => {
      dispatch(clearFuncTestingStatuses());
    };
  }, [id, seasonsFilter]);

  return (
    <Wrapper p="0">
      <Box px={4} py={3}>
        <Typography variant="h4">Динамика статусов</Typography>
      </Box>
      <Divider />
      <Box pb={4} pt={2} px={4}>
        <Box pb={4} width={200}>
          <Autocomplete
            multiple
            isLoading={seasonsData?.isLoading}
            label="Сезоны"
            limitTags={1}
            options={options}
            value={seasonsFilter}
            onChange={(e, v) => setSeasonsFilter(v)}
          />
        </Box>
        <Stack alignItems="shrink" direction="row">
          <Stack direction="column" justifyContent="space-between" py="1%">
            {graphParams.map(({colors, label}, index) => (
              <Stack alignItems="center" direction="row" key={index} spacing={2}>
                <ColorIndicator color={colors} />
                <Typography variant="body2">{label}</Typography>
              </Stack>
            ))}
          </Stack>
          <LineChart
            options={{
              tooltip: {
                show: true,
                backgroundColor: grey['68'],
                borderColor: 'transparent',
                textStyle: {
                  color: '#fff',
                  fontSize: 12,
                  fontFamily: 'Proxima Nova',
                },
              },
              grid: {
                show: true,
                borderWidth: 0.5,
                shadowColor: grey['12'],
                left: 18,
                right: 18,
                top: 20,
                bottom: 20,
              },
              xAxis: {
                ...axisCommonConf,
                type: 'category',
                data: dates,
                boundaryGap: false,
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: grey['12'],
                  },
                },
              },
              yAxis: {
                ...axisCommonConf,
                axisLabel: {show: false},
                type: 'value',
                min: 1,
                max: 6,
              },

              series: [
                {
                  data: values,
                  type: 'line',
                  markPoint: {
                    data: markPoints,
                    symbol: 'roundRect',
                    symbolSize: [24, 18],
                  },
                  smooth: true,
                  color: darkRed['65'],
                  symbolSize: 7,
                },
              ],
            }}
            width="100%"
          />
        </Stack>
      </Box>
    </Wrapper>
  );
}

export default DynamicsStatuses;

const ColorIndicator = styled.div`
  background: linear-gradient(90deg, ${({color}) => `${color[0]} 50%, ${color?.[1] || color[0]} 50%`});
  border-radius: 50%;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
`;
