import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

export function getSchedule(params) {
  return axios.get(`${API_ENDPOINT}/v1/dashboard/manager/schedule`, {params});
}
export function getNextGames(params) {
  return axios.get(`${API_ENDPOINT}/v1/dashboard/manager/nextgames`, {params});
}
export function getPastGames(params) {
  return axios.get(`${API_ENDPOINT}/v1/dashboard/manager/pastgames`, {params});
}
export function getInjuredPlayers(params) {
  return axios.get(`${API_ENDPOINT}/v1/dashboard/manager/injuredplayers`, {params});
}
export function getMissedPlayers(params) {
  return axios.get(`${API_ENDPOINT}/v1/dashboard/manager/missingplayers`, {params});
}
export function getIllnessPlayers(params) {
  return axios.get(`${API_ENDPOINT}/v1/dashboard/manager/illnessplayers`, {params});
}
