import {useCurrentBreakPoint} from '@components/apps/MaterialsModule';
import useToggle from '@hooks/useToggle';
import {Box, Chip, Stack, Tooltip, Typography} from '@mui/material';
import {grey} from '@ui/MUI/colors';
import React, {useEffect, useRef} from 'react';

import {Container, Image} from './Card';

const MAX_TITLE_HEIGHT = 40; // px

const Card = ({
  width,
  height,
  onClick,
  padding,
  border,
  borderRadius,
  icon,
  image,
  title,
  description,
  chip,
  actionIconSlot,
  contentStyles,
}) => {
  const isOverFlow = useToggle();
  const titleRef = useRef(null);

  const breakpoint = useCurrentBreakPoint();

  useEffect(() => {
    const height = titleRef.current.clientHeight;
    if (height > MAX_TITLE_HEIGHT) isOverFlow.on();
  }, [titleRef]);

  return (
    <Container
      border={border}
      borderRadius={borderRadius}
      height={height}
      padding={padding}
      width={width}
      onClick={onClick}
    >
      <Stack>
        <Box>
          <Box mb={2}>{image ? <Image src={image} /> : icon}</Box>
          <Box {...contentStyles}>
            <Tooltip title={isOverFlow.isToggle && title}>
              <Typography
                ref={titleRef}
                sx={{
                  wordBreak: 'break-word',
                  ...(isOverFlow.isToggle && {
                    display: '-webkit-box',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    height: breakpoint === 'xs' ? '0px' : '40px',
                  }),
                }}
                variant="subtitle2"
              >
                {title}
              </Typography>
            </Tooltip>
            {description && (
              <Typography color={grey[38]} fontWeight="600" mt={0}>
                {description}
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        {chip && <Chip icon={chip.icon} label={chip.name} size="small" variant="outlined" />}
        {actionIconSlot && actionIconSlot}
      </Stack>
    </Container>
  );
};

export default Card;
