import {
  createGroupReport,
  createOuterReport,
  createUserReport,
  deleteGroupReport,
  deleteReport,
  editGroupReport,
  editReport,
  getGroupReport,
  getGroupReports,
  getInnerGroup,
  getInnerUserReports,
  getInnerUsers,
  getOuterSchool,
  getOuterSchoolGroup,
  getOuterUserReports,
  getOuterUsers,
  getReport,
} from '../../api/ScoutReportAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {SCOUT_REPORT_TYPES} from '../../constants';
import {NOTIFICATION_TYPES} from '../../constants/config';
import {
  ADD_USER,
  ADD_USER_ERROR,
  ADD_USER_SUCCESS,
  CLEAN_CLUBS,
  CLEAN_GROUP_REPORT,
  CLEAN_GROUP_REPORTS,
  CLEAN_GROUPS,
  CLEAN_REPORT,
  CLEAN_USER_REPORTS,
  CLEAN_USERS,
  CREATE_GROUP_REPORT,
  CREATE_GROUP_REPORT_ERROR,
  CREATE_GROUP_REPORT_SUCCESS,
  CREATE_REPORT,
  CREATE_REPORT_ERROR,
  CREATE_REPORT_SUCCESS,
  DELETE_GROUP_REPORT,
  DELETE_GROUP_REPORT_ERROR,
  DELETE_GROUP_REPORT_SUCCESS,
  DELETE_REPORT,
  DELETE_REPORT_ERROR,
  DELETE_REPORT_SUCCESS,
  EDIT_GROUP_REPORT,
  EDIT_GROUP_REPORT_ERROR,
  EDIT_GROUP_REPORT_SUCCESS,
  EDIT_REPORT,
  EDIT_REPORT_ERROR,
  EDIT_REPORT_SUCCESS,
  GET_CLUBS,
  GET_CLUBS_ERROR,
  GET_CLUBS_SUCCESS,
  GET_GROUP_REPORT,
  GET_GROUP_REPORT_ERROR,
  GET_GROUP_REPORT_SUCCESS,
  GET_GROUP_REPORTS,
  GET_GROUP_REPORTS_ERROR,
  GET_GROUP_REPORTS_SUCCESS,
  GET_GROUPS,
  GET_GROUPS_ERROR,
  GET_GROUPS_SUCCESS,
  GET_REPORT,
  GET_REPORT_ERROR,
  GET_REPORT_SUCCESS,
  GET_USER_REPORTS,
  GET_USER_REPORTS_ERROR,
  GET_USER_REPORTS_SUCCESS,
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
} from '../ActionTypes';

const errorMessage = (err, title = 'Ошибка при загрузке', type) => {
  if (err?.response?.data?.message) {
    ReactComponentNotification(type || NOTIFICATION_TYPES['error'], `${title}: ${err.response.data.message}`);
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

const successMessage = (title) => ReactComponentNotification(NOTIFICATION_TYPES.success, title);

export function getClubs(type, params = {}) {
  return (dispatch) => {
    dispatch({
      type: GET_CLUBS,
    });
    if (!type) return;
    return (type === SCOUT_REPORT_TYPES.inner ? getInnerGroup : getOuterSchool)({...params})
      .then((data) => {
        dispatch({
          type: GET_CLUBS_SUCCESS,
          payload: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при загрузке клубов');
        dispatch({
          type: GET_CLUBS_ERROR,
          payload: err,
        });
      });
  };
}

export function cleanClubs() {
  return (dispatch) => {
    dispatch({
      type: CLEAN_CLUBS,
    });
  };
}

export function getGroups(id) {
  return (dispatch) => {
    dispatch({
      type: GET_GROUPS,
    });
    return getOuterSchoolGroup(id)
      .then((data) => {
        dispatch({
          type: GET_GROUPS_SUCCESS,
          groups: data.data.groups,
          school: data.data.school,
        });
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при загрузке');
        dispatch({
          type: GET_GROUPS_ERROR,
          payload: err,
        });
      });
  };
}

export function cleanGroups() {
  return (dispatch) => {
    dispatch({
      type: CLEAN_GROUPS,
    });
  };
}

export function getUsers(id, type, params) {
  return (dispatch) => {
    dispatch({
      type: GET_USERS,
    });
    if (!type) return;
    return (type === SCOUT_REPORT_TYPES.inner ? getInnerUsers : getOuterUsers)(id, params)
      .then((result) => {
        const {data} = result;
        dispatch({
          type: GET_USERS_SUCCESS,
          users: data?.students || {},
          school: data?.school || {},
          group: data?.group || {},
          permissions: data?.permissions || {},
        });
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при загрузке учеников');
        dispatch({
          type: GET_USERS_ERROR,
          payload: err,
        });
      });
  };
}

export function cleanUsers() {
  return (dispatch) => {
    dispatch({
      type: CLEAN_USERS,
    });
  };
}

export function addUser(data, callback = () => {}, hockeyRole = '') {
  return (dispatch) => {
    dispatch({
      type: ADD_USER,
    });
    return createOuterReport(data)
      .then((result) => {
        const {data} = result;
        dispatch({
          type: ADD_USER_SUCCESS,
          payload: data,
          hockeyRole,
        });
        successMessage('Игрок успешно добавлен');
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при добавлении игрока');
        dispatch({
          type: ADD_USER_ERROR,
          payload: err,
        });
      })
      .finally(() => callback());
  };
}

export function getUserReports(studentId, type, params) {
  return (dispatch) => {
    dispatch({
      type: GET_USER_REPORTS,
    });
    if (!type) return;
    return (type === SCOUT_REPORT_TYPES.inner ? getInnerUserReports : getOuterUserReports)(studentId, params)
      .then((result) => {
        const {
          data: {reports, group, school, student, permissions, total},
        } = result;
        dispatch({
          type: GET_USER_REPORTS_SUCCESS,
          reports,
          group,
          school,
          student,
          permissions,
          total,
        });
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при загрузке отчетов');
        dispatch({
          type: GET_USER_REPORTS_ERROR,
          payload: err,
        });
      });
  };
}

export function cleanUserReports() {
  return (dispatch) => {
    dispatch({
      type: CLEAN_USER_REPORTS,
    });
  };
}

export function createReport(data, callback = () => {}, onSuccess = () => {}, reportType) {
  return (dispatch) => {
    dispatch({
      type: CREATE_REPORT,
    });
    return createUserReport(reportType, data)
      .then(() => {
        dispatch({
          type: CREATE_REPORT_SUCCESS,
        });
        onSuccess();
        successMessage('Новый отчет добавлен');
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при создании отчета');
        dispatch({
          type: CREATE_REPORT_ERROR,
          payload: err,
        });
      })
      .finally(() => callback());
  };
}

export function getCurrentReport(reportId) {
  return (dispatch) => {
    dispatch({
      type: GET_REPORT,
    });
    return getReport(reportId)
      .then((result) => {
        const {data} = result;
        dispatch({
          type: GET_REPORT_SUCCESS,
          report: data?.report,
          student: data?.student,
          group: data?.group || {},
          school: data?.school || {},
          permissions: data?.permissions || {},
        });
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при загрузке отчета');
        dispatch({
          type: GET_REPORT_ERROR,
          payload: err,
        });
      });
  };
}

export function cleanCurrentReport() {
  return (dispatch) => {
    dispatch({
      type: CLEAN_REPORT,
    });
  };
}

export function editCurrentReport(reportId, data) {
  return (dispatch) => {
    dispatch({
      type: EDIT_REPORT,
    });
    return editReport(reportId, data)
      .then((result) => {
        dispatch({
          type: EDIT_REPORT_SUCCESS,
          payload: result.data,
        });
        successMessage('Отчет отредактирован');
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при редактировании отчета');
        dispatch({
          type: EDIT_REPORT_ERROR,
        });
      });
  };
}

export function deleteCurrentReport(reportId, onSuccess = () => {}) {
  return (dispatch) => {
    dispatch({
      type: DELETE_REPORT,
    });
    return deleteReport(reportId)
      .then(() => {
        dispatch({
          type: DELETE_REPORT_SUCCESS,
        });
        successMessage('Отчет удален');
        onSuccess();
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при удалении отчета');
        dispatch({
          type: DELETE_REPORT_ERROR,
          payload: err,
        });
      });
  };
}

export function getCurrentGroupReports(params) {
  return (dispatch) => {
    dispatch({
      type: GET_GROUP_REPORTS,
    });
    return getGroupReports(params)
      .then((result) => {
        const {
          data: {reports, group, permissions, total},
        } = result;
        dispatch({
          type: GET_GROUP_REPORTS_SUCCESS,
          reports,
          group,
          permissions,
          total: total || 0,
        });
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при загрузке отчетов');
        dispatch({
          type: GET_GROUP_REPORTS_ERROR,
          payload: err,
        });
      });
  };
}

export function cleanCurrentGroupReports() {
  return (dispatch) => {
    dispatch({
      type: CLEAN_GROUP_REPORTS,
    });
  };
}

export function createGroupNewReport(data, callback = () => {}, onSuccess = () => {}) {
  return (dispatch) => {
    dispatch({
      type: CREATE_GROUP_REPORT,
    });
    return createGroupReport(data)
      .then(() => {
        dispatch({
          type: CREATE_GROUP_REPORT_SUCCESS,
        });
        onSuccess();
        successMessage('Новый отчет добавлен');
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при создании отчета');
        dispatch({
          type: CREATE_GROUP_REPORT_ERROR,
          payload: err,
        });
      })
      .finally(() => callback());
  };
}

export function getCurrentGroupReport(reportId) {
  return (dispatch) => {
    dispatch({
      type: GET_GROUP_REPORT,
    });
    return getGroupReport(reportId)
      .then((result) => {
        const {data} = result;
        dispatch({
          type: GET_GROUP_REPORT_SUCCESS,
          report: data?.report,
          group: data?.group || {},
          permissions: data?.permissions || {},
        });
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при загрузке отчета');
        dispatch({
          type: GET_GROUP_REPORT_ERROR,
          payload: err,
        });
      });
  };
}

export function cleanCurrentGroupReport() {
  return (dispatch) => {
    dispatch({
      type: CLEAN_GROUP_REPORT,
    });
  };
}

export function editCurrentGroupReport(reportId, data) {
  return (dispatch) => {
    dispatch({
      type: EDIT_GROUP_REPORT,
    });
    return editGroupReport(reportId, data)
      .then((result) => {
        dispatch({
          type: EDIT_GROUP_REPORT_SUCCESS,
          payload: result.data,
        });
        successMessage('Отчет отредактирован');
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при редактировании отчета');
        dispatch({
          type: EDIT_GROUP_REPORT_ERROR,
        });
      });
  };
}

export function deleteCurrentGroupReport(reportId, onSuccess = () => {}) {
  return (dispatch) => {
    dispatch({
      type: DELETE_GROUP_REPORT,
    });
    return deleteGroupReport(reportId)
      .then(() => {
        dispatch({
          type: DELETE_GROUP_REPORT_SUCCESS,
        });
        successMessage('Отчет удален');
        onSuccess();
      })
      .catch(function (err) {
        errorMessage(err, 'Ошибка при удалении отчета');
        dispatch({
          type: DELETE_GROUP_REPORT_ERROR,
          payload: err,
        });
      });
  };
}
