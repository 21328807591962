import ButtonDefault from '@ui/MUI/Button';
import React from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';

import SkillList from './components/SkillList';

const Skills = () => {
  const {fields, append, remove} = useFieldArray({
    name: 'skills',
    key: 'innerId',
    shouldUnregister: true,
  });

  const {
    getValues,
    formState: {isValid, isDirty},
  } = useFormContext();

  const handleCreate = () => {
    const value = getValues();
    append(value);
  };

  const canCreate = isValid && isDirty;

  return (
    <>
      <div>
        <ButtonDefault color="primary" disabled={!canCreate} onClick={handleCreate}>
          сформировать навык
        </ButtonDefault>
      </div>
      <SkillList fields={fields} remove={remove} />
    </>
  );
};
export default Skills;
