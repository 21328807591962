import React from 'react';

import useResize from '../../../../../hooks/useResize';
import GoaltendersTable from './components/GoaltendersTable';
import PlayersTable from './components/PlayersTable';

export default function EstimationTableForStudent(props) {
  const size = useResize();
  const {gameEstimateReport} = props;

  return (
    <>
      {gameEstimateReport?.student?.hockeyRole !== 'goaltender' ? (
        <PlayersTable gameEstimateReport={gameEstimateReport} size={size} />
      ) : (
        <GoaltendersTable gameEstimateReport={gameEstimateReport} size={size} />
      )}
    </>
  );
}
