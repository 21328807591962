import {longDash} from '@src/constants';
import Typography from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled, {css} from 'styled-components';

function PlayTimeCell({value, hasRegress, isLoading, preloaderWidthBetween = [50, 80]}) {
  return (
    <Value
      hasregress={!isLoading && hasRegress}
      isLoading={isLoading}
      preloaderWidthBetween={preloaderWidthBetween}
      variant={hasRegress ? 'subtitle2' : 'body2'}
    >
      {value || longDash}
    </Value>
  );
}

PlayTimeCell.propTypes = {
  value: PropTypes.string,
  hasRegress: PropTypes.bool,
};

export default PlayTimeCell;

const Value = styled(Typography)`
  position: relative;
  display: inline-block;
  padding-right: ${({theme}) => theme.spacing(3)};
  ${(props) =>
    props.hasregress &&
    css`
      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 35%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 5px 0 5px;
        border-color: ${({theme}) => theme.palette.primary.main} transparent transparent transparent;
      }
    `}
`;
