import {bytesToMb} from '@helpers/index';
import {Button} from '@mui/material';
import Chip from '@ui/MUI/Chip';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React, {useState} from 'react';

// вынести логику в хук
const MultiFileUpload = ({onChange, value = [], label, max, accept, maxSizeMb}) => {
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const files = e.target.files;
    const formated = Object.keys(files).map((key) => files[key]);

    e.target.value = '';
    if (max && formated.length > max) {
      setError(`Выберите меньше ${max} файлов `);
      return;
    }

    if (maxSizeMb) {
      const size = bytesToMb(formated.reduce((prev, curr) => prev + curr.size, 0));
      if (size > maxSizeMb) {
        setError(`Выберите файл(ы) меньше ${maxSizeMb} mb`);
        return;
      }
    }

    if (error) setError('');
    onChange && onChange(formated);
  };

  const handleDeleteFile = (file) => {
    const name = file.name;
    const filteredFiles = value.filter((file) => file.name !== name);
    onChange && onChange(filteredFiles);
  };

  return (
    <Stack spacing={2}>
      {value.length !== 0 && (
        <Stack direction="row" flexWrap="wrap" gap={1}>
          {value.map((file) => (
            <Chip
              key={file.name}
              label={file.name}
              sx={{maxWidth: '220px'}}
              variant="outlined"
              onDelete={() => handleDeleteFile(file)}
            />
          ))}
        </Stack>
      )}
      <div>
        <Button color="primary" component="label">
          {label}
          <input hidden multiple accept={accept} type="file" onChange={handleChange} />
        </Button>
        {error && (
          <TypographyDefault color="error" mt={1}>
            {error}
          </TypographyDefault>
        )}
      </div>
    </Stack>
  );
};

export default MultiFileUpload;
