import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const GroupsAPI = {
  async getAllGroupsV2(params) {
    return await axios.get(`${API_ENDPOINT}/v1/groups`, {params});
  },

  getAllGroups() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/groups/all`)
        .then((d) => d.data)
        .then((exercises) => {
          if (exercises.error !== undefined) {
            return reject(exercises.error);
          }
          resolve(exercises);
        })
        .catch((err) => reject(err));
    });
  },

  getGroupById(id) {
    return axios.get(`${API_ENDPOINT}/v1/groups/${id}?isActive=${true}`);
  },

  createGroup(group) {
    return axios.post(`${API_ENDPOINT}/v1/groups`, group);
  },

  updateGroup(groupId, group) {
    return axios.put(`${API_ENDPOINT}/v1/groups/${groupId}`, group);
  },
  deleteGroup(groupId) {
    return axios.delete(`${API_ENDPOINT}/v1/groups/${groupId}`);
  },

  getGroupStatsMetrics() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/statistics/metrics`)
        .then((d) => d.data)
        .then((metrics) => {
          if (metrics.error !== undefined) {
            return reject(metrics.error);
          }
          resolve(metrics);
        })
        .catch((err) => reject(err));
    });
  },

  getGroupStatistics(filter) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${API_ENDPOINT}/statistics?metric=all&schoolId=${filter.schoolId}&teamId=${filter.teamId}&season=${filter.season}&timeStart=${filter.timeStart}&timeEnd=${filter.timeEnd}&hockeyRole=${filter.hockeyRole}&isActive=${filter.isActive}`,
        )
        .then((d) => d.data)
        .then((stats) => {
          if (stats.error !== undefined) {
            return reject(stats.error);
          }
          resolve(stats);
        })
        .catch((err) => reject(err));
    });
  },
  getGroupRate(filter, isActive) {
    return axios.get(`${API_ENDPOINT}/rating/${filter.season}/${filter.group}`, {
      params: {isActive},
    });
  },

  getGroupsTeachers(groups) {
    return axios
      .get(`${API_ENDPOINT}/v1/groups/teachers`, {params: {groups}})
      .then((res) => res.data.map((item) => ({name: item.fullName, id: item.id})));
  },

  getGroupsStudents(groups) {
    return axios.get(`${API_ENDPOINT}/v1/groups/students`, {params: {groups}}).then((res) => res.data);
  },
};

export function getAges() {
  return axios.get(`${API_ENDPOINT}/v1/groups/ages`);
}

export default GroupsAPI;
