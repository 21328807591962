import {FACELESS_AVATAR} from '@src/constants/config';
import {convertImgTo200} from '@src/helpers';
import moment from 'moment';
import React, {useMemo} from 'react';
import styled from 'styled-components';

import ColoredElement from '../ColoredElement/ColoredElement';
import DashboardsBlock from '../dashboards/trainers-dashboard/components/DashboardsBlock';
import Grid from '../Grid/Grid';

const Content = (props) => {
  const {formatedGame, formatedLines, getOneLine, isLoading} = props;

  return (
    <Container>
      <>
        <NextGameWrapper>
          {(formatedGame?.fullDate || formatedGame?.startTime) && (
            <Grid>
              <NextGameFullDate>{formatedGame?.fullDate || '-'}</NextGameFullDate>
              <NextGameTime>{formatedGame?.startTime || '-'}</NextGameTime>
            </Grid>
          )}
          <NextGameTitle>{formatedGame?.title || 'Ближайшая игра отсутствует'}</NextGameTitle>
        </NextGameWrapper>
        <Grid direction="column" padding={!formatedLines && '0px 0px 0px 34px'}>
          {isLoading && 'Загрузка...'}
          {!isLoading && !getOneLine && 'Вас не добавили в состав'}
          {formatedLines && (
            <>
              <Grid direction="column">
                <Header>Атакующая линия</Header>
                <PlayersWrapper>
                  {!formatedLines?.wingers?.length === 0 && 'Игроки отсутствуют'}
                  {/*{TODO оставить на тот случай, если данные по звеньям будут приходить с бэка}*/}
                  {/*{formatedLines?.wingers?.map(*/}
                  {/*  (line, lineNumber) =>*/}
                  {/*    line.length !== 0 && (*/}
                  {/*      <Grid>*/}
                  {/*        {line.map(({ avatar, playerNumber, firstName, lastName }) => (*/}
                  {/*          <PlayerWrapper>*/}
                  {/*            <Grid>*/}
                  {/*              <PlayerAvatar src={convertImgTo200(avatar)} />*/}
                  {/*            </Grid>*/}
                  {/*            <PlayerName>*/}
                  {/*              <ColoredElement color="#887755" marginRight="3px">*/}
                  {/*                {playerNumber}*/}
                  {/*              </ColoredElement>*/}
                  {/*              {firstName} {lastName}*/}
                  {/*            </PlayerName>*/}
                  {/*          </PlayerWrapper>*/}
                  {/*        ))}*/}
                  {/*      </Grid>*/}
                  {/*    ),*/}
                  {/*)}*/}
                  {getOneLine?.wingersLine?.map(
                    (line, lineNumber) =>
                      line?.length !== 0 && (
                        <Grid key={lineNumber}>
                          <PlayerWrapper>
                            <Grid>
                              <PlayerAvatar src={line?.avatar ? convertImgTo200(line?.avatar) : FACELESS_AVATAR} />
                            </Grid>
                            <PlayerName>
                              <ColoredElement color="#887755" marginRight="3px">
                                {line?.playerNumber}
                              </ColoredElement>
                              {line?.firstName} {line?.lastName}
                            </PlayerName>
                          </PlayerWrapper>
                        </Grid>
                      ),
                  )}
                </PlayersWrapper>
              </Grid>
              <Grid direction="column">
                <Header>Оборонительная линия</Header>
                <PlayersWrapper withOutPadding>
                  {!formatedLines?.defensemans?.length === 0 && 'Игроки отсутствуют'}
                  {getOneLine?.defensemansLine?.map(
                    (line, lineNumber) =>
                      line?.length !== 0 && (
                        <Grid key={lineNumber}>
                          <PlayerWrapper>
                            <Grid>
                              <PlayerAvatar src={line?.avatar ? convertImgTo200(line?.avatar) : FACELESS_AVATAR} />
                            </Grid>
                            <PlayerName>
                              <ColoredElement color="#887755" marginRight="3px">
                                {line?.playerNumber}
                              </ColoredElement>
                              {line?.firstName} {line?.lastName}
                            </PlayerName>
                          </PlayerWrapper>
                        </Grid>
                      ),
                  )}
                  {/*{TODO оставить на тот случай, если данные по звеньям будут приходить с бэка}*/}
                  {/*{formatedLines?.defensemans?.map(*/}
                  {/*  (line, lineNumber) =>*/}
                  {/*    line.length !== 0 && (*/}
                  {/*      <Grid marginBottom={lineNumber && '10px'}>*/}
                  {/*        {line.map(({ playerNumber, firstName, lastName, avatar }) => (*/}
                  {/*          <PlayerWrapper>*/}
                  {/*            <Grid>*/}
                  {/*              <PlayerAvatar src={avatar} />*/}
                  {/*            </Grid>*/}
                  {/*            <PlayerName>*/}
                  {/*              <ColoredElement color="#887755" marginRight="3px">*/}
                  {/*                {playerNumber}*/}
                  {/*              </ColoredElement>*/}
                  {/*              {firstName} {lastName}*/}
                  {/*            </PlayerName>*/}
                  {/*          </PlayerWrapper>*/}
                  {/*        ))}*/}
                  {/*      </Grid>*/}
                  {/*    ),*/}
                  {/*)}*/}
                </PlayersWrapper>
              </Grid>
            </>
          )}
        </Grid>
      </>
    </Container>
  );
};

const NextGame = (props) => {
  const {nextGame, group, linesForm, id, isMobile, currentUserId, isLoading} = props;

  const formatedGame = useMemo(() => {
    if (nextGame && group) {
      const {startTimestamp} = nextGame.rooms[0];
      const fullDate = moment(startTimestamp).format('DD.MM.YYYY');
      const startTime = moment(startTimestamp).format('HH:mm');
      const title = `${group.name} - ${nextGame.opponent}`;
      return {fullDate, startTime, title};
    }
    return null;
  }, [nextGame, group]);

  //TODO когда будет бэк, нужно будет убрать
  const getOneLine = useMemo(() => {
    if (linesForm) {
      const {playersLines} = linesForm;
      const temp = playersLines.find((line) => {
        return line.wingersLine.find((el) => {
          return el?.id?.includes(currentUserId);
        });
      });
      if (typeof temp === 'undefined') {
        return playersLines.find((line) => {
          return line.defensemansLine.find((el) => {
            return el?.id?.includes(currentUserId);
          });
        });
      }
      return temp;
    }
  }, [linesForm?.playersLines]);

  const formatedLines = useMemo(() => {
    if (linesForm) {
      const {playersLines} = linesForm;

      const lines = playersLines.reduce(
        (prev, curr) => {
          return {
            ...prev,
            wingers: [...prev.wingers, curr.wingersLine],
            defensemans: [...prev.defensemans, curr.defensemansLine],
          };
        },
        {wingers: [], defensemans: []},
      );

      const allPlayers = [...lines.wingers.flat(), ...lines.defensemans.flat()];

      if (allPlayers?.some((player) => player?.id === id)) return lines;
    }
    return null;
  }, [linesForm?.playersLines]);

  const content = useMemo(
    () => (
      <Content
        formatedGame={formatedGame}
        formatedLines={formatedLines}
        getOneLine={getOneLine}
        isLoading={isLoading}
      />
    ),
    [formatedGame, formatedLines, isLoading],
  );

  return (
    <DashboardsBlock
      withLargePadding
      buttonsBlockType="link"
      content={content}
      link="/lines"
      linkText="Посмотреть актуальный состав"
      title={!isMobile && 'Следующая игра'}
      withLargeBorder={isMobile}
      withOutButtons={!formatedLines}
    />
  );
};

const Container = styled.div`
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 600;
`;

const Header = styled.div`
  display: flex;
  padding: 4px 24px;
  padding-left: 34px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;
  font-size: 14px;
  color: #747474;
  font-weight: normal;
`;

const PlayersWrapper = styled.div`
  padding: 15px 24px;
  padding-left: 34px;
  padding-bottom: ${(props) => props.withOutPadding && '0'};
  flex-wrap: wrap;
  display: flex;

  @media (max-width: 360px) {
    padding: 5px 0 5px 0;
  }
`;

const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-top: 4px;
  //&:last-child {
  //  margin-right: 0;
  //}

  @media (max-width: 650px) {
    flex-direction: column;
    max-width: 100px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 12px;
  }
`;

const PlayerName = styled.div`
  letter-spacing: 0.02em;
`;

const PlayerAvatar = styled.div`
  margin-right: 11px;
  width: 40px;
  height: 40px;
  background: ${(props) => `url(${props.src})
    no-repeat center`};
  background-size: cover;
  border-radius: 50%;
  @media (max-width: 650px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

const NextGameWrapper = styled.div`
  display: flex;
  padding-left: 34px;
  padding-top: 13px;
  padding-bottom: 16px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
  @media (max-width: 360px) {
    padding: 12px;
  }
`;

const NextGameFullDate = styled.div`
  color: #887755;
`;

const NextGameTime = styled.div`
  margin-left: 10px;
  margin-right: 15px;
  color: #990011;
`;

const NextGameTitle = styled.div`
  text-transform: uppercase;
`;

export default NextGame;
