import 'echarts/lib/chart/bar';

import * as echarts from 'echarts';
import React, {useEffect, useRef, useState} from 'react';

export default function BarChart(props) {
  const {options, minHeight = 300, maxHeight = 360, isResponsitive = true, width} = props;

  let defaultOption = {
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [20, 100, 0, 80, 70, 10, 10],
        type: 'bar',
      },
    ],
  };

  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  /**
   * Инициализация графика
   */
  const init = (opt) => {
    // eslint-disable-next-line import/namespace
    const chart = echarts.init(chartRef.current, null, {renderer: 'svg'});

    chart.setOption(opt ? opt : defaultOption);
    setChartInstance(chart);
  };
  useEffect(() => {
    init(options);
  }, []);
  /**
   * Реинициализация графика
   */
  const reInit = (options) => {
    if (!chartInstance) return;
    chartInstance.dispose();
    init(options);
  };

  useEffect(() => {
    reInit(options);
  }, [options]);

  useEffect(() => {
    if (isResponsitive && chartInstance) {
      window.addEventListener('resize', function () {
        chartInstance.resize();
      });
      return () => document.removeEventListener('resize', () => {});
    }
  }, [isResponsitive, chartInstance]);

  return (
    <>
      <div
        className={props.className || 'BarChart'}
        style={{
          background: '#fff',
          textAlign: 'center',
          width: '100%',
          minHeight: minHeight,
          height: '100%',
          maxHeight: maxHeight,
          overflowX: 'scroll',
          overflowY: 'hidden',
        }}
      >
        <div
          ref={chartRef}
          style={{
            overflowX: 'scroll',
            //width: '1620px',
            width: width,
            minHeight: minHeight,
            height: '100%',
            maxHeight: maxHeight,
          }}
        ></div>
      </div>
    </>
  );
}
