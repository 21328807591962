import React from 'react';

function Sort() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M5.89008 2.92233L4.57758 1.60983C4.43133 1.46339 4.19358 1.46339 4.04733 1.60983L2.73483 2.92233C2.58839 3.06877 2.58839 3.30614 2.73483 3.45258C2.88108 3.59902 3.11883 3.59902 3.26508 3.45258L3.93745 2.7802V10.125C3.93745 10.332 4.10545 10.5 4.31245 10.5C4.51945 10.5 4.68745 10.332 4.68745 10.125V2.7802L5.35983 3.45258C5.43295 3.52589 5.52895 3.56245 5.62495 3.56245C5.72095 3.56245 5.81695 3.52589 5.89008 3.45258C6.03652 3.30614 6.03652 3.06877 5.89008 2.92233Z"
          fill="#2C2C2C"
        />
        <path
          d="M9.26508 8.54737C9.11864 8.40094 8.88127 8.40094 8.73483 8.54737L8.06245 9.21975V1.875C8.06245 1.668 7.89445 1.5 7.68745 1.5C7.48045 1.5 7.31245 1.668 7.31245 1.875V9.21975L6.64008 8.54737C6.49364 8.40094 6.25608 8.40094 6.10983 8.54737C5.96339 8.69381 5.96339 8.93119 6.10983 9.07763L7.42233 10.3901C7.49564 10.4634 7.59145 10.5 7.68745 10.5C7.78345 10.5 7.87927 10.4634 7.95258 10.3901L9.26508 9.07763C9.41152 8.93119 9.41152 8.69381 9.26508 8.54737Z"
          fill="#2C2C2C"
        />
      </g>
    </React.Fragment>
  );
}
export default Sort;
