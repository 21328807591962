import UserReportList from '@common/ScoutReport/UserReportList';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {SCOUT_REPORT_TYPES} from '../../../../../constants';
import {StyledButton, StyledPageTitleDefault} from '../../../../common/ScoutReport/CommonScoutReport';
import {PERMISSIONS} from '../../../../common/ScoutReport/constants';
import ChessTemplate from '../../../../templates/ChessTemplate';

function UserReports({reportType}) {
  const {userId} = useParams();
  const {reports} = useSelector(({scoutReport}) => ({reports: scoutReport.reports}));

  const [isModalOpen, setIsModalOpen] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const ClubBreadcrumbs = {
    label: reportType === SCOUT_REPORT_TYPES.inner ? reports?.group?.name : reports?.school?.name,
    id: reportType === SCOUT_REPORT_TYPES.inner ? reports?.group?.id : reports?.school?.id,
    path(id) {
      return reportType === SCOUT_REPORT_TYPES.inner
        ? `/trainer_report/inner/teams/${id}`
        : `/trainer_report/${id}/years`;
    },
  };
  return (
    <ChessTemplate active={'trainer_report'}>
      <StyledPageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Отчет тренера', path: `/trainer_report/${reportType}/teams`},
          {
            label: ClubBreadcrumbs.label || 'Клуб',
            path: ClubBreadcrumbs.id ? ClubBreadcrumbs.path(ClubBreadcrumbs.id) : '',
          },
          {
            label:
              reports?.student?.firstName && reports?.student?.lastName
                ? `${reports.student.firstName} ${reports.student.lastName}`
                : 'Игрок',
            path: '/',
          },
        ]}
        text="Отчет тренера"
      />
      {reports.permissions?.[PERMISSIONS.canCreate] && (
        <StyledButton
          color="primary"
          size="medium"
          variant="contained"
          onClick={() => setIsModalOpen({...isModalOpen, right: !isModalOpen.right})}
        >
          <span className="plus"></span>Добавить отчет
        </StyledButton>
      )}
      <UserReportList
        isOpen={isModalOpen}
        perView={8}
        reportType={reportType}
        setIsOpen={(state) =>
          setIsModalOpen({
            ...isModalOpen,
            right: typeof state === 'boolean' ? state : !isModalOpen.right,
          })
        }
        userId={userId}
      />
    </ChessTemplate>
  );
}

UserReports.propTypes = {
  reportType: PropTypes.string.isRequired,
};

export default UserReports;
