import {useAge, useTheme} from '@components/apps/TrainingControlProcess/hooks/api';
import Stack from '@ui/MUI/Stack';
import React from 'react';

import List from '../List';
import Chips from './components/Chips';

const Cloud = ({age = [], theme = [], onChange}) => {
  const {data: ages} = useAge();
  const {data: themes} = useTheme();

  return (
    <Stack borderRight="1px solid rgba(0, 0, 0, 0.24)" flex="1" px={2} py={3} spacing={5}>
      <List actionText="Сбросить" label="Тематика" onActionClick={() => onChange('theme', [])}>
        <Chips chips={themes} value={theme} onChange={(value) => onChange('theme', value)} />
      </List>
      <List actionText="Сбросить" label="Возраст" onActionClick={() => onChange('age', [])}>
        <Chips chips={ages} value={age} onChange={(value) => onChange('age', value)} />
      </List>
    </Stack>
  );
};

export default Cloud;
