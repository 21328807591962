import {useEstimates} from '@common/DevelopmentJournal/hooks';
import FullHeightWrapper from '@common/FullHeightWrapper';
import {PageWrapper} from '@common/Page';
import CompleteModal from '@components/apps/DevelopmentJournalPlayerSkills/components/Complete';
import EditModal from '@components/apps/DevelopmentJournalPlayerSkills/components/Edit';
import Estimate from '@components/apps/DevelopmentJournalPlayerSkills/components/Estimate';
import LocalPreloader from '@components/preloader/LocalPreloader';
import useQueryString from '@hooks/useQueryString';
import {Typography} from '@mui/material';
import {Divider, Stack} from '@ui/MUI';
import React, {useEffect, useMemo, useRef} from 'react';
import {useParams} from 'react-router';

const EstimateList = () => {
  const {id} = useParams();
  const [{isCompleted}] = useQueryString();
  const refForAnchor = useRef(null);
  const scrollForAnchorRef = () => {
    return refForAnchor?.current.scrollIntoView({behavior: 'smooth', block: 'end'});
  };
  useEffect(() => {
    if (isCompleted && refForAnchor?.current) {
      scrollForAnchorRef();
    }
  }, [isCompleted, refForAnchor?.current]);
  const playersIds = useMemo(() => {
    return [id];
  }, [id]);

  const estimates = useEstimates({playersIds});
  const activeEstimates = estimates?.data?.filter((estimate) => estimate.isCompleted === false);
  const archivedEstimates = estimates?.data?.filter((estimate) => estimate.isCompleted === true);

  if (estimates.isLoading) {
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center" position="relative">
        <LocalPreloader visible />
      </FullHeightWrapper>
    );
  }

  if (estimates.isNoData) {
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
        <Typography variant="subtitle1">Навыки не добавлены</Typography>
      </FullHeightWrapper>
    );
  }

  return (
    <>
      {activeEstimates?.length > 0 && (
        <>
          <Typography color="primary.contrastText" mb={3} variant="h3">
            Актуальные навыки
          </Typography>
          <PageWrapper>
            <CompleteModal />
            <EditModal />
            <Stack spacing={5}>
              {activeEstimates?.map((estimate, index) => {
                const isLast = index === activeEstimates.length - 1;
                return (
                  <React.Fragment key={estimate.id}>
                    <Estimate
                      categoryName={estimate.skill.category.name}
                      controlDate={estimate.controlDate}
                      createdAt={estimate.createdAt}
                      expectedScore={estimate.expectedScore}
                      id={estimate.id}
                      isArchived={false}
                      plan={estimate.plan}
                      score={estimate.score}
                      skillName={estimate.skill.name}
                      specialists={estimate.specialists}
                      todo={estimate.todo}
                    />
                    {!isLast && <Divider />}
                  </React.Fragment>
                );
              })}
            </Stack>
          </PageWrapper>
        </>
      )}
      {archivedEstimates?.length > 0 && (
        <>
          <Typography color="primary.contrastText" mb={3} mt={4} variant="h3">
            Завершенные навыки
          </Typography>
          <PageWrapper>
            <Stack ref={refForAnchor} spacing={5}>
              {archivedEstimates?.map((estimate, index) => {
                const isLast = index === archivedEstimates.length - 1;
                return (
                  <React.Fragment key={estimate.id}>
                    <Estimate
                      categoryName={estimate.skill.category.name}
                      completedScore={estimate.completedScore}
                      controlDate={estimate.controlDate}
                      createdAt={estimate.createdAt}
                      expectedScore={estimate.expectedScore}
                      id={estimate.id}
                      isArchived={true}
                      plan={estimate.plan}
                      score={estimate.score}
                      skillName={estimate.skill.name}
                      specialists={estimate.specialists}
                      todo={estimate.todo}
                    />
                    {!isLast && <Divider />}
                  </React.Fragment>
                );
              })}
            </Stack>
          </PageWrapper>
        </>
      )}
    </>
  );
};

export default EstimateList;
