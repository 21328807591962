import {useCurrentBreakPoint} from '@components/apps/MaterialsModule/hooks';
import useEvent from '@hooks/useEvent';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {updatePreviewData, updatePreviewVisability} from '@slices/materials';
import {Box, Stack} from '@ui/MUI';
import React, {useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import Bottom from './components/Bottom';
import Content from './components/Content';
import {border, iconMap, iconStyles, PREVIEW_IMAGE_PATH} from './constants';
import {Image} from './styles';

export const FileCard = (props) => {
  const {type, name, createdAt} = props;
  const Icon = useMemo(() => iconMap[type.slug], [type.slug]);

  const breakpoint = useCurrentBreakPoint();

  return (
    <Stack
      border={border}
      borderRadius="12px"
      breakpoint={breakpoint}
      pb={3}
      pl={3}
      px={3}
      py={2}
      sx={{position: 'relative'}}
      width={breakpoint === 'xxs' ? '100%' : 200}
    >
      <Icon sx={iconStyles} />
      <Stack
        mt={2}
        spacing={4}
        sx={{
          pl: {
            xs: '0px',
            xxs: '18px',
          },
        }}
      >
        <Content createdAt={createdAt} name={name} />
      </Stack>
      <Bottom {...props} />
    </Stack>
  );
};

export const VideoCard = (props) => {
  const {name, createdAt, category} = props;
  const dispatch = useStoreDispatch();

  const openPreview = useEvent(() => {
    dispatch(updatePreviewData(props));
    dispatch(updatePreviewVisability(true));
  });

  return (
    <Box
      sx={{
        cursor: 'pointer',
        width: {
          sm: '260px',
          xs: '48%',
          xxs: '100%',
        },
      }}
      onClick={openPreview}
    >
      <Image radius="4px" src={PREVIEW_IMAGE_PATH} />
      <Stack mt={1} spacing={2}>
        <Content createdAt={createdAt} name={name} />
        <Bottom category={category} />
      </Stack>
    </Box>
  );
};

export const ImageCard = (props) => {
  const {name, createdAt, category, storage} = props;

  const dispatch = useStoreDispatch();

  const openPreview = useEvent(() => {
    dispatch(updatePreviewData(props));
    dispatch(updatePreviewVisability(true));
  });

  return (
    <Stack
      border={border}
      borderRadius="6px"
      sx={{
        cursor: 'pointer',
        width: {
          sm: '144px',
          xs: '48%',
          xxs: '100%',
        },
      }}
      onClick={openPreview}
    >
      <Image radius="6px 6px 0px 0px" src={storage.link} />
      <Stack mt={2} p={2} spacing={2}>
        <Content createdAt={createdAt} name={name} />
        <Bottom category={category} />
      </Stack>
    </Stack>
  );
};

export const VideoAnalyticsCard = ({name, createdAt, category, id}) => {
  const history = useHistory();

  const handleClick = useEvent(() => {
    history.push(`/materials/${id}`);
  });

  return (
    <Box
      sx={{
        cursor: 'pointer',
        width: {
          sm: '260px',
          xs: '48%',
          xxs: '100%',
        },
      }}
      onClick={handleClick}
    >
      <Image radius="4px" src={PREVIEW_IMAGE_PATH} />
      <Stack mt={1} spacing={2}>
        <Content createdAt={createdAt} name={name} />
        <Bottom category={category} />
      </Stack>
    </Box>
  );
};
