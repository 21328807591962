import {Stages} from '@common/Statistics/PlayOff/types';
import {StagesEnum} from '@src/types/tournament';

export const mock: Stages = [
  {
    stage: StagesEnum.EIGHTH,
    games: [],
  },
  {
    stage: StagesEnum.FOURTH,
    games: [],
  },
  {
    stage: StagesEnum.HALF,
    games: [],
  },
  {
    stage: StagesEnum.FINAL,
    games: [],
  },
];
