import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

FallBackImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fallbackImg: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

function FallBackImage({src = '', width = 45, fallbackImg = '', alt = ''}) {
  return (
    <Img
      alt={alt}
      draggable={false}
      src={src}
      width={width}
      onError={({currentTarget}) => {
        currentTarget.onerror = null;
        currentTarget.src = fallbackImg;
      }}
    />
  );
}

export default FallBackImage;

const Img = styled.img`
  display: block;
  flex-shrink: 0;
`;
