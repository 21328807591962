import ReactComponentModal from '@ui/ReactComponent/ReactComponentModal/ReactComponentModal';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {StyledButton} from '../ScoutReport/CommonScoutReport';

function DeleteModal({isVisible, onDelete, onCancel, title, text}) {
  return (
    <ReactComponentModal
      buttons={
        <>
          <StyledButton color="primary" margin={0} size="medium" variant="contained" onClick={onDelete}>
            удалить
          </StyledButton>
          <StyledButton color="secondary" margin={0} size="medium" variant="contained" onClick={onCancel}>
            отменить
          </StyledButton>
        </>
      }
      className="ReactComponentModalDefault LinesNotSaved"
      content={<ModalBodyText className="ErrorText">{text}</ModalBodyText>}
      title={title}
      visible={isVisible}
      onClose={onCancel}
    />
  );
}

DeleteModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

export default DeleteModal;

const ModalBodyText = styled.p`
  margin-bottom: 34px;
`;
