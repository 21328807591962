import AcceptInput from '@common/AcceptInput';
import ActionText from '@common/ActionText';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Add} from '@mui/icons-material';
import {Divider} from '@mui/material';
import {deleteTrainingControl, updateTrainingControl} from '@slices/trainingControlProcess';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

const AccordionContent = ({name, categories, id, createCategory}) => {
  const dispatch = useStoreDispatch();
  return (
    <Stack spacing={4}>
      <Stack>
        <AcceptInput
          initialValue={name}
          onAccept={(isNew, value) => {
            dispatch(updateTrainingControl({name: 'section', payload: {name: value}, id}));
          }}
          onDelete={() => {
            dispatch(deleteTrainingControl({name: 'section', payload: id}));
          }}
        />
      </Stack>
      <Divider />
      <Stack>
        <TypographyDefault variant="h4">Категории:</TypographyDefault>
        <Stack alignItems="flex-start" direction="row" flexWrap="wrap" gap="10px 40px">
          <Stack flex="1" minWidth="300px" spacing={4}>
            {categories.map((category) => (
              <AcceptInput
                initialValue={category.name}
                key={category.id}
                onAccept={(isNew, value) => {
                  dispatch(
                    updateTrainingControl({
                      name: 'category',
                      payload: {name: value, sectionId: id},
                      id: category.id,
                      connectores: ['section'],
                    }),
                  );
                }}
                onDelete={() => {
                  dispatch(
                    deleteTrainingControl({
                      name: 'category',
                      payload: category.id,
                      connectores: ['section'],
                    }),
                  );
                }}
              />
            ))}
          </Stack>
          <ActionText startIcon={<Add />} onClick={() => createCategory({sectionId: id})}>
            Добавить категорию
          </ActionText>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AccordionContent;
