import PowerStruggleTab from '@common/Statistics/PlayerStatistics/Tabs/PowerStruggleTab';
import PropTypes from 'prop-types';
import React from 'react';

import {useLoadPlayersStatisticsData} from '../../hooks';

function PowerStruggle({tab, filter, matchFilter}) {
  const statisticsTab = useLoadPlayersStatisticsData({tab, filter, matchFilter});

  const {isLoad, isLoading, data, hasError, hasMatchFilter} = statisticsTab;

  return <PowerStruggleTab {...{isLoad, isLoading, data, hasError, hasMatchFilter}} />;
}

PowerStruggle.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default PowerStruggle;
