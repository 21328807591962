import CircularProgress from '@mui/material/CircularProgress';
import React, {useEffect} from 'react';

import Theme from '../Theme';

/**@deprecated не использовать этот компонент, заменить на ./CircularProgress**/
export default function CircularDeterminate(props) {
  const {progress, setProgress} = props;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 10000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Theme>
      <CircularProgress color="secondary" value={progress} variant="determinate" />
    </Theme>
  );
}
