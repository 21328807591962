import GroupsAPI from '../../api/GroupsAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

const errorMessage = (err) => {
  let errorText = 'Ошибка при загрузке';
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
      }
    }
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

let loadGroups_ = () => {
  return {
    type: types.LOAD_GROUPS,
  };
};
let loadGroupsSuccess = (groups) => {
  return {
    type: types.LOAD_GROUPS_SUCCESS,
    groups: groups,
  };
};
let loadGroupsFailed = (error) => {
  return {
    type: types.LOAD_GROUPS_FAIL,
    error: error,
  };
};

//thunk
export function loadAllGroups() {
  return (dispatch) => {
    dispatch(loadGroups_());
    return GroupsAPI.getAllGroups().then(
      (groups) => dispatch(loadGroupsSuccess(groups)),
      (error) => dispatch(loadGroupsFailed(error)),
    );
  };
}

//TODO: разобраться, что это за старая точка и убрать ее
export function loadGroupById(id) {
  return (dispatch) => {
    dispatch(loadGroups_());
    return GroupsAPI.getGroupById(id).then(
      (group) => dispatch(loadGroupsSuccess([group])),
      (error) => dispatch(loadGroupsFailed(error)),
    );
  };
}

export function getGroupById(id) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GROUPS,
    });
    return GroupsAPI.getGroupById(id)
      .then((data) => {
        dispatch({
          type: types.LOAD_GROUP_SUCCESS,
          group: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.LOAD_GROUPS_FAIL,
        });
      });
  };
}

export const createGroup = (group) => (dispatch) => {
  dispatch({
    type: types.CREATE_GROUP,
  });
  return GroupsAPI.createGroup(group)
    .then((data) => {
      dispatch({
        type: types.CREATE_GROUP_SUCCESS,
        group: data.data,
      });
      return data;
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch({
        type: types.CREATE_GROUP_FAIL,
      });
      return err;
    });
};

export function updateGroup(groupId, group) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_GROUP,
    });
    return GroupsAPI.updateGroup(groupId, group)
      .then((data) => {
        dispatch({
          type: types.UPDATE_GROUP_SUCCESS,
          group: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.UPDATE_GROUP_FAIL,
        });
      });
  };
}

export function deleteGroup(groupId) {
  return (dispatch) => {
    dispatch({
      type: types.DELETE_GROUP,
    });
    return GroupsAPI.deleteGroup(groupId)
      .then((data) => {
        dispatch({
          type: types.DELETE_GROUP_SUCCESS,
          group: data.data,
        });
      })
      .catch(function (err) {
        errorMessage(err);
        dispatch({
          type: types.DELETE_GROUP_FAIL,
        });
      });
  };
}

//clean group statistic
export function cleanGroupStatistics() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAN_GROUP_STATISTICS,
    });
  };
}

//load group statistic metrics
export function loadgroupStatsMetrics() {
  return (dispatch) => {
    dispatch({});
    return GroupsAPI.getGroupStatsMetrics()
      .then((data) => {
        dispatch({});
        dispatch({
          type: types.LOAD_GROUP_STATISTICS_METRICS,
          groupStatsMetrics: data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке метрик';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText} : ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
export function getGroupRate(request, isActive) {
  return (dispatch) => {
    return GroupsAPI.getGroupRate(request, isActive)
      .then((data) => {
        dispatch({
          type: types.LOAD_GROUP_RATE,
          groupRate: data.data,
        });
      })
      .catch(function (err) {
        dispatch({
          type: types.LOAD_GROUP_RATE,
          groupRate: undefined,
        });
        let errorText = 'Ошибка при загрузке рейтинга';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText} : ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
export function cleanGroupRate() {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GROUP_RATE,
      groupRate: [],
    });
  };
}
export function saveRatingFilters(allSelectedFilters) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_RATING_FILTERS,
      allSelectedFilters: allSelectedFilters,
    });
  };
}
