import Field from '@common/FormField';
import {Box, Stack} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

ProfileInfoItems.propTypes = {
  isEdit: PropTypes.bool,
  fields: PropTypes.array,
};

function ProfileInfoItems({isEdit, fields = [], ...props}) {
  return (
    <Stack alignItems="center" direction="row" flexWrap="wrap" gap={3} mb={8} {...props}>
      {fields.map(({showWhenEdit, showWhenNoEdit, Component, ...item}, index) => {
        return (
          <Box
            key={index + item?.name}
            mb={1}
            width={{
              xxs: '100%',
              xs: 'calc(100% * 6 / 12 - 16px / 2)',
              sm: 'calc(100% * 4 / 12 - 16px * 2 / 3)',
              md: 'calc(100% * 3 / 12 - 16px * 3 / 4)',
            }}
          >
            {Component ? (
              <Component {...item} readOnly={!isEdit} />
            ) : (
              <Field
                size="small"
                textFieldVariant="outlined"
                type="input"
                variant="outlined"
                {...item}
                InputProps={{readOnly: !isEdit || item?.isDisabled}}
                readOnly={!isEdit || item?.isDisabled}
              />
            )}
          </Box>
        );
      })}
    </Stack>
  );
}

export default ProfileInfoItems;
