import {fill} from 'lodash';

export const mock = {
  students: fill([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], {
    id: '3cKdr9CWWZgU',
    firstName: 'Никита',
    lastName: 'Коротенко',
    avatar: 'https://stars.hawk.ru/api/storage/68c4fe74-d43c-4158-84b6-c27dcac8a7bf.blob',
    playerNumber: '88',
    params: {
      1669693310174: [
        {
          playerId: '3cKdr9CWWZgU',
          height: 180,
          weight: 79,
          bmi: 24.38,
          date: '1669693310174',
          caliperometry: 0,
          warnings: {
            increaseHeightMoreFivePerSixMonth: false,
            decreaseHeightLessFivePerSixMonth: false,
            increaseWeightMoreFivePerSixMonth: false,
            decreaseWeightLessFivePerSixMonth: false,
            increaseFatMoreFivePerSixMonth: false,
            decreaseFatLessFivePerSixMonth: false,
          },
        },
      ],
      1670926155821: [
        {
          playerId: '3cKdr9CWWZgU',
          height: 180,
          weight: 79,
          bmi: 24.38,
          date: '1670926155821',
          caliperometry: 0,
          warnings: {
            increaseHeightMoreFivePerSixMonth: false,
            decreaseHeightLessFivePerSixMonth: false,
            increaseWeightMoreFivePerSixMonth: false,
            decreaseWeightLessFivePerSixMonth: false,
            increaseFatMoreFivePerSixMonth: false,
            decreaseFatLessFivePerSixMonth: false,
          },
        },
      ],
      1672896120334: [
        {
          playerId: '3cKdr9CWWZgU',
          height: 180,
          weight: 78,
          bmi: 24.07,
          date: '1672896120334',
          caliperometry: 0,
          warnings: {
            increaseHeightMoreFivePerSixMonth: false,
            decreaseHeightLessFivePerSixMonth: false,
            increaseWeightMoreFivePerSixMonth: false,
            decreaseWeightLessFivePerSixMonth: false,
            increaseFatMoreFivePerSixMonth: false,
            decreaseFatLessFivePerSixMonth: false,
          },
        },
      ],
      1674634287597: [
        {
          playerId: '3cKdr9CWWZgU',
          height: 180,
          weight: 79,
          bmi: 24.38,
          date: '1674634287597',
          caliperometry: 0,
          warnings: {
            increaseHeightMoreFivePerSixMonth: false,
            decreaseHeightLessFivePerSixMonth: false,
            increaseWeightMoreFivePerSixMonth: false,
            decreaseWeightLessFivePerSixMonth: false,
            increaseFatMoreFivePerSixMonth: false,
            decreaseFatLessFivePerSixMonth: false,
          },
        },
      ],
      1675745658608: [
        {
          playerId: '3cKdr9CWWZgU',
          height: 180,
          weight: 79,
          bmi: 24.38,
          date: '1676953346909',
          caliperometry: 0,
          warnings: {
            increaseHeightMoreFivePerSixMonth: false,
            decreaseHeightLessFivePerSixMonth: false,
            increaseWeightMoreFivePerSixMonth: false,
            decreaseWeightLessFivePerSixMonth: false,
            increaseFatMoreFivePerSixMonth: false,
            decreaseFatLessFivePerSixMonth: false,
          },
        },
      ],
    },
  }),
  dates: [1669693310174, 1670926155821, 1672896120334, 1674634287597, 1675745658608],
};
