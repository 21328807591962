import React from 'react';

function TooltipQuestion() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M5.5 0C2.46718 0 0 2.46718 0 5.5C0 8.53282 2.46718 11 5.5 11C8.53282 11 11 8.53282 11 5.5C11 2.46718 8.53282 0 5.5 0ZM5.5 8.70831C5.24697 8.70831 5.04169 8.50303 5.04169 8.25C5.04169 7.99697 5.24697 7.79169 5.5 7.79169C5.75303 7.79169 5.95831 7.99697 5.95831 8.25C5.95831 8.50303 5.75303 8.70831 5.5 8.70831ZM6.22552 5.79424C6.06329 5.86893 5.95831 6.03258 5.95831 6.21091V6.41669C5.95831 6.66964 5.75345 6.875 5.5 6.875C5.24655 6.875 5.04169 6.66964 5.04169 6.41669V6.21091C5.04169 5.67599 5.35607 5.18562 5.84148 4.96146C6.30852 4.74654 6.64581 4.17586 6.64581 3.89581C6.64581 3.26428 6.13203 2.75 5.5 2.75C4.86797 2.75 4.35419 3.26428 4.35419 3.89581C4.35419 4.14883 4.14925 4.35419 3.89581 4.35419C3.64236 4.35419 3.4375 4.14883 3.4375 3.89581C3.4375 2.75873 4.36284 1.83331 5.5 1.83331C6.63716 1.83331 7.5625 2.75873 7.5625 3.89581C7.5625 4.51508 7.02531 5.42531 6.22552 5.79424Z"
          fill="#C4C4C4"
        />
      </g>
    </React.Fragment>
  );
}
export default TooltipQuestion;
