import styled from 'styled-components';

export const LocalPreloaderWrapper = styled.div`
  margin-right: 0;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: ${({theme, props}) => (props?.padding ? props?.padding : theme.spacing(4))};
  overflow: hidden;
  margin-bottom: ${(props) => props.marginBottom || 'unset'};
  position: relative;
  height: ${(props) => props.isLoading && '70vh'};
`;
