import React from 'react';

function AddRounded() {
  return (
    <React.Fragment>
      <g>
        <path
          clipRule="evenodd"
          d="M9.75 4C9.47386 4 9.25 4.22386 9.25 4.5V9.25H4.5C4.22386 9.25 4 9.47386 4 9.75V10.25C4 10.5261 4.22386 10.75 4.5 10.75H9.25V15.5C9.25 15.7761 9.47386 16 9.75 16H10.25C10.5261 16 10.75 15.7761 10.75 15.5V10.75H15.5C15.7761 10.75 16 10.5261 16 10.25V9.75C16 9.47386 15.7761 9.25 15.5 9.25H10.75V4.5C10.75 4.22386 10.5261 4 10.25 4H9.75Z"
          fill="#990011"
          fillRule="evenodd"
        />
      </g>
    </React.Fragment>
  );
}
export default AddRounded;
