import FullHeightWrapper from '@common/FullHeightWrapper';
import ReactTableComponent from '@common/ReactTableComponent';
import {colors} from '@components/apps/DevelopmentJournal/components/PlayersDiary/components/QuestionnaireStatistics';
import date from '@helpers/date';
import {longDash} from '@src/constants';
import {QUESTIONNAIRE_QUESTION_TYPES as QT, trainingQuestions} from '@src/constants/playerDiary';
import {Box, Tooltip, Typography} from '@ui/MUI';
import React, {useMemo} from 'react';

const constructSubColumns = (columns, accessorKey, isLoading) => {
  const defaultColumns = columns?.map((column) => {
    const {label, value} = column;
    return {
      id: `${value}_${accessorKey}`,
      header: label,
      headerStyle: {
        maxWidth: 112,
      },
      accessorFn: (row) => {
        return row?.[accessorKey]?.[value] || '';
      },
      cell: ({getValue, row}) => {
        const isNumber = typeof getValue() === 'number';
        const isDayOff =
          row?.original?.morning?.isDayOff ||
          row?.original?.game?.isDayOff ||
          row?.original?.trainingGroup?.isDayOff ||
          row?.original?.trainingIndividual?.isDayOff ||
          false;
        return (
          <Typography isLoading={isLoading} preloaderWidthBetween={[30, 40]} variant="body2">
            {getValue() && (
              <Box
                component="span"
                sx={{
                  display: 'inline-block',
                  mr: 1,
                  width: 8,
                  height: 8,
                  backgroundColor: [...colors]?.[isNumber ? getValue() : 0],
                  borderRadius: '50%',
                }}
              />
            )}
            {getValue() || (isDayOff ? 'Выходной' : longDash)}
          </Typography>
        );
      },
      cellStyle: {
        maxWidth: 112,
      },
    };
  });
  const result = [
    ...defaultColumns,
    {
      header: 'Комментарий',
      id: `comment_${accessorKey}`,
      cell: ({row}) => {
        const commentLength = row?.original?.[accessorKey]?.comment?.length;
        return row?.original?.[accessorKey]?.comment ? (
          <>
            {commentLength > 120 ? (
              <Tooltip title={row?.original?.[accessorKey]?.comment}>
                <Typography
                  isLoading={isLoading}
                  preloaderWidthBetween={[90, 100]}
                  sx={{
                    maxWidth: 140,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  variant="body2"
                >
                  {row?.original?.[accessorKey]?.comment}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                isLoading={isLoading}
                preloaderWidthBetween={[90, 100]}
                sx={{
                  maxWidth: 140,
                  whiteSpace: 'pre-line',
                }}
                variant="body2"
              >
                {row?.original?.[accessorKey]?.comment}
              </Typography>
            )}
          </>
        ) : (
          <Typography
            isLoading={isLoading}
            preloaderWidthBetween={[90, 100]}
            sx={{
              maxWidth: 140,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            variant="body2"
          >
            {longDash}
          </Typography>
        );
      },
    },
  ];
  return result;
};

const MonthTable = ({isLoading, isLoad, isNoData, data = []}) => {
  const columns = useMemo(
    () => [
      {
        header: 'Дата',
        accessorKey: 'date',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[70, 80]} variant="subtitle2">
            {date(getValue()).format('DD.MM.YYYY')}
          </Typography>
        ),
        sticky: {left: true},
      },
      {
        header: 'Утро',
        accessorKey: 'morning',
        columns: constructSubColumns([QT.sleep, QT.mood, QT.willToTraining], 'morning', isLoading),
      },
      {
        header: 'Групповая тренировка',
        accessorKey: 'trainingGroup',
        columns: constructSubColumns(trainingQuestions, 'trainingGroup', isLoading),
      },
      {
        header: 'Индивидуальная тренировка',
        accessorKey: 'trainingIndividual',
        columns: constructSubColumns(trainingQuestions, 'trainingIndividual', isLoading),
      },
      {
        header: 'Игра',
        accessorKey: 'game',
        columns: constructSubColumns(
          [
            QT.activity,
            QT.stress,
            QT.health,
            QT.attention,
            QT.fellingIceAndGoal,
            QT.comunicationWithTrainer,
            QT.selfScore,
          ],
          'game',
          isLoading,
        ),
      },
    ],
    [isLoading],
  );
  return isLoad && isNoData ? (
    <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
      <Typography variant="subtitle1">Нет данных</Typography>
    </FullHeightWrapper>
  ) : (
    <ReactTableComponent columns={columns} data={data} isAvailableHeight={false} />
  );
};

export default MonthTable;
