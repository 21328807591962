import PlaceholderWrapper from '@common/FullHeightWrapper';
import NoDataFullHeight from '@common/NoDataFullHeight';
import LocalPreloader from '@components/preloader/LocalPreloader';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {fetchOverallData, setFiltersValue} from '@slices/PhysicalTestingEditCreate';
import React, {useEffect} from 'react';

import TableEditCreate from './components/EditCreateTable';
import Filters from './components/Filters';

const CreateEditPhysicalTesting = (props) => {
  const dispatch = useStoreDispatch();
  const tableDataNew = useStore((state) => state.physicalTestingEditCreate.teamInfo.items);
  const allTests = useStore((state) => state.physicalTestingEditCreate.teamInfo.tests);
  const filters = useStore((state) => state.physicalTestingEditCreate.filters);
  const {isLoading, error} = useStore((state) => state.physicalTestingEditCreate.teamInfo);
  const isFilterSelected =
    filters?.group?.id && filters?.season?.value && filters?.stage?.value && filters?.typeOfTests?.value;

  const updateFilters = (data) => {
    dispatch(setFiltersValue(data));
  };

  useEffect(() => {
    if (isFilterSelected) {
      dispatch(
        fetchOverallData({
          groupId: filters.group.id,
          season: filters.season.value,
          stage: filters.stage.value,
          typeOfTests: filters.typeOfTests.value,
        }),
      );
    }
  }, [filters]);

  return (
    <>
      <Filters filters={filters} updateFilters={updateFilters} />
      {isLoading ? (
        <PlaceholderWrapper marginBottom="8px" padding={'0 24px'} position="relative">
          <LocalPreloader visible={true} />
        </PlaceholderWrapper>
      ) : !tableDataNew?.length || error || !isFilterSelected ? (
        <NoDataFullHeight>
          {error || 'Выберите вид тестов, сезон и этап, чтобы добавить результаты тестирования'}
        </NoDataFullHeight>
      ) : (
        <TableEditCreate allTests={allTests} filters={filters} tableData={tableDataNew} />
      )}
      {props?.children && React.cloneElement(props.children, {setParentState: updateFilters})}
    </>
  );
};

export default CreateEditPhysicalTesting;
