import {EllipsisTypography} from '@components/apps/StatsReportForOneGame/components/ShiftsTimeline/ShiftsTimeline';
import {getRoleLabel} from '@helpers/roles';
import {LogoutOutlined} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import {STUDENT_SUBROLES} from '@src/constants';
import {Box, IconButton, Stack, Typography} from '@ui/MUI';
import Avatar from '@ui/MUI/Avatar';
import {grey, olive} from '@ui/MUI/colors';
import React, {useState} from 'react';

import ModalCropUploadPanel from '../upload/ModalCropUploadPanel';

export default function BottomUserPanel({
  user = {},
  onLogout = () => {},
  onClick = () => {},
  onUpdate = () => {},
  isLoading,
  isMenuCollapsed,
}) {
  const {firstName, lastName, avatar, userRole, teacherRole} = user;

  const [imageCropModalState, setImageCropModalState] = useState(false);

  if (!user) return null;
  return (
    <>
      <Stack alignItems="flex-start" direction={isMenuCollapsed ? 'column' : 'row'} gap={2} mb={3} width="100%">
        <Avatar
          src={avatar}
          sx={{cursor: 'pointer'}}
          width={32}
          onClick={() => {
            setImageCropModalState(true);
          }}
        />
        {!isMenuCollapsed && (
          <Box overflow="hidden" sx={{cursor: 'pointer'}} onClick={onClick}>
            <EllipsisTypography color="#fff" variant="subtitle2">
              {lastName}
            </EllipsisTypography>
            <EllipsisTypography color="#fff" variant="subtitle2">
              {firstName}
            </EllipsisTypography>
            {user?.subRole !== STUDENT_SUBROLES.amateur && (
              <Typography color={olive['100']} variant="caption1">
                {getRoleLabel(userRole, teacherRole || user?.subRole, '')}
              </Typography>
            )}
          </Box>
        )}
        <Box ml={!isMenuCollapsed && 'auto'}>
          {!isLoading ? (
            <IconButton color={grey['54']} size="medium" onClick={onLogout}>
              <LogoutOutlined fontSize="inherit" sx={{fill: grey['54']}} />
            </IconButton>
          ) : (
            <CircularProgress color="secondary" size={12} />
          )}
        </Box>
      </Stack>

      <ModalCropUploadPanel
        cropModalState={imageCropModalState}
        onChange={(url) => {
          onUpdate({
            avatar: url,
          });
        }}
        onClose={() => {
          setImageCropModalState(false);
        }}
      />
    </>
  );
}
