import styled from 'styled-components';

import {ButtonsBlock} from '../LinesMobile';

export const Wrapper = styled.div`
  position: relative;
  padding-top: 32px;
  padding-bottom: 0;
  min-height: 250px;
  background-color: white;
  border-radius: 6px;
  max-height: ${(props) => (props.isLoading ? '250px' : 'none')};
  overflow: ${(props) => (props.isLoading ? 'hidden' : 'visible')};

  .MuiFormControl {
    &-root {
      padding-right: 12px;
      padding-left: 12px;
      .MuiInputLabel {
        &-root {
          left: 12px;
        }
      }
    }
  }

  ${ButtonsBlock} {
    padding-top: 16px;
    .MuiButton {
      &-root {
        @media screen and (max-width: 360px) {
          padding: 8px 12px;
          font-size: 12px;
        }
      }
    }
  }
`;
export const TitleBlock = styled.div`
  padding: 0 12px;
  .MuiTypography {
    &-subtitle2 {
      &:last-child {
        font-size: 14px;
        line-height: 20px;
        text-transform: ${(props) => (props.isEmptyBlock ? 'none' : 'uppercase')};
      }
    }
  }
`;
