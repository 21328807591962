import TabsNew from '@ui/MUI/TabsNew';
import React from 'react';

import {methodistExercisesContentTabs} from '../../constant';
import {PageBox} from '../../TrainingControlProcess';

const Exercises = () => {
  return (
    <PageBox>
      <TabsNew tabs={methodistExercisesContentTabs} variant="light" />
    </PageBox>
  );
};

export default Exercises;
