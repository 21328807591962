import styled from 'styled-components';

import {Body, Rows} from '../../widgets/TodaySchedule/styles';

export const DashboardsBlockWrapper = styled.div`
  padding-bottom: 8px;
  @media screen and (min-width: 1440px) {
    padding: 8px;
  }
  @media screen and (max-width: 1024px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  margin: 0 -8px;
  box-sizing: border-box;
  @media (min-width: 1200px) and (max-width: 1439px) {
    padding-right: 0;
    padding-left: 0;
  }
  @media screen and (max-width: 1024px) {
    margin: 0;
  }
`;
export const Half = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 0 8px;
  @media screen and (max-width: 1199px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 8px 0 0;
  }
  &:first-child {
    @media screen and (max-width: 1199px) {
      padding-top: 0;
    }
  }
  .Headline {
    padding-left: 24px;
  }
  ${Rows} {
    padding-top: 4px !important;
    padding-left: 24px;
  }
  ${Body} {
    padding-left: 24px;
  }
  .DashboardButtonsBlock {
    border-top: 1px solid #dddad4;
    padding-left: 24px;
  }

  @media screen and (max-width: 360px) {
    .Headline,
    ${Body}, ${Rows} {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
`;
export const ContentText = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font: 600 normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
  span {
    color: #626262;
  }
`;
export const ContentTextScheduleItem = styled(ContentText)`
  color: #626262;
  letter-spacing: -0.02em;
`;
export const Headline = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 4px 24px;
  background-color: white;
  border-top: 1px solid #dddad4;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;
export const Column = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  &:first-child {
    ${ContentText} {
      &:last-child {
        padding-left: 12px;
      }
    }
  }
`;
export const HeadlineColumnTitle = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
`;
export const HeadlineSpaceBetween = styled(Headline)`
  justify-content: space-between;
  ${Column} {
    &:last-child {
      ${HeadlineColumnTitle} {
        text-align: right;
      }
    }
  }
`;
export const GroupName = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: 400 normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
`;
export const CardContainer = styled.div`
  @media screen and (max-width: 480px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  & > a {
    display: block;
    text-decoration: none;
    .LinesAvailablePlayerName {
      transition: text-decoration 0.33s;
    }
    &:focus {
      .LinesAvailablePlayerName {
        text-decoration: none;
      }
    }
    &:hover {
      .LinesAvailablePlayerName {
        text-decoration: underline;
      }
    }
  }
`;
export const CardFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  .LinesAvailablePlayerAvatar {
    margin-left: 0;
  }
`;
export const ContentWrapper = styled.div`
  .TableEmptyBlock {
    padding-left: 24px;
    padding-right: 24px;
    border-top: 1px solid #dddad4;
    border-bottom: 1px solid #dddad4;
    justify-content: flex-start;
    min-height: 80px;
    @media screen and (max-width: 360px) {
      padding-left: 12px;
      padding-right: 12px;
    }
    p {
      @media screen and (max-width: 992px) {
        text-align: left;
      }
    }
  }
`;
export const ScheduleItem = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #f3f3f350;
  padding-right: 24px;
  padding-left: 24px;
  cursor: pointer;
  @media screen and (max-width: 360px) {
    padding-left: 12px;
    padding-right: 12px;
  }
  &:first-child {
    ${Column} {
      .ScheduleItemText {
        color: #990011;
      }
    }
  }
  ${Column} {
    padding-top: 12px;
    padding-bottom: 12px;
    flex: 0 0 50%;
    max-width: 50%;
    &:nth-child(2) {
      @media screen and (max-width: 480px) {
        padding-top: 0;
      }
    }
    &:last-child {
      @media screen and (max-width: 480px) {
        padding-left: 0;
      }
    }
  }
`;
export const ScheduleItemRoom = styled(HeadlineColumnTitle)`
  color: #626262;
  text-align: right;
`;
export const ContentWrapperSchedule = styled(ContentWrapper)`
  ${HeadlineSpaceBetween},
  ${ScheduleItem} {
    ${Column} {
      &:last-child {
        flex: 0 0 32%;
        max-width: 32%;
        @media screen and (max-width: 480px) {
          flex: 0 0 50%;
          max-width: 50%;
          order: 2;
        }
      }
      &:first-child {
        flex: 0 0 18%;
        max-width: 18%;
        @media screen and (max-width: 480px) {
          flex: 0 0 50%;
          max-width: 50%;
          order: 1;
        }
        ${ContentText} {
          &:last-child {
            padding-left: 0;
            color: #626262;
          }
          &:first-child {
            @media screen and (max-width: 360px) {
              padding-left: 0;
              color: #626262;
            }
          }
        }
      }
      &:nth-child(2) {
        @media screen and (max-width: 480px) {
          flex: 0 0 100%;
          max-width: 100%;
          order: 3;
        }
      }
    }
  }
`;
export const BoxShadow = styled.div`
  position: relative;
  @media screen and (max-width: 480px) {
    display: none;
  }
  &:after {
    display: block;
    width: 100%;
    height: 2px;
    box-shadow: 0 5px 5px -5px #333;
    content: '';
  }
`;
export const ContentBody = styled.div`
  background-color: white;
`;
export const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px 24px;
  min-height: 53px;
  background-color: white;
  border-bottom: 1px solid #dddad4;
  @media screen and (max-width: 475px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 360px) {
    padding: 12px;
  }
`;
export const ContentRowTextBlock = styled.div`
  text-align: right;
  @media screen and (max-width: 480px) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left;
  }
  &:last-child {
    @media screen and (max-width: 480px) {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
`;
export const ContentRowText = styled(HeadlineColumnTitle)`
  max-width: 210px;
  color: #626262;
  @media screen and (max-width: 480px) {
    max-width: none;
  }
`;
