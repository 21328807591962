import styled from 'styled-components';

export const Header = styled.p`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  .number {
    color: #887755;
  }
`;

export const HeaderTeam = styled.p`
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #747474;
  margin: 0;
`;

export const HeaderDate = styled.p`
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
`;
