import Box from '@ui/MUI/Box';
import {grey} from '@ui/MUI/colors';
import styled from 'styled-components';

export const TableWrapper = styled(Box)`
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  .borderRight {
    border-right: 1px solid;
  }
  .borderTop {
    border-top: 1px solid;
  }
  .MuiDataGrid {
    &-footerContainer {
      display: none;
    }
    &-row {
      &:last-child {
        .MuiDataGrid-cell {
          border-bottom: none !important;
        }
      }
      &:first-child {
        .borderTop {
          border-top: initial;
        }
      }
    }
    &-cell,
    &-columnHeader {
      border-color: ${grey[12]} !important;
    }
    &-columnSeparator {
      display: none;
    }
    &-columnHeaderTitle {
      ${({theme}) => theme.typography.caption2}
      white-space: break-spaces !important;
    }
    &-cellContent {
      ${({theme}) => theme.typography.body2}
    }
    &-columnHeader {
      padding: ${({theme}) => theme.spacing(1, 4)} !important;
    }
    &-cell {
      padding: ${({theme}) => theme.spacing(3, 4)} !important;
    }
  }
  .goaltenders_table {
    .MuiDataGrid-row:nth-last-child(-n + 2) {
      .MuiDataGrid-cell {
        border-bottom-width: 2px;
      }
    }
    .MuiDataGrid-row:last-child {
      .MuiDataGrid-cell,
      .MuiDataGrid-cellContent {
        font-weight: 600 !important;
      }
    }
  }
`;
