import React from 'react';

import {ChipContainer, TrainerAvatar} from '../groups/components/group/style';

export const formatGroup = (group, isEdit) => {
  return {
    name: group.name,
    description: group.description,
    birthYear: group.birthYear,
    instatId: group.instatId,
    schoolId: group.schoolId.value ? group.schoolId.value : group.schoolId,
    teachersIds: isEdit ? group.teachers.map((teacher) => teacher.id) : group.teachers.map((teacher) => teacher.value),
  };
};
export const formatTeacherChipContent = (option, key) => {
  return (
    <ChipContainer key={key}>
      <TrainerAvatar src={option.avatar} />
      {option.lastName} {option.firstName}
    </ChipContainer>
  );
};
