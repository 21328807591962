import ChildrenField from '@common/ChildrenField';
import SelectableList from '@common/SelectableList';
import Skill from '@common/Skill';
import {useSkill} from '@components/apps/TrainingControlProcess/hooks/api';
import useStoreDispatch from '@hooks/useStoreDispatch';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import {fetcherTrainingControl} from '@slices/trainingControlProcess';
import Stack from '@ui/MUI/Stack';
import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';

import {StyledSkill} from './style';

const FormContentSkills = () => {
  const skill = useSkill();
  const {setValue, watch} = useFormContext();
  const skills = watch('skills');
  const dispatch = useStoreDispatch();

  const deleteSkill = (id) => {
    const filteredSkills = skills.filter((skill) => skill.id !== id);
    setValue('skills', filteredSkills, {shouldValidate: true, shouldDirty: true});
  };

  useEffect(() => {
    dispatch(fetcherTrainingControl('skill', {}));
  }, []);

  return (
    <Stack spacing={4}>
      <ChildrenField isRequired name="skills">
        <SelectableList
          isLoading={skill.isLoading}
          label="Введите код навыка или название"
          options={skill.data}
          value={skills}
          onInputChange={(search) => {
            dispatch(fetcherTrainingControl('skill', {search}));
          }}
        >
          {({handleSelect, ...skill}) => {
            return (
              <StyledSkill
                {...skill}
                endIcon={skill.isSelected && <Check color="primary" sx={{fontSize: '20px'}} />}
                key={skill.id}
                onClick={handleSelect}
              />
            );
          }}
        </SelectableList>
      </ChildrenField>
      <Stack spacing={1}>
        {skills?.map((skill) => {
          return <Skill {...skill} endIcon={<Close onClick={() => deleteSkill(skill.id)} />} key={skill.id} />;
        })}
      </Stack>
    </Stack>
  );
};

export default FormContentSkills;
