import isEqual from 'lodash/isEqual';
import {useEffect, useRef} from 'react';

const useDeepEffect = (cb, deps) => {
  const ref = useRef({});

  if (!isEqual(ref.current, deps)) {
    ref.current = deps;
  }

  useEffect(cb, [ref.current]);
};

export default useDeepEffect;
