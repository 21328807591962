import {random} from 'lodash';

const groupObj = {
  id: 'jq-AJj4heYKl',
  name: 'увцувц',
  tag: {
    name: 'a',
    slug: 'backcheck',
  },
  link: 'https://www.youtube.com/watch?v=srodNyimqTM',
  createdAt: '2023-04-25T14:33:52.797Z',
  isNew: false,
};
export const mock = (length = 12) =>
  [...Array(Number(length) > 12 || Number(length) === 0 ? 12 : Number(length))]
    .fill(groupObj)
    .map((item) => ({...item, tag: {...item.tag, name: 'a'.repeat(random(8, 20))}}));
