import ReactTableComponent from '@common/ReactTableComponent';
import {Stack, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

Table.propTypes = {
  columns: PropTypes.array,
  isLoading: PropTypes.bool,
  isLoad: PropTypes.bool,
  data: PropTypes.array,
};

function Table({columns, isLoad, error, data}) {
  return (
    <>
      {(isLoad && !data?.length) || error ? (
        <Stack alignItems="center" height={350} justifyContent="center">
          <Typography variant="subtitle1">Данные отсутствуют</Typography>
        </Stack>
      ) : (
        <ReactTableComponent
          columns={columns}
          data={data}
          data-testid="table"
          tableBodyCellProps={{sx: {py: {xxs: 1, sm: 3}, px: {xxs: 2, sm: 4}, height: 'auto !important'}}}
          tableHeaderCellProps={{sx: {py: 0, px: {xxs: 2, sm: 4}, height: 'auto !important'}}}
        />
      )}
    </>
  );
}

export default Table;
