import {useEffect, useState} from 'react';

const useDebounce = (currentValue, ms) => {
  const [value, setValue] = useState(currentValue);

  useEffect(() => {
    const timeOut = setTimeout(() => setValue(currentValue), ms);
    return () => clearTimeout(timeOut);
  }, [currentValue, ms]);

  return value;
};

export default useDebounce;
