import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const DashboardsAPI = {
  getManagerDashboard(filter) {
    return axios.get(`${API_ENDPOINT}/dashboard/manager`, {params: filter});
  },
  getTeachersDashboard() {
    return axios.get(`${API_ENDPOINT}/dashboard/teacher`);
  },
  getDashboard() {
    return axios.get(`${API_ENDPOINT}/v1/dashboard`);
  },
  getPastGamesOfSeason(filter) {
    return axios.get(`${API_ENDPOINT}/v1/dashboard/manager/pastGames`, {params: filter});
  },
};

export default DashboardsAPI;
