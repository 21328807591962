import {
  handleChangeMedicalProfileRecommendation,
  postPlayerMedicalProfileForm,
  savePlayerMedicalProfileRecommendation,
} from '@actions/MedicalProfileActions';
import AddIcon from '@mui/icons-material/Add';
import {userRoles} from '@src/constants';
import ButtonDefault from '@ui/MUI/Button';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import OldTabs from '@ui/OldTabs';
import SelectDefault from '@ui/Select';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import styled, {createGlobalStyle} from 'styled-components';

import CommonHelper from '../../../helpers/CommonHelper';
import StateHelper from '../../../helpers/StateHelper';
import {TYPES_OPTIONS} from '../constants';
import Consultation from '../modals/Consultation';
import GeneralInfo from '../modals/GeneralInfo';
import Nutrition from '../modals/Nutrition';
import Recommendations from '../modals/Recommendations';
import Training from '../modals/Training';

export default function MedicalProfileFilter(props) {
  let {onChange = () => {}, updateUserData = () => {}} = props;
  const [addModalVisible, setAddModalVisible] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: {errors},
    reset,
  } = useForm();
  const currentUser = useSelector((state) => state.users.currentUser);
  const medicalProfileForm = useSelector((state) => state.medicalProfile.medicalProfileForm || {});
  const state = useSelector((state) => state, shallowEqual);
  const schools = useSelector((state) => {
    const currentUser = state.users.currentUser;

    const schools = state.schools.schoolsMap.toArray().filter((u) => u.teachersIds.indexOf(currentUser.id) > -1);
    const allSchools = state.schools.schoolsMap.toArray();
    return currentUser.userRole === userRoles.teacher ? schools : allSchools;
  }, shallowEqual);
  const players = useSelector((state) => state.medicalProfile.medicalProfileFiltersPlayers);
  const medicalProfileRecommendation = useSelector(
    (state) =>
      state.medicalProfile.medicalProfileRecommendation || {
        comment: '',
      },
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      handleChangeMedicalProfileRecommendation({
        comment: '',
        taskGroups: [],
      }),
    );
  }, [medicalProfileForm.objectId]);

  const addModalContent = () => {
    return (
      <>
        <ModalTitle>Добавить</ModalTitle>
        <OldTabs
          tabs={[
            {
              label: 'Общая информация',
              content: <GeneralInfo addCard={addCard} control={control} errors={errors} setValue={setValue} />,
            },
            {
              label: 'Тренировки',
              content: <Training />,
            },
            {
              label: 'Посещение/Консультация',
              content: <Consultation />,
            },
            {
              label: 'Питание',
              content: <Nutrition />,
            },
            {
              label: 'Рекомендации',
              content: <Recommendations value={medicalProfileRecommendation} />,
            },
          ]}
        />
      </>
    );
  };

  const [schoolsOptions, setSchoolsOptions] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [playersOptions, setPlayersOptions] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState('');

  useEffect(() => {
    if (players?.length > 0) {
      setPlayersOptions(players);
    }
  }, [players]);

  const convertSchoolToOptions = (schools) => {
    if (!schools) return;
    let result = schools.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

    result.sort((a, b) => {
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      return 0;
    });

    return result;
  };
  useEffect(() => {
    schools?.length && !schoolsOptions.length && setSchoolsOptions(convertSchoolToOptions(schools));
  }, [schools]);
  useEffect(() => {
    if (currentUser.userRole === userRoles.student) {
      const schoolId = currentUser.schoolId;
      const school = schools.filter((school) => school.id === schoolId)[0];
      const group = StateHelper.getStudentGroup(state, currentUser?.id);

      if (school && group) {
        setSelectedGroup({
          value: group.id,
          label: group.name,
        });
        setSelectedSchool({
          value: school.id,
          label: school.name,
        });
        setSelectedPlayer({
          value: currentUser?.id,
          label: `${currentUser.lastName} ${currentUser.firstName}`,
        });
      }
    }
  }, [currentUser, schools]);
  useEffect(() => {
    if (selectedSchool.value) {
      StateHelper.getGroupsBySchoolId(selectedSchool.value).then((options) => {
        setGroupOptions(options);
      });
    }
  }, [selectedSchool.value]);

  const getFilterObject = () => {
    let result = {};
    result.group = selectedGroup ? selectedGroup.value : '';
    result.school = selectedSchool ? selectedSchool.value : '';
    result.playerId = selectedPlayer ? selectedPlayer.value : '';
    result.type = selectedType ? selectedType.value : '';
    return result;
  };

  const isValidMedicalForm = (medicalProfileForm) => {
    let result = true;

    if (
      !medicalProfileForm.school ||
      !medicalProfileForm.group ||
      !medicalProfileForm.description ||
      !medicalProfileForm.playerId ||
      !medicalProfileForm.type ||
      !medicalProfileForm.status
    ) {
      result = false;
    }

    return result;
  };

  useEffect(() => {
    const filterObject = getFilterObject();
    onChange({...filterObject});
  }, []);

  const onSubmit = () => {};
  const [addCard, setAddCard] = useState(false);

  useEffect(() => {
    if (!addModalVisible) {
      setAddCard(false);
    }
  }, [addModalVisible]);

  return (
    <Wrapper className={'SkillAssessmentFilter'} id="medical_form" onSubmit={handleSubmit(onSubmit)}>
      <Filter className={'Filter'}>
        <Column className={'Column'}>
          <Label>Школа</Label>
          <SelectDefault
            isMulti={false}
            options={schoolsOptions}
            placeholder={'Выберите школу'}
            value={selectedSchool}
            onChange={(a) => {
              const filterObject = getFilterObject();
              onChange({...filterObject, school: a.value});
              setSelectedSchool(a);
              setSelectedGroup('');
            }}
          />
        </Column>
        <Column className={'Column'}>
          <Label>Команда</Label>
          <SelectDefault
            isClearable={true}
            isLoading={selectedSchool && !groupOptions.length > 0}
            isMulti={false}
            options={CommonHelper.getDropDownTeamsByNamedList(groupOptions)}
            placeholder={'Выберите команду'}
            value={selectedGroup}
            onChange={(a) => {
              setSelectedGroup(a);
              setSelectedPlayer('');
              const filterObject = getFilterObject();
              onChange({...filterObject, group: a ? a.value : '', playerId: ''});
            }}
          />
        </Column>
        <Column className={'Column'}>
          <Label>Тип</Label>
          <SelectDefault
            isClearable={true}
            isMulti={false}
            options={TYPES_OPTIONS}
            placeholder={'Выберите тип'}
            value={selectedType}
            onChange={(a) => {
              setSelectedType(a);
              const filterObject = getFilterObject();
              onChange({...filterObject, type: a ? a.value : ''});
            }}
          />
        </Column>
        <Column className={'Column'}>
          <Label>Игрок</Label>
          <SelectDefault
            isMulti={false}
            options={CommonHelper.getDropDownItemsByUsers(playersOptions)}
            placeholder={'Выберите игрока'}
            value={selectedPlayer}
            onChange={(a) => {
              setSelectedPlayer(a);

              const filterObject = getFilterObject();
              onChange({...filterObject, playerId: a.value});
            }}
          />
        </Column>
        <ButtonWrapper>
          <ButtonContainer>
            <ButtonDefault
              className={'ButtonWithIcon MedicalProfileAddButton'}
              color="primary"
              startIcon={<AddIcon />}
              type="button"
              variant="contained"
              onClick={() => {
                setAddModalVisible({...addModalVisible, right: true});
                setAddCard(true);
              }}
            >
              Добавить
            </ButtonDefault>
          </ButtonContainer>
        </ButtonWrapper>
      </Filter>
      <>
        <GlobalStyle />
        <DrawerDefault
          buttons={
            <ModalButtonsContainer>
              <ButtonDefault
                color="primary"
                form="medical_form"
                type="submit"
                variant="contained"
                onClick={() => {
                  if (!isValidMedicalForm(medicalProfileForm)) return;
                  dispatch(postPlayerMedicalProfileForm(medicalProfileForm, false)).then((data) => {
                    if (!data) {
                      return;
                    }
                    const requestObject = {
                      playerId: medicalProfileForm.playerId,
                      comment: medicalProfileRecommendation.comment,
                      taskGroups: medicalProfileRecommendation.taskGroups || [],
                    };
                    if (medicalProfileForm.playerId) {
                      dispatch(savePlayerMedicalProfileRecommendation(data.data.objectId, requestObject))
                        .then(() => {
                          dispatch({
                            type: 'MEDICAL_PROFILE_LOADING_FORM',
                            medicalProfileForm: {},
                          });
                          setAddModalVisible({...addModalVisible, right: false});
                          updateUserData();
                        })
                        .finally(() => {
                          reset({});
                        });
                    }
                  });
                }}
              >
                Сохранить
              </ButtonDefault>
              <ButtonDefault
                color="neutral"
                variant="contained"
                onClick={() => {
                  setAddModalVisible({...addModalVisible, right: false});
                  reset({});
                }}
              >
                Закрыть
              </ButtonDefault>
            </ModalButtonsContainer>
          }
          content={addModalContent()}
          drawerState={addModalVisible}
          setDrawerState={setAddModalVisible}
        />
      </>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  padding: 20px 34px 20px;
  margin-top: 20px;
  margin-bottom: 8px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  @media screen and (max-width: 480px) {
    padding-right: 18px;
    padding-left: 18px;
  }

  .TabItemsBlock {
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 -20px;
    justify-content: flex-start;
    background-color: #fffaed;
    box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.3);

    @media (min-width: 769px) and (max-width: 1200px) {
      height: 60px;
    }
    @media screen and (max-width: 768px) {
      padding-top: 8px;
      padding-bottom: 8px;
      flex-direction: column;
      height: auto;
    }

    .TabName {
      margin-right: 24px;
      color: #990011;
      text-transform: uppercase;
      @media screen and (max-width: 768px) {
        margin-right: 0;
        :last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .AddComment {
    .sidebar__inner {
      padding-right: 20px;
      padding-left: 20px;

      .sidebar__header {
        .sidebar__closer {
          right: 20px;
        }
      }

      .sidebar__content {
        @media screen and (max-width: 767px) {
          padding: 0;
        }
      }
    }
  }

  .EditTherapy {
    .sidebar__inner {
      .sidebar__content {
        padding-bottom: 0;
      }
    }
  }
`;

const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -14px;
  margin-left: -14px;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    justify-content: flex-end;
  }
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-left: 0;
  }
  @media screen and (max-width: 480px) {
    margin-right: 0;
    margin-left: 0;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font: 600 normal 12px/16px 'Proxima Nova';
  color: #747474;
  text-transform: uppercase;
`;
const Column = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 14px;
  padding-left: 14px;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1199px) {
    margin-bottom: 12px;

    :nth-child(4),
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;
const ButtonWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 14px;
  padding-right: 14px;
  @media screen and (max-width: 1024px) {
    padding-top: 14px;
    padding-bottom: 0;
  }
  @media screen and (max-width: 992px) {
    padding-top: 20px;
    padding-right: 0;
    padding-left: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 360px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
const ButtonContainer = styled.div`
  @media screen and (min-width: 1025px) {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
const ModalTitle = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  font: bold normal 16px/20px 'Proxima Nova';
  color: rgb(44, 44, 44);
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;
const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const GlobalStyle = createGlobalStyle`
  .MuiDrawer {
    &-paper {
      max-width: calc(100% - 250px) !important; 
      @media screen and (max-width:1024px) {
        max-width: 100%;
      }
      .TabItemsBlock {
        padding-right: 32px;
        padding-left: 32px;
        margin: 0 -78px 0 -32px;
        justify-content: flex-start;
        background-color: #fffaed;
        box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.3);

        @media (min-width: 769px) and (max-width: 1200px) {
          height: 60px;
        }
        @media screen and (max-width: 768px) {
          padding-top: 8px;
          padding-bottom: 8px;
          flex-direction: column;
          height: auto;
        }

        .TabName {
          margin-right: 24px;
          color: #990011;
          text-transform: uppercase;
          @media screen and (max-width: 768px) {
            margin-right: 0;
            :last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }  
    }
  } 
`;
