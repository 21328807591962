import InfoBlock from '@components/apps/PlayerPersonalFile/components/DevelopmentJournal/components/InfoBlock';
import Skills from '@components/apps/PlayerPersonalFile/components/DevelopmentJournal/components/Skills';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectCategories, selectPersonalDevelopmentJournalPlayerSkills} from '@selectors/skill';
import {fetchCategories, fetchPersonalDevelopmentJournalSkillsByPlayerId} from '@slices/skill';
import {Stack} from '@ui/MUI';
import React, {useEffect} from 'react';
import {useParams} from 'react-router';

function PersonalFileDevelopmentJournal() {
  const {id} = useParams();
  const dispatch = useStoreDispatch();
  useEffect(() => {
    dispatch(fetchCategories());
    if (id) {
      dispatch(fetchPersonalDevelopmentJournalSkillsByPlayerId(id));
    }
  }, [id]);
  const categories = useStore(selectCategories);
  const {data, isLoad, isLoading} = useStore(selectPersonalDevelopmentJournalPlayerSkills);
  return (
    <Stack direction="row" flexWrap="wrap" gap={3} mb={3}>
      <InfoBlock categories={categories} isLoading={isLoading} percentForGroup={data?.percentForGroup} />
      <Skills isLoad={isLoad} isLoading={isLoading} skills={data} />
    </Stack>
  );
}

export default PersonalFileDevelopmentJournal;
