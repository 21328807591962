import React from 'react';

function MissedShotsIcon(props) {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.1726 5.27843L10.7209 5.82676L8.54703 8.00065L10.7209 10.1745L10.1726 10.7229L7.9987 8.54898L5.82481 10.7229L5.27648 10.1745L7.45036 8.00065L5.27648 5.82676L5.82481 5.27843L7.9987 7.45232L10.1726 5.27843Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default MissedShotsIcon;
