import useQueryString from '@hooks/useQueryString';
import {Autocomplete, Box, Stack} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

import Wrapper from '../Wrapper';

Filters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      multiply: PropTypes.bool,
      disabled: PropTypes.bool,
      isLoading: PropTypes.bool,
      options: PropTypes.array.isRequired,
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};

function Filters({filters = [], onChange, value = {}, children, sx}) {
  const [qs, setQs] = useQueryString();
  return (
    <Wrapper>
      <Stack alignItems="center" direction="row" gap={2} sx={sx}>
        <Stack direction="row" gap={{xxs: 2, sm: 5}} width="100%">
          {filters.map((item, index) => {
            return (
              <Box key={index} maxWidth={{xxs: '100%', sm: 190}} width="100%">
                <Autocomplete
                  {...item}
                  label={item?.label}
                  multiple={item?.multiple}
                  value={value?.[item?.value] || null}
                  onChange={(e, v) => {
                    setQs({...qs, [item?.value]: v?.value});
                    onChange({...value, [item?.value]: v || null});
                    item?.onChange?.(v);
                  }}
                />
              </Box>
            );
          })}
        </Stack>
        <Box>{children}</Box>
      </Stack>
    </Wrapper>
  );
}

export default Filters;
