import useQueryStringTabsWithSubTabs from '@hooks/useQueryStringTabsWithSubtabs';
import useStore from '@hooks/useStore';
import {selectShotsMapData, selectThrowinsMap} from '@selectors/gameStatistics';
import {selectActionsMapData} from '@selectors/gameStatisticsMkc';
import {clearShotsMap, loadShotsMap} from '@slices/statistics/shotsMap';
import {clearThrowinsMap, loadThrowinsMap} from '@slices/statistics/throwins';
import {clearActionsMap, loadActionsMap} from '@slices/statisticsMKC';
import {StatisticsGetStatisticsListItemDto} from '@src/types/generated';
import {Box, Button, Stack} from '@ui/MUI';
import Drawer from '@ui/MUI/DrawerNew';
import TabsNew from '@ui/MUI/TabsNew';
import React, {Dispatch, SetStateAction} from 'react';

import ActionVideoModalForPersonalFileContent from '../ActionVideoModalForPersonalFileContent';

type ActionVideoModalForPersonalFileProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: StatisticsGetStatisticsListItemDto;
};

function ActionVideoModalForPersonalFile({isOpen, setIsOpen, data}: ActionVideoModalForPersonalFileProps) {
  const {student} = useStore((state) => state.users);
  const tabs = [
    // {id: 'goals', label: 'Голы', value: <ActionVideoModalForPersonalFileContent />},
    {
      id: 'shots',
      label: 'Броски',
      value: (
        <ActionVideoModalForPersonalFileContent
          cleaner={clearShotsMap}
          loader={loadShotsMap}
          mapDataGetter={selectShotsMapData}
          selectedPlayerName={`${student.firstName} ${student.lastName}`}
          statisticsIds={data?.statisticId ? [data?.statisticId] : []}
        />
      ),
    },
    {
      id: 'actions',
      label: 'Действия',
      value: (
        <ActionVideoModalForPersonalFileContent
          cleaner={clearActionsMap}
          loader={loadActionsMap}
          mapDataGetter={selectActionsMapData}
          selectedPlayerName={`${student.firstName} ${student.lastName}`}
          statisticsIds={data?.statisticId ? [data?.statisticId] : []}
        />
      ),
    },
    {
      id: 'shifts',
      label: 'Смены',
      value: (
        <ActionVideoModalForPersonalFileContent
          isShifts={true}
          selectedPlayerName={`${student.firstName} ${student.lastName}`}
          statisticsIds={data?.statisticId ? [data?.statisticId] : []}
        />
      ),
    },
    {
      id: 'faceoffs',
      label: 'Вбрасывания',
      value: (
        <ActionVideoModalForPersonalFileContent
          cleaner={clearThrowinsMap}
          isFaceOffs={true}
          loader={loadThrowinsMap}
          mapDataGetter={selectThrowinsMap}
          selectedPlayerName={`${student.firstName} ${student.lastName}`}
          statisticsIds={data?.statisticId ? [data?.statisticId] : []}
        />
      ),
    },
  ];
  const {value, onChange} = useQueryStringTabsWithSubTabs({tabs});
  return (
    <Drawer
      buttons={
        <Stack
          direction={{xxs: 'column', xs: 'row'}}
          flexWrap="wrap"
          gap={3}
          justifyContent="space-between"
          width="100%"
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Закрыть
          </Button>
        </Stack>
      }
      open={isOpen}
      title={`${data?.team || ''} - ${data?.opponent || ''}`}
      onClose={() => {
        setIsOpen(false);
        return false;
      }}
    >
      <Box mt={5}>
        <TabsNew tabs={tabs} value={value} withoutPx={true} onChange={onChange} />
      </Box>
    </Drawer>
  );
}

export default ActionVideoModalForPersonalFile;
