import EditableCellComponent from '@common/EditableCellComponent';
import {noDataCheck} from '@components/apps/NewBodyMassIndex/helpers';
import {longDash} from '@src/constants';
import {Box, Stack, TextField, Tooltip, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';

const EditableCell = ({isEdit = false, isLoading, isDisabled, max = 99.99, ...props}) => {
  return (
    <EditableCellComponent
      {...props}
      EditComponent={(props) => {
        return (
          <Stack direction="row" gap={1} width="100%">
            {['от', 'до'].map((item, index) => {
              const name = 'fields.' + props.cell.id?.replaceAll('_', '.');
              return (
                <Box key={item} width="50%">
                  <Controller
                    name={name + `.${index}`}
                    render={({field: {onChange, value}, fieldState: {error, ...fieldState}}) => {
                      return (
                        <Tooltip open={!!error} placement="bottom" title={error?.message}>
                          <TextField
                            control={props.control}
                            disabled={isDisabled}
                            helperText=""
                            inputProps={{step: 0.1, min: 0, max}}
                            label={item}
                            step={0.1}
                            type="number"
                            {...fieldState}
                            error={!!error}
                            value={value}
                            onChange={(e) => {
                              e.stopPropagation();
                              let value = e.target.value;
                              if (value > max) value = max;
                              onChange(noDataCheck(value) ? parseFloat(value) : null);
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </Tooltip>
                      );
                    }}
                    rules={{
                      validate: (value, formValues) => {
                        const neighbor = get(formValues, name + `.${index === 1 ? 0 : 1}`);
                        if (noDataCheck(neighbor) && !noDataCheck(value)) {
                          return 'Введите значение';
                        }
                        if (index === 1 && value < neighbor) return `Значение должно быть больше ${neighbor}`;
                        if (value > max) return `Значение не должно быть больше ${max}`;
                      },
                    }}
                  />
                </Box>
              );
            })}
          </Stack>
        );
      }}
      InitialComponent={({children}) => {
        return (
          <Typography
            color={grey['68']}
            data-testid="cell-value"
            isLoading={isLoading}
            preloaderWidthBetween={[40, 100]}
            variant="body2"
          >
            {noDataCheck(children?.[0]) && noDataCheck(children?.[1])
              ? `от ${children[0]} - до ${children[1]}`
              : longDash}
          </Typography>
        );
      }}
      data-testid="editable-cell"
      isActive={isEdit}
      key={props?.cell?.id}
      setIsActive={() => {}}
    />
  );
};

EditableCell.propTypes = {
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  max: PropTypes.number,
};

export default React.memo(EditableCell);
