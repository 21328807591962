import {UPDATE_FILTERS} from '../ActionTypes';

const filtersReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return {...state, ...action.filters};
    default:
      return state;
  }
};

export default filtersReducer;
