import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% / 4 - 8px * 3 / 4);
  background: #ffffff;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: ${({theme}) => theme.spacing(3)};
  box-sizing: border-box;
  @media screen and (max-width: 1400px) {
    width: calc(100% / 3 - 8px * 2 / 3);
  }
  @media screen and (max-width: 768px) {
    width: calc(100% / 2 - 8px / 2);
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

export const Title = styled.p`
  position: relative;
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2c;
  margin: 0;
  margin-bottom: ${({theme}) => theme.spacing(0)};
`;

export const ConditionItem = styled.span`
  position: ${(props) => (!props.reportsCount ? 'initial' : 'absolute')};
  top: ${(props) => (!props.reportsCount ? 'initial' : '50%')};
  right: auto;
  transform: ${(props) => (!props.reportsCount ? 'initial' : 'translateY(-50%)')};
  margin: 0;
  display: ${(props) => (!props.reportsCount ? 'block' : 'initial')};
  padding-right: 0;
  padding-left: ${(props) => (!props.reportsCount ? 0 : '8px')};
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
`;

export const LinkText = styled.p`
  cursor: pointer;
  margin-top: ${({theme}) => theme.spacing(4)};
  margin-bottom: 0;
  font: 600 normal 14px/22px 'Proxima Nova';
  color: #b51f18;
  letter-spacing: 0.46px;
  text-transform: uppercase;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  height: 22px;
  align-items: center;
  p {
    margin-left: ${({theme}) => theme.spacing(2)};
  }
`;
