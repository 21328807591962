import useDeepEffect from '@hooks/useDeepEffect';
import TabsDefault from '@ui/MUI/Tabs';
import React, {memo, useMemo} from 'react';
import {useSelector} from 'react-redux';

const PsychoTabs = (props) => {
  const {onChange} = props;

  const groups = useSelector((state) =>
    state.groups.groupsMap
      .toArray()
      .filter((group) => group.schoolId === 'ITfD4PL0oy')
      .sort((a, b) => a.birthYear - b.birthYear),
  );

  const formatedGroups = useMemo(() => {
    if (groups) {
      return groups?.map((group) => ({label: group.name, value: group.id}));
    }
    return [];
  }, [groups]);

  useDeepEffect(() => {
    if (formatedGroups[0]?.value) {
      onChange(formatedGroups[0]?.value);
    }
  }, [formatedGroups]);

  const handelChange = (newValue) => {
    onChange && onChange(formatedGroups[newValue]?.value);
  };

  return (
    groups?.length > 0 && (
      <TabsDefault
        tabs={{
          headlineItems: formatedGroups.map((group) => ({label: group.label})),
        }}
        onChange={handelChange}
      />
    )
  );
};

export default memo(PsychoTabs);
