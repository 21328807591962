import 'react-quill/dist/quill.snow.css';

import MarkdownEditor from '@common/MarkdownEditor';
import {POTENTIAL_OPTIONS, ROLE_MODEL_OPTIONS} from '@src/constants';
import SelectDefault from '@ui/MUI/Select';
import TextFieldDefault from '@ui/MUI/TextField';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {Field, Half} from '../CommonScoutReport';

export default function ReportFields() {
  const {control} = useFormContext();
  return (
    <>
      <Field margin="0 0 24px 0">
        <Controller
          control={control}
          name="description"
          render={({field: {value, onChange, ...field}, fieldState: {error}}) => (
            <MarkdownEditor
              error={!!error}
              helperText={error?.message}
              placeholder="Отчет"
              value={value}
              onChange={(value) => onChange(value)}
              {...field}
            />
          )}
          rules={{
            required: 'Заполните поле',
            validate: (value) => (value === '<p><br></p>' ? 'Заполните поле' : true),
          }}
        />
      </Field>

      <Field margin="0 0 24px 0">
        <Controller
          control={control}
          name="strongSide"
          render={({field: {value, onChange}, fieldState: {error}}) => (
            <TextFieldDefault
              multiline
              error={!!error}
              helperText={error?.message}
              inputProps={{maxLength: 1000}}
              label={'Сильные стороны'}
              maxRows={4}
              value={value}
              onChange={onChange}
            />
          )}
          rules={{required: 'Заполните поле'}}
        />
      </Field>

      <Field margin="0 0 24px 0">
        <Controller
          control={control}
          name="weakSide"
          render={({field: {value, onChange}, fieldState: {error}}) => (
            <TextFieldDefault
              multiline
              error={!!error}
              helperText={error?.message}
              inputProps={{maxLength: 1000}}
              label={'Слабые стороны'}
              maxRows={4}
              value={value}
              onChange={onChange}
            />
          )}
          rules={{required: 'Заполните поле'}}
        />
      </Field>

      <Field margin="0 0 24px 0">
        <Half>
          <Controller
            control={control}
            name="potential"
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <SelectDefault
                error={!!error}
                hasFixedLabel={false}
                helperText={error?.message}
                label="Потенциал"
                options={[...POTENTIAL_OPTIONS]}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{required: false}}
          />
        </Half>
        <Half>
          <Controller
            control={control}
            name="roleModel"
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <SelectDefault
                error={!!error}
                hasFixedLabel={false}
                helperText={error?.message}
                label="Ролевая модель"
                options={[...ROLE_MODEL_OPTIONS]}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{required: 'Выберите значение'}}
          />
        </Half>
      </Field>
    </>
  );
}
