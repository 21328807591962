import ChildrenField from '@common/ChildrenField';
import MultiFileUpload from '@common/MultiFileUpload';
import {mimeType} from '@src/constants';
import React from 'react';

const accept = `${mimeType.image}, ${mimeType.video}, ${mimeType.pdf}, ${mimeType.powerPoint}`;

const FormContentImage = () => {
  return (
    <ChildrenField isRequired name="schemesFile">
      <MultiFileUpload accept={accept} label="Загрузить схему" max={4} maxSizeMb={300} />
    </ChildrenField>
  );
};

export default FormContentImage;
