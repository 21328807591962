import ContentWrapper from '@common/ContentWrapper';
import Buttons from '@common/Statistics/ActionsMap/Buttons';
import Field from '@common/Statistics/ActionsMap/Field';
import Filters from '@common/Statistics/ActionsMap/Filters';
import MatchesFilter from '@components/apps/StatisticsWithMkcUral/components/MatchesFilter';
import {useMatches} from '@components/apps/StatisticsWithMkcUral/hooks/useMatches';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectActionsMapButtonTypes, selectActionsMapData, selectActionsMapFilters} from '@selectors/gameStatisticsMkc';
import {
  clearActionsMap,
  clearActionsMapFilters,
  clearButtonTypes,
  loadActionsMap,
  loadButtonTypes,
  setActionsMapFilters,
} from '@slices/statisticsMKC';
import {Stack} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

ActionsMap.propTypes = {
  hasOT: PropTypes.bool,
};

function ActionsMap({hasOT}) {
  const {statisticIds, isMatchesLoading} = useMatches();
  const dispatch = useStoreDispatch();
  return (
    <Stack direction="column" gap={1}>
      <MatchesFilter
        wrapperProps={{mb: '0'}}
        onClear={() => {
          dispatch(setActionsMapFilters({playerIds: []}));
          dispatch(clearActionsMap());
        }}
      />

      <Filters
        getter={selectActionsMapFilters}
        hasOT={hasOT}
        setter={setActionsMapFilters}
        statisticsIds={statisticIds}
      />
      <ContentWrapper>
        <Buttons
          cleanup={() => {
            dispatch(clearButtonTypes());
            dispatch(clearActionsMapFilters());
          }}
          filterGetter={selectActionsMapFilters}
          getter={selectActionsMapButtonTypes}
          loader={loadButtonTypes}
          setter={setActionsMapFilters}
          typesGetter={selectActionsMapButtonTypes}
        />
        <Field
          weAtHome
          cleaner={clearActionsMap}
          filtersGetter={selectActionsMapFilters}
          isLoading={isMatchesLoading}
          loader={loadActionsMap}
          mapDataGetter={selectActionsMapData}
          statisticsIds={statisticIds}
        />
      </ContentWrapper>
    </Stack>
  );
}

export default ActionsMap;
