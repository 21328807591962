import ActionText from '@common/ActionText';
import SearchInput from '@common/SearchInput';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import useUpdateEffect from '@hooks/useUpdateEffect';
import {Checkbox, FormControlLabel} from '@mui/material';
import {fetcherTrainingControl} from '@slices/trainingControlProcess';
import {userRoles} from '@src/constants';
import {Box, Chip, Grid} from '@ui/MUI';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import React, {memo, useState} from 'react';

import {getOptionLabel, isOptionEqualToValue} from '../../constant';
import {useAge, useTheme} from '../../hooks/api';

const getId = (arr) => arr.map((item) => item.id);

const ExerciseFilters = () => {
  const dispatch = useStoreDispatch();

  const theme = useTheme();
  const age = useAge();
  const isTeacher = useStore((state) => state.users.currentUser.userRole === userRoles.teacher);

  const [filters, setFilters] = useState({
    themes: [],
    ages: [],
    search: '',
    myOnly: false,
  });

  const updateFilters = (name, value) => {
    setFilters({...filters, [name]: value});
  };

  useUpdateEffect(() => {
    const params = {
      search: filters.search || null,
      themes: getId(filters.themes),
      ages: getId(filters.ages),
      myOnly: filters.myOnly || null,
    };

    dispatch(fetcherTrainingControl('exercise', params));
  }, [filters]);

  return (
    <div>
      <Grid container alignItems="center" spacing={4}>
        <Grid item xs={12}>
          <SearchInput label="Поиск по упражнениям" onChange={(value) => updateFilters('search', value)} />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteDefault
            getOptionLabel={getOptionLabel}
            isLoading={theme.isLoading}
            isOptionEqualToValue={isOptionEqualToValue}
            label="Тематика"
            options={theme.data}
            value={filters.themes}
            onChange={(_, newValue) => updateFilters('themes', newValue)}
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteDefault
            getOptionLabel={getOptionLabel}
            isLoading={theme.isLoading}
            isOptionEqualToValue={isOptionEqualToValue}
            label="Возрастная группа"
            options={age.data}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option?.name} size="small" variant="outlined" {...getTagProps({index})} key={index} />
              ))
            }
            value={filters.ages}
            onChange={(_, newValue) => updateFilters('ages', newValue)}
          />
        </Grid>
        <Grid item xs={1}>
          <ActionText
            onClick={() => {
              setFilters({themes: [], ages: [], search: ''});
            }}
          >
            сбросить
          </ActionText>
        </Grid>
      </Grid>
      {isTeacher && (
        <Box mt={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.myOnly}
                size="small"
                onChange={() => setFilters({...filters, myOnly: !filters.myOnly})}
              />
            }
            label="Показывать только мои"
          />
        </Box>
      )}
    </div>
  );
};

export default memo(ExerciseFilters);

// import ActionText from '@common/ActionText';
// import SearchInput from '@common/SearchInput';
// import {
//   getOptionLabel,
//   isOptionEqualToValue,
// } from '@components/apps/TrainingControlProcess/constant';
// import { useAge, useTheme } from '@components/apps/TrainingControlProcess/hooks/api';
// import useStore from '@hooks/useStore';
// import Search from '@mui/icons-material/Search';
// import Checkbox from '@mui/material/Checkbox';
// import { userRoles } from '@src/constants';

// import AutocompleteDefault from '@ui/MUI/Autocomplete/AutocompleteDefault';
// import Box from '@ui/MUI/Box';
// import Grid from '@ui/MUI/Grid';
// import React from 'react';
// import { useExerciseFilters } from './hooks';

// const Filters = () => {
//   const { filters, update, reset } = useExerciseFilters({
//     search: '',
//     theme: '',
//     age: '',
//     myOnly: false,
//   });

//   const userRole = useStore(state => state.users.currentUser.userRole);
//   const isTeacher = userRoles.teacher === userRole;

//   const theme = useTheme();
//   const age = useAge();

//   return (
//     <div>
//       <Grid container spacing={4}>
//         <Grid item xs={12}>
//           <SearchInput
//             label="Поиск по упражнениям"
//             onChange={value => update('search', value)}
//             InputProps={{
//               endAdornment: <Search sx={{ fontSize: '24px', color: 'rgba(0, 0, 0, 0.54)' }} />,
//             }}
//           />
//         </Grid>
//         <Grid item sm={6}>
//           <AutocompleteDefault
//             label="Тематика"
//             onChange={(_, v) => update('theme', v)}
//             options={theme.data}
//             isLoading={theme.isLoading}
//             value={filters.theme}
//             getOptionLabel={getOptionLabel}
//             multiple={false}
//             isOptionEqualToValue={isOptionEqualToValue}
//           />
//         </Grid>
//         <Grid item sm={4}>
//           <AutocompleteDefault
//             onChange={(_, v) => update('age', v)}
//             value={filters.age}
//             options={age.data}
//             multiple={false}
//             isLoading={age.isLoading}
//             getOptionLabel={getOptionLabel}
//             isOptionEqualToValue={isOptionEqualToValue}
//             label="Возрастная группа"
//           />
//         </Grid>
//         <Grid item sm={1} display="flex" alignItems="center">
//           <ActionText onClick={reset} label="Возрастная группа">
//             сбросить
//           </ActionText>
//         </Grid>
//       </Grid>
//       {isTeacher && (
//         <Box mt={3}>
//           <Checkbox
//             checked={filters.myOnly}
//             label="Показывать только мои"
//             size="small"
//             onChange={() => update('myOnly', !filters.myOnly)}
//           />
//         </Box>
//       )}
//     </div>
//   );
// };

// export default Filters;
