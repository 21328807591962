import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 16px;
`;

export const WrapperInner = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  height: ${(props) => props.loading && '70vh'};
  gap: 8px;
  box-sizing: border-box;
`;
