import {PlayoffItems} from '@components/apps/Tournaments/components/TournamentView/types';
import useDeepMemo from '@hooks/useDeepMemo';
import {longDash} from '@src/constants';
import {Box, Link, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React, {memo} from 'react';
import styled from 'styled-components';

interface Team {
  totalScore: number;
  name: string;
  id: string;
}

interface Teams {
  team1: Team;
  team2: Team;
}
function TreeItem({games = [], isLoading = false}: {games: PlayoffItems; isLoading: boolean}) {
  const teams = useDeepMemo<Teams, [PlayoffItems]>(() => {
    const teams = {
      team1: {
        id: '',
        name: '',
        totalScore: 0,
      },
      team2: {
        id: '',
        name: '',
        totalScore: 0,
      },
    };
    games?.forEach((game) => {
      let winnerId = game?.score1 > game?.score2 ? game?.team1?.id : game?.team2?.id;
      if (game?.score1 === game?.score2) {
        winnerId = '';
      }
      if (!teams.team1.name) teams.team1 = {...teams.team1, ...(game?.team1 ?? {})};
      if (!teams.team2.name) teams.team2 = {...teams.team2, ...(game?.team2 ?? {})};
      if (teams.team1.id === winnerId) {
        teams.team1.totalScore += 1;
      }
      if (teams.team2.id === winnerId) {
        teams.team2.totalScore += 1;
      }
    });

    return teams;
  }, [games]);

  const {team1, team2} = teams;

  return (
    <Wrapper>
      <Box borderBottom={`1px solid ${grey['24']}`} p={1}>
        <Stack alignItems="center" direction="row" gap={0} justifyContent="space-between">
          <Typography isLoading={isLoading} preloaderWidthBetween={[80, 100]} variant="subtitle2">
            {team1.name || longDash}
          </Typography>
          <Typography isLoading={isLoading} preloaderWidthBetween={[10, 20]} variant="subtitle2">
            {team1.totalScore}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" gap={0} justifyContent="space-between">
          <Typography isLoading={isLoading} preloaderWidthBetween={[80, 100]} variant="subtitle2">
            {team2.name || longDash}
          </Typography>
          <Typography isLoading={isLoading} preloaderWidthBetween={[10, 20]} variant="subtitle2">
            {team2.totalScore}
          </Typography>
        </Stack>
      </Box>
      <Stack
        alignItems="center"
        bgcolor={grey['4']}
        direction="row"
        flexWrap="wrap"
        gap={2}
        justifyContent="center"
        p={1}
      >
        {games && games?.length && !isLoading ? (
          games?.map((game, index) => (
            <Link
              color={grey['68']}
              key={`game${index}`}
              to={
                game?.statisticId && game?.statisticsIsReady
                  ? `/reports/stats_report/match_info/${game?.statisticId}`
                  : ''
              }
              underline={game?.statisticsIsReady ? 'always' : 'none'}
              variant="subtitle2"
            >
              {`${game?.score1 ?? 0}:${game?.score2 ?? 0}`}
              {(game?.isBull || game?.isOT) && (
                <Typography
                  color="#fff"
                  sx={{px: '2px', ml: 0, borderRadius: '2px', bgcolor: grey['54']}}
                  variant="overline2"
                >
                  {game?.isOT ? 'от' : 'бул'}
                </Typography>
              )}
            </Link>
          ))
        ) : (
          <Typography color={grey['54']} isLoading={isLoading} preloaderWidthBetween={[100, 150]} variant="subtitle2">
            Игры еще не запланированы
          </Typography>
        )}
      </Stack>
    </Wrapper>
  );
}

export default memo(TreeItem);

const Wrapper = styled(Box)`
  position: relative;
  border: 1px solid ${grey['24']};
  border-radius: 6px;
  z-index: 1;
  background-color: #fff;
  overflow: hidden;
`;
