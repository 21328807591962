import FullHeightWrapper from '@common/FullHeightWrapper';
import ReactTableComponent from '@common/ReactTableComponent';
import {declineWords} from '@helpers/CommonHelper';
import date from '@helpers/date';
import useQueryString from '@hooks/useQueryString';
import {useRoleAccess} from '@hooks/useRoleAccess';
import {useMediaQuery} from '@mui/material';
import {userRoles} from '@src/constants';
import {Box, Button, Chip, Typography} from '@ui/MUI';
import {darkRed, green, grey, yellow} from '@ui/MUI/colors';
import React, {useMemo} from 'react';
import {useHistory} from 'react-router';

import {overallMock} from '../../mock';

function objToQs(obj = {}) {
  const entries = Object.entries(obj);
  if (entries?.length) {
    return `?${entries.map((entry) => entry.join('=')).join('&')}`;
  }
  return '';
}

function Table({data = [], isLoad, isLoading}) {
  const history = useHistory();
  const [qs, _] = useQueryString();
  const canWatchDiary = useRoleAccess([userRoles.psycho]);
  const isUpLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const columns = useMemo(
    () => [
      {
        header: 'Дата',
        accessorFn: (row) => {
          return date(row?.date).format('DD.MM.YYYY');
        },
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="subtitle2">
              {getValue()}
            </Typography>
          );
        },
      },
      {
        header: 'Заполнили',
        accessorFn: (row) => {
          return row?.completed;
        },
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="subtitle2">
              {getValue()} {declineWords({value: getValue(), words: ['игрок', 'игрока', 'игроков']})}
            </Typography>
          );
        },
      },
      {
        header: 'Не заполнили',
        accessorFn: (row) => {
          return row?.notCompleted;
        },
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="subtitle2">
              {getValue()} {declineWords({value: getValue(), words: ['игрок', 'игрока', 'игроков']})}
            </Typography>
          );
        },
      },
      {
        header: '% заполняемости',
        accessorFn: (row) => {
          return row?.percentCompleted;
        },
        cell: ({getValue}) => {
          const getBgColor = (value) => {
            if (value <= 40) {
              return darkRed[65];
            }
            if (value > 40 && value < 80) {
              return yellow[60];
            }
            return green[70];
          };
          return (
            <Box>
              <Chip
                icon={
                  getValue() && (
                    <Box
                      component="span"
                      sx={{width: 8, height: 8, borderRadius: '50%', backgroundColor: getBgColor(getValue())}}
                    />
                  )
                }
                isLoading={isLoading}
                label={`${getValue()}% заполнено`}
                preloaderWidthBetween={[50, 70]}
                variant="outlined"
              />
            </Box>
          );
        },
      },
      {
        accessorKey: 'date',
        header: '',
        id: 'actionCell',
        cell: ({getValue}) => {
          const value = getValue();
          return (
            <Button
              color="primary"
              disabled={!value || !canWatchDiary}
              sx={{px: 0}}
              variant="text"
              onClick={() => {
                history.push(`/development_journal/details${objToQs({...qs, reportDate: value})}`);
              }}
            >
              Смотреть
            </Button>
          );
        },
        cellStyle: {
          width: 124,
        },
      },
    ],
    [isLoading],
  );

  if (isLoad && !data?.length) {
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
        <Typography variant="subtitle1">Нет данных</Typography>
      </FullHeightWrapper>
    );
  }
  return (
    <Box
      sx={{
        marginTop: '0px !important',
        '& > .TableWrapper': {borderRadius: '0 0 6px 6px', borderTop: `1px solid ${grey[12]}`},
      }}
    >
      <ReactTableComponent columns={columns} data={isLoading ? overallMock : data || []} isAvailableHeight={!isUpLg} />
    </Box>
  );
}

export default Table;
