import {getActionsMapPlayers} from '@api/StatsAPI';
import useDeepEffect from '@hooks/useDeepEffect';
import {useState} from 'react';

const initialState = {isLoading: false, data: [], error: ''};

export function usePlayersFilterData({statisticsIds}) {
  const [players, setPlayers] = useState({...initialState});
  useDeepEffect(() => {
    if (statisticsIds?.length) {
      setPlayers((prevState) => ({...prevState, isLoading: true}));
      getActionsMapPlayers({statisticsIds})
        .then(({data}) => {
          setPlayers({
            ...initialState,
            data:
              data
                ?.map((item) => ({
                  ...item,
                  label: `${item?.firstName} ${item?.lastName}`,
                  value: item?.avangardId,
                }))
                .sort((a, b) => a?.hockeyRole?.localeCompare(b?.hockeyRole)) || [],
          });
        })
        .catch(() => setPlayers({...initialState, error: 'Ошибка загрузки списка игроков'}));
    }
  }, [statisticsIds]);

  return {...players};
}
