import {getSeasons} from '@api/SeasonsAPI';
import {useEffect, useState} from 'react';

interface FormattedSeasons {
  id: string;
  name: string;
  label: string;
  value: string;
  startDate?: string;
  endDate?: string;
}

type OnSuccessDataParam = {seasons: FormattedSeasons[]; currentSeason: FormattedSeasons};
export function useSeasons(
  needShortSeasons: boolean = false,
  onSuccess: (data: OnSuccessDataParam) => void = () => {},
  loadingInitialState: boolean = false,
): {isLoading: boolean; seasons: FormattedSeasons[]; options: FormattedSeasons[]; currentSeason: FormattedSeasons} {
  const [seasonsState, setSeasonsState] = useState<{isLoading: boolean; seasons: any[]}>({
    isLoading: loadingInitialState,
    seasons: [],
  });
  useEffect(() => {
    !loadingInitialState && setSeasonsState((prev) => ({...prev, isLoading: true}));
    getSeasons()
      .then((seasons) => {
        const formattedSeasons: FormattedSeasons[] = seasons?.data?.map((season: {name: string; id: string}) => ({
          ...season,
          name: needShortSeasons ? season?.name : `Сезон ${season.name}`,
          label: needShortSeasons ? season?.name : `Сезон ${season.name}`,
          value: season?.id,
        }));
        setSeasonsState({isLoading: false, seasons: formattedSeasons});
        onSuccess({seasons: formattedSeasons, currentSeason: formattedSeasons?.[0]});
      })
      .catch(() => setSeasonsState((prev) => ({...prev, isLoading: false})));
  }, [needShortSeasons]);
  return {
    isLoading: seasonsState.isLoading,
    seasons: seasonsState.seasons,
    options: seasonsState.seasons,
    currentSeason: seasonsState.seasons?.[0],
  };
}
