import ButtonDefault from '@ui/MUI/Button';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {FACELESS_AVATAR, TEACHERS_ROLES_OPTIONS} from '../../../../../constants/config';
import LocalPreloader from '../../../../preloader/LocalPreloader';
import EditModal from '../modals/EditModal';
import {TrainerAvatar} from '../style';

export default function AboutGroup(props) {
  const {loading, group} = props;

  const userRole = useSelector((state) => state.users.currentUser?.userRole);

  const [editModalState, setEditModalState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleEditModal = (anchor, open) => {
    setEditModalState({...editModalState, [anchor]: open});
  };

  const sortedTeachers = group?.teachers
    ?.filter((teacher) => teacher.teacherRole === 'main')
    .concat(group?.teachers?.filter((teacher) => teacher.teacherRole !== 'main'));

  return (
    <Wrapper loading={loading.toString()}>
      <LocalPreloader visible={loading} />
      {group && (
        <Container>
          <Headline>
            <HeadlineBlock>
              <Title>{group?.name}</Title>
              <InstatId>{group?.instatId ? `Instat ID ${group?.instatId}` : 'Instat ID -'}</InstatId>
            </HeadlineBlock>
            {userRole !== 'teacher' && (
              <HeadlineBlock>
                <ButtonDefault
                  color="primary"
                  text="Редактировать"
                  variant="contained"
                  onClick={() => {
                    toggleEditModal('right', true);
                  }}
                />
              </HeadlineBlock>
            )}
          </Headline>
          <Block>
            <Title>Информация</Title>
            <Row>
              <Column>
                <ColumnTitle>Школа</ColumnTitle>
                <ColumnText>{group?.school?.name || '-'}</ColumnText>
              </Column>
              <Column>
                <ColumnTitle>Кол-во игроков</ColumnTitle>
                <ColumnText>{group?.studentsCount || 0}</ColumnText>
              </Column>
              <Column>
                <ColumnTitle>Местные / Иногородние</ColumnTitle>
                <ColumnText>
                  {group?.resident || 0}%/{group?.nonResident || 0}%
                </ColumnText>
              </Column>
              <Column>
                <ColumnTitle>Год Рождения (группа / средний)</ColumnTitle>
                <ColumnText>
                  {group?.birthYear || '-'} ({group?.ageGroup || '-'} / {group?.averageAge || '-'})
                </ColumnText>
              </Column>
            </Row>
          </Block>
          <Trainers>
            <Title>Тренеры</Title>
            <Row>
              {group?.teachers?.length > 0 ? (
                sortedTeachers?.map((teacher) => {
                  return (
                    <Column key={teacher.id}>
                      <Trainer>
                        <TrainerAvatar src={teacher.avatar ? teacher.avatar : FACELESS_AVATAR} />
                        <TrainerName>
                          <span>{teacher.lastName}</span>
                          <span> {teacher.firstName}</span>
                        </TrainerName>
                        <TrainerRole>
                          {TEACHERS_ROLES_OPTIONS.find((option) => option?.value === teacher.teacherRole)?.label}
                        </TrainerRole>
                      </Trainer>
                    </Column>
                  );
                })
              ) : (
                <EmptyBlock> К команде пока не привязан ни один тренер. </EmptyBlock>
              )}
            </Row>
          </Trainers>
        </Container>
      )}
      <EditModal
        group={group}
        modalState={editModalState}
        setModalState={setEditModalState}
        toggleEditModal={toggleEditModal}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: ${(props) => (props.loading === 'true' ? '80vh' : 'auto')};
  overflow: ${(props) => (props.loading === 'true' ? 'hidden' : 'visible')};
  padding: 24px;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;
const Container = styled.div``;
const Headline = styled.div`
  padding-top: 0;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
`;
const HeadlineBlock = styled.div``;
const Title = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font: 600 normal 18px/24px 'Proxima Nova';
  color: black;
  text-transform: uppercase;
`;
const InstatId = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  font: 400 normal 12px/16px 'Proxima Nova';
  color: #747474;
  text-transform: uppercase;
`;
const Block = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;

  ${Title} {
    margin-top: 0;
    margin-bottom: 16px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Column = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  @media screen and (max-width: 768px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding-bottom: 16px;
  }
  @media screen and (max-width: 575px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 376px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  &:last-child {
    @media screen and (max-width: 376px) {
      padding-bottom: 0;
    }
  }
`;
const Trainers = styled(Block)`
  ${Title} {
    margin-bottom: 12px;
  }
  ${Column} {
    padding-top: 0;
    padding-bottom: 16px;
  }
`;
const ColumnTitle = styled.p`
  margin-top: 0;
  margin-bottom: 8px;
  font: 400 normal 12px/16px 'Proxima Nova';
  color: #747474;
  text-transform: uppercase;
`;
const ColumnText = styled.p`
  margin-top: 8px;
  margin-bottom: 0;
  font: 600 normal 14px/18px 'Proxima Nova';
  color: #2c2c2c;
`;
const Trainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 56px;
  min-height: 40px;
`;
const TrainerName = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: 600 normal 14px/20px 'Proxima Nova';
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.15px;
  & > span {
    display: block;
  }
`;
const TrainerRole = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  flex: 0 0 100%;
  max-width: 100%;
  font: 400 normal 14px/18px 'Proxima Nova';
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.18px;
`;
const EmptyBlock = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 16px/20px 'Proxima Nova';
  color: #2c2c2c;
`;
