import {
  ContentWrapper,
  TitleWrapper,
  Wrapper,
} from '@components/apps/StudentDashboard/components/DashboardBlock/DashboardBlock';
import {Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

DashboardBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleWrapperProps: PropTypes.object,
};

function DashboardBlock({title, titleWrapperProps = {}, contentWrapperProps, children, ...props}) {
  return (
    <Wrapper {...props}>
      <TitleWrapper px={{xxs: 3, sm: 4}} py={{xxs: 1, sm: 2}} {...titleWrapperProps}>
        {typeof title === 'string' ? <Typography variant="h2">{title}</Typography> : title}
      </TitleWrapper>
      <ContentWrapper p={{xxs: 3, sm: 4}} {...contentWrapperProps}>
        {children}
      </ContentWrapper>
    </Wrapper>
  );
}

export default DashboardBlock;
