import React from 'react';

function PencilGray() {
  return (
    <React.Fragment>
      <path
        d="M5 16.1486V19.1111H7.96255L16.7001 10.3736L13.7376 7.41103L5 16.1486ZM18.9911 8.08255C19.2992 7.77444 19.2992 7.27673 18.9911 6.96863L17.1425 5.12C16.8344 4.81189 16.3367 4.81189 16.0286 5.12L14.5829 6.56572L17.5454 9.52827L18.9911 8.08255Z"
        fill="black"
        fillOpacity="0.78"
      />
    </React.Fragment>
  );
}
export default PencilGray;
