import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 6px;
`;

export const CardTitle = styled.div`
  padding: ${({theme}) => theme.spacing(3, 3, 1, 3)};
  background: #fffaed;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
`;

export const CardContent = styled.div`
  position: relative;
  background-color: #fff;
  padding-bottom: ${({theme}) => theme.spacing(3)};
  height: ${(props) => props.height};
`;
