import styled from 'styled-components';

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 12px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    padding: 15px;
  }
`;
