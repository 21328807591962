import * as types from '../ActionTypes.js';

const initialState = {
  loading: false,
  error: undefined,
};

const PhysicalTestingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.PHYSICAL_TESTING_LOAD:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case types.PHYSICAL_TESTING_ERROR:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case types.LOAD_PHYSICAL_TESTING_FORM:
      return {
        loading: false,
        error: undefined,
        testingForm: action.testingForm,
      };
    case types.LOAD_TEAM_PHYSICAL_TESTING_REPORT:
      return {
        loading: false,
        error: undefined,
        teamTestingReport: action.teamTestingReport,
      };
    case types.LOAD_PERSONAL_PHYSICAL_TESTING_REPORT:
      return {
        loading: false,
        error: undefined,
        personalTestingReport: action.personalTestingReport,
      };
    case types.POST_PHYSICAL_TESTING_FORM:
      return {
        loading: false,
        error: undefined,
        testingForm: action.testingForm,
      };
    case types.LOAD_RATING_PHYSICAL_TESTING_REPORT:
      return {
        loading: false,
        error: undefined,
        ratingTestingReport: action.ratingTestingReport,
      };
    case types.LOAD_PLAYER_PHYSICAL_TESTING_RECOMMENDATION:
    case types.CHANGE_PLAYER_PHYSICAL_TESTING_RECOMMENDATIONS:
      return {
        ...state,
        loading: false,
        error: undefined,
        recommendation: action.recommendation,
      };
    default:
      return state;
  }
};

export default PhysicalTestingReducer;
