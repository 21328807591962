import moduleNames from '../moduleNames';

export const selectTournaments = (state) => state[moduleNames.tournamentsList].tournamentsList;
export const selectTournament = (state) => {
  return state[moduleNames.tournamentsList].tournamentsItem;
};
export const selectTournamentById = (id) => (state) => {
  const tournaments = selectTournaments(state);
  return tournaments?.data?.find((tournament) => tournament?.id === id);
};

export const selectCurrentTournament = (state) => state[moduleNames.tournamentsList].currentTournament;

export const selectGames = (state) => state[moduleNames.tournamentsList].games;
