import {getBodyMassIndexReportForPlayer} from '@actions/BMIActions';
import BMIItem from '@components/apps/PlayerPersonalFile/components/MainInfo/components/BMIItem';
import {BMI_ITEMS} from '@components/apps/PlayerPersonalFile/constants';
import date from '@helpers/date';
import useFatAccess from '@hooks/useFatAccess';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Stack} from '@ui/MUI';
import {blue, green, yellow} from '@ui/MUI/colors';
import React, {memo, useEffect, useMemo} from 'react';
import {useParams} from 'react-router';

const startDate = date().currentDate.subtract(1, 'year').valueOf();
const endDate = date().currentDate.valueOf();
function BMIItems({student, ...props}) {
  const dispatch = useStoreDispatch();
  const {id} = useParams();
  const {hasAccess} = useFatAccess(student?.group?.id);
  const {heightValues, weightValues, caliperometryValues, bmiValues, paramsDates} = useStore(
    (state) =>
      state.bodyMassIndex.items?.splitData || {
        heightValues: [],
        weightValues: [],
        caliperometryValues: [],
        bmiValues: [],
        paramsDates: [],
      },
  );
  const items = useMemo(() => {
    const result = [
      [
        {seriesData: heightValues, unit: 'см', title: 'Рост', id: BMI_ITEMS.height},
        {
          seriesData: weightValues,
          unit: 'кг',
          title: 'Вес',
          color: {primary: green['100'], secondary: green['15']},
          id: BMI_ITEMS.weight,
        },
      ],
    ];
    if (hasAccess) {
      result.push([
        {
          seriesData: caliperometryValues,
          unit: '%',
          title: '% Жира',
          color: {primary: blue['100'], secondary: blue['15']},
          minValue: 0,
          id: BMI_ITEMS.fat,
        },
        {
          seriesData: bmiValues,
          unit: '%',
          title: 'ИМТ',
          color: {primary: yellow['100'], secondary: yellow['20']},
          id: BMI_ITEMS.bmi,
        },
      ]);
    }
    return result;
  }, [heightValues, hasAccess]);
  const isLoading = useStore((state) => state.bodyMassIndex.loading);
  const isLoad = useStore((state) => state.bodyMassIndex.load);
  useEffect(() => {
    id && dispatch(getBodyMassIndexReportForPlayer({playerId: id, startDate, endDate}));
  }, [id]);

  return (
    <>
      {items.map((chunk, index) => (
        <Stack direction="row" flex={2} flexWrap="wrap" gap={3} key={index} minWidth={180}>
          {chunk.map((item, index) => (
            <BMIItem dates={paramsDates} isLoading={isLoading || props.isLoading || !isLoad} key={index} {...item} />
          ))}
        </Stack>
      ))}
    </>
  );
}

export default memo(BMIItems);
