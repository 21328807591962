import get from 'lodash/get';
import React, {useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import useEvent from '../../hooks/useEvent';
import {fields} from './constants';

const Field = ({
  name,
  component,
  onChange,
  connector,
  isRequired,
  className,
  placeholder,
  withOutMinLength,
  watcher,
}) => {
  const {control, setValue} = useFormContext();

  const field = useMemo(() => {
    const tempObj = {name, component};

    const field = fields[component];

    return {...tempObj, ...field};
  }, [component]);

  const handelChange = useEvent((v) => {
    let value = v;
    if (field.getter) {
      value = get(v, field.getter);
    }

    if (field.transform) {
      value = field.transform(value);
    }

    setValue(name, value);
    onChange && onChange(value, name);
  });

  return (
    <Controller
      control={control}
      name={name}
      render={({field: formField, fieldState}) => {
        return field.component ? (
          field.component({
            ...formField,
            onChange: handelChange,
            connector,
            errorText: fieldState?.error?.message,
            isInvalid: fieldState?.error?.message,
            placeholder: placeholder || field?.placeholder,
            type: field.isPassword && 'password',
            className,
            watcher,
            value: formField.value || '',
          })
        ) : (
          <div>Компонента нет:(</div>
        );
      }}
      rules={{
        required: {
          value: isRequired,
          message: 'Обязательное поле',
        },
        minLength: !withOutMinLength &&
          field.minLength && {
            value: field.minLength.value,
            message: field.minLength.message,
          },
        pattern: field?.pattern,
      }}
    />
  );
};

export default Field;
