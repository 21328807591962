import {longDash} from '@src/constants';
import {Typography} from '@ui/MUI';
import React from 'react';

export const columns = [
  {
    field: 'name',
    headerName: 'Тренировка',
    flex: 0.35,
    minWidth: 450,
    renderCell: (params) => <Typography variant="body2">{params?.value || longDash}</Typography>,
  },
  {
    field: 'formatedDate',
    headerName: 'Дата',
    flex: 0.1,
    minWidth: 110,
    renderCell: (params) => <Typography variant="body2">{params?.value || longDash}</Typography>,
  },
  {
    field: 'fullTime',
    headerName: 'Время',
    flex: 0.15,
    minWidth: 174,
    renderCell: (params) => <Typography variant="body2">{params?.value || longDash}</Typography>,
  },
  {
    field: 'author',
    headerName: 'Тренер',
    flex: 0.2,
    minWidth: 215,

    renderCell: (params) => <Typography variant="body2">{params?.value?.name || longDash}</Typography>,
  },
];
