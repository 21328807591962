import {createEditPhysicalReport, getPhysicalTestingOverall, getTestsForEditCreate} from '@api/newPhysicalTestingAPI';
import {fetchOverallMapper} from '@components/apps/PhysicalTesting/helpers/mappers/fetchOverallMapper';
import {createSlice} from '@reduxjs/toolkit';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';

const initialState = {
  teamInfo: {
    items: [],
    testsMap: Map,
    tests: [],
    ageGroup: '',
    isLoading: false,
    isLoad: false,
    error: false,
  },
  filters: {
    school: null,
    group: null,
    typeOfTests: null,
    season: null,
    stage: null,
  },
};

const physicalTestingEditCreate = createSlice({
  name: 'physicalTestingEditCreate',
  initialState,
  reducers: {
    physicalTestingEditCreateLoading: ({teamInfo}) => {
      teamInfo.isLoading = true;
    },
    physicalTestingEditCreateLoad: ({teamInfo}, action) => {
      teamInfo.isLoading = false;
      teamInfo.isLoad = true;
      teamInfo.items = action.payload.tableData;
      teamInfo.tests = action.payload.tests;
      teamInfo.error = false;
    },
    physicalTestingEditCreateError: ({teamInfo}, action) => {
      teamInfo.isLoading = false;
      teamInfo.isLoad = true;
      teamInfo.error = action.payload;
    },
    physicalTestingEditCreateClear: (state) => {
      state.teamInfo = {};
    },
    physicalTestingEditCreateSetFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

const {actions, reducer} = physicalTestingEditCreate;

const {
  physicalTestingEditCreateLoading,
  physicalTestingEditCreateLoad,
  physicalTestingEditCreateError,
  physicalTestingEditCreateClear,
  physicalTestingEditCreateSetFilters,
} = actions;

export {reducer as physicalTestingEditCreateReducer};

export const setFiltersValue = (data) => (dispatch) => {
  dispatch(physicalTestingEditCreateSetFilters(data));
};

export const startLoading = (dispatch) => {
  dispatch(physicalTestingEditCreateLoading());
};

export const clearState = () => async (dispatch) => {
  dispatch(physicalTestingEditCreateClear());
};

export const fetchOverallData = (data) => async (dispatch) => {
  dispatch(physicalTestingEditCreateLoading());
  dispatch(physicalTestingEditCreateClear());
  startLoading(dispatch);

  const [{data: physicalTestingData}, {data: allTests}] = await Promise.all([
    getPhysicalTestingOverall(data),
    getTestsForEditCreate({type: data?.typeOfTests, teamId: data?.groupId}),
  ]);

  const tableData = physicalTestingData;

  if (tableData?.length === 0) {
    dispatch(
      physicalTestingEditCreateError('Произошла ошибка при загрузке команды, попробуйте выбрать другую команду'),
    );
    return;
  }
  const comparedDataAdaptedForTable = fetchOverallMapper(tableData);
  dispatch(
    physicalTestingEditCreateLoad({
      tableData: comparedDataAdaptedForTable,
      tests: allTests,
    }),
  );
};

export const editCreateOverallData = (form) => async (dispatch) => {
  dispatch(physicalTestingEditCreateLoading());
  try {
    await createEditPhysicalReport(form.data);
    ReactComponentNotification(NOTIFICATION_TYPES.success, 'Отчет успешно сохранён');
    dispatch(
      fetchOverallData({
        groupId: form.groupId,
        season: form.season,
        stage: form.stage,
        typeOfTests: form?.typeOfTests || form?.type,
      }),
    );
  } catch (e) {
    ReactComponentNotification(
      NOTIFICATION_TYPES.error,
      e?.response?.data?.message || 'Произошла ошибка при сохранении',
    );
  }
};
