import LocalPreloader from '@components/preloader/LocalPreloader';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '300px'};
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const LoadingHolder = ({width, height, bgcolor}) => {
  return (
    <Container height={height} width={width}>
      <LocalPreloader visible bgcolor={bgcolor} />
    </Container>
  );
};

export default LoadingHolder;
