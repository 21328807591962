import React from 'react';
import styled from 'styled-components';

import {DARK_SIDE} from './Colors';
/**@deprecated не использовать этот компонент**/
export default function TextareaDefault(props) {
  return (
    <>
      <Textarea
        {...props}
        disabled={props.disabled || false}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
      {props.isInvalid && <ErrorText>{props.errorText}</ErrorText>}
    </>
  );
}

const DEFAULT_INPUT_HEIGHT = 28;
const DEFAULT_INPUT_FONT_SIZE = 14;

const Textarea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  min-height: 6em;
  padding-left: 8px;
  line-height: ${DEFAULT_INPUT_HEIGHT}px;
  border: ${(props) => (props.isInvalid ? '1px solid #FF0000' : '1px solid #ccc')};
  border-radius: 4px;
  outline: none;
  background-color: ${(props) => (props.disabled ? '#F3F3F3' : 'white')};
  font-size: ${DEFAULT_INPUT_FONT_SIZE}px;
  color: ${(props) => (props.disabled ? '#747474' : '#2C2C2C')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:focus {
    border-color: ${DARK_SIDE};
  }
  font-family: 'Proxima Nova';
`;
const ErrorText = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  color: #ff0000;
`;
