import date from '@helpers/date';
import {longDash} from '@src/constants';
import moment from 'moment';

export const generateDates = (max, date, unix, dateType = 'day') => {
  if (!date?.isValid?.()) {
    return [];
  }
  return Array(max)
    .fill(date)
    .map((date, index) => {
      const formatedDate = date.clone().add(index, dateType);
      if (unix) {
        return formatedDate.valueOf();
      }
      return formatedDate;
    });
};

// Получение дней недели: =>  понедельник 21 мая, вторник 22 мая...
export const getWeekDays = (maxDays = 7, asUnix, date) => {
  const startOfWeek = date ? moment(date) : moment().startOf('week');
  return generateDates(maxDays, startOfWeek, asUnix);
};

// получение дней месяца => понедельник 21 мая, вторник 22 мая...
export const getMonthDays = (maxDays, asUnix, date, isMonthFirstDate = false) => {
  const startDate = moment(date).startOf('month');
  if (!isMonthFirstDate) {
    startDate.startOf('week');
  }

  return generateDates(maxDays || 42, startDate, asUnix);
};

// получение часов дня => 01:01 понедельника
export const getDayHours = (asUnix) => {
  const startDay = moment().startOf('day');
  return generateDates(1, startDay, asUnix);
};

export const getPeriod = (mode, date, stub = longDash) => {
  if (!dateIsValid(date) || !moment(date).isValid) return stub;
  const formatString = 'DD.MM.YYYY';
  const currentDate = moment(date);
  if (mode === 'week' || mode === 'month') {
    const start = currentDate.startOf(mode).format(formatString);
    const end = currentDate.endOf(mode).format(formatString);
    return `${start} - ${end}`;
  }
  if (mode === 'day') {
    return currentDate.format(formatString);
  }
  return stub;
};

export function convertTimeMMSS(d, format = '', stub = '00:00') {
  let timeInMilliseconds = d;
  if ((typeof d === 'string' && /^[0-9.]+$/.test(d)) || typeof d === 'number') {
    timeInMilliseconds = Math.round(Number(d));
  } else if (date(d, format).isValid()) {
    timeInMilliseconds = date(d, format).valueOf() - date(d, format).startOf('day').valueOf();
  } else {
    return stub;
  }

  const duration = date.duration(timeInMilliseconds);
  const minutes = duration.hours() * 60 + duration.minutes();
  const seconds = duration.seconds();

  return `${(minutes < 10 ? '0' : '') + minutes}:${(seconds < 10 ? '0' : '') + seconds}`;
}

export function dateIsValid(date) {
  return date instanceof Date && !isNaN(date);
}
