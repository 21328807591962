import * as dashboardsActions from '@actions/DashboardsActions';
import {cleanLinesForm, getLinesForm} from '@actions/LinesActions';
import Diary from '@components/apps/StudentDashboard/components/DayDiary';
import DailyDiary from '@components/apps/StudentDashboard/components/MorningDiary';
import {DIARY_REQUEST_DATE} from '@components/apps/StudentDashboard/constants';
import ProfileBlock from '@components/students/blocks/ProfileBlock/ProfileBlock';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {loadPlayerDiary} from '@slices/playerDiary';
import {STUDENT_SUBROLES} from '@src/constants';
import {Box, Stack} from '@ui/MUI';
import React, {useEffect} from 'react';
import styled from 'styled-components';

import NextGame from '../../../../widgets/NextGame';
import PastGamesOfSeason from '../../../../widgets/PastGamesOfSeason';
import TodaySchedule from '../../../../widgets/TodaySchedule/TodaySchedule';

export default function Content({id, student, group, isMobile, currentUserId}) {
  const dispatch = useStoreDispatch();
  const isAmateur = student?.subRole === STUDENT_SUBROLES.amateur;

  useEffect(() => {
    dispatch(dashboardsActions.getDashboard());
    dispatch(loadPlayerDiary({date: DIARY_REQUEST_DATE}));
  }, []);

  useEffect(() => {
    if (student?.group?.id) {
      dispatch(getLinesForm({team: student?.group?.id})).catch(() => {
        dispatch(cleanLinesForm());
      });
    }
  }, [student?.group?.id]);

  const {dashboard, linesForm} = useStore((state) => {
    return {
      groupRate: state.groups.groupRate || {},
      dashboard: state.dashboard.dashboard,
      linesForm: state.lines.linesForm || undefined,
    };
  });
  const loadingLines = useStore((state) => state.lines.loading);

  const pastGames = dashboard?.pastGames;
  const todaySchedule = dashboard?.schedule;
  const nextGame = dashboard?.nextGame;

  return (
    <Wrapper>
      {isAmateur ? (
        <Stack alignItems="stretch" direction={{xxs: 'column', md: 'row'}} spacing={1}>
          <Half>
            <ProfileBlock isAmateur student={student} />
          </Half>
          <Half>
            <PastGamesOfSeason isStudent isWithoutButtons groupId={student?.group?.id} pastGames={pastGames} />
          </Half>
        </Stack>
      ) : (
        <Stack direction={{xxs: 'column', lg: 'row'}} spacing={1}>
          <>
            <Half>
              {!isMobile && (
                <Box order={1}>
                  <ProfileBlock student={student} />
                </Box>
              )}
              <Box order={{xxs: 1, sm: 2}}>
                <DailyDiary />
              </Box>
              <Box order={{xxs: 1, sm: 3}}>
                <Diary />
              </Box>
              <Box order={{xxs: 5, sm: 4}}>
                <TodaySchedule group={group} todaySchedule={todaySchedule} />
              </Box>
            </Half>

            <Half>
              <Box order={{xxs: 3, sm: 6}}>
                <NextGame
                  currentUserId={currentUserId}
                  group={group}
                  id={id}
                  isLoading={loadingLines}
                  linesForm={linesForm}
                  nextGame={nextGame}
                  student={student}
                />
              </Box>
              <Box order={{xxs: 4, sm: 9}}>
                <PastGamesOfSeason isStudent isWithoutButtons groupId={student?.group?.id} pastGames={pastGames} />
              </Box>
            </Half>
          </>
        </Stack>
      )}
    </Wrapper>
  );
}

const Half = styled((props) => <Stack gap={1} {...props} />)`
  width: 100%;
  max-width: 50%;
  box-sizing: border-box;
  ${({theme}) => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      maxWidth: '100%',
    },
  })}
`;

const Wrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.loading ? '80vh' : 'auto')};
  overflow: ${(props) => (props.loading ? 'hidden' : 'visible')};
`;
