import {useRandomNumberBetween} from '@hooks/useRandomNumberBetween';
import {Theme, useMediaQuery} from '@mui/material';
import MuiChip from '@mui/material/Chip';
import {ChipProps} from '@mui/material/Chip/Chip';
import {SkeletonProps} from '@mui/material/Skeleton/Skeleton';
import {Skeleton} from '@ui/MUI';
import React from 'react';

export type CustomChipProps = {
  loaderProps?: SkeletonProps;
  isLoading?: boolean;
  preloaderWidthBetween?: [number, number];
} & ChipProps;

function Loader({
  isLoading,
  children,
  loaderProps = {},
  preloaderWidthBetween,
}: {children: React.FunctionComponent | string} & CustomChipProps) {
  const memoRandomWidth = useRandomNumberBetween(preloaderWidthBetween);
  return isLoading ? (
    <Skeleton width={preloaderWidthBetween?.length ? memoRandomWidth : void 0} {...loaderProps}>
      {children}
    </Skeleton>
  ) : (
    children
  );
}

function Chip({size, label, variant, preloaderWidthBetween, loaderProps, isLoading, ...props}: CustomChipProps) {
  const isSmResolution = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return (
    <Loader isLoading={isLoading} loaderProps={loaderProps} preloaderWidthBetween={preloaderWidthBetween}>
      <MuiChip label={label} size={size || isSmResolution ? 'small' : 'medium'} variant={variant} {...props} />
    </Loader>
  );
}

export default Chip;
