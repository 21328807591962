export const mock = [...Array(20)].fill({
  link: 'https://storage.yandexcloud.net/hawk.academy/game_999850%20%281%29.csv',
  fileName: 'game_999850 (1).csv',
  date: '4/23/2023',
  team: 'Югра-ЮКИОР',
  opponent: 'Авангард-2006',
  score: '7:4',
  author: {
    id: 'kMjmFArpK64d',
    name: 'Кулик Е.',
  },
  id: 'WWVv76higmkP',
  updatedAt: '4/23/2023',
});
