import * as types from '../ActionTypes.js';

const initialState = {
  loading: false,
  error: null,
};

const TVScheduleReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.TV_SCHEDULE_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case types.TV_SCHEDULE_LOADING_END:
      return {
        ...state,
        loading: false,
        schedule: !Array.isArray(action.tvSchedule) ? {...action.tvSchedule} : action.tvSchedule,
      };
    case types.TV_SCHEDULE_LOADING_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default TVScheduleReducer;
