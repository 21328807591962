import {addUser, cleanUsers, getUsers} from '@actions/ScoutReportActions';
import ContentWrapper from '@common/ContentWrapper';
import FullHeightWrapper from '@common/FullHeightWrapper';
import {StyledButton} from '@common/ScoutReport/CommonScoutReport';
import {PERMISSIONS} from '@common/ScoutReport/constants';
import {Typography} from '@mui/material';
import {AMPLUA_OPTIONS, SCOUT_REPORT_TYPES} from '@src/constants';
import {Stack} from '@ui/MUI';
import SelectDefault from '@ui/MUI/Select';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Table from '../../../../groups/components/group/table';
import LocalPreloader from '../../../../preloader/LocalPreloader';
import ChessTemplate from '../../../../templates/ChessTemplate';
import OuterPlayersTable from '../OuterPlayersTable';
import UserCreateModal from '../UserCreateModal';
import {Filter} from './OuterPlayers';

export default function OuterPlayers() {
  const dispatch = useDispatch();
  const {teamid} = useParams();
  const [isModalOpen, setIsModalOpen] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [hockeyRole, setHockeyRole] = useState('');
  const {users} = useSelector(({scoutReport}) => ({users: scoutReport.users}));
  useEffect(() => {
    if (teamid) dispatch(getUsers(teamid, SCOUT_REPORT_TYPES.outer, {hockeyRole}));
    return () => dispatch(cleanUsers());
  }, [teamid, hockeyRole]);

  return (
    <ChessTemplate active="trainer_report">
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Отчет тренера', path: '/trainer_report/outer/teams'},
          {
            label: users?.school?.name || 'Клуб',
            path: users?.school?.id ? `/trainer_report/${users.school.id}/years` : '',
          },
          {label: users?.group?.name || 'Год', path: ''},
        ]}
        text="Отчет тренера"
      />
      <ContentWrapper mb="8px">
        <Filter>
          <SelectDefault
            hasFixedLabel={false}
            label="Амплуа"
            options={[{value: '', label: 'Все'}, ...AMPLUA_OPTIONS]}
            value={hockeyRole}
            onChange={(e) => setHockeyRole(e.target.value)}
          />

          {users.permissions?.[PERMISSIONS.canCreate] && (
            <StyledButton
              color="primary"
              margin={0}
              size="medium"
              variant="contained"
              onClick={() => setIsModalOpen({...isModalOpen, right: !isModalOpen.right})}
            >
              <span className="plus"></span>Добавить игрока
            </StyledButton>
          )}
        </Filter>
      </ContentWrapper>
      <ContentWrapper isLoading={users.loading} marginBottom="8px" padding={'0 24px'}>
        {users.loading ? (
          <FullHeightWrapper sx={{position: 'relative'}}>
            <LocalPreloader visible={true} />
          </FullHeightWrapper>
        ) : !users?.data?.length ? (
          <FullHeightWrapper>
            <Stack alignItems="center" direction="column" height="inherit" justifyContent="center">
              <Typography variant="subtitle1">Нет данных по игрокам</Typography>
            </Stack>
          </FullHeightWrapper>
        ) : (
          <Table>
            <OuterPlayersTable users={users?.data} />
          </Table>
        )}
      </ContentWrapper>
      <UserCreateModal
        info={{school: users?.school || {}, group: users?.group || {}}}
        isOpen={isModalOpen}
        reportType={SCOUT_REPORT_TYPES.outer}
        setIsOpen={() => setIsModalOpen({...isModalOpen, right: !isModalOpen.right})}
        onCreate={(data, callback) => dispatch(addUser(data, callback, hockeyRole))}
      />
    </ChessTemplate>
  );
}
