import {getPhysicalTestingOverall, getPhysicalTestingSirius} from '@api/newPhysicalTestingAPI';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  siriusInfo: {
    items: [],
    isLoading: false,
    isLoad: false,
    error: '',
  },
};

const physicalTestingSirius = createSlice({
  name: 'physicalTestingSirius',
  initialState,
  reducers: {
    physicalTestingSiriusLoading: ({siriusInfo}) => {
      siriusInfo.isLoading = true;
    },
    physicalTestingSiriusLoad: ({siriusInfo}, action) => {
      siriusInfo.isLoading = false;
      siriusInfo.isLoad = true;
      siriusInfo.items = action.payload;
    },
    physicalTestingSiriusError: ({siriusInfo}) => {
      siriusInfo.isLoading = false;
      siriusInfo.isLoad = true;
      siriusInfo.error = 'Отчёта для выбранной команды не существует';
    },
    physicalTestingSiriusClear: ({siriusInfo}) => {
      siriusInfo.items = [];
    },
  },
});

const {actions, reducer} = physicalTestingSirius;

const {
  physicalTestingSiriusLoading,
  physicalTestingSiriusLoad,
  physicalTestingSiriusClear,
  physicalTestingSiriusError,
} = actions;

export {reducer as physicalSiriusReducer};

export const fetchSiriusData = (data) => async (dispatch) => {
  dispatch(physicalTestingSiriusLoading());
  dispatch(physicalTestingSiriusClear());

  const changedDataForFetch = {
    typeOfTests: data.type,
    ...data,
  };

  const [{data: physicalTestingData}, {data: siriusTests}] = await Promise.all([
    getPhysicalTestingOverall(changedDataForFetch),
    getPhysicalTestingSirius(data.sirius),
  ]);

  let isPlayersHaveCreatedReport = false;
  const formattedDataForTable = physicalTestingData.map((player) => {
    let cParams = [];

    siriusTests.forEach((sTest) => {
      const foundedPlayerParam = player.params.find((test) => test.name === sTest.name);
      let tempObj = {
        ...sTest,
      };
      if (foundedPlayerParam) {
        tempObj.playerValue = foundedPlayerParam.value;
        isPlayersHaveCreatedReport = true;
      }
      cParams.push(tempObj);
    });

    return {
      ...player,
      params: cParams,
    };
  });

  if (!isPlayersHaveCreatedReport) {
    dispatch(physicalTestingSiriusError());
  }
  dispatch(physicalTestingSiriusLoad(formattedDataForTable));
};

export const clearPhysicalOverallState = () => (dispatch) => dispatch(physicalTestingSiriusClear());
