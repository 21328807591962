import DatePickerDefault from '@ui/MUI/DatePicker';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import MedicalProfileAPI from '../../../api/MedicalProfileAPI';
import {NOTIFICATION_TYPES} from '../../../constants/config';
import {generateId} from '../../../helpers/MedicalProfileHelper';
import {handleChangeForm} from '../../../redux/actions/MedicalProfileActions';
import {Button, LightButton} from '../../ui/Button';
import Icon from '../../ui/Icon/Icon';
import InputDefault from '../../ui/Input';
import {ReactComponentNotification} from '../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import SelectDefault from '../../ui/Select';

export default function Nutrition() {
  const medicalProfileForm = useSelector((state) => state.medicalProfile.medicalProfileForm || {});

  const dispatch = useDispatch();
  const [typesOptions, setTypesOptions] = useState([]);
  useEffect(() => {
    MedicalProfileAPI.getNutritionRecommendations()
      .then((data) => {
        setTypesOptions(data.data);
      })
      .catch(function () {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при загрузке рекомендаций к тренировкам');
      });
  }, []);

  const handleDeleteNutrition = (nutritionId) => {
    if (!nutritionId) return;

    let cloneNutrition = medicalProfileForm.nutrition ? [...medicalProfileForm.nutrition] : [];
    let currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    cloneNutrition.splice(
      cloneNutrition.findIndex((i) => i.idFront === nutritionId),
      1,
    );

    currentMedicalProfileForm.nutrition = cloneNutrition;
    dispatch(handleChangeForm(currentMedicalProfileForm));
  };

  const handleAddNutrtition = () => {
    const cloneNutrition = medicalProfileForm?.nutrition?.length ? [...medicalProfileForm.nutrition] : [];
    const currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    dispatch(
      handleChangeForm({
        ...currentMedicalProfileForm,
        nutrition: [
          ...cloneNutrition,
          {
            idFront: generateId(),
            type: '',
            startDate: '',
            comments: '',
          },
        ],
      }),
    );
  };
  const handleNutrition = (nutritionId, name, value) => {
    let cloneNutrition = medicalProfileForm.nutrition ? [...medicalProfileForm.nutrition] : [];
    let currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};
    const nutrition = cloneNutrition.map((i) => {
      if (i.idFront === nutritionId) return {...i, [name]: value};
      return i;
    });

    dispatch(handleChangeForm({...currentMedicalProfileForm, nutrition}));
  };

  const selectedDate = (date, id) => {
    if (date) {
      return new Date(date);
    } else {
      handleNutrition(id, 'startDate', new Date().getTime());
      return new Date();
    }
  };

  return (
    <Wrapper>
      <Title>Рекомендации по системе питания</Title>
      {medicalProfileForm.nutrition
        ? medicalProfileForm.nutrition.map((nutrition, index) => {
            return (
              <Recommendation key={index}>
                <Fields>
                  <FieldsWrapper>
                    <Field>
                      <Label>Тип рекомендации</Label>
                      <SelectDefault
                        errorText="Обязательное поле для заполнения"
                        isInvalid={!typesOptions.filter((option) => option.value === nutrition.type)[0]}
                        isMulti={false}
                        options={typesOptions}
                        placeholder={'Выберите тип рекомендации'}
                        value={
                          typesOptions.filter((option) => option.value === nutrition.type).length
                            ? typesOptions.filter((option) => option.value === nutrition.type)[0]
                            : ''
                        }
                        onChange={(type) => {
                          handleNutrition(nutrition.idFront, 'type', type.value);
                        }}
                      />
                    </Field>
                    <Field>
                      <Label>Дата начала рекомендации</Label>
                      <DatePickerDefault
                        inputFormat="DD.MM.yyyy"
                        value={selectedDate(nutrition.startDate, nutrition.idFront)}
                        variant="outlined"
                        onChange={(newDate) => {
                          handleNutrition(nutrition.idFront, 'startDate', new Date(newDate).getTime());
                        }}
                      />
                    </Field>
                  </FieldsWrapper>
                </Fields>
                <Fields>
                  <FieldsWrapper>
                    <Field>
                      <Label>Комментарий</Label>
                      <InputDefault
                        errorText="Обязательное поле для заполнения"
                        isInvalid={!nutrition.comments}
                        value={nutrition.comments ? nutrition.comments : ''}
                        onChange={(evt) => {
                          handleNutrition(nutrition.idFront, 'comments', evt.target.value);
                        }}
                      />
                    </Field>
                  </FieldsWrapper>
                </Fields>
                <Button
                  onClick={() => {
                    handleDeleteNutrition(nutrition.idFront);
                  }}
                >
                  <Icon height={17} type={'CloseRed'} viewBox={'0 0 17 17'} width={17} />
                </Button>
              </Recommendation>
            );
          })
        : null}
      <LightButton
        className="ButtonWithIcon"
        onClick={() => {
          handleAddNutrtition();
        }}
      >
        <IconContainer>
          <Icon height="12" type="Plus" viewBox="0 0 12 12" width="12" />
          Добавить рекомендацию по питанию
        </IconContainer>
      </LightButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`;
const Title = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  font: 600 normal 16px/1 'Proxima Nova';
  color: #990011;
  letter-spacing: 0.02em;
`;
const Recommendation = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  ${Button} {
    position: absolute;
    top: 40px;
    right: 36px;
    border-radius: 0;
    background-color: transparent;
    z-index: 1;

    @media screen and (max-width: 1024px) {
      right: 0;
    }
    @media screen and (max-width: 768px) {
      top: auto;
      bottom: 20px;
      right: -24px;
    }
  }
`;
const Fields = styled.div`
  flex: 0 0 50%;
  max-width: 50%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  box-sizing: border-box;
  :first-of-type {
    padding-right: 20px;
    padding-left: 0;
    @media screen and (max-width: 768px) {
      padding-right: 0;
    }
  }
  :last-of-type {
    padding-right: 0;
    padding-left: 20px;
    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
`;
const FieldsWrapper = styled.div`
  ${LightButton} {
    width: 100%;
  }
`;
const Field = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;

  input {
    resize: none;
    min-height: 40px;
    max-height: 40px;
    max-width: 80%;
    border: ${(props) => (props.isInvalid ? '1px solid #FF0000' : '1px solid #f3f3f3')};
    border-radius: 5px;
    font: normal normal 15px/40px 'Proxima Nova';
    color: #2c2c2c;

    @media screen and (max-width: 768px) {
      max-width: 90%;
      padding-right: 8px;
    }
    ::placeholder {
      font: normal normal 15px/40px 'Proxima Nova';
      color: #c4c4c4;
    }
  }
`;
const Label = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
`;
const IconContainer = styled.div`
  position: relative;
  font: normal normal 12px/25px 'Proxima Nova';
  color: #747474;
  svg {
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
  }
`;
