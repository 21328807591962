import {loadAllUsers} from '@actions/UsersActions';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import * as lessonsActions from '../../redux/actions/LessonsActions';
import * as schoolsActions from '../../redux/actions/SchoolsActions';
import AdminStatsPanel from '../admin/panels/AdminStatsPanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function AdminIndexApp() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAllUsers());
    dispatch(schoolsActions.loadAllSchools());
    dispatch(lessonsActions.loadAllLessons());
  }, []);
  return (
    <ChessTemplate active={'index'}>
      <AdminStatsPanel />
    </ChessTemplate>
  );
}
