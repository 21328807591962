import {Button} from '@ui/MUI';
import {string} from 'prop-types';
import React, {useState} from 'react';

import HighlightVideoModal from '../HighlightsVideoModal';

HighLights.propTypes = {
  url: string.isRequired,
};

function HighLights({isLoading, url}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Button color="primary" disabled={isLoading || !url} variant="contained" onClick={() => setIsModalOpen(true)}>
        смотреть хайлайты
      </Button>
      <HighlightVideoModal isOpen={isModalOpen} url={url} onClose={setIsModalOpen} />
    </>
  );
}

export default HighLights;
