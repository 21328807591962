import CircularProgress from '@mui/material/CircularProgress';
import React, {memo} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
/**@deprecated не использовать этот компонент**/
const SelectDefault = React.forwardRef(function SelectDefault(props, ref) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      justifyContent: 'space-between',
      backgroundColor: 'white',
      borderColor: props.isInvalid ? '#FF0000' : '#F3F3F3',
      borderRadius: '5px',
      boxShadow: state.isFocused ? '0 0 0 transparent' : '0 0 0 transparent',
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 10,
      zIndex: 2000,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '8px 10px',
      backgroundColor: state.isFocused ? '#f3f3f3' : 'transparent',
      minHeight: 42,
      font: 'bold normal 15px/18px "Proxima Nova"',
      color: '#2c2c2c',
    }),
    placeholder: (provided) => ({
      ...provided,
      font: 'normal normal 16px/18px "Proxima Nova"',
      color: '#2c2c2c',
    }),
    singleValue: (provided) => ({
      ...provided,
      font: 'normal normal 16px/18px "Proxima Nova"',
      color: '#2c2c2c',
    }),
    multiValue: (provided) => ({
      ...provided,
      padding: 5,
      backgroundColor: '#f3f3f3',
      borderRadius: 5,
      overflow: 'hidden',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: 0,
      font: 'normal normal 12px/16px Proxima Nova',
      color: '#2c2c2c',
      letterSpacing: '.02em',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      backgroundColor: '#f3f3f3',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
    }),
  };
  const LoadingIndicator = () => {
    return <CircularProgress color="secondary" size={12} />;
  };
  return (
    <SelectContainer>
      <Select
        className="Select"
        closeMenuOnSelect={props.closeOnSelect}
        components={{LoadingIndicator}}
        defaultValue={props.defaultValue}
        isClearable={props.isClearable}
        isDisabled={props.isLoading || props.isDisabled}
        isLoading={props.isLoading ? props.isLoading : false}
        isMulti={props.isMulti}
        isSearchable={props.isSearchable}
        openMenuOnFocus={props.openMenuOnFocus}
        options={props.options}
        placeholder={props.placeholder || 'Выберите'}
        ref={ref}
        styles={customStyles}
        value={props.value}
        onChange={props.onChange}
        {...props}
      />
      {props.isInvalid && <ErrorText>{props.errorText}</ErrorText>}
    </SelectContainer>
  );
});

export default memo(SelectDefault);

const SelectContainer = styled.div`
  /* z-index: 999; */
  .Select {
    svg path {
      fill: #c4c4c4;
    }
    .SelectMessage {
      padding: 8px 12px;
      margin-top: 0;
      margin-bottom: 0;
      box-sizing: border-box;
      text-align: center;
      color: hsl(0, 0%, 60%);
    }
  }
  &.disabled {
    .Select {
      svg {
        display: none;
      }
    }
  }
`;
const ErrorText = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  color: #ff0000;
`;
