export default {
  /**
   * Установка токена в localStorage
   * @param {object} token - объект токена
   */
  setToken(token) {
    window.localStorage.setItem('token', token);
  },
  /**
   * Получение токена из localStorage
   * @return {object} token - объект токена
   */
  getToken() {
    const data = window.localStorage.getItem('token');
    return data;
  },
  /**
   * Удаление токена из localStorage
   */
  destroyToken() {
    window.localStorage.removeItem('token');
  },
  /**
   * Проверка на то, есть ли токен в localStorage
   * @return {boolean}
   */
  isAuth() {
    const data = window.localStorage.getItem('token');
    return !!data;
  },
};
