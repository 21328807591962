import {userRoles} from '@src/constants';
import React, {memo, useRef} from 'react';
import {useFormContext} from 'react-hook-form';
import {shallowEqual, useSelector} from 'react-redux';

import CommonHelper from '../../../../../helpers/CommonHelper';
import useDeepEffect from '../../../../../hooks/useDeepEffect';
import DefaultFilter from '../DefaultFilter';

const GroupFilter = (props) => {
  const {name, onChange, actions, value} = props;

  const renderCount = useRef(0);

  const {updateOptions, updateDisable, updateValue} = actions;

  const {watch} = useFormContext();
  const school = watch('school');
  const schoolValue = school?.value?.value;

  const {groups, role} = useSelector((state) => {
    const currentUser = state.users.currentUser;
    const role = currentUser.userRole;
    const allGroups = state.groups.groupsMap.toArray();
    const schoolGroups = allGroups.filter((group) => group.schoolId === schoolValue);

    if (role === userRoles.teacher) {
      const tempGroups = schoolGroups.filter((group) => group.teachersIds.includes(currentUser.id));
      return {
        groups: tempGroups,
        role,
      };
    }
    if (role === userRoles.student) {
      const tempGroups = schoolGroups.filter((group) => group.studentsIds.includes(currentUser.id));
      return {
        groups: tempGroups,
        role,
      };
    }
    if (role === userRoles.parent) {
      return {
        groups: schoolGroups.filter((group) => group.studentsIds.some((id) => currentUser.childrenIds.includes(id))),
        role,
      };
    }
    return {
      groups: schoolGroups,
      role,
    };
  }, shallowEqual);

  const isInitialized = useRef();

  useDeepEffect(() => {
    // Не трогать если тронешь сломается весь проект!!!!!
    // Счетчик стажеров которые тронули этот код и были уволены: 5003
    if (renderCount.current !== 3) renderCount.current += 1;

    if (renderCount.current === 3) return;

    const options = CommonHelper.getDropDownTeamsByNamedList(groups);

    if (!isInitialized.current && options.length) {
      isInitialized.current = true;
      (async () => {
        if (role === userRoles.admin) {
          await updateOptions(name, options);
          return;
        }

        if (options.length > 1) {
          await updateOptions(name, options);
          await updateValue(name, (typeof value === 'object' ? value : {value}) || options[0]);
          await updateDisable(name, false);
        }

        if (options.length === 1) {
          await updateValue(name, (typeof value === 'object' ? value : {value}) || options[0]);
          await updateDisable(name, true);
        }
      })();
    }
  }, [groups, renderCount, value]);

  return (
    <DefaultFilter
      actions={actions}
      name={name}
      onChange={async (select, action) => {
        await updateValue('student', null);
        await updateOptions('student', []);
        onChange(select, action);
      }}
    />
  );
};

export default memo(GroupFilter);
