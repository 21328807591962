import {API_ENDPOINT} from '@src/constants/config';
import axios from 'axios';

export default {
  uploadFile: async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`${API_ENDPOINT}/v1/storage/s3/upload`, formData, {
      failed: (err) => ({message: err?.data?.message}),
    });
  },
};
