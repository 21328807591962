import {TEAM_COLORS} from '@src/constants';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';
import styled from 'styled-components';

import DonutChart from '../../../charts/DonutChart';
import {ChartContainer, ChartWrapper} from './StatsComponentsChart';

const StatsComponentsChart = ({title, chart, weAtHome, isLoading, wrapperProps = {}}) => {
  const seriesData = chart?.seriesData ? [...chart.seriesData] : [];

  return (
    <ChartWrapper maxWidth="25%" width="100%" {...wrapperProps}>
      <ChartContainer>
        <PercentCount isLoading={isLoading} preloaderWidthBetween={[20, 30]} variant="h4">
          {Math.ceil(seriesData[weAtHome ? 0 : 1]?.percentValue)}%
        </PercentCount>
        {chart && (
          <DonutChart
            height={chart?.height}
            isLoading={isLoading}
            options={{
              series: [
                {
                  animation: !isLoading,
                  type: 'pie',
                  radius: ['70%', '100%'],
                  avoidLabelOverlap: false,
                  itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1,
                  },
                  silent: true,
                  emphasis: {
                    label: {
                      show: false,
                    },
                  },
                  labelLine: {
                    show: false,
                  },
                  label: {
                    show: false,
                  },
                  colorBy: 'data',
                  data: seriesData?.reverse(),
                  color: weAtHome ? [...TEAM_COLORS] : [...TEAM_COLORS]?.reverse(),
                  tooltip: {
                    show: false,
                  },
                },
              ],
            }}
            width={chart?.width}
          />
        )}
      </ChartContainer>
      <TypographyDefault mt={2} textAlign="center" variant="subtitle2">
        {title}
      </TypographyDefault>
    </ChartWrapper>
  );
};

export default React.memo(StatsComponentsChart);

const PercentCount = styled(TypographyDefault)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
