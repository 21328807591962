import {useEffect, useRef} from 'react';

const useClickAway = (cb) => {
  const ref = useRef();
  useEffect(() => {
    const listener = (event) => {
      if (!ref || !ref.current || ref.current.contains(event.target)) {
        return;
      }
      cb(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, cb]);

  return ref;
};

export default useClickAway;
