import React from 'react';

function AverageLengthOfShift() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M18.7086 31.8762H16.6521C14.8465 31.8762 13.3418 30.3715 13.3418 28.5658V25.9075H14.4452V28.5658C14.4452 29.7696 15.4484 30.7727 16.6521 30.7727H18.7086C19.9123 30.7727 20.9155 29.7696 20.9155 28.5658V25.8573H22.0189V28.5658C22.0189 30.4216 20.5142 31.8762 18.7086 31.8762Z"
          fill="#354546"
        />
        <path
          d="M14.6458 25.8574C14.6458 26.2586 14.2947 26.6097 13.8935 26.6097C13.4922 26.6097 13.1411 26.2586 13.1411 25.8574C13.1411 25.4561 13.4922 25.105 13.8935 25.105C14.2947 25.105 14.6458 25.4561 14.6458 25.8574Z"
          fill="#354546"
        />
        <path
          d="M22.2195 25.8574C22.2195 26.2586 21.8684 26.6097 21.4672 26.6097C21.0659 26.6097 20.7148 26.2586 20.7148 25.8574C20.7148 25.4561 21.0659 25.105 21.4672 25.105C21.8684 25.105 22.2195 25.4561 22.2195 25.8574Z"
          fill="#354546"
        />
        <path
          d="M20.3135 18.4843H14.6959C13.442 18.4843 12.489 19.4875 12.489 20.6912V25.2555C12.489 26.5094 13.4921 27.4624 14.6959 27.4624H20.2633C21.5172 27.4624 22.4702 26.4593 22.4702 25.2555V20.6912C22.5204 19.4875 21.5172 18.4843 20.3135 18.4843ZM20.2132 24.5031C20.2132 25.1552 19.6614 25.7069 19.0094 25.7069H15.9498C15.2978 25.7069 14.7461 25.1552 14.7461 24.5031V21.7947C14.7461 21.1426 15.2978 20.5909 15.9498 20.5909H19.0094C19.6614 20.5909 20.2132 21.1426 20.2132 21.7947V24.5031Z"
          fill="#990011"
        />
        <path
          d="M27.7366 18.4843C27.7366 19.7382 27.4859 21.0423 26.9341 22.2461C26.7837 22.6473 26.3824 22.8981 25.931 22.8981H2.15672C1.75546 22.8981 1.35421 22.6473 1.15358 22.2461C0.601858 21.0423 0.351074 19.7884 0.351074 18.4843C0.351074 12.7163 5.91847 7.95141 12.9404 7.55016C13.3417 7.5 13.6928 7.5 14.0439 7.5C16.1504 7.5 18.1567 7.90126 19.9624 8.5533C24.5768 10.3589 27.7366 14.1207 27.7366 18.4843Z"
          fill="#C52E25"
        />
        <path
          d="M27.7366 18.4843C27.7366 14.1207 24.5265 10.3589 19.9623 8.60345C15.4983 10.3088 12.3886 13.9201 12.188 18.1332H26.8839C27.1849 18.1332 27.4858 18.2335 27.7366 18.4843Z"
          fill="#D53D33"
        />
        <path
          d="M12.439 20.5909C12.5894 21.1426 12.7399 21.6944 12.9907 22.2461C13.1412 22.6473 13.5424 22.8981 13.9938 22.8981H25.9813C26.3825 22.8981 26.7838 22.6473 26.9844 22.2461C27.2352 21.6442 27.4358 21.0423 27.5863 20.3903C27.3857 20.5408 27.185 20.5909 26.9343 20.5909H12.439Z"
          fill="#D53D33"
        />
        <path
          d="M28.0879 19.337C28.0879 18.9859 27.9374 18.6849 27.7368 18.4342C27.7368 18.4342 27.7368 18.4342 27.7368 18.4843C27.7368 19.1364 27.6866 19.7382 27.5361 20.3903C27.8872 20.1897 28.0879 19.7884 28.0879 19.337Z"
          fill="#C52E25"
        />
        <path
          d="M12.1881 18.1332H1.25392C0.551724 18.1332 0 18.685 0 19.3872C0 20.0893 0.551724 20.6411 1.25392 20.6411H12.4389C12.2884 19.9389 12.1881 19.2367 12.1881 18.5345C12.1379 18.384 12.1881 18.2335 12.1881 18.1332Z"
          fill="#B51F18"
        />
        <path
          d="M27.7366 18.4843C27.5359 18.2837 27.1849 18.1332 26.8839 18.1332H12.188C12.188 18.2837 12.188 18.384 12.188 18.5345C12.188 19.2367 12.2883 19.9389 12.4388 20.6411H26.8839C27.1347 20.6411 27.3353 20.5909 27.5359 20.4404C27.6864 19.7884 27.7366 19.1364 27.7366 18.4843Z"
          fill="#C52E25"
        />
        <path
          d="M11.837 20.5909H6.26964C5.01572 20.5909 4.06274 21.594 4.06274 22.7978V27.3621C4.06274 28.616 5.06588 29.569 6.26964 29.569H11.837C13.091 29.569 14.0439 28.5658 14.0439 27.3621V22.7978C14.0439 21.594 13.0408 20.5909 11.837 20.5909ZM11.7367 26.6097C11.7367 27.2617 11.185 27.8135 10.533 27.8135H7.52356C6.87152 27.8135 6.3198 27.2617 6.3198 26.6097V23.9012C6.3198 23.2492 6.87152 22.6975 7.52356 22.6975H10.5831C11.2352 22.6975 11.7869 23.2492 11.7869 23.9012V26.6097H11.7367Z"
          fill="#C52E25"
        />
        <path
          d="M5.76794 22.9984C5.76794 23.7508 5.16606 24.3527 4.41371 24.3527H4.01246C4.01246 24.3025 4.01246 24.2523 4.01246 24.2523C4.01246 23.5 3.41058 22.8981 2.65823 22.8981H2.30713C2.35729 22.547 2.50776 22.2461 2.70838 22.0454C2.95917 21.7947 3.31026 21.6442 3.66136 21.6442H4.36356C5.06575 21.6442 5.66763 22.1959 5.71779 22.8981C5.76794 22.8981 5.76794 22.9483 5.76794 22.9984Z"
          fill="#C52E25"
        />
        <path
          d="M27.3355 15.1238C27.3355 15.5251 27.0345 15.826 26.6333 15.826L8.72734 15.826C8.32609 15.826 8.02515 15.5251 8.02515 15.1238C8.02515 14.7226 8.32609 14.4216 8.72734 14.4216L26.6333 14.4216C27.0345 14.4216 27.3355 14.7727 27.3355 15.1238Z"
          fill="#FFFAED"
        />
        <path
          d="M18.3073 17.0298C18.3073 17.2304 18.1569 17.3809 17.9562 17.3809H9.02834C8.82772 17.3809 8.67725 17.2304 8.67725 17.0298C8.67725 16.8292 8.82772 16.6787 9.02834 16.6787H17.9562C18.1569 16.6787 18.3073 16.8292 18.3073 17.0298Z"
          fill="#E54D42"
        />
        <path
          d="M26.9342 17.0298C26.9342 17.2304 26.7837 17.3809 26.5831 17.3809H17.605C17.4044 17.3809 17.2539 17.2304 17.2539 17.0298C17.2539 16.8291 17.4044 16.6787 17.605 16.6787H26.5831C26.7837 16.6787 26.9342 16.8291 26.9342 17.0298Z"
          fill="#E54D42"
        />
        <path
          d="M28.0879 17.0298C28.0879 17.2304 27.9374 17.3809 27.7368 17.3809H18.8089C18.6082 17.3809 18.4578 17.2304 18.4578 17.0298C18.4578 16.8291 18.6082 16.6787 18.8089 16.6787H27.7368C27.9374 16.6787 28.0879 16.8291 28.0879 17.0298Z"
          fill="#FFFAED"
        />
        <path
          d="M10.1318 34.1332H8.02519C6.21955 34.1332 4.71484 32.6285 4.71484 30.8229V28.1646H5.81829V30.8229C5.81829 32.0266 6.82143 33.0298 8.02519 33.0298H10.0816C11.2854 33.0298 12.2885 32.0266 12.2885 30.8229V28.1144H13.392V30.8229C13.4421 32.6285 11.9374 34.1332 10.1318 34.1332Z"
          fill="#354546"
        />
        <path
          d="M6.06891 28.1144C6.06891 28.5157 5.71781 28.8668 5.31656 28.8668C4.91531 28.8668 4.56421 28.5157 4.56421 28.1144C4.56421 27.7132 4.91531 27.3621 5.31656 27.3621C5.71781 27.3119 6.06891 27.663 6.06891 28.1144Z"
          fill="#354546"
        />
        <path
          d="M13.6426 28.1144C13.6426 28.5157 13.2915 28.8668 12.8903 28.8668C12.489 28.8668 12.1379 28.5157 12.1379 28.1144C12.1379 27.7131 12.489 27.3621 12.8903 27.3621C13.2915 27.3621 13.6426 27.663 13.6426 28.1144Z"
          fill="#354546"
        />
        <path
          d="M17.9061 39.5C17.8058 39.5 17.7055 39.5 17.6051 39.5C16.7023 39.4498 15.8998 38.9984 15.2979 38.2962L11.2854 33.5815L12.3387 32.7288L16.3512 37.4436C16.7023 37.8448 17.2039 38.0956 17.7055 38.1458C18.2572 38.1959 18.7588 38.0455 19.16 37.6944L20.0628 36.942C20.9155 36.2398 21.0158 34.9357 20.3136 34.0831L18.3575 31.7759L19.3606 30.9232L21.3167 33.2304C22.5205 34.6348 22.37 36.7915 20.9155 37.9953L20.0628 38.6975C19.4609 39.1991 18.6584 39.5 17.9061 39.5Z"
          fill="#41555E"
        />
        <path
          d="M12.79 33.1301C13.091 33.4812 13.091 34.0329 12.7399 34.3338C12.3888 34.6348 11.8371 34.6348 11.5361 34.2837L10.7838 33.4812C10.4828 33.1301 10.4828 32.5784 10.8339 32.2774C11.185 31.9765 11.7367 31.9765 12.0377 32.3276L12.79 33.1301Z"
          fill="#303030"
        />
        <path
          d="M19.9121 31.0736C20.213 31.4247 20.213 31.9765 19.8619 32.2774C19.5108 32.5783 18.9591 32.5783 18.6582 32.2273L17.9058 31.4247C17.6049 31.0736 17.6049 30.5219 17.956 30.221C18.3071 29.92 18.8588 29.92 19.1598 30.2711L19.9121 31.0736Z"
          fill="#303030"
        />
        <circle cx="22.5" cy="9.5" fill="#263536" r="9.5" />
        <path
          d="M25.145 9.00194L25.8523 9.70928L27.8533 7.70831C28.0489 7.51272 28.0489 7.19656 27.8533 7.00097L25.8523 5L25.145 5.70734L26.292 6.8544H20V7.85488H26.292L25.145 9.00194Z"
          fill="white"
        />
        <path
          d="M19.8549 13.002L18.7079 11.8549H25V10.8544H18.7079L19.8549 9.70735L19.1476 9L17.1466 11.001C17.049 11.0985 17 11.2266 17 11.3547C17 11.4827 17.049 11.6108 17.1466 11.7084L19.1476 13.7094L19.8549 13.002Z"
          fill="white"
        />
      </g>
    </React.Fragment>
  );
}
export default AverageLengthOfShift;
