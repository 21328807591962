import styled from 'styled-components';

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${(props) => props.src});
  border-radius: 6px 0px 0px 6px;
`;

export const LogoHead = styled.img`
  position: absolute;
`;

export const ImageContainer = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    display: none;
  }
`;
