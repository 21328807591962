import moment from 'moment';
import {useMemo} from 'react';

import {getDayHours, getMonthDays, getPeriod, getWeekDays} from '../../../../../../../helpers/dateHelper';

const filterByProperty = (item, property) => {
  const roomId = item?.extendedProps?.lesson?.rooms[0]?.roomId;
  if (Array.isArray(property)) {
    return property.includes(roomId);
  }
  return roomId === property;
};

// переписать на enum;
export const SPORT_EVENTS = [
  'QTLaWG5PUv',
  'cCi7HYAl5E',
  '1VQxx2Dje1',
  'YnPUPiw7y1',
  'QvUwFTZqfA',
  '6CuPCIvPCd',
  'FrtBxDIgS0',
  'NIrZtOBThI',
  'bjjYTrM0H4',
  'kv4C2oiCJZ',
];

const getEvents = (events, viewType, date) => {
  let days = [];

  if (viewType === 'week') {
    const maxDays = 7;
    const asUnix = null;
    days = getWeekDays(maxDays, asUnix, date);
  }

  if (viewType === 'day') {
    days = getDayHours();
  }

  if (viewType === 'month') {
    days = getMonthDays();
  }
  return days.map((day) => {
    const filteredEvents = events.filter(
      (event) => moment(event.start).format('DD.MM.YYYY') === moment(day).format('DD.MM.YYYY'),
    );
    return {date: day.valueOf(), events: filteredEvents};
  });
};

const useFormatedLessons = ({events, viewType, filters, date}) => {
  return useMemo(() => {
    if (!events.length) return [];

    const bigArena = {
      title: 'Большая арена',
      items: events.filter((item) => filterByProperty(item, 'RnanlyjQOi')),
    };

    const smallArena = {
      title: 'Малая арена',
      items: events.filter((item) => filterByProperty(item, 'PbTHwI0YJW')),
    };

    const sportArena = {
      title: 'Спортивные залы',
      items: events.filter((item) => filterByProperty(item, SPORT_EVENTS)),
    };

    return [bigArena, smallArena, sportArena].map((arr) => {
      const formattedEvents = arr.items.map((item) => {
        if (item.extendedProps.type === 'training_opponent') {
          item.extendedProps = {
            ...item.extendedProps,
            groupName: item.extendedProps.lesson.opponent,
          };
        }
        if (item.extendedProps.type === 'game') {
          item.extendedProps = {
            ...item.extendedProps,
            groupName: item.extendedProps.groupName,
            fullName: `${item.extendedProps.groupName} - ${item.extendedProps.lesson.opponent}`,
          };
        }
        if (
          item.extendedProps.type === 'training' &&
          item.extendedProps.lesson?.playerRole?.length === 1 &&
          item.extendedProps.lesson?.playerRole.filter((item) => item.value === 'goaltender')?.length > 0
        ) {
          item.extendedProps = {
            ...item.extendedProps,
            groupName: 'Вратари',
          };
        }
        return item;
      });
      return {
        title: arr.title,
        school: filters?.schoolId?.label,
        period: getPeriod(viewType, date),
        events: getEvents(formattedEvents, viewType, date),
      };
    });
  }, [events, viewType, filters]);
};

export default useFormatedLessons;
