import useDeepEffect from '@hooks/useDeepEffect';
import axios, {CancelTokenSource, isCancel} from 'axios';
import {useCallback, useRef} from 'react';

/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 * This function sets up the appropriate useEffect to handle the canceling.
 *
 * @returns {newCancelToken: function, isCancel: function}
 * newCancelToken - used to generate the cancel token sent in the Axios request.
 * isCancel - used to check if error returned in response is a cancel token error.
 */
export const useCancelToken = ({cancelDeps = []}: {cancelDeps?: any[]}) => {
  const axiosSource = useRef<CancelTokenSource | null>(null);
  const newCancelToken = useCallback(() => {
    axiosSource.current = axios.CancelToken.source();
    return axiosSource.current.token;
  }, [...cancelDeps]);

  useDeepEffect(() => {
    if (cancelDeps && axiosSource.current) axiosSource.current.cancel();
    return () => {
      if (axiosSource.current) axiosSource.current.cancel();
    };
  }, [...cancelDeps]);

  return {newCancelToken, isCancel};
};
