import React from 'react';

function Goals() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M13.4592 3.89435L9.69354 0.12873C9.61116 0.0463184 9.49937 0 9.38282 0H3.02833C2.13715 0 1.41211 0.725039 1.41211 1.61619V13.3838C1.41211 14.275 2.13715 15 3.0283 15H11.9717C12.8629 15 13.5879 14.275 13.5879 13.3838V4.20507C13.5879 4.08853 13.5416 3.97676 13.4592 3.89435ZM9.82228 1.50038L12.0875 3.76562H10.5596C10.153 3.76562 9.82228 3.43485 9.82228 3.0283V1.50038ZM11.9717 14.1211H3.0283C2.62178 14.1211 2.29102 13.7903 2.29102 13.3838V1.61619C2.29102 1.20967 2.62178 0.878906 3.0283 0.878906H8.94334V3.0283C8.94334 3.91948 9.66838 4.64452 10.5596 4.64452H12.709V13.3838C12.709 13.7903 12.3783 14.1211 11.9717 14.1211Z"
          fill="black"
        />
        <path
          d="M5.6172 6.58984H3.73438C3.49168 6.58984 3.29492 6.7866 3.29492 7.0293V8.91212C3.29492 9.15481 3.49168 9.35157 3.73438 9.35157H5.6172C5.85989 9.35157 6.05665 9.15481 6.05665 8.91212V7.02927C6.05665 6.78657 5.85992 6.58984 5.6172 6.58984ZM5.17774 8.47267H4.17383V7.46872H5.17774V8.47267Z"
          fill="black"
        />
        <path
          d="M5.6172 10.3555H3.73438C3.49168 10.3555 3.29492 10.5522 3.29492 10.7949V12.6777C3.29492 12.9204 3.49168 13.1172 3.73438 13.1172H5.6172C5.85989 13.1172 6.05665 12.9204 6.05665 12.6777V10.7949C6.05665 10.5522 5.85992 10.3555 5.6172 10.3555ZM5.17774 12.2383H4.17383V11.2343H5.17774V12.2383Z"
          fill="black"
        />
        <path
          d="M7.06055 7.0293C7.06055 7.27199 7.2573 7.46875 7.5 7.46875H9.38282C9.62552 7.46875 9.82228 7.27199 9.82228 7.0293C9.82228 6.7866 9.62552 6.58984 9.38282 6.58984H7.5C7.2573 6.58984 7.06055 6.78657 7.06055 7.0293Z"
          fill="black"
        />
        <path
          d="M11.2656 8.47266H7.5C7.2573 8.47266 7.06055 8.66941 7.06055 8.91211C7.06055 9.1548 7.2573 9.35156 7.5 9.35156H11.2656C11.5083 9.35156 11.7051 9.1548 11.7051 8.91211C11.7051 8.66941 11.5083 8.47266 11.2656 8.47266Z"
          fill="black"
        />
        <path
          d="M7.5 11.2344H9.38282C9.62552 11.2344 9.82228 11.0376 9.82228 10.7949C9.82228 10.5522 9.62552 10.3555 9.38282 10.3555H7.5C7.2573 10.3555 7.06055 10.5522 7.06055 10.7949C7.06055 11.0376 7.2573 11.2344 7.5 11.2344Z"
          fill="black"
        />
        <path
          d="M11.2656 12.2383H7.5C7.2573 12.2383 7.06055 12.435 7.06055 12.6777C7.06055 12.9204 7.2573 13.1172 7.5 13.1172H11.2656C11.5083 13.1172 11.7051 12.9204 11.7051 12.6777C11.7051 12.435 11.5083 12.2383 11.2656 12.2383Z"
          fill="black"
        />
        <path
          d="M3.73437 3.23241H6.08787C6.33057 3.23241 6.52733 3.03565 6.52733 2.79295C6.52733 2.55026 6.33057 2.3535 6.08787 2.3535H3.73437C3.49168 2.3535 3.29492 2.55026 3.29492 2.79295C3.29492 3.03565 3.49168 3.23241 3.73437 3.23241Z"
          fill="black"
        />
        <path
          d="M3.73437 5.11523H7.49999C7.74268 5.11523 7.93944 4.91848 7.93944 4.67578C7.93944 4.43309 7.74268 4.23633 7.49999 4.23633H3.73437C3.49168 4.23633 3.29492 4.43309 3.29492 4.67578C3.29492 4.91848 3.49168 5.11523 3.73437 5.11523Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_12307_40620">
          <rect fill="white" height="15" width="15" />
        </clipPath>
      </defs>
    </React.Fragment>
  );
}
export default Goals;
