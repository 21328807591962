import AutocompleteDefault from '@ui/MUI/Autocomplete';
import InputDefault from '@ui/MUI/Input';
import SelectDefault from '@ui/MUI/Select';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import UsersAPI from '../../../../../../api/UsersAPI';
import {NOTIFICATION_TYPES} from '../../../../../../constants/config';
import * as groupsActions from '../../../../../../redux/actions/GroupsActions';
import Filters from '../../../../../Filters/Filters';
import DialogDefault from '../../../../../ui/MUI/Modals/Dialog/Dialog';
import {ReactComponentNotification} from '../../../../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {formatGroup, formatTeacherChipContent} from '../../../../helper';

export default function EditModalContent(props) {
  const {group, toggleModal = () => {}, confirmModalOpen, setConfirmModalOpen} = props;

  const dispatch = useDispatch();
  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  const [isTeachersLoading, setIsTeachersLoading] = useState(false);
  const [allSchoolTeachers, setAllSchoolTeachers] = useState([]);

  const groupId = group?.id;

  const {
    handleSubmit,
    formState: {},
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: group?.name || '',
      description: group?.description || '',
      schoolId: group?.school?.id || '',
      birthYear: group?.birthYear || '',
      instatId: group?.instatId || '',
      teachers: group?.teachers || [],
    },
  });

  const schoolId = watch('schoolId');

  const getAllSchoolTeachers = async () => {
    setIsTeachersLoading(true);
    const schoolTeachers = await UsersAPI.getTeachersBySchool(schoolId);
    setAllSchoolTeachers(schoolTeachers.data);
    setIsTeachersLoading(false);
  };

  useEffect(() => {
    if (schoolId) {
      getAllSchoolTeachers();
    }
  }, [schoolId]);

  const onSubmitEditGroupForm = (group) => {
    dispatch(groupsActions.updateGroup(groupId, formatGroup(group, true)));
    ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Команда успешно сохранена.');
    toggleModal('right', false);
  };

  const deleteGroup = (groupId) => {
    dispatch(groupsActions.deleteGroup(groupId));
    ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Команда успешно удалена.');
    toggleModal('right', false);
    history.goBack();
  };

  return (
    <GroupModalContent>
      <GroupModalTitle>Редактирование команды</GroupModalTitle>
      <GroupModalForm id="edit_form" onSubmit={handleSubmit(onSubmitEditGroupForm)}>
        <Controller
          control={control}
          name="name"
          render={({field: {onChange, value}}) => (
            <GroupModalFormField>
              <InputDefault
                id="team_name"
                label="Название команды"
                required={true}
                value={value}
                onChange={(newName) => {
                  onChange(newName);
                }}
              />
            </GroupModalFormField>
          )}
        />
        <Filters
          className="SchoolFilter"
          filters={[
            {
              name: 'school',
              label: null,
              value: group?.school?.id ? {value: group.school.id, label: group.school.name} : {},
              renderFilter: (props) => {
                return (
                  <GroupModalFormField>
                    <SelectDefault
                      id="schoolId"
                      isDisabled={props.filter.isDisabled}
                      label="Школа"
                      options={props.filter.options}
                      required={true}
                      value={
                        props.filter?.value?.value?.value
                          ? props.filter?.value?.value?.value
                          : props.filter?.value?.value
                      }
                      onChange={(e) => {
                        props.actions.updateValue('school', {value: e.target.value});
                      }}
                    />
                  </GroupModalFormField>
                );
              },
            },
          ]}
          isLegacy={false}
          onChange={(filters) => {
            setValue('schoolId', filters.school);
          }}
        />
        <Controller
          control={control}
          name="birthYear"
          render={({field: {onChange, value}}) => (
            <GroupModalFormField>
              <InputDefault
                id="birthYear"
                label="Год рождения"
                required={true}
                value={value}
                onChange={(newBirthYear) => {
                  onChange(newBirthYear);
                }}
              />
            </GroupModalFormField>
          )}
        />
        <Controller
          control={control}
          name="instatId"
          render={({field: {onChange, value}}) => (
            <GroupModalFormField>
              <InputDefault
                id="instat_id"
                label="ID Команды (Instat)"
                required={false}
                value={value}
                onChange={(newId) => {
                  onChange(newId);
                }}
              />
            </GroupModalFormField>
          )}
        />
        <Controller
          control={control}
          name="teachers"
          render={({field: {onChange, value}}) => {
            return (
              <GroupModalFormField>
                <AutocompleteDefault
                  getOptionLabel={(option) => `${option.lastName} ${option.firstName}`}
                  id="teachers"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  label="Тренеры"
                  options={[...allSchoolTeachers]}
                  placeholder="Поиск..."
                  renderTags={(value) => value.map((option, index) => formatTeacherChipContent(option, index))}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                />
              </GroupModalFormField>
            );
          }}
        />
      </GroupModalForm>
      <DialogDefault
        actionText="Удалить"
        closeText="Отменить"
        content={
          <ConfirmModalContent>
            <ConfirmModalContentText>Вы действительно хотите удалить команду?</ConfirmModalContentText>
          </ConfirmModalContent>
        }
        handleClickOpen={() => {
          setConfirmModalOpen(true);
        }}
        open={confirmModalOpen}
        title="Удаление команды"
        onActionButtonClick={() => {
          deleteGroup(groupId);
          setConfirmModalOpen(false);
        }}
        onCloseDefault={() => {
          setConfirmModalOpen(false);
        }}
      />
    </GroupModalContent>
  );
}

const GroupModalContent = styled.div`
  .SchoolFilter {
    padding: 0;
    margin-bottom: 24px;
    flex: 0 0 100%;
    max-width: 100%;
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    .filter-container {
      flex: 0 0 100%;
      max-width: 100%;
      & > label {
        display: none;
      }
    }
  }
`;
const GroupModalTitle = styled.p`
  margin-top: 0;
  margin-bottom: 20px;
  font: 700 normal 16px/20px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;
const GroupModalForm = styled.form``;
const GroupModalFormField = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const ConfirmModalContent = styled.div``;
const ConfirmModalContentText = styled.p`
  margin-top: -6px;
  margin-bottom: 0;
  font: 400 normal 14px/20px 'Proxima Nova';
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.17px;
`;
