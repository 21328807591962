import {GAMES_COUNT_BUTTONS} from '@components/apps/PlayerPersonalFile/constants';
import {Button, Divider, Stack} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

GamesCountButtons.propTypes = {
  gamesCount: PropTypes.string.isRequired,
  setGamesCount: PropTypes.func.isRequired,
};

function GamesCountButtons({gamesCount = GAMES_COUNT_BUTTONS[0].value, setGamesCount}) {
  return (
    <Stack
      alignItems="center"
      direction="row"
      divider={<Divider flexItem orientation="vertical" />}
      spacing={{xxs: 1, sm: 3}}
    >
      {GAMES_COUNT_BUTTONS.map(({label, value}, index) => {
        const isSelected = gamesCount === value;
        return (
          <Button
            color={isSelected ? 'primary' : 'secondary'}
            key={index}
            size="small"
            variant={isSelected ? 'contained' : 'text'}
            onClick={() => setGamesCount(value)}
          >
            {label}
          </Button>
        );
      })}
    </Stack>
  );
}

export default GamesCountButtons;
