import {useOpponents} from '@components/apps/TechnicalSkillsVideoEstimation/hooks/useOpponents';
import {useTournaments} from '@components/apps/TechnicalSkillsVideoEstimation/hooks/useTournaments';
import {useVideoTags} from '@components/apps/TechnicalSkillsVideoEstimation/hooks/useVideoTags';
import Box from '@components/Box';
import {useStudent} from '@hooks/filters';
import {useSeasons} from '@hooks/seasonsHooks';
import {Add} from '@mui/icons-material';
import {Box as MuiBox, Button, Chip, Stack} from '@ui/MUI';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import React from 'react';

const Filters = ({groupId, filters, onChange, onClick, isStudent}) => {
  const students = useStudent({connectorValue: groupId});
  const tags = useVideoTags();

  const needShortSeasons = true;
  const seasons = useSeasons(needShortSeasons);
  const handleChange = (name, v) => {
    onChange({...filters, [name]: v});
  };
  const tournaments = useTournaments({seasons: filters.seasons?.map((season) => season?.value), groups: [groupId]});
  const opponents = useOpponents({seasons: filters.seasons?.map((season) => season?.value), groups: [groupId]});

  return (
    <Box>
      <Stack direction="row" flexWrap={{xxs: 'wrap', sm: 'nowrap'}} gap={4} justifyContent="space-between">
        <Stack direction="row" flexWrap="wrap" gap={4} width="100%">
          <MuiBox
            maxWidth={{xxs: '100%', xs: 320}}
            width={(theme) => ({xxs: '100%', xs: `calc(100% / ${!isStudent ? 5 : 4} - ${theme.spacing(4)})`})}
          >
            <AutocompleteDefault
              isLoading={seasons?.isLoading}
              label="Сезон"
              limitTags={1}
              multiple={true}
              options={seasons?.options || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    <Chip label={option?.label} size="small" variant="outlined" {...getTagProps({index})} key={index} />
                  );
                })
              }
              value={filters.seasons}
              onChange={(_, v) => handleChange('seasons', v)}
            />
          </MuiBox>
          <MuiBox
            maxWidth={{xxs: '100%', xs: 320}}
            width={(theme) => ({xxs: '100%', xs: `calc(100% / ${!isStudent ? 5 : 4} - ${theme.spacing(4)})`})}
          >
            <AutocompleteDefault
              isLoading={tournaments?.isLoading}
              label="Турнир"
              limitTags={1}
              multiple={true}
              options={
                tournaments?.data?.map((tournament) => {
                  return {label: tournament, value: tournament};
                }) || []
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return <Chip label={option} size="small" variant="outlined" {...getTagProps({index})} key={index} />;
                })
              }
              value={filters.tournaments}
              onChange={(_, v) =>
                handleChange(
                  'tournaments',
                  v?.map(({value}) => {
                    return value;
                  }),
                )
              }
            />
          </MuiBox>
          <MuiBox
            maxWidth={{xxs: '100%', xs: 320}}
            width={(theme) => ({xxs: '100%', xs: `calc(100% / ${!isStudent ? 5 : 4} - ${theme.spacing(4)})`})}
          >
            <AutocompleteDefault
              isLoading={opponents?.isLoading}
              label="Соперник"
              multiple={true}
              options={
                opponents?.data?.map((opponent) => {
                  return {label: opponent, value: opponent};
                }) || []
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return <Chip label={option} size="small" variant="outlined" {...getTagProps({index})} key={index} />;
                })
              }
              value={filters.opponents}
              onChange={(_, v) =>
                handleChange(
                  'opponents',
                  v?.map(({value}) => {
                    return value;
                  }),
                )
              }
            />
          </MuiBox>
          <MuiBox
            maxWidth={{xxs: '100%', xs: 320}}
            width={(theme) => ({xxs: '100%', xs: `calc(100% / ${!isStudent ? 5 : 4} - ${theme.spacing(4)})`})}
          >
            <AutocompleteDefault
              isLoading={tags.isLoading}
              label="Теги"
              limitTags={1}
              options={tags.data}
              value={filters.tags}
              onChange={(_, v) => handleChange('tags', v)}
            />
          </MuiBox>
          {!isStudent && (
            <MuiBox
              maxWidth={{xxs: '100%', xs: 320}}
              width={(theme) => ({xxs: '100%', xs: `calc(100% / ${!isStudent ? 5 : 4} - ${theme.spacing(4)})`})}
            >
              <AutocompleteDefault
                isLoading={students.isLoading}
                label="Игроки"
                limitTags={1}
                options={students.options}
                value={filters.students}
                onChange={(_, v) => handleChange('students', v)}
              />
            </MuiBox>
          )}
        </Stack>
        {!isStudent && (
          <MuiBox flexShrink="0">
            <Button
              disableElevation
              color="primary"
              startIcon={<Add />}
              variant="contained"
              width={{xxs: '100%', xs: 'auto'}}
              onClick={onClick}
            >
              Добавить
            </Button>
          </MuiBox>
        )}
      </Stack>
    </Box>
  );
};

export default Filters;
