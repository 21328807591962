import ExerciseItem from '@common/TrainingControl/ExerciseItem';
import Stack from '@ui/MUI/Stack';
import React from 'react';

const ExercisesBlock = ({exercises, isForm}) => {
  return (
    <Stack spacing={{sm: 4, xs: 4, xxs: 2}}>
      {exercises.map((exercise, index) => (
        <ExerciseItem
          {...exercise}
          isExpendable
          index={index}
          isForm={isForm}
          key={exercise.id}
          number={index + 1}
          p={{sm: 4, xs: 4, xxs: 2}}
        />
      ))}
    </Stack>
  );
};

export default ExercisesBlock;
