import ContentWrapper from '@common/ContentWrapper';
import Filters from '@common/Statistics/PlayerStatistics/Throwins/Filters';
import Metrics from '@common/Statistics/PlayerStatistics/Throwins/Metrics';
import ThrowinsMap from '@common/Statistics/PlayerStatistics/Throwins/ThrowinsMap';
import ThrowinsVideoTable from '@common/Statistics/PlayerStatistics/Throwins/ThrowinsVideoTable';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import {selectThrowinsFilters, selectThrowinsMap, selectThrowinsMetrics} from '@selectors/gameStatistics';
import {
  clearThrowinsFilters,
  clearThrowinsMap,
  clearThrowinsMetrics,
  loadThrowinsMap,
  loadThrowinsMetrics,
  setThrowinsFilters,
} from '@slices/statistics/throwins';
import {parseFormToGetValue} from '@src/helpers';
import {Stack} from '@ui/MUI';
import React, {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';

export default function ThrowinsTab({hasOT, weAtHome}) {
  const {statisticId} = useParams();
  const filters = useStore(selectThrowinsFilters);

  const statisticsIds = useMemo(() => (statisticId ? [statisticId] : []), [statisticId]);
  const requestFilters = useDeepMemo(() => parseFormToGetValue(filters), [filters]);

  useEffect(() => {
    window.ym(80266396, 'reachGoal', 'statistika_vbrasyvaniya');
  }, []);

  return (
    <Stack direction="column" spacing={1}>
      <ContentWrapper>
        <Filters
          cleanup={clearThrowinsFilters}
          getter={selectThrowinsFilters}
          hasOT={hasOT}
          setter={setThrowinsFilters}
          statisticsIds={statisticsIds}
        />
      </ContentWrapper>

      <ContentWrapper>
        <Metrics
          cleanup={clearThrowinsMetrics}
          filters={requestFilters}
          getter={selectThrowinsMetrics}
          loader={loadThrowinsMetrics}
          statisticsIds={statisticsIds}
          weAtHome={weAtHome}
        />
        <ThrowinsMap
          cleanup={clearThrowinsMap}
          filters={requestFilters}
          getter={selectThrowinsMap}
          loader={loadThrowinsMap}
          statisticsIds={statisticsIds}
          weAtHome={weAtHome}
        />
      </ContentWrapper>
      <ThrowinsVideoTable getter={selectThrowinsMap} />
    </Stack>
  );
}
