import PassesTab from '@common/Statistics/PlayerStatistics/Tabs/PassesTab';
import PropTypes from 'prop-types';
import React from 'react';

import {useLoadPlayersStatisticsData} from '../../hooks';

function Passes({tab, filter, matchFilter}) {
  const statisticsTab = useLoadPlayersStatisticsData({tab, filter, matchFilter});

  const {isLoad, isLoading, data, hasError, hasMatchFilter} = statisticsTab;

  return <PassesTab {...{isLoad, isLoading, data, hasError, hasMatchFilter}} />;
}

Passes.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default Passes;
