import PlaceholderWrapper from '@common/FullHeightWrapper';
import ReactTableComponent from '@common/ReactTableComponent';
import {mock} from '@components/apps/EditStats/components/EditStatsTable/mock';
import date from '@helpers/date';
import useElementSize from '@hooks/useElementSize';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useMediaQuery} from '@mui/material';
import {longDash} from '@src/constants';
import {Box, Chip, IconButton, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Pagination from '@ui/MUI/Pagination';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {Wrapper} from './EditStatsTable';

const EditStatsTable = ({filters, selectedPage, onPaginationChange}) => {
  const history = useHistory();
  const {loading, ...statsList} = useSelector((state) => state.stats.filesList);

  const [paginationRef, {height}] = useElementSize();
  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const columns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Дата',
        sticky: {left: true},
        cell: ({getValue}) => (
          <Typography isLoading={loading} preloaderWidthBetween={[50, 70]} variant="body2">
            {date(getValue()).format('DD.MM.YYYY')}
          </Typography>
        ),
      },
      {
        accessorKey: 'team',
        header: 'Команда',
        cell: ({getValue}) => (
          <Typography isLoading={loading} preloaderWidthBetween={[80, 120]} variant="body2">
            {getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: 'opponent',
        header: 'Соперник',
        cell: ({getValue}) => (
          <Typography isLoading={loading} preloaderWidthBetween={[80, 120]} variant="body2">
            {getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: 'score',
        header: 'Счет',
        cell: ({getValue}) => (
          <Typography isLoading={loading} preloaderWidthBetween={[30, 50]} variant="body2">
            {getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: 'author',
        header: 'Автор',
        cell: ({getValue}) => (
          <Typography isLoading={loading} preloaderWidthBetween={[30, 50]} variant="body2">
            {getValue()?.name}
          </Typography>
        ),
      },
      {
        accessorKey: 'updatedAt',
        header: 'Дата загрузки',
        cell: ({getValue}) => (
          <Typography isLoading={loading} preloaderWidthBetween={[50, 70]} variant="body2">
            {date(getValue()).format('DD.MM.YYYY')}
          </Typography>
        ),
      },
      {
        id: 'fileName',
        accessorFn: ({fileName, link}) => ({fileName, link}),
        header: 'Файл',
        cellStyle: {py: 0},
        cell: ({getValue}) => {
          const {fileName, link} = getValue();
          return fileName && link ? (
            <Chip
              clickable
              component="a"
              href={link}
              icon={<DescriptionOutlinedIcon fontSize="small" />}
              isLoading={loading}
              label={fileName}
              preloaderWidthBetween={[100, 130]}
              variant="outlined"
            />
          ) : (
            longDash
          );
        },
      },
      {
        accessorKey: 'linkToVideo',
        header: 'Видео игры',
        cell: ({getValue}) => {
          const value = getValue();
          return value ? <CheckOutlinedIcon sx={{fill: grey['54']}} /> : longDash;
        },
      },
      {
        accessorKey: 'linkToVideoHighlights',
        header: 'Хайлайты',
        cell: ({getValue}) => {
          const value = getValue();
          return value ? <CheckOutlinedIcon sx={{fill: grey['54']}} /> : longDash;
        },
      },
      {
        id: 'manage',
        accessorFn: (data) => data,
        header: ' ',
        sticky: {right: !isSmResolution},
        cellStyle: {py: 0, boxShadow: `inset 1px -1px 0 0 ${grey[12]}`},
        cell: ({getValue}) => {
          const {statisticId, id} = getValue();
          return (
            <Stack direction="row" spacing={1}>
              <IconButton disabled={loading} onClick={() => history.push(`/edit_stats/${statisticId}`)}>
                <EditOutlinedIcon fontSize="inherit" />
              </IconButton>
              <IconButton disabled={loading} onClick={() => history.push(`/edit_stats/watch/${id}`)}>
                <VisibilityIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          );
        },
      },
    ],
    [loading, isSmResolution],
  );

  const pagesCount = useMemo(() => {
    const filesCount = statsList?.total || 0;
    if (filesCount > 0) {
      return Math.ceil(filesCount / 20);
    }
    return 1;
  }, [statsList?.total]);

  return (
    <Wrapper>
      {(loading || statsList?.total) > 0 && filters?.group?.value ? (
        <Box borderRadius="6px" overflow="hidden">
          <ReactTableComponent
            isAvailableHeight
            bottomBlockHeight={height}
            columns={columns}
            data={loading ? mock : statsList?.result || []}
            tableBodyCellProps={{
              sx: {
                py: {xxs: 1, sm: 3},
                px: {xxs: 2, sm: 4},
                height: 'auto !important',
                position: 'relative',
                boxShadow: `inset 0 -1px 0 0 ${grey[12]}`,
              },
            }}
            tableHeaderCellProps={{
              sx: {
                py: {xxs: 1, sm: 3},
                px: {xxs: 2, sm: 4},
                height: 'auto !important',
                boxShadow: `inset 0 -1px 0 0 ${grey[12]}`,
              },
            }}
          />
          {pagesCount > 1 && onPaginationChange && (
            <Box borderTop={`1px solid ${grey['12']}`} ref={paginationRef} sx={{transform: 'translateY(-1px)'}}>
              <Pagination count={pagesCount} page={selectedPage} onChange={onPaginationChange} />
            </Box>
          )}
        </Box>
      ) : (
        <PlaceholderWrapper
          alignItems="center"
          data-testid="no-filters"
          display="flex"
          justifyContent="center"
          px={{xxs: 2, sm: 4}}
          py={{xxs: 1, sm: 3}}
        >
          <Typography variant="subtitle1">
            {filters?.school && filters?.group && filters?.season
              ? 'Отсутствуют файлы статистики'
              : 'Выберите школу, команду и сезон.'}
          </Typography>
        </PlaceholderWrapper>
      )}
    </Wrapper>
  );
};

export default EditStatsTable;
