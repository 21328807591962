import React from 'react';

import AllStudentsPanel from '../admin/panels/AllStudentsPanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function AdminStudentsApp() {
  return (
    <ChessTemplate active={'students'}>
      <AllStudentsPanel titleVisible={true} />
    </ChessTemplate>
  );
}
