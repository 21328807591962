import {Stack, Typography} from '@ui/MUI';
import React from 'react';

const EstimateItemWithLabelHorizontal = ({
  label,
  children,
  spacing = '4px',
  labelVariant = 'body2',
  labelColor,
  contentColor,
  contentVariant = 'subtitle1',
}) => {
  const isSimpleChildren = typeof children === 'string' || typeof children === 'number';
  return (
    <Stack alignItems="center" direction="row" spacing={spacing}>
      <Typography color={labelColor} variant={labelVariant}>
        {label}
      </Typography>
      {!isSimpleChildren ? (
        children
      ) : (
        <Typography color={contentColor} variant={contentVariant}>
          {children}
        </Typography>
      )}
    </Stack>
  );
};

export default EstimateItemWithLabelHorizontal;
