import {getBodyMassIndexParamsForPlayerDashboard} from '@actions/BMIActions';
import {
  cleanCurrentReport,
  cleanUserReports,
  createReport,
  deleteCurrentReport,
  editCurrentReport,
  getCurrentReport,
  getUserReports,
} from '@actions/ScoutReportActions';
import NoDataFullHeight from '@common/NoDataFullHeight';
import ReactTableComponent from '@common/ReactTableComponent';
import {reportsMock} from '@common/ScoutReport/UserReportList/mock';
import {getRoleLabel} from '@helpers/roles';
import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectBMIParamsForPlayer} from '@selectors/bodyMassIndex';
import {longDash, ROLE_MODEL_ASSOCIATIONS} from '@src/constants';
import {Button, Typography} from '@ui/MUI';
import TablePaginationDefault from '@ui/MUI/TablePagination';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {Wrapper} from '../CommonScoutReport';
import ReportCreateModal from '../ReportCreateModal';
import ReportViewModal from '../ReportViewModal';
import {AuthorWrapper} from './UserReportList';

function UserReportList({reportType, isOpen, setIsOpen, userId, perView = 8, wrpperProps = {}}) {
  const dispatch = useStoreDispatch();
  const {reports, report} = useSelector(({scoutReport}) => scoutReport, shallowEqual);
  const [page, setPage] = useState(1);
  const [isViewModalOpen, setIsViewModalOpen] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    if (userId && reportType) {
      getReports();
      dispatch(getBodyMassIndexParamsForPlayerDashboard(userId));
    }
    return () => {
      dispatch(cleanUserReports());
    };
  }, [userId, reportType, page]);

  function getReports() {
    dispatch(getUserReports(userId, reportType, {limit: perView, page}));
  }
  const bmiParams = useStore(selectBMIParamsForPlayer);
  const {height, weight} = bmiParams;
  const [sorting, setSorting] = useState([{desc: true, id: 'date'}]);
  const columns = useMemo(() => {
    const isLoading = reports?.loading;
    return [
      {
        accessorKey: 'date',
        header: 'Дата',
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[70, 100]} variant="body2">
              {getValue()}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'roleModel',
        header: 'Ролевая модель',
        cell: ({getValue}) => {
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[100, 140]} variant="body2">
              {getValue()}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'weakSide',
        header: 'Слабые стороны',
        cell: ({getValue}) => {
          const value = getValue();
          return isLoading ? (
            <Typography isLoading={isLoading} preloaderWidthBetween={[100, 140]} variant="body2">
              {value}
            </Typography>
          ) : (
            value
          );
        },
        cellStyle: {
          maxWidth: 240,
        },
      },
      {
        accessorKey: 'author',
        header: 'Автор',
        cell: ({getValue}) => {
          const author = getValue();
          return !author?.firstName && !author?.lastName ? (
            'Не указан'
          ) : (
            <AuthorWrapper>
              <Typography
                isLoading={isLoading}
                preloaderWidthBetween={[90, 110]}
                variant="body2"
              >{`${author?.firstName} ${author?.lastName}`}</Typography>
              <Typography isLoading={isLoading} preloaderWidthBetween={[50, 60]} variant="caption2">
                {getRoleLabel(author?.userRole, author?.teacherRole)}
              </Typography>
            </AuthorWrapper>
          );
        },
      },
      {
        accessorKey: 'id',
        id: 'actionCell',
        header: '',
        cell: ({getValue}) => {
          const value = getValue();
          return (
            <Button
              color="secondary"
              disabled={!value || isLoading}
              variant="text"
              onClick={() => {
                dispatch(getCurrentReport(value));
                setIsViewModalOpen({...isViewModalOpen, right: !isViewModalOpen.right});
              }}
            >
              Смотреть
            </Button>
          );
        },
      },
    ];
  }, [reports?.loading]);
  return (
    <>
      <Wrapper isLoading={reports.loading} padding="0" {...wrpperProps}>
        {reports.loading || reports.data?.length ? (
          <>
            <ReactTableComponent
              columns={columns}
              data={
                reports?.loading
                  ? reportsMock
                  : reports?.data?.map(({createdAt, author, roleModel, weakSide, id}) => {
                      return {
                        id,
                        date: moment(createdAt).format('DD.MM.YYYY'),
                        author,
                        roleModel: ROLE_MODEL_ASSOCIATIONS[roleModel?.toUpperCase()] || longDash,
                        weakSide,
                        actions: id,
                      };
                    })
              }
              setSorting={setSorting}
              sorting={sorting}
              tableBodyCellProps={{
                sx: {
                  '&.lastRowCell': {boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset'},
                  boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset',
                },
              }}
              tableHeaderCellProps={{
                sx: {
                  boxShadow: 'rgb(224, 224, 224) 0px -1px 0px 0px inset',
                },
              }}
            />
            {reports?.total > perView && (
              <TablePaginationDefault
                count={reports?.total}
                page={page - 1}
                rowsPerPage={perView}
                rowsPerPageOptions={[]}
                onPageChange={(event, page) => setPage(page + 1)}
              />
            )}
          </>
        ) : (
          <NoDataFullHeight>Отчетов пока нет</NoDataFullHeight>
        )}
      </Wrapper>
      <ReportCreateModal
        isOpen={isOpen}
        reportType={reportType}
        setIsOpen={setIsOpen}
        studentData={{
          student: {...reports?.student, height, weight} || {},
          group: {...reports?.group} || {},
          school: {...reports?.school} || {},
        }}
        onCreate={(data, callback) => dispatch(createReport(data, callback, getReports, reportType))}
      />
      <ReportViewModal
        isOpen={isViewModalOpen}
        report={{...report, student: {...report?.student, height, weight}}}
        reportType={reportType}
        setIsOpen={() => setIsViewModalOpen({...isViewModalOpen, right: !isViewModalOpen.right})}
        onClose={() => {
          setIsViewModalOpen({...isViewModalOpen, right: !isViewModalOpen.right});
          dispatch(cleanCurrentReport());
        }}
        onDelete={(reportId) =>
          dispatch(
            deleteCurrentReport(reportId, () => {
              reports.data?.length === 1 && Number(page) > 1 ? setPage(page - 1) : getReports();
            }),
          )
        }
        onEdit={(reportId, data) => {
          return dispatch(editCurrentReport(reportId, data));
        }}
      />
    </>
  );
}

UserReportList.propTypes = {
  reportType: PropTypes.string.isRequired,
  isOpen: PropTypes.shape({
    top: PropTypes.bool,
    left: PropTypes.bool,
    bottom: PropTypes.bool,
    right: PropTypes.bool,
  }),
  setIsOpen: PropTypes.func,
  userId: PropTypes.string.isRequired,
};

export default UserReportList;
