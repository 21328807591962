import DayOrMonthFilter, {switchButtons} from '@common/DevelopmentJournal/components/DayOrMonthFilter';
import {usePlayerDiaryList} from '@common/DevelopmentJournal/hooks';
import {colors} from '@components/apps/DevelopmentJournal/components/PlayersDiary/components/QuestionnaireStatistics';
import Wrapper from '@components/apps/DevelopmentJournal/components/Wrapper';
import DevelopmentJournalBarChart from '@components/apps/DevelopmentJournalPlayer/components/DevelopmentJournalPlayerReport/components/DevelopmentJournalBarChart';
import date from '@helpers/date';
import {defaultSort} from '@helpers/index';
import useQueryString from '@hooks/useQueryString';
import {DEFAULT_DATE_SEND_FORMAT} from '@src/constants';
import {QUESTIONNAIRE_ITEMS, QUESTIONNAIRE_QUESTION_TYPES as QT, trainingQuestions} from '@src/constants/playerDiary';
import {Box, Typography} from '@ui/MUI';
import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router';

import MonthTable from './components/MonthTable';
import ReportTable from './components/ReportTable';

const DevelopmentJournalPlayerReport = () => {
  const {id} = useParams();
  const [qs, _] = useQueryString();
  const [datePickerValue, setDatePickerValue] = useState(qs?.reportDate);

  const defaultSelectedMonthInterval = [
    date(qs?.reportDate).startOf('month').format(DEFAULT_DATE_SEND_FORMAT),
    date(qs?.reportDate).endOf('month').format(DEFAULT_DATE_SEND_FORMAT),
  ];
  const [activeMonthInterval, setActiveMonthInterval] = useState(defaultSelectedMonthInterval);

  const [activeTypeOfView, setActiveTypeOfView] = useState('table');
  const [selectedViewType, setSelectedViewType] = useState(switchButtons?.[0]?.name);

  const isMonth = selectedViewType === 'month';

  useEffect(() => {
    if (!datePickerValue) {
      setDatePickerValue(date().innerDate.format(DEFAULT_DATE_SEND_FORMAT));
    }
  }, [datePickerValue]);
  const {data, isLoad, isLoading} = usePlayerDiaryList({
    filters: {interval: !isMonth ? [datePickerValue, datePickerValue] : activeMonthInterval},
    playersIds: [id],
  });
  const sortedData = [...data]?.sort((a, b) => {
    return defaultSort(a?.date, b?.date);
  });
  const morningColumns = useMemo(
    () =>
      [QT.sleep, QT.mood, QT.willToTraining]?.map(({label, value, questions}) => {
        return {
          accessorKey: value,
          accessorFn: (row) => {
            const isDayOff = row?.morning?.isDayOff;
            const text = isDayOff ? 'Выходной' : 'Не заполнено';
            return row?.morning?.[value]
              ? `${questions?.find((question) => question.value === row?.morning?.[value])?.value}. ${questions?.find((question) => question.value === row?.morning?.[value])?.label}`
              : text;
          },
          cell: ({getValue}) => {
            return (
              <Typography
                isLoading={isLoading}
                preloaderWidthBetween={[40, 50]}
                sx={{
                  position: 'relative',
                  pl: 3,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                variant="body2"
              >
                <Box
                  component="span"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    transform: 'translateY(-50%)',
                    display: 'block',
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: colors?.[getValue()?.split('.')?.length > 1 ? getValue()?.split('.')?.[0] : 0],
                  }}
                />{' '}
                {getValue()}
              </Typography>
            );
          },
          header: label,
        };
      }),
    [isLoading],
  );
  const groupTrainingColumns = useMemo(
    () =>
      trainingQuestions?.map(({label, value, questions}) => {
        return {
          accessorKey: value,
          header: label,
          accessorFn: (row) => {
            const isDayOff = row?.trainingGroup?.isDayOff;
            const text = isDayOff ? 'Выходной' : 'Не заполнено';
            return row?.trainingGroup?.[value]
              ? `${questions?.find((question) => question.value === row?.trainingGroup?.[value])?.value}. ${questions?.find((question) => question.value === row?.trainingGroup?.[value])?.label}`
              : text;
          },
          cell: ({getValue}) => {
            return (
              <Typography
                isLoading={isLoading}
                preloaderWidthBetween={[40, 50]}
                sx={{
                  position: 'relative',
                  pl: 3,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                variant="body2"
              >
                <Box
                  component="span"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    transform: 'translateY(-50%)',
                    display: 'block',
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: colors[getValue()?.split('.')?.length > 1 ? getValue()?.split('.')?.[0] : 0],
                  }}
                />{' '}
                {getValue()}
              </Typography>
            );
          },
        };
      }),
    [isLoading],
  );
  const individualTrainingColumns = useMemo(
    () =>
      trainingQuestions?.map(({label, value, questions}) => {
        return {
          accessorKey: value,
          header: label,
          accessorFn: (row) => {
            const isDayOff = row?.trainingIndividual?.isDayOff;
            const text = isDayOff ? 'Выходной' : 'Не заполнено';
            return row?.trainingIndividual?.[value]
              ? `${questions?.find((question) => question?.value === row?.trainingIndividual?.[value])?.value}. ${questions?.find((question) => question?.value === row?.trainingIndividual?.[value])?.label}`
              : text;
          },
          cell: ({getValue}) => {
            return (
              <Typography
                sx={{
                  position: 'relative',
                  pl: 3,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                variant="body2"
              >
                <Box
                  component="span"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    transform: 'translateY(-50%)',
                    display: 'block',
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: colors[getValue()?.split('.')?.length > 1 ? getValue()?.split('.')?.[0] : 0],
                  }}
                />{' '}
                {getValue()}
              </Typography>
            );
          },
        };
      }),
    [isLoading],
  );
  const gameColumns = useMemo(
    () =>
      [
        QT.activity,
        QT.stress,
        QT.health,
        QT.attention,
        QT.fellingIceAndGoal,
        QT.comunicationWithTrainer,
        QT.selfScore,
      ]?.map(({label, value, questions}) => {
        return {
          accessorKey: value,
          header: () => (
            <Typography
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
              sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}
              variant="subtitle2"
            >
              {label}
            </Typography>
          ),
          accessorFn: (row) => {
            const isDayOff = row?.game?.isDayOff;
            const text = isDayOff ? 'Выходной' : 'Не заполнено';
            return row?.game?.[value]
              ? `${questions?.find((question) => question.value === row?.game?.[value])?.value}. ${questions?.find((question) => question.value === row?.game?.[value])?.label}`
              : text;
          },
          cell: ({getValue}) => {
            return (
              <Typography
                isLoading={isLoading}
                preloaderWidthBetween={[40, 50]}
                sx={{
                  position: 'relative',
                  pl: 3,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                variant="body2"
              >
                <Box
                  component="span"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    transform: 'translateY(-50%)',
                    display: 'block',
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: colors[getValue()?.split('.')?.length > 1 ? getValue()?.split('.')?.[0] : 0],
                  }}
                />{' '}
                {getValue()}
              </Typography>
            );
          },
          headerStyle: {
            maxWidth: 165,
            whiteSpace: 'nowrap !important',
            textOverflow: 'ellipsis !important',
          },
          cellStyle: {
            maxWidth: 165,
          },
        };
      }),
    [isLoading],
  );

  const isDayOff = data?.[0]?.morning?.isDayOff || false;

  return (
    <>
      <DayOrMonthFilter
        activeMonthInterval={activeMonthInterval}
        activeTypeOfView={activeTypeOfView}
        datePickerValue={datePickerValue}
        isTypeOfViewShowed={isMonth}
        selectedViewType={selectedViewType}
        setActiveMonthInterval={setActiveMonthInterval}
        setActiveTypeOfView={setActiveTypeOfView}
        setDatePickerValue={setDatePickerValue}
        setSelectedViewType={setSelectedViewType}
      />
      {activeTypeOfView === 'table' ? (
        <>
          {!isMonth ? (
            <>
              {!isDayOff ? (
                <Box
                  borderRadius="6px"
                  mb={{xxs: 2, sm: 3}}
                  overflow="hidden"
                  sx={{'& > .TableWrapper': {borderRadius: '0px'}}}
                >
                  <ReportTable
                    columns={morningColumns}
                    comment={data?.[0]?.morning?.comment}
                    data={data}
                    isLoading={isLoading}
                    isNoData={!data?.[0]?.morning}
                    title="Утро"
                  />
                  <ReportTable
                    columns={groupTrainingColumns}
                    comment={data?.[0]?.trainingGroup?.comment}
                    data={data}
                    isLoading={isLoading}
                    isNoData={!data?.[0]?.trainingGroup}
                    title="Групповая тренировка"
                  />
                  <ReportTable
                    columns={individualTrainingColumns}
                    comment={data?.[0]?.trainingIndividual?.comment}
                    data={data}
                    isLoading={isLoading}
                    isNoData={!data?.[0]?.trainingIndividual}
                    title="Индивидуальная тренировка"
                  />
                  <ReportTable
                    columns={gameColumns}
                    comment={data?.[0]?.game?.comment}
                    data={data}
                    isLoading={isLoading}
                    isNoData={!data?.[0]?.game}
                    title="Игра"
                  />
                </Box>
              ) : (
                <Box
                  alignItems="center"
                  backgroundColor="#fff"
                  borderRadius="6px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  mb={{xxs: 2, sm: 3}}
                  minHeight={610}
                  overflow="hidden"
                >
                  <Typography mb={1} variant="body1">
                    Игрок отметил {date(datePickerValue)?.format('DD.MM.YYYY')} как
                  </Typography>
                  <Typography textTransform="uppercase" variant="h4">
                    выходной
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <MonthTable data={sortedData} isLoad={isLoad} isLoading={isLoading} isNoData={!data?.length} />
          )}
        </>
      ) : (
        QUESTIONNAIRE_ITEMS?.map(({id, title, questions}) => {
          return (
            <Wrapper key={id} mb={{xxs: 2, sm: 3}} overflow="unset" p={4}>
              <Typography display="block" mb={6} variant="overline0">
                {title}
              </Typography>
              <DevelopmentJournalBarChart
                data={sortedData}
                id={id}
                isLoading={isLoading}
                key={id}
                questions={questions}
              />
            </Wrapper>
          );
        })
      )}
    </>
  );
};

export default DevelopmentJournalPlayerReport;
