import PlaceholderWrapper from '@common/FullHeightWrapper';
import Filters from '@components/apps/DevelopmentJournal/components/Filters';
import Table from '@components/apps/DevelopmentJournal/components/PlayersDiary/components/Table';
import Wrapper from '@components/apps/DevelopmentJournal/components/Wrapper';
import date from '@helpers/date';
import {useGroup, useSchool} from '@hooks/filters';
import useDeepEffect from '@hooks/useDeepEffect';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {selectPlayerDiaryReport} from '@selectors/playerDiary';
import {loadPlayerDiaryOverall} from '@slices/playerDiary';
import {Box, IconButton, Stack, Typography} from '@ui/MUI';
import React, {useEffect, useState} from 'react';

import {CURRENT_MONTH_INTERVAL} from './constants';

function PlayersDiary() {
  const [filters, setFilters] = useState({
    interval: [],
  });
  useEffect(() => {
    setFilters({...filters, interval: CURRENT_MONTH_INTERVAL});
  }, []);
  const school = useSchool();
  const group = useGroup({connectorValue: filters?.school?.value});
  const dispatch = useStoreDispatch();
  const {data, isLoad, isLoading, error} = useStore(selectPlayerDiaryReport);
  const [qs, setQs] = useQueryString();
  useEffect(() => {
    if (group?.value?.value) {
      setQs({...qs, group: group?.value?.value});
    }
  }, [group?.value?.value]);

  useDeepEffect(() => {
    if (filters?.interval?.length > 1 && filters?.group?.value) {
      const [startDate, endDate] = filters.interval;
      dispatch(
        loadPlayerDiaryOverall({startDate, endDate, groupId: filters?.group?.value, type: filters?.type?.value}),
      );
    }
  }, [filters]);

  useDeepEffect(() => {
    if (school?.options?.length === 1) {
      setFilters((prevState) => ({...prevState, school: school.options?.[0]}));
    }
    if (qs?.school) {
      setFilters((prevState) => ({
        ...prevState,
        school: school.options?.find((option) => option?.value === qs?.school),
      }));
    }
  }, [qs?.school, school.options]);

  useDeepEffect(() => {
    if (group?.options?.length === 1) {
      setFilters((prevState) => ({...prevState, group: group.options?.[0]}));
    }
    if (qs?.group) {
      setFilters((prevState) => ({
        ...prevState,
        group: group.options?.find((option) => option?.value === qs?.group),
      }));
    }
  }, [qs?.group, group.options]);
  return (
    <Stack spacing={1}>
      <Filters
        filters={[
          {
            label: 'Школа',
            value: 'school',
            multiple: false,
            disabled: school?.isLoading || school?.options?.length === 1,
            isLoading: school?.isLoading,
            options: school?.options,
            onChange() {
              setFilters((prevState) => ({...prevState, group: null}));
            },
          },
          {
            label: 'Команда',
            value: 'group',
            multiple: false,
            disabled: !filters?.school || school?.isLoading || group?.isLoading || group?.options?.length === 1,
            isLoading: school?.isLoading || group?.isLoading,
            options: group?.options,
          },
          {
            label: 'Тип анкеты',
            value: 'type',
            multiple: false,
            options: [
              {value: 'morning', label: 'Утро'},
              {value: 'game', label: 'Игра'},
              {value: 'trainingGroup', label: 'Тренировка групповая'},
              {value: 'trainingIndividual', label: 'Тренировка индивидуальная'},
            ],
          },
        ]}
        value={filters}
        onChange={setFilters}
      />
      <Stack direction="column" spacing={1}>
        <Wrapper sx={{borderRadius: '6px 6px 0 0'}}>
          <Box display="flex" justifyContent="space-between">
            <Box alignItems="center" display="flex">
              <IconButton
                color="primary"
                sx={{p: '0px', minWidth: 24}}
                onClick={() => {
                  const prevMonthNumber = Number(date(filters?.interval?.[0])?.format('M')) - 1;
                  const prevYear = Number(date(filters?.interval?.[0])?.format('YYYY')) - 1;
                  const prevDate = {
                    monthNumber: prevMonthNumber < 1 ? 12 : Number(date(filters?.interval?.[0])?.format('M')) - 1,
                    year: prevMonthNumber < 1 ? prevYear : Number(date(filters?.interval?.[0])?.format('YYYY')),
                  };
                  setFilters({
                    ...filters,
                    interval: [
                      `${prevDate.year}-${date(prevDate?.monthNumber, 'M').format('MM')}-01`,
                      `${prevDate.year}-${date(prevDate?.monthNumber, 'M').format('MM')}-${date(
                        prevDate?.monthNumber,
                        'M',
                      )
                        .endOf('month')
                        ?.format('DD')}`,
                    ],
                  });
                }}
              >
                <ChevronLeftIcon sx={{'&:nth-of-type(1)': {fontSize: '24px !important'}}} />
              </IconButton>
              <Typography sx={{px: 2}} variant="h4">
                {date(filters?.interval?.[0]).format('MMMM YYYY')}
              </Typography>
              <IconButton
                color="primary"
                sx={{p: '0px', minWidth: 24}}
                onClick={() => {
                  const nextMonthNumber = Number(date(filters?.interval?.[0]).format('M')) + 1;
                  const nextYear = Number(date(filters?.interval?.[0]).format('YYYY')) + 1;
                  const nextDate = {
                    monthNumber: nextMonthNumber > 12 ? 1 : Number(date(filters?.interval?.[0]).format('M')) + 1,
                    year: nextMonthNumber > 12 ? nextYear : Number(date(filters?.interval?.[0]).format('YYYY')),
                  };
                  setFilters({
                    ...filters,
                    interval: [
                      `${nextDate.year}-${date(nextDate?.monthNumber, 'M').format('MM')}-${date(
                        nextDate?.monthNumber,
                        'M',
                      )
                        .startOf('month')
                        ?.format('DD')}`,
                      `${nextDate.year}-${date(nextDate?.monthNumber, 'M').format('MM')}-${date(
                        nextDate?.monthNumber,
                        'M',
                      )
                        .endOf('month')
                        ?.format('DD')}`,
                    ],
                  });
                }}
              >
                <ChevronRightIcon sx={{'&:nth-of-type(1)': {fontSize: '24px !important'}}} />
              </IconButton>
            </Box>
          </Box>
        </Wrapper>
        {error || !filters?.group?.value ? (
          <PlaceholderWrapper
            alignItems="center"
            data-testid="no-filters"
            display="flex"
            justifyContent="center"
            px={{xxs: 2, sm: 4}}
            py={{xxs: 1, sm: 3}}
          >
            <Typography variant="subtitle1">
              {error ? 'Ошибка загрузки журнала' : 'Заполните фильтры, чтобы увидеть данные'}
            </Typography>
          </PlaceholderWrapper>
        ) : (
          <Table data={!data?.length ? [] : data} isLoad={isLoad} isLoading={isLoading} />
        )}
      </Stack>
    </Stack>
  );
}

export default PlayersDiary;
