import React from 'react';

function BlackMan() {
  return (
    <React.Fragment>
      <g clipPath="url(#clip11)">
        <path d="M12 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 7h-6v13h-2v-6h-2v6H9V9H3V7h18v2z" />
      </g>
      <defs>
        <clipPath id="clip11">
          <rect fill="white" height="30" width="30" />
        </clipPath>
      </defs>
    </React.Fragment>
  );
}
export default BlackMan;
