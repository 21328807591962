import ReactTableComponent from '@common/ReactTableComponent';
import ToggleBlock from '@common/ToggleBlock';
import QuestionnaireDrawer from '@components/apps/DevelopmentJournal/components/PlayersDiary/components/QuestionnaireDrawer';
import Wrapper from '@components/apps/DevelopmentJournal/components/Wrapper';
import DonutChart from '@components/charts/DonutChart';
import useDeepMemo from '@hooks/useDeepMemo';
import {longDash} from '@src/constants';
import {QUESTIONNAIRE_QUESTION_TYPES} from '@src/constants/playerDiary';
import {pluralize} from '@src/helpers';
import {Box, Button, Divider, Stack, Typography} from '@ui/MUI';
import {blue, darkRed, green, grey, purple, yellow} from '@ui/MUI/colors';
// eslint-disable-next-line
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

QuestionnaireStatistics.propTypes = {
  title: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  dateInterval: PropTypes.array.isRequired,
};

export const colors = [grey[12], purple[100], darkRed[100], yellow[100], blue[100], green[100]];

function addColorsKey(arr = []) {
  return [...cloneDeep(arr)].reverse()?.map((item) => ({
    ...item,
    label: item?.value === 0 ? 'Не заполнили' : `${item.value}. ${item.label}`,
    color: colors?.[item?.value || 0],
  }));
}

function QuestionnaireStatistics({
  title = 'Анкета. Утро',
  questions = [
    QUESTIONNAIRE_QUESTION_TYPES.sleep,
    QUESTIONNAIRE_QUESTION_TYPES.mood,
    QUESTIONNAIRE_QUESTION_TYPES.willToTraining,
  ],
  data = {},
  isLoading,
  dateInterval,
}) {
  const [currentQuestions, setCurrentQuestions] = useState({
    ...questions[0],
    questions: addColorsKey(questions[0].questions),
  });
  const [showDetailsTable, setShowDetailsTable] = useState(false);
  const [displayUserAnswers, setDisplayUserAnswers] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const formattedData = useMemo(() => {
    return [
      ...(data?.[currentQuestions.value] || []),
      {
        count: [...(data?.notCompleted || []), ...(data?.dayOffPlayers || [])].length || 0,
        score: 0,
        players: [...(data?.notCompleted || []), ...(data?.dayOffPlayers || [])],
      },
    ];
  }, [data, currentQuestions?.value]);

  const columns = useMemo(
    () => [
      {
        header: '',
        accessorKey: 'score',
        sticky: {left: true},
        cellStyle: {
          verticalAlign: 'baseline',
        },
        cell: ({getValue}) => {
          const value = getValue();
          return (
            <Typography pl={!value ? '0px' : 4} position="relative" variant="subtitle2">
              {value ? (
                <>
                  <Circle bgc={[...colors]?.[value]} />
                  {value}
                </>
              ) : (
                'Не заполнили'
              )}
            </Typography>
          );
        },
      },
      {
        header: '',
        accessorKey: 'count',
        cellStyle: {
          verticalAlign: 'baseline',
        },
        cell: ({getValue}) => {
          const value = getValue();
          return (
            <Typography variant="body2">{`${value} ${pluralize(value, ['игрок', 'игрока', 'игроков'])}`}</Typography>
          );
        },
      },
      {
        header: '',
        accessorKey: 'players',
        cellStyle: {
          minWidth: 320,
          maxWidth: {xxs: 'unset', sm: '100%'},
          whiteSpace: 'pre-line',
        },
        cell: ({getValue}) => {
          const value = getValue();
          return (
            <Stack direction="row" flexWrap="wrap" gap={2}>
              {value?.map((item, index) => (
                <Typography
                  key={index}
                  sx={{textDecoration: 'underline', cursor: 'pointer', '&:hover': {textDecoration: 'none'}}}
                  variant="body2"
                  onClick={() => {
                    setSelectedUser(item);
                    setDisplayUserAnswers(true);
                  }}
                >
                  {item.lastName} {item.firstName}
                </Typography>
              )) || longDash}
            </Stack>
          );
        },
      },
    ],
    [setDisplayUserAnswers],
  );

  const rows = useMemo(
    () => formattedData.map((item) => ({...item})).sort((a, b) => b?.score - a?.score),
    [formattedData],
  );

  const options = useDeepMemo(
    () => ({
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          type: 'pie',
          radius: ['45%', '95%'],
          avoidLabelOverlap: false,
          labelLine: {
            show: false,
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
          data: formattedData.map((item) => ({
            id: item?.score,
            value: item?.count,
            itemStyle: {
              color: colors?.[item?.score || 0],
            },
            label: {
              formatter: ({value}) => {
                return value;
              },
              position: 'inside',
              fontFamily: 'Proxima Nova',
              fontSize: '16px',
              textBorderColor: '#fff',
              textBorderWidth: 1,
              color: '#000',
              fontWeight: 600,
            },
            tooltip: {
              position: 'inside',
              valueFormatter: () =>
                currentQuestions?.questions?.find((question) => question?.value === item?.score)?.label ||
                'Не заполнили',
            },
          })),
          showEmptyCircle: true,
        },
      ],
    }),
    [currentQuestions.value, data],
  );
  useEffect(() => {
    if (isLoading && showDetailsTable) {
      setShowDetailsTable(false);
    }
  }, [isLoading, showDetailsTable]);

  return (
    <Wrapper overflow="unset" p={4}>
      <Typography display="block" mb={6} variant="overline0">
        {title}
      </Typography>
      <Stack alignItems="center" direction="column" spacing={4}>
        <Stack direction="row" width="100%">
          <Box width="50%">
            <DonutChart
              hideHistory
              height={250}
              options={options}
              style={{
                minHeight: 250,
                maxHeight: 400,
              }}
              width="100%"
            />
          </Box>
          <Box overflow="hidden" width="50%">
            {currentQuestions?.questions?.map(({label, color}, index) => {
              return (
                <Stack direction="row" key={index} mb={2} pl={4} position="relative" spacing={2}>
                  <Circle bgc={color} />
                  <Typography
                    isLoading={isLoading}
                    ml="0px !important"
                    preloaderWidthBetween={[100, 200]}
                    variant="body2"
                  >
                    {label}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        </Stack>
        <Stack direction="row" flexWrap="wrap" justifyContent="center" maxWidth="80%">
          {questions.map((item, index) => {
            const {value, label, questions} = item;
            const isActive = currentQuestions?.value === value;
            return (
              <Button
                color={isActive ? 'primary' : 'secondary'}
                disabled={isLoading}
                key={index}
                size="small"
                variant={isActive ? 'contained' : 'text'}
                onClick={() => {
                  setCurrentQuestions({
                    ...item,
                    questions: addColorsKey(questions),
                  });
                }}
              >
                {label}
              </Button>
            );
          })}
        </Stack>
      </Stack>
      <Divider sx={{mt: 4, mb: 3}} />
      <ToggleBlock
        closeText="Детальная информация"
        contentProps={{width: '100%', mt: 3, pb: '0 !important'}}
        disabled={isLoading}
        isOpen={showDetailsTable}
        setIsOpen={setShowDetailsTable}
        showText="Детальная информация"
        wrapperProps={{display: 'flex', flexDirection: 'column-reverse', alignItems: 'flex-start'}}
      >
        {!isLoading && (
          <Box border={`1px solid ${grey['12']}`} borderRadius="6px" overflow="hidden">
            <ReactTableComponent
              columns={columns}
              data={rows}
              displayHeader={false}
              tableBodyCellProps={{
                sx: {
                  py: {xxs: 1, sm: 3},
                  px: {xxs: 2, sm: 4},
                  height: 'auto !important',
                },
              }}
            />
          </Box>
        )}
      </ToggleBlock>
      <QuestionnaireDrawer
        dateInterval={dateInterval}
        open={displayUserAnswers}
        player={selectedUser}
        onClose={(state) => {
          setSelectedUser(null);
          setDisplayUserAnswers(state);
        }}
      />
    </Wrapper>
  );
}

export default QuestionnaireStatistics;

const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  background-color: ${({bgc}) => bgc};
`;
