import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import * as groupsActions from '../../../redux/actions/GroupsActions';
import AboutGroup from '../../groups/components/group/about-group/index';
import PlayersComposition from '../../groups/components/group/players-composition/index';
import TabsDefault from '../../ui/MUI/Tabs/index';
import GroupScoutReport from '../components/group/GroupScoutReport';

export default function GroupPanel(props) {
  let {id} = props;

  const dispatch = useDispatch();

  const group = useSelector((state) => state.groups.group);
  const loading = useSelector((state) => state.groups.loading);
  useEffect(() => {
    if (id) {
      dispatch(groupsActions.getGroupById(id));
    }
  }, [id]);

  const groupsTabs = {
    headlineItems: [{label: 'О команде'}, {label: 'Состав'}, {label: 'СКАУТ-РЕПОРТ'}],
    contentItems: [
      <AboutGroup group={group} key={0} loading={loading} />,
      <PlayersComposition group={group} key={1} loading={loading} />,
      <GroupScoutReport groupId={group?.id} key={4} />,
    ],
  };

  return (
    <Wrapper>
      <PageTitleDefault>{group?.name ? group.name : 'Команда'}</PageTitleDefault>
      <TabsDefault ariaLabel="group's tabs" tabs={groupsTabs} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .MuiTabs {
    &-root {
      box-shadow: 0 0 0 transparent;
    }
  }

  .MuiTab {
    &-root {
      margin-left: 0;
      text-align: left;

      &:not(:last-child) {
        margin-right: 24px;
      }

      color: white;

      &:hover {
        color: white;
        opacity: 0.8;
      }

      &.Mui-selected {
        color: white;
      }
    }
  }
`;
