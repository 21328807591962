import * as types from '../ActionTypes.js';

const initialState = {
  injuredPlayers: {
    data: [],
    isLoading: false,
    isError: false,
  },
  psychoDashboard: {
    data: [],
    isLoading: false,
    isError: false,
  },
  playersRating: {
    data: [],
    isLoading: false,
    isError: false,
    selectedGroupId: null,
  },
};

const PsychoProfileReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.PSYCHO_DASHBOARD_WIDGET_UPDATE:
      return {
        ...state,
        [action.payload.name]: {
          isLoading: action.payload.isLoading,
          data: action.payload.data || [],
          isError: action.payload.isError,
          selectedGroupId: action.payload.selectedGroupId,
        },
      };
    default:
      return state;
  }
};

export default PsychoProfileReducer;
