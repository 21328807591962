import {StudentSubroles, TeacherSubroles, UserRoles} from '@src/types';
import {darkRed, grey} from '@ui/MUI/colors';

export const playerStatus = {
  available: 'Доступен',
  notavailable: 'Недоступен',
  injury: 'Травмирован',
  illness: 'Болен',
};
export const playerHandFullNames = {
  right: 'правый',
  left: 'левый',
};
export const defensemenPositions = ['лз', 'пз'];
export const wingerPositions = ['лн', 'цн', 'пн'];

export const ROOMS = [
  {
    id: 'RnanlyjQOi',
    timestamp: 1598530673070,
    updatedTimestamp: 1606157467056,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Большая арена',
    description: '',
  },
  {
    id: 'PbTHwI0YJW',
    timestamp: 1598531721609,
    updatedTimestamp: 1606157467055,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Малая арена',
    description: '',
  },
  {
    id: 'bjjYTrM0H4',
    timestamp: 1598535488162,
    updatedTimestamp: 1598535741494,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Хокк.Бег.Дорожка',
    description: '',
  },
  {
    id: 'YnPUPiw7y1',
    timestamp: 1598535505070,
    updatedTimestamp: 1629797320167,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Зал тренажерный',
    description: '',
  },
  {
    id: '6CuPCIvPCd',
    timestamp: 1598535512878,
    updatedTimestamp: 1629805733572,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Зал №3017 – КРФК',
    description: '',
  },
  {
    id: 'YBj0XrJtWe',
    timestamp: 1598535526490,
    updatedTimestamp: 1598535771783,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Инд. подготовки',
    description: '',
  },
  {
    id: 'sOtk3Uzwj9',
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Кабинет 207',
    description: '',
    _created_at: '2024-03-15 09:38:11',
    _updated_at: '2024-03-15 09:38:11',
  },
  {
    id: '1VQxx2Dje1',
    timestamp: 1598535537590,
    updatedTimestamp: 1629805708983,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Зал кардиоподготовки',
    description: '',
  },
  {
    id: 'QvUwFTZqfA',
    timestamp: 1606157467062,
    updatedTimestamp: 1629805724322,
    schoolId: 'ITfD4PL0oy',
    name: 'Зал №2052 – игровой',
    description: '',
  },
  {
    id: 'FrtBxDIgS0',
    timestamp: 1606157467062,
    updatedTimestamp: 1607957896223,
    schoolId: 'ITfD4PL0oy',
    name: 'Сауна',
  },
  {
    id: '6gEYj1xa4B',
    timestamp: 1607957887524,
    updatedTimestamp: 1607957896208,
    schoolId: 'ITfD4PL0oy',
    name: 'Зал теоретической подготовки',
  },
  {
    id: 'eH7FzwuH7V',
    timestamp: 1620140401943,
    updatedTimestamp: 1620140401943,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Кабинет 201',
    description: '',
  },
  {
    id: 'NIrZtOBThI',
    timestamp: 1629805746010,
    updatedTimestamp: 1629805746010,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Спорт площадка (улица)',
    description: '',
  },
  {
    id: 'QTLaWG5PUv',
    timestamp: 1632248144408,
    updatedTimestamp: 1632248144408,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Бассейн',
    description: '',
  },
  {
    id: 'YqCqThDIBn',
    timestamp: 1632248267588,
    updatedTimestamp: 1632248267588,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Кабинет 203',
    description: '',
  },
  {
    id: 'DwjcjpIXDm',
    timestamp: 1632248283141,
    updatedTimestamp: 1632248283141,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Кабинет 205',
    description: '',
  },
  {
    id: '3JGIkEZKCL',
    timestamp: 1632248299039,
    updatedTimestamp: 1632248299039,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Кабинет 209',
    description: '',
  },
  {
    id: 'PSMASQBfn6',
    timestamp: 1632248425448,
    updatedTimestamp: 1632248425448,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Конференц-зал',
    description: '',
  },
  {
    id: 'anA9fJd6Oc',
    timestamp: 1632248457431,
    updatedTimestamp: 1632248457431,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Колледж',
    description: '',
  },
  {
    id: '6D6vDWHUdV',
    timestamp: 1632248497786,
    updatedTimestamp: 1632248497786,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Пресс-центр',
    description: '',
  },
  {
    id: 'Ym11zDZHXI',
    timestamp: 1632248552933,
    updatedTimestamp: 1632248552933,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Столовая',
    description: '',
  },
  {
    id: 'XDtFyEPbSR',
    timestamp: 1632248583550,
    updatedTimestamp: 1632248583550,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Училище ОР',
    description: '',
  },
  {
    id: 'F0lkQFFzx3',
    timestamp: 1632248599093,
    updatedTimestamp: 1632248599093,
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    name: 'Школа 18',
    description: '',
  },
  {
    description: '',
    franchiseeId: 'H6KiRBmxLL',
    id: 'fcYADnciyQ',
    name: 'Медблок',
    schoolId: 'ITfD4PL0oy',
    timestamp: 1633676061232,
    updatedTimestamp: 1633676061232,
  },
  {
    description: '',
    franchiseeId: 'H6KiRBmxLL',
    id: 'cCi7HYAl5E',
    name: 'Бросковая зона',
    schoolId: 'ITfD4PL0oy',
    timestamp: 1635841929026,
    updatedTimestamp: 1635841929026,
  },
  {
    description: '',
    franchiseeId: 'H6KiRBmxLL',
    id: 'kv4C2oiCJZ',
    name: 'Хоккейный тренажер HDTS',
    schoolId: 'ITfD4PL0oy',
    timestamp: 1635842181810,
    updatedTimestamp: 1635842181810,
  },
  {
    description: '',
    franchiseeId: 'H6KiRBmxLL',
    id: 'kv4C2oiCQQ',
    name: 'Игровой зал (ДХЦ)',
    schoolId: 'ITfD4PL0oy',
    timestamp: 1635842181855,
    updatedTimestamp: 1635842181855,
  },
  {
    description: '',
    franchiseeId: 'H6KiRBmxLL',
    id: 'kv4C2oiCQR',
    name: 'Лёд (ДХЦ)',
    schoolId: 'ITfD4PL0oy',
    timestamp: 1635842181870,
    updatedTimestamp: 1635842181870,
  },
  {
    description: '',
    franchiseeId: 'H6KiRBmxLL',
    id: 'kv4C2oiCQT',
    name: 'Тренажёрный зал (ДХЦ)',
    schoolId: 'ITfD4PL0oy',
    timestamp: 1635842181890,
    updatedTimestamp: 1635842181890,
  },
  {
    id: 'Ud45khD98g',
    name: 'Кабинет 202',
    description: '',
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    timestamp: 1635842181900,
    updatedTimestamp: 1635842181900,
  },
  {
    id: 'Ve56liE89h',
    name: 'Кабинет 208',
    description: '',
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    timestamp: 1635842181895,
    updatedTimestamp: 1635842181895,
  },
  {
    id: 'lr454UxX7V',
    name: 'Кинозал (ДХЦ)',
    description: '',
    schoolId: 'zAsFb02i53',
    franchiseeId: 'H6KiRBmxLL',
    timestamp: 1635842181910,
    updatedTimestamp: 1635842181910,
  },
  {
    id: 'br454UxXzS',
    name: 'Арена G-Drive',
    description: '',
    schoolId: 'ITfD4PL0oy',
    franchiseeId: 'H6KiRBmxLL',
    timestamp: 1663740931,
    updatedTimestamp: 1663740931,
  },
];

export const roleOptions = [
  {label: 'Игрок', value: 'student'},
  {label: 'Менеджер', value: 'franchisee'},
  {label: 'Администратор школы', value: 'schools_admin'},
  {label: 'Администратор', value: 'admin'},
  {label: 'Тренер', value: 'teacher'},
  {label: 'Врач', value: 'doctor'},
  {label: 'Психолог', value: 'psycho'},
  {label: 'Родитель', value: 'parent'},
  {label: 'Скаут', value: 'scout'},
  {label: 'Видеоаналитик', value: 'video_analyst'},
  {label: 'Методист', value: 'methodist'},
];

export const rolesMap = {
  student: 'Игрок',
  franchisee: 'Менеджер',
  schools_admin: 'Администратор школы',
  admin: 'Администратор',
  teacher: 'Тренер',
  doctor: 'Врач',
  psycho: 'Психолог',
  parent: 'Родитель',
  scout: 'Скаут',
  video_analyst: 'Видеоаналитик',
  methodist: 'Методист',
};

export const HAND_MAP = {
  left: 'Левый',
  right: 'Правый',
};

export const HAND_OPTIONS = [
  {
    value: 'left',
    label: 'Левый',
  },
  {
    value: 'right',
    label: 'Правый',
  },
];

export const HOCKEY_ROLES_ENUM = {
  winger: 'winger',
  goaltender: 'goaltender',
  defenseman: 'defenseman',
};

export const HOCKEY_ROLES = {
  [HOCKEY_ROLES_ENUM.winger]: 'Нападающий',
  [HOCKEY_ROLES_ENUM.goaltender]: 'Вратарь',
  [HOCKEY_ROLES_ENUM.defenseman]: 'Защитник',
};

export const AMPLUA_OPTIONS = [
  {
    value: 'defenseman',
    label: 'Защитник',
  },
  {
    value: 'goaltender',
    label: 'Вратарь',
  },
  {
    value: 'winger',
    label: 'Нападающий',
  },
];

export const SCOUT_REPORT_TYPES = {
  inner: 'inner',
  outer: 'outer',
};

export const POTENTIAL_ASSOCIATIONS = {
  'A++': 'Звезда НХЛ (A++)',
  'A+': 'Топ 6/4 НХЛ (A+)',
  A: 'Игрок НХЛ (A)',
  'B++': 'Звезда КХЛ/Игрок НХЛ (B++)',
  'B+': 'Топ 6/4 КХЛ (B+)',
  B: 'Игрок КХЛ (B)',
  'C++': 'Звезда ВХЛ/Игрок КХЛ (C++)',
  'C+': 'Топ 6/4 ВХЛ (C+)',
  C: 'Игрок ВХЛ (C)',
  'Б/П': 'Без потенциала',
};

export const POTENTIAL_OPTIONS = [
  {
    value: 'A++',
    label: 'Звезда НХЛ (A++)',
  },
  {
    value: 'A+',
    label: 'Топ 6/4 НХЛ (A+)',
  },
  {
    value: 'A',
    label: 'Игрок НХЛ (A)',
  },
  {
    value: 'B++',
    label: 'Звезда КХЛ/Игрок НХЛ (B++)',
  },
  {
    value: 'B+',
    label: 'Топ 6/4 КХЛ (B+)',
  },
  {
    value: 'B',
    label: 'Игрок КХЛ (B)',
  },
  {
    value: 'C++',
    label: 'Звезда ВХЛ/Игрок КХЛ (C++)',
  },
  {
    value: 'C+',
    label: 'Топ 6/4 ВХЛ (C+)',
  },
  {
    value: 'C',
    label: 'Игрок ВХЛ (C)',
  },
  {
    value: 'Б/П',
    label: 'Без потенциала',
  },
];

export const ROLE_MODEL_ASSOCIATIONS = {
  ДВФ: 'Двусторонний форвард (ДВФ)',
  МФ: 'Мощный форвард (МФ)',
  ПЛМ: 'Плеймейкер (ПЛМ)',
  СН: 'Снайпер (СН)',
  ЧК: 'Чекер (ЧК)',
  АЗ: 'Атакующий защитник (АЗ)',
  ОЗ: 'Оборонительный защитник (ОЗ)',
  УЗ: 'Универсальный защитник (УЗ)',
  ВР: 'Вратарь (ВР)',
};

export const ROLE_MODEL_OPTIONS = [
  {
    value: 'ДВФ',
    label: 'Двусторонний форвард (ДВФ)',
  },
  {
    value: 'МФ',
    label: 'Мощный форвард (МФ)',
  },
  {
    value: 'ПЛМ',
    label: 'Плеймейкер (ПЛМ)',
  },
  {
    value: 'СН',
    label: 'Снайпер (СН)',
  },
  {
    value: 'ЧК',
    label: 'Чекер (ЧК)',
  },
  {
    value: 'АЗ',
    label: 'Атакующий защитник (АЗ)',
  },
  {
    value: 'ОЗ',
    label: 'Оборонительный защитник (ОЗ)',
  },
  {
    value: 'УЗ',
    label: 'Универсальный защитник (УЗ)',
  },
  {
    value: 'ВР',
    label: 'Вратарь (ВР)',
  },
];

/** @deprecated use UserRoles enum **/
export const userRoles = {
  student: UserRoles.STUDENT,
  franchisee: UserRoles.FRANCHISEE,
  schools_admin: UserRoles.SCHOOLS_ADMIN,
  admin: UserRoles.ADMIN,
  teacher: UserRoles.TEACHER,
  doctor: UserRoles.DOCTOR,
  psycho: UserRoles.PSYCHO,
  parent: UserRoles.PARENT,
  scout: UserRoles.SCOUT,
  video_analyst: UserRoles.VIDEO_ANALYST,
  methodist: UserRoles.METHODIST,
};

/** @deprecated use TeacherSubroles enum **/
export const TEACHER_SUBROLES = {
  default: TeacherSubroles.DEFAULT,
  main: TeacherSubroles.MAIN,
  goaltender: TeacherSubroles.GOALTENDER,
  defensemens_trainer: TeacherSubroles.DEFENSEMENS_TRAINER,
  wingers_trainer: TeacherSubroles.WINGERS_TRAINER,
  physical: TeacherSubroles.PHYSICAL,
};

/** @deprecated use StudentSubroles enum **/
export const STUDENT_SUBROLES = {
  amateur: StudentSubroles.AMATEUR,
};
export const rolesWithSubrolesMap = {
  [UserRoles.STUDENT]: {
    role: 'Игрок',
    subRole: {
      [StudentSubroles.AMATEUR]: 'Любитель',
    },
  },
  [UserRoles.FRANCHISEE]: {
    role: 'Менеджер',
  },
  [UserRoles.SCHOOLS_ADMIN]: {
    role: 'Администратор школы',
  },
  [UserRoles.ADMIN]: {
    role: 'Администратор',
  },
  [UserRoles.TEACHER]: {
    role: 'Тренер',
    subRole: {
      [TeacherSubroles.DEFAULT]: 'Тренер',
      [TeacherSubroles.MAIN]: 'Главный тренер',
      [TeacherSubroles.GOALTENDER]: 'Тренер вратарей',
      [TeacherSubroles.DEFENSEMENS_TRAINER]: 'Тренер защитников',
      [TeacherSubroles.WINGERS_TRAINER]: 'Тренер нападающих',
      [TeacherSubroles.PHYSICAL]: 'Тренер ОФП',
    },
  },
  [UserRoles.DOCTOR]: {
    role: 'Врач',
  },
  [UserRoles.PSYCHO]: {
    role: 'Психолог',
  },
  [UserRoles.PARENT]: {
    role: 'Родитель',
  },
  [UserRoles.SCOUT]: {
    role: 'Скаут',
  },
  [UserRoles.VIDEO_ANALYST]: {
    role: 'Видеоаналитик',
  },
  [UserRoles.METHODIST]: {
    role: 'Методист',
  },
};

export const longDash = '\u2013';

export const DEFAULT_DATE_SEND_FORMAT = 'YYYY-MM-DD';

export const GAME_TYPES = {
  training: 'Тренировка групповая',
  personal: 'Тренировка индивидуальная',
  webinar: 'Вебинар',
  iceFill: 'Заливка льда',
  technical: 'Профилактические / ремонтные работы',
  game: 'Игра команды (домашняя)',
  away_game: 'Игра команды (выездная)',
  tournament: 'Турнир',
  rent: 'Аренда',
  other: 'Мероприятие объекта',
};

export const mimeType = {
  video: 'video/*',
  image: 'image/*',
  pdf: 'application/pdf',
  powerPoint: ['.pptx', '.ppt', '.rtf', '.pptm'].join(', '),
};

export const STATISTICS_PLAYERS_TABS = {
  classic: {
    label: 'Классическая',
    value: 'classic',
  },
  time: {
    label: 'Время',
    value: 'time',
  },
  shots: {
    label: 'Броски',
    value: 'shots',
  },
  dekings: {
    label: 'Обводки',
    value: 'dekings',
  },
  fouls: {
    label: 'Удаления',
    value: 'fouls',
  },
  passes: {
    label: 'Обостряющие передачи',
    value: 'passes',
  },
  puckBattle: {
    label: 'Борьба за шайбу',
    value: 'puckBattle',
  },
  powerStruggle: {
    label: 'Силовая борьба',
    value: 'powerStruggle',
  },
  corsi: {
    label: 'Corsi',
    value: 'corsi',
  },
};

export const STATISTICS_PARSING_CHECK = {
  lineup: {
    label: 'Состав',
    value: 'lineup',
  },
  goals: {
    label: 'Голы',
    value: 'goals',
  },
  fouls: {
    label: 'Удаления',
    value: 'fouls',
  },
  faceoffs: {
    label: 'Вбрасывания',
    value: 'faceoffs',
  },
  toi: {
    label: 'Игровое время',
    value: 'toi',
  },
  shortShifts: {
    label: 'Короткие смены',
    value: 'shortShifts',
  },
  longShifts: {
    label: 'Длинные смены',
    value: 'longShifts',
  },
  goaltenders: {
    label: 'Вратари',
    value: 'goaltenders',
  },
};

export const TEAM_COLORS = [grey['68'], darkRed['100']];

export const PERIOD_VALUES = {
  all: 'all',
  1: '1',
  2: '2',
  3: '3',
  OT: 'OT',
};

export const initialStateIndicators = {
  isLoading: false,
  isLoad: false,
  error: null,
  data: [],
};

export const STATISTICS_TABLE_TYPES = {
  chess: 'chess',
  tournament: 'tournament',
};

export const PLRS_OPTIONS = [
  {
    label: 'Равные',
    value: 'equals',
  },
  {
    label: 'Большинство',
    value: 'home',
  },
  {
    label: 'Меньшинство',
    value: 'away',
  },
];

export const IMPORTANCE_OPTIONS = [
  {
    label: 'Все',
    value: 'all',
  },
  {
    label: 'Ключевые',
    value: 'important',
  },
  {
    label: 'Остальные',
    value: 'not_important',
  },
];
export const HALF_OPTIONS = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
];
export const HALF_OPTIONS_WITH_OT = [
  ...HALF_OPTIONS,
  {
    label: 'Овертайм',
    value: 'OT',
  },
];

export const IS_PRODUCTION = process.env.REACT_APP_PRODUCTION === 'true';

export const PLAYER_STATUSES = {
  injury: 'Травмирован',
  illness: 'Болен',
};

export const YOUNGER_AGE_GROUP = 'U13';
