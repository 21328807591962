import TabsDefault from '@ui/MUI/Tabs';
import styled from 'styled-components';

export const TabsWrapper = styled.div`
  .TableEmptyBlock {
    flex-direction: column;
    .MuiButton {
      &-root {
        margin-left: 0;
        margin-top: ${({theme}) => theme.spacing(3)};
      }
    }
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  padding: 0 24px 0;
  margin-top: 18px;
  margin-bottom: 24px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
`;

export const StyledTabsDefault = styled(TabsDefault)`
  margin-left: 0;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  box-shadow: none !important;

  & + div .MuiBox-root .MuiTypography-h4 {
    margin-top: 20px;
  }

  .MuiTabs {
    &-indicator {
      height: 3px;
      margin-bottom: 8px;
    }
    &-flexContainer {
      ${({theme}) => theme.breakpoints.down('xs')} {
        justify-content: center;
      }
      ${({theme}) => theme.breakpoints.down('mobileM')} {
        justify-content: initial;
      }
    }
  }

  .MuiTab {
    &-root {
      padding: 4px 12px;
      margin-left: 12px;
      margin-right: 12px;
      font: 600 normal 14px/16px 'Proxima Nova';
      color: white;
      letter-spacing: 0.02em;

      ${({theme}) => theme.breakpoints.down('sm')} {
        padding-right: 0;
        padding-left: 0;
        font-size: 12px;
        line-height: 14px;
      }

      &:first-child {
        margin-left: 0;
        ${({theme}) => theme.breakpoints.down('sm')} {
          margin-right: ${({theme}) => theme.spacing(0)};
        }
      }

      &:last-child {
        margin-right: 0;
        ${({theme}) => theme.breakpoints.down('sm')} {
          margin-left: ${({theme}) => theme.spacing(0)};
        }
      }

      &:hover {
        color: rgba(255, 255, 255, 0.6);
      }

      &.Mui-selected {
        color: #fff;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  ${({theme}) => theme.breakpoints.down('xs')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  ${(props) => props?.sx}
  .MuiButton {
    &-root {
      ${({theme}) => theme.breakpoints.down('xs')} {
        width: 100%;
      }
      &:not(:last-child) {
        margin-right: ${({theme}) => theme.spacing(2)};
        ${({theme}) => theme.breakpoints.down('xs')} {
          margin-right: 0;
          margin-bottom: ${({theme}) => theme.spacing(1)};
        }
      }
      &:not(:first-child) {
        margin-left: ${({theme}) => theme.spacing(2)};
        ${({theme}) => theme.breakpoints.down('xs')} {
          margin-left: 0;
        }
      }
    }
  }
`;

export const LocalPreloaderWrapper = styled.div`
  margin-right: 0;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: ${(props) => props.padding || '24px'};
  overflow: hidden;
  margin-bottom: ${(props) => props.marginBottom || 'unset'};
  position: relative;
  height: ${(props) => props.isLoading && '70vh'};
`;
