import {SCOUT_REPORT_TYPES} from '@src/constants';
import {Button} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormProvider, useForm, useFormState} from 'react-hook-form';

import DrawerDefault from '../../../ui/MUI/Modals/Drawer/Drawer';
import CancelModal from '../CancelModal';
import ReportCreateContent from '../ReportCreateContent';
import {ButtonsWrapper} from './ReportCreateModal';

const outerDefaultValues = {
  description: '',
  strongSide: '',
  weakSide: '',
  potential: '',
  roleModel: '',
};

const innerDefaultValues = {
  ...outerDefaultValues,
  gameCount: '',
  goalPoint: '',
  passPoint: '',
};

const defaultValues = {
  [SCOUT_REPORT_TYPES.inner]: innerDefaultValues,
  [SCOUT_REPORT_TYPES.outer]: outerDefaultValues,
};
function ReportCreateModal({isOpen, setIsOpen = () => {}, reportType, onCreate, studentData}) {
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      ...setDefaultValues(),
    },
  });
  const {control, handleSubmit, reset} = methods;
  const {isDirty} = useFormState({
    control,
  });

  const handleClose = () => {
    if (isDirty) return setIsCancelModalVisible(true);
    reset();
    setIsOpen(false);
  };

  function setDefaultValues() {
    return defaultValues[reportType] || {};
  }

  return (
    <>
      <DrawerDefault
        buttons={
          <ButtonsWrapper>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                handleSubmit((data) => {
                  setIsOpen(false);
                  const formattedData = {
                    ...data,
                    student: studentData.student.id,
                    gameCount: Number(data.gameCount),
                    goalPoint: Number(data.goalPoint),
                    passPoint: Number(data.passPoint),
                  };
                  onCreate(
                    Object.keys(formattedData)?.reduce((result, currentKey) => {
                      if (currentKey !== 'author' && formattedData[currentKey]) {
                        result[currentKey] = formattedData[currentKey];
                      }
                      return result;
                    }, {}),
                    reset,
                  );
                })()
              }
            >
              добавить
            </Button>
            <Button color="secondary" variant="contained" onClick={handleClose}>
              Отменить
            </Button>
          </ButtonsWrapper>
        }
        content={
          <FormProvider {...methods}>
            <ReportCreateContent reportType={reportType} studentData={studentData} />
          </FormProvider>
        }
        drawerState={isOpen}
        hasFullWidth="false"
        setDrawerState={setIsOpen}
        onClose={handleClose}
      />
      <CancelModal
        isVisible={isCancelModalVisible}
        onCancel={() => setIsCancelModalVisible(false)}
        onClose={() => {
          reset();
          setIsOpen(false);
          setIsCancelModalVisible(false);
        }}
      />
    </>
  );
}

ReportCreateModal.propTypes = {
  isOpen: PropTypes.object,
  setIsOpen: PropTypes.func,
  onCreate: PropTypes.func,
  studentData: PropTypes.object.isRequired,
};

export default ReportCreateModal;
