import TableTemplate from '@common/Statistics/PlayerStatistics/TableTemplate';
import TopFiveItem from '@common/Statistics/PlayerStatistics/TopFiveItem';
import {customTableComparator} from '@src/helpers';
import {Tooltip, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

import SingleProgressCell from '../../SingleProgressCell';

const columns = [
  {
    accessorKey: 'goals',
    header: () => {
      return (
        <Tooltip title="Голы">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Г
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <SingleProgressCell percent={Math.abs(getValue()?.value)} value={getValue()?.value} />;
    },
    cellStyle: {position: 'relative', textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'shots',
    header: () => {
      return (
        <Tooltip title="Броски">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Б
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'shotsOnGoal',
    header: () => {
      return (
        <Tooltip title="Броски в створ">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            БвС
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'shotsRealization',
    header: () => {
      return (
        <Tooltip title="Реализация бросков %">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            РБ%
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'shotsAccuracy',
    header: () => {
      return (
        <Tooltip title="Точность бросков %">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            ТБ%
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'unsuccessfulShotAttempts',
    header: () => {
      return (
        <Tooltip title="Неудачные попытки броска">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            НПБ
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
];
function ShotsTab({isLoad, isLoading, data, hasError, hasMatchFilter}) {
  const [sorting, setSorting] = useState([{asc: true, id: 'goals'}]);
  const rowsData = useMemo(
    () =>
      data?.map((item) => {
        return {
          student: item?.student,
          goals: item?.goals,
          shots: item?.shots,
          shotsRealization: item?.shotsRealization,
          shotsOnGoal: item?.shotsOnGoal,
          shotsAccuracy: item?.shotsAccuracy,
          unsuccessfulShotAttempts: item?.unsuccessfulShotAttempts,
        };
      }),
    [data],
  );

  return (
    <TableTemplate
      columns={columns}
      hasError={hasError}
      hasMatchFilter={hasMatchFilter}
      isLoad={isLoad}
      isLoading={isLoading}
      rowsData={rowsData}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
}

ShotsTab.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ShotsTab;
