import {createTraining} from '@api/TrainingControlProcess';
import dateInterface from '@helpers/date';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Check} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {createTrainingControl, updateStep, updateTab, updateTrainingControl} from '@slices/trainingControlProcess';
import {Typography} from '@ui/MUI';
import Box from '@ui/MUI/Box';
import ButtonDefault from '@ui/MUI/Button';
import Stack from '@ui/MUI/Stack';
import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';

import useStep from '../../hooks/useStep';

const BottomBlock = () => {
  const {step, next, prev} = useStep();
  const [template, setTemplate] = useState({isLoading: false, value: null});

  const {
    handleSubmit,
    reset,
    getValues,
    formState: {isValid, isDirty},
  } = useFormContext();

  const dispatch = useStoreDispatch();
  const isLoading = useStore(
    (state) =>
      state.trainingControlProcess.training_library.form.isLoading ||
      state.trainingControlProcess.training_templates.form.isLoading,
  );

  const isDataHasTemplate = useStore((state) => state.trainingControlProcess.tempData?.withTemplate);

  const createTemplate = () => {
    const {id, date, group, school, exercises, dates, isTemplate, ...rest} = getValues();
    const payload = {
      group: group?.id,
      school: school?.id,
      exercises: exercises.map(({id, time, repeat}) => ({
        time: Number(time),
        exerciseId: id,
        id,
        repeat: Number(repeat),
      })),
      ...rest,
    };

    setTemplate({value: null, isLoading: true});

    createTraining({
      training: {...payload, isTemplate: true},
      dates: dates.map(({date, startTime, endTime}) => ({
        date: dateInterface(date).valueOf(),
        startTime: dateInterface(startTime).format('HH:mm'),
        endTime: dateInterface(endTime).format('HH:mm'),
      })),
    }).then(() => {
      setTemplate({value: true, isLoading: false});
    });
  };

  const onSubmit = (data) => {
    const {id, date, group, school, exercises, dates, isTemplate, ...rest} = data;
    const payload = {
      group: group?.id,
      school: school?.id,
      exercises: exercises.map(({id, time, repeat}) => ({
        time: Number(time),
        exerciseId: id,
        id,
        repeat: Number(repeat),
      })),
      ...rest,
    };

    if (id) {
      const {startTime, endTime, date} = dates[0];
      dispatch(
        updateTrainingControl({
          name: 'training_library',
          payload: {
            ...payload,
            date,
            startTime: dateInterface(startTime).format('HH:mm'),
            endTime: dateInterface(endTime).format('HH:mm'),
          },
          onSuccess: () => {
            dispatch(updateTab('plan'));
            reset();
            dispatch(updateStep(0));
          },
          id,
        }),
      );
    } else {
      dispatch(
        createTrainingControl({
          name: 'training_library',
          payload: {
            training: {...payload},
            dates: dates.map(({date, startTime, endTime}) => ({
              date: dateInterface(date).valueOf(),
              startTime: dateInterface(startTime).format('HH:mm'),
              endTime: dateInterface(endTime).format('HH:mm'),
            })),
          },
          onSuccess: () => {
            dispatch(updateTab('plan'));
            reset();
            dispatch(updateStep(0));
          },
        }),
      );
    }
  };

  return (
    <Stack
      boxShadow="0px -4px 12px 0px rgba(0, 0, 0, 0.15)"
      direction="row"
      justifyContent="space-between"
      px={4}
      py={3}
    >
      <Box>
        {step !== 0 && (
          <ButtonDefault disableElevation variant="contained" onClick={prev}>
            {step === 2 ? 'Назад' : 'выбор упражнений'}
          </ButtonDefault>
        )}
      </Box>
      <Stack direction="row" spacing={4}>
        {step === 2 &&
          (template.value ? (
            <Stack alignItems="center" direction="row" spacing="6px">
              <Check sx={{fontSize: '18px', color: 'rgba(0, 0, 0, 0.6)'}} />
              <Typography color="rgba(0, 0, 0, 0.6)" fontSize="14px" fontWeight="600" variant="button">
                добавлено в шаблоны
              </Typography>
            </Stack>
          ) : (
            <LoadingButton
              disableElevation
              color="secondary"
              disabled={!isDirty}
              loading={template.isLoading}
              variant="contained"
              onClick={createTemplate}
            >
              Сохранить в шаблоны
            </LoadingButton>
          ))}
        <LoadingButton
          color="primary"
          disabled={step === 2 && !isDataHasTemplate ? !isDirty || !isValid : !isValid}
          loading={isLoading}
          variant="contained"
          onClick={step === 2 ? handleSubmit(onSubmit, (err) => console.error(err)) : next}
        >
          {step === 2 ? 'Сохранить' : 'Далее'}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default BottomBlock;
