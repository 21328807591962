import FoulsTab from '@common/Statistics/PlayerStatistics/Tabs/FoulsTab';
import PropTypes from 'prop-types';
import React from 'react';

import {useLoadPlayersStatisticsData} from '../../hooks';

function Fouls({tab, filter, matchFilter}) {
  const statisticsTab = useLoadPlayersStatisticsData({tab, filter, matchFilter});

  const {isLoad, isLoading, data, hasError, hasMatchFilter} = statisticsTab;

  return <FoulsTab {...{isLoad, isLoading, data, hasError, hasMatchFilter}} />;
}

Fouls.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default Fouls;
