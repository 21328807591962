import CorsiTab from '@common/Statistics/PlayerStatistics/Tabs/CorsiTab';
import PropTypes from 'prop-types';
import React from 'react';

import {useLoadPlayersStatisticsData} from '../../hooks';

function Corsi({tab, filter, matchFilter}) {
  const statisticsTab = useLoadPlayersStatisticsData({tab, filter, matchFilter});

  const {isLoad, isLoading, data, hasError, hasMatchFilter} = statisticsTab;

  return <CorsiTab {...{isLoad, isLoading, data, hasError, hasMatchFilter}} />;
}

Corsi.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default Corsi;
