import {getSchools} from '@api/schoolsAPIV2';
import {actionsGetter, defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {SchoolsV2State} from '@slices/schools/types';
import {initialStateIndicators} from '@src/constants';
import {Params} from '@src/types';

import moduleNames from '../../moduleNames';

const statePaths = {
  schools: {
    name: 'schools',
    path: 'schools',
  },
};

const initialState: SchoolsV2State['schoolsV2'] = {
  schools: {...initialStateIndicators},
};

//redusers
const slice = createSlice({
  name: moduleNames.schoolsV2,
  initialState,
  reducers: {
    ...defaultSliceReducersConstructor(statePaths.schools.name, statePaths.schools.path, initialState).functions,
  },
});

//exports

const {actions, reducer} = slice;
export {reducer as schoolsV2Reducer};

//fetch fns
const schoolsActions = actionsGetter(statePaths.schools.name, actions);

export const loadSchools = <T>(params: Params & T) =>
  defaultLoader(() => getSchools(params), schoolsActions.loading, schoolsActions.load, schoolsActions.error);

export const clearSchools = () => (dispatch: Dispatch) => dispatch(schoolsActions.clear());
