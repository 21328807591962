import MuiBox from '@mui/material/Box';
import {BoxProps} from '@mui/material/Box/Box';
import React, {forwardRef} from 'react';

function Box({children, ...props}: BoxProps, ref: React.Ref<unknown> | undefined) {
  return (
    <MuiBox {...props} ref={ref}>
      {children}
    </MuiBox>
  );
}

export default forwardRef(Box);
