import {getStatisticsTeamNames} from '@api/StatsAPI';
import ActionsMap from '@components/apps/StatsReportForOneGame/components/ActionsMap';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useQueryStringTabs from '@hooks/useQueryStringTabs';
import {longDash} from '@src/constants';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import GoaltendersStatisticsTab from './components/GoaltendersStatisticsTab';
import Main from './components/Main';
import PlayersStatistics from './components/PlayersStatistics';
import ShiftsTimeline from './components/ShiftsTimeline';
import Throwins from './components/ThrowinsTab';
import ThrowsStatistics from './components/ThrowsStatistics';
import {Wrapper} from './StatsReportForOneGame';

const StatsReportForOneGameApp = () => {
  const {statisticId} = useParams();
  const [teams, setTeams] = useState({
    home: '',
    away: '',
    weAtHome: false,
    winByBuls: false,
    winByOT: false,
  });
  const hasOT = useMemo(() => teams?.winByOT || teams?.winByBuls, [teams]);

  const tabs = useDeepMemo(
    () => [
      {
        label: 'Обзор',
        value: <Main hasOT={hasOT} winByBuls={teams?.winByBuls} winByOT={teams?.winByOT} />,
        id: 'view',
      },
      {label: 'Смены', value: <ShiftsTimeline hasOT={hasOT} teams={teams} />, id: 'shifts'},
      {label: 'Броски', value: <ThrowsStatistics hasOT={hasOT} weAtHome={teams.weAtHome} />, id: 'shots'},
      {label: 'Действия', value: <ActionsMap hasOT={hasOT} weAtHome={teams.weAtHome} />, id: 'actions'},
      {label: 'Игроки', value: <PlayersStatistics hasOT={hasOT} weAtHome={teams.weAtHome} />, id: 'players'},
      {label: 'Вбрасывания', value: <Throwins hasOT={hasOT} weAtHome={teams.weAtHome} />, id: 'throwIns'},
      {
        label: 'Вратари',
        value: <GoaltendersStatisticsTab hasOT={hasOT} weAtHome={teams.weAtHome} />,
        id: 'goaltenders',
      },
    ],
    [hasOT, teams],
  );

  const {value, onChange} = useQueryStringTabs({tabs});

  useDeepEffect(() => {
    getStatisticsTeamNames({statisticId}).then((data) => {
      setTeams(data.data);
    });
  }, [statisticId]);

  return (
    <Wrapper>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Игровая статистика', path: '/statistics'},
          {
            label: teams?.home && teams?.away ? `${teams?.home} ${longDash} ${teams?.away}` : 'Игра',
            path: null,
          },
        ]}
        className="GameStatsTitle"
        text="Игровая статистика"
      />
      <TabsNew
        hasMobileView
        withoutWrapper
        tabs={tabs}
        tabsWrapperProps={{px: '0', pt: '0px'}}
        value={value}
        onChange={onChange}
      />
    </Wrapper>
  );
};

export default StatsReportForOneGameApp;
