import StatsComponentsChart from '@common/Statistics/StatsComponentsChart';
import {useMediaQuery} from '@mui/material';
import {Stack} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

ThrowinsMetricsCharts.propTypes = {
  zones: PropTypes.array,
  isLoading: PropTypes.bool,
  weAtHome: PropTypes.bool,
};
const zoneNamesLabels = ['Зона атаки', 'Средняя зона', 'Зона защиты'];
function ThrowinsMetricsCharts({isLoading, zones = [], weAtHome}) {
  const zoneNames = useMemo(() => (weAtHome ? [...zoneNamesLabels].reverse() : zoneNamesLabels), [weAtHome]);
  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return (
    <StatsChartsWrapper alignItems="flex-start" direction="row" mt={7}>
      {(weAtHome ? [...zones].reverse() : [...zones])?.map(({home, away, percent}, index) => {
        return (
          <StatsComponentsChart
            chart={{
              seriesData: [
                {percentValue: percent, value: home},
                {percentValue: percent, value: away},
              ],
              width: isXsResolution ? 80 : 112,
              height: isXsResolution ? 80 : 112,
            }}
            isLoading={isLoading}
            key={index}
            title={zoneNames[index]}
            weAtHome={weAtHome}
            wrapperProps={{
              maxWidth: {xxs: `calc(100% / ${index !== 1 ? 2 : 1}) !important`, sm: 'calc(100% / 3) !important'},
              order: {xxs: index === 1 ? 3 : '', md: index + 1},
            }}
          />
        );
      })}
    </StatsChartsWrapper>
  );
}

export default memo(ThrowinsMetricsCharts);

const StatsChartsWrapper = styled(Stack)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: ${(props) => props.alignItems || 'center'};
  flex-wrap: wrap;
`;
