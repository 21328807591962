import uniqBy from 'lodash/uniqBy';
import queryString from 'query-string';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';

import APIHelper from '../../../../api/APIHelper';
import {NOTIFICATION_TYPES} from '../../../../constants/config';
import CommonHelper, {getWindowDimensions} from '../../../../helpers/CommonHelper';
import isObjectEmpty from '../../../../helpers/isObjectEmpty';
import StateHelper from '../../../../helpers/StateHelper';
import useStateCallback from '../../../../hooks/useStateCallBack';
import {ReactComponentNotification} from '../../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import SelectDefault from '../../../ui/Select';
import Tabs from '../../../ui/Tabs';
import {filterUsersConfig} from '../../constants';
import {SPORT_EVENTS} from '../fullcalendar/components/PrintTable/hooks/useFormatedLessons';
import {Column, Filter, Label, Wrapper} from './style';

/*
 TODO: Перенести запросы schools, rooms в этот компонент
 обернуть onChange в useCallBack
 */

const CalendarFilter = (props) => {
  const {
    schools = [],
    rooms = [],
    onChange = () => {},
    currentUser = {
      userRole: 'guest',
    },
    isSchoolLoading = false,
    setLoadingState = () => {},
    childrens,
    setStudentsIds = () => {},
  } = props;

  const ref = useRef(null);
  let history = useHistory();
  const qs = queryString.parse(history.location.search);
  const {url} = useRouteMatch();
  const isMobile = getWindowDimensions().width <= 768;
  const isEdit = true;

  const [filterOptions, setFilterOptions] = useState({
    groupOption: {isLoading: false, groups: []},
    studentOption: {isLoading: false, students: []},
  });

  const [selectedFilters, setSelectedFilters] = useStateCallback({
    groupIds: [],
    studentIds: [],
    roomIds: [],
    teacherIds: [],
    schoolId: '',
  });

  const setFilterByTeacher = () => {
    const teachersSchool = schools.find((school) => school.teachersIds.includes(currentUser.id));
    if (!teachersSchool) {
      return;
    }

    setSelectedFilters(
      {
        ...selectedFilters,
        schoolId: {label: teachersSchool.name, value: teachersSchool.id},
      },
      async (state) => {
        const schoolGroupsIds = await StateHelper.getGroupsBySchoolId(teachersSchool.id);

        setFilterOptions({
          ...filterOptions,
          groupOption: {isLoading: true, groups: []},
        });

        let schoolGroups = await APIHelper.getGroupsByIds(schoolGroupsIds.map((groupObject) => groupObject.id));

        schoolGroups = schoolGroups.map((group) => group?.data);

        setFilterOptions({
          ...filterOptions,
          groupOption: {isLoading: false, groups: schoolGroups},
        });

        const teachersGroup = schoolGroups.filter((group) =>
          group.teachers.map((teacher) => teacher.id)?.includes(currentUser.id),
        );

        const formatedTeachersGroup = teachersGroup.map((group) => ({
          label: group.name,
          value: group.id,
        }));

        setSelectedFilters({...state, groupIds: formatedTeachersGroup}, (state) =>
          onChange({...state, groups: schoolGroups}),
        );
        setFilterOptions((state) => ({
          ...state,
          studentOption: {isLoading: true, students: []},
        }));

        const students = await Promise.all(teachersGroup.map((player) => StateHelper.getStudentsByTeamId(player.id)));
        setFilterOptions((state) => ({
          ...state,
          studentOption: {isLoading: false, students: students.flat()},
        }));
      },
    );
  };

  const setFilterByFranchisee = async () => {
    const schoolId = currentUser.schoolsIds[0];
    if (schoolId) {
      const franchiseeSchool = schools.find((school) => school.id === schoolId);

      if (!franchiseeSchool) {
        return;
      }

      setSelectedFilters(
        {
          ...selectedFilters,
          schoolId: {label: franchiseeSchool.name, value: franchiseeSchool.id},
        },
        async (state) => {
          const schoolGroupsIds = await StateHelper.getGroupsBySchoolId(franchiseeSchool.id);
          setFilterOptions({
            ...filterOptions,
            groupOption: {isLoading: true, groups: []},
          });
          let schoolGroups = [];
          try {
            schoolGroups = await APIHelper.getGroupsByIds(schoolGroupsIds.map((groupObject) => groupObject.id));
            schoolGroups = schoolGroups.map((group) => group?.data);
          } catch (error) {
            ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка при загрузке команд: ${error}`);
          }

          let formatedManagersGroup = [];

          if (qs.groupIds) {
            formatedManagersGroup = schoolGroups
              .filter((group) => group.id === qs.groupIds)
              .map((group) => ({
                label: group.name,
                value: group.id,
              }));
          }

          setSelectedFilters({...state, groupIds: formatedManagersGroup});
          setFilterOptions({
            ...filterOptions,
            groupOption: {isLoading: false, groups: schoolGroups},
          });
          onChange({...state, groups: schoolGroups, groupIds: formatedManagersGroup});
        },
      );
    }
  };

  const setFilterByPlayer = async () => {
    setLoadingState(true);
    const playerSchool = schools.find((school) => school.id === currentUser.schoolId);
    try {
      if (playerSchool) {
        const schoolGroupsIds = await StateHelper.getGroupsBySchoolId(playerSchool.id);
        let schoolGroups = await APIHelper.getGroupsByIds(schoolGroupsIds.map((groupObject) => groupObject.id));

        schoolGroups = schoolGroups?.map((group) => group?.data);

        const filterByStudent = {
          schoolId: {label: playerSchool.name, value: playerSchool.id},
          studentIds: [{label: `${currentUser.lastName} ${currentUser.firstName}`, value: currentUser.id}],
          groups: schoolGroups,
        };
        setSelectedFilters(filterByStudent, (state) => onChange(state));
      }
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
    }
  };

  const setFilterByParent = async () => {
    setLoadingState(true);
    const selectedChild = childrens.find((el) => el.id === playerId);
    const playerSchool = schools.find((school) => school.id === selectedChild?.schoolId);
    try {
      if (playerSchool) {
        const schoolGroupsIds = await StateHelper.getGroupsBySchoolId(playerSchool.id);
        let schoolGroups = await APIHelper.getGroupsByIds(schoolGroupsIds.map((groupObject) => groupObject.id));
        schoolGroups = schoolGroups?.map((group) => group?.data);

        const filterByParent = {
          schoolId: {label: playerSchool.name, value: playerSchool.id},
          studentIds: [
            {
              label: `${selectedChild.lastName} ${selectedChild.firstName}`,
              value: selectedChild.id,
            },
          ],
          groups: schoolGroups,
        };
        setSelectedFilters(filterByParent, (state) => onChange(state));
      }
    } catch (error) {
      setLoadingState(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setSelectedFilters({
      ...selectedFilters,
      groupIds: null,
    });
  }, [selectedFilters.schoolId]);

  const selectFilter = (filter, action) => {
    const {name} = action;
    if (!name) {
      throw new Error(`У селекта отсутствует обязательное поле name`);
    }

    if (name === 'roomIds' && filter?.some((item) => item.value === 'sport')) {
      const prevFilters = filter;
      const filteredRooms = rooms
        .filter((room) => SPORT_EVENTS.includes(room.id))
        .map((room) => ({label: room.name, value: room.id}));

      setSelectedFilters(
        {
          ...selectedFilters,
          [name]: uniqBy([...prevFilters, ...filteredRooms], 'value'),
        },
        (state) => onChange(state),
      );
      return;
    }

    if (name === 'schoolId') {
      if (selectedFilters[name]?.value === filter?.value) {
        return;
      }

      if (filter) {
        setSelectedFilters({...selectedFilters, [name]: filter}, async (state) => {
          setFilterOptions({...filterOptions, groupOption: {isLoading: true, groups: []}});
          let groups = [];
          try {
            groups = await StateHelper.getGroupsBySchoolId(filter.value);
          } catch (error) {
            ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка при загрузке игроков: ${error}`);
          }
          setFilterOptions({...filterOptions, groupOption: {isLoading: false, groups}});
          onChange(state);
        });
      } else {
        setSelectedFilters({...selectedFilters, [name]: filter, groupIds: [], studentIds: []}, async (state) => {
          setFilterOptions({...filterOptions, groupOption: {isLoading: false, groups: []}});
          onChange(state);
        });
      }
      return;
    }

    setSelectedFilters({...selectedFilters, [name]: filter}, async (state) => {
      if (name === 'groupIds') {
        setFilterOptions({...filterOptions, studentOption: {isLoading: true, students: []}});
        let students = [];
        try {
          students = await Promise.all(filter.map((player) => StateHelper.getStudentsByTeamId(player.value)));
        } catch (error) {
          ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка при загрузке игроков: ${error}`);
        }
        setFilterOptions({
          ...filterOptions,
          studentOption: {isLoading: false, students: students.flat()},
        });
        setStudentsIds(
          students.flat().map((player) => ({
            value: player.id,
            label: `${player.student.lastName} ${player.student.firstName}`,
          })),
        );
      }

      onChange(state);
    });
  };
  const playerId = useMemo(() => {
    if (currentUser.userRole === 'parent') {
      if (!isObjectEmpty(qs) && childrens) {
        return childrens[0]?.id;
      }
      if (isObjectEmpty(qs)) {
        return qs.tab;
      }
    }
  }, [qs]);

  useEffect(() => {
    if (currentUser.userRole === 'teacher') {
      setFilterByTeacher();
    }
    if (currentUser.userRole === 'student') {
      setFilterByPlayer();
    }
    if (currentUser.userRole === 'franchisee') {
      setFilterByFranchisee();
    }

    if (currentUser.userRole === 'schools_admin') {
      setFilterByFranchisee();
    }
  }, [currentUser?.id, playerId]);

  useEffect(() => {
    if (currentUser.userRole === 'parent' && childrens) {
      setFilterByParent();
    }
  }, [currentUser?.id, playerId, childrens]);

  const tabs = useMemo(() => {
    if (!currentUser.userRole === 'parent') return;
    return childrens?.map((el, index) => {
      return {
        label: `${el.firstName} ${el.lastName}`,
        link: index === 0 ? '' : el.id,
      };
    });
  }, [childrens]);

  const setStudentsByFilterGroups = async () => {
    const students = await Promise.all(
      selectedFilters?.groupIds?.map((groupId) => StateHelper.getStudentsByTeamId(groupId?.value)),
    );
    setFilterOptions((state) => ({
      ...state,
      studentOption: {isLoading: false, students: students.flat()},
    }));
  };

  useEffect(() => {
    if (selectedFilters?.groupIds?.length > 0) {
      setStudentsByFilterGroups();
    }
  }, [selectedFilters?.groupIds?.length]);

  return (
    <>
      {filterUsersConfig[currentUser.userRole]?.hasFilter && (
        <Wrapper filterHeight={ref?.current?.offsetHeight} ref={ref}>
          <Filter className={'Filter'}>
            <Column className={'Column'}>
              <Label>Школа</Label>
              <SelectDefault
                isClearable={true}
                isDisabled={currentUser.userRole === 'schools_admin'}
                isMulti={false}
                name="schoolId"
                options={CommonHelper.getDropDownItemsByNamedList(schools)}
                placeHolder="Выбрать"
                value={selectedFilters.schoolId}
                onChange={selectFilter}
              />
            </Column>
            <Column className={'Column'}>
              <Label>Команда</Label>
              <SelectDefault
                isLoading={filterOptions.groupOption.isLoading}
                isMulti={true}
                name="groupIds"
                options={CommonHelper.getDropDownTeamsByNamedList(filterOptions.groupOption.groups)}
                value={selectedFilters.groupIds}
                onChange={selectFilter}
              />
            </Column>

            <Column className={'Column'}>
              <Label>Игрок</Label>
              <SelectDefault
                isLoading={filterOptions.studentOption.isLoading}
                isMulti={true}
                name="studentIds"
                options={CommonHelper.getDropDownItemsByNamedList(filterOptions.studentOption.students)}
                value={selectedFilters.studentOption}
                onChange={selectFilter}
              />
            </Column>

            <Column className={'Column'}>
              <Label>Помещение</Label>
              <SelectDefault
                isLoading={isSchoolLoading}
                isMulti={true}
                name="roomIds"
                options={[
                  ...CommonHelper.getDropDownItemsByNamedList(rooms),
                  {label: 'Спортивные залы', value: 'sport'},
                ]}
                value={selectedFilters.roomIds}
                onChange={selectFilter}
              />
            </Column>
          </Filter>
        </Wrapper>
      )}
      {currentUser.userRole === 'parent' && (
        <Tabs currentUser={currentUser} isEdit={isEdit} isMobile={isMobile} tabs={tabs} url={url} />
      )}
    </>
  );
};

export default CalendarFilter;
