import React from 'react';

function Clear() {
  return (
    <React.Fragment>
      <g>
        <path
          clipRule="evenodd"
          d="M0.160373 0.547547C-0.0534578 0.761364 -0.0534578 1.10805 0.160373 1.32188L3.8385 5L0.160373 8.67813C-0.0534578 8.89192 -0.0534578 9.23858 0.160373 9.45251L0.547548 9.83965C0.761379 10.0535 1.10805 10.0535 1.32188 9.83965L5.00002 6.16152L8.67815 9.83965C8.89205 10.0535 9.23871 10.0535 9.45251 9.83965L9.83965 9.45251C10.0535 9.23858 10.0535 8.89192 9.83965 8.67813L6.16153 5L9.83965 1.32188C10.0535 1.10805 10.0535 0.761364 9.83965 0.547547L9.45251 0.160373C9.23871 -0.0534576 8.89205 -0.0534576 8.67815 0.160373L5.00002 3.83849L1.32188 0.160373C1.10805 -0.0534576 0.761379 -0.0534576 0.547548 0.160373L0.160373 0.547547Z"
          fill="#2C2C2C"
          fillRule="evenodd"
        />
      </g>
    </React.Fragment>
  );
}
export default Clear;
