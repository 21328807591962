import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import * as schoolsActions from '../../redux/actions/SchoolsActions';
import PsychotestReportPanel from '../psychotest/panels/PsychotestReportPanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function PsychotestReportApp() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(schoolsActions.loadAllSchools());
  }, []);
  return (
    <ChessTemplate active="psychotest_report">
      <PsychotestReportPanel />
    </ChessTemplate>
  );
}
