import TimeInput from '@common/TimeInput';
import UploadFile from '@components/UploadFile';
import {Grid} from '@mui/material';
import {DateTimePicker, TextField} from '@ui/MUI';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import CheckboxDefault from '@ui/MUI/Checkbox';
import DatePickerDefault from '@ui/MUI/DatePicker';
import {bool, func, node, number, oneOf, oneOfType, shape, string} from 'prop-types';
import React, {memo, useEffect, useRef} from 'react';
import {Controller} from 'react-hook-form';

const Field = ({
  id,
  type = 'autocomplete',
  md = 12,
  name,
  onChange: handleChange,
  options = [],
  label,
  multiple = false,
  isLoading = false,
  shouldUnregister = false,
  multiline = false,
  disabled = false,
  accept,
  isRequired,
  isSelectableAll,
  isUploadToStorage = false,
  pattern,
  inputType = 'text',
  isOptionEqualToValue,
  getOptionLabel,
  minValue,
  maxValue,
  validate,
  hasHandleWheel = false,
  helperText = '',
  ...props
}) => {
  const textFieldRef = useRef(null);
  useEffect(() => {
    if (hasHandleWheel) {
      const handleWheel = (e) => e.preventDefault();
      textFieldRef?.current?.addEventListener('wheel', handleWheel);

      return () => {
        textFieldRef?.current?.removeEventListener('wheel', handleWheel);
      };
    }
  }, [hasHandleWheel]);
  return (
    <Grid item md={md} {...props}>
      <Controller
        name={name}
        render={({field: {onChange, value, ref, ...field}, fieldState: {error, ...fieldState}}) => {
          return (
            <>
              {type === 'autocomplete' && (
                <AutocompleteDefault
                  disabled={disabled}
                  error={error}
                  getOptionLabel={getOptionLabel}
                  helperText={error && (error.message || helperText || 'Обязательное поле!')}
                  inputRef={ref}
                  isLoading={isLoading}
                  isOptionEqualToValue={isOptionEqualToValue}
                  isSelectableAll={isSelectableAll}
                  label={label}
                  multiple={multiple}
                  options={options}
                  value={value}
                  {...field}
                  {...fieldState}
                  {...props}
                  onChange={(_, v) => {
                    onChange(v);
                    handleChange && handleChange(v);
                  }}
                />
              )}
              {type === 'input' && (
                <TextField
                  shouldFocusError
                  {...field}
                  {...fieldState}
                  helperText={error && (error.message || helperText || 'Обязательное поле!')}
                  inputRef={ref}
                  ref={textFieldRef}
                  variant="standard"
                  {...props}
                  fullWidth
                  disabled={disabled}
                  error={!!error}
                  label={label}
                  multiline={multiline}
                  type={inputType}
                  value={value ?? ''}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}
              {type === 'upload' && (
                <UploadFile
                  accept={accept}
                  disabled={disabled}
                  error={!!error}
                  helperText={error && (error.message || helperText || 'Обязательное поле!')}
                  isUploadToStorage={isUploadToStorage}
                  label={label}
                  value={value}
                  {...props}
                  onChange={(v) => {
                    onChange(v);
                  }}
                />
              )}
              {type === 'mask' && <TimeInput label={label} value={value} onChange={onChange} />}
              {type === 'datePicker' && (
                <DatePickerDefault
                  error={error}
                  helperText={error && (error.message || helperText || 'Обязательное поле!')}
                  inputRef={ref}
                  label={label}
                  mask="__/__/____"
                  value={value || null}
                  onChange={onChange}
                  {...props}
                />
              )}
              {type === 'dateTimePicker' && (
                <DateTimePicker
                  error={error}
                  helperText={error && (error.message || helperText || 'Обязательное поле!')}
                  inputRef={ref}
                  label={label}
                  value={value || null}
                  onChange={onChange}
                  {...props}
                />
              )}
              {type === 'checkBox' && (
                <CheckboxDefault
                  checked={value}
                  error={error}
                  helperText={error && (error.message || helperText || 'Обязательное поле!')}
                  id={id}
                  inputProps={{'aria-label': 'controlled'}}
                  label={label}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  {...fieldState}
                  {...props}
                />
              )}
            </>
          );
        }}
        rules={{
          validate,
          required: isRequired,
          pattern: pattern && {value: pattern.value, message: pattern.message},
          min: minValue && {
            value: minValue,
            message: `Число должно быть больше ${minValue}`,
          },
          max: maxValue && {
            value: maxValue,
            message: `Число должно быть меньше ${maxValue}`,
          },
        }}
        shouldUnregister={shouldUnregister}
        type={type === 'checkBox' && 'checkbox'}
      />
    </Grid>
  );
};
Field.propTypes = {
  type: oneOf(['autocomplete', 'input', 'upload', 'mask', 'datePicker', 'dateTimePicker', 'checkBox']),
  md: number,
  name: string.isRequired,
  onChange: func,
  label: oneOfType([string, node]),
  multiple: bool,
  isLoading: bool,
  shouldUnregister: bool,
  multiline: bool,
  disabled: bool,
  accept: string,
  isRequired: bool,
  isSelectableAll: bool,
  isUploadToStorage: bool,
  pattern: oneOfType([string, shape({value: string, message: string})]),
  hasHandleWheel: bool,
};
export default memo(Field);
