import React from 'react';

function Search() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M18.2962 17.418L13.4736 12.5656C14.7642 11.2541 15.4434 9.51639 15.4434 7.71311C15.4434 3.7459 12.0811 0.5 7.9717 0.5C3.86226 0.5 0.5 3.7459 0.5 7.71311C0.5 11.6803 3.86226 14.9262 7.9717 14.9262C9.60189 14.9262 11.1981 14.4016 12.4887 13.4508L17.3453 18.3033C17.4811 18.4344 17.6509 18.5 17.8208 18.5C17.9906 18.5 18.1604 18.4344 18.2962 18.3033C18.4321 18.2049 18.5 18.041 18.5 17.877C18.5 17.7131 18.4321 17.5492 18.2962 17.418ZM7.9717 1.81148C11.334 1.81148 14.0849 4.46721 14.0849 7.71311C14.0849 10.959 11.334 13.6148 7.9717 13.6148C4.60943 13.6148 1.85849 10.959 1.85849 7.71311C1.85849 4.46721 4.60943 1.81148 7.9717 1.81148Z"
          fill="#CAB791"
        />
      </g>
    </React.Fragment>
  );
}
export default Search;
