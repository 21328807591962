import Field from '@common/FormField';
import {getOptionLabel, isOptionEqualToValue} from '@components/apps/MaterialsModule/helpers';
import {useSchool} from '@hooks/filters';
import React from 'react';

const SchoolSelect = () => {
  const school = useSchool({keepOriginalValues: true});
  return (
    <Field
      isRequired
      multiple
      getOptionLabel={getOptionLabel}
      isLoading={school.isLoading}
      isOptionEqualToValue={isOptionEqualToValue}
      label="Школа"
      limitTags={1}
      name="schools"
      options={school.options}
    />
  );
};

export default SchoolSelect;
