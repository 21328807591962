import Field from '@common/FormField';
import {gameMock} from '@components/apps/Tournaments/components/TournamentView/constants';
import {PlayoffItem} from '@components/apps/Tournaments/components/TournamentView/types';
import useDeepMemo from '@hooks/useDeepMemo';
import AddIcon from '@mui/icons-material/Add';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {Theme, useMediaQuery} from '@mui/material';
import {TeamDto} from '@src/types/generated';
import {Box, Button, Divider, IconButton, Stack, TextField, Typography} from '@ui/MUI';
import {get, isEmpty} from 'lodash';
import React, {memo, useCallback} from 'react';
import {Controller, FormProvider, useFieldArray, useFormContext, useWatch} from 'react-hook-form';

type RequiredFields = 'date' | 'team1' | 'team2' | 'score' | 'isBull' | 'isOT';

type GameFieldProps = {
  name: string;
  minLength: number;
  maxLength?: number;
  canAdd?: boolean;
  requiredFields?: RequiredFields[];
  teams: Array<TeamDto>;
  isTeamsLoading: boolean;
};
function GameField({name, minLength, maxLength, requiredFields, teams = [], isTeamsLoading}: GameFieldProps) {
  const isSmallDesktopRes = useMediaQuery((theme: Theme) => theme.breakpoints.down('smallLaptop'));
  const form = useFormContext();
  const {control} = form;
  const {fields, append, remove} = useFieldArray({
    control,
    rules: {maxLength},
    name,
  });
  const isRequired = useCallback((name: RequiredFields) => requiredFields?.includes(name), [requiredFields]);
  const handleAppend = useCallback(() => append(gameMock), [append]);
  const gameFieldState: PlayoffItem = useWatch({control, name});
  const filteredOptions = useDeepMemo(() => {
    const pickedTeams: Array<TeamDto> = [];
    if (!isEmpty(gameFieldState)) {
      const {team1, team2} = gameFieldState;
      team1?.id && pickedTeams.push(team1);
      team2?.id && pickedTeams.push(team2);
    }
    return teams.filter(
      (option) =>
        !pickedTeams?.some((team) => {
          return option?.id === team?.id;
        }),
    );
  }, [teams, gameFieldState]);
  return (
    <FormProvider {...form}>
      <Stack gap={2}>
        {fields?.map((field, index) => (
          <Stack direction="column" gap={2} key={field.id} width="100%">
            <Stack
              alignItems="flex-end"
              direction="row"
              divider={
                isSmallDesktopRes ? null : <Divider flexItem orientation="vertical" sx={{borderStyle: 'dashed'}} />
              }
              flexWrap="wrap"
              gap={4}
              key={index}
            >
              <Stack alignItems="flex-end" direction="row" sx={{flex: {xxs: 1, lg: 0.8}}}>
                <Typography mr={3} variant="subtitle1">
                  {index + 1}.
                </Typography>
                <Box minWidth={135} mr={5}>
                  <Field
                    shouldUnregister
                    disablePast={false}
                    helperText="Заполните поле"
                    isRequired={isRequired('date')}
                    label="Дата игры"
                    name={`${name}.${index}.date`}
                    size="small"
                    type="dateTimePicker"
                    variant="standard"
                  />
                </Box>
                <Box maxWidth={180} mr={4} width="100%">
                  <Field
                    shouldUnregister
                    helperText="Заполните поле"
                    isLoading={isTeamsLoading}
                    isRequired={isRequired('team1')}
                    label="Команда"
                    name={`${name}.${index}.team1`}
                    options={filteredOptions}
                    type="autocomplete"
                    validate={(value: TeamDto, formState: FormData) => {
                      if (value?.id && get(formState, `${name}.${index}.team2.id`) === value?.id) {
                        return ' ';
                      }
                    }}
                  />
                </Box>
                <Box maxWidth={180} width="100%">
                  <Field
                    shouldUnregister
                    helperText="Заполните поле"
                    isLoading={isTeamsLoading}
                    isRequired={isRequired('team2')}
                    label="Команда"
                    name={`${name}.${index}.team2`}
                    options={filteredOptions}
                    type="autocomplete"
                    validate={(value: TeamDto, formState: FormData) => {
                      if (value?.id && get(formState, `${name}.${index}.team1.id`) === value?.id) {
                        return 'Ошибка';
                      }
                    }}
                  />
                </Box>
              </Stack>
              <Stack alignItems="center" direction="row" gap={2} sx={{flex: 0.8}}>
                <Stack alignItems="center" direction="row" gap={3}>
                  <Box width={45}>
                    <Controller
                      name={`${name}.${index}.score1`}
                      render={({field, fieldState: {error}}) => (
                        <TextField
                          InputProps={{inputProps: {min: 0}}}
                          error={!!error}
                          helperText={error?.message}
                          label="Счет"
                          type="number"
                          {...field}
                          onChange={(event) => {
                            if (event?.target?.value) {
                              return field.onChange(
                                Number(event?.target?.value) <= 0 ? 0 : Number(event?.target?.value).toString(),
                              );
                            }
                            return field.onChange(event?.target?.value);
                          }}
                        />
                      )}
                      rules={{required: isRequired('score') && 'Заполните'}}
                    />
                  </Box>
                  <Typography variant="body1">-</Typography>
                  <Box width={45}>
                    <Controller
                      name={`${name}.${index}.score2`}
                      render={({field, fieldState: {error}}) => (
                        <TextField
                          InputProps={{inputProps: {min: 0}}}
                          error={!!error}
                          helperText={error?.message}
                          label="Счет"
                          type="number"
                          {...field}
                          onChange={(event) => {
                            if (event?.target?.value) {
                              return field.onChange(
                                Number(event?.target?.value) <= 0 ? 0 : Number(event?.target?.value).toString(),
                              );
                            }
                            return field.onChange(event?.target?.value);
                          }}
                        />
                      )}
                      rules={{required: isRequired('score') && 'Заполните'}}
                    />
                  </Box>
                </Stack>
                <Box>
                  <Field
                    shouldUnregister
                    isRequired={isRequired('isOT')}
                    label="ОТ"
                    name={`${name}.${index}.isOT`}
                    type="checkBox"
                  />
                </Box>
                <Box>
                  <Field
                    shouldUnregister
                    isRequired={isRequired('isBull')}
                    label="БУЛЛ"
                    name={`${name}.${index}.isBull`}
                    type="checkBox"
                  />
                </Box>
                <IconButton
                  disabled={fields?.length <= minLength && index + 1 <= minLength}
                  size="small"
                  sx={{opacity: fields?.length <= minLength && index + 1 <= minLength ? 0 : 1}}
                  onClick={() => remove(index)}
                >
                  <CloseOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Button
        color="primary"
        disabled={fields?.length === maxLength}
        size="small"
        startIcon={<AddIcon />}
        sx={{mt: 3}}
        variant="text"
        onClick={handleAppend}
      >
        Добавить
      </Button>
    </FormProvider>
  );
}

export default memo(GameField);
