import React from 'react';

function Plus() {
  return (
    <React.Fragment>
      <g>
        <path
          clipRule="evenodd"
          d="M5.75 0C5.47386 0 5.25 0.223858 5.25 0.5V5.25H0.5C0.223858 5.25 0 5.47386 0 5.75V6.25C0 6.52614 0.223858 6.75 0.5 6.75H5.25V11.5C5.25 11.7761 5.47386 12 5.75 12H6.25C6.52614 12 6.75 11.7761 6.75 11.5V6.75H11.5C11.7761 6.75 12 6.52614 12 6.25V5.75C12 5.47386 11.7761 5.25 11.5 5.25H6.75V0.5C6.75 0.223858 6.52614 0 6.25 0H5.75Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
    </React.Fragment>
  );
}
export default Plus;
