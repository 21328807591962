import DashboardBlock from '@components/apps/StudentDashboard/components/DashboardBlock';
import EmojiIcon from '@components/apps/StudentDashboard/components/EmojiIcon';
import Questionnaire from '@components/apps/StudentDashboard/components/Questionnaire';
import {updateDiaryEntity, updateDiaryEntityDayOff} from '@components/apps/StudentDashboard/helpers';
import date from '@helpers/date';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import CircleIcon from '@mui/icons-material/Circle';
import {useMediaQuery} from '@mui/material';
import {selectPlayerDiary} from '@selectors/playerDiary';
import {QUESTIONNAIRE_QUESTION_TYPES} from '@src/constants/playerDiary';
import {Button, Chip, Divider, Stack, Typography} from '@ui/MUI';
import CircularProgress from '@ui/MUI/CircularProgress';
// eslint-disable-next-line
import {isNull} from 'lodash';
import React, {useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

const entity = 'morning';
const questions = [
  {
    category: {
      label: '',
      value: entity,
    },
    subCategories: [
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.sleep,
        isRequired: true,
        nested: true,
      },
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.mood,
        isRequired: true,
        nested: true,
      },
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.willToTraining,
        isRequired: true,
        nested: true,
      },
    ],
  },
];

function MorningDiary() {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {data, ...restDiary} = useStore(selectPlayerDiary);
  const isLoading = useMemo(() => {
    return restDiary?.isLoading || data?.[entity]?.isLoading;
  }, [restDiary?.isLoading, data?.[entity]?.isLoading]);
  const isDayOff = useMemo(() => data?.[entity]?.isDayOff, [data?.[entity]?.isDayOff]);

  const methods = useForm();
  const dispatch = useStoreDispatch();
  const {reset, handleSubmit} = methods;
  const [isButtonBlocked, setIsButtonBlocked] = useState(false);

  const controlButtons = useMemo(() => {
    if (isLoading) {
      return <CircularProgress color="primary" size={20} />;
    }
    if (isDayOff) {
      return (
        <>
          <Divider sx={{my: 3}} />

          <Button
            color="primary"
            data-testid="reset-button"
            variant="text"
            onClick={() => updateDiaryEntityDayOff(data, false, entity, dispatch)}
          >
            Вернуться к заполнению
          </Button>
        </>
      );
    }
    if (!data?.[entity] || isNull(data?.[entity]?.sleep)) {
      return (
        <Stack
          alignItems={{xxs: 'stretch', mobileM: 'center'}}
          direction={{xxs: 'column', mobileM: 'row'}}
          spacing={{xxs: 2, xs: 4}}
        >
          <Button color="primary" data-testid="fill-button" variant="contained" onClick={() => setIsOpen(true)}>
            заполнить
          </Button>
          <Button
            color="secondary"
            data-testid="skip-button"
            variant="contained"
            onClick={() => updateDiaryEntityDayOff(data, true, entity, dispatch)}
          >
            Сегодня выходной
          </Button>
        </Stack>
      );
    }
    return (
      <Chip
        data-testid="filled-state-indicator"
        icon={<CircleIcon fontSize="inherit" sx={(theme) => ({fill: theme.palette.success.main})} />}
        label="Заполнено"
        variant="outlined"
      />
    );
  }, [isLoading, isDayOff]);

  return (
    <DashboardBlock
      title={
        <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={1}>
          <Typography variant="h2">Твой дневник. Утро</Typography>
          <Typography variant="subtitle2">{date().innerDate.format('DD.MM.YYYY, dd')}</Typography>
        </Stack>
      }
    >
      {data?.[entity]?.isDayOff ? (
        <Typography data-testid="filled-title" mb={2} variant="subtitle1">
          Хорошего выходного дня и настроения! <EmojiIcon name="sun" />
        </Typography>
      ) : (
        <Typography data-testid="not-filled-title" mb={2} variant="subtitle1">
          Доброе утро!{' '}
          {isMobile && (
            <>
              <EmojiIcon name="sun" />
              <br />
            </>
          )}
          Как твое самочувствие? {!isMobile && <EmojiIcon name="sun" />}
        </Typography>
      )}
      {controlButtons}

      <FormProvider {...methods}>
        <Questionnaire
          setIsButtonBlocked
          content={questions}
          isButtonBlocked={isButtonBlocked}
          open={isOpen}
          title="Анкета. Утро"
          onClose={() => {
            setIsOpen(false);
            reset({});
          }}
          onSubmit={handleSubmit((formValues) => {
            setIsButtonBlocked(true);
            updateDiaryEntity(formValues, data, entity, dispatch, () => {
              setIsButtonBlocked(false);
              setIsOpen(false);
            });
          })}
        />
      </FormProvider>
    </DashboardBlock>
  );
}

export default MorningDiary;
