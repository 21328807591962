import {useMemo, useState} from 'react';

import useDebounce from './useDebounce';

const useSearch = (ms = 300) => {
  const [search, setSearch] = useState('');
  const debounsedValue = useDebounce(search, ms);

  return useMemo(() => ({search, debounsedValue, updateValue: setSearch}), [search, debounsedValue, setSearch]);
};

export default useSearch;
