import EstimateControlDate from '@common/DevelopmentJournal/components/EstimateControlDate';
import EstimateNumber from '@common/DevelopmentJournal/components/EstimateNumber';
import {PageWrapper} from '@common/Page';
import ReactTableComponent from '@common/ReactTableComponent';
import {getPersonalDevelopmentJournalPlayerSkillsMock} from '@src/mocks/api/skill/responseMock';
import {Stack, Typography} from '@ui/MUI';
import React from 'react';

function Skills({skills, isLoad, isLoading}) {
  const columns = [
    {
      accessorKey: 'skill.name',
      header: 'Навык, группа',
      cell: ({getValue, row}) => {
        return (
          <>
            <Typography
              data-testid="category-name"
              isLoading={isLoading}
              preloaderWidthBetween={[70, 100]}
              variant="subtitle1"
              whiteSpace="pre-line"
            >
              {row.original.skill.category.name}
            </Typography>
            <Typography
              data-testid="skill-name"
              isLoading={isLoading}
              preloaderWidthBetween={[70, 100]}
              variant="body2"
              whiteSpace="pre-line"
            >
              {getValue()}
            </Typography>
          </>
        );
      },
      headerStyle: {width: 200, maxWidth: 200},
      cellStyle: {width: 200, maxWidth: 200, verticalAlign: 'baseline'},
    },
    {
      accessorKey: 'score',
      header: 'Текущий результат',
      cell: ({getValue}) => {
        return (
          <EstimateNumber data-testid="score" isLoading={isLoading} isPrimary={true}>
            {getValue()}
          </EstimateNumber>
        );
      },
      headerStyle: {width: 150, maxWidth: 150},
      cellStyle: {verticalAlign: 'baseline', width: 150, maxWidth: 150},
    },
    {
      accessorKey: 'expectedScore',
      header: 'Целевой результат',
      cell: ({getValue}) => {
        return (
          <EstimateNumber data-testid="expected-score" isLoading={isLoading} isPrimary={true}>
            {getValue()}
          </EstimateNumber>
        );
      },
      headerStyle: {width: 150, maxWidth: 150},
      cellStyle: {verticalAlign: 'baseline', minWidth: 150, maxWidth: 150},
    },
    {
      accessorKey: 'todo',
      header: 'Что нужно исправить/улучшить',
      cell: ({getValue}) => {
        return (
          <Typography data-testid="todo" isLoading={isLoading} preloaderWidthBetween={[70, 100]} variant="body1">
            {getValue()}
          </Typography>
        );
      },
      cellStyle: {verticalAlign: 'baseline', width: 300, whiteSpace: 'pre-line !important'},
    },
    {
      accessorKey: 'plan',
      header: 'План работы с игроком',
      cell: ({getValue}) => {
        return (
          <Typography data-testid="plan" isLoading={isLoading} preloaderWidthBetween={[70, 100]} variant="body1">
            {getValue()}
          </Typography>
        );
      },
      cellStyle: {verticalAlign: 'baseline', width: 300, whiteSpace: 'pre-line !important'},
    },
    {
      accessorKey: 'controlDate',
      header: 'Срок выполнения',
      cell: ({getValue}) => {
        return <EstimateControlDate data-testid="control-date" date={getValue()} />;
      },
      headerStyle: {width: 140, maxWidth: 140},
      cellStyle: {width: 140, maxWidth: 140, verticalAlign: 'baseline'},
    },
  ];
  const archivedColumns = [
    {
      accessorKey: 'skill.name',
      header: 'Навык, группа',
      cell: ({getValue, row}) => {
        return (
          <>
            <Typography
              data-testid="archived-category-name"
              isLoading={isLoading}
              preloaderWidthBetween={[70, 100]}
              variant="subtitle1"
              whiteSpace="pre-line"
            >
              {row.original.skill.category.name}
            </Typography>
            <Typography
              data-testid="archived-skill-name"
              isLoading={isLoading}
              preloaderWidthBetween={[70, 100]}
              variant="body2"
              whiteSpace="pre-line"
            >
              {getValue()}
            </Typography>
          </>
        );
      },
      headerStyle: {width: 200, maxWidth: 200},
      cellStyle: {width: 200, maxWidth: 200, verticalAlign: 'baseline'},
    },
    {
      accessorKey: 'score',
      header: 'Исходный результат',
      cell: ({getValue}) => {
        return (
          <EstimateNumber data-testid="archived-score" isArchived={true} isLoading={isLoading} isPrimary={false}>
            {getValue()}
          </EstimateNumber>
        );
      },
      headerStyle: {width: 150, maxWidth: 150},
      cellStyle: {verticalAlign: 'baseline', width: 150, maxWidth: 150},
    },
    {
      accessorKey: 'completedScore',
      header: 'Итоговый результат',
      cell: ({getValue, row}) => {
        return (
          <>
            <EstimateNumber data-testid="completed-score" isLoading={isLoading} isPrimary={true}>
              {getValue()}
            </EstimateNumber>
            <Typography
              data-testid="archived-expected-score"
              isLoading={isLoading}
              maxWidth={60}
              mt={2}
              preloaderWidthBetween={[70, 100]}
              variant="caption2"
              whiteSpace="pre-line"
            >
              Целевой рез-т: {row.original.expectedScore}
            </Typography>
          </>
        );
      },
      headerStyle: {width: 150, maxWidth: 150},
      cellStyle: {verticalAlign: 'baseline', width: 150, maxWidth: 150},
    },
    {
      accessorKey: 'todo',
      header: 'Что нужно исправить/улучшить',
      headerStyle: {width: 300, maxWidth: 300},
      cellStyle: {verticalAlign: 'baseline', width: 300, maxwidth: 300, whiteSpace: 'pre-line !important'},
      cell: ({getValue}) => (
        <Typography data-testid="archived-todo" isLoading={isLoading} preloaderWidthBetween={[70, 100]} variant="body1">
          {getValue()}
        </Typography>
      ),
    },
    {
      accessorKey: 'plan',
      header: 'План работы с игроком',
      headerStyle: {width: 300, maxWidth: 300},
      cellStyle: {verticalAlign: 'baseline', width: 300, maxWidth: 300, whiteSpace: 'pre-line !important'},
      cell: ({getValue}) => (
        <Typography data-testid="archived-plan" isLoading={isLoading} preloaderWidthBetween={[70, 100]} variant="body1">
          {getValue()}
        </Typography>
      ),
    },
    {
      accessorKey: 'controlDate',
      header: 'Срок выполнения',
      cell: ({getValue}) => {
        return <EstimateControlDate data-testid="archived-control-date" date={getValue()} />;
      },
      headerStyle: {width: 140, maxWidth: 140},
      cellStyle: {width: 140, maxWidth: 140, verticalAlign: 'baseline'},
    },
  ];
  return (
    <>
      <Typography color="primary.contrastText" mb="0px" variant="h3" width="100%">
        Актуальные навыки
      </Typography>
      <PageWrapper sx={{p: '0px'}} width="100%">
        {!skills?.active?.length ? (
          <Stack px={4} py={9} spacing={5}>
            <Typography textAlign="center" variant="subtitle2" width="100%">
              Навыки для развития еще не назначены.
            </Typography>
          </Stack>
        ) : (
          <Stack pb="0px" spacing={5}>
            <ReactTableComponent
              columns={columns}
              data={isLoad && !isLoading ? skills?.active : getPersonalDevelopmentJournalPlayerSkillsMock?.active}
            />
          </Stack>
        )}
      </PageWrapper>
      <Typography color="primary.contrastText" mb="0px" variant="h3" width="100%">
        Завершенные навыки
      </Typography>
      <PageWrapper sx={{p: '0px'}} width="100%">
        {!skills?.finished?.length ? (
          <Stack px={4} py={9} spacing={5}>
            <Typography textAlign="center" variant="subtitle2" width="100%">
              У игрока еще нет завершенных навыков.
            </Typography>
          </Stack>
        ) : (
          <Stack pb="0px" spacing={5}>
            <ReactTableComponent
              columns={archivedColumns}
              data={isLoad && !isLoading ? skills?.finished : getPersonalDevelopmentJournalPlayerSkillsMock?.finished}
              isAvailableHeight={false}
            />
          </Stack>
        )}
      </PageWrapper>
    </>
  );
}

export default Skills;
