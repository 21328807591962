import Field from '@common/FormField';
import {Stack} from '@mui/material';
import {Box} from '@ui/MUI';
import React from 'react';
import {useWatch} from 'react-hook-form';

const Description = () => {
  const type = useWatch({name: 'type'});
  return type ? (
    <Stack spacing={4}>
      <Box width="100%">
        <Field isRequired label="Название" name="name" type="input" />
      </Box>
      <Box width="100%">
        <Field label="Описание" name="description" type="input" />
      </Box>
    </Stack>
  ) : null;
};

export default Description;
