import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;
export const TrainerAvatar = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url(${(props) => props.src}) no-repeat center;
  background-size: cover;
`;
export const ChipContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 36px;
  padding-right: 8px;
  min-height: 24px;

  ${TrainerAvatar} {
    width: 24px;
    height: 24px;
  }
`;
