import LoadingBox from '@common/LoadingBox';
import EditableCell from '@components/apps/NewBodyMassIndex/components/Norms/components/EditableCell';
import Table from '@components/apps/NewBodyMassIndex/components/Norms/components/Table';
import {mock} from '@components/apps/NewBodyMassIndex/mock';
import {useGroupAges} from '@hooks/useGroupAges';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectNorms} from '@selectors/bodyMassIndex';
import {clearNorms, editNorms, loadNorms} from '@slices/bodyMassIndex';
import {longDash, userRoles} from '@src/constants';
import {Autocomplete, Box, Button, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import LoadingButton from '@ui/MUI/LoadingButton';
import React, {useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import {Wrapper} from '../../BodyMassIndex';

function Norms(props) {
  const ages = useGroupAges();
  const dispatch = useStoreDispatch();
  const {data, isLoading, isLoad, error} = useStore(selectNorms);
  const canEdit = useStore((store) => store.users.currentUser?.userRole === userRoles.doctor);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAge, setSelectedAge] = useState(null);

  const methods = useForm();
  const {
    reset,
    control,
    formState: {isDirty},
  } = methods;

  const columns = useMemo(
    () => [
      {
        header: 'Тип телосложения',
        enableRowSpan: true,
        columns: [
          {
            header: 'Not visible header 2',
            accessorKey: 'typeOfBody.name',
            headerStyle: {display: 'none'},
            cell: (params) => {
              const value = params?.getValue();
              return (
                <Typography color={grey['68']} isLoading={isLoading} preloaderWidthBetween={[40, 100]} variant="body2">
                  {value ? value : longDash}
                </Typography>
              );
            },
          },
        ],
        rowSpan: '2',
      },
      {
        header: '% жира',
        accesorKey: 'fatProcent',
        headerStyle: {textAlign: 'center'},
        columns: [
          {
            accessorKey: 'fatProcent.firstHalf',
            header: 'Первое полугодие',
            headerStyle: {textAlign: 'center'},
            cellStyle: {textAlign: 'center'},
            cell: (params) => {
              return <EditableCell {...params} control={control} isEdit={isEdit} isLoading={isLoading} />;
            },
          },
          {
            accessorKey: 'fatProcent.secondHalf',
            header: 'Второе полугодие',
            headerStyle: {textAlign: 'center'},
            cellStyle: {textAlign: 'center'},
            cell: (params) => <EditableCell {...params} control={control} isEdit={isEdit} isLoading={isLoading} />,
          },
        ],
      },
      {
        header: 'ИМТ',
        headerStyle: {textAlign: 'center'},
        columns: [
          {
            accessorKey: 'bmi.firstHalf',
            header: 'Первое полугодие',
            headerStyle: {textAlign: 'center'},
            cellStyle: {textAlign: 'center'},
            cell: (params) => <EditableCell {...params} control={control} isEdit={isEdit} isLoading={isLoading} />,
          },
          {
            accessorKey: 'bmi.secondHalf',
            header: 'Второе полугодие',
            headerStyle: {textAlign: 'center'},
            cellStyle: {textAlign: 'center'},
            cell: (params) => <EditableCell {...params} control={control} isEdit={isEdit} isLoading={isLoading} />,
          },
        ],
      },
    ],
    [isEdit, isLoading],
  );
  useEffect(() => {
    selectedAge?.value && dispatch(loadNorms(selectedAge?.value, reset));
    return () => {
      dispatch(clearNorms());
    };
  }, [selectedAge?.value]);

  return (
    <FormProvider {...methods}>
      <Wrapper mb={0} p={{xxs: 2, sm: 4}}>
        <Stack
          alignItems={{xxs: 'start', sm: 'center'}}
          direction={{xxs: 'column', sm: 'row'}}
          justifyContent="space-between"
          spacing={1}
          width="100%"
        >
          <Box width={{xxs: '100%', sm: 220}}>
            <Autocomplete
              data-testid="ages-list"
              disabled={ages.isLoading}
              isLoading={ages.isLoading}
              label="Возрастная группа"
              multiple={false}
              options={ages.data}
              value={selectedAge}
              onChange={(_, value) => {
                setIsEdit(false);
                setSelectedAge(value);
              }}
            />
          </Box>
          {canEdit && !isEdit && selectedAge && (
            <Button
              color="primary"
              data-testid="edit-button"
              disabled={isLoading}
              variant="contained"
              onClick={() => setIsEdit(true)}
            >
              Редактировать
            </Button>
          )}
        </Stack>
      </Wrapper>
      {selectedAge ? (
        <Wrapper mb={0}>
          <LoadingBox isLoading={isEditing}>
            <Table columns={columns} data={isLoading ? mock : data?.fields} error={error} isLoad={isLoad} />
          </LoadingBox>
        </Wrapper>
      ) : (
        <Wrapper alignItems="center" data-testid="no-data" display="flex" height={400} justifyContent="center" mb={0}>
          <Typography variant="subtitle1">Для отображения данных заполните фильтры</Typography>
        </Wrapper>
      )}

      {isEdit && (
        <Wrapper px={{xxs: 2, sm: 4}} py={{xxs: 1, sm: 2}}>
          <Stack alignItems="center" direction="row" gap={1} justifyContent="space-between">
            <LoadingButton
              color="primary"
              data-testid="save-button"
              disabled={!isDirty}
              loading={isEditing}
              variant="contained"
              onClick={() => {
                methods.handleSubmit((formData) => {
                  setIsEditing(true);
                  const result = {
                    ...formData,
                    fields: formData.fields?.map((item) => ({...item, typeOfBody: item.typeOfBody.value})),
                  };
                  dispatch(
                    editNorms(
                      result,
                      () => setIsEditing(false),
                      () => setIsEdit(false),
                    ),
                  );
                })();
              }}
            >
              Сохранить
            </LoadingButton>
            <Button
              color="primary"
              disabled={isEditing}
              variant="outlined"
              onClick={() => {
                reset(data);
                setIsEdit(false);
              }}
            >
              Отменить
            </Button>
          </Stack>
        </Wrapper>
      )}
      {props?.children && React.cloneElement(props.children, {setParentState: setSelectedAge})}
    </FormProvider>
  );
}

export default Norms;
