import LocalPreloader from '@components/preloader/LocalPreloader';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {IconButton} from '@mui/material';
import {loadTodaySchedule} from '@slices/managerDashboard';
import {DEFAULT_DATE_SEND_FORMAT, GAME_TYPES, longDash} from '@src/constants';
import Box from '@ui/MUI/Box';
import Link from '@ui/MUI/Link';
import DatePickerMobile from '@ui/MUI/MobileDatePicker';
import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import Stack from '@ui/MUI/Stack';
import TabsDefault from '@ui/MUI/Tabs';
import TypographyDefault from '@ui/MUI/Typography';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {BoxShadow, TitleText} from '../../ManagerDashboard';
import Card from '../Card';
import LessonView from '../LessonView';
import NoData from '../NoData';

const third = 100 / 3;
const headlines = [
  {
    title: 'Время',
    width: {xxs: '50%', sm: third + '%'},
  },
  {title: 'Тип мероприятия', width: {xxs: '100%', sm: third + '%'}, order: {xxs: 3, sm: 2}},
  {
    title: 'Помещение',
    width: {xxs: '50%', sm: third + '%'},
    order: {xxs: 2, sm: 3},
    textAlign: 'right',
  },
];
export default function Schedule({groupSelect}) {
  const [date, setDate] = useState(moment());
  const [groupId, setGroupId] = useState({index: 0, id: groupSelect?.groups?.[0]?.id});
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });
  const [watchInfoIndex, setWatchInfoIndex] = useState(null);
  const dispatch = useDispatch();
  const {data, isLoad, isLoading, error} = useSelector((state) => state.managerDashboard.todaySchedule);

  useEffect(() => {
    groupId.id &&
      dispatch(
        loadTodaySchedule({
          scheduleFilter: groupId.id,
          scheduleDate: moment(date).format(DEFAULT_DATE_SEND_FORMAT),
        }),
      );
  }, [date, groupId]);

  useEffect(() => {
    groupSelect?.groups?.length && setGroupId({index: 0, id: groupSelect?.groups?.[0]?.id});
  }, [groupSelect.groups]);

  return (
    <>
      <Card
        height={(isLoading || groupSelect?.loading) && '250px'}
        titleComponent={
          <Stack direction="row" justifyContent="space-between">
            <TitleText variant="h2">расписание на сегодня</TitleText>
            <Stack alignItems="center" direction="row" flexShrink="0">
              <IconButton color="darkText" size="small" onClick={() => setDate(moment(date).add(-1, 'days'))}>
                <ChevronLeftIcon />
              </IconButton>
              <TitleText cursor="pointer" variant="h2" onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
                {moment(date).format('DD MMMM, dd')}
              </TitleText>
              <IconButton color="darkText" size="small" onClick={() => setDate(moment(date).add(1, 'days'))}>
                <ChevronRightIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <LocalPreloader visible={isLoading || groupSelect?.loading} />
        <Box>
          <DatePickerMobile
            allowSameDateSelection={false}
            closeDataPicker={() => setIsDatePickerOpen(false)}
            handleChange={(value) => {
              value && setDate(value);
              setIsDatePickerOpen(false);
            }}
            isDatePickerOpen={isDatePickerOpen}
            okText=""
            value={moment(date)}
          />
          <BoxShadow>
            <TabsDefault
              tabs={{
                headlineItems: groupSelect?.groups?.map((group) => ({
                  label: group.name,
                  value: group.id,
                })) || [{label: ''}],
              }}
              value={groupId.index}
              onChange={(index) => setGroupId({index, id: groupSelect?.groups[index].id})}
            />
            {isLoad && Boolean(data?.length) && (
              <Box p={3} pb={0} pt={2}>
                <Stack direction="row" gap={1} justifyContent="space-between">
                  {headlines.map((item, index) => (
                    <Box display={item.display} key={index} width={item.width}>
                      <TypographyDefault textAlign={item.textAlign} variant="body2">
                        {item.title}
                      </TypographyDefault>
                    </Box>
                  ))}
                </Stack>
              </Box>
            )}
          </BoxShadow>
        </Box>
        {(isLoad && !data?.length) || error ? (
          <NoData>На сегодня нет мероприятий в расписании команды</NoData>
        ) : (
          <Box>
            {data.map(({timeStart, timeEnd, type, room}, index) => (
              <Stack
                alignItems="center"
                backgroundColor={index % 2 !== 0 && '#F3F3F3'}
                direction="row"
                gap={1}
                justifyContent="space-between"
                key={index}
                p={3}
                pb={2}
                pt={2}
                onClick={() => {
                  setWatchInfoIndex(index);
                  setIsModalOpen({...isModalOpen, right: true});
                }}
              >
                <Box overflow="hidden" width={headlines[0].width}>
                  <TypographyDefault fontWeight="600" variant="body2">
                    {`${moment(timeStart).format('HH:mm')}-${moment(timeEnd).format('HH:mm')}`}
                  </TypographyDefault>
                </Box>
                <Box overflow="hidden" width={headlines[1].width}>
                  <TypographyDefault fontWeight="600" variant="body2">
                    {GAME_TYPES[type]}
                  </TypographyDefault>
                </Box>
                <Box overflow="hidden" textAlign="right" width={headlines[2].width}>
                  <TypographyDefault variant="body2">{room || longDash}</TypographyDefault>
                </Box>
              </Stack>
            ))}
          </Box>
        )}
        {groupId?.id && (
          <Box p={3} pb={0} pt={2}>
            <Link to={`/schedule?groupIds=${groupId.id}&viewMode=week`}>Посмотреть расписание на неделю</Link>
          </Box>
        )}
      </Card>
      <DrawerDefault
        content={
          <>
            <TypographyDefault variant="h3">Просмотр мероприятия</TypographyDefault>
            {watchInfoIndex >= 0 && <LessonView {...data[watchInfoIndex]} />}
          </>
        }
        drawerState={isModalOpen}
        setDrawerState={setIsModalOpen}
        onClose={() => {
          setIsModalOpen({...isModalOpen, right: false});
          setWatchInfoIndex(null);
        }}
      />
    </>
  );
}
