import {Theme} from '@mui/material';
import MUIPagination from '@mui/material/Pagination';
import {PaginationProps} from '@mui/material/Pagination/Pagination';
import Stack from '@mui/material/Stack';
import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';

type CustomPagination = {
  justifyContent?: React.CSSProperties['justifyContent'];
  textColor?: React.CSSProperties['color'];
} & PaginationProps;

const CustomizedPagination = styled((props: CustomPagination) => (
  <>
    <GlobalStyle justifyContent={props.justifyContent} textColor={props.textColor} />
    <Stack spacing={2}>
      <MUIPagination {...props} />
    </Stack>
  </>
))``;

export default function Pagination({color = 'primary', justifyContent, ...props}: CustomPagination) {
  return <CustomizedPagination color={color} {...props} justifyContent={justifyContent} />;
}

const GlobalStyle = createGlobalStyle<{theme: Theme} & CustomPagination>`
  .MuiPagination {
    &-root {
      padding: ${({theme}) => theme.spacing(1, '0')};
    }
    &-ul {
      justify-content: ${(props) => props.justifyContent || 'center'};  
    }
  }
  .MuiPaginationItem-root {
    font-family: "Proxima Nova",serif !important;
    color: #2c2c2c;
    &.Mui-selected {
      color: ${(props) => (props.textColor ? props.textColor : 'white')};
    }
  }
`;
