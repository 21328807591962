import Field from '@common/FormField';
import {getOptionLabel, isOptionEqualToValue} from '@components/apps/MaterialsModule/helpers';
import {useGroup} from '@hooks/filters';
import React from 'react';
import {useWatch} from 'react-hook-form';

const GroupSelect = () => {
  const schoolWatcher = useWatch({name: 'schools'});
  const group = useGroup({
    connectorValue: schoolWatcher,
    keepOriginalValues: true,
  });

  return (
    <Field
      multiple
      getOptionLabel={getOptionLabel}
      isLoading={group.isLoading}
      isOptionEqualToValue={isOptionEqualToValue}
      label="Команда"
      limitTags={1}
      name="groups"
      options={group.options}
    />
  );
};

export default GroupSelect;
