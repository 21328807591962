import ControlledQuestionsList from '@components/apps/StudentDashboard/components/ControlledQuestionsList';
import momentDate from '@helpers/date';
import {Box, Button, Divider, Stack, TextField, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import DrawerNew from '@ui/MUI/DrawerNew';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, FormProvider, useFormContext} from 'react-hook-form';
import styled from 'styled-components';

Questionnaire.propTypes = {
  title: PropTypes.string.isRequired,
  skills: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  gameEstimate: PropTypes.shape({
    lesson: PropTypes.object,
    parameters: PropTypes.array,
    student: PropTypes.object,
  }),
};

const constructGameName = (game) => {
  if (game?.type === 'away_game') {
    return `${game?.opponent} - ${game?.group?.name}`;
  }
  return `${game?.group?.name} - ${game?.opponent}`;
};

function Questionnaire({
  skills = [],
  gameEstimate,
  title,
  open = false,
  onClose,
  date,
  content = [],
  externalContent,
  onSubmit,
  isButtonBlocked,
}) {
  const methods = useFormContext();

  return (
    <FormProvider {...methods}>
      <DrawerNew
        buttons={
          <Stack direction={{xxs: 'column', mobileM: 'row'}} justifyContent="space-between" spacing={1}>
            <Button
              color="primary"
              data-testid="questionnaire-save-button"
              disabled={isButtonBlocked}
              variant="contained"
              onClick={onSubmit}
            >
              Сохранить
            </Button>
            <Button color="secondary" variant="contained" onClick={onClose}>
              Отменить
            </Button>
          </Stack>
        }
        data-testid="edit-drawer"
        open={open}
        title={
          <Box>
            <Typography variant="h3">{title}</Typography>
            <Typography color={grey['68']} mb={4} mt={1} variant="subtitle2">
              {date || momentDate().innerDate.format('DD.MM.YYYY')}
            </Typography>
          </Box>
        }
        onClose={onClose}
      >
        {content.map(({category: {label, ...category}, subCategories}, index) => {
          const isLast = index === content?.length - 1;
          const isIndividualOrGroupTraining =
            content[content?.length - 1]?.category?.value === 'trainingGroup' ||
            content[content?.length - 1]?.category?.value === 'trainingIndividual';
          return (
            <Box key={index}>
              {isLast && isIndividualOrGroupTraining && (
                <Controller
                  name={`${category.value}.comment`}
                  render={({field, fieldState: {error}}) => <TextField {...field} error={error} label="Комментарий" />}
                  rules={{required: false}}
                />
              )}
              {index > 0 && <Divider sx={{my: 4}} />}
              {label && (
                <>
                  <Typography mb={4} variant="h4">
                    {label}{' '}
                  </Typography>
                  <Typography mb={4} variant="h4">
                    {content[content?.length - 1]?.category?.value === 'game' &&
                      index === content?.length - 1 &&
                      gameEstimate?.lesson &&
                      `"${constructGameName(gameEstimate?.lesson)}"`}
                  </Typography>
                </>
              )}
              <Stack direction="column" spacing={4}>
                {isIndividualOrGroupTraining &&
                  index === content?.length - 1 &&
                  skills?.length > 0 &&
                  skills.map(({id, skill}) => (
                    <>
                      <StyledBox
                        key={skill.id}
                        sx={{
                          pl: 2,
                          mb: 3,
                        }}
                      >
                        <Typography display="block" mb={1} variant="subtitle1">
                          {skill.name}
                        </Typography>
                        <Typography>
                          <Typography color={grey['54']} component="span" variant="body2">
                            Группа:{' '}
                          </Typography>
                          <Typography component="span" variant="subtitle2">
                            {skill.category.name}
                          </Typography>
                        </Typography>
                      </StyledBox>
                      <Controller
                        control={methods.control}
                        key={`${content[content?.length - 1]?.category?.value}.comments.${id}`}
                        name={`${content[content?.length - 1]?.category?.value}.comments.${id}`}
                        render={({field: {value, onChange}, fieldState: {error}}) => {
                          return (
                            <TextField
                              multiline
                              error={!!error}
                              helperText={
                                error ? 'Обязательное поле!' : 'Укажите количество или время выполнения упражнения'
                              }
                              label="Что делал для улучшения навыка?"
                              required={true}
                              value={value}
                              onChange={onChange}
                            />
                          );
                        }}
                        rules={{required: true}}
                      />
                    </>
                  ))}
                {subCategories.map(({label, value, questions, nested = false, isRequired = false}, index) => {
                  return (
                    <Controller
                      key={index}
                      name={nested && category?.value ? `${category?.value}.${value}` : value}
                      render={({field, fieldState: {error}}) => (
                        <ControlledQuestionsList {...field} error={error} questions={questions} title={label} />
                      )}
                      rules={{required: isRequired && 'Обязательное поле!'}}
                    />
                  );
                })}
                {isLast && !isIndividualOrGroupTraining && (
                  <Controller
                    name={`${category.value}.comment`}
                    render={({field, fieldState: {error}}) => (
                      <TextField {...field} error={error} label="Комментарий" />
                    )}
                    rules={{required: false}}
                  />
                )}
              </Stack>
            </Box>
          );
        })}
        {externalContent}
      </DrawerNew>
    </FormProvider>
  );
}

const StyledBox = styled(Box)`
  position: relative;
  min-height: 56px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 3px;
    height: 100%;
    background-color: ${({theme}) => theme.palette.primary.main};
    border-radius: 6px;
    content: '';
  }
`;

export default Questionnaire;
