import {useCallback, useEffect, useRef, useState} from 'react';

/*
Кастомный хук который вызывает cb в момент обновления стейта.
----- 
API: 
const [state, setState] = useStateCallBack(initialState);
setState(value, (state) => {
... логика
})
*/

function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb;
    setState(state);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
}

export default useStateCallback;
