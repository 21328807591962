import Field from '@common/FormField';
import CommonHelper from '@helpers/CommonHelper';
import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import React, {memo} from 'react';

function CitizenshipField({isEdit, ...props}) {
  const {citizenships, loading} = useStore((state) => state.users, shallowEqual);

  return (
    <Field
      readOnly={!isEdit}
      size="small"
      textFieldVariant="outlined"
      type="autocomplete"
      {...props}
      isLoading={loading}
      options={citizenships ? CommonHelper.getDropDownItemsByNamedList(citizenships) : []}
    />
  );
}

export default memo(CitizenshipField);
