import {routes} from '@src/routes';
import {Typography} from '@ui/MUI';
import React, {memo, useMemo} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import styled from 'styled-components';

import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';

const PageTitleDefault = ({
  className = '',
  onClick = () => {},
  breadCrumbs = null,
  text = '',
  children = null,
  withOutBreadCrumbs = false,
  isLoading = false,
}) => {
  const {location} = useHistory();
  const pathname = location.pathname;

  const params = useParams();

  const formattedBreadCrumbs = useMemo(() => {
    return breadCrumbs?.map((crumb) => {
      return typeof crumb.label === 'function' ? {...crumb, label: crumb.label(params)} : crumb;
    });
  }, [breadCrumbs]);

  return (
    <Container className={className}>
      <Typography
        withoutWrapper
        color="#fff"
        isLoading={isLoading}
        preloaderWidthBetween={[150, 200]}
        variant="h1"
        onClick={onClick}
      >
        {(typeof text === 'function' ? text(params) : text) || children}
      </Typography>
      {!withOutBreadCrumbs && (
        <BreadCrumbs
          crumbs={pathname === '/' && !breadCrumbs ? [{label: 'Главная', path: null}] : formattedBreadCrumbs || routes}
          dynamicTitle={text || children}
          isLoading={isLoading}
          withRoutingCheck={!breadCrumbs}
        />
      )}
    </Container>
  );
};

export default memo(PageTitleDefault);

const Container = styled.div`
  ${({theme}) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  })}
  display: flex;
  flex-direction: column;
  ${({theme}) => ({
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  })}
  ${({theme}) => theme.breakpoints.down('xs')} {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;
