import 'echarts/lib/chart/radar';

import TooltipDefault from '@ui/MUI/Tooltip';
import * as echarts from 'echarts';
import React, {useEffect, useRef, useState} from 'react';

const tooltipsMatrix = {
  Тревожность: 'Эмоциональность, восприимчивость, незащищённость.',
  Лабильность:
    'Эмотивность, выраженная изменчивость настроения, мотивационная неустойчивость, сентиментальность, стремление к эмоциональной вовлеченности.',
  Экстраверсия:
    'Обращенность в мир реально существующих объектов и ценностей, открытость, стремление к расширению круга контактов, общительность.',
  Спонтанность: 'Непродуманность в высказываниях и поступках.',
  Стеничность: 'Активная самореализация, упрямство и своеволие в отстаивании своих интересов.',
  Ригидность:
    'Инертность, тугоподвижность установок, субъективизм, повышенное стремление к отстаиванию своих взглядов и принципов, критичность в отношении иных мнений.',
  Интроверсия:
    'Обращенность в мир субъективных представлений и переживаний, тенденция к уходу в мир иллюзий, фантазий и субъективных идеальных ценностей сдержанность, замкнутость.',
  Сензитивность: 'Впечатлительность, склонность к рефлексии, пессимистичность в оценке перспектив.',
  ' Реакция на простой стимул ': 'Быстрота реагирования на зрительный стимул.',
  ' Реакция на сложный стимул ': 'Быстрота реагирования на зрительный стимул в ситуации выбора из двух альтернатив.',
  ' Пространственно-временное \nпредвидение  ':
    'Зрительная экстраполяция (в какой точке и в какое время окажется объект).',
  ' Мышление ':
    'Наглядно-действенное мышление в условиях дефицита времени (анализ ситуации и быстроты принятия решения)',
  ' Внимание ': 'Анализ ситуации и быстроты принятия решения.',
};

export default function RadarChart(props) {
  let defaultOption = {
    legend: {
      data: [],
    },
    triggerEvent: true,
    radar: [
      {
        center: ['25%', '50%'],
        radius: 120,
        startAngle: 90,
        splitNumber: 4,
        splitArea: {
          areaStyle: {
            color: [],
          },
        },
      },
    ],
    series: [
      {
        name: 'Option',
        type: 'radar',
        data: [],
      },
    ],
  };

  let {options = {}, categorySelected = () => {}} = props;

  const [tooltip, setTooltip] = useState({isShowed: false});

  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  /**
   * Инициализация графика
   */
  const init = (opt) => {
    // eslint-disable-next-line import/namespace
    const chart = echarts.init(chartRef.current, null, {renderer: 'svg'});

    chart.setOption(opt ? opt : defaultOption);
    setChartInstance(chart);
    chart.on('click', {targetType: 'axisName'}, function (params) {
      categorySelected(params);
    });
    chart.on('mousemove', 'radar', function (e) {
      setTooltip({isShowed: true, text: tooltipsMatrix[e.name]});
    });
    chart.on('mouseout', 'radar', function () {
      setTooltip({isShowed: false});
    });
  };
  useEffect(() => {
    init();
  }, []);
  /**
   * Реинициализация графика
   */
  const reInit = (options) => {
    if (!chartInstance) return;
    chartInstance.dispose();
    init(options);
  };

  useEffect(() => {
    reInit(options);
  }, [options]);

  return (
    <>
      <div
        className={props.className || 'RadarChart'}
        style={{
          background: '#fff',
          textAlign: 'center',
          width: '100%',
          height: '100%',
          minHeight: window.innerWidth >= 481 ? 535 : 380,
        }}
      >
        <TooltipDefault
          arrow={false}
          followCursor={true}
          open={tooltip?.isShowed && tooltip?.text?.length > 0}
          title={tooltip?.text}
          onClose={() => setTooltip({isShowed: false})}
          onOpen={() => setTooltip({isShowed: true, text: tooltip?.text})}
        >
          <div
            id="radar"
            ref={chartRef}
            style={{
              width: '80%',
              height: '100%',
              minHeight: window.innerWidth >= 481 ? 535 : 380,
            }}
          ></div>
        </TooltipDefault>
      </div>
    </>
  );
}
