import {useCallback, useLayoutEffect, useRef} from 'react';
// оригинальный реквест и более подробное обьяснение от Дэна обрамова https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md

const useEvent = (handler) => {
  const handleRef = useRef(null);
  // при каждом ре-рендере записываем новую функцию которая приходит в аргументы в реф
  useLayoutEffect(() => {
    handleRef.current = handler;
  });

  // возвращаем каждый раз одну и туже функцию
  return useCallback((...args) => {
    const fn = handleRef.current;
    // вызываем новую функцию с обновленным контекстом
    fn(...args);
  }, []);
};

export default useEvent;
