import NumberInput from '@common/NumberInput';
import {Box, Stack, Typography} from '@ui/MUI';
import React from 'react';

const InputMax = ({max, width, onChange, value, maxLength, endSlot}) => {
  return (
    <Stack alignItems="center" direction="row">
      <Box mr={1} width={width}>
        <NumberInput alignCenter max={max} maxLength={maxLength} min={1} value={value} onChange={onChange} />
      </Box>
      {max && <Typography>/ {max}</Typography>}
      {endSlot && endSlot}
    </Stack>
  );
};

export default InputMax;
