import {Stack} from '@ui/MUI';
import React from 'react';

import {Filters} from './components/Filters';
import Tabs from './components/Tabs';

const CategoryEstimateTab = () => {
  return (
    <Stack spacing={3}>
      <Filters />
      <Tabs />
    </Stack>
  );
};

export default CategoryEstimateTab;
