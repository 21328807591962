import {useEstimateMutation, useModal} from '@hooks/skill';
import {Button, Stack} from '@ui/MUI';
import LoadingButton from '@ui/MUI/LoadingButton';
import React from 'react';
import {useFormContext} from 'react-hook-form';

const Buttons = () => {
  const {close, isLoading} = useModal();
  const form = useFormContext();
  const {create} = useEstimateMutation();
  const {isDirty, isValid} = form.formState;
  const isDisabled = !isDirty || !isValid;
  return (
    <Stack direction="row" justifyContent="space-between">
      <LoadingButton
        color="primary"
        disabled={isDisabled}
        isLoading={isLoading}
        variant="contained"
        onClick={form.handleSubmit(create)}
      >
        Сохранить
      </LoadingButton>
      <Button variant="contained" onClick={close}>
        Отменить
      </Button>
    </Stack>
  );
};

export default Buttons;
