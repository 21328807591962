import {userRoles} from '@src/constants';
import PageTitleDefault from '@ui/PageTitleDefault';
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import FranchiseeSchoolsPanel from '../schools/panels/FranchiseeSchoolsPanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function FranchiseeSchoolsApp() {
  const currentUser = useSelector((state) => state.users.currentUser, shallowEqual);

  return (
    <ChessTemplate active={'schools'}>
      <PageTitleDefault>Школы</PageTitleDefault>
      <FranchiseeSchoolsPanel
        canAddSchool={currentUser?.userRole !== userRoles.schools_admin}
        id={currentUser?.franchiseeId || currentUser?.id}
        schoolsIds={currentUser.schoolsIds}
      />
    </ChessTemplate>
  );
}
