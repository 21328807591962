import useFilters from '@hooks/statistics/useFilters';
import useDeepMemo from '@hooks/useDeepMemo';
import {HALF_OPTIONS, HALF_OPTIONS_WITH_OT, IMPORTANCE_OPTIONS, PLRS_OPTIONS} from '@src/constants';
import {Autocomplete, Box, Stack} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

Filters.propTypes = {
  hasOT: PropTypes.bool,
  setter: PropTypes.func.isRequired,
  getter: PropTypes.func.isRequired,
  cleanup: PropTypes.func,
};

function Filters({hasOT = false, setter = () => {}, getter = () => {}, cleanup = () => {}}) {
  const {filterValues, setFilters} = useFilters({
    setter,
    cleanup,
    getter,
    effect: (setFilters) => {
      setFilters({
        half: [...(hasOT ? HALF_OPTIONS_WITH_OT : HALF_OPTIONS)],
      });
    },
    effectDeps: [hasOT],
  });
  const filters = useDeepMemo(() => {
    return [
      {
        name: 'plrs',
        options: [...PLRS_OPTIONS],
        multiple: true,
        label: 'Составы',
        isSelectableAll: true,
        order: 1,
        width: 220,
        limitTags: 1,
      },
      {
        name: 'importance',
        options: [...IMPORTANCE_OPTIONS],
        multiple: false,
        label: 'Важность',
        order: 2,
        width: 220,
        limitTags: 1,
      },
      {
        name: 'half',
        options: [...(hasOT ? HALF_OPTIONS_WITH_OT : HALF_OPTIONS)],
        multiple: true,
        limitTags: 2,
        label: 'Период',
        isSelectableAll: true,
        order: 3,
        width: 220,
      },
    ];
  }, [hasOT]);
  return (
    <Stack alignItems="center" direction="row" flexWrap="wrap" gap={3}>
      {filters?.map(({order, type, name, width, ...item}, index) => (
        <Box
          key={index}
          order={order || index + 1}
          width={(theme) => ({xxs: '100%', xs: `calc(50% - (${theme.spacing(3)} / 2))`, md: width})}
        >
          <Autocomplete
            value={filterValues?.[name]}
            onChange={(_, value) => {
              setFilters({[name]: value});
            }}
            {...item}
          />
        </Box>
      ))}
    </Stack>
  );
}

export default Filters;
