import {postGames} from '@api/TournamentsAPI';
import ContentWrapper from '@common/ContentWrapper';
import FullHeightWrapper from '@common/FullHeightWrapper';
import Filters from '@components/apps/Tournaments/components/TournamentView/components/Filters';
import GroupTournamentContainer from '@components/apps/Tournaments/components/TournamentView/components/GroupTournamentContainer';
import StagesTournamentContainer from '@components/apps/Tournaments/components/TournamentView/components/StagesTournamentContainer';
import {playOffStagesoptions} from '@components/apps/Tournaments/components/TournamentView/constants';
import {
  CurrentTournament,
  FormData,
  PlayoffItem,
  PlayoffItems,
  StateFilters,
} from '@components/apps/Tournaments/components/TournamentView/types';
import LocalPreloader from '@components/preloader/LocalPreloader';
import ChessTemplate from '@components/templates/ChessTemplate';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectCurrentTournament, selectGames} from '@selectors/tournaments';
import {clearCurrentEditTournament, clearGames, loadCurrentEditTournament, loadGames} from '@slices/tournaments';
import {InitialStateIndicators} from '@src/types';
import {TournamentGameCreateDto} from '@src/types/generated';
import {StagesEnum} from '@src/types/tournament';
import {Button, Stack, Typography} from '@ui/MUI';
import PageTitleDefault from '@ui/PageTitleDefault';
import {isEmpty} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

const filtersInitialState: StateFilters = {isGroupTournament: false, isPlayOff: false, playOffStages: null};
function TournamentsView() {
  const dispatch = useStoreDispatch();
  const {id} = useParams<{id: string}>();
  const [filters, setFilters] = useState<StateFilters>({...filtersInitialState});
  const {isGroupTournament, isPlayOff, playOffStages} = filters;
  const isEmptyFilters = !isGroupTournament && !isPlayOff;
  const {data, isLoading} = useStore<InitialStateIndicators<FormData>>(selectGames);
  const currentTournament = useStore<CurrentTournament>(selectCurrentTournament);
  const form = useForm<FormData>();
  const handleLoadGames = useCallback(() => {
    dispatch(
      loadGames({tournament: id}, (data: PlayoffItems) => {
        const state: StateFilters = {...filtersInitialState};
        data?.forEach((item) => {
          if (item?.stage === StagesEnum.GROUP) {
            state.isGroupTournament = true;
          }
          if (item.stage) {
            const stage = item.stage?.includes('/') ? Number(item.stage?.split('/')[1]) : 0;
            const stateStage = state.playOffStages?.value?.includes('/')
              ? Number(state.playOffStages?.value?.split('/')[1])
              : 0;
            if (!state.playOffStages?.value || stateStage < stage) {
              state.playOffStages = playOffStagesoptions.find((option) => option.value === item.stage);
            }
            state.isPlayOff = true;
          }
        });
        setFilters(state);
      }),
    );
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(loadCurrentEditTournament(id, true));
      handleLoadGames();
    }
    return () => {
      dispatch(clearCurrentEditTournament());
      dispatch(clearGames());
    };
  }, [id]);
  return (
    <StyledChessTemplate active="tournaments">
      <PageTitleDefault isLoading={currentTournament?.isLoading} text={currentTournament?.data?.name || 'Турнир'} />
      <FormProvider {...form}>
        <Stack direction="column" gap={{xxs: 2, sm: 3}} position="relative">
          <Filters {...{filters, setFilters, isLoading}} />

          {isEmptyFilters || isLoading ? (
            <FullHeightWrapper alignItems="center" display="flex" justifyContent="center" position="relative">
              {isLoading ? (
                <LocalPreloader visible />
              ) : (
                <Typography variant="subtitle1">Выберите тип турнира</Typography>
              )}
            </FullHeightWrapper>
          ) : (
            <>
              {isGroupTournament && <GroupTournamentContainer games={data} />}
              {!isEmpty(playOffStages) && isPlayOff && (
                <StagesTournamentContainer games={data} playOffStages={playOffStages} />
              )}
              {!isEmptyFilters && (
                <ContentWrapper sx={{position: 'sticky', bottom: 0, boxShadow: '0px -4px 9px 0px #00000026'}}>
                  <Button
                    color="primary"
                    sx={{ml: 'auto', display: 'flex'}}
                    variant="contained"
                    onClick={form.handleSubmit((form) => {
                      const pairs = Object.entries(form);
                      const result = pairs
                        ?.map(([key, value]) => {
                          let val = value;
                          if (key === StagesEnum.GROUP) {
                            val = val?.flat(1);
                          }
                          return val?.map(
                            ({team1, team2, score1, score2, group, ...item}: PlayoffItem): TournamentGameCreateDto => {
                              return {
                                ...item,
                                team1: team1?.id,
                                team2: team2?.id,
                                score1: Number(score1),
                                score2: Number(score2),
                                stage: key,
                                tournament: id,
                                group: group?.id || team1?.group?.id,
                              };
                            },
                          );
                        })
                        ?.flat(1)
                        ?.filter((value: TournamentGameCreateDto) => value?.date && value?.team1 && value?.team2);
                      postGames(result).then(() => handleLoadGames());
                    })}
                  >
                    Сохранить
                  </Button>
                </ContentWrapper>
              )}
            </>
          )}
        </Stack>
      </FormProvider>
    </StyledChessTemplate>
  );
}

export default TournamentsView;

const StyledChessTemplate = styled(ChessTemplate)`
  .Right {
    padding-bottom: 0;
  }
`;
