import {PageWrapper} from '@common/Page';
import {useGroup, useSchool} from '@hooks/filters';
import useQueryString from '@hooks/useQueryString';
import {selectFilters} from '@selectors/skill';
import {updateFilters} from '@slices/skill';
import {Autocomplete, Box} from '@ui/MUI';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const SchoolFilter = (props) => {
  const school = useSchool();
  const value = useSelector(selectFilters({entityName: 'archive', name: 'school'}));

  const [qs, _] = useQueryString();
  return (
    <Autocomplete
      disabled={school.options.length === 1}
      options={school.options}
      {...props}
      value={
        school.options.length > 1
          ? school.options.find((option) => option.value === qs?.school) || value
          : school.options?.[0]
      }
    />
  );
};

const GroupFilter = (props) => {
  const school = useSelector(selectFilters({entityName: 'archive', name: 'school'}));
  const group = useGroup({connectorValue: school?.value});
  const value = useSelector(selectFilters({entityName: 'archive', name: 'group'}));
  const [qs, _] = useQueryString();
  return (
    <Autocomplete
      disabled={group.options.length === 1}
      options={group.options}
      {...props}
      value={
        group.options.length > 1
          ? group.options.find((option) => option.value === qs?.group) || value
          : group.options?.[0]
      }
    />
  );
};

const filters = [
  {label: 'Школа', maxWidth: 190, component: SchoolFilter, name: 'school', multiple: false},
  {label: 'Команда', maxWidth: 204, component: GroupFilter, name: 'group', multiple: false},
];

export const Filters = () => {
  const dispatch = useDispatch();
  const handleChange = (name, value) => {
    dispatch(updateFilters({name, value, entityName: 'archive'}));
  };

  return (
    <PageWrapper alignItems="end" direction="row" gap={4} px={4} py={3}>
      {filters.map((filter) => {
        return (
          <Box flex="1" key={filter.label} maxWidth={filter.maxWidth}>
            {filter.component({
              label: filter.label,
              onChange: (_, value) => handleChange(filter.name, value),
              multiple: filter.multiple,
            })}
          </Box>
        );
      })}
    </PageWrapper>
  );
};
