import ReactTableComponent from '@common/ReactTableComponent';
import CellDoubleProgress from '@components/apps/PlayerPersonalFile/components/MainInfo/components/CellDoubleProgress';
import CellProgress from '@components/apps/PlayerPersonalFile/components/MainInfo/components/CellProgress';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import date from '@helpers/date';
import {mmSSToMs} from '@helpers/time';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import {selectMainTabPastGames} from '@selectors/playerPersonalFile';
import {clearMainTabPastGames, loadMainTabPastGames} from '@slices/playerPersonalFile';
import {HOCKEY_ROLES_ENUM, longDash} from '@src/constants';
import {Box, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {oneOf} from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import {useParams} from 'react-router';

function restPlayersColumns(isLoading) {
  return [
    {
      header: 'Голы',
      accessorKey: 'goals',
      cell: ({getValue}) => (
        <Typography isLoading={isLoading} preloaderWidthBetween={[20, 50]} variant="body2">
          {getValue() || longDash}
        </Typography>
      ),
    },
    {
      header: 'Пасы',
      accessorKey: 'assists',
      cell: ({getValue}) => (
        <Typography isLoading={isLoading} preloaderWidthBetween={[20, 50]} variant="body2">
          {getValue() || longDash}
        </Typography>
      ),
    },
    {
      header: 'Очки',
      accessorKey: 'score',
      cell: ({getValue}) => (
        <Typography isLoading={isLoading} preloaderWidthBetween={[20, 50]} variant="body2">
          {getValue() || longDash}
        </Typography>
      ),
    },
    {
      header: '+/-',
      accessorKey: 'plusMinus',
      cell: ({getValue}) => {
        const value = getValue();
        return (
          <>
            <Typography isLoading={isLoading} preloaderWidthBetween={[20, 50]} variant="body2">
              {value || longDash}
            </Typography>
            <CellDoubleProgress max={5} min={0} num={Number(value)} />
          </>
        );
      },
    },
    {
      header: 'Время',
      accessorKey: 'time',
      cell: ({getValue}) => {
        const value = getValue();
        return (
          <>
            <Typography isLoading={isLoading} preloaderWidthBetween={[20, 50]} variant="body2">
              {value || longDash}
            </Typography>
            <CellProgress color="green" max={1800000} min={0} num={mmSSToMs(value)} />
          </>
        );
      },
    },
    {
      header: 'Броски',
      accessorKey: 'shots',
      cell: ({getValue}) => {
        const value = getValue();
        return (
          <>
            <Typography isLoading={isLoading} preloaderWidthBetween={[20, 50]} variant="body2">
              {value || longDash}
            </Typography>
            <CellProgress color="blue" max={10} min={0} num={Number(value)} />
          </>
        );
      },
    },
    {
      header: 'Блок. броски',
      accessorKey: 'blockedShots',
      cell: ({getValue}) => {
        const value = getValue();
        return (
          <>
            <Typography isLoading={isLoading} preloaderWidthBetween={[20, 50]} variant="body2">
              {value || longDash}
            </Typography>
            <CellProgress color="yellow" max={10} min={0} num={Number(value)} />
          </>
        );
      },
    },
    {
      header: 'Единоборства',
      accessorKey: 'winBattles',
      cell: ({getValue}) => {
        const value = getValue();
        return (
          <>
            <Typography isLoading={isLoading} preloaderWidthBetween={[20, 50]} variant="body2">
              {value || longDash}
            </Typography>
            <CellProgress color="green" max={10} min={0} num={Number(value)} />
          </>
        );
      },
    },
    {
      header: 'Corsi',
      accessorKey: 'corsi',
      cell: ({getValue}) => {
        const value = Math.abs(getValue());
        return (
          <>
            <Typography isLoading={isLoading} preloaderWidthBetween={[20, 50]} variant="body2">
              {value || value === 0 ? value : longDash}
            </Typography>
            {value < 50 && (
              <CellProgress
                half
                color="red"
                left="50%"
                max={50}
                maxWidth="50%"
                min={0}
                num={50 - Number(value)}
                sx={{transform: 'translateX(-100%)'}}
              />
            )}
            {value > 50 && (
              <CellProgress half color="green" left="50%" max={100} maxWidth="50%" min={50} num={Number(value)} />
            )}
          </>
        );
      },
    },
  ];
}

function setGoaltendersColumns(isLoading) {
  const columns = [
    {header: 'Броски в створ', accessorKey: 'shotsOnGoal'},
    {header: 'Пропущ. голы', accessorKey: 'concededGoals'},
    {header: '% сейвов', accessorKey: 'saves'},
    {header: '% сейвов из опасн. слота', accessorKey: 'savesDangerous'},
    {header: '% сейвов не из слота', accessorKey: 'savesNotDangerous'},
    {header: 'Точность передач', accessorKey: 'passAccuracy'},
    {header: 'Ср. угол отстока', accessorKey: 'avgReboundAngle'},
    {header: 'Ср. дистанция отстока', accessorKey: 'avgReboundDistance'},
  ];
  return columns.map(({header, accessorKey}, index) => ({
    id: header + index,
    header,
    accessorKey,
    cell: ({getValue}) => {
      const value = getValue();
      return (
        <>
          <Typography isLoading={isLoading} preloaderWidthBetween={[20, 50 + index]} variant="body2">
            {value?.includes('-1') ? longDash : value}
          </Typography>
        </>
      );
    },
  }));
}

PastGamesTable.propTypes = {
  hockeyRole: oneOf(Object.values(HOCKEY_ROLES_ENUM)),
};
function PastGamesTable({hockeyRole}) {
  const dispatch = useStoreDispatch();
  const {id} = useParams();
  const {data, isLoading, isLoad, error} = useStore(selectMainTabPastGames);

  const columns = useMemo(
    () => [
      {
        header: 'Игры',
        accessorFn: (row) => row,
        sticky: {left: true},
        cellStyle: {textAlign: 'left'},
        headerStyle: {textAlign: 'left'},
        cell: (params) => {
          const value = params.getValue();
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[120, 150]} variant="body2">{`${
              value?.homeTeamName || 'Домашняя команда'
            } ${longDash} ${value?.awayTeamName || 'Домашняя команда'}`}</Typography>
          );
        },
      },
      {
        header: 'Дата',
        accessorKey: 'date',
        cell: ({getValue}) => {
          const gameDate = date(Number(getValue()));
          return (
            <Typography isLoading={isLoading} preloaderWidthBetween={[60, 80]} variant="body2">
              {gameDate.isValid() ? gameDate.format('DD.MM.YYYY') : longDash}
            </Typography>
          );
        },
      },
      ...(hockeyRole === HOCKEY_ROLES_ENUM.goaltender
        ? setGoaltendersColumns(isLoading)
        : restPlayersColumns(isLoading)),
    ],
    [isLoading],
  );

  useEffect(() => {
    dispatch(loadMainTabPastGames({playerId: id, gameCount: 6}));
    return () => {
      dispatch(clearMainTabPastGames());
    };
  }, [id]);

  return (
    <Wrapper mb={3} p="0">
      <Box p={3}>
        <Typography variant="h4">прошедшие Игры сезона 2023-2024</Typography>
      </Box>
      {(isLoad && !data?.length) || error ? (
        <Stack alignItems="center" justifyContent="center" py={5}>
          <SportsHockeyIcon sx={{mb: 1, fill: grey['24'], width: 32, height: 32}} />
          <Typography variant="subtitle1">Игр в этом сезоне еще не было</Typography>
        </Stack>
      ) : (
        <Box borderTop={`1px solid ${grey['12']}`}>
          <ReactTableComponent
            {...{columns, data}}
            tableBodyCellProps={{sx: {textAlign: 'center', position: 'relative'}}}
            tableHeaderCellProps={{sx: {textAlign: 'center'}}}
          />
        </Box>
      )}
    </Wrapper>
  );
}

export default PastGamesTable;
