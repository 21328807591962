import * as types from '../ActionTypes.js';

const initialState = {};
const initialAction = {
  payload: null,
  type: null,
};

const FormReducer = (state = initialState, {type, payload} = initialAction) => {
  switch (type) {
    case types.CREATE_DEFAULT_FORM_VALUES:
      return {
        ...state,
        [payload.formName]: {...payload.values},
      };
    case types.UPDATE_FORM_VALUE:
      return {
        ...state,
        [payload.formName]: {
          ...state[payload.formName],
          [payload.filterName]: {
            value: payload.filterValue,
            options: payload.filterOptions,
            isDisabled: payload.filterIsDisabled,
          },
        },
      };
    case types.GET_FORM_VALUE:
      return state;
    default:
      return state;
  }
};
export default FormReducer;
