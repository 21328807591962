import ChildrenField from '@common/ChildrenField';
import NumberInput from '@common/NumberInput';
import {getOptionLabel, isOptionEqualToValue} from '@components/apps/TrainingControlProcess/constant';
import {useAge, useSection, useSkill} from '@components/apps/TrainingControlProcess/hooks/api';
import {Chip, Grid} from '@ui/MUI';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import TextFieldDefault from '@ui/MUI/TextField';
import React, {memo, useEffect, useMemo} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

const validateSkillName = (name, fields, skills) => {
  return skills.some((skill) => skill.name === name) || fields.some((field) => field.name === name);
};

const MainContent = ({age, isEdit}) => {
  const section = useSection();
  const sectionWatcher = useWatch({name: 'section'});
  const currentSkillsWatcher = useWatch({name: 'skills'});

  const {data: skills} = useSkill();
  const {reset, getValues, setValue} = useFormContext();
  const ageOptions = useAge();

  const skillNumbers = useMemo(() => {
    return skills.map((skill) => skill.number);
  }, [skills]);

  const currentSkillNumbers = useMemo(() => {
    return currentSkillsWatcher?.map((skill) => skill.number) || [];
  }, [currentSkillsWatcher]);

  useEffect(() => {
    const options = ageOptions?.data;
    const defaultAgeOption = options?.find((item) => item?.id === age);
    if (options?.length && defaultAgeOption && !isEdit) {
      reset((formValues) => ({...formValues, ages: [defaultAgeOption]}));
    }
  }, [ageOptions?.data?.length, age, isEdit]);

  const categories = useMemo(() => {
    return section.data.find((section) => section.id === sectionWatcher?.id)?.categories || [];
  }, [sectionWatcher, section]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} xxs={12}>
          <ChildrenField
            isRequired
            name="name"
            validate={(name) => {
              const isEdit = getValues('id');
              if (!isEdit && validateSkillName(name, currentSkillsWatcher || [], skills)) {
                return 'Название навыка уже используется';
              }

              return null;
            }}
          >
            <TextFieldDefault label="Название навыка" />
          </ChildrenField>
        </Grid>
        <Grid item xs={5}>
          <ChildrenField isRequired name="section" onChange={() => setValue('category', null)}>
            <AutocompleteDefault
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isOptionEqualToValue}
              label="Раздел"
              multiple={false}
              options={section.data}
            />
          </ChildrenField>
        </Grid>
        <Grid item xs={7}>
          <ChildrenField isRequired name="category">
            <AutocompleteDefault
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isOptionEqualToValue}
              label="Направления подготовки"
              multiple={false}
              options={categories}
            />
          </ChildrenField>
        </Grid>
        <Grid item xs={7}>
          <ChildrenField isRequired name="ages">
            <AutocompleteDefault
              multiple
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isOptionEqualToValue}
              label="Возрастная группа"
              options={ageOptions?.data}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option?.name} size="small" variant="outlined" {...getTagProps({index})} key={index} />
                ))
              }
            />
          </ChildrenField>
        </Grid>
        <Grid item xs={6}>
          <ChildrenField
            isRequired
            name="number"
            validate={(number) => {
              const isNumberExistOnSkills =
                currentSkillNumbers.includes(Number(number)) || skillNumbers.includes(Number(number));

              return isNumberExistOnSkills ? 'Номер навыка уже используется' : null;
            }}
          >
            <NumberInput label="Номер навыка" min={1} />
          </ChildrenField>
        </Grid>
      </Grid>
    </div>
  );
};
export default memo(MainContent);
