import React from 'react';

function HockeySticks() {
  return (
    <React.Fragment>
      <g>
        <path d="M9.30844 10.6811L4.05838 0.171051L2.95166 0.723917L8.61702 12.0653L9.30844 10.6811Z" fill="#686868" />
        <path
          d="M18.5566 16.1176H12.0241L11.3828 14.8337L10.6914 16.2179L11.9557 18.7489C12.2632 19.3646 13.0141 19.8289 13.7024 19.8289H18.5566C19.3525 19.8289 19.9999 19.1815 19.9999 18.3856V17.5609C19.9999 16.765 19.3525 16.1176 18.5566 16.1176Z"
          fill="#686868"
        />
        <path
          d="M15.9415 0.171051L7.97579 16.1176H1.4433C0.647464 16.1176 0 16.7651 0 17.5609V18.3856C0 19.1815 0.647464 19.8289 1.4433 19.8289H6.29757C6.98581 19.8289 7.7367 19.3647 8.0442 18.749L17.0482 0.723917L15.9415 0.171051Z"
          fill="#686868"
        />
      </g>
    </React.Fragment>
  );
}
export default HockeySticks;
