import React, {useState} from 'react';

import DashboardsBlock from '../dashboards/trainers-dashboard/components/DashboardsBlock';

const IllnessPlayers = (props) => {
  const {contentBlock = () => {}, illnessPlayers = []} = props;
  const [isIllnessPlayersShowed, setIsIllnessPlayersShowed] = useState(false);
  let players = [];
  if (!isIllnessPlayersShowed && window.innerWidth >= 1025) {
    players = illnessPlayers.slice(0, 5);
  } else {
    players = illnessPlayers;
  }
  return (
    <DashboardsBlock
      buttonText={!isIllnessPlayersShowed ? 'Показать всех игроков' : 'Скрыть'}
      buttonsBlockType="button"
      content={contentBlock(
        players,
        isIllnessPlayersShowed,
        'В данный момент в команде нет игроков на больничном',
        'Статус болезни',
      )}
      isButtonsShowed={window.innerWidth >= 1025 && illnessPlayers.length > 5}
      title="Игроки на больничном"
      onButtonClick={() => {
        setIsIllnessPlayersShowed((prevState) => !prevState);
      }}
    />
  );
};

export default IllnessPlayers;
