import React, {memo} from 'react';

import LoadingButton from '../LoadingButton';
import {Description, Error, FieldsCover, Link, StyledField, Title} from './Stage';

const Stage = ({
  title,
  description,
  fields,
  error,
  button,
  link,
  updateStage,
  loading,
  email,
  isDisabled,
  updateDisable,
}) => {
  const handleChange = (v) => {
    if (email) {
      if (isDisabled && v !== email) {
        updateDisable(false);
      }

      if (!isDisabled && v === email) {
        updateDisable(true);
      }
    }
  };

  return (
    <>
      {title && <Title>{title}</Title>}
      {description && <Description>{typeof description === 'function' ? description(email) : description}</Description>}
      {fields && (
        <FieldsCover>
          {fields.map((field, index) => (
            <StyledField key={index} {...field} isRequired onChange={field.name === 'email' && handleChange} />
          ))}
        </FieldsCover>
      )}
      {typeof error === 'string' && <Error>{error}</Error>}
      {button && <LoadingButton {...button} disabled={isDisabled || loading} loading={loading} />}
      {link && (
        <Link disabled={loading} loading={loading} type="button" onClick={() => updateStage(link.goTo)}>
          {link.label}
        </Link>
      )}
    </>
  );
};

export default memo(Stage);
