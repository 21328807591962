import Box from '@ui/MUI/Box';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

export default function NoData({children, padding = 3}) {
  return (
    <Box p={padding} textAlign="center">
      <TypographyDefault fontWeight="600" variant="body2">
        {children}
      </TypographyDefault>
    </Box>
  );
}
