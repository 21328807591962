import DateTimePicker from '@ui/MUI/DateTimePicker';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import MedicalProfileAPI from '../../../api/MedicalProfileAPI';
import {NOTIFICATION_TYPES} from '../../../constants/config';
import {generateId} from '../../../helpers/MedicalProfileHelper';
import {handleChangeForm} from '../../../redux/actions/MedicalProfileActions';
import {Button, LightButton} from '../../ui/Button';
import Icon from '../../ui/Icon/Icon';
import InputDefault from '../../ui/Input';
import {ReactComponentNotification} from '../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import SelectDefault from '../../ui/Select';

export default function Consultation() {
  const medicalProfileForm = useSelector((state) => state.medicalProfile?.medicalProfileForm || {});
  const [typesOptions, setTypesOptions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    MedicalProfileAPI.getConsultationRecommendations()
      .then((data) => {
        setTypesOptions(data.data);
      })
      .catch(function () {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при загрузке рекомендаций к тренировкам');
      });
  }, []);

  typesOptions.sort((a, b) => {
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    return 0;
  });

  const handleDeleteConsultation = (consultationId) => {
    if (!consultationId) return;

    let cloneConsultations = medicalProfileForm?.consultations?.length ? [...medicalProfileForm.consultations] : [];
    let currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    cloneConsultations.splice(
      cloneConsultations.findIndex((i) => i.idFront === consultationId),
      1,
    );

    currentMedicalProfileForm.consultations = cloneConsultations;
    dispatch(handleChangeForm(currentMedicalProfileForm));
  };

  const handleAddConsultation = () => {
    const cloneConsultation = medicalProfileForm?.consultations?.length ? [...medicalProfileForm.consultations] : [];
    const currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    dispatch(
      handleChangeForm({
        ...currentMedicalProfileForm,
        consultations: [
          ...cloneConsultation,
          {
            idFront: generateId(),
            type: '',
            date: '',
            time: '',
            comments: '',
          },
        ],
      }),
    );
  };
  const handleConsultation = (consultationsId, name, value) => {
    const cloneConsultation = medicalProfileForm?.consultations?.length ? [...medicalProfileForm.consultations] : [];
    const currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    dispatch(
      handleChangeForm({
        ...currentMedicalProfileForm,
        consultations: cloneConsultation.map((item) => {
          if (item.idFront === consultationsId) return {...item, [name]: value};
          return item;
        }),
      }),
    );
  };

  const selectedDate = (date, id) => {
    if (date) {
      return new Date(date);
    } else {
      handleConsultation(id, 'date', new Date().getTime());
      return new Date();
    }
  };

  return (
    <Wrapper>
      <Title>Рекомендации по посещению/консультированию</Title>

      <RecommendationContainer>
        {medicalProfileForm.consultations
          ? medicalProfileForm.consultations.map((consultation, index) => {
              return (
                <Recommendation key={index}>
                  <Field>
                    <Label>Тип рекомендации</Label>
                    <SelectDefault
                      errorText="Обязательное поле для заполнения"
                      isInvalid={!typesOptions.filter((option) => option.value === consultation.type)[0]}
                      isMulti={false}
                      options={typesOptions}
                      placeholder={'Выберите тип рекомендации'}
                      value={
                        typesOptions.filter((option) => option.value === consultation.type).length
                          ? typesOptions.filter((option) => option.value === consultation.type)[0]
                          : ''
                      }
                      onChange={(type) => {
                        handleConsultation(consultation.idFront, 'type', type.value);
                      }}
                    />
                  </Field>
                  <FieldSmall>
                    <Label>Дата</Label>
                    <DateTimePicker
                      inputFormat="DD.MM.YYYY HH:mm"
                      value={selectedDate(consultation.date, consultation.idFront)}
                      variant="outlined"
                      onChange={(newDate) => {
                        handleConsultation(consultation.idFront, 'date', new Date(newDate).getTime());
                      }}
                    />
                  </FieldSmall>
                  <FieldMiddle style={{paddingRight: 100}}>
                    <Label>Комментарий</Label>
                    <InputDefault
                      errorText="Обязательное поле для заполнения"
                      isInvalid={!consultation.comments}
                      value={consultation.comments ? consultation.comments : ''}
                      onChange={(evt) => {
                        handleConsultation(consultation.idFront, 'comments', evt.target.value);
                      }}
                    />
                  </FieldMiddle>
                  <Button
                    onClick={() => {
                      handleDeleteConsultation(consultation.idFront);
                    }}
                  >
                    <Icon height={17} type={'CloseRed'} viewBox={'0 0 17 17'} width={17} />
                  </Button>
                </Recommendation>
              );
            })
          : null}
        <FieldRecommendation>
          <LightButton
            className="ButtonWithIcon"
            onClick={() => {
              handleAddConsultation();
            }}
          >
            <IconContainer>
              <Icon height="12" type="Plus" viewBox="0 0 12 12" width="12" />
              Добавить рекомендацию к посещению
            </IconContainer>
          </LightButton>
        </FieldRecommendation>
      </RecommendationContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`;
const Title = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  font: 600 normal 16px/1 'Proxima Nova';
  color: #990011;
  letter-spacing: 0.02em;
`;
const RecommendationContainer = styled.div`
  margin: 0 -20px;
`;
const Recommendation = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  ${Button} {
    position: absolute;
    top: 34px;
    right: 36px;
    border-radius: 0;
    background-color: transparent;

    @media screen and (max-width: 1200px) {
      top: 105px;
      right: 0;
    }
    @media screen and (max-width: 768px) {
      top: 172px;
    }
  }
`;
const Field = styled.div`
  margin-top: 12px;
  margin-bottom: 0;
  padding-right: 20px;
  padding-left: 20px;
  flex: 0 0 30%;
  max-width: 30%;

  @media (min-width: 769px) and (max-width: 1200px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  textarea {
    resize: none;
    min-height: 38px;
    max-height: 38px;
    max-width: 80%;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    font: normal normal 15px/38px 'Proxima Nova';
    color: #2c2c2c;
    ::placeholder {
      font: normal normal 15px/38px 'Proxima Nova';
      color: #c4c4c4;
    }
    @media (min-width: 769px) and (max-width: 1200px) {
      max-width: 95%;
    }
    @media screen and (max-width: 768px) {
      max-width: 90%;
    }
  }
`;
const FieldRecommendation = styled(Field)`
  flex: 0 0 50%;
  max-width: 50%;

  @media screen and (max-width: 1200px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  ${LightButton} {
    width: 100%;
  }
`;
const FieldMiddle = styled(Field)`
  flex: 0 0 50%;
  max-width: 50%;

  @media screen and (max-width: 1200px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
const FieldSmall = styled(Field)`
  flex: 0 0 20%;
  max-width: 20%;
  .react-datepicker__time-list-item--selected {
    background-color: #e12 !important;
  }
  @media screen and (max-width: 1200px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;
const Label = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
`;
const IconContainer = styled.div`
  position: relative;
  font: normal normal 12px/25px 'Proxima Nova';
  color: #747474;
  svg {
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
  }
`;
