import {getLastUserReport} from '@api/ScoutReportAPI';
import {longDash} from '@src/constants';
import {Box, Stack, Typography} from '@ui/MUI';
import {blue, grey} from '@ui/MUI/colors';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';

function ScoutReportBlock() {
  const [scoutReport, setScoutReport] = useState({isLoading: false, data: {}});
  const {id} = useParams();
  useEffect(() => {
    if (id) {
      setScoutReport((prevState) => ({...prevState, isLoading: true}));
      getLastUserReport(id)
        .then(({data}) => setScoutReport({isLoading: false, data: data?.[0] || {}}))
        .catch(() => setScoutReport((prevState) => ({...prevState, isLoading: false})));
    }
  }, [id]);
  return (
    <ScoutReportWrapper flex={1} mr={-4}>
      <Stack direction="row" flexWrap="wrap" gap={0}>
        <Typography color={grey['54']} variant="body1">
          Отчет тренера:
        </Typography>
        <Typography
          color={grey['54']}
          isLoading={scoutReport.isLoading}
          preloaderWidthBetween={[80, 100]}
          variant="subtitle1"
        >
          {scoutReport.data?.potential || scoutReport.data?.roleModel
            ? `${scoutReport.data?.potential || ''}/${scoutReport.data?.roleModel || ''}`
            : longDash}
        </Typography>
      </Stack>
    </ScoutReportWrapper>
  );
}

export default ScoutReportBlock;

const ScoutReportWrapper = styled(Box)`
  background-color: ${blue['15']};
  border-radius: 6px 0 0 6px;
  padding: ${({theme}) => theme.spacing(3)};
  width: -webkit-fill-available;
  min-width: fit-content;
`;
