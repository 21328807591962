import styled from 'styled-components';

export const CenterPlaceholder = styled.div`
  height: 13vh;
  max-height: 80%;
  aspect-ratio: 1;
  position: relative;
`;

export const ImagePlaceholder = styled.div`
  width: 70%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Img = styled.img`
  width: 100%;
  display: inline-block;
`;
