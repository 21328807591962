import React from 'react';

function PossessionOfThePuck() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M34.5318 2.02556L34.571 0L32.9171 1.17011C32.1174 1.73578 31.1326 1.91824 30.0899 2.11128C29.0836 2.29763 28.0431 2.49034 27.0976 3.04446C26.602 3.33458 26.1393 3.70809 25.7412 4.07915C25.703 3.6169 25.6644 3.08876 25.6465 2.83261L25.553 1.49509L24.2875 1.93745C21.3213 2.97432 19.5677 5.32534 18.6616 6.96795L16.8466 4.33119L15.977 5.24611C13.0402 8.33569 12.886 11.5611 13.2737 13.7228L14.2813 13.542L22.0305 21.1185L21.8966 22.1334C22.3345 22.1912 22.8131 22.2278 23.323 22.2278C25.4361 22.2278 28.0837 21.5979 30.5728 19.2322L31.5988 18.257L28.6471 16.674C30.053 15.8001 31.9526 14.3132 33.04 12.1815L33.515 11.2503L30.935 10.0011C32.4089 8.69342 34.4523 6.1449 34.5318 2.02556Z"
          fill="#B51F18"
        />
        <path
          d="M17.7347 12.7927L22.0449 17.1029C23.1623 18.2203 23.235 20.174 22.2497 22.6038C21.3428 24.8404 19.6305 27.2852 17.4275 29.4883C15.2245 31.6914 12.7796 33.4035 10.543 34.3105C8.11319 35.2958 6.15954 35.2231 5.04207 34.1056L0.731934 29.7955L17.7347 12.7927Z"
          fill="#354546"
        />
        <path
          d="M17.7348 12.7927L22.0449 17.1029C23.1624 18.2203 23.2351 20.174 22.2498 22.6038C21.3429 24.8404 19.6306 27.2852 17.4276 29.4883L9.2334 21.2941L17.7348 12.7927Z"
          fill="#354546"
        />
        <path
          d="M17.7348 12.7927C19.7209 14.7789 17.5249 20.1955 12.8298 24.8906C8.13476 29.5857 2.71815 31.7817 0.731968 29.7955C-1.25456 27.809 0.94149 22.3924 5.63656 17.6973C10.3316 13.0023 15.7482 10.8062 17.7348 12.7927Z"
          fill="#263536"
        />
        <path
          d="M17.7347 12.7927C19.7209 14.7789 17.5248 20.1955 12.8297 24.8906L5.63647 17.6973C10.3315 13.0023 15.7481 10.8062 17.7347 12.7927Z"
          fill="#263536"
        />
      </g>
    </React.Fragment>
  );
}
export default PossessionOfThePuck;
