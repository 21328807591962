import moment from 'moment';
import React, {useMemo} from 'react';
import styled from 'styled-components';

import {getWindowDimensions} from '../../../helpers/CommonHelper';
import Typography from '../../../Typography/Typography';
import {EVENT_TYPES, EVENT_TYPES_COLOR, SCHOOL_LESSONS} from '../../calendar/constants';
import ColoredElement from '../../ColoredElement/ColoredElement';
import DashboardsBlock from '../../dashboards/trainers-dashboard/components/DashboardsBlock';
import Grid from '../../Grid/Grid';
import TableEmptyBlock from '../../ui/TableEmptyBlock';
import {Body, Container, Header, Rows, Title, TodayDate} from '../TodaySchedule/styles';

const TodaySchedule = (props) => {
  const {todaySchedule, group, hasGroupName = false, isPhysicalTrainerDashboard = false} = props;
  const sortedSchedule = todaySchedule?.sort((a, b) => a.rooms[0].startTimestamp - b.rooms[0].startTimestamp);
  const isMobile = getWindowDimensions().width <= 560;

  const formatedData = useMemo(() => {
    if (sortedSchedule && sortedSchedule.length) {
      return sortedSchedule.map((schedule) => {
        const {name, startTimestamp, duration} = schedule.rooms[0];
        const startTime = moment(startTimestamp).format('HH:mm');
        const endTime = moment(startTimestamp + duration).format('HH:mm');
        const isGame = schedule.type === 'game' || schedule.type === 'away_game';
        const type = EVENT_TYPES.find((type) => type.value === schedule.type);
        const subType = SCHOOL_LESSONS.find((type) => type.value === schedule.description)?.label;
        const typeColor = EVENT_TYPES_COLOR[type.value];
        return {
          startTime,
          endTime,
          name,
          isGame,
          groupName: group?.name || schedule?.group?.name,
          oponentName: schedule?.opponent,
          typeName: type.value === 'school_lessons' || type.value === 'additional_education' ? subType : type?.label,
          typeColor: typeColor,
        };
      });
    }
    return [];
  }, [todaySchedule, group]);

  return (
    <DashboardsBlock
      withLargePadding
      buttonsBlockType="link"
      content={
        <Container isMobile={isMobile}>
          <Rows isMobile={isMobile}>
            <Grid flexSize={isMobile ? 0.5 : 0.4}>Время</Grid>
            <Grid flexSize={isMobile ? 1 : 0.8}>Тип мероприятия {isMobile && '/ Помещение'}</Grid>
            {hasGroupName && <Grid flexSize={isMobile ? 0.4 : 0.8}>Команда</Grid>}
            {!isMobile && (
              <Grid flexSize={1} justify="flex-end">
                Помещение
              </Grid>
            )}
          </Rows>
          <Body>
            {formatedData.length ? (
              formatedData.map(
                ({startTime, endTime, name, groupName, oponentName, isGame, typeColor, typeName}, index, self) => {
                  const isLast = index === self.length - 1;
                  return (
                    <Grid key={index} marginBottom={isLast ? '0' : '20px'}>
                      <Grid flexSize={isMobile ? 0.5 : 0.4}>
                        <ColoredElement color="#626262">
                          {startTime} {'-'} {endTime}
                        </ColoredElement>
                      </Grid>
                      <Grid direction={isMobile && 'column'} flexSize={isMobile ? 1 : 0.8}>
                        {!isPhysicalTrainerDashboard ? (
                          <ColoredElement color={typeColor || 'black'}>
                            {isGame ? `${groupName} - ${oponentName}` : typeName || '-'}
                          </ColoredElement>
                        ) : (
                          <ColoredElement color="#2c2c2c">{typeName || '-'}</ColoredElement>
                        )}
                        {isMobile && (
                          <Grid marginTop="5px">
                            <Typography color="#686868" size="14px" weight={400}>
                              {name || '-'}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      {hasGroupName && (
                        <Grid flexSize={isMobile ? 0.4 : 0.8}>
                          <Typography color="#2c2c2c" size="14px" weight={400}>
                            {groupName}
                          </Typography>
                        </Grid>
                      )}
                      {!isMobile && (
                        <Grid flexSize={1} justify="flex-end">
                          <ColoredElement color="#626262">{name || '-'}</ColoredElement>
                        </Grid>
                      )}
                    </Grid>
                  );
                },
              )
            ) : (
              <StyledTableEmptyBlock text="Расписание на сегодня отсутствует" />
            )}
          </Body>
        </Container>
      }
      link="/schedule?viewMode=week"
      linkText="Посмотреть расписание на неделю"
      title={
        <Header>
          <Title>Расписание на сегодня</Title>
          <TodayDate>{moment().format('DD MMMM, ddd').toLowerCase()}</TodayDate>
        </Header>
      }
      withLargeBorder={isMobile}
    />
  );
};

const StyledTableEmptyBlock = styled(TableEmptyBlock)`
  justify-content: flex-start;
  padding: 0;
  min-height: 80px;
`;

export default TodaySchedule;
