import React from 'react';

import StudentGameEstimationSummaryPanel from '../game-estimation/panels/game-estimation-summary/StudentGameEstimationSummaryPanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function StudentGameEstimationSummaryApp() {
  return (
    <ChessTemplate active={`gameEstimationSummary`}>
      <StudentGameEstimationSummaryPanel />
    </ChessTemplate>
  );
}
