import React from 'react';

function TopArrow() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M8.0853 0.217211L14.7604 6.27705C14.9149 6.4172 15 6.6043 15 6.8038C15 7.00329 14.9149 7.19039 14.7604 7.33055L14.2689 7.77681C13.9487 8.0672 13.4282 8.0672 13.1084 7.77681L7.50311 2.68822L1.89157 7.78246C1.73706 7.92261 1.53108 8 1.31145 8C1.09158 8 0.885602 7.92261 0.730968 7.78246L0.239632 7.33619C0.0851202 7.19593 0 7.00894 0 6.80944C0 6.60995 0.0851202 6.42285 0.239632 6.28269L6.9208 0.217211C7.0758 0.0767226 7.28275 -0.000440598 7.50274 1.90735e-06C7.7236 -0.000440598 7.93042 0.0767226 8.0853 0.217211Z"
          fill="#2C2C2C"
        />
      </g>
    </React.Fragment>
  );
}
export default TopArrow;
