import {TextField} from '@mui/material';
import React, {useMemo} from 'react';
import {IMask, IMaskMixin} from 'react-imask';

const StyledInput = IMaskMixin(({inputRef, ...props}) => {
  return <TextField {...props} fullWidth inputRef={inputRef} variant="standard" />;
});

const masks = {
  day: {
    placeholder: 'hh:mm:ss',
    mask: 'HH:MM:SS',
    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        placeholderChar: 'HH',
        from: 0,
        to: 23,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        placeholderChar: 'MM',
        from: 0,
        to: 59,
        maxLength: 2,
      },
      SS: {
        mask: IMask.MaskedRange,
        placeholderChar: 'SS',
        from: 0,
        to: 60,
        maxLength: 2,
      },
    },
  },
  hour: {
    placeholder: 'hh:mm',
    mask: 'HH:MM',
    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        placeholderChar: 'HH',
        from: 0,
        to: 23,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        placeholderChar: 'MM',
        from: 0,
        to: 59,
        maxLength: 2,
      },
    },
  },
  minutes: {
    placeholder: 'mm',
    mask: 'MM',
    blocks: {
      MM: {
        mask: IMask.MaskedRange,
        placeholderChar: 'MM',
        from: 0,
        to: 59,
        maxLength: 2,
      },
    },
  },
};

const TimeInput = ({onChange, value = '', mode = 'day', ...rest}) => {
  const currentMask = masks[mode];

  const numberToString = useMemo(() => {
    if (typeof value === 'number') return String(value);
    return value;
  }, [value]);

  return (
    <StyledInput
      blocks={currentMask.blocks}
      mask={currentMask.mask}
      placeholder={currentMask.placeholder}
      // mask="HH:MM:SS"
      // blocks={{
      //   HH: {
      //     mask: IMask.MaskedRange,
      //     placeholderChar: "HH",
      //     from: 0,
      //     to: 23,
      //     maxLength: 2,
      //   },
      //   MM: {
      //     mask: IMask.MaskedRange,
      //     placeholderChar: "MM",
      //     from: 0,
      //     to: 59,
      //     maxLength: 2,
      //   },
      //   SS: {
      //     mask: IMask.MaskedRange,
      //     placeholderChar: "SS",
      //     from: 0,
      //     to: 60,
      //     maxLength: 2,
      //   },
      // }}
      value={numberToString}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      {...rest}
    />
  );
};

export default TimeInput;
