import useElementSize from '@hooks/useElementSize';
import CloseIcon from '@mui/icons-material/Close';
import {Drawer as MuiDrawer} from '@mui/material';
import {BoxProps} from '@mui/material/Box/Box';
import {DrawerProps} from '@mui/material/Drawer/Drawer';
import {Button, Typography} from '@ui/MUI';
import React from 'react';
import styled from 'styled-components';

import {
  DrawerButtonsBlock,
  DrawerButtonsBlockContent,
  DrawerCloser,
  DrawerContentContainer,
  DrawerInner,
  TitleWrapper,
} from './Drawer';

type CustomDrawerProps = DrawerProps & {
  contentWrapperProps?: BoxProps;
  width?: string;
  closeButtonText?: string;
  hasCrossButton?: boolean;
  hasButtons?: boolean;
  buttons?: React.ReactElement;
  ['data-testid']?: string;
};

function Drawer({
  anchor = 'right',
  children,
  open,
  hasButtons = true,
  hasCrossButton = true,
  buttons,
  closeButtonText = 'Закрыть',
  onClose,
  width = '640px',
  contentWrapperProps = {},
  title,
  ...props
}: {onClose: (value: boolean) => boolean} & CustomDrawerProps) {
  const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    onClose(state);
  };
  const [buttonsRef, {height}] = useElementSize();
  return (
    <>
      <StyledDrawer
        anchor={anchor}
        data-testid={props['data-testid']}
        open={open}
        transitionDuration={330}
        width={width}
        onClose={toggleDrawer(false)}
      >
        <DrawerInner buttons-block-height={height} hasbuttons={hasButtons}>
          <DrawerContentContainer {...contentWrapperProps}>
            <TitleWrapper sx={{backgroundColor: title ? '#fff' : '', zIndex: '2 !important'}}>
              {typeof title === 'string' ? <Typography variant="h3">{title}</Typography> : title}
              {hasCrossButton && (
                <DrawerCloser color="secondary" size="large" onClick={toggleDrawer(false)}>
                  <CloseIcon color="inherit" fontSize="medium" />
                </DrawerCloser>
              )}
            </TitleWrapper>
            {children}
          </DrawerContentContainer>
          {hasButtons && (
            <>
              {buttons ? (
                <DrawerButtonsBlock ref={buttonsRef}>
                  <DrawerButtonsBlockContent>{buttons}</DrawerButtonsBlockContent>
                </DrawerButtonsBlock>
              ) : (
                <DrawerButtonsBlock ref={buttonsRef}>
                  <DrawerButtonsBlockContent>
                    <Button
                      color="secondary"
                      sx={{ml: 'auto', display: 'block'}}
                      variant="contained"
                      onClick={toggleDrawer(false)}
                    >
                      {closeButtonText}
                    </Button>
                  </DrawerButtonsBlockContent>
                </DrawerButtonsBlock>
              )}
            </>
          )}
        </DrawerInner>
      </StyledDrawer>
    </>
  );
}

export default Drawer;
const StyledDrawer = styled(MuiDrawer)<{width: string} & DrawerProps>`
  .MuiDrawer {
    &-paper {
      width: 100%;
      max-width: ${({width}) => width};
      border-radius: 0;
      box-sizing: border-box;

      ${({theme}) => theme.breakpoints.down('md')} {
        max-width: 100%;
      }
    }
  }
`;
