import {Editor} from '@tinymce/tinymce-react';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {handleChangeMedicalProfileRecommendation} from '../../../redux/actions/MedicalProfileActions';
import TaskList from '../../tools/TaskList';

export default function Recommendations() {
  const dispatch = useDispatch();

  const medicalProfileRecommendation = useSelector(
    (state) =>
      state.medicalProfile.medicalProfileRecommendation || {
        comment: '',
        taskGroups: [],
      },
  );

  const onRecommendationsChange = (name, value) => {
    const newRecommendation = {...medicalProfileRecommendation, [name]: value};
    dispatch(handleChangeMedicalProfileRecommendation(newRecommendation));
  };
  return (
    <Wrapper>
      <Title>Рекомендации по восстановлению</Title>
      <EditorContainer>
        <Editor
          apiKey={'cu07ryo9s4iu21e81l5n3eplzqncz895r129q4s0og41j59v'}
          init={{
            height: 160,
            fontSize: 14,
            menubar: false,
            statusbar: false,
            resize: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help',
          }}
          value={medicalProfileRecommendation.comment || ''}
          onEditorChange={(comment) => {
            onRecommendationsChange('comment', comment);
          }}
        />
      </EditorContainer>
      <TaskList
        tasks={medicalProfileRecommendation.taskGroups}
        onChange={(changeFunction) => {
          onRecommendationsChange('taskGroups', changeFunction(medicalProfileRecommendation.taskGroups));
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  @media screen and (max-width: 767px) {
    padding-bottom: 120px;
  }
`;
const Title = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  font: 600 normal 16px/1 'Proxima Nova';
  color: #990011;
  letter-spacing: 0.02em;
`;
const EditorContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 10px;
  box-sizing: border-box;
`;
