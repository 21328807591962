import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import styled from 'styled-components';

import FullPagePreloader from '../../preloader/FullPagePreloader';
import SchoolPanel from '../../schools/panels/SchoolPanel';
import ChessTemplate from '../../templates/ChessTemplate';
import TableEmptyBlock from '../../ui/TableEmptyBlock';

export default function TeacherPersonalPanel() {
  let {loading, schools} = useSelector((state) => {
    const id = state.users.currentUser.id;
    return {
      id,
      loading: state.users.loading,
      schools: state.schools.schoolsMap.toArray().filter((sc) => sc.teachersIds.indexOf(id) > -1),
      teacher: state.users.currentUser,
    };
  }, shallowEqual);

  return (
    <ChessTemplate active="index">
      <Wrapper>
        <FullPagePreloader visible={loading} />
        {schools.length > 0 ? (
          <SchoolsList>
            <SimpleSchoolItem>
              <SchoolPanel id={schools[0].id} />
            </SimpleSchoolItem>
          </SchoolsList>
        ) : (
          <>
            <TableEmptyBlock text="Данный тренер не прикреплен ни к одной школе." />
          </>
        )}
      </Wrapper>
    </ChessTemplate>
  );
}

const Wrapper = styled.div`
  .TableEmptyBlock {
    width: calc(100% - 4px);
  }
`;

const SchoolsList = styled.div``;

const SimpleSchoolItem = styled.div``;
