import ActionsMap from '@components/apps/StatisticsWithMkcUral/components/ActionsMap';
import CommonTab from '@components/apps/StatisticsWithMkcUral/components/CommonTab';
import GoaltendersStatisticsTab from '@components/apps/StatisticsWithMkcUral/components/GoaltendersStatisticsTab';
import PlayersStatistics from '@components/apps/StatisticsWithMkcUral/components/PlayersStatistics';
import ShotsMap from '@components/apps/StatisticsWithMkcUral/components/ShotsMap';
import Throwins from '@components/apps/StatisticsWithMkcUral/components/Throwins';
import useQueryStringTabs from '@hooks/useQueryStringTabs';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {clearMatches, clearTournaments, setMatchesFilterData} from '@slices/statisticsMKC';
import TabsNew from '@ui/MUI/TabsNew';
import React, {useEffect} from 'react';

import {StyledPageTitle} from '../../UralStatistics';
import PreparingForOpponent from '../PreparingForOpponent';
import TableTab from '../TournamentTab';

const TABS = [
  {label: 'Турнир', value: <TableTab />, id: 'tournament'},
  {label: 'Соперник', value: <PreparingForOpponent />, id: 'opponent'},
  {label: 'Командная', value: <CommonTab />, id: 'team'},
  {label: 'Действия', value: <ActionsMap weAtHome />, id: 'actions'},
  {label: 'Игроки', value: <PlayersStatistics />, id: 'players'},
  {label: 'Броски', value: <ShotsMap weAtHome />, id: 'shots'},
  {label: 'Вбрасывания', value: <Throwins weAtHome />, id: 'throwins'},
  {label: 'Вратари', value: <GoaltendersStatisticsTab weAtHome />, id: 'goaltenders'},
];
function Statistics() {
  const {value, onChange} = useQueryStringTabs({tabs: TABS});
  const dispatch = useStoreDispatch();

  useEffect(() => {
    return () => {
      dispatch(setMatchesFilterData({}));
      dispatch(clearTournaments());
      dispatch(clearMatches());
    };
  }, []);
  return (
    <>
      <StyledPageTitle
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Статистика', path: '/statistics'},
          {label: 'Статистика команды'},
        ]}
        text="Статистика"
      />
      <TabsNew hasMobileView withoutWrapper tabs={TABS} value={value} onChange={onChange} />
    </>
  );
}

export default React.memo(Statistics);
