import {getAges} from '@api/GroupsAPI';
import {getGroups, getSchoolGroups} from '@api/groupsAPIV2';
import {actionsGetter, defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {GroupsV2State} from '@slices/groups/types';
import {initialStateIndicators} from '@src/constants';
import {AgeGroupDto} from '@src/types/generated';
import {GroupsParams} from '@src/types/groupsAPIV2';

import moduleNames from '../../moduleNames';

const statePaths = {
  ages: {
    name: 'ages',
    path: 'ages',
  },
  groups: {
    name: 'groups',
    path: 'groups',
  },
};

const initialState: GroupsV2State['groupsV2'] = {
  ages: {
    ...initialStateIndicators,
  },
  groups: {...initialStateIndicators},
};

//redusers
const groupsNew = createSlice({
  name: moduleNames.groupsV2,
  initialState,
  reducers: {
    ...defaultSliceReducersConstructor(statePaths.ages.name, statePaths.ages.path, initialState).functions,
    ...defaultSliceReducersConstructor(statePaths.groups.name, statePaths.groups.path, initialState).functions,
  },
});

//exports

const {actions, reducer} = groupsNew;
export {reducer as groupsNewReducer};

//fetch fns
const agesActions = actionsGetter(statePaths.ages.name, actions);
const groupsActions = actionsGetter(statePaths.groups.name, actions);

export const clearAges = () => (dispatch: Dispatch) => dispatch(agesActions.clear());

export const loadAges = () =>
  defaultLoader(
    () => getAges(),
    agesActions.loading,
    ({data}: {data: AgeGroupDto[]}) => {
      const dataWithOptions = data?.map((item) => ({...item, label: item.short, value: item.withU}));
      return agesActions.load({data: dataWithOptions});
    },
    agesActions.error,
  );

export const clearGroups = () => (dispatch: Dispatch) => dispatch(groupsActions.clear());

export const loadGroups = <T>(params?: GroupsParams & T, schoolIds?: string[] | undefined) =>
  defaultLoader(
    () => {
      const schoolIdsStr = schoolIds?.join(',');
      return schoolIds?.length === 1
        ? getSchoolGroups(schoolIds[0], params)
        : getGroups({...(params || {}), schoolIds: schoolIdsStr});
    },
    groupsActions.loading,
    groupsActions.load,
    groupsActions.error,
  );
