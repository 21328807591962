import {getActionsMapPlayers} from '@api/StatsAPI';
import {ROLE_CATEGORIES} from '@components/apps/StatsReportForOneGame/constants';
import CommonHelper from '@helpers/CommonHelper';
import useDeepEffect from '@hooks/useDeepEffect';
import {ListSubheader} from '@mui/material';
import {HOCKEY_ROLES_ENUM} from '@src/constants';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import Box from '@ui/MUI/Box';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React, {memo, useState} from 'react';

PlayersFilter.propTypes = {
  usersFilter: PropTypes.array.isRequired,
  setUsersFilter: PropTypes.func.isRequired,
  statisticsIds: PropTypes.arrayOf(PropTypes.string),
  valueEntity: PropTypes.oneOf(['id', 'avangardId']),
};

function PlayersFilter({usersFilter, setUsersFilter, statisticsIds, valueEntity = 'id', disabled}) {
  const [usersFilterData, setUsersFilterData] = useState([]);
  useDeepEffect(() => {
    statisticsIds?.length &&
      getActionsMapPlayers({statisticsIds}).then((data) =>
        setUsersFilterData(
          data?.data
            ?.sort((a, b) => {
              if (a.hockeyRole === b.hockeyRole) {
                return b.shotsCount - a.shotsCount;
              }
              if (a.hockeyRole === HOCKEY_ROLES_ENUM.goaltender) {
                return 0;
              }
              return a.hockeyRole > b.hockeyRole ? 1 : -1;
            })
            ?.map((item) => ({...item, label: CommonHelper.getShortNameByUser(item), value: item?.[valueEntity]})) ||
            [],
        ),
      );
  }, [statisticsIds, valueEntity]);
  return (
    <Box maxWidth={520} mb={4} width="100%">
      <AutocompleteDefault
        disabled={disabled}
        groupBy={(option) => option.hockeyRole}
        label="Игроки"
        limitTags={3}
        options={usersFilterData}
        renderGroup={(params) => (
          <>
            <ListSubheader
              sx={{
                top: '-8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {ROLE_CATEGORIES[params?.group]}
              <TypographyDefault
                color="primary"
                sx={{cursor: 'pointer'}}
                variant="subtitle2"
                onClick={() =>
                  setUsersFilter([
                    ...usersFilter,
                    ...usersFilterData.filter((item) => item?.hockeyRole === params?.group),
                  ])
                }
              >
                Выбрать всех
              </TypographyDefault>
            </ListSubheader>

            {params.children}
          </>
        )}
        renderOption={(props, option) => (
          <Stack component="li" direction="row" sx={{justifyContent: 'space-between !important'}} {...props}>
            <TypographyDefault variant="body1">{option.label}</TypographyDefault>
            <TypographyDefault color="#666666" variant="body1">
              {option.shotsCount} бр.
            </TypographyDefault>
          </Stack>
        )}
        value={usersFilter}
        onChange={(_, value) => setUsersFilter(value)}
      />
    </Box>
  );
}

export default memo(PlayersFilter);
