import Skeleton from '@ui/MUI/Skeleton';
import Stack from '@ui/MUI/Stack';
import React from 'react';

const MaterialSkeleton = () => {
  return (
    <>
      <Stack mb={4} spacing={1}>
        <Skeleton />
        <Skeleton width={79} />
      </Stack>
      <Skeleton height={400} variant="rounded" width={700} />
      <Stack direction="row" mb={4} mt={5} spacing={1}>
        <Skeleton height={26} variant="rounded" width={50} />
        <Skeleton height={26} variant="rounded" width={50} />
      </Stack>
      <Stack maxWidth={300} spacing={0}>
        <Skeleton />
        <Skeleton />
      </Stack>
    </>
  );
};

export default MaterialSkeleton;
