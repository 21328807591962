import React, {useEffect, useRef, useState} from 'react';
import Scrollbar from 'react-scrollbars-custom';

import {Thumb, Track} from './AutoHeightScrollBar';

const AutoHeightScrollBar = ({children, maxHeight, styles, translateContentSizeYToHolder, minHeight, ...props}) => {
  const contentRef = useRef();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const content = contentRef.current;
    setHeight(content?.clientHeight || 0);
  }, [contentRef, children]);

  return (
    <Scrollbar
      {...props}
      mobileNative
      style={{...styles, width: '100%', height, maxHeight}}
      thumbYProps={{
        renderer: (props) => {
          const {elementRef, ...rest} = props;
          return <Thumb {...rest} ref={elementRef} />;
        },
      }}
      trackYProps={{
        renderer: (props) => {
          const {elementRef, ...rest} = props;
          return <Track {...rest} ref={elementRef} />;
        },
      }}
      translateContentSizeYToHolder={translateContentSizeYToHolder}
    >
      <div ref={contentRef} style={{minHeight}}>
        {children}
      </div>
    </Scrollbar>
  );
};

export default AutoHeightScrollBar;
