import Field from '@common/FormField';
import CommonHelper from '@helpers/CommonHelper';
import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import React, {memo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';

function SchoolField({isEdit, ...props}) {
  const {loading} = useStore((state) => state.users, shallowEqual);
  const allSchools = useSelector((state) => state.schools.schoolsMap.toArray());
  const {setValue} = useFormContext();
  return (
    <Field
      readOnly={!isEdit}
      size="small"
      textFieldVariant="outlined"
      type="autocomplete"
      onChange={() => setValue('group', '')}
      {...props}
      isLoading={loading}
      options={CommonHelper.getDropDownItemsByNamedList(allSchools)}
    />
  );
}

export default memo(SchoolField);
