import {getGames, getTournamentById, getTournamentsList} from '@api/TournamentsAPI';
import {actionsGetter, defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {createSlice} from '@reduxjs/toolkit';
import {initialStateIndicators} from '@src/constants';
import {groupBy} from 'lodash';

import moduleNames from '../moduleNames';

const statePaths = {
  tournamentsList: {
    name: 'tournamentsList',
    path: 'tournamentsList',
  },
  tournamentsItem: {
    name: 'tournamentsItem',
    path: 'tournamentsItem',
  },
  tournamentsFilters: {
    path: 'tournamentsFilters',
    name: 'tournamentsFilters',
  },
  games: {
    path: 'games',
    name: 'games',
  },
};

const initialState = {
  [statePaths.tournamentsList.name]: {
    ...initialStateIndicators,
    cache: {},
  },
  [statePaths.tournamentsItem.name]: {
    ...initialStateIndicators,
    data: null,
  },
  currentTournament: {
    ...initialStateIndicators,
    data: {},
    teams: [],
  },
  [statePaths.games.name]: {
    ...initialStateIndicators,
    data: {},
  },
};

//redusers

const tournaments = createSlice({
  name: moduleNames.tournamentsList,
  initialState,
  reducers: {
    clearData: () => initialState,
    tournamentsListLoading: (state) => {
      state.tournamentsList = {...initialState.tournamentsList, cache: state.tournamentsList?.cache, isLoading: true};
    },
    tournamentsListLoad: ({tournamentsList}, {payload: {data, season}}) => {
      tournamentsList.isLoading = false;
      tournamentsList.data = data;
      tournamentsList.isLoad = true;
      if (season) tournamentsList.cache[season] = data;
    },
    tournamentsListError: (state) => {
      state.tournamentsList = {
        ...initialState.tournamentsList,
        isLoad: true,
        error: 'Ошибка при загрузке списка турниров',
      };
    },
    clearTournamentsListCache: ({tournamentsList}) => {
      tournamentsList.cache = initialState.tournamentsList.cache;
    },
    ...defaultSliceReducersConstructor(statePaths.tournamentsItem.name, statePaths.tournamentsItem.path, initialState)
      .functions,

    currentTournamentLoading: (state) => {
      state.currentTournament = {
        ...initialState.currentTournament,
        cache: state.currentTournament?.cache,
        isLoading: true,
      };
    },
    currentTournamentLoad: (state, {payload: {data, needTeamsList}}) => {
      state.currentTournament = {
        ...initialState.currentTournament,
        isLoad: true,
        data,
      };
      if (needTeamsList) {
        state.currentTournament.teams = data?.groups
          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          ?.map((group, groupIndex) => group?.teams?.map((team) => ({...team, groupIndex, group})) || [])
          .flat(1);
      }
    },
    currentTournamentError: (state, {payload}) => {
      state.currentTournament = {
        ...initialState.tournamentsList,
        isLoad: true,
        error: payload,
      };
    },
    currentTournamentClear: (state) => {
      state.currentTournament = initialState.currentTournament;
    },
    ...defaultSliceReducersConstructor(statePaths.games.name, statePaths.games.path, initialState).functions,
  },
});

const {actions, reducer} = tournaments;

const {clearData, tournamentsListLoad, tournamentsListError, tournamentsListLoading} = actions;

export {reducer as tournamentsReducer};

const tournamentsItemActions = actionsGetter(statePaths.tournamentsItem?.name, actions);
const gamesActions = actionsGetter(statePaths.games?.name, actions);
export const clearTournamentsData = () => (dispatch) => dispatch(clearData());
export const clearTournamentsListCache = () => (dispatch) => dispatch(actions.clearTournamentsListCache());
export const loadTournamentsList = (params) => async (dispatch) => {
  dispatch(tournamentsListLoading());
  await getTournamentsList(params)
    .then((tournaments) => {
      dispatch(tournamentsListLoad({data: tournaments.data, season: params?.season}));
    })
    .catch((err) => {
      dispatch(tournamentsListError(err));
    });
};

export const loadTournamentItem = (id) =>
  defaultLoader(
    () => getTournamentById(id),
    tournamentsItemActions.loading,
    tournamentsItemActions.load,
    tournamentsItemActions.error,
  );

export const loadCurrentEditTournament = (id, needTeamsList = false) =>
  defaultLoader(
    () => getTournamentById(id),
    actions.currentTournamentLoading,
    ({data}) => actions.currentTournamentLoad({data, needTeamsList}),
    actions.currentTournamentError,
  );

export const clearCurrentEditTournament = () => (dispatch) => dispatch(actions.currentTournamentClear());

export const loadGames = (params = {}, onLoad = (_data, _actualData) => {}) =>
  defaultLoader(
    () => getGames(params),
    gamesActions.loading,
    ({data}) => {
      const result = groupBy(data, (item) => item?.stage);
      if (result?.group) {
        result.group = Object.values(groupBy(result.group, (item) => item?.group?.name));
      }
      onLoad(data, result);
      return gamesActions.load({data: result});
    },
    gamesActions.error,
  );

export const clearGames = () => (dispatch) => dispatch(gamesActions.clear());
