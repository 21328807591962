import LoadingBox from '@common/LoadingBox';
import UserReportList from '@common/ScoutReport/UserReportList';
import {TitleWrapper} from '@components/apps/PlayerPersonalFile/components/TrainerReport/TrainerReport';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {SCOUT_REPORT_TYPES} from '@src/constants';
import {Typography} from '@ui/MUI';
import React from 'react';
import {useParams} from 'react-router-dom';

function ScoutReport() {
  const {id} = useParams();

  return (
    <Wrapper p="0">
      <TitleWrapper px={4} py={3}>
        <Typography variant="h4">Отчет тренера</Typography>
      </TitleWrapper>
      <LoadingBox isLoading={false}>
        <UserReportList reportType={SCOUT_REPORT_TYPES.inner} userId={id} wrpperProps={{m: '0 !important'}} />
      </LoadingBox>
    </Wrapper>
  );
}

export default ScoutReport;
