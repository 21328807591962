import ScorePage from '@common/Statistics/PlayerStatistics/ScorePage';
import {useMatches} from '@components/apps/StatisticsWithMkcUral/hooks/useMatches';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectCommonTab} from '@selectors/gameStatisticsMkc';
import {clearCommonTab, loadCommonTab} from '@slices/statisticsMKC';
import {PERIOD_VALUES} from '@src/constants';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import MatchesFilter from '../MatchesFilter';

CommonTab.propTypes = {
  hasOT: PropTypes.bool,
  winByOT: PropTypes.bool,
  winByBuls: PropTypes.bool,
};
const initialFilters = {half: PERIOD_VALUES.all, statisticsId: []};
function CommonTab() {
  const {statisticIds, isMatchesLoading} = useMatches();
  const dispatch = useStoreDispatch();
  const {data, isLoading} = useStore(selectCommonTab);
  const [filters, setFilters] = useState(initialFilters);
  const {newCancelToken, isCancel} = useCancelToken({});
  const [qs] = useQueryString();

  useDeepEffect(() => {
    if (statisticIds?.length)
      dispatch(
        loadCommonTab(
          {...filters, statisticsId: statisticIds, cancelToken: newCancelToken(), ...(qs || {})},
          (error) => {
            if (isCancel(error)) return void 0;
          },
        ),
      );
    return () => {
      dispatch(clearCommonTab());
    };
  }, [filters, statisticIds]);

  return (
    <>
      <MatchesFilter
        onClear={() => {
          setFilters(initialFilters);
          dispatch(clearCommonTab());
        }}
      />

      <ScorePage
        handlePeriodChange={(half) => setFilters((prevState) => ({...prevState, half}))}
        periodValue={filters?.half}
        placeholder={!filters?.statisticsId?.length ? 'Выберите матчи, чтобы увидеть статистику' : void 0}
        {...{
          hasOT: data?.winByOT,
          isLoading: isLoading || isMatchesLoading,
          gameResults: {...(data ? data : {}), weAtHome: true},
        }}
      />
    </>
  );
}

export default CommonTab;
