import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {cleanClubs, getClubs} from '../../../../../redux/actions/ScoutReportActions';
import SearchInput from '../../../../common/SearchInput';
import {RoundedBox, StyledPagination} from '../../ScoutReport';
import TeamCards from '../TeamCards';
import {InputWrapper} from './TeamClubs';

const perPage = 12;

function TeamClubs({onClick, hasSearch}) {
  const dispatch = useDispatch();
  const {type} = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const {clubs} = useSelector(({scoutReport}) => ({clubs: scoutReport.clubs}), shallowEqual);
  useEffect(() => {
    dispatch(
      getClubs(type, {
        limit: perPage,
        page: selectedPage,
        name: searchValue,
      }),
    );
    return () => dispatch(cleanClubs());
  }, [selectedPage, searchValue]);

  return (
    <>
      {hasSearch && (
        <RoundedBox>
          <InputWrapper>
            <SearchInput onChange={setSearchValue} />
          </InputWrapper>
        </RoundedBox>
      )}
      <TeamCards cards={clubs} onClick={onClick} />
      {clubs?.data?.total > perPage && (
        <StyledPagination
          count={Math.round(clubs?.data?.total / 12)}
          page={selectedPage}
          onChange={(e, value) => setSelectedPage(value)}
        />
      )}
    </>
  );
}

TeamClubs.propTypes = {
  onClick: PropTypes.func,
  hasSearch: PropTypes.bool,
};

export default TeamClubs;
