import date from '@helpers/date';
import {DEFAULT_DATE_SEND_FORMAT} from '@src/constants';
import {QUESTIONNAIRE_QUESTION_TYPES} from '@src/constants/playerDiary';

export const trainingsQuestions = [
  {
    category: {
      label: 'Зал',
      value: '',
    },
    subCategories: [
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.gymMood,
        isRequired: true,
      },
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.gymHealth,
        isRequired: true,
      },
    ],
  },

  {
    category: {
      label: 'Лед',
      value: '',
    },
    subCategories: [
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.iceMood,
        isRequired: true,
      },
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.iceHealth,
        isRequired: true,
      },
    ],
  },

  {
    category: {
      label: 'Работа над навыком',
      value: '',
    },
    subCategories: [
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.skillMood,
        isRequired: true,
      },
      {
        ...QUESTIONNAIRE_QUESTION_TYPES.skillHealth,
        isRequired: true,
      },
    ],
  },
];

export const DIARY_REQUEST_DATE = date().innerDate.format(DEFAULT_DATE_SEND_FORMAT);
