import {grey} from '@ui/MUI/colors';

export const GOAL_TYPES = {
  goal: 'goal',
  transfer: 'transfer',
};

export const borderRightOptions = {
  headerStyle: {boxShadow: `inset -1px -1px 0 0 ${grey[12]}`},
  cellStyle: {boxShadow: `inset -1px -1px 0 0 ${grey[12]}`},
};
