import './style.scss';
import '@fullcalendar/react/dist/vdom';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import {getWindowDimensions} from '@helpers/CommonHelper';
import {useTournament} from '@hooks/tournament';
import useQueryString from '@hooks/useQueryString';
import {userRoles} from '@src/constants';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import DatePickerMobile from '@ui/MUI/MobileDatePicker';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import moment from 'moment';
import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';

import LessonsAPI from '../../../../api/LessonsAPI';
import useResize from '../../../../hooks/useResize';
import LocalPreloader from '../../../preloader/LocalPreloader';
import {ADDITIONAL_EDUCATION_LESSONS, ARCHIVED_SCHOOL_LESSONS, EVENT_TYPES, SCHOOL_LESSONS} from '../../constants';
import {exportCSV, formatFilterDataForRequest, parseLessonsForCalendar} from '../../helper';
import ListItem from './components/ListItem';
import PrintTable from './components/PrintTable';
import config from './config';

const Calendar = (props) => {
  const {
    onCalendarEventClick = () => {},
    createEvent,
    hasFilter,
    currentUser,
    filter,
    count, //Костыль для обновления календаря из внешнего компонента
    isParentLoading,
    studentsIds,
  } = props;

  const ref = useRef(null);
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const {width} = getWindowDimensions();
  const size = useResize();
  const [isCalendarGrid, setIsCalendarGrid] = useState(width >= 1300);
  const [loading, setLoading] = useState(false);
  const [slotMinTime, setSlotMinTime] = useState('07:00');
  const [events, setEvents] = useState([]);
  const [value, setValue] = useState(new Date(moment().format()));
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [{viewMode}] = useQueryString();
  const [viewType, setViewType] = useState('week');

  const isTextDark = (type) => {
    return (
      type === 'exit_from_academy' ||
      type === 'room_inaccessibility' ||
      type === 'warmup' ||
      type === 'personal' ||
      type === 'training_gpt' ||
      type === 'training_cherry' ||
      type === 'training_opponent' ||
      type === 'training_tkg' ||
      type === 'training_vhl' ||
      type === 'rehabilitation_vhl' ||
      type === 'home_games_vhl' ||
      type === 'training_trainers_ha' ||
      type === 'training_video_hockey'
    );
  };

  const hasRoom = (type) => {
    return type !== 'away_game' && type !== 'room_inaccessibility';
  };

  const hasLesson = (type) => {
    return (
      type === 'additional_education' ||
      type === 'group_consultation' ||
      type === 'personal_consultation' ||
      type === 'school_lessons'
    );
  };

  const hasName = (type) => {
    return type === 'educational_event' || type === 'master';
  };

  const eventTypesTranslations = EVENT_TYPES.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});

  const schoolLessonsTranslations = SCHOOL_LESSONS.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});

  const additionalEducationLessonsTranslations = ADDITIONAL_EDUCATION_LESSONS.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});

  const archivedLessonsTranslations = ARCHIVED_SCHOOL_LESSONS.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});

  const getFirstStringByType = (eventInfo) => {
    const type = eventInfo.event.extendedProps.type;
    if (hasLesson(type)) {
      return (
        schoolLessonsTranslations[eventInfo.event.extendedProps.lesson.description] ||
        archivedLessonsTranslations[eventInfo.event.extendedProps.lesson.description] ||
        additionalEducationLessonsTranslations[eventInfo.event.extendedProps.lesson.description]
      );
    }
    if (hasName(type)) {
      return eventInfo.event.extendedProps.lesson.name;
    }
    return eventTypesTranslations[type];
  };

  const initialView = useMemo(() => {
    if (isCalendarGrid) {
      return 'timeGridWeek';
    }

    if (viewMode === 'week') return 'listWeek';

    if (currentUser.userRole === 'student') {
      return 'listDay';
    }

    return 'timeGridDay';
  }, [isCalendarGrid, viewMode, currentUser]);

  const [tournamentId, setTournamentId] = useState();
  const tournamentName = useTournament(tournamentId)?.data?.name;

  const getTimeGridEventView = (eventInfo) => {
    const eventType = eventInfo.event.extendedProps.type;
    const firstString = getFirstStringByType(eventInfo);

    if (eventType === 'game' || eventType === 'away_game') {
      setTournamentId(eventInfo.event.extendedProps.tournamentId);
      const group = eventInfo.event.extendedProps.groupName;
      const opponent = eventInfo.event.extendedProps.lesson?.opponent;
      return (
        <div
          className="custom-event"
          onClick={() => {
            onCalendarEventClick(eventInfo.event.extendedProps.lesson);
          }}
        >
          <p className="custom-event__time">{firstString}</p>

          <p className="custom-event__time">{tournamentName}</p>

          <p className="custom-event__time custom-event__time-sub">
            {eventType === 'game'
              ? opponent
                ? group + ' — ' + opponent
                : group
              : opponent
                ? opponent + ' — ' + group
                : group}
          </p>
        </div>
      );
    }

    const isEventShort = new Date(eventInfo.event.end).getTime() - new Date(eventInfo.event.start).getTime() <= 1800000;

    const isEventLasts30Minutes =
      new Date(eventInfo.event.end).getTime() - new Date(eventInfo.event.start).getTime() === 1800000;

    return isEventShort ? (
      <div
        className="custom-event"
        onClick={() => {
          onCalendarEventClick(eventInfo.event.extendedProps.lesson);
        }}
      >
        <div
          className={
            isTextDark(eventInfo.event.extendedProps.type)
              ? 'custom-event__time custom-event__time_short custom-event__time-dark'
              : 'custom-event__time custom-event__time_short'
          }
        >
          {isEventLasts30Minutes ? (
            <>
              {firstString}
              <div
                className={
                  isTextDark(eventInfo.event.extendedProps.type)
                    ? 'custom-event__time custom-event__time_short custom-event__time-sub custom-event__time-dark'
                    : 'custom-event__time custom-event__time_short custom-event__time-sub'
                }
              >
                <span>{eventInfo.event.extendedProps.groupName + '; '}</span>
                <span>{hasRoom(eventInfo.event.extendedProps.type) && eventInfo.event.extendedProps.roomName}</span>
              </div>
            </>
          ) : (
            <>
              {firstString + '; '}
              <span>{eventInfo.event.extendedProps.groupName + ';'}</span>
              <span className="custom-event__block">
                {hasRoom(eventInfo.event.extendedProps.type) && eventInfo.event.extendedProps.roomName}
              </span>
            </>
          )}
        </div>
      </div>
    ) : (
      <div
        className="custom-event"
        onClick={() => {
          onCalendarEventClick(eventInfo.event.extendedProps.lesson);
        }}
      >
        <p
          className={
            isTextDark(eventInfo.event.extendedProps.type)
              ? 'custom-event__time custom-event__time-dark'
              : 'custom-event__time'
          }
        >
          {firstString}
        </p>

        <p
          className={
            isTextDark(eventInfo.event.extendedProps.type)
              ? 'custom-event__time custom-event__time-sub custom-event__time-dark'
              : 'custom-event__time custom-event__time-sub'
          }
        >
          {eventInfo.event.extendedProps.groupName || ''}
        </p>

        {hasRoom(eventInfo.event.extendedProps.type) && (
          <p
            className={
              isTextDark(eventInfo.event.extendedProps.type)
                ? 'custom-event__time custom-event__time-sub custom-event__time-dark'
                : 'custom-event__time custom-event__time-sub'
            }
          >
            {eventInfo.event.extendedProps.roomName}
          </p>
        )}
      </div>
    );
  };

  const eventContents = {
    dayGridMonth(eventInfo) {
      return (
        <div
          className="custom-event"
          onClick={() => {
            onCalendarEventClick(eventInfo.event.extendedProps.lesson);
          }}
        >
          <span className="custom-event__type" style={{backgroundColor: eventInfo.backgroundColor}} />
          <p className="custom-event__text">
            {moment(eventInfo.event.start).format('HH:mm')} {eventInfo.event.title}
          </p>
        </div>
      );
    },
    timeGridWeek(eventInfo) {
      return getTimeGridEventView(eventInfo);
    },
    timeGridDay(eventInfo) {
      return getTimeGridEventView(eventInfo);
    },
    listDay: (eventInfo) => (
      <ListItem
        currentUser={currentUser}
        eventInfo={eventInfo}
        size={size}
        onCalendarEventClick={onCalendarEventClick}
      />
    ),
    listWeek: (eventInfo) => (
      <ListItem
        currentUser={currentUser}
        eventInfo={eventInfo}
        size={size}
        onCalendarEventClick={onCalendarEventClick}
      />
    ),
    listMonth: (eventInfo) => (
      <ListItem
        currentUser={currentUser}
        eventInfo={eventInfo}
        size={size}
        onCalendarEventClick={onCalendarEventClick}
      />
    ),
  };

  const renderEventContent = (eventInfo) => {
    const calendarType = eventInfo.view.type;
    return eventContents[calendarType](eventInfo);
  };

  const getEarliestEventTime = (events) => {
    const eventHours = events.length ? events.map((event) => moment(event.start).hours()) : [];
    const earliestEventTime = eventHours.length ? Math.min(...eventHours) : 7;
    return earliestEventTime > 7 ? '07:00' : moment(earliestEventTime, 'HH').format('HH:mm');
  };

  useEffect(() => {
    try {
      const currentHour = moment().format('HH:00:00');
      const el = document.querySelectorAll(`[data-time='${currentHour}']`);
      if (el[0]) {
        el[0].scrollIntoView();
      }
    } catch (error) {
      console.error(error);
    }
  });

  const submitDay = useCallback(() => {
    const Calendar = ref?.current?.getApi();
    Calendar.gotoDate(moment(value).format());
  });

  const openDatePicker = () => {
    setIsDatePickerOpen((prevState) => !prevState);
  };

  const closeDataPicker = () => {
    setIsDatePickerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const dataElement = document.querySelector('.fc-toolbar-title');
    dataElement.addEventListener('click', openDatePicker);
    return () => {
      dataElement.removeEventListener('click', openDatePicker);
    };
  }, []);

  const dayChange = useCallback(() => setViewType('day'), []);
  const weekChange = useCallback(() => setViewType('week'), []);
  const monthChange = useCallback(() => setViewType('month'), []);

  useEffect(() => {
    const dayMode = document.querySelector('.fc-timeGridDay-button');
    const weekMode = document.querySelector('.fc-timeGridWeek-button');
    const monthMode = document.querySelector('.fc-dayGridMonth-button');
    if (dayMode && weekMode && monthMode) {
      dayMode.addEventListener('click', dayChange);
      weekMode.addEventListener('click', weekChange);
      monthMode.addEventListener('click', monthChange);
    }
    return () => {
      if (dayMode && weekMode && monthMode) {
        dayMode.removeEventListener('click', dayChange);
        weekMode.removeEventListener('click', weekChange);
        monthMode.removeEventListener('click', monthChange);
      }
    };
  });

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const addStudentsIdsToFilter = (filter) => {
    if (studentsIds.length > 0) {
      return {...filter, studentIds: studentsIds};
    } else {
      return filter;
    }
  };

  const fetchLessons = async (lessonFetchFilter) => {
    if (currentUser.userRole !== 'student' && lessonFetchFilter?.groupIds?.length) {
      lessonFetchFilter = addStudentsIdsToFilter(lessonFetchFilter);
    }
    try {
      const res = await LessonsAPI.getLessonsByFilter(formatFilterDataForRequest(lessonFetchFilter));
      return res.data.data;
    } catch (error) {
      ReactComponentNotification(NOTIFICATION_TYPES['error'], `Ошибка сервера: ${error}, попробуйте позже`);
      throw error;
    }
  };

  const memoizedFetchLessons = useCallback(
    async (fetchInfo, successCallback, failureCallback) => {
      setLoading(true);
      const studentIds =
        currentUser?.userRole === userRoles.student
          ? [{label: `${currentUser?.lastName} ${currentUser?.firstName}`, value: currentUser?.id}]
          : filter?.studentIds;
      const schoolId =
        currentUser?.userRole === userRoles.student
          ? {label: currentUser?.schools[0]?.name, value: currentUser?.schools[0]?.objectId}
          : filter?.schoolId;
      let lessonFetchFilter = {
        ...filter,
        studentIds: studentIds,
        schoolId: schoolId,
        startDate: fetchInfo.start.getTime(),
        endDate: fetchInfo.end.getTime(),
      };

      if (filter.studentIds?.length) {
        lessonFetchFilter.groupIds = [];
      }

      if (lessonFetchFilter.schoolId?.value || lessonFetchFilter.roomIds?.length) {
        try {
          const lessons = await fetchLessons(lessonFetchFilter);
          const parsedLessons = parseLessonsForCalendar(lessons);
          setLoading(false);
          const earliestEventTime = getEarliestEventTime(parsedLessons);
          setEvents(parsedLessons);
          setSlotMinTime(earliestEventTime);
          successCallback(parsedLessons);
        } catch (error) {
          failureCallback(error);
        }
      }
      setLoading(false);
      successCallback([]);
    },
    [filter.schoolId, filter.roomIds, filter.groupIds, filter.studentIds, count],
  );
  return (
    <React.Fragment>
      <PrintTable calendarRef={ref} events={events} filters={filter} printRef={printRef} viewType={viewType} />
      <DatePickerMobile
        closeDataPicker={closeDataPicker}
        handleChange={handleChange}
        isDatePickerOpen={isDatePickerOpen}
        submitDay={submitDay}
        value={value}
      />

      <LocalPreloader visible={loading} />
      <FullCalendar
        contentHeight={isParentLoading || loading ? 700 : 'auto'}
        customButtons={{
          gridButton: {
            text: 'Календарь',
            click: () => {
              setIsCalendarGrid(true);
              ref.current.getApi().changeView('dayGridMonth');
            },
          },
          listButton: {
            text: 'Список',
            click: () => {
              setIsCalendarGrid(false);
              ref.current.getApi().changeView('listMonth');
            },
          },
          exportButton: {
            text: 'Экспорт',
            click: () => {
              exportCSV(events, filter);
            },
          },
          printButton: {
            text: 'Печать',
            click: () => {
              try {
                handlePrint();
              } catch (exc) {
                console.error('Can not print');
              }
            },
          },
          eventAddButton: {
            text: 'Добавить мероприятие',
            click: () => {
              try {
                createEvent();
              } catch (error) {
                console.error(error);
              }
            },
          },
        }}
        eventContent={renderEventContent}
        events={memoizedFetchLessons}
        headerToolbar={{
          left: hasFilter
            ? `listButton,gridButton,exportButton,${
                currentUser.userRole !== 'parent' && events.length && viewType !== 'month' && 'printButton'
              },eventAddButton,`
            : 'prev,title,next',
          center: hasFilter ? 'prev,title,next' : '',
          right:
            (isCalendarGrid === false && currentUser.userRole === 'student' ? 'cellFilling' : '') ||
            (isCalendarGrid === true ? 'timeGridDay, timeGridWeek, dayGridMonth' : 'listDay, listWeek, listMonth'),
        }}
        initialView={initialView}
        plugins={[dayGridPlugin, listPlugin, interactionPlugin, resourceTimeGridPlugin, momentPlugin, timeGridPlugin]}
        ref={ref}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        select={(date) => {
          try {
            createEvent(date);
          } catch (error) {
            console.error(error);
          }
        }}
        slotMinTime={slotMinTime}
        {...config}
      />
    </React.Fragment>
  );
};

export default memo(Calendar);
