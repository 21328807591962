import useStore from '@hooks/useStore';
import {Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React, {memo, useMemo} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import styled from 'styled-components';

const Crumb = ({path, label, dynamicTitle, isDynamic, isLast, isLoading}) => {
  return (
    <Stack alignItems="center" direction="row">
      {isLast ? (
        <Typography
          withoutWrapper
          color="#fff"
          isLoading={isDynamic && isLoading}
          preloaderWidthBetween={[150, 200]}
          variant="body2"
        >
          {isDynamic ? dynamicTitle || label : label}
        </Typography>
      ) : (
        <Typography
          color={grey['54']}
          component={Link}
          sx={{textDecoration: 'none', textOverflow: 'ellipsis'}}
          to={path}
          variant="body2"
        >
          {isDynamic ? dynamicTitle || label : label}
        </Typography>
      )}
      {!isLast && (
        <Typography color={grey['54']} mx={0} variant="body2">
          /
        </Typography>
      )}
    </Stack>
  );
};

const BreadCrumbs = ({
  dynamicTitle = false,
  crumbs = [],
  withRoutingCheck = false,
  className = '',
  isLoading = false,
}) => {
  const match = useRouteMatch();
  const userRole = useStore((state) => state.users.currentUser?.userRole);

  const formatedRoutesByUserRole = useMemo(() => {
    if (!withRoutingCheck) return crumbs;
    if (match.path === '/') return crumbs;
    return crumbs.filter((crumb) => crumb.accessRoles.includes(userRole) || crumb.accessRoles === 'all');
  }, [crumbs, userRole, withRoutingCheck]);

  const findedCrumbs = formatedRoutesByUserRole.find((route) => route.path === match.path);
  const currentCrumbs = withRoutingCheck && match.path !== '/' ? findedCrumbs?.breadCrumbs || [] : crumbs;

  return (
    currentCrumbs && (
      <Crumbs className={className}>
        {currentCrumbs.map((crumb, index, self) => (
          <Crumb
            key={index}
            {...crumb}
            dynamicTitle={dynamicTitle}
            isLast={self.length - 1 === index}
            isLoading={isLoading}
          />
        ))}
      </Crumbs>
    )
  );
};

const Crumbs = styled.div`
  display: flex;
  ${({theme}) => theme.breakpoints.down('xs')} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export default memo(BreadCrumbs);
