import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import {HAND_MAP, POTENTIAL_ASSOCIATIONS, ROLE_MODEL_ASSOCIATIONS} from '../../../../../constants';
import {ShowButton} from '../../../../common/ScoutReport/CommonScoutReport';
import PlayersHorizontalCard from '../../../../students/tools/PlayersHorizontalCard';
import DefaultSortTable from '../../../../ui/MUI/Table/Table';
import PlayersTableTooltip from '../PlayersTableTooltip';

const InnerPlayersTable = ({students}) => {
  return (
    <DefaultSortTable
      columns={[
        {
          key: 'player',
          field: 'player',
          headerName: 'Игрок',
          minWidth: 275,
          align: 'left',
          headerAlign: 'left',
          flex: 1,
          sortComparator: (v1, v2, param1, param2) => {
            if (param1.value.lastName.toLowerCase() > param2.value.lastName.toLowerCase()) {
              return 1;
            }
            if (param1.value.lastName.toLowerCase() < param2.value.lastName.toLowerCase()) {
              return -1;
            }
            return 0;
          },
          renderCell: (params) => {
            if (window.innerWidth <= 360) {
              params.value.firstName = params.value.firstName[0] + '.';
            }
            return (
              <Link className="TableColumnLink" to={`/student/${params.value.id}`}>
                <PlayersHorizontalCard student={params.value} />
              </Link>
            );
          },
        },
        {
          key: 'birthDate',
          field: 'birthDate',
          headerName: 'Дата рождения',
          minWidth: 175,
          align: 'left',
          headerAlign: 'left',
          flex: 0.5,
        },
        {
          key: 'hand',
          field: 'hand',
          headerName: 'Хват',
          minWidth: 175,
          align: 'left',
          headerAlign: 'left',
          flex: 1,
        },
        {
          key: 'potential',
          field: 'potential',
          headerName: 'Потенциал',
          minWidth: 175,
          align: 'left',
          headerAlign: 'left',
          flex: 1,
          sortable: false,
          renderCell: (params) => <PlayersTableTooltip associations={POTENTIAL_ASSOCIATIONS} data={params.value} />,
        },
        {
          key: 'roleModel',
          field: 'roleModel',
          headerName: 'Ролевая модель',
          minWidth: 165,
          align: 'left',
          headerAlign: 'left',
          flex: 1,
          sortable: false,
          renderCell: (params) => <PlayersTableTooltip associations={ROLE_MODEL_ASSOCIATIONS} data={params.value} />,
        },
        {
          key: 'actions',
          field: 'actions',
          headerName: 'Действиe',
          minWidth: 155,
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          sortable: false,
          renderCell: (params) => {
            return (
              <ShowButton>
                <Link className="BreakLinkStyle" to={`/trainer_report/inner/users/${params.value}`}>
                  Смотреть
                </Link>
              </ShowButton>
            );
          },
        },
      ]}
      hasMaxHeight={false}
      rows={students?.map((student, index) => {
        return {
          id: `${student.id}.id ${index}`,
          player: student,
          birthDate: moment(student.birthday).format('DD.MM.YYYY'),
          hand: HAND_MAP[student.hand],
          potential: student.potential,
          roleModel: student.roleModel,
          actions: student.id,
        };
      })}
    />
  );
};

InnerPlayersTable.propTypes = {
  role: PropTypes.string,
  group: PropTypes.array,
};

export default InnerPlayersTable;
