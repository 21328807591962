import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useCallback} from 'react';

function useFilters({cleanup = () => {}, setter = () => {}, getter = () => {}, effect = () => {}, effectDeps = []}) {
  const filterValues = useStore(getter);
  const dispatch = useStoreDispatch();
  const setFilters = useCallback((data = {}) => dispatch(setter(data)), [setter]);
  useDeepEffect(() => {
    effect(setFilters);
    return () => {
      dispatch(cleanup());
    };
  }, [...effectDeps]);

  return {filterValues, setFilters};
}

export default useFilters;
