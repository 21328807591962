import {loadStatsMatchShifts} from '@actions/StatsActions';
import FullHeightWrapper from '@common/FullHeightWrapper';
import VideoPlayer from '@common/Statistics/VideoPlayer';
import {minuteInMs} from '@components/apps/StatsReportForOneGame/constants';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {convertTimeMMSS} from '@helpers/dateHelper';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStoreDispatch from '@hooks/useStoreDispatch';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {PERIOD_VALUES} from '@src/constants';
import {StatisticsGetMatchShiftsResponseDto} from '@src/types/generated';
import {Box, Button, Stack, Tooltip, Typography} from '@ui/MUI';
import {yellow} from '@ui/MUI/colors';
import {Cancel} from 'axios';
import {cloneDeep} from 'lodash';
import React, {useState} from 'react';

type ShiftsProps = {
  selectedPlayerName: string;
  statistic: StatisticsGetMatchShiftsResponseDto & {
    loading?: boolean;
    isLoading?: boolean;
    error?: object | null;
  };
  statisticsIds: Array<string>;
  cleaner?: () => (dispatch: unknown) => unknown;
  loader?: (params: object, isCancel?: (value: unknown) => value is Cancel) => (dispatch: unknown) => Promise<void>;
};

function Shifts({selectedPlayerName, statistic, statisticsIds, cleaner, loader}: ShiftsProps) {
  const dispatch = useStoreDispatch();

  const [selectedActionIndex, setSelectedActionIndex] = useState(0);

  const shifts = statistic?.shifts?.filter(({fullName}: {fullName: string}) => {
    const isNameAndSurnameSame =
      fullName?.includes(selectedPlayerName?.split(' ')?.[0]) &&
      fullName?.includes(selectedPlayerName?.split(' ')?.[1]);
    return isNameAndSurnameSame;
  });
  const selectedShift = shifts?.[selectedActionIndex];

  const isNoData = !shifts?.length;

  useDeepEffect(() => {
    if (statisticsIds?.[0]) {
      dispatch(loadStatsMatchShifts({statisticId: statisticsIds?.[0], half: PERIOD_VALUES.all}));
    }
    return () => {
      cleaner && dispatch(cleaner());
    };
  }, [statisticsIds, loader]);

  const {infoItems, formattedShiftData} = useDeepMemo(() => {
    const [, start, end, total] = selectedShift?.value || [];
    const infoItems = [
      {
        label: 'Продолжительность:',
        value: convertTimeMMSS(total),
        icon: Number(total) > minuteInMs && (
          <Tooltip title="Переигранная смена">
            <ErrorOutlineOutlinedIcon fontSize="inherit" sx={{fill: yellow['100']}} />
          </Tooltip>
        ),
      },
      {label: 'Время начала:', value: convertTimeMMSS(start)},
      {label: 'Время окончания:', value: convertTimeMMSS(end)},
      {label: 'Игрок:', value: selectedShift?.fullName},
    ];
    const formattedShiftData = cloneDeep(selectedShift);
    if (formattedShiftData?.videoOffsets?.end) {
      formattedShiftData.videoOffsets.end =
        formattedShiftData?.videoOffsets?.end + Number(total) >= 0 ? Math.round(Number(total) / 1000) : 0;
    }

    return {infoItems, formattedShiftData};
  }, [selectedShift]);

  const video = {data: formattedShiftData, key: formattedShiftData?.id};
  return (
    <>
      {!isNoData && (
        <Stack
          alignItems="center"
          direction="row"
          flexWrap={{xxs: 'wrap', xs: 'nowrap'}}
          gap={3}
          justifyContent="space-between"
          marginBottom={3}
          width={{xxs: '100%', xs: 'unset'}}
        >
          <Typography
            isLoading={statistic?.isLoading || statistic?.loading}
            preloaderWidthBetween={[100, 120]}
            variant="h3"
          >
            Смена. {selectedActionIndex + 1}/{shifts?.length}
          </Typography>
          <Box>
            <Button
              color="primary"
              disabled={selectedActionIndex === 0 || statistic?.isLoading || statistic?.loading}
              startIcon={<ChevronLeftOutlinedIcon />}
              sx={{width: {xxs: '45%', xs: 'unset'}, mr: 3}}
              variant="outlined"
              onClick={() => {
                setSelectedActionIndex(selectedActionIndex - 1);
              }}
            >
              назад
            </Button>
            <Button
              color="primary"
              disabled={statistic?.isLoading || statistic?.loading || selectedActionIndex + 1 === shifts?.length}
              endIcon={<ChevronLeftOutlinedIcon sx={{transform: 'rotate(180deg)'}} />}
              sx={{width: {xxs: '45%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => {
                setSelectedActionIndex(selectedActionIndex + 1);
              }}
            >
              вперед
            </Button>
          </Box>
        </Stack>
      )}
      {statistic?.isLoading || statistic?.loading ? (
        <LocalPreloader visible={true} />
      ) : (
        <>{video?.data && <VideoPlayer data={video?.data} key={video?.key} />}</>
      )}
      <Box sx={{mt: 3, width: '100%'}}>
        {isNoData ? (
          <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
            <Typography variant="subtitle1">Игрок не провел ни одной смены в данном матче.</Typography>
          </FullHeightWrapper>
        ) : (
          infoItems?.map(({label, value, icon}, index) => {
            return (
              <Typography
                isLoading={statistic?.isLoading || statistic?.loading}
                key={index}
                mb={1}
                preloaderWidthBetween={[70, 100]}
                sx={{position: 'relative'}}
                variant="body1"
              >
                {label}{' '}
                <Typography
                  component="span"
                  isLoading={statistic?.isLoading || statistic?.loading}
                  preloaderWidthBetween={[100, 120]}
                  variant="subtitle1"
                >
                  {value}
                </Typography>
                {icon && (
                  <Box component="span" sx={{position: 'absolute', top: '60%', transform: 'translateY(-50%)', ml: 0}}>
                    {icon}
                  </Box>
                )}
              </Typography>
            );
          })
        )}
      </Box>
    </>
  );
}
export default Shifts;
