import {usePlayersEstimates} from '@common/DevelopmentJournal/hooks';
import FullHeightWrapper from '@common/FullHeightWrapper';
import ReactTableComponent from '@common/ReactTableComponent';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {Typography} from '@ui/MUI';
import React from 'react';

import {columns} from './constants';

export const Table = () => {
  const {isLoading, data, isNotFilledFilters} = usePlayersEstimates();

  if (!isNotFilledFilters && !data?.length)
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
        <Typography variant="subtitle1">По выбранным критериям пока нет ни одного навыка.</Typography>
      </FullHeightWrapper>
    );

  if (isNotFilledFilters)
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center">
        <Typography variant="subtitle1">Заполните фильтры, чтобы увидеть данные.</Typography>
      </FullHeightWrapper>
    );

  if (isLoading)
    return (
      <FullHeightWrapper alignItems="center" display="flex" justifyContent="center" position="relative">
        <LocalPreloader visible />
      </FullHeightWrapper>
    );

  return <ReactTableComponent columns={columns} data={data} />;
};
