import useStore from '@hooks/useStore';
import {Box, Typography} from '@ui/MUI';
import {grey, primary} from '@ui/MUI/colors';
import React from 'react';

import LocalPreloader from '../../preloader/LocalPreloader';
import TableEmptyBlock from '../../ui/TableEmptyBlock';
import PsychoProfileChart from '../charts/PsychoProfileChart';

export default function PlayerPsychotestReportTab() {
  const playerPsychotestReport = useStore((state) =>
    state.psychoTesting.playerPsychotestReport ? state.psychoTesting.playerPsychotestReport : [],
  );
  const loading = useStore((state) => state.psychoTesting.loading);

  const legend = [
    {title: 'Реакция на простой стимул', subtitle: 'Быстрота реагирования на зрительный стимул.'},
    {
      title: 'Реакция на сложный стимул',
      subtitle: 'Быстрота реагирования на зрительный стимул в ситуации выбора из двух альтернатив.',
    },
    {
      title: 'Мышление',
      subtitle:
        'Наглядно-действенное мышление в условиях дефицита времени (анализ ситуации и быстроты принятия решения)',
    },
    {title: 'Внимание', subtitle: 'Анализ ситуации и быстроты принятия решения.'},
    {
      title: 'Пространственно-временное предвидение',
      subtitle: 'Зрительная экстраполяция (в какой точке и в какое время окажется объект).',
    },
  ];

  return (
    <>
      {Object.values(playerPsychotestReport).length > 0 ? (
        <>
          <LocalPreloader visible={loading} />

          <PsychoProfileChart playerPsychotestReport={playerPsychotestReport} />
          <Box pb={4} px={{xxs: 4, xs: 5}}>
            <Typography mb={3} variant="subtitle1">
              Описание показателей
            </Typography>
            <Box sx={{backgroundColor: '#fff', border: `1px solid ${grey[24]}`, borderRadius: 3, p: 4}}>
              {legend?.map(({title, subtitle}, index) => {
                return (
                  <Box key={index} sx={{'&:not(:last-child)': {pb: 4}}}>
                    <Typography sx={{position: 'relative', pl: 2}} variant="subtitle1">
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: 0,
                          transform: 'translateY(-50%)',
                          backgroundColor: primary?.main,
                          borderRadius: '50%',
                          width: 4,
                          height: 4,
                        }}
                        variant="span"
                      />
                      {title}
                    </Typography>
                    <Typography pl={2} variant="body2">
                      {subtitle}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </>
      ) : (
        <TableEmptyBlock text={'Для отображения данных отчета выберите нужные значения фильтров.'} />
      )}
    </>
  );
}
