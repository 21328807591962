import useStore from '@hooks/useStore';
import {Box, Typography} from '@ui/MUI';
import {grey, primary} from '@ui/MUI/colors';
import TabsNew from '@ui/MUI/TabsNew';
import React from 'react';

import LocalPreloader from '../../preloader/LocalPreloader';
import TableEmptyBlock from '../../ui/TableEmptyBlock';
import TypologicalProfileChart from '../charts/TypologicalProfileChart';

export default function PlayerTypologicalReportTab({isAllFiltersNotEmpty}) {
  const userRole = useStore((state) => state.users.currentUser.userRole);

  const playerTypologicalReport = useStore((state) =>
    state.psychoTesting.playerTypologicalReport ? state.psychoTesting.playerTypologicalReport : [],
  );

  const loading = useStore((state) => state.psychoTesting.loading);

  const legend = {
    primary: [
      {
        title: 'Экстраверсия',
        subtitle:
          'Обращенность в мир реально существующих объектов и ценностей, открытость, стремление к расширению круга контактов, общительность.',
      },
      {
        title: 'Спонтанность',
        subtitle: 'Непродуманность в высказываниях и поступках.',
      },
      {
        title: 'Агрессивность',
        subtitle: 'Активная самореализация, упрямство и своеволие в отстаивании своих интересов.',
      },
      {
        title: 'Ригидность',
        subtitle:
          'Инертность, тугоподвижность установок, субъективизм, повышенное стремление к отстаиванию своих взглядов и принципов, критичность в отношении иных мнений.',
      },
      {
        title: 'Интроверсия',
        subtitle:
          'Обращенность в мир субъективных представлений и переживаний, тенденция к уходу в мир иллюзий, фантазий и субъективных идеальных ценностей сдержанность, замкнутость.',
      },
      {
        title: 'Сензитивность',
        subtitle: 'Впечатлительность, склонность к рефлексии, пессимистичность в оценке перспектив.',
      },
      {
        title: 'Тревожность',
        subtitle: 'Эмоциональность, восприимчивость, незащищённость.',
      },
      {
        title: 'Лабильность',
        subtitle:
          'Эмотивность, выраженная изменчивость настроения, мотивационная неустойчивость, сентиментальность, стремление к эмоциональной вовлеченности.',
      },
    ],
    secondary: [
      {
        title: 'Лидерство',
        subtitle: 'Самостоятельность в принятии решений, предприимчивость и стремление быть ведущим.',
      },
      {
        title: 'Неконформность',
        subtitle:
          'Преобладание значимости собственного мнения над значимостью мнения группы, общественных норм и стереотипов. Ориентация на двигательную активность (спортивная деятельность, путешествия, профессии, связанные с повышенными физическими нагрузками).',
      },
      {
        title: 'Конфликтность',
        subtitle:
          'Сочетание устойчивости собственного мнения с тенденцией к агрессивной манере самоутверждения вопреки интересам окружающих.',
      },
      {
        title: 'Индивидуализм',
        subtitle:
          'Устойчивость собственного мнения, временами достаточно самобытного и субъективного. Системный, синтетический, прагматичный стиль мышления.',
      },
      {
        title: 'Зависимость',
        subtitle:
          'Чувствительность и ранимость в отношении среды, с другой стороны, выраженной потребностью в глубокой и постоянной привязанности и в защите со стороны более сильной личности, что создает почву для поведения, направленного на избегание конфликта и поиска щадящей социальной ниши.',
      },
      {
        title: 'Конформность',
        subtitle: 'Избыточная ориентированность на общепринятые нормы поведения.',
      },
      {
        title: 'Компромиссность',
        subtitle: 'Стремление к самоутверждению сопровождается избеганием конфликта.',
      },
      {
        title: 'Коммуникативность',
        subtitle: 'Склонность к широким социальным контактам и коммуникабельность.',
      },
    ],
  };

  return (
    <>
      {Object.values(playerTypologicalReport).length ? (
        <>
          <LocalPreloader visible={loading} />
          <TypologicalProfileChart playerTypologicalReport={playerTypologicalReport} />
          <Box pb={4} px={{xxs: 4, xs: 5}}>
            <Typography mb={3} variant="subtitle1">
              Описание показателей
            </Typography>
            <Box sx={{backgroundColor: '#fff', border: `1px solid ${grey[24]}`, borderRadius: 3, p: 4}}>
              <TabsNew
                tabs={[
                  {
                    id: 'primary',
                    label: 'Первичные',
                    value: (
                      <>
                        {legend?.primary?.map(({title, subtitle}, index) => {
                          return (
                            <Box key={index} sx={{'&:not(:last-child)': {pb: 4}}}>
                              <Typography sx={{position: 'relative', pl: 2}} variant="subtitle1">
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: 0,
                                    transform: 'translateY(-50%)',
                                    backgroundColor: primary?.main,
                                    borderRadius: '50%',
                                    width: 4,
                                    height: 4,
                                  }}
                                  variant="span"
                                />
                                {title}
                              </Typography>
                              <Typography pl={2} variant="body2">
                                {subtitle}
                              </Typography>
                            </Box>
                          );
                        })}
                      </>
                    ),
                  },
                  {
                    id: 'secondary',
                    label: 'Вторичные',
                    value: (
                      <>
                        {legend?.secondary?.map(({title, subtitle}, index) => {
                          return (
                            <Box key={index} sx={{'&:not(:last-child)': {pb: 4}}}>
                              <Typography sx={{position: 'relative', pl: 2}} variant="subtitle1">
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: 0,
                                    transform: 'translateY(-50%)',
                                    backgroundColor: primary?.main,
                                    borderRadius: '50%',
                                    width: 4,
                                    height: 4,
                                  }}
                                  variant="span"
                                />
                                {title}
                              </Typography>
                              <Typography pl={2} variant="body2">
                                {subtitle}
                              </Typography>
                            </Box>
                          );
                        })}
                      </>
                    ),
                  },
                ]}
                tabsWrapperProps={{sx: {boxShadow: 'none', paddingLeft: '0px !important', pb: 4}}}
              />
            </Box>
          </Box>
        </>
      ) : (
        <TableEmptyBlock
          text={
            userRole !== 'student'
              ? isAllFiltersNotEmpty
                ? 'Данные отсутствуют.'
                : 'Недостаточно данных. Для отображения формы выберите школу, команду и игрока.'
              : 'За выбранный сезон нет данных.'
          }
        />
      )}
    </>
  );
}
