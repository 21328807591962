import FutureGame from '@components/apps/StatisticsWithMkcUral/components/TournamentTab/components/FutureGame';
import Wrapper from '@components/apps/StatisticsWithMkcUral/components/Wrapper';
import {GAME_TYPES} from '@components/apps/StatisticsWithMkcUral/constants';
import TabsNew from '@ui/MUI/TabsNew';
import PropTypes from 'prop-types';
import React from 'react';
import {useParams} from 'react-router-dom';

import GamesList from '../../../GamesList';
import PastGame from '../../../PastGame';

Games.propTypes = {
  isFinal: PropTypes.bool,
  season: PropTypes.string,
};

function Games({isFinal = false, season}) {
  const {teamMKCId} = useParams();
  return (
    <Wrapper p="0">
      <TabsNew
        tabs={[
          {
            value: (
              <GamesList
                Component={PastGame}
                isFinal={isFinal}
                placeholder="Нет данных по прошедшим играм"
                season={season}
                teamMKCId={teamMKCId}
                type={GAME_TYPES.pastGames}
              />
            ),
            label: 'Прошедшие игры',
          },
          {
            value: (
              <GamesList
                hasWrapper
                Component={FutureGame}
                isFinal={isFinal}
                placeholder="Сезон завершен"
                season={season}
                teamMKCId={teamMKCId}
                type={GAME_TYPES.nextGames}
                wrapperProps={{gap: {xxs: 2, lg: 3}, flexWrap: 'wrap'}}
              />
            ),
            label: 'Будущие игры',
          },
        ]}
      />
    </Wrapper>
  );
}

export default Games;
