import {IconButtonProps, Theme, useMediaQuery} from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import React from 'react';

function IconButton({size, ...props}: IconButtonProps) {
  const isSmResolution = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return (
    <MuiIconButton
      size={size || isSmResolution ? 'small' : 'medium'}
      sx={{...props.sx, position: 'relative'}}
      {...props}
    />
  );
}

export default IconButton;
