import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {IconButton, Stack, Typography} from '@ui/MUI';
import LoadingButton from '@ui/MUI/LoadingButton';
import React from 'react';
import styled from 'styled-components';

import ButtonDefault from '../../Button';

const CustomizedDialog = styled((props) => <Dialog {...props} />)``;

const CancelIcon = () => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" fill="#F3F3F3" r="12" />
    <path
      clipRule="evenodd"
      d="M7.16037 7.54755C6.94654 7.76136 6.94654 8.10805 7.16037 8.32188L10.8385 12L7.16037 15.6781C6.94654 15.8919 6.94654 16.2386 7.16037 16.4525L7.54755 16.8396C7.76138 17.0535 8.10805 17.0535 8.32188 16.8396L12 13.1615L15.6782 16.8396C15.892 17.0535 16.2387 17.0535 16.4525 16.8396L16.8396 16.4525C17.0535 16.2386 17.0535 15.8919 16.8396 15.6781L13.1615 12L16.8396 8.32188C17.0535 8.10805 17.0535 7.76136 16.8396 7.54755L16.4525 7.16037C16.2387 6.94654 15.892 6.94654 15.6782 7.16037L12 10.8385L8.32188 7.16037C8.10805 6.94654 7.76138 6.94654 7.54755 7.16037L7.16037 7.54755Z"
      fill="#2C2C2C"
      fillRule="evenodd"
    />
  </svg>
);

export default function DialogDefault({
  open,
  isLoading,
  handleClickOpen = () => {},
  onActionButtonClick = () => {},
  onCloseDefault = () => {},
  title = '',
  content = React.ReactNode,
  actionText = 'Сохранить',
  closeText = 'Закрыть',
  actionButtonProps = {},
  closeButtonProps = {},
  sx,
  ...props
}) {
  const defaultStyles = {
    '.MuiDialog-paper': {
      p: 2,
      boxShadow: '0 4px 12px rgba(0, 0, 0, .1)',
      borderRadius: '6px',
      minWidth: '428px',
      '@media screen and (max-width: 480px)': {
        minWidth: '300px',
      },
    },
    '.MuiDialogContent-root': {
      py: 3,
      px: '0px',
    },
    '.MuiDialogActions-root': {
      p: '0px',
      justifyContent: 'space-between',
    },
  };

  return (
    <CustomizedDialog
      {...props}
      aria-labelledby="customized-dialog-title"
      handleClickOpen={handleClickOpen}
      open={open}
      sx={{...defaultStyles, ...sx}}
      onClose={onCloseDefault}
    >
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography variant="h4">{title}</Typography>
        <IconButton
          aria-label="close"
          size="medium"
          sx={{padding: '0px'}}
          onClick={() => {
            onCloseDefault();
          }}
        >
          <CancelIcon />
        </IconButton>
      </Stack>

      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <LoadingButton
          {...actionButtonProps}
          color="primary"
          loading={isLoading}
          text={actionText}
          variant="contained"
          onClick={() => {
            onActionButtonClick();
          }}
        >
          {actionText}
        </LoadingButton>
        <ButtonDefault
          {...closeButtonProps}
          color="secondary"
          disabled={isLoading}
          text={closeText}
          variant="contained"
          onClick={() => {
            onCloseDefault();
          }}
        />
      </DialogActions>
    </CustomizedDialog>
  );
}
