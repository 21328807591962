/**
 *
 * @param {{}} obj
 */
const cleanObject = (obj) => {
  if (!obj || typeof obj !== 'object') throw new Error('obj is not defined or invalid type');
  return Object.keys(obj).reduce((prev, key) => {
    if (obj[key]) return {...prev, [key]: obj[key]};
    return prev;
  }, {});
};

export default cleanObject;
