import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {calcWidth} from '@helpers/theme';
import {longDash} from '@src/constants';
import {Divider, Stack, Typography} from '@ui/MUI';
import {grey, primary} from '@ui/MUI/colors';
import React, {useMemo} from 'react';

function Metric({
  label,
  count,
  unit,
  percent,
  countInMajority,
  placeInTournament,
  isLoading,
  isOddLength,
  isLastOfThree,
}) {
  const metrics = useMemo(() => {
    const result = [];
    if (percent || count) result.push({count: percent || count, label: unit || 'всего'});
    if (countInMajority) result.push({count: countInMajority, label: 'в большин.'});
    return result;
  }, [percent, countInMajority, count, unit]);
  return (
    <Wrapper
      p={{xxs: 3, xs: 4}}
      width={(theme) => ({
        xxs: '100%',
        xs: calcWidth(2, theme.spacing(3)),
        md: calcWidth(3, theme.spacing(3)),
        lg: calcWidth(isOddLength && isLastOfThree ? 3 : 4, theme.spacing(3)),
      })}
    >
      <Typography mb={3} variant="h4">
        {label}
      </Typography>
      {count || percent || placeInTournament ? (
        <>
          <Stack
            alignItems="center"
            direction="row"
            divider={<Divider flexItem borderColor={primary.main} orientation="vertical" variant="inset" />}
            spacing={3}
          >
            {metrics?.map(({count, label}, index) => (
              <Stack alignItems="flex-end" direction="row" gap={0} key={index}>
                <Typography
                  color="primary"
                  fontSize="40px"
                  fontWeight={600}
                  isLoading={isLoading}
                  lineHeight="40px"
                  preloaderWidthBetween={[20, 40]}
                >
                  {count || longDash}
                </Typography>
                <Typography color="primary" variant="subtitle1">
                  {label}
                </Typography>
              </Stack>
            ))}
          </Stack>
          {placeInTournament && (
            <>
              <Divider sx={{my: 3}} />
              <Stack direction="column">
                <Stack alignItems="flex-end" direction="row" gap={0}>
                  <Typography color="primary" isLoading={isLoading} preloaderWidthBetween={[10, 30]} variant="h3">
                    {placeInTournament}
                  </Typography>
                  <Typography color="primary" fontWeight={600} variant="body2">
                    место
                  </Typography>
                  <Typography color={grey['54']} variant="body2">
                    в турнире
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
        </>
      ) : (
        <Typography py={3} variant="subtitle1">
          Нет данных по показателю
        </Typography>
      )}
    </Wrapper>
  );
}

export default Metric;
