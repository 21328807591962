import AdapterMoment from '@mui/lab/AdapterMoment';
import {InputProps} from '@mui/material/Input/Input';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {
  DesktopDatePicker as DesktopDatePickerMUI,
  DesktopDatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {Moment} from 'moment/moment';
import React from 'react';

import TextFieldDefault from '../TextField';

type DatePickerCustomProps = Pick<TextFieldProps, 'size' | 'variant' | 'helperText' | 'error'> &
  DesktopDatePickerProps<Moment, AdapterMoment> & {
    textFieldStyles: Pick<InputProps, 'sx'>;
    inputStyles: Pick<InputProps, 'sx'>;
    ['data-testid']: string;
    cancelText: string;
    okText: string;
  } & HTMLDivElement;

const DesktopDatePicker = ({
  label,
  value = null,
  onChange = () => {},
  open,
  onClose,
  onAccept,
  toolbarTitle = '',
  inputFormat = 'DD.MM.YYYY',
  mask = '__.__.____',
  error,
  helperText,
  variant,
  size,
  renderInput,
  ...props
}: DatePickerCustomProps) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePickerMUI
          inputFormat={inputFormat}
          label={label}
          mask={mask}
          open={open}
          renderInput={
            renderInput ||
            ((params: TextFieldProps) => {
              return (
                <TextFieldDefault
                  {...params}
                  data-testid={props?.['data-testid']}
                  error={error}
                  helperText={helperText}
                  inputProps={{...params.inputProps, placeholder: mask}}
                  size={size}
                  variant={variant}
                />
              );
            })
          }
          showDaysOutsideCurrentMonth={true}
          showToolbar={false}
          toolbarTitle={toolbarTitle}
          value={value}
          onAccept={onAccept}
          onChange={onChange}
          onClose={onClose}
          {...props}
        />
      </LocalizationProvider>
    </>
  );
};

export default DesktopDatePicker;
