import ReactTableComponent from '@common/ReactTableComponent';
import SimpleLoadingCell from '@components/apps/StatisticsWithMkcUral/components/TournamentTab/components/SimpleLoadingCell';
import TeamNameCell from '@components/apps/StatisticsWithMkcUral/components/TournamentTab/components/TeamNameCell';
import {useTableTabTableData} from '@components/apps/StatisticsWithMkcUral/hooks/useTableTabTableData';
import {MiniDiagram} from '@components/apps/StatisticsWithMkcUral/UralStatistics';
import {useMediaQuery} from '@mui/material';
import {longDash, STATISTICS_TABLE_TYPES} from '@src/constants';
import {Tooltip, Typography} from '@ui/MUI';
import {blue, darkRed, grey} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

TournamentTable.propTypes = {
  isFinal: PropTypes.bool,
  season: PropTypes.string,
};

function TournamentTable({isFinal = false, season}) {
  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {
    [STATISTICS_TABLE_TYPES.tournament]: {isLoading, isLoad, error, data},
  } = useTableTabTableData(STATISTICS_TABLE_TYPES.tournament, isFinal, season);
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'teamName',
          header: 'Соперник',
          sticky: {left: !isXsResolution},
          cell: ({getValue}) => {
            const {isNext, value} = getValue();
            return <TeamNameCell isLoading={isLoading} isNext={isNext} value={value} />;
          },
        },
        {
          accessorKey: 'games',
          header: <Tooltip title="Игры">И</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell fontWeight={600} getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'wins',
          header: <Tooltip title="Выигрыши">В</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'winsOT',
          header: <Tooltip title="Выигрыши в овертайме">ВО</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'winsBullet',
          header: <Tooltip title="Выигрыши по буллитам">ВБ</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'losesBullet',
          header: <Tooltip title="Поражения по буллитам">ПБ</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'loses',
          header: <Tooltip title="Поражения">П</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'losesOT',
          header: <Tooltip title="Поражения в овертайме">ПО</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'score',
          header: <Tooltip title="Очки">О</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell fontWeight={600} getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'puckDiff',
          header: <Tooltip title="Разница шайб">РШ</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'goals',
          header: <Tooltip title="Забито шайб">ЗШ</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'puckPasses',
          header: <Tooltip title="Пропущено шайб">ПШ</Tooltip>,
          cell: ({getValue}) => <SimpleLoadingCell getValue={getValue} isLoading={isLoading} />,
        },
        {
          accessorKey: 'indicators',
          header: 'Форма',
          cell: ({getValue}) => (
            <Stack direction="row" gap={1} width="fit-content">
              {getValue().value?.map((item, index) => <MiniDiagram key={index} size={12} type={item} />) || longDash}
            </Stack>
          ),
        },
      ].map((item, index) => ({
        ...item,
        cellStyle: (theme, rowIndex, rows) => {
          const {
            original: {isOurTeam, isNext},
          } = rows[rowIndex];
          return {
            ...(item?.cellStyle || {}),
            backgroundColor: (isOurTeam ? darkRed['20'] : isNext ? blue['15'] : '') + '!important',
            boxShadow: index !== 0 ? `${grey['12']} 0 -1px 0 0 inset` : `${grey['12']} -1px -1px 0 0 inset`,
          };
        },
        headerStyle: {
          boxShadow: index !== 0 ? `${grey['12']} 0 -1px 0 0 inset` : `${grey['12']} -1px -1px 0 0 inset`,
        },
      })),
    [isXsResolution, isLoading],
  );
  const rows = useMemo(
    () =>
      (isLoading ? [...Array(10)] : data?.items)?.map((item, id) => ({
        id,
        ...item,
        teamName: {
          value: {teamName: item?.teamName || longDash, teamLogo: item?.teamLogo},
          isNext: item?.isNext || false,
          isOurTeam: item?.isOurTeam,
        },
        indicators: {
          value: item?.indicators,
          isNext: item?.isNext || false,
          isOurTeam: item?.isOurTeam,
        },
      })),
    [isLoading, data?.items],
  );

  if ((isLoad && error) || (isLoad && !data?.items?.length)) {
    return (
      <Stack alignItems="center" height={350} justifyContent="center" position="relative">
        <Typography variant="body1">Данные по играм отсутствуют</Typography>
      </Stack>
    );
  }
  return (
    <ReactTableComponent
      columns={columns}
      data={rows}
      tableBodyCellProps={{
        sx: (theme) => ({
          padding: theme.spacing(2, 3) + '!important',
          textAlign: 'center',
          height: 'auto !important',
          '&:last-child, &:first-child': {
            textAlign: 'left',
          },
        }),
      }}
      tableHeaderCellProps={{
        sx: (theme) => ({
          padding: theme.spacing(2, 3) + '!important',
          textAlign: 'center',
          height: 'auto !important',
          '&:last-child, &:first-child': {
            textAlign: 'left',
          },
        }),
      }}
    />
  );
}

export default TournamentTable;
