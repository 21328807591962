export function PrintElem(elem, teamName, titleDate) {
  let mywindow = window.open('', 'PRINT', 'height=800,width=600');

  const avangardSvgLogo =
    '<svg xmlns="http://www.w3.org/2000/svg" width="238" height="45" viewBox="0 0 238 45" style="\n' +
    '    position: absolute;\n' +
    '    right: 30px;\n' +
    '    top: 20px;\n' +
    '" fill="none">\n' +
    '<g clip-path="url(#clip0)">\n' +
    '<path d="M97.3199 14.0984L95.2505 10.1901L98.8631 6.31676H97.1328L94.5608 9.11676L93.1227 6.31676H91.5678L93.5086 10.0268L89.6738 14.0984H91.4041L94.1983 11.1118L95.7766 14.0984H97.3199Z" fill="#202124"/>\n' +
    '<path d="M101.971 14.2384C104.485 14.2384 106.437 12.2084 106.437 9.63009C106.437 7.49509 104.801 6.18842 102.708 6.18842C100.194 6.18842 98.2419 8.21843 98.2419 10.7851C98.2419 12.9318 99.8786 14.2384 101.971 14.2384ZM102.053 13.0251C100.709 13.0251 99.6682 12.1734 99.6682 10.7034C99.6682 8.82509 101.001 7.40176 102.626 7.40176C103.959 7.40176 105.011 8.26509 105.011 9.72342C105.011 11.6018 103.667 13.0251 102.053 13.0251Z" fill="#202124"/>\n' +
    '<path d="M113.132 14.0984L110.642 9.99176L114.617 6.31676H112.84L109.076 9.91009L109.871 6.31676H108.503L106.784 14.0984H108.152L108.701 11.6134L109.602 10.7618L111.531 14.0984H113.132Z" fill="#202124"/>\n' +
    '<path d="M120.394 14.0984L117.903 9.99176L121.879 6.31676H120.101L116.337 9.91009L117.132 6.31676H115.764L114.045 14.0984H115.413L115.963 11.6134L116.863 10.7618L118.792 14.0984H120.394Z" fill="#202124"/>\n' +
    '<path d="M126.65 14.0984L126.907 12.8968H122.932L123.411 10.7384H127.304L127.573 9.53676H123.68L124.124 7.51843H128.099L128.368 6.31676H123.025L121.307 14.0984H126.65Z" fill="#202124"/>\n' +
    '<path d="M129.375 14.0984L134.718 8.33509L133.432 14.0984H134.812L136.53 6.31676H135.127L129.902 11.9284L131.141 6.31676H129.773L128.054 14.0984H129.375ZM135.467 4.89342L134.987 4.42676C134.531 4.90509 133.947 5.19676 133.327 5.19676C132.684 5.19676 132.24 4.89342 132.006 4.42676L131.363 4.89342C131.702 5.52342 132.368 5.92009 133.198 5.92009C134.04 5.92009 134.859 5.52342 135.467 4.89342Z" fill="#202124"/>\n' +
    '<path d="M143.261 14.0984L144.98 6.31676H143.612L142.911 9.50176H138.854L139.555 6.31676H138.187L136.469 14.0984H137.837L138.585 10.7034H142.642L141.894 14.0984H143.261Z" fill="#202124"/>\n' +
    '<path d="M152.984 14.0984L154.691 6.31676H153.324L151.617 14.0984H152.984ZM146.624 6.31676L144.918 14.0984H148.32C150.658 14.0984 151.441 12.4884 151.441 11.3218C151.441 10.1784 150.623 9.22176 149.126 9.22176H147.349L147.992 6.31676H146.624ZM148.904 10.4234C149.559 10.4234 150.015 10.8784 150.015 11.4501C150.015 12.2434 149.419 12.8968 148.378 12.8968H146.543L147.092 10.4234H148.904Z" fill="#202124"/>\n' +
    '<path d="M155.966 14.0984L161.309 8.33509L160.023 14.0984H161.403L163.121 6.31676H161.718L156.492 11.9284L157.731 6.31676H156.364L154.645 14.0984H155.966ZM162.057 4.89342L161.578 4.42676C161.122 4.90509 160.537 5.19676 159.918 5.19676C159.275 5.19676 158.83 4.89342 158.597 4.42676L157.954 4.89342C158.293 5.52342 158.959 5.92009 159.789 5.92009C160.631 5.92009 161.449 5.52342 162.057 4.89342Z" fill="#202124"/>\n' +
    '<path d="M172.411 14.0984L169.92 9.99176L173.895 6.31676H172.118L168.354 9.91009L169.149 6.31676H167.781L166.062 14.0984H167.43L167.98 11.6134L168.88 10.7618L170.809 14.0984H172.411Z" fill="#202124"/>\n' +
    '<path d="M172.786 14.2384C174.294 14.2384 175.428 13.7134 176.527 10.4118L177.497 7.51843H180.198L178.748 14.0984H180.116L181.823 6.31676H176.527L175.194 10.3184C174.411 12.6168 173.896 13.0251 173.055 13.0251L172.786 14.2384Z" fill="#202124"/>\n' +
    '<path d="M182.673 14.2384C184.005 14.2384 184.742 13.5734 185.432 12.6401L190.073 6.31676H188.436L185.28 10.8551L184.134 6.31676H182.661L184.309 11.9518L184.251 12.0451C183.736 12.7451 183.421 13.0251 182.848 13.0251C182.474 13.0251 182.146 12.8268 181.924 12.5818L181.199 13.7018C181.386 13.9584 181.971 14.2384 182.673 14.2384Z" fill="#202124"/>\n' +
    '<path d="M190.923 6.31676L189.216 14.0984H192.642C194.957 14.0984 195.74 12.4884 195.74 11.3218C195.74 10.1784 194.898 9.22176 193.437 9.22176H191.648L192.034 7.51843H196.009L196.266 6.31676H190.923ZM193.203 10.4234C193.858 10.4234 194.314 10.8784 194.314 11.4501C194.314 12.2434 193.717 12.8968 192.712 12.8968H190.841L191.391 10.4234H193.203Z" fill="#202124"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M75.2694 21.4016C75.0713 18.5185 72.9941 15.6042 68.57 12.0167V12.0146C68.7779 11.5343 68.8836 9.56356 66.6785 8.38238C61.1278 5.41491 49.2065 2.29065 40.2519 1.8125C40.3395 2.05512 40.5488 2.73261 40.6976 3.237C30.0253 2.73899 19.1423 3.27673 11.084 4.0734C8.38653 4.34014 0 5.50926 0 5.50926L18.9483 13.6923C16.926 14.1535 14.8732 14.6508 12.8079 15.1913C10.6994 15.7454 4.45473 17.8935 4.45473 17.8935C4.45473 17.8935 10.0006 19.1151 12.029 19.5606C15.8009 20.3878 26.4356 22.6828 30.9752 23.7405C30.3751 25.6148 26.4662 38.5935 26.4662 38.5935C26.4662 38.5935 31.5288 33.187 33.1011 31.6767C35.0996 29.7556 35.7603 29.1037 36.3597 28.6064C36.1295 31.3702 35.6198 37.3031 35.4891 38.7652C35.3841 39.9322 35.6108 41.1438 36.2804 41.8518C36.9188 42.5258 38.0342 42.8486 39.0161 42.7393C39.8179 42.6492 40.811 42.0881 41.5377 41.4829C44.5891 38.9376 52.2308 32.0555 58.6946 25.7254C59.0576 25.6701 59.4206 25.619 59.785 25.58C64.2209 25.0841 68.5171 25.7978 69.2507 28.0374C69.732 29.5087 69.2368 30.4147 68.7897 31.8228C69.5512 31.0907 71.5157 29.2633 72.2834 28.2921C73.533 26.7136 75.4655 24.2612 75.2694 21.4016Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M40.6164 9.9768C40.6456 9.97396 40.6755 9.9697 40.7033 9.96687C40.4411 9.45467 40.22 8.92403 40.0218 8.5374C43.6567 8.09685 52.0731 8.5374 55.4326 10.7096C58.7927 12.8811 61.8442 13.626 64.1418 13.1074C63.9373 13.7041 63.1133 14.4135 63.1133 14.4135C62.3135 14.5866 61.4659 14.6391 60.5069 14.4936C59.557 16.0806 57.2197 15.8848 53.5334 17.7626C49.5961 19.7688 43.7283 23.0896 38.6637 26.7729C38.5142 28.6167 38.1964 32.2013 38.1526 32.7596L45.4598 30.0745L43.9849 28.8075L50.2219 27.3603C51.2323 25.7088 58.961 18.1422 60.1842 17.2235C60.6828 17.122 61.098 17.0376 61.4276 17.0113C63.034 15.5684 64.8323 14.2127 66.7599 13.2011C66.7933 13.0904 66.8232 12.9819 66.8392 12.8797C66.9512 12.2022 66.9025 11.2197 66.0235 10.7543C51.1593 2.89401 23.6639 5.43158 8.85742 6.64539L22.8211 12.8506C29.5039 11.4651 35.6832 10.5266 40.6164 9.9768Z" fill="#BFBD9C"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M43.9873 28.8077L45.4637 30.0747L38.155 32.7599C38.155 32.7599 37.7635 37.547 37.6606 38.675C37.5229 40.2052 38.8066 40.6776 40.0242 39.6632C42.7384 37.3966 49.0575 31.8412 54.9468 26.2666L43.9873 28.8077Z" fill="#6F7757"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M57.0191 15.3946C54.2466 15.8423 52.9107 13.8665 52.6652 11.7823C51.6694 11.6149 50.6764 11.5177 49.7821 11.4375C49.7369 14.051 51.4858 16.8993 55.6965 16.2565C57.259 16.0196 58.0094 15.4819 58.3515 14.9278C58.033 15.1328 57.6074 15.3017 57.0191 15.3946Z" fill="#FEFEFE"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M63.9431 17.2337C63.9431 17.2337 64.2234 16.4591 65.3555 15.9327C67.0537 16.6903 67.8228 17.731 67.8228 17.731L63.9431 17.2337ZM66.7616 13.2012C60.9668 16.2403 56.3042 22.3987 54.6895 24.6398C59.4731 23.0698 69.3533 22.2157 70.9486 27.1121C73.5702 23.6544 76.2149 20.6486 66.7616 13.2012Z" fill="#F2F2D9"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M32.3061 17.0569L44.4117 15.6934L37.8721 20.5678L38.0126 18.5793L32.3061 17.0569ZM50.6615 16.9326C48.6761 15.8047 48.0412 13.5991 48.2582 11.5361C48.2568 11.5368 48.2547 11.5361 48.2533 11.5361C45.0816 11.7809 28.0186 13.1444 12.5918 17.2944C17.5736 18.1734 33.75 21.9901 33.75 21.9901C33.75 21.9901 31.9796 27.3107 31.0672 30.6967C36.9704 25.4435 40.8737 22.3504 50.6615 16.9326Z" fill="#FF0000"/>\n' +
    '<path d="M165.162 36.4758L167.995 21.6885H181.811L179.614 24.9939H171.695L169.96 34.0403C169.671 35.432 168.457 36.4179 167.07 36.4179L165.162 36.4758Z" fill="#26292E"/>\n' +
    '<path d="M99.6608 21.6299H90.3535L90.2957 21.8618C91.2207 22.2098 92.6081 22.9636 91.8566 24.1234L83.8789 36.4172H87.174C88.2146 36.4172 89.1395 35.8953 89.7176 35.0255L90.3535 34.0397H98.5046L99.0827 36.4172H103.707L99.6608 21.6299ZM92.1456 31.0242L95.7298 25.2252H96.2501L97.6953 31.0242H92.1456Z" fill="#26292E"/>\n' +
    '<path d="M139.203 21.6299H129.953L129.895 21.8618C130.762 22.2098 131.976 22.8477 131.63 23.8915L123.479 36.4172H126.774C127.814 36.4172 128.739 35.8953 129.317 35.0255L129.953 34.0397H138.104L138.682 36.4172H143.307L139.203 21.6299ZM131.687 31.0242L135.272 25.2252H135.792L137.237 31.0242H131.687Z" fill="#26292E"/>\n' +
    '<path d="M193.026 21.6299H183.777L183.661 21.8618C184.586 22.2098 185.916 22.9057 185.28 24.0655L177.244 36.4173H180.539C181.58 36.4173 182.505 35.8954 183.025 35.0255L183.661 34.0397H191.812L192.39 36.4173H197.015L193.026 21.6299ZM185.511 31.0242L189.095 25.2252H189.615L191.061 31.0242H185.511Z" fill="#26292E"/>\n' +
    '<path d="M235.048 33.4604L237.303 21.6885H220.422L220.307 21.9204C221.232 22.2684 222.33 22.9063 221.925 24.0661L218.168 33.4024H215.451L214.41 38.7954H216.318C217.705 38.7954 218.919 37.8096 219.208 36.4179H233.025L232.562 38.7954H236.956L237.996 33.4024H235.048V33.4604ZM230.655 33.4604H222.908L226.434 24.7039H232.331L230.655 33.4604Z" fill="#26292E"/>\n' +
    '<path d="M121.57 28.5893L121.281 28.5313L121.339 28.2993L121.628 28.2413C122.206 28.0673 122.784 27.7774 123.188 27.3135C123.882 26.6176 124.229 25.6898 124.171 24.7039C124.171 22.2104 121.801 21.6885 119.778 21.6885H106.135L106.019 21.9204C106.944 22.2684 107.869 22.8483 107.638 24.182L105.268 36.4759H117.523C119.72 36.4759 121.281 35.896 122.263 34.7942C123.073 33.8663 123.477 32.6485 123.42 31.4307C123.42 30.039 122.842 28.9952 121.57 28.5893ZM118.217 32.7645C117.754 33.2864 117.118 33.5764 116.425 33.5184H110.239L110.875 30.155H116.945C117.35 30.155 118.621 30.155 118.621 31.4307C118.679 31.8947 118.506 32.4166 118.217 32.7645ZM118.795 26.9655C118.39 27.4295 117.812 27.6614 117.234 27.6034H111.395L111.973 24.588H117.754C118.795 24.588 119.257 24.9939 119.257 25.8057C119.257 26.2117 119.084 26.6756 118.795 26.9655Z" fill="#26292E"/>\n' +
    '<path d="M160.482 21.6299L159.383 27.1969H151.001L152.099 21.6299H145.74C145.682 21.6879 145.682 21.8039 145.625 21.8618C146.55 22.2098 147.474 22.7897 147.243 24.1234L144.873 36.4173H146.839C148.226 36.4173 149.44 35.4314 149.729 34.0397L150.423 30.5603H158.805L157.649 36.4752H162.042L164.933 21.6879L160.482 21.6299Z" fill="#26292E"/>\n' +
    '<path d="M212.505 21.6299H199.556L199.44 21.8618C200.365 22.2098 201.29 22.7897 201.001 24.1234L198.631 36.4173H200.539C201.926 36.4173 203.14 35.4314 203.429 34.0397L203.892 31.4881H210.655C213.141 31.4881 214.875 30.8502 216.031 29.5165C216.956 28.3567 217.419 26.849 217.361 25.3412C217.477 22.7897 215.916 21.6299 212.505 21.6299ZM212.1 27.6608C211.638 28.2987 210.886 28.5886 210.135 28.5307H204.585L205.279 24.8193H210.713C211.927 24.8193 212.563 25.2832 212.563 26.2111C212.563 26.791 212.389 27.2549 212.1 27.6608Z" fill="#26292E"/>\n' +
    '</g>\n' +
    '<defs>\n' +
    '<clipPath id="clip0">\n' +
    '<rect width="238" height="45" fill="white"/>\n' +
    '</clipPath>\n' +
    '</defs>\n' +
    '</svg>';

  mywindow.document.write('<html><head><title>' + document.title + '</title>');
  mywindow.document.write(
    '<style>.Lines {\n' +
      '    margin-top: 6px;\n' +
      '    margin-bottom: 0;\n' +
      '}\n' +
      '.LinesHead {\n' +
      '    padding-left: 32px;\n' +
      '    padding-right: 32px;\n' +
      '    display: flex;\n' +
      '    align-items: center;\n' +
      '    justify-content: space-between;\n' +
      '    border-radius: 6px 6px 0 0;\n' +
      '    border-bottom: 1px solid #dddad4;\n' +
      '    background-color: #fffaed;\n' +
      '    box-shadow: 0 0.5px 1px rgba(0, 0, 0, .3);\n' +
      '}\n' +
      '.LinesHead button {\n' +
      '    position: relative;\n' +
      '    padding-left: 24px;\n' +
      '    margin: 0 0 0 18px;\n' +
      '    background-color: transparent;\n' +
      '    border: none;\n' +
      '    border-radius: 0;\n' +
      '}\n' +
      'hr {\n' +
      '    margin: 0 7px;\n' +
      '}\n' +
      '.LinesHead button span {\n' +
      "    font: bold normal 16px/36px 'Bebas Neue';\n" +
      '    color: #2c2c2c;\n' +
      '    text-transform: uppercase;\n' +
      '}\n' +
      '.LinesHead button svg {\n' +
      '    position: absolute;\n' +
      '    top: 50%;\n' +
      '    transform: translateY(-50%);\n' +
      '    left: 0;\n' +
      '}\n' +
      '.LinesHeadContainer > * {\n' +
      '    display: inline-block;\n' +
      '}\n' +
      '.LinesHeadContainer button {\n' +
      '    height: 100%;\n' +
      '}\n' +
      '.LinesHeadContainer button > span {\n' +
      '    line-height: 60px;\n' +
      '}\n' +
      '.LinesHeadTitle {\n' +
      '    margin: 0 18px 0 0;\n' +
      "    font: bold normal 16px/63px 'Proxima Nova';\n" +
      '    color: #2c2c2c;\n' +
      '    letter-spacing: 0.02em;\n' +
      '    text-transform: uppercase;\n' +
      '}\n' +
      '.LinesBody {\n' +
      '    display: flex;\n' +
      '    flex-wrap: wrap;\n' +
      '}\n' +
      '.LinesBlockHeadTitle {\n' +
      '    margin-top: 0;\n' +
      '    margin-bottom: 0;\n' +
      "    font: normal normal 12px/28px 'Proxima Nova';\n" +
      '    color: #747474;\n' +
      '    letter-spacing: 0.02em;\n' +
      '    text-align: center;\n' +
      '}\n' +
      '.LinesBlockHead {\n' +
      '    border-top: 1px solid #dddad4;\n' +
      '    background: white;\n' +
      '    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);\n' +
      '}\n' +
      '.LinesBlockHead > .LinesBlockHeadTitle {\n' +
      '    padding-left: 32px;\n' +
      '    padding-right: 0;\n' +
      '    text-align: left;\n' +
      '}\n' +
      '.LinesBlockHeadColumns {\n' +
      '    display: flex;\n' +
      '    flex-wrap: wrap;\n' +
      '    box-sizing: border-box;\n' +
      '}\n' +
      '.LinesBlockHeadFlexContainer {\n' +
      '    display: flex;\n' +
      '}\n' +
      '.LinesBlockHeadFlexContainer .LinesBlockHeadTitle:last-child {\n' +
      '    width: calc(100% - 52px);\n' +
      '}\n' +
      '.LinesBlockHeadColumn {\n' +
      '    box-sizing: border-box;\n' +
      '}\n' +
      '.LinesBlockHeadColumn:last-child {\n' +
      '    padding-right: 0;\n' +
      '    padding-left: 0;\n' +
      '    flex: 0 0 41%;\n' +
      '    max-width: 41%;\n' +
      '}\n' +
      '.LinesBlockHeadColumn:first-child {\n' +
      '    padding-right: 0;\n' +
      '    padding-left: 32px;\n' +
      '    flex: 0 0 59%;\n' +
      '    max-width: 59%;\n' +
      '}\n' +
      '.LinesViewBlock {\n' +
      '    flex: 0 0 65%;\n' +
      '    /*max-width: 65%;*/\n' +
      "    font: normal 12px/25px 'Proxima Nova';\n" +
      '    border-right: 1px solid #dddad4;\n' +
      '    box-sizing: border-box;\n' +
      '}\n' +
      '.LinesAvailablePlayersBlock {\n' +
      '    flex: 0 0 35%;\n' +
      '    max-width: 35%;\n' +
      '    border-left: 1px solid #dddad4;\n' +
      '    box-sizing: border-box;\n' +
      '}\n' +
      '.LinesTabTrainers {\n' +
      '    padding: 8px 32px;\n' +
      '    background-color: white;\n' +
      '    display: flex;\n' +
      '    flex-wrap: wrap;\n' +
      '}\n' +
      '.LinesTabTrainer {\n' +
      '    position: relative;\n' +
      '    padding-left: 52px;\n' +
      '    margin-bottom: 15px;\n' +
      '    padding-right: 12px;\n' +
      '    flex: 0 0 33.333%;\n' +
      '    max-width: 33.333%;\n' +
      '    box-sizing: border-box;\n' +
      '}\n' +
      '.LinesTabTrainerImg {\n' +
      '    position: absolute;\n' +
      '    top: 0;\n' +
      '    left: 0;\n' +
      '    display: none;\n' +
      '    width: 40px;\n' +
      '    height: 40px;\n' +
      '    background: no-repeat center;\n' +
      '    background-size: cover;\n' +
      '    border-radius: 50%;\n' +
      '    overflow: hidden;\n' +
      '}\n' +
      '.LinesTabTrainersType {\n' +
      '    margin-top: 0;\n' +
      '    margin-bottom: 0;\n' +
      "    font: 600 normal 12px/16px 'Proxima Nova';\n" +
      '    color: #747474;\n' +
      '    text-transform: uppercase;\n' +
      '}\n' +
      '.LinesTabTrainersName {\n' +
      '    margin-top: 0;\n' +
      '    margin-bottom: 0;\n' +
      "    font: bold normal 14px/25px 'Proxima Nova';\n" +
      '    color: #2c2c2c;\n' +
      '}\n' +
      '.LinesTabLineContainer {\n' +
      '    padding: 35px 32px;\n' +
      '    background-color: #f3f3f3;\n' +
      '}\n' +
      '.LinesTabLine {\n' +
      '    position: relative;\n' +
      '    display: -webkit-box;\n' +
      '    display: -webkit-flex;\n' +
      '    display: -ms-flexbox;\n' +
      '    display: flex;\n' +
      '    -webkit-flex-wrap: wrap;\n' +
      '    -ms-flex-wrap: wrap;\n' +
      '    flex-wrap: wrap;\n' +
      '    box-sizing: border-box;\n' +
      '}\n' +
      '.LinesTabLineNumber {\n' +
      '    position: absolute;\n' +
      '    top: 40%;\n' +
      '    left: -18px;\n' +
      '    transform: rotate(-90deg) translateY(-50%);\n' +
      '    margin-top: 0;\n' +
      '    margin-bottom: 2px;\n' +
      "    font: bold normal 12px/25px 'Proxima Nova';\n" +
      '    color: #2c2c2c;\n' +
      '    text-transform: uppercase;\n' +
      '}\n' +
      '.LinesTabLineContainerGoaltenders {\n' +
      '    padding: 0;\n' +
      '    border-radius: 0 0 6px 6px;\n' +
      '    background-color: #F3F3F3;\n' +
      '    padding: 0;\n' +
      '    border-radius: 0 0 6px 6px;\n' +
      '}\n' +
      '.LinesTabLineContainerGoaltenders .LinesTabLine .LinesTabLineNumber {\n' +
      '    top: 50%;\n' +
      '    left: 18px;\n' +
      '}\n' +
      '.LinesTabLineColumn {\n' +
      '    border-right: 2px solid #dddad4;\n' +
      '    box-sizing: border-box;\n' +
      '    padding-left: 32px;\n' +
      '}\n' +
      '.LinesTabLineColumn :last-child {\n' +
      '    border-right: 0;\n' +
      '}\n' +
      '.TeacherHockeyLinesPlayerCards {\n' +
      '    display: flex;\n' +
      '    align-items: center;\n' +
      '    justify-content: space-between;\n' +
      '    flex-wrap: wrap;\n' +
      '}\n' +
      '.LinesTabLineColumnWingers {\n' +
      '    flex: 0 0 60%;\n' +
      '    max-width: 60%;\n' +
      '    border-right: 2px solid #dddad4;\n' +
      '}\n' +
      '.LinesTabLineColumnWingers .TeacherHockeyLinesPlayerCards .Card, .LinesTabLineColumnWingers .TeacherHockeyLinesPlayerCards .EmptyCard {\n' +
      '    flex: 0 0 33.333%;\n' +
      '    max-width: 33.333%;\n' +
      '}\n' +
      '\n' +
      '.EmptyCard{\n' +
      '     padding-left: 20px;\n' +
      '    padding-right: 20px;\n' +
      '    box-sizing: border-box;\n' +
      '    cursor: pointer;\n' +
      '}\n' +
      '\n' +
      '.LinesTabLineColumnDefensemen {\n' +
      '    flex: 0 0 39%;\n' +
      '    max-width: 39%;\n' +
      '}\n' +
      '.LinesTabLineColumnDefensemen .Card, .LinesTabLineColumnDefensemen .EmptyCard {\n' +
      '    flex: 0 0 50%;\n' +
      '    max-width: 50%;\n' +
      '}\n' +
      '.LinesTabLineColumnGoaltenders {\n' +
      '    flex: 0 0 59%;\n' +
      '    max-width: 59%;\n' +
      '    padding: 20px 0 20px 64px;\n' +
      '}\n' +
      '.LinesTabLineColumnGoaltenders .TeacherHockeyLinesPlayerCards .Card, .LinesTabLineColumnGoaltenders .TeacherHockeyLinesPlayerCards .EmptyCard {\n' +
      '    flex: 0 0 33.333%;\n' +
      '    max-width: 33.333%;\n' +
      '\n' +
      '   \n' +
      '}\n' +
      '.LinesTabLineColumnGoaltenders .TeacherHockeyLinesPlayerCards .CardHand_right {\n' +
      '    right: -8px;\n' +
      '}\n' +
      '.LinesTabLineColumnGoaltenders .TeacherHockeyLinesPlayerCards .CardHand_left {\n' +
      '    left: -8px;\n' +
      '}\n' +
      '.LinesTabLineColumnGoaltenders .TeacherHockeyLinesPlayerCards .CardRemovePlayer {\n' +
      '    right: 4px;\n' +
      '}\n' +
      '.LinesTabLineColumnGoaltenders .TeacherHockeyLinesPlayerCards .CardStatus .CardImgWrapper :before {\n' +
      '    left: 10px;\n' +
      '}\n' +
      '.LinesTabLineColumnGoaltendersHead {\n' +
      '    width: 100%;\n' +
      '    padding-top: 4px;\n' +
      '    padding-bottom: 4px;\n' +
      '    background-color: white;\n' +
      '    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n' +
      '}\n' +
      '.LinesTabLineColumnGoaltendersHeadColumn {\n' +
      '    flex: 0 0 60%;\n' +
      '    max-width: 60%;\n' +
      '    box-sizing: border-box;\n' +
      '    padding-left: 60px;\n' +
      '    padding-right: 0;\n' +
      '    text-align: center;\n' +
      '}\n' +
      '.LinesAvailablePlayers {\n' +
      '    max-height: 615px;\n' +
      '    overflow: auto;\n' +
      '}\n' +
      '.LinesAvailablePlayers .TableEmptyBlock {\n' +
      '    border-radius: 0;\n' +
      '}\n' +
      '.LinesAvailablePlayers .TableEmptyBlock > p {\n' +
      '    text-align: center;\n' +
      '}\n' +
      '.LinesAvailablePlayer {\n' +
      '    display: flex;\n' +
      '    align-items: center;\n' +
      '    flex-wrap: wrap;\n' +
      '    padding: 4px 12px;\n' +
      '    background-color: #f3f3f3;\n' +
      '    border-bottom: 1px solid #c0c0c0;\n' +
      '    cursor: pointer;\n' +
      '    position: relative;\n' +
      '}\n' +
      '.LinesAvailablePlayer :nth-child(even) {\n' +
      '    background-color: white;\n' +
      '}\n' +
      '.LinesAvailablePlayer .LinesAvailablePlayerButton {\n' +
      '    position: relative;\n' +
      '    border-radius: 0;\n' +
      '    border: none;\n' +
      '    padding-left: 16px;\n' +
      '    padding-right: 0;\n' +
      '}\n' +
      '.LinesAvailablePlayer .LinesAvailablePlayerButton > svg {\n' +
      '    position: absolute;\n' +
      '    top: 50%;\n' +
      '    left: 0;\n' +
      '    transform: translateY(-50%);\n' +
      '}\n' +
      '.LinesAvailablePlayerContain {\n' +
      '    position: absolute;\n' +
      '    top: 0;\n' +
      '    bottom: 0;\n' +
      '    left: 0;\n' +
      '    right: 0;\n' +
      '    z-index: 1;\n' +
      '}\n' +
      '.LinesAvailablePlayerContain.active {\n' +
      '    background-color: #0f03f0;\n' +
      '}\n' +
      '.LinesAvailablePlayerContain .ButtonsContainer button {\n' +
      '    border-radius: 30px;\n' +
      '    font-size: 16px;\n' +
      '    line-height: 20px;\n' +
      '}\n' +
      '.LinesAvailablePlayerContain .ButtonsContainer button.CancelButton {\n' +
      '    background-color: white;\n' +
      '    border: 1px solid #901;\n' +
      '    color: #901;\n' +
      '}\n' +
      '.LinesAvailablePlayersContainer {\n' +
      '    position: relative;\n' +
      '}\n' +
      '.LinesAvailablePlayersContainer :after {\n' +
      '    position: absolute;\n' +
      '    bottom: -16px;\n' +
      '    display: block;\n' +
      '    width: 100%;\n' +
      '    height: 16px;\n' +
      '    background-color: white;\n' +
      '    border-bottom-right-radius: 6px;\n' +
      "    content: '';\n" +
      '}\n' +
      '.LinesAvailablePlayerAvatar {\n' +
      '    display: none;\n' +
      '    margin-left: 12px;\n' +
      '    margin-right: 12px;\n' +
      '    width: 28px;\n' +
      '    height: 28px;\n' +
      '    background-size: cover;\n' +
      '    border-radius: 50%;\n' +
      '    overflow: hidden;\n' +
      '}\n' +
      '.LinesAvailablePlayerName {\n' +
      '    margin-top: 0;\n' +
      '    margin-bottom: 0;\n' +
      '    min-width: 170px;\n' +
      "    font: bold normal 14px/16px 'Proxima Nova';\n" +
      '    color: #2c2c2c;\n' +
      '    letter-spacing: 0.02em;\n' +
      '    transition: all 0.33s;\n' +
      '}\n' +
      '.LinesAvailablePlayerName :focus {\n' +
      '    text-decoration: underline;\n' +
      '}\n' +
      '.LinesAvailablePlayerName :hover {\n' +
      '    text-decoration: none;\n' +
      '}\n' +
      '.LinesAvailablePlayerNumber {\n' +
      '    color: #875;\n' +
      '}\n' +
      '.LinesAvailablePlayerHandIconContainer {\n' +
      '    position: relative;\n' +
      '    display: none;\n' +
      '    margin-right: 8px;\n' +
      '    margin-left: 12px;\n' +
      '    border-radius: 50%;\n' +
      '    width: 20px;\n' +
      '    height: 20px;\n' +
      '}\n' +
      '.LinesAvailablePlayerHandIconContainer svg {\n' +
      '    position: absolute;\n' +
      '    top: 50%;\n' +
      '    left: 50%;\n' +
      '    transform: translate(-50%, -50%);\n' +
      '}\n' +
      '.LinesAvailablePlayerHand {\n' +
      '    min-width: 105px;\n' +
      '}\n' +
      '.LinesAvailablePlayerHand span {\n' +
      '    margin: 0 12px 0 0;\n' +
      "    font: 600 normal 14px/20px 'Proxima Nova';\n" +
      '    letter-spacing: 0.02em;\n' +
      '    color: #747474;\n' +
      '}\n' +
      '.LinesAvailablePlayerHandFlexContainer {\n' +
      '    display: flex;\n' +
      '    align-items: center;\n' +
      '}\n' +
      '.LinesAvailablePlayerStatus {\n' +
      '    position: relative;\n' +
      '    display: block;\n' +
      '    margin: 0 0 0 12px;\n' +
      '    padding-left: 16px;\n' +
      "    font: 600 normal 14px/20px 'Proxima Nova';\n" +
      '    letter-spacing: 0.02em;\n' +
      '    color: #747474;\n' +
      '}\n' +
      '.LinesAvailablePlayerStatus :before {\n' +
      '    position: absolute;\n' +
      '    top: 50%;\n' +
      '    left: 0;\n' +
      '    transform: translateY(-60%);\n' +
      '    display: none;\n' +
      '    width: 8px;\n' +
      '    height: 8px;\n' +
      '    border: 2px solid white;\n' +
      '    border-radius: 50%;\n' +
      '    box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);\n' +
      "    content: '';\n" +
      '}\n' +
      '.LinesAvailablePlayerStatus.LinesAvailablePlayerStatus_available :before {\n' +
      '    background-color: #6dc26d;\n' +
      '}\n' +
      '.LinesAvailablePlayerStatus.LinesAvailablePlayerStatus_notavailable :before {\n' +
      '    background-color: #901;\n' +
      '}\n' +
      '.LinesAvailablePlayerStatus.LinesAvailablePlayerStatus_traumatized :before {\n' +
      '    background-color: #c69534;\n' +
      '}\n' +
      '\n' +
      '.CardPlayerName {\n' +
      '\t margin-top: 8px;\n' +
      '\t margin-bottom: 0;\n' +
      "\t font: bold normal 14px/16px 'Proxima Nova';\n" +
      '\t color: #2c2c2c;\n' +
      '\t letter-spacing: 0.02em;\n' +
      '\t text-align: center;\n' +
      '\t transition: all 0.33s;\n' +
      '}\n' +
      ' .CardPlayerNumber {\n' +
      '\t color: #875;\n' +
      '}\n' +
      ' .Card {\n' +
      '\t padding-left: 20px;\n' +
      '\t padding-right: 20px;\n' +
      '\t box-sizing: border-box;\n' +
      '\t cursor: pointer;\n' +
      '}\n' +
      ' .CardImg {\n' +
      '\t position: absolute;\n' +
      '\t left: 50%;\n' +
      '\t transform: translateX(-50%);\n' +
      '\t display: none;\n' +
      '\t width: 60px;\n' +
      '\t height: 60px;\n' +
      '\t background-size: cover;\n' +
      '\t background-repeat: no-repeat;\n' +
      '\t background-position: center;\n' +
      '\t border-radius: 50%;\n' +
      '\t overflow: hidden;\n' +
      '\t z-index: 0;\n' +
      '}\n' +
      ' .CardImgWrapper {\n' +
      '\t position: relative;\n' +
      '\t width: 100%;\n' +
      '\t max-width: 84px;\n' +
      '}\n' +
      ' .CardImgWrapper .CardRemovePlayer {\n' +
      '\t display: none;\n' +
      '}\n' +
      ' .CardImgWrapper .CardRemovePlayer svg {\n' +
      '\t position: absolute;\n' +
      '\t top: 50%;\n' +
      '\t left: 50%;\n' +
      '\t transform: translate(-50%, -50%);\n' +
      '}\n' +
      ' .CardImgWrapper .CardRemovePlayer svg path {\n' +
      '\t fill: #686868;\n' +
      '}\n' +
      ' .CardImgContainer {\n' +
      '\t position: relative;\n' +
      '\t min-height: 60px;\n' +
      '\t display: flex;\n' +
      '\t justify-content: center;\n' +
      '}\n' +
      '\n' +
      ' .CardImgContainer.CardStatus_notavailable .CardImgWrapper :before {\n' +
      '\t background-color: #901;\n' +
      '}\n' +
      ' .CardImgContainer.CardStatus_traumatized .CardImgWrapper :before {\n' +
      '\t background-color: #c69534;\n' +
      '}\n' +
      ' .CardHand {\n' +
      '\t display: none;\n' +
      '}\n' +
      ' .CardHand.CardHand_right {\n' +
      '\t right: 4px;\n' +
      '}\n' +
      ' .CardHand.CardHand_left {\n' +
      '\t left: 4px;\n' +
      '}\n' +
      ' .CardPlayerName{\n' +
      '  margin-top: 8px;\n' +
      '  margin-bottom: 0; \n' +
      "  font: bold normal 14px/16px 'Proxima Nova';\n" +
      '  color: #2c2c2c;\n' +
      '  letter-spacing: .02em;\n' +
      '  text-align: center;\n' +
      '  transition: all .33s;\n' +
      '}\n' +
      '.EmptyCard{\n' +
      '  padding-left: 20px;\n' +
      '  padding-right: 20px;  \n' +
      '  box-sizing: border-box;\n' +
      '  cursor: pointer;\n' +
      '}\n' +
      '.EmptyCardContainer{\n' +
      '  display: flex;\n' +
      '  justify-content: center;\n' +
      '  align-items: center;\n' +
      '}\n' +
      '.EmptyCardIconWrapper {\n' +
      '\t position: relative;\n' +
      '\t display: none;\n' +
      '\t width: 60px;\n' +
      '\t height: 60px;\n' +
      '\t border-radius: 50%;\n' +
      '}\n' +
      ' .EmptyCardIconWrapper.isHighlight {\n' +
      '\t background-color: #0f03f;\n' +
      '}\n' +
      ' .EmptyCardIconWrapper.isHighlight svg path {\n' +
      '\t fill: #c4c4c4;\n' +
      '}\n' +
      ' .EmptyCardIconWrapper svg {\n' +
      '\t position: absolute;\n' +
      '\t top: 50%;\n' +
      '\t left: 50%;\n' +
      '\t transform: translate(-50%, -60%);\n' +
      '}\n' +
      ' .EmptyCardIconWrapper svg path {\n' +
      '\t fill: white;\n' +
      '}\n' +
      ' \n' +
      '\n' +
      '.LinesTabLineContainer:nth-child(even) {\n' +
      '    background-color: white;\n' +
      '}\n' +
      '.hide-when-print{\n' +
      '\tdisplay: none;\n' +
      '}</style>',
  );
  mywindow.document.write('</head><body>');
  mywindow.document.write('<h1>' + teamName + '</h1>');
  mywindow.document.write('<h2>Состав команды ' + titleDate + '</h2>');
  mywindow.document.write(avangardSvgLogo);
  mywindow.document.write(document.getElementById(elem).innerHTML);
  mywindow.document.write('<br><br>');
  mywindow.document.write('<div style="text-align:center;">Для заметок</div>');
  mywindow.document.write('<br><hr><br><hr><br><hr><br><hr>');
  mywindow.document.write('</body></html>');

  mywindow.document.close(); //necessary for IE >= 10
  mywindow.focus(); //necessary for IE >= 10

  mywindow.print();
  // mywindow.close();

  return true;
}
