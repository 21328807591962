import {darkRed, green, secondary, yellow} from '@ui/MUI/colors';

export const actions = {
  actionEdit: 'edit',
  actionAdd: 'add',
};

export const rowsMods = {
  edit: 'edit',
  view: 'view',
};

export const PSYCHO_EMOTIONAL_STATE_OPTIONS = [
  {
    value: 'stability',
    label: 'Устойчивое состояние',
  },
  {
    value: 'poised',
    label: 'Уравновешенное состояние',
  },
  {
    value: 'balance',
    label: 'Сбалансированное состояние',
  },
  {
    value: 'fatigue',
    label: 'Накопленная усталость',
  },
];

export const ENERGY_LEVEL_OPTIONS = [
  {
    value: 'high',
    label: 'Высокие',
  },
  {
    value: 'optimal',
    label: 'Оптимальные',
  },
  {
    value: 'normal',
    label: 'В норме',
  },
  {
    value: 'low',
    label: 'Пониженные',
  },
];

export const FUNCTIONAL_READINESS_OPTIONS = [
  {
    value: 'ready',
    label: 'Готов',
  },
  {
    value: 'not_ready',
    label: 'Не готов',
  },
];

export const renderCondition = (condition) => {
  let state = 0;
  if (condition >= 5.5) {
    state = 6;
  }
  if (condition >= 4.5 && condition <= 5.49) {
    state = 5;
  }
  if (condition >= 3.5 && condition <= 4.49) {
    state = 4;
  }
  if (condition >= 2.5 && condition <= 3.49) {
    state = 3;
  }
  if (condition >= 1.5 && condition <= 2.49) {
    state = 2;
  }
  if (condition > 0 && condition <= 1.49) {
    state = 1;
  }
  return state;
};

export const FUNCTIONAL_STATE_INDICATORS_MATRIX = {
  6: [green[100]],
  5: [green[100], yellow[100]],
  4: [yellow[100]],
  3: [yellow[100], darkRed[100]],
  2: [darkRed[100]],
  1: [secondary.contrastText],
};
