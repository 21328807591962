import Box from '@ui/MUI/Box';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

const NumberBlock = ({number}) => {
  return (
    <Box borderRight="1px solid #0000003D" p={4}>
      <TypographyDefault variant="h3">{number}</TypographyDefault>
    </Box>
  );
};

export default NumberBlock;
