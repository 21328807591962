import {userRoles} from '@src/constants';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {DARK_SIDE} from './Colors';

const getJustifyContent = (isEdit, isWithTitle) => {
  return isEdit ? 'flex-start' : isWithTitle ? 'space-between' : 'flex-end';
};

const getWidth = (isEdit, isMobile) => {
  return isEdit ? (isMobile ? '100%' : '15%') : '100%';
};
/**@deprecated не использовать этот компонент**/
export default function Tabs(props) {
  let tabs = !props.tabs ? [] : props.tabs;
  const {extraTopButton = null, title} = props;
  const goodTabs = tabs.filter((a) => a !== undefined);
  const mainUrl = props.url;

  const isParent = props?.currentUser?.userRole === userRoles.parent;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let selectedTabQuery = query.get('tab') ? query.get('tab') : '';
  if (goodTabs.length === 0) {
    return null;
  }
  if (goodTabs.length === 1 && !isParent) {
    return goodTabs[0].content;
  }
  return (
    <Wrapper className={'Wrapper'} getWidth={getWidth} isEdit={props.isEdit} isMobile={props.isMobile}>
      <TabNamesItems
        className={'TabNamesItems TabItemsBlock'}
        getJustifyContent={getJustifyContent}
        isEdit={props.isEdit}
        isWithTitle={title && true}
      >
        <div>{title}</div>
        {tabs.header ? <h1>{tabs.header}</h1> : null}
        <div>
          {goodTabs.map((tb, k) => {
            let {label, link} = tb;
            let url = `${mainUrl}?tab=${link}`;
            return (
              <Link key={k} to={k ? url : mainUrl}>
                <TabName className={selectedTabQuery === link ? 'tab-active' : 'TabName'}>{label}</TabName>
              </Link>
            );
          })}
          {!extraTopButton ? null : <ExtraPlaceholder>{extraTopButton}</ExtraPlaceholder>}
        </div>
      </TabNamesItems>

      {goodTabs.every((tab) => tab.content) &&
        goodTabs.map((el, k) => {
          let {content, link} = el;
          if (link === selectedTabQuery) {
            return (
              <ContentPlaceholder className={'ContentPlaceholder'} key={k}>
                {content}
              </ContentPlaceholder>
            );
          }
        })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  //width: ${(props) => props.getWidth(props.isEdit, props.isMobile)};
  width: 100%;
  // margin-top: ${(props) => (props.isMobile ? '10px' : '0')};
`;

const TabNamesItems = styled.div`
  display: flex;
  flex-direction: row;
  //justify-content: ${(props) => (props.isWithTitle ? 'space-between' : 'flex-end')};
  justify-content: ${(props) => props.getJustifyContent(props.isEdit, props.isWithTitle)};
  margin-left: ${(props) => (props.isEdit ? '25px' : 0)};
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 7px;
  background: transparent;
  // position: sticky;
  // top: 0;
  // border: none;
  z-index: 100;

  @media screen and (max-width: 1200px) {
    position: relative;
    top: auto;
    padding: 48px 20px 10px;
    height: auto;
    justify-content: flex-start;
    align-items: flex-end;
  }
  @media screen and (max-width: 576px) {
    position: relative;
    top: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
`;

const TabName = styled.div`
  display: inline-block;
  vertical-align: top;
  cursor: ${(props) => (props.selected === true ? 'default' : 'pointer')};
  margin-right: 5px;
  border-bottom: ${(props) => (props.selected === true ? `2px solid ${DARK_SIDE}` : 'none')};
  white-space: nowrap;
  :hover {
    opacity: 0.8;
  }
  :last-of-type {
    margin-right: 20px;
  }
  font-family: 'Proxima Nova', serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #fffaed;
  padding: 2px 10px;

  @media screen and (max-width: 777px) {
    margin-top: 10px;
  }
  @media screen and (max-width: 576px) {
    margin-right: 0;
    margin-left: 0;
    :last-of-type {
      margin-right: 0;
    }
  }
`;

const ExtraPlaceholder = styled.div`
  margin-left: auto;
`;

const ContentPlaceholder = styled.div`
  width: calc(100% - 4px);
`;

export const TabItem = styled.div``;
