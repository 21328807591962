export const mock = {
  data: [...Array(15)].fill({
    name: '30 метров спринт без шайбы (сек)',
    params: [
      {
        stage1: 4.7,
        avg: 4.46,
        season: '22-23',
        name: '30 метров спринт без шайбы (сек)',
        stage3: 4.23,
      },
      {
        stage1: 1,
        avg: 1,
        season: '23-24',
        name: '30 метров спринт без шайбы (сек)',
        delta: -3.46,
      },
    ],
  }),
  seasons: ['20-21', '22-23', '23-24'],
};
