import React from 'react';

function Play() {
  return (
    <React.Fragment>
      <>
        <g clipPath="url(#clip0_7824:7109)">
          <path
            d="M13.9967 6.77217C13.8792 3.09447 10.9064 0.121752 7.22883 0.00430605C5.22772 -0.0591838 3.35556 0.702498 1.97195 2.13102C0.641811 3.50431 -0.0570687 5.31475 0.00406241 7.22908C0.121411 10.9067 3.09423 13.8795 6.77182 13.9968C6.84839 13.9993 6.92426 14.0005 7.00033 14.0005C8.9123 14.0005 10.6981 13.2439 12.0287 11.8701C13.3588 10.4969 14.0578 8.68639 13.9967 6.77217ZM10.9698 10.8446C9.91939 11.9291 8.50964 12.5263 7.00033 12.5263C6.93998 12.5263 6.87964 12.5254 6.8188 12.5235C3.91635 12.4308 1.57018 10.0846 1.4774 7.1821C1.42914 5.67033 1.9808 4.24073 3.03084 3.15668C4.08127 2.07215 5.49102 1.47489 7.00033 1.47489C7.06067 1.47489 7.12102 1.47587 7.18185 1.47774C10.0843 1.57042 12.4305 3.9166 12.5233 6.81914C12.5715 8.33091 12.0198 9.76051 10.9698 10.8446Z"
            fill="#626262"
          />
          <path
            d="M9.29595 6.59332L6.19183 4.36764C5.86072 4.13019 5.39978 4.36686 5.39978 4.77433V9.22579C5.39978 9.63327 5.86072 9.86993 6.19183 9.63248L9.29585 7.4067C9.57438 7.20709 9.57438 6.79303 9.29595 6.59332Z"
            fill="#626262"
          />
        </g>
        <defs>
          <clipPath id="clip0_7824:7109">
            <rect fill="white" height="14" width="14" />
          </clipPath>
        </defs>
      </>
    </React.Fragment>
  );
}
export default Play;
