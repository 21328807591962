import IconButtonGroup from '@common/IconButtonGroup';
import {useRoleAccess} from '@hooks/useRoleAccess';
import TextField from '@mui/material/TextField';
import {userRoles} from '@src/constants';
import Stack from '@ui/MUI/Stack';
// import TextFieldDefault from '@ui/MUI/TextField/TextFieldDefault';
import React, {useRef, useState} from 'react';

const AcceptInput = ({initialValue = '', onAccept, onDelete}) => {
  const [value, setValue] = useState(initialValue);
  const isAcceptable = value !== initialValue;
  const isNew = !initialValue;

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const ref = useRef(null);

  const canEdit = useRoleAccess([userRoles?.teacher, userRoles?.methodist]);

  return (
    <Stack direction="row" spacing={5}>
      <TextField
        fullWidth
        inputRef={ref}
        readOnly={!canEdit}
        value={value}
        variant="standard"
        onChange={handleChange}
      />
      {canEdit && (
        <IconButtonGroup
          isAcceptable={isAcceptable}
          onAccept={() => onAccept(isNew, value)}
          onDelete={() => onDelete(isNew)}
          onEdit={() => ref.current.focus()}
        />
      )}
    </Stack>
  );
};

export default AcceptInput;
