import React from 'react';

function Xls() {
  return (
    <React.Fragment>
      <g>
        <g clipPath="url(#clip0)">
          <path
            d="M0.875 12.6873V1.31226C0.875 1.07063 1.07086 0.874763 1.3125 0.874763H7.875V2.62476C7.875 3.10801 8.26675 3.49976 8.75 3.49976H10.5V4.81226H11.375V3.06226C11.3757 2.94597 11.33 2.83419 11.2481 2.75164L8.62313 0.126638C8.54057 0.0447433 8.42879 -0.00089341 8.3125 -0.00023716H1.3125C0.587617 -0.00023716 0 0.587407 0 1.31226V12.6873C0 13.4121 0.587617 13.9998 1.3125 13.9998H4.375V13.1248H1.3125C1.07089 13.1248 0.875 12.9289 0.875 12.6873Z"
            fill="#2C2C2C"
          />
          <path
            d="M5.73562 7.24064L5.25 8.20752L4.76438 7.24064C4.69025 7.09353 4.53975 7.00051 4.375 7.00002H3.5V7.87502H4.10375L4.76 9.18752L4.10375 10.5H3.5V11.375H4.375C4.53975 11.3745 4.69025 11.2815 4.76438 11.1344L5.25 10.1675L5.73562 11.1344C5.80975 11.2815 5.96025 11.3745 6.125 11.375H7V10.5H6.39625L5.74 9.18752L6.39625 7.87502H7V7.00002H6.125C5.96025 7.00053 5.80975 7.09353 5.73562 7.24064Z"
            fill="#2C2C2C"
          />
          <path
            d="M12.6875 8.75002H11.8125C11.5709 8.75002 11.375 8.55415 11.375 8.31252C11.375 8.07088 11.5709 7.87502 11.8125 7.87502H12.6875C12.9291 7.87502 13.125 8.07088 13.125 8.31252H14C14 7.58763 13.4124 7.00002 12.6875 7.00002H11.8125C11.0876 7.00002 10.5 7.58763 10.5 8.31252C10.5 9.0374 11.0876 9.62501 11.8125 9.62501H12.6875C12.9291 9.62501 13.125 9.82088 13.125 10.0625C13.125 10.3042 12.9291 10.5 12.6875 10.5H11.8125C11.5709 10.5 11.375 10.3042 11.375 10.0625H10.5C10.5 10.7874 11.0876 11.375 11.8125 11.375H12.6875C13.4124 11.375 14 10.7874 14 10.0625C14 9.33766 13.4124 8.75002 12.6875 8.75002Z"
            fill="#2C2C2C"
          />
          <path
            d="M8.3125 7.00002H7.4375V10.9375C7.4375 11.1792 7.63336 11.375 7.875 11.375H10.0625V10.5H8.3125V7.00002Z"
            fill="#2C2C2C"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect fill="white" height="14" width="14" />
          </clipPath>
        </defs>
      </g>
    </React.Fragment>
  );
}
export default Xls;
