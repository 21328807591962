import date from '@helpers/date';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectCurrentPlayerDiary} from '@selectors/playerDiary';
import {loadCurrentUserDiary} from '@slices/playerDiary';
import {DEFAULT_DATE_SEND_FORMAT, longDash} from '@src/constants';
import {QUESTIONNAIRE_ITEMS_TYPES, QUESTIONNAIRE_QUESTION_TYPES as QT} from '@src/constants/playerDiary';
import {Box, Divider, Stack, Typography} from '@ui/MUI';
import DesktopDatePicker from '@ui/MUI/DesktopDatePicker';
import DrawerNew from '@ui/MUI/DrawerNew';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

QuestionnaireDrawer.propTypes = {
  player: PropTypes.oneOf([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    }).isRequired,
    null,
  ]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dateInterval: PropTypes.array.isRequired,
};
const trainingQuestions = [
  {
    ...QT.gymMood,
    label: 'Зал:Настроение',
  },
  {
    ...QT.gymHealth,
    label: 'Зал:самочувствие',
  },
  {
    ...QT.iceMood,
    label: 'Лед:Настроение',
  },
  {
    ...QT.iceHealth,
    label: 'Лед:Самочувствие',
  },
];
const questions = [
  {
    id: QUESTIONNAIRE_ITEMS_TYPES.morning,
    label: 'Шаг 1. Утром',
    questions: [QT.sleep, QT.mood, QT.willToTraining],
  },
  {
    id: QUESTIONNAIRE_ITEMS_TYPES.trainingGroup,
    label: 'Шаг 2. После групповой тренировки',
    questions: [...trainingQuestions],
  },
  {
    id: QUESTIONNAIRE_ITEMS_TYPES.trainingIndividual,
    label: 'Шаг 3. После индивидуальной тренировки',
    questions: [...trainingQuestions],
  },
  {
    id: QUESTIONNAIRE_ITEMS_TYPES.game,
    label: 'Шаг 4. После игры',
    questions: [
      QT.activity,
      QT.stress,
      QT.health,
      QT.attention,
      QT.fellingIceAndGoal,
      QT.comunicationWithTrainer,
      QT.selfScore,
    ],
  },
];
function QuestionnaireDrawer({player, open, onClose, dateInterval}) {
  const [currentDate, setCurrentDate] = useState();
  const dispatch = useStoreDispatch();
  const {data, isLoading, error, isLoad} = useStore(selectCurrentPlayerDiary);
  useEffect(() => {
    if (open && player?.id && date(currentDate).isValid()) {
      dispatch(loadCurrentUserDiary({playerId: player.id, date: date(currentDate).format(DEFAULT_DATE_SEND_FORMAT)}));
    }
  }, [open, player?.id, currentDate]);
  useEffect(() => {
    if (dateInterval?.[1]) {
      setCurrentDate(dateInterval?.[1]);
    }
  }, [dateInterval?.[1]]);

  const noData = useDeepMemo(
    () =>
      !data[QUESTIONNAIRE_ITEMS_TYPES.morning] &&
      !data[QUESTIONNAIRE_ITEMS_TYPES.trainingIndividual] &&
      !data[QUESTIONNAIRE_ITEMS_TYPES.trainingGroup] &&
      !data[QUESTIONNAIRE_ITEMS_TYPES.game],
    [data],
  );

  return (
    <DrawerNew
      open={open}
      title={
        <Box>
          <Stack direction="row" gap={0}>
            <Typography variant="h3">{'Анкета. '}</Typography>
            <Typography isLoading={isLoading} preloaderWidthBetween={[200, 250]} variant="h3">
              {player?.fullName || 'Игрока'}
            </Typography>
          </Stack>

          <Box mb={5} mt={4} width={200}>
            <DesktopDatePicker
              color="primary"
              maxDate={date(dateInterval?.[1]).innerDate}
              minDate={date(dateInterval?.[0]).innerDate}
              value={currentDate}
              variant="standard"
              onChange={(value) => {
                let result = value;
                if (!date(value).isValid()) return;
                if (date(value).isAfter(date(dateInterval?.[1]).innerDate)) {
                  result = date(dateInterval?.[1]).innerDate;
                }
                if (date(value).isBefore(date(dateInterval?.[0]).innerDate)) {
                  result = date(dateInterval?.[0]).innerDate;
                }
                setCurrentDate(result);
              }}
            />
          </Box>
        </Box>
      }
      onClose={(state) => {
        onClose(state);
        setCurrentDate(dateInterval?.[1]);
      }}
    >
      {isLoad && noData ? (
        <Box mt="60%" textAlign="center">
          <Typography variant="subtitle1">
            {error ? 'Ошибка при загрузке анкеты игрока' : 'За выбранную дату анкета не заполнена'}
          </Typography>
        </Box>
      ) : (
        questions.map(({id, label, questions}, index, arr) => {
          return (
            <React.Fragment key={index}>
              <Typography mb={4} variant="h4">
                {label}
              </Typography>
              {questions.map(({label, value, questions}, index) => {
                const answerId = data?.[id]?.[value];
                const answer = questions.find((question) => question?.value === answerId)?.label;
                return (
                  <Box key={index} mb={4}>
                    <Typography mb={1} variant="subtitle1">
                      {label}
                    </Typography>
                    <Typography isLoading={isLoading} preloaderWidthBetween={[200, 400]} variant="body2">
                      {answer || longDash}
                    </Typography>
                  </Box>
                );
              })}
              {index !== arr?.length - 1 && <Divider sx={{mb: 4}} />}
            </React.Fragment>
          );
        })
      )}
    </DrawerNew>
  );
}

export default QuestionnaireDrawer;
