import React from 'react';
import styled from 'styled-components';
/**@deprecated не использовать этот компонент**/
export default function TableDefaultContainer(props) {
  return (
    <Wrapper className={props.className ? `TableDefaultContainer ${props.className}` : 'TableDefaultContainer'}>
      <Container className={'container'}>
        {(props.title || props.headlineTitleContent || props.headlineContent) && (
          <HeadLine className={'Headline'}>
            <HeadLineTitleContainer className={'HeadlineTitleContainer'}>
              <HeadLineTitle className={'HeadlineTitle'}>
                {props.title && <HeadLineTitleText className={'HeadlineTitleText'}>{props.title}</HeadLineTitleText>}
                {props.headlineTitleContent && (
                  <HeadLineTitleContent className={'HeadLineTitleContent'}>
                    {props.headlineTitleContent}
                  </HeadLineTitleContent>
                )}
              </HeadLineTitle>
            </HeadLineTitleContainer>
            {props.headlineContent && (
              <HeadLineContent className={'HeadLineContent'}>{props.headlineContent}</HeadLineContent>
            )}
          </HeadLine>
        )}
        <TableBody className={'TableBody'}>{props.content}</TableBody>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
`;
const Container = styled.div``;
const HeadLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 34px;
  min-height: 60px;
  background: #fffaed;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.3);
`;
const HeadLineTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeadLineTitleText = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 16px/20px 'Proxima Nova';
  text-transform: uppercase;
`;
const HeadLineTitleContainer = styled.div`
  width: 100%;
`;
const HeadLineTitleContent = styled.div``;
const HeadLineContent = styled.div``;
const TableBody = styled.div`
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;
`;
