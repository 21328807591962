import TableTemplate from '@common/Statistics/PlayerStatistics/TableTemplate';
import TopFiveItem from '@common/Statistics/PlayerStatistics/TopFiveItem';
import {customTableComparator} from '@src/helpers';
import {Tooltip, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

import SingleProgressCell from '../../SingleProgressCell';

const columns = [
  {
    accessorKey: 'dekings',
    header: () => {
      return (
        <Tooltip title="Успешные обводки игроком">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Об+
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <SingleProgressCell percent={Math.abs(getValue()?.value)} value={getValue()?.value} />;
    },
    cellStyle: {position: 'relative', textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'playerDekingAttempts',
    header: () => {
      return (
        <Tooltip title="Попытки обводок игроком">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Об+Σ
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <TopFiveItem value={getValue()} />;
    },
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'successfulDekings',
    header: () => {
      return (
        <Tooltip title="% успешных обводок">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Об+%
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <TopFiveItem value={getValue()} />;
    },
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'unsuccessfulDekings',
    header: () => {
      return (
        <Tooltip title="Неуспешные обводки игрока">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Об-
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <TopFiveItem value={getValue()} />;
    },
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'dekingOnPlayerAttempts',
    header: () => {
      return (
        <Tooltip title="Попытки обводок игрока">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Об-Σ
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
  },
  {
    accessorKey: 'savedDekings',
    header: () => {
      return (
        <Tooltip title="Обводят в % случаев">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Об-%
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <TopFiveItem value={getValue()} />;
    },
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
];
function DekingsTab({isLoad, isLoading, data, hasError, hasMatchFilter}) {
  const [sorting, setSorting] = useState([{asc: true, id: 'dekings'}]);
  const rowsData = useMemo(
    () =>
      data?.map((item) => {
        return {
          student: item?.student,
          dekings: item?.dekings,
          playerDekingAttempts: item?.playerDekingAttempts,
          successfulDekings: item?.successfulDekings || {value: 0},
          unsuccessfulDekings: item?.unsuccessfulDekings,
          dekingOnPlayerAttempts: item?.dekingOnPlayerAttempts,
          savedDekings: item?.savedDekings,
        };
      }),
    [data],
  );

  return (
    <TableTemplate
      columns={columns}
      hasError={hasError}
      hasMatchFilter={hasMatchFilter}
      isLoad={isLoad}
      isLoading={isLoading}
      rowsData={rowsData}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
}

DekingsTab.propTypes = {
  data: PropTypes.array.isRequired,
};

export default DekingsTab;
