import {generateRandomId} from '@components/apps/TrainingControlProcess/constant';
import Stack from '@ui/MUI/Stack';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import SectionBotttom from '../SectionBottom';
import SectionTop from '../SectionTop';

const StyledStack = styled(Stack)`
  flex: 1;
  border-right: ${(props) => props.side === 'left' && '1px solid rgba(0, 0, 0, 0.24)'};
`;

const Section = ({side, title, initialItems, onAccept, onDelete}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const handleCreate = () => {
    setItems([...items, {name: '', id: generateRandomId()}]);
  };

  const handleDelete = (isNew, id) => {
    if (isNew) {
      setItems(items.filter((item) => item.id !== id));
    } else {
      onDelete(id);
    }
  };

  return (
    <StyledStack side={side}>
      <SectionTop side={side} title={title} onAdd={handleCreate} />
      <SectionBotttom items={items} side={side} onAccept={onAccept} onDelete={handleDelete} />
    </StyledStack>
  );
};

export default Section;
