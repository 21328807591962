import 'echarts/lib/chart/scatter';

import {Box} from '@ui/MUI';
import * as echarts from 'echarts';
import React, {useEffect, useRef, useState} from 'react';

function Echart({width, height, options, isResponsitive = true, className = '', resizeDeps}) {
  const defaultOption = {
    xAxis: {},
    yAxis: {},
    series: [],
  };

  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  /**
   * Инициализация графика
   */
  const init = (opt) => {
    // eslint-disable-next-line import/namespace
    const chart = echarts.init(chartRef.current);

    chart.setOption(opt ? opt : defaultOption);
    setChartInstance(chart);
  };
  useEffect(() => {
    init(options);
  }, []);
  /**
   * Реинициализация графика
   */
  const reInit = (options) => {
    if (!chartInstance) return;
    chartInstance.dispose();
    init(options);
  };

  useEffect(() => {
    reInit(options);
  }, [options]);

  useEffect(() => {
    if (isResponsitive && chartInstance) {
      window.addEventListener('resize', function () {
        chartInstance.resize();
      });
      return () => document.removeEventListener('resize', () => {});
    }
  }, [isResponsitive, chartInstance, resizeDeps]);

  return (
    <>
      <Box
        className={className + ' ' + 'EchartsChart'}
        sx={{
          textAlign: 'center',
          width: width,
          height: height,
        }}
      >
        <Box
          ref={chartRef}
          sx={{
            width: width,
            minHeight: height,
            height: height,
            maxHeight: height,
          }}
        ></Box>
      </Box>
    </>
  );
}

export default React.memo(Echart);
