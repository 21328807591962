import useStore from '@hooks/useStore';
import {Cancel} from 'axios';
import React from 'react';

import Actions from './Actions';
import FaceOffs from './FaceOffs';
import Shifts from './Shifts';

type ContentProps = {
  isShifts?: boolean;
  isFaceOffs?: boolean;
  cleaner?: () => (dispatch: unknown) => unknown;
  loader?: (params: object, isCancel?: (value: unknown) => value is Cancel) => (dispatch: unknown) => Promise<void>;
  mapDataGetter?: (state: {data: object}) => {
    data: object;
  };
  statisticsIds: Array<string>;
  selectedPlayerName: string;
};

function ActionVideoModalForPersonalFileContent({
  cleaner,
  loader,
  mapDataGetter,
  statisticsIds,
  selectedPlayerName,
  isShifts,
  isFaceOffs,
}: ContentProps) {
  const statistic = useStore(!isShifts && mapDataGetter ? mapDataGetter : (state) => state?.stats?.matchShifts);

  if (isShifts) {
    return (
      <Shifts
        cleaner={cleaner}
        loader={loader}
        selectedPlayerName={selectedPlayerName}
        statistic={statistic}
        statisticsIds={statisticsIds}
      />
    );
  }
  if (isFaceOffs) {
    return (
      <FaceOffs
        cleaner={cleaner}
        loader={loader}
        selectedPlayerName={selectedPlayerName}
        statistic={statistic}
        statisticsIds={statisticsIds}
      />
    );
  }
  return (
    <Actions
      cleaner={cleaner}
      loader={loader}
      selectedPlayerName={selectedPlayerName}
      statistic={statistic}
      statisticsIds={statisticsIds}
    />
  );
}
export default ActionVideoModalForPersonalFileContent;
