import {usePlayerDiaryList} from '@common/DevelopmentJournal/hooks';
import LoadingBox from '@common/LoadingBox';
import {PageWrapper} from '@common/Page';
import ReactTableComponent from '@common/ReactTableComponent';
import Filters from '@components/apps/DevelopmentJournal/components/Filters';
import IntervalFilter from '@components/apps/DevelopmentJournal/components/PlayersDiary/components/IntervalFilter';
import {TYPES_OPTIONS} from '@components/apps/DevelopmentJournalPlayer/constants';
import {tableDataMock} from '@components/apps/DevelopmentJournalPlayerSkills/components/SkillsWork/mock';
import date from '@helpers/date';
import {defaultSort} from '@helpers/index';
import {useMediaQuery} from '@mui/material';
import {DEFAULT_DATE_SEND_FORMAT, longDash} from '@src/constants';
import {Box, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useMemo, useState} from 'react';
import {useParams} from 'react-router';

function sortColumn(rowA, rowB, columnId) {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);
  return defaultSort(a, b);
}

const buttons = [
  {
    label: 'Неделя',
    name: 'week',
    interval: [
      date(date().innerDate).subtract(1, 'week').format(DEFAULT_DATE_SEND_FORMAT),
      date().innerDate.format(DEFAULT_DATE_SEND_FORMAT),
    ],
    onClick() {
      return this.interval;
    },
  },
  {
    label: 'Месяц',
    name: 'month',
    interval: [
      date(date().innerDate).subtract(1, 'month').format(DEFAULT_DATE_SEND_FORMAT),
      date().innerDate.format(DEFAULT_DATE_SEND_FORMAT),
    ],
    onClick() {
      return this.interval;
    },
  },
  {
    label: 'Промежуток',
    name: 'interval',
    interval: [
      date(date().innerDate).subtract(1, 'month').format(DEFAULT_DATE_SEND_FORMAT),
      date().innerDate.format(DEFAULT_DATE_SEND_FORMAT),
    ],
  },
];

const SkillsWork = () => {
  const [filters, setFilters] = useState({
    interval: [
      date(date().innerDate).subtract(1, 'week').format(DEFAULT_DATE_SEND_FORMAT),
      date().innerDate.format(DEFAULT_DATE_SEND_FORMAT),
    ],
  });
  const {id} = useParams();
  const [sorting, setSorting] = useState([{desc: true, id: 'date'}]);
  const {data, isLoading, isNoData} = usePlayerDiaryList({filters: filters, playersIds: [id]});
  const numeralsDeclension = {
    1: 'балл',
    2: 'балла',
    3: 'балла',
    4: 'балла',
    5: 'баллов',
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Дата',
        cell: ({getValue}) => {
          return (
            <Typography fontWeight={600} isLoading={isLoading} preloaderWidthBetween={[70, 80]} variant="subtitle2">
              {date(getValue())?.format('DD.MM.YYYY')}
            </Typography>
          );
        },
        sortingFn: sortColumn,
      },
      {
        accessorKey: 'type',
        header: 'Тип мероприятия',
        cell: ({getValue}) => (
          <Typography fontWeight={600} isLoading={isLoading} preloaderWidthBetween={[120, 130]} variant="subtitle2">
            {getValue() || longDash}
          </Typography>
        ),
      },
      {
        accessorKey: 'comments',
        header: 'Что делал для работы над навыком',
        cell: ({getValue, row}) => {
          return getValue()?.length > 0 ? (
            getValue()?.map(({estimate, comment}) => {
              return (
                <>
                  <Typography
                    fontWeight="bold"
                    isLoading={isLoading}
                    key={estimate.id}
                    preloaderWidthBetween={[180, 200]}
                    variant="body2"
                    whiteSpace="pre-line"
                  >
                    {estimate.skill.name}:{' '}
                    <Typography component="span" fontWeight="normal" isLoading={isLoading}>
                      {comment}.{' '}
                    </Typography>
                  </Typography>
                </>
              );
            })
          ) : (
            <Typography component="span" fontWeight="normal" isLoading={isLoading}>
              {!row?.original?.isDayOff ? 'Не заполнено' : 'Выходной'}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'mood',
        header: 'Настроение',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[100, 120]} variant="body2">
            {!getValue() ? 'Не заполнено' : `${getValue()} ${numeralsDeclension[getValue()]}`}
          </Typography>
        ),
      },
      {
        accessorKey: 'health',
        header: 'Самочувствие',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[100, 120]} variant="body2">
            {!getValue() ? 'Не заполнено' : `${getValue()} ${numeralsDeclension[getValue()]}`}
          </Typography>
        ),
      },
    ],
    [isLoading],
  );
  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <PageWrapper sx={{p: '0px', overflow: 'hidden'}}>
      <Filters
        filters={[
          {
            label: 'Тип тренировки',
            value: 'type',
            multiple: false,
            disabled: isLoading,
            isLoading: isLoading,
            options: TYPES_OPTIONS,
            onChange(newValue) {
              setFilters((prevState) => ({...prevState, type: newValue || null}));
            },
          },
        ]}
        sx={{
          flexWrap: isSmResolution && 'wrap',
          justifyContent: 'space-between',
          '.MuiBox-root:last-child > .MuiStack-root': {
            justifyContent: 'flex-end',
            minWidth: !isSmResolution ? 550 : 'auto',
            width: !isSmResolution ? 'auto' : '100%',
          },
        }}
        value={filters}
        onChange={setFilters}
      >
        <IntervalFilter buttons={buttons} value={filters?.interval} onChange={setFilters} />
      </Filters>
      {isNoData ? (
        <TableEmptyBlock text="Нет данных." />
      ) : (
        <LoadingBox isLoading={isLoading}>
          <Box borderTop={`1px solid ${grey['12']}`}>
            <ReactTableComponent
              columns={columns}
              data={
                !isLoading
                  ? data
                      ?.map((item) => {
                        if (!filters?.type?.value) {
                          return [
                            {
                              date: item?.date,
                              type: 'Индивидульная тренировка',
                              comments: item?.trainingIndividual?.comments || [],
                              mood: item?.trainingIndividual?.skillMood,
                              health: item?.trainingIndividual?.skillHealth,
                              isDayOff: item?.trainingIndividual?.isDayOff || false,
                            },
                            {
                              date: item?.date,
                              type: 'Групповая тренировка',
                              comments: item?.trainingGroup?.comments || [],
                              mood: item?.trainingGroup?.skillMood,
                              health: item?.trainingGroup?.skillHealth,
                              isDayOff: item?.trainingGroup?.isDayOff || false,
                            },
                          ];
                        } else {
                          return [
                            {
                              date: item?.date,
                              type: filters?.type?.label,
                              comments: item?.[filters?.type?.value]?.comments || [],
                              mood: item?.[filters?.type?.value]?.skillMood,
                              health: item?.[filters?.type?.value]?.skillHealth,
                              isDayOff: item?.[filters?.type?.value]?.isDayOff || false,
                            },
                          ];
                        }
                      })
                      .flat()
                  : tableDataMock
              }
              setSorting={setSorting}
              sorting={sorting}
            />
          </Box>
        </LoadingBox>
      )}
    </PageWrapper>
  );
};

export default SkillsWork;
