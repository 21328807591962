export const mock = {
  groups: [
    {
      name: 'Авангард-2007',
      id: '3b6EgNSDVw',
    },
  ],
  schools: [
    {
      name: '"Авангард" Академия ',
      id: 'ITfD4PL0oy',
    },
  ],
  students: [
    {
      id: 'QwQPPT-S6NtU',
      name: 'Белодедов Александр',
    },
  ],
  teachers: [
    {
      id: 'MIaHFspW7V',
      name: 'Баранов Константин',
    },
    {
      id: 'qCdZh6Gnii',
      name: 'Смагин Дмитрий',
    },
  ],
  moments: [],
  id: '2NCg3eB6aRy6',
  name: 'Кубок ястребов. Матч со СКА-Юность. Большинство. Прохождение своей и средней зоны',
  tag: {
    name: 'Бэкчек',
    slug: 'backcheck',
  },
  description: 'rfregrtg',
  author: null,
  link: 'https://youtu.be/X6Zu3mqveA0',
  createdAt: '2023-04-24T09:51:18.079Z',
  isNew: false,
  canEdit: false,
  canDelete: false,
};
