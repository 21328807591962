import styled from 'styled-components';

export const Container = styled.div`
  width: ${({width}) => (width ? `${width}px` : '100%')};
  height: ${({height}) => height && `${height}px`};
  cursor: ${({onClick}) => onClick && 'pointer'};
  border: ${({border}) => border && border};
  padding: ${({padding}) => padding && padding};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${({borderRadius}) => borderRadius && borderRadius};
`;

export const TopWrapper = styled.div`
  margin-bottom: 12px;
`;

export const ContentWrapper = styled.div``;

export const BottomWrapper = styled.div``;

export const Image = styled.img`
  width: 100%;
  height: 144px;
  object-fit: cover;
`;
