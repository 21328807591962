import ColumnHeader from '@common/Statistics/PlayerStatistics/ColumnHeader';
import ProgressCell from '@common/Statistics/PlayerStatistics/ProgressCell';
import TableTemplate from '@common/Statistics/PlayerStatistics/TableTemplate';
import TopFiveItem from '@common/Statistics/PlayerStatistics/TopFiveItem';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectOpponentPlayersStats} from '@selectors/gameStatisticsMkc';
import {clearOpponentPlayersStats, loadOpponentPlayersStats} from '@slices/statisticsMKC';
import {noDataNumberValue} from '@src/helpers';
import DrawerNew from '@ui/MUI/DrawerNew';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

PlayerStatisticsContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isFinal: PropTypes.bool,
  season: PropTypes.string,
};

const columns = [
  {
    accessorKey: 'score',
    cell: (params) => <TopFiveItem value={params?.getValue()} />,
    header: <ColumnHeader isIndividual title="Очки" value="О" />,
  },
  {
    accessorKey: 'goals',
    cell: (params) => <TopFiveItem value={params?.getValue()} />,
    header: <ColumnHeader isIndividual title="Голы" value="Г" />,
  },
  {
    accessorKey: 'assists',
    cell: (params) => <TopFiveItem value={params?.getValue()} />,
    header: <ColumnHeader isIndividual title="Ассисты" value="А" />,
  },
  {
    accessorKey: 'plusMinus',
    cellStyle: {position: 'relative'},
    cell: (params) => (
      <ProgressCell percent={Math.abs(params.getValue()?.percent) || 0} value={params.getValue()?.value || 0} />
    ),
    header: <ColumnHeader title="Плюс минус" value="+/-" />,
  },
  {
    accessorKey: 'plus',
    header: <ColumnHeader value="+" />,
  },
  {
    accessorKey: 'minus',
    header: <ColumnHeader value="-" />,
  },
];
function PlayerStatisticsContent({isOpen, setIsOpen, teamId, isFinal = false, season}) {
  const dispatch = useStoreDispatch();
  const {data, isLoading, isLoad, hasError} = useStore(selectOpponentPlayersStats);
  useEffect(() => {
    isOpen && dispatch(loadOpponentPlayersStats({teamId, isFinal, season}));
    return () => {
      dispatch(clearOpponentPlayersStats());
    };
  }, [teamId, isOpen, isFinal, season]);

  return (
    <DrawerNew
      contentWrapperProps={{sx: {px: '0 !important', pt: (theme) => theme.spacing(7) + '!important'}}}
      hasButtons={false}
      open={isOpen}
      width="calc(100% - 180px)"
      onClose={setIsOpen}
    >
      <TableTemplate
        columns={columns}
        hasError={hasError}
        isLoad={isLoad}
        loading={isLoading}
        rowsData={data?.map(({assists, goals, minus, plus, plusMinus, score, student}) => ({
          student,
          score,
          goals,
          assists,
          plusMinus,
          plus: noDataNumberValue(plus),
          minus: noDataNumberValue(minus),
        }))}
        showAnnotation={false}
        tableHeaderCellProps={{sx: {position: 'relative'}}}
      />
    </DrawerNew>
  );
}

export default PlayerStatisticsContent;
