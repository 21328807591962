import AddStatsForm from '@common/AddStatsForm';
import PageTitleDefault from '@ui/PageTitleDefault';
import React from 'react';

const AddStatsApp = () => {
  return (
    <>
      <PageTitleDefault className="PageTitle" text="Загрузка статистики" />
      <AddStatsForm />
    </>
  );
};

export default AddStatsApp;
