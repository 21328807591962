import DashboardBlock from '@components/apps/StudentDashboard/components/DashboardBlock';
import Game from '@components/apps/StudentDashboard/components/DayDiary/components/Game';
import GroupTraining from '@components/apps/StudentDashboard/components/DayDiary/components/GroupTraining';
import IndividualTraining from '@components/apps/StudentDashboard/components/DayDiary/components/IndividualTraining';
import date from '@helpers/date';
import {Stack, Typography} from '@ui/MUI';
import React from 'react';

function DayDiary() {
  return (
    <DashboardBlock
      title={
        <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={1}>
          <Typography variant="h2">Твой дневник</Typography>
          <Typography variant="subtitle2">{date().innerDate.format('DD.MM.YYYY, dd')}</Typography>
        </Stack>
      }
    >
      <Typography mb={2} variant="subtitle1">
        Как прошел твой день?
        <br />
        Выбери, какое мероприятие сегодня было, и опиши свои впечатления.
      </Typography>
      <Stack direction="column" spacing={3}>
        <Game />
        <GroupTraining />
        <IndividualTraining />
      </Stack>
    </DashboardBlock>
  );
}

export default DayDiary;
