import ProgressCell from '@common/Statistics/PlayerStatistics/ProgressCell';
import TableTemplate from '@common/Statistics/PlayerStatistics/TableTemplate';
import {customTableComparator} from '@src/helpers';
import {Tooltip, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

const columns = [
  {
    accessorKey: 'corsiForPercent',
    header: () => {
      return (
        <Tooltip title="Доля всех бросков нашей команды в сторону ворот, когда игрок был на льду (>50% - хорошо)">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            CF%
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {position: 'relative', textAlign: 'center'},
    cell: ({getValue}) => <ProgressCell percent={getValue()?.percent || 0} value={getValue()?.value} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'corsiFor',
    header: () => {
      return (
        <Tooltip title="Броски нашей команды в сторону ворот, когда игрок был на льду">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            CF
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'corsiAgainst',
    header: () => {
      return (
        <Tooltip title="Броски cоперника в сторону ворот, когда игрок был на льду">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            CA
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'corsiForOZPercent',
    header: () => {
      return (
        <Tooltip title="Доля всех бросков нашей команды в сторону ворот, когда игрок был на льду (>50% - хорошо)">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            CF ОЗ%
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {position: 'relative', textAlign: 'center'},
    cell: ({getValue}) => <ProgressCell percent={getValue()?.percent || 0} value={getValue()?.value} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'corsiForOZ',
    header: () => {
      return (
        <Tooltip title="Броски нашей команды из опасной зоны в стороны ворот, когда игрок был на льду">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            CF ОЗ
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
  },
  {
    accessorKey: 'corsiAgainstOZ',
    header: () => {
      return (
        <Tooltip title="Броски соперника из опасной зоны в стороны ворот, когда игрок был на льду">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            CA ОЗ
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
  },
];
function CorsiTab({isLoad, isLoading, data, hasError, hasMatchFilter}) {
  const [sorting, setSorting] = useState([{asc: true, id: 'corsiForPercent'}]);
  const rowsData = useMemo(
    () =>
      data?.map((item) => {
        return {
          student: item?.student,
          corsiFor: item?.corsiFor,
          corsiAgainst: item?.corsiAgainst,
          corsiForPercent: item?.corsiForPercent,
          corsiForOZPercent: item?.corsiForOZPercent,
          corsiForOZ: item?.corsiForOZ,
          corsiAgainstOZ: item?.corsiAgainstOZ,
        };
      }),
    [data],
  );

  return (
    <TableTemplate
      columns={columns}
      hasError={hasError}
      hasMatchFilter={hasMatchFilter}
      isLoad={isLoad}
      isLoading={isLoading}
      rowsData={rowsData}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
}

CorsiTab.propTypes = {
  data: PropTypes.array.isRequired,
};

export default CorsiTab;
