import FunctionalTestingAPI from '@api/FunctionalTestingAPI';
import {createSlice} from '@reduxjs/toolkit';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';

const initialState = {
  tableEditCreate: {
    items: [],
    states: {},
    isLoading: false,
    isLoad: false,
    error: false,
  },
  stagedTableEditCreate: {
    items: [],
    states: {},
    isLoading: false,
    isLoad: false,
    error: false,
  },
  generalState: {
    items: [],
    isLoading: false,
    isLoad: false,
    error: false,
  },
  reportsInformation: {
    items: [],
    isLoading: false,
    isLoad: false,
    error: false,
  },
  dynamicOfTests: {
    items: [],
    isLoading: false,
    isLoad: false,
    error: false,
  },
  stageTests: {
    items: [],
    isLoading: false,
    isLoad: false,
    error: false,
  },
};

const functionalTesting = createSlice({
  name: 'functionalTesting',
  initialState,
  reducers: {
    tableEditCreateLoading: ({tableEditCreate}) => {
      tableEditCreate.isLoading = true;
    },
    tableEditCreateLoad: ({tableEditCreate}, action) => {
      tableEditCreate.isLoading = false;
      tableEditCreate.isLoad = true;
      tableEditCreate.items = action.payload;
    },
    tableEditCreateError: ({tableEditCreate}) => {
      tableEditCreate.isLoading = false;
      tableEditCreate.isLoad = true;
      tableEditCreate.error = 'Ошибка при загрузке данных';
    },
    tableEditCreateClear: ({tableEditCreate}) => {
      tableEditCreate.items = [];
    },
    tableEditCreateEndLoading: ({tableEditCreate}) => {
      tableEditCreate.isLoading = false;
    },
    statesLoad: ({tableEditCreate}, action) => {
      tableEditCreate.isLoading = false;
      tableEditCreate.isLoad = true;
      tableEditCreate.states = action.payload;
    },
    generalStateLoading: ({generalState}) => {
      generalState.isLoading = true;
    },
    generalStateLoad: ({generalState}, action) => {
      generalState.isLoading = false;
      generalState.isLoad = true;
      generalState.items = action.payload;
    },
    generalStateError: ({generalState}) => {
      generalState.isLoading = false;
      generalState.isLoad = true;
      generalState.error = 'Ошибка при загрузке данных';
    },
    generalStateClear: ({generalState}) => {
      generalState.items = [];
    },
    reportsInformationLoading: ({reportsInformation}) => {
      reportsInformation.isLoading = true;
    },
    reportsInformationLoad: ({reportsInformation}, action) => {
      reportsInformation.isLoading = false;
      reportsInformation.isLoad = true;
      reportsInformation.items = action.payload;
    },
    reportsInformationError: ({reportsInformation}) => {
      reportsInformation.isLoading = false;
      reportsInformation.isLoad = true;
      reportsInformation.error = 'Ошибка при загрузке данных';
    },
    reportsInformationClear: ({reportsInformation}) => {
      reportsInformation.items = [];
    },
    dynamicOfTestsLoading: ({dynamicOfTests}) => {
      dynamicOfTests.isLoading = true;
    },
    dynamicOfTestsLoad: ({dynamicOfTests}, action) => {
      dynamicOfTests.isLoading = false;
      dynamicOfTests.isLoad = true;
      dynamicOfTests.items = action.payload;
    },
    dynamicOfTestsClear: ({dynamicOfTests}) => {
      dynamicOfTests.items = [];
    },
    stageTestsLoading: ({stageTests}) => {
      stageTests.isLoading = true;
    },
    stageTestsLoad: ({stageTests}, action) => {
      stageTests.isLoading = false;
      stageTests.isLoad = true;
      stageTests.items = action.payload;
    },
    stageTestsClear: ({stageTests}) => {
      stageTests.items = [];
    },
    stagedTableEditCreateLoading: ({stagedTableEditCreate}) => {
      stagedTableEditCreate.isLoading = true;
    },
    stagedTableEditCreateLoad: ({stagedTableEditCreate}, action) => {
      stagedTableEditCreate.isLoading = false;
      stagedTableEditCreate.isLoad = true;
      stagedTableEditCreate.items = action.payload;
    },
    stagedTableEditCreateError: ({stagedTableEditCreate}) => {
      stagedTableEditCreate.isLoading = false;
      stagedTableEditCreate.isLoad = true;
      stagedTableEditCreate.error = 'Ошибка при загрузке данных';
    },
    stagedTableEditCreateClear: ({stagedTableEditCreate}) => {
      stagedTableEditCreate.items = [];
    },
    stagedTableEditCreateEndLoading: ({stagedTableEditCreate}) => {
      stagedTableEditCreate.isLoading = false;
    },
  },
});

const {actions, reducer} = functionalTesting;

const {
  tableEditCreateLoading,
  tableEditCreateLoad,
  tableEditCreateError,
  tableEditCreateClear,
  tableEditCreateEndLoading,
  statesLoad,
  generalStateLoading,
  generalStateLoad,
  generalStateError,
  generalStateClear,
  reportsInformationLoading,
  reportsInformationLoad,
  reportsInformationError,
  reportsInformationClear,
  dynamicOfTestsLoading,
  dynamicOfTestsLoad,
  dynamicOfTestsClear,
  stageTestsLoading,
  stageTestsLoad,
  stageTestsClear,
  stagedTableEditCreateLoading,
  stagedTableEditCreateLoad,
  stagedTableEditCreateError,
  stagedTableEditCreateEndLoading,
} = actions;

export {reducer as functionalTestingReducer};

const errorMessage = (err) => {
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], err.response.data.message);
      }
    }
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

export const fetchStates = () => async (dispatch) => {
  const states = await FunctionalTestingAPI.getStates();
  dispatch(statesLoad(states.data));
};

export const fetchDateForReportByDate = (teamId, date) => async (dispatch) => {
  dispatch(tableEditCreateLoading());
  dispatch(tableEditCreateClear());
  const states = await FunctionalTestingAPI.getStates();
  dispatch(statesLoad(states.data));
  const players = await FunctionalTestingAPI.getReportByDate(date, teamId);
  const playersData = players.data.map(({id, ...rest}) => {
    return {
      ...rest,
      psycho: {value: rest.psycho, label: states.data.psychologic[rest.psycho]},
      energy: {value: rest.energy, label: states.data.energy[rest.energy]},
      readiness: {value: rest.readiness, label: states.data.readiness[rest.readiness]},
      functional: {value: rest.functional, label: states.data.functional[rest.functional]},
      id: id,
    };
  });
  dispatch(tableEditCreateLoad(playersData));
};

export const saveFunctionalTestingReport = (tableData) => (dispatch) => {
  dispatch(tableEditCreateLoading());
  return FunctionalTestingAPI.saveReport(tableData)
    .then((response) => {
      if (response.status === 201) {
        dispatch(tableEditCreateEndLoading());
        return response;
      }
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch(tableEditCreateError());
      return err;
    });
};

export const editFunctionalTestingReport = (tableData) => (dispatch) => {
  dispatch(tableEditCreateLoading());
  return FunctionalTestingAPI.editReport(tableData)
    .then((response) => {
      dispatch(tableEditCreateEndLoading());
      dispatch(tableEditCreateLoad(response.data));
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch(tableEditCreateError());
      return err;
    });
};

export const getFunctionalTestingGeneralState = () => (dispatch) => {
  dispatch(generalStateLoading());
  dispatch(generalStateClear());
  return FunctionalTestingAPI.getGeneralState()
    .then((response) => {
      if (response.status === 200) {
        dispatch(generalStateLoad(response.data));
        return response;
      }
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch(generalStateError());
    });
};

export const getReportsInformation = (groupId) => (dispatch) => {
  dispatch(reportsInformationLoading());
  dispatch(reportsInformationClear());
  return FunctionalTestingAPI.getReportsForGroup(groupId)
    .then((response) => {
      if (response.status === 200) {
        dispatch(reportsInformationLoad(response.data));
        return response;
      }
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch(reportsInformationError());
    });
};

export const fetchFunctionalTestsDynamic = (groupId, seasons) => (dispatch) => {
  dispatch(dynamicOfTestsLoading());
  dispatch(dynamicOfTestsClear());
  FunctionalTestingAPI.getFunctionalTestingDynamic(groupId, seasons).then((dynamicOfTests) => {
    dispatch(dynamicOfTestsLoad(dynamicOfTests.data));
  });
};

export const fetchStageTestsByGroupId = (groupId, seasons) => (dispatch) => {
  dispatch(stageTestsLoading());
  dispatch(stageTestsClear());
  FunctionalTestingAPI.getStageTestsByGroupId(groupId, seasons).then((res) => {
    dispatch(stageTestsLoad(res.data));
  });
};

export const fetchStageTestsByDate = (groupId, date) => (dispatch) => {
  dispatch(stageTestsLoading());
  dispatch(stageTestsClear());
  FunctionalTestingAPI.getStageTestsByDate(groupId, date).then((res) => {
    dispatch(stageTestsLoad(res.data));
  });
};

export const saveStagedTestingReport = (tableData) => (dispatch) => {
  dispatch(stagedTableEditCreateLoading());
  return FunctionalTestingAPI.saveStagedTesting(tableData)
    .then((response) => {
      if (response.status === 201) {
        dispatch(stagedTableEditCreateEndLoading());
        return response;
      }
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch(stagedTableEditCreateError());
      return err;
    });
};

export const editStagedTestingReport = (tableData) => (dispatch) => {
  dispatch(stagedTableEditCreateLoading());
  return FunctionalTestingAPI.editStagedTesting(tableData)
    .then((response) => {
      dispatch(stagedTableEditCreateEndLoading());
      dispatch(stagedTableEditCreateLoad(response.data));
    })
    .catch(function (err) {
      errorMessage(err);
      dispatch(stagedTableEditCreateError());
      return err;
    });
};
