import {Box} from '@ui/MUI';
import React from 'react';
import styled from 'styled-components';

function Wrapper({children, ...props}) {
  return (
    <BlockWrapper overflow="hidden" px={4} py={3} {...props}>
      {children}
    </BlockWrapper>
  );
}

export default Wrapper;

const BlockWrapper = styled(Box)`
  background-color: #fff;
  border-radius: 6px;
  ${(props) => props.sx}
`;
