import React from 'react';

function Refresh() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M10.2858 0C10.0491 0 9.85727 0.191878 9.85727 0.428579V1.99414C7.65081 -0.142031 4.13045 -0.0850279 1.99428 2.1214C0.987555 3.16125 0.425934 4.55266 0.428721 5.99999C0.428721 6.23669 0.620599 6.42857 0.857301 6.42857C1.094 6.42857 1.28586 6.23669 1.28586 5.99999C1.28638 3.39637 3.39744 1.28612 6.00108 1.28664C7.32338 1.28689 8.58477 1.84249 9.47758 2.81785L7.57902 3.45083C7.35417 3.52564 7.23251 3.76855 7.30731 3.9934C7.38212 4.21825 7.62503 4.33991 7.84987 4.2651L10.4213 3.40797C10.5968 3.34951 10.715 3.18498 10.7145 2.99998V0.428554C10.7144 0.191878 10.5226 0 10.2858 0Z"
          fill="#2C2C2C"
        />
        <path
          d="M11.1428 5.57129C10.9061 5.57129 10.7142 5.76317 10.7142 5.99987C10.7137 8.60349 8.60266 10.7137 5.99902 10.7132C4.67672 10.713 3.41534 10.1574 2.52252 9.18201L4.42108 8.54902C4.64593 8.47421 4.7676 8.23131 4.69279 8.00646C4.61798 7.78161 4.37508 7.65995 4.15023 7.73475L1.5788 8.59189C1.40327 8.65035 1.28507 8.81488 1.28565 8.99987V11.5713C1.28565 11.808 1.47752 11.9999 1.71423 11.9999C1.95093 11.9999 2.14281 11.808 2.14281 11.5713V10.0057C4.34926 12.1419 7.86966 12.0849 10.0058 9.87845C11.0125 8.83861 11.5741 7.4472 11.5714 5.99987C11.5714 5.76317 11.3795 5.57129 11.1428 5.57129Z"
          fill="#2C2C2C"
        />
      </g>
    </React.Fragment>
  );
}
export default Refresh;
