import React, {memo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import {FilterContainer, Label} from '../../styles';
import PureSelect from './PureSelect';

const Filter = (props) => {
  const {label, options, updateOptions, updateLoading, updateValue, handelChange, updateDisable, value, ...restProps} =
    props;

  const state = useSelector((state) => {
    const schools = state.schools.schoolsMap.toArray();
    const groups = state.groups.groupsMap.toArray();
    const currentUser = state.users.currentUser;
    const role = currentUser.userRole;
    const userId = currentUser.id;
    const isTeacher = role === 'teacher';
    const isStudent = role === 'student';
    const isFranchisee = role === 'franchisee';
    const isPsycho = role === 'psycho';
    const isSchoolsAdmin = role === 'schools_admin';
    const isAdmin = role === 'admin';
    const isDoctor = role === 'doctor';

    return {
      schools,
      groups,
      role,
      isDoctor,
      isAdmin,
      userId,
      isTeacher,
      isStudent,
      isFranchisee,
      isPsycho,
      currentUser,
      isSchoolsAdmin,
    };
  }, shallowEqual);

  return (
    <FilterContainer className={props.className}>
      {props.label && <Label>{props.label}</Label>}
      {props.renderFilter ? (
        props.renderFilter(props)
      ) : (
        <PureSelect
          {...restProps}
          currentUser={state.currentUser}
          filters={props.filters}
          groups={state.groups}
          isAdmin={state.isAdmin}
          isDoctor={state.isDoctor}
          isFranchisee={state.isFranchisee}
          isPsycho={state.isPsycho}
          isSchoolsAdmin={state.isSchoolsAdmin}
          isTeacher={state.isTeacher}
          isUserStudent={state.isStudent}
          label={label}
          options={state.options || options}
          schools={state.schools}
          updateDisable={updateDisable}
          updateLoading={updateLoading}
          updateOptions={updateOptions}
          updateValue={updateValue}
          userId={state.userId}
          value={state.currentValue || value}
          onChange={handelChange}
        />
      )}
    </FilterContainer>
  );
};

export default memo(Filter);
