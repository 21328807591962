import {getGameActionById} from '@api/StatsAPI';
import {actionButtonsMock} from '@common/Statistics/ActionsMap/Buttons/mock';
import {actionsGetter, defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {createSlice} from '@reduxjs/toolkit';
import {actionsMapInitialState, actionsMapStatePaths} from '@slices/statistics/actionsMap';
import {goaltenderReducer, goaltendersInitialState, goaltendersStatePaths} from '@slices/statistics/goaltenders';
import {metricStatisticsReducer, shotsInitialState, shotsMapStatePaths} from '@slices/statistics/shotsMap';
import {
  throwinsInitialState,
  throwinsMapReducer,
  throwinsMetricsReducer,
  throwinsStatePaths,
} from '@slices/statistics/throwins';
import {initialStateIndicators, STATISTICS_PLAYERS_TABS as TABS} from '@src/constants';

import moduleNames from '../../moduleNames';

const statePaths = {
  ...actionsMapStatePaths,
  ...shotsMapStatePaths,
  ...goaltendersStatePaths,
  ...throwinsStatePaths,
  gameActionDetails: {
    name: 'gameActionDetails',
    path: 'gameActionDetails',
  },
};

export const initialState = {
  players: {
    [TABS.classic.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.time.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.shots.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.dekings.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.fouls.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.passes.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.puckBattle.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.powerStruggle.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
    [TABS.corsi.value]: {
      isLoading: false,
      isLoad: false,
      data: [],
      hasError: false,
    },
  },
  ...actionsMapInitialState,
  ...shotsInitialState,
  ...goaltendersInitialState,
  ...throwinsInitialState,
  actionModal: {
    isOpen: false,
    data: {},
  },
  gameActionDetails: {
    ...initialStateIndicators,
    data: {},
  },
  teamActionsSide: {
    isOur: false,
  },
};

//redusers
const index = createSlice({
  name: moduleNames.gameStatistics,
  initialState,
  reducers: {
    clearPlayerStatisticsTab: (state, {payload}) => {
      if (state.players?.[payload] && initialState.players?.[payload]) {
        state.players[payload] = initialState.players[payload];
      } else {
        state.players = initialState.players;
      }
    },
    ...defaultSliceReducersConstructor(statePaths.actionsMap?.name, statePaths.actionsMap?.path, initialState)
      .functions,

    ...defaultSliceReducersConstructor(
      statePaths.actionsMapButtonTypes?.name,
      statePaths.actionsMapButtonTypes?.path,
      initialState,
      {},
      actionButtonsMock,
    ).functions,
    setVideoModal: (state, {payload}) => {
      state.actionModal = {...state.actionModal, ...payload};
    },
    ...defaultSliceReducersConstructor(statePaths?.shotsMap?.name, statePaths?.shotsMap?.path, initialState).functions,
    setEntityFilters: (state, {payload: {entity, ...payload}}) => {
      if (entity && state?.[entity]) state[entity].filters = {...state[entity].filters, ...payload};
    },
    clearEntityFilters: (state, {payload}) => {
      const entity = payload.entity;
      if (entity && state?.[entity]) state[entity].filters = {...initialState[entity].filters};
    },
    ...defaultSliceReducersConstructor(
      statePaths.shotsMapButtonTypes?.name,
      statePaths.shotsMapButtonTypes?.path,
      initialState,
      {},
      actionButtonsMock,
    ).functions,
    ...metricStatisticsReducer,
    ...goaltenderReducer,
    ...throwinsMetricsReducer,
    ...throwinsMapReducer,
    ...defaultSliceReducersConstructor(
      statePaths.gameActionDetails?.name,
      statePaths.gameActionDetails?.path,
      initialState,
    ).functions,
    setTeamActionsSide: (state, {payload}) => {
      state.teamActionsSide.isOur = payload;
    },
  },
});

//exports

export const {actions, reducer} = index;

export const actionsMapActions = actionsGetter(statePaths?.actionsMap?.name, actions);
export const actionsMapButtonTypesActions = actionsGetter(statePaths?.actionsMapButtonTypes?.name, actions);
export const shotsMapActions = actionsGetter(shotsMapStatePaths?.shotsMap?.name, actions);
export const shotsMapButtonTypesActions = actionsGetter(shotsMapStatePaths?.shotsMapButtonTypes?.name, actions);
export const goaltendersActions = actionsGetter(goaltendersStatePaths?.goaltenders?.name, actions);
export const metricStatisticsActions = actionsGetter(shotsMapStatePaths?.metricStatistics?.name, actions);
export const throwinsMetricsActions = actionsGetter(throwinsStatePaths?.throwinsMetrics?.name, actions);
export const throwinsMapActions = actionsGetter(throwinsStatePaths?.throwinsMap?.name, actions);
export const setVideoModal = (data) => (dispatch) => dispatch(actions?.setVideoModal(data));
export const gameActionDetailsActions = actionsGetter(statePaths?.gameActionDetails?.name, actions);

export const loadGameActionDetails = (actionId, {cancelToken = null, isCancel = false} = {}) => {
  return defaultLoader(
    () => getGameActionById(actionId, cancelToken),
    gameActionDetailsActions.loading,
    gameActionDetailsActions.load,
    gameActionDetailsActions.error,
    {isCancel, onCancelAction: gameActionDetailsActions.loading},
  );
};
export const setTeamActionsSide = (data) => (dispatch) => dispatch(actions.setTeamActionsSide(data));
