import ReactTableComponent from '@common/ReactTableComponent';
import useDeepMemo from '@hooks/useDeepMemo';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {ColumnDef} from '@tanstack/react-table';
import {IconButton, Stack, Typography} from '@ui/MUI';
import React from 'react';
import {useHistory} from 'react-router-dom';

interface TableProps {
  isLoading: boolean;
  data: unknown[];
  onEdit: (id: string) => void;
}
function Table({data = [], isLoading = false, onEdit}: TableProps) {
  const history = useHistory();
  const columns = useDeepMemo<unknown, Partial<ColumnDef<{name: string; id: string}>>[]>(
    () => [
      {
        header: 'Название турнира',
        accessorKey: 'name',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[100, 120]} variant="body1">
            {getValue()}
          </Typography>
        ),
      },
      {
        header: '',
        accessorKey: 'id',
        cellStyle: {
          width: '150px',
          textAlign: 'center',
        },
        cell: ({getValue}) => {
          const id = getValue();
          return (
            <Stack direction="row" justifyContent="center" spacing={2}>
              <IconButton
                color="primary"
                disabled={isLoading}
                size="medium"
                onClick={() => history.push(`/tournaments/${id}`)}
              >
                <AddchartOutlinedIcon fontSize="inherit" />
              </IconButton>
              <IconButton color="primary" disabled={isLoading} size="medium" onClick={() => onEdit(id)}>
                <EditOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          );
        },
      },
    ],
    [data, isLoading],
  );

  return <ReactTableComponent columns={columns} data={data} />;
}

export default Table;
