import React from 'react';

import PsychoProfilePanel from '../../psycho-profile/panels/PsychoProfilePanel';
import ChessTemplate from '../../templates/ChessTemplate';

export default function PsychoProfileApp() {
  return (
    <ChessTemplate active={'index'}>
      <PsychoProfilePanel />
    </ChessTemplate>
  );
}
