import {LocalPreloaderWrapper} from '@components/apps/FunctionalTesting/components/CreateEditReport/CreateEditReport';
import {actions} from '@components/apps/FunctionalTesting/constants';
import LocalPreloader from '@components/preloader/LocalPreloader';
import ChessTemplate from '@components/templates/ChessTemplate';
import {getTeamName} from '@helpers/CommonHelper';
import date from '@helpers/date';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectTableEditCreate} from '@selectors/functionalTesting';
import {fetchStageTestsByDate} from '@slices/functionalTesting';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import StagedTestingTableEditCreate from './EditCreateStagedTestingTable';

const CreateEditStagedTestingReport = () => {
  const {isLoading} = useStore(selectTableEditCreate);
  const {teamId, typeAction, reportDate} = useParams();

  const isEdit = actions.actionEdit === typeAction;

  const [teamName, setTeamName] = useState();

  const dispatch = useStoreDispatch();

  useEffect(() => {
    getTeamName(teamId).then((res) => {
      setTeamName(res);
    });
  }, [teamId]);

  useEffect(() => {
    if (isEdit) {
      dispatch(fetchStageTestsByDate(teamId, reportDate));
    }
  }, [teamId]);

  return (
    <ChessTemplate active={'functional_testing'}>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {
            label: 'Функциональное тестирование',
            path: '/functional_testing',
          },
          {
            label: teamName,
            path: `/functional_testing/${teamId}`,
          },
          {
            label:
              actions.actionEdit === typeAction
                ? `Редактирование отчёта за ${date(reportDate).format('DD.MM.YYYY')}`
                : `Новый отчёт. Этапное тестирование`,
          },
        ]}
      >
        {isEdit
          ? `Редактирование отчёта за ${date(reportDate).format('DD.MM.YYYY')}`
          : `Новый отчёт. Этапное тестирование`}
      </PageTitleDefault>
      {isLoading ? (
        <LocalPreloaderWrapper isLoading={isLoading} marginBottom="8px" padding={'0 24px'}>
          <LocalPreloader visible={true} />
        </LocalPreloaderWrapper>
      ) : (
        <StagedTestingTableEditCreate isEdit={isEdit} />
      )}
    </ChessTemplate>
  );
};

export default CreateEditStagedTestingReport;
