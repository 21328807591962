import PlayerPage from '@common/BodyMassIndex/PlayerPage';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {useSeasons} from '@hooks/seasonsHooks';
import {Autocomplete, Box} from '@ui/MUI';
import React, {useState} from 'react';

function BodyMassIndex() {
  const [seasons, setSeasons] = useState([]);
  const {options, isLoading} = useSeasons(false, ({currentSeason}) => setSeasons([currentSeason]));
  return (
    <>
      <Wrapper mb={1} py={3}>
        <Box width={290}>
          <Autocomplete
            multiple
            isLoading={isLoading}
            label="Сезон"
            limitTags={1}
            options={options}
            value={seasons}
            onChange={(_, value) => setSeasons(value)}
          />
        </Box>
      </Wrapper>
      <Wrapper p="0">
        <PlayerPage
          isPersonalFile
          preload={Boolean(seasons?.length)}
          season={seasons?.map((item) => item?.value)?.join(',')}
        />
      </Wrapper>
    </>
  );
}

export default BodyMassIndex;
