export const DIAGRAM_TYPES = {
  GG: 'GG',
  GY: 'GY',
  RY: 'RY',
  RR: 'RR',
  NP: 'NP',
};

export const GREEN = '#4BA849';
export const YELLOW = '#FFC633';
export const RED = '#C05B54';

export const DEFAULT_MATCHES_VALUES = {
  tournamentId: null,
  type: [],
  gameType: [],
  puckDiff: [],
  onlyAgainstTopThree: false,
  games: null,
};

export const GAME_TYPES = {
  pastGames: 'pastGames',
  nextGames: 'nextGames',
};

export const preloderCardsMockData = [...Array(9)].fill({
  tournamentName: '',
  teamName: '',
  rank: null,
  points: null,
  againstTopThree: null,
  potentialPoints: null,
  games: [...Array(4)].fill({
    date: '',
    opponent: '',
    homeScore: null,
    awayScore: null,
    resultDiagram: '',
    statisticId: '',
  }),
});
