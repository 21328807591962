import {Circle} from '@components/apps/MaterialsModule/styles';
import useEvent from '@hooks/useEvent';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {MoreVert} from '@mui/icons-material';
import {Popover} from '@mui/material';
import {updateFormData, updateFormVisability} from '@slices/materials';
import {Box, Chip, Divider, IconButton, Stack, Typography} from '@ui/MUI';
import React, {useState} from 'react';

const anchorOrigin = {vertical: 'middle', horizontal: 'right'};

const Bottom = (props) => {
  const {type, category, canEdit, link, storage} = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isFile = type?.slug === 'file';
  const dispatch = useStoreDispatch();

  const edit = () => {
    dispatch(updateFormData(props));
    dispatch(updateFormVisability(true));
    setAnchorEl(null);
  };

  const openFile = useEvent(() => {
    window.open(link || storage.link, '_blank');
    setAnchorEl(null);
  });

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{
        mt: {
          xs: '24px !important',
          xxs: '14px !important',
        },
      }}
    >
      <Chip icon={<Circle type={category.slug} />} label={category.name} size="small" variant="outlined" />
      {type && (
        <>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            elevation={1}
            open={open}
            onClose={() => setAnchorEl(null)}
          >
            <Box borderRadius={1} py={1} sx={{background: 'white'}} width="124px">
              <Stack spacing={1}>
                <Typography fontWeight={600} pl={2} sx={{cursor: 'pointer'}} variant="subtitle2" onClick={openFile}>
                  {isFile ? 'Скачать' : 'Открыть'}
                </Typography>
                {canEdit && (
                  <>
                    <Divider />
                    <Typography fontWeight={600} pl={2} sx={{cursor: 'pointer'}} variant="subtitle2" onClick={edit}>
                      Редактировать
                    </Typography>
                  </>
                )}
              </Stack>
            </Box>
          </Popover>
          <IconButton
            size="small"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>
        </>
      )}
    </Stack>
  );
};

export default Bottom;
