import React from 'react';
import {useWatch} from 'react-hook-form';

import {useGroup} from '../../../../hooks/filters';
import SelectDefault from '../../../ui/Select';

const GroupSelect = ({onChange, value, watcher}) => {
  const schoolId = useWatch({name: watcher});
  const hook = useGroup({value, connectorValue: schoolId?.value || schoolId});
  return <SelectDefault options={hook.options || []} value={value} onChange={onChange} />;
};

export default GroupSelect;
