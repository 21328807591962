import React from 'react';

function Star() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M12.651 4.65138L8.05414 4.34916L6.32296 0L4.59178 4.34916L0 4.65138L3.52186 7.6418L2.36618 12.1781L6.32296 9.67702L10.2798 12.1781L9.12409 7.6418L12.651 4.65138Z"
          fill="#F5DCB2"
        />
      </g>
    </React.Fragment>
  );
}
export default Star;
