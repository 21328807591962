import ToggleBlock from '@common/ToggleBlock';
import {DIAGRAM_TYPES} from '@components/apps/StatisticsWithMkcUral/constants';
import {MiniDiagram} from '@components/apps/StatisticsWithMkcUral/UralStatistics';
import date from '@helpers/date';
import {shallowEqual} from '@helpers/shallowEqual';
import useDeepEffect from '@hooks/useDeepEffect';
import useQueryString from '@hooks/useQueryString';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectMatchesFilter} from '@selectors/gameStatisticsMkc';
import {
  clearMatches,
  loadMatches,
  loadTournaments,
  setMatchesFilterData,
  setMatchesFilterOpen,
  setMatchesFilterState,
} from '@slices/statisticsMKC';
import {longDash} from '@src/constants';
import {parseFormToGetValue} from '@src/helpers';
import {Autocomplete, Box, Button, Divider, Stack, Typography} from '@ui/MUI';
import CheckboxDefault from '@ui/MUI/Checkbox';
import {grey} from '@ui/MUI/colors';
import DrawerNew from '@ui/MUI/DrawerNew';
import {isEmpty, isEqual} from 'lodash';
import PropTypes from 'prop-types';
import React, {memo, useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

const filters = [
  {
    name: 'type',
    label: 'Тип',
    options: [
      {value: 'home', label: 'Домашняя'},
      {value: 'away', label: 'Гостевая'},
    ],
    isMulti: true,
  },
  {
    name: 'gameType',
    label: 'Итог',
    options: [
      {value: 'win', label: 'Победа'},
      {value: 'lose', label: 'Поражение'},
    ],
    isMulti: true,
  },
  {
    name: 'puckDiff',
    label: 'Разница счета',
    options: [
      {value: 'one', label: '1 шайба'},
      {value: 'two', label: '2 шайбы'},
      {value: 'mtt', label: 'более 3 шайб'},
    ],
    isMulti: true,
  },
];

MatchesFilter.propTypes = {
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
};

function MatchesFilter({onSubmit = () => {}, onClear = () => {}, wrapperProps = {}}) {
  const [qs] = useQueryString();
  const {teamMKCId} = useParams();
  const dispatch = useStoreDispatch();
  const {tournaments, matches, data, state, isOpen} = useStore(selectMatchesFilter, shallowEqual);

  const {statisticIds, ...depsDataWithoutMatches} = state;

  const setIsOpen = useCallback((state) => dispatch(setMatchesFilterOpen(state)));
  const setFormState = useCallback((value) => dispatch(setMatchesFilterState(value)), []);
  useEffect(() => {
    if (!tournaments?.isLoad && !tournaments?.data?.length) {
      dispatch(
        loadTournaments({teamMkcId: teamMKCId, season: qs?.season}, (data) => {
          if (data?.length) setFormState({tournamentId: data.at(-1)});
        }),
      );
    }
  }, [teamMKCId, tournaments?.isLoad]);

  const onMatchesLoad = useCallback(
    (data) => {
      const statisticIds = data?.map((item) => item?.statisticId);
      setFormState({statisticIds: statisticIds});

      !isOpen && dispatch(setMatchesFilterData({...state, statisticIds: data?.length ? statisticIds : []}));
    },
    [setFormState, state],
  );

  const handleLoadMatches = useCallback(
    (onLoad = () => {}, externalLoadCond = false) => {
      const {filter, tournamentId, statisticIds, ...stateData} = state;
      const hasParams = tournamentId?.value && teamMKCId;

      if (hasParams && (!isEqual(data, state) || externalLoadCond)) {
        dispatch(
          loadMatches(
            parseFormToGetValue({...stateData, teamId: teamMKCId, isFinal: tournamentId?.isFinal, tournamentId}),
            onLoad,
          ),
        );
      }
    },
    [depsDataWithoutMatches, teamMKCId, isOpen],
  );

  useDeepEffect(() => {
    handleLoadMatches(onMatchesLoad);
  }, [depsDataWithoutMatches, teamMKCId, isOpen]);

  useDeepEffect(() => {
    !isOpen && handleLoadMatches(() => {}, isEqual(data, state));
  }, [depsDataWithoutMatches, teamMKCId, isOpen]);

  return (
    <>
      <Wrapper mb={1} px={{xxs: 1, sm: 4}} py={{xxs: 1, sm: 3}} {...wrapperProps}>
        <Stack alignItems="center" direction="row" gap={0}>
          <Typography variant="h4">Выбрано матчей:</Typography>
          <Typography
            isLoading={!data?.statisticIds?.length && matches?.isLoading && !isOpen}
            preloaderWidthBetween={[15, 25]}
            variant="h4"
          >
            {data?.statisticIds?.length || 0}
          </Typography>
        </Stack>

        <Button color="primary" variant="contained" onClick={() => setIsOpen(true)}>
          выбор матчей
        </Button>
      </Wrapper>
      <DrawerNew
        buttons={
          <Stack alignItems="center" direction="row" flexWrap={{xxs: 'wrap', xs: 'nowrap'}} gap={3}>
            <Typography color={grey['54']} variant="h4">
              Выбрано:
              <Typography component="span" variant="h4">
                {statisticIds?.length || 0}
              </Typography>
            </Typography>
            <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
              <Button
                color="primary"
                disabled={statisticIds?.length < 1}
                variant="contained"
                onClick={() => {
                  const {filter, statisticIds, ...formData} = state;
                  dispatch(setMatchesFilterData({statisticIds, ...formData}));
                  onSubmit(
                    parseFormToGetValue({
                      filter,
                      statisticIds: statisticIds
                        ?.map((item) => item?.value && item?.statisticId)
                        ?.filter((item) => item),
                    }),
                  );
                  setIsOpen(false);
                }}
              >
                применить
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  dispatch(clearMatches());
                  onClear();
                  setIsOpen(false);
                  dispatch(setMatchesFilterData({}));
                  dispatch(setMatchesFilterState({}));
                }}
              >
                сбросить
              </Button>
            </Stack>
          </Stack>
        }
        open={isOpen}
        title="выбор матчей"
        width="800px"
        onClose={(state) => {
          setFormState(isEmpty(data) ? {tournamentId: tournaments.at(-1)} : data);
          setIsOpen(state);
        }}
      >
        <ToggleBlock hasToggleBreakpoint="sm">
          <Stack direction="column" gap={3}>
            <Box maxWidth="750px" width="100%">
              <Autocomplete
                isRequired
                disabled={tournaments?.isLoading || (tournaments?.data?.length === 1 && state?.tournamentId)}
                isLoading={tournaments?.isLoading}
                isOptionEqualToValue={(option, value) =>
                  option.tournamentId === value.tournamentId && option?.isFinal === value?.isFinal
                }
                label="Турнир"
                limitTags={1}
                multiple={false}
                name="tournamentId"
                options={tournaments?.data || []}
                value={state?.tournamentId}
                onChange={(_, value) => {
                  !value && dispatch(clearMatches());
                  setFormState({tournamentId: value});
                }}
              />
            </Box>
            <Stack alignItems={{sm: 'center'}} direction={{sm: 'row'}} gap={3} justifyContent="space-between">
              {filters.map(({name, label, options, isMulti}, index) => (
                <Box
                  key={index}
                  maxWidth={(theme) => ({
                    sm: `calc(100% / 4 - ${theme.spacing(3)})`,
                  })}
                  width="100%"
                >
                  <Autocomplete
                    label={label}
                    limitTags={1}
                    multiple={isMulti}
                    options={options}
                    value={state?.[name]}
                    onChange={(_, value) => setFormState({[name]: value})}
                  />
                </Box>
              ))}
              <Box sx={{whiteSpace: 'nowrap'}} width="max-content">
                <CheckboxDefault
                  checked={state?.onlyAgainstTopThree}
                  id="checkBoxTop"
                  label="Только против топ-3"
                  onChange={(e, value) => setFormState({onlyAgainstTopThree: value})}
                />
              </Box>
            </Stack>
          </Stack>
        </ToggleBlock>
        <Divider sx={{mt: 3}} />
        {(matches?.isLoad && matches?.data?.length > 0) || matches?.isLoading ? (
          <>
            <CheckboxDefault
              checked={matches?.data?.length === state?.statisticIds?.length}
              disabeld={matches?.isLoading}
              label="Выбрать все"
              value={matches?.data?.length === state?.statisticIds?.length}
              onChange={(e) => {
                setFormState({
                  statisticIds:
                    e.currentTarget.value === 'false' ? matches?.data?.map((item) => item?.statisticId) : [],
                });
              }}
            />
            {matches.data?.map(
              ({statisticId, score, resultIndicator, teams, homeTeamName, awayTeamName, ...item}, index) => (
                <CheckboxDefault
                  checked={state?.statisticIds?.includes(statisticId)}
                  disabled={matches?.isLoading}
                  id={statisticId}
                  key={statisticId + index}
                  label={
                    <Stack
                      alignItems={{xxs: 'unset', sm: 'center'}}
                      direction={{xxs: 'column', sm: 'row'}}
                      divider={<Divider flexItem orientation="vertical" variant="middle" />}
                      flexShrink={0}
                      spacing={{xxs: '', sm: 1}}
                    >
                      <Stack
                        alignItems="center"
                        direction="row"
                        divider={<Divider flexItem orientation="vertical" variant="middle" />}
                        flex={1.3}
                        spacing={1}
                      >
                        <Box flex={{xxs: '', sm: 1}}>
                          <Typography
                            isLoading={matches?.isLoading}
                            preloaderProps={{width: '70px'}}
                            sx={{display: 'table-cell'}}
                            variant="body1"
                          >
                            {date(item?.date).format('DD.MM.YYYY')}
                          </Typography>
                        </Box>
                        <Box flex={{xxs: '', sm: 1}}>
                          <Stack alignItems="center" direction="row" justifyContent="center">
                            <MiniDiagram mr={0} type={DIAGRAM_TYPES?.[resultIndicator]} />
                            <Typography isLoading={matches?.isLoading} preloaderProps={{width: '50px'}}>
                              {score?.[0] + ':' + score?.[1]}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>

                      <Box flex={3} width={{xxs: '100%', sm: 'auto'}}>
                        <Typography isLoading={matches?.isLoading} preloaderProps={{width: '100%'}} variant="subtitle2">
                          {`${homeTeamName} ${longDash} ${awayTeamName}`}
                        </Typography>
                      </Box>
                    </Stack>
                  }
                  labelProps={{sx: {width: '100%', mb: 1}}}
                  sx={{mb: 1}}
                  value={state?.statisticIds?.includes(statisticId)}
                  onChange={(e) => {
                    const data = [...(state?.statisticIds?.length ? state.statisticIds : [])];
                    setFormState({
                      statisticIds:
                        e.currentTarget.value === 'true'
                          ? data?.filter((item) => item !== statisticId)
                          : [...data, statisticId],
                    });
                  }}
                />
              ),
            )}
          </>
        ) : (
          <Box height={300} mt={1} position="relative" width="100%">
            <Typography variant="body2">Выберите или измените фильтры, чтобы отобразить матчи</Typography>
          </Box>
        )}
      </DrawerNew>
    </>
  );
}

export default memo(MatchesFilter);

const Wrapper = styled(Box)`
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({theme}) => theme.spacing(1)};
`;
