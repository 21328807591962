import * as types from '../ActionTypes.js';

const initialState = {
  items: [],
  loading: false,
  error: {},
  tableEditCreate: {
    teamName: '',
    items: [],
    loading: false,
    error: null,
  },
};

const BMIReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_BMI_DATA:
      return {
        ...state,
        loading: false,
        load: true,
        error: undefined,
        items: action.data,
      };
    case types.BMI_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case types.BMI_LOADING_END:
      return {
        ...state,
        loading: false,
        load: true,
      };
    case types.BMI_LOADING_ERROR: {
      return {
        ...state,
        loading: false,
        load: true,
      };
    }
    case types.GET_BMI_DATA_CREATE_EDIT: {
      return {
        ...state,
        tableEditCreate: {
          teamName: action.data.teamName,
          items: action.data.players,
          loading: false,
          error: null,
        },
      };
    }
    case types.BMI_DATA_CREATE_EDIT_LOADING_START: {
      return {
        ...state,
        tableEditCreate: {
          teamName: '',
          items: [],
          error: false,
          loading: true,
        },
      };
    }
    case types.BMI_DATA_CREATE_EDIT_LOADING_END: {
      return {
        ...state,
        tableEditCreate: {
          loading: false,
        },
      };
    }
    case types.BMI_DATA_CREATE_EDIT_LOADING_ERROR: {
      return {
        ...state,
        tableEditCreate: {
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default BMIReducer;
