import PlayersTable from '@common/Statistics/PlayerStatistics/VideoModal/PlayersTable';
import VideoPlayer from '@common/Statistics/VideoPlayer';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import {selectGameActionDetails, selectVideoModal} from '@selectors/gameStatistics';
import {loadGameActionDetails, setVideoModal} from '@slices/statistics';
import {longDash} from '@src/constants';
import {Button, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Drawer from '@ui/MUI/DrawerNew';
import {array} from 'prop-types';
import React, {useCallback, useEffect} from 'react';

GoalVideoModal.propTypes = {
  goals: array.isRequired,
};

function GoalVideoModal({goals = []}) {
  const dispatch = useStoreDispatch();
  const {isOpen, data} = useStore(selectVideoModal);
  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [data?.id]});
  const shotDetails = useStore(selectGameActionDetails);
  const playersOnIce = shotDetails?.data?.playersOnIce || [];
  const shotDetailsLoading = shotDetails?.isLoading;
  const {index} = data;
  const mainInfo = useDeepMemo(() => {
    return [
      {label: 'Игрок: ', value: data?.playerName === '' ? 'Соперник' : data?.playerName},
      {label: 'Ассистенты: ', value: data?.assists?.map(({playerName}) => playerName)?.join(', ') || null},
    ];
  }, [data]);

  const {infoItems} = useDeepMemo(() => {
    const type = shotDetails?.data?.type;
    const infoItems = [
      {
        label: 'Тип броска:',
        value: type?.name ? type.name + (type?.engName ? ` (${type.engName})` : '') : '',
      },
    ];
    return {infoItems};
  }, [shotDetails]);

  const onClose = useCallback(() => {
    dispatch(setVideoModal({isOpen: false, data: {}}));
  }, []);
  const setData = useCallback((data) => {
    dispatch(setVideoModal({isOpen: true, data}));
  }, []);

  useEffect(() => {
    data?.id && dispatch(loadGameActionDetails(data?.id, {cancelToken: newCancelToken(), isCancel}));
  }, [data?.id]);
  return (
    <Drawer
      buttons={
        <Stack
          direction={{xxs: 'column', xs: 'row'}}
          flexWrap="wrap"
          gap={3}
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" gap={3} width={{xxs: '100%', xs: 'unset'}}>
            <Button
              color="primary"
              disabled={index === 0}
              startIcon={<ChevronLeftOutlinedIcon />}
              sx={{width: {xxs: '50%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => setData(goals[index - 1])}
            >
              назад
            </Button>
            <Button
              color="primary"
              disabled={index + 1 === goals?.length}
              endIcon={<ChevronLeftOutlinedIcon sx={{transform: 'rotate(180deg)'}} />}
              sx={{width: {xxs: '50%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => setData(goals[index + 1])}
            >
              вперед
            </Button>
          </Stack>
          <Button color="secondary" variant="contained" onClick={onClose}>
            Закрыть
          </Button>
        </Stack>
      }
      open={isOpen}
      title={`Гол. ${data?.time ? 'Время ' + data?.time + '′' : ''}`}
      onClose={onClose}
    >
      <Stack direction="column" gap={1} mb={4} mt={6}>
        {mainInfo.map(({label, value}, index) => (
          <Stack direction="row" gap={0} key={index}>
            <Typography variant="body1">{label}</Typography>
            <Typography variant="subtitle1">{value || longDash}</Typography>
          </Stack>
        ))}
      </Stack>
      <VideoPlayer data={data} />
      <Typography mb={2} mt={6} variant="h4">
        о броске
      </Typography>
      <Stack direction="column" gap={2} p={{xxs: 3, sm: 4}} sx={{backgroundColor: grey['4'], borderRadius: '6px'}}>
        {infoItems.map(({label, value}, index) => (
          <Stack direction="row" flexWrap="wrap" gap={0} key={index}>
            <Typography variant="body1">{label}</Typography>
            <Typography isLoading={shotDetailsLoading} preloaderWidthBetween={[100, 120]} variant="subtitle1">
              {value || longDash}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Typography mb={2} mt={4} variant="h4">
        Игроки на льду
      </Typography>
      <PlayersTable isLoading={shotDetailsLoading} playersOnIce={playersOnIce} />
    </Drawer>
  );
}

export default GoalVideoModal;
