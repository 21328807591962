import DynamicsStatuses from '@components/apps/PlayerPersonalFile/components/FunctionalTesting/components/DynamicsStatuses';
import InjuryHistory from '@components/apps/PlayerPersonalFile/components/FunctionalTesting/components/InjuryHistory';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {loadInjuryHistory} from '@slices/playerPersonalFile';
import {Stack} from '@ui/MUI';
import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

function FunctionalTesting() {
  const {id} = useParams();
  const dispatch = useStoreDispatch();
  useEffect(() => {
    id && dispatch(loadInjuryHistory(id));
    return () => {};
  }, [id]);
  return (
    <Stack direction="column" spacing={1}>
      <DynamicsStatuses />
      <InjuryHistory />
    </Stack>
  );
}

export default FunctionalTesting;
