import {playOffStages} from '@src/constants/tournament';

export const gameMock = {
  date: '',
  team1: null,
  team2: null,
  score1: '',
  score2: '',
  isOt: false,
  isBull: false,
};

export const playOffStagesoptions = Object.entries(playOffStages).map(([value, label]) => ({value, label}));
