import {getPhysicalTestingOverall, getTestsForEditCreate} from '@api/newPhysicalTestingAPI';
import {fetchOverallMapper} from '@components/apps/PhysicalTesting/helpers/mappers/fetchOverallMapper';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  overallInfo: {
    items: [],
    isLoading: false,
    isLoad: false,
    error: '',
  },
};

const physicalTestingOverall = createSlice({
  name: 'physicalTestingPlayerOverall',
  initialState,
  reducers: {
    physicalTestingOverallLoading: ({overallInfo}) => {
      overallInfo.isLoading = true;
    },
    physicalTestingOverallLoad: ({overallInfo}, action) => {
      overallInfo.isLoading = false;
      overallInfo.isLoad = true;
      overallInfo.items = action.payload;
    },
    physicalTestingOverallError: ({overallInfo}) => {
      overallInfo.isLoading = false;
      overallInfo.isLoad = true;
      overallInfo.error = 'Ошибка при загрузке данных';
    },
    physicalTestingOverallClear: ({overallInfo}) => {
      overallInfo.items = [];
    },
    physicalTestingExerciseslLoad: ({overallInfo}, action) => {
      overallInfo.isLoading = false;
      overallInfo.isLoad = true;
      overallInfo.exercises = action.payload;
    },
  },
});

const {actions, reducer} = physicalTestingOverall;

const {
  physicalTestingOverallLoading,
  physicalTestingOverallLoad,
  physicalTestingExerciseslLoad,
  physicalTestingOverallClear,
} = actions;

export {reducer as physicalTestingOverallReducer};

export const fetchOverallData = (data) => async (dispatch) => {
  dispatch(physicalTestingOverallLoading());
  dispatch(physicalTestingOverallClear());

  const [{data: physicalTestingData}, {data: exercises}] = await Promise.all([
    getPhysicalTestingOverall(data),
    getTestsForEditCreate({type: data?.typeOfTests, teamId: data?.groupId}),
  ]);
  const isAdditionParamSeparate = false;
  const comparedDataAdaptedForTable = fetchOverallMapper(physicalTestingData, isAdditionParamSeparate);
  dispatch(physicalTestingExerciseslLoad(exercises));
  dispatch(physicalTestingOverallLoad(comparedDataAdaptedForTable));
};

export const clearPhysicalOverallState = () => (dispatch) => dispatch(physicalTestingOverallClear());
