import {userRoles} from '@src/constants';
import DatePickerDefault from '@ui/MUI/DatePicker';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import MedicalProfileAPI from '../../../api/MedicalProfileAPI';
import {injuryPlaces, injuryTypes, NOTIFICATION_TYPES} from '../../../constants/config';
import CommonHelper from '../../../helpers/CommonHelper';
import StateHelper from '../../../helpers/StateHelper';
import {handleChangeForm} from '../../../redux/actions/MedicalProfileActions';
import {ReactComponentNotification} from '../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import SelectDefault from '../../ui/Select';
import TextareaDefault from '../../ui/Textarea';
import {TYPES_OPTIONS} from '../constants';

export default function GeneralInfo(props) {
  let {errors = {}, control = {}, setValue} = props;

  const {userRole, allSchools, allGroups, allPlayers, medicalProfileForm, generalInformation} = useSelector((state) => {
    let currentUser = StateHelper.getCurrentUser(state);
    let userRole = currentUser?.userRole;
    let allSchools = CommonHelper.getSortedArray(state.schools.schoolsMap.toArray());
    const allGroups = state.groups.groupsMap.toArray();
    const allPlayers = state.users.usersMap.toArray().filter((u) => u.userRole === 'student');

    let medicalProfileForm =
      state.medicalProfile.medicalProfileForm && Object.keys(state.medicalProfile.medicalProfileForm).length
        ? state.medicalProfile.medicalProfileForm
        : {
            training: [],
            consultations: [],
            nutrition: [],
          };
    const generalInformation = medicalProfileForm ? medicalProfileForm : {};
    return {
      userRole: userRole,
      loading: state.users.loading,
      usersMap: state.users.usersMap,
      allSchools: allSchools,
      allGroups: allGroups,
      allPlayers: allPlayers,
      medicalProfileForm,
      generalInformation,
      state,
    };
  }, shallowEqual);

  const selectedSchool = () => {
    if (medicalProfileForm.school) {
      const school = allSchools.find((school) => school.id === medicalProfileForm.school);
      const schoolOption = CommonHelper.getDropDownItemsByNamedList([school]);
      return schoolOption;
    } else {
      return '';
    }
  };
  const selectedGroup = () => {
    if (medicalProfileForm.group) {
      const group = allGroups.find((group) => group.id === medicalProfileForm.group);
      const groupOption = CommonHelper.getDropDownItemsByNamedList([group]);

      return groupOption[0];
    } else {
      return '';
    }
  };
  const selectedPlayer = () => {
    if (medicalProfileForm.playerId) {
      const player = allPlayers.find((player) => player.id === medicalProfileForm.playerId);
      const playerOption = {
        label: player?.lastName + ' ' + player?.firstName,
        value: player?.id,
      };

      return playerOption;
    } else {
      return '';
    }
  };

  const selectedDate = () => {
    const newDateUT = new Date().getTime();
    if (medicalProfileForm.date) {
      return new Date(medicalProfileForm.date);
    } else {
      handleGeneralInfoChange('date', newDateUT);
      return new Date();
    }
  };

  const selectedType = () => {
    if (medicalProfileForm.type) {
      return TYPES_OPTIONS.find((type) => type.value === medicalProfileForm.type);
    } else {
      return '';
    }
  };

  const selectedStage = () => {
    if (medicalProfileForm.status) {
      const stage = medicalProfileStages.find((stage) => stage.value === medicalProfileForm.status);
      return stage;
    } else {
      return '';
    }
  };

  const selectedInjuryType = () => {
    if (medicalProfileForm.injuryType) {
      const injuryType = injuryTypes.find((ijt) => ijt.value === medicalProfileForm.injuryType);
      return injuryType;
    }
    return '';
  };

  const selectedInjuryPlace = () => {
    if (medicalProfileForm.injuryPlace) {
      const injuryPlace = injuryPlaces.find((ijp) => ijp.value === medicalProfileForm.injuryPlace);
      return injuryPlace;
    }
    return '';
  };

  const selectedRecoveryDate = () => {
    const newDateUT = new Date().getTime();
    if (medicalProfileForm.recoveryDate) {
      return new Date(medicalProfileForm.recoveryDate);
    } else {
      handleGeneralInfoChange('recoveryDate', newDateUT);
      return new Date();
    }
  };

  let [description, setDescription] = useState(medicalProfileForm.description ? medicalProfileForm.description : '');

  const dispatch = useDispatch();
  const groupsOptions = () => {
    let groups = [];
    allGroups.map((group) => {
      let school = medicalProfileForm.school;
      if (!school) return;
      if (group.schoolId === school) {
        groups.push(group);
      }
    });

    const options = groups.map((group) => {
      return {
        value: group.id,
        label: group.name,
      };
    });

    options.sort((a, b) => {
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      return 0;
    });

    return options;
  };

  let [medicalProfileStages, setMedicalProfileStages] = useState([]);

  const [studentsOptions, setStudentsOptions] = useState([]);

  useEffect(() => {
    if (medicalProfileForm?.group) {
      StateHelper.getStudentsByTeamId(medicalProfileForm?.group).then((result) => {
        const options = result
          .map((player) => {
            return {
              value: player.id,
              label: player.name,
            };
          })
          .sort((a, b) => {
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return 1;
            }
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
              return -1;
            }
            return 0;
          });
        setStudentsOptions(options);
      });
    }
  }, [medicalProfileForm?.group]);

  useEffect(() => {
    MedicalProfileAPI.getMedicalProfileStages()
      .then((data) => {
        setMedicalProfileStages(data.data);
      })
      .catch(function () {
        ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при загрузке стадий');
      });
  }, []);

  useEffect(() => {
    setValue('school', generalInformation.school);
    setValue('group', generalInformation.group);
    setValue('description', generalInformation.description);
    setValue('playerId', generalInformation.playerId);
    setValue('diseaseName', generalInformation.diseaseName);
    setValue('type', generalInformation.type);
    setValue('status', generalInformation.status);
  }, [generalInformation]);

  const handleGeneralInfoChange = (name, value) => {
    const currentGeneralInfo = medicalProfileForm ? medicalProfileForm : {};

    dispatch(handleChangeForm({...currentGeneralInfo, [name]: value}));
  };
  const handleMedicalProfileChange = (name, value) => {
    const currentMedicalProfileForm = medicalProfileForm ? {...medicalProfileForm} : {};

    dispatch(handleChangeForm({...currentMedicalProfileForm, [name]: value}));
  };
  const handleMedicalProfileFormChange = (name, value) => {
    const currentMedicalProfileForm = medicalProfileForm ? medicalProfileForm : {};
    dispatch(handleChangeForm({...currentMedicalProfileForm, [name]: value}));
  };

  const isInjury = medicalProfileForm?.type === 'injury';

  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (userRole === 'doctor' && !props.addCard) {
      setCanEdit(false);
    } else {
      setCanEdit(true);
    }
  }, [userRole]);

  return (
    <Wrapper>
      <Title>Информация</Title>
      <FieldsContainer>
        <Fields>
          <FieldsWrapper>
            <Field>
              <Label>Школа</Label>
              <Controller
                control={control}
                name={'school'}
                render={() => {
                  return (
                    <SelectDefault
                      errorText="Обязательное поле для заполнения"
                      isDisabled={!canEdit}
                      isInvalid={errors.school}
                      isMulti={false}
                      options={CommonHelper.getDropDownItemsByNamedList(allSchools)}
                      placeholder={'Выберите школу'}
                      value={selectedSchool()}
                      onChange={(school) => {
                        handleMedicalProfileFormChange('group', null);
                        handleMedicalProfileFormChange('playerId', null);
                        handleGeneralInfoChange('school', school.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
            <Field>
              <Label>Команда</Label>
              <Controller
                control={control}
                name={'group'}
                render={() => {
                  return (
                    <SelectDefault
                      errorText="Обязательное поле для заполнения"
                      isDisabled={!canEdit}
                      isInvalid={errors.group}
                      isMulti={false}
                      options={groupsOptions()}
                      placeholder={'Выберите команду'}
                      value={selectedGroup()}
                      onChange={(a) => {
                        handleMedicalProfileFormChange('playerId', null);
                        handleGeneralInfoChange('group', a.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
            <Field>
              <Label>Игрок</Label>
              <Controller
                control={control}
                name={'playerId'}
                render={() => {
                  return (
                    <SelectDefault
                      errorText="Обязательное поле для заполнения"
                      isDisabled={!canEdit}
                      isInvalid={errors.playerId}
                      isMulti={false}
                      options={studentsOptions}
                      placeholder={'Выберите игрока'}
                      value={selectedPlayer()}
                      onChange={(a) => {
                        handleMedicalProfileFormChange('playerId', a.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
            <Field>
              <Label>Дата</Label>
              <DatePickerDefault
                disabled={userRole === userRoles.doctor ? false : true}
                inputFormat="DD.MM.yyyy"
                maxDate={moment()}
                value={selectedDate()}
                onChange={(startDate) => {
                  const startDate_UT = new Date(startDate).getTime();
                  handleGeneralInfoChange('date', startDate_UT);
                }}
              />
            </Field>
          </FieldsWrapper>
        </Fields>
        <Fields>
          <FieldsWrapper>
            <Field>
              <Label>Тип</Label>
              <Controller
                control={control}
                name={'type'}
                render={() => {
                  return (
                    <SelectDefault
                      errorText="Обязательное поле для заполнения"
                      isDisabled={!canEdit}
                      isInvalid={errors.type}
                      isMulti={false}
                      options={TYPES_OPTIONS}
                      placeholder={'Выберите тип'}
                      value={selectedType()}
                      onChange={(type) => {
                        handleGeneralInfoChange('type', type.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
            <Field>
              <Label>Стадия</Label>
              <Controller
                control={control}
                name={'status'}
                render={() => {
                  return (
                    <SelectDefault
                      errorText="Обязательное поле для заполнения"
                      isInvalid={errors.status}
                      isMulti={false}
                      options={medicalProfileStages}
                      placeholder={'Выберите стадию'}
                      value={selectedStage()}
                      onChange={(stage) => {
                        handleMedicalProfileChange('status', stage.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
            {isInjury && (
              <>
                <Field>
                  <Label>Вид травмы</Label>
                  <Controller
                    control={control}
                    name={'injuryType'}
                    render={() => {
                      return (
                        <SelectDefault
                          errorText="Обязательное поле для заполнения"
                          isDisabled={!canEdit}
                          isInvalid={errors.status}
                          options={injuryTypes}
                          placeholder={'Выберите вид травмы'}
                          value={selectedInjuryType()}
                          onChange={(stage) => {
                            handleMedicalProfileChange('injuryType', stage.value);
                          }}
                        />
                      );
                    }}
                    rules={{required: false}}
                    shouldUnregister={false}
                  />
                </Field>
                <Field>
                  <Label>Где игрок получил травму</Label>
                  <Controller
                    control={control}
                    name={'injuryPlace'}
                    render={() => {
                      return (
                        <SelectDefault
                          errorText="Обязательное поле для заполнения"
                          isDisabled={!canEdit}
                          isInvalid={errors.status}
                          isMulti={false}
                          options={injuryPlaces}
                          placeholder={'Выберите место, где игрок получил травму'}
                          value={selectedInjuryPlace()}
                          onChange={(stage) => {
                            handleMedicalProfileChange('injuryPlace', stage.value);
                          }}
                        />
                      );
                    }}
                    rules={{required: true}}
                    shouldUnregister={false}
                  />
                </Field>
              </>
            )}
            {selectedStage() && selectedStage().value === 'recovered' ? (
              <Field>
                <Label>Примерная дата восстановления</Label>
                <DatePickerDefault
                  disabled={userRole === userRoles.doctor ? false : true}
                  inputFormat="DD.MM.yyyy"
                  value={selectedRecoveryDate()}
                  variant="outlined"
                  onChange={(recoveryDate) => {
                    const recoveryDate_UT = new Date(recoveryDate).getTime();
                    handleGeneralInfoChange('recoveryDate', recoveryDate_UT);
                  }}
                />
              </Field>
            ) : null}
            <Field>
              <Label>Дополнительное описание</Label>
              <Controller
                control={control}
                name={'description'}
                render={() => {
                  return (
                    <TextareaDefault
                      disabled={!canEdit}
                      errorText="Обязательное поле для заполнения"
                      isInvalid={errors.description}
                      placeholder="Травма получена в результате удара об борт во время силового приема."
                      value={description}
                      onChange={(a) => {
                        setDescription(a.target.value);
                        handleGeneralInfoChange('description', a.target.value);
                      }}
                    />
                  );
                }}
                rules={{required: true}}
                shouldUnregister={false}
              />
            </Field>
          </FieldsWrapper>
        </Fields>
      </FieldsContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  @media screen and (max-width: 767px) {
    padding-bottom: 120px;
  }
`;
const Title = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  font: 600 normal 16px/1 'Proxima Nova';
  color: #990011;
  letter-spacing: 0.02em;
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Fields = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  :first-of-type {
    padding-right: 60px;
    padding-left: 0;
    @media screen and (max-width: 991px) {
      padding-right: 30px;
      @media screen and (max-width: 768px) {
        padding-right: 0;
      }
    }
  }

  :last-of-type {
    padding-right: 0;
    padding-left: 60px;
    @media screen and (max-width: 991px) {
      padding-left: 30px;
      @media screen and (max-width: 768px) {
        padding-left: 0;
      }
    }
  }
`;
const FieldsWrapper = styled.div``;
const Field = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;

  input {
    border: ${(props) => (props.isInvalid ? '1px solid #FF0000' : '1px solid #f3f3f3')};

    ::placeholder {
      font: normal normal 15px/25px 'Proxima Nova';
      color: #2c2c2c;
    }
  }

  textarea {
    resize: none;
    margin-top: 12px;
    margin-bottom: 0;
    min-height: 70px;
    border-radius: 5px;
    font: normal normal 15px/25px 'Proxima Nova';
    color: #2c2c2c;

    ::placeholder {
      font: normal normal 15px/25px 'Proxima Nova';
      color: #747474;
    }

    @media screen and (max-width: 360px) {
      padding-right: 8px;
    }
  }

  .SelectContainer {
    .Select {
      & + p {
        margin-top: 4px;
        margin-bottom: 0;
      }
    }
  }
`;
const Label = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
`;
