import React from 'react';

const UnFillInfo = () => {
  return (
    <React.Fragment>
      <path
        d="M7.99998 1.33325C6.68144 1.33325 5.39251 1.72425 4.29618 2.45679C3.19985 3.18933 2.34537 4.23052 1.84079 5.4487C1.3362 6.66687 1.20418 8.00731 1.46141 9.30052C1.71865 10.5937 2.35359 11.7816 3.28594 12.714C4.21829 13.6463 5.40617 14.2813 6.69938 14.5385C7.99259 14.7957 9.33303 14.6637 10.5512 14.1591C11.7694 13.6545 12.8106 12.8 13.5431 11.7037C14.2757 10.6074 14.6666 9.31846 14.6666 7.99992C14.6647 6.23241 13.9617 4.53786 12.7119 3.28804C11.462 2.03823 9.76749 1.33522 7.99998 1.33325V1.33325ZM7.99998 13.3333C6.94515 13.3333 5.914 13.0205 5.03694 12.4344C4.15988 11.8484 3.47629 11.0154 3.07263 10.0409C2.66896 9.06636 2.56334 7.994 2.76913 6.95944C2.97492 5.92487 3.48287 4.97456 4.22875 4.22868C4.97463 3.4828 5.92494 2.97485 6.9595 2.76906C7.99407 2.56328 9.06642 2.66889 10.041 3.07256C11.0155 3.47623 11.8485 4.15982 12.4345 5.03688C13.0205 5.91394 13.3333 6.94509 13.3333 7.99992C13.3317 9.41391 12.7693 10.7695 11.7694 11.7694C10.7696 12.7692 9.41397 13.3316 7.99998 13.3333V13.3333ZM7.99998 7.66659C7.82317 7.66659 7.6536 7.73682 7.52858 7.86185C7.40355 7.98687 7.33332 8.15644 7.33332 8.33325V10.3333C7.33332 10.5101 7.40355 10.6796 7.52858 10.8047C7.6536 10.9297 7.82317 10.9999 7.99998 10.9999C8.17679 10.9999 8.34636 10.9297 8.47139 10.8047C8.59641 10.6796 8.66665 10.5101 8.66665 10.3333V8.33325C8.66665 8.15644 8.59641 7.98687 8.47139 7.86185C8.34636 7.73682 8.17679 7.66659 7.99998 7.66659ZM7.99998 4.99992C7.83516 4.99992 7.67405 5.04879 7.53701 5.14036C7.39997 5.23193 7.29316 5.36208 7.23008 5.51435C7.16701 5.66662 7.15051 5.83418 7.18266 5.99583C7.21482 6.15748 7.29418 6.30596 7.41073 6.42251C7.52727 6.53905 7.67576 6.61842 7.83741 6.65057C7.99906 6.68273 8.16661 6.66622 8.31889 6.60315C8.47116 6.54008 8.60131 6.43327 8.69287 6.29623C8.78444 6.15919 8.83332 5.99807 8.83332 5.83325C8.83332 5.61224 8.74552 5.40028 8.58924 5.244C8.43296 5.08772 8.221 4.99992 7.99998 4.99992V4.99992Z"
        fill="#C4C4C4"
      />
    </React.Fragment>
  );
};

export default UnFillInfo;
