import {getPhysicalPlayerProgress} from '@api/newPhysicalTestingAPI';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  playerProgressInfo: {
    items: {},
    isLoading: false,
    isLoad: false,
    error: '',
  },
};

const physicalTestingPlayerProgress = createSlice({
  name: 'physicalTestingPlayerProgress',
  initialState,
  reducers: {
    playerProgressLoading: ({playerProgressInfo}) => {
      playerProgressInfo.isLoading = true;
    },
    playerProgressLoad: ({playerProgressInfo}, action) => {
      playerProgressInfo.isLoading = false;
      playerProgressInfo.isLoad = true;
      playerProgressInfo.items = action.payload.data.data;
    },
    playerProgressError: ({playerProgressInfo}) => {
      playerProgressInfo.isLoading = false;
      playerProgressInfo.isLoad = true;
      playerProgressInfo.error = 'Ошибка при загрузке данных';
    },
    playerProgressClear: ({playerProgressInfo}) => {
      playerProgressInfo.items = {};
    },
  },
});

const {actions, reducer} = physicalTestingPlayerProgress;

const {playerProgressLoading, playerProgressLoad, playerProgressError, playerProgressClear} = actions;

export {reducer as physicalTestingPlayerProgressReducer};

export function fetchPlayerProgressData(data) {
  return (dispatch) => {
    dispatch(playerProgressLoading());
    dispatch(playerProgressClear());
    return getPhysicalPlayerProgress(data)
      .then((data) => dispatch(playerProgressLoad({data})))
      .catch((error) => {
        dispatch(playerProgressError({error}));
      });
  };
}

export const clearPhysicalPlayerState = () => (dispatch) => dispatch(playerProgressClear());
