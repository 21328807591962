import LinesAPI from '@api/LinesAPI';
import {NearestGame} from '@components/teachers/panels/TeacherHockeyLinesPanel/components/LinesMobile/LinesMobile';
import LinesMobileTableComponent from '@components/teachers/panels/TeacherHockeyLinesPanel/components/LinesMobile/LinesMobileTable';
import TeacherHockeyLinesPlayerCard from '@components/teachers/panels/TeacherHockeyLinesPanel/components/TeacherHockeyLinesPlayerCard';
import TeacherHockeyLinesPlayerEmptyCard from '@components/teachers/panels/TeacherHockeyLinesPanel/components/TeacherHockeyLinesPlayerEmptyCard';
import {useMediaQuery} from '@mui/material';
import TypographyDefault from '@ui/MUI/Typography';
import PageTitleDefault from '@ui/PageTitleDefault';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {defensemenPositions, wingerPositions} from '../../../constants';
import {cleanLinesForm, getLinesForm} from '../../../redux/actions/LinesActions';
import {loadStudentById} from '../../../redux/actions/UsersActions';
import LocalPreloader from '../../preloader/LocalPreloader';
import {
  Container,
  HockeyLineContent,
  HockeyLineContentWithoutStatus,
  HockeyLineContentWithoutText,
  HockeyLineContentWithoutWrapper,
  HockeyLineContentWrapper,
  HockeyLineHeader,
  HockeyLineWrapper,
  LinesBlockHead,
  LinesBlockHeadColumn,
  LinesBlockHeadColumns,
  LinesBlockHeadFlexContainer,
  LinesBlockHeadTitle,
  LinesTab,
  LinesTabLine,
  LinesTabLineColumnDefensemen,
  LinesTabLineColumnGoaltenders,
  LinesTabLineColumnGoaltendersHead,
  LinesTabLineColumnGoaltendersHeadColumn,
  LinesTabLineColumnWingers,
  LinesTabLineContainer,
  LinesTabLineContainerGoaltenders,
  LinesTabLineNumber,
  TeacherHockeyLinesPlayerCards,
} from './StudentLines';

export default function StudentLines() {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const loading = useSelector((state) => state.lines.loading);
  const linesForm = useSelector((state) => state.lines.linesForm);
  const currentUserId = useSelector((state) => state.users.currentUserId);
  const teamName = useSelector((state) => state.users.student?.team?.name || state.users.student?.group?.name);
  const teamId = useSelector((state) => {
    const student = state.users.student;
    const team = student ? student.team : {};
    return team ? team.objectId : undefined;
  });

  const [nearestGame, setNearestGame] = useState();

  const getNearGame = async (teamId) => {
    const nearestGame = await LinesAPI.getNearestGame(teamId);
    setNearestGame(nearestGame.data);
  };

  useEffect(() => {
    if (teamId) {
      getNearGame(teamId);
    }
  }, [teamId]);

  useEffect(() => {
    if (currentUserId) {
      dispatch(loadStudentById(currentUserId));
    }
  }, [currentUserId]);

  const getLinesData = () => {
    if (teamId) {
      dispatch(getLinesForm({team: teamId})).catch(() => {
        dispatch(cleanLinesForm());
      });
    } else {
      dispatch(cleanLinesForm());
    }
  };

  useEffect(() => {
    getLinesData();
  }, [teamId]);

  useEffect(() => {
    window.ym(80266396, 'reachGoal', 'vhod_na_zveno_igrokom');
  }, []);

  //TODO: отрефакторить вместе с десктопом и мобильным компонентом

  return (
    <HockeyLineWrapper>
      <PageTitleDefault>Состав</PageTitleDefault>
      {!isMobile ? (
        linesForm && linesForm.playersLines ? (
          <>
            <HockeyLineHeader>Актуальный состав</HockeyLineHeader>
            <HockeyLineContentWrapper>
              <HockeyLineContent>
                {!linesForm.playersLines.length ? (
                  <HockeyLineContentWithoutWrapper>
                    <HockeyLineContentWithoutStatus>Без звена</HockeyLineContentWithoutStatus>
                    <HockeyLineContentWithoutText>
                      Вас не добавили в звенья на данное событие
                    </HockeyLineContentWithoutText>
                  </HockeyLineContentWithoutWrapper>
                ) : (
                  <LinesTab className={'LinesTab'}>
                    <LocalPreloader visible={loading} />
                    <LinesBlockHead className={'LinesBlockHead'}>
                      <LinesBlockHeadColumns className={'LinesBlockHeadColumns'}>
                        <LinesBlockHeadColumn className={'LinesBlockHeadColumn'}>
                          <LinesBlockHeadFlexContainer className={'LinesBlockHeadFlexContainer'}>
                            <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>№ звена</LinesBlockHeadTitle>
                            <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>Атакующая линия</LinesBlockHeadTitle>
                          </LinesBlockHeadFlexContainer>
                        </LinesBlockHeadColumn>
                        <LinesBlockHeadColumn className={'LinesBlockHeadColumn'}>
                          <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                            Оборонительная линия
                          </LinesBlockHeadTitle>
                        </LinesBlockHeadColumn>
                      </LinesBlockHeadColumns>
                    </LinesBlockHead>
                    <div className={'LinesTabLines'}>
                      {linesForm
                        ? linesForm.playersLines
                          ? linesForm.playersLines.map((it, lineIndex) => {
                              return (
                                <LinesTabLineContainer className={'LinesTabLineContainer'} key={lineIndex}>
                                  <LinesTabLine className={'LinesTabLine'}>
                                    <LinesTabLineNumber className={'LinesTabLineNumber'}>
                                      Звено №{lineIndex + 1}
                                    </LinesTabLineNumber>
                                    <LinesTabLineColumnWingers className={'LinesTabLineColumnWingers'}>
                                      <TeacherHockeyLinesPlayerCards className={'TeacherHockeyLinesPlayerCards'}>
                                        {['', '', ''].map((winger, wk) => {
                                          if (it.wingersLine[wk]) {
                                            return (
                                              <TeacherHockeyLinesPlayerCard
                                                className={'TeacherHockeyLinesPlayerCard'}
                                                key={wk}
                                                player={it.wingersLine[wk]}
                                                position={wingerPositions[wk]}
                                                readOnly={true}
                                              />
                                            );
                                          } else {
                                            return (
                                              <TeacherHockeyLinesPlayerEmptyCard
                                                className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                key={wk}
                                                lineNumber={lineIndex + 1}
                                                position={wingerPositions[wk]}
                                                readOnly={true}
                                              />
                                            );
                                          }
                                        })}
                                      </TeacherHockeyLinesPlayerCards>
                                    </LinesTabLineColumnWingers>
                                    <LinesTabLineColumnDefensemen className={'LinesTabLineColumnDefensemen'}>
                                      <TeacherHockeyLinesPlayerCards className={'TeacherHockeyLinesPlayerCards'}>
                                        {['', ''].map((defenseman, dk) => {
                                          if (it.defensemansLine[dk]) {
                                            return (
                                              <TeacherHockeyLinesPlayerCard
                                                className={'TeacherHockeyLinesPlayerCard'}
                                                key={dk}
                                                player={it.defensemansLine[dk]}
                                                position={defensemenPositions[dk]}
                                                readOnly={true}
                                              />
                                            );
                                          } else {
                                            return (
                                              <TeacherHockeyLinesPlayerEmptyCard
                                                className={'TeacherHockeyLinesPlayerEmptyCard'}
                                                key={dk}
                                                lineNumber={lineIndex + 1}
                                                position={defensemenPositions[dk]}
                                                readOnly={true}
                                              />
                                            );
                                          }
                                        })}
                                      </TeacherHockeyLinesPlayerCards>
                                    </LinesTabLineColumnDefensemen>
                                  </LinesTabLine>
                                </LinesTabLineContainer>
                              );
                            })
                          : null
                        : null}
                      {linesForm && (
                        <LinesTabLineContainerGoaltenders className={'LinesTabLineContainerGoaltenders'}>
                          <LinesTabLine className={'LinesTabLine'}>
                            <LinesTabLineNumber className={'LinesTabLineNumber'}>Вратари</LinesTabLineNumber>
                            <LinesTabLineColumnGoaltendersHead className={'LinesTabLineColumnGoaltendersHead'}>
                              <LinesTabLineColumnGoaltendersHeadColumn
                                className={'LinesTabLineColumnGoaltendersHeadColumn'}
                              >
                                <LinesBlockHeadTitle className={'LinesBlockHeadTitle'}>
                                  Состав вратарей
                                </LinesBlockHeadTitle>
                              </LinesTabLineColumnGoaltendersHeadColumn>
                            </LinesTabLineColumnGoaltendersHead>
                            <LinesTabLineColumnGoaltenders className={'LinesTabLineColumnGoaltenders'}>
                              <TeacherHockeyLinesPlayerCards className={'TeacherHockeyLinesPlayerCards'}>
                                {['', '', ''].map((goaltender, gk) => {
                                  if (
                                    linesForm.goaltendersLine &&
                                    linesForm.goaltendersLine.length &&
                                    linesForm.goaltendersLine[gk]
                                  ) {
                                    return (
                                      <TeacherHockeyLinesPlayerCard
                                        className={'TeacherHockeyLinesPlayerCard'}
                                        key={gk}
                                        player={linesForm.goaltendersLine[gk]}
                                        position={'вр'}
                                        readOnly={true}
                                      />
                                    );
                                  } else {
                                    return (
                                      <TeacherHockeyLinesPlayerEmptyCard
                                        className={'TeacherHockeyLinesPlayerEmptyCard'}
                                        key={gk}
                                        position={'вр'}
                                        readOnly={true}
                                      />
                                    );
                                  }
                                })}
                              </TeacherHockeyLinesPlayerCards>
                            </LinesTabLineColumnGoaltenders>
                          </LinesTabLine>
                        </LinesTabLineContainerGoaltenders>
                      )}
                    </div>
                  </LinesTab>
                )}
              </HockeyLineContent>
            </HockeyLineContentWrapper>
          </>
        ) : (
          <HockeyLineContentWithoutWrapper>
            <HockeyLineContentWithoutStatus>Актуальный состав</HockeyLineContentWithoutStatus>
            <HockeyLineContentWithoutText>Состав пуст</HockeyLineContentWithoutText>
          </HockeyLineContentWithoutWrapper>
        )
      ) : (
        <Container>
          <NearestGame>
            <TypographyDefault color="#757575" component="p" variant="caption1">
              Ближайшее мероприятие
            </TypographyDefault>
            {nearestGame ? (
              <>
                <TypographyDefault color="#B63830" variant="subtitle2">
                  {moment(nearestGame.rooms[0].startTimestamp).format('DD.MM.YYYY')}{' '}
                  {moment(nearestGame.rooms[0].startTimestamp).format('HH:mm')}
                </TypographyDefault>
                {nearestGame.type === 'game' ? (
                  <TypographyDefault color="#000" variant="subtitle2">
                    {`${teamName} - ${nearestGame?.opponent}`}
                  </TypographyDefault>
                ) : (
                  <TypographyDefault color="#000" variant="subtitle2">
                    {`${nearestGame?.opponent} - ${teamName}`}
                  </TypographyDefault>
                )}
              </>
            ) : (
              <TypographyDefault color="#000" variant="subtitle2">
                Отсутствует ближайшее мероприятие
              </TypographyDefault>
            )}
          </NearestGame>
          <LinesMobileTableComponent isButtonsShowed={false} linesForm={linesForm} />
        </Container>
      )}
    </HockeyLineWrapper>
  );
}
