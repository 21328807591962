import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const PersonalFileAPI = {
  getPlayersGames(request) {
    return axios.get(`${API_ENDPOINT}/games/${request.season}/${request.playerId}`);
  },
  getPlayersStatusesHistory(playerId) {
    return axios.get(`${API_ENDPOINT}/v1/status-history/student/${playerId}`);
  },
};

export function getPastGames({playerId, ...params}) {
  return axios.get(`${API_ENDPOINT}/v1/statistics/player/${playerId}/metrics/last-games`, {params});
}

export default PersonalFileAPI;
