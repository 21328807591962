import {
  DOCTOR_STATUSES_OPTIONS,
  DOCTOR_SUB_STATUSES_OPTIONS,
  NOTAVILABLE_SUB_STATUSES_OPTIONS,
  STATUSES_OPTIONS,
} from '@components/students/constants';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import TabsDefault from '@ui/MUI/Tabs';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import React, {useState} from 'react';

import {
  LinesMobilePlayerNumber,
  LinesMobileTable,
  LinesMobileTableBody,
  LinesMobileTableHead,
  LinesMobileTableTd,
  LinesMobileTableTh,
  LinesMobileTableTr,
  StatusIndicator,
} from '../LinesMobile';
import {ModalWrapper} from './LinesPlayersList';

const allStatuses = DOCTOR_SUB_STATUSES_OPTIONS.concat(
  NOTAVILABLE_SUB_STATUSES_OPTIONS,
  DOCTOR_STATUSES_OPTIONS,
  STATUSES_OPTIONS,
);

export default function LinesPlayersList({
  basket,
  selectedPlayer,
  selectPlayerPosition,
  selectGoaltenderPosition,
  setIsPlayersListModalOpen,
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  //ToDo: обернуть в мемо
  const getPlayersByTabIndex = (activeTabIndex) => {
    let players = [];
    if (activeTabIndex === 0) {
      players = basket?.filter((item) => item.student.hockeyRole === 'winger');
    }
    if (activeTabIndex === 1) {
      players = basket?.filter((item) => item.student.hockeyRole === 'defenseman');
    }
    if (activeTabIndex === 2) {
      players = basket?.filter((item) => item.student.hockeyRole === 'goaltender');
    }
    return players;
  };

  return (
    <ModalWrapper>
      <TabsDefault
        tabs={{
          headlineItems: [{label: 'Атакующая'}, {label: 'Оборонительная'}, {label: 'Вратари'}],
          contentItems: ['', '', ''].map((contentItem, index) => {
            return (
              <LinesMobileTable key={index}>
                <LinesMobileTableHead>
                  <LinesMobileTableTr>
                    <LinesMobileTableTh>Игрок</LinesMobileTableTh>
                    <LinesMobileTableTh>Хват</LinesMobileTableTh>
                    <LinesMobileTableTh>Статус</LinesMobileTableTh>
                  </LinesMobileTableTr>
                </LinesMobileTableHead>
                <LinesMobileTableBody>
                  {getPlayersByTabIndex(activeTabIndex)?.map((player) => {
                    return (
                      <LinesMobileTableTr
                        key={player.id}
                        onClick={() => {
                          if (player.student?.status !== 'available') {
                            ReactComponentNotification(NOTIFICATION_TYPES['info'], `Игрок недоступен для выбора`);
                            return;
                          } else {
                            if (selectedPlayer.playersType !== 'goaltendersLine') {
                              selectPlayerPosition &&
                                selectPlayerPosition(
                                  selectedPlayer.playersType,
                                  selectedPlayer.lineIndex,
                                  selectedPlayer.playerPosition,
                                  player.student,
                                );
                            } else {
                              selectGoaltenderPosition &&
                                selectGoaltenderPosition(selectedPlayer.playerPosition, player.student);
                            }
                            setIsPlayersListModalOpen && setIsPlayersListModalOpen(false);
                          }
                        }}
                      >
                        <LinesMobileTableTd>
                          <LinesMobilePlayerNumber>{player.student.number}</LinesMobilePlayerNumber> {player.name}
                        </LinesMobileTableTd>
                        <LinesMobileTableTd>{player.student.hand === 'right' ? 'Правый' : 'Левый'}</LinesMobileTableTd>
                        <LinesMobileTableTd hasIndicator>
                          <StatusIndicator color={player.student.status === 'available' ? '#4BA849' : '#C05B54'} />
                          {allStatuses.find((status) => status.value === player.student.status)?.label}
                        </LinesMobileTableTd>
                      </LinesMobileTableTr>
                    );
                  })}
                </LinesMobileTableBody>
              </LinesMobileTable>
            );
          }),
        }}
        onChange={(index) => setActiveTabIndex(index)}
      />
    </ModalWrapper>
  );
}
