import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

export default function ScheduleTableDefaultWithRooms(props) {
  let {tvSchedule = [], hasLockerRoom = true} = props;

  const isNow = (start, end) => {
    return moment().unix() >= start && moment().unix() <= end;
  };

  const constructEventName = (event) => {
    let result = '';
    if (event.type === 'game') {
      result = `${event.groupName} - ${event.opponent}`;
    } else if (event.type === 'away_game') {
      result = `Оппонент - ${event.groupName}`;
    } else {
      result = event.name;
    }
    return result;
  };

  return (
    <ScheduleTable border="0" cellpadding="0" cellspacing="0">
      <ScheduleTableHead>
        <ScheduleTableHeadTr>
          <ScheduleTableHeadTd>Время</ScheduleTableHeadTd>
          <ScheduleTableHeadTd>Мероприятие</ScheduleTableHeadTd>
          <ScheduleTableHeadTd>Команда</ScheduleTableHeadTd>
          {hasLockerRoom && <ScheduleTableHeadTd>Раздевалка</ScheduleTableHeadTd>}
        </ScheduleTableHeadTr>
      </ScheduleTableHead>
      <ScheduleTableBody>
        {moment(tvSchedule[0]?.startDate).format('HH:mm') > '06:00' && (
          <ScheduleTableTr>
            <ScheduleTableTd>06:00 - {moment(tvSchedule[0]?.startDate).format('HH:mm')}</ScheduleTableTd>
            <ScheduleTableTd></ScheduleTableTd>
            <ScheduleTableTd></ScheduleTableTd>
            {hasLockerRoom && <ScheduleTableTd></ScheduleTableTd>}
          </ScheduleTableTr>
        )}
        {tvSchedule.map((event, index) => {
          return (
            <ScheduleTableTr
              className={`${isNow(event.startDate, event.endDate) ? 'ScheduleTableCurrentEvent' : ''}`}
              key={index}
            >
              <ScheduleTableTd>
                {moment(event.startDate).format('HH:mm')} - {moment(event.endDate).format('HH:mm')}
              </ScheduleTableTd>
              <ScheduleTableTd>{constructEventName(event)}</ScheduleTableTd>
              <ScheduleTableTd>{event.groupName}</ScheduleTableTd>
              {hasLockerRoom && <ScheduleTableTd>{event.lockerRoom ? `№${event.lockerRoom}` : ''}</ScheduleTableTd>}
            </ScheduleTableTr>
          );
        })}
        {moment(tvSchedule[0]?.endDate).format('HH:mm') < '22:00' && (
          <ScheduleTableTr>
            <ScheduleTableTd>
              {moment(tvSchedule[tvSchedule.length - 1]?.endDate).format('HH:mm')} - 22:00
            </ScheduleTableTd>
            <ScheduleTableTd></ScheduleTableTd>
            <ScheduleTableTd></ScheduleTableTd>
            {hasLockerRoom && <ScheduleTableTd></ScheduleTableTd>}
          </ScheduleTableTr>
        )}
        <ScheduleTableTr>
          <ScheduleTableTd></ScheduleTableTd>
          <ScheduleTableTd></ScheduleTableTd>
          <ScheduleTableTd></ScheduleTableTd>
          {hasLockerRoom && <ScheduleTableTd></ScheduleTableTd>}
        </ScheduleTableTr>
        <ScheduleTableTr>
          <ScheduleTableTd></ScheduleTableTd>
          <ScheduleTableTd></ScheduleTableTd>
          <ScheduleTableTd></ScheduleTableTd>
          {hasLockerRoom && <ScheduleTableTd></ScheduleTableTd>}
        </ScheduleTableTr>
      </ScheduleTableBody>
    </ScheduleTable>
  );
}

const ScheduleTable = styled.table`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  width: 100%;
  height: calc(100vh - 15.5rem);
  border: 0;
  border-spacing: 0;
  border-radius: 0.5rem;
  overflow: hidden;
`;

const ScheduleTableHead = styled.thead`
  background: black;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.3);
`;
const ScheduleTableHeadTr = styled.tr``;
const ScheduleTableHeadTd = styled.th`
  padding: 0.5rem 2.5rem;
  font: normal normal 2.1875rem/1 'Bebas Neue';
  color: white;
  text-align: center;
  text-transform: uppercase;
  &:first-child {
    text-align: left;
  }
`;
const ScheduleTableBody = styled.tbody``;
const ScheduleTableTd = styled.td`
  padding: 0.5rem 2.5rem;
  border-bottom: 2px solid #353535;
  font: bold normal 2rem/1 'Bebas Neue';
  color: black;
  text-transform: uppercase;
  text-align: center;
  &:first-child {
    text-align: left;
  }
`;
const ScheduleTableTr = styled.tr`
  background-color: white;
  min-height: 6vh;
  height: calc(100% / 8);
  ${ScheduleTableTd} {
    border-right: 1px solid white;
    border-left: 1px solid white;
  }
  &:nth-child(even) {
    background-color: #f3f3f3;
    ${ScheduleTableTd} {
      border-right: 1px solid #f3f3f3;
      border-left: 1px solid #f3f3f3;
    }
  }
  &:last-child {
    ${ScheduleTableTd} {
      border-bottom: 0;
    }
  }
  &.ScheduleTableGame {
    background-color: #990011;
    :nth-child(even) {
      background-color: #990011;
    }
    ${ScheduleTableTd} {
      color: white;
    }
  }
  &.ScheduleTableCurrentEvent {
    background-color: #990011;
    :nth-child(even) {
      background-color: #990011;
    }
    ${ScheduleTableTd} {
      color: white;
    }
  }
`;
