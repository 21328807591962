import {developCode} from '@helpers/developCode';
import React, {forwardRef, useEffect, useMemo} from 'react';

import NewFilters from './newFilters/Filters';
import OldFilters from './oldFilters/Filters';

// Это враппер для того чтобы синхронизировать старые фильтры с новыми.
// Всё что он делает это по пропу isLegacy рендерит oldFilters или newFilters
/**@deprecated не использовать этот компонент**/
const Filters = (
  {
    isLegacy,
    minWidth,
    filters,
    withReduxStore,
    filtersName,
    withQueryString,
    apiRef,
    className,
    sizes,
    spacing,
    onChange,
    button,
  },
  ref,
) => {
  useEffect(() => {
    developCode(
      console.warn(`
    поддержка компонента Filters прекращена и будет со временем полностью выпиливаться.
    Пожалуйста не используйте этот компонент на новых страницах.
    Используйте хуки useStudent, useSchool, useGroup.
    `),
    );
  }, []);

  return useMemo(() => {
    return isLegacy ? (
      <OldFilters
        apiRef={apiRef}
        className={className}
        filters={filters}
        filtersName={filtersName}
        withReduxStore={withReduxStore}
        onChange={onChange}
      />
    ) : (
      <NewFilters
        apiRef={apiRef}
        className={className}
        filters={filters}
        filtersName={filtersName}
        withReduxStore={withReduxStore}
        onChange={onChange}
      />
    );
  }, [
    ref,
    filters,
    minWidth,
    withReduxStore,
    withQueryString,
    apiRef,
    className,
    sizes,
    button,
    spacing,
    onChange,
    isLegacy,
    filtersName,
  ]);
};

export default forwardRef(Filters);
