import {metricsMock} from '@components/apps/PlayerPersonalFile/components/Statistics/components/GoaltenderMetrics/mock';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {GOALTENDER_STATISTIC_METRICS} from '@components/apps/PlayerPersonalFile/constants';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectPlayerTournamentStat} from '@selectors/gameStatisticsMkc';
import {clearPlayerTournamentStat, loadPlayerTournamentStat} from '@slices/statisticsMKC';
import {Stack, Typography} from '@ui/MUI';
import {isEmpty, mapValues, merge} from 'lodash';
import React from 'react';
import {useParams} from 'react-router-dom';

import GoaltenderMetric from '../GoaltenderMetric';

function GoaltenderMetrics({requestFilters, isYoungerAgeGroup}) {
  const playerId = useParams()?.id;
  const dispatch = useStoreDispatch();
  const {data, isLoading} = useStore(selectPlayerTournamentStat);
  const metrics = useDeepMemo(() => {
    const newData = mapValues(isLoading ? metricsMock : data, (value) => {
      if (typeof value === 'string' || typeof value === 'number') {
        return {count: value};
      }
      if (value?.value) {
        return {
          count: value?.value,
          ...value,
        };
      }
      return value;
    });
    const result = Object.values(merge(GOALTENDER_STATISTIC_METRICS, newData));
    if (isYoungerAgeGroup) return result?.filter((item) => item?.isYoungerAgeGroup);
    return result;
  }, [data, isYoungerAgeGroup]);
  useDeepEffect(() => {
    const {isFilled, ...params} = requestFilters;
    if (isFilled && playerId) dispatch(loadPlayerTournamentStat({playerId, ...params}));
    return () => {
      dispatch(clearPlayerTournamentStat());
    };
  }, [requestFilters, playerId]);

  return (isEmpty(data) && !isLoading) || !requestFilters?.teamMkcId ? (
    <Wrapper>
      <Stack alignItems="center" height={250} justifyContent="center">
        <Typography variant="subtitle1">
          {!requestFilters?.teamMkcId ? 'Заполните фильтры, чтобы увидеть статистику по команде' : 'Данные отсутствуют'}
        </Typography>
      </Stack>
    </Wrapper>
  ) : (
    <Stack direction="row" flexWrap="wrap" gap={3}>
      {metrics?.map((item, index, arr) => (
        <GoaltenderMetric isLoading={isLoading} itemIndex={index} itemsCount={arr.length} key={index} {...item} />
      ))}
    </Stack>
  );
}

export default GoaltenderMetrics;
