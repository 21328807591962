import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {
  FunctionalStateIndicator,
  TableCellFlexContainer,
} from '@components/apps/FunctionalTesting/components/Report/ReportTable/ReportTable';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import {useMediaQuery} from '@mui/material';
import {yellow} from '@ui/MUI/colors';
import React, {useState} from 'react';

const setBackground = (value, id) => {
  if ((id === 'energy' || id === 'readiness') && value === 1) {
    return 'rgba(182, 56, 48, 0.1)';
  }
  if (id === 'psycho' && value === 3) {
    return yellow[20];
  }
  if (id === 'psycho' && (value === 1 || value === 2)) {
    return 'rgba(182, 56, 48, 0.1)';
  }
  if (id === 'functional' && (value == 1 || value === 2 || value === 3 || value === 4)) {
    return 'rgba(182, 56, 48, 0.1)';
  }
  return 'transparent';
};

const ReportTable = ({tableData}) => {
  const isSmResolution = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isToggled, setToggleUser] = useState(false);
  const [sorting, setSorting] = useState([{id: 'player', desc: true}]);
  const columns = [
    {
      accessorKey: 'player',
      header: (
        <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
          Игрок
        </ToggledHeader>
      ),
      cell: (info) => {
        return (
          <TableCellFlexContainer isToggled={isToggled}>
            <PlayersHorizontalCard
              hasImage={!isToggled}
              isShortName={isSmResolution}
              onlyNumber={isToggled}
              student={info.getValue()}
            />
          </TableCellFlexContainer>
        );
      },
      sortingFn: 'defaultSorting',
      sticky: {left: true},
    },
    {
      accessorKey: 'psycho',
      header: 'Психоэмоциональная активность',
      cell: (info) => (info.getValue().value !== 0 ? info.getValue().label : '-'),
      cellbackground: (info) => {
        return setBackground(info.children.props.cell.getValue()?.value, info.children.props.cell.column.id);
      },
    },
    {
      accessorKey: 'energy',
      header: 'Уровень энергетических ресурсов',
      cell: (info) => (info.getValue().value !== 0 ? info.getValue().label : '-'),
      cellbackground: (info) => {
        return setBackground(info.children.props.cell.getValue()?.value, info.children.props.cell.column.id);
      },
    },
    {
      accessorKey: 'readiness',
      header: 'Функциональная готовность',
      cell: (info) => (info.getValue().value !== 0 ? info.getValue().label : '-'),
      cellbackground: (info) => {
        return setBackground(info.children.props.cell.getValue()?.value, info.children.props.cell.column.id);
      },
    },
    {
      accessorKey: 'functional',
      header: 'Функциональное состояние',
      cell: (info) => {
        if (info.getValue().value !== 0) {
          return (
            <TableCellFlexContainer>
              <FunctionalStateIndicator type={info.getValue()?.value} />
              <span>{`${info.getValue().label}`}</span>
            </TableCellFlexContainer>
          );
        } else {
          return '-';
        }
      },
      cellbackground: (info) => {
        return setBackground(info.children.props.cell.getValue()?.value, info.children.props.cell.column.id);
      },
    },
  ];

  return (
    <ReactTableComponent
      columns={columns}
      data={tableData}
      data-testid="report-table"
      isAvailableHeight={true}
      setSorting={setSorting}
      sorting={sorting}
      tableBodyCellProps={{
        sx: {
          boxShadow: 'rgb(224, 224, 224) -1px 0 0px 0px inset',
          width: '100%',
          '&:last-child': {
            boxShadow: 'none',
          },
          '&:first-child.sticky-left': {
            width: '300px',
          },
          '@media screen and (max-width: 575px)': {
            width: '160px !important',
            maxWidth: 160,
            fontSize: '12px !important',
          },
        },
      }}
      tableHeaderCellProps={{
        sx: {
          width: '100%',
          '&:first-child.sticky-left': {
            width: '300px',
            display: 'flex',
          },
          '@media screen and (max-width: 575px)': {
            width: '160px !important',
            maxWidth: 160,
            fontSize: '12px !important',
          },
        },
      }}
    />
  );
};

export default ReportTable;
