import React from 'react';

function PlayerColorful() {
  return (
    <React.Fragment>
      <g>
        <g clipPath="url(#clip0)">
          <path
            d="M45 22.5C45 29.6209 41.6921 35.9689 36.5289 40.0912C32.6819 43.1632 27.8064 45 22.5 45C19.4108 45 16.4671 44.3772 13.7875 43.2504C12.9821 42.9116 12.1996 42.5277 11.4447 42.1C10.4556 41.5414 9.51416 40.9103 8.62633 40.2134C8.5738 40.1722 8.52161 40.1307 8.46909 40.0895C3.30688 35.9679 0 29.6202 0 22.5C0 17.3 1.76434 12.511 4.72687 8.70049C5.77641 7.35054 6.97701 6.1235 8.30189 5.04547C12.1749 1.89102 17.1157 0 22.5 0C34.9266 0 45 10.0734 45 22.5Z"
            fill="#E7ECED"
          />
          <path
            d="M36.0373 28.5614C34.9462 27.4665 33.9362 26.2838 33.0315 25.042C32.3988 24.1751 31.8 23.2522 31.2528 22.2988C28.672 17.7978 27.3083 12.6586 27.3083 7.43877C27.3083 5.14022 27.5655 2.88081 28.0677 0.694191C26.8932 0.394814 25.682 0.188133 24.4412 0.0827332C23.905 2.47947 23.6348 4.93903 23.6348 7.43877C23.6348 13.2989 25.1667 19.0688 28.066 24.1263C28.6802 25.1971 29.3535 26.234 30.0642 27.2076C31.0773 28.5988 32.2113 29.9268 33.4356 31.1548C35.4643 33.1907 37.7319 34.9492 40.1939 36.4004C40.9506 35.4381 41.631 34.4123 42.2253 33.3322C39.967 32.0217 37.8916 30.4218 36.0373 28.5614Z"
            fill="#F2EFEA"
          />
          <path
            d="M14.3159 21.9318C14.3159 23.313 13.3068 24.458 11.9857 24.6705C11.8415 24.6938 11.6929 24.7062 11.5415 24.7062C10.0089 24.7062 8.76709 23.4644 8.76709 21.9318C8.76709 20.3992 10.0089 19.1577 11.5415 19.1577C11.6929 19.1577 11.8415 19.1698 11.9857 19.1931C13.3068 19.4056 14.3159 20.5509 14.3159 21.9318Z"
            fill="#F6BA97"
          />
          <path
            d="M14.3158 21.9318C14.3158 23.313 13.3068 24.458 11.9857 24.6705C11.7457 23.7981 11.6177 22.8797 11.6177 21.9318C11.6177 20.9839 11.7457 20.0658 11.9857 19.1931C13.3068 19.4056 14.3158 20.551 14.3158 21.9318Z"
            fill="#EAA27F"
          />
          <path
            d="M12.5333 21.9318C12.5333 22.4794 12.0894 22.9233 11.5418 22.9233C10.9942 22.9233 10.5503 22.4794 10.5503 21.9318C10.5503 21.3842 10.9942 20.9403 11.5418 20.9403C12.0894 20.9403 12.5333 21.3842 12.5333 21.9318Z"
            fill="#EAA27F"
          />
          <path
            d="M30.6826 21.9318C30.6826 23.313 31.6916 24.458 33.0124 24.6705C33.1569 24.6938 33.3056 24.7062 33.457 24.7062C34.9896 24.7062 36.2314 23.4644 36.2314 21.9318C36.2314 20.3992 34.9896 19.1577 33.457 19.1577C33.3056 19.1577 33.1569 19.1698 33.0124 19.1931C31.6916 19.4056 30.6826 20.5509 30.6826 21.9318Z"
            fill="#F6BA97"
          />
          <path
            d="M30.6826 21.9318C30.6826 23.313 31.6916 24.458 33.0124 24.6705C33.2524 23.7981 33.3808 22.8797 33.3808 21.9318C33.3808 20.9839 33.2527 20.0658 33.0124 19.1931C31.6916 19.4056 30.6826 20.551 30.6826 21.9318Z"
            fill="#EAA27F"
          />
          <path
            d="M34.4484 21.9318C34.4484 22.4794 34.0044 22.9233 33.4568 22.9233C32.9092 22.9233 32.4653 22.4794 32.4653 21.9318C32.4653 21.3842 32.9092 20.9403 33.4568 20.9403C34.0044 20.9403 34.4484 21.3842 34.4484 21.9318Z"
            fill="#EAA27F"
          />
          <path
            d="M36.529 40.0912C32.6821 43.1632 27.8065 45 22.5002 45C19.4109 45 16.4673 44.3772 13.7877 43.2504C11.8647 42.4416 10.0763 41.3742 8.46924 40.0895C8.70819 38.6475 9.2345 37.3024 9.98913 36.1165C11.6608 33.4856 14.4516 31.6351 17.6933 31.2355C17.8423 31.2163 17.9927 31.2005 18.1434 31.1888C18.4318 31.1648 18.7239 31.1528 19.0178 31.1528H25.9797C26.4288 31.1528 26.8717 31.1806 27.306 31.2338C27.6678 31.2788 28.0232 31.3413 28.373 31.4209C31.155 32.0574 33.5242 33.7795 35.0091 36.1165C35.7641 37.3041 36.2904 38.6485 36.529 40.0912Z"
            fill="#990011"
          />
          <path
            d="M36.5289 40.0912C32.682 43.1632 27.8065 45 22.5001 45C19.4109 45 16.4672 44.3772 13.7876 43.2504V41.8428C13.7876 41.0851 13.8666 40.3456 14.0163 39.6332C14.3222 38.1765 14.9243 36.831 15.7566 35.6592C17.6943 32.9319 20.8793 31.1528 24.479 31.1528H25.9797C31.2888 31.1528 35.6943 35.02 36.5289 40.0912Z"
            fill="#D53D33"
          />
          <path
            d="M35.0088 36.1165C33.3385 38.7488 30.5466 40.5993 27.3046 40.9986C27.1559 41.0178 27.0052 41.0336 26.8548 41.0456C26.5665 41.0693 26.2743 41.0813 25.9804 41.0813H19.0182C18.5691 41.0813 18.1265 41.0539 17.6922 41.0006C17.3304 40.9557 16.975 40.8932 16.6249 40.8132C13.8422 40.1767 11.474 38.4549 9.98877 36.1165C11.5004 33.7373 13.9277 31.996 16.7766 31.389C17.0777 31.3244 17.3836 31.2733 17.6929 31.2355C17.8419 31.2163 17.9923 31.2005 18.143 31.1888C18.4314 31.1648 18.7236 31.1528 19.0175 31.1528H25.9794C26.4284 31.1528 26.8713 31.1806 27.3056 31.2338C27.616 31.2722 27.9209 31.3237 28.2223 31.389C28.2728 31.3989 28.3232 31.4099 28.3727 31.4209C31.1546 32.0574 33.5239 33.7795 35.0088 36.1165Z"
            fill="#F2EFEA"
          />
          <path
            d="M19.0175 31.1528H24.4787C20.879 31.1528 17.6929 32.9319 15.7562 35.6592C14.9233 36.831 14.3208 38.1765 14.0159 39.6332C14.0029 39.6957 13.9902 39.7578 13.9782 39.8213C12.3501 38.95 10.9751 37.6687 9.98877 36.1165C11.6604 33.4856 14.4513 31.6351 17.6929 31.2355C17.8419 31.2163 17.9923 31.2005 18.143 31.1888C18.4314 31.1648 18.7236 31.1528 19.0175 31.1528Z"
            fill="#D8D4CE"
          />
          <path
            d="M28.2225 31.389C28.148 34.4809 25.6095 36.9735 22.5 36.9735H22.499C20.0793 36.9735 17.9109 35.4416 17.1027 33.1622C16.9008 32.592 16.7916 31.996 16.7769 31.389C17.0779 31.3244 17.3838 31.2733 17.6932 31.2355C17.8422 31.2163 17.9926 31.2005 18.1433 31.1888C18.4317 31.1648 18.7238 31.1528 19.0177 31.1528H25.9796C26.4287 31.1528 26.8716 31.1806 27.3059 31.2338C27.6162 31.2722 27.9211 31.3237 28.2225 31.389Z"
            fill="#D53D33"
          />
          <path
            d="M27.3056 31.2338V31.2492C27.3056 33.9035 25.154 36.0551 22.4997 36.0551H22.4987C20.4086 36.0551 18.6288 34.7199 17.9686 32.8557C17.7904 32.353 17.6929 31.8133 17.6929 31.2492V31.2355C17.8419 31.2163 17.9922 31.2005 18.143 31.1888C18.4314 31.1648 18.7235 31.1528 19.0174 31.1528H25.9793C26.4284 31.1528 26.8713 31.1806 27.3056 31.2338Z"
            fill="#F6BA97"
          />
          <path
            d="M19.0174 31.139H24.4786C22.1001 31.139 19.9018 31.916 18.1255 33.2292C18.0678 33.1035 18.0153 32.9741 17.9686 32.8416C17.7904 32.3393 17.6929 31.7992 17.6929 31.2355V31.2218C17.8419 31.2026 17.9922 31.1868 18.143 31.1747C18.4314 31.1511 18.7235 31.139 19.0174 31.139Z"
            fill="#EAA27F"
          />
          <path
            d="M25.4827 27.9321V31.5929C25.4827 32.9332 24.599 34.0662 23.3816 34.4425C23.1031 34.529 22.8062 34.5757 22.4989 34.5757C20.8516 34.5757 19.5161 33.2405 19.5161 31.5929V27.9321H25.4827Z"
            fill="#EAA27F"
          />
          <path
            d="M25.4822 31.3612V31.5929C25.4822 32.9332 24.5985 34.0662 23.3811 34.4425C22.2828 34.105 21.4571 33.1499 21.3047 31.9815C21.5038 31.9936 21.7043 31.9997 21.9055 31.9997C22.4699 31.9997 23.0234 31.9551 23.5624 31.8665C24.2264 31.7598 24.868 31.5891 25.4822 31.3612Z"
            fill="#F6BA97"
          />
          <path
            d="M14.0676 21.7914C14.0676 23.2145 13.0284 24.3941 11.6671 24.6132C11.4199 23.7143 11.2881 22.7681 11.2881 21.7914C11.2881 20.815 11.4199 19.8688 11.6671 18.97C13.0284 19.1887 14.0676 20.3687 14.0676 21.7914Z"
            fill="#EAA27F"
          />
          <path
            d="M30.9302 21.7914C30.9302 23.2145 31.9698 24.3941 33.3307 24.6132C33.5782 23.7143 33.7101 22.7681 33.7101 21.7914C33.7101 20.815 33.5779 19.8688 33.3307 18.97C31.9698 19.1887 30.9302 20.3687 30.9302 21.7914Z"
            fill="#EAA27F"
          />
          <path
            d="M33.7401 16.5269V20.4576C33.7401 23.1448 32.7767 25.7447 31.0271 27.7796C29.2968 29.7935 26.9083 31.1335 24.3018 31.5524L24.2551 31.5606C23.6838 31.6516 23.095 31.6976 22.499 31.6976C16.301 31.6976 11.2593 26.6556 11.2593 20.4576V16.5269C11.2593 16.0328 11.2912 15.5415 11.3547 15.0557H33.6426C33.7078 15.5415 33.7401 16.0328 33.7401 16.5269Z"
            fill="#D53D33"
          />
          <path
            d="M32.8212 16.5269V20.4576C32.8212 25.594 29.0687 29.8553 24.1554 30.646C24.139 30.6488 24.1214 30.6515 24.105 30.6543C23.5824 30.7377 23.0462 30.7792 22.4989 30.7792C16.7984 30.7792 12.1772 26.1581 12.1772 20.4576V16.5269C12.1772 15.3266 12.3819 14.1734 12.7602 13.1015C14.172 9.08466 17.9997 6.20453 22.4989 6.20453H22.4996C23.0462 6.20453 23.5835 6.24676 24.1067 6.3295C29.044 7.10094 32.8212 11.3733 32.8212 16.5269Z"
            fill="#EAA27F"
          />
          <path
            d="M30.7572 20.4943V26.6518C29.1878 28.7395 26.846 30.2134 24.1561 30.646C24.1396 30.6488 24.1221 30.6515 24.1056 30.6543C19.1683 29.8828 15.3921 25.6105 15.3921 20.4576V18.2167H28.4799C29.7378 18.2167 30.7572 19.236 30.7572 20.4943Z"
            fill="#F6BA97"
          />
          <path d="M12.6733 16.8311H32.6939V23.7188H12.6733V16.8311Z" fill="#9FC2CC" />
          <path
            d="M30.7572 20.4943V23.7188H15.9184C15.5768 22.6936 15.3921 21.5974 15.3921 20.4576V18.2167H28.4799C29.7378 18.2167 30.7572 19.236 30.7572 20.4943Z"
            fill="#C1DCE5"
          />
          <path
            d="M22.4992 26.0005C21.6347 26.0005 20.7705 25.7839 19.9826 25.3509C19.7605 25.2287 19.6795 24.9496 19.8013 24.7271C19.9236 24.505 20.203 24.424 20.4252 24.5458C21.7239 25.26 23.2747 25.26 24.5732 24.5458C24.7953 24.424 25.0744 24.505 25.1967 24.7271C25.3189 24.9496 25.2379 25.2287 25.0157 25.3509C24.2278 25.7839 23.3633 26.0005 22.4992 26.0005Z"
            fill="#EAA27F"
          />
          <path
            d="M23.6699 22.8049H21.3281C21.049 22.8049 20.8228 22.5786 20.8228 22.2998C20.8228 22.0207 21.049 21.7945 21.3281 21.7945H23.6699C23.9487 21.7945 24.175 22.0207 24.175 22.2998C24.175 22.5786 23.9487 22.8049 23.6699 22.8049Z"
            fill="#9FC2CC"
          />
          <path
            d="M20.3013 19.8564C20.3013 20.5032 19.777 21.0275 19.1302 21.0275C18.4837 21.0275 17.9595 20.5032 17.9595 19.8564C17.9595 19.2099 18.4837 18.6857 19.1302 18.6857C19.777 18.6857 20.3013 19.2099 20.3013 19.8564Z"
            fill="#2F373B"
          />
          <path
            d="M27.0396 19.8564C27.0396 20.5032 26.5153 21.0275 25.8685 21.0275C25.222 21.0275 24.6978 20.5032 24.6978 19.8564C24.6978 19.2099 25.222 18.6857 25.8685 18.6857C26.5153 18.6857 27.0396 19.2099 27.0396 19.8564Z"
            fill="#2F373B"
          />
          <path
            d="M20.0623 19.6501C20.0623 19.9271 19.8377 20.1517 19.5607 20.1517C19.2836 20.1517 19.0591 19.9271 19.0591 19.6501C19.0591 19.373 19.2836 19.1481 19.5607 19.1481C19.8377 19.1481 20.0623 19.373 20.0623 19.6501Z"
            fill="white"
          />
          <path
            d="M26.858 19.6501C26.858 19.9271 26.6335 20.1517 26.3564 20.1517C26.0794 20.1517 25.8545 19.9271 25.8545 19.6501C25.8545 19.373 26.0794 19.1481 26.3564 19.1481C26.6335 19.1481 26.858 19.373 26.858 19.6501Z"
            fill="white"
          />
          <path
            d="M32.1284 13.2869H12.8711C12.8711 12.6892 12.9253 12.1042 13.0301 11.5374C13.1248 11.0213 13.2604 10.5191 13.4352 10.035C14.3481 7.49027 16.3033 5.44132 18.7855 4.40174C19.1216 4.26132 19.4955 4.24552 19.8454 4.34577C20.267 4.46594 20.7002 4.55966 21.1438 4.62215C21.5863 4.68463 22.0392 4.71656 22.5003 4.71656C23.4204 4.71656 24.3113 4.58713 25.1545 4.34577C25.505 4.24552 25.8779 4.26132 26.214 4.40174C28.6972 5.44132 30.6518 7.48924 31.5647 10.035C31.7391 10.5191 31.875 11.0213 31.9695 11.5384C32.0742 12.1049 32.1284 12.6899 32.1284 13.2869Z"
            fill="#D53D33"
          />
          <path
            d="M22.4269 4.7159C19.3164 6.31201 17.1878 9.55023 17.1878 13.287H12.8716C12.8716 9.28587 15.3126 5.85573 18.7857 4.40176C19.1218 4.26134 19.4957 4.24589 19.8455 4.3458C20.2671 4.46596 20.7004 4.55969 21.1439 4.62217C21.5628 4.68088 21.9916 4.71315 22.4269 4.7159Z"
            fill="#990011"
          />
          <path
            d="M32.1284 13.2869H12.8711C12.8711 12.6892 12.9253 12.1042 13.0301 11.5374C13.1248 11.0213 13.2604 10.5191 13.4352 10.035H31.5647C31.7391 10.5191 31.875 11.0213 31.9695 11.5384C32.0742 12.1049 32.1284 12.6899 32.1284 13.2869Z"
            fill="#990011"
          />
          <path
            d="M33.8334 13.2035V24.3155C33.8334 24.9362 33.5818 25.4993 33.1742 25.9061C32.7674 26.314 32.2043 26.5656 31.5836 26.5656V19.7593C31.5836 18.5017 30.5639 17.482 29.3064 17.482H13.4146V26.5656C12.1721 26.5656 11.1655 25.558 11.1655 24.3155V13.2035C11.1655 11.9607 12.1728 10.9534 13.4157 10.9534H31.5836C32.8261 10.9534 33.8334 11.9607 33.8334 13.2035Z"
            fill="#D53D33"
          />
          <path
            d="M18.9026 10.9534C17.6602 10.9534 16.6535 11.961 16.6535 13.2035V17.482H15.6919C14.4343 17.482 13.4146 18.5017 13.4146 19.7593V26.5656C12.1721 26.5656 11.1655 25.558 11.1655 24.3155V13.2035C11.1655 11.9607 12.1728 10.9534 13.4157 10.9534H18.9026Z"
            fill="#990011"
          />
          <path d="M31.4888 13.6028H33.8333V15.1165H31.4888V13.6028Z" fill="#DD104F" />
          <path d="M32.4351 16.1575H33.8334V17.6715H32.4351V16.1575Z" fill="#DD104F" />
          <path d="M11.1655 13.6028H13.5101V15.1165H11.1655V13.6028Z" fill="#D53D33" />
          <path d="M11.1655 16.1575H12.5639V17.6715H11.1655V16.1575Z" fill="#F71C55" />
          <path
            d="M25.751 8.1443H25.2178V10.586H27.2005V9.59347C27.2005 8.79318 26.5516 8.1443 25.751 8.1443Z"
            fill="#990011"
          />
          <path
            d="M19.2474 8.1443H19.7805V10.586H17.7979V9.59347C17.7979 8.79318 18.4467 8.1443 19.2474 8.1443Z"
            fill="#990011"
          />
          <path
            d="M17.6317 14.6269C17.6317 15.1254 17.2276 15.5295 16.7291 15.5295C16.2303 15.5295 15.8262 15.1254 15.8262 14.6269C15.8262 14.1281 16.2303 13.724 16.7291 13.724C17.2276 13.724 17.6317 14.1281 17.6317 14.6269Z"
            fill="#990011"
          />
          <path
            d="M29.1722 14.6269C29.1722 15.1254 28.7681 15.5295 28.2696 15.5295C27.7708 15.5295 27.3667 15.1254 27.3667 14.6269C27.3667 14.1281 27.7708 13.724 28.2696 13.724C28.7681 13.724 29.1722 14.1281 29.1722 14.6269Z"
            fill="#990011"
          />
          <path
            d="M11.4014 21.9775L11.4447 42.1C10.4556 41.5414 9.51416 40.9103 8.62633 40.2134L8.59028 21.9957L7.93282 10.8054C7.92904 10.2012 7.60495 9.64394 7.08138 9.34079L4.72687 8.70049L0.888182 6.4737C0.250631 6.10429 0.0329649 5.28718 0.403067 4.64894L1.54942 2.67243C1.91952 2.03522 2.73663 1.81756 3.37418 2.18663L8.30189 5.04548L10.6458 7.13013C11.4512 7.59671 11.948 8.45433 11.9545 9.38473L11.4014 21.9775Z"
            fill="#2F373B"
          />
          <path
            d="M2.65674 7.49954L5.14274 3.21281L11.1049 6.67042C11.9096 7.13734 12.4071 7.99461 12.4133 8.92502L12.4037 20.1781L11.4019 21.9771L8.59039 21.9957L7.44884 20.2094L7.47356 11.2644C7.46944 10.6602 7.14534 10.1026 6.62246 9.79946L2.65674 7.49954Z"
            fill="#F2EFEA"
          />
          <path
            d="M12.1987 34.7693V37.3837C12.1987 38.3684 11.5907 39.2116 10.7293 39.5566C10.4595 39.6661 10.1656 39.7259 9.85694 39.7259C8.56399 39.7259 7.51514 38.677 7.51514 37.3837V34.7693C7.51514 33.4764 8.56399 32.4275 9.85694 32.4275C10.1656 32.4275 10.4595 32.4873 10.7293 32.5954C11.0253 32.7139 11.2913 32.8913 11.5128 33.1135C11.9361 33.5378 12.1987 34.1228 12.1987 34.7693Z"
            fill="#EAA27F"
          />
          <path
            d="M12.1991 34.7693V37.3837C12.1991 38.3684 11.5911 39.2116 10.7297 39.5566C9.86828 39.2116 9.26025 38.3684 9.26025 37.3837V34.7693C9.26025 33.7847 9.86828 32.9418 10.7297 32.5954C11.0256 32.7138 11.2917 32.8913 11.5131 33.1135C11.9365 33.5378 12.1991 34.1228 12.1991 34.7693Z"
            fill="#F6BA97"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect fill="white" height="45" width="45" />
          </clipPath>
        </defs>
      </g>
    </React.Fragment>
  );
}
export default PlayerColorful;
