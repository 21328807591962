export const generateClassNameByEstimation = (estimationValue) => {
  if (!estimationValue) {
    return 'Estimation WithoutEstimation';
  }
  if (estimationValue < 3) {
    return 'Estimation LowEstimation';
  }
  if (estimationValue >= 5) {
    return 'Estimation HighEstimation';
  }
  return ' Estimation MiddleEstimation';
};
