import styled from 'styled-components';

export const TableWrapper = styled.div``;

export const InfoBlock = styled.div`
  padding: ${({theme}) => theme.spacing(3, 4)};
  margin-top: ${({theme}) => theme.spacing(4)};
  margin-bottom: ${({theme}) => theme.spacing(1)};
  background-color: white;
  border-radius: 6px;
  ${(props) => props?.sx}

  ${({theme}) => theme.breakpoints.down('sm')} {
    padding: ${({theme}) => theme.spacing(2, 3)};
  }

  .MuiFormControlLabel {
    &-root {
      &:not(:first-child) {
        padding-right: 0;
        padding-left: ${({theme}) => theme.spacing(6)};
        margin: 0;
        ${({theme}) => theme.breakpoints.down('xs')} {
          padding-left: 0;
        }
      }
    }
    &-label {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const LocalPreloaderWrapper = styled.div`
  margin-right: 0;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: ${({theme, props}) => (props?.padding ? props?.padding : theme.spacing(4))};
  overflow: hidden;
  margin-bottom: ${(props) => props.marginBottom || 'unset'};
  position: relative;
  height: ${(props) => props.isLoading && '70vh'};
`;
