import schema from '@assets/images/arena-schema.svg';
import schemaVertical from '@assets/images/arena-schema-vertical.svg';
import ThrowinsMetricsCharts from '@common/Statistics/PlayerStatistics/Throwins/ThrowinsMetricsCharts';
import {PLAY_ZONES} from '@components/apps/StatsReportForOneGame/constants';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import CircleIcon from '@mui/icons-material/Circle';
import {useMediaQuery} from '@mui/material';
import {TEAM_COLORS} from '@src/constants';
import {Typography} from '@ui/MUI';
import {darkRed, grey} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {Chart, Circle, End, FieldImgBackground, FieldWrapper, Middle, Start, ZoneLabels} from './ThrowinsMap';

ThrowinsMap.propTypes = {
  weAtHome: PropTypes.bool,
  statisticsIds: PropTypes.array,
  getter: PropTypes.func.isRequired,
  loader: PropTypes.func.isRequired,
  cleanup: PropTypes.func,
  filters: PropTypes.object,
};

const legendItems = [
  {color: darkRed['100'], label: 'Победа'},
  {color: grey['68'], label: 'Поражение'},
  {color: grey['26'], label: 'Нейтральная'},
];
export default function ThrowinsMap({
  weAtHome = false,
  statisticsIds = [],
  getter = () => {},
  loader = () => {},
  cleanup = () => {},
  filters = {},
}) {
  const dispatch = useStoreDispatch();
  const {data, isLoading, isLoad, error} = useStore(getter);

  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [statisticsIds]});

  const isXsResolution = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {teamColors, playZones} = useMemo(
    () =>
      weAtHome
        ? {teamColors: TEAM_COLORS, playZones: [...PLAY_ZONES].reverse()}
        : {teamColors: [...TEAM_COLORS].reverse(), playZones: PLAY_ZONES},
    [weAtHome],
  );

  useDeepEffect(() => {
    statisticsIds?.length && dispatch(loader({statisticsIds, ...filters, cancelToken: newCancelToken()}, isCancel));
    return () => {
      dispatch(cleanup());
    };
  }, [loader, statisticsIds, filters]);

  return (
    <>
      <ThrowinsMetricsCharts
        error={error}
        isLoad={isLoad}
        isLoading={isLoading}
        weAtHome={weAtHome}
        zones={data?.zones?.length ? data.zones : []}
      />
      <Stack pb={{xxs: 2, xs: 4}} pt={6}>
        <FieldWrapper weathome={weAtHome}>
          <LocalPreloader bgcolor="transparent" visible={isLoading} />
          <FieldImgBackground nodata={!isLoad || error || isLoading} src={isXsResolution ? schemaVertical : schema} />
          <Chart>
            {(isLoad && !error) || isLoading ? (
              <>
                <Start>
                  {[
                    {item: data?.circleTwo, number: 2},
                    {item: data?.circleOne, number: 1},
                  ].map(({item, number}, index) => (
                    <Circle
                      colors={teamColors}
                      key={index}
                      loading={isLoading}
                      score={[item?.home, item?.away, item?.percent]}
                      width={{xxs: '39%', xs: '53%'}}
                    >
                      <Typography variant="caption1">{number}</Typography>
                    </Circle>
                  ))}
                </Start>
                <Middle>
                  <Stack
                    alignItems="center"
                    direction={{xxs: 'column', xs: 'row'}}
                    justifyContent="space-around"
                    width={{xxs: 100 / 3 + '%', xs: 'auto'}}
                  >
                    {[
                      {item: data?.circleFour, number: 4},
                      {item: data?.circleSeven, number: 7},
                    ].map(({item, number}, index) => (
                      <Circle
                        colors={teamColors}
                        key={index}
                        loading={isLoading}
                        score={[item?.home, item?.away, item?.percent]}
                        width={{xxs: '92%', xs: '46%'}}
                      >
                        <Typography variant="caption1">{number}</Typography>
                      </Circle>
                    ))}
                  </Stack>
                  <Stack
                    alignItems="center"
                    direction={{xxs: 'column', xs: 'row'}}
                    justifyContent="center"
                    width={{xxs: 100 / 3 + '%', xs: '100%'}}
                  >
                    <Circle
                      colors={teamColors}
                      loading={isLoading}
                      score={[data?.circleFive?.home, data?.circleFive?.away, data?.circleFive?.percent]}
                      width={{xxs: '92%', xs: '46%'}}
                    >
                      <Typography variant="caption1">5</Typography>
                    </Circle>
                  </Stack>
                  <Stack
                    alignItems="center"
                    direction={{xxs: 'column', xs: 'row'}}
                    justifyContent="space-around"
                    width={{xxs: 100 / 3 + '%', xs: 'auto'}}
                  >
                    {[
                      {item: data?.circleThree, number: 3},
                      {item: data?.circleSix, number: 6},
                    ].map(({item, number}, index) => (
                      <Circle
                        colors={teamColors}
                        key={index}
                        loading={isLoading}
                        score={[item?.home, item?.away, item?.percent]}
                        width={{xxs: '92%', xs: '46%'}}
                      >
                        <Typography variant="caption1">{number}</Typography>
                      </Circle>
                    ))}
                  </Stack>
                </Middle>
                <End>
                  {[
                    {item: data?.circleNine, number: 9},
                    {item: data?.circleEight, number: 8},
                  ].map(({item, number}, index) => (
                    <Circle
                      colors={teamColors}
                      key={index}
                      loading={isLoading}
                      score={[item?.home, item?.away, item?.percent]}
                      width={{xxs: '39%', xs: '53%'}}
                    >
                      <Typography variant="caption1">{number}</Typography>
                    </Circle>
                  ))}
                </End>
              </>
            ) : (
              <Stack alignItems="center" height="100%" justifyContent="center" width="100%">
                <TypographyDefault m="auto" variant="subtitle2">
                  Данные по вбрасываниям отсутствуют
                </TypographyDefault>
              </Stack>
            )}
          </Chart>
          <ZoneLabels
            direction={{xxs: 'column', xs: 'row'}}
            height={{xxs: '101%', xs: 'auto'}}
            justifyContent="space-between"
            px={{xxs: '0', xs: '7%'}}
          >
            {playZones.map((item, index) => (
              <TypographyDefault key={index} textAlign={{xxs: 'center', xs: 'left'}} variant="body1">
                {item}
              </TypographyDefault>
            ))}
          </ZoneLabels>
        </FieldWrapper>
      </Stack>
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={{xxs: 1, xs: 4}} justifyContent="center" mt={2}>
        {legendItems.map(({color, label}, index) => (
          <Stack alignItems="center" direction="row" gap={1} key={index}>
            <CircleIcon sx={{width: 8, fill: color}} />
            <Typography variant="body2">{label}</Typography>
          </Stack>
        ))}
      </Stack>
    </>
  );
}
