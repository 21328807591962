import hockeyStick from '@assets/images/hockeyStick.svg';
import TeamLogo from '@common/Statistics/PlayerStatistics/TeamLogo';
import date from '@helpers/date';
import {SportsHockey} from '@mui/icons-material';
import {Tooltip, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import {bool, string} from 'prop-types';
import React from 'react';

TeamNameCell.propTypes = {
  value: string,
  isNext: bool,
  isLoading: bool,
};

function TeamNameCell({value, isNext, isLoading}) {
  return (
    <Tooltip
      title={
        isNext ? (
          <Typography align="center" color="#fff" variant="caption2">
            Следующий соперник
            <br />
            {date(isNext?.date).format('DD.MM.YYYY')}
          </Typography>
        ) : (
          ''
        )
      }
    >
      <Stack direction="row" position="inherit" spacing={1} width={140}>
        <TeamLogo src={isLoading ? hockeyStick : value?.teamLogo} width={20} />
        <Typography noWrap isLoading={isLoading} preloaderWidthBetween={[100, 110]} variant="body2">
          {value?.teamName}
        </Typography>
        {isNext && <SportsHockey sx={{fill: grey['68']}} variant="body2" />}
      </Stack>
    </Tooltip>
  );
}

export default TeamNameCell;
