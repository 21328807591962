import React from 'react';

function SearchGrey() {
  return (
    <React.Fragment>
      <path
        d="M21.7509 20.6776L15.8566 14.7468C17.434 13.1439 18.2642 11.02 18.2642 8.81603C18.2642 3.96721 14.1547 0 9.13208 0C4.10943 0 0 3.96721 0 8.81603C0 13.6648 4.10943 17.6321 9.13208 17.6321C11.1245 17.6321 13.0755 16.9909 14.6528 15.8288L20.5887 21.7596C20.7547 21.9199 20.9623 22 21.1698 22C21.3774 22 21.5849 21.9199 21.7509 21.7596C21.917 21.6393 22 21.439 22 21.2386C22 21.0383 21.917 20.8379 21.7509 20.6776ZM9.13208 1.60291C13.2415 1.60291 16.6038 4.84882 16.6038 8.81603C16.6038 12.7832 13.2415 16.0291 9.13208 16.0291C5.02264 16.0291 1.66038 12.7832 1.66038 8.81603C1.66038 4.84882 5.02264 1.60291 9.13208 1.60291Z"
        fill="#C4C4C4"
      />
    </React.Fragment>
  );
}
export default SearchGrey;
