import React from 'react';

function HockeyPitchColorful() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M7.75873 2.32761H37.2415C39.8127 2.32761 41.8967 4.41158 41.8967 6.98282V38.0173C41.8967 40.5885 39.8127 42.6725 37.2415 42.6725H7.75873C5.18749 42.6725 3.10352 40.5885 3.10352 38.0173V6.98273C3.10352 4.41158 5.18749 2.32761 7.75873 2.32761Z"
          fill="#E7ECED"
        />
        <path
          d="M22.4999 27.1552C19.9334 27.1552 17.8447 25.0674 17.8447 22.5C17.8447 19.9326 19.9334 17.8448 22.4999 17.8448C25.0665 17.8448 27.1552 19.9327 27.1552 22.5C27.1552 25.0673 25.0665 27.1552 22.4999 27.1552ZM22.4999 19.3966C20.7884 19.3966 19.3965 20.7885 19.3965 22.5C19.3965 24.2115 20.7884 25.6034 22.4999 25.6034C24.2114 25.6034 25.6034 24.2115 25.6034 22.5C25.6034 20.7885 24.2115 19.3966 22.4999 19.3966Z"
          fill="#A9AAAC"
        />
        <path
          d="M18.6206 38.0173H26.3792C26.3792 35.8751 24.6421 34.1379 22.4999 34.1379C20.3578 34.1379 18.6206 35.8751 18.6206 38.0173Z"
          fill="#A9AAAC"
        />
        <path
          d="M26.3792 6.98273H18.6206C18.6206 9.12489 20.3578 10.862 22.4999 10.862C24.6421 10.862 26.3792 9.12489 26.3792 6.98273Z"
          fill="#A9AAAC"
        />
        <path
          d="M34.1381 18.6207C31.999 18.6207 30.2588 16.8804 30.2588 14.7414C30.2588 12.6023 31.999 10.862 34.1381 10.862C36.2772 10.862 38.0174 12.6023 38.0174 14.7414C38.0174 16.8804 36.2771 18.6207 34.1381 18.6207ZM34.1381 12.4138C32.8548 12.4138 31.8105 13.4581 31.8105 14.7414C31.8105 16.0247 32.8548 17.069 34.1381 17.069C35.4214 17.069 36.4657 16.0247 36.4657 14.7414C36.4657 13.4581 35.4214 12.4138 34.1381 12.4138Z"
          fill="#D53D33"
        />
        <path
          d="M10.8622 18.6207C8.72314 18.6207 6.98291 16.8804 6.98291 14.7414C6.98291 12.6023 8.72314 10.862 10.8622 10.862C13.0013 10.862 14.7415 12.6023 14.7415 14.7414C14.7415 16.8804 13.0013 18.6207 10.8622 18.6207ZM10.8622 12.4138C9.57894 12.4138 8.53462 13.4581 8.53462 14.7414C8.53462 16.0247 9.57894 17.069 10.8622 17.069C12.1455 17.069 13.1898 16.0247 13.1898 14.7414C13.1898 13.4581 12.1455 12.4138 10.8622 12.4138Z"
          fill="#D53D33"
        />
        <path
          d="M3.10352 4.65521V6.98282H41.8967V4.65521C41.8967 2.08397 39.8127 0 37.2415 0H7.75873C5.18749 0 3.10352 2.08477 3.10352 4.65521Z"
          fill="#D53D33"
        />
        <path
          d="M10.8622 34.1379C8.72314 34.1379 6.98291 32.3977 6.98291 30.2586C6.98291 28.1196 8.72314 26.3793 10.8622 26.3793C13.0013 26.3793 14.7415 28.1196 14.7415 30.2586C14.7415 32.3977 13.0013 34.1379 10.8622 34.1379ZM10.8622 27.931C9.57894 27.931 8.53462 28.9753 8.53462 30.2586C8.53462 31.5419 9.57894 32.5862 10.8622 32.5862C12.1455 32.5862 13.1898 31.5419 13.1898 30.2586C13.1898 28.9753 12.1455 27.931 10.8622 27.931Z"
          fill="#D53D33"
        />
        <path
          d="M34.1381 34.1379C31.999 34.1379 30.2588 32.3977 30.2588 30.2586C30.2588 28.1196 31.999 26.3793 34.1381 26.3793C36.2772 26.3793 38.0174 28.1196 38.0174 30.2586C38.0174 32.3977 36.2771 34.1379 34.1381 34.1379ZM34.1381 27.931C32.8548 27.931 31.8105 28.9753 31.8105 30.2586C31.8105 31.5419 32.8548 32.5862 34.1381 32.5862C35.4214 32.5862 36.4657 31.5419 36.4657 30.2586C36.4657 28.9753 35.4214 27.931 34.1381 27.931Z"
          fill="#D53D33"
        />
        <path
          d="M41.8967 40.3448V38.0172H3.10352V40.3448C3.10352 42.916 5.18749 45 7.75873 45H37.2415C39.8127 45 41.8967 42.9152 41.8967 40.3448Z"
          fill="#D53D33"
        />
        <path d="M41.8966 21.7241H3.10352V23.2758H41.8966V21.7241Z" fill="#A9AAAC" />
      </g>
    </React.Fragment>
  );
}
export default HockeyPitchColorful;
