import DesktopViewStages from '@common/Statistics/PlayOff/components/DesktopViewStages';
import MobileViewStages from '@common/Statistics/PlayOff/components/MobileViewStages';
import {mock} from '@common/Statistics/PlayOff/components/MobileViewStages/mock';
import {Stages} from '@common/Statistics/PlayOff/types';
import {FormData, PlayoffItems} from '@components/apps/Tournaments/components/TournamentView/types';
import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {Theme, useMediaQuery} from '@mui/material';
import {selectGames} from '@selectors/tournaments';
import {clearGames, loadGames} from '@slices/tournaments';
import {InitialStateIndicators} from '@src/types';
import {StagesEnum} from '@src/types/tournament';
import {Box, Stack} from '@ui/MUI';
import {isEmpty} from 'lodash';
import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

function getFractionBottom(str: string): number {
  return str.includes('/') ? Number(str.split('/')[1]) : 0;
}

type Games = {
  stages: Stages;
} & InitialStateIndicators<FormData>;

const {GROUP, FINAL, ...playOffStages} = StagesEnum;
const stagesArr = [
  ...Object.values(playOffStages)
    .sort((a, b) => Number(a.replace(/\\/g, '')) - Number(b.replace(/\\/g, '')))
    .reverse(),
  FINAL,
];
function PlayOff() {
  const dispatch = useStoreDispatch();
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const {stages, isLoading} = useStore<Games>((state) => {
    const games = selectGames(state);
    const data = games.data;
    const {group, ...restStages} = data;

    let stages = [] as Games['stages'];

    if (!isEmpty(data)) {
      const dataEntries = Object.entries(restStages).sort((a, b) => getFractionBottom(b[0]) - getFractionBottom(a[0]));
      const maxStageIndex = stagesArr.findIndex((stage) => stage === dataEntries?.[0]?.[0]);
      if (maxStageIndex >= 0) {
        stages = stagesArr
          .slice(maxStageIndex, stagesArr?.length)
          .map((stage) => ({stage, games: (data[stage] || []) as PlayoffItems}));
      }
    }

    return {...games, stages};
  }, shallowEqual);

  const {id} = useParams<{id: string}>();

  useEffect(() => {
    dispatch(loadGames({tournament: id}));
    return () => {
      dispatch(clearGames());
    };
  }, [id]);
  return (
    <Box overflow={{xxs: 'hidden', sm: 'auto'}} width="100%">
      <Stack
        alignItems="stretch"
        direction="row"
        justifyContent={{xxs: 'center', sm: stages?.length && stages?.length <= 2 ? 'center' : 'unset'}}
        minHeight={500}
        p={3}
        width="100%"
      >
        {isSm ? (
          <MobileViewStages
            isLoading={isLoading}
            stages={isLoading || (!isLoading && !stages?.length) ? mock : stages}
          />
        ) : (
          <DesktopViewStages
            isLoading={isLoading}
            stages={isLoading || (!isLoading && !stages?.length) ? mock : stages}
            stagesArr={stagesArr}
          />
        )}
      </Stack>
    </Box>
  );
}

export default PlayOff;
