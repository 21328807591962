import DrawerDefault from '@ui/MUI/Modals/Drawer/Drawer';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {NOTIFICATION_TYPES} from '../../../../constants/config';
import CommonHelper from '../../../../helpers/CommonHelper';
import StateHelper from '../../../../helpers/StateHelper';
import * as dashboardsActions from '../../../../redux/actions/DashboardsActions';
import LessonView from '../../../calendar/components/sidebar/components/LessonView';
import LocalPreloader from '../../../preloader/LocalPreloader';
import {
  DOCTOR_STATUSES_OPTIONS,
  DOCTOR_SUB_STATUSES_OPTIONS,
  NOTAVILABLE_SUB_STATUSES_OPTIONS,
} from '../../../students/constants';
import PlayersHorizontalCard from '../../../students/tools/PlayersHorizontalCard';
import ChessTemplate from '../../../templates/ChessTemplate';
import TabsDefault from '../../../ui/MUI/Tabs';
import {ReactComponentNotification} from '../../../ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import TableEmptyBlock from '../../../ui/TableEmptyBlock';
import IllnessPlayers from '../../../widgets/IllnessPlayers';
import InjuredPlayers from '../../../widgets/InjuredPlayers';
import MissingPlayers from '../../../widgets/MissingPlayers';
import PastGamesOfSeason from '../../../widgets/PastGamesOfSeason';
import {getSortedIceTeamEvents} from '../../helper';
import DashboardsBlock from '../../trainers-dashboard/components/DashboardsBlock';
import {
  BoxShadow,
  CardContainer,
  CardFlexWrapper,
  Column,
  Container,
  ContentBody,
  ContentRow,
  ContentRowText,
  ContentRowTextBlock,
  ContentText,
  ContentTextScheduleItem,
  ContentWrapper,
  ContentWrapperSchedule,
  DashboardsBlockWrapper,
  Half,
  HeadlineColumnTitle,
  HeadlineSpaceBetween,
  ScheduleItem,
  ScheduleItemRoom,
} from '../styles';

export default function TrainersDashboardPanel() {
  const dispatch = useDispatch();

  const [lessonViewVisible, setLessonViewVisible] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });
  const [lessonViewLoading, setLessonViewLoading] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState({});
  let history = useHistory();

  const [fullLessonInformation, setFullLessonInformation] = useState({
    players: [],
  });

  useEffect(() => {
    dispatch(dashboardsActions.getTeachersDashboard());
  }, []);

  const {loading, group, teachersDashboard, teacherRole} = useSelector((state) => {
    const id = state.users.currentUser.id;
    return {
      loading: state.dashboard.loading,
      group: StateHelper.getTeacherGroups(state, id)[0],
      teachersDashboard: state.dashboard.teachersDashboard,
      teacherRole: state.users.currentUser.teacherRole,
    };
  });

  const injuredPlayers = CommonHelper.getSortedUsers(teachersDashboard?.injuredPlayers);
  const missingPlayers = CommonHelper.getSortedUsers(teachersDashboard?.missingPlayers);
  const illnessPlayers = CommonHelper.getSortedUsers(teachersDashboard?.illnesPlayers);
  const nextGame = teachersDashboard?.nextGame;
  const filteredSchedule = getSortedIceTeamEvents(teachersDashboard?.schedule);
  const pastGames = teachersDashboard?.pastGames;

  const constructEventName = (event) => {
    const matrix = {
      training: 'Групповая тренировка',
      game: `${event.group.name || ''} - ${event.opponent || ''}`,
      away_game: `${event.opponent || ''} - ${event.group.name || ''}`,
      warmup: 'Раскатка',
    };
    return matrix[event.type];
  };

  const calculateTimeEnd = (timeStart, duration) => {
    return timeStart + duration;
  };
  const allStatuses = DOCTOR_SUB_STATUSES_OPTIONS.concat(NOTAVILABLE_SUB_STATUSES_OPTIONS, DOCTOR_STATUSES_OPTIONS);

  const [pastGamesActiveTab, setPastGamesActiveTab] = useState(0);
  const [pastGamesActiveNameGroup, setPastGamesActiveNameGroup] = useState('');

  const pastGamesGroupNames = pastGames?.map((el) => {
    return el.group.name;
  });

  let uniqueGroupNames = [...new Set(pastGamesGroupNames)];

  const pastGamesFiltered = pastGames?.filter((el) => el.group.name === pastGamesActiveNameGroup);

  useEffect(() => {
    setPastGamesActiveNameGroup(uniqueGroupNames[pastGamesActiveTab]);
  }, [pastGames]);

  const contentBlock = (players, contentStatus, emptyText, statusText) => {
    return (
      <ContentWrapper>
        {players.length ? (
          <>
            <BoxShadow>
              <HeadlineSpaceBetween>
                <Column>
                  <HeadlineColumnTitle>ФИО игрока</HeadlineColumnTitle>
                </Column>
                <Column>
                  <HeadlineColumnTitle>{statusText ? statusText : 'Статус'}</HeadlineColumnTitle>
                </Column>
              </HeadlineSpaceBetween>
            </BoxShadow>
            <ContentBody>
              {players.map((item) => {
                return (
                  <ContentRow key={item.objectId}>
                    <CardContainer>
                      <Link target="_blank" to={`/student/${item.objectId}`}>
                        <CardFlexWrapper>
                          <PlayersHorizontalCard student={item} />
                        </CardFlexWrapper>
                      </Link>
                    </CardContainer>
                    <ContentRowTextBlock>
                      <ContentRowText>
                        {item.subStatus ? allStatuses.find((status) => status.value === item.subStatus)?.label : ''}
                      </ContentRowText>
                    </ContentRowTextBlock>
                  </ContentRow>
                );
              })}
            </ContentBody>
          </>
        ) : (
          <TableEmptyBlock text={emptyText} />
        )}
      </ContentWrapper>
    );
  };
  return (
    <ChessTemplate active="index">
      <PreloaderContainer>
        <LocalPreloader visible={loading} />
        <Wrapper>
          {teachersDashboard ? (
            <>
              <DashboardsBlockWrapper>
                <DashboardsBlock
                  content={
                    <ContentWrapper>
                      {nextGame ? (
                        <WrapperHeadlineTextBlockInfo>
                          <HeadlineTextBlockInfo>
                            <HeadlineTextBlockInfoDate>
                              Дата игры:{' '}
                              <span style={{color: '#887755'}}>
                                {moment(nextGame.rooms[0].startTimestamp).format('DD.MM.YYYY')}{' '}
                              </span>
                            </HeadlineTextBlockInfoDate>
                            <HeadlineTextBlockInfoTime>
                              Время:{' '}
                              <span style={{color: '#990011'}}>
                                {moment(nextGame.rooms[0].startTimestamp).format('HH:mm')}{' '}
                              </span>
                            </HeadlineTextBlockInfoTime>
                            <HeadlineTextBlockInfoDark>
                              {nextGame.type !== 'away_game'
                                ? `${nextGame.group.name || ''} - ${nextGame.opponent || ''}`
                                : `${nextGame.opponent || ''} - ${nextGame.group.name || ''}`}
                            </HeadlineTextBlockInfoDark>
                          </HeadlineTextBlockInfo>
                          <WrapperButtonActualTeam>
                            <ButtonActualTeam
                              onClick={() => {
                                history.push('/lines');
                              }}
                            >
                              Актуализировать состав
                            </ButtonActualTeam>
                          </WrapperButtonActualTeam>
                        </WrapperHeadlineTextBlockInfo>
                      ) : (
                        <TableEmptyBlock text="У команды не запланировано игр в ближайшее время" />
                      )}
                    </ContentWrapper>
                  }
                  title="Следующая игра"
                />
              </DashboardsBlockWrapper>

              <Container>
                <Half>
                  <DashboardsBlock
                    buttonsBlockType="link"
                    content={
                      <ContentWrapperSchedule>
                        {filteredSchedule.length ? (
                          <>
                            <BoxShadow>
                              <HeadlineSpaceBetween>
                                <Column>
                                  <HeadlineColumnTitle>Время</HeadlineColumnTitle>
                                </Column>
                                <Column>
                                  <HeadlineColumnTitle>Тип мероприятия</HeadlineColumnTitle>
                                </Column>
                                <Column>
                                  <HeadlineColumnTitle>Помещение</HeadlineColumnTitle>
                                </Column>
                              </HeadlineSpaceBetween>
                            </BoxShadow>
                            <ContentBody>
                              {filteredSchedule.map((item) => {
                                const timeEnd = item.startTimestamp
                                  ? calculateTimeEnd(item.startTimestamp, item.rooms[0].duration)
                                  : calculateTimeEnd(item.rooms[0].startTimestamp, item.rooms[0].duration);
                                return (
                                  <ScheduleItem
                                    key={item.objectId}
                                    onClick={() => {
                                      //ToDo Рефакторинг
                                      setSelectedLesson(item);
                                      if (item.group) {
                                        setLessonViewLoading(true);
                                        StateHelper.getStudentsByTeamId(item.groupId)
                                          .then((res) => {
                                            setFullLessonInformation({
                                              players: res,
                                            });
                                            setLessonViewLoading(false);
                                          })
                                          .catch((error) => {
                                            setLessonViewLoading(false);
                                            ReactComponentNotification(
                                              NOTIFICATION_TYPES['error'],
                                              `Ошибка сервера: ${error}, попробуйте позже`,
                                            );
                                          });
                                      }
                                      setLessonViewVisible({...lessonViewVisible, right: true});
                                    }}
                                  >
                                    <Column>
                                      <ContentTextScheduleItem>
                                        {moment(item.startTimestamp).format('HH:mm')} -{' '}
                                        {moment(timeEnd).format('HH:mm')}
                                      </ContentTextScheduleItem>
                                    </Column>
                                    <Column>
                                      <ContentText className="ScheduleItemText">{constructEventName(item)}</ContentText>
                                    </Column>
                                    <Column>
                                      <ScheduleItemRoom>{item.rooms[0].name || ''}</ScheduleItemRoom>
                                    </Column>
                                  </ScheduleItem>
                                );
                              })}
                            </ContentBody>
                          </>
                        ) : (
                          <TableEmptyBlock text="На сегодня мероприятий больше нет" />
                        )}
                      </ContentWrapperSchedule>
                    }
                    link="/schedule?viewMode=week"
                    linkText="Посмотреть расписание на неделю"
                    marginBottom="8px !important"
                    title="Расписание на сегодня"
                    titleSpan={`${moment().format('DD MMMM, dd')}`}
                  />
                  {injuredPlayers?.length !== 0 && (
                    <InjuredPlayers contentBlock={contentBlock} injuredPlayers={injuredPlayers} />
                  )}
                  {missingPlayers?.length !== 0 && (
                    <MissingPlayers contentBlock={contentBlock} missingPlayers={missingPlayers} />
                  )}
                  {illnessPlayers?.length !== 0 && (
                    <IllnessPlayers contentBlock={contentBlock} illnessPlayers={illnessPlayers} />
                  )}
                </Half>

                <Half>
                  <DashboardsBlock
                    content={
                      <ContentWrapperSchedule>
                        <BoxShadow>
                          <TabsDefault
                            tabs={{
                              headlineItems: uniqueGroupNames?.map((name) => ({
                                label: name,
                              })),
                            }}
                            value={Number(pastGamesActiveTab)}
                            onChange={(e) => {
                              const nameGroup = uniqueGroupNames[e];
                              setPastGamesActiveTab(e);
                              setPastGamesActiveNameGroup(nameGroup);
                            }}
                          />
                        </BoxShadow>
                        <PastGamesOfSeason
                          groupId={pastGamesFiltered && pastGamesFiltered[0]?.group?.objectId}
                          hasHeadlineBlock={false}
                          isTeacherWhichCanEstimateGames={
                            teacherRole === 'default' || teacherRole === 'main' || teacherRole === 'goaltender'
                          }
                          isTeacherWhichCanNotEstimateGames={
                            teacherRole === 'wingers_trainer' || teacherRole === 'defensemens_trainer'
                          }
                          pastGames={pastGamesFiltered}
                        />
                      </ContentWrapperSchedule>
                    }
                    title="Прошедшие игры сезона"
                  />
                </Half>
              </Container>

              <DrawerDefault
                content={
                  <>
                    <LocalPreloader visible={lessonViewLoading} />
                    <ModalTitle>Просмотр мероприятия</ModalTitle>
                    <LessonView
                      groups={[selectedLesson.group]}
                      isButtonsShowed={false}
                      players={fullLessonInformation.players}
                      selectedLesson={selectedLesson}
                    />
                  </>
                }
                drawerState={lessonViewVisible}
                setDrawerState={setLessonViewVisible}
              />
            </>
          ) : (
            <TableEmptyBlock
              text={
                group
                  ? 'Информация для рабочего стола отсутствует. Возможно что-то пошло не так.'
                  : 'Вы не являетесь тренером ни одной команды.'
              }
            />
          )}
        </Wrapper>
      </PreloaderContainer>
    </ChessTemplate>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding-top: 8px;
  padding-right: 6px;
  .EventModal {
    max-width: 60%;
    @media screen and (max-width: 767px) {
      max-width: none;
      width: 100% !important;
    }
    .sidebar__header {
      border-bottom: 0;
    }
    .Heading {
      @media screen and (max-width: 767px) {
        top: -8px;
      }
    }
  }
  .LocalPreloader {
    @media screen and (max-width: 767px) {
      top: 20px;
    }
  }
`;

const ModalTitle = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  font: bold normal 16px/20px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

const PreloaderContainer = styled.div`
  position: relative;
  .LocalPreloader {
    top: 16px;
    border-radius: 6px;
  }
`;
const HeadlineTextBlockInfo = styled.div`
  display: flex;
  padding: 18px 24px 0;
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 18px/1 'Bebas Neue';
  color: black;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    padding: 0 24px 0;
  }
  @media screen and (max-width: 500px) {
    padding: 12px 12px 0;
    font-size: 16px;
  }
`;
const HeadlineTextBlockInfoDate = styled.span`
  padding-left: 0;
  padding-right: 4px;
  color: #000000;
  font: bold normal 28px/22px 'Bebas Neue';

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;
const HeadlineTextBlockInfoTime = styled.span`
  padding-right: 8px;
  font: bold normal 28px/22px 'Bebas Neue';
  color: #2c2c2c;
  margin-left: 30px;
  @media screen and (max-width: 500px) {
    margin-top: 5px;
    margin-left: 0;
    font-size: 20px;
  }
`;
const HeadlineTextBlockInfoDark = styled.span`
  font: bold normal 28px/22px 'Bebas Neue';
  margin-left: 40px;
  color: #000000;
  @media screen and (max-width: 500px) {
    margin-top: 5px;
    margin-left: 0;
    font-size: 20px;
  }
`;

const WrapperHeadlineTextBlockInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonActualTeam = styled.div`
  cursor: pointer;
  margin-right: 30px;
  margin-top: 10px;
  padding: 10px 24px 10px;
  display: flex;
  align-items: center;
  color: white;
  background: #ee1122;
  border-radius: 30px;
  @media screen and (max-width: 500px) {
    justify-content: center;
    margin-right: 0;
  }
`;

const WrapperButtonActualTeam = styled.div`
  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;
