import moment from 'moment';
import React, {useMemo} from 'react';

import useFormatedLessons from './hooks/useFormatedLessons';
import {Cell, Container, Content, Row, Table} from './PrintTable';

const PrintTable = (props) => {
  const date = useMemo(() => {
    if (props.calendarRef.current) {
      const Calendar = props.calendarRef.current?.getApi();
      return Calendar.getDate();
    }
  }, [props.events]);
  const tables = useFormatedLessons({...props, date});
  return (
    <div style={{display: 'none'}}>
      <Container ref={props.printRef}>
        {tables.length > 0 &&
          tables.map((events) => {
            return (
              events.events &&
              events.events.some((event) => event.events.length) > 0 && (
                <Table>
                  <Content center margin="10px 0">
                    График
                  </Content>
                  <Content center margin="10px 0">
                    загрузки {events.school} {events.title} {events.period}
                  </Content>
                  <Row>
                    <Cell size={6}>
                      <Content center>Время</Content>
                    </Cell>
                    <Cell size={5}>
                      <Content center>Команда</Content>
                    </Cell>
                    <Cell size={3}>
                      <Content center>Тип мероприятия</Content>
                    </Cell>
                    <Cell size={7}>
                      <Content center>Раздевалка</Content>
                    </Cell>
                    <Cell size={4}>
                      <Content center>Зал</Content>
                    </Cell>
                  </Row>
                  {events.events.map((day) => {
                    const date = moment(day.date);
                    const dateTitle = date.format('dddd');
                    const dateDay = date.format('DD');
                    const dateMouth = date.format('MMMM');
                    return (
                      <>
                        <Row>
                          <Cell center size={1}>
                            <Content center>
                              {dateTitle.toUpperCase()} {dateDay} {dateMouth}
                            </Content>
                          </Cell>
                        </Row>
                        {day.events.length === 0 && (
                          <Row>
                            <Cell size={1}>
                              <Content center>Мероприятия отсутствуют</Content>
                            </Cell>
                          </Row>
                        )}
                        {day.events.map((event, index) => {
                          const start = moment(event.start).format('HH:mm');
                          const end = moment(event.end).format('HH:mm');
                          return (
                            <Row key={index}>
                              <Cell noPadding size={6}>
                                <Cell size={2}>
                                  <Content center>{start}</Content>
                                </Cell>
                                <Cell noBorder size={2}>
                                  <Content center>{end}</Content>
                                </Cell>
                              </Cell>
                              <Cell size={5}>
                                <Content>{event?.extendedProps?.fullName || event?.extendedProps?.groupName}</Content>
                              </Cell>
                              <Cell size={3.1}>
                                <Content center>{event.title}</Content>
                              </Cell>
                              <Cell size={7}>
                                <Content center>{event?.extendedProps?.lesson?.lockerRoom}</Content>
                              </Cell>
                              <Cell size={4}>
                                <Content center>{event.extendedProps.roomName}</Content>
                              </Cell>
                            </Row>
                          );
                        })}
                      </>
                    );
                  })}
                </Table>
              )
            );
          })}
      </Container>
    </div>
  );
};

export default PrintTable;
