import {LocalPreloaderWrapper} from '@components/apps/FunctionalTesting/components/CreateEditReport/CreateEditReport';
import TableEditCreate from '@components/apps/FunctionalTesting/components/CreateEditReport/EditCreateTable';
import {actions} from '@components/apps/FunctionalTesting/constants';
import LocalPreloader from '@components/preloader/LocalPreloader';
import {getTeamName} from '@helpers/CommonHelper';
import date from '@helpers/date';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectTableEditCreate} from '@selectors/functionalTesting';
import {fetchDateForReportByDate} from '@slices/functionalTesting';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {formatTableData} from '../../helpers';

const CreateEditReport = () => {
  const {isLoading, items} = useStore(selectTableEditCreate);
  const tableData = items;
  const {teamId, typeAction, reportDate} = useParams();

  const [teamName, setTeamName] = useState();

  useEffect(() => {
    getTeamName(teamId).then((res) => {
      setTeamName(res);
    });
  }, [teamId]);

  const isEdit = actions.actionEdit === typeAction;

  const dispatch = useStoreDispatch();

  useEffect(() => {
    if (isEdit) {
      dispatch(fetchDateForReportByDate(teamId, reportDate));
    }
  }, [teamId]);

  return (
    <>
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {
            label: 'Функциональное тестирование',
            path: '/functional_testing',
          },
          {
            label: teamName,
            path: `/functional_testing/${teamId}`,
          },
          {
            label:
              actions.actionEdit === typeAction
                ? `Редактирование отчёта за ${date(reportDate).format('DD.MM.YYYY')}`
                : `Новый отчёт за ${date(reportDate).format('DD.MM.YYYY')}`,
          },
        ]}
      >
        {actions.actionEdit === typeAction
          ? `Редактирование отчёта за ${date(reportDate).format('DD.MM.YYYY')}`
          : `Новый отчёт. Текущее тестирование`}
      </PageTitleDefault>
      {isLoading ? (
        <LocalPreloaderWrapper isLoading={isLoading} marginBottom="8px" padding={'0 24px'}>
          <LocalPreloader visible={true} />
        </LocalPreloaderWrapper>
      ) : (
        <TableEditCreate isEdit={isEdit} tableData={tableData && formatTableData(tableData)} />
      )}
    </>
  );
};

export default CreateEditReport;
