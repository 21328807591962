import {CircularProgress} from '@mui/material';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Indicator = styled(CircularProgress)`
  position: absolute;
  top: 10px;
`;

export const Button = styled.button`
  min-height: 42px;
  border: none;
  font-weight: 600;
  font-size: 18px;
  background: #d32f2f;
  border-radius: 4px;
  color: white;
  width: 100%;
  &:disabled {
    background: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
  }
  cursor: pointer;
`;
