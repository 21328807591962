import bustsInSilhouette from '@assets/emoji/busts-in-silhouette.png';
import checkMarkButton from '@assets/emoji/check-mark-button.png';
import goalNet from '@assets/emoji/goal-net.png';
import grinningFace from '@assets/emoji/grinning-face.png';
import iceHockey from '@assets/emoji/ice-hockey.png';
import pensiveFace from '@assets/emoji/pensive-face.png';
import perseveringFace from '@assets/emoji/persevering-face.png';
import slightlySmilingFace from '@assets/emoji/slightly-smiling-face.png';
import starStruck from '@assets/emoji/star-struck.png';
import sun from '@assets/emoji/sun.png';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const emojis = {
  pensiveFace: {
    name: 'pensiveFace',
    src: pensiveFace,
  },
  sun: {
    name: 'sun',
    src: sun,
  },
  iceHockey: {
    name: 'iceHockey',
    src: iceHockey,
  },
  bustsInSilhouette: {
    name: 'bustsInSilhouette',
    src: bustsInSilhouette,
  },
  checkMarkButton: {
    name: 'checkMarkButton',
    src: checkMarkButton,
  },
  goalNet: {
    name: 'goalNet',
    src: goalNet,
  },
  grinningFace: {
    name: 'grinningFace',
    src: grinningFace,
  },
  slightlySmilingFace: {
    name: 'slightlySmilingFace',
    src: slightlySmilingFace,
  },
  perseveringFace: {
    name: 'perseveringFace',
    src: perseveringFace,
  },
  starStruck: {
    name: 'starStruck',
    src: starStruck,
  },
};

EmojiIcon.propTypes = {
  name: PropTypes.oneOf([
    'pensiveFace',
    'sun',
    'iceHockey',
    'bustsInSilhouette',
    'checkMarkButton',
    'goalNet',
    'grinningFace',
    'slightlySmilingFace',
    'perseveringFace',
    'starStruck',
  ]).isRequired,
};

function EmojiIcon({name, ...props}) {
  return emojis?.[name]?.name ? (
    <Image alt={emojis[name].name} draggable={false} src={emojis[name].src} {...props} />
  ) : null;
}

export default EmojiIcon;

const Image = styled.img`
  width: 20px;
  display: inline-block;
  user-select: none;
  vertical-align: center;
`;
