export const getMaterials = (state) => {
  return state.materialsModule.materials;
};

export const getMaterialsTypes = (state) => {
  return state.materialsModule.materialTypes;
};

export const getMaterialsFormState = (state) => {
  return state.materialsModule.form;
};

export const getMaterialPreview = (state) => {
  return state.materialsModule.preview;
};

export const getMaterialPreviews = (state) => {
  if (state?.materialsModule?.materialSection?.data?.materials) {
    return state.materialsModule.materialSection.data.materials;
  }

  if (state?.materialsModule?.materials?.data) {
    return state.materialsModule.materials.data
      .filter((section) => ['images', 'video'].includes(section.slug))
      .map((section) => section.materials)
      .flat();
  }

  return [];
};

export const getMaterialTabs = (state) => {
  return state.materialsModule.materialTabs;
};

export const getMaterialsSection = (state) => {
  return state.materialsModule.materialSection;
};
