import {getActions, getActionsTypes} from '@api/StatsAPI';
import {defaultLoader} from '@helpers/slices';
import {actions, actionsMapActions, actionsMapButtonTypesActions} from '@slices/statistics/index';
import {initialStateIndicators} from '@src/constants';
import {MAPS_SIZE} from '@src/constants/stitictics';
import {groupBy} from 'lodash';

const section = 'action_map';
export const actionsMapStatePaths = {
  actionsMap: {
    name: 'actionsMap',
    path: 'actionsMap.data',
  },
  actionsMapFilters: {
    name: 'actionsMapFilters',
    path: 'actionsMap.filters',
  },
  actionsMapButtonTypes: {
    name: 'actionsMapButtonTypes',
    path: 'actionsMap.buttonTypes',
  },
};

export const actionsMapInitialState = {
  actionsMap: {
    buttonTypes: {
      ...initialStateIndicators,
    },
    filters: {plrs: [], actionIds: [], importances: [], playerIds: [], types: []},
    data: {...initialStateIndicators, data: {home: [], away: []}},
    actionModal: {
      isOpen: false,
      data: {},
    },
  },
};

export const loadActionsMap = (params = {}, isCancel) =>
  defaultLoader(
    () => getActions({...params, section}),
    actionsMapActions.loading,
    ({data}) => {
      const result = groupBy(data, ({isOur, weAtHome}) => {
        if (isOur) {
          return weAtHome ? 'home' : 'away';
        }
        return weAtHome ? 'away' : 'home';
      });
      return actionsMapActions.load({
        data: {
          home: result?.home || [],
          away:
            result?.away?.map((item) => ({
              ...item,
              posX: MAPS_SIZE.x - item?.posX,
              posY: MAPS_SIZE.y - item?.posY,
              opponentPosX: MAPS_SIZE.x - item?.opponentPosX,
              opponentPosY: MAPS_SIZE.y - item?.opponentPosY,
            })) || [],
          weAtHome: data?.[0]?.weAtHome,
        },
      });
    },
    actionsMapActions.error,
    {isCancel, onCancelAction: actionsMapActions.loading},
  );
export const clearActionsMap = () => (dispatch) => dispatch(actionsMapActions.clear());

export const setActionsMapFilters = (data) => (dispatch) =>
  dispatch(actions.setEntityFilters({...data, entity: actionsMapStatePaths.actionsMap.name}));
export const clearActionsMapFilters = () => (dispatch) =>
  dispatch(actions.clearEntityFilters({entity: actionsMapStatePaths.actionsMap.name}));

export const loadButtonTypes = (params = {}, onSuccess = () => {}) =>
  defaultLoader(
    () => getActionsTypes({...params, section}),
    actionsMapButtonTypesActions.loading,
    ({data}) => {
      onSuccess(data);
      return actionsMapButtonTypesActions.load({data});
    },
    actionsMapButtonTypesActions.error,
  );
export const clearButtonTypes = () => (dispatch) => dispatch(actionsMapButtonTypesActions.clear());
