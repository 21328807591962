import LoadingBox from '@common/LoadingBox';
import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {WrapperTableContainer} from '@components/apps/PhysicalTesting/components/SiriusCompare/components/SiriusCompareTable/SiriusCompareTable';
import {mock} from '@components/apps/PhysicalTesting/components/SiriusCompare/mock';
import {sfpTestsExercisesOrdersMatrix} from '@components/apps/PhysicalTesting/constants';
import {sortColumn} from '@components/apps/PhysicalTesting/helpers';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import {longDash} from '@src/constants';
import {Stack, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router';

const calculateColorAndValue = (diff) => {
  if (!diff) return;
  const value = diff.toFixed(3);

  if (value > 0) {
    return {
      color: '#B63830',
      value: `+${value}`,
    };
  }
  if (value < 0) {
    return {
      color: '#4BA849',
      value: value,
    };
  }
  return null;
};

const SiriusCompareTable = ({tableData, isLoading}) => {
  const history = useHistory();
  const formattedTableData = tableData?.map((item) => {
    return {
      ...item,
      params: item.params.map((param) => {
        return {...param, diff: param.value && param.playerValue ? param.playerValue - param.value : null};
      }),
    };
  });

  const [isToggled, setToggleUser] = useState(false);

  const [sorting, setSorting] = useState([{id: 'player', asc: true}]);

  const exercises = tableData
    ?.map((item) => item.params)
    ?.flat()
    ?.reduce((a, c) => (a.map((e) => e.name).includes(c.name) || a.push(c), a), []);

  const sortedExercises = exercises
    ?.map((ex) => ({
      ...ex,
      order: sfpTestsExercisesOrdersMatrix[ex?.id],
    }))
    .sort((a, b) => a.order - b.order);

  const columns = useMemo(
    () => [
      {
        header: (
          <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
            Игрок
          </ToggledHeader>
        ),
        headerStyle: {
          '.MuiBox-root': {
            display: 'flex',
            boxShadow: 'none',
            padding: '0px',
          },
        },
        id: 'player',
        accessorKey: 'lastName',
        enableRowSpan: true,
        sticky: {left: true},
        columns: [
          {
            header: 'Not visible header',
            accessorFn: ({params, ...data}) => {
              return data;
            },
            headerStyle: {display: 'none !important'},
            cellStyle: {height: 'auto !important'},
            sticky: {left: true},
            cell: (params) => {
              const student = params?.getValue();
              return (
                <Stack
                  alignItems="center"
                  direction="row"
                  sx={{cursor: 'pointer'}}
                  onClick={() => (student?.playerId ? history.push(`/student/${student?.playerId}`) : void 0)}
                >
                  <PlayersHorizontalCard isLoading={isLoading} onlyNumber={isToggled} student={student} />
                </Stack>
              );
            },
          },
        ],
        rowSpan: '2',
        sortingFn: sortColumn,
      },
      ...(isLoading ? mock.exercises : sortedExercises).map((exercise) => {
        return {
          id: exercise?.id,
          header: () => {
            return (
              <Typography
                fontWeight={600}
                isLoading={isLoading}
                preloaderWidthBetween={[100, 150]}
                sx={{zIndex: 1}}
                variant="caption1"
              >
                {exercise.name}
              </Typography>
            );
          },
          columns: [
            {label: 'результат', value: 'playerValue'},
            {label: 'отклон.', value: 'diff'},
          ].map(({label, value}) => {
            return {
              accessorFn: ({params}) => {
                return params?.find((param) => param.id === exercise.id);
              },
              id: `params.${exercise.id}.${value}`,
              header: label,
              headerStyle: {
                height: 'auto !important',
                boxShadow:
                  value === 'diff'
                    ? 'rgb(224, 224, 224) -1px -1px 0px 0px inset !important'
                    : 'rgb(224, 224, 224) 0px -1px 0px 0px inset !important',
                textAlign: 'center',
              },
              cellStyle: {
                height: 'auto !important',
                boxShadow: value === 'diff' ? 'rgb(224, 224, 224) -1px 0px 0px 0px inset !important' : 'none',
                textAlign: 'center',
              },
              cell: ({getValue}) => {
                const cellData = getValue();
                if (value === 'diff') {
                  const coloredDiff = calculateColorAndValue(cellData[value]);
                  return (
                    <Typography
                      color={coloredDiff?.color}
                      isLoading={isLoading}
                      preloaderWidthBetween={[40, 50]}
                      variant="body2"
                    >
                      {coloredDiff?.value ? coloredDiff?.value : longDash}
                    </Typography>
                  );
                } else {
                  return (
                    <Typography isLoading={isLoading} preloaderWidthBetween={[40, 50]} variant="body2">
                      {cellData[value] || longDash}
                    </Typography>
                  );
                }
              },
            };
          }),
        };
      }),
    ],
    [isToggled, isLoading],
  );
  return (
    <WrapperTableContainer canEdit={false}>
      <LoadingBox isLoading={isLoading}>
        <ReactTableComponent
          isAvailableHeight
          columns={columns}
          data={!isLoading ? formattedTableData : mock.data}
          setSorting={setSorting}
          sorting={sorting}
          tableBodyCellProps={{
            sx: {
              py: {xxs: 1, lg: 3},
              px: {xxs: 2, lg: 4},
              '&.lastRowCell': {
                boxShadow: 'none',
              },
            },
          }}
          tableHeaderCellProps={{
            sx: {
              py: {xxs: '0', lg: 3},
              px: {xxs: 2, lg: 4},
            },
          }}
        />
      </LoadingBox>
    </WrapperTableContainer>
  );
};

SiriusCompareTable.propTypes = {
  tableData: PropTypes.array.isRequired,
};

export default SiriusCompareTable;
