import Grid from '@ui/MUI/Grid';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

const values = [
  {name: 'repeat', label: 'Повторов:', cap: '4', value: '2'},
  {name: 'time', label: 'Время, мин:', cap: '60', value: '1'},
  {name: 'score', label: 'Оценка:', cap: '3', value: '1'},
  {name: 'impact', label: 'Влияние на статистику:', value: 'Высокое'},
];

const ScoreBlock = () => {
  return (
    <>
      <Grid container gap="12px 40px">
        {values.map(({name, label, cap, value}) => {
          return (
            <Grid item key={name}>
              <Stack direction="row" spacing={1}>
                <TypographyDefault>{label}</TypographyDefault>
                <Stack direction="row" spacing="5px">
                  {<TypographyDefault>{value}</TypographyDefault>}
                  {cap && <TypographyDefault color="rgba(0, 0, 0, 0.6)">/ {cap}</TypographyDefault>}
                </Stack>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ScoreBlock;
