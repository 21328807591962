import Score from '@common/Statistics/Score';
import Goals from '@components/apps/StatisticsWithMkcUral/components/PastGame/components/Goals';
import {setTechResultScore} from '@components/apps/StatisticsWithMkcUral/helpers';
import TeamLogo from '@components/common/Statistics/PlayerStatistics/TeamLogo';
import useQueryString from '@hooks/useQueryString';
import {useMediaQuery} from '@mui/material';
import {Box, Button, Divider, Link, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {flatten, values} from 'lodash';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import styled from 'styled-components';

PastGame.propTypes = {
  borderTop: PropTypes.bool,
  game: PropTypes.object,
};
const borderStyle = `1px solid ${grey['12']}`;

function PastGame({game}) {
  const history = useHistory();
  const {
    homeTeamName,
    awayTeamName,
    homeScore,
    awayScore,
    goals,
    halfsScores,
    isOT,
    isBuls,
    translationLink,
    statisticId,
    awayTeamLogo,
    homeTeamLogo,
    techResult,
  } = game;
  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const teamsGoals = useMemo(() => [goals?.homeTeam, goals?.awayTeam], [goals]);

  const {teamMKCId} = useParams();

  const [qs, _] = useQueryString();

  return (
    <Box border={borderStyle} borderRadius="12px" mb={5}>
      <Stack alignItems="shrink" direction={{xxs: 'column', md: 'row'}}>
        <Stack
          alignItems="center"
          direction="column"
          gap={5}
          justifyContent="center"
          p={{xxs: 1, smallDesktop: 2}}
          width={{xxs: '100%', md: '60%'}}
        >
          <Stack
            alignItems="center"
            direction={{xxs: 'column', sm: 'row', md: 'column', smallLaptop: 'row'}}
            gap={{xxs: 2, lg: 5}}
            justifyContent={{xxs: 'space-evenly', lg: 'center'}}
            width="100%"
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              width={{xxs: 'fit-content', sm: 'calc(100% / 3)', md: 'fit-content', smallLaptop: 'calc(100% / 3)'}}
            >
              <TeamLogo src={homeTeamLogo} width={40} />
              <Typography variant="h2">{homeTeamName}</Typography>
            </Stack>
            <ScoreBlock {...{homeScore, awayScore, halfsScores, isOT, isBuls, techResult}} />
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              width={{xxs: 'fit-content', sm: 'calc(100% / 3)', md: 'fit-content', smallLaptop: 'calc(100% / 3)'}}
            >
              <TeamLogo src={awayTeamLogo} width={40} />
              <Typography variant="h2">{awayTeamName}</Typography>
            </Stack>
          </Stack>

          <Stack direction="row" gap={2} p={{xxs: 1, smallDesktop: 2}}>
            <Button
              color="primary"
              disabled={!statisticId}
              variant="contained"
              onClick={() =>
                history.push(
                  `/reports/stats_report/match_info/${statisticId}?teamMKCId=${teamMKCId}&isFinal=${qs?.isFinal}&season=${qs?.season}`,
                )
              }
            >
              К отчету
            </Button>
            <Button
              color="primary"
              component={Link}
              disabled={!translationLink}
              href={translationLink}
              target="_blank"
              underline="none"
              variant="outlined"
            >
              К матчу
            </Button>
          </Stack>
        </Stack>

        <Divider flexItem borderColor={grey['12']} orientation={isMd ? 'horizontal' : 'vertical'} />
        <Box width={{xxs: '100%', md: '40%'}}>
          {teamsGoals?.map((goals, index) => {
            return (
              <Stack
                alignItems="flex-start"
                borderBottom={index > 0 ? '' : borderStyle}
                direction={{xxs: 'column', xs: 'row'}}
                key={index}
                p={3}
                spacing={4}
              >
                <TeamLogo src={index === 0 ? homeTeamLogo : awayTeamLogo} width={25} />
                <Stack direction="column" gap={1}>
                  <Goals goals={flatten(values(goals))} key={index} />
                </Stack>
              </Stack>
            );
          })}
        </Box>
      </Stack>
    </Box>
  );
}

export default PastGame;

function ScoreBlock({homeScore, awayScore, halfsScores = [], isOT, isBuls, techResult}) {
  const winBy = useMemo(() => {
    if (isOT) {
      return 'ОТ';
    }
    if (isBuls) {
      return 'Булл.';
    }
    return ' ';
  }, [isOT, isBuls]);
  const isHomeWin = useMemo(() => homeScore > awayScore, [homeScore, awayScore]);

  return (
    <Stack alignItems="center" justifyContent="center">
      <Stack alignItems="center" direction="row" gap={2} justifyContent="space-around" pt={2}>
        <Box position="relative">
          <OTIndication opacity={homeScore > awayScore} variant="caption2">
            {winBy}
          </OTIndication>
          <ScoreNumber color={!isHomeWin && grey['54']}>{setTechResultScore(homeScore, techResult)}</ScoreNumber>
        </Box>
        <ScoreNumber>-</ScoreNumber>
        <Box position="relative">
          <OTIndication opacity={homeScore < awayScore} variant="caption2">
            {winBy}
          </OTIndication>
          <ScoreNumber color={isHomeWin && grey['54']}>{setTechResultScore(awayScore, techResult)}</ScoreNumber>
        </Box>
      </Stack>

      <Stack direction="row" gap={0}>
        {halfsScores?.map((item, index) => (
          <Score key={index} score={item} />
        ))}
      </Stack>
    </Stack>
  );
}

const ScoreNumber = styled(Typography)`
  font-weight: 700;
  line-height: normal;
  font-size: 56px;
  ${({theme}) => theme.breakpoints.down('smallLaptop')} {
    font-size: 32px;
    line-height: normal;
  }
`;

const OTIndication = styled(Typography)`
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${({opacity}) => (opacity ? 1 : 0)};
`;
