import {Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React from 'react';

const EstimateItemWithLabelVertical = ({
  label,
  children,
  spacing = '4px',
  labelVariant = 'body2',
  labelColor = grey[54],
  contentColor,
  contentVariant = 'subtitle1',
}) => {
  return (
    <Stack spacing={spacing}>
      <Typography color={labelColor} variant={labelVariant}>
        {label}
      </Typography>
      <Typography color={contentColor} variant={contentVariant}>
        {children}
      </Typography>
    </Stack>
  );
};

export default EstimateItemWithLabelVertical;
