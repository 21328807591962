import ChildrenField from '@common/ChildrenField';
import EstimateItemWithLabelHorizontal from '@common/DevelopmentJournal/components/EstimateItemWithLabelHorizontal';
import EstimateItemWithLabelVertical from '@common/DevelopmentJournal/components/EstimateItemWithLabelVertical';
import EstimateNumber from '@common/DevelopmentJournal/components/EstimateNumber';
import EstimateRange from '@common/DevelopmentJournal/components/EstimateRange';
import {useUsersWithRequest} from '@hooks/filters';
import {useModal} from '@hooks/skill';
import {ErrorOutline} from '@mui/icons-material';
import {userRoles} from '@src/constants';
import {Box, DatePicker, Stack, TextField, Tooltip, Typography} from '@ui/MUI';
import AutocompleteWithVirtualization from '@ui/MUI/Autocomplete/AutocompleteWithVirtualization';
import {primary, secondary} from '@ui/MUI/colors';
import moment from 'moment';
import React from 'react';
import {useWatch} from 'react-hook-form';

const Form = ({form}) => {
  const {setValue} = form;
  const {data} = useModal('edit');
  const score = useWatch({name: 'score'});
  const specialists = useWatch({name: 'specialists'});
  const users = useUsersWithRequest([userRoles.teacher, userRoles.psycho]);
  return (
    <Stack mt={5} spacing={5}>
      <Stack direction="row" spacing={8}>
        <EstimateItemWithLabelVertical label="Навык">{data?.skill?.name}</EstimateItemWithLabelVertical>
        <EstimateItemWithLabelVertical label="Группа навыков">{data.skill.category.name}</EstimateItemWithLabelVertical>
      </Stack>
      <Stack spacing={4}>
        <ChildrenField isRequired name="todo">
          <TextField
            label="Что должен сделать игрок"
            validate={(value) => {
              if (value?.length > 255) {
                form?.setError('plan', {message: 'Введите не более 255 символов'});
              } else {
                form?.clearErrors('plan');
              }
            }}
          />
        </ChildrenField>
        <ChildrenField isRequired name="plan">
          <TextField
            label="План работы с игроком"
            validate={(value) => {
              if (value?.length > 255) {
                form?.setError('plan', {message: 'Введите не более 255 символов'});
              } else {
                form?.clearErrors('plan');
              }
            }}
          />
        </ChildrenField>
      </Stack>
      <Stack spacing={3}>
        <EstimateItemWithLabelHorizontal label="Текущий результат" labelColor={secondary.contrastText} spacing={3}>
          <EstimateNumber isPrimary>{data.score}</EstimateNumber>
        </EstimateItemWithLabelHorizontal>
        <EstimateItemWithLabelHorizontal label="Целевой результат" labelColor={secondary.contrastText} spacing={3}>
          <ChildrenField isRequired name="expectedScore">
            <EstimateRange max={10} min={score + 1} />
          </ChildrenField>
        </EstimateItemWithLabelHorizontal>
      </Stack>
      <ChildrenField isRequired name="controlDate">
        {({value, onChange}) => {
          const isOutdated = moment().isAfter(value);
          const tomorrow = moment().add(1, 'day');
          return (
            <Stack alignItems="center" direction="row" spacing={2}>
              <Box maxWidth="120px">
                <DatePicker minDate={tomorrow} value={value} variant="standard" onChange={onChange} />
              </Box>
              {isOutdated && (
                <Tooltip title="Дата контроля прошла">
                  <ErrorOutline
                    sx={{
                      fontSize: '20px',
                      color: primary.main,
                    }}
                  />
                </Tooltip>
              )}
            </Stack>
          );
        }}
      </ChildrenField>
      <Stack spacing={4}>
        <Typography variant="subtitle1">Задействованные специалисты</Typography>
        <ChildrenField name="specialists">
          <AutocompleteWithVirtualization
            options={users?.options?.map((option) => ({...option, id: option.value}))}
            value={specialists}
            onChange={(_, newValue) => {
              setValue('specialists', newValue);
            }}
          />
        </ChildrenField>
      </Stack>
    </Stack>
  );
};

export default Form;
