import GameEstimateApi from '../../api/GameEstimateAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

export function loadGameEstimationFormByRole(userRole, lessonId) {
  return (dispatch) => {
    return GameEstimateApi.getGameEstimationFormByRole(userRole, lessonId)
      .then((data) => {
        dispatch({
          type: types.LOAD_GAME_ESTIMATE,
          gameEstimate: data.data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке игры';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}

export function cleanGameEstimate() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAN_GAME_ESTIMATE,
      gameEstimate: undefined,
    });
  };
}

//thunk
export function loadPeriodGameEstimateReport(data) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_ESTIMATE_LOADING,
    });
    return GameEstimateApi.getPeriodGameEstimateReport(data)
      .then((data) => {
        dispatch({
          type: types.LOAD_PERIOD_GAME_ESTIMATE_REPORT,
          periodGemesEstimateReport: data.data,
        });
      })
      .catch(function (err) {
        dispatch({
          type: types.LOAD_GAME_ESTIMATE_ERROR,
        });

        let errorText = 'Ошибка при загрузке игры';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}

export function cleanPeriodGameEstimateReport() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAN_PERIOD_GAME_ESTIMATE_REPORT,
    });
  };
}

export function postGameEstimate(request, onSuccess) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_ESTIMATE_LOADING,
    });
    return GameEstimateApi.postGameEstimate(request)
      .then((data) => {
        onSuccess && onSuccess();
        dispatch({
          type: types.LOAD_GAME_ESTIMATE,
          gameEstimate: data.data,
        });
        ReactComponentNotification(NOTIFICATION_TYPES['success'], 'Оценка сохранена');
      })
      .catch((err) => {
        dispatch({
          type: types.LOAD_GAME_ESTIMATE_ERROR,
        });
        let errorText = 'Ошибка при сохранении';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}

export function loadGameEstimateReport(lessonId) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_ESTIMATE_LOADING,
    });

    return GameEstimateApi.getGameEstimateReport(lessonId)
      .then((data) => {
        dispatch({
          type: types.LOAD_GAME_ESTIMATE_REPORT,
          gameEstimateReport: data.data,
        });
      })
      .catch(function (err) {
        dispatch({
          type: types.LOAD_GAME_ESTIMATE_ERROR,
        });

        let errorText = 'Ошибка при загрузке отчета';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}

export function loadStudentGameEstimateReport(request) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_ESTIMATE_LOADING,
    });

    return GameEstimateApi.getStudentGameEstimateReport(request)
      .then((data) => {
        dispatch({
          type: types.LOAD_GAME_ESTIMATE_REPORT,
          gameEstimateReport: data.data,
        });
      })
      .catch(function (err) {
        dispatch({
          type: types.LOAD_GAME_ESTIMATE_ERROR,
        });

        let errorText = 'Ошибка при загрузке отчета';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
export function cleanGameEstimateReport() {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_ESTIMATE_REPORT,
      gameEstimateReport: undefined,
    });
  };
}
export function loadGroupEstimations(schoolId) {
  return (dispatch) => {
    // dispatch(loadGameEstimate_());
    return GameEstimateApi.getGroupGameEstimations(schoolId)
      .then((data) => {
        dispatch({
          type: types.LOAD_ALL_GAME_ESTIMATE,
          allGameEstimations: data.data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
export function loadSchoolEstimations(filter) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_ESTIMATE_LOADING,
    });
    return GameEstimateApi.getSchoolGameEstimations(filter)
      .then((data) => {
        dispatch({
          type: types.LOAD_ALL_GAME_ESTIMATE,
          allGameEstimations: data.data,
          allGameEstimationsCount: data.total,
        });
      })
      .catch(function (err) {
        dispatch({
          type: types.LOAD_GAME_ESTIMATE_ERROR,
        });
        let errorText = 'Ошибка при загрузке';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
export function loadPlayerEstimations(playerId) {
  return (dispatch) => {
    return GameEstimateApi.getPlayerGameEstimations(playerId)
      .then((data) => {
        dispatch({
          type: types.LOAD_PLAYER_GAMES_ESTIMATE,
          playerGameEstimations: data.data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}

export function loadPlayerEstimateChartData(playerId, request = {}) {
  return (dispatch) => {
    return GameEstimateApi.getPlayerEstimateChartData(playerId, request)
      .then((data) => {
        dispatch({
          type: types.LOAD_PLAYER_ESTIMATE_CHART_DATA,
          playerEstimateChartData: data.data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}

export function cleanPlayerEstimateChartData() {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_PLAYER_ESTIMATE_CHART_DATA,
      playerEstimateChartData: undefined,
    });
  };
}

export function loadGroupEstimateChartData(groupId, order, request = {}) {
  return (dispatch) => {
    return GameEstimateApi.getGroupEstimateChartData(groupId, request)
      .then((data) => {
        dispatch({
          type: types.LOAD_GROUP_ESTIMATE_CHART_DATA,
          groupEstimateChartData: data.data,
          order: order,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
export function loadSchoolEstimateChartData(schoolId, order, request = {}) {
  return (dispatch) => {
    return GameEstimateApi.getSchoolEstimateChartData(schoolId, request)
      .then((data) => {
        dispatch({
          type: types.LOAD_SCHOOL_ESTIMATE_CHART_DATA,
          schoolEstimateChartData: data.data,
          order: order,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
export function clearGroupEstimateChartData() {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GROUP_ESTIMATE_CHART_DATA,
      groupEstimateChartData: [],
    });
  };
}
export function loadGameWithoutEstimateReport(gameId) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_ESTIMATE_LOADING,
    });
    return GameEstimateApi.getGameWithoutEstimateReport(gameId)
      .then((data) => {
        dispatch({
          type: types.LOAD_GAME_WITHOUT_ESTIMATE_REPORT,
          gameWithoutEstimateReport: data.data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке отчета';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
export function cleanGameWithoutEstimateReport() {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_WITHOUT_ESTIMATE_REPORT,
      gameWithoutEstimateReport: undefined,
    });
  };
}

export function loadGamesKeyParams(request) {
  return (dispatch) => {
    // dispatch({
    //   type: types.LOAD_GAME_ESTIMATE_LOADING
    // })
    return GameEstimateApi.getGamesKeyParams(request)
      .then((data) => {
        dispatch({
          type: types.LOAD_GAMES_KEY_PARAMS,
          gamesKeyParams: data.data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке отчета';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}

export function cleanPlayerGamePeriodReport() {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_PERIOD_ESTIMATE_REPORT,
      gamePeriodReport: null,
    });
  };
}

export function getPlayerGamePeriodReport(filter) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_ESTIMATE_LOADING,
    });
    return GameEstimateApi.getPlayerGamePeriodReport(filter)
      .then((data) => {
        dispatch({
          type: types.LOAD_GAME_PERIOD_ESTIMATE_REPORT,
          gamePeriodReport: data.data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке отчета';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      })
      .finally(() => {
        dispatch({
          type: types.LOAD_GAME_ESTIMATE_ERROR,
        });
      });
  };
}

export function getGameEstimationsForPlayersPersonalFile(request) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_GAME_ESTIMATIONS_FOR_PLAYER_PERSONAL_FILE_LOADING,
    });
    return GameEstimateApi.getGameEstimationsForPlayersPersonalFile(request)
      .then((data) => {
        dispatch({
          type: types.LOAD_GAME_ESTIMATIONS_FOR_PLAYER_PERSONAL_FILE,
          personalFileGameEstimations: data.data.data,
          personalFileGameEstimationsCount: data.data.total,
        });
      })
      .catch(function (err) {
        dispatch({
          type: types.LOAD_GAME_ESTIMATIONS_FOR_PLAYER_PERSONAL_FILE_ERROR,
        });
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${err.response.data.message}`);
            }
          }
        }
      });
  };
}
