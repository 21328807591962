import MUIInput from '@mui/material/Input';
import {InputProps} from '@mui/material/Input/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@ui/MUI/FormHelperText';
import React from 'react';

import {InputDefaultWrapper} from './styles';

type CustomInputProps = {helperText?: string | number; label?: string} & InputProps;

export default function Input(props: CustomInputProps) {
  const {id, helperText = '', label = '', error, size = 'small'} = props;

  return (
    <InputDefaultWrapper>
      <InputLabel error={error} htmlFor={id}>
        {label}
      </InputLabel>
      <MUIInput error={error} size={size} {...props} />
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </InputDefaultWrapper>
  );
}
