import 'echarts/lib/chart/line';

import useDeepEffect from '@hooks/useDeepEffect';
import useEventListener from '@hooks/useEventListener';
import * as echarts from 'echarts';
import React, {memo, useEffect, useRef, useState} from 'react';

function LineChart({options, height = 400, className}) {
  let defaultOption = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: [],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
    },
    xAxis: {
      type: '',
      boundaryGap: false,
      data: [],
    },
    yAxis: {
      type: '',
    },
    series: [],
  };

  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  /**
   * Инициализация графика
   */
  const init = (opt) => {
    // eslint-disable-next-line import/namespace
    const chart = echarts.init(chartRef.current);

    chart.setOption(opt ? opt : defaultOption);
    setChartInstance(chart);
  };
  useEffect(() => {
    init(options);
  }, []);
  /**
   * Реинициализация графика
   */
  const reInit = (options) => {
    if (!chartInstance) return;
    chartInstance.dispose();
    init(options);
  };

  useDeepEffect(() => {
    reInit(options);
  }, [options, height]);

  useEventListener('resize', () => chartInstance.resize(), chartInstance);
  return (
    <>
      <div
        className={className || 'LineChart'}
        style={{
          background: '#fff',
          textAlign: 'center',
          width: '100%',
          minHeight: height,
          height: height,
        }}
      >
        <div
          ref={chartRef}
          style={{
            width: '100%',
            minHeight: height,
            height: height,
            maxHeight: height,
          }}
        ></div>
      </div>
    </>
  );
}

export default memo(LineChart);
