import ReactTableComponent from '@common/ReactTableComponent';
import {createColumnHelper} from '@tanstack/react-table';
import {mean, meanBy} from 'lodash';
import React, {useMemo} from 'react';

import {diffProcent} from '../../helpers';

const columnHelper = createColumnHelper();

const avarageValue = (info, result) => {
  const value = info.getValue();
  const diff = diffProcent(value, result).toFixed(0);

  return diff;
};

const Table = ({data}) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Вид',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('count', {
        header: 'Всего',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('periodCount', {
        header: 'За период',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('timeEstimate', {
        header: 'Время фактическое, %',
        cell: (info) => avarageValue(info, data?.total?.timeEstimate),
      }),
      columnHelper.accessor('time', {
        header: 'Время по плану, %',
        cell: (info) => avarageValue(info, data?.total?.time),
      }),
      columnHelper.accessor('repeat', {
        header: 'Кол-во повтор.',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('qualityEstimate', {
        header: 'Качество ср.',
        cell: (info) => {
          const value = info.getValue();
          return Number(value).toFixed(1);
        },
      }),
    ],
    [data?.total],
  );
  return (
    <ReactTableComponent
      bottomRow={([Row, Cell]) => {
        const {count, time, timeEstimate, qualityEstimate, periodCount} = data.total;
        const timeEstimateAvg = mean(data?.result?.map((item) => diffProcent(item.timeEstimate, timeEstimate))).toFixed(
          0,
        );
        return (
          <Row>
            <Cell>Итого</Cell>
            <Cell>{count}</Cell>
            <Cell>{periodCount}</Cell>
            <Cell>
              {time} / {timeEstimate}
            </Cell>
            <Cell>{timeEstimateAvg}</Cell>
            <Cell>{meanBy(data.result, 'repeat').toFixed(0)}</Cell>
            <Cell>{qualityEstimate.toFixed(1)} / 3.0</Cell>
          </Row>
        );
      }}
      columns={columns}
      data={data.result}
    />
  );
};

export default Table;
