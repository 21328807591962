import ChildrenField from '@common/ChildrenField';
import EstimateItemWithLabelHorizontal from '@common/DevelopmentJournal/components/EstimateItemWithLabelHorizontal';
import EstimateItemWithLabelVertical from '@common/DevelopmentJournal/components/EstimateItemWithLabelVertical';
import EstimateNumber from '@common/DevelopmentJournal/components/EstimateNumber';
import EstimateRange from '@common/DevelopmentJournal/components/EstimateRange';
import {useModal} from '@hooks/skill';
import {Divider, Stack, TextField, Typography} from '@ui/MUI';
import moment from 'moment';
import React from 'react';

const Form = () => {
  const {data} = useModal('complete');
  const createAt = moment(data.createdAt).format('DD.MM.YYYY');
  const controlDate = moment(data.controlDate).format('DD.MM.YYYY');
  return (
    <Stack spacing={4}>
      <Stack direction="row" spacing={8}>
        <EstimateItemWithLabelVertical label="Навык">{data?.skill?.name}</EstimateItemWithLabelVertical>
        <EstimateItemWithLabelVertical label="Группа навыков">{data.skill.category.name}</EstimateItemWithLabelVertical>
      </Stack>
      <EstimateItemWithLabelVertical contentVariant="body1" label="Что должен сделать игрок">
        {data.todo}
      </EstimateItemWithLabelVertical>
      <Stack direction="row" spacing={5}>
        <EstimateItemWithLabelHorizontal label="Текущий результат" labelVariant="body1" spacing={2}>
          <EstimateNumber isPrimary>{data.score}</EstimateNumber>
        </EstimateItemWithLabelHorizontal>
        <EstimateItemWithLabelHorizontal label="Целевой результат" labelVariant="body1" spacing={2}>
          <EstimateNumber isPrimary>{data.expectedScore}</EstimateNumber>
        </EstimateItemWithLabelHorizontal>
      </Stack>
      <Stack direction="row" spacing={5}>
        <EstimateItemWithLabelVertical label="Дата создания">{createAt}</EstimateItemWithLabelVertical>
        <EstimateItemWithLabelVertical label="Дата контроля">{controlDate}</EstimateItemWithLabelVertical>
      </Stack>
      <Divider />
      <Typography variant="h4">Итоговый результат</Typography>
      <ChildrenField isRequired name="completedScore">
        <EstimateRange />
      </ChildrenField>
      <ChildrenField name="comment">
        <TextField label="Комментарий для игрока" />
      </ChildrenField>
    </Stack>
  );
};

export default Form;
