import {useRandomNumberBetween} from '@hooks/useRandomNumberBetween';
import {Typography} from '@mui/material';
import {SkeletonProps} from '@mui/material/Skeleton/Skeleton';
import {TypographyProps} from '@mui/material/Typography/Typography';
import React, {ForwardedRef, forwardRef} from 'react';

import Skeleton from '../Skeleton';

type CustomTypographyProps = {
  withoutWrapper?: boolean;
  preloaderProps?: SkeletonProps;
  isLoading?: boolean;
  preloaderWidthBetween?: [number, number];
} & TypographyProps;

const TypographyDefault = forwardRef(function TypographyDefault(
  {
    children,
    withoutWrapper,
    preloaderProps = {},
    preloaderWidthBetween = [0, 0],
    isLoading,
    ...props
  }: CustomTypographyProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const memoRandomWidth = useRandomNumberBetween(preloaderWidthBetween);
  return (
    <Typography ref={ref} {...props}>
      {isLoading ? (
        <Skeleton
          variant="text"
          width={preloaderWidthBetween?.length ? memoRandomWidth : void 0}
          {...preloaderProps}
          sx={{
            ...(preloaderProps?.sx || {}),
            backgroundColor: withoutWrapper ? 'rgba(255, 255, 255, .3)' : void 0,
          }}
        />
      ) : (
        children
      )}
    </Typography>
  );
});
export default TypographyDefault;
