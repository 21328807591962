import ContentWrapper from '@common/ContentWrapper';
import {ROLE_CATEGORIES} from '@components/apps/StatsReportForOneGame/constants';
import {usePlayersFilterData} from '@hooks/statistics/actionsMap/usePlayersFilterData';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {ListSubheader} from '@mui/material';
import {HALF_OPTIONS, HALF_OPTIONS_WITH_OT, IMPORTANCE_OPTIONS, PLRS_OPTIONS} from '@src/constants';
import {Autocomplete, Stack} from '@ui/MUI';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import Box from '@ui/MUI/Box';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React, {memo, useCallback, useEffect, useMemo} from 'react';

const importances = [...IMPORTANCE_OPTIONS].slice(1, 3);

Filters.propTypes = {
  hasOT: PropTypes.bool,
  statisticsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  getter: PropTypes.func.isRequired,
  setter: PropTypes.func.isRequired,
};
function Filters({hasOT, statisticsIds, getter = () => {}, setter = () => {}}) {
  const filtersData = useStore(getter);
  const dispatch = useStoreDispatch();

  const {data, isLoading} = usePlayersFilterData({statisticsIds});
  const setFilters = useCallback((data) => dispatch(setter(data)), []);
  const filters = useMemo(
    () => [
      {
        name: 'plrs',
        options: [...PLRS_OPTIONS],
        multiple: true,
        label: 'Составы',
        isSelectableAll: true,
      },
      {
        name: 'importance',
        options: [...importances],
        multiple: false,
        label: 'Важность',
      },
      {
        name: 'half',
        options: [...(hasOT ? HALF_OPTIONS_WITH_OT : HALF_OPTIONS)],
        multiple: true,
        label: 'Период',
        isSelectableAll: true,
        limitTags: 2,
      },
    ],
    [hasOT],
  );
  useEffect(() => {
    setFilters({
      plrs: PLRS_OPTIONS,
      half: hasOT ? HALF_OPTIONS_WITH_OT : HALF_OPTIONS,
    });
  }, [hasOT]);

  return (
    <ContentWrapper py={2} sx={{overflow: ' '}}>
      <Stack alignItems="center" direction="row" flexWrap={{xxs: 'wrap', lg: 'nowrap'}} gap={3}>
        <AutocompleteDefault
          multiple
          disabled={isLoading || !statisticsIds?.length}
          groupBy={(option) => option.hockeyRole}
          isLoading={isLoading}
          label="Игроки"
          limitTags={1}
          options={data}
          renderGroup={(params) => (
            <>
              <ListSubheader
                sx={{
                  top: '-8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {ROLE_CATEGORIES[params?.group]}
                <TypographyDefault
                  color="primary"
                  sx={{cursor: 'pointer'}}
                  variant="subtitle2"
                  onClick={() =>
                    setFilters({
                      playerIds: [
                        ...(filtersData?.playerIds?.length ? filtersData.playerIds : []),
                        ...data.filter((item) => item?.hockeyRole === params?.group),
                      ],
                    })
                  }
                >
                  Выбрать всех
                </TypographyDefault>
              </ListSubheader>

              {params.children}
            </>
          )}
          renderOption={(props, option) => (
            <Stack component="li" direction="row" sx={{justifyContent: 'space-between !important'}} {...props}>
              <TypographyDefault variant="body1">{option.label}</TypographyDefault>
            </Stack>
          )}
          value={filtersData?.playerIds}
          wrapperProps={{
            position: 'relative',
            width: (theme) => ({xxs: '100%', xs: `calc(50% - (${theme.spacing(3)} / 2))`, md: 240}),
          }}
          onChange={(_, value) => setFilters({playerIds: value})}
        />

        {filters.map((item, index) => (
          <Box
            key={index + 'filter'}
            width={(theme) => ({xxs: '100%', xs: `calc(50% - (${theme.spacing(3)} / 2))`, md: 200})}
          >
            <Autocomplete
              limitTags={1}
              {...item}
              value={filtersData?.[item?.name]}
              onChange={(_, value) => setFilters({[item?.name]: value})}
            />
          </Box>
        ))}
      </Stack>
    </ContentWrapper>
  );
}

export default memo(Filters);
