import {roleOptions, userRoles} from '@src/constants';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import useDebouncedCallback from 'use-debounce/lib/callback';

import useDeepEffect from '../../../../hooks/useDeepEffect';
import {Button} from '../../../ui/Button';
import Icon from '../../../ui/Icon/Icon';
import InputDefault from '../../../ui/Input';
import SelectDefault from '../../../ui/Select';

const UsersFilters = (props) => {
  const history = useHistory();

  const [school, setSchool] = useState(null);

  const isLoading = useSelector((state) => state.schools.loading);
  const schools = useSelector((state) =>
    state.schools.schoolsMap.toArray().map((school) => ({value: school.id, label: school.name})),
  );
  const userSchool = useSelector((state) => state.users.currentUser.schoolsIds[0]);
  const isAdmin = useSelector((state) => state.users.currentUser.userRole === userRoles.admin);

  useDeepEffect(() => {
    if (userSchool && schools?.length) {
      handelChange(
        schools.find((school) => school.value === userSchool),
        {name: 'schoolId'},
      );
    }
  }, [userSchool, schools]);

  const handelChange = (select, {name}) => {
    if (name === 'schoolId') {
      setSchool(select);
    }
    props.onChange(select.value, name);
  };

  const [handleSearch] = useDebouncedCallback(
    (value) => {
      props.onChange(value, 'q');
    },
    300,
    [],
  );
  const formatedRole = useMemo(() => {
    return props.userRole === 'admin' ? roleOptions : roleOptions.filter((role) => role.value !== 'admin');
  }, [roleOptions, props.userRole]);

  return (
    <Wrapper>
      <Container>
        <FilterContainer>
          <Label>ПОИСК ПО ФИО</Label>
          <InputContainer>
            <StyledInput placeholder="Введите запрос в поиске..." onChange={(e) => handleSearch(e.target.value)} />
            <IconContainer>
              <Icon height="23" type="SearchGrey" viewBox="0 0 23 23" width="23" />
            </IconContainer>
          </InputContainer>
        </FilterContainer>
        <FilterContainer>
          <Label>Школа</Label>
          <SelectDefault
            isDisabled={!isAdmin}
            isLoading={isLoading}
            name="schoolId"
            options={schools}
            placeholder="Выберите"
            value={school}
            onChange={handelChange}
          />
        </FilterContainer>
        <FilterContainer>
          <Label>Роль</Label>
          <SelectDefault name="userRole" options={formatedRole} placeholder="Выберите" onChange={handelChange} />
        </FilterContainer>
        <CreateButtonContainer onClick={() => history.push('/users/create')}>
          <Button>Добавить пользователя</Button>
        </CreateButtonContainer>
      </Container>
    </Wrapper>
  );
};

const StyledInput = styled(InputDefault)`
  padding-left: 45px;
`;

const CreateButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 187px;
  ${Button} {
    padding: 20px 35px;
    line-height: 0;
    @media (max-width: 1320px) {
      padding: 20px 15px;
    }
  }

  @media (max-width: 1130px) {
    margin-top: 10px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  left: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font: 600 12px / 16px 'Proxima Nova';
  color: rgb(116, 116, 116);
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  padding: 12px 34px 20px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

const FilterContainer = styled.div`
  flex: 1;
  min-width: 200px;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
  }
  padding-right: 14px;
  padding-left: 14px;
  box-sizing: border-box;
`;

export default UsersFilters;
