import {longDash} from '@src/constants';
import {grey} from '@ui/MUI/colors';
import TypographyDefault from '@ui/MUI/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

function SingleProgressCell({percent, value}) {
  return (
    <>
      <Wrapper>
        <TypographyDefault variant="subtitle2">{value || value === 0 ? value : longDash}</TypographyDefault>
      </Wrapper>
      {percent !== 0 && <Marker width={percent} />}
    </>
  );
}

SingleProgressCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default SingleProgressCell;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Marker = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 2px;
  max-width: calc(100% - 12px);
  width: ${({width}) => width + '%'};
  height: 8px;
  background-color: ${grey['24']};
`;
