import Classiс from '@components/common/PlayersStatistics/components/Classiс';
import Corsi from '@components/common/PlayersStatistics/components/Corsi';
import Dekings from '@components/common/PlayersStatistics/components/Dekings';
import Fouls from '@components/common/PlayersStatistics/components/Fouls';
import Passes from '@components/common/PlayersStatistics/components/Passes';
import PowerStruggle from '@components/common/PlayersStatistics/components/PowerStruggle';
import PuckBattle from '@components/common/PlayersStatistics/components/PuckBattle';
import Shots from '@components/common/PlayersStatistics/components/Shots';
import Time from '@components/common/PlayersStatistics/components/Time';
import Filter from '@components/common/Statistics/Filter';
import useDefaultFilterValues from '@hooks/useDefaultFilterValues';
import {HALF_OPTIONS, HALF_OPTIONS_WITH_OT, IMPORTANCE_OPTIONS, STATISTICS_PLAYERS_TABS as TABS} from '@src/constants';
import TabsNew from '@ui/MUI/TabsNew';
import React, {useEffect, useMemo, useState} from 'react';

import {BoxWrapper} from '../../StatsReportForOneGame';

const tabsData = [
  {
    ...TABS.classic,
    Component: (props) => <Classiс {...props} />,
  },
  {
    ...TABS.time,
    Component: (props) => <Time {...props} />,
  },
  {
    ...TABS.shots,
    Component: (props) => <Shots {...props} />,
  },
  {
    ...TABS.dekings,
    Component: (props) => <Dekings {...props} />,
  },
  {
    ...TABS.fouls,
    Component: (props) => <Fouls {...props} />,
  },
  {
    ...TABS.passes,
    Component: (props) => <Passes {...props} />,
  },
  {
    ...TABS.puckBattle,
    Component: (props) => <PuckBattle {...props} />,
  },
  {
    ...TABS.powerStruggle,
    Component: (props) => <PowerStruggle {...props} />,
  },
  {
    ...TABS.corsi,
    Component: (props) => <Corsi {...props} />,
    customFilters: (hasOT) => [
      {
        name: 'importance',
        options: [...IMPORTANCE_OPTIONS],
        multiple: false,
        label: 'Важность',
        isSelectableAll: false,
      },
      {
        name: 'half',
        options: [...(hasOT ? HALF_OPTIONS_WITH_OT : HALF_OPTIONS)],
        multiple: true,
        label: 'Период',
        isSelectableAll: true,
        limitTags: 3,
      },
    ],
    excludedFilters: ['plrs'],
  },
];
export default function PlayersStatistics({hasOT, weAtHome}) {
  useEffect(() => {
    window.ym(80266396, 'reachGoal', 'statistika_igroki');
  }, []);
  const [selectedTab, setSelectedTab] = useState(0);
  const defaultFilterValues = useDefaultFilterValues(hasOT);
  const [filter, setFilter] = useState(defaultFilterValues);

  const tabs = useMemo(
    () =>
      tabsData.map(({Component, value, label}, index) => {
        const filtersToExclude = tabsData[index]?.excludedFilters || [];
        const forFetch = {...(filter?.forFetch || {})};
        filtersToExclude.forEach((item) => (forFetch[item] = []));
        return {
          label: label,
          value: <Component filter={forFetch} key={index} tab={value} />,
        };
      }),
    [filter?.forFetch],
  );

  return (
    <>
      <BoxWrapper mb={1}>
        <Filter
          {...{filter, setFilter, hasOT, weAtHome}}
          customFilters={tabsData?.[selectedTab]?.customFilters?.(hasOT)}
        />
      </BoxWrapper>
      <BoxWrapper p="0 !important">
        <TabsNew hasMobileView tabs={tabs} value={selectedTab} onChange={setSelectedTab} />
      </BoxWrapper>
    </>
  );
}
