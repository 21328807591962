import PlayersTable from '@common/Statistics/PlayerStatistics/VideoModal/PlayersTable';
import VideoPlayer from '@common/Statistics/VideoPlayer';
import {shallowEqual} from '@helpers/shallowEqual';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import {selectGameActionDetails, selectShotsMapData, selectTeamActionsSide} from '@selectors/gameStatistics';
import {loadGameActionDetails} from '@slices/statistics';
import {longDash} from '@src/constants';
import {Button, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Drawer from '@ui/MUI/DrawerNew';
import {get, isEmpty} from 'lodash';
import {func} from 'prop-types';
import React, {useEffect} from 'react';

ShotVideoModal.propTypes = {
  stateGetter: func.isRequired,
  stateSetter: func.isRequired,
};

const commonInfo = [
  {label: 'Команда: ', value: 'teamName'},
  {label: 'Время: ', value: 'time'},
];

function ShotVideoModal({stateGetter = () => {}, stateSetter = () => {}}) {
  const dispatch = useStoreDispatch();
  const shotsMapData = useStore((state) => {
    const shots = selectShotsMapData(state)?.data;
    const isOurSide = stateGetter(state)?.data?.isOur ?? selectTeamActionsSide(state);
    return [shots?.home || [], shots?.away || []]
      .flat()
      .filter((item) => item?.isOur === isOurSide)
      .sort((a, b) => a?.videoTime.localeCompare(b?.videoTime));
  }, shallowEqual);
  const {isOpen, data} = useStore(stateGetter);
  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [data?.id]});
  const shotDetails = useStore(selectGameActionDetails);
  const playersOnIce = shotDetails.data?.playersOnIce;
  const shotDetailsLoading = shotDetails?.isLoading;
  const shotInfo = useDeepMemo(
    () => [
      {label: 'Результат броска:', value: data?.name},
      {
        label: 'Тип броска:',
        value: data?.type?.name ? data.type.name + (data?.type?.engName ? ` (${data.type.engName})` : '') : '',
      },
    ],
    [data],
  );

  const {count, index} = useDeepMemo(() => {
    const count = shotsMapData?.length || 0;
    const index = shotsMapData?.findIndex((item) => item?.id === data?.id) || 0;
    return {count, index};
  }, [shotsMapData, data?.id]);

  useEffect(() => {
    data?.id && dispatch(loadGameActionDetails(data?.id, {cancelToken: newCancelToken(), isCancel}));
  }, [data?.id]);

  useDeepEffect(() => {
    if (isOpen && isEmpty(data) && !isEmpty(shotsMapData[0])) {
      dispatch(stateSetter({isOpen: true, data: shotsMapData[0]}));
    }
  }, [shotsMapData, data, isOpen]);

  return (
    <Drawer
      buttons={
        <Stack
          direction={{xxs: 'column', xs: 'row'}}
          flexWrap="wrap"
          gap={3}
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" gap={3} width={{xxs: '100%', xs: 'unset'}}>
            <Button
              color="primary"
              disabled={index === 0}
              startIcon={<ChevronLeftOutlinedIcon />}
              sx={{width: {xxs: '50%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => dispatch(stateSetter({isOpen: true, data: shotsMapData[index - 1]}))}
            >
              назад
            </Button>
            <Button
              color="primary"
              disabled={index + 1 === count}
              endIcon={<ChevronLeftOutlinedIcon sx={{transform: 'rotate(180deg)'}} />}
              sx={{width: {xxs: '50%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => dispatch(stateSetter({isOpen: true, data: shotsMapData[index + 1]}))}
            >
              вперед
            </Button>
          </Stack>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => dispatch(stateSetter({isOpen: false, data: {}}))}
          >
            Закрыть
          </Button>
        </Stack>
      }
      open={isOpen}
      title={`Бросок${count ? ` ${index + 1}/${count}` : ''}. ${data?.playerName || ''}`}
      onClose={() => {
        dispatch(stateSetter({isOpen: false, data: {}}));
      }}
    >
      <Stack direction="column" gap={1} mb={4} mt={6}>
        {commonInfo.map(({label, value, fallback}, index) => (
          <Stack direction="row" gap={0} key={index}>
            <Typography variant="body1">{label}</Typography>
            <Typography variant="subtitle1">{get(data, value) || fallback || longDash}</Typography>
          </Stack>
        ))}
      </Stack>
      <VideoPlayer data={data} key={data?.id} />
      <Typography mb={2} mt={6} variant="h4">
        о броске
      </Typography>
      <Stack direction="column" gap={2} p={{xxs: 3, sm: 4}} sx={{backgroundColor: grey['4'], borderRadius: '6px'}}>
        {shotInfo.map(({label, value}, index) => (
          <Stack direction="row" flexWrap="wrap" gap={0} key={index}>
            <Typography variant="body1">{label}</Typography>
            <Typography variant="subtitle1">{value || longDash}</Typography>
          </Stack>
        ))}
      </Stack>

      <Typography mb={2} mt={4} variant="h4">
        Игроки на льду
      </Typography>
      <PlayersTable isLoading={shotDetailsLoading} playersOnIce={playersOnIce} />
    </Drawer>
  );
}

export default ShotVideoModal;
