import styled from 'styled-components';

import {ColumnValue} from '../../../styles';

export const MobieHeadColumn = styled.th`
  padding: 7px 14px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #747474;
  letter-spacing: 0.02em;
  text-align: ${(props) => props.align || 'left'};
  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
`;
export const Range = styled(ColumnValue)`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
export const MobileColumn = styled.td`
  text-align: ${(props) => props.align};
  padding: 7px 14px;
  &:first-child {
    padding-left: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  &:last-child {
    padding-right: 24px;
  }
`;
