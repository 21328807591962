import PropTypes from 'prop-types';
import styled from 'styled-components';

const boxSizes = {
  normal: '24px',
  small: '17px',
  big: '34px',
};

const Box = styled.div`
  margin-bottom: ${(props) => props.mb && `${props.mb}px`};
  background-color: ${(props) => !props.noColor && 'white'};
  padding: ${(props) => !props.noPadding && (boxSizes[props.size] || boxSizes.normal)};
  border-radius: 6px;
  display: ${(props) => props.df && 'flex'};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  align-items: ${(props) => props.alignItems};
  position: relative;
`;

Box.propTypes = {
  noColor: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(boxSizes)),
};

export default Box;
