import LineChart from '@components/charts/LineChart';
import date from '@helpers/date';
import useDeepMemo from '@hooks/useDeepMemo';
import useElementSize from '@hooks/useElementSize';
import {Box} from '@ui/MUI';
import {darkRed, grey} from '@ui/MUI/colors';
import {groupBy, uniqBy} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

BMIChart.propTypes = {
  chartsData: PropTypes.array,
  dates: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.number,
  unit: PropTypes.string,
  color: PropTypes.string,
};
const yAxisLabelStyle = {fontFamily: 'Proxima Nova', fontSize: 14, color: grey['54'], fontWeight: 600, margin: 16};
const xAxisLabelStyle = {
  fontFamily: 'Proxima Nova',
  rotate: 45,
  fontSize: 12,
  color: grey['68'],
  margin: 16,
};

function BMIChart({chartsData = [], type, color = darkRed['65'], min, max, unit = ''}) {
  const [ref, {availableHeight}] = useElementSize();

  const {data, moreThanOneSeason, markAreas, xAxisData} = useDeepMemo(() => {
    const data = chartsData?.map((item, index) => ({
      ...item,
      value: item?.[type],
      index,
      tooltip: {
        show: true,
        formatter: `
          ${date(Number(item?.date)).format('DD.MM.YY')}<br>
          ${item?.[type] + unit}
        `,
      },
    }));
    const moreThanOneSeason = uniqBy(data, 'season.id')?.length > 1;
    const markAreas = moreThanOneSeason ? Object.entries(groupBy(data, 'season.id')) : [];
    const xAxisData = data?.map((item) => date(Number(item?.date)).format('DD.MM.YY'));
    return {data, moreThanOneSeason, markAreas, xAxisData};
  }, [chartsData, type, unit]);

  return (
    <Box height={availableHeight - 8} ref={ref}>
      <LineChart
        height="100%"
        options={{
          tooltip: {
            show: true,
            backgroundColor: grey['68'],
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
              fontSize: 12,
              fontFamily: 'Proxima Nova',
            },
          },
          xAxis: {
            show: !moreThanOneSeason,
            position: 'bottom',
            splitLine: {show: false},
            type: 'category',
            data: xAxisData,
            axisTick: {show: false},
            axisLine: {show: false},
            axisLabel: {...xAxisLabelStyle},
            boundaryGap: false,
          },

          yAxis: {
            splitLine: {show: true, lineStyle: {color: grey['8']}},
            axisLabel: {
              formatter: (value) => `${value} ${unit}`,
              ...yAxisLabelStyle,
            },
            type: 'value',
            max: function (value) {
              return !max || max < value.max ? value.max + 20 : max;
            },
            min: function (value) {
              return (!min && min !== 0) || min > value.min ? value.min - 20 : min;
            },
          },
          grid: {
            left: 5,
            show: true,
            containLabel: true,
            top: 20,
            bottom: 10,
            right: 10,
            borderColor: grey['8'],
            borderWidth: 1,
          },
          series: [
            {
              symbol: 'emptyCircle',
              symbolSize: 7,
              data: data?.length ? data : [],
              type: 'line',
              smooth: true,
              color,
              markArea: {
                silent: true,
                offset: 100,
                data: markAreas?.map(([season, items], index) => [
                  {
                    name: `Сезон ${season}`,
                    xAxis: items[0]?.index - (index > 0 ? 1 : 0),
                    itemStyle: {
                      color: 'none',
                      borderColor: grey['8'],
                      borderWidth: 1,
                      borderJoin: 'miter',
                    },
                    label: {
                      position: 'bottom',
                      align: 'center',
                      ...xAxisLabelStyle,
                      rotate: 0,
                      padding: 16,
                    },
                  },
                  {xAxis: items[items.length - 1]?.index},
                ]),
              },
            },
          ],
        }}
      />
    </Box>
  );
}

export default BMIChart;
