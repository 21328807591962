import useEventListener from '@hooks/useEventListener';
import {useCallback, useLayoutEffect, useState} from 'react';

interface Size {
  width: number;
  height: number;
  availableHeight: number;
  scrollWidth: number;
  offsetParent: Element | null;
}

type SetRef = (ref: HTMLElement | null) => void;
type UseElementSize = [SetRef, Size];

function useElementSize(): UseElementSize {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
    availableHeight: 0,
    scrollWidth: 0,
    offsetParent: null,
  });

  // Prevent too many renderings using useCallback
  const handleSize = useCallback(() => {
    if (ref) {
      const availableHeight = window.innerHeight - (ref?.getBoundingClientRect().top || 0);
      setSize({
        width: ref?.offsetWidth || 0,
        height: ref?.offsetHeight || 0,
        scrollWidth: ref?.scrollWidth || 0,
        availableHeight,
        offsetParent: ref.offsetParent,
      });
    }
  }, [ref]);

  useEventListener('resize', handleSize);

  useLayoutEffect(() => {
    handleSize();
  }, [ref]);

  return [setRef, size];
}

export default useElementSize;
