import React from 'react';

function RightArrow() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M7.78279 6.9147L1.72295 0.239632C1.5828 0.0851211 1.3957 -1.22016e-07 1.1962 -1.04575e-07C0.996706 -8.71348e-08 0.809609 0.0851212 0.669453 0.239632L0.223188 0.731092C-0.0671997 1.05133 -0.0671997 1.57182 0.223188 1.89157L5.31178 7.49689L0.217543 13.1084C0.0773862 13.2629 1.17749e-06 13.4689 1.19669e-06 13.6885C1.21591e-06 13.9084 0.0773863 14.1144 0.217543 14.269L0.663808 14.7604C0.804075 14.9149 0.991062 15 1.19056 15C1.39005 15 1.57715 14.9149 1.71731 14.7604L7.78279 8.0792C7.92328 7.9242 8.00044 7.71725 8 7.49726C8.00044 7.2764 7.92328 7.06958 7.78279 6.9147Z"
          fill="#2C2C2C"
          fillOpacity=".2"
        />
      </g>
    </React.Fragment>
  );
}
export default RightArrow;
