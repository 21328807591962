import {getFullName} from '@common/DevelopmentJournal/helpers';
import isObjectEmpty from '@helpers/isObjectEmpty';
import {useModal} from '@hooks/skill';
import Drawer from '@ui/MUI/DrawerNew';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import Buttons from './components/Buttons';
import Form from './components/Form';

const CreateModal = () => {
  const {isOpen, close, data} = useModal('create');
  const fullName = getFullName(data?.student);
  const drawerTitle = `Выбор навыка для развития. ${fullName}`;
  const form = useForm();

  useEffect(() => {
    if (!isObjectEmpty(data)) {
      form.reset(data);
    }
  }, [data]);

  return (
    <FormProvider {...form}>
      <Drawer buttons={<Buttons />} open={isOpen} title={drawerTitle} width="700px" onClose={close}>
        <Form form={form} />
      </Drawer>
    </FormProvider>
  );
};

export default CreateModal;
