import {
  ADD_USER,
  ADD_USER_ERROR,
  ADD_USER_SUCCESS,
  CLEAN_CLUBS,
  CLEAN_GROUP_REPORT,
  CLEAN_GROUP_REPORTS,
  CLEAN_GROUPS,
  CLEAN_REPORT,
  CLEAN_USER_REPORTS,
  CLEAN_USERS,
  EDIT_GROUP_REPORT,
  EDIT_GROUP_REPORT_ERROR,
  EDIT_GROUP_REPORT_SUCCESS,
  EDIT_REPORT,
  EDIT_REPORT_ERROR,
  EDIT_REPORT_SUCCESS,
  GET_CLUBS,
  GET_CLUBS_ERROR,
  GET_CLUBS_SUCCESS,
  GET_GROUP_REPORT,
  GET_GROUP_REPORT_ERROR,
  GET_GROUP_REPORT_SUCCESS,
  GET_GROUP_REPORTS,
  GET_GROUP_REPORTS_ERROR,
  GET_GROUP_REPORTS_SUCCESS,
  GET_GROUPS,
  GET_GROUPS_ERROR,
  GET_GROUPS_SUCCESS,
  GET_REPORT,
  GET_REPORT_ERROR,
  GET_REPORT_SUCCESS,
  GET_USER_REPORTS,
  GET_USER_REPORTS_ERROR,
  GET_USER_REPORTS_SUCCESS,
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
} from '../ActionTypes.js';

const initialState = {
  clubs: {
    loading: false,
    isLoad: false,
    error: {},
    data: {
      result: [],
      total: 0,
    },
  },
  groups: {
    loading: false,
    isLoad: false,
    error: {},
    data: {
      result: [],
      total: 0,
    },
    school: {
      name: '',
    },
  },
  users: {
    loading: false,
    isLoad: false,
    error: {},
    data: [],
    school: {},
    group: {},
    permissions: {},
  },
  reports: {
    loading: false,
    isLoad: false,
    error: {},
    data: [],
    total: 0,
    permissions: {},
    student: {},
    school: {},
    group: {},
  },
  report: {
    loading: false,
    isLoad: false,
    error: {},
    data: {},
    student: {},
    school: {},
    group: {},
    permissions: {},
  },
  groupReports: {
    loading: false,
    isLoad: false,
    error: {},
    data: [],
    total: 0,
    permissions: {},
    group: {},
  },
  groupReport: {
    loading: false,
    isLoad: false,
    error: {},
    data: {},
    group: {},
    permissions: {},
  },
};

const ScoutReportReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CLUBS:
      return {
        ...state,
        clubs: {
          ...state.clubs,
          loading: true,
          isLoad: false,
        },
      };

    case GET_CLUBS_SUCCESS:
      return {
        ...state,
        clubs: {
          ...state.clubs,
          loading: false,
          isLoad: true,
          data: {...action.payload},
        },
      };

    case GET_CLUBS_ERROR:
      return {
        ...state,
        clubs: {
          ...state.clubs,
          loading: false,
          isLoad: true,
          error: action.payload,
        },
      };
    case CLEAN_CLUBS:
      return {
        ...state,
        clubs: {
          ...initialState.clubs,
        },
      };

    case GET_GROUPS:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: true,
          isLoad: false,
        },
      };

    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false,
          isLoad: true,
          data: {result: [...action.groups], total: 0},
          school: {...action.school},
        },
      };

    case GET_GROUPS_ERROR:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false,
          isLoad: true,
          error: action.payload,
        },
      };

    case CLEAN_GROUPS:
      return {
        ...state,
        clubs: {
          ...initialState.groups,
        },
      };

    case GET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          isLoad: false,
        },
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          isLoad: true,
          data: [...action.users],
          school: {...action.school},
          group: {...action.group},
          permissions: {...action.permissions},
        },
      };

    case GET_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          isLoad: true,
          error: action.payload,
        },
      };

    case CLEAN_USERS:
      return {
        ...state,
        users: {
          ...initialState.users,
        },
      };
    case ADD_USER:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          isLoad: false,
        },
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          isLoad: true,
          data:
            action?.hockeyRole === action.payload?.hockeyRole || !action?.hockeyRole
              ? [...state.users.data, {...action.payload}]
              : [...state.users.data],
        },
      };
    case ADD_USER_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          isLoad: true,
        },
      };

    case GET_USER_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
          isLoad: false,
        },
      };

    case GET_USER_REPORTS_SUCCESS:
      const {reports, group, school, student, permissions, total} = action;
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          isLoad: true,
          data: [...reports],
          group: {...group},
          school: {...school},
          student: {...student},
          permissions: {...permissions},
          total,
        },
      };

    case GET_USER_REPORTS_ERROR:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          isLoad: true,
          error: action.payload,
        },
      };

    case CLEAN_USER_REPORTS:
      return {
        ...state,
        reports: {
          ...initialState.reports,
        },
      };
    case GET_REPORT:
      return {
        ...state,
        report: {
          ...state.report,
          loading: true,
          isLoad: false,
        },
      };

    case GET_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          loading: false,
          isLoad: true,
          data: {...action.report},
          student: {...action.student},
          school: {...action.school},
          group: {...action.group},
          permissions: {...action.permissions},
        },
      };

    case GET_REPORT_ERROR:
      return {
        ...state,
        report: {
          ...state.report,
          loading: false,
          isLoad: true,
          error: {...action.payload},
        },
      };

    case CLEAN_REPORT:
      return {
        ...state,
        report: {
          ...initialState.report,
        },
      };

    case EDIT_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
          isLoad: false,
        },
      };

    case EDIT_REPORT_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          isLoad: true,
          data: [...state.reports.data].map((item) => (item.id === action.payload.id ? {...action.payload} : item)),
        },
      };

    case EDIT_REPORT_ERROR:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          isLoad: true,
        },
      };

    case GET_GROUP_REPORTS:
      return {
        ...state,
        groupReports: {
          ...state.groupReports,
          loading: true,
          isLoad: false,
        },
      };

    case GET_GROUP_REPORTS_SUCCESS:
      return {
        ...state,
        groupReports: {
          ...state.groupReports,
          loading: false,
          isLoad: true,
          data: [...action.reports],
          group: {...action.group},
          permissions: {...action.permissions},
          total: {...action.total},
        },
      };

    case GET_GROUP_REPORTS_ERROR:
      return {
        ...state,
        groupReports: {
          ...state.groupReports,
          loading: false,
          isLoad: true,
          error: action.payload,
        },
      };

    case CLEAN_GROUP_REPORTS:
      return {
        ...state,
        groupReports: {
          ...initialState.groupReports,
        },
      };

    case GET_GROUP_REPORT:
      return {
        ...state,
        groupReport: {
          ...state.groupReport,
          loading: true,
          isLoad: false,
        },
      };

    case GET_GROUP_REPORT_SUCCESS:
      return {
        ...state,
        groupReport: {
          ...state.groupReport,
          loading: false,
          isLoad: true,
          data: {...action.report},
          group: {...action.group},
          permissions: {...action.permissions},
        },
      };

    case GET_GROUP_REPORT_ERROR:
      return {
        ...state,
        groupReport: {
          ...state.groupReport,
          loading: false,
          isLoad: true,
          error: {...action.payload},
        },
      };

    case CLEAN_GROUP_REPORT:
      return {
        ...state,
        groupReport: {
          ...initialState.groupReport,
        },
      };
    case EDIT_GROUP_REPORT:
      return {
        ...state,
        groupReports: {
          ...state.groupReports,
          loading: true,
          isLoad: false,
        },
      };

    case EDIT_GROUP_REPORT_SUCCESS:
      return {
        ...state,
        groupReports: {
          ...state.groupReports,
          loading: false,
          isLoad: true,
          data: [...state.groupReports.data].map((item) =>
            item.id === action.payload.id ? {...action.payload} : item,
          ),
        },
      };

    case EDIT_GROUP_REPORT_ERROR:
      return {
        ...state,
        groupReports: {
          ...state.groupReports,
          loading: false,
          isLoad: true,
        },
      };
    default:
      return state;
  }
};

export default ScoutReportReducer;
