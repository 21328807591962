import ProgressCell from '@common/Statistics/PlayerStatistics/ProgressCell';
import TableTemplate from '@common/Statistics/PlayerStatistics/TableTemplate';
import {customTableComparator} from '@src/helpers';
import {Tooltip, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

import SingleProgressCell from '../../SingleProgressCell';

const columns = [
  {
    accessorKey: 'hits',
    header: () => {
      return (
        <Tooltip title="Хиты">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Хиты
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {position: 'relative', textAlign: 'center'},
    cell: ({getValue}) => {
      return <SingleProgressCell percent={Math.abs(getValue()?.value)} value={getValue()?.value} />;
    },
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'winBattlesPercent',
    header: () => {
      return (
        <Tooltip title="Выигранные единоборства %">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Ед+%
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {position: 'relative', textAlign: 'center'},
    cell: ({getValue}) => <ProgressCell isIndividual percent={getValue()?.percent || 0} value={getValue()?.value} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'winBattles',
    header: () => {
      return (
        <Tooltip title="Выигранные единоборства">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Ед+
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
  },
  {
    accessorKey: 'allBattles',
    header: () => {
      return (
        <Tooltip title="Всего единоборств">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Ед
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
  },
];

function PowerStruggleTab({isLoad, isLoading, data, hasError, hasMatchFilter}) {
  const [sorting, setSorting] = useState([{asc: true, id: 'hits'}]);
  const rowsData = useMemo(
    () =>
      data?.map((item) => {
        return {
          student: item?.student,
          hits: item?.hits,
          winBattlesPercent: item?.winBattlesPercent || {value: 0},
          winBattles: (item?.winBattles || item?.winBattles === 0) && item?.winBattles,
          allBattles: (item?.allBattles || item?.allBattles === 0) && item?.allBattles,
        };
      }),
    [data],
  );

  return (
    <TableTemplate
      columns={columns}
      hasError={hasError}
      hasMatchFilter={hasMatchFilter}
      isLoad={isLoad}
      isLoading={isLoading}
      rowsData={rowsData}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
}

PowerStruggleTab.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PowerStruggleTab;
