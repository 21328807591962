import {mock} from '@components/apps/GameStatisticsParsingCheck/components/Goals/mock';
import TableComponent from '@components/apps/GameStatisticsParsingCheck/components/TableComponent';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useDeepMemo from '@hooks/useDeepMemo';
import {selectGameStatisticsParsingCheckTabs} from '@selectors/gameStatisticsParsingCheck';
import {longDash} from '@src/constants';
import {Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import GoalCell from '../GoalCell';

Goals.propTypes = {
  tab: PropTypes.string.isRequired,
};

function Goals({tab}) {
  const {data, isLoading, isLoad, hasError} = useSelector(
    (state) => selectGameStatisticsParsingCheckTabs(state, tab),
    shallowEqual,
  );

  const columns = useMemo(
    () => [
      {
        id: 'goals',
        accessorKey: 'goals',
        header: 'Голы',
        headerStyle: {boxShadow: `inset -1px -1px 0 0 ${grey[12]}`},
        cellStyle: {boxShadow: `inset -1px -1px 0 0 ${grey[12]}`},
        cell: (params) => {
          const value = params?.getValue();
          return <GoalCell isLoading={isLoading} isOponent={!value?.isOurGoal} type={value?.type} />;
        },
      },
      {
        id: 'player',
        accessorKey: 'player',
        header: 'Игрок',
        cell: (params) => {
          const student = params?.getValue();
          return (
            <PlayersHorizontalCard
              hasImage={false}
              isLoading={isLoading}
              preloaderWidthBetween={[100, 170]}
              student={{...student, playerNumber: student?.number}}
            />
          );
        },
      },
      {
        id: 'playerTeam',
        accessorKey: 'playerTeam',
        header: 'Команда игрока',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 110]} variant="body2">
            {getValue() || longDash}
          </Typography>
        ),
      },
      {
        id: 'time',
        accessorKey: 'time',
        header: 'Время',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 80]} variant="body2">
            {getValue() || longDash}
          </Typography>
        ),
      },
      {
        id: 'videoTime',
        accessorKey: 'videoTime',
        header: 'Видео-тайм',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 80]} variant="body2">
            {getValue() || longDash}
          </Typography>
        ),
      },
      {
        id: 'gameFormat',
        accessorKey: 'gameFormat',
        header: 'Формат игры',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[80, 100]} variant="body2">
            {getValue() || longDash}
          </Typography>
        ),
      },
      {
        id: 'currentScore',
        accessorKey: 'currentScore',
        header: 'Текущий счет',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 60]} variant="body2">
            {getValue() || longDash}
          </Typography>
        ),
      },
    ],
    [isLoading],
  );

  const formattedData = useMemo(
    () =>
      data
        ?.map((item) => {
          if (item?.assists?.length) {
            const {assists, ...rest} = item;
            return [{...rest}, ...assists];
          }
          return item;
        })
        ?.flat() || [],
    [data],
  );
  const rows = useDeepMemo(
    () =>
      (isLoading ? mock : formattedData).map((item, id) => ({
        id,
        goals: {
          type: item?.type,
          isOurGoal: item?.isOurGoal,
        },
        player: {
          number: item?.playerNumber,
          lastName: item?.playerLastName,
          firstName: item?.playerFirstName,
        },
        playerTeam: item?.playerTeam,
        time: item?.time,
        videoTime: item?.videoTime,
        gameFormat: item?.gameFormat,
        currentScore: item?.currentScore,
      })),
    [isLoading, data],
  );
  return (
    <TableComponent
      columns={columns}
      data={rows}
      hasError={hasError || !data?.length}
      isLoad={isLoad}
      isLoading={isLoading}
      tab={tab}
    />
  );
}

export default Goals;
