import SkillsCategoryTab from '@components/apps/TrainingControlProcess/components/Skills/components/SkillsCategoryTab';
import SkillsLibaryTab from '@components/apps/TrainingControlProcess/components/Skills/components/SkillsLibaryTab';
import {useAge} from '@components/apps/TrainingControlProcess/hooks/api';
import useDeepMemo from '@hooks/useDeepMemo';
import TabsNew from '@ui/MUI/TabsNew';
import React from 'react';

import {PageBox} from '../../TrainingControlProcess';

const Skills = () => {
  const {data} = useAge();
  const tabs = useDeepMemo(
    () => [
      ...data.map((item) => ({label: `Навыки ${item.name}`, value: <SkillsLibaryTab ageId={item.id} />})),
      {label: 'направления подготовки', value: <SkillsCategoryTab />},
    ],
    [data],
  );
  return (
    <PageBox>
      <TabsNew tabs={tabs} />
    </PageBox>
  );
};

export default Skills;
