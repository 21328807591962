import {Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React from 'react';

Score.propTypes = {
  variant: PropTypes.string,
  score: PropTypes.array.isRequired,
};

function Score({
  variant = 'body2',
  score = [],
  winnerProps = {fontWeight: 600},
  looserProps = {color: grey['54']},
  ...props
}) {
  return (
    <Stack alignItems="center" direction="row" {...props}>
      <Typography {...(score[0] >= score[1] ? winnerProps : looserProps)} variant={variant}>
        {score[0]}
      </Typography>
      <Typography color={grey['54']} variant={variant}>
        :
      </Typography>
      <Typography {...(score[1] >= score[0] ? winnerProps : looserProps)} variant={variant}>
        {score[1]}
      </Typography>
    </Stack>
  );
}

export default Score;
