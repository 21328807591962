import MarkdownEditor from '@common/MarkdownEditor';
import {getRoleLabel} from '@helpers/roles';
import {
  HAND_MAP,
  HOCKEY_ROLES,
  longDash,
  POTENTIAL_ASSOCIATIONS,
  ROLE_MODEL_ASSOCIATIONS,
  SCOUT_REPORT_TYPES,
} from '@src/constants';
import {isEmpty} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import LocalPreloader from '../../../preloader/LocalPreloader';
import DrawerDefault from '../../../ui/MUI/Modals/Drawer/Drawer';
import DeleteModal from '../../DeleteModal';
import {Field, FixedInput, StyledButton} from '../CommonScoutReport';
import {PERMISSIONS} from '../constants';
import ReportCreateContent from '../ReportCreateContent';
import {ButtonsWrapper, Header, HeaderDate, HeaderTeam} from './ReportViewModal';

function ReportViewModal({isOpen, setIsOpen = () => {}, reportType, report, onClose, onEdit, onDelete}) {
  const methods = useForm({
    mode: 'onTouched',
  });
  const {handleSubmit, reset} = methods;

  const canEdit = report.permissions?.[PERMISSIONS.canEdit];

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    if ((!isEmpty(report.error) && isOpen.right) || (report.isLoad && isEmpty(report.data))) setIsOpen();
  }, [report.error, isOpen.right]);

  useEffect(() => {
    if (!isEmpty(report.data)) {
      reset({
        ...report.data,
      });
    }
    return () => {
      reset();
      setIsEdit(false);
    };
  }, [report.isLoad]);

  const [isEdit, setIsEdit] = useState(false);

  return (
    <>
      <DrawerDefault
        buttons={
          <ButtonsWrapper>
            {report.isLoad && (
              <>
                {isEdit ? (
                  <>
                    {canEdit && (
                      <StyledButton
                        color="primary"
                        size="medium"
                        variant="contained"
                        onClick={() =>
                          handleSubmit((data) => {
                            const formattedData = {
                              ...data,
                              gameCount: Number(data.gameCount),
                              goalPoint: Number(data.goalPoint),
                              passPoint: Number(data.passPoint),
                            };
                            onEdit(
                              report.data.id,
                              Object.keys(formattedData)?.reduce((result, currentKey) => {
                                if (currentKey !== 'author' && formattedData[currentKey]) {
                                  result[currentKey] = formattedData[currentKey];
                                }
                                return result;
                              }, {}),
                            );
                            onClose();
                          })()
                        }
                      >
                        сохранить
                      </StyledButton>
                    )}
                    {canEdit && (
                      <StyledButton
                        color="primary"
                        margin={canEdit ? '0 0 0 auto' : ''}
                        size="medium"
                        variant="outlined"
                        onClick={() => setIsDeleteModalVisible(true)}
                      >
                        удалить
                      </StyledButton>
                    )}
                  </>
                ) : (
                  canEdit && (
                    <StyledButton
                      color="primary"
                      margin="0px"
                      size="medium"
                      variant="contained"
                      onClick={() => setIsEdit(true)}
                    >
                      редактировать
                    </StyledButton>
                  )
                )}
                <StyledButton color="neutral" size="medium" variant="contained" onClick={onClose}>
                  Закрыть
                </StyledButton>
              </>
            )}
          </ButtonsWrapper>
        }
        content={
          <>
            <LocalPreloader visible={report.loading} />
            {isEmpty(report.data) ? (
              <></>
            ) : !isEdit ? (
              <>
                <Field align="flex-start" justify="space-between" margin="0 0 24px 0">
                  <div>
                    <Header>
                      {report.student?.playerNumber && <span className="number">{report.student?.playerNumber}</span>}{' '}
                      {`${report.student?.firstName || ''} ${report.student?.lastName || ''}`}
                    </Header>
                    <HeaderTeam>
                      {(reportType === SCOUT_REPORT_TYPES.inner ? report.group?.name : report.school?.name) || ''}
                    </HeaderTeam>
                  </div>
                  <HeaderDate>
                    {report.data?.createdAt ? moment(report.data?.createdAt).format('DD.MM.YYYY') : ''}
                  </HeaderDate>
                </Field>
                {reportType === SCOUT_REPORT_TYPES.inner ? (
                  <InnerReportFields
                    studentData={{
                      ...report.student,
                      gameCount: report.data.gameCount,
                      goalPoint: report.data.goalPoint,
                      passPoint: report.data.passPoint,
                    }}
                  />
                ) : (
                  <OuterReportFields report={report.data} studentData={report.student} />
                )}
                <Field align="flex-start" margin="0 0 24px 0">
                  <FixedInput maxWidth="160px">
                    <span className="label">Потенциал</span>
                    <span className="text">{POTENTIAL_ASSOCIATIONS?.[report.data?.potential] || longDash}</span>
                  </FixedInput>
                  <FixedInput>
                    <span className="label">Ролевая модель</span>
                    <span className="text">{ROLE_MODEL_ASSOCIATIONS?.[report.data?.roleModel] || longDash}</span>
                  </FixedInput>
                </Field>
                <Field align="flex-start" margin="0 0 16px 0">
                  <FixedInput>
                    <span className="label">Первенство</span>
                    <span className="text">{report?.data?.championship || longDash}</span>
                  </FixedInput>
                </Field>

                <Field align="flex-start" margin="0 0 24px 0">
                  <FixedInput>
                    <span className="label">Количество игр</span>
                    <span className="text">{report?.data?.gameCount || 0}</span>
                  </FixedInput>
                  {HOCKEY_ROLES?.[report.student?.hockeyRole] === HOCKEY_ROLES.goaltender ? (
                    <>
                      <FixedInput>
                        <span className="label">Коэффициент надёжности</span>
                        <span className="text">{report?.data?.impenetrability || longDash}</span>
                      </FixedInput>
                      <FixedInput>
                        <span className="label">% отраженных бросков</span>
                        <span className="text">{report?.data?.deflected || longDash}%</span>
                      </FixedInput>
                    </>
                  ) : (
                    <FixedInput>
                      <span className="label">Очки (Гол + Пас)</span>
                      <span className="text">{`${report?.data.goalPoint || 0} + ${report?.data.passPoint || 0}`}</span>
                    </FixedInput>
                  )}
                </Field>

                <Field align="flex-start" margin="0 0 24px 0">
                  <FixedInput>
                    <span className="label">Отчет</span>
                    <MarkdownEditor readOnly value={report.data?.description || longDash} />
                  </FixedInput>
                </Field>
                <Field align="flex-start" margin="0 0 24px 0">
                  <FixedInput>
                    <span className="label">Сильные стороны</span>
                    <span className="text">{report.data.strongSide || longDash}</span>
                  </FixedInput>
                </Field>
                <Field align="flex-start" margin="0 0 24px 0">
                  <FixedInput>
                    <span className="label">Слабые стороны</span>
                    <span className="text">{report.data.weakSide || longDash}</span>
                  </FixedInput>
                </Field>
                <Field align="flex-start" margin="0 0 24px 0">
                  <FixedInput>
                    <span className="label">Автор</span>
                    <span className="text">
                      {report?.data?.author
                        ? `
                        ${report?.data?.author?.firstName}
                        ${report?.data?.author?.lastName}, 
                        ${getRoleLabel(
                          report?.data?.author?.userRole,
                          report?.data?.author?.teacherRole,
                        )?.toLowerCase()}`
                        : longDash}
                    </span>
                  </FixedInput>
                </Field>
              </>
            ) : (
              <FormProvider {...methods}>
                <ReportCreateContent
                  isEdit={true}
                  reportType={reportType}
                  studentData={{
                    student: {...report?.student} || {},
                    group: {...report?.group} || {},
                    school: {...report?.school} || {},
                  }}
                />
              </FormProvider>
            )}
          </>
        }
        drawerState={isOpen}
        hasFullWidth={'false'}
        setDrawerState={setIsOpen}
        onClose={onClose}
      />
      <DeleteModal
        isVisible={isDeleteModalVisible}
        text="Вы уверены, что хотите удалить отчет?"
        title="Удаление отчета"
        onCancel={() => setIsDeleteModalVisible(false)}
        onDelete={() => {
          setIsDeleteModalVisible(false);
          onDelete(report.data?.id);
          onClose();
        }}
      />
    </>
  );
}

ReportViewModal.propTypes = {
  isOpen: PropTypes.shape({
    top: PropTypes.bool,
    left: PropTypes.bool,
    bottom: PropTypes.bool,
    right: PropTypes.bool,
  }),
  setIsOpen: PropTypes.func,
  reportType: PropTypes.string.isRequired,
  report: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
};

export default ReportViewModal;

function InnerReportFields({studentData}) {
  return (
    <>
      <Field align="flex-start" margin="0 0 38px 0">
        <FixedInput>
          <span className="label">Амплуа</span>
          <span className="text">{HOCKEY_ROLES[studentData.hockeyRole] || ''}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">Хват</span>
          <span className="text">{HAND_MAP[studentData.hand] || ''}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">Вес</span>
          <span className="text">{studentData.weight ? `${studentData.weight} кг` : ''}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">Рост</span>
          <span className="text">{studentData.height ? `${studentData.height} см` : ''}</span>
        </FixedInput>
      </Field>
    </>
  );
}

function OuterReportFields({studentData}) {
  return (
    <>
      <Field align="flex-start" margin="0 0 24px 0">
        <FixedInput>
          <span className="label">Дата рождения</span>
          <span className="text">
            {studentData?.birthday ? `${moment(studentData?.birthday).format('DD.MM.YYYY')} г.` : ''}
          </span>
        </FixedInput>
        <FixedInput>
          <span className="label">Амплуа</span>
          <span className="text">{HOCKEY_ROLES[studentData?.hockeyRole]}</span>
        </FixedInput>
        <FixedInput>
          <span className="label">Хват</span>
          <span className="text">{HAND_MAP[studentData?.hand]}</span>
        </FixedInput>
      </Field>
    </>
  );
}
