import React from 'react';

function Eye() {
  return (
    <React.Fragment>
      <path
        d="M9.40002 4C5.76366 4 2.65821 6.28067 1.40002 9.5C2.65821 12.7193 5.76366 15 9.40002 15C13.0364 15 16.1418 12.7193 17.4 9.5C16.1418 6.28067 13.0364 4 9.40002 4ZM9.40002 13.1667C7.39275 13.1667 5.76366 11.524 5.76366 9.5C5.76366 7.476 7.39275 5.83333 9.40002 5.83333C11.4073 5.83333 13.0364 7.476 13.0364 9.5C13.0364 11.524 11.4073 13.1667 9.40002 13.1667ZM9.40002 7.3C8.19275 7.3 7.21821 8.28267 7.21821 9.5C7.21821 10.7173 8.19275 11.7 9.40002 11.7C10.6073 11.7 11.5818 10.7173 11.5818 9.5C11.5818 8.28267 10.6073 7.3 9.40002 7.3Z"
        fill="black"
        fillOpacity="0.87"
      />
    </React.Fragment>
  );
}
export default Eye;
