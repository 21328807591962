import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {calcWidth} from '@helpers/theme';
import {longDash} from '@src/constants';
import {Box, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React, {useMemo} from 'react';

function GoaltenderMetric({
  label,
  count,
  countUnit,
  percent,
  placeInTournament,
  isLoading,
  averageValue,
  valueLabel,
  itemsCount,
  itemIndex,
  playerTimeInMinutes,
}) {
  const isLastOfThree = useMemo(() => itemIndex >= itemsCount - 3, [itemsCount, itemIndex]);
  const isOddLength = useMemo(() => itemsCount % 2 !== 0, [itemsCount]);
  return (
    <Wrapper
      p={{xxs: 3, xs: 4}}
      width={(theme) => ({
        xxs: '100%',
        xs: calcWidth(2, theme.spacing(3)),
        md: calcWidth(3, theme.spacing(3)),
        lg: calcWidth(isOddLength && isLastOfThree && itemsCount > 5 ? 3 : 4, theme.spacing(3)),
      })}
    >
      <Typography mb={3} variant="h4">
        {label}
      </Typography>
      {count || percent || placeInTournament ? (
        <Stack alignItems="center" direction="row" spacing={3}>
          <Box>
            <Stack alignItems="flex-end" direction="row" gap={0}>
              <Typography
                color="primary"
                fontSize="40px"
                fontWeight={600}
                isLoading={isLoading}
                lineHeight="40px"
                preloaderWidthBetween={[20, 40]}
              >
                {percent || count || longDash}
              </Typography>
              {(percent || count) && (
                <Typography color="primary" variant="subtitle1">
                  {countUnit}
                </Typography>
              )}
            </Stack>
            {(averageValue || valueLabel) && (
              <Typography color={grey['54']} variant="body2">
                {averageValue ? `ср. знач: ${averageValue}` : valueLabel}
              </Typography>
            )}
          </Box>
          {playerTimeInMinutes && (
            <Stack alignItems="flex-end" direction="row" gap={0}>
              <Typography color="primary" sx={{fontSize: '18px', lineHeight: '24px'}} variant="subtitle2">
                {playerTimeInMinutes}
              </Typography>
              <Typography variant="subtitle2">минут</Typography>
            </Stack>
          )}
          {placeInTournament && (
            <Stack direction="column">
              <Stack alignItems="flex-end" direction="row" gap={0}>
                <Typography color="primary" isLoading={isLoading} preloaderWidthBetween={[10, 30]} variant="h3">
                  {placeInTournament}
                </Typography>
                <Typography color="primary" fontWeight={600} variant="body2">
                  место
                </Typography>
              </Stack>
              <Typography color={grey['54']} variant="body2">
                в турнире
              </Typography>
            </Stack>
          )}
        </Stack>
      ) : (
        <Typography py={3} variant="subtitle1">
          Нет данных по показателю
        </Typography>
      )}
    </Wrapper>
  );
}

export default GoaltenderMetric;
