const groupObj = {
  id: 'sMO6zOAileW0',
  name: 'Name',
  birthYear: 1800,
  schoolId: 'zAsFb02i53',
  videos: 0,
};
export const mock = [
  {
    id: 'zAsFb02i53',
    name: 'School',
    groups: [...Array(12)].fill(groupObj),
  },
  {
    id: 'ITfD4PL0oy',
    name: 'School',
    groups: [...Array(12)].fill(groupObj),
  },
];
