import {loadAllLessonsTypes} from '@actions/LessonsActions';
import * as actions from '@actions/LessonsActions';
import {loadAllDressingRooms} from '@actions/RoomsActions';
import * as roomsActions from '@actions/RoomsActions';
import {loadStudents, loadTeachers} from '@actions/UsersActions';
import CommonHelper from '@helpers/CommonHelper';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import Calendar from '../../calendar';

export default function SchoolLessonsPanel(props) {
  const {schoolId, hasFilter = false} = props;

  useEffect(() => {
    dispatch(loadTeachers());
    dispatch(loadStudents());
  }, []);

  const {currentUser, loading, lessons, school, teachers, rooms, groups} = useSelector((state) => {
    const sc = state.schools.schoolsMap.get(schoolId);
    let teachers = state.users.usersMap
      .toArray()
      .filter((a) => a.userRole === 'teacher')
      .filter((t) => sc.teachersIds !== undefined && sc.teachersIds.indexOf(t.id) > -1);
    const frId = sc?.franchiseeId;
    const franchiseeStudentsArray =
      frId === undefined
        ? state.users.usersMap.toArray().filter((u) => u.userRole === 'student')
        : state.users.usersMap.toArray().filter((u) => u.userRole === 'student' && u.franchiseeId === frId);

    return {
      currentUser: state.users.currentUser,
      loading: state.lessons.loading,
      school: sc,
      lessons: state.lessons.calendarLessons || [],
      teachers: teachers,
      franchiseeStudents: franchiseeStudentsArray,
      rooms: CommonHelper.getSortedArray(state.rooms.roomsMap.toArray().filter((a) => a.schoolId === schoolId)),
      groups: state.groups.groupsMap.toArray().filter((a) => a.schoolId === schoolId),
      currentUserIsFranchisee: CommonHelper.getCurrentUserRole(state) === 'franchisee',
      currentUserIsAdmin: CommonHelper.getCurrentUserRole(state) === 'admin',
      usersMap: state.users.usersMap,
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if (hasFilter === false) {
      return;
    }
    dispatch(roomsActions.loadAllRooms()).then((pld) => {
      if (pld.rooms === undefined || pld.rooms.length === 0) {
        return;
      }
      const fRooms = CommonHelper.getSortedArray(
        pld.rooms.filter((a) => a.schoolId === schoolId),
        'name',
      );
      if (fRooms.length > 0) {
        // setFilterRoomId(fRooms[0].id);
      }
    });

    dispatch(loadAllDressingRooms());
    dispatch(loadAllLessonsTypes());
  }, []);
  const [filterState, setFilterState] = useState({});

  const roomsObj = {};

  rooms.forEach((room) => {
    roomsObj[room.id] = room;
  });

  const calendarRangeState = {
    startDate: new Date().getTime() - 7776000000,
    endDate: new Date().getTime() + 7776000000,
  };

  const formatFilterDataForRequest = (filter, schoolId) => {
    const formattedFilter = {
      schoolIds: schoolId,
      ...calendarRangeState,
    };

    Object.entries(filter).map(([key, value]) => {
      if (value.length) {
        formattedFilter[key] = value.map((el) => el.value).join(',');
      }
    });
    return formattedFilter;
  };

  const onFilterChange = (filter) => {
    const formattedFilterDataForRequest = formatFilterDataForRequest(filter, school.id);
    setFilterState(filter);
    dispatch(actions.loadLessonsByFilter(formattedFilterDataForRequest));
  };

  //ToDo Delete this code
  useEffect(() => {
    if (currentUser.userRole === 'student') {
      const newFilter = {
        studentIds: [{value: currentUser.id}],
      };
      const formattedFilterDataForRequest = formatFilterDataForRequest(newFilter, currentUser.schoolId);
      setFilterState(newFilter);
      dispatch(actions.loadLessonsByFilter(formattedFilterDataForRequest));
    }
  }, []);
  if (school === undefined || currentUser === undefined) {
    return null;
  }
  return (
    <Wrapper className={props.className}>
      <div>
        <Calendar
          currentUser={currentUser}
          filterState={filterState}
          groups={groups}
          lessons={lessons}
          loading={loading}
          rooms={rooms}
          school={school}
          teachers={teachers}
          onFilterChange={onFilterChange}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding-right: 2px;
  padding-left: 2px;
`;
