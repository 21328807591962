import Field from '@common/FormField';
import {useGroup} from '@hooks/filters';
import React, {memo} from 'react';
import {useFormContext} from 'react-hook-form';

function GroupField({isEdit, ...props}) {
  const {watch} = useFormContext();
  const groups = useGroup({connectorValue: watch('school')?.value});
  return (
    <Field
      readOnly={!isEdit}
      size="small"
      textFieldVariant="outlined"
      type="autocomplete"
      {...props}
      isLoading={groups?.isLoading}
      options={groups?.options || []}
    />
  );
}

export default memo(GroupField);
