import field from '@assets/images/field_map.svg';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import {Stack} from '@ui/MUI';
import Box from '@ui/MUI/Box';
import {grey} from '@ui/MUI/colors';
import React from 'react';

import {Cell, Grid, ImageWrapper, Map, Percent, Score} from './FieldMap';

const fieldZones = ['leftMidZone', 'midZone', 'rightMidZone', '', '', '', 'leftBotZone', 'midBotZone', 'rightBotZone'];

function FieldMap({shotsByZones, isLoading, width = {xs: '100%', sm: '74%'}}) {
  return (
    <Box m="0 auto" maxWidth="500px">
      <Map width={width}>
        <ImageWrapper>
          <img alt="Поле" src={field} />
        </ImageWrapper>
        <Grid>
          {fieldZones.map((item, index) => {
            const data = shotsByZones?.[item];
            return (
              <Cell key={index}>
                <Box
                  alignItems="center"
                  className="cell__inner"
                  display="flex"
                  height="100%"
                  justifyContent="center"
                  overflow="hidden"
                  width="100%"
                >
                  <Box textAlign="center">
                    {(data?.percentValue || isLoading) && (
                      <Stack alignItems="center" direction="row" spacing={0}>
                        {data?.isLowestPercent && <LocalFireDepartmentIcon color="primary" fontSize="13px" />}
                        <Percent
                          color={data?.isLowestPercent && 'primary'}
                          isLoading={isLoading}
                          preloaderWidthBetween={[50, 70]}
                        >
                          {data?.percentValue + '%'}
                        </Percent>
                      </Stack>
                    )}
                    <Score color={grey['68']} isLoading={isLoading} preloaderWidthBetween={[30, 40]} variant="h4">
                      {data?.value}
                    </Score>
                  </Box>
                </Box>
              </Cell>
            );
          })}
        </Grid>
      </Map>
    </Box>
  );
}

export default FieldMap;
