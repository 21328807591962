import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import MedicalProfileAPI from '../../../api/MedicalProfileAPI';
import {cleanMedicalProfileHistory, getMedicalProfileHistory} from '../../../redux/actions/MedicalProfileActions';
import Icon from '../../ui/Icon/Icon';

export default function MedicalHistory() {
  const dispatch = useDispatch();

  const cardHistory = useSelector((state) => state.medicalProfile?.cardHistory || []);
  const medicalProfileForm = useSelector((state) => state.medicalProfile?.medicalProfileForm || {});
  const [nutritionTypes, setNutritionTypes] = useState([]);
  const [consultationsTypes, setConsultationsTypes] = useState([]);
  const [trainingsTypes, setTrainingsTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    dispatch(getMedicalProfileHistory(medicalProfileForm.playerId, medicalProfileForm.objectId));
  }, [medicalProfileForm.playerId, medicalProfileForm.objectId]);
  //todo переделать на единый запрос для всей модалки
  useEffect(() => {
    MedicalProfileAPI.getNutritionRecommendations().then((data) => {
      setNutritionTypes(data.data);
    });
    MedicalProfileAPI.getTrainingRecommendations().then((data) => {
      setTrainingsTypes(data.data);
    });
    MedicalProfileAPI.getConsultationRecommendations().then((data) => {
      setConsultationsTypes(data.data);
    });
    MedicalProfileAPI.getMedicalProfileStages().then((data) => {
      setStatuses(data.data);
    });

    return () => {
      dispatch(cleanMedicalProfileHistory());
    };
  }, []);

  // To Do Вынести в функцию или переписать
  const groupBy = (xs, key) => {
    if (!xs) return;
    return xs.reduce((rv, x) => {
      let myKey = '';
      if (key === 'updatedAt') {
        myKey = moment(x[key]).format('DD.MM.YYYY');
      } else {
        myKey = x[key];
      }
      (rv[myKey] = rv[myKey] || []).push(x);
      return rv;
    }, {});
  };

  const sortedByDate = groupBy(cardHistory, 'updatedAt');

  return (
    <Wrapper>
      {Object.keys(sortedByDate).map((key, index) => {
        return (
          <HistoryDateContainer key={'dateCases' + index}>
            <Date>{key}</Date>
            <HistoryItems>
              {sortedByDate[key].map((historyCase, historyIndex) => {
                if (historyCase.category === 'status') {
                  let from = historyCase.from ? statuses.find((type) => type.value === historyCase.from) : '';
                  let to = statuses.find((type) => type.value === historyCase.to);
                  return (
                    <HistoryItem key={'historyCase' + historyIndex}>
                      <HistoryItemIcon>
                        <Icon height={13} type={'Change'} viewBox={'0 0 13 13'} width={13} />
                      </HistoryItemIcon>
                      <HistoryItemTitle>Смена статуса</HistoryItemTitle>
                      <HistoryItemRecommendationName>
                        {from ? from.label : ''} на {to ? to.label : ''}
                      </HistoryItemRecommendationName>
                    </HistoryItem>
                  );
                } else if (historyCase.category === 'nutrition') {
                  let type = nutritionTypes.find((type) => type.value === historyCase.type);
                  return (
                    <HistoryItem key={'historyCase' + historyIndex}>
                      <HistoryItemIcon>
                        <Icon height={13} type={'ForkAndKnife'} viewBox={'0 0 13 13'} width={13} />
                      </HistoryItemIcon>
                      {historyCase.action === 'add' ? (
                        <HistoryItemTitle>
                          <HistoryItemTitleAdd>Добавить</HistoryItemTitleAdd> в рацион питания
                        </HistoryItemTitle>
                      ) : (
                        <HistoryItemTitle>
                          <HistoryItemTitleDelete>Исключить</HistoryItemTitleDelete> из рациона питания
                        </HistoryItemTitle>
                      )}
                      <HistoryItemRecommendationName>{type ? type.label : ''}</HistoryItemRecommendationName>
                      <HistoryItemRecommendationDesc>{historyCase.comments}</HistoryItemRecommendationDesc>
                    </HistoryItem>
                  );
                } else if (historyCase.category === 'consultations') {
                  let type = consultationsTypes.find((type) => type.value === historyCase.type);
                  let date = historyCase.date ? moment(historyCase.date).format('DD.MM.YYYY') : '';
                  let time = historyCase.date ? moment(historyCase.date).format('HH:MM') : '';
                  return (
                    <HistoryItem key={'historyCase' + historyIndex}>
                      <HistoryItemIcon>
                        <Icon height={13} type={'Heart'} viewBox={'0 0 13 13'} width={13} />
                      </HistoryItemIcon>
                      {historyCase.action === 'add' ? (
                        <HistoryItemTitle>
                          <HistoryItemTitleAdd>Назначить</HistoryItemTitleAdd> посещение/консультирование
                        </HistoryItemTitle>
                      ) : (
                        <HistoryItemTitle>
                          <HistoryItemTitleDelete>Отменить</HistoryItemTitleDelete> посещение/консультирование
                        </HistoryItemTitle>
                      )}
                      <HistoryItemRecommendationName>
                        {type ? type.label : ''}, {date} ({time})
                      </HistoryItemRecommendationName>
                      <HistoryItemRecommendationDesc>{historyCase.comments}</HistoryItemRecommendationDesc>
                    </HistoryItem>
                  );
                } else if (historyCase.category === 'training') {
                  let type = trainingsTypes.find((type) => type.value === historyCase.type);
                  return (
                    <HistoryItem key={'historyCase' + historyIndex}>
                      <HistoryItemIcon>
                        <Icon height={13} type={'Thunderbolt'} viewBox={'0 0 8 13'} width={8} />
                      </HistoryItemIcon>
                      {historyCase.action === 'add' ? (
                        <HistoryItemTitle>
                          <HistoryItemTitleAdd>Добавить</HistoryItemTitleAdd> в программу тренировок
                        </HistoryItemTitle>
                      ) : (
                        <HistoryItemTitle>
                          <HistoryItemTitleDelete>Убрать</HistoryItemTitleDelete> из программы тренировок
                        </HistoryItemTitle>
                      )}

                      <HistoryItemRecommendationName>
                        {type ? type.label : ''} ({historyCase.period})
                      </HistoryItemRecommendationName>
                      <HistoryItemRecommendationDesc>{historyCase.comments}</HistoryItemRecommendationDesc>
                    </HistoryItem>
                  );
                }
              })}
            </HistoryItems>
          </HistoryDateContainer>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;
const HistoryDateContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  overflow-y: auto;
  :last-of-type {
    margin-bottom: 0;
  }
  :first-of-type {
    margin-top: 0;
  }
`;
const Date = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  font: bold normal 14px/16px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
`;
const HistoryItems = styled.div``;
const HistoryItem = styled.div`
  position: relative;
  padding-left: 48px;
  padding-bottom: 20px;
  max-width: 515px;

  :after {
    position: absolute;
    top: 36px;
    left: 18px;
    min-height: 1px;
    height: 100%;
    border-left: 1px solid #dddad4;
    content: '';
  }
`;
const HistoryItemIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%);
    width: 20px;
    height: 20px;
  }
`;
const HistoryItemTitle = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font: bold normal 14px/16px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
`;
const HistoryItemTitleAdd = styled.span`
  color: #598855;
`;
const HistoryItemTitleDelete = styled.span`
  color: #a5202f;
`;
const HistoryItemRecommendationName = styled(HistoryItemTitle)`
  margin-top: 4px;
  margin-bottom: 6px;
  font-weight: normal;

  :before {
    padding-right: 4px;
    content: '-';
  }
`;
const HistoryItemRecommendationDesc = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
`;
