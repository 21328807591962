import axios from 'axios';
import moment from 'moment';

import {API_ENDPOINT} from '../constants/config';

const GameEstimateAPI = {
  getGameEstimationFormByRole(userRole, lessonId) {
    return axios.get(`${API_ENDPOINT}/v1/estimate-game/${userRole}/${lessonId}`);
  },
  getGameEstimate(id) {
    return axios.get(`${API_ENDPOINT}/estimate-game/${id}`);
  },
  getGameEstimateReport(lessonId) {
    return axios.get(`${API_ENDPOINT}/v1/estimate-game/report/${lessonId}`);
  },
  getStudentGameEstimateReport(request) {
    return axios.get(`${API_ENDPOINT}/v1/estimate-game/report/${request.lessonId}/student/${request.studentId}`);
  },
  getGroupGameEstimations(schoolId) {
    return axios.get(`${API_ENDPOINT}/estimate-game?groupId=${schoolId}`);
  },
  getSchoolGameEstimations(filter) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/estimate-game?${filter}`)
        .then((d) => d.data)
        .then((game) => {
          if (game.error !== undefined) {
            return reject(game.error);
          }
          resolve(game);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPlayerGameEstimations(playerId) {
    return axios.get(`${API_ENDPOINT}/estimate-game?playerId=${playerId}`);
  },
  getPlayerEstimateChartData(playerId, request) {
    let result = {...request};
    if (result?.startDate) {
      result.startDate = moment(result.startDate).format('DD.MM.YYYY');
    }
    if (result?.endDate) {
      result.endDate = moment(result.endDate).format('DD.MM.YYYY');
    }
    return axios.get(`${API_ENDPOINT}/estimate-game/report/player/${playerId}`, {params: result});
  },
  getGroupEstimateChartData(groupId, request) {
    let result = {...request};
    if (result?.startDate) {
      result.startDate = moment(result.startDate).format('DD.MM.YYYY');
    }
    if (result?.endDate) {
      result.endDate = moment(result.endDate).format('DD.MM.YYYY');
    }
    return axios.get(`${API_ENDPOINT}/estimate-game/report/group/${groupId}`, {params: result});
  },
  getSchoolEstimateChartData(schoolId, request) {
    let result = {...request};
    if (result?.startDate) {
      result.startDate = moment(result.startDate).format('DD.MM.YYYY');
    }
    if (result?.endDate) {
      result.endDate = moment(result.endDate).format('DD.MM.YYYY');
    }
    return axios.get(`${API_ENDPOINT}/estimate-game/report/school/${schoolId}`, {params: result});
  },

  postGameEstimate(request) {
    return axios.post(`${API_ENDPOINT}/v1/estimate-game/${request.userRole}/${request.lessonId}`, request.gameEstimate);
  },
  getGameWithoutEstimateReport(id) {
    return axios.get(`${API_ENDPOINT}/estimate-game/status/${id}`);
  },
  getGamesKeyParams(request) {
    const startDate = moment(request.startDate).format('YYYY-MM-DD');
    const endDate = moment(request.endDate).format('YYYY-MM-DD');
    return axios.get(
      `${API_ENDPOINT}/statistics/game-period?schoolId=${request.schoolId}&teamId=${request.teamId}&timeStart=${startDate}&timeEnd=${endDate}`,
    );
  },
  getPeriodGameEstimateReport(data) {
    return axios.get(
      `${API_ENDPOINT}/estimate-game/report?startDate=${data.startDate}&endDate=${data.endDate}&groupId=${data.groupId}`,
    );
  },
  getPlayerGamePeriodReport(filter) {
    return axios.get(`${API_ENDPOINT}/rating/game-period-report/${filter.teamId}/${filter.id}`);
  },
  getGameEstimationsForPlayersPersonalFile(request) {
    return axios.get(
      `${API_ENDPOINT}/estimate-game/player/${request.playerId}?season=${request.season}&page=${request.page}&page_size=${request.pageSize}`,
    );
  },
};

export default GameEstimateAPI;
