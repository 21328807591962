import {userRoles} from '@src/constants';
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import CommonHelper from '../../../../../helpers/CommonHelper';
import useDeepEffect from '../../../../../hooks/useDeepEffect';
import DefaultFilter from '../DefaultFilter';

const SchoolFilter = (props) => {
  const {name, onChange, actions, value} = props;

  const {updateOptions, updateDisable, updateValue} = actions;

  const {schools, role} = useSelector((state) => {
    const currentUser = state.users.currentUser;
    const role = currentUser.userRole;
    const schoolsIds = currentUser.schoolsIds;
    const allSchools = state.schools.schoolsMap.toArray();
    const allUsers = state.users.usersMap.toArray();

    if (role === userRoles.admin) {
      return {schools: allSchools, role: currentUser.userRole};
    }

    if (role === userRoles.teacher) {
      return {
        schools: allSchools.filter((school) => school.teachersIds.includes(currentUser.id)),
        role: currentUser.userRole,
      };
    }

    if (role === userRoles.student) {
      return {
        schools: allSchools.filter((school) => allUsers.some((user) => user.schoolId === school.id)),
        role: currentUser.userRole,
      };
    }

    if (role === userRoles.parent) {
      const childrensSchools = allUsers
        .filter((user) => currentUser?.childrenIds?.includes(user.id))
        .map((children) => children.schoolId);

      return {schools: allSchools.filter((school) => childrensSchools.includes(school.id)), role};
    }

    return {
      schools: allSchools.filter((school) => schoolsIds.includes(school.id)),
      role: currentUser.userRole,
    };
  }, shallowEqual);

  useDeepEffect(() => {
    const options = CommonHelper.getDropDownItemsByNamedList(schools);

    (async () => {
      if (role === userRoles.admin) {
        await updateOptions(name, options);
        return;
      }

      if (options.length === 1) {
        await updateDisable(name, true);
        await updateValue(name, (value && {value}) || options[0]);
        await updateOptions(name, options);
      }

      if (options.length > 1) {
        await updateValue(name, (value && {value}) || options[0]);
        await updateOptions(name, options);
      }
    })();
  }, [schools, role, value]);

  return (
    <DefaultFilter
      actions={actions}
      name={name}
      onChange={async (select, action) => {
        await updateValue('group', null);
        await updateOptions('group', []);

        await updateValue('student', null);
        await updateOptions('student', []);
        onChange(select, action);
      }}
    />
  );
};

export default SchoolFilter;
