import {defensemenPositions, wingerPositions} from '@src/constants';
import ButtonDefault from '@ui/MUI/Button';
import React from 'react';
import {Scrollbar} from 'react-scrollbars-custom';

import TeacherHockeyLinesPlayerCard from '../../TeacherHockeyLinesPlayerCard';
import TeacherHockeyLinesPlayerEmptyCard from '../../TeacherHockeyLinesPlayerEmptyCard';
import {
  ButtonsBottomBlock,
  LinesMobileTable,
  LinesMobileTableBody,
  LinesMobileTableContainer,
  LinesMobileTableGoaltenders,
  LinesMobileTableGoaltendersHead,
  LinesMobileTableGoaltendersTd,
  LinesMobileTableGoaltendersTh,
  LinesMobileTableGoaltendersTr,
  LinesMobileTableHead,
  LinesMobileTableTd,
  LinesMobileTableTdFlexWrapper,
  LinesMobileTableTh,
  LinesMobileTableTr,
} from '../LinesMobile';

export default function LinesMobileTableComponent({
  linesForm,
  isCanEdit,
  setIsPlayersListModalOpen,
  setSelectedPlayer,
  isActiveCard,
  saveLines,
  setCancelModalState,
  deletePlayerFromPosition,
  deleteGoaltenderPlayerFromPosition,
  isButtonsShowed = true,
}) {
  return (
    <LinesMobileTableContainer isButtonsShowed={isButtonsShowed}>
      <Scrollbar
        style={{
          height: linesForm?.playersLines?.length * 100 + 26,
          width: '100%',
        }}
      >
        <LinesMobileTable>
          <LinesMobileTableHead>
            <LinesMobileTableTr>
              <LinesMobileTableTh>№</LinesMobileTableTh>
              <LinesMobileTableTh>Атакующая линия</LinesMobileTableTh>
              <LinesMobileTableTh>Оборонительная линия</LinesMobileTableTh>
            </LinesMobileTableTr>
          </LinesMobileTableHead>
          <LinesMobileTableBody>
            {linesForm?.playersLines?.map((line, lineIndex) => {
              return (
                <LinesMobileTableTr key={line.name}>
                  <LinesMobileTableTd>{lineIndex + 1}</LinesMobileTableTd>
                  <LinesMobileTableTd>
                    <LinesMobileTableTdFlexWrapper>
                      {['', '', ''].map((winger, wk) => {
                        if (line.wingersLine[wk]) {
                          return (
                            <TeacherHockeyLinesPlayerCard
                              className={'TeacherHockeyLinesPlayerCard'}
                              key={wk}
                              player={line.wingersLine[wk]}
                              position={wingerPositions[wk]}
                              readOnly={!isCanEdit}
                              onClick={() => {
                                if (isCanEdit) {
                                  setIsPlayersListModalOpen(true);
                                  setSelectedPlayer({
                                    playersType: 'wingersLine',
                                    lineIndex: lineIndex,
                                    playerPosition: wk,
                                  });
                                }
                              }}
                              onDelete={() => {
                                deletePlayerFromPosition('wingersLine', lineIndex, wk);
                              }}
                            />
                          );
                        } else {
                          return (
                            <TeacherHockeyLinesPlayerEmptyCard
                              className={'TeacherHockeyLinesPlayerEmptyCard'}
                              isActive={isActiveCard}
                              key={wk}
                              lineNumber={lineIndex + 1}
                              position={wingerPositions[wk]}
                              readOnly={!isCanEdit}
                              onClick={() => {
                                if (isCanEdit) {
                                  setIsPlayersListModalOpen(true);
                                  setSelectedPlayer({
                                    playersType: 'wingersLine',
                                    lineIndex: lineIndex,
                                    playerPosition: wk,
                                  });
                                }
                              }}
                            />
                          );
                        }
                      })}
                    </LinesMobileTableTdFlexWrapper>
                  </LinesMobileTableTd>
                  <LinesMobileTableTd>
                    <LinesMobileTableTdFlexWrapper>
                      {['', ''].map((_, df) => {
                        if (line.defensemansLine[df]) {
                          return (
                            <TeacherHockeyLinesPlayerCard
                              className={'TeacherHockeyLinesPlayerCard'}
                              key={df}
                              player={line.defensemansLine[df]}
                              position={defensemenPositions[df]}
                              readOnly={!isCanEdit}
                              onClick={() => {
                                if (isCanEdit) {
                                  setIsPlayersListModalOpen(true);
                                  setSelectedPlayer({
                                    playersType: 'defensemansLine',
                                    lineIndex: lineIndex,
                                    playerPosition: df,
                                  });
                                }
                              }}
                              onDelete={() => {
                                deletePlayerFromPosition('defensemansLine', lineIndex, df);
                              }}
                            />
                          );
                        } else {
                          return (
                            <TeacherHockeyLinesPlayerEmptyCard
                              className={'TeacherHockeyLinesPlayerEmptyCard'}
                              isActive={isActiveCard}
                              key={df}
                              lineNumber={lineIndex + 1}
                              position={defensemenPositions[df]}
                              readOnly={!isCanEdit}
                              onClick={() => {
                                if (isCanEdit) {
                                  setIsPlayersListModalOpen(true);
                                  setSelectedPlayer({
                                    playersType: 'defensemansLine',
                                    lineIndex: lineIndex,
                                    playerPosition: df,
                                  });
                                }
                              }}
                            />
                          );
                        }
                      })}
                    </LinesMobileTableTdFlexWrapper>
                  </LinesMobileTableTd>
                </LinesMobileTableTr>
              );
            })}
          </LinesMobileTableBody>
        </LinesMobileTable>
      </Scrollbar>
      <LinesMobileTableGoaltenders>
        <LinesMobileTableGoaltendersHead>
          <LinesMobileTableGoaltendersTr>
            <LinesMobileTableGoaltendersTh>Вратари</LinesMobileTableGoaltendersTh>
          </LinesMobileTableGoaltendersTr>
        </LinesMobileTableGoaltendersHead>
        <LinesMobileTableBody>
          <LinesMobileTableGoaltendersTr>
            <LinesMobileTableGoaltendersTd>
              <LinesMobileTableTdFlexWrapper>
                {['', '', ''].map((goaltender, gk) => {
                  if (
                    linesForm?.goaltendersLine &&
                    linesForm?.goaltendersLine.length &&
                    linesForm?.goaltendersLine[gk]
                  ) {
                    return (
                      <TeacherHockeyLinesPlayerCard
                        className={'TeacherHockeyLinesPlayerCard'}
                        key={gk}
                        player={linesForm?.goaltendersLine[gk]}
                        position={'вр'}
                        readOnly={!isCanEdit}
                        onClick={() => {
                          if (isCanEdit) {
                            setIsPlayersListModalOpen(true);
                            setSelectedPlayer({
                              playersType: 'goaltendersLine',
                              playerPosition: gk,
                            });
                          }
                        }}
                        onDelete={() => {
                          deleteGoaltenderPlayerFromPosition(gk);
                        }}
                      />
                    );
                  } else {
                    return (
                      <TeacherHockeyLinesPlayerEmptyCard
                        className={'TeacherHockeyLinesPlayerEmptyCard'}
                        isActive={isActiveCard}
                        key={gk}
                        position={'вр'}
                        readOnly={!isCanEdit}
                        onClick={() => {
                          if (isCanEdit) {
                            setIsPlayersListModalOpen(true);
                            setSelectedPlayer({
                              playersType: 'goaltendersLine',
                              playerPosition: gk,
                            });
                          }
                        }}
                      />
                    );
                  }
                })}
              </LinesMobileTableTdFlexWrapper>
            </LinesMobileTableGoaltendersTd>
          </LinesMobileTableGoaltendersTr>
        </LinesMobileTableBody>
      </LinesMobileTableGoaltenders>
      {isButtonsShowed && (
        <ButtonsBottomBlock>
          <ButtonDefault
            color="primary"
            variant="contained"
            onClick={() => {
              saveLines && saveLines();
              window.ym(80266396, 'reachGoal', 'save_for_lines');
            }}
          >
            Сохранить
          </ButtonDefault>
          <ButtonDefault
            color="neutral"
            variant="contained"
            onClick={() => {
              setCancelModalState && setCancelModalState(true);
            }}
          >
            Сбросить
          </ButtonDefault>
        </ButtonsBottomBlock>
      )}
    </LinesMobileTableContainer>
  );
}
