import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

const BlockItem = ({title, description}) => {
  return (
    <Stack direction="row" spacing="4px">
      <TypographyDefault color="#00000099" fontWeight={400} variant="body2">
        {title}
      </TypographyDefault>
      <TypographyDefault color="#000000DE" fontWeight={400} variant="body2">
        {description}
      </TypographyDefault>
    </Stack>
  );
};

export default BlockItem;
