import * as types from '../ActionTypes.js';

const initialState = {
  loading: false,
  error: undefined,
  linesRecommendation: {
    comment: '',
  },
};

const LinesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LINES_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case types.LINES_LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case types.LINES_LOADING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.LINES_LOADING_FORM:
    case types.LINES_SAVE_FORM:
      return {
        ...state,
        loading: false,
        error: undefined,
        linesForm: {...action.linesForm},
      };

    case types.LINES_HISTORY_LOADING:
      return {
        ...state,
        loading: false,
        error: undefined,
        linesHistory: [...action.linesHistory],
      };
    case types.LINES_LOADING_FAVORITES_LIST:
      return {
        ...state,
        loading: false,
        error: undefined,
        linesFavoriteList: [...action.linesFavoriteList],
      };
    case types.LINES_CHANGE_FORM:
      return {
        ...state,
        loading: false,
        error: undefined,
        linesForm: {...action.linesForm},
      };
    case types.LINES_LOADING_PLAYER_HISTORY:
      return {
        ...state,
        loading: false,
        error: undefined,
        playerLinesHistory: action.playerLinesHistory,
      };
    default:
      return state;
  }
};

export default LinesReducer;
