import TimeTab from '@common/Statistics/PlayerStatistics/Tabs/TimeTab';
import PropTypes from 'prop-types';
import React from 'react';

import {useLoadPlayersStatisticsData} from '../../hooks';

function Time({tab, filter, matchFilter}) {
  const statisticsTab = useLoadPlayersStatisticsData({tab, filter, matchFilter});

  const {isLoad, isLoading, data, hasError, hasMatchFilter} = statisticsTab;

  return <TimeTab {...{isLoad, isLoading, data, hasError, hasMatchFilter}} />;
}

Time.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default Time;
