import styled from 'styled-components';

export const FiltersWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  background-color: #fff;
  gap: 24px;
  border-radius: 6px;
  flex-wrap: wrap;
  margin-bottom: ${(props) => props.mb && props.theme.spacing(props.mb)};

  ${({theme}) => ({
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  })}
`;

export const FilterWrapper = styled.div`
  min-width: ${(props) => props.minWidth};
`;
