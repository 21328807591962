import TreeItem from '@common/Statistics/PlayOff/components/TreeItem';
import {PlayoffItems} from '@components/apps/Tournaments/components/TournamentView/types';
import useDeepMemo from '@hooks/useDeepMemo';
import useElementSize from '@hooks/useElementSize';
import {Theme} from '@mui/material';
import {playOffStages} from '@src/constants/tournament';
import {StagesEnum} from '@src/types/tournament';
import {Box, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {chunk, values} from 'lodash';
import React, {memo, useMemo} from 'react';
import styled from 'styled-components';

type TreeColumnProps = {
  stage: StagesEnum;
  columnIndex: number;
  games: PlayoffItems;
  isLoading?: boolean;
  columnsCount?: number;
  isMobile?: boolean;
};
function TreeColumn({
  stage,
  columnsCount,
  columnIndex,
  games = [],
  isLoading = false,
  isMobile = false,
}: TreeColumnProps) {
  const count: number = useMemo(() => (stage?.includes('/') ? Number(stage.split('/')[1]) : 1), [stage]);
  const [ref, {height}] = useElementSize();

  const groupedGames = useDeepMemo<PlayoffItems[][], [PlayoffItems]>(() => {
    const result = {};

    games.map((game) => {
      const team1Id = game.team1.id;
      const team2Id = game.team2.id;

      if (result[team1Id + team2Id]) {
        result[team1Id + team2Id] = [...(result[team1Id + team2Id] ?? []), game];
      } else if (result[team2Id + team1Id]) {
        result[team2Id + team1Id] = [...(result[team2Id + team1Id] ?? []), game];
      } else {
        result[team1Id + team2Id] = [...(result[team1Id + team2Id] ?? []), game];
      }
    });

    return chunk(values(result), 2);
  }, [games]);
  return (
    <Box flex={1} maxWidth={isMobile ? 350 : 400} minWidth={{xxs: 250, xs: 300, sm: 290}} width="100%">
      {stage && !isMobile && (
        <Typography color={grey['68']} mb={3} mx="auto" ref={ref} textAlign="center" variant="h4">
          {playOffStages[stage]}
        </Typography>
      )}
      <Stack
        alignItems="stretch"
        direction="column"
        gap={4}
        height={(theme) => `calc(100% - ${theme.spacing(3)} - ${height}px)`}
        justifyContent="space-around"
      >
        {[...Array(count >= 2 ? count / 2 : count)].map((_, index, array) => {
          const treeItems = groupedGames[index];
          return (
            <GroupTeamsWrapper
              alignItems="stretch"
              direction="column"
              gap={1}
              height={count > 1 ? `calc(100% / ${array.length})` : 'auto'}
              hideVLine={isMobile || count === 1}
              justifyContent="space-around"
              key={stage + index}
            >
              <LineContainer
                hideLine={isMobile || (columnsCount - 1 === 0 && columnIndex === 0)}
                hideVLine={isMobile || count === 1}
                index={0}
                leftLine={columnIndex !== 0}
                px={isMobile ? '0' : 4}
                rightLine={columnsCount - 1 !== columnIndex}
              >
                <TreeItem games={treeItems?.[0]} isLoading={isLoading} />
              </LineContainer>
              {count > 1 && (
                <LineContainer
                  hideLine={isMobile || (columnsCount - 1 === 0 && columnIndex === 0)}
                  hideVLine={isMobile}
                  index={1}
                  leftLine={columnIndex !== 0}
                  px={isMobile ? '0' : 4}
                  rightLine={columnsCount - 1 !== columnIndex}
                >
                  <TreeItem games={treeItems?.[1]} isLoading={isLoading} />
                </LineContainer>
              )}
            </GroupTeamsWrapper>
          );
        })}
      </Stack>
    </Box>
  );
}

export default memo(TreeColumn);

const GroupTeamsWrapper = styled(Stack)<{hideVLine?: boolean}>`
  position: relative;
  &::before {
    display: ${({hideVLine}) => (hideVLine ? 'none' : '')};
    content: '';
    position: absolute;
    z-index: 0;
    border-right: 1px solid ${grey['24']};
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 40%;
  }
`;

const LineContainer = styled(Box)<{
  theme: Theme;
  leftLine: boolean;
  rightLine: boolean;
  index: number;
  hideVLine?: boolean;
  hideLine?: boolean;
}>`
  position: relative;
  &::after,
  &::before {
    content: '';
    position: absolute;
    z-index: 0;
  }
  &::after {
    display: ${({hideVLine}) => (hideVLine ? 'none' : '')};
    top: ${({index}) => (index === 0 ? '50%' : '-50%')};
    border-right: 1px solid ${grey['24']};
    height: 100%;
    right: 0;
  }
  &::before {
    display: ${({hideLine}) => (hideLine ? 'none' : 'block')};
    top: 50%;
    border-bottom: 1px solid ${grey['24']};
    width: ${({theme, leftLine, rightLine}) => (leftLine && rightLine ? '100%' : `calc(100% - ${theme.spacing(4)})`)};
    transform: ${({leftLine, rightLine}) => (leftLine && rightLine ? 'translate(-50%)' : 'none')};
    height: 0;
    left: ${({theme, leftLine, rightLine}) => {
      if (leftLine && rightLine) {
        return '50%';
      }
      if (rightLine) {
        return theme.spacing(4);
      }
      if (leftLine) {
        return 0;
      }
    }};
  }
`;
