import {useEstimateMutation, useModal} from '@hooks/skill';
import {Button, Stack} from '@ui/MUI';
import LoadingButton from '@ui/MUI/LoadingButton';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';

const Buttons = () => {
  const {close, isLoading} = useModal('edit');
  const {update, remove} = useEstimateMutation();
  const form = useFormContext();
  const {isDirty, isValid} = form.formState;
  const isDisabled = !isDirty || !isValid;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState();
  return (
    <Stack direction="row" justifyContent="space-between">
      <LoadingButton
        color="primary"
        disabled={isDisabled}
        loading={isLoading}
        variant="contained"
        onClick={form.handleSubmit(update)}
      >
        Сохранить
      </LoadingButton>
      <Stack direction="row" spacing={4}>
        <LoadingButton color="primary" loading={isLoading} onClick={() => setIsDeleteDialogOpen(true)}>
          Удалить
        </LoadingButton>
        <Button variant="contained" onClick={close}>
          Отменить
        </Button>
      </Stack>
      <DialogDefault
        actionText="Удалить"
        open={isDeleteDialogOpen}
        title="Вы уверены что хотите удалить навык?"
        onActionButtonClick={remove}
        onCloseDefault={() => setIsDeleteDialogOpen(false)}
      />
    </Stack>
  );
};

export default Buttons;
