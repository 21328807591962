import {FiltersWrapper, FilterWrapper} from '@components/apps/TrainingControlProcess/TrainingControlProcess';
import {useGroup, useSchool} from '@hooks/filters';
import {Autocomplete, RangePicker} from '@ui/MUI';
import React from 'react';

const Filters = ({filters, updateFilters}) => {
  const school = useSchool({});
  const group = useGroup({connectorValue: filters.school?.value});

  return (
    <FiltersWrapper>
      <FilterWrapper minWidth="190px">
        <Autocomplete
          isLoading={school.isLoading}
          label="Школа"
          multiple={false}
          options={school.options}
          value={filters.school}
          onChange={(_, newValue) => {
            updateFilters({...filters, school: newValue, group: null});
          }}
        />
      </FilterWrapper>
      <FilterWrapper minWidth="204px">
        <Autocomplete
          disabled={!filters.school}
          isLoading={group.isLoading}
          label="Команда"
          multiple={false}
          options={group.options}
          value={filters.group}
          onChange={(_, newValue) => {
            updateFilters({...filters, group: newValue});
          }}
        />
      </FilterWrapper>
      <FilterWrapper minWidth="184px">
        <RangePicker
          disableRestoreFocus
          endLabel="Конец"
          format="DD.MM.YYYY"
          startLabel="Начало"
          value={filters.period}
          variant="standard"
          onChange={(value) => {
            updateFilters({...filters, period: value});
          }}
        />
      </FilterWrapper>
    </FiltersWrapper>
  );
};

export default Filters;
