import PropTypes from 'prop-types';
import React from 'react';

import {TitleText} from '../../ManagerDashboard';
import {CardContent, CardTitle, Wrapper} from './Card';

function Card({title, titleComponent, children, height}) {
  return (
    <Wrapper>
      <CardTitle>{titleComponent || <TitleText variant="h2">{title}</TitleText>}</CardTitle>
      <CardContent height={height}>{children}</CardContent>
    </Wrapper>
  );
}

Card.propTypes = {
  title: PropTypes.string,
};

export default Card;
