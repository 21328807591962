import ReactTableComponent from '@common/ReactTableComponent';
import VideoPlayer from '@common/Statistics/VideoPlayer';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import {shallowEqual} from '@helpers/shallowEqual';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import {selectActionsMapData, selectGameActionDetails, selectTeamActionsSide} from '@selectors/gameStatistics';
import {loadGameActionDetails} from '@slices/statistics';
import {longDash} from '@src/constants';
import {Box, Button, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Drawer from '@ui/MUI/DrawerNew';
import {get, isEmpty} from 'lodash';
import {func} from 'prop-types';
import React, {useEffect} from 'react';

ActionVideoModal.propTypes = {
  stateGetter: func.isRequired,
  stateSetter: func.isRequired,
};

function ActionVideoModal({stateGetter = () => {}, stateSetter = () => {}}) {
  const dispatch = useStoreDispatch();
  const actionsMapData = useStore((state) => {
    const actions = selectActionsMapData(state)?.data;
    const isOurSide = stateGetter(state)?.data?.isOur ?? selectTeamActionsSide(state);
    return [actions?.home || [], actions?.away || []]
      .flat()
      .filter((item) => item?.isOur === isOurSide)
      .sort((a, b) => a?.videoTime.localeCompare(b?.videoTime));
  }, shallowEqual);
  const {isOpen, data} = useStore(stateGetter);
  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [data?.id]});
  const shotDetails = useStore(selectGameActionDetails);
  const playersOnIce = shotDetails.data?.playersOnIce;

  const {count, index} = useDeepMemo(() => {
    const count = actionsMapData?.length || 0;
    const index = actionsMapData?.findIndex((item) => item?.id === data?.id) || 0;
    return {count, index};
  }, [actionsMapData, data?.id]);

  useEffect(() => {
    data?.id && dispatch(loadGameActionDetails(data?.id, {cancelToken: newCancelToken(), isCancel}));
  }, [data?.id]);

  useDeepEffect(() => {
    if (isOpen && isEmpty(data) && !isEmpty(actionsMapData[0])) {
      dispatch(stateSetter({isOpen: true, data: actionsMapData[0]}));
    }
  }, [actionsMapData, data, isOpen]);

  return (
    <Drawer
      buttons={
        <Stack
          direction={{xxs: 'column', xs: 'row'}}
          flexWrap="wrap"
          gap={3}
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" gap={3} width={{xxs: '100%', xs: 'unset'}}>
            <Button
              color="primary"
              disabled={index === 0}
              startIcon={<ChevronLeftOutlinedIcon />}
              sx={{width: {xxs: '50%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => dispatch(stateSetter({isOpen: true, data: actionsMapData[index - 1]}))}
            >
              назад
            </Button>
            <Button
              color="primary"
              disabled={index + 1 === count}
              endIcon={<ChevronLeftOutlinedIcon sx={{transform: 'rotate(180deg)'}} />}
              sx={{width: {xxs: '50%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => dispatch(stateSetter({isOpen: true, data: actionsMapData[index + 1]}))}
            >
              вперед
            </Button>
          </Stack>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => dispatch(stateSetter({isOpen: false, data: {}}))}
          >
            Закрыть
          </Button>
        </Stack>
      }
      open={isOpen}
      title={`${data?.name ? data?.name : ''}${count ? ` ${index + 1}/${count}` : ''}. ${data?.playerName || ''}`}
      onClose={() => {
        dispatch(stateSetter({isOpen: false, data: {}}));
      }}
    >
      <Stack direction="column" gap={1} mb={4} mt={6}>
        {[
          {label: 'Тип: ', value: 'type.name', fallback: 'Не указано'},
          {label: 'Команда: ', value: 'teamName'},
          {label: 'Время: ', value: 'time'},
        ].map(({label, value, fallback}, index) => (
          <Stack direction="row" gap={0} key={index}>
            <Typography variant="body1">{label}</Typography>
            <Typography variant="subtitle1">{get(data, value) || fallback || longDash}</Typography>
          </Stack>
        ))}
      </Stack>
      <VideoPlayer data={data} key={data?.id} />
      {!isEmpty(playersOnIce) && (
        <>
          <Typography mb={2} mt={4} variant="h4">
            Игроки на льду
          </Typography>
          <Box sx={{overflow: 'hidden', border: `1px solid ${grey['12']}`, borderBottom: 'none', borderRadius: '6px'}}>
            <ReactTableComponent
              columns={[
                {
                  id: 'player',
                  header: 'Игрок',
                  accessorFn: (data) => data,
                  cell: ({getValue}) => {
                    const value = getValue();
                    return (
                      <Stack alignItems="center" direction="row">
                        <PlayersHorizontalCard student={value} />
                      </Stack>
                    );
                  },
                  sticky: {left: true},
                },
                {
                  accessorKey: 'shiftDuration',
                  header: 'Длина смены',
                },
                {
                  accessorKey: 'timeOnIce',
                  header: 'Время события',
                },
              ]}
              data={playersOnIce}
            />
          </Box>
        </>
      )}
    </Drawer>
  );
}

export default ActionVideoModal;
