import {Box} from '@ui/MUI';
import styled from 'styled-components';

export const AddStatsFormContainer = styled(Box)`
  position: relative;
  margin-bottom: 0;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;
export const Form = styled.form``;
export const FormField = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  @media (min-width: 576px) and (max-width: 991px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  &:not(:last-child) {
    padding-right: 16px;
    @media screen and (max-width: 575px) {
      padding-right: 0;
      padding-bottom: 8px;
    }
  }
  &:not(:first-child) {
    padding-left: 16px;
    @media screen and (max-width: 575px) {
      padding-left: 0;
    }
  }
  &:nth-child(even) {
    @media screen and (max-width: 991px) {
      padding-right: 0;
    }
  }
  &:nth-child(odd) {
    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }
`;
export const FormButtonContainer = styled(Box)`
  box-shadow: -4px 0 12px rgba(0, 0, 0, 0.15);
`;
