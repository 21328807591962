import {getThrowinsMap, getThrowsinsStatistics} from '@api/StatsAPI';
import {defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {actions, throwinsMapActions, throwinsMetricsActions} from '@slices/statistics';
import {HALF_OPTIONS, IMPORTANCE_OPTIONS, initialStateIndicators, PLRS_OPTIONS} from '@src/constants';

export const throwinsStatePaths = {
  throwinsFilters: {
    name: 'throwinsFilters',
    path: 'throwins.filters',
  },
  throwinsMetrics: {
    name: 'throwinsMetrics',
    path: 'throwins.metrics',
  },
  throwinsMap: {
    name: 'throwinsMap',
    path: 'throwins.map',
  },
};

export const throwinsInitialState = {
  throwins: {
    filters: {
      plrs: [...PLRS_OPTIONS],
      importance: IMPORTANCE_OPTIONS[0],
      half: [...HALF_OPTIONS],
      playerIds: [],
    },
    metrics: {...initialStateIndicators, data: {}},
    map: {
      ...initialStateIndicators,
      data: {
        circleEight: {home: 0, away: 0},
        circleFive: {home: 0, away: 0},
        circleFour: {home: 0, away: 0},
        circleNine: {home: 0, away: 0},
        circleOne: {home: 0, away: 0},
        circleSeven: {home: 0, away: 0},
        circleSix: {home: 0, away: 0},
        circleThree: {home: 0, away: 0},
        circleTwo: {home: 0, away: 0},
        zones: [
          {
            name: '',
            home: 0,
            away: 0,
            percent: 0,
          },
          {
            name: '',
            home: 0,
            away: 0,
            percent: 0,
          },
          {
            name: '',
            home: 0,
            away: 0,
            percent: 0,
          },
        ],
      },
    },
  },
};

export const throwinsMetricsReducer = defaultSliceReducersConstructor(
  throwinsStatePaths.throwinsMetrics.name,
  throwinsStatePaths.throwinsMetrics.path,
  throwinsInitialState,
).functions;

export const throwinsMapReducer = defaultSliceReducersConstructor(
  throwinsStatePaths.throwinsMap.name,
  throwinsStatePaths.throwinsMap.path,
  throwinsInitialState,
).functions;

export const setThrowinsFilters = (data) => (dispatch) =>
  dispatch(actions.setEntityFilters({...data, entity: 'throwins'}));

export const clearThrowinsFilters = () => (dispatch) => dispatch(actions.clearEntityFilters({entity: 'throwins'}));

export const loadThrowinsMetrics = (params = {}, isCancel) =>
  defaultLoader(
    () => getThrowsinsStatistics({...params}),
    throwinsMetricsActions.loading,
    throwinsMetricsActions.load,
    throwinsMetricsActions.error,
    {isCancel, onCancelAction: throwinsMetricsActions.loading},
  );
export const clearThrowinsMetrics = () => (dispatch) => dispatch(throwinsMetricsActions.clear());

export const loadThrowinsMap = (params = {}, isCancel) =>
  defaultLoader(
    () => getThrowinsMap({...params}),
    throwinsMapActions.loading,
    throwinsMapActions.load,
    throwinsMapActions.error,
    {isCancel, onCancelAction: throwinsMapActions.loading},
  );
export const clearThrowinsMap = () => (dispatch) => dispatch(throwinsMapActions.clear());
