import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';
import styled from 'styled-components';

const StyledStack = styled(Stack)`
  user-select: none;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  cursor: pointer;
  color: #990011;
  align-items: center;
  & .MuiSvgIcon-root {
    font-size: 18px;
  }
`;

const ActionText = ({children, startIcon, onClick, endIcon}) => {
  return (
    <StyledStack direction="row" spacing="5px" onClick={onClick}>
      {startIcon}
      <TypographyDefault color="primary" variant="subtitle2">
        {children}
      </TypographyDefault>
      {endIcon}
    </StyledStack>
  );
};

export default ActionText;
