import {Stack, Typography} from '@ui/MUI';
import {grey, primary} from '@ui/MUI/colors';
import React from 'react';

const EstimateNumber = ({children, isSelectable, isPrimary, onClick, isDisabled, isArchived = false, ...props}) => {
  const isCursorDisabled = (isSelectable && isPrimary) || isDisabled;
  const isSimpleChildren = typeof children === 'string' || typeof children === 'number';
  const cursor = isCursorDisabled ? 'not-allowed' : 'pointer';
  const constructBackgroundColor = () => {
    if (isPrimary) return primary.main;
    if (isArchived) return grey['38'];
    if (isDisabled) return '#EBEBEB';
    return 'transparent';
  };
  const constructTypography = () => {
    const sx = {
      color: 'rgba(0, 0, 0, 0.87)',
    };

    if (isPrimary || isArchived) {
      sx.color = '#FFFFFF';
    }

    return sx;
  };

  const sx = {
    width: 32,
    height: 32,
    backgroundColor: constructBackgroundColor(),
    borderRadius: '6px',
    cursor: isSelectable ? cursor : 'default',
    userSelect: 'none',
    border: !isPrimary && !isDisabled && !isArchived && '1px solid #C2C2C2',
  };

  const typographySx = constructTypography();

  const handleClick = () => {
    if (!onClick) return;
    if (isCursorDisabled) return;
    if (!isSimpleChildren) {
      throw new Error('EstimateNumber: children должен быть числом или строкой');
    }
    onClick(children);
  };

  return (
    <Stack alignItems="center" justifyContent="center" sx={!props?.isLoading && sx} onClick={handleClick} {...props}>
      <Typography
        isLoading={props.isLoading}
        preloaderWidthBetween={[20, 30]}
        sx={!props.isLoading && typographySx}
        variant="subtitle2"
      >
        {children}
      </Typography>
    </Stack>
  );
};

export default EstimateNumber;
