import {mock} from '@components/apps/GameStatisticsParsingCheck/components/Goaltenders/mock';
import TableComponent from '@components/apps/GameStatisticsParsingCheck/components/TableComponent';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import useDeepMemo from '@hooks/useDeepMemo';
import {selectGameStatisticsParsingCheckTabs} from '@selectors/gameStatisticsParsingCheck';
import {longDash} from '@src/constants';
import {noDataNumberValue} from '@src/helpers';
import {Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

Goaltenders.propTypes = {
  tab: PropTypes.string.isRequired,
};

function Goaltenders({tab}) {
  const {data, isLoading, isLoad, hasError, hasOT} = useSelector(
    (state) => selectGameStatisticsParsingCheckTabs(state, tab),
    shallowEqual,
  );
  const memoColumns = useMemo(() => {
    const columns = [
      {
        id: 'player',
        accessorKey: 'player',
        header: 'Игрок',
        sticky: {left: true},
        cell: (params) => {
          const student = params?.getValue();
          if (student?.firstName !== 'total') {
            return (
              <PlayersHorizontalCard
                hasImage={false}
                isLoading={isLoading}
                preloaderWidthBetween={[100, 170]}
                student={{...student, playerNumber: student?.number}}
              />
            );
          }
          return 'Итог';
        },
      },
      {
        id: 'missedGoals',
        accessorKey: 'missedGoals',
        header: 'Пропущенные голы',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {noDataNumberValue(getValue())}
          </Typography>
        ),
      },
      {
        id: 'totalTime',
        accessorKey: 'totalTime',
        header: 'Общее игровое время',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
      {
        id: 'firstPeriodTime',
        accessorKey: 'firstPeriodTime',
        header: 'Игровое время в 1 периоде',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
      {
        id: 'secondPeriodTime',
        accessorKey: 'secondPeriodTime',
        header: 'Игровое время в 2 периоде',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
      {
        id: 'thirdPeriodTime',
        accessorKey: 'thirdPeriodTime',
        header: 'Игровое время в 3 периоде',
        cell: ({getValue}) => (
          <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
            {getValue() ?? longDash}
          </Typography>
        ),
      },
    ];
    return hasOT
      ? [
          ...columns,
          {
            id: 'OTTime',
            accessorKey: 'OTTime',
            header: 'Игровое время в овертайме',
            cell: ({getValue}) => (
              <Typography isLoading={isLoading} preloaderWidthBetween={[50, 70]} variant="body2">
                {getValue() ?? longDash}
              </Typography>
            ),
          },
        ]
      : [...columns];
  }, [hasOT, isLoading]);

  const rows = useDeepMemo(
    () =>
      (isLoading ? mock : data)
        ?.map((item, id) => ({
          id,
          player: {
            firstName: item?.playerFirstName,
            lastName: item?.playerLastName,
            number: item?.playerNumber,
          },
          missedGoals: item?.missedGoals,
          totalTime: item?.overallTOI,
          firstPeriodTime: item?.firstHalfTOI,
          secondPeriodTime: item?.secondHalfTOI,
          thirdPeriodTime: item?.thirdHalfTOI,
          OTTime: item?.overTimeTOI,
        }))
        ?.sort((a, b) => (b?.player?.firstName === 'total' ? -1 : 0)),
    [isLoading, data?.length],
  );
  return (
    <TableComponent
      className="goaltenders_table"
      columns={memoColumns}
      data={rows}
      hasError={hasError || !data?.length}
      isLoad={isLoad}
      isLoading={isLoading}
      tab={tab}
    />
  );
}

export default Goaltenders;
