import React from 'react';

import ChessTemplate from '../../templates/ChessTemplate';
import VideoList from './components/VideoList';

export default function VideoListApp({isStudent = false}) {
  return (
    <ChessTemplate active={'video'}>
      <VideoList isStudent={isStudent} />
    </ChessTemplate>
  );
}
