import PlayersTable from '@common/Statistics/PlayerStatistics/VideoModal/PlayersTable';
import VideoPlayer from '@common/Statistics/VideoPlayer';
import {shallowEqual} from '@helpers/shallowEqual';
import {useCancelToken} from '@hooks/useCancelToken';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import {selectGameActionDetails} from '@selectors/gameStatistics';
import {loadGameActionDetails} from '@slices/statistics';
import {longDash} from '@src/constants';
import {ZONE_LABELS} from '@src/constants/stitictics';
import {Button, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import Drawer from '@ui/MUI/DrawerNew';
import {func, number} from 'prop-types';
import React, {useEffect} from 'react';

ThrowinVideoModal.propTypes = {
  stateGetter: func.isRequired,
  stateSetter: func.isRequired,
  itemsCount: number.isRequired,
  setData: func.isRequired,
  onClose: func.isRequired,
};

function ThrowinVideoModal({
  setData = () => {},
  onClose = () => {},
  itemsCount = 0,
  stateGetter = () => {},
  stateSetter = () => {},
}) {
  const dispatch = useStoreDispatch();
  const {isOpen, data} = useStore(stateGetter, shallowEqual);
  const {newCancelToken, isCancel} = useCancelToken({cancelDeps: [data?.id]});
  const shotDetails = useStore(selectGameActionDetails);
  const playersOnIce = shotDetails?.data?.playersOnIce || [];
  const shotDetailsLoading = shotDetails?.isLoading;
  const {infoItems, throwinItems} = useDeepMemo(() => {
    const {isWeWin, videoTime, zone} = data;
    const infoItems = [
      {label: 'Результат:', value: isWeWin ? 'Выигранное' : 'Проигранное'},
      {label: 'Время:', value: videoTime},
    ];
    const throwinItems = [{label: 'Зона:', value: ZONE_LABELS?.[zone]?.zone}];
    return {infoItems, throwinItems};
  }, [data]);

  useEffect(() => {
    data?.id && dispatch(loadGameActionDetails(data?.id, {cancelToken: newCancelToken(), isCancel}));
  }, [data?.id]);

  return (
    <Drawer
      buttons={
        <Stack
          direction={{xxs: 'column', xs: 'row'}}
          flexWrap="wrap"
          gap={3}
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" gap={3} width={{xxs: '100%', xs: 'unset'}}>
            <Button
              color="primary"
              disabled={data?.index === 1}
              startIcon={<ChevronLeftOutlinedIcon />}
              sx={{width: {xxs: '50%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => setData(data?.index - 1)}
            >
              назад
            </Button>
            <Button
              color="primary"
              disabled={data?.index === itemsCount}
              endIcon={<ChevronLeftOutlinedIcon sx={{transform: 'rotate(180deg)'}} />}
              sx={{width: {xxs: '50%', xs: 'unset'}}}
              variant="outlined"
              onClick={() => setData(data?.index + 1)}
            >
              вперед
            </Button>
          </Stack>
          <Button color="secondary" variant="contained" onClick={onClose}>
            Закрыть
          </Button>
        </Stack>
      }
      open={isOpen}
      title={`${itemsCount ? `вбрасывание ${data?.index}/${itemsCount}.` : ''} ${data?.playerName || ''}`}
      onClose={() => {
        dispatch(stateSetter({isOpen: false}));
      }}
    >
      <Stack direction="column" gap={1} mb={4} mt={6}>
        {infoItems.map(({label, value}, index) => (
          <Stack direction="row" gap={0} key={index}>
            <Typography variant="body1">{label}</Typography>
            <Typography variant="subtitle1">{value || longDash}</Typography>
          </Stack>
        ))}
      </Stack>
      <VideoPlayer data={data} key={data?.index} />
      <Typography mb={2} mt={4} variant="h3">
        О вбрасывании
      </Typography>
      <Stack direction="column" gap={2} p={{xxs: 3, sm: 4}} sx={{backgroundColor: grey['4'], borderRadius: '6px'}}>
        {throwinItems.map(({label, value}, index) => (
          <Stack direction="row" flexWrap="wrap" gap={0} key={index}>
            <Typography variant="body1">{label}</Typography>
            <Typography variant="subtitle1">{value || longDash}</Typography>
          </Stack>
        ))}
      </Stack>
      <Typography mb={2} mt={4} variant="h4">
        Игроки на льду
      </Typography>
      <PlayersTable isLoading={shotDetailsLoading} playersOnIce={playersOnIce} />
    </Drawer>
  );
}

export default ThrowinVideoModal;
