import APIHelper from '@api/APIHelper';
import date from '@helpers/date';
import {NOTIFICATION_TYPES, seasonRange} from '@src/constants/config';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import moment from 'moment';

export function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
}

const SIGNUM_MAP = {
  asc: 1,
  desc: -1,
};

const CommonHelper = {
  getCurrentUserRole(state) {
    if (!state) {
      return false;
    }
    let {currentUserId, usersMap} = state.users;
    let currentUser = usersMap.get(currentUserId);
    if (!currentUser) {
      return undefined;
    }
    return currentUser.userRole;
  },

  getDropDownItemsByUsers(users, shortNameMode = false) {
    return users
      ?.map((user) => {
        if (user) {
          return {
            value: user?.objectId || user?.id,
            label:
              shortNameMode === true ? CommonHelper.getShortNameByUser(user) : `${user?.lastName} ${user?.firstName}`,
            sortString: `${user?.lastName} ${user?.firstName}`,
          };
        }
      })
      .sort((a, b) => {
        if (a.sortString.toLowerCase() > b.sortString.toLowerCase()) {
          return 1;
        }
        if (a.sortString.toLowerCase() < b.sortString.toLowerCase()) {
          return -1;
        }
        return 0;
      });
  },
  getDropDownItemsByNamedList(arr) {
    return arr
      ?.map((a) => {
        return {
          value: a.id,
          label: `${a.name}`,
        };
      })
      .sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        return 0;
      });
  },
  getDropDownTeamsByNamedList(arr) {
    return arr
      ?.map((a) => {
        return {
          ...a,
          value: a?.id || a?.value,
          label: `${a?.name || a?.label}`,
        };
      })
      ?.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        return 0;
      })
      ?.sort(function (a, b) {
        return ('' + a.label).localeCompare(b.label);
      })
      ?.reverse();
  },
  getDropDownItemsByNamedListCustomProps(arr, label, value) {
    return arr
      ?.map((a) => {
        return {
          value: a?.[value],
          label: a?.[label]?.toString(),
        };
      })
      ?.sort((a, b) => {
        if (a.label?.toLowerCase() > b.label?.toLowerCase()) {
          return 1;
        }
        if (a.label?.toLowerCase() < b.label?.toLowerCase()) {
          return -1;
        }
        return 0;
      });
  },

  sortArrayBySortingSettings(arr, sortingSettings) {
    let res = arr.sort((a, b) => {
      let s1 = a[sortingSettings.type];
      let s2 = b[sortingSettings.type];
      if (s1 > s2) {
        return SIGNUM_MAP[sortingSettings.mode];
      }
      if (s1 < s2) {
        return -SIGNUM_MAP[sortingSettings.mode];
      }
      return 0;
    });
    return res;
  },

  getShortNameByUser(userObject) {
    if (typeof userObject !== 'object') {
      return void 0;
    }
    const {firstName, lastName} = userObject;
    const firstCharUppercase = (str) =>
      typeof str === 'string' ? str.trim().charAt(0).toUpperCase() + str.slice(1) : '';

    let fn = firstCharUppercase(firstName);
    let ln = firstCharUppercase(lastName);

    if (fn.split('').length > 1 && ln) {
      fn = fn.split('')[0] + '.';
    }
    const result = [];
    if (ln) result.push(ln);
    if (fn) result.push(fn);

    return result.join(' ');
  },

  getSortedArray(arr, fieldName = 'name') {
    return arr?.sort((a, b) => {
      let s1 = a?.[fieldName] || '';
      let s2 = b?.[fieldName] || '';
      if (s1 > s2) {
        return 1;
      }
      if (s1 < s2) {
        return -1;
      }
      return 0;
    });
  },

  isMobile() {
    let w = window.innerWidth;
    const breakWidth = 920;
    return w <= breakWidth;
  },

  lastPartOfUrl() {
    let partsOfUrl = window.location.href.split('/');

    return partsOfUrl.pop();
  },

  getSeasonRange(season, isArray) {
    if (!season || typeof season !== 'string' || !season.includes('-') || season?.split('-').length !== 2) {
      return void 0;
    }
    const seasonYears = season.split('-');

    if (isArray) {
      return seasonYears;
    }

    const startYearOfSeason = seasonYears[0];
    const endYearOfSeason = seasonYears[1];

    return {
      start: moment(`${seasonRange.start}.20${startYearOfSeason}`, 'DD.MM.YYYY'),
      end: moment(`${seasonRange.end}.20${endYearOfSeason}`, 'DD.MM.YYYY'),
    };
  },
  getSortedUsers(arr = []) {
    return arr?.sort(function (a, b) {
      if (a === null || b === null) return;
      const aFullName = `${a.lastName} ${a.firstName}`;
      const bFullName = `${b.lastName} ${b.firstName}`;
      if (aFullName < bFullName) {
        return -1;
      }
      if (aFullName > bFullName) {
        return 1;
      }
      return 0;
    });
  },
  getDropDownItemsByUsersWithCustomProps(users, ...customProps) {
    return users
      ?.map((user) => {
        const customPropsFromUser = customProps.reduce((acc, currPropsName) => {
          return {
            ...acc,
            [currPropsName]: user[currPropsName],
          };
        }, {});
        return {
          id: user.objectId || user.id,
          value: user.objectId || user.id,
          label: user.name || `${user.lastName} ${user.firstName}`,
          sortString: user.name || `${user.lastName} ${user.firstName}`,
          ...customPropsFromUser,
        };
      })
      ?.sort((a, b) => {
        if (a.sortString.toLowerCase() > b.sortString.toLowerCase()) {
          return 1;
        }
        if (a.sortString.toLowerCase() < b.sortString.toLowerCase()) {
          return -1;
        }
        return 0;
      });
  },
  generateGameTitle(lesson) {
    if (!lesson || !lesson?.type) {
      return '';
    }
    const gameDate = date(lesson?.startTimestamp).format('DD.MM.YYYY');
    if (lesson.type === 'game') {
      return `${lesson.group.name} - ${lesson.opponent} ${gameDate}`;
    }
    if (lesson.type === 'away_game') {
      return `${lesson.opponent} - ${lesson.group.name} ${gameDate}`;
    }
  },
};

export const unDuplicateArraySingleValues = (array) => {
  // Проверка, что это не пустой массив
  if ((Array.isArray(array) || array instanceof Array) && array.length) {
    // Возвращает массив уникальных значений
    return array.filter((currValue, ind) => {
      return array.indexOf(currValue) === ind;
    });
  } else {
    // Это не заполненный массив,
    // возвращаем переданное без изменений
    return array;
  }
};

export const unDuplicateArrayObjects = (array, propertyName) => {
  if (
    (Array.isArray(array) || array instanceof Array) &&
    array.length &&
    typeof propertyName === 'string' &&
    propertyName.length
  ) {
    // Массив значений из ключа propertyName, который надо проверить
    const objectValuesArrayFromKey = array.map((item) => item[propertyName]);
    // Удалить дубли этих значений с помощью предыдущей функции
    const uniqueValues = unDuplicateArraySingleValues(objectValuesArrayFromKey);
    // Вернуть массив только с уникальными объектами
    return uniqueValues.map((key) => array.find((item) => item[propertyName] === key));
  } else {
    return array;
  }
};

export const errorMessageNotification = (err, title = 'Ошибка при загрузке', type) => {
  if (err) {
    ReactComponentNotification(
      type || NOTIFICATION_TYPES['error'],
      title ? `${title}: ${err?.response?.data.message || err}` : `${err?.response?.data.message || err}`,
    );
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

export const successMessageNotification = (title) => ReactComponentNotification(NOTIFICATION_TYPES.success, title);

//function for words declination
export const declineWords = ({value, words}) => {
  //example: words = ['игрок', 'игрока', 'игроков'];
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
};

export const getTeamName = async (teamId) => {
  if (teamId) {
    const res = await APIHelper.getGroupsByIds(new Array(teamId));
    return res[0]?.data?.name || '';
  }
};

export default CommonHelper;
