import Field from '@common/FormField';
import {getOptionLabel, haveValue, isRoleOptionEqualToValue} from '@components/apps/MaterialsModule/helpers';
import useToggle from '@hooks/useToggle';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {roleOptions} from '@src/constants';
import Box from '@ui/MUI/Box';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import React from 'react';
import {useWatch} from 'react-hook-form';

import StudentSelect from '../StudentSelect';
import TeacherSelect from '../TeacherSelect';

const iconStyles = {fontSize: 20, color: '#B63830'};
const formatedRoles = roleOptions.map((role) => ({
  userRole: role.value,
  name: role.label,
}));

const AdditionalContent = () => {
  const type = useWatch({name: 'type'});
  const memberRoles = useWatch({name: 'memberRoles'});
  const isHaveStudent = haveValue('student', memberRoles, 'userRole');
  const isHaveTeacher = haveValue('teacher', memberRoles, 'userRole');

  const toggle = useToggle();
  return type ? (
    <Box>
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        sx={{cursor: 'pointer'}}
        onClick={toggle.isToggle ? toggle.off : toggle.on}
      >
        <Typography color="primary" variant="subtitle2">
          Дополнительные параметры
        </Typography>
        {toggle.isToggle ? <ExpandLess sx={iconStyles} /> : <ExpandMore sx={iconStyles} />}
      </Stack>
      {toggle.isToggle && (
        <Stack mt={2} spacing={3}>
          <Box width="100%">
            <Field
              multiple
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isRoleOptionEqualToValue}
              label="Для кого"
              name="memberRoles"
              options={formatedRoles}
            />
          </Box>
          {isHaveStudent && (
            <Box width="100%">
              <StudentSelect />
            </Box>
          )}
          {isHaveTeacher && (
            <Box width="100%">
              <TeacherSelect />
            </Box>
          )}
        </Stack>
      )}
    </Box>
  ) : null;
};

export default AdditionalContent;
