import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import styled from 'styled-components';

export const Wrapper = styled(Stack)`
  position: relative;
  background-color: #fff;
  border-radius: 6px;
  padding: ${({theme}) => theme.spacing(4, 5)};
  height: ${({height}) => height};
  ${({theme}) => theme.breakpoints.down('xs')} {
    padding: ${({theme}) => theme.spacing(2, 1)};
  }
`;

export const Count = styled(TypographyDefault)`
  font-weight: 600;
  font-size: 42px;
  line-height: 58px;
  flex-shrink: 0;
  ${({theme}) => theme.breakpoints.down('lg')} {
    font-size: 32px;
    line-height: 48px;
  }
  ${({theme}) => theme.breakpoints.down('xs')} {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const MetricDescription = styled(TypographyDefault)`
  max-width: 90%;
  ${({theme}) => theme.typography.h4}
  font-weight: 600 !important;
  ${({theme}) => theme.breakpoints.down('xs')} {
    max-width: auto;
    ${({theme}) => theme.typography.caption1}
  }
`;
