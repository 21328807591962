import isEqual from 'lodash/isEqual';
import {useRef} from 'react';

const useDeepCallback = (fn, deps) => {
  const ref = useRef();

  if (!ref.current || !isEqual(ref.current.deps)) {
    ref.current = {deps, value: fn};
  }

  return ref.current.value;
};

export default useDeepCallback;
