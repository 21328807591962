import {DrawerInner} from '@ui/MUI/Modals/Drawer/styles';
import styled from 'styled-components';

import {Button} from '../../../ui/Button';

export const Form = styled.form`
  .EventModal {
    max-width: 60%;
    @media screen and (max-width: 768px) {
      width: 100% !important;
      max-width: none;
    }
    .Heading {
      margin-top: -38px;
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        margin-top: -20px;
      }
    }
    .BottomPanel {
      left: -30px;
      margin: 0;
      width: calc(100% + 30px + 30px);
      box-shadow: 0 0 0 transparent;
      border-radius: 0 0 0 6px;
      .Buttons {
        display: flex;
        justify-content: space-between;
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.2);
        padding-right: 60px;
        @media screen and (max-width: 768px) {
          flex-direction: row;
          padding-right: 20px;
        }
        @media screen and (max-width: 480px) {
          position: fixed;
          bottom: 0;
          flex-direction: column;
        }
        > button {
          margin: 0 10px;
          height: 40px;
          width: 100%;
          max-width: 160px;
          font: normal normal 16px/40px 'Proxima Nova';
          @media screen and (max-width: 480px) {
            margin: 0;
          }
          &:not(:last-child) {
            @media screen and (max-width: 480px) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
    .sidebar {
      &__inner {
        padding: 22px 30px 0;
        border-radius: 6px 0 0 6px;
      }
      &__header {
        @media screen and (max-width: 767px) {
          position: relative;
          border: none;
          top: auto;
          left: auto;
        }
      }
      &__closer {
        right: 0;
        .icon-button {
          justify-content: flex-end;
        }
      }
      &__content {
        @media screen and (max-width: 767px) {
          padding-top: 0;
          padding-right: 0;
          padding-left: 0;
        }
        @media screen and (max-width: 480px) {
          padding-bottom: 108px;
        }
      }
    }
  }
  ${DrawerInner} {
    height: 100%;
  }
`;
export const FormContent = styled.div`
  padding: 16px 0px 10px 0px;
`;
export const FormContentItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -12px;
  margin-right: -12px;
  box-sizing: border-box;

  .CustomFilter {
    padding: 0;
    margin: 0;
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    border-radius: 0;
    flex: 0 0 100%;
    max-width: 100%;

    &:not(:last-child) {
      padding-right: 14px;
    }

    label {
      display: block;
      font: normal normal 14px/16px 'Proxima Nova';
      color: #747474;
      text-transform: none;
    }
  }

  .filters-container {
    .filter-container {
      margin-top: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
    & > .CustomFilter {
      flex: 0 0 50%;
      max-width: 50%;

      @media screen and (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
`;
export const FormContentItem = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  flex: 0 0 100%;
  max-width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .tox-tinymce {
    border-color: #f3f3f3 !important;
  }
`;
export const FormContentItemFull = styled(FormContentItem)`
  flex: 0 0 100%;
  max-width: 100%;
`;
export const FormContentItemShort = styled(FormContentItem)`
  flex: 0 0 30%;
  max-width: 30%;
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
export const FormContentItemLong = styled(FormContentItem)`
  flex: 0 0 60%;
  max-width: 60%;
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
export const FormContentItemHalf = styled(FormContentItem)`
  flex: 0 0 50%;
  max-width: 50%;
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
export const InfoText = styled.p`
  font: normal 600 14px/16px 'Proxima Nova';
  color: #2c2c2c;
`;
export const InfoBlock = styled.div`
  margin-top: 25px;
  margin-right: 50px;
  min-width: 150px;
  :last-child {
    margin-right: 0;
  }
`;
export const InfoWrapper = styled.div``;
export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font: normal normal 14px/16px 'Proxima Nova';
  color: #747474;
  > input {
    margin-top: 4px;
    margin-bottom: 0;
    border-color: #f3f3f3;
    font: normal normal 15px/25px 'Proxima Nova';
    color: #2c2c2c;
    &::placeholder {
      font: normal normal 15px/25px 'Proxima Nova';
      color: #c4c4c4;
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const BottomPanel = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0 -20px;
  background: white;
  box-shadow: 4px 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;

  @media screen and (max-width: 768px) {
    left: 0;
    width: calc(100% + 20px + 20px);
  }
`;
export const Buttons = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px;
  width: calc(100% + 20px + 20px);
  background: white;
  box-shadow: 4px 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  > ${Button} {
    margin-right: 30px;
    margin-left: 30px;
    height: 30px;
    line-height: 30px;
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 220px;
      margin-right: 0;
      margin-left: 0;
    }
    :last-of-type {
      margin-right: 0;
      @media screen and (max-width: 768px) {
        margin-top: 6px;
        margin-bottom: 0;
      }
    }
    :first-of-type {
      margin-left: 0;
      @media screen and (max-width: 768px) {
        margin-top: 0;
        margin-bottom: 6px;
      }
    }
  }
`;
