import Stack from '@ui/MUI/Stack';
import React from 'react';

import BlockWrapper from './components/BlockWrapper';
import FormContentBottom from './components/FormContentBottom';
import FormContentImage from './components/FormContentImage';
import FormContentMain from './components/FormContentMain';
import FormContentSkills from './components/FormContentSkills';
import FormContentVideo from './components/FormContentVideo';

const FormContent = () => {
  return (
    <Stack spacing={6}>
      <BlockWrapper>
        <FormContentMain />
      </BlockWrapper>
      <BlockWrapper title="Схема выполнения">
        <FormContentImage />
      </BlockWrapper>
      <BlockWrapper title="Видео-пример выполнения">
        <FormContentVideo />
      </BlockWrapper>
      <BlockWrapper title="Навыки">
        <FormContentSkills />
      </BlockWrapper>
      <BlockWrapper>
        <FormContentBottom />
      </BlockWrapper>
    </Stack>
  );
};

export default FormContent;
