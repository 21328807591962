import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

export function getSeasons() {
  return axios.get(`${API_ENDPOINT}/v1/seasons`, {
    failed: () => ({message: 'Ошибка при загрузке сезонов'}),
  });
}
export function getCurrentSeason() {
  return axios.get(`${API_ENDPOINT}/v1/seasons/current`);
}
