import {Box} from '@ui/MUI';
import React from 'react';

function Wrapper({children, ...props}) {
  return (
    <Box bgcolor="#fff" borderRadius="6px" overflow="hidden" p={4} {...props}>
      {children}
    </Box>
  );
}

export default Wrapper;
