import {AxiosError} from 'axios';

export enum UserRoles {
  STUDENT = 'student',
  FRANCHISEE = 'franchisee',
  SCHOOLS_ADMIN = 'schools_admin',
  ADMIN = 'admin',
  TEACHER = 'teacher',
  DOCTOR = 'doctor',
  PSYCHO = 'psycho',
  PARENT = 'parent',
  SCOUT = 'scout',
  VIDEO_ANALYST = 'video_analyst',
  METHODIST = 'methodist',
}

export enum TeacherSubroles {
  DEFAULT = 'default',
  MAIN = 'main',
  GOALTENDER = 'goaltender',
  DEFENSEMENS_TRAINER = 'defensemens_trainer',
  WINGERS_TRAINER = 'wingers_trainer',
  PHYSICAL = 'physical',
}

export enum StudentSubroles {
  AMATEUR = 'amateur',
}

export interface InitialStateIndicators<TData> {
  isLoading: boolean;
  isLoad: boolean;
  error: null | AxiosError;
  data: TData;
}

export type Params = {
  sort?: string;
  limit?: number;
  offset?: number;
};
