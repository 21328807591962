import {Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React from 'react';

SimpleLoadingCell.propTypes = {
  getValue: PropTypes.func.isRequired,
};

function SimpleLoadingCell({getValue = () => {}, isLoading, ...props}) {
  return (
    <Typography isLoading={isLoading} preloaderWidthBetween={[15, 20]} variant="body2" {...props}>
      {getValue()}
    </Typography>
  );
}

export default SimpleLoadingCell;
