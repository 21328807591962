import ReactTableComponent from '@common/ReactTableComponent';
import {Box, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React from 'react';

const ReportTable = ({title, columns, data, comment, isNoData, isLoading}) => {
  return (
    <>
      <Typography
        isLoading={isLoading}
        preloaderWidthBetween={[100, 120]}
        sx={{px: 4, backgroundColor: grey[12], lineHeight: 4}}
        variant="subtitle2"
      >
        {title}
      </Typography>
      {!isNoData ? (
        <>
          <ReactTableComponent columns={columns} data={data} isAvailableHeight={false} />
          <Typography
            isLoading={isLoading}
            preloaderWidthBetween={[100, 120]}
            sx={{px: 4, py: 3, backgroundColor: '#fff', minHeight: 52}}
            variant="subtitle2"
          >
            Комментарий:{' '}
            <Typography component="span" isLoading={isLoading} preloaderWidthBetween={[100, 120]} variant="body2">
              {comment || '–'}
            </Typography>
          </Typography>
        </>
      ) : (
        <Box bgcolor="#fff" sx={{px: 4, py: 2}}>
          <Typography isLoading={isLoading} preloaderWidthBetween={[100, 120]} variant="subtitle2">
            Не заполнено
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ReportTable;
