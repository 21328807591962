import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import * as actions from '../../../redux/actions/SchoolsActions';
import TableDefaultContainer from '../../ui/TableDefaultContainer';

export default function FranchiseeSchoolsPanel(props) {
  const {id, schoolsIds = [], canAddSchool = false} = props;
  const dispatch = useDispatch();
  const schools = useSelector((state) => {
    let schools = state.schools.schoolsMap.toArray().filter((u) => u.franchiseeId === id);
    if (!canAddSchool && schoolsIds.length) {
      schools = schools.filter((a) => schoolsIds.indexOf(a.id) > -1);
    }
    return schools;
  });

  useEffect(() => {
    dispatch(actions.loadAllSchools());
  }, [id]);

  return (
    <>
      <TableDefaultContainer
        content={
          <TableContent>
            <ContentPlaceholder>
              <SchoolsList>
                {schools.map((sc) => {
                  return (
                    <SchoolItem key={sc.id}>
                      <NamePlaceholder>{sc.name}</NamePlaceholder>
                      <DescriptionPlaceholder>{sc.description}</DescriptionPlaceholder>
                    </SchoolItem>
                  );
                })}
              </SchoolsList>
            </ContentPlaceholder>
          </TableContent>
        }
        title={'Список школ'}
      />
    </>
  );
}

const TableContent = styled.div``;

const ContentPlaceholder = styled.div``;

const SchoolsList = styled.div``;

const SchoolItem = styled.div`
  padding: 10px 34px;
  background: white;

  :nth-child(even) {
    background-color: #f3f3f3;
  }
`;

const NamePlaceholder = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const DescriptionPlaceholder = styled.div``;
