import useStore from '@hooks/useStore';
import {useMediaQuery} from '@mui/material';
import {userRoles} from '@src/constants';
import {Divider, Stack, Typography} from '@ui/MUI';
import {primary} from '@ui/MUI/colors';
import {chunk} from 'lodash';
import React from 'react';

import {Dates} from '../../TrainingPlan';
import Date from '../Date';
import WeekDays from '../WeekDays';

const Calendar = ({events}) => {
  const userRole = useStore((state) => state?.users?.currentUser?.userRole);
  const isStudent = userRole === userRoles.student;
  const isLgResolution = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const isMdResolution = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const eventsByWeeks = chunk(events, 7);

  return (
    <>
      <Typography color="#FFFFFF" mb={3} mt={4} variant="h3">
        график тренировок, месяц
      </Typography>
      <Stack spacing={2}>
        {!isLgResolution && <WeekDays />}
        {!isMdResolution ? (
          <Dates>
            {events.map(({date: currentDate, events, isGame, isNoEvents, isNotInCurrentMount}, index) => {
              return (
                <Date
                  currentDate={currentDate}
                  events={events}
                  index={index}
                  isGame={isGame}
                  isNoEvents={isNoEvents}
                  isNotInCurrentMount={isNotInCurrentMount}
                  isStudent={isStudent}
                  key={index}
                />
              );
            })}
          </Dates>
        ) : (
          <>
            {eventsByWeeks?.map((week, index) => {
              return (
                <>
                  <Dates key={index}>
                    {week?.map(({date: currentDate, events, isGame, isNoEvents, isNotInCurrentMount}, index) => {
                      return (
                        <Date
                          currentDate={currentDate}
                          events={events}
                          index={index}
                          isGame={isGame}
                          isNoEvents={isNoEvents}
                          isNotInCurrentMount={isNotInCurrentMount}
                          isStudent={isStudent}
                          key={index}
                        />
                      );
                    })}
                  </Dates>
                  {index !== eventsByWeeks.length - 1 && <Divider color={primary.contrastText} />}
                </>
              );
            })}
          </>
        )}
      </Stack>
    </>
  );
};

export default Calendar;
