export const metricsMock = {
  games: '1',
  wins: '1',
  shotsOnGoals: {
    count: '0',
    placeInTournament: '1',
    averageValue: '81.0',
  },
  reliabilityCoefficient: {
    count: '1',
    placeInTournament: '1',
    averageValue: '3.8',
  },
  saves: {
    percent: '0.0',
    placeInTournament: '1',
    averageValue: '87.8',
  },
  savesDangerous: '0.0',
  savesNotDangerous: '0.0',
};
