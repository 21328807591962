import AddButton from '@common/AddButton';
import Form from '@common/Form';
import ExerciseFilters from '@components/apps/TrainingControlProcess/filters/ExerciseFilters';
import {useExercise} from '@components/apps/TrainingControlProcess/hooks/api';
import {PageContentWrapper} from '@components/apps/TrainingControlProcess/TrainingControlProcess';
import {useRoleAccess} from '@hooks/useRoleAccess';
import {userRoles} from '@src/constants';
import Box from '@ui/MUI/Box';
import Stack from '@ui/MUI/Stack';
import React from 'react';
import {FormProvider} from 'react-hook-form';

import DeleteModal from '../../../DeleteModal';
import LoadingHolder from '../../../LoadingHolder';
import ExercisesList from './components/ExercisesList';
import FormContent from './components/FormContent';
import {useDeleteModal, useExercisesForm} from './hooks';

const ExercisesLibaryTab = () => {
  const {methods, isEdit, updateForm, handleSubmit, ...form} = useExercisesForm();
  const {toggle, handleDelete} = useDeleteModal();
  const exercise = useExercise();

  const canEdit = useRoleAccess([userRoles?.teacher, userRoles?.methodist]);

  return (
    <Box bgcolor="#fff" borderRadius={2}>
      <FormProvider {...methods}>
        <DeleteModal elementName="упражнение" modal={toggle} title="удаление упражнения" onDelete={handleDelete} />
        <Form
          content={<FormContent />}
          form={form}
          name="exercise"
          title={isEdit ? 'Редактирование упражнения' : 'Создание упражнения'}
          onSubmit={handleSubmit}
        />
        <PageContentWrapper>
          <Stack spacing={5}>
            {canEdit && <AddButton onClick={form.open}>добавить упражнение</AddButton>}
            <ExerciseFilters />
            {exercise.isLoading ? (
              <LoadingHolder />
            ) : (
              <ExercisesList exercises={exercise.data} form={form} onDelete={toggle.on} onEdit={updateForm} />
            )}
          </Stack>
        </PageContentWrapper>
      </FormProvider>
    </Box>
  );
};

export default ExercisesLibaryTab;
