import logo from '@assets/img/logo.svg';
import {CenterPlaceholder, ImagePlaceholder, Img} from '@components/preloader/styles';
import {Box, CircularProgress} from '@ui/MUI';
import React from 'react';
import styled from 'styled-components';

export default function FullPagePreloader(props) {
  let visible = !props.visible ? false : props.visible;
  if (!visible) {
    return null;
  }

  return (
    <Wrapper>
      <Inner>
        <CenterPlaceholder>
          <Box height="inherit" sx={{zIndex: 1}}>
            <CircularProgress size="100%" thickness={1} />
          </Box>

          <ImagePlaceholder>
            <Img src={logo} />
          </ImagePlaceholder>
        </CenterPlaceholder>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-sizing: border-box;
  overflow: hidden !important;
`;

const Inner = styled.div`
  box-sizing: border-box;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
