import React from 'react';

import TrainingPlanLessonItem from './TrainingPlanLessonItem';

export default function TrainingPlanLessonList(props) {
  let {lessonsList = [], onChange = () => {}, emptyLesson = {}, formatDateTime, parseDateTime} = props;

  const addNewLesson = () => {
    lessonsList.push({...emptyLesson});
    onChange(lessonsList);
  };
  const deleteLesson = (index) => {
    const lessons = [...lessonsList.slice(0, index), ...lessonsList.slice(index + 1)];
    onChange(lessons);
  };
  const handleLessonChange = (index, name, value) => {
    lessonsList[index][name] = value;
    onChange(lessonsList);
  };
  return lessonsList.map((lesson, index) => (
    <TrainingPlanLessonItem
      addNewLesson={addNewLesson}
      deleteLesson={() => deleteLesson(index)}
      formatDateTime={formatDateTime}
      index={index}
      key={index}
      lesson={lesson}
      parseDateTime={parseDateTime}
      onChange={handleLessonChange}
    />
  ));
}
