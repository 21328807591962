import {useMediaQuery} from '@mui/material';
import Grid from '@ui/MUI/Grid';
import Stack from '@ui/MUI/Stack';
import React from 'react';
import ReactPlayer from 'react-player';

import Block from '../Block';
import {Image} from './BottomBlock';

const BottomBlock = ({schemesFile = [], videoFile}) => {
  const imagesCount = schemesFile.length;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return (
    <>
      <Stack direction="row" flexWrap="wrap" spacing={7} sx={{marginBottom: {md: 4}}}>
        <Block flex={!isMobile ? '1' : '0 0 100%'} spacing={3} title="Схема выполнения">
          <div>
            {imagesCount !== 0 ? (
              <Grid container spacing={4}>
                {schemesFile.map((scheme, index) => (
                  <Grid item key={index} xs={imagesCount > 1 ? 6 : 12}>
                    <Image imagesCount={imagesCount} src={scheme.link} width="100%" />
                  </Grid>
                ))}
              </Grid>
            ) : (
              '-'
            )}
          </div>
        </Block>
        <Block
          flex="1"
          spacing={3}
          sx={{marginTop: isMobile && '12px !important', marginLeft: isMobile && '0px !important'}}
          title="Пример выполнения"
        >
          <div>
            {videoFile ? (
              <ReactPlayer controls height="100%" style={{maxWidth: '400px'}} url={videoFile.link} width="100%" />
            ) : (
              '-'
            )}
          </div>
        </Block>
      </Stack>
    </>
  );
};

export default BottomBlock;
