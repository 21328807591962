import useDeepMemo from '@hooks/useDeepMemo';
import useQueryString from '@hooks/useQueryString';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {useMediaQuery} from '@mui/material';
import {clearPlayersTables} from '@slices/statisticsMKC';
import {HOCKEY_ROLES_ENUM} from '@src/constants';
import {Box, Stack} from '@ui/MUI';
import {darkRed} from '@ui/MUI/colors';
import TabsNew from '@ui/MUI/TabsNew';
import Scrollbar from '@ui/Scrollbar';
import React, {memo, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import PlayersTableWrapper from '../PlayersTableWrapper';
import Wrapper from '../Wrapper';
import ChessTable from './components/ChessTable';
import Games from './components/Games';
import TournamentTable from './components/TournamentTable';

function TournamentTab() {
  const [qs] = useQueryString();
  const tabs = useDeepMemo(
    () => [
      {label: 'Турнирная', value: <TournamentTable isFinal={qs?.isFinal} season={qs?.season} />},
      {label: 'Шахматная', value: <ChessTable isFinal={qs?.isFinal} season={qs?.season} />},
      // {label: 'Плей-офф', value: <PlayOff />},
    ],
    [qs],
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const dispatch = useStoreDispatch();
  const {teamMKCId} = useParams();
  useEffect(() => {
    return () => {
      dispatch(clearPlayersTables());
    };
  }, []);

  return (
    <Stack mb={3} spacing={{xxs: 1, sm: 2}}>
      <Wrapper p="0" spacing={1}>
        <TabsNew tabs={tabs} />
      </Wrapper>
      <Scrollbar
        disableTrackXWidthCompensation
        mobileNative
        translateContentSizeYToHolder
        withoutWrapper
        contentProps={!isMobile && {style: {display: 'block', float: 'left'}}}
      >
        <Stack alignItems="stretch" direction="row" pb={{xxs: '0', md: 2}} spacing={{xxs: 1, sm: 2}} width="100%">
          {[HOCKEY_ROLES_ENUM.winger, HOCKEY_ROLES_ENUM.defenseman, HOCKEY_ROLES_ENUM.goaltender].map((item, index) => (
            <Box key={index} minWidth={270} width="calc(100% / 3)">
              <PlayersTableWrapper
                accentColors={[darkRed['20'], darkRed['65']]}
                hockeyRole={item}
                isFinal={qs?.isFinal}
                season={qs?.season}
                teamMKCId={teamMKCId}
              />
            </Box>
          ))}
        </Stack>
      </Scrollbar>
      <Games isFinal={qs?.isFinal} season={qs?.season} teamMKCId={teamMKCId} />
    </Stack>
  );
}

export default memo(TournamentTab);
