import React from 'react';

function EditWithBorder() {
  return (
    <React.Fragment>
      <g clipPath="url(#clip0_9148_8539)">
        <path d="M13.3636 12.7273H0.636353V14H13.3636V12.7273Z" fill="#2C2C2C" />
        <path
          d="M9.76178 0L1.90906 7.85272V11.4546H5.51089L13.3636 3.60183L9.76178 0ZM4.9827 10.1818H3.1818V8.38091L9.76178 1.8009L11.5627 3.6018L4.9827 10.1818Z"
          fill="#2C2C2C"
        />
      </g>
      <defs>
        <clipPath id="clip0_9148_8539">
          <rect fill="white" height="14" width="14" />
        </clipPath>
      </defs>
    </React.Fragment>
  );
}
export default EditWithBorder;
