import {MailRounded, Telegram} from '@mui/icons-material';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import ImageWrapper from './components/ImageWrapper';
import Stage from './components/Stage';
import useLoginStages from './hooks/useLoginStage';
import {CircleContainer, Contacts, Container, Form, Wrapper} from './LoginPage';

const LoginPage = ({isRecovery = false}) => {
  const methods = useForm();

  const {handleSubmit, currentStage, updateStage, error, loading, email, isDisabled, stage, updateDisable} =
    useLoginStages(isRecovery, methods.setValue);

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <Container>
          <ImageWrapper />
          <Form onSubmit={methods.handleSubmit(handleSubmit)}>
            <Stage
              email={email}
              error={error}
              isDisabled={isDisabled}
              loading={loading}
              stage={stage}
              updateDisable={updateDisable}
              updateStage={updateStage}
              {...currentStage}
            />
            <Contacts>
              <TypographyDefault color="#00000099" variant="caption1">
                Контакты для связи, в случае возниковения проблем со входом:
              </TypographyDefault>
              <TypographyDefault
                color="#000000DE"
                component="a"
                href="https://telegram.me/mikhailsouchkov"
                target="_blank"
                variant="body2"
              >
                <CircleContainer>
                  <Telegram color="white" fontSize="inherit" />
                </CircleContainer>
                mikhailsouchkov
              </TypographyDefault>
              <TypographyDefault
                color="#000000DE"
                component="a"
                href="mailto:suchkov.ms@hc-avangard.com"
                target="_blank"
                variant="body2"
              >
                <MailRounded color="gray" fontSize="small" />
                suchkov.ms@hc-avangard.com
              </TypographyDefault>
            </Contacts>
          </Form>
        </Container>
      </Wrapper>
    </FormProvider>
  );
};

export default LoginPage;
