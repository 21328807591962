import {useGroup, useSchool, useStudent} from '@hooks/filters';
import {useSeasons} from '@hooks/seasonsHooks';
import useDeepEffect from '@hooks/useDeepEffect';
import {Box} from '@mui/material';
import {Autocomplete, Grid, Stack} from '@ui/MUI';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import PsychoTestingAPI from '../../../api/PsychoTestingAPI';
import {
  cleanPsychoTestingReport,
  cleanTypologicalProfileReport,
  loadPlayerPsychotestReport,
  loadPlayerTypologicalProfileReport,
} from '../../../redux/actions/PsychoTestingActions';
import {Wrapper} from '../styles/styles';
import PlayerPsychotestReportTab from '../tabs/PlayerPsychotestReportTab';
import PlayerTypologicalReportTab from '../tabs/PlayerTypologicalReportTab';

export default function PsychotestReportPanel() {
  const dispatch = useDispatch();

  const [reportDates, setReportDates] = useState({isLoading: false, dates: []});
  const [isTypologicalProfile, setIsTypologicalProfile] = useState(false);
  useEffect(() => {
    dispatch(cleanTypologicalProfileReport());
    dispatch(cleanPsychoTestingReport());
  }, [isTypologicalProfile]);

  const [filter, setFilter] = useState({});

  const [activeTab, setActiveTab] = useState(0);

  const setDates = () => {
    setReportDates({...reportDates, isLoading: true});
    PsychoTestingAPI.getPlayerReportDates({student: filter?.student?.value}).then(({data}) => {
      setReportDates({...reportDates, dates: data?.dates});
    });
    setReportDates({...reportDates, isLoading: false});
  };

  useEffect(() => {
    if (filter?.student?.value) {
      if (!isTypologicalProfile) {
        setDates();
      }
    }
  }, [filter?.student?.value]);

  const isAllFiltersNotEmpty =
    filter?.season?.value && filter?.school?.value && filter?.group?.value && filter?.student?.value;

  const school = useSchool({});
  const group = useGroup({connectorValue: filter?.school?.value});
  const {options, isLoading} = useSeasons(false, ({currentSeason}) => setFilter({...filter, season: currentSeason}));
  const students = useStudent({connectorValue: filter?.group?.value});

  useEffect(() => {
    dispatch(cleanTypologicalProfileReport());
    if (isTypologicalProfile && filter?.season?.value && filter?.student?.value) {
      dispatch(
        loadPlayerTypologicalProfileReport({
          season: filter?.season?.value,
          playerId: filter?.student?.value,
        }),
      );
    }
  }, [isTypologicalProfile, filter?.season?.value, filter?.student?.value]);

  useDeepEffect(() => {
    if (!isTypologicalProfile && filter?.student?.value && filter?.reportDates?.length > 0) {
      const dates = filter?.reportDates?.map((date) => date?.value);
      dispatch(
        loadPlayerPsychotestReport({
          playerId: filter?.student?.value,
          dates: dates,
        }),
      );
    }
    dispatch(cleanPsychoTestingReport());
  }, [filter?.student?.value, filter?.reportDates, isTypologicalProfile]);

  const changeTab = (key) => {
    setActiveTab(key);
  };

  return (
    <Wrapper>
      <PageTitleDefault className="PageTitleDefault" text={'Отчет психологического профиля'} />

      <Stack borderRadius="6px" sx={{p: 3, mb: 1, backgroundColor: '#fff'}}>
        <Wrapper>
          <Grid container gap={2}>
            {isTypologicalProfile && (
              <Grid item md={2.5} minWidth="200px">
                <Autocomplete
                  isLoading={isLoading}
                  label="Сезон"
                  multiple={false}
                  options={options}
                  value={filter?.season}
                  onChange={(_, newValue) => {
                    setFilter({...filter, season: newValue, reportDates: []});
                  }}
                />
              </Grid>
            )}
            <Grid item md={2.5} minWidth="200px">
              <Autocomplete
                isLoading={school.isLoading}
                label="Школа"
                multiple={false}
                name="school"
                options={school.options}
                value={filter.school}
                onChange={(_, newValue) => {
                  setFilter({...filter, school: newValue, group: null});
                }}
              />
            </Grid>
            <Grid item md={2.5} minWidth="200px">
              <Autocomplete
                isLoading={group.isLoading}
                label="Команда"
                multiple={false}
                name="group"
                options={group.options}
                value={filter?.group}
                onChange={(_, newValue) => {
                  setFilter({...filter, group: newValue});
                }}
              />
            </Grid>
            <Grid item md={2.5} minWidth="200px">
              <Autocomplete
                isLoading={students?.isLoading}
                label="Игрок"
                multiple={false}
                name="student"
                options={students?.options}
                value={filter?.student}
                onChange={(_, newValue) => {
                  setFilter({...filter, student: newValue});
                }}
              />
            </Grid>
            {!isTypologicalProfile && (
              <Grid item md={2.5} minWidth="200px">
                <Autocomplete
                  isLoading={reportDates?.isLoading}
                  label="Дата отчета"
                  multiple={true}
                  options={
                    reportDates?.dates?.length > 0
                      ? reportDates?.dates?.map((item) => ({label: item, value: item}))
                      : []
                  }
                  value={filter?.reportDates}
                  onChange={(_, newValue) => {
                    setFilter({...filter, reportDates: newValue});
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Wrapper>
      </Stack>
      <Box bgcolor="#fff" borderRadius="6px" overflow="hidden">
        <TabsNew
          tabs={[
            {label: 'Психофизиологический', value: <PlayerPsychotestReportTab />},
            {
              label: 'Индивидуально-типологический',
              value: <PlayerTypologicalReportTab isAllFiltersNotEmpty={isAllFiltersNotEmpty} />,
            },
          ]}
          value={activeTab}
          onChange={(key) => {
            changeTab(key);
            if (key === 1) {
              setIsTypologicalProfile(true);
            } else {
              if (filter?.student?.value) {
                setDates();
              }

              setIsTypologicalProfile(false);
            }
          }}
        />
      </Box>
    </Wrapper>
  );
}
