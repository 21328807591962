import {defaultTabs, methodistTabs, parentTabs, playerTabs} from '@components/apps/PhysicalTesting/constants';
import {userRoles} from '@src/constants';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import styled from 'styled-components';

const TABS = {
  [userRoles.parent]: parentTabs,
  [userRoles.student]: playerTabs,
  [userRoles.methodist]: methodistTabs,
};

const PhysicalTestingNew = () => {
  const userRole = useSelector((state) => state.users.currentUser.userRole);
  const history = useHistory();
  const {type} = useParams();

  const tabs = useMemo(() => TABS?.[userRole] || defaultTabs, [userRole]);

  const typeIndex = useMemo(() => tabs.findIndex((item) => item?.link === type), [type]);

  useEffect(() => {
    if (typeIndex < 0) {
      return history.push(`/physical_testing_new/${tabs[0].link}`);
    }
  }, [typeIndex]);

  return (
    <>
      <StyledPageTitle
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'ОФП/СФП', path: '/physical_testing_new/overall_report'},
          {
            label: `${tabs[typeIndex]?.label}`,
            path: `/physical_testing_new/${tabs[typeIndex]?.link}`,
          },
        ]}
      >
        ОФП/СФП
      </StyledPageTitle>
      <TabsNew
        withoutWrapper
        tabs={tabs}
        value={typeIndex >= 0 ? typeIndex : 0}
        onChange={(e) => {
          history.push(`/physical_testing_new/${tabs[e]?.link}`);
        }}
      />
    </>
  );
};

export default PhysicalTestingNew;

const StyledPageTitle = styled((props) => <PageTitleDefault {...props} />)`
  margin-left: 0;
`;
