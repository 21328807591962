import ClassicTab from '@common/Statistics/PlayerStatistics/Tabs/ClassicTab';
import PropTypes from 'prop-types';
import React from 'react';

import {useLoadPlayersStatisticsData} from '../../hooks';

function Classic({tab, filter, matchFilter}) {
  const statisticsTab = useLoadPlayersStatisticsData({tab, filter, matchFilter});

  const {isLoad, isLoading, data, hasError, hasMatchFilter} = statisticsTab;

  return <ClassicTab {...{isLoad, isLoading, data, hasError, filter, hasMatchFilter}} />;
}

Classic.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default Classic;
