import LessonsAPI from '../../api/LessonsAPI';
import {ReactComponentNotification} from '../../components/ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';
import {NOTIFICATION_TYPES} from '../../constants/config';
import * as types from '../ActionTypes';

let loadLessons_ = () => {
  return {
    type: types.LOAD_LESSONS,
  };
};
let loadLessonsSuccess = (lessons) => {
  return {
    type: types.LOAD_LESSONS_SUCCESS,
    lessons: lessons,
  };
};
let loadLessonsFailed = (error) => {
  return {
    type: types.LOAD_LESSONS_FAIL,
    error: error,
  };
};

//thunk
export function loadAllLessons() {
  return (dispatch) => {
    dispatch(loadLessons_());
    return LessonsAPI.getAllLessons().then(
      (lessons) => dispatch(loadLessonsSuccess(lessons)),
      (error) => dispatch(loadLessonsFailed(error)),
    );
  };
}

export function loadLessonById(id) {
  return (dispatch) => {
    dispatch(loadLessons_());
    return LessonsAPI.getLesson(id).then(
      (lesson) => dispatch(loadLessonsSuccess([lesson])),
      (error) => dispatch(loadLessonsFailed(error)),
    );
  };
}

let createLessonSuccess = (lesson) => {
  return {
    type: types.CREATE_LESSON_SUCCESS,
    lesson: lesson,
  };
};
let createLessonFailed = (error) => {
  return {
    type: types.CREATE_LESSON_FAIL,
    error: error,
  };
};

//thunk
export function createLesson(data) {
  return (dispatch) => {
    return LessonsAPI.createLesson(data).then(
      (lesson) => dispatch(createLessonSuccess(lesson)),
      (error) => dispatch(createLessonFailed(error)),
    );
  };
}

let updateLesson_ = () => {
  return {
    type: types.UPDATE_LESSON,
  };
};
let updateLessonSuccess = (lesson) => {
  return {
    type: types.UPDATE_LESSON_SUCCESS,
    lesson: lesson,
  };
};
let updateLessonFailed = (error) => {
  return {
    type: types.UPDATE_LESSON_FAIL,
    error: error,
  };
};

//thunk
export function updateLesson(data) {
  return (dispatch) => {
    dispatch(updateLesson_());
    return LessonsAPI.updateLesson(data).then(
      (lesson) => dispatch(updateLessonSuccess(lesson)),
      (error) => dispatch(updateLessonFailed(error)),
    );
  };
}

let deleteLesson_ = () => {
  return {
    type: types.DELETE_LESSON,
  };
};
let deleteLessonSuccess = (id) => {
  return {
    type: types.DELETE_LESSON_SUCCESS,
    id: id,
  };
};
let deleteLessonFailed = (error) => {
  return {
    type: types.DELETE_LESSON_FAIL,
    error: error,
  };
};

//thunk
export function deleteLesson(id) {
  return (dispatch) => {
    dispatch(deleteLesson_());
    return LessonsAPI.deleteLesson(id).then(
      () => dispatch(deleteLessonSuccess(id)),
      (error) => dispatch(deleteLessonFailed(error)),
    );
  };
}

export function loadAllLessonsTypes() {
  return (dispatch) => {
    return LessonsAPI.getAllLessonsTypes()
      .then((data) => {
        dispatch({
          type: types.LOAD_LESSONS_TYPES,
          lessonsTypes: data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке мероприятий';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
      });
  };
}
export function loadLessonStatistics(request) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_LESSONS,
    });
    return LessonsAPI.getLessonStatistics(request)
      .then((data) => {
        dispatch({
          type: types.LOAD_LESSONS_STATISTICS,
          lessonsStatistics: data.data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке мероприятий';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
        dispatch({
          type: types.LOAD_LESSONS_FAIL,
        });
      });
  };
}

export function cleanLessonStatistics() {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_LESSONS_STATISTICS,
      lessonsStatistics: undefined,
    });
  };
}

export function loadLessonsByFilter(filter) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_LESSONS,
    });
    return LessonsAPI.getLessonsByFilter(filter)
      .then((data) => {
        dispatch({
          type: types.LOAD_FILTERED_LESSONS,
          calendarLessons: data.data.data,
        });
      })
      .catch(function (err) {
        let errorText = 'Ошибка при загрузке мероприятий';
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              ReactComponentNotification(NOTIFICATION_TYPES['error'], `${errorText}: ${err.response.data.message}`);
            }
          }
        }
        dispatch({
          type: types.LOAD_LESSONS_FAIL,
        });
      });
  };
}

export function cleanCalendarLessons() {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_FILTERED_LESSONS,
      calendarLessons: [],
    });
  };
}
