import React from 'react';

function Puck() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M13.1909 0.923941C11.5427 0.551642 9.51657 0.646286 7.48574 1.19045C5.45491 1.7346 3.65289 2.66572 2.41165 3.81224C1.11643 5.0086 0.574578 6.30726 0.885837 7.46889L1.76157 10.7372C2.07283 11.8988 3.19141 12.7525 4.9113 13.141C6.5595 13.5133 8.58565 13.4187 10.6165 12.8745C12.6473 12.3304 14.4493 11.3993 15.6906 10.2527C16.9858 9.05638 17.5277 7.75775 17.2164 6.59608L16.3407 3.32779C16.0294 2.16615 14.9108 1.31241 13.1909 0.923941ZM16.1722 5.16205L16.6007 6.76107C16.8476 7.68284 16.3709 8.75659 15.2581 9.78448C14.0913 10.8621 12.3843 11.7409 10.4515 12.2588C8.51866 12.7767 6.60098 12.8692 5.05173 12.5193C3.57408 12.1855 2.62429 11.494 2.3773 10.5722L1.94885 8.97318C2.48053 9.37441 3.18419 9.68045 4.03556 9.87274C5.68376 10.245 7.70992 10.1504 9.74075 9.60624C11.7716 9.06208 13.5736 8.13097 14.8148 6.98444C15.456 6.3922 15.9123 5.77537 16.1722 5.16205ZM14.3823 6.51619C13.2156 7.59385 11.5086 8.47261 9.57576 8.99051C7.64292 9.50841 5.72524 9.60088 4.17599 9.25097C2.69834 8.91718 1.74856 8.22569 1.50157 7.30391C1.25458 6.38213 1.73137 5.30839 2.84416 4.28049C4.01089 3.20283 5.71789 2.32408 7.65073 1.80617C9.58356 1.28827 11.5012 1.1958 13.0505 1.54571C14.5281 1.8795 15.4779 2.57099 15.7249 3.49277C15.9719 4.41455 15.4951 5.48829 14.3823 6.51619Z"
          fill="#626262"
          stroke="#626262"
          strokeWidth="0.5"
        />
      </g>
    </React.Fragment>
  );
}
export default Puck;
