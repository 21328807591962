import styled from 'styled-components';

import {ButtonsBlock} from '../LinesMobile';

export const Wrapper = styled.div`
  position: relative;
  min-height: 250px;
  padding-top: 12px;
  padding-bottom: 0;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;

  .MuiFormControl {
    &-root {
      padding-right: 12px;
      padding-left: 12px;
      .MuiInputLabel {
        &-root {
          left: 12px;
        }
      }
    }
  }

  ${ButtonsBlock} {
    padding-top: 16px;
    .MuiButton {
      &-root {
        @media screen and (max-width: 360px) {
          padding: 8px 12px;
          font-size: 12px;
        }
      }
    }
  }
`;

//ToDo: закинуть тему

export const TitleBlock = styled.div`
  padding: 0 12px;

  .MuiTypography {
    &-subtitle2 {
      &:last-child {
        font-size: 14px;
        line-height: 20px;
        text-transform: ${(props) => (props.isEmptyBlock ? 'none' : 'uppercase')};
      }
    }
  }
`;

export const ConfirmModalContent = styled.div``;

export const ConfirmModalContentText = styled.p`
  margin-top: -6px;
  margin-bottom: 0;
  font: 400 normal 14px/20px 'Proxima Nova';
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.17px;
`;
