import ToggleBlock from '@common/ToggleBlock';
import {Wrapper} from '@components/apps/NewBodyMassIndex/BodyMassIndex';
import {GLOSSARY_ITEMS} from '@components/apps/NewBodyMassIndex/constants';
import useFatAccess from '@hooks/useFatAccess';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {longDash} from '@src/constants';
import {Box, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

Glossary.propTypes = {
  groupId: PropTypes.string,
};
const border = `1px solid ${grey['12']}`;

function Glossary({groupId}) {
  const {hasAccess} = useFatAccess(groupId);
  const bodyParams = useMemo(
    () =>
      [{...GLOSSARY_ITEMS.height}, {...GLOSSARY_ITEMS.weight}, {...GLOSSARY_ITEMS.caliperometry}].slice(
        0,
        hasAccess ? 3 : 2,
      ),
    [hasAccess],
  );
  return (
    <Wrapper py={{xxs: 1, sm: 3}}>
      <Box mx={{xxs: 2, sm: 4}}>
        <ToggleBlock closeText="Скрыть" contentProps={{mx: {xxs: -2, sm: -4}, overflow: 'unset'}} showText="Глоссарий">
          {bodyParams.map(({label, increase, decrease}, index) => {
            return (
              <Stack borderBottom={border} direction="row" key={index}>
                <Box borderRight={border} flex={0.2} px={{xxs: 1, sm: 3}} py={{xxs: 0, sm: 2}}>
                  <Typography variant="subtitle2">{label}</Typography>
                </Box>
                <Stack direction={{xxs: 'column', xs: 'row'}} flex={1}>
                  {[increase, decrease].map(({label, bgc, mainColor}, index) => (
                    <Stack direction="row" flex={1} key={index} px={3} py={2} spacing={1}>
                      <Box backgroundColor={bgc} borderRadius="2px" height={24} width={24}>
                        {!index ? <ExpandLessIcon sx={{fill: mainColor}} /> : <ExpandMoreIcon sx={{fill: mainColor}} />}
                      </Box>
                      <Typography variant="caption1">{longDash}</Typography>
                      <Typography variant="caption1">{label}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            );
          })}
        </ToggleBlock>
      </Box>
    </Wrapper>
  );
}

export default Glossary;
