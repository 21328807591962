import {Map} from 'immutable';

import * as types from '../ActionTypes';

const initialState = {
  usersMap: Map(),
  student: {},
  currentUserId: undefined,
  currentUser: undefined,
  loading: false,
  error: undefined,
  initialized: false,
  selectedFilters: {
    selectedAmplua: null,
    selectedSchool: null,
    selectedGroup: null,
  },
  studentProfileFilters: {
    ratingFilter: null,
    statsFilters: {
      seasonFilter: null,
      periodFilter: null,
    },
    estimationGamesFilters: {
      estimationSeasonFilter: null,
      estimationSelectedPage: null,
    },
    skillsEstimationFilter: null,
  },
};

const UsersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CREATE_USER:
    case types.DELETE_USER:
    case types.UPDATE_USER:
    case types.LOAD_USERS:
    case types.SIGNUP:
    case types.LOGOUT:
    case types.LOGIN:
    case types.RECOVERY_PASSWORD:
    case types.RESET_PASSWORD:
    case types.VALIDATE_TOKEN:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case types.CREATE_USER_FAIL:
    case types.DELETE_USER_FAIL:
    case types.UPDATE_USER_FAIL:
    case types.LOAD_USERS_FAIL:
    case types.LOGIN_FAIL:
    case types.LOGOUT_FAIL:
    case types.SIGNUP_FAIL:
    case types.LOGIN_CLEAR_ERRORS:
    case types.RECOVERY_PASSWORD_FAILED:
    case types.LOGIN_THROW_ERROR:
    case types.RESET_PASSWORD_FAILED:
    case types.VALIDATE_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.RECOVERY_PASSWORD_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        currentUser: action.user,
        loading: false,
        error: undefined,
      };

    case types.INITIALIZE_AUTH:
      return {
        ...state,
        loading: true,
        initialized: false,
        error: undefined,
      };

    case types.INITIALIZE_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        initialized: false,
        error: action.error,
      };

    case types.SIGNUP_SUCCESS:
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        currentUserId: action.user.id,
        currentUser: action.user,
        usersMap: state.usersMap.set(action.user.id, action.user),
        loading: false,
      };

    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        currentUserId: undefined,
        currentUser: undefined,
        loading: false,
      };

    case types.INITIALIZE_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        initialized: true,
        currentUserId: action.user === undefined ? undefined : action.user.id,
        currentUser: action.user === undefined ? undefined : action.user,
        usersMap: action.user === undefined ? state.usersMap : state.usersMap.set(action.user.id, action.user),
      };

    case types.LOAD_USERS_SUCCESS:
      return {
        ...state,
        usersMap: state.usersMap.merge(
          action.users.reduce((res, u) => {
            return res.set(u.id, u);
          }, Map()),
        ),
        loading: false,
      };

    case types.LOAD_STUDENT_SUCCESS:
      return {
        ...state,
        student: action.student,
        loading: false,
      };
    case types.CLEAN_STUDENT_STATE:
      return {
        ...state,
        loading: false,
        student: {},
      };
    case types.UPDATE_USER_SUCCESS:
    case types.CREATE_USER_SUCCESS:
      if (action.user.userRole !== 'student') {
        return {
          ...state,
          usersMap: state.usersMap.set(action.user.id, action.user),
          loading: false,
        };
      } else {
        return {
          ...state,
          student: {...action.user},
          //usersMap: state.usersMap.set(action.user.id, action.user),
          loading: false,
        };
      }

    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        usersMap: state.usersMap.delete(action.id),
        loading: false,
      };

    case types.INITIALIZE_CITIZENSHIPS:
      return {
        ...state,
        citizenships: action.citizenships,
        loading: false,
      };
    case types.INITIALIZE_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
        loading: false,
      };
    case types.INITIALIZE_AMPLUA:
      return {
        ...state,
        amplua: action.amplua,
        loading: false,
      };
    case types.LOAD_PLAYER_STATISTICS:
      return {
        ...state,
        userStatistics: action.userStatistics,
        loading: false,
      };
    case types.LOAD_PLAYER_STATISTICS_WITHOUT:
      return {
        ...state,
        userStatistics: action.userStatistics,
        loading: false,
      };
    case types.STUDENT_LOADING:
    case types.TEACHERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.STOP_STUDENT_LOADING:
      return {
        ...state,
        loading: false,
      };
    case types.LOAD_STUDENTS:
      return {
        ...state,
        students: action.students,
        studentsCount: action.studentsCount,
      };
    case types.LOAD_TEACHERS:
      return {
        ...state,
        teachers: action.teachers,
        teachersCount: action.teachersCount,
      };
    case types.LOAD_RATING_PLAYER_STATISTICS:
      return {
        ...state,
        ratingPlayerStatistics: action.ratingPlayerStatistics,
        loading: false,
      };
    case types.CHANGE_TRAINER_DEFAULT_FILTERS:
      return {
        ...state,
        defaultFilters: action.defaultFilters,
        loading: false,
      };
    case types.SAVE_STUDENTS_FILTERS:
      return {
        ...state,
        selectedFilters: {
          selectedAmplua: action.allSelectedFilters.selectedAmplua,
          selectedGroup: action.allSelectedFilters.selectedGroup,
          selectedSchool: action.allSelectedFilters.selectedSchool,
        },
      };
    case types.SAVE_STUDENT_PROFILE_FILTERS:
      const {ratingPeriod, seasonFilter, periodFilter, estimationSeasonFilter, selectedPage, skillsEstimationSeason} =
        action.allSelectedFilters;
      return {
        ...state,
        studentProfileFilters: {
          ratingFilter: ratingPeriod ? ratingPeriod : state.studentProfileFilters.ratingFilter,
          statsFilters: {
            seasonFilter: seasonFilter ? seasonFilter : state.studentProfileFilters.statsFilters.seasonFilter,
            periodFilter: periodFilter ? periodFilter : state.studentProfileFilters.statsFilters.periodFilter,
          },
          estimationGamesFilters: {
            estimationSeasonFilter: estimationSeasonFilter
              ? estimationSeasonFilter
              : state.studentProfileFilters.estimationGamesFilters.estimationSeasonFilter,
            estimationSelectedPage: selectedPage
              ? selectedPage
              : state.studentProfileFilters.estimationGamesFilters.estimationSelectedPage,
          },
          skillsEstimationFilter: skillsEstimationSeason
            ? skillsEstimationSeason
            : state.studentProfileFilters.skillsEstimationFilter,
        },
      };
    default:
      return state;
  }
};

export default UsersReducer;
