import ProgressCell from '@common/Statistics/PlayerStatistics/ProgressCell';
import TableTemplate from '@common/Statistics/PlayerStatistics/TableTemplate';
import {longDash} from '@src/constants';
import {customTableComparator, customTableTimeComparator} from '@src/helpers';
import {Tooltip, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import TopFiveItem from '../../TopFiveItem';

const columns = [
  {
    accessorKey: 'foulDifference',
    header: () => {
      return (
        <Tooltip title="Разница удалений">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            УдР
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {position: 'relative', textAlign: 'center'},
    cell: ({getValue}) => (
      <ProgressCell allowPositiveSign isIndividual percent={Math.abs(getValue()?.percent)} value={getValue()?.value} />
    ),
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'earnedFouls',
    header: () => {
      return (
        <Tooltip title="Заработанные удаления">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            УдЗ
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'fouls',
    header: () => {
      return (
        <Tooltip title="Удаления">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            УдП
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'foulTime',
    header: () => {
      return (
        <Tooltip title="Штрафные минуты">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Штр
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableTimeComparator,
  },
  {
    accessorKey: 'plusMinus',
    header: () => {
      return (
        <Tooltip title="Разница заработанных и полученных удалений когда игрок был на льду">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Уд +/-
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cell: ({getValue}) => {
      return <ProgressCell allowPositiveSign percent={Math.abs(getValue()?.percent)} value={getValue()?.value} />;
    },
    cellStyle: {position: 'relative', textAlign: 'center'},
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'plus',
    header: () => {
      return (
        <Tooltip title="Игрок находился на льду когда было заработано удаление">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Уд +
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <Typography variant="body2">{getValue() >= 0 ? getValue() : longDash}</Typography>,
  },
  {
    accessorKey: 'minus',
    header: () => {
      return (
        <Tooltip title="Игрок находился на льду когда было получено удаление">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Уд -
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <Typography variant="body2">{getValue() >= 0 ? getValue() : longDash}</Typography>,
  },
];
function FoulsTab({isLoad, isLoading, data, hasError, hasMatchFilter}) {
  const [sorting, setSorting] = useState([{asc: true, id: 'foulDifference'}]);

  return (
    <TableTemplate
      columns={columns}
      hasError={hasError}
      hasMatchFilter={hasMatchFilter}
      isLoad={isLoad}
      isLoading={isLoading}
      rowsData={data}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
}

FoulsTab.propTypes = {
  data: PropTypes.array.isRequired,
};

export default FoulsTab;
