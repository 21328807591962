import * as types from '../ActionTypes.js';

const initialState = {
  loading: false,
  error: undefined,
};

const DashboardsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.DASHBOARD_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case types.DASHBOARD_LOADING_END:
      return {
        ...state,
        loading: false,
        teachersDashboard: {...action.dashboard},
      };
    case types.STUDENT_DASHBOARD_LOADING_END:
      return {
        ...state,
        loading: false,
        dashboard: {...action.dashboard},
      };
    case types.DASHBOARD_LOADING_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default DashboardsReducer;
