import React from 'react';

function DoubleArrows() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M2.27608 3.99999H9.99992C10.3681 3.99999 10.6666 3.70152 10.6666 3.33333C10.6666 2.96514 10.3681 2.66667 9.99992 2.66667H2.27608L3.80467 1.13807C4.06502 0.877728 4.06502 0.455603 3.80467 0.195258C3.54433 -0.0650861 3.1222 -0.0650861 2.86186 0.195258L0.195172 2.86192C0.179641 2.87745 0.164953 2.8938 0.151016 2.9108C0.144703 2.91848 0.139203 2.92661 0.133297 2.93451C0.126172 2.94401 0.118828 2.9533 0.112203 2.9632C0.105859 2.97267 0.100359 2.98251 0.0945469 2.99223C0.0892031 3.00117 0.0835781 3.00992 0.0786406 3.01914C0.0733281 3.02905 0.0688281 3.03926 0.0640781 3.04939C0.0595469 3.05898 0.0547656 3.06842 0.0506719 3.07826C0.0466094 3.08811 0.0432969 3.09817 0.0397344 3.10817C0.0359531 3.1187 0.0319219 3.12908 0.0286719 3.13983C0.0256406 3.14983 0.0234219 3.15998 0.0208906 3.17011C0.0181094 3.18108 0.0150469 3.19192 0.0128281 3.20311C0.0105156 3.21483 0.00914062 3.22664 0.00745312 3.23842C0.00607812 3.24814 0.00420312 3.25773 0.00323437 3.26758C-0.00107813 3.31133 -0.00107813 3.35542 0.00323437 3.39917C0.00420312 3.40902 0.00604687 3.41858 0.00745312 3.42833C0.00914062 3.44011 0.0105156 3.45195 0.0128594 3.46364C0.0150781 3.47483 0.0181406 3.48564 0.0209219 3.49661C0.0234531 3.50673 0.0256719 3.51689 0.0287031 3.52689C0.0319531 3.53764 0.0359844 3.54805 0.0397656 3.55855C0.0433594 3.56855 0.0466406 3.57861 0.0507031 3.58845C0.0547656 3.5983 0.0595781 3.6077 0.0641094 3.6173C0.0688906 3.62742 0.0733906 3.63764 0.0787031 3.64758C0.0836406 3.65677 0.0892344 3.66548 0.0945781 3.67442C0.100391 3.68417 0.105891 3.69402 0.112234 3.70352C0.118828 3.71339 0.126172 3.72264 0.133266 3.73214C0.139203 3.74008 0.144703 3.7482 0.151047 3.75592C0.164891 3.7728 0.179484 3.78902 0.194891 3.80442C0.195016 3.80455 0.195078 3.80467 0.195203 3.80477L2.86186 6.47143C3.1222 6.73177 3.54433 6.73177 3.80467 6.47143C4.06502 6.21108 4.06502 5.78896 3.80467 5.52861L2.27608 3.99999Z"
          fill="#990011"
        />
        <path
          d="M15.8488 7.75585C15.8551 7.7481 15.8606 7.73994 15.8666 7.73197C15.8737 7.72254 15.881 7.71329 15.8876 7.70344C15.894 7.69394 15.8995 7.68407 15.9053 7.67432C15.9106 7.66541 15.9162 7.65669 15.9211 7.64754C15.9264 7.6376 15.9309 7.62735 15.9357 7.61719C15.9402 7.60763 15.945 7.59823 15.9491 7.58841C15.9531 7.57857 15.9565 7.56851 15.96 7.55851C15.9638 7.54798 15.9678 7.5376 15.9711 7.52685C15.9741 7.51685 15.9763 7.50666 15.9789 7.49657C15.9816 7.4856 15.9847 7.47476 15.9869 7.4636C15.9892 7.45188 15.9906 7.44007 15.9923 7.42829C15.9937 7.41857 15.9956 7.40901 15.9965 7.39913C16.0009 7.35538 16.0009 7.31129 15.9965 7.26754C15.9956 7.25769 15.9937 7.24813 15.9923 7.23838C15.9906 7.2266 15.9893 7.21476 15.9869 7.20307C15.9847 7.19188 15.9816 7.18107 15.9789 7.1701C15.9763 7.15998 15.9741 7.14982 15.9711 7.13982C15.9678 7.12907 15.9638 7.11866 15.96 7.10816C15.9564 7.09816 15.9531 7.0881 15.9491 7.07826C15.945 7.06844 15.9402 7.05904 15.9357 7.04947C15.9309 7.03932 15.9264 7.02907 15.9211 7.01913C15.9162 7.00997 15.9106 7.00126 15.9053 6.99235C15.8995 6.9826 15.8939 6.97272 15.8876 6.96322C15.881 6.95338 15.8737 6.94413 15.8666 6.93469C15.8606 6.92672 15.8551 6.91857 15.8488 6.91082C15.835 6.8941 15.8205 6.87801 15.8053 6.86269C15.805 6.86244 15.8048 6.86219 15.8046 6.86197L13.138 4.19532C12.8776 3.93498 12.4555 3.93498 12.1951 4.19532C11.9348 4.45566 11.9348 4.87779 12.1951 5.13813L13.7237 6.66673H5.99991C5.63172 6.66673 5.33325 6.96519 5.33325 7.33338C5.33325 7.70157 5.63172 8.00004 5.99991 8.00004H13.7237L12.1951 9.52863C11.9348 9.78897 11.9348 10.2111 12.1951 10.4714C12.4555 10.7318 12.8776 10.7318 13.138 10.4714L15.8046 7.80479C15.8049 7.80454 15.805 7.80429 15.8053 7.80407C15.8205 7.78866 15.835 7.77257 15.8488 7.75585Z"
          fill="#990011"
        />
      </g>
    </React.Fragment>
  );
}
export default DoubleArrows;
