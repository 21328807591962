import {API_ENDPOINT} from '@src/constants/config';
import {Params} from '@src/types';
import {TeamDto} from '@src/types/generated';
import {GroupsParams} from '@src/types/groupsAPIV2';
import axios from 'axios';

export function getSchoolGroups<T>(schoolId: string, params: Params & T) {
  return axios.get<TeamDto>(`${API_ENDPOINT}/v2/schools/${schoolId}/teams`, {params});
}

export function getGroups<T>(params: GroupsParams & T) {
  return axios.get<TeamDto>(`${API_ENDPOINT}/v2/teams`, {params});
}
