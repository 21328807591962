import Card from '@common/Card';
import useEvent from '@hooks/useEvent';
import {Article, Link, MoreVert} from '@mui/icons-material';
import {Box, Divider, IconButton, Popover, Stack, Typography} from '@mui/material';
import {updateFormData, updateFormVisability, updatePreviewData, updatePreviewVisability} from '@slices/materials';
import {grey} from '@ui/MUI/colors';
import moment from 'moment';
import React, {useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {cardStylesBySectionType} from '../../constants';
import {Circle} from '../../styles';

const iconStyles = {
  fontSize: 28,
  color: grey[42],
};

const PREVIEW_IMAGE_PATH = '/img/video-preview.jpg';

const MaterialCard = ({sectionType, ...material}) => {
  const isImage = material.type.slug === 'image';
  const isFile = material.type.slug === 'file';
  const isLink = material.type.slug === 'link';
  const isVideo = material.type.slug === 'video';
  const isEditable = material.canEdit;

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = useEvent(() => {
    if (material.category.slug === 'video_analytics') {
      history.push(`/materials/${material.id}`);
    } else {
      dispatch(updatePreviewData(material));
      dispatch(updatePreviewVisability(true));
    }
  });

  const cardStyles = useMemo(() => {
    return cardStylesBySectionType[sectionType];
  }, [sectionType]);

  const icon = useMemo(() => {
    if (['file', 'link'].includes(material.type.slug)) {
      return material.type.slug === 'file' ? <Article sx={iconStyles} /> : <Link sx={iconStyles} />;
    }
  }, [material.type.slug]);

  const date = useMemo(() => moment(material.createdAt).format('DD.MM.YYYY'), [material.createdAt]);

  const chip = useMemo(
    () => ({
      name: material.category.name,
      icon: <Circle type={material.category.slug} />,
    }),
    [material.category.slug],
  );

  const openNewFile = () => {
    window.open(material.link || material.storage.link, '_blank');
    setAnchorEl(null);
  };

  const edit = () => {
    dispatch(updateFormData(material));
    dispatch(updateFormVisability(true));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const image = useMemo(() => {
    if (isImage) {
      return material.link || material?.storage?.link;
    }
    if (isVideo) return PREVIEW_IMAGE_PATH;
  }, [isImage, isVideo, material]);

  return (
    <Card
      {...cardStyles}
      actionIconSlot={
        (isFile || isLink) && (
          <>
            <IconButton
              size="small"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <MoreVert />
            </IconButton>
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{vertical: 'middle', horizontal: 'right'}}
              elevation={1}
              open={open}
              onClose={() => setAnchorEl(null)}
            >
              <Box
                borderRadius={1}
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
                py={1}
                sx={{background: 'white'}}
                width="124px"
              >
                <Stack spacing={1}>
                  <Typography
                    fontWeight={600}
                    pl={2}
                    sx={{cursor: 'pointer'}}
                    variant="subtitle2"
                    onClick={openNewFile}
                  >
                    {isFile ? 'Скачать' : 'Открыть'}
                  </Typography>
                  {isEditable && (
                    <>
                      <Divider />
                      <Typography fontWeight={600} pl={2} sx={{cursor: 'pointer'}} variant="subtitle2" onClick={edit}>
                        Редактировать
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </Popover>
          </>
        )
      }
      chip={chip}
      description={date}
      icon={icon}
      image={image}
      title={material.name}
      onClick={sectionType !== 'links_and_files' ? handleClick : undefined}
    />
  );
};

MaterialCard.propTypes = {};

export default MaterialCard;
