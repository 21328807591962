import FieldMap from '@common/Statistics/PlayerStatistics/FieldMap';
import GoaltenderZone from '@components/apps/PlayerPersonalFile/components/Statistics/components/GoaltenderZone';
import {selectShotsZones} from '@selectors/playerPersonalFile';
import {clearShotsZones, loadShotsZones} from '@slices/playerPersonalFile';
import React from 'react';

function FieldZones({...props}) {
  return (
    <GoaltenderZone
      Component={FieldMap}
      cleaner={clearShotsZones}
      loader={loadShotsZones}
      selector={selectShotsZones}
      title="Броски по зонам"
      {...props}
    />
  );
}

export default FieldZones;
