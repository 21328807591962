import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {shallowEqual} from '@helpers/shallowEqual';
import {useSeasons} from '@hooks/seasonsHooks';
import useStore from '@hooks/useStore';
import {selectPlayerTournaments} from '@selectors/gameStatisticsMkc';
import {selectStatFilters} from '@selectors/playerPersonalFile';
import {clearStatisticsFilter, setStatisticsFilter} from '@slices/playerPersonalFile';
import {clearPlayerTournaments, loadPlayerTournaments} from '@slices/statisticsMKC';
import {Autocomplete, Box, Stack} from '@ui/MUI';
import CheckboxDefault from '@ui/MUI/Checkbox';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

function Filters({isYoungerAgeGroup}) {
  const playerId = useParams()?.id;
  const dispatch = useDispatch();
  const filters = useStore(selectStatFilters, shallowEqual);
  const tournaments = useStore(selectPlayerTournaments, shallowEqual);
  const seasons = useSeasons(false, ({currentSeason}) => dispatch(setStatisticsFilter({season: currentSeason})));

  useEffect(() => {
    const season = filters?.season?.value;
    if (season && playerId) dispatch(loadPlayerTournaments({playerId, season}));
  }, [playerId, filters?.season?.value]);

  useEffect(() => {
    return () => {
      dispatch(clearPlayerTournaments());
      dispatch(clearStatisticsFilter());
    };
  }, []);

  return (
    <Wrapper p={{xxs: 3, xs: 4}}>
      <Stack alignItems="center" direction={{xxs: 'column', sm: 'row'}} gap={3}>
        <Box width={{xxs: '100%', sm: 160}}>
          <Autocomplete
            isLoading={seasons?.isLoading}
            label="Сезон"
            multiple={false}
            options={seasons?.options}
            value={filters?.season}
            onChange={(_, value) =>
              dispatch(setStatisticsFilter({season: value, tournamentId: '', teamMkcId: '', isFilled: false}))
            }
          />
        </Box>
        <Box width={{xxs: '100%', sm: 220}}>
          <Autocomplete
            disabled={!filters?.season}
            isLoading={tournaments?.isLoading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            label="Турнир"
            multiple={false}
            name="tournamentId"
            options={tournaments?.data || []}
            value={filters?.tournamentId}
            width={{xxs: '100%', sm: 220}}
            onChange={(_, value) =>
              dispatch(setStatisticsFilter({tournamentId: value, teamMkcId: '', isFilled: false}))
            }
          />
        </Box>
        <Box width={{xxs: '100%', sm: 220}}>
          <Autocomplete
            disabled={!filters?.tournamentId}
            label="Команда"
            multiple={false}
            options={filters?.tournamentId?.groups || []}
            value={filters?.teamMkcId}
            onChange={(_, value) => dispatch(setStatisticsFilter({teamMkcId: value, isFilled: Boolean(value)}))}
          />
        </Box>
        {!isYoungerAgeGroup && (
          <Box ml="auto">
            <CheckboxDefault
              label="Против ТОП-3"
              value={filters?.onlyAgainstTopThree}
              onChange={(_, value) => dispatch(setStatisticsFilter({onlyAgainstTopThree: value}))}
            />
          </Box>
        )}
      </Stack>
    </Wrapper>
  );
}

export default Filters;
