import {createEstimate, deleteEstimate, updateEstimate} from '@api/skill';
import useEvent from '@hooks/useEvent';
import {selectFilters, selectModal} from '@selectors/skill';
import {fetchEstimatesGroupedByPlayers, fetchEstimatesList, updateModal} from '@slices/skill';
import moment from 'moment';
import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

export const useModal = (modalType) => {
  const {isOpen, type, data, isLoading} = useSelector(selectModal);
  const dispatch = useDispatch();
  const isShow = useMemo(() => {
    if (modalType) {
      return modalType === type && isOpen;
    }
    return isOpen;
  }, [modalType, isOpen]);

  const open = useEvent((payload) => {
    dispatch(updateModal({isOpen: true, data: payload, type: modalType}));
  });

  const close = useEvent(() => {
    dispatch(updateModal({isOpen: false, type: modalType, isLoading: false}));
  });

  const startLoading = useEvent(() => {
    dispatch(updateModal({isLoading: true}));
  });

  const stopLoading = useEvent(() => {
    dispatch(updateModal({isLoading: false}));
  });

  return {
    isOpen: isShow,
    type,
    data,
    isLoading,
    open,
    close,
    startLoading,
    stopLoading,
  };
};

const formatDataBeforeMutation = (data) => {
  const {student, skill, specialists, controlDate, ...rest} = data;
  const playerId = student.id;
  const skillId = skill.id;
  const specialistsIds = specialists ? specialists.map((specialist) => specialist.id || specialist?.value) : [];
  const date = moment(controlDate).format('YYYY-MM-DD');
  const isCompleted = Boolean(data?.completedScore);
  const payload = {
    ...rest,
    playerId,
    skillId,
    specialistsIds,
    controlDate: date,
    isCompleted,
  };
  return payload;
};

export const useEstimateMutation = () => {
  const {startLoading, stopLoading, close, data} = useModal();
  const filters = useSelector(selectFilters({entityName: 'players'}));

  const dispatch = useDispatch();

  const update = useEvent(async (data) => {
    startLoading();
    const formattedData = formatDataBeforeMutation(data);
    try {
      await updateEstimate(data.id, formattedData);
      dispatch(fetchEstimatesList({playersIds: [data.student.id]}));
      close();
    } finally {
      stopLoading(false);
    }
  });

  const create = useEvent(async (data) => {
    const groupsIds = [filters?.group?.value];
    const categoriesIds = filters?.categories.map((category) => category.id);
    const specialistsIds = filters?.specialists?.map((specialist) => specialist.value);
    const formattedData = formatDataBeforeMutation(data);
    startLoading();
    try {
      await createEstimate(formattedData);
      dispatch(fetchEstimatesGroupedByPlayers({groupsIds, categoriesIds, specialistsIds, isCompleted: false}));
      close();
    } finally {
      stopLoading();
    }
  });

  const remove = useEvent(async () => {
    startLoading();
    try {
      await deleteEstimate(data.id);
      dispatch(fetchEstimatesList({playersIds: [data.student.id]}));
      close();
    } finally {
      stopLoading();
    }
  });

  return {
    update,
    create,
    remove,
  };
};
