import {Divider} from '@mui/material';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import {bool, func, number, shape, string} from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled(Stack)`
  flex: ${(props) => props.fullWidth && '1'};
  background-color: #e3e3e3;
  border-radius: 12px;
  padding: 6px 8px;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  padding-left: 6px;
  padding-top: 4px;
`;

const IconWrapper = styled.div`
  & .MuiSvgIcon-root {
    cursor: pointer;
    font-size: 20px;
  }
`;

const Skill = ({fullWidth, className, onClick, name, section, category, number, endIcon}) => {
  return (
    <Container className={className} direction="row" fullWidth={fullWidth} spacing={2} onClick={onClick}>
      <Stack flex="1" spacing="4px">
        <Content>
          <TypographyDefault mr={1} variant="subtitle2">
            {number}
          </TypographyDefault>
          <TypographyDefault variant="body2">{name}</TypographyDefault>
        </Content>
        <Divider />
        <Content>
          <TypographyDefault variant="caption1">
            {section?.name || 'Отсутствует'};{category?.name || 'Отсутствует'}
          </TypographyDefault>
        </Content>
      </Stack>
      <IconWrapper>{endIcon}</IconWrapper>
    </Container>
  );
};

Skill.propTypes = {
  fullWidth: bool,
  className: string,
  onClick: func,
  name: string.isRequired,
  section: shape({name: string, id: string}).isRequired,
  category: shape({name: string, id: string}).isRequired,
  number: number.isRequired,
};

export default Skill;
