import {getMatchgoaltendersinfo, getStatisticsFilesList, getStatsForOneGame, getStatsMatchShifts} from '@api/StatsAPI';
import {NOTIFICATION_TYPES} from '@src/constants/config';
import {ReactComponentNotification} from '@ui/ReactComponent/ReactComponentNotification/ReactComponentNotification';

import {
  CLEAR_STATISTICS_FILES_LIST,
  CLEAR_STATS_MATCH_GOALTENDER_INFO,
  LOAD_STATISTICS_FILES_LIST,
  LOAD_STATISTICS_FILES_LIST_ERROR,
  LOAD_STATISTICS_FILES_LIST_SUCCESS,
  LOAD_STATS_ERROR,
  LOAD_STATS_FOR_ONE_GAME,
  LOAD_STATS_FOR_ONE_GAME_SUCCESS,
  LOAD_STATS_MATCH_GOALTENDER_INFO,
  LOAD_STATS_MATCH_GOALTENDER_INFO_ERROR,
  LOAD_STATS_MATCH_GOALTENDER_INFO_SUCCESS,
  LOAD_STATS_MATCH_SHIFTS,
  LOAD_STATS_MATCH_SHIFTS_ERROR,
  LOAD_STATS_MATCH_SHIFTS_SUCCESS,
} from '../ActionTypes';

const errorMessage = (err, title = 'Ошибка при загрузке', type) => {
  if (err?.response?.data?.message) {
    ReactComponentNotification(type || NOTIFICATION_TYPES['error'], `${title}: ${err.response.data.message}`);
  } else {
    ReactComponentNotification(NOTIFICATION_TYPES['error'], 'Ошибка при взаимодействии с сервером');
  }
};

export function loadStatsForOneGame(params) {
  return (dispatch) => {
    dispatch({
      type: LOAD_STATS_FOR_ONE_GAME,
    });
    return getStatsForOneGame(params)
      .then((stats) => {
        dispatch({
          type: LOAD_STATS_FOR_ONE_GAME_SUCCESS,
          gameStatistics: stats.data,
        });
      })
      .catch((err) => {
        errorMessage(err, 'Ошибка при загрузке');
        dispatch({
          type: LOAD_STATS_ERROR,
          payload: err,
        });
      });
  };
}

export function loadStatsMatchShifts(params) {
  return (dispatch) => {
    dispatch({
      type: LOAD_STATS_MATCH_SHIFTS,
    });
    return getStatsMatchShifts(params)
      .then((res) => {
        dispatch({
          type: LOAD_STATS_MATCH_SHIFTS_SUCCESS,
          matchShifts: {
            ...res.data,
            players: res.data?.players || [],
          },
        });
      })
      .catch((err) => {
        errorMessage(err, 'Ошибка при загрузке');
        dispatch({
          type: LOAD_STATS_MATCH_SHIFTS_ERROR,
          payload: err,
        });
      });
  };
}

export function clearMatchGoaltenderInfo() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_STATS_MATCH_GOALTENDER_INFO,
    });
  };
}

export function loadMatchGoaltenderInfo(params) {
  return (dispatch) => {
    dispatch({
      type: LOAD_STATS_MATCH_GOALTENDER_INFO,
    });
    return getMatchgoaltendersinfo(params)
      .then((res) => {
        dispatch({
          type: LOAD_STATS_MATCH_GOALTENDER_INFO_SUCCESS,
          payload: res.data,
        });
      })
      .catch(() => {
        errorMessage({}, 'Ошибка при загрузке');
        dispatch({
          type: LOAD_STATS_MATCH_GOALTENDER_INFO_ERROR,
        });
      });
  };
}

export function loadStatisticsFilesList(params) {
  return (dispatch) => {
    dispatch({
      type: LOAD_STATISTICS_FILES_LIST,
    });
    return getStatisticsFilesList(params)
      .then((res) => {
        dispatch({
          type: LOAD_STATISTICS_FILES_LIST_SUCCESS,
          filesList: res.data,
        });
      })
      .catch((err) => {
        errorMessage(err, 'Ошибка при загрузке');
        dispatch({
          type: LOAD_STATISTICS_FILES_LIST_ERROR,
          payload: err,
        });
      });
  };
}

export function clearStatisticsFilesList() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_STATISTICS_FILES_LIST,
    });
  };
}
