import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const TVScheduleAPI = {
  getTVScheduleForRoom(roomId) {
    return axios.get(`${API_ENDPOINT}/schedule/room/${roomId}`);
  },
  getTVScheduleForHall() {
    return axios.get(`${API_ENDPOINT}/schedule/hall`);
  },
};

export default TVScheduleAPI;
