import * as schoolsActions from '@actions/SchoolsActions';
import {loadAllCitizenships, loadAllCountries} from '@actions/UsersActions';
import * as usersActions from '@actions/UsersActions';
import UsersAPI from '@api/UsersAPI';
import FullHeightWrapper from '@common/FullHeightWrapper';
import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import {generateUserFields} from '@components/apps/PlayerPersonalFile/helpers';
import usePersonalDataFields from '@components/apps/PlayerPersonalFile/hooks/usePersonalDataFields';
import {
  DOCTOR_STATUSES_OPTIONS,
  HANDS_OPTIONS,
  HOCKEY_ROLES_OPTIONS,
  STATUSES_OPTIONS,
} from '@components/students/constants';
import CommonHelper from '@helpers/CommonHelper';
import date from '@helpers/date';
import {shallowEqual} from '@helpers/shallowEqual';
import useDeepEffect from '@hooks/useDeepEffect';
import useDeepMemo from '@hooks/useDeepMemo';
import useElementSize from '@hooks/useElementSize';
import {useRoleAccess} from '@hooks/useRoleAccess';
import useStore from '@hooks/useStore';
import EditIcon from '@mui/icons-material/Edit';
import {userRoles} from '@src/constants';
import {parseFormToGetValue} from '@src/helpers';
import {Box, Button, IconButton, Stack, Typography} from '@ui/MUI';
import Avatar from '@ui/MUI/Avatar';
import {grey} from '@ui/MUI/colors';
import {get, isEmpty} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';

import ArchiveModal from './components/ArchiveModal';
import CropModal from './components/CropModal';
import ProfileInfoItems from './components/ProfileInfoItems';

const findOptionByValue = (options, value) => options?.find((item) => item?.value === value);
const resetUserObj = (student, citizenships, countries) => {
  const {hockeyRole, status, hand, citizenshipId, countryId} = student;
  return {
    ...student,
    hockeyRole: findOptionByValue(HOCKEY_ROLES_OPTIONS, hockeyRole),
    status: findOptionByValue([...STATUSES_OPTIONS, ...DOCTOR_STATUSES_OPTIONS], status),
    hand: findOptionByValue(HANDS_OPTIONS, hand),
    citizenshipId: findOptionByValue(CommonHelper.getDropDownItemsByNamedList(citizenships), citizenshipId),
    countryId: findOptionByValue(CommonHelper.getDropDownItemsByNamedList(countries), countryId),
    password: '',
    age: date().diff(date(student?.birthdayTimestamp).innerDate, 'years') || 0,
  };
};

function OtherTab() {
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [cropPhotoOpen, setCropPhotoOpen] = useState(false);
  const {student, citizenships, countries} = useStore((state) => state.users, shallowEqual);
  const formFields = generateUserFields(true, student?.status);
  const formFieldsArray = [
    ...formFields.personalDataFields,
    ...formFields.teamDataFields,
    ...formFields.firstRepresentativeFields,
    ...formFields.secondRepresentativeFields,
  ];
  const [buttonsRef, {height}] = useElementSize();
  const {avatar, active} = useDeepMemo(() => student, [student]);
  const [isActive, setIsActive] = useState(active);
  const methods = useForm();
  const {reset, handleSubmit} = methods;

  const {
    isEdit,
    setIsEdit,
    fields: {personalDataFields, teamDataFields, firstRepresentativeFields, secondRepresentativeFields},
  } = usePersonalDataFields();

  const canAddToArchive = useRoleAccess([userRoles.admin]);

  const dispatch = useDispatch();
  const resetToInitialValues = useCallback(() => {
    if (!isEmpty(student)) {
      reset(resetUserObj(student, citizenships, countries));
    }
  }, [student, citizenships, countries]);

  useEffect(() => {
    dispatch(loadAllCitizenships());
    dispatch(loadAllCountries());
    dispatch(schoolsActions.loadAllSchools());
  }, []);

  useDeepEffect(resetToInitialValues, [student, citizenships, countries]);
  const onSubmit = (data) => {
    const fields = {};
    formFieldsArray.forEach(({name, payloadName}) => (fields[payloadName || name] = get(data, name)));
    const result = {...fields, ...parseFormToGetValue(fields)};
    dispatch(
      usersActions.updateStudent(
        {
          ...result,
          avatar: data.avatar,
          id: student?.id || student._id,
          firstName: result?.firstName?.trim(),
          lastName: result?.lastName?.trim(),
          middleName: result?.middleName?.trim() || '',
        },
        (student) => {
          reset(resetUserObj(student, citizenships, countries));
          setIsEdit(false);
        },
      ),
    );
  };
  const onAddToArchive = (id) => {
    UsersAPI.addStudentToArchive(id).then(({data}) => {
      setIsActive(data.active);
      setIsEdit(false);
    });
  };
  const onRemoveFromArchive = (id) => {
    UsersAPI.removeStudentFromArchive(id).then(({data}) => {
      setIsActive(data.active);
      setIsEdit(false);
    });
  };
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
          setIsEdit(false);
          return data;
        })}
      >
        <Wrapper p="0">
          <FullHeightWrapper>
            <Box height="100%" overflow="auto" p={4} pb={height ? height + 'px' : ''} position="relative" width="100%">
              <Stack alignItems="flex-start" direction="row" gap={1} justifyContent="space-between" mb={4} width="100%">
                <Typography variant="h3">Личные данные</Typography>
                {canAddToArchive && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => (!isEdit ? setIsEdit(true) : setArchiveModalOpen(true))}
                  >
                    {isEdit ? (isActive ? 'Архивировать' : 'Разархивировать') : 'Редактировать'}
                  </Button>
                )}
              </Stack>
              <Box sx={{mb: 4, position: 'relative', width: 'fit-content'}}>
                <Avatar src={avatar} width={100} />
                {isEdit && (
                  <EditAvatarButton onClick={() => setCropPhotoOpen(true)}>
                    <EditIcon />
                  </EditAvatarButton>
                )}
              </Box>
              <ProfileInfoItems fields={personalDataFields} isEdit={isEdit} />
              <Typography mb={4} variant="h3">
                Команда и игровые данные
              </Typography>
              <ProfileInfoItems fields={teamDataFields} isEdit={isEdit} />
              <Typography mb={4} variant="h3">
                Контактная информация и представители
              </Typography>
              <ProfileInfoItems fields={firstRepresentativeFields} isEdit={isEdit} mb={3} />
              <ProfileInfoItems fields={secondRepresentativeFields} isEdit={isEdit} mb="unset" />
            </Box>
            {isEdit && (
              <ButtonsWrapper direction="row" gap={1} px={4} py={3} ref={buttonsRef}>
                <Button color="primary" type="submit" variant="contained">
                  Сохранить
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    reset(resetUserObj(student, citizenships, countries));
                    setIsEdit(false);
                  }}
                >
                  Отменить
                </Button>
              </ButtonsWrapper>
            )}
          </FullHeightWrapper>
        </Wrapper>
        <ArchiveModal
          handleSubmit={() => {
            if (isActive) {
              onAddToArchive(student?.id);
            } else {
              onRemoveFromArchive(student?.id);
            }
            setArchiveModalOpen(false);
          }}
          isOpen={archiveModalOpen}
          isPlayerActive={isActive}
          setIsOpen={setArchiveModalOpen}
        />
        <Controller
          name="avatar"
          render={({field: {onChange, value}}) => (
            <CropModal cropModalState={cropPhotoOpen} value={value} onChange={onChange} onClose={setCropPhotoOpen} />
          )}
        />
      </form>
    </FormProvider>
  );
}

export default OtherTab;

const ButtonsWrapper = styled(Stack)`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -4px 14px 0px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fff;
  z-index: 1;
`;

const EditAvatarButton = styled(IconButton)`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: ${grey['8']};
  &:hover {
    background-color: ${grey['8']};
  }
`;
