import ContentWrapper from '@common/ContentWrapper';
import ThrowsDonutCharts from '@common/Statistics/ThrowsMap/ThrowsDonutCharts';
import {useMatches} from '@components/apps/StatisticsWithMkcUral/hooks/useMatches';
import useDeepMemo from '@hooks/useDeepMemo';
import useStore from '@hooks/useStore';
import {selectMetricStatistics, selectShotsMapFilters} from '@selectors/gameStatisticsMkc';
import {clearMetricStatistics, loadMetricStatistics} from '@slices/statisticsMKC/shotsMap';
import {parseFormToGetValue} from '@src/helpers';
import PropTypes from 'prop-types';
import React from 'react';

DonutCharts.propTypes = {
  weAtHome: PropTypes.bool,
};

function DonutCharts({weAtHome = true}) {
  const {statisticIds, isMatchesLoading} = useMatches();
  const {playerIds, ...filterValues} = useStore(selectShotsMapFilters);
  const filters = useDeepMemo(() => parseFormToGetValue(filterValues), [filterValues]);
  return (
    <ContentWrapper position="relative">
      <ThrowsDonutCharts
        cleanup={clearMetricStatistics}
        filters={{...filters, statisticsIds: statisticIds}}
        getter={selectMetricStatistics}
        isLoading={isMatchesLoading}
        loader={loadMetricStatistics}
        weAtHome={weAtHome}
      />
    </ContentWrapper>
  );
}

export default DonutCharts;
