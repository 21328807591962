import {cardStylesBySectionType, maxItemsLimit, sectionGaps} from '@components/apps/MaterialsModule/constants';
import {useCurrentBreakPoint} from '@components/apps/MaterialsModule/hooks';
import Skeleton from '@ui/MUI/Skeleton';
import Stack from '@ui/MUI/Stack';
import React from 'react';

const LoadingSkeleton = () => {
  const names = ['links_and_files', 'images', 'video'];
  const breakPoint = useCurrentBreakPoint();
  return (
    <Stack spacing={8}>
      {names.map((name) => {
        const skeletons = new Array(maxItemsLimit[name][breakPoint] || 5).fill(1);
        const width = cardStylesBySectionType[name].width;
        const height = cardStylesBySectionType[name].height;
        return (
          <Stack key={name} spacing={4}>
            <Skeleton height={24} variant="rounded" width={200} />
            <Stack direction="row" flexWrap="wrap" gap={sectionGaps[name]}>
              {skeletons.map((_, index) => {
                return <Skeleton height={height} key={index} variant="rounded" width={width} />;
              })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default LoadingSkeleton;
