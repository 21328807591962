import React from 'react';

const Dumbbell = () => {
  return (
    <>
      <g>
        <path
          d="M19.6508 6.14373L17.9969 4.4899L19.2402 3.24713C19.3501 3.13732 19.4119 2.98853 19.4119 2.83291C19.4119 2.67728 19.3501 2.52849 19.2402 2.41865L17.5757 0.754113C17.3559 0.534386 16.9668 0.534386 16.7471 0.754113L15.5043 1.99736L13.8503 0.343214C13.3927 -0.114405 12.6507 -0.114405 12.193 0.343214C11.7353 0.800832 11.7353 1.54283 12.193 2.00048L17.9934 7.801C18.4511 8.25862 19.1931 8.25862 19.6508 7.801C20.1084 7.34338 20.1084 6.60139 19.6508 6.14373Z"
          fill="#686868"
        />
        <path
          d="M7.80707 17.9996L2.00662 12.199C1.54893 11.7414 0.807013 11.7414 0.349317 12.199C-0.108301 12.6567 -0.108301 13.3987 0.349317 13.8564L2.00342 15.5105L0.762599 16.7508C0.652716 16.8606 0.590919 17.0094 0.590919 17.165C0.590919 17.3206 0.652716 17.4694 0.762599 17.5793L2.42713 19.2433C2.54155 19.3578 2.69147 19.415 2.84139 19.415C2.99131 19.415 3.14124 19.3578 3.25565 19.2433L4.49597 18.003L6.1498 19.6569C6.60742 20.1145 7.34941 20.1145 7.80707 19.6569C8.26476 19.1992 8.26476 18.4572 7.80707 17.9996Z"
          fill="#686868"
        />
        <path d="M10.5357 6.97241L6.97842 10.5418L9.46437 13.0277L13.0216 9.45828L10.5357 6.97241Z" fill="#686868" />
        <path
          d="M17.9934 9.45823L10.5357 2.00044C10.078 1.54282 9.33601 1.54282 8.8784 2.00044C8.4207 2.45814 8.4207 3.20005 8.8784 3.65775L16.3362 11.1155C16.7938 11.5732 17.5358 11.5732 17.9934 11.1155C18.4511 10.6579 18.4511 9.91592 17.9934 9.45823Z"
          fill="#686868"
        />
        <path
          d="M11.1217 16.3422L3.66383 8.88435C3.20621 8.42673 2.46422 8.42673 2.0066 8.88435C1.54891 9.34205 1.54891 10.084 2.0066 10.5417L9.46439 17.9994C9.92201 18.457 10.664 18.457 11.1217 17.9994C11.5794 17.5418 11.5794 16.7998 11.1217 16.3422Z"
          fill="#686868"
        />
      </g>
    </>
  );
};

export default Dumbbell;
