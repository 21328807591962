import React from 'react';

import PlayerImage from '../../../../../assets/images/login_cover_hockey.jpg';
import HeadImage from '../../../../../assets/images/logo-head.svg';
import {Image, ImageContainer, LogoHead} from './ImageWrapper';

const ImageWrapper = () => {
  return (
    <ImageContainer>
      <Image src={PlayerImage} />
      <LogoHead src={HeadImage} />
    </ImageContainer>
  );
};

export default ImageWrapper;
