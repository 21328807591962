export const haveValue = (propertyToFind, roles = [], propertyToCompare = null) => {
  return roles.some((role) => (role[propertyToCompare] || role.value) === propertyToFind);
};

export const getIds = (array) => array?.map((item) => item.id).filter((item) => item) || [];
export const getSlugs = (array) => array?.map((item) => item.slug).filter((item) => item) || [];
export const getValues = (array) => array?.map((item) => item.value).filter((item) => item) || [];
export const getNames = (array) => array?.map((item) => item.name) || [];
export const getSections = (array) => array;

export const isOptionEqualToValue = (option, value) => option.id === value.id;
export const getOptionLabel = (option) => option.name;
export const isRoleOptionEqualToValue = (option, value) => option.userRole === value.userRole;
export const isMaterialOptionEqualToValue = (option, value) => option.slug === value.slug;
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 BT';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['BT', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
