import {getRoleLabel} from '@helpers/roles';
import {IS_PRODUCTION, longDash} from '@src/constants';

export const handleInputNumberChange = (e, callback = () => {}, min = 0) => {
  let value = e?.target?.value.replace(/[^0-9]/g, '');
  if (value && Number(value) < min) value = min;
  return callback(value ? Number(value) : '');
};

/**
 * функция для преобразования inputValue в float
 * Указать следующие пропы для TextFieldDefault
 * type="number"
 * inputProps={{ step: 0.01, min: 0.01, max: 999.99 }}
 */
export const onFloatChange = (e, dightsCount = 2) => {
  const value = e?.target.value;
  const max = Number(e?.target?.max) || 0;
  const min = Number(e?.target?.min) || 0;
  if (!value) return value;
  if (Number(value) < min) return min;
  if (Number(value) > max) return max;
  return parseFloat(Number(value)?.toFixed(dightsCount)) || min;
};

/**
 * Если остаток от деления на 100 равен от 10 до 20, то 'касс'
 * Если остаток от деления на 10 равен 1, то 'касса'
 * Если остаток от деления на 10 равен 2-4, то 'кассы'
 * Оставщиеся случаи 'касс'
 * @param number
 * @param titles ['касса', 'кассы', 'касс']
 * @returns {*}
 */
export function pluralize(number, titles) {
  const num = Number(number);
  const decl1 = 1;
  const decl2x4 = 4;
  const titleOther = 2;
  const ten = 10;
  if (num % (ten * ten) >= ten && num % (ten * ten) < ten + ten) return titles[titleOther];
  if (num % ten === decl1) return titles[0];
  if (num % ten > decl1 && num % ten <= decl2x4) return titles[1];
  return titles[titleOther];
}

/**
 *
 * @param {Array<{name?: string, slug?: string, id?: string}>} options
 */
export const getNormalizedOptions = (options) => {
  return options?.map((option) => ({
    label: option.name,
    value: option?.id || option?.slug || option.userRole || option._id,
  }));
};

export const parseFormToGetValue = (object) => {
  return Object.keys(object).reduce((prev, key) => {
    const item = object[key];
    if (item || typeof item === 'boolean') {
      if (Array.isArray(item)) {
        return {...prev, [key]: item.map((v) => v?.value || v?.id || v)};
      }

      if (!item?.value && !item?.id) {
        return {...prev, [key]: item};
      }

      return {...prev, [key]: item.value || item.id};
    }
    return prev;
  }, {});
};

export const parseToRequest = (object) => {
  const values = parseFormToGetValue(object);
  return Object.keys(values).reduce((prev, key) => {
    const item = values[key];
    if (Array.isArray(item) && item?.length) {
      return {...prev, [key]: item.join(',')};
    }
    return {...prev, [key]: item};
  }, {});
};

export const bytesToMb = (bytes) => {
  return bytes / (1024 * 1024);
};

export const convertImgTo200 = (longPath) => {
  if (!longPath || longPath?.includes('englishpatient')) return '';
  return longPath?.replace('storage/', 'storage/200_') || longPath;
};

export const customTableTimeComparator = (rowA, rowB, columnId) => {
  const getSeconds = (value) => Number(value?.split(':')?.[0]) * 60 + Number(value?.split(':')[1]);

  const a = typeof rowA.getValue(columnId) === 'number' ? rowA.getValue(columnId) : rowA.getValue(columnId)?.value;
  const b = typeof rowB.getValue(columnId) === 'number' ? rowB.getValue(columnId) : rowB.getValue(columnId)?.value;
  if (!a) return 0;
  if (getSeconds(a) < getSeconds(b)) return -1;
  if (getSeconds(a) > getSeconds(b)) return 1;
};

export const defaultSort = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const customTableComparator = (rowA, rowB, columnId) => {
  const a = typeof rowA.getValue(columnId) === 'number' ? rowA.getValue(columnId) : rowA.getValue(columnId)?.value;
  const b = typeof rowB.getValue(columnId) === 'number' ? rowB.getValue(columnId) : rowB.getValue(columnId)?.value;
  if (!a && a !== 0) return 0;
  return a < b ? 1 : -1;
};

/**
 * Функция вывода заглушки если числовое значение отсутсвует
 * @param value
 * @param stub заглушка при отсутствии значения
 * @returns longDash || number
 */
export const noDataNumberValue = (value, stub = longDash) => {
  if (value !== null && (value >= 0 || value <= 0)) return value;
  return stub;
};

export const setSentryUser = (event) => {
  const user = JSON.parse(localStorage.getItem(`Parse/${process.env.REACT_APP_PARSE_APP_ID}/currentUser`));
  if ((user?.email || user?.userRole) && IS_PRODUCTION) {
    const userRole = getRoleLabel(user?.userRole, user?.teacherRole, '');
    event.tags.email = user?.email;
    event.tags.userRole = userRole;
  }
};

export const validateUrl = (url = '') => {
  // eslint-disable-next-line no-useless-escape
  return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
    url,
  );
};
