import ActionText from '@common/ActionText';
import IconButtonGroup from '@common/IconButtonGroup';
import {useRoleAccess} from '@hooks/useRoleAccess';
import useToggle from '@hooks/useToggle';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Stack, useMediaQuery} from '@mui/material';
import {userRoles} from '@src/constants';
import Box from '@ui/MUI/Box';
import Divider from '@ui/MUI/Divider';
import React from 'react';

import AdditionalInfoBlock from './components/AdditionalInfoBlock';
import BottomBlock from './components/BottomBlock';
import FormBlock from './components/FormBlock';
import MainInfoBlock from './components/MainInfoBlock';
import NumberBlock from './components/NumberBlock';
import ScoreBlock from './components/ScoreBlock';
import SkillsBlock from './components/SkillsBlock';

const ExerciseItem = ({
  border = '1px solid #0000003D',
  name,
  age,
  theme,
  skills,
  number,
  time,
  repeat,
  highlights,
  description,
  isExpendable = false,
  onEdit,
  onDelete,
  score,
  goal,
  schemesFile,
  videoFile,
  author,
  flex = '1',
  p = 4,
  createdAt,
  isForm,
  index,
}) => {
  const toggle = useToggle({initialState: !isExpendable});

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const canEdit = useRoleAccess([userRoles?.teacher, userRoles?.methodist]);

  return (
    <Stack direction="row" flex={flex} spacing={{sm: 4, xs: 4, xxs: 2}}>
      <Box border={border} borderRadius={2} display="flex" flex={flex}>
        {!isMobile && number && <NumberBlock number={number} />}
        <Stack display="flex" flex="1" p={p} spacing={{sm: 4, xs: 4, xxs: 2}}>
          <MainInfoBlock
            age={age}
            author={author}
            createdAt={createdAt}
            name={name}
            repeat={repeat}
            theme={theme}
            time={time}
          />
          {toggle.isToggle && (
            <>
              <Divider />
              <AdditionalInfoBlock description={description} highlights={highlights} />
              <SkillsBlock skills={skills} />
              <BottomBlock schemesFile={schemesFile} videoFile={videoFile} />
            </>
          )}
          {isExpendable && (
            <ActionText
              endIcon={toggle.isToggle ? <ExpandLess /> : <ExpandMore />}
              onClick={toggle.isToggle ? toggle.off : toggle.on}
            >
              {toggle.isToggle ? 'Скрыть детали упражнения' : 'Показать детали упражнения'}
            </ActionText>
          )}
          {(score || goal) && <ScoreBlock goal={goal} score={score} />}
          {isForm && <FormBlock index={index} repeat={repeat} time={time} />}
        </Stack>
      </Box>
      {(onEdit || onDelete) && canEdit && <IconButtonGroup onDelete={() => null} onEdit={() => null} />}
    </Stack>
  );
};

export default ExerciseItem;
