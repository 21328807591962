import {Stack} from '@ui/MUI';
import React, {useMemo} from 'react';
import styled from 'styled-components';

const RadioButton = styled.div`
  padding: 5px 11px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  background: ${(props) => (props.isSelected ? '#990011' : '#e3e3e3')};
  border-radius: 5px;
  color: ${(props) => (props.isSelected ? '#FFFFFF' : '#2C2C2C')};
  cursor: pointer;
`;

const RadioRange = ({max, gap = 1, value, onChange}) => {
  const buttons = useMemo(() => {
    return [...Array(max).keys()].map((key) => key + 1);
  }, []);

  const handleClick = (number) => {
    onChange && onChange(number);
  };

  return (
    <Stack direction="row" gap={gap}>
      {buttons.map((number) => (
        <RadioButton isSelected={number === value} key={number} onClick={() => handleClick(number)}>
          {number}
        </RadioButton>
      ))}
    </Stack>
  );
};

export default RadioRange;
