import LocalPreloader from '@components/preloader/LocalPreloader';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectPlayersTables} from '@selectors/gameStatisticsMkc';
import {loadPlayersTables} from '@slices/statisticsMKC';
import {HOCKEY_ROLES_ENUM} from '@src/constants';
import {Box, Divider, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import TabsNew from '@ui/MUI/TabsNew';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import {shallowEqual} from 'react-redux';

import PlayersTable from '../PlayersTable';
import Wrapper from '../Wrapper';

PlayersTableWrapper.propTypes = {
  hockeyRole: PropTypes.string,
  accentColors: PropTypes.array.isRequired,
  wrapperProps: PropTypes.object,
  teamMKCId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  reverseAccentIndication: PropTypes.bool,
  isFinal: PropTypes.bool,
  season: PropTypes.string,
};
const TITLES = {
  [HOCKEY_ROLES_ENUM.winger]: 'Нападающие',
  [HOCKEY_ROLES_ENUM.defenseman]: 'Защитники',
  [HOCKEY_ROLES_ENUM.goaltender]: 'Вратари',
};

function PlayersTableWrapper({hockeyRole, wrapperProps, accentColors, teamMKCId, isFinal, season, ...props}) {
  const dispatch = useStoreDispatch();
  const {
    [hockeyRole]: {isLoading, isLoad, error, data},
  } = useStore(selectPlayersTables, shallowEqual);
  useEffect(() => {
    teamMKCId && dispatch(loadPlayersTables({teamMKCId, playersBlockType: hockeyRole, isFinal, season}, hockeyRole));
  }, [hockeyRole, teamMKCId, isFinal, season]);

  const tabs = useMemo(() => {
    const TABS = {
      [HOCKEY_ROLES_ENUM.winger]: [
        {
          label: 'Очки',
          value: <PlayersTable accentColors={accentColors} players={data?.score} />,
        },
        {
          label: 'Голы',
          value: <PlayersTable accentColors={accentColors} players={data?.goals} />,
        },
        {
          label: 'Пасы',
          value: <PlayersTable accentColors={accentColors} players={data?.assists} />,
        },
        {
          label: '+/-',
          value: <PlayersTable accentColors={accentColors} players={data?.plusMinus} />,
        },
      ],

      [HOCKEY_ROLES_ENUM.goaltender]: [
        {
          label: '%ОБ',
          value: <PlayersTable accentColors={accentColors} players={data?.savePercent} />,
        },
        {
          label: 'КН',
          value: <PlayersTable accentColors={accentColors} players={data?.safetyFactor} />,
        },
      ],
    };
    TABS[HOCKEY_ROLES_ENUM.defenseman] = [...TABS[HOCKEY_ROLES_ENUM.winger]];
    return TABS[hockeyRole];
  }, [accentColors, hockeyRole, data]);
  return (
    <Wrapper height="100%" p="0" {...wrapperProps}>
      <Typography p={{xxs: 2, sm: 3}} variant="h4">
        {TITLES[hockeyRole]}
      </Typography>
      <Divider sx={{color: grey['14']}} />
      <>
        {props?.isLoading || isLoading ? (
          <Box height={300} maxWidth={400} position="relative" width="100%">
            <LocalPreloader visible />
          </Box>
        ) : isLoad && error ? (
          <Stack
            alignItems="center"
            height="100%"
            justifyContent="center"
            maxWidth={400}
            minHeight={300}
            position="relative"
            width="100%"
          >
            <Typography variant="body1">Нет данных по игрокам</Typography>
          </Stack>
        ) : (
          <TabsNew tabs={tabs} tabsWrapperProps={{boxShadow: 'none'}} />
        )}
      </>
    </Wrapper>
  );
}

export default React.memo(PlayersTableWrapper);
