import {Radio as RadioButton} from '@mui/material';
import {RadioProps} from '@mui/material/Radio/Radio';
import React, {forwardRef} from 'react';

function Radio(
  {...props}: RadioProps,
  ref: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined,
) {
  return <RadioButton ref={ref} {...props} />;
}

export default forwardRef(Radio);
