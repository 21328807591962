import {Box, Typography} from '@ui/MUI';
import {grey, primary} from '@ui/MUI/colors';
import styled, {css} from 'styled-components';

export const ProgressBar = styled(Box)`
  height: 16px;
  width: 100%;
  border-radius: 0 6px 6px 0;
  background-color: ${grey[24]};
  position: relative;
  transition: 0.3s ease-in-out;
  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: ${({percent}) => (percent * 10 || 0) + '%'};
    background-color: ${({theme}) => theme.palette.primary.main};
    max-width: 100%;
    border-radius: inherit;
    transition: 0.3s ease-in-out;
  }
`;

export const ProgressIndicator = styled(Box)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  position: absolute;
  top: 50%;
  left: ${({percent}) => (percent * 10 || 0) + '%'};
  transform: translate(-50%, -50%);
  filter: drop-shadow(2px 0px 6px rgba(0, 0, 0, 0.25));
  transition: 0.3s ease-in-out;
  &::before {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    background-color: #fff;
  }
`;

export const ProgressNumber = styled(Typography)`
  padding: ${({theme}) => theme.spacing(0, 1)};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({current, expected}) => {
    if (current) {
      return css`
        border: 1px solid ${grey['24']};
      `;
    }
    if (expected) {
      return css`
        background-color: ${primary.main};
        color: #fff;
      `;
    }
  }}
`;
