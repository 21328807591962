import useEvent from '@hooks/useEvent';
import {useMediaQuery, useTheme} from '@mui/material';
import {
  fetchMaterial,
  fetchMaterials,
  fetchMaterialSection,
  fetchMaterialTabs,
  fetchMaterialTypes,
  getMaterialPreview,
  getMaterialPreviews,
  getMaterials,
  getMaterialsFormState,
  getMaterialsSection,
  getMaterialsTypes,
  getMaterialTabs,
  loadedMaterialSection,
  updatePreviewData,
} from '@slices/materials';
import {useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const useMaterialSection = () => {
  const section = useSelector(getMaterialsSection, shallowEqual);
  const dispatch = useDispatch();
  const params = useParams();

  const refetch = useEvent((payload) => {
    dispatch(fetchMaterialSection(payload, params.type));
  });

  const clear = useEvent(() => {
    dispatch(loadedMaterialSection({data: {}}));
  });

  return {...section, refetch, clear};
};

export const useMaterialsForm = () => {
  const materialsFormState = useSelector(getMaterialsFormState, shallowEqual);

  return materialsFormState;
};

export const useMaterials = () => {
  const materials = useSelector(getMaterials, shallowEqual);
  const dispatch = useDispatch();
  const refetch = useEvent((filters) => {
    dispatch(fetchMaterials(filters));
  });

  return {...materials, refetch};
};
export const useMaterial = () => {
  const material = useSelector((state) => state.materialsModule.material);
  const dispatch = useDispatch();
  const refetch = useEvent((id) => {
    dispatch(fetchMaterial(id));
  });

  return {...material, refetch};
};
export const useMaterialsTypes = () => {
  const materialTypes = useSelector(getMaterialsTypes, shallowEqual);

  const dispatch = useDispatch();
  const refetch = useEvent(() => {
    dispatch(fetchMaterialTypes());
  });

  return {...materialTypes, refetch};
};

export const useMaterialsPreview = () => {
  const materialPreview = useSelector(getMaterialPreview, shallowEqual);
  const materialPreviews = useSelector(getMaterialPreviews);
  const currentIndex = useMemo(() => {
    return materialPreviews.findIndex((item) => item.id === materialPreview.data?.id);
  }, [materialPreview.data?.id]);

  const dispatch = useDispatch();

  const isNext = useMemo(() => {
    return currentIndex !== materialPreviews.length - 1;
  }, [currentIndex, materialPreviews]);

  const isPrev = useMemo(() => {
    return currentIndex !== 0;
  }, [currentIndex]);

  const goNext = useEvent(() => {
    const copy = {...materialPreviews[currentIndex + 1]};
    dispatch(updatePreviewData(copy));
  });
  const goPrev = useEvent(() => {
    const copy = {...materialPreviews[currentIndex - 1]};
    dispatch(updatePreviewData(copy));
  });

  return {...materialPreview, isNext, isPrev, goNext, goPrev};
};

export const useMaterialTabs = () => {
  const tabs = useSelector(getMaterialTabs, shallowEqual);
  const dispatch = useDispatch();
  const refetch = useEvent(() => {
    dispatch(fetchMaterialTabs());
  });
  return {...tabs, refetch};
};

export const useCurrentBreakPoint = () => {
  const theme = useTheme();
  const xxs = useMediaQuery(theme.breakpoints.up('xxs'));
  const xs = useMediaQuery(theme.breakpoints.up('xs'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const xxl = useMediaQuery(theme.breakpoints.up('xxl'));

  return useMemo(() => {
    if (xxl) return 'xxl';
    if (xl) return 'xl';
    if (lg) return 'lg';
    if (md) return 'md';
    if (sm) return 'sm';
    if (xs) return 'xs';
    if (xxs) return 'xxs';
  }, [xxs, xs, sm, md, lg, xl, xxl]);
};
