import {Theme} from '@mui/material';
import Stack from '@mui/material/Stack';
import MUITablePagination from '@mui/material/TablePagination';
import {TablePaginationProps} from '@mui/material/TablePagination/TablePagination';
import React from 'react';
import styled from 'styled-components';

const CustomizedPagination = styled((props: TablePaginationProps) => (
  <Stack spacing={2}>
    <MUITablePagination {...props} />
  </Stack>
))``;

export default function TablePagination(props: TablePaginationProps) {
  return (
    <Wrapper>
      <CustomizedPagination color="secondary" rowsPerPageOptions={[25, 50, 100]} {...props} />
    </Wrapper>
  );
}

const Wrapper = styled.div<{theme: Theme}>`
  .MuiTablePagination {
    &-root {
      padding: ${({theme}) => theme.spacing(1, '0')};
      font-family: 'Proxima Nova', serif !important;
      color: #2c2c2c;
    }
    &-select,
    &-selectLabel,
    &-displayedRows {
      font-family: 'Proxima Nova', serif !important;
      color: #2c2c2c;
    }
  }
`;
