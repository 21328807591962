export const mock = [...Array(5)].fill({
  id: 'eceEHJeYDK',
  firstName: 'Владимир',
  lastName: 'Корзин',
  avatar: 'https://stars-ural.hawk.ru:8081/sport/images/players/9a33f732934ae01e6ae2cc4201f3d29e.jpg',
  playerNumber: '78',
  cityOfBirth: 'г. Санкт-Петербург ',
  hockeyRole: 'winger',
  hand: 'right',
  status: 'available',
  birthdayTimestamp: 1170266400000,
  avangardId: 61262819,
  shiftDuration: '00:00:31',
  timeOnIce: '00:00:11',
});
