import {Close, FilterNone} from '@mui/icons-material';
import {grey} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React, {useMemo} from 'react';

import {formatBytes} from '../../../../../../../../helpers';
import {FileContainer, FileWrapper} from './File';

const File = ({file, onDelete}) => {
  const convertSize = useMemo(() => formatBytes(file.size), [file.size]);

  return (
    <FileContainer>
      <FileWrapper>
        <FilterNone sx={{fontSize: 16, color: grey[42]}} />
        <Stack ml={3} spacing="2px">
          <TypographyDefault variant="subtitle2">{file.originalName}</TypographyDefault>
          <TypographyDefault color={grey[54]} variant="caption1">
            {convertSize}
          </TypographyDefault>
        </Stack>
      </FileWrapper>
      <Close sx={{fontSize: 16, cursor: 'pointer', color: grey[42]}} onClick={() => onDelete(null)} />
    </FileContainer>
  );
};

export default File;
