import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Stack
          alignItems="center"
          backgroundColor="#fff"
          boderRadius="6px"
          height="100vh"
          justifyContent="center"
          p={2}
        >
          <TypographyDefault textAlign="center" variant="h2">
            Что-то пошло не так.
            <br /> Попробуйте обновить страницу.
          </TypographyDefault>
        </Stack>
      );
    }

    return this.props.children;
  }
}
