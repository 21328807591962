import React from 'react';

function HockeyStickForBasicParams() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M39.1108 13.3623L38.3669 14.744L37.6229 16.1256L36.879 17.5072L32.9466 24.9468L29.1205 32.1739L22.6374 42.3768C22.6374 42.3768 20.5118 44.9275 20.193 44.8212C20.0867 44.7149 19.8742 44.6086 19.7679 44.5024C19.1302 43.971 18.9176 42.9082 19.3428 42.1642L20.0867 40.6763L29.0142 23.9903L32.9466 16.7633L33.478 15.7005L34.222 14.3188L34.9659 12.9372L35.7099 11.5556"
          fill="#A5100E"
        />
        <path
          d="M38.3669 13.3623L37.6229 14.744L36.879 16.1256L36.135 17.6135L32.2026 25.0531L28.3766 32.2802L21.7872 42.3768C21.7872 42.3768 20.5118 43.8647 19.7679 44.5024C19.1302 43.971 18.9176 42.9082 19.3428 42.1642L20.0867 40.6763L29.0142 23.9903L32.9466 16.7633L33.478 15.7005L34.222 14.3188L34.9659 12.9372L35.7099 11.5556"
          fill="#C52E25"
        />
        <path
          d="M32.3091 26.1158L23.169 43.1206C21.7874 45.565 21.1497 46.309 18.8116 46.9467C18.3864 47.053 16.4734 47.3718 16.0483 47.3718L4.14492 48.6472H3.82608C1.70048 48.6472 0 47.4781 0 45.3525C0 45.1399 0 44.9274 0.10628 44.7148C0.42512 42.908 2.01932 41.5264 3.93236 41.5264H17.2174C18.1739 41.5264 19.4492 41.739 20.1932 40.7824L29.1207 24.0965L31.884 25.9032L32.3091 26.1158Z"
          fill="#AD7C5E"
        />
        <path
          d="M31.884 25.797L23.2753 41.8453C21.8937 44.2897 21.256 45.0337 18.9178 45.6714C18.4927 45.7776 16.5797 46.0965 16.1546 46.0965L4.14492 47.3718H3.82608C1.91304 47.3718 0.31884 46.4153 0 44.6086C0.31884 42.8018 1.91304 41.4202 3.82608 41.4202H17.1111C18.0676 41.4202 19.343 41.6327 20.0869 40.6762L29.0144 23.9902L31.884 25.797Z"
          fill="#C2AA88"
        />
        <path d="M37.7291 16.1254L36.8789 17.6134L33.5842 15.7003L34.3282 14.3187L37.7291 16.1254Z" fill="#FECCBD" />
        <path d="M39.1112 13.3623L38.3673 14.744L34.9663 12.9372L35.7103 11.5556L39.1112 13.3623Z" fill="#FECCBD" />
        <path
          d="M15.5167 33.3429C15.5167 34.6183 12.1158 35.7874 7.86456 35.7874C3.61336 35.7874 0.212402 34.7246 0.212402 33.3429C0.212402 32.599 0.212402 29.0917 0.212402 29.0917C1.59404 28.7729 5.95152 31.0048 7.86456 31.0048C9.7776 31.0048 14.2414 28.7729 15.5167 29.0917C15.5167 28.9854 15.5167 32.599 15.5167 33.3429Z"
          fill="#263536"
        />
        <path
          d="M15.5167 28.9853C15.5167 30.2607 12.1158 31.4298 7.86456 31.4298C3.61336 31.4298 0.212402 30.367 0.212402 28.9853C0.212402 27.71 3.61336 26.5409 7.86456 26.5409C12.0095 26.6472 15.5167 27.71 15.5167 28.9853Z"
          fill="#354546"
        />
        <path
          d="M11.4074 28.6669C11.4074 29.092 9.95887 29.4817 8.14817 29.4817C6.33747 29.4817 4.88892 29.1274 4.88892 28.6669C4.88892 28.2417 6.33747 27.8521 8.14817 27.8521C9.9136 27.8875 11.4074 28.2417 11.4074 28.6669Z"
          fill="#EE1122"
        />
      </g>
    </React.Fragment>
  );
}
export default HockeyStickForBasicParams;
