import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const LinesAPI = {
  getLinesForm(request) {
    return axios.get(`${API_ENDPOINT}/players-lines/${request.team}`);
  },
  getTeamFavoritesLinesById(teamId) {
    return axios.get(`${API_ENDPOINT}/players-lines/favorites/${teamId}`);
  },
  getTeamLinesHistoryById(teamId) {
    return axios.get(`${API_ENDPOINT}/players-lines/compositions-history/${teamId}`);
  },
  postLinesForm(form) {
    return axios.post(`${API_ENDPOINT}/players-lines/save-composition`, form);
  },
  updateLinesForm(form) {
    return axios.put(`${API_ENDPOINT}/players-lines`, form);
  },
  getLinesHistoryByPlayer(playerId) {
    return axios.get(`${API_ENDPOINT}/players-lines/player-history/${playerId}`);
  },
  getNearestGame(teamId) {
    return axios.get(`${API_ENDPOINT}/players-lines/nearest-game/${teamId}`);
  },
  deleteLinesFromFavorite(objectId) {
    return axios.delete(`${API_ENDPOINT}/players-lines/${objectId}`);
  },
  setActual(objectId) {
    return axios.post(`${API_ENDPOINT}/players-lines/set-actual/${objectId}`);
  },
  setHistoryActual(objectId) {
    return axios.post(`${API_ENDPOINT}/players-lines/set-actual-history/${objectId}`);
  },
};
export default LinesAPI;
