import {Button} from '@mui/material';
import {userRoles} from '@src/constants';
import ButtonDefault from '@ui/MUI/Button';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import DrawerDefault from '../../../../ui/MUI/Modals/Drawer/Drawer';
import EditModalContent from '../modals/components/EditModalContent';

export default function EditModal(props) {
  const {group, modalState, setModalState, toggleEditModal = () => {}} = props;

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const userRole = useSelector((state) => state.users.currentUser.userRole);

  return (
    <DrawerDefault
      buttons={
        <GroupModalButtonsContainer>
          <ButtonDefault color="primary" form="edit_form" text="Сохранить" type="submit" variant="contained" />

          <ButtonsBlock>
            {![userRoles.video_analyst, userRoles.teacher].includes(userRole) && (
              <Button onClick={() => setConfirmModalOpen(true)}>Удалить</Button>
            )}
            <ButtonDefault
              color="neutral"
              text="Отменить"
              variant="contained"
              onClick={() => {
                toggleEditModal('right', false);
              }}
            />
          </ButtonsBlock>
        </GroupModalButtonsContainer>
      }
      content={
        <EditModalContent
          confirmModalOpen={confirmModalOpen}
          group={group}
          setConfirmModalOpen={setConfirmModalOpen}
          toggleModal={toggleEditModal}
        />
      }
      drawerState={modalState}
      hasFullWidth={false}
      setDrawerState={setModalState}
    />
  );
}

const GroupModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    padding-top: 8px;
    padding-bottom: 8px;
    flex-direction: column;
  }

  & > button {
    &:not(:last-child) {
      @media screen and (max-width: 480px) {
        margin-bottom: 8px;
      }
    }
  }
`;
const ButtonsBlock = styled.div`
  @media screen and (max-width: 480px) {
    display: flex;
    flex-wrap: wrap;
  }
  & > button {
    @media screen and (max-width: 480px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 8px;
    }
    &:first-child {
      @media screen and (min-width: 481px) {
        margin-right: 30px;
      }
    }
  }
`;
