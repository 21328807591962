import CommonHelper from '@helpers/CommonHelper';
import queryString from 'query-string';
import React, {memo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {AMPLUA_OPTIONS, roleOptions} from '../../../constants';
import {ESTIMATION_STAGES_REPORT} from '../../../constants/config';
import useDeepEffect from '../../../hooks/useDeepEffect';
import {UPDATE_FORM_VALUE} from '../../../redux/ActionTypes';
import {Container, Wrapper} from '../styles';
import Filter from './components/Filter';

// переписать все в один обьект {season: {label, options, placeholder ...}}

const labelMap = {
  season: 'Сезон',
  school: 'Школа',
  group: 'Команда',
  student: 'Игрок',
  stage: 'Этап',
  role: 'Роль',
  amplua: 'Амплуа',
};

const optionsMap = {
  stage: ESTIMATION_STAGES_REPORT,
  role: roleOptions,
  amplua: AMPLUA_OPTIONS,
};

const valueMap = {
  stage: ESTIMATION_STAGES_REPORT[0],
};

const Filters = (props) => {
  const {filters, onChange, filtersName, withReduxStore = false, className, apiRef} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const qs = queryString.parse(history.location.search);

  const currentForm = useSelector((state) => withReduxStore && state.form[filtersName]);

  const formatedFilters = React.useMemo(() => {
    if (!filters) return [];
    return filters.map((filter) => {
      const reduxFormValue = currentForm && currentForm[filter.name].value;
      const reduxFormOptions = currentForm && currentForm[filter.name].options;
      const reduxFormIsDisabled = currentForm && currentForm[filter.name].isDisabled;

      const queryStringValue = qs[filter.name];
      const qsFoundedValues =
        filter?.options?.find((item) => queryStringValue === item.value) ||
        optionsMap[filter.name]?.find((item) => queryStringValue === item.value);

      const columns = 12;
      const filtersLength = filters.length;
      const xl = columns / filtersLength;
      const lg = columns / 4;
      const md = columns / 3;
      const sm = columns / 2;
      const xxs = columns / 1;

      const {
        options,
        isLoading,
        isDisabled,
        isVisable = true,
        placeholder,
        label,
        value,
        renderFilter,
        maxValues,
        sizes,
      } = filter;
      return {
        ...filter,
        options: reduxFormOptions || options || optionsMap[filter.name] || [],
        isLoading: isLoading || false,
        isDisabled: reduxFormIsDisabled || isDisabled || false,
        placeholder: placeholder || 'Выберите',
        label: label || labelMap[filter.name],
        value: qsFoundedValues || reduxFormValue || value || valueMap[filter.name] || null,
        renderFilter: renderFilter || null,
        sizes: sizes || {xl, lg, md, xxs, sm},
        isVisable: isVisable,
        maxValues: maxValues,
        qsValue: queryStringValue,
      };
    });
  }, [filters]);

  const updateOptions = (name, options) => {
    if (name === 'group') {
      updateFilters('options', name, CommonHelper.getDropDownTeamsByNamedList(options));
    } else {
      updateFilters('options', name, options);
    }
  };

  const updateLoading = (name, isLoading) => {
    updateFilters('isLoading', name, isLoading);
  };

  const updateValue = (name, value) => {
    updateFilters('value', name, value);
  };

  const updateDisable = (name, isDisable) => {
    updateFilters('isDisabled', name, isDisable);
  };

  const updateVisability = (name, isVisable) => {
    updateFilters('isVisable', name, isVisable);
  };

  const updateName = (name, value) => {
    updateFilters('name', name, value);
  };

  const updateFilters = (whatToChange, name, value) => {
    setCurrentFilters((prevState) => {
      return {
        ...prevState,
        filters: prevState.filters.map((filter) => {
          return filter.name === name ? {...filter, [whatToChange]: value} : filter;
        }),
      };
    });
  };

  const handelChange = (value, action, maxValues) => {
    const {name} = action;
    const isArray = Array.isArray(value);
    if (isArray) {
      if (value.length > maxValues) return;
    }
    updateValue(name, value);
  };

  const [currentFilters, setCurrentFilters] = React.useState({
    filters: formatedFilters,
    updateOptions,
    updateLoading,
    updateValue,
    updateVisability,
    handelChange,
    updateDisable,
  });

  const formatedForRedux = React.useMemo(() => {
    return currentFilters.filters.reduce((prev, current) => {
      return {
        ...prev,
        [current.name]: {
          value: current.value,
          options: current.options,
          isDisabled: current.isDisabled,
        },
      };
    }, {});
  }, [currentFilters.filters]);

  useDeepEffect(() => {
    for (const name of Object.keys(formatedForRedux)) {
      const currentObject = formatedForRedux[name];
      if (onChange) {
        onChange(currentObject.value, name, {
          updateVisability,
          updateValue,
          updateOptions,
          updateLoading,
          updateDisable,
        });
      }
      if (withReduxStore) {
        dispatch({
          type: UPDATE_FORM_VALUE,
          payload: {
            formName: filtersName,
            filterName: name,
            filterOptions: currentObject.options,
            filterValue: currentObject.value,
            filterIsDisabled: currentObject.isDisabled,
          },
        });
      }
    }
  }, [formatedForRedux]);

  useEffect(() => {
    if (apiRef) {
      apiRef.current = {
        updateDisable,
        updateFilters,
        updateLoading,
        updateOptions,
        updateValue,
        updateVisability,
        updateName,
      };
    }
  }, [apiRef]);
  return (
    <Wrapper className={className}>
      <Container className="filters-container">
        {currentFilters.filters.length !== 0 &&
          currentFilters.filters.map((filter, index, self) => {
            return (
              filter.isVisable && (
                <Filter
                  key={filter.name}
                  {...filter}
                  className={props.className || 'current-filter'}
                  filters={self}
                  handelChange={handelChange}
                  updateDisable={updateDisable}
                  updateLoading={updateLoading}
                  updateOptions={updateOptions}
                  updateValue={updateValue}
                  updateVisability={updateVisability}
                />
              )
            );
          })}
      </Container>
    </Wrapper>
  );
};

export default memo(Filters);
