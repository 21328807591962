import useStore from '@hooks/useStore';
import DialogDefault from '@ui/MUI/Modals/Dialog/Dialog';
import Typography from '@ui/MUI/Typography';
import React, {memo} from 'react';
import {useWatch} from 'react-hook-form';

const DeleteConfirm = ({open, close, onConfirm}) => {
  const name = useWatch({name: 'name'});
  const isLoading = useStore((state) => state.materialsModule.dialog.isLoading);
  return (
    <DialogDefault
      actionText="Удалить"
      closeText="Отменить"
      content={
        <>
          <Typography>Удалить материал {name}?</Typography>
        </>
      }
      isLoading={isLoading}
      open={open}
      title="Удаление материала"
      onActionButtonClick={onConfirm}
      onCloseDefault={close}
    />
  );
};

export default memo(DeleteConfirm);
