import {getCommonReport, getNorms, getPlayerReports, getTeamCommonReport, putNorms} from '@api/bmi';
import date from '@helpers/date';
import {defaultLoader, defaultSliceReducersConstructor} from '@helpers/slices';
import {createSlice} from '@reduxjs/toolkit';
import {initialStateIndicators} from '@src/constants';
import {groupBy, uniq} from 'lodash';

import moduleNames from '../moduleNames';

const statePaths = {
  commonReport: {
    name: 'commonReport',
    path: 'commonReport.report',
  },
  teamCommonReport: {
    name: 'teamCommonReport',
    path: 'teamCommonReport',
  },
  norms: {
    name: 'norms',
    path: 'norms',
  },
  playerReports: {
    name: 'playerReports',
    path: 'playerReports',
  },
};
const currentDate = date().currentDate;
const date180DaysBefore = date().subtract('days', 180);

const initialState = {
  commonReport: {
    filters: {
      schools: '',
      groups: '',
      period: [date180DaysBefore, currentDate],
    },
    report: {
      ...initialStateIndicators,
      data: {
        students: [],
        dates: [],
      },
    },
  },
  teamCommonReport: {
    ...initialStateIndicators,
    data: {
      data: [],
      currentLength: 0,
      previousLength: 0,
    },
  },
  norms: {
    ...initialStateIndicators,
    data: {
      ageGroup: '',
      fields: [],
    },
  },
  playerReports: {
    ...initialStateIndicators,
  },
};

//redusers
const bmi = createSlice({
  name: moduleNames.bmi,
  initialState,
  reducers: {
    setCommonReportFilter: ({commonReport}, {payload}) => {
      commonReport.filters = payload;
    },
    clearCommonReportFilter: ({commonReport}) => {
      commonReport.filters = initialState.commonReport.filters;
    },
    editCommonReportTypeOfBody: ({commonReport}, {payload}) => {
      commonReport.report.data.students = payload;
    },
    ...defaultSliceReducersConstructor(statePaths.commonReport.name, statePaths.commonReport.path, initialState)
      .functions,
    ...defaultSliceReducersConstructor(statePaths.teamCommonReport.name, statePaths.teamCommonReport.path, initialState)
      .functions,
    ...defaultSliceReducersConstructor(statePaths.norms.name, statePaths.norms.path, initialState).functions,
    normsEdit: ({norms}, {payload}) => {
      norms.data.fields = norms.data.fields?.map((item, index) => ({...item, ...(payload?.[index] || {})}));
    },
    ...defaultSliceReducersConstructor(statePaths.playerReports.name, statePaths.playerReports.path, initialState)
      .functions,
  },
});

//exports
const {actions, reducer} = bmi;
const {normsEdit} = actions;
export {reducer as bmiReducer};

//fetch fns
export const setCommonReportFilter = (data) => (dispatch) => dispatch(actions.setCommonReportFilter(data));
export const clearCommonReportFilter = (data) => (dispatch) => dispatch(actions.clearCommonReportFilter(data));

const commonReportActions = defaultSliceReducersConstructor(
  statePaths.commonReport.name,
  statePaths.commonReport.path,
  initialState,
  actions,
).actionsValues;

const teamCommonReportActions = defaultSliceReducersConstructor(
  statePaths.teamCommonReport.name,
  statePaths.teamCommonReport.path,
  initialState,
  actions,
).actionsValues;

const normsActions = defaultSliceReducersConstructor(
  statePaths.norms.name,
  statePaths.norms.path,
  initialState,
  actions,
).actionsValues;

const playerReportsActions = defaultSliceReducersConstructor(
  statePaths.playerReports.name,
  statePaths.playerReports.path,
  initialState,
  actions,
).actionsValues;

export const clearCommonReport = () => (dispatch) => dispatch(commonReportActions.clear());

export const loadCommonReport = (data, onSuccess) =>
  defaultLoader(
    () => getCommonReport(data),
    commonReportActions.loading,
    ({data}) => {
      onSuccess({form: data?.students || []});
      return commonReportActions.load({data: {students: data.students, dates: uniq(data.dates)}});
    },
    commonReportActions.error,
  );

export const editCommonReportTypeOfBody = (payload) => (dispatch) =>
  dispatch(actions.editCommonReportTypeOfBody(payload));

export const clearTeamCommonReport = () => (dispatch) => dispatch(teamCommonReportActions.clear());
const calcItemLength = (item) => {
  if (item?.bmi || item?.caliperometry || item?.height || item?.weight) {
    return 1;
  }
  return 0;
};
export const loadTeamCommonReport = (data, onSuccess) =>
  defaultLoader(
    () => getTeamCommonReport(data),
    teamCommonReportActions.loading,
    ({data}) => {
      let currentLength = 0;
      let previousLength = 0;
      data.forEach(({current, previous}) => {
        currentLength += calcItemLength(current);
        previousLength += calcItemLength(previous);
      });
      onSuccess({form: data});
      return teamCommonReportActions.load({
        data: {data, currentLength, previousLength},
      });
    },
    teamCommonReportActions.error,
  );

export const clearNorms = () => (dispatch) => dispatch(normsActions.clear());

export const loadNorms = (ageGroup, onLoad = () => {}) =>
  defaultLoader(
    () => getNorms(ageGroup),
    normsActions.loading,
    ({data}) => {
      const result = {
        ageGroup: data[0].ageGroup,
        fields: data,
      };
      onLoad(result);
      return normsActions.load({data: result});
    },
    normsActions.error,
  );

export const editNorms = (data, onLoad = () => {}, onSuccess = () => {}) => {
  return async (dispatch) => {
    await putNorms(data)
      .then((response) => {
        dispatch(normsEdit(response.data));
        onSuccess();
      })
      .finally(() => onLoad());
  };
};

export const loadPlayerReports = (params, groupDataBy) =>
  defaultLoader(
    () => getPlayerReports(params),
    playerReportsActions.loading,
    ({data}) => {
      return playerReportsActions.load({
        data: groupDataBy ? groupBy(data, groupDataBy) : data,
      });
    },
    playerReportsActions.error,
  );

export const clearPlayerReports = () => (dispatch) => dispatch(playerReportsActions.clear());
