import AutoHeightScrollBar from '@common/AutoHeightScrollBar';
import ExerciseItemCompact from '@common/TrainingControl/ExerciseItemCompact';
import {useExercise} from '@components/apps/TrainingControlProcess/hooks/api';
import useSearch from '@hooks/useSearch';
import useStoreDispatch from '@hooks/useStoreDispatch';
import useUpdateEffect from '@hooks/useUpdateEffect';
import {Search} from '@mui/icons-material';
import {Checkbox} from '@mui/material';
import {fetcherTrainingControl} from '@slices/trainingControlProcess';
import Box from '@ui/MUI/Box';
import Stack from '@ui/MUI/Stack';
import TextFieldDefault from '@ui/MUI/TextField';
import TypographyDefault from '@ui/MUI/Typography';
import React, {useState} from 'react';
import {useFieldArray} from 'react-hook-form';

import LoadingHolder from '../../../LoadingHolder';
import Cloud from './components/Cloud';

const SelectableList = ({on}) => {
  const {data, isLoading, isLoad} = useExercise();
  const dispatch = useStoreDispatch();
  const {search, debounsedValue, updateValue} = useSearch();

  const [filters, setFilters] = useState({
    age: [],
    theme: [],
  });

  const handleChange = (name, value) => {
    setFilters({...filters, [name]: value});
  };

  useUpdateEffect(() => {
    dispatch(
      fetcherTrainingControl('exercise', {
        search: debounsedValue,
        themes: filters.theme,
        ages: filters.age,
      }),
    );
  }, [filters, debounsedValue]);

  const {fields, append, remove} = useFieldArray({
    name: 'exercises',
    keyName: 'innerKey',
    rules: {required: true},
  });

  return (
    <>
      <TypographyDefault mb={2} mt={6} variant="h4">
        Упражнения
      </TypographyDefault>
      <Stack spacing={4}>
        <TextFieldDefault
          InputProps={{
            endAdornment: <Search sx={{fontSize: '24px', color: 'rgba(0, 0, 0, 0.54)'}} />,
          }}
          label="Поиск по упражнениям"
          value={search}
          onChange={(e) => updateValue(e.target.value)}
        />
        <Stack border="1px solid rgba(0, 0, 0, 0.24)" borderRadius={2} direction="row">
          <Cloud age={filters.age} theme={filters.theme} onChange={handleChange} />
          <Box bgcolor="rgba(209, 209, 209, 1)" flex="1">
            {isLoading && <LoadingHolder height="100%" />}
            {!isLoading && (
              <AutoHeightScrollBar maxHeight="400px">
                <Stack px={2} py={3} spacing={2}>
                  {data.length === 0 && isLoad && <TypographyDefault>Данные отсутствуют</TypographyDefault>}
                  {data.map((exercise) => {
                    const isChecked = fields.some((field) => field?.id === exercise.id);
                    const index = fields.findIndex((field) => field?.id === exercise.id);
                    return (
                      <Stack alignItems="center" direction="row" key={exercise.id} spacing={1}>
                        <Checkbox
                          checked={isChecked}
                          color="primary"
                          size="small"
                          onChange={() => (isChecked ? remove(index) : append({...exercise, time: '', repeat: ''}))}
                        />
                        <ExerciseItemCompact {...exercise} bgcolor="white" onInfo={() => on(exercise)} />
                      </Stack>
                    );
                  })}
                </Stack>
              </AutoHeightScrollBar>
            )}
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default SelectableList;
