import {get, set} from 'lodash';

export function defaultLoader(
  fetchAPI,
  loadingAction = () => {},
  loadAction = () => {},
  errorAction = () => {},
  cancel = {isCancel: () => {}, onCancelAction: loadingAction},
) {
  return async (dispatch) => {
    dispatch(loadingAction());
    await fetchAPI()
      .then((data) => {
        return dispatch(loadAction({data: data?.data || data}));
      })
      .catch((error) => {
        if (
          typeof cancel?.isCancel === 'function' &&
          typeof cancel?.onCancelAction === 'function' &&
          cancel?.isCancel(error)
        ) {
          return dispatch(cancel.onCancelAction('Canceled'));
        }
        dispatch(errorAction(error));
      });
  };
}

const emptyFn = () => {};
const actionsTypes = ['Loading', 'Load', 'Error', 'Clear'];

/**
 * @param name - имя для экшена
 * @param actions - экшены из функции createSlice
 */

export function actionsGetter(name, actions) {
  const [loading, load, error, clear] = [...actionsTypes].map((item) => name + item);
  return {
    loading: actions?.[loading] || emptyFn,
    load: actions?.[load] || emptyFn,
    error: actions?.[error] || emptyFn,
    clear: actions?.[clear] || emptyFn,
  };
}
/**
 * @param actionNameRoot - имя поля в объектя для нейминга экшенов (users)
 * @param path - если объект имеет вложенность (managerDashboard.pastGames.users)
 * @param initialState - initial state откуда брать начальное состояние для сброса значений в сторе
 * @param actions - экшены из функции createSlice - Deprecated: будет удален
 * @param mock - мок данные для лоадеров при состоянии isLoading
 */

export const defaultSliceReducersConstructor = (
  actionNameRoot = '',
  path = '',
  initialState = {},
  /**
   * @deprecated будет удален
   */
  actions = {},
  mock = void 0,
) => {
  const names = [...actionsTypes].map((item) => actionNameRoot + item);
  const initialStateEntity = get(initialState, path);
  const functions = {
    [names[0]]: (state) => {
      set(state, path, {...initialStateEntity, isLoading: true, data: mock ?? initialStateEntity?.data});
    },
    [names[1]]: (state, {payload}) => {
      const {needToSet = true, data} = payload;
      const result = {...initialStateEntity, isLoad: true};
      if (needToSet) result.data = data;
      set(state, path, result);
    },
    [names[2]]: (state, {payload}) => {
      set(state, path, {...initialStateEntity, error: payload, isLoad: true});
    },
    [names[3]]: (state) => {
      set(state, path, initialState ? initialStateEntity : {});
    },
  };
  /**
   * @deprecated use actionsGetter() instead
   */
  const actionsValues = actionsGetter(actionNameRoot, actions);

  return {names, functions, actionsValues};
};
