import qs from 'query-string';
import React from 'react';
import {useHistory} from 'react-router-dom';

import ChessTemplate from '../../../../../templates/ChessTemplate';
import VideoPanel from '../../VideoPanel';

export default function VideoApp() {
  const {location} = useHistory();
  const {isStudent, studentId, isPreview} = qs.parse(location.search);

  return (
    <ChessTemplate active={'video'}>
      <VideoPanel isPreview={isPreview} isStudent={isStudent} studentId={studentId} />
    </ChessTemplate>
  );
}
