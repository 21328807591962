import styled from 'styled-components';

import {LinesMobileTableTd, LinesMobileTableTh} from '../LinesMobile';

export const ModalWrapper = styled.div`
  .MuiTabs {
    &-root {
      padding-right: 0;
      padding-top: 36px;
      padding-bottom: 10px;
      box-shadow: 0 0 0 transparent;
    }
  }
  .MuiTab {
    &-root {
      margin-right: 10px;
      margin-left: 10px;
      align-items: flex-start;
      min-width: 58px;
      font: 600 normal 14px/1 'Proxima Nova';
      letter-spacing: 0.02em;
      @media screen and (max-width: 359px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  ${LinesMobileTableTh},
  ${LinesMobileTableTd} {
    &:first-child {
      position: initial;
      left: initial;
      background-color: transparent;
      box-shadow: 0 0 0 transparent;
    }
  }
`;
