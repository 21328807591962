import ChessTemplate from '@components/templates/ChessTemplate';
import useEvent from '@hooks/useEvent';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {updateFormData, updateFormVisability} from '@slices/materials';
import Box from '@ui/MUI/Box';
import ButtonDefault from '@ui/MUI/Button';
import Chip from '@ui/MUI/Chip';
import Stack from '@ui/MUI/Stack';
import Typography from '@ui/MUI/Typography';
import PageTitleDefault from '@ui/PageTitleDefault';
import moment from 'moment';
import React, {useEffect} from 'react';
import ReactPlayer from 'react-player';
import {useHistory, useParams} from 'react-router-dom';

import Form from '../../components/Form';
import Item from '../../components/Item';
import MainMoments from '../../components/MainMoments';
import {getNames} from '../../helpers';
import {useMaterial} from '../../hooks';
import {PageBox} from '../../styles';
import MaterialSkeleton from './skeleton';

const MaterialPage = () => {
  const {data, refetch, isLoading} = useMaterial();
  const {id} = useParams();
  const history = useHistory();
  const {
    canEdit,
    name,
    createdAt,
    link,
    tags,
    storage,
    description,
    students,
    teachers,
    groups,
    videoAnalyticsMoments,
  } = data.material || {};
  const fetch = useEvent(() => refetch(id));
  const dispatch = useStoreDispatch();
  useEffect(() => fetch(), [fetch, id]);
  useEffect(() => {
    if (data?.material) {
      dispatch(updateFormData(data.material));
    }
    return () => {
      dispatch(updateFormData(null));
    };
  }, [data?.material]);

  const handleClose = useEvent((reset) => {
    dispatch(updateFormVisability(false));
    reset({...data.material});
  });

  const handleDelete = useEvent(() => {
    history.push('/materials');
  });

  return (
    <ChessTemplate active="materials">
      <PageTitleDefault
        breadCrumbs={[
          {label: 'Главная', path: '/'},
          {label: 'Материалы', path: '/materials'},
          {label: name || 'Материал', path: null},
        ]}
      >
        {name || 'Материал'}
      </PageTitleDefault>
      <Form refetcher={fetch} onClose={handleClose} onDelete={handleDelete} />
      <Stack direction="row">
        <PageBox flex="1" maxWidth="735px" mt={3}>
          {isLoading ? (
            <MaterialSkeleton />
          ) : (
            <>
              <Typography mb={1} variant="h3">
                {name}
              </Typography>
              <Typography color="#747474" mb={1}>
                {moment(createdAt).format('DD.MM.YYYY')}
              </Typography>
              <ReactPlayer url={link || storage?.link} width="100%" />
              {!!tags?.length && (
                <Stack direction="row" flexDirection="row" flexWrap="wrap" gap={2} mt={5}>
                  {tags.map((tag) => (
                    <Chip key={tag.slug} label={tag.name} variant="outlined" />
                  ))}
                </Stack>
              )}
              <Stack mt={4} spacing={3}>
                {!!description && <Item description={description} title="Описание" />}
                {!!groups?.length && <Item description={getNames(groups).join(', ')} label="Команда" />}
                {!!students?.length && <Item description={getNames(students).join(', ')} label="Игроки" />}
                {!!teachers?.length && <Item description={getNames(teachers).join(', ')} label="Тренеры" />}
              </Stack>
              {canEdit && (
                <Box mt={4}>
                  <ButtonDefault
                    color="primary"
                    disabled={isLoading}
                    sx={{p: 0, border: 'none'}}
                    variant="text"
                    onClick={() => {
                      dispatch(updateFormVisability(true));
                    }}
                  >
                    Редактировать
                  </ButtonDefault>
                </Box>
              )}
            </>
          )}
        </PageBox>
        <MainMoments id={id} moments={videoAnalyticsMoments} students={students} />
      </Stack>
    </ChessTemplate>
  );
};

export default MaterialPage;
