import styled from 'styled-components';

export const GroupModalContent = styled.div``;
export const GroupModalTitle = styled.p`
  margin-top: 0;
  margin-bottom: 20px;
  font: 700 normal 16px/20px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;
export const GroupModalForm = styled.form`
  .SchoolFilter {
    padding: 0;
    margin-bottom: 24px;
    flex: 0 0 100%;
    max-width: 100%;
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    & > label {
      display: none;
    }
  }
`;
export const GroupModalFormField = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
export const GroupModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ButtonsBlock = styled.div`
  & > button {
    &:first-child {
      margin-right: 30px;
    }
  }
`;
export const TrainerAvatar = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: url(${(props) => props.src}) no-repeat top;
  background-size: cover;
`;
export const ChipContainer = styled.div`
  position: relative;
  padding: 8px 8px 8px 28px;
  display: flex;
  align-items: center;
`;
