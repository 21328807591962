import {longDash} from '@src/constants';
import {Typography} from '@ui/MUI';
import moment from 'moment';
import React from 'react';

export const columns = [
  {
    field: 'school',
    headerName: 'Школа',
    flex: 0.1,
    minWidth: 78,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value?.name || longDash}</Typography>;
    },
  },
  {
    field: 'group',
    headerName: 'Команда',
    flex: 0.1,
    minWidth: 122,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value?.name || longDash}</Typography>;
    },
  },
  {
    field: 'date',
    headerName: 'Дата',
    flex: 0.1,
    minWidth: 86,
    renderCell: (params) => {
      return <Typography variant="body2">{moment(params?.value).format('DD.MM.YYYY') || longDash}</Typography>;
    },
  },
  {
    field: 'fullTime',
    headerName: 'Время',
    flex: 0.1,
    minWidth: 122,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value || longDash}</Typography>;
    },
  },
  {
    field: 'name',
    headerName: 'Тренировка',
    flex: 0.1,
    minWidth: 128,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value || longDash}</Typography>;
    },
  },
  {
    field: 'author',
    headerName: 'Тренер',
    flex: 0.1,
    minWidth: 128,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value?.shortName || longDash}</Typography>;
    },
  },
  {
    field: 'averageQualityEstimate',
    headerName: 'Ср.оц. по упр.',
    flex: 0.1,
    minWidth: 75,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value || longDash}</Typography>;
    },
  },
  {
    field: 'connectionEstimate',
    headerName: 'Оценка согласов.',
    flex: 0.1,
    minWidth: 75,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value || longDash}</Typography>;
    },
  },
  {
    field: 'structureEstimate',
    headerName: 'Оценка структ.',
    flex: 0.1,
    minWidth: 75,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value || longDash}</Typography>;
    },
  },
  {
    field: 'skillEstimate',
    headerName: 'Оценка исп. нав.',
    flex: 0.1,
    minWidth: 75,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value || longDash}</Typography>;
    },
  },
  {
    field: 'timeMatch',
    headerName: 'Вр.',
    flex: 0.1,
    minWidth: 72,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value || longDash}</Typography>;
    },
  },
  {
    field: 'repeatMatch',
    headerName: 'Повт.',
    flex: 0.1,
    minWidth: 72,
    renderCell: (params) => {
      return <Typography variant="body2">{params?.value || longDash}</Typography>;
    },
  },
];
