import {adminPageTabs, methodistPageTabs, tearcherPageTabs} from '@components/apps/TrainingControlProcess/constant';
import ChessTemplate from '@components/templates/ChessTemplate';
import useStore from '@hooks/useStore';
import {userRoles} from '@src/constants';
import TabsNew from '@ui/MUI/TabsNew';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useMemo} from 'react';
import {useHistory, useParams} from 'react-router';

import TrainingPlan from './components/TrainingPlan';
import {usePageTitle, useTrainingControlProcessBreadCrumbs} from './hooks';
import {useInitialLoading} from './hooks/api';

const TrainingControlProcess = () => {
  const {tab} = useParams();
  const history = useHistory();
  const userRole = useStore((state) => state?.users?.currentUser?.userRole);
  const isStudent = userRole === userRoles.student;
  const tabs = useMemo(() => {
    if (userRole === userRoles.admin) {
      return adminPageTabs;
    } else {
      return [userRoles.teacher, userRoles.franchisee].includes(userRole) ? tearcherPageTabs : methodistPageTabs;
    }
  }, [userRole]);

  useInitialLoading();

  return (
    <ChessTemplate active="training_control_process">
      <PageTitleDefault breadCrumbs={useTrainingControlProcessBreadCrumbs()} text={usePageTitle()} />
      {!isStudent ? (
        <TabsNew
          withoutWrapper
          tabs={tabs}
          value={Number(tab || 0)}
          onChange={(tabIndex) => history.push(`/training_control_process/${tabIndex}`)}
        />
      ) : (
        <TrainingPlan />
      )}
    </ChessTemplate>
  );
};

export default TrainingControlProcess;
