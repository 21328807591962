import {getOneTraining} from '@api/TrainingControlProcess';
import TrainingItem from '@common/TrainingControl/TrainingItem';
import dateInterface from '@helpers/date';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import useToggle from '@hooks/useToggle';
import {ArrowBack} from '@mui/icons-material';
import {updateStep, updateTab, updateTempData} from '@slices/trainingControlProcess';
import {userRoles} from '@src/constants';
import {Box, Button} from '@ui/MUI';
import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router';

import DeleteModal from '../DeleteModal';
import LoadingHolder from '../LoadingHolder';
import useDeleteModal from './hooks/useDeleteModal';

const TrainingPage = () => {
  const {id} = useParams();
  const userRole = useStore((state) => state?.users?.currentUser?.userRole);
  const isStudent = userRole === userRoles.student;
  const [training, setTraining] = useState(null);
  const history = useHistory();
  const toggle = useToggle();
  const dispatch = useStoreDispatch();
  const {toggle: modalToggle, handleDelete} = useDeleteModal();
  const isDeleteLoading = useStore((state) => {
    return state.trainingControlProcess.training.form.isLoading;
  });

  const updateTraining = () => {
    const {startTime, endTime, date} = training;
    const dates = [
      {
        date,
        startTime: dateInterface(startTime, 'hh:mm').valueOf(),
        endTime: dateInterface(endTime, 'hh:mm').valueOf(),
      },
    ];

    dispatch(
      updateTempData({
        ...training,
        dates,
        isTemplate: false,
      }),
    );
    dispatch(updateTab('new_training'));
    dispatch(updateStep(1));
    history.push('/training_control_process');
  };

  const goBackToPlan = () => {
    dispatch(updateTab('plan'));
    history.push('/training_control_process');
  };

  useEffect(() => {
    toggle.on();
    getOneTraining(id).then(setTraining).finally(toggle.off);
  }, [id]);

  const pageTitle = useMemo(() => {
    return {
      text: `Тренировка ${training && dateInterface(training.date).format('DD.MM.YY')}`,
      breadCrumbs: [
        {label: 'Главная', path: '/'},
        {
          label: 'Контроль тренировочного процесса',
          path: '/training_control_process/1',
        },
        {label: training ? training.name : 'Тренировка', path: null},
      ],
    };
  }, [training]);

  return (
    <Box>
      <DeleteModal
        elementName={training?.name}
        isLoading={isDeleteLoading}
        modal={modalToggle}
        title="удаление тренировки"
        onDelete={handleDelete}
      />
      <PageTitleDefault {...pageTitle} />
      <Button color="primary" startIcon={<ArrowBack />} variant="contained" onClick={goBackToPlan}>
        Назад к тренировкам
      </Button>
      {toggle.isToggle ? (
        <LoadingHolder />
      ) : (
        <Box bgcolor="#fff" borderRadius={1} mt={4} px={{sm: 4, xs: 4, xxs: 2}} py="20px">
          {training && (
            <TrainingItem
              {...training}
              isStudent={isStudent}
              onDelete={() => modalToggle.on(id)}
              onEdit={updateTraining}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default TrainingPage;
