import {Box} from '@ui/MUI';
import styled from 'styled-components';

export const LocalPreloaderWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  height: ${(props) => props.isLoading && '70vh'};
`;

export const Wrapper = styled(Box)`
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
`;
