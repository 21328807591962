import styled from 'styled-components';

export const TabsContainer = styled.div`
  .rc-tabs {
    &-nav {
      padding-top: 18px;
      padding-bottom: 18px;
      &-operations-hidden {
        display: none;
      }
      &-list {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        padding-left: 34px;
        padding-right: 20px;
        @media (min-width: 481px) and (max-width: 991px) {
          justify-content: flex-start;
        }
        @media screen and (max-width: 480px) {
          padding-left: 0;
          padding-right: 0;
          justify-content: center;
        }
      }
    }
    &-content {
      &-holder {
        position: relative;
      }
    }
    &-tab {
      padding: 2px 10px;
      margin-right: 20px;
      border-radius: 20px;
      font: normal normal 14px/20px 'Proxima Nova';
      color: #fffaed;
      text-transform: uppercase;
      opacity: 1;
      transition: opacity 0.33s;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        margin-top: 4px;
        margin-bottom: 4px;
      }
      @media screen and (max-width: 480px) {
        flex: 0 0 70%;
        max-width: 70%;
        margin-right: 0;
        text-align: center;
      }
      &:focus {
        opacity: 1;
      }
      &:hover {
        opacity: 0.8;
      }
      &-active {
        background-color: #990011;
      }
      &-btn {
        > a {
          color: #fffaed;
          text-decoration: none;
          text-transform: none;
        }
      }
    }
    &-ink-bar {
      display: none;
    }
    &.Tabs {
      &_dark {
        .rc-tabs {
          &-tab {
            color: #2c2c2c;
            &-active {
              color: #fffaed;
            }
          }
        }
      }
      &_light {
        .rc-tabs {
          &-tab {
            color: #fffaed;
            &-active {
              color: #fffaed;
            }
          }
        }
      }
      &_right {
        .rc-tabs-nav {
          &-list {
            @media screen and (min-width: 481px) {
              justify-content: flex-end;
            }
          }
        }
      }
      &_left {
        .rc-tabs-nav {
          &-list {
            @media screen and (min-width: 481px) {
              justify-content: flex-start;
            }
          }
        }
      }
      &_withoutBg {
      }
      &_withBg {
        .rc-tabs {
          &-nav {
            background: #fffaed;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          }
          &-tab {
            color: #990011;
            &-active {
              color: #fffaed;
            }
          }
        }
      }
    }
  }
`;
