import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const MedicalProfileAPI = {
  postMedicalProfileCard(request) {
    return axios.post(`${API_ENDPOINT}/medical-profile`, request);
  },
  deleteMedicalProfileCard(cardId) {
    return axios.delete(`${API_ENDPOINT}/medical-profile/${cardId}`);
  },
  saveMedicalProfileRecommendation(cardId, request) {
    return axios.post(`${API_ENDPOINT}/medical-profile/recommendation/${cardId}`, request);
  },
  getMedicalProfileRecommendationsByPlayerId(playerId) {
    return axios.get(`${API_ENDPOINT}/medical-profile/recommendation?playerId=${playerId}`);
  },
  getMedicalProfileRecommendationByCardId(cardId) {
    return axios.get(`${API_ENDPOINT}/medical-profile/recommendation/${cardId}`);
  },
  getMedicalProfileStages() {
    return axios.get(`${API_ENDPOINT}/medical-profile/status-card`);
  },
  getMedicalProfileUserData(requestBody) {
    return axios.post(`${API_ENDPOINT}/medical-profile/composite-filter`, requestBody);
  },
  getTrainingRecommendations() {
    return axios.get(`${API_ENDPOINT}/medical-profile/training-recomendation`);
  },
  getConsultationRecommendations() {
    return axios.get(`${API_ENDPOINT}/medical-profile/consultation-recomendation`);
  },
  getNutritionRecommendations() {
    return axios.get(`${API_ENDPOINT}/medical-profile/nutrition-recomendation`);
  },
  getCardHistory(playerId, cardId) {
    return axios.get(`${API_ENDPOINT}/medical-profile/history/${playerId}/${cardId}`);
  },
  postPlayerBMIForm(request) {
    return axios.post(`${API_ENDPOINT}/body-index`, request);
  },
  getPlayerBMIReport(filter) {
    return axios.get(
      `${API_ENDPOINT}/body-index?teamId=${filter.teamId}&timeStart=${filter.period.timeStart}&timeEnd=${filter.period.timeEnd}`,
    );
  },
  getNutritionRecommendationsByTeam(filter) {
    return axios.get(
      `${API_ENDPOINT}/nutrition?teamId=${filter.teamId}&timeStart=${filter.period.timeStart}&timeEnd=${filter.period.timeEnd}`,
    );
  },
};
export default MedicalProfileAPI;

export function getUserMedicalData(playerId) {
  return axios.get(`${API_ENDPOINT}/medical-profile/user-data/${playerId}`);
}
