export const mock = [...Array(15)].fill({
  playerNumber: 58,
  playerFirstName: 'Родион',
  playerLastName: 'Сафин',
  faceoffsWonCount: 0,
  faceoffsLoseCount: 1,
  faceoffsCount: 3,
  detailedInfo: [
    {
      type: 'lost',
      time: '35:41',
      videoTime: '1:28:20',
      zone: 'DZ',
    },
  ],
});
