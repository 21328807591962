import {Wrapper} from '@ui/MUI/Autocomplete';
import styled from 'styled-components';

export const TableContainer = styled.div`
  ${Wrapper} {
    ${({theme}) => theme.breakpoints.up('sm')} {
      width: 240px;
    }
  }
`;
