import {useEstimates} from '@common/DevelopmentJournal/hooks';
import BMIItems from '@components/apps/PlayerPersonalFile/components/MainInfo/components/BMIItems';
import FuncTestingCard from '@components/apps/PlayerPersonalFile/components/MainInfo/components/FuncTestingCard';
import GPTCard from '@components/apps/PlayerPersonalFile/components/MainInfo/components/GPTCard';
import PastGamesTable from '@components/apps/PlayerPersonalFile/components/MainInfo/components/PastGamesTable';
import PlayerInfo from '@components/apps/PlayerPersonalFile/components/MainInfo/components/PlayerInfo';
import SkillCard from '@components/apps/PlayerPersonalFile/components/MainInfo/components/SkillCard';
import useStore from '@hooks/useStore';
import {Stack} from '@ui/MUI';
import React from 'react';
import {useParams} from 'react-router';

function MainInfo() {
  const {student, loading} = useStore((state) => state.users);
  const {id} = useParams();
  const estimates = useEstimates({playersIds: [id], isCompleted: false});
  return (
    <>
      <Stack direction="row" flexWrap="wrap" gap={3} mb={3}>
        <PlayerInfo isLoading={loading} student={student} />
        <BMIItems isLoading={loading} student={student} />
      </Stack>
      <PastGamesTable hockeyRole={student?.hockeyRole} />
      <Stack direction="row" flexWrap="wrap" gap={3} mb={3} width="100%">
        <Stack direction="row" flex={2} flexWrap="wrap" gap={3}>
          <GPTCard isLoading={loading} />
          <SkillCard {...estimates} index={0} />
        </Stack>
        <Stack direction="row" flex={2} flexWrap="wrap" gap={3}>
          <SkillCard {...estimates} index={1} />
          <FuncTestingCard />
        </Stack>
      </Stack>
    </>
  );
}

export default MainInfo;
