import React from 'react';

function Help() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M7.00004 0.333252C5.6815 0.333252 4.39257 0.724245 3.29624 1.45679C2.19991 2.18933 1.34543 3.23052 0.840847 4.4487C0.336263 5.66687 0.20424 7.00731 0.461475 8.30052C0.71871 9.59373 1.35365 10.7816 2.286 11.714C3.21835 12.6463 4.40623 13.2813 5.69944 13.5385C6.99265 13.7957 8.33309 13.6637 9.55127 13.1591C10.7694 12.6545 11.8106 11.8 12.5432 10.7037C13.2757 9.60739 13.6667 8.31846 13.6667 6.99992C13.6647 5.23241 12.9617 3.53786 11.7119 2.28804C10.4621 1.03823 8.76755 0.335218 7.00004 0.333252ZM7.00004 12.3333C5.94521 12.3333 4.91406 12.0205 4.037 11.4344C3.15994 10.8484 2.47635 10.0154 2.07269 9.0409C1.66902 8.06636 1.5634 6.994 1.76919 5.95944C1.97498 4.92487 2.48293 3.97456 3.22881 3.22868C3.97469 2.4828 4.925 1.97485 5.95956 1.76906C6.99413 1.56328 8.06648 1.66889 9.04102 2.07256C10.0156 2.47623 10.8485 3.15982 11.4345 4.03688C12.0206 4.91394 12.3334 5.94509 12.3334 6.99992C12.3318 8.41391 11.7693 9.76952 10.7695 10.7694C9.76965 11.7692 8.41403 12.3316 7.00004 12.3333ZM7.00004 6.66659C6.82323 6.66659 6.65366 6.73682 6.52864 6.86185C6.40361 6.98687 6.33338 7.15644 6.33338 7.33325V9.33325C6.33338 9.51006 6.40361 9.67963 6.52864 9.80466C6.65366 9.92968 6.82323 9.99992 7.00004 9.99992C7.17685 9.99992 7.34642 9.92968 7.47145 9.80466C7.59647 9.67963 7.66671 9.51006 7.66671 9.33325V7.33325C7.66671 7.15644 7.59647 6.98687 7.47145 6.86185C7.34642 6.73682 7.17685 6.66659 7.00004 6.66659ZM7.00004 3.99992C6.83523 3.99992 6.67411 4.04879 6.53707 4.14036C6.40003 4.23193 6.29322 4.36208 6.23014 4.51435C6.16707 4.66662 6.15057 4.83418 6.18272 4.99583C6.21488 5.15748 6.29424 5.30596 6.41079 5.42251C6.52733 5.53905 6.67582 5.61842 6.83747 5.65057C6.99912 5.68273 7.16667 5.66622 7.31895 5.60315C7.47122 5.54008 7.60137 5.43327 7.69293 5.29623C7.7845 5.15919 7.83338 4.99807 7.83338 4.83325C7.83338 4.61224 7.74558 4.40028 7.5893 4.244C7.43302 4.08772 7.22106 3.99992 7.00004 3.99992Z"
          fill="#C4C4C4"
        />
      </g>
    </React.Fragment>
  );
}
export default Help;
