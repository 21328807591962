import React from 'react';

function TShirt() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M7.85547 2.60938C6.40158 2.60938 5.21875 3.79221 5.21875 5.24609C5.21875 6.69998 6.40158 7.88281 7.85547 7.88281C9.30936 7.88281 10.4922 6.69998 10.4922 5.24609C10.4922 3.79221 9.30936 2.60938 7.85547 2.60938ZM7.85547 6.82812C6.98314 6.82812 6.27344 6.11843 6.27344 5.24609C6.27344 4.37376 6.98314 3.66406 7.85547 3.66406C8.7278 3.66406 9.4375 4.37376 9.4375 5.24609C9.4375 6.11843 8.7278 6.82812 7.85547 6.82812Z"
          fill="#626262"
        />
        <path
          d="M12.6016 0.5V1.55469H11.5469V0.5L7.85547 0.5C5.41671 0.5 3.40202 2.34904 3.13884 4.71875H2.58203C1.7097 4.71875 1 5.42845 1 6.30078L1 10.5195C1 11.9734 2.18283 13.1562 3.63672 13.1562C4.50905 13.1562 5.21875 12.4466 5.21875 11.5742C5.21875 10.7019 4.50905 9.99219 3.63672 9.99219C2.76439 9.99219 2.05469 9.28249 2.05469 8.41016V6.30078C2.05469 6.01 2.29125 5.77344 2.58203 5.77344H3.13884C3.40202 8.14314 5.41671 9.99219 7.85547 9.99219C10.0978 9.99219 12.0234 8.4142 12.4818 6.21605L19 4.60425V0.5L12.6016 0.5ZM3.63672 11.0469C3.9275 11.0469 4.16406 11.2834 4.16406 11.5742C4.16406 11.865 3.9275 12.1016 3.63672 12.1016C2.76439 12.1016 2.05469 11.3919 2.05469 10.5195V10.5183C2.49565 10.85 3.04367 11.0469 3.63672 11.0469ZM17.9453 3.77857L11.543 5.36172C11.5412 5.36524 11.267 8.9375 7.85547 8.9375C5.82003 8.9375 4.16406 7.28154 4.16406 5.24609C4.16406 3.21065 5.82003 1.55469 7.85547 1.55469H10.4922V2.60938H13.6562V1.55469H17.9453V3.77857Z"
          fill="#626262"
        />
      </g>
    </React.Fragment>
  );
}
export default TShirt;
