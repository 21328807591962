import AddButton from '@common/AddButton';
import ConfirmModal from '@common/ConfirmModal';
import SkillsForm from '@components/apps/TrainingControlProcess/forms/SkillsForm';
import {useSkill} from '@components/apps/TrainingControlProcess/hooks/api';
import {PageContentWrapper} from '@components/apps/TrainingControlProcess/TrainingControlProcess';
import useEvent from '@hooks/useEvent';
import {useRoleAccess} from '@hooks/useRoleAccess';
import useToggle from '@hooks/useToggle';
import {userRoles} from '@src/constants';
import Stack from '@ui/MUI/Stack';
import React, {memo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import DeleteModal from '../../../DeleteModal';
import LoadingHolder from '../../../LoadingHolder';
import Filters from './components/Filters';
import SkillList from './components/SkillList';
import {useDeleteModal} from './hooks';

const defaultValues = {
  name: '',
  section: null,
  category: null,
  skills: [],
};

const SkillsLibaryTab = ({ageId}) => {
  const skill = useSkill();

  const {toggle, handleDelete} = useDeleteModal(ageId);
  const methods = useForm({defaultValues, mode: 'onChange'});
  const form = useToggle();

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const {reset, formState} = methods;
  const onClose = useEvent(() => {
    if (formState?.isDirty) {
      setIsConfirmModalVisible(true);
    } else {
      form.off();
      reset(defaultValues);
      if (isConfirmModalVisible) {
        setIsConfirmModalVisible(!isConfirmModalVisible);
      }
    }
  });
  const onSubmitClose = useEvent(() => {
    form.off();
    reset(defaultValues);
  });

  const canEdit = useRoleAccess([userRoles?.teacher, userRoles?.methodist]);

  return (
    <>
      <FormProvider {...methods}>
        <SkillsForm
          age={ageId}
          isConfirmModalVisible={isConfirmModalVisible}
          isOpen={form.isToggle}
          setIsConfirmModalVisible={setIsConfirmModalVisible}
          onClose={onClose}
          onSubmitClose={onSubmitClose}
        />
        <DeleteModal age={ageId} elementName="навык" modal={toggle} title="удаление навыка" onDelete={handleDelete} />
        <PageContentWrapper>
          <Stack spacing={5}>
            {canEdit && <AddButton onClick={form.on}>добавить навык</AddButton>}
            <Filters age={ageId} />
            {skill.isLoading ? (
              <LoadingHolder />
            ) : (
              <SkillList
                items={skill.data}
                onDelete={toggle.on}
                onEdit={(payload) => {
                  reset(payload);
                  form.on();
                }}
              />
            )}
          </Stack>
        </PageContentWrapper>
      </FormProvider>
      <ConfirmModal
        isToggle={isConfirmModalVisible}
        onActionButtonClick={() => {
          form.off();
          reset(defaultValues);
          setIsConfirmModalVisible(false);
        }}
        onClose={() => setIsConfirmModalVisible(false)}
      />
    </>
  );
};

export default memo(SkillsLibaryTab);
