import 'moment/locale/ru';

import moment from 'moment';
import React from 'react';

moment.locale('ru');

const config = {
  locale: 'ru',
  timeZone: 'local',
  allDaySlot: true,
  firstDay: 1,
  stickyHeaderDates: true,
  scrollTime: moment().format('HH:00:00'),
  slotDuration: '00:15:00',
  editable: false,
  eventAdd: function () {},
  eventChange: function () {},
  eventRemove: function () {},
  moreLinkContent: ({num}) => {
    return <p> + {num} скрыто</p>;
  },
  slotMaxTime: '24:00',
  dayMaxEventRows: true,
  dayMaxEvents: 1,
  selectable: true,
  slotLabelInterval: {
    hours: 1,
  },
  slotLabelFormat: {
    hour: '2-digit',
    minute: '2-digit',
  },
  eventTimeFormat: {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
  },
  views: {
    timeGridWeek: {
      titleFormat: 'D MMMM',
    },
    timeGridDay: {
      titleFormat: 'D MMMM, YYYY г.',
    },
    dayGridMonth: {
      titleFormat: 'MMMM YYYY г.',
      dayMaxEventRows: 2,
    },
  },
  buttonText: {
    dayGridMonth: 'Месяц',
    timeGridWeek: 'Неделя',
    timeGridDay: 'День',
    listDay: 'День',
    listWeek: 'Неделя',
    listMonth: 'Месяц',
  },
  allDayContent: false,
  schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
};

export default config;
