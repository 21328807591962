import 'echarts/lib/chart/pie';

import useEventListener from '@hooks/useEventListener';
import TypographyDefault from '@ui/MUI/Typography';
import * as echarts from 'echarts';
import React, {useEffect, useRef, useState} from 'react';

function DonutChart({options, width, height, color, hideHistory, style = {}, isLoading, ...props}) {
  let defaultOption = {
    tooltip: {
      trigger: 'item',
    },
    series: options?.series || [],
    color: color,
  };

  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  /**
   * Инициализация графика
   */
  const init = (opt) => {
    // eslint-disable-next-line import/namespace
    const chart = echarts.init(chartRef.current);

    chart.setOption(opt ? opt : defaultOption);
    setChartInstance(chart);
  };
  useEffect(() => {
    init();
  }, []);
  /**
   * Реинициализация графика
   */
  const reInit = (options) => {
    if (!chartInstance) return;
    chartInstance.dispose();
    init(options);
  };

  useEffect(() => {
    reInit(options);
  }, [options]);

  useEventListener('resize', () => chartInstance.resize(), chartInstance);

  return (
    <>
      <div
        className={props.className || 'DonutChart'}
        style={{
          background: '#fff',
          textAlign: 'center',
          width: width,
          minHeight: height,
          ...style,
        }}
      >
        <div
          ref={chartRef}
          style={{
            width: width,
            minHeight: height,
            height: height,
            maxHeight: height,
            ...style,
          }}
        ></div>
        {!hideHistory && defaultOption?.series[0]?.data && (
          <div className="DonutChart__values">
            <TypographyDefault
              color="rgba(44, 44, 44, 0.7)"
              component="p"
              gutterBottom={false}
              isLoading={isLoading}
              mr={1}
              preloaderWidthBetween={[20, 30]}
              variant="subtitle2"
            >
              {defaultOption?.series[0]?.data[1]?.value}
            </TypographyDefault>
            <TypographyDefault
              color="rgba(44, 44, 44, 0.7)"
              component="p"
              gutterBottom={false}
              isLoading={isLoading}
              ml={1}
              preloaderWidthBetween={[20, 30]}
              variant="subtitle2"
            >
              {defaultOption?.series[0]?.data[0]?.value}
            </TypographyDefault>
          </div>
        )}
      </div>
    </>
  );
}

export default DonutChart;
