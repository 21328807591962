import LocalPreloader from '@components/preloader/LocalPreloader';
import {
  DOCTOR_STATUSES_OPTIONS,
  DOCTOR_SUB_STATUSES_OPTIONS,
  NOTAVILABLE_SUB_STATUSES_OPTIONS,
} from '@components/students/constants';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import Box from '@ui/MUI/Box';
import Link from '@ui/MUI/Link';
import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

import {BoxShadow} from '../../ManagerDashboard';
import Card from '../Card';
import NoData from '../NoData';

const third = 100 / 3;
const headlines = [
  {
    title: 'ФИО игрока',
    width: {xxs: '60%', sm: third + '%'},
  },
  {
    title: 'Команда',
    width: {xxs: '100%', sm: third + '%'},
    display: {xxs: 'none', sm: 'block'},
    order: {xxs: 3, sm: 'initial'},
  },
  {
    title: 'Статус травмы',
    width: {xxs: '35%', sm: third + '%'},
    textAlign: 'right',
    order: {xxs: 2, sm: 'initial'},
  },
];
const allStatuses = DOCTOR_SUB_STATUSES_OPTIONS.concat(NOTAVILABLE_SUB_STATUSES_OPTIONS, DOCTOR_STATUSES_OPTIONS);
function PlayerStatusTable({title, data, isLoading, isLoad, error, lastTableHeadline, isAll, setIsAll, noData}) {
  return (
    <Card height={isLoading && '250px'} title={title}>
      <LocalPreloader visible={isLoading} />
      {(isLoad && !data?.players?.length) || error ? (
        <NoData>На данный момент нет {noData}</NoData>
      ) : (
        <>
          <BoxShadow>
            <Box p={3} pb={0} pt={0}>
              <Stack direction="row" gap={1} justifyContent="space-between">
                {headlines.map((item, index) => (
                  <Box display={item.display} key={index} width={item.width}>
                    <TypographyDefault textAlign={item.textAlign} variant="body2">
                      {index === headlines.length - 1 && lastTableHeadline ? lastTableHeadline : item.title}
                    </TypographyDefault>
                  </Box>
                ))}
              </Stack>
            </Box>
          </BoxShadow>
          <Box>
            {data?.players?.map((item, index) => (
              <Stack
                alignItems="center"
                borderBottom="1px solid #DDDAD4"
                direction="row"
                flexWrap={{xxs: 'wrap', sm: 'initial'}}
                gap={1}
                justifyContent="space-between"
                key={index}
                p={3}
                pb={2}
                pt={2}
              >
                <Box overflow="hidden" width={headlines[0].width}>
                  <Link alignItems="center" display="flex" target="_blank" to={`/student/${item.id}`} underline="none">
                    <PlayersHorizontalCard student={{...item}} />
                  </Link>
                </Box>
                <Box order={headlines[1].order} overflow="hidden" width={headlines[1].width}>
                  <TypographyDefault variant="body2">{item.teamName}</TypographyDefault>
                </Box>
                <Box
                  order={headlines[2].order}
                  overflow="hidden"
                  textAlign={headlines[2].textAlign}
                  width={headlines[2].width}
                >
                  <TypographyDefault variant="body2">
                    {
                      allStatuses.find(
                        (status) => status.value === (item?.injuryStatus || item?.missingReason || item?.illnessStatus),
                      )?.label
                    }
                  </TypographyDefault>
                </Box>
              </Stack>
            ))}
          </Box>
          {data?.players?.length && !isAll && !data?.notEnough && (
            <Box p={3} pb={0} pt={2}>
              <Link onClick={() => setIsAll(true)}>Показать всех игроков</Link>
            </Box>
          )}
        </>
      )}
    </Card>
  );
}

PlayerStatusTable.propTypes = {};

export default PlayerStatusTable;
