import React from 'react';

function ShotInTargetIcon(props) {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.21875 10.298C5.85235 10.9764 6.71955 11.3156 7.82035 11.3156C8.63955 11.3156 9.25715 11.13 9.67315 10.7588C10.0892 10.3876 10.2972 9.91397 10.2972 9.33797C10.2972 9.01157 10.2268 8.72997 10.0859 8.49317C9.95155 8.25637 9.77555 8.07077 9.55795 7.93637C9.34035 7.80197 9.09715 7.68997 8.82835 7.60037C8.56595 7.50437 8.30035 7.42437 8.03155 7.36037C7.76915 7.29637 7.52915 7.23237 7.31155 7.16837C7.09395 7.09797 6.91475 7.00837 6.77395 6.89957C6.63955 6.79077 6.57235 6.65637 6.57235 6.49637C6.57235 6.25317 6.66835 6.06117 6.86035 5.92037C7.05875 5.77317 7.32435 5.69957 7.65715 5.69957C8.40595 5.69957 9.02355 5.94277 9.50995 6.42917L10.1532 5.59397C9.53875 4.99877 8.73875 4.70117 7.75315 4.70117C7.06195 4.70117 6.49875 4.88037 6.06355 5.23877C5.63475 5.59077 5.42035 6.03557 5.42035 6.57317C5.42035 7.00837 5.54835 7.36357 5.80435 7.63877C6.06035 7.91397 6.37075 8.10917 6.73555 8.22437C7.10675 8.33957 7.47475 8.43877 7.83955 8.52197C8.20435 8.60517 8.51475 8.72037 8.77075 8.86757C9.02675 9.00837 9.15475 9.20037 9.15475 9.44357C9.15475 9.68037 9.04595 9.88517 8.82835 10.058C8.61715 10.2308 8.29715 10.3172 7.86835 10.3172C7.06835 10.3172 6.39315 10.0228 5.84275 9.43397L5.21875 10.298Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default ShotInTargetIcon;
