export const actionButtonsMock = [
  {
    id: 300100,
    name: 'Удаление',
    engName: 'Foul',
    engNameShort: 'FL',
  },
  {
    id: 600100,
    name: 'Силовой прием',
    engName: 'Hit',
    engNameShort: 'HT',
  },
  {
    id: 999001,
    name: 'Обводка',
    engName: 'Deking',
    engNameShort: 'DK',
    subTypes: [
      {
        name: 'Успешный',
        engName: 'Successfull',
        engNameShort: 'SC',
      },
      {
        name: 'Неуспешный',
        engName: 'Unsuccessfull',
        engNameShort: 'US',
      },
    ],
  },
  {
    id: 999002,
    name: 'Единоборство',
    engName: 'Puck battle',
    engNameShort: 'PB',
  },
  {
    id: 999004,
    name: 'Подбор',
    engName: 'Possession take',
    engNameShort: 'PT',
  },
  {
    id: 1000200,
    name: 'Отбор',
    engName: 'Takeaway',
    engNameShort: 'TA',
    subTypes: [
      {
        name: 'Клюшкой',
        engName: 'Stick to puck',
        engNameShort: 'SP',
      },
      {
        name: 'Корпусом',
        engName: 'Body',
        engNameShort: 'BD',
      },
      {
        name: 'Перехват',
        engName: 'Interception',
        engNameShort: 'IT',
      },
    ],
  },
  {
    id: 1000201,
    name: 'Потеря',
    engName: 'Giveaway',
    engNameShort: 'GA',
    subTypes: [
      {
        name: 'Активная',
        engName: 'Active',
        engNameShort: 'AC',
      },
      {
        name: 'Пассивная',
        engName: 'Passive',
        engNameShort: 'PS',
      },
      {
        name: 'Выход',
        engName: 'Breakout',
        engNameShort: 'BO',
      },
      {
        name: 'Вход',
        engName: 'Entry',
        engNameShort: 'EN',
      },
    ],
  },
  {
    id: 1000700,
    name: 'Успешный пас',
    engName: 'Pass accurate',
    engNameShort: 'PA',
  },
  {
    id: 1000800,
    name: 'Неуспешный пас',
    engName: 'Pass inaccurate',
    engNameShort: 'PI',
  },
];
