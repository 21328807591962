import PageTitleDefault from '@ui/PageTitleDefault';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';

import * as schoolsActions from '../../redux/actions/SchoolsActions';
import ListOfParticipantsWithoutEstimationTable from '../game-estimation/tools/ListOfParticipantsWithoutEstimationTable';
import ChessTemplate from '../templates/ChessTemplate';
import TableDefaultContainer from '../ui/TableDefaultContainer';

export default function ListOfParticipantsWithoutEstimationApp() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(schoolsActions.loadAllSchools());
  }, []);
  return (
    <ChessTemplate active="reports">
      <Wrapper>
        <PageHeadline>
          <PageTitleDefault text={'Отчеты'} />
        </PageHeadline>
        <TableDefaultContainer
          content={<ListOfParticipantsWithoutEstimationTable />}
          title={'Список участников без оценки'}
        />
      </Wrapper>
    </ChessTemplate>
  );
}

const Wrapper = styled.div`
  .TableDefaultContainer {
    .Headline {
      @media screen and (max-width: 767px) {
        padding-right: 18px;
        padding-left: 18px;
      }
    }
  }
`;
const PageHeadline = styled.div``;
