import {Box} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import styled, {css} from 'styled-components';

const topHeight = 40;
const topHeightCollapsed = 70;

export const Wrapper = styled(Box)`
  height: 100%;
  box-sizing: border-box;
  min-height: 600px;
  overflow: hidden;
  padding-top: ${({theme}) => theme.spacing(3)};

  ${({theme}) => theme.breakpoints.down('md')} {
    padding: 20px 0;
    min-height: 100%;
    width: auto;
  }
`;
export const BottomUserPanelContainer = styled(Box)`
  padding-top: 20px;
  padding-bottom: 0;
`;

export const LogoImage = styled.img`
  display: block;
  height: 100%;
  margin: 0 auto;
  ${({theme}) => theme.breakpoints.down('sm')} {
    width: 60px;
  }
`;

export const LogoPlaceholder = styled.div`
  height: ${topHeight}px;
  line-height: ${topHeight}px;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({theme}) => theme.spacing('0', 3)};
  ${({collapsed, theme}) =>
    collapsed &&
    css`
      height: ${topHeightCollapsed}px;
      line-height: ${topHeightCollapsed}px;
      flex-direction: column;
      padding: ${theme.spacing('0', 0)};
      ${LogoImage} {
        width: 100%;
        height: auto;
        margin-bottom: ${theme.spacing(4)};
      }
    `}
`;

export const BurgerMenuIcon = styled.div`
  cursor: pointer;
  user-select: none;
  display: none;
  position: relative;
  z-index: 1;
  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0;
    transition:
      transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
    &:first-child {
      transform-origin: 0 0;
    }
    &:nth-last-child(2) {
      transform-origin: 0 100%;
    }
    ${({active}) =>
      active &&
      css`
        opacity: 1;
        transform: rotate(-45deg) translate(-3px, -1px);
        &:nth-last-child(2) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }
        &:nth-last-child(3) {
          transform: rotate(45deg) translate(0, -1px);
        }
      `}
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    color: #fffaed;
    position: absolute;
    display: block;
    top: 10px;
    left: 20px;
  }
`;

// noinspection CssInvalidPropertyValue
export const OptionsList = styled(Box)`
  position: relative;
  height: ${({collapsed}) => `calc(100% - ${collapsed ? topHeightCollapsed : topHeight}px)`};
  box-sizing: border-box;
  padding-top: ${({theme}) => theme.spacing(3)};
  padding-right: ${({collapsed}) => (collapsed ? 0 : '')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({theme}) => theme.breakpoints.down('md')} {
    position: absolute;
    z-index: 99;
    width: 100%;
    top: 0;
    padding-top: ${({theme}) => `calc(${theme.spacing(4)} + ${topHeight * 2}px)`};
    padding-bottom: 20px;
    min-height: 88vh;
    min-height: fill-available;
    height: auto;
    height: fill-available;
    max-height: 100vh;
    transform: translateX(${({visible}) => (visible ? 0 : '-100%')});
    transition: 0.3s ease-in;
  }
`;

export const OptionItem = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  background: ${(props) => (props.selected === true ? '#FFFAED' : 'transparent')};
  border-radius: 0 20px 20px 0;

  transition: background-color 0.33s;
  color: ${(props) => (props.selected === true ? '#000' : 'rgba(255, 250, 237, .9)')};
  cursor: ${(props) => (props.selected === true ? 'default' : 'pointer')};
  :hover {
    background: ${(props) => (props.selected === true ? '#FFFAED' : 'rgba(255, 250, 237, .05)')};
  }
`;

export const OptionItemTitleText = styled.span`
  color: ${grey['68']};
  font-family: Proxima Nova;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  max-width: ${({collapsed}) => (collapsed ? 0 : 'unset')};
  overflow: hidden;
`;
// noinspection CssInvalidPropertyValue
export const OptionsListContainerLinks = styled.div`
  height: 350px;
  height: fill-available;
  overflow: auto;
`;
