import ContentWrapper from '@common/ContentWrapper';
import GoaltendersStatistics from '@common/Statistics/PlayerStatistics/GoaltendersStatistics';
import Filters from '@common/Statistics/PlayerStatistics/GoaltendersStatistics/components/Filters';
import useStatsGoaltendersList from '@hooks/useStatsGoaltendersList';
import {selectGoaltendersData, selectGoaltendersFilters} from '@selectors/gameStatistics';
import {
  clearGoaltendersFilters,
  clearGoaltendersStat,
  loadGoaltendersStat,
  setGoaltendersFilters,
} from '@slices/statistics/goaltenders';
import Stack from '@ui/MUI/Stack';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

function GoaltendersStatisticsTab({hasOT}) {
  const {statisticId} = useParams();

  const {playerIds, isplayerIdsLoading} = useStatsGoaltendersList([statisticId]);

  useEffect(() => {
    window.ym(80266396, 'reachGoal', 'statistika_vratary');
  }, []);

  return (
    <Stack direction="column" spacing={1}>
      <ContentWrapper>
        <Filters
          cleanup={clearGoaltendersFilters}
          getter={selectGoaltendersFilters}
          hasOT={hasOT}
          isPlayerIdsLoading={isplayerIdsLoading}
          playerIds={playerIds}
          setter={setGoaltendersFilters}
        />
      </ContentWrapper>
      <GoaltendersStatistics
        cleaner={clearGoaltendersStat}
        loader={loadGoaltendersStat}
        loading={isplayerIdsLoading}
        selectData={selectGoaltendersData}
        selectFilters={selectGoaltendersFilters}
        statisticsIds={[statisticId]}
      />
    </Stack>
  );
}

GoaltendersStatisticsTab.propTypes = {
  hasOT: PropTypes.bool,
};

export default GoaltendersStatisticsTab;
