import {Button} from '@ui/Button';
import styled from 'styled-components';

export const WrapperTableContainer = styled.div`
  overflow: hidden;
  background: white;
  box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
  border-radius: 6px 6px 0 0;
`;
export const TableBottom = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  justify-content: flex-end;
  padding: ${({theme}) => theme.spacing(0, 4)};
  box-shadow: 0 -9px 10px -7px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;
  z-index: 1000;
`;

export const ButtonWrapper = styled.div`
  margin: 0 0 0 40px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  width: 140px;
  min-width: 64px;
  height: 44px;
  background: ${(props) => (props.isDisabled ? '#c95f5f' : '#c62828')};
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 5px 15px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  ${({theme}) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;
