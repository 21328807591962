import {FiltersWrapper, FilterWrapper} from '@components/apps/TrainingControlProcess/TrainingControlProcess';
import date from '@helpers/date';
import {useGroup, useSchool} from '@hooks/filters';
import {useSeasons} from '@hooks/seasonsHooks';
import useStore from '@hooks/useStore';
import {userRoles} from '@src/constants';
import {Autocomplete} from '@ui/MUI';
import React from 'react';

const Filters = ({filters, updateFilters}) => {
  const months = date.months().map((m) => ({label: m.format('MMMM'), value: m.valueOf()}));

  const school = useSchool({});
  const group = useGroup({connectorValue: filters?.school?.value});
  const shortSeasons = useSeasons(true);

  const userRole = useStore((state) => state?.users?.currentUser?.userRole);
  const isStudent = userRole === userRoles?.student;

  return (
    <FiltersWrapper>
      {!isStudent && (
        <FilterWrapper minWidth="190px">
          <Autocomplete
            isLoading={school.isLoading}
            label="Школа"
            multiple={false}
            options={school.options}
            value={filters?.school}
            onChange={(_, newValue) => {
              updateFilters({...filters, school: newValue, group: null});
            }}
          />
        </FilterWrapper>
      )}
      <FilterWrapper minWidth="190px">
        <Autocomplete
          label="Сезон"
          multiple={false}
          options={shortSeasons?.options}
          value={filters?.season}
          onChange={(_, newValue) => {
            updateFilters({...filters, season: newValue});
          }}
        />
      </FilterWrapper>
      {!isStudent && (
        <FilterWrapper minWidth="204px">
          <Autocomplete
            disabled={!filters.school}
            isLoading={group.isLoading}
            label="Команда"
            multiple={false}
            options={group.options}
            value={filters?.group}
            onChange={(_, newValue) => {
              updateFilters({...filters, group: newValue});
            }}
          />
        </FilterWrapper>
      )}
      <FilterWrapper minWidth="204px">
        <Autocomplete
          label="Месяц"
          multiple={false}
          options={months}
          value={filters.month}
          onChange={(_, newValue) => {
            updateFilters({...filters, month: newValue});
          }}
        />
      </FilterWrapper>
    </FiltersWrapper>
  );
};

export default Filters;
