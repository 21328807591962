import Field from '@common/FormField';
import PlaceholderWrapper from '@common/FullHeightWrapper';
import ReactTableComponent from '@common/ReactTableComponent';
import ToggledHeader from '@common/ToggledHeader';
import {Wrapper} from '@components/apps/NewBodyMassIndex/BodyMassIndex';
import {mock} from '@components/apps/NewBodyMassIndex/components/CommonReport/mock';
import {GLOSSARY_ITEMS, TYPES_OF_BODY} from '@components/apps/NewBodyMassIndex/constants';
import LoadingBox from '@components/common/LoadingBox';
import PlayersHorizontalCard from '@components/students/tools/PlayersHorizontalCard';
import date from '@helpers/date';
import useFatAccess from '@hooks/useFatAccess';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {useMediaQuery} from '@mui/material';
import {noDataNumberValue} from '@src/helpers';
import {IconButton, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useHistory} from 'react-router';
import styled, {css} from 'styled-components';

Table.propTypes = {
  data: PropTypes.shape({
    students: PropTypes.array.isRequired,
    dates: PropTypes.array.isRequired,
  }),
  isLoading: PropTypes.bool,
  hasNoData: PropTypes.bool,
  hasError: PropTypes.bool,
  group: PropTypes.object,
  onSave: PropTypes.func,
  canEdit: PropTypes.bool,
};

function Table({data = {students: [], dates: []}, isLoading, hasNoData, hasError, group, onSave, canEdit}) {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isToggled, setToggleUser] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {hasAccess} = useFatAccess(group?.id);

  const measurementTypes = useMemo(
    () =>
      [
        {
          value: 'height',
          label: 'Рост',
        },
        {
          value: 'weight',
          label: 'Вес',
        },
        {
          value: 'caliperometry',
          label: '% жира',
        },
        {
          value: 'bmi',
          label: 'ИМТ',
        },
      ].slice(0, hasAccess ? 4 : 2),
    [hasAccess],
  );

  const {
    formState: {isDirty},
  } = useFormContext();
  const columns = useMemo(
    () => [
      {
        header: (
          <ToggledHeader isToggled={isToggled} setIsToggled={setToggleUser}>
            Игрок
          </ToggledHeader>
        ),
        id: 'user',
        enableRowSpan: true,
        sticky: {left: true},
        columns: [
          {
            header: 'Not visible header 2',
            accessorFn: ({params, ...data}) => data,
            headerStyle: {display: 'none !important'},
            cellStyle: {textAlign: isToggled ? 'center' : 'left'},
            sticky: {left: true},
            cell: (params) => {
              const student = params?.getValue();
              return (
                <PlayersHorizontalCard
                  hasImage={false}
                  isLoading={isLoading}
                  onlyNumber={isToggled}
                  student={student}
                />
              );
            },
          },
        ],
        rowSpan: '2',
      },
      ...(isLoading ? mock.dates : data?.dates || []).map((item) => ({
        header: (
          <Stack alignItems="center" direction="row" justifyContent="center">
            <Typography
              data-testid="headline-cell-date"
              isLoading={isLoading}
              mr={0}
              preloaderWidthBetween={[100, 120]}
              variant="subtitle2"
            >
              {date(Number(item)).format('DD.MM.YYYY')}
            </Typography>
            {canEdit && (
              <IconButton
                color="secondaryInverse"
                data-testid="edit-report-button"
                disabled={isLoading}
                name={item}
                size="small"
                onClick={() => group?.value && history.push(`/bmi/edit/${group.value}?date=${item}`)}
              >
                <EditIcon color="inherit" fontSize="inherit" />
              </IconButton>
            )}
          </Stack>
        ),
        id: item,
        headerStyle: {textAlign: 'center'},
        columns: measurementTypes.map(({label, value}, index, arr) => {
          const column = {
            accessorFn: ({params}) => params?.[item],
            id: `params.${item}.${value}`,
            header: () => <Typography variant="caption1">{label}</Typography>,
            headerStyle: {
              color: grey['54'],
            },
            cellStyle: {},
            cell: ({getValue}) => {
              const cellData = getValue();
              const warnings = cellData?.warnings || {};
              const glossaryItem = GLOSSARY_ITEMS?.[value] || {};
              const flagParams =
                glossaryItem?.flag?.(warnings[glossaryItem?.increase.flag], warnings[glossaryItem?.decrease.flag]) ||
                {};
              return (
                <IndicatorCell
                  alignItems="center"
                  bgc={flagParams?.bgc}
                  className={flagParams?.type}
                  data-testid="indicator-cell"
                  direction="row"
                >
                  <Typography
                    color={flagParams?.mainColor}
                    isLoading={isLoading}
                    preloaderWidthBetween={[30, 40]}
                    sx={{zIndex: 1}}
                    variant="body2"
                  >
                    {noDataNumberValue(cellData?.[value])}
                  </Typography>
                  {flagParams?.icon && flagParams.icon}
                </IndicatorCell>
              );
            },
          };
          if (index !== arr.length - 1) {
            column.headerStyle.boxShadow = `inset 0px -1px 0px 0px ${grey[12]}`;
            column.cellStyle.boxShadow = `inset 0px -1px 0px 0px ${grey[12]}`;
          }
          return column;
        }),
      })),
      {
        header: (
          <Stack alignItems="center" direction="row">
            <Typography mr={0} variant="subtitle2">
              Телосложение
            </Typography>
            {canEdit && (
              <IconButton
                color="secondaryInverse"
                data-testid="edit-type-of-body-btn"
                disabled={isLoading}
                size="small"
                onClick={() => {
                  if (isEditing && isDirty) {
                    setIsSaving(true);
                    onSave(() => {
                      setIsSaving(false);
                      setIsEditing((prevState) => {
                        return !prevState;
                      });
                    });
                  } else {
                    setIsEditing((prevState) => {
                      return !prevState;
                    });
                  }
                }}
              >
                {isEditing && <SaveIcon color="inherit" fontSize="inherit" />}
                {!isEditing && <EditIcon color="inherit" fontSize="inherit" />}
              </IconButton>
            )}
          </Stack>
        ),
        id: 'typeOfBody',
        enableRowSpan: true,
        sticky: {right: !isMobile},
        columns: [
          {
            header: 'Not visible header 2',
            accessorKey: 'typeOfBody',
            headerStyle: {display: 'none'},
            cellStyle: {position: 'relative'},
            sticky: {right: !isMobile},
            cell: ({getValue, cell: {id}}) => {
              const value = getValue()?.name || getValue()?.label;
              const name = `form.${id?.replace('_', '.')}`;
              return isEditing ? (
                <Field
                  data-testid="type-of-body-input"
                  getOptionLabel={(data) => data?.name || data?.label}
                  multiple={false}
                  name={name}
                  options={TYPES_OF_BODY}
                  placeholder="Не выбрано"
                  type="autocomplete"
                />
              ) : (
                <Typography
                  color={grey['68']}
                  data-testid="type-of-body-value"
                  isLoading={isLoading}
                  preloaderWidthBetween={[40, 100]}
                  variant="body2"
                >
                  {value ? value : 'Не выбрано'}
                </Typography>
              );
            },
          },
        ],
        rowSpan: '2',
      },
    ],
    [isLoading, isEditing, group, isToggled, isMobile, canEdit, isDirty, hasAccess],
  );
  return hasError || hasNoData ? (
    <PlaceholderWrapper
      alignItems="center"
      data-testid="no-data"
      display="flex"
      justifyContent="center"
      px={{xxs: 2, sm: 4}}
      py={{xxs: 1, sm: 3}}
    >
      <Typography align="center" variant="subtitle1">
        {hasError ? 'Ошибка при загрузке данных' : `Данные по команде ${group?.label || ''} еще не добавлены`}
      </Typography>
    </PlaceholderWrapper>
  ) : (
    <Wrapper data-testid="table-wrapper">
      <LoadingBox isLoading={isSaving}>
        <ReactTableComponent
          isAvailableHeight
          columns={columns}
          data={isLoading ? mock.students : data?.students}
          data-testid="common-report-table"
          tableBodyCellProps={{
            sx: {py: {xxs: 1, sm: 3}, px: {xxs: 2, sm: 4}, height: 'auto !important', position: 'relative'},
          }}
          tableHeaderCellProps={{sx: {py: 0, px: {xxs: 2, sm: 4}, height: 'auto !important'}}}
        />
      </LoadingBox>
    </Wrapper>
  );
}

export default Table;

const IndicatorCell = styled(Stack)`
  ${({bgc}) => css`
    &:before {
      content: '';
      background-color: ${bgc};
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  `}
`;
