import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import {HAND_MAP, HOCKEY_ROLES, POTENTIAL_ASSOCIATIONS, ROLE_MODEL_ASSOCIATIONS} from '../../../../../constants';
import {ShowButton} from '../../../../common/ScoutReport/CommonScoutReport';
import PlayersHorizontalCard from '../../../../students/tools/PlayersHorizontalCard';
import DefaultSortTable from '../../../../ui/MUI/Table/Table';
import PlayersTableTooltip from '../PlayersTableTooltip';

const OuterPlayersTable = ({users = []}) => {
  return (
    <DefaultSortTable
      columns={[
        {
          key: 'player',
          field: 'player',
          headerName: 'Игрок',
          minWidth: 275,
          align: 'left',
          headerAlign: 'left',
          flex: 1,
          sortable: false,
          renderCell: (params) => {
            if (window.innerWidth <= 360) {
              params.value.firstName = params.value.firstName[0] + '.';
            }
            return <PlayersHorizontalCard hasImage={false} student={params.value} />;
          },
        },
        {
          key: 'birthDate',
          field: 'birthDate',
          headerName: 'Дата рождения',
          minWidth: 175,
          align: 'left',
          headerAlign: 'left',
          flex: 0.5,
          sortable: false,
        },
        {
          key: 'hockeyRole',
          field: 'hockeyRole',
          headerName: 'Амплуа',
          minWidth: 150,
          align: 'left',
          headerAlign: 'left',
          flex: 0.5,
          sortable: false,
        },
        {
          key: 'hand',
          field: 'hand',
          headerName: 'Хват',
          minWidth: 150,
          align: 'left',
          headerAlign: 'left',
          flex: 1,
          sortable: false,
        },
        {
          key: 'potential',
          field: 'potential',
          headerName: 'Потенциал',
          minWidth: 150,
          align: 'left',
          headerAlign: 'left',
          flex: 1,
          sortable: false,
          renderCell: (params) => <PlayersTableTooltip associations={POTENTIAL_ASSOCIATIONS} data={params.value} />,
        },
        {
          key: 'roleModel',
          field: 'roleModel',
          headerName: 'Ролевая модель',
          minWidth: 175,
          align: 'left',
          headerAlign: 'left',
          flex: 1,
          sortable: false,
          renderCell: (params) => <PlayersTableTooltip associations={ROLE_MODEL_ASSOCIATIONS} data={params.value} />,
        },
        {
          key: 'actions',
          field: 'actions',
          headerName: 'Действиe',
          minWidth: 150,
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          sortable: false,
          renderCell: (params) => {
            return (
              <ShowButton>
                <Link className="BreakLinkStyle" to={`/trainer_report/outer/users/${params.value}`}>
                  Смотреть
                </Link>
              </ShowButton>
            );
          },
        },
      ]}
      hasMaxHeight={false}
      rows={users?.map((student, index) => {
        return {
          id: `${student.id}.id ${index}`,
          player: student,
          birthDate: moment(student.birthdayTimestamp).format('DD.MM.YYYY'),
          hockeyRole: HOCKEY_ROLES[student.hockeyRole],
          hand: HAND_MAP[student.hand],
          potential: student.potential,
          roleModel: student.roleModel,
          actions: student.id,
        };
      })}
    />
  );
};

OuterPlayersTable.propTypes = {
  role: PropTypes.string,
  users: PropTypes.array,
};

export default OuterPlayersTable;
