import TableTemplate from '@common/Statistics/PlayerStatistics/TableTemplate';
import TopFiveItem from '@common/Statistics/PlayerStatistics/TopFiveItem';
import {customTableComparator} from '@src/helpers';
import {Tooltip, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

import SingleProgressCell from '../../SingleProgressCell';

const columns = [
  {
    accessorKey: 'takeaways',
    header: () => {
      return (
        <Tooltip title="Отборы">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Отб
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {position: 'relative', textAlign: 'center'},
    cell: ({getValue}) => {
      return <SingleProgressCell percent={Math.abs(getValue()?.value)} value={getValue()?.value} />;
    },
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'takeawaysOZ',
    header: () => {
      return (
        <Tooltip title="Отборы в зоне атаки">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Отб ЗА
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'takeawaysDZ',
    header: () => {
      return (
        <Tooltip title="Отборы в зоне защиты">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Отб ЗЩ
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'giveaways',
    header: () => {
      return (
        <Tooltip title="Потери">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Птр
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'giveawaysOZ',
    header: () => {
      return (
        <Tooltip title="Потери в зоне атаки">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Птр ЗА
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'giveawaysDZ',
    header: () => {
      return (
        <Tooltip title="Потери в зоне защиты">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Птр ЗЩ
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'takesOZ',
    header: () => {
      return (
        <Tooltip title="Подборы в зоне атаки">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Пдб ЗА
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
  {
    accessorKey: 'takesDZ',
    header: () => {
      return (
        <Tooltip title="Подборы в зоне защиты">
          <Typography sx={{display: 'inline-block'}} variant="subtitle2">
            Пдб ЗЩ
          </Typography>
        </Tooltip>
      );
    },
    headerStyle: {height: '44px !important', maxHeight: 44, textAlign: 'center'},
    cellStyle: {textAlign: 'center'},
    cell: ({getValue}) => <TopFiveItem value={getValue()} />,
    sortingFn: customTableComparator,
  },
];
function PuckBattleTab({isLoad, isLoading, data, hasError, hasMatchFilter}) {
  const [sorting, setSorting] = useState([{asc: true, id: 'takeaways'}]);
  const rowsData = useMemo(
    () =>
      data?.map((item) => {
        return {
          student: item?.student,
          takeaways: item?.takeaways,
          takeawaysOZ: item?.takeawaysOZ,
          takeawaysDZ: item?.takeawaysDZ,
          giveaways: item?.giveaways,
          giveawaysOZ: item?.giveawaysOZ,
          giveawaysDZ: item?.giveawaysDZ,
          takesOZ: item?.takesOZ,
          takesDZ: item?.takesDZ,
        };
      }),
    [data],
  );

  return (
    <TableTemplate
      columns={columns}
      hasError={hasError}
      hasMatchFilter={hasMatchFilter}
      isLoad={isLoad}
      isLoading={isLoading}
      rowsData={rowsData}
      setSorting={setSorting}
      sorting={sorting}
    />
  );
}

PuckBattleTab.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PuckBattleTab;
