import VideoModalTeamPick from '@common/Statistics/PlayerStatistics/VideoModalTeamPick';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {setTeamActionsSide, setVideoModal} from '@slices/statistics';
import {Autocomplete, Box, Button, Skeleton, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';

Buttons.propTypes = {
  typesGetter: PropTypes.func.isRequired,
  setter: PropTypes.func.isRequired,
  cleanup: PropTypes.func,
  loader: PropTypes.func.isRequired,
};
function Buttons({
  typesGetter = () => {},
  filterGetter = () => {},
  setter = () => {},
  cleanup = () => {},
  loader = () => {},
  hasVideo = false,
}) {
  const dispatch = useStoreDispatch();
  const {data, isLoading} = useStore(typesGetter);
  const {
    actionIds: [activeButton],
    types,
  } = useStore(filterGetter);

  const setFilters = useCallback((value = {}) => dispatch(setter({...value})), []);
  const {options, label} = useMemo(() => {
    const result = data?.find((item) => item?.id === activeButton);
    return {
      options: result?.subTypes?.map((item) => ({...item, label: item?.name, value: item?.engName})),
      label: result?.name,
    };
  }, [activeButton, data]);

  useEffect(() => {
    dispatch(
      loader({}, (data) => {
        setFilters({actionIds: [data?.[0]?.id]});
      }),
    );
    return () => {
      cleanup();
    };
  }, []);

  return (
    <Stack direction="column" gap={5}>
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2}>
        {data.map((item, index) => {
          const isActive = item?.id === activeButton;
          return (
            <Skeleton isLoading={isLoading} key={index}>
              <Button
                color={isActive ? 'primary' : 'secondary'}
                size="small"
                startIcon={
                  <Icon active={isActive}>
                    <Typography className="icon-text" variant="caption">
                      {item?.engNameShort}
                    </Typography>
                  </Icon>
                }
                variant="contained"
                onClick={() => (isActive ? '' : setFilters({actionIds: [item.id], types: []}))}
              >
                {item?.name}
              </Button>
            </Skeleton>
          );
        })}
      </Stack>
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={{xxs: 3, sm: 1}} justifyContent="space-between">
        <div>
          {options?.length && (
            <Box width={260}>
              <Autocomplete
                multiple
                isOptionEqualToValue={(option, value) => option.engName === value.engName}
                label={label}
                limitTags={1}
                options={options}
                value={types}
                onChange={(_, types) => {
                  setFilters({types});
                }}
              />
            </Box>
          )}
        </div>
        {hasVideo && (
          <VideoModalTeamPick
            label="Смотреть все:"
            onClick={(value) => {
              dispatch(setVideoModal({isOpen: true}));
              dispatch(setTeamActionsSide(value));
            }}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default Buttons;

const Icon = styled(Box)`
  & .icon-text {
    font-size: 8px;
    line-height: 8px;
    color: ${({theme, active}) => (active ? theme.palette.primary.main : '#fff')};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({active}) => (active ? '#fff' : grey['54'])};
  flex-shrink: 0;
`;
