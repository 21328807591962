import {reducer} from '@reducers/index';
import {configureStore} from '@reduxjs/toolkit';
import {IS_PRODUCTION} from '@src/constants';

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  devTools: !IS_PRODUCTION,
});

if (IS_PRODUCTION && module.hot) {
  module.hot.accept('../redux/reducers', () => store.replaceReducer(reducer));
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
