import {FUNCTIONAL_STATE_INDICATORS_MATRIX as indicatorColor} from '@components/apps/FunctionalTesting/constants';
import {useFuncTestingLastTest} from '@components/apps/PlayerPersonalFile/hooks/useFuncTestingLastTest';
import date from '@helpers/date';
import {longDash} from '@src/constants';
import {Box, Stack, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {isEmpty} from 'lodash';
import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';

import NamedCard from '../NamedCard';

FuncTestingCard.propTypes = {};
const pieParams = {
  0: {
    color: [grey['24']],
    value: 0,
    label: 'Не выбрано',
  },
  1: {
    color: indicatorColor[1],
    value: 1,
    label: 'Перетренированность',
  },
  2: {
    color: indicatorColor[2],
    value: 2,
    label: 'Переутомление',
  },
  3: {
    color: indicatorColor[3],
    value: 3,
    label: 'Напряжение/Переутомление',
  },
  4: {
    color: indicatorColor[4],
    value: 4,
    label: 'Напряжение',
  },
  5: {
    color: indicatorColor[5],
    value: 5,
    label: 'Оптимальное/Напряжение',
  },
  6: {
    color: indicatorColor[6],
    value: 6,
    label: 'Оптимальное',
  },
};

function FuncTestingCard() {
  const {id} = useParams();
  const {data, isLoading, error} = useFuncTestingLastTest(id);

  return (
    <NamedCard flex={1} minWidth={240} title="ФТ" width="fill-available">
      <Stack direction="column" height="100%" justifyContent="space-between" position="relative">
        {(isLoading || !isEmpty(data)) && data?.functional > 0 && !error ? (
          <>
            <Stack direction="row" gap={4} justifyContent="space-between" mb={1}>
              <Typography variant="h4">{data?.readiness === 2 ? 'Готов' : 'Не готов'}</Typography>
              <Stack alignItems="center" direction="row" gap={1}>
                <Indicator color={pieParams?.[data?.functional || '0']?.color} />
                <Typography isLoading={isLoading} preloaderWidthBetween={[120, 150]} variant="body2">
                  {pieParams[data?.functional || 0]?.label}
                </Typography>
              </Stack>
            </Stack>
            <Stack alignItems="center" justifyContent="center" width="100%">
              <Pie value={data?.functional || 0} width="100px" />
            </Stack>
            <Stack direction="row" gap={0}>
              <Typography color={grey[54]} variant="body1">
                Дата контроля:
              </Typography>
              <Typography color={grey[54]} isLoading={isLoading} preloaderWidthBetween={[40, 60]} variant="subtitle1">
                {data?.date ? date(data.date).format('DD.MM.YYYY') : longDash}
              </Typography>
            </Stack>
          </>
        ) : (
          <Typography m="auto" variant="subtitle1">
            Отчетов пока нет
          </Typography>
        )}
      </Stack>
    </NamedCard>
  );
}

export default FuncTestingCard;

const Pie = styled(Box)`
  @property --p {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
  }
  --p: ${({value}) => (pieParams[value]?.value / 6) * 100};
  --b: 12px;
  --c: ${({value}) => pieParams[value]?.color?.[0]};
  --c2: ${({value}) => pieParams[value]?.color?.[1] || pieParams[value]?.color?.[0]};
  --w: ${({width}) => width};

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  transition: 0.3s ease-in-out;
  &:before,
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }
  &:before {
    inset: 0;
    background:
      radial-gradient(farthest-side, var(--c2) 98%, #0000) top/var(--b) var(--b) no-repeat,
      conic-gradient(
        var(--c2) calc(var(--p) / 2.3 * 1%),
        var(--c) calc(var(--p) / 1.7 * 1%) calc(var(--p) * 1%),
        ${grey['24']} 0
      );
    mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
  }
  &:after {
    inset: calc(50% - var(--b) / 2);
    background: var(--c);
    transform: rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 2));
  }

  animation: p 1s 0.5s both;
  &.no-round:before {
    background-size:
      0 0,
      auto;
  }
  &.no-round:after {
    content: none;
  }
  @keyframes p {
    from {
      --p: 0;
    }
  }
`;

const Indicator = styled(Box)`
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  background: ${({color}) => `linear-gradient(90deg, ${color[0]} 50%, ${color?.[1] || color?.[0]} 50%)`};
`;
