import Stack from '@ui/MUI/Stack';
import TypographyDefault from '@ui/MUI/Typography';
import React from 'react';

const BlockWrapper = ({title, children}) => {
  return (
    <Stack spacing={3}>
      {title && <TypographyDefault variant="h4">{title}</TypographyDefault>}
      <div>{children}</div>
    </Stack>
  );
};

export default BlockWrapper;
