import React from 'react';

function Success() {
  return (
    <React.Fragment>
      <g>
        <circle cx="8" cy="8" fill="#57B557" r="8" />
        <path
          d="M6.81602 10.5131L5.06007 8.638C4.8644 8.42907 4.55335 8.42907 4.35769 8.638C4.16202 8.84694 4.16202 9.17909 4.35769 9.38803L6.45981 11.6328C6.65548 11.8417 6.97155 11.8417 7.16721 11.6328L12.4852 5.95934C12.6809 5.7504 12.6809 5.41825 12.4852 5.20931C12.2896 5.00038 11.9785 5.00038 11.7829 5.20931L6.81602 10.5131Z"
          fill="white"
        />
      </g>
    </React.Fragment>
  );
}
export default Success;
