import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
  background-color: white;
  border-radius: 6px;
`;

export const HockeyLineWrapper = styled.div``;

export const HockeyLineHeader = styled.div`
  font: bold normal 20px/22px 'Bebas Neue';
  color: #2c2c2c;
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: #fffaed;
  padding: 15px 34px;
  border-radius: 6px 6px 0 0;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.3);
`;

export const HockeyLineContentWrapper = styled.div`
  background-color: #fff;
  border-radius: 0 0 6px 6px;
`;
export const HockeyLineContent = styled.div`
  min-height: 40px;
`;
export const HockeyLineContentWithoutWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 5px 20px;
  background: white;
  box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
  border-radius: 6px;
`;
export const HockeyLineContentWithoutStatus = styled.p`
  background: #990011;
  border-radius: 3px;
  font: 600 normal 14px/16px 'Proxima Nova';
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 6px;
  color: #fff;
`;
export const HockeyLineContentWithoutText = styled.p`
  font: bold normal 14px/16px 'Proxima Nova';
  color: #686868;
  margin-left: 15px;
`;
export const LinesBlockHeadTitle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 12px/28px 'Proxima Nova';
  color: #747474;
  letter-spacing: 0.02em;
  text-align: center;
`;
export const LinesBlockHead = styled.div`
  border-top: 1px solid #dddad4;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 1200px) {
    display: none;
  }
  & > ${LinesBlockHeadTitle} {
    padding-left: 32px;
    padding-right: 0;
    text-align: left;
  }
`;
export const LinesBlockHeadColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
export const LinesBlockHeadFlexContainer = styled.div`
  display: flex;
  @media screen and (max-width: 1440px) {
    padding: 0 16px;
  }
  ${LinesBlockHeadTitle} {
    &:last-child {
      width: calc(100% - 52px);
    }
  }
`;
export const LinesBlockHeadColumn = styled.div`
  box-sizing: border-box;
  &:last-child {
    padding-right: 0;
    padding-left: 0;
    flex: 0 0 41%;
    max-width: 41%;
  }
  &:first-child {
    padding-right: 0;
    padding-left: 34px;
    flex: 0 0 59%;
    max-width: 59%;
    @media screen and (max-width: 1440px) {
      padding-left: 0;
    }
  }
`;
export const LinesTab = styled.div`
  position: relative;
`;
export const LinesTabLineContainer = styled.div`
  position: relative;
  padding: 10px 32px;
  background-color: #f3f3f3;
  @media screen and (max-width: 1200px) {
    padding: 10px 18px;
  }
  :nth-child(even) {
    background-color: white;
  }
`;
export const LinesTabLine = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
export const LinesTabLineNumber = styled.p`
  margin-top: 0;
  margin-bottom: 2px;
  font: bold normal 12px/25px 'Proxima Nova';
  color: #2c2c2c;
  text-transform: uppercase;

  @media screen and (min-width: 576px) {
    position: absolute;
    top: 40%;
    left: -10px;
    transform: rotate(-90deg) translateY(-50%);
  }
`;
export const LinesTabLineColumn = styled.div`
  border-right: 2px solid #dddad4;
  box-sizing: border-box;
  padding-left: 32px;
  &:last-child {
    border-right: 0;
  }
  &:not(:last-child) {
    @media screen and (max-width: 575px) {
      padding-bottom: 12px;
    }
  }
  @media screen and (max-width: 1440px) {
    padding-left: 0;
  }
  @media screen and (max-width: 1200px) {
    flex: 0 0 100%;
    max-width: 100%;
    border-right: 0;
  }
`;
export const TeacherHockeyLinesPlayerCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .Card {
    .CardPlayerNumber {
      @media screen and (max-width: 360px) {
        display: block;
      }
    }
  }
`;
export const LinesTabLineColumnWingers = styled(LinesTabLineColumn)`
  flex: 0 0 60%;
  max-width: 60%;
  border-right: 2px solid #dddad4;
  ${TeacherHockeyLinesPlayerCards} {
    .Card,
    .EmptyCard {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
  }
`;
export const LinesTabLineColumnDefensemen = styled(LinesTabLineColumn)`
  flex: 0 0 40%;
  max-width: 40%;
  .Card,
  .EmptyCard {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

export const LinesTabLineContainerGoaltenders = styled(LinesTabLineContainer)`
  padding: 0;
  @media (min-width: 576px) and (max-width: 1200px) {
    padding: 0;
  }
  ${LinesTabLine} {
    ${LinesTabLineNumber} {
      top: 50%;
      left: 32px;
      @media screen and (max-width: 1200px) {
        left: 14px;
      }
    }
  }
`;
export const LinesTabLineColumnGoaltendersHead = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
export const LinesTabLineColumnGoaltendersHeadColumn = styled.div`
  flex: 0 0 60%;
  max-width: 60%;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 0;
  text-align: center;
  @media screen and (max-width: 1440px) {
    padding-left: 16px;
  }
`;
export const LinesTabLineColumnGoaltenders = styled(LinesTabLineColumn)`
  flex: 0 0 59%;
  max-width: 59%;
  padding: 12px 0 12px 64px;
  @media (min-width: 1201px) and (max-width: 1440px) {
    padding-left: 30px;
  }
  @media screen and (max-width: 1200px) {
    padding-left: 18px;
  }
  ${TeacherHockeyLinesPlayerCards} {
    .Card,
    .EmptyCard {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
    .CardHand {
      &_right {
        right: -8px;
      }
      &_left {
        left: -8px;
      }
    }
    .CardRemovePlayer {
      right: 4px;
    }
    .CardStatus {
      .CardImgWrapper {
        :before {
          left: 10px;
        }
      }
    }
  }
`;
