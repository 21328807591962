import React from 'react';
import styled from 'styled-components';

export default function Table(props) {
  const {title = '', table = React.ReactNode, children} = props;

  return (
    <TableWrapper>
      {title && <TableTitle>{title}</TableTitle>}
      <TableContainer>{table || children}</TableContainer>
    </TableWrapper>
  );
}

const TableContainer = styled.div`
  position: relative;
  margin-right: -24px;
  margin-left: -24px;
  .TableColumnLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    min-height: 52px;
    width: 100%;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 transparent;
    }
    .LinesAvailablePlayerAvatar {
      width: 40px;
      height: 40px;
      margin-left: 0;
    }
  }
  .BreakLinkStyle {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
  }
  .MuiDataGrid {
    &-root {
      border: 0;
      .MuiDataGrid {
        &-columnHeader {
          padding-right: 24px;
          padding-left: 24px;
          border: 0;
        }
        &-row {
          &:first-child {
            .MuiDataGrid-cell {
              border-top: 1px solid rgba(224, 224, 224, 1);
            }
          }
        }
        &-cell {
          padding-right: 24px;
          padding-left: 24px;
        }
      }
    }
    &-columnSeparator {
      display: none !important;
    }
    &-actionsCell {
      .MuiButton {
        &-root {
          color: inherit !important;
        }
      }
    }
  }
`;
const TableWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
const TableTitle = styled.p`
  margin-top: 0;
  margin-bottom: 8px;
  font: 600 normal 18px/24px 'Proxima Nova';
  color: #2c2c2c;
  text-transform: uppercase;
`;
