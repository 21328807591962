import {getMatchgoaltendersnames} from '@api/StatsAPI';
import useDeepEffect from '@hooks/useDeepEffect';
import {useState} from 'react';

function useStatsGoaltendersList(statisticsIds) {
  const [isplayerIdsLoading, setIsplayerIdsLoading] = useState(false);
  const [playerIds, setPlayerIds] = useState([]);
  useDeepEffect(() => {
    if (statisticsIds?.length) {
      setIsplayerIdsLoading(true);
      getMatchgoaltendersnames({
        statisticsIds,
      })
        .then(({data}) =>
          setPlayerIds(
            data?.names?.map((res) => ({
              label: res.playerName,
              value: res.playerId,
            })) || [],
          ),
        )
        .finally(() => setIsplayerIdsLoading(false));
    }
  }, [statisticsIds]);
  return {
    playerIds,
    isplayerIdsLoading,
  };
}

export default useStatsGoaltendersList;
