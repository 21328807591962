import TypographyDefault from '@ui/MUI/Typography';
import styled from 'styled-components';

export const TeamName = styled(TypographyDefault)`
  padding-right: 20px;
  position: relative;
  width: fit-content;

  &::before {
    position: absolute;
    content: '';
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.bgc};
  }
  &.lastTeamName {
    padding-right: 0;
    padding-left: 20px;
    &::before {
      left: 0;
      right: unset;
    }
  }
`;

export const Score = styled(TypographyDefault)`
  font-family: 'Proxima Nova', serif;
  font-weight: 600;
  font-size: 52px;
  line-height: 63px;
  padding-bottom: ${({theme}) => theme.spacing(3)};
  position: relative;
  &::before {
    ${({theme}) => theme.typography.h4};
    color: #666666;
    position: absolute;
    content: '${(props) => props.percent}';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  ${({theme}) => theme.breakpoints.down('sm')} {
    font-size: 30px;
    line-height: 37px;
  }
`;
