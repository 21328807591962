import preview from '@assets/images/video_preview.png';
import useDeepMemo from '@hooks/useDeepMemo';
import {number, shape, string} from 'prop-types';
import React, {memo, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

function timeToSeconds(timeString) {
  const sptitedTime = timeString?.split(':');
  if (sptitedTime?.length !== 3) return 0;
  const [hours, minutes, seconds] = sptitedTime.map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

VideoPlayer.propTypes = {
  data: shape({
    videoOffsets: shape({start: number, end: number}),
    videoTime: string,
    linkToVideo: string,
  }),
};

function VideoPlayer({data}) {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const {start, end} = useDeepMemo(() => {
    let start = 0;
    let end = 0;
    const time = timeToSeconds(data?.videoTime);
    if (time) {
      start = time - (data?.videoOffsets?.start || 0);
      end = time + (data?.videoOffsets?.end || 1);
    }
    return {start, end};
  }, [data?.videoTime, data?.videoOffsets]);

  return (
    <Wrapper>
      <ReactPlayer
        controls
        light
        config={{
          youtube: {
            playerVars: {
              version: 3,
              showinfo: 0,
              modestbranding: 1,
              controls: 1,
              start,
              end,
            },
          },
          fileConfig: {
            cacheResponse: false,
          },
        }}
        playing={playing}
        ref={playerRef}
        url={data.linkToVideo}
        width="100%"
        onEnded={() => {
          setPlaying(false);
          playerRef.current.seekTo(start);
        }}
        onStart={() => playerRef.current.seekTo(start)}
      />
    </Wrapper>
  );
}

export default memo(VideoPlayer);

const Wrapper = styled.div`
  background: url(${preview});
  background-size: cover;
  overflow: hidden;
  border-radius: 6px;
`;
