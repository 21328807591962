import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useMediaQuery} from '@mui/material';
import {IconButton, Stack, Typography} from '@ui/MUI';
import PropTypes from 'prop-types';
import React, {memo, useEffect} from 'react';

ToggledHeader.propTypes = {
  isToggled: PropTypes.bool.isRequired,
  setIsToggled: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

function ToggledHeader({isToggled = false, setIsToggled, children}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isToggled && !isMobile) setIsToggled(false);
  }, [isMobile, isToggled]);

  return (
    <Stack alignItems="center" direction="row">
      {!isToggled && <Typography variant="subtitle2">{children}</Typography>}
      {isMobile && (
        <IconButton
          color="primary"
          size="small"
          sx={(theme) => ({
            position: 'absolute',
            top: '50%',
            right: !isToggled ? theme.spacing(3) : 'auto',
            left: isToggled ? '50%' : 'auto',
            transform: isToggled ? 'translate(-50%, -50%)' : 'translateY(-50%)',
            ml: 'auto',
            mr: isToggled ? 'auto' : '0',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            zIndex: 4,
            '&:hover': {backgroundColor: theme.palette.primary.main},
          })}
          variant="contained"
          onClick={() => setIsToggled((prevState) => !prevState)}
        >
          {isToggled ? (
            <ChevronRightIcon color="inherit" fontSize="inherit" />
          ) : (
            <ChevronLeftIcon color="inherit" fontSize="inherit" />
          )}
        </IconButton>
      )}
    </Stack>
  );
}

export default memo(ToggledHeader);
