import useQueryString from '@hooks/useQueryString';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

import useDeepCallback from './useDeepCallback';

useQueryStringTabs.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({label: PropTypes.string.isRequired, value: PropTypes.node.isRequired, id: PropTypes.string}),
  ),
};
function useQueryStringTabs({tabs = [], queryKey = 'tab'}) {
  const [qs, set] = useQueryString();
  const currentTab = qs?.[queryKey];
  const value = useMemo(() => {
    const idIndex = tabs?.findIndex((item, index) => currentTab === item.id || Number(currentTab) === index);
    return idIndex >= 0 ? idIndex : 0;
  }, [currentTab, tabs]);
  const onChange = useDeepCallback((tabIndex) => set({...qs, [queryKey]: tabs[tabIndex]?.id || tabIndex}), [qs]);

  return {value, onChange};
}

export default useQueryStringTabs;
