import {FormControl, FormHelperText} from '@mui/material';
import {FormControlProps} from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {MenuItemProps} from '@mui/material/MenuItem/MenuItem';
import MUISelect from '@mui/material/Select';
import {SelectProps} from '@mui/material/Select/Select';
import React from 'react';
import styled from 'styled-components';

import {grey} from '../colors';

type MenuItem = {label: string} & Pick<MenuItemProps, 'value'>;

export type CustomSelectProps = {
  isDisabled?: boolean;
  isMultiple?: boolean;
  hasFixedLabel?: boolean;
  controlProps?: FormControlProps;
  minWidth?: React.CSSProperties['minWidth'];
  helperText?: string;
  options?: MenuItem[];
} & SelectProps;

const CustomizedSelect = styled(
  ({controlProps, hasFixedLabel = false, minWidth, error, helperText, ...props}: CustomSelectProps) => {
    return (
      <Wrapper hasFixedLabel={hasFixedLabel} minWidth={minWidth}>
        <FormControl fullWidth {...controlProps} error={error}>
          <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
          <MUISelect {...props}>
            {props.options?.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </MUISelect>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </Wrapper>
    );
  },
)``;

export default function Select(props: CustomSelectProps) {
  const {
    required = false,
    isMultiple,
    error = false,
    label = '',
    options = [],
    defaultValue,
    value,
    variant = 'standard',
    size = 'small',
    isDisabled = false,
    onChange = () => {},
    renderValue,
    controlProps = {},
    hasFixedLabel = true,
    minWidth = '180px',
    disabled,
  } = props;

  return (
    <CustomizedSelect
      {...props}
      controlProps={{variant, size, ...controlProps}}
      defaultValue={defaultValue}
      disabled={disabled || isDisabled}
      error={error}
      hasFixedLabel={hasFixedLabel}
      label={label}
      minWidth={minWidth}
      multiple={isMultiple}
      options={options}
      renderValue={renderValue}
      required={required}
      value={isMultiple ? value || [] : value || ''}
      onChange={onChange}
    />
  );
}

const Wrapper = styled.div<{minWidth: React.CSSProperties['minWidth']; hasFixedLabel: boolean}>`
  min-width: ${({minWidth}) => minWidth};
  .MuiInput {
    &-root {
      width: 100%;
      &:not(.Mui-error)::before,
      &:not(.Mui-error)::after {
        border-color: rgba(0, 0, 0, 0.42) !important;
      }
    }
  }
  .MuiInputLabel {
    &-root {
      padding-top: 0;
      padding-bottom: 4px;
      font: 400 normal 12px/1 'Proxima Nova';
      letter-spacing: 0.15px;
      color: ${grey['54']};
      transform: ${({hasFixedLabel}) => Boolean(hasFixedLabel) && 'none'};
      &.Mui-focused {
        color: inherit;
      }
    }
  }
  .MuiSelect {
    &-select {
      &.MuiInputBase {
        &-input {
          &.MuiInput {
            &-input {
              &:focus {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
`;
