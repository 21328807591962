import useDeepEffect from '@hooks/useDeepEffect';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectPlayerDiaryList, selectPlayerDiaryReportAverage} from '@selectors/playerDiary';
import {
  selectArchivePlayers,
  selectCategories,
  selectCategoriesEstimates,
  selectEstimatesList,
  selectFilters,
  selectPlayers,
} from '@selectors/skill';
import {loadPlayerDiaryList, loadPlayerDiaryOverallAverage} from '@slices/playerDiary';
import {
  fetchArchiveEstimates,
  fetchCategories,
  fetchEstimatesGroupedByCategory,
  fetchEstimatesGroupedByPlayers,
  fetchEstimatesList,
} from '@slices/skill';
import {useEffect, useMemo} from 'react';

export const useCategories = () => {
  const categories = useStore(selectCategories);

  const dispatch = useStoreDispatch();
  useEffect(() => {
    if (!categories?.data?.length) {
      dispatch(fetchCategories());
    }
  }, []);

  return categories;
};

export const usePlayersEstimates = () => {
  const filters = useStore(selectFilters({entityName: 'players'}));
  const players = useStore(selectPlayers);
  const dispatch = useStoreDispatch();

  useDeepEffect(() => {
    const groupsIds = [filters?.group?.value];
    const categoriesIds = filters?.categories.map((category) => category.id);
    const specialistsIds = filters?.specialists?.map((specialist) => specialist.value);

    if (filters?.group?.value) {
      dispatch(fetchEstimatesGroupedByPlayers({groupsIds, categoriesIds, specialistsIds, isCompleted: false}));
    }
  }, [filters]);

  const isNotFilledFilters = useMemo(() => {
    return Boolean(!filters?.group?.value);
  }, [filters]);

  return {...players, isNotFilledFilters};
};

export const useArchiveEstimates = () => {
  const filters = useStore(selectFilters({entityName: 'archive'}));
  const archivePlayers = useStore(selectArchivePlayers);
  const isNotFilledFilters = Boolean(!filters?.group?.value);
  const isNoData = !archivePlayers?.data?.length && archivePlayers.isLoad;
  const dispatch = useStoreDispatch();

  useEffect(() => {
    const groupsIds = [filters?.group?.value];
    if (filters?.group?.value) {
      dispatch(fetchArchiveEstimates({groupsIds}));
    }
  }, [filters]);

  return {...archivePlayers, isNotFilledFilters, isNoData};
};

export const useCategoriesEstimates = (params) => {
  const filters = useStore(selectFilters({entityName: 'categoriesEstimates'}));
  const categoriesEstimates = useStore(selectCategoriesEstimates);
  const isNoData =
    !categoriesEstimates.data.some((category) => category.estimates.length) && categoriesEstimates.isLoad;
  const dispatch = useStoreDispatch();

  useEffect(() => {
    const groupsIds = params.groupsIds || [filters?.group?.value];
    const categoriesIds = filters?.categories.map((category) => category.id);
    dispatch(fetchEstimatesGroupedByCategory({groupsIds, categoriesIds, isCompleted: false}));
  }, [filters]);

  return {...categoriesEstimates, isNoData};
};

export const useEstimates = ({playersIds = [], isCompleted}) => {
  const estimates = useStore(selectEstimatesList);
  const dispatch = useStoreDispatch();
  const isNoData = !estimates?.data?.length && estimates.isLoad;

  useDeepEffect(() => {
    if (playersIds?.length) {
      dispatch(fetchEstimatesList({playersIds, isCompleted}));
    }
  }, [playersIds, isCompleted]);

  return {...estimates, isNoData};
};

export const usePlayerDiaryList = ({filters = {}, playersIds = []}) => {
  const dispatch = useStoreDispatch();
  const diaryList = useStore(selectPlayerDiaryList);
  const isNoData = !diaryList?.data?.length && diaryList.isLoad;
  useEffect(() => {
    if (filters?.interval?.length > 1) {
      const [startDate, endDate] = filters.interval;
      if (startDate && endDate) {
        dispatch(loadPlayerDiaryList({playersIds, startDate, endDate, type: filters?.type?.value}));
      }
    }
  }, [filters?.interval?.[0], filters?.interval?.[1], filters?.type?.value, playersIds?.length]);
  return {data: diaryList.data, isNoData, isLoad: diaryList.isLoad, isLoading: diaryList.isLoading};
};

export const usePlayerDiaryAverage = ({filters = {}, groupId}) => {
  const dispatch = useStoreDispatch();
  useEffect(() => {
    if (filters?.interval?.length > 1) {
      const [startDate, endDate] = filters.interval;
      if (startDate && endDate) {
        dispatch(loadPlayerDiaryOverallAverage({groupId, startDate, endDate}));
      }
    }
  }, [filters?.interval?.[0], filters?.interval?.[1], groupId]);
  const diaryListAverage = useStore(selectPlayerDiaryReportAverage);
  const isNoData = !diaryListAverage?.data?.length && diaryListAverage.isLoad;
  return {
    data: diaryListAverage.data,
    isNoData,
    isLoad: diaryListAverage.isLoad,
    isLoading: diaryListAverage.isLoading,
  };
};
