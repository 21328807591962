import styled from 'styled-components';

export const AuthorWrapper = styled.div`
  .authorWrapper__name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: block;
  }
  .authorWrapper__role {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #747474;
    display: block;
  }
`;

export const Cell = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
