import UsersAPI from '@api/UsersAPI';
import React, {memo, useEffect} from 'react';

import CommonHelper from '../../../../helpers/CommonHelper';
import useDeepEffect from '../../../../hooks/useDeepEffect';
import SelectDefault from '../../../ui/Select';

const getOptionsAndValue = (
  value,
  name,
  updateOptions,
  updateValue,
  updateLoading,
  groups,
  schools,
  getStudentByGroup,
  filters,
  isTeacher,
  userId,
  updateDisable,
) => {
  const isFiltersHaveGroup = filters.some((filter) => filter.name === 'group');
  const isFiltersHaveStudent = filters.some((filter) => filter.name === 'student');
  const isFiltersGroupOptions = filters.some((filter) => filter.name === 'group' && filter.options.length);
  const isFiltersStudentOptions = filters.some((filter) => filter.name === 'student' && filter.options.length);

  if (name === 'school') {
    const schoolGroups = groups.filter((group) => group.schoolId === value.value);
    if (isFiltersHaveGroup && isFiltersGroupOptions) {
      updateValue('group', null);
      updateOptions('group', []);
    }

    if (isFiltersHaveStudent && isFiltersStudentOptions) {
      updateValue('student', null);
      updateOptions('student', []);
    }

    if (isTeacher) {
      const options = schoolGroups.filter((school) => school.teachersIds.includes(userId));
      updateDisable('group', false);

      updateOptions('group', CommonHelper.getDropDownTeamsByNamedList(options));
      return;
    }
    updateOptions('group', CommonHelper.getDropDownTeamsByNamedList(schoolGroups));
  }

  if (name === 'group') {
    if (isFiltersHaveStudent && isFiltersStudentOptions) {
      updateValue('student', null);
      updateOptions('student', []);
    }

    updateLoading('student', true);
    getStudentByGroup(value.value);
  }
};

const updateGroupsBySchoolId = (id, groups, updateOptions, withOutSorting, isTeacher, userId) => {
  if (id && groups) {
    const schoolGroups = groups.filter((group) => group.schoolId === id);
    if (schoolGroups.length) {
      const sortedGroups = CommonHelper.getDropDownTeamsByNamedList(schoolGroups);
      if (isTeacher && userId) {
        const options = groups.filter((group) => group.teachersIds.includes(userId));

        updateOptions('group', CommonHelper.getDropDownTeamsByNamedList(options));
        return schoolGroups;
      }
      updateOptions('group', sortedGroups);
      if (!withOutSorting) {
        return sortedGroups;
      }
    }
    return schoolGroups;
  }
};

const PureSelect = (props) => {
  const {
    name,
    updateOptions,
    filters,
    updateValue,
    onChange,
    groups,
    schools,
    options,
    updateLoading,
    isTeacher,
    userId,
    isUserStudent,
    updateDisable,
    isSchoolsAdmin,
    isFranchisee,
    isDoctor,
    currentUser,
    isAdmin,
    isPsycho,
  } = props;

  const isSchool = name === 'school';
  const isGroup = name === 'group';
  const isStudent = name === 'student';

  const school = filters.find((filter) => filter.name === 'school');
  const group = filters.find((filter) => filter.name === 'group');
  const student = filters.find((filter) => filter.name === 'student');

  const getStudentByGroup = async (id, isFirstLoad) => {
    const students = await UsersAPI.getStudentsV2({
      active: true,
      groupId: id,
      page: 1,
      limit: 999,
    });
    const sortedStudents = CommonHelper.getDropDownItemsByUsers(students?.result);
    updateOptions('student', sortedStudents);
    updateLoading('student', false);
    if (isFirstLoad && student.qsValue) {
      const currentStudent = sortedStudents.find((cStudent) => cStudent.value === student.qsValue);
      updateValue('student', currentStudent);
    }
  };

  useDeepEffect(() => {
    if (isSchool && schools.length && groups.length) {
      if (isAdmin) {
        updateOptions(name, CommonHelper.getDropDownItemsByNamedList(schools));
      }

      if (isTeacher && userId && !school?.value?.value) {
        // updateDisable('group', true);
        const teacherSchool = schools.filter((school) => school.teachersIds.includes(userId));
        if (teacherSchool.length) {
          const options = teacherSchool.map((school) => ({
            value: school.id,
            label: school.name,
          }));

          if (teacherSchool.length > 1) {
            updateOptions('school', options);
            updateValue('school', options[0]);
          }

          if (teacherSchool.length === 1) {
            updateDisable('school', true);
            updateValue('school', options[0]);
          }

          if (group) {
            const schoolGroups = updateGroupsBySchoolId(
              teacherSchool[0].id,
              groups,
              updateOptions,
              true,
              isTeacher,
              userId,
            );

            if (schoolGroups?.length) {
              const teacherGroups = schoolGroups.filter(
                (cGroup) => group?.qsValue === cGroup.id || cGroup.teachersIds.includes(userId),
              );

              if (teacherGroups) {
                const options = teacherGroups.map((group) => ({
                  label: group.name,
                  value: group.id,
                }));

                if (teacherGroups.length > 1) {
                  updateDisable('group', false);
                  updateOptions('group', options);
                  updateValue('group', options[0]);
                }

                if (teacherGroups.length === 1) {
                  updateDisable('group', true);
                  updateValue('group', options[0]);
                }

                if (student) {
                  updateLoading('student', true);
                  getStudentByGroup(options[0].value, true);
                }
              }
            }
          }
        }
      }

      if (isUserStudent && !school?.value?.value) {
        const userSchoolId = currentUser.schoolId;
        const userSchool = schools.find((school) => school.id === userSchoolId);
        updateDisable('school', true);
        updateDisable('group', true);
        updateDisable('student', true);

        if (userSchool) {
          updateValue('school', {value: userSchool.id, label: userSchool.name});
        }

        const userGroup = groups.find((cGroup) => cGroup.studentsIds.includes(userId));

        if (userGroup) {
          updateValue('group', {value: userGroup.id, label: userGroup.name});
        }

        updateValue('student', {
          value: userId,
          label: `${currentUser.lastName} ${currentUser.firstName}`,
        });
      }

      // if (isPsycho && !school?.value?.value) {
      //   const franchiseeSchool = schools.find(cSchool => school.qsValue === cSchool.id);
      //   if (franchiseeSchool) {
      //     updateValue('school', {
      //       value: franchiseeSchool?.id,
      //       label: franchiseeSchool?.name,
      //     });

      //     const schoolGroups = updateGroupsBySchoolId(franchiseeSchool.id, groups, updateOptions);
      //     if (group.qsValue) {
      //       const queryGroup = schoolGroups.find(cGroup => cGroup.value === group.qsValue);
      //       updateValue('group', queryGroup);
      //       if (student.qsValue && queryGroup) {
      //         getStudentByGroup(queryGroup.value, true);
      //       }
      //     }
      //   }
      // }

      if ((isFranchisee || isSchoolsAdmin || isDoctor || isPsycho) && !school?.value?.value) {
        let franchiseeSchool = [];

        if (school.qsValue) {
          franchiseeSchool = schools.filter((cSchool) => {
            return school.qsValue === cSchool.id;
          });
        } else {
          franchiseeSchool = schools.filter((cSchool) => {
            return school.qsValue === cSchool.id || currentUser.schoolsIds.includes(cSchool.id);
          });
        }

        const options = franchiseeSchool.map((school) => ({
          value: school.id,
          label: school.name,
        }));

        if (isSchoolsAdmin) {
          updateDisable('school', true);
        }

        if (franchiseeSchool.length > 1) {
          updateOptions('school', options);
          updateValue('school', options[0]);
        }

        if (franchiseeSchool.length === 1) {
          updateValue('school', options[0]);
          updateDisable('school', true);
        }

        // updateValue('school', {
        //   value: franchiseeSchool.id,
        //   label: franchiseeSchool.name,
        // });

        const schoolGroups = updateGroupsBySchoolId(franchiseeSchool[0].id, groups, updateOptions);

        if (group.qsValue) {
          const queryGroup = schoolGroups.find((cGroup) => cGroup.value === group.qsValue);
          updateValue('group', queryGroup);
          if (student?.qsValue && queryGroup) {
            getStudentByGroup(queryGroup.value, true);
          }
        }
      }
    }
  }, [isSchool, isTeacher, userId, isUserStudent, currentUser, isFranchisee, schools, groups]);

  useEffect(() => {
    if (isTeacher) return;
    if (isGroup && school?.value?.value && groups.length) {
      const schoolGroups = groups.filter((group) => group.schoolId === school?.value?.value);
      if (schoolGroups.length) {
        updateOptions(name, CommonHelper.getDropDownTeamsByNamedList(schoolGroups));
      }
    }
  }, [isGroup]);

  useEffect(() => {
    if (isStudent && group?.value?.value && !options.length) {
      updateLoading(name, true);
      getStudentByGroup(group?.value?.value);
    }
  }, [isStudent]);

  const handelChange = (value, action) => {
    getOptionsAndValue(
      value,
      action.name,
      updateOptions,
      updateValue,
      updateLoading,
      groups,
      schools,
      getStudentByGroup,
      filters,
      isTeacher,
      userId,
      updateDisable,
    );
    onChange(value, action, props.maxValues);
  };

  return <SelectDefault {...props} onChange={handelChange} />;
};

export default memo(PureSelect);
