import styled from 'styled-components';

export const Dates = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 12px;
  ${({theme}) => theme.breakpoints.down('lg')} {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    padding-bottom: ${({theme}) => theme.spacing(0)};
    &:not(:last-child) {
      padding-top: ${({theme}) => theme.spacing(0)};
    }
  }
`;

export const DateContainer = styled.div`
  min-height: 152px;
  background-color: ${({isGame, isNoEvents}) => {
    if (isGame) return '#DB9C98';
    if (isNoEvents) return '#E0E0E0';
    return '#ffffff';
  }};
  opacity: ${({isNotInCurrentMonth}) => isNotInCurrentMonth && '0.6'};
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
  overflow: hidden;
`;

export const DateTop = styled.div`
  display: flex;
  padding: 12px;
  border-bottom: 1px solid ${({isGame}) => (isGame ? '#E0E0E0' : '#C2C2C2')};
`;

export const MainInfoBlock = styled.div`
  height: 84px;
  padding-left: ${(props) => props.theme.spacing(2)};
`;

export const BottomBlock = styled.div`
  display: flex;
  padding: 12px;
  border-top: 1px solid #c2c2c2;
`;

export const NoEvents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84px;
`;
