import SiriusCompareTable from '@components/apps/PhysicalTesting/components/SiriusCompare/components/SiriusCompareTable';
import {FiltersWrapper, SiriusSelect} from '@components/apps/PhysicalTesting/components/SiriusCompare/SiriusCompare';
import {siriusOptions} from '@components/apps/PhysicalTesting/constants';
import Filters from '@components/Filters/Filters';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {selectSiriusInfo} from '@selectors/physicalTesting';
import {fetchSiriusData} from '@slices/PhysicalTestingSirius';
import {clearPhysicalOverallState} from '@src/redux/slices/PhysicalTestingOverall';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import TableEmptyBlock from '@ui/TableEmptyBlock';
import React, {useEffect, useState} from 'react';

const filtersValue = [
  {name: 'school'},
  {name: 'group'},
  {
    name: 'typeOfTests',
    disabled: true,
    value: {
      value: 'sfp',
      label: 'СФП',
    },
  },
  {name: 'season'},
  {name: 'stage'},
];
const SiriusCompare = (props) => {
  const dispatch = useStoreDispatch();
  const {items, isLoading, isLoad} = useStore(selectSiriusInfo);
  const [filters, setFilters] = useState({});
  const [sirius, setSirius] = useState({});

  const isAllFiltersValuesNotEmpty =
    !!filters.group && !!filters.season && !!filters.stage && !!filters.typeOfTests && !!sirius?.value;

  const fetchData = () => {
    if (isAllFiltersValuesNotEmpty && sirius?.value) {
      dispatch(
        fetchSiriusData({
          groupId: filters.group,
          season: filters.season,
          stage: Number(filters.stage),
          type: filters.typeOfTests,
          sirius: sirius?.value,
        }),
      );
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(clearPhysicalOverallState());
    };
  }, [filters, isAllFiltersValuesNotEmpty, sirius]);

  return (
    <>
      <FiltersWrapper>
        <Filters isStage filters={filtersValue} sizes={{xxs: 1}} onChange={setFilters} />
        <SiriusSelect>
          <AutocompleteDefault
            data-testid="age-autocomplete"
            isLoading={isLoading}
            label="Сравнивать с"
            multiple={false}
            name="sirius"
            options={siriusOptions}
            value={sirius?.value ? sirius : null}
            onChange={(_, newValue) => setSirius(newValue)}
          />
        </SiriusSelect>
      </FiltersWrapper>
      {!isAllFiltersValuesNotEmpty ? (
        <TableEmptyBlock text="Для отображения данных заполните фильтры." />
      ) : (
        <>
          {!isLoad && !items?.length ? (
            <TableEmptyBlock text="Нет данных." />
          ) : (
            <SiriusCompareTable isLoading={isLoading} tableData={items} />
          )}
        </>
      )}
      {props?.children && React.cloneElement(props.children, {setParentState: setFilters})}
    </>
  );
};

export default SiriusCompare;
