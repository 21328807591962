import {Box} from '@ui/MUI';
import {bg, grey} from '@ui/MUI/colors';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  overflow: hidden;
  border-radius: 6px;
`;
export const TitleWrapper = styled(Box)`
  background-color: ${bg.yellow};
  border-bottom: 1px solid ${grey['12']};
`;
export const ContentWrapper = styled(Box)`
  background-color: #ffffff;
`;
