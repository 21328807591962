import logo from '@assets/images/logo.svg';
import {EllipsisTypography} from '@components/apps/StatsReportForOneGame/components/ShiftsTimeline/ShiftsTimeline';
import {leftWidth, leftWidthCollapsed} from '@components/templates/ChessTemplate';
import useStore from '@hooks/useStore';
import useStoreDispatch from '@hooks/useStoreDispatch';
import {AppsOutlined} from '@mui/icons-material';
import FormatIndentDecreaseOutlinedIcon from '@mui/icons-material/FormatIndentDecreaseOutlined';
import {useMediaQuery} from '@mui/material';
import {selectIsMenuCollapsed, selectMenuItems} from '@selectors/menu';
import {setMenuCollapsed} from '@slices/menu';
import {MENU_CATEGORIES_LABELS} from '@src/constants/menu';
import {MENU_CATEGORIES_ENUM} from '@src/types/menu';
import {Box, IconButton, Stack, Tooltip, Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import {values} from 'lodash';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import BottomUserPanel from '../BottomUserPanel';
import {
  BottomUserPanelContainer,
  BurgerMenuIcon,
  LogoImage,
  LogoPlaceholder,
  OptionItem,
  OptionItemTitleText,
  OptionsList,
  OptionsListContainerLinks,
  Wrapper,
} from './MySidebar';

const menuCategories = values(MENU_CATEGORIES_ENUM);
function MySidebar(props) {
  const {
    currentUser = {},
    loading,
    active,
    onLogout = () => {},
    onCurrentUserClick = () => {},
    onUserUpdate = () => {},
  } = props;

  const dispatch = useStoreDispatch();
  const isMdResolution = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const {userRole} = currentUser;
  const [showMenu, setShowMenu] = useState(false);
  const menuItems = useStore(selectMenuItems);
  const isMenuCollapsed = useStore(selectIsMenuCollapsed);
  const {pathname} = useLocation();
  const collapsed = useMemo(() => (isMdResolution ? false : isMenuCollapsed), [isMenuCollapsed, isMdResolution]);

  return (
    <Wrapper pr={collapsed ? 1 : '0'} width={collapsed ? leftWidthCollapsed : leftWidth}>
      <LogoPlaceholder collapsed={collapsed}>
        <BurgerMenuIcon active={showMenu} onClick={() => setShowMenu((prevState) => !prevState)}>
          <span />
          <span />
          <span />
        </BurgerMenuIcon>
        <LogoImage data-testid="logo-image" src={logo} />
        {!isMdResolution && (
          <IconButton color="secondary" size="medium" onClick={() => dispatch(setMenuCollapsed(!isMenuCollapsed))}>
            <FormatIndentDecreaseOutlinedIcon fontSize="inherit" sx={{fill: grey['24']}} />
          </IconButton>
        )}
      </LogoPlaceholder>

      <OptionsList
        className="OptionsList"
        collapsed={isMenuCollapsed}
        data-testid="menu-items-container"
        visible={showMenu}
      >
        <OptionsListContainerLinks>
          {userRole &&
            menuCategories?.map((category, index) => {
              const links = menuItems[category];
              const categotyName = MENU_CATEGORIES_LABELS[category];

              if (!links?.length) return null;

              return (
                <React.Fragment key={index}>
                  {categotyName && (
                    <Stack
                      alignItems="center"
                      data-testid="menu-item"
                      direction="row"
                      gap={1}
                      key={'category' + index}
                      ml={2}
                      mt={2}
                    >
                      <AppsOutlined fontSize="25px" sx={{fill: grey['68']}} />
                      <OptionItemTitleText collapsed={collapsed}>{categotyName}</OptionItemTitleText>
                    </Stack>
                  )}
                  {links.map((link, index) => {
                    const {label, url, path, name, isNew, Icon} = link;
                    const isSelected = active === name || pathname === url;
                    return (
                      <Link
                        data-testid="menu-item"
                        key={'link' + index}
                        style={{textDecoration: 'none'}}
                        to={url || path}
                      >
                        <Tooltip placement="right" title={collapsed ? label : ''}>
                          <OptionItem mt="0" pl={2} selected={isSelected}>
                            {Icon && (
                              <Icon
                                fontSize={collapsed ? '24px' : '20px'}
                                sx={{mr: collapsed ? '0' : 1, fill: isSelected ? grey['100'] : grey['68']}}
                              />
                            )}

                            <EllipsisTypography
                              color={isSelected ? grey['100'] : '#FFFAED'}
                              lineHeight="34px !important"
                              overfolw="hidden"
                              variant="subtitle2"
                              width={collapsed ? '0' : 'auto'}
                            >
                              {label}
                            </EllipsisTypography>
                            {isNew && !collapsed && (
                              <Typography color="primary" variant="subtitle2">
                                NEW
                              </Typography>
                            )}
                          </OptionItem>
                        </Tooltip>
                      </Link>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </OptionsListContainerLinks>
        <BottomUserPanelContainer>
          <Box px={2}>
            <BottomUserPanel
              canUpdateAvatar={true}
              isLoading={loading}
              isMenuCollapsed={collapsed}
              user={currentUser}
              onClick={() => {
                setShowMenu(false);
                onCurrentUserClick();
              }}
              onLogout={onLogout}
              onUpdate={(updData) => {
                onUserUpdate(updData);
              }}
            />
          </Box>
        </BottomUserPanelContainer>
      </OptionsList>
    </Wrapper>
  );
}

MySidebar.propTypes = {
  currentUser: PropTypes.object,
  loading: PropTypes.bool,
  active: PropTypes.string.isRequired,
  onLogout: PropTypes.func,
  onCurrentUserClick: PropTypes.func,
  onUserUpdate: PropTypes.func,
};

export default MySidebar;
