import SearchInput from '@common/SearchInput';
import {getOptionLabel, isOptionEqualToValue} from '@components/apps/TrainingControlProcess/constant';
import {useSection} from '@components/apps/TrainingControlProcess/hooks/api';
import {Search} from '@mui/icons-material';
import AutocompleteDefault from '@ui/MUI/Autocomplete';
import Grid from '@ui/MUI/Grid';
import React, {useMemo} from 'react';

import {useSkillsFilters} from './hooks';

const Filters = ({age}) => {
  const {filters, update} = useSkillsFilters({
    search: '',
    section: null,
    category: null,
    age,
  });

  const section = useSection();

  const categories = useMemo(() => {
    if (filters.section?.id) {
      return section.data.find((section) => section.id === filters.section.id).categories;
    }

    return [];
  }, [section.data, filters.section]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SearchInput
            InputProps={{
              endAdornment: <Search sx={{fontSize: '24px', color: 'rgba(0, 0, 0, 0.54)'}} />,
            }}
            label="Поиск по навыкам"
            onChange={(v) => update('search', v)}
          />
        </Grid>
        <Grid item sm={3}>
          <AutocompleteDefault
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            label="Раздел"
            multiple={false}
            options={section.data}
            value={filters.section}
            onChange={(_, v) => update('section', v)}
          />
        </Grid>
        <Grid item sm={5}>
          <AutocompleteDefault
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            label="Направления подготовки"
            multiple={false}
            options={categories}
            value={filters.category}
            onChange={(_, v) => update('category', v)}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default Filters;
