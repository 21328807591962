import React from 'react';

function ReplaceArrows() {
  return (
    <React.Fragment>
      <path
        d="M5.86833 12.0161L4.08587 10.7218H11.363V9.506H4.08582L5.86873 8.21139L5.15394 7.22745L1.17944 10.1139L5.15434 13L5.86833 12.0161Z"
        fill="#990011"
      />
      <path
        d="M7.13127 4.78901L7.84608 5.77293L11.8206 2.88648L7.84608 0L7.13127 0.983914L8.91418 2.27855H1.63696V3.4944H8.91418L7.13127 4.78901Z"
        fill="#990011"
      />
    </React.Fragment>
  );
}
export default ReplaceArrows;
