import React from 'react';

function UralStatistics() {
  return (
    <React.Fragment>
      <g filter="url(#filter0_d_102_5)">
        <path
          d="M17.5689 5.03518H14.5414C14.3034 5.03518 14.1103 5.22824 14.1103 5.46628V8.39731H11.9449V3.4311C11.9449 3.19306 11.7519 3 11.5138 3H8.48621C8.24817 3 8.05511 3.19306 8.05511 3.4311V11.5884H5.88974V7.92397C5.88974 7.68586 5.69668 7.49287 5.45864 7.49287H2.4311C2.19299 7.49287 2 7.68586 2 7.92397V16.1769C2 16.415 2.19306 16.608 2.4311 16.608H5.45864H8.48618H11.5138H14.5414H17.5689C17.807 16.608 18 16.4149 18 16.1769V5.46628C18 5.22824 17.807 5.03518 17.5689 5.03518ZM5.02757 15.7458H2.8622V8.35507H5.02757V15.7458ZM8.05518 15.7458H5.88981V12.4506H8.05518V15.7458ZM11.0828 8.82841V15.7458H8.91735V3.8622H11.0828V8.82841ZM14.1104 15.7458H11.945V9.25951H14.1104V15.7458ZM17.1379 15.7458H14.9726V8.82841V5.89738H17.1379V15.7458Z"
          fill="#626262"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="15.608"
          id="filter0_d_102_5"
          width="18"
          x="1"
          y="2.5"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_102_5" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_102_5" mode="normal" result="shape" />
        </filter>
      </defs>
    </React.Fragment>
  );
}
export default UralStatistics;
