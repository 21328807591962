import {HOCKEY_ROLES} from '@src/constants';
import {handleInputNumberChange, onFloatChange} from '@src/helpers';
import TextFieldDefault from '@ui/MUI/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {Field, InputWrapper} from '../CommonScoutReport';

function OuterPlayerScoreFields({hockeyRole}) {
  const {control} = useFormContext();

  if (HOCKEY_ROLES?.[hockeyRole]) {
    return (
      <>
        <Field margin="0 0 24px 0">
          <Controller
            control={control}
            name="championship"
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <TextFieldDefault
                error={!!error}
                helperText={error?.message}
                inputProps={{maxLength: 500}}
                label={'Первенство'}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{required: 'Заполните поле'}}
          />
        </Field>
        <Field margin="0 0 38px 0">
          <InputWrapper flex={1}>
            <Controller
              control={control}
              name="gameCount"
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextFieldDefault
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{maxLength: 3}}
                  label={'Количество игр'}
                  value={value}
                  onChange={(e) => handleInputNumberChange(e, onChange, 0)}
                />
              )}
              rules={{
                required: 'Заполните поле',
                pattern: {
                  value: /^[0-9]\d*(\d+)?$/i,
                  message: 'Введите число',
                },
              }}
            />
          </InputWrapper>
          {HOCKEY_ROLES?.[hockeyRole] === HOCKEY_ROLES.goaltender && (
            <>
              <InputWrapper flex={1}>
                <Controller
                  control={control}
                  name="impenetrability"
                  render={({field: {value, onChange, onBlur}, fieldState: {error}}) => (
                    <TextFieldDefault
                      error={!!error}
                      helperText={error?.message}
                      inputProps={{step: 0.01, min: 0.01, max: 999.99}}
                      label={'Коэффициент надёжности'}
                      type="number"
                      value={value}
                      onBlur={(e) => {
                        if (!e.target.value) return;
                        onBlur();
                        onChange(onFloatChange(e));
                      }}
                      onChange={(e) => onChange(parseFloat(e.target.value))}
                    />
                  )}
                  rules={{
                    required: 'Заполните поле',
                  }}
                />
              </InputWrapper>
              <InputWrapper flex={1}>
                <Controller
                  control={control}
                  name="deflected"
                  render={({field: {value, onChange, onBlur}, fieldState: {error}}) => (
                    <TextFieldDefault
                      error={!!error}
                      helperText={error?.message}
                      inputProps={{step: 0.01, min: 0.01, max: 999.99}}
                      label={'% отраженных бросков'}
                      type="number"
                      value={value}
                      onBlur={(e) => {
                        if (!e.target.value) return;
                        onBlur();
                        onChange(onFloatChange(e));
                      }}
                      onChange={(e) => onChange(parseFloat(e.target.value))}
                    />
                  )}
                  rules={{
                    required: 'Заполните поле',
                  }}
                />
              </InputWrapper>
            </>
          )}
          {HOCKEY_ROLES?.[hockeyRole] !== HOCKEY_ROLES.goaltender && (
            <>
              <InputWrapper flex={1}>
                <Controller
                  control={control}
                  name="goalPoint"
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <TextFieldDefault
                      error={!!error}
                      helperText={error?.message}
                      inputProps={{maxLength: 3}}
                      label={'Гол'}
                      value={value}
                      onChange={(e) => handleInputNumberChange(e, onChange, 0)}
                    />
                  )}
                  rules={{
                    required: 'Заполните поле',
                    pattern: {
                      value: /^[0-9]\d*(\d+)?$/i,
                      message: 'Введите число',
                    },
                  }}
                />
              </InputWrapper>
              <InputWrapper flex={1}>
                <Controller
                  control={control}
                  name="passPoint"
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <TextFieldDefault
                      error={!!error}
                      helperText={error?.message}
                      inputProps={{maxLength: 3}}
                      label={'Пас'}
                      value={value}
                      onChange={(e) => handleInputNumberChange(e, onChange, 0)}
                    />
                  )}
                  rules={{
                    required: 'Заполните поле',
                    pattern: {
                      value: /^[0-9]\d*(\d+)?$/i,
                      message: 'Введите число',
                    },
                  }}
                />
              </InputWrapper>
            </>
          )}
        </Field>
      </>
    );
  }
  return <></>;
}

OuterPlayerScoreFields.propTypes = {
  hockeyRole: PropTypes.string,
};

export default OuterPlayerScoreFields;
