import {FiltersWrapper, FilterWrapper} from '@components/apps/TrainingControlProcess/TrainingControlProcess';
import {useGroup, useSchool} from '@hooks/filters';
import {Autocomplete, RangePicker} from '@ui/MUI';
import React, {memo} from 'react';

const Filters = ({filters, updateFilters}) => {
  const schools = useSchool({});
  const groups = useGroup({connectorValue: filters.schools});

  return (
    <FiltersWrapper>
      <FilterWrapper minWidth="220px">
        <Autocomplete
          isLoading={schools.isLoading}
          label="Школы"
          options={schools.options}
          value={filters.schools}
          onChange={(_, newValue) => {
            updateFilters({...filters, schools: newValue});
          }}
        />
      </FilterWrapper>
      <FilterWrapper minWidth="331px">
        <Autocomplete
          disabled={!groups.options.length}
          label="Команды"
          options={groups.options}
          value={filters.groups}
          onChange={(_, newValue) => {
            updateFilters({...filters, groups: newValue});
          }}
        />
      </FilterWrapper>
      <FilterWrapper minWidth="184px">
        <RangePicker
          disableRestoreFocus
          endLabel="Конец"
          format="DD.MM.YYYY"
          startLabel="Начало"
          value={filters.period}
          variant="standard"
          onChange={(value) => {
            updateFilters({...filters, period: value});
          }}
        />
      </FilterWrapper>
    </FiltersWrapper>
  );
};

export default memo(Filters);
