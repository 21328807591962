import React from 'react';

function TrendDown() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M10.5032 3.79985H9.55143C9.14555 3.79985 8.81347 4.13193 8.81347 4.5378L8.81347 10.2276H6.64013C5.98335 10.2276 5.65127 11.0246 6.11618 11.4895L9.50339 15.8767C9.64126 16.0149 9.82844 16.0925 10.0236 16.0925C10.2188 16.0925 10.406 16.0149 10.5439 15.8767L13.9311 11.4895C14.396 11.0246 14.0713 10.2276 13.4145 10.2276H11.2412L11.2412 4.5378C11.2412 4.13193 10.9091 3.79985 10.5032 3.79985Z"
          fill="#FF0000"
        />
      </g>
    </React.Fragment>
  );
}
export default TrendDown;
