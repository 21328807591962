import HighLights from '@common/Statistics/PlayerStatistics/ScorePage/components/HighLights';
import TeamLogo from '@common/Statistics/PlayerStatistics/TeamLogo';
import {TEAM_COLORS} from '@src/constants';
import {Box, Divider, Tooltip, Typography} from '@ui/MUI';
import {grey, secondary} from '@ui/MUI/colors';
import Stack from '@ui/MUI/Stack';
import React, {useMemo} from 'react';

import {GameBlock, GameBlockScore, GameBlockTeamColor, Score, TeamName} from './GameInfo';

const GameInfo = ({gameResults, winByOT, winByBuls, isLoading}) => {
  const isHomeTeamWon = gameResults?.score?.data.home > gameResults?.score?.data.away;
  const OTTitle = useMemo(() => (winByBuls ? 'БУЛЛ' : winByOT ? 'ОТ' : ''), [winByOT, winByBuls]);

  return (
    <>
      <Stack
        alignItems="center"
        direction={{xxs: 'column', xs: 'row'}}
        divider={
          <Divider flexItem color={grey['12']} orientation="vertical" sx={{display: {xxs: 'none', xs: 'initial'}}} />
        }
        gap={{xxs: 1, xs: 3}}
        justifyContent="center"
        mb={3}
      >
        {gameResults?.gameInfo?.date && (
          <Typography color={grey['54']} variant="subtitle1">
            {gameResults.gameInfo.date}
          </Typography>
        )}
        {gameResults?.group?.description && (
          <Tooltip title={gameResults.group.description}>
            <Typography sx={{userSelect: 'none'}} variant="subtitle1">
              {gameResults.group.description?.slice(0, 26) + '...'}
            </Typography>
          </Tooltip>
        )}
      </Stack>
      <Stack
        alignItems="center"
        direction={{xxs: 'column', sm: 'row'}}
        gap={{xxs: 2, sm: 5}}
        justifyContent="center"
        width="100%"
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent={{xxs: 'center', sm: 'flex-end'}}
          maxWidth={{xxs: 'auto', sm: 'calc(100% / 3)'}}
          spacing={{xxs: 0, sm: 3}}
          width={{xxs: 'auto', xs: '100%'}}
        >
          <TeamLogo src={gameResults?.teamLogo?.home || gameResults?.homeTeamLogo} width={32} />
          <TeamName
            isLoading={isLoading}
            preloaderProps={{sx: {maxWidth: '70%'}}}
            preloaderWidthBetween={[100, 130]}
            variant="h2"
          >
            {gameResults?.teamNames?.[0]}
          </TeamName>
          <GameBlockTeamColor color={TEAM_COLORS[!gameResults?.weAtHome ? 0 : 1]} />
        </Stack>
        <GameBlock maxWidth={{xxs: 'auto', sm: 'calc(100% / 3)'}}>
          <GameBlockScore direction="row" gap={2} pt={OTTitle && '20px'}>
            <Score
              OTTitle={isHomeTeamWon && OTTitle}
              color={!isHomeTeamWon && grey['68']}
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
            >
              {gameResults?.score?.data.home}
            </Score>
            <Score color={grey['68']}>-</Score>
            <Score
              OTTitle={!isHomeTeamWon && OTTitle}
              color={isHomeTeamWon && grey['68']}
              isLoading={isLoading}
              preloaderWidthBetween={[40, 50]}
            >
              {gameResults?.score?.data.away}
            </Score>
          </GameBlockScore>
          <Typography color={grey['68']} isLoading={isLoading} preloaderWidthBetween={[150, 170]} variant="subtitle1">
            {gameResults?.halfsScores?.map((halfScore, index) => {
              const isLast = index === gameResults?.halfsScores?.length - 1;
              const firstNumber = Number(halfScore?.split(':')?.[0]);
              const secondNumber = Number(halfScore?.split(':')?.[1]);
              const isFirstNumberMore = firstNumber > secondNumber;
              return (
                <React.Fragment key={index}>
                  <Box component="span" sx={{color: isFirstNumberMore ? secondary?.contrastText : grey[68]}}>
                    {firstNumber}
                  </Box>
                  :
                  <Box component="span" sx={{color: !isFirstNumberMore ? secondary?.contrastText : grey[68]}}>
                    {secondNumber}
                    {!isLast && ', '}
                  </Box>
                </React.Fragment>
              );
            })}
          </Typography>
        </GameBlock>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent={{xxs: 'center', sm: 'flex-start'}}
          maxWidth={{xxs: 'auto', sm: 'calc(100% / 3)'}}
          spacing={{xxs: 0, sm: 3}}
          width={{xxs: 'auto', xs: '100%'}}
        >
          <GameBlockTeamColor color={TEAM_COLORS[gameResults?.weAtHome ? 0 : 1]} />
          <TeamName
            isLoading={isLoading}
            preloaderProps={{sx: {maxWidth: '70%'}}}
            preloaderWidthBetween={[100, 130]}
            textAlign={{xxs: 'right', sm: 'left'}}
            variant="h2"
          >
            {gameResults?.teamNames?.[1]}
          </TeamName>
          <TeamLogo src={gameResults?.teamLogo?.away || gameResults?.awayTeamLogo} width={32} />
        </Stack>
      </Stack>
      <Box mt={2}>
        <HighLights isLoading={isLoading} url={gameResults?.linkToVideoHighlights || ''} />
      </Box>
    </>
  );
};

export default GameInfo;
