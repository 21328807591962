import Filters from '@components/apps/PlayerPersonalFile/components/Statistics/components/Filters';
import GoaltenderMetrics from '@components/apps/PlayerPersonalFile/components/Statistics/components/GoaltenderMetrics';
import GoaltenderZones from '@components/apps/PlayerPersonalFile/components/Statistics/components/GoaltenderZones';
import Metrics from '@components/apps/PlayerPersonalFile/components/Statistics/components/Metrics';
import Time from '@components/apps/PlayerPersonalFile/components/Statistics/components/Time';
import Tournaments from '@components/apps/PlayerPersonalFile/components/Statistics/components/Tournaments';
import {shallowEqual} from '@helpers/shallowEqual';
import useStore from '@hooks/useStore';
import {selectStatFilters} from '@selectors/playerPersonalFile';
import {HOCKEY_ROLES_ENUM, YOUNGER_AGE_GROUP} from '@src/constants';
import {parseFormToGetValue} from '@src/helpers';
import {Stack} from '@ui/MUI';
import React, {useMemo} from 'react';

function Statistics() {
  const filters = useStore(selectStatFilters, shallowEqual);
  const {hockeyRole, group} = useStore((state) => state.users?.student, shallowEqual);
  const requestFilters = parseFormToGetValue({...filters, isFinal: Boolean(filters?.tournamentId?.isFinal)});

  const isGoaltender = useMemo(() => HOCKEY_ROLES_ENUM.goaltender === hockeyRole, [hockeyRole]);
  const isYoungerAgeGroup = useMemo(() => group?.ageGroup <= YOUNGER_AGE_GROUP, [group?.ageGroup]);
  return (
    <Stack direction="column" spacing={3}>
      <Filters isYoungerAgeGroup={isYoungerAgeGroup} />
      {isGoaltender ? (
        <GoaltenderMetrics isYoungerAgeGroup={isYoungerAgeGroup} requestFilters={requestFilters} />
      ) : (
        <Metrics isYoungerAgeGroup={isYoungerAgeGroup} requestFilters={requestFilters} />
      )}
      {isGoaltender && !isYoungerAgeGroup && <GoaltenderZones />}
      {!isGoaltender && !isYoungerAgeGroup && <Time requestFilters={requestFilters} />}
      <Tournaments />
    </Stack>
  );
}

export default Statistics;
