import Wrapper from '@components/apps/PlayerPersonalFile/components/Wrapper';
import LineChart from '@components/charts/LineChart';
import {Typography} from '@ui/MUI';
import {grey} from '@ui/MUI/colors';
import React from 'react';

import {TOOLTIP_TITLES_MATRIX} from '../../constants';

function Chart({exerciseId, options, title}) {
  const isBeepTest = exerciseId === 'cc_yDyptaY6b';
  const isYbalance = exerciseId === 'wMhqP7HzsSgC';
  const min = options?.series
    ?.map(({data}) => data)
    ?.flat()
    ?.sort()?.[0];
  const max = options?.series
    ?.map(({data}) => data)
    ?.flat()
    ?.sort()?.[options?.series?.map(({data}) => data)?.flat()?.length - 1];
  return (
    <Wrapper display="flex" mb={1} position="relative" py={3}>
      <LineChart
        options={{
          ...options,
          tooltip: {
            trigger: 'axis',
            textStyle: {
              fontSize: 12,
              fontFamily: 'Proxima Nova',
              fontWeight: 'normal',
              align: 'left',
            },
            formatter: (params) => {
              if (params?.length > 1) {
                const reducedParams = params?.reduce((res, curr) => {
                  const title =
                    TOOLTIP_TITLES_MATRIX[
                      curr?.marker?.substring(curr?.marker?.indexOf('#'), curr?.marker?.lastIndexOf(';'))
                    ];
                  if (title?.length) {
                    res.push(
                      `<p style="position:relative;padding-left:16px;min-width:180px;display:flex;align-items:center;justify-content:space-between;flex-wrap:wrap;"><span style="position:absolute;top:1px;left:0;">${
                        curr.marker
                      }</span><span style="width:100%;">${title}</span><span style="font-weight: bold;">${
                        !options?.isDoubleParam
                          ? curr.value
                          : isBeepTest
                            ? `<span>Уровень: ${curr?.value.split('.')?.[0]}<br/></span><span> Номер отрезка: ${
                                curr?.value.split('.')?.[1]
                              }</span>`
                            : !isYbalance
                              ? `${[...Array(Number(curr.axisValue?.split(';')?.[1]))].fill(`●`)?.join('')} (${curr.value})`
                              : `${curr.value}`
                      }</span> </br></p>`,
                    );
                  }
                  return res;
                }, []);
                return [
                  !options?.isDoubleParam
                    ? `<span style="font-weight:bold;">${params?.[0]?.axisValueLabel?.split(';')?.[0]} ${
                        params?.[0]?.axisValueLabel?.split(';')?.[1]
                      }</span></br>`
                    : `<span style="font-weight:bold;">${params?.[0]?.axisValueLabel?.split(';')?.[0]}</span></br>`,
                  ...reducedParams,
                ].join('\n');
              } else {
                return [
                  !options?.isDoubleParam
                    ? `<span style="font-weight:bold;">${params?.[0]?.axisValueLabel?.split(';')?.[0]} ${
                        params?.[0]?.axisValueLabel?.split(';')?.[1]
                      }</span></br>`
                    : `<span style="font-weight:bold;">${params?.[0]?.axisValueLabel?.split(';')?.[0]}</span></br>`,
                  `<span style="font-weight: bold;">${
                    isBeepTest
                      ? `Уровень: ${params?.[0]?.value?.split('.')?.[0]} </br> Номер отрезка: ${
                          params?.[0]?.value?.split('.')?.[1]
                        }`
                      : params?.[0]?.value
                  }</span> </br>`,
                ]?.join('\n');
              }
            },
          },
          grid: {
            show: true,
            borderWidth: 0.5,
            shadowColor: grey['12'],
            left: !title ? 40 : 60,
            right: 24,
            top: 40,
            bottom: 60,
          },
          xAxis: {
            type: 'category',
            data: options?.xAxis?.data || [],
            splitLine: {
              show: true,
              lineStyle: {
                color: grey['12'],
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              padding: 8,
              formatter: (value) => {
                if (isBeepTest) {
                  return [`${value?.split(';')?.[0]}`]?.flat()?.join('\n');
                } else {
                  return (
                    !options?.isDoubleParam
                      ? [`${value?.split(';')?.[0]}`, `${value?.split(';')?.[1]}`]
                      : [
                          `${value?.split(';')?.[0]}`,
                          `{b|${[...Array(Number(value?.split(';')?.[1]))].fill(`●`)?.join('')}}`,
                        ]?.flat()
                  )?.join('\n');
                }
              },
              textStyle: {lineHeight: 18},
              rich: {
                b: {
                  fontSize: 18,
                },
              },
            },
          },
          yAxis: options?.isInverse
            ? {
                axisLabel: {show: true},
                type: 'value',
                inverse: options?.isInverse || false,
                min: min,
                max: max === min ? max + 1 : max,
              }
            : {
                axisLabel: {show: true},
                type: 'value',
                inverse: options?.isInverse || false,
                splitNumber: 10,
                ...(isBeepTest && {
                  min: min - 1,
                  max: max === min ? max + 1 : max,
                  interval: 1,
                  minorTick: {
                    show: true,
                    splitNumber: 10,
                  },
                }),
              },
        }}
        width="100%"
      />
      <Typography
        color={grey[54]}
        sx={{position: 'absolute', top: '50%', left: -8, transform: 'rotate(-90deg)'}}
        variant="subtitle2"
      >
        {title?.y || ''}
      </Typography>
      <Typography
        color={grey[54]}
        sx={{position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-42%)'}}
        variant="subtitle2"
      >
        {title?.x || ''}
      </Typography>
    </Wrapper>
  );
}

export default Chart;
