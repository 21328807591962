import styled from 'styled-components';

export const PlayerCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isToggled ? 'center' : 'flex-start')};
`;

export const Wrapper = styled.div`
  padding: ${({theme}) => theme.spacing(2)} ${({theme}) => theme.spacing(3)};
  background-color: white;
  border-radius: 6px;

  &:last-child {
    margin-top: ${({theme}) => theme.spacing(1)};
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 0;
    margin-bottom: ${({theme}) => theme.spacing(1)};
    ${({theme}) => theme.breakpoints.down('md')} {
      margin-top: ${({theme}) => theme.spacing(4)};
    }
  }
`;

export const ButtonsWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  ${({theme}) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  & > div {
    ${({theme}) => theme.breakpoints.down('sm')} {
      display: flex;
      flex-direction: column;
    }
    .MuiButtonBase-root {
      &:not(:last-child) {
        margin-right: ${({theme}) => theme.spacing(4)};
        ${({theme}) => theme.breakpoints.down('sm')} {
          margin-right: 0;
        }
      }
    }
  }
  .MuiButtonBase-root {
    ${({theme}) => theme.breakpoints.down('sm')} {
      margin-right: 0;
      margin-bottom: ${({theme}) => theme.spacing(1)};
    }
  }
`;
