import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {renderCondition} from '../../constants';
import {FunctionalStateIndicator} from '../Report/ReportTable/ReportTable';
import {ConditionItem, LinkText, Title, Wrapper} from './TeamCard';

const TeamCard = ({team}) => {
  const history = useHistory();

  return (
    <Wrapper>
      <Title underline="none" variant="button">
        {team.name.toUpperCase()}
        <ConditionItem reportsCount={team.reportsCount}>
          {!team.reportsCount ? (
            'Отчеты еще не добавлены.'
          ) : (
            <FunctionalStateIndicator type={renderCondition(team.state)} />
          )}
        </ConditionItem>
      </Title>
      <LinkText onClick={() => history.push(`/functional_testing/${team.groupId}`)}>Смотреть</LinkText>
    </Wrapper>
  );
};

TeamCard.propTypes = {
  groupId: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.number,
};

export default TeamCard;
