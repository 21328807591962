import React from 'react';

function Print() {
  return (
    <React.Fragment>
      <g>
        <path
          d="M16.3828 3.9375H14.0625V1.26562C14.0625 0.567738 13.4948 0 12.7969 0H5.20312C4.50524 0 3.9375 0.567738 3.9375 1.26562V3.9375H1.61719C0.725484 3.9375 0 4.66298 0 5.55469V12.4453C0 13.337 0.725484 14.0625 1.61719 14.0625H5.0625H12.9375H16.3828C17.2745 14.0625 18 13.337 18 12.4453V5.55469C18 4.66298 17.2745 3.9375 16.3828 3.9375ZM5.0625 1.26562C5.0625 1.18807 5.12557 1.125 5.20312 1.125H12.7969C12.8744 1.125 12.9375 1.18807 12.9375 1.26562V3.9375H5.0625V1.26562ZM5.625 12.9375V11.9531C5.625 11.8756 5.68807 11.8125 5.76562 11.8125H12.2344C12.3119 11.8125 12.375 11.8756 12.375 11.9531V12.9375H5.625ZM16.875 12.4453C16.875 12.7167 16.6542 12.9375 16.3828 12.9375H13.5V11.9531C13.5 11.2552 12.9323 10.6875 12.2344 10.6875H5.76562C5.06774 10.6875 4.5 11.2552 4.5 11.9531V12.9375H1.61719C1.34578 12.9375 1.125 12.7167 1.125 12.4453V5.55469C1.125 5.28328 1.34578 5.0625 1.61719 5.0625H4.5H13.5H16.3828C16.6542 5.0625 16.875 5.28328 16.875 5.55469V12.4453Z"
          fill="#2C2C2C"
        />
      </g>
    </React.Fragment>
  );
}
export default Print;
