import axios from 'axios';

import {API_ENDPOINT} from '../constants/config';

const RoomsAPI = {
  getAllRooms() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/rooms/all`)
        .then((d) => d.data)
        .then((exercises) => {
          if (exercises.error !== undefined) {
            return reject(exercises.error);
          }
          resolve(exercises);
        })
        .catch((err) => reject(err));
    });
  },

  createRoom(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}/rooms/create`, data)
        .then((d) => d.data)
        .then((ex) => {
          if (ex.error !== undefined) {
            return reject(ex.error);
          }
          resolve(ex);
        })
        .catch((err) => reject(err));
    });
  },

  updateRoom(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}/rooms/update`, data)
        .then((d) => d.data)
        .then((ex) => {
          if (ex.error !== undefined) {
            return reject(ex.error);
          }
          resolve(ex);
        })
        .catch((err) => reject(err));
    });
  },

  deleteRoom(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}/rooms/delete/${id}`)
        .then((d) => d.data)
        .then((ex) => {
          if (ex.error !== undefined) {
            return reject(ex.error);
          }
          resolve(ex);
        })
        .catch((err) => reject(err));
    });
  },
  loadAllDressingRooms() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/dressing-room`)
        .then((d) => d.data)
        .then((exercises) => {
          if (exercises.error !== undefined) {
            return reject(exercises.error);
          }
          resolve(exercises);
        })
        .catch((err) => reject(err));
    });
  },
};

export default RoomsAPI;
