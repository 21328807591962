export const mock = [...Array(15)].fill({
  type: 'goal',
  playerFirstName: 'Name4',
  playerLastName: 'Surname4',
  playerNumber: 4,
  playerTeam: 'Югра-2006',
  time: '05:15',
  videoTime: '0:27:07',
  gameFormat: 'Равные составы',
  currentScore: '1:0',
  isOurGoal: false,
});
