import React from 'react';

import SchedulePanel from '../calendar/panels/SchedulePanel';
import ChessTemplate from '../templates/ChessTemplate';

export default function ScheduleApp() {
  return (
    <ChessTemplate active="schedule">
      <SchedulePanel />
    </ChessTemplate>
  );
}
